import React from 'react';

function PartListPitClean({modalType, pitClean, setPitClean}) {

    return (
        <>
            <div className="jobCardDivHead" >Pit Clean</div>
                    <div className="jobCardInputDivSec" >
                        <div>Depth (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.depth}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Depth (m)" value={pitClean.depth} 
                            onChange={(e)=>setPitClean({ ...pitClean, depth : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Width (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.width}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Width (m)" value={pitClean.width} 
                            onChange={(e)=>setPitClean({...pitClean, width : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Length (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.length}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Length (m)" value={pitClean.length} 
                            onChange={(e)=>setPitClean({...pitClean, length : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Hose Length (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.hose}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Hose Length (m)" value={pitClean.hose}
                             onChange={(e)=>setPitClean({...pitClean, hose : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Truck Access (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.truckAccess}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Truck Access (m)" value={pitClean.truckAccess} 
                            onChange={(e)=>setPitClean({...pitClean, truckAccess : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Truck Capacity (L)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.truckCapacity}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Truck Capacity (L)" value={pitClean.truckCapacity} 
                            onChange={(e)=>setPitClean({...pitClean, truckCapacity : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Estimated Waste (L)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.estimate}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Esttimated Waste (L)" value={pitClean.estimate} 
                            onChange={(e)=>setPitClean({...pitClean, estimate : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Waste Type</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitClean.wasteType}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Waste Type" value={pitClean.wasteType} 
                            onChange={(e)=>setPitClean({...pitClean, wasteType : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Confined Space Equipment</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ pitClean.confined } 
                                onChange={(e)=>setPitClean({...pitClean, confined : true})} /><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !pitClean.confined } 
                                onChange={(e)=>setPitClean({...pitClean, confined : false})} /><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{pitClean.confined ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    

        </>
    );
}

export default PartListPitClean;