import React, {useEffect, useState} from 'react'

import '../intranet/components/style.css'
// import { LEAVESTATUS_UPDATE
// } from '../../store/endpoint';
import Swal from 'sweetalert2'

import { connect } from 'react-redux';
import Scrollbars from '../../common/ImprovedScrollBar';
import { fetch_all_timesheetList, team_empl_timeSheet, team_timeSheetLogList } from './common/actions';
import { 
    //Avatar, 
    CircularProgress } from '@material-ui/core';
import './style.css';
import moment from 'moment';
import Api from '../../store/api'
import Failerrdevmsg from '../../common/failErrDevMsg';
import { cl_green, cl_red } from '../../common/Colors';
import EmpTimeSheetModalBase from './EmpTimeSheetModalBase';
import { useDispatch } from 'react-redux';
import { ACCOUNTS_TIMESHEET_STATUS_UPDATE } from '../../store/endpoint';
import TimeSheetAccessModal from './TimeSheetAccessModal';
import { delete_timeSheet } from '../team/action';

function TimeSheetList({ empTimeSheetLoader, timesheetList, timeSheetListPage, team_TimesheetFx, timeSheetListLoader, stopPaging, timeSheetInit, teamTimeSheetLog,delete_timeSheetFx }) {
    
    const [nextPage, setNextPage] = useState(timeSheetListPage)
    const [timeSheetFetch, setTimeSheetFetch] = useState(null)
    const [timeSheetAccess, setTimeSheetAccess] = useState(null)
    const [timeSheetAccessModal, setTimeSheetAccessModal] = useState(false)
    const [timeSheetModal, setTimeSheetmodal] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        team_TimesheetFx(null, 1, null, null);
    }, [team_TimesheetFx]);


    const approveTimeSheet = (val, id) =>{
        if(val){
            let get_url = `${ACCOUNTS_TIMESHEET_STATUS_UPDATE}${id}/`
            let data = new FormData()
            data.append('timesheet_status','accepted')
        const success = () => {
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Timesheet Approved',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
            
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }
        
        Api('put', get_url, data, success, failed).then(res => {
                success()
                dispatch({
                    type : 'REMOVE_TIMESHEET',
                    id   : id
                });
            })
            .catch(err => failed() )
        }
        else{
            
            let get_url = `${ACCOUNTS_TIMESHEET_STATUS_UPDATE}${id}/`
            let data = new FormData()
            data.append('timesheet_status','rejected')
        const success = () => {
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Timesheet Rejected',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
        
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }
        
        Api('put', get_url, data, success, failed).then(res => {
                success()
                dispatch({
                    type : 'REMOVE_TIMESHEET',
                    id   : id
                });
            })
            .catch(err => failed())
        }
        
    }

    const ScrollUp = (val) =>{
        const {top} = val
        // console.log('SCROLL',top, pageNo, clientPage)
        if((top >= 0.999)&&(nextPage===timeSheetListPage)&&!stopPaging){
            setNextPage(timeSheetListPage+1)
            team_TimesheetFx(null, timeSheetListPage+1, null, null)
        }
    }

    const getTimeSheetLogList = (id) =>{
        const success = () =>{
            setTimeSheetAccessModal(true);
            setTimeSheetAccess(null)
        }
        const failed = (res) =>{
            Failerrdevmsg(res)
        }
        teamTimeSheetLog(id, null, success, failed)
    }

    const teamTimeSheetDelete = (timeShhetId) =>{
        const success = () => {
            dispatch({
                type : 'REMOVE_TIMESHEET',
                id   : timeShhetId
            });
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'TimeSheet Deleted successfully!',
              showConfirmButton: false,
              timer: 1500
          })
      }
    
      const failed = (res) => {
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res ? res.data && res.data.app_data ? res.data.app_data : 'Something went wrong!' : 'Something went wrong!'
          })
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: cl_red,
        cancelButtonColor: cl_green,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          console.log(result)
        if (result.value) {
          delete_timeSheetFx(timeShhetId, success, failed)
        }
      })
    
      }
    

    return  timesheetList && timesheetList.length!==0 ?
                    <Scrollbars style={{ height:'68vh', width:'100%', border:'1px solid lightgray', backgroundColor:'white' }} 
                    autoHide   onUpdate={ScrollUp} >
                    <table className="accountsTableSec">
                            <th>Employee</th>
                            <th>TimeSheet Last Updated</th>
                            <th>TimeSheet Week</th>
                            <th>TimeSheet View</th>
                            <th>TimeSheet Access Log View</th>
                            <th>TimeSheet Status</th>
                        <tbody style={{ width:'100%' }}>
                            {
                            timesheetList.map((item,k) => {
                                
                                return(
                                    item.timesheet_status==='pending' ?
                            <tr key={k}
                                style={{
                                    backgroundColor: k%2===0 ? 'white' : 'var(--cl_ivery)'
                                }}
                            >
                                <td className="shdl-Leave-emp-body" valign='center'>
                                    {/* <Avatar src={item.employee_dp} variant={'rounded'} style={{ paddingLeft:'5%' }} /> */}
                                    <p style={{ wordBreak:'break-all', textAlign : 'center', width:'100%' }}>{item.employee.name}</p>
                                </td>
                                <td>{moment(item.edited_date_time).format('DD-MMM-YYYY')}</td>
                                <td>{moment(item.week_startdate).format('DD-MMM-YYYY')} - {moment(item.week_startdate).add(6,'day').format('DD-MMM-YYYY')}</td>
                                
                                <td >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }}>
                                        {
                                            timeSheetFetch && timeSheetFetch.id===item.id && timeSheetModal ? (
                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                            ) : (
                                                <button className="viewBtn"
                                                onClick={()=>{ 
                                                    // let todayDate = moment(new Date());
                                                        setTimeSheetFetch(item)
                                                        dispatch({
                                                            type:'INIT_EMPLOYEE_TIMESHEET',
                                                            timeSheet: {...item.weekly_report, id : item.id},
                                                            filterDate: item.week_startdate,
                                                            userId : item.employee.employee_id,
                                                        })
                                                        setTimeSheetmodal(true); }}
                                                >View</button>
                                            )
                                        }
                                        <button className="viewBtn" onClick={()=>{ teamTimeSheetDelete(item.id) }}
                                        style={{ background:'var(--cl_red)', color:'white', fontWeight:'bold' }}>Delete</button>
                                    </div>
                                </td>
                                <td
                                onClick={()=>{ 
                                        // let todayDate = moment(new Date());
                                            setTimeSheetAccess(item)
                                            getTimeSheetLogList(item.id)
                                            // dispatch({
                                            //     type:'INIT_EMPLOYEE_TIMESHEET',
                                            //     timeSheet: {...item.weekly_report, id : item.id},
                                            //     filterDate: item.week_startdate,
                                            //     userId : item.employee.employee_id,
                                            // })
                                             }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }}>
                                        {
                                            timeSheetAccess && timeSheetAccess.id===item.id && timeSheetAccessModal ? (
                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                            ) : (
                                                <button className="viewBtn">View</button>
                                            )
                                        }
                                    </div>
                                </td>
                                
                                <td>{   
                                    item.timesheet_status==='pending' ?
                                    <div style={{ display:'flex', flexDirection:'row' }}>
                                        <div className="accountTableStatusBtn"  onClick={()=> approveTimeSheet(true, item.id) }
                                         style={{ backgroundColor:'var(--cl_green)' }}>Approve</div>
                                        {/* <div className="accountTableStatusBtn"
                                              onClick={()=> approveLeave(false, item.id)}
                                        >Reject</div> */}
                                    </div>
                                :
                                    <div>
                                        <div className="accountTableStatusBtnDis"
                                        
                                         style={{ backgroundColor: item.timesheet_status ? 'var(--cl_green)' : 'red' }}>
                                             { item.timesheet_status ? 'Approved' : 'Rejected'}
                                        </div>
                                    </div> 
                                    }
                                </td>
                            </tr>
                              : null
                              )
                            })    
                        }
                        {
                            timeSheetListLoader ?
                                <CircularProgress />
                            :   null
                        }
                        </tbody>
                    </table>
                    {    empTimeSheetLoader ?
                            <EmpTimeSheetModalBase 
                                modalStatus={timeSheetModal} 
                                modalAction={setTimeSheetmodal} 
                                usrName={timeSheetFetch && timeSheetFetch.employee.name} 
                                usrId={timeSheetFetch && timeSheetFetch.employee.employee_id} 
                            />
                        :
                        null
                    }
                    <TimeSheetAccessModal
                        modalAction={setTimeSheetAccessModal}
                        modalStatus={timeSheetAccessModal}
                        timeSheetItem={timeSheetAccess}
                    />
                    </Scrollbars>
                    :
                    timesheetList && timesheetList.length===0 && !timeSheetListLoader ?
                    <table className="accountsTableSec">
                    <thead>
                        <th>No Time-Sheets</th>
                        
                    </thead>
                    </table>
                    :
                    stopPaging ?    null :
                    <div style={{ display: 'flex', height: '100px', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center',padding:'10px 0' }} >
                            <CircularProgress style={{height:'30px',width:'30px'}}/>
                        </div>
                        
                    
            
    
}

const mapStateToProps = (state) =>{
    const { empTimeSheetLoader, timesheetList, timeSheetListPage, timeSheetListLoader, stopPaging  } = state.accountsData
    return { empTimeSheetLoader, timesheetList, timeSheetListPage, timeSheetListLoader, stopPaging }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        team_TimesheetFx: (data, page, success, failed) => dispatch(fetch_all_timesheetList(page)),     
        timeSheetInit : (data, success, failed, contentDate, id) => dispatch(team_empl_timeSheet(data, success, failed, contentDate, id)),
        teamTimeSheetLog : (id, page, success, failed) => dispatch(team_timeSheetLogList(id, page, success, failed)),
        delete_timeSheetFx : (id, success, failed) => dispatch(delete_timeSheet(id, success, failed))   
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (TimeSheetList)
