import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Scrollbars from '../../../common/ImprovedScrollBar';
import Api from '../../../store/api';
import { CLIENT_EDIT, DEL_QUOTE_GEN_TOW_NEW, EDIT_DEL_QUOTE_GEN_TOW, EDIT_QUOTE_GEN_TOW_NEW, QUOTE_GEN_ADD_TOW, SITE_TYPE_OF_WASTE_LIST } from '../../../store/endpoint';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { DeleteForever, EditRounded } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { cl_green, cl_red } from '../../../common/Colors';
const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    
  modalLow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  function TransitionsModal({
      modalStatus, 
      modalAction, 
      typeOfWaste,
      setTypeOfWaste,
      jobCardListFn,
      clientId,
      typeOfWasteList,
      setClientDet,
      setSiteMailID,
      setClientName,
      setClientSelection,
      setClientId,
      setTypeOfWasteList,
      setClientCardCSS,
      setTypeOfWasteId,
      typeOfWasteId
  }) {
  
      const classes = useStyles();
      const handleShut = () => {
        if(currTOWObj && (currTOWObj.child_exists===true && (newSiteProf && newSiteProfName===''))){
          let postData = { 'type_of_waste' : typeOfWaste }
          Api('post', `${CLIENT_EDIT}wastetype/${clientId}/`, postData)
            .then((res) => {
              console.log(res)
              setTypeOfWaste(res.type_of_waste_str_profile_name)
              if(res.id)
                setTypeOfWasteId(res.id)
              jobCardListFn(clientId, newSiteProfName==='' ? res.type_of_waste_str_profile_name : newSiteProfName, null)      
          })
        }else if(newSiteProf && newSiteProfName!==''){
          let postData = { 'child_name' : newSiteProfName, 'type_of_waste' : typeOfWaste }
          Api('post', `${CLIENT_EDIT}wastetype/${clientId}/`, postData)
            .then((res) => {
              console.log(res)
              setTypeOfWaste(`${newSiteProfName}(${typeOfWaste})`)
              if(res.id)
                setTypeOfWasteId(res.id)
              jobCardListFn(clientId, newSiteProfName==='' ? typeOfWaste : newSiteProfName,null)      
            })
        }else if(currTOWObj && currTOWObj.child_exists!==true){
          let postData = { 'type_of_waste' : typeOfWaste }
          Api('post', `${CLIENT_EDIT}wastetype/${clientId}/`, postData)
            .then((res) => {
              console.log(res)
              if(res.id)
                setTypeOfWasteId(res.id)
              jobCardListFn(clientId, typeOfWaste,null)      
          })
        }
        else{
          jobCardListFn(clientId,typeOfWaste,null)
        }
        
        

        setTimeout(()=>{
          setClientCardCSS(0)
      }, 2000)
        modalAction(false)
    };

    const [newSiteProf, setNewsiteProf] = useState(false)
    const [newSiteProfShift, setNewsiteProfShift] = useState(false)
    const [newSiteProfName, setNewSiteProfName] = useState('')
    const [currTOWObj, setCurrTOWObj]   = useState(null)
    const [towDataEdit, setTowDataEdit] = useState({status : 0, v : null, isDeletable : true})
    const [newTow, setNewTow] = useState('')
    const [newTowGst, setNewTowGst] = useState(0);
    console.log('TYPEofWaste=>',typeOfWasteList)

    // let options1 = [{   name : 'AMMONIA', value : 'AMMONIA'},
    // {   name : 'DESTRUCTION CERTIFICATE', value : 'DESTRUCTION CERTIFICATE'},
    // {   name : 'DILUTION PIT',            value : 'DILUTION PIT'},
    // {   name : 'DRUMS',                   value : 'DRUMS'},
    // {   name : 'FOOD WASTE',              value : 'FOOD WASTE'},
    // {   name : 'GERNIE WASHES',           value : 'GERNIE WASHES'},
    // {   name : 'GREASE',                  value : 'GREASE'},
    // {   name : 'GROUND WATER',            value : 'GROUND WATER'},
    // {   name : 'J120',                    value : 'J120'}, 
    // {   name : 'LEVY',                    value : 'LEVY' },
    // {   name : 'M250',                    value :'M250'},
    // {   name : 'N205',                    value : 'N205'}, 
    // {   name : 'OIL SEPERATOR',           value : 'OIL SEPERATOR'},
    // {   name : 'PACKAGED WASTE',          value : 'PACKAGED WASTE'},
    // {   name : 'PRODUCT DESTRUCTION',     value : 'PRODUCT DESTRUCTION'},
    // {   name : 'PUMP SERVICE',            value : 'PUMP SERVICE'},
    // {   name : 'RETENTION PIT',           value : 'RETENTION PIT'},
    // {   name : 'SEWER',                   value :'SEWER'},
    // {   name : 'STORMWATER',              value :'STORMWATER'},
    // {   name : 'TANK WASH OUT',           value : 'TANK WASH OUT'},      
    // {   name : 'TRADE WASTE',             value : 'TRADE WASTE'},                                              
    // {   name : 'TRANSPORT',               value : 'TRANSPORT'},  
    // {   name : 'WIEGH BRIDGE',            value : 'WEIGH BRIDGE'},                          
    // {   name : 'Z140',                    value : 'Z140'},                            
    // { name : 'NOT APPLICABLE',            value : 'NOT APPLICABLE'}]

    const editTOWName = (val=null, delVal=true) =>{
      const success = () => {
        setNewTow(null)
        setNewTowGst(0)
        Api('get',  `${SITE_TYPE_OF_WASTE_LIST}${clientId}/`,'')
            .then(response => {
                                setTypeOfWasteList(response)
                            })
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Type of Waste ${ val ? 'Edited' : 'Added'} successful!`,
            showConfirmButton: false,
            timer: 1500
        })
        // Api('get',`${SCOPEOFWORK_CRUD_TYPE_OF_WASTE}`).then( res => {
        //     setTypeofWaste(res);
        // })
    }

    const failed = () => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
        })
    }

    // const inpVal = (val) => setNewTow(val)

          if(val===null){
            Api('post',QUOTE_GEN_ADD_TOW,{
              name : newTow,
              gst_percentage : newTowGst
            }, success, failed)
          }
          else{
            let data = delVal ? { 'name' : newTow, 'gst_percentage' : newTowGst } : {"type_of_waste_str_profile_name": newTow,  "child_id": val}
                // console.log(data,EDIT_DEL_QUOTE_GEN_TOW,val)
                Api('put-json', delVal ? `${EDIT_DEL_QUOTE_GEN_TOW}${val}/` : `${EDIT_QUOTE_GEN_TOW_NEW}`, data, success, failed)
                
          }
            // if(val){
            //     Api('put-json',`${SCOPEOFWORK_CRUD_TYPE_OF_WASTE}${val.id}/`,{
            //         field_name : newTOW[0],
            //         gst_percentage        : newTOW[1]
            //     }, success, failed)
            // }
            // else{
            //     let postData = new FormData()
            //     postData.append('field_name',newTOW[0])
            //     postData.append('gst_percentage',newTOW[1])
            //     Api('post-form',`${SCOPEOFWORK_CRUD_TYPE_OF_WASTE}`,postData, success, failed)
            // }
        
         setTowDataEdit({status : 0, v : null})
    
    }

    const delTow = (val, delVal=null) =>{

      const success = () =>{
        setTowDataEdit({status : 0, v : null})
        Api('get',`${SITE_TYPE_OF_WASTE_LIST}${clientId}/`,'')
            .then(response => {
                                setTypeOfWasteList(response)
                            })
        
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Type of Waste deleted successful!',
              showConfirmButton: false,
              timer: 1500
          })
      }

      const failed = () =>{
        setTowDataEdit({status : 0, v : null})
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!'
          })
          
      }

      Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          customClass: {
            container : 'swal-improve-z'
          },
          showCancelButton: true,
          confirmButtonColor: cl_red,
          cancelButtonColor: cl_green,
          confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        setTowDataEdit({status : 0, v : null})
          if (result.value) {
            Api('delete', delVal===null ? `${EDIT_DEL_QUOTE_GEN_TOW}${val}/` : `${DEL_QUOTE_GEN_TOW_NEW}${val}/${delVal}`,null, success, failed)
            
              // delSingleDraft(val,   
              // success,                                
              // failed)
      }
  });
  
  }



      return(
        towDataEdit.status!==2 ?
        <Modal
        className={classes.modal}
        open={modalStatus}
        >
          {
            towDataEdit.status===1 ? 
            <div className="reoccurModalLayout"  style={{ overflowY:'auto' }}>
              <h3 style={{ marginBottom:'1%' }}> Enter Type of Waste </h3>
              <div className='TOWasteInputText'>
                <input
                  type="text" placeholder='Enter type of Waste' value={newTow} onChange={(e)=> setNewTow(e.target.value) }
                />
              </div>
              <h3 style={{ marginBottom:'1%' }}> Enter GST Percentage </h3>
              <div className='TOWasteInputText'>
                <input
                  type="text" placeholder='Enter GST Percentage value' value={newTowGst} onChange={(e)=> setNewTowGst(e.target.value) }
                />
              </div>
              
              <div className="reoccurModalContent">
                
              {
                  
                <div className="header-button-top newJobCardBtnActive cancelContinueBtn" 
                onClick={()=>{
                        setTowDataEdit({status : 0, v : null})
                }}  style={{ width:'auto', padding:'0 10px' }}>
                    Cancel
                </div>
                }
                
                  <div className="header-button-top newJobCardBtnActive" 
                 onClick={() =>{ 
                   editTOWName(towDataEdit.v, towDataEdit.isDeletable);
                }} style={{ width:'auto', padding:'0 10px'}}>
                    { towDataEdit.v===null ? 'Add New ' : 'Edit '} Type of Waste / Site Profile
                </div>
                
                
            </div>
            </div>
            :
            towDataEdit.status===0 ?
            <div className="reoccurModalLayout" style={{ overflowY:'auto' }}>
            <h3 style={{ marginBottom:'1%' }}>Choose Site Profile</h3>
            
            <div style={{ alignItems:'flex-start', width:'39vw' }}><p>Type of Waste (Profiles) <strong>Available</strong></p></div> 
            {   typeOfWasteList && typeOfWasteList.filter(el => el.child_exists).length!==0 ?
            <div className="reoccurModalContent typeOfWasteChipGrid" style={{ width:'40vw' }}>
              <Scrollbars style={{ height:'20vh' }} >
                {
                  typeOfWasteList.map((item, k)=>{
                    if(!item.child_exists) return null
                    else
                    return(
                      <div key={k} className={typeOfWaste === item.waste_type_str ? 'typeOfWasteChipSel' : 'typeOfWasteChip'}
                      onClick={()=>{ 
                        // if(!item.deletable){
                        setTypeOfWaste(item.original_name!==null ? `${item.waste_type_str}(${item.original_name})` : `${item.waste_type_str}`);
                     setTypeOfWasteId(item.id); setCurrTOWObj(item);
                      // }
                      
                //       Api('get',`${clientId}/`,'')
                // .then(response => {
                //       })                        
                    }}
                      >
                        <div onClick={()=>{ 
                        // if(item.deletable){
                        setTypeOfWaste(item.original_name!==null ? `${item.waste_type_str}(${item.original_name})` : `${item.waste_type_str}`);
                     setTypeOfWasteId(item.id); setCurrTOWObj(item);
                      // }
                    }}
                        >
                        {item.waste_type_str}{item.original_name!==null ? `(${item.original_name})` : null}<br />
                        {/* {item.hidden ? `(Waste type inaccessible. Previous client didn't accept quote.)` : null} */}
                        </div>
                        { 
                        <div className='typeOfWasteChipBtn' onClick={()=>{ 
                          setTowDataEdit({ status : 2, v : null  })
                          delTow(item.type_of_waste_id ? item.type_of_waste_id : item.id, 
                          !item.deletable ? item.waste_type_str : null); }}>
                          <DeleteForever style={{ fontSize:'.9rem' }} />
                        </div>
                        
                        }
                        { 
                        <div className='typeOfWasteChipBtn' onClick={()=>{ 
                          setNewTow(item.waste_type_str)
                          setNewTowGst(item.gst_percentage ? item.gst_percentage : 0)
                          setTowDataEdit({ status : 1, v : item.type_of_waste_id ? item.type_of_waste_id : item.id, isDeletable : item.deletable}); }}>
                          <EditRounded  style={{ fontSize:'.9rem' }}/>
                        </div>
                      
                        }
                      </div>
                    )
                  })
                }          
                </Scrollbars>              
            </div>
            :
            <div style={{ width:'39vw', textAlign:'center', backgroundColor:'var(--cl_ivery)', margin:'2% 0' }}><p>No type of waste (Profiles) available</p></div>
            }
            <div style={{ alignItems:'flex-start', width:'39vw' }}><p>Type of Waste (Profiles) <strong>Not Available</strong></p></div>
            {   typeOfWasteList && typeOfWasteList.filter(el => !el.child_exists).length!==0 ? 
            <div className="reoccurModalContent typeOfWasteChipGrid" style={{ width:'40vw', paddingTop:'1%' }}>
              <div style={{ width:'97%', textAlign:'left', marginBottom:'1%', fontSize:'.9rem', fontWeight:'500' }}><p>A new site profile will be created with the type of waste you choose</p></div>
              <Scrollbars style={{ height:'20vh' }} >
                {
                  typeOfWasteList && typeOfWasteList.map((item, k)=>{
                    if(item.child_exists) return null
                    else
                    return(
                      <div key={k} className={typeOfWaste === item.waste_type_str ? 'typeOfWasteChipSel' : 'typeOfWasteChip'}>
                        <div onClick={()=>{ setTypeOfWaste(item.waste_type_str); setCurrTOWObj(item) }}>{item.waste_type_str}</div>
                        
                        { item.deletable ?
                        <div className='typeOfWasteChipBtn' onClick={()=>{ 
                          setTowDataEdit({ status : 2, v : null, isDeletable : item.deletable  })
                          delTow(item.type_of_waste_id ? item.type_of_waste_id : item.id); }}>
                          <DeleteForever style={{ fontSize:'.9rem' }} />
                        </div>
                        : null
                        }
                        { item.deletable ?
                        <div className='typeOfWasteChipBtn' onClick={()=>{ 
                          setNewTow(item.waste_type_str)
                          setNewTowGst(item.gst_percentage ? item.gst_percentage : 0)
                          setTowDataEdit({ status : 1, v : item.type_of_waste_id ? item.type_of_waste_id : item.id, isDeletable : item.deletable}); }}>
                          <EditRounded  style={{ fontSize:'.9rem' }}/>
                        </div>
                        : null
                        }
                      </div>
                    )
                  })
                }          
                
                </Scrollbars>              
            </div>
            :
            <div style={{ width:'39vw', textAlign:'center', backgroundColor:'var(--cl_ivery)', margin:'2% 0' }}><p>No Type of Waste (Profiles) Available</p></div>
            }
            
            { currTOWObj && currTOWObj.child_exists ?
            <div className='newsiteProfCheck'>
              <div onClick={()=>{ setNewsiteProf(!newSiteProf); setNewsiteProfShift(true); }}>
                <input type='checkbox' value={newSiteProf} onChange={()=>{ setNewsiteProf(!newSiteProf);  }}/>
                <p className={newSiteProf ? 'newsiteProfCheckYES':'newsiteProfCheckNO'}>{newSiteProf ? 'YES' : 'NO'}</p>
                <span className={newSiteProf ? 'newsiteProfCheckON':'newsiteProfCheckOFF'}></span>
              </div>
              <p>Do you want to <strong>CREATE</strong> a new site profile with same type of waste</p>
            </div>
            : null
            }
            { newSiteProfShift && newSiteProf ?
            <div className='newsiteProfCheckName'>
              <p>Provide a unique name for new Site Profile :</p>
              <input type='text' value={newSiteProfName} placeholder={typeOfWaste} onChange={(e)=> setNewSiteProfName(e.target.value) } />
              <CancelRoundedIcon style={{ color:'red', fontSize:'1.7rem', cursor:'pointer' }} onClick={()=>{
                setNewsiteProfShift(false)
                setNewSiteProfName('')
              }} />
            </div>  
            : null}
            <div className="reoccurModalContent">
                
              {
                  
                <div className="header-button-top newJobCardBtnActive cancelContinueBtn" 
                onClick={()=>{
                        setClientDet(null)
                        setSiteMailID('')
                        setClientName('')
                        setClientSelection('')
                        setClientId('')
                        setTypeOfWaste('')
                        setTypeOfWasteList([])
                        modalAction(false)
                        setNewsiteProf(false)
                        setCurrTOWObj(null)

setTimeout(()=>{
  setClientCardCSS(0)
}, 2000)
                
                }}  style={{ width:'auto', padding:'0 10px' }}>
                    Cancel
                </div>
                }
                {
                  currTOWObj ?
                <div className={ currTOWObj && currTOWObj.child_exists ? "header-button-top newJobCardBtnActive greenContinueBtn" : "header-button-top newJobCardBtnActive"} 
                id="towContinueBtn"
                 onClick={() =>{ 
                    handleShut()
                }} style={{ width:'auto', padding:'0 10px'}}>
                    {
                      currTOWObj && currTOWObj.child_exists ? 'Continue' : 'Create Site Profile and Continue'
                    }
                </div>
                :
                <div className="header-button-top newJobCardBtnActive" id="towAddNewBtn"
                 onClick={() =>{ 
                  setTowDataEdit({status : 1, v : null, isDeletable : true}); 
                }} style={{ width:'auto', padding:'0 10px'}}>
                    Add New Type of Waste / Site Profile
                </div>
                
                }
            </div>
            
    </div>
    : <span></span>
          }
            
        </Modal>
        : null
      );
  }

export default TransitionsModal;