import React from 'react'
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { EnviroBrandLogo } from '../../../common/Images'

function Header({ permissions }) {
    const { pathname } = useLocation()

    let navHeadStatus = true;
    let chk_rout_o = pathname.split('/')
    let checking_value = '/' + chk_rout_o[1] + '/' + chk_rout_o[2] + '/generate-quote/' 
    let chk_route = pathname.split(checking_value)
    if(chk_route[0] === '' && chk_route[1]) {
        navHeadStatus = false
    }
    
    // const EnviroWaste = () => {

    //     if( permissions && permissions.companies.waste ) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'waste/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
            
    //         return (
    //             <Link to={route_path} className={routing_path[2] === 'waste' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Waste</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }

    // const EnviroHills = () => {
    //     if(permissions && permissions.companies.hills) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'hills/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} className={routing_path[2] === 'hills' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Hills</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }

    // const EnviroPump = () => {
    //     if(permissions && permissions.companies.pumps) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'pump/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} className={routing_path[2] === 'pump' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Pumps</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }

    // const EnviroDestruction = () => {
    //     if(permissions && permissions.companies.destruction) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'destruction/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} className={routing_path[2] === 'destruction' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Destruction</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }

    return (
        <div className="common-header">
            <div className="common-brand-layout">
                <img className="common-brand-img" src={EnviroBrandLogo} alt='enviro logo' />
            </div>
            {
                navHeadStatus ? (
                    <div className="header-button-layout">
                        {/* <EnviroWaste />
                        <EnviroHills />
                        <EnviroPump />
                        <EnviroDestruction />
                        <div className="header-button-top">
                            <p>Enviro All</p>
                        </div> */}
                    </div>
                ) : (
                    <Link to={checking_value} style={{ textDecoration: 'none' }} className="header-button-layout">
                        <div className="header-button-top">
                            <p>Back</p>
                        </div>
                    </Link>
                )
            }
        </div>
    )
}


const mapStateToProps = (state) => {
    const { user, permissions } = state.userLogin
    return { user, permissions }
}

export default connect(mapStateToProps) (Header)
