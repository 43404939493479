import React, {Fragment}  from 'react'
import { useParams } from 'react-router-dom'
import FolderIcon from '@material-ui/icons/Folder'
import SearchIcon from '@material-ui/icons/Search'
import Scrollbar from '../../../common/ImprovedScrollBar.jsx';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { HOST } from '../../../store/endpoint'
import { search_folder_details } from '../action'
import NoDatasScreen from '../../../common/no_datas'

function Innerpage({ folder_details, search_folder, permissions }) {

    const {folder} = useParams()
         return (
        <div className="team-detail-layout-inner">
            <div className="team-detail-layout-top">
                <div className="team-detail-layout-inner-top"> 
                  <FolderIcon style={{ marginLeft: 10, marginRight: 10 }}/> {folder}
                </div>
                <div className="team-detail-layout-inner-search"> 
                   <input className="team-inner-search"
                        type='text'
                        placeholder='Sarch an employee'
                        onChange={txt => search_folder(txt.target.value)}
                   />
                   <SearchIcon style={{cursor:'pointer'}}/>
                </div>
                {
                    (permissions?.team?.personal_profile_only ||
                    !permissions?.team?.view) &&
                    <Link to="/team"  className="team-back-btn-layout">
                <div className="header-back-btn">
                    <p className="p-0 m-0">Back</p>
                </div>
                </Link>
                }
                
               
            </div>
            
            <Scrollbar style={{ height: '70vh' }} className="team-detail-layout-bottom">
            
                 {
                     folder_details.length > 0 ? (
                         <Fragment>
                             <div className="team-detail-layout-bottom-top">
                                <div className='team-work-table-title1 team-inner-txt-color-blue'>
                                    Emp ID
                                </div>
                                <div className='team-work-table-title2 team-inner-txt-color-blue'>
                                    Name of the Employee
                                </div>
                                <div className='team-work-table-title3 team-inner-txt-color-blue'>
                                    Position Title
                                </div>
                                <div className='team-work-table-title4 team-inner-txt-color-blue'>
                                    Driving Licience
                                </div>
                                <div className='team-work-table-title5 team-inner-txt-color-blue'>
                                    Expiry Date
                                </div>
                                <div className='team-work-table-title6 team-inner-txt-color-blue'>
                                    Notification Alert
                                </div>
                                <div className='team-work-table-title7 team-inner-txt-color-blue'>
                                    Status
                                </div>
                            </div>

                            
                            {
                                folder_details.map((item, key) => {
                                    const { employee, name, designation, file_item, expiry_date, alert_before } = item;
                                    return (
                                        <div key={key} className="team-detail-layout-bottom-top">
                                            <div className='team-work-table-title1'>
                                                {employee}
                                            </div>
                                            <div className='team-work-table-title2'>
                                                {name}
                                            </div>
                                            <div className='team-work-table-title3'>
                                                {designation}
                                            </div>
                                            <div className='team-work-table-title4'>
                                                {/* {file_item} */}
                                                <a href={HOST + file_item} target="__blank"  >
                                                    <button>File</button>
                                                </a>
                                            </div>
                                            <div className='team-work-table-title5'>
                                                {expiry_date}
                                            </div>
                                            <div className='team-work-table-title6'>
                                                {alert_before}
                                            </div>
                                            <div className='team-work-table-title7'>
                                                {'Accepted'}
                                            </div>
                                        </div>

                                        
                                    )
                                })
                            }
                         </Fragment>
                     ) : ( <NoDatasScreen /> )
                 }
            </Scrollbar>
        
    </div>
    )    
}

const mapStateProps = (state) => {
    const { folder_details } = state.team
    const { permissions } = state.userLogin
    return { folder_details, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        search_folder: (keyword) => dispatch(search_folder_details(keyword))
      }
}

export default connect(mapStateProps, mapDispatchToProps) (Innerpage)
