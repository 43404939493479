import React, {useState} from 'react';
import DateTimeInput from './DateTimeInput';
import Scrollbars from './ImprovedScrollBar';
import { team_leaveApply } from '../screens/team/action';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import moment from 'moment';
import { TextAreaLimiter } from './TextAreaLimiter';

function LeaveApplyModal({
    modalAction,
    Name,
    leave_application
}) {


    const [leaveFromDate, setLeaveFromDate] = useState('')
    const [leaveToDate, setLeaveToDate] = useState('')
    const [lastFromWork, setLastFromWork] = useState('')
    const [leaveToWork, setLeaveToWork] = useState('')
    const [Attachment, setAttachment] = useState([])
    const [reasonComment, setReasonComment] = useState('');
    const [leaveType, setLeaveType] = useState('')

    const [normHrs, setNormHrs] = useState('0')
    const [normDays, setNormDays] = useState('0')
    const [pubHrs, setPubHrs] = useState('0')
    const [pubDays, setPubDays] = useState('0')
    const [othDays, setOthDays] = useState('0')
    const [othHrs, setOthHrs] = useState('0')
    const [textAreaValid, setTextAreaValid] = useState(true)
    const [comment, setComment] = useState('')

    const leaveApply = () =>{
        let postData = new FormData()
        postData.append('leave_type', leaveType)
        postData.append('reason', reasonComment)
        postData.append('attachment',Attachment)
        postData.append('leave_from', moment(leaveFromDate).format('YYYY-MM-DDThh:mm:ss'))
        postData.append('leave_to', moment(leaveToDate).format('YYYY-MM-DDThh:mm:ss'))
        postData.append('last_working_day', moment(lastFromWork).format('YYYY-MM-DDThh:mm:ss'))
        postData.append('return_working_day',leaveToWork)
        postData.append('normal_working_hours',normHrs)
        postData.append('normal_working_days',normDays)
        postData.append('public_holidays',pubDays)
        postData.append('public_holiday_hours',pubHrs)
        postData.append('other_days', othDays)
        postData.append('other_hours',othHrs)
        postData.append('total_days',parseInt(normDays)+parseInt(pubDays)+parseInt(othDays))
        postData.append('total_hours',parseInt(normHrs)+parseInt(pubHrs)+parseInt(othHrs))
        postData.append('comments', comment)

        const success = () => {
            modalAction(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Leave Application Sent',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () => {
            modalAction(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        for(var k of postData.entries()){
            console.log(k[0],k[1])
        }
        leave_application(postData, success, failed)
    }


    return (
        <div className="leaveApplyModalLayout">
            <div className="leaveApplyModalHeading">
                <div>LEAVE APPLICATION</div>
                <div className="header-button-top"  onClick={()=>  modalAction(false) }>
                    Back
                </div>  
            </div>
            <Scrollbars autoHide style={{ height:'75vh' }} >
            <div className="leaveTypeCheckDiv">
                <span className="leaveCommentImp">
                    <strong>Annual Leave</strong> must be applied for at least <strong>2 weeks before</strong> leave is to be taken. 
                </span>
                <div className="leaveTypeCheckHead">Tick appropiate leave:<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></div>
                <div className="leaveTypeCheckBoxDiv">
                    <div>
                        <span>
                            <input type="radio" checked={leaveType==='annual_leave'} value="annual_leave"
                            onChange={(txt)=> setLeaveType(txt.target.value) } />
                        </span>
                        <p>Annual Leave</p>
                    </div>
                    <div>
                        <span>
                            <input type="radio" checked={leaveType==='leave_without_pay'} value="leave_without_pay"
                            onChange={(txt)=> setLeaveType(txt.target.value) } />
                        </span>
                        <p>Leave Without Pay</p>
                    </div>
                    <div>
                        <span>
                            <input type="radio" checked={leaveType==='personal_leave'} value="personal_leave"
                            onChange={(txt)=> setLeaveType(txt.target.value) } />
                        </span>
                        <p>Personal Leave (Give reason): </p>
                    </div>
                    <div>
                        <span className="textAreaDiv">
                            <TextAreaLimiter isValid={setTextAreaValid}
                                configs={{
                                    cols : "30", rows : "10", placeholder : "Type your reason...",
                                    onChange : (txt)=> setReasonComment(txt.target.value) 
                                }}
                                
                            v={reasonComment}/>
                        </span>
                    </div>
                    <div>
                        <span>
                            <input type="radio" checked={leaveType==='long_service_leave'} value="long_service_leave"
                            onChange={(txt)=> setLeaveType(txt.target.value) } />
                        </span>
                        <p>Long Service Leave</p>
                    </div>
                    {/* <div>
                        <span>
                            <input type="radio" checked={leaveType==='sick_leave'} value="sick_leave"
                            onChange={(txt)=> setLeaveType(txt.target.value) } />
                        </span>
                        <p>Sick Leave</p>
                    </div> */}
                    <div>
                        <span>
                            <input 
                                type="file"
                                id="license-file-input"
                                className="team-input"
                                multiple
                                onChange={file => {

                                    setAttachment(file.target.files[0])
                                    
                                }}
                            />
                        </span>
                        <p>Attach Certificates</p>
                    </div>
                    
                </div>
            </div>
            <div className="leaveApplyDateDiv">
                <div className="leaveApplyDateSec">
                    <p>Leave From :<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                    <DateTimeInput
                        dateSetFn={setLeaveFromDate}
                        dateValue={leaveFromDate}
                        past={true}
                    />
                </div>
                <div className="leaveApplyDateSec">
                    <p>Leave To :<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                    <DateTimeInput
                        dateSetFn={setLeaveToDate}
                        dateValue={leaveToDate}
                        past={true}
                    />
                </div>
            </div>
            <div className="leaveApplyDateDiv">
                <div className="leaveApplyDateSec">
                    <p>Last Day of Work :<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                    <DateTimeInput
                        dateSetFn={setLastFromWork}
                        dateValue={lastFromWork}
                        rangeDateMax={leaveFromDate}
                    />
                </div>
                <div className="leaveApplyDateSec">
                    <p>Return to work on<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                    <DateTimeInput
                        dateSetFn={setLeaveToWork}
                        dateValue={leaveToWork}
                        rangeDate={leaveToDate}
                    />
                </div>
            </div>
            <div className="leaveApplyTableDiv">
                <table className="leaveApplyTable">
                    <p style={{ padding:'2%', fontWeight:600, textAlign:'center' }} >Number of Days and Hours <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                                                
                    <tr>
                        <th>Number of Working Days</th>
                        <td><input type="text" value={normDays} onChange={(txt)=> setNormDays(txt.target.value) } /><p>Days</p></td>
                        <td><input type="text" value={normHrs} onChange={(txt)=> setNormHrs(txt.target.value) } /><p>Hrs</p></td>
                    </tr>
                    <tr>
                        <th>Public Holidays</th>
                        <td><input type="text" value={pubDays} onChange={(txt)=> setPubDays(txt.target.value) } /><p>Days</p></td>
                        <td><input type="text" value={pubHrs} onChange={(txt)=> setPubHrs(txt.target.value) } /><p>Hrs</p></td>
                    </tr>
                    <tr>
                        <th>Other</th>
                        <td><input type="text" value={othDays} onChange={(txt)=> setOthDays(txt.target.value)} /><p>Days</p></td>
                        <td><input type="text" value={othHrs} onChange={(txt)=> setOthHrs(txt.target.value) } /><p>Hrs</p></td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <td>{parseInt(normDays)+parseInt(pubDays)+parseInt(othDays)}<p>Days</p></td>
                        <td>{parseInt(normHrs)+parseInt(pubHrs)+parseInt(othHrs)}<p>Hrs</p></td>
                    </tr>
                </table>
            </div>
            <div className="leaveApplyComment">
                <p>Comments: <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                <span>
                    <input type="text" value={comment} onChange={(txt)=> setComment(txt.target.value) } />
                </span>
            </div>
            <div style={{
                display:'flex', alignItems:'center', justifyContent:'center'
            }}>
                <div 
                    className={ leaveType && leaveFromDate && leaveToDate && lastFromWork && leaveToWork && normHrs && textAreaValid && 
                        normDays && pubDays && pubHrs && othDays && othHrs && (parseInt(normDays)+parseInt(pubDays)+parseInt(othDays))
                        && (parseInt(normHrs)+parseInt(pubHrs)+parseInt(othHrs)) && comment 
                       ? "leaveApplyBtn" : "leaveApplyBtn leaveApplyBtnDisabled"}
                    onClick={()=> leaveApply() }>
                    Apply Leave{leaveFromDate && leaveToDate && lastFromWork && leaveToWork ? '' : '(Please check the dates)' }
                </div>
            </div>
            </Scrollbars>
        </div>
    );
}

const mapDispatchToProps = (dispatch) =>{
    return{
        leave_application : (data, success, failed) => dispatch(team_leaveApply(data, 1, success, failed))
    }
}

export default connect(null, mapDispatchToProps) (LeaveApplyModal);