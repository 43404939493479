import React, {useState} from 'react'
// import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import { connect } from 'react-redux'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Fragment } from 'react';
import { PersonAddRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function VehicleLayout({ vehicle_listDateTime, selectedVehicleArr, setSelectedVehicleArr, primeVehicle, setPrimeVehicle, employee_listDateTime,
    selectedTeamArr, setSelectedTeamArr, additonalDriv, setAdditionalVehTeamArr, primeVehDriver, setPrimeVehicleDriver,
    primeVehObj=null, additionalVehArrObj=null, vehicleAvailLoader, empAvailLoader
    // chng=false
 }) {

    // console.log(vehicle_listDateTime, selectedVehicleArr,primeVehDriver,additonalDriv)

    const [selectedAddVeh, setSelectedAddVeh] = useState(null)

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: 120,
            maxHeight: 20,
            marginLeft: 5,
            marginRight: 5,
            marginTop: 0,
        },
        selectEmpty: {
            marginTop: 0,
        },
        resize: {
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }
    }));

    const SortDropdown = ({v, setVal}) => {
        const classes = useStyles();
        return (
            <FormControl className={classes.formControl}
                onClick={(e)=>{ e.stopPropagation(); }}
            >
                {
                    <InputLabel id="demo-multiple-chip-label">
                        {
                            empAvailLoader ?
                            <CircularProgress size={15}/>
                            :
                            'Driver'
                        }
                        
                    </InputLabel>
                }
                {/* <InputLabel id="demo-multiple-chip-label">Driver</InputLabel> */}
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14, color: 'black' }}
                    value={(typeof v !=='string' && typeof v !=='number') ? '' : v}
                    className={classes.resize}
                    style={{ color: 'black', borderBottom:'1px solid var(--cl_blue)' }}
                    
                    onChange={(val) => {
                        console.log(v, additonalDriv)
                        if(typeof val.target.value ==='string' || typeof val.target.value ==='number'){
                            setSelectedAddVeh(val)
                            setVal(val.target.value)
                            
                        }
                        val.preventDefault();
                        val.stopPropagation();
                        }}
                >
                    <MenuItem value={''}><PersonAddRounded/></MenuItem>
                    {/* {console.log(employee_listDateTime, v, setVal)} */}
                    {
                            employee_listDateTime.map((item, key) => {
                                let validCand = (!(selectedTeamArr && selectedTeamArr.find(el => el===item.id)) && !(additonalDriv && additonalDriv.find(el => el.driver_id===item.id)) && (primeVehDriver!==item.id)) && item.is_available ? true : false;

                                if(!item.terminated_status )
                                return (
                                        <MenuItem key={key} value={item.id}
                                        style={{
                                            cursor : validCand ? 'pointer' : 'default',
                                            pointerEvents: validCand ?  'auto': 'none',
                                            opacity: validCand ?  '1' : '0.4',
                                        }}
                                        >{item.name}</MenuItem>
                                )
                                else return null 
                            })
                    }

                </Select>
            </FormControl>
        )
    }


    const PrimeVehicleSelect = () =>{
        

        return(
            <div style={{ 
                height: 65, 
                backgroundColor: '#fff', 
                borderRadius: 20, 
                marginBottom: 10, 
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer' 
            }}
            onClick={(e)=>{ 
                    setSelectedAddVeh(null)
                    if(selectedVehicleArr && selectedVehicleArr.length>1){
                        setPrimeVehicle(selectedVehicleArr[0])
                        let removed = selectedVehicleArr.filter((_,k) => k!==0)
                        let removedTeam = additonalDriv ? additonalDriv.filter((el) => el.vehicle_id!==selectedVehicleArr[0]) : []
                        setAdditionalVehTeamArr(removedTeam)
                        setSelectedVehicleArr(removed)
                    }
                    else if(selectedVehicleArr && selectedVehicleArr.length===1){
                        setPrimeVehicle(selectedVehicleArr[0])
                        setSelectedVehicleArr([])
                        setAdditionalVehTeamArr([])
                    }
                    else{
                        setPrimeVehicle(null)
                        setPrimeVehicleDriver(null)
                    }
                
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {console.log(vehicle_listDateTime, primeVehicle, primeVehObj)}
                <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >{
                    vehicle_listDateTime && primeVehicle && vehicle_listDateTime.find(el=>el.id===primeVehicle) ? vehicle_listDateTime.find(el=>el.id===primeVehicle).vehicle_type :
                    primeVehObj && primeVehObj.id === primeVehicle ?
                    primeVehObj.vehicle_type : null}</p>
                <p style={{ fontSize: 16 }}>{
                    vehicle_listDateTime && primeVehicle && vehicle_listDateTime.find(el=>el.id===primeVehicle) ? vehicle_listDateTime.find(el=>el.id===primeVehicle).registration : 
                    primeVehObj && primeVehObj.id === primeVehicle ?
                    primeVehObj.registration : null}</p>
                {/* <p style={{ color: 'red', fontWeight: 300 }}>{inspection_msg}</p> */}
            </div>
            {   (primeVehicle && primeVehicle!=='') || (primeVehObj!==null && primeVehObj.id === primeVehicle) ?
                            <SortDropdown v={primeVehDriver} setVal={setPrimeVehicleDriver} />
                            : null
            }
            {   (primeVehicle && primeVehicle!=='') || (primeVehObj!==null && primeVehObj.id === primeVehicle) ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>Prime Vehicle</p> 
                    </div>
                    :   null
            }
            
        </div>
        )
    }

    const SelectedListArr = () =>{
        
         return (
             <>
            {/* {  additionalVehArrObj &&  (additionalVehArrObj.every((el)=>selectedVehicleArr.includes(el.id))) ?
                    additionalVehArrObj.map((item, key) => {
                        if(item.id===primeVehicle || item.id===primeVehObj.id) return null 
                        else
                        return(
                        <div style={{ 
                            height: 65, 
                            backgroundColor: '#fff', 
                            borderRadius: 20, 
                            marginBottom: 10, 
                            padding: 10,
                            display: 'flex',
                            justifyContent: 'space-between',
                            cursor: 'pointer' 
                        }}
                        key={key}
                        onClick={(e)=>{ e.stopPropagation(); e.preventDefault(); 
                            setSelectedAddVeh(null)
                            if( selectedVehicleArr){
                            let removed = selectedVehicleArr.filter(itemzx => itemzx !== item.id)
                            setSelectedVehicleArr(removed)
                            let removedTeam = additonalDriv ? additonalDriv.filter((el) => el.vehicle_id!==selectedVehicleArr[0]) : []
                        setAdditionalVehTeamArr(removedTeam)
                            } 
                                                              
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >
                                {item.vehicle_type}(ADDVOBJ)
                            </p>
                            <p style={{ fontSize: 16 }}>
                                {item.registration}
                            </p>
                        </div>
                        {   selectedVehicleArr && selectedVehicleArr.length!==0 ?
                                            <SortDropdown v={additonalDriv && additonalDriv.find(el => el.vehicle_id===item.id) ? additonalDriv.find(el => el.vehicle_id===item.id).driver_id : selectedAddVeh} 
                                            setVal={(val)=>{ 
                                                if(additonalDriv && additonalDriv.find(el => el.vehicle_id===item.id)){
                                                    let oldObj = additonalDriv
                                                    let oldObjIdx = additonalDriv.findIndex(el => el.vehicle_id===item.id)
                                                    oldObj.splice(oldObjIdx, 1, { vehicle_id : item.id, driver_id : val})
                                                    setAdditionalVehTeamArr(Object.assign([], oldObj))
                                                }
                                                else{
                                                    let obj={ vehicle_id : item, driver_id : val}; setAdditionalVehTeamArr(data => [...data, obj]);
                                                }
                                                }} />
                                        : null
                        }
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckCircleOutlineIcon /> 
                        </div>
                        
                        
                    </div>); })
                :
                null 
            } */}
            {   
                selectedVehicleArr.map((item, key) => {
                    // if(additionalVehArrObj && additionalVehArrObj.find(el => el.id===item)) return null 
                    // else
                    return(
                    <div style={{ 
                        height: 65, 
                        backgroundColor: '#fff', 
                        borderRadius: 20, 
                        marginBottom: 10, 
                        padding: 10,
                        display: 'flex',
                        justifyContent: 'space-between',
                        cursor: 'pointer' 
                    }}
                    key={key}
                    onClick={(e)=>{ e.stopPropagation(); e.preventDefault(); 
                        setSelectedAddVeh(null)
                        if( selectedVehicleArr){
                        let removed = selectedVehicleArr.filter(itemzx => itemzx !== item)
                        setSelectedVehicleArr(removed)
                        let removedTeam = additonalDriv ? additonalDriv.filter((el) => el.vehicle_id!==selectedVehicleArr[0]) : []
                        setAdditionalVehTeamArr(removedTeam)
                        } 
                                                          
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >
                            {vehicle_listDateTime && vehicle_listDateTime.find(el=>el.id===item) ? 
                                vehicle_listDateTime.find(el=>el.id===item).vehicle_type :
                                primeVehObj && primeVehObj.id===item ? primeVehObj.vehicle_type : null}
                        </p>
                        <p style={{ fontSize: 16 }}>
                            {vehicle_listDateTime && vehicle_listDateTime.find(el=>el.id===item) ? vehicle_listDateTime.find(el=>el.id===item).registration : 
                                primeVehObj && primeVehObj.id===item ? primeVehObj.registration : null
                            }
                        </p>
                        {/* <p style={{ color: 'red', fontWeight: 300 }}>{inspection_msg}</p> */}
                    </div>
                    {   selectedVehicleArr && selectedVehicleArr.length!==0 ?
                                        <SortDropdown v={additonalDriv && additonalDriv.find(el => el.vehicle_id===item) ? additonalDriv.find(el => el.vehicle_id===item).driver_id : selectedAddVeh} 
                                        setVal={(val)=>{ 
                                            if(additonalDriv && additonalDriv.find(el => el.vehicle_id===item)){
                                                let oldObj = additonalDriv
                                                let oldObjIdx = additonalDriv.findIndex(el => el.vehicle_id===item)
                                                oldObj.splice(oldObjIdx, 1, { vehicle_id : item, driver_id : val})
                                                setAdditionalVehTeamArr(Object.assign([], oldObj))
                                            }
                                            else{
                                                let obj={ vehicle_id : item, driver_id : val}; setAdditionalVehTeamArr(data => [...data, obj]);
                                            }
                                            }} />
                                    : null
                    }
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleOutlineIcon /> 
                    </div>
                    
                    
                </div>); })
            }
            { primeVehObj && primeVehObj.id !== primeVehicle && !(selectedVehicleArr && selectedVehicleArr.includes(primeVehObj.id)) ?
                    <div style={{ 
                            height: 65, 
                            backgroundColor: '#cccccc', 
                            borderRadius: 20, 
                            marginBottom: 10, 
                            padding: 10,
                            display: 'flex',
                            justifyContent: 'space-between',
                            cursor: 'pointer' 
                        }}
                        
                        onClick={(e)=>{ e.stopPropagation(); e.preventDefault(); 
                             
                            setSelectedAddVeh(null)
                            if(selectedVehicleArr && selectedVehicleArr.length===0 && primeVehicle===null){
                                setPrimeVehicle(primeVehObj.id)
                            }
                            else if(selectedVehicleArr && !selectedVehicleArr.some(itmzz => itmzz === primeVehObj.id)) 
                                setSelectedVehicleArr(itm => [...itm, primeVehObj.id]); 
                            
                            // else if(primeVehicle===primeVehObj.id){
                            //     if(selectedVehicleArr){
                            //         setPrimeVehicle(selectedVehicleArr[0])
                            //         let removed = selectedVehicleArr.filter((_,k) => k!==0)
                            //         setSelectedVehicleArr(removed)
                            //     }
                            //     else{
                            //         setPrimeVehicle(null)
                            //     }
                            // }
                            // else if( selectedVehicleArr){
                            //     let removed = selectedVehicleArr.filter(itemzx => itemzx !== id)
                            //     setSelectedVehicleArr(removed)
                            // } 
                            else{
                                setSelectedVehicleArr(itm => [...itm, primeVehObj.id])
                            }                                  
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >
                                {primeVehObj.vehicle_type}
                            </p>
                            <p style={{ fontSize: 16 }}>
                                {primeVehObj.registration}
                            </p>
                            {/* <p style={{ color: 'red', fontWeight: 300 }}>{inspection_msg}</p> */}
                        </div>
                                                
                    </div>
                    : null
                    }
                    {   additionalVehArrObj && ((selectedVehicleArr && additionalVehArrObj.some((el)=> !selectedVehicleArr.includes(el.id))) || selectedVehicleArr.length===0)
                        && (selectedVehicleArr && additionalVehArrObj.every((el)=> selectedVehicleArr.includes(el.id))) ?
                            additionalVehArrObj.map((el, k)=> {
                                if((primeVehicle && primeVehicle===el.id)||(primeVehObj && primeVehObj.id===el.id)) return null 
                                else
                                return(
                                    <div style={{ 
                                        height: 65, 
                                        backgroundColor: '#cccccc', 
                                        borderRadius: 20, 
                                        marginBottom: 10, 
                                        padding: 10,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        cursor: 'pointer' 
                                    }}
                                    key={k}
                                    onClick={(e)=>{ 
                                        setSelectedAddVeh(null)
                                        if(selectedVehicleArr && selectedVehicleArr.length===0 && primeVehicle===null){
                                            setPrimeVehicle(el.id)
                                        }
                                        else if(selectedVehicleArr && !selectedVehicleArr.some(itmzz => itmzz === el.id)) 
                                            setSelectedVehicleArr(itm => [...itm, el.id]); 
                                        
                                        else if(primeVehicle===el.id){
                                            if(selectedVehicleArr){
                                                setPrimeVehicle(selectedVehicleArr[0])
                                                let removed = selectedVehicleArr.filter((_,k) => k!==0)
                                                setSelectedVehicleArr(removed)
                                            }
                                            else{
                                                setPrimeVehicle(null)
                                                setPrimeVehicleDriver(null)
                                            }
                                        }
                                        else if( selectedVehicleArr){
                                            let removed = selectedVehicleArr.filter(itemzx => itemzx !== el.id)
                                            setSelectedVehicleArr(removed)
                                        } 
                                        else{
                                            setSelectedVehicleArr(itm => [...itm, el.id])
                                        }
                                                         
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >
                                            {el.vehicle_type}
                                        </p>
                                        <p style={{ fontSize: 16 }}>
                                            {el.registration}
                                        </p>
                                        {/* <p style={{ color: 'red', fontWeight: 300 }}>{inspection_msg}</p> */}
                                    </div>
                                                            
                                </div>
                                )
                            })
                    : null
                    }
            </>
         )
    }

    const VehicleListAll = () =>{
        

        return(
            vehicle_listDateTime.map((item, key) => {
                const { id, is_available,
                    // pre_inspection_check, 
                    registration, vehicle_type  } = item
                // let inspection_msg = '';
                // if(vehicle_type === 'truck' && !pre_inspection_check) {
                //     inspection_msg = '(Inspection Check Pending)'
                // }
                if(vehicle_type === 'truck' && is_available &&
                    !((selectedVehicleArr && selectedVehicleArr.find(itmzz => itmzz === id)) || (primeVehicle===id || (primeVehObj && primeVehObj.id===id))) && 
                    !((additionalVehArrObj && additionalVehArrObj.find(itmzz => itmzz.id === id)))) {
                    return (
                        <div style={{ 
                                height: 65, 
                                backgroundColor: ((selectedVehicleArr && selectedVehicleArr.find(itmzz => itmzz === id)) || (primeVehicle===id)) ? '#fff' : '#cccccc', 
                                borderRadius: 20, 
                                marginBottom: 10, 
                                padding: 10,
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer' 
                            }}
                            key={key}
                            onClick={(e)=>{ 
                                setSelectedAddVeh(null)
                                if(selectedVehicleArr && selectedVehicleArr.length===0 && primeVehicle===null){
                                    setPrimeVehicle(id)
                                }
                                else if(selectedVehicleArr && !selectedVehicleArr.some(itmzz => itmzz === id)) 
                                    setSelectedVehicleArr(itm => [...itm, id]); 
                                
                                else if(primeVehicle===id){
                                    if(selectedVehicleArr){
                                        setPrimeVehicle(selectedVehicleArr[0])
                                        let removed = selectedVehicleArr.filter((_,k) => k!==0)
                                        setSelectedVehicleArr(removed)
                                    }
                                    else{
                                        setPrimeVehicle(null)
                                        setPrimeVehicleDriver(null)
                                    }
                                }
                                else if( selectedVehicleArr){
                                    let removed = selectedVehicleArr.filter(itemzx => itemzx !== id)
                                    setSelectedVehicleArr(removed)
                                } 
                                else{
                                    setSelectedVehicleArr(itm => [...itm, id])
                                }
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >{vehicle_type}</p>
                                <p style={{ fontSize: 16 }}>{registration}</p>
                                {/* <p style={{ color: 'red', fontWeight: 300 }}>{inspection_msg}</p> */}
                            </div>
                            {   ((selectedVehicleArr && selectedVehicleArr.find(itmzz => itmzz === id)) ||
                                (primeVehicle && primeVehicle===id)) ?
                                    <SortDropdown 
                                    v={primeVehicle && primeVehicle===id ? primeVehDriver : additonalDriv && additonalDriv.find(item => item.vehicle_id===id) ? additonalDriv.find(item => item.vehicle_id===id).driver_id : selectedAddVeh}
                                     setVal={primeVehicle && primeVehicle===id ? setPrimeVehicleDriver : (val)=>{
                                        if(additonalDriv && additonalDriv.find(el => el.vehicle_id===item)){
                                            let oldObj = additonalDriv
                                            let oldObjIdx = additonalDriv.findIndex(el => el.vehicle_id===item)
                                            oldObj.splice(oldObjIdx, 1, { vehicle_id : item, driver_id : val})
                                            setAdditionalVehTeamArr(Object.assign([], oldObj))
                                        }
                                        else{
                                            let obj={ vehicle_id : item, driver_id : val}; setAdditionalVehTeamArr(data => [...data, obj]);
                                        }
                                    } } />
                                : null
                            }
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            {
                                selectedVehicleArr && selectedVehicleArr.find(itmzz => itmzz === id) ? (<CheckCircleOutlineIcon />) : 
                                primeVehicle && primeVehicle===id ? (<p>Prime Vehicle</p>) : (null)
                            } 
                            </div>
                            
                            
                        </div>
                        
                    )
                } else return null
                
            })
        )
    }


    

    return (
        <div className="shdl-modal-center-lay" 
        //style={!chng ? { marginLeft:'10px', } : null}
        >

            <div style={{ height: 
                //!chng ? '58vh' : 
                'auto' }} className="shdl-modal-center-x-layout">
                <div style={{ marginBottom: 20 }}>
                    <p>Add Vehicles</p>
                </div>

                {/* {
                    !chng ? (
                        <Scrollbars autoHide style={{ height: '54vh' }}>
                            {
                                vehicle_listDateTime.map((item, key) => {
                                    const { id, is_available, pre_inspection_check, registration, vehicle_type  } = item
                                    let inspection_msg = '';
                                    if(vehicle_type === 'truck' && !pre_inspection_check) {
                                        inspection_msg = '(Inspection Check Pending)'
                                    }
                                    if(is_available) {
                                        return (
                                            <div style={{ 
                                                    height: 65, 
                                                    backgroundColor: selectedVehicleArr && selectedVehicleArr.find(itmzz => itmzz === id) ? '#fff' : '#cccccc', 
                                                    borderRadius: 20, 
                                                    marginBottom: 10, 
                                                    padding: 10,
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    cursor: 'pointer' 
                                                }}
                                                onClick={(e)=>{ e.stopPropagation() 
                                                    if(selectedVehicleArr && !selectedVehicleArr.some(itmzz => itmzz === id)) 
                                                        setSelectedVehicleArr(itm => [...itm, id]); 
                                                    else if( selectedVehicleArr){
                                                        let removed = selectedVehicleArr.filter(itemzx => itemzx !== id)
                                                        setSelectedVehicleArr(removed)
                                                    } 
                                                    else{
                                                        setSelectedVehicleArr(itm => [...itm, id])
                                                    }
                                                }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <p style={{ textTransform: 'capitalize', fontWeight: 300 }} >{vehicle_type}</p>
                                                    <p style={{ fontSize: 16 }}>ENV{registration}</p>
                                                    <p style={{ color: 'red', fontWeight: 300 }}>{inspection_msg}</p>
                                                </div>

                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {
                                                    selectedVehicleArr && selectedVehicleArr.find(itmzz => itmzz === id) ? (<CheckCircleOutlineIcon />) : (null)
                                                } 
                                                </div>
                                                
                                            </div>
                                            
                                        )
                                    } else return null
                                    
                                })
                            }
                        </Scrollbars>
                    ) : ( */}
                        <Fragment>
                            {   
                                vehicleAvailLoader  ?
                                <CircularProgress/>
                                :
                                vehicle_listDateTime.filter(item => item.is_available===true).length === 0 ?
                            <div style={{ fontSize:'13px' }}> No Vehicles Available for this date and time</div>
                            
                            :
                            <>
                                {console.log(vehicle_listDateTime, primeVehicle, selectedVehicleArr)}
                                {   ((primeVehicle!==null && vehicle_listDateTime) || (primeVehObj && primeVehObj.id===primeVehicle)) ?
                                        <PrimeVehicleSelect />
                                      :   null
                                }
                                {   ((selectedVehicleArr && selectedVehicleArr.length!==0 && vehicle_listDateTime) || selectedVehicleArr!==null) ?
                                    <SelectedListArr />  :   null
                                }

                                {
                                    <VehicleListAll />
                                }
                            </>
                            }
                        </Fragment>
                    {/* )
                }
                
            {   !chng ? 
                <Scrollbars autoHide style={{ height: '53vh' }}>
                    <VehicleContentList />
                </Scrollbars>
                :
                    <VehicleContentList />
            } */}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {  vehicle_listDateTime } = state.schedule
    const { selected_team, employee_listDateTime, vehicleAvailLoader, empAvailLoader } = state.schedule
    return { selected_team, employee_listDateTime, vehicle_listDateTime, vehicleAvailLoader, empAvailLoader }
}

export default connect(mapStateToProps) (VehicleLayout)
