let initialState = {
    selected_job: null,
    loadPage: 0,
    listLoader: true,
    jobCard_list: [],
    jobCardListPage: 1,
    stopPagingJobCard: false
}

const jobView = (state = initialState, actions) =>{

    switch (actions.type) {
        case "REFRESH_JOB_VIEW":
            return{
                ...state,
                loadPage: 0,
                selected_job: null,
                listLoader: true,
                jobCard_list: [],
                jobCardListPage: 1,
                stopPagingJobCard: false
            }
            
        case "SALES_JOB_VIEW":
            return{
                ...state,
                selected_job: actions.selectedJob,
                loadPage: 1
            }

        case "GET_JOBCARDS" :
            return {
                ...state,
                jobCard_list: actions.jobs.sort(function(x, y) {
                    // true values first
                    return (x.connected === y.connected)? 0 : x.connected ? 1 : -1;
                }),
                listLoader : false
            }

        case "GET_JOBCARDS_LISTPAGING" :
            let oldCardsList = actions.page === 1 ? actions.jobs : state.jobCard_list.concat(actions.jobs)
            let newCardsList = oldCardsList.sort(function(x, y) {
                // true values first
                return (x.connected === y.connected)? 0 : x.connected ? 1 : -1;
            })
            return {
                ...state,
                jobCard_list : newCardsList,
                jobCardListPage: actions.page,
                stopPagingJobCard: actions.jobs && actions.jobs.length === 20 ? false : true,
                listLoader : false
            }
    
        default:
            return state;
    }
}

export default jobView