import React, {useEffect, useState} from 'react';
import Scrollbars from '../../../common/ImprovedScrollBar';
import './style.css'
import { 
    AddCircleOutlineRounded,
    //AddCircleOutline, RemoveCircleOutline,
     CheckCircleOutlineRounded, DeleteForever, DescriptionRounded, EditOutlined } from '@material-ui/icons';

import DateTimeInput from '../../../common/DateTimeInput'
import {connect} from 'react-redux'
import { addNewJobCard, editNewJobCard, editNewJobCardName, listJobCards } from '../action';
import Swal from 'sweetalert2'
import { CLIENT_SINGLE_FETCH, CONVERTJOB, HOST_WITHOUT_SLASH, NEWJOBCARD_ADD_FOLDER, NEWJOBCARD_DELETE, OHS_AND_S_FILE_ADD, OHS_AND_S_FILE_DELETE, SALES_ALL_CLIENTS_LIST, SALES_JOBCARD_DELETE_MULTIFILE, SITE_INDUSTRY_LIST, SITE_TYPE_OF_WASTE_LIST, TEAM_SALESPERSON_GET } from '../../../store/endpoint';
import Api from '../../../store/api'
import { HOME_LATITUDE, HOME_LONGITUDE, LETTERS_VALIDATION, NUMBER_VALIDATION_REGEX } from '../../../store/config';
import { cl_blue, cl_green, cl_red } from '../../../common/Colors';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { SearchGoogleAddress, GoogleMapInit } from '../../client/component/editStack/GoogleMap/GoogleMap';
import { TextAreaLimiter } from '../../../common/TextAreaLimiter';
import moment from 'moment';
import FilesDragAndDrop from '../../../common/DragNDropFiles';

let tempImgObj = {    tcreq       : [], dataform    : [], weighBreq   : [], safety      : [], chemappr    : [], manifest    : [] }

const Wastejobcard = ({  setJobCardModal, jobObj, modalType, tabType=null, clientList, addJob, editJob, verifyJobCard=null, user, preSelect=null, setJobCardLoader=null, editJobCardName,
    fetch_jobCards, typeOfWaste=null, typeOfWaste_Id=null, fallBackFn=null, setupSalesPerson=null, addNVerify=false, setAddNVerify=null, prevJobFill=false }) => {
        const isUndefinedOrNull = (val) => {
            if(val==='null' || val==='undefined' || !val)
                return ''
            else 
                return val
        }
        const isUndefinedOrNullBool = (val) => {
            if(val==='null' || val==='undefined' || !val)
                return false
            else 
                return val
        }
            
        useEffect(()=>{
            if(jobObj){
                updatingClientData()
            }
            if((modalType!=='add' || prevJobFill)&&jobObj){
                updatingAddInfoData()
                if(jobObj || modalType!=='add'){
                    setUpdatingFormerData(true)
                    setTimeout(() => {
                        setUpdatingFormerData(false)        
                    }, 2000);}
            }else{
                setUpdatingFormerData(false)
            }
            setTypeofWaste( modalType==='add' ? typeOfWaste===null ? '' : typeOfWaste : jobObj.type_of_waste_str)
            setTypeOfWasteId( jobObj ? jobObj.type_of_waste_str_id : typeOfWaste_Id===null ? '' : typeOfWaste_Id)
            
        },[modalType, jobObj, prevJobFill, typeOfWaste, typeOfWaste_Id])

        
        useEffect(()=>{
            Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                res =>{
                    console.log(res)
                    setAllClientsLoader(false)
                    if(res.length>0)
                        setAllClientsList(res)
                    else 
                        setAllClientsList([])
                }        
            )
            Api('get',`${TEAM_SALESPERSON_GET}`).then(
                res =>{
                    console.log(res)
                    setTeamSalespersonLoader(false)
                    if(res.length>0)
                        setTeamSalespersonList(res)
                    else 
                        setTeamSalespersonList([])
                }
            )
            Api('get',SITE_INDUSTRY_LIST,'').then(response => {
                console.log('INDUSTRY',response)
                setIndustryTypeLoader(false)
                setindustryTypeList(response)
            })
            
        },[tabType])

        
    let mapInit = GoogleMapInit()
        console.log(jobObj,'MODALTYPE=>',modalType)

    const [clientID, setClientID]               = useState(jobObj ? jobObj.client : null)
    const [editOpt, setEditOpt]                 = useState(false)
    const [textAreaValid, setTextAreaValid]     = useState(null)
    const [allClientsList, setAllClientsList]   = useState([])
    const [allTOWList, setAllTOWList]           = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)
    const [TeamSalespersonLoader, setTeamSalespersonLoader] = useState(true)
    const [TeamSalespersonList, setTeamSalespersonList] = useState([])
    const [allTOWListLoader, setAllTOWListLoader] = useState(false)
    const [TypeofWaste, setTypeofWaste]         = useState('')
    const [typeOfWasteId, setTypeOfWasteId]     = useState('')
    const [jobCardName, setJobCardName]         = useState('')
    const [siteName, setSiteName]               = useState('')
    const [siteAddr, setSiteAddr]               = useState('')
    const [sitePost, setSitePost]               = useState('')
    const [siteContactName, setSiteContactName]     = useState('')
    const [siteContactEmail, setSiteContactEmail]   = useState('')
    const [siteContactPhone, setSiteContactPhone]   = useState('')
    const [siteContactMob, setSiteContactMob]       = useState('')

    const [custName, setCustName]               = useState('')
    const [custAddr, setCustAddr]               = useState('')
    const [custContactPhone, setCustContactPhone] = useState('')
    const [custContactMob, setCustContactMob]   = useState('')
    const [custContactEmail, setCustContactEmail] = useState('')
   
    const [inductionType, setInductionType]         = useState('')
    const [salesPerson, setSalesPerson]             = useState('')
    const [jobDate, setJobDate]                     = useState(new Date())
    const [tcReq, setTcreq]                 = useState('')
    const [dataForm, setDataForm]           = useState( false)
    const [accessHeight, setAccessHeight]   = useState('')    
    const [pitDist, setPitDist]             = useState('')
    const [waterTap, setWaterTap]           = useState('')
    const [gumReq, setGumReq]               = useState(false)
    const [confSpace, setConfSpace]         = useState(false)
    const [noTruck, setNoTruck]             = useState('')
    const [servTime, setServTime]           = useState('')
    const [spcPPE, setSpcPPE]               = useState(false)
    const [spcPPEComment, setSpcPPEComment] = useState('')
    const [noPallets, setNoPallets]             = useState('')
    const [safetyDataReq, setSafetyDataReq]     = useState(false)
    const [ChemAppr, setChemAppr]               = useState(false)
    const [smokeAlarm, setSmokeAlarm]           = useState('')
    const [imgReq, setImgReq]               = useState('')
    const [manifest, setManifest]           = useState('')
    const [buttonLoader, setButtonLoader]   = useState(false)
    const [typeOfReoccur, setTypeOfReoccur] = useState('weekly')
    const [reoccurWeek, setreoccurWeek] = useState([])
    const [access, setAccess]               = useState('')
    // const [keyReq, setKeyReq]               = useState(false)
    const [keyReqNew, setKeyReqNew]               = useState('')
    const [weighBridgeReq, setWeighBridgeReq]   = useState("false")
    const [addInfo, setAddInfo]                 = useState('')
    const [capacity, setCapacity]               = useState('')
    const [barcode, setBarcode]                 = useState('')
    // const [jobStatus, setJobStatus]             = useState('')
    const [pitLoc, setPitLoc]                   = useState('')
    const [purchaseComment, setPurchaseComment] = useState('')
    const [estJobDuration, setEstJobDuration] = useState('')
    const [frq, setFrq]                     = useState(0)
    const [reoccur, setReoccur]             = useState(false )
    const [outsourced, setOutsourced] = useState(false)
    
    const [initLoad, setInitLoad] = useState(false)
    const [tempImgFileList, setTempImgFileList] = useState(tempImgObj)
    const [fileAtt, setFileAtt]             = useState(
    {
        tcreq : null, dataform : null, weighBreq : null, safety : null, chemappr : null, imgFile : null, manifest : null, purchaseOrderFile : null
    })
    const [photoRequired, setPhotoRequired] = useState(false)
    const [certOfDistReq, setCertOfDistReq] = useState(false)

    const [photoRequiredComment, setPhotoRequiredComment] = useState('')
    const [certOfDistReqComment, setCertOfDistReqComment] = useState('')

    const [wastePackage, setWastePackage] = useState('')
    const [volumeExpected, setVolumeExpected] = useState('')
    const [noofLoads, setNoOfLoads] = useState('')
    const [sampleOfWaste, setSampleOfWaste] = useState(false)
    const [sampleOfWasteComment, setSampleOfWasteComment] = useState('')
    const [estDateAndTime, setEstDateAndTime] = useState('')
    const [flowMeterReq, setFlowMeterReq] = useState(false)

    const [convJBType, setConvJBType] = useState(false)
    const [convreoccurWeek, setconvreoccurWeek] = useState([])
    const [convFreq, setConvFreq] = useState('')
    const [convDates, setConvDates] = useState(new Date())
    const [contypeOfReoccur, setConvTypeOfReoccur] = useState('weekly')
    
    const [IndustryTypeList, setindustryTypeList] = useState([])
    const [industryTypeID, setindustryTypeID] = useState(null)
    const [IndustryTypeLoader, setIndustryTypeLoader] = useState(true)
    const [UpdatingFormerData, setUpdatingFormerData] = useState(true)
    const [markers, setMarkers]             = useState(jobObj ? 
        [{ lat: jobObj.latitude ? parseFloat(jobObj.latitude) : null,    
            lng: jobObj.longitude ? parseFloat(jobObj.longitude) : null }] : 
        [{  lat: HOME_LATITUDE, lng: HOME_LONGITUDE }])
        // const [AddInfoButton, setAddInfoButton] = useState(0)
        const [additionalEmail, setAdditionalEmail] = useState('')
        const [additionalCont, setAdditionalCont] = useState('')
        const [additionalEmailArr, setAdditionalEmailArr] = useState([])
        const [additionalContArr, setAdditionalContArr] = useState([])
        const [AddInfoButton, setAddInfoButton] = useState(jobObj && jobObj?.add_info_button)

        

    
    const updatingClientData = () =>{
        setClientID(jobObj ? jobObj.client : null)
            setJobCardName(jobObj && jobObj.job_card_name ? jobObj.job_card_name : '')
                    setUpdatingFormerData(true)
                    setTimeout(() => {
                        setUpdatingFormerData(false)        
                    }, 2000);
            
                    setSiteName(isUndefinedOrNull(jobObj?.site_name))
                    setSiteAddr(isUndefinedOrNull(jobObj?.site_address))
                    setSitePost(isUndefinedOrNull(jobObj?.site_post_code))
                    setSiteContactName(isUndefinedOrNull(jobObj?.site_contact_person))
                    setSiteContactEmail(isUndefinedOrNull(jobObj?.site_contact_email))
                    setSiteContactPhone(jobObj?.site_contact_phone ? isUndefinedOrNull(jobObj?.site_contact_phone) : isUndefinedOrNull(jobObj?.site_phone_no))
                    setSiteContactMob(isUndefinedOrNull(jobObj?.site_contact_mob))
                
                    setCustName(isUndefinedOrNull(jobObj?.customer_name??jobObj?.company_name))
                    setCustAddr(isUndefinedOrNull(jobObj?.customer_address??jobObj?.company_address))
                    setCustContactPhone(isUndefinedOrNull(jobObj?.customer_contact_phone??jobObj?.company_contact_phone))
                    setCustContactMob(isUndefinedOrNull(jobObj?.customer_contact_mob??jobObj?.company_mobile_number))
                    setCustContactEmail(isUndefinedOrNull(jobObj?.customer_contact_email??jobObj?.company_email))
                   
                    setInductionType(isUndefinedOrNull(jobObj?.type_of_induction))
                    setSalesPerson(isUndefinedOrNull(jobObj?.sales_person))
                    setJobDate(jobObj?.date)
    }

    const updatingAddInfoData = () =>{
                setTcreq( isUndefinedOrNull(jobObj.tc_required_comment))
                setDataForm( isUndefinedOrNullBool(jobObj.waste_data_form) )
                setAccessHeight( isUndefinedOrNull(jobObj.access_height))    
                setPitDist( isUndefinedOrNull(jobObj.pit_distance_from_truck_location))
                setWaterTap( isUndefinedOrNull(jobObj.water_tap_location))
                setGumReq( isUndefinedOrNullBool(jobObj.gurney_required) )
                setConfSpace( isUndefinedOrNullBool(jobObj.confined_space) )
                setNoTruck( isUndefinedOrNull(jobObj.number_of_trucks_required))
                setServTime( isUndefinedOrNull(jobObj.time_for_service))
                setSpcPPE( isUndefinedOrNullBool(jobObj.specific_ppe_reqired) )
                setSpcPPEComment( isUndefinedOrNull(jobObj.specific_ppe_required_comment))
                setNoPallets( isUndefinedOrNull(jobObj.no_and_type_pallet_to_be_exchanged))
                setSafetyDataReq( isUndefinedOrNullBool(jobObj.safety_data_sheet_required) )
                setChemAppr( isUndefinedOrNullBool(jobObj.chemist_approval) )
                setSmokeAlarm( isUndefinedOrNull(jobObj.smoke_alarms))
                setImgReq( isUndefinedOrNull(jobObj.additional_images))
                setManifest( isUndefinedOrNull(jobObj.manifest))
                setTypeOfReoccur( jobObj.daily_schedule ? 'daily' : 'weekly')
                setConvTypeOfReoccur(jobObj.daily_schedule ? 'daily' : 'weekly')
                setreoccurWeek(jobObj.schedule_days && jobObj.schedule_days.length!==0 ? jobObj.schedule_days.split(',').map(el => parseInt(el)) :  [])
                setconvreoccurWeek(jobObj.schedule_days && jobObj.schedule_days.length!==0 ? jobObj.schedule_days.split(',').map(el => parseInt(el)) :  [])
                setAccess( isUndefinedOrNull(jobObj.access_restriction))
                // setKeyReq( jobObj.key_required )
                setKeyReqNew( isUndefinedOrNullBool(jobObj.key_required_new))

                setWeighBridgeReq( isUndefinedOrNullBool(jobObj.weigh_bridge_required))
                setAddInfo( isUndefinedOrNull(jobObj.additional_information))
                setCapacity( isUndefinedOrNull(jobObj.capacity))
                setBarcode( isUndefinedOrNull(jobObj.barcode))
                // setJobStatus( isUndefinedOrNull(jobObj.job_status))
                setPitLoc( isUndefinedOrNull(jobObj.pit_location))
                setPurchaseComment( isUndefinedOrNull(jobObj.purchase_comment))
                setEstJobDuration( isUndefinedOrNull(jobObj.estimated_job_duration))
                setFrq( jobObj.frequency ? jobObj.frequency : 0 )
                setConvFreq(jobObj.frequency ? `${jobObj.frequency}` : '0')
                setReoccur(jobObj.reoccurring ? jobObj.reoccurring : false )
                setOutsourced( isUndefinedOrNullBool(jobObj.outsourced_job) )
                setindustryTypeID(jobObj && jobObj.industry_type_info ? jobObj.industry_type_info?.id??jobObj.industry_type_info : null)
                setInductionType(isUndefinedOrNull(jobObj?.type_of_induction))
                setFileAtt(
                    jobObj.jobcardinfo_files ?
                {
                    tcreq       : jobObj.jobcardinfo_files.tc_required_multiple_file, 
                    dataform    : jobObj.jobcardinfo_files.data_form_required_multiple_file, 
                    weighBreq   : jobObj.jobcardinfo_files.weigh_bridge_required_multiple_file, 
                    safety      : jobObj.jobcardinfo_files.safety_data_sheet_files, 
                    chemappr    : jobObj.jobcardinfo_files.chemist_approval_multiple_file,
                    manifest    : jobObj.jobcardinfo_files.manifest_multiple_file,
                    imgFile     : jobObj.files,
                    purchaseOrderFile : jobObj.jobcardinfo_files.purchase_order,
                    
                }
                :
                {
                    tcreq : null, dataform : null, weighBreq : null, safety : null, chemappr : null, imgFile : null, manifest : null, purchaseOrderFile : null
                })
                setAdditionalContArr(jobObj?.additional_phone_number ? 
                    Object.values(jobObj?.additional_phone_number) 
                    : [])
                    setAdditionalEmailArr(jobObj?.additional_email ? 
                    Object.values(jobObj?.additional_email) 
                    : [])
                
                setPhotoRequired(jobObj && jobObj?.photo_required ? jobObj?.photo_required : false)
                setPhotoRequiredComment(jobObj && jobObj?.photo_required_text ? jobObj?.photo_required_text : '')
                setCertOfDistReq(jobObj && jobObj?.certificate_of_destruction_required ? jobObj?.certificate_of_destruction_required : false)
                setCertOfDistReqComment(jobObj && jobObj?.certificate_of_destruction_required_text ? jobObj?.certificate_of_destruction_required_text : '')

                setWastePackage(jobObj && jobObj?.waste_packaged_method ? jobObj?.waste_packaged_method : '')
                setVolumeExpected(jobObj && jobObj?.volume_expected ? jobObj?.volume_expected : '')
                setNoOfLoads(jobObj && jobObj?.number_of_loads_expected ? jobObj?.number_of_loads_expected : '')
                setSampleOfWaste(jobObj && jobObj?.sample_of_waste_to_be_collected ? jobObj?.sample_of_waste_to_be_collected : false)

                setSampleOfWasteComment(jobObj && jobObj?.sample_of_waste_to_be_collected_text ? jobObj?.sample_of_waste_to_be_collected_text : '')
                setEstDateAndTime(jobObj && jobObj?.estimated_datetime_of_disposal ? jobObj?.estimated_datetime_of_disposal : '')
                setFlowMeterReq(jobObj && jobObj?.flow_meter_required ? jobObj?.flow_meter_required : false)
                setAddInfoButton(isNaN(parseInt(jobObj && jobObj?.add_info_button)) ? 0 : parseInt(jobObj && jobObj?.add_info_button))

    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    
    const perm =    [                               
        { name : 'DILUTION PIT',        perms : [1,0,1,1,1,1,0,0,0]},
        { name : 'DRUMS',               perms : [1,0,1,1,1,1,1,1,1]},
        { name :  'FOOD WASTE',         perms : [1,0,1,1,1,1,1,1,1]},
        { name :  'GREASE',             perms : [1,1,1,1,1,1,0,0,0]},
        { name :  'GREASE ROOM CLEAN',  perms : [1,1,1,1,1,1,0,0,0]},
        { name :  'IBC',                perms : [1,0,1,1,1,1,1,1,1]},
        { name :  'J120',               perms : [1,0,1,1,1,1,1,0,0]}, 
        { name :  'M250',               perms : [1,0,1,1,1,1,1,1,1]},
        { name :  'N205',               perms : [1,0,1,1,1,1,1,1,1]}, 
        { name :  'OIL SEPERATOR',      perms : [1,0,1,1,1,1,0,0,0]},
        { name :  'PIT WASH AND CLEAN', perms : [1,0,1,1,1,1,0,0,0]},
        { name :  'PRODUCT DESTRUCTION', perms : [1,0,1,1,1,1,1,1,1]},
        { name :  'RETENTION PITS',     perms : [1,0,1,1,1,1,0,0,0]},
        { name :  'SEWER',              perms : [1,0,1,1,1,1,0,0,0]},
        { name :  'SLUDGE',             perms : [1,0,1,1,1,1,1,0,0]},
        { name :  'STORMWATER',         perms : [1,0,1,1,1,1,0,0,0]},
        { name :  'TRANSPORT',          perms : [0,0,0,0,0,0,0,0,0]},   
        { name :  'Z140',               perms : [1,0,1,1,1,1,0,0,0]},
        { name :    '',                 perms : [1,1,1,1,1,1,1,1,1]}
    ]
    console.log('JOBDATE=>',jobDate,new Date(jobDate))


    const filterAndMapIds = (fileList) => {
        return Object.keys(fileList).reduce((acc, key) => {
            acc[key] = fileList[key]?.filter(el => typeof el.id !== 'undefined').map(el => el.id);
            return acc;
        }, {});
    };
    
    const filterWithoutIds = (fileList) => {
        return Object.keys(fileList).reduce((acc, key) => {
            acc[key] = fileList[key]?.filter(el => typeof el.id === 'undefined');
            return acc;
        }, {});
    };


    const addJobCard = () =>{
        console.log('FILEATT=>',fileAtt)
        console.log('tempImgFile',tempImgFileList)
        let dateStr = jobDate!=='' ? new Date(jobDate) : new Date()
        
        let postData = new FormData()
        let additionalContArrObj = {}
        let newFileAtt = null
        let newtempImgFileList = null
        let updatedFileAtt = null 
        let updatedTempImg = null

        let correctedKeysObj = {
            tc_required_multiple_file : fileAtt?.tcreq,
            chemist_approval_multiple_file : fileAtt?.chemappr,
            weigh_bridge_required_multiple_file : fileAtt?.weighBreq,
            safety_data_sheet_files : fileAtt?.safety,
            data_form_required_multiple_file : fileAtt?.dataform,
            manifest_multiple_file : fileAtt?.manifest,
            images : fileAtt?.imgFile,
            purchase_order : fileAtt?.purchaseOrderFile
        }
        let correctedKeysTempObj = {
            tc_required_multiple_file : tempImgFileList?.tcreq,
            chemist_approval_multiple_file : tempImgFileList?.chemappr,
            weigh_bridge_required_multiple_file : tempImgFileList?.weighBreq,
            safety_data_sheet_files : tempImgFileList?.safety,
            data_form_required_multiple_file : tempImgFileList?.dataform,
            manifest_multiple_file : tempImgFileList?.manifest,
            purchase_order : tempImgFileList?.purchaseOrderFile
        }

        if (modalType === 'add' && jobObj?.pre_autofill) {
            newFileAtt = filterAndMapIds(correctedKeysObj);
            newtempImgFileList = filterAndMapIds(correctedKeysTempObj);
            updatedFileAtt = filterWithoutIds(correctedKeysObj);
            updatedTempImg = filterWithoutIds(correctedKeysTempObj);
        }

        const shouldAppendNewFiles = newFileAtt && modalType === 'add';
        const shouldAppendNewTempFiles = newtempImgFileList && modalType !== 'add';
        const shouldAppendUpdatedFiles = updatedFileAtt && modalType === 'add';
        const shouldAppendUpdatedTempFiles = updatedTempImg && modalType !== 'add';

        additionalContArr?.forEach((v,i)=>{
            additionalContArrObj[i] = v
        })
        let additionalEmailArrObj = {}
        additionalEmailArr?.forEach((v,i)=>{
            additionalEmailArrObj[i] = v
        })
        
        postData.append('tab_type'              ,   tabType)
        postData.append('client'                ,   clientID)
        postData.append('site_name'             ,   siteName)
        postData.append('site_address'          ,   siteAddr)
        postData.append('site_post_code'        ,   sitePost)
        postData.append('site_contact_person'   ,   siteContactName)
        postData.append('site_contact_email'    ,   siteContactEmail)
        postData.append('site_contact_phone'    ,   siteContactPhone)
        postData.append('site_phone_no'    ,   siteContactPhone)
        postData.append('site_contact_mob'      ,   siteContactMob)

        postData.append('customer_name'                 ,   custName)
        postData.append('customer_address'              ,   custAddr)
        postData.append('customer_contact_phone'        ,   custContactPhone)
        postData.append('customer_contact_mob'          ,   custContactMob)
        postData.append('customer_contact_email'        ,   custContactEmail)

        // postData.append('price'                 ,   price)
        // postData.append('account_status'        ,   accountStatus)
        postData.append('type_of_induction'     ,   inductionType)
        postData.append('sales_person'          ,   salesPerson)
        postData.append('date'                  ,    dateStr.toISOString())
        // postData.append('service_list'          ,   JSON.stringify( serviceDet))
        postData.append('type_of_waste_str'     ,   TypeofWaste)
        postData.append('type_of_waste_str_id'  ,   typeOfWasteId)
        postData.append('access_restriction'    ,   access)
        postData.append('tc_required_comment'   ,   tcReq)
        postData.append('waste_data_form'       ,   dataForm)
        postData.append('access_height'         ,   accessHeight)
        // postData.append('key_required'          ,   keyReq)
        postData.append('key_required_new'          ,   keyReqNew)

        postData.append('pit_distance_from_truck_location' , pitDist)
        postData.append('water_tap_location'    ,   waterTap)
        postData.append('gurney_required'       ,   gumReq)
        postData.append('confined_space'        ,   confSpace)
        postData.append('number_of_trucks_required' , noTruck)
        postData.append('estimated_job_duration' , estJobDuration)
        postData.append('time_for_service'      ,   servTime)
        postData.append('specific_ppe_reqired' ,   spcPPE)
        postData.append('weigh_bridge_required' ,   weighBridgeReq)
        postData.append('no_and_type_pallet_to_be_exchanged' , noPallets)
        postData.append('safety_data_sheet_required'    ,   safetyDataReq)
        postData.append('chemist_approval'      ,   ChemAppr)
        postData.append('additional_information',   addInfo)
        postData.append('capacity'              ,   capacity)
        postData.append('barcode'               ,   barcode)
        // postData.append('job_status'            ,   jobStatus)
        postData.append('pit_location'          ,   pitLoc)
        postData.append('smoke_alarms'          ,   smokeAlarm)
        postData.append('frequency'             ,   frq)
        postData.append('reoccurring'           ,   reoccur)  
        postData.append('latitude'              ,   markers[0].lat)
        postData.append('longitude'             ,   markers[0].lng)
        postData.append('additional_images'     ,   imgReq)
        postData.append('manifest'      ,   manifest)
        postData.append('specific_ppe_required_comment', spcPPEComment)
        postData.append('purchase_comment', purchaseComment)
        postData.append('outsourced_job', outsourced)
        postData.append('daily_schedule'        ,   typeOfReoccur==='daily' ? true : false)
        postData.append('schedule_days'         , reoccurWeek)
        postData.append('industry_type'         ,   industryTypeID)

        postData.append('additional_phone_number', JSON.stringify(additionalContArrObj))

        postData.append('additional_email', JSON.stringify(additionalEmailArrObj))
        
        postData.append('photo_required',photoRequired)
        postData.append('photo_required_text',photoRequiredComment)
        postData.append('certificate_of_destruction_required',certOfDistReq)
        postData.append('certificate_of_destruction_required_text',certOfDistReqComment)

        postData.append('waste_packaged_method', wastePackage)
        postData.append('volume_expected', volumeExpected)
        postData.append('number_of_loads_expected', noofLoads)
        postData.append('sample_of_waste_to_be_collected', sampleOfWaste)
        postData.append('sample_of_waste_to_be_collected_text', sampleOfWasteComment)
        postData.append('estimated_datetime_of_disposal', estDateAndTime)
        postData.append('flow_meter_required', flowMeterReq)
        postData.append('add_info_button', AddInfoButton)

        const appendFilesToPostData = (fileList, suffix = '') => {
            for (const [key, files] of Object.entries(fileList)) {
                if (files) {
                    for (let i = 0; i < files.length; ++i) {
                        postData.append(`${key}${suffix!=='' ? `${suffix}` : ''}`, files[i]);
                    }
                }
            }
        };

        if (shouldAppendNewFiles || shouldAppendNewTempFiles || shouldAppendUpdatedFiles || shouldAppendUpdatedTempFiles) {
            if (shouldAppendNewFiles) {
                appendFilesToPostData(newFileAtt, '_copy');
            } else if (shouldAppendNewTempFiles) {
                appendFilesToPostData(newtempImgFileList, '_copy');
            } 
            
            if (shouldAppendUpdatedFiles) {
                appendFilesToPostData(updatedFileAtt);
            } else if (shouldAppendUpdatedTempFiles) {
                appendFilesToPostData(updatedTempImg);
            }
        }
        else if((fileAtt.chemappr || fileAtt.weighBreq || fileAtt.dataform || fileAtt.imgFile ||
            fileAtt.manifest || fileAtt.purchaseOrderFile || fileAtt.safety || fileAtt.tcreq) && modalType==='add'){
            
            if(fileAtt.chemappr){
                for(let i=0; i<fileAtt.chemappr.length; ++i)
                    postData.append('chemist_approval_multiple_file' ,   fileAtt.chemappr[i])
            }if(fileAtt.weighBreq){
                for(let i=0; i<fileAtt.weighBreq.length; ++i)
                    postData.append('weigh_bridge_required_multiple_file'    ,   fileAtt.weighBreq[i])
            }if(fileAtt.safety){
                for(let i=0; i<fileAtt.safety.length; ++i)
                    postData.append('safety_data_sheet_files'    ,   fileAtt.safety[i])
            }if(fileAtt.dataform){
                for(let i=0; i<fileAtt.dataform.length; ++i)
                    postData.append('data_form_required_multiple_file'   , fileAtt.dataform[i])
            }if(fileAtt.tcreq){
                for(let i=0; i<fileAtt.tcreq.length; ++i)
                    postData.append('tc_required_multiple_file'      ,   fileAtt.tcreq[i])
            }if(fileAtt.manifest){
                for(let i=0; i<fileAtt.manifest.length; ++i)
                    postData.append('manifest_multiple_file'         ,   fileAtt.manifest[i])
            }
            if(fileAtt.imgFile){
                for(let i=0; i<fileAtt.imgFile.length; ++i)
                    postData.append('images',   fileAtt.imgFile[i])
            }
            if(fileAtt.purchaseOrderFile){
                for(let i=0; i<fileAtt.purchaseOrderFile.length; ++i)
                    postData.append('purchase_order',   fileAtt.purchaseOrderFile[i])
            }
            }
        else if((tempImgFileList.chemappr || tempImgFileList.weighBreq || tempImgFileList.dataform || tempImgFileList.imgFile ||
            tempImgFileList.manifest || tempImgFileList.purchaseOrderFile || tempImgFileList.safety || tempImgFileList.tcreq) && modalType!=='add'){
            
                if(tempImgFileList.chemappr){
                    for(let i=0; i<tempImgFileList.chemappr.length; ++i)
                        postData.append('chemist_approval_multiple_file' ,   tempImgFileList.chemappr[i])
                }if(tempImgFileList.weighBreq){
                    for(let i=0; i<tempImgFileList.weighBreq.length; ++i)
                        postData.append('weigh_bridge_required_multiple_file'    ,   tempImgFileList.weighBreq[i])
                }if(tempImgFileList.safety){
                    for(let i=0; i<tempImgFileList.safety.length; ++i)
                        postData.append('safety_data_sheet_files'    ,   tempImgFileList.safety[i])
                }if(tempImgFileList.dataform){
                    for(let i=0; i<tempImgFileList.dataform.length; ++i)
                        postData.append('data_form_required_multiple_file'   , tempImgFileList.dataform[i])
                }if(tempImgFileList.tcreq){
                    for(let i=0; i<tempImgFileList.tcreq.length; ++i)
                        postData.append('tc_required_multiple_file'      ,   tempImgFileList.tcreq[i])
                }if(tempImgFileList.manifest){
                    for(let i=0; i<tempImgFileList.manifest.length; ++i)
                        postData.append('manifest_multiple_file'         ,   tempImgFileList.manifest[i])
                }if(tempImgFileList.purchaseOrderFile){
                    for(let i=0; i<tempImgFileList.purchaseOrderFile.length; ++i)
                        postData.append('purchase_order'         ,   tempImgFileList.purchaseOrderFile[i])
                }
    
            }

                
            if(tempImgFileList.imgFile && tempImgFileList.imgFile.length!==0 && modalType!=='add'){
                setInitLoad(true)
                console.log('ASDFASDF')
                file_upload(tempImgFileList.imgFile, postData)
            }else{
                sendJobBtnFn(postData)
            }    
                
    }

    const sendJobBtnFn = (postData) =>{
        if(modalType==='add'){
            const success = (res) =>{
                if(addNVerify){
                    let elmObjIdx = TeamSalespersonList ? TeamSalespersonList.findIndex(el => el.id===parseInt(salesPerson)) : null
                    let elm = TeamSalespersonList ? TeamSalespersonList[elmObjIdx] : null
                    if(setupSalesPerson!==null){
                        console.log(elm,setupSalesPerson)
                        setupSalesPerson(elm)
                    }
                    let respData = {
                        'tab_type'            : tabType,
                        'client'              : clientID,
                        'site_name'           : siteName,
                        'site_address'        : siteAddr,
                        'site_post_code'      : sitePost,
                        'site_contact_person' : siteContactName,
                        'site_contact_email'  : siteContactEmail,
                        'site_contact_phone'  : siteContactPhone,
                        'site_phone_no'  : siteContactPhone,
                        'site_contact_mob'    : siteContactMob,

                        'customer_name'       : custName,
                        'customer_address'    : custAddr,
                        'customer_contact_phone'      : custContactPhone,
                        'customer_contact_mob'        : custContactMob,
                        'customer_contact_email'      : custContactEmail,
                        'frequency'           : frq,
                        'sales_person'        :   salesPerson

                    }
                    verifyJobCard(res.id, {reoccurring : reoccur, frequency : frq},res.job_card_code, respData)
                }
                
                setClientID(null)  
                setJobDate('')    
                setTypeofWaste('') 
                setSmokeAlarm('')
                setTypeOfWasteId(null)
                if(setJobCardLoader!==null){
                    setJobCardLoader(false)
                }
                if(fallBackFn!==null){
                    fallBackFn()
                }
                setButtonLoader(false)
                if(!initLoad)
                    setJobCardModal(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title : `Job Card Created${addNVerify ? ' and Verified.' : '.'}`,
                    showConfirmButton: false,
                    timer: 1500
                })

            }
            const failed = () => {
                setButtonLoader(false)
                if(setJobCardLoader!==null){
                    setJobCardLoader(false)
                }
                if(!initLoad)
                    setJobCardModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            if(setJobCardLoader!==null){
                setJobCardLoader(true)
            }
            addJob(postData, success, failed)
        }
        else{
            console.log('EDITFUNC')
            const success = () =>{

                if(verifyJobCard!==null){
                    let elmObjIdx = TeamSalespersonList ? TeamSalespersonList.findIndex(el => el.id===parseInt(salesPerson)) : null
                    let elm = TeamSalespersonList ? TeamSalespersonList[elmObjIdx] : null
                    if(setupSalesPerson!==null){
                        console.log(elm,setupSalesPerson)
                        setupSalesPerson(elm)
                    }
                    let respData = {
                        'tab_type'            : tabType,
                        'client'              : clientID,
                        'site_name'           : siteName,
                        'site_address'        : siteAddr,
                        'site_post_code'      : sitePost,
                        'site_contact_person' : siteContactName,
                        'site_contact_email'  : siteContactEmail,
                        'site_contact_phone'  : siteContactPhone,
                        'site_phone_no'  : siteContactPhone,
                        'site_contact_mob'    : siteContactMob,

                        'customer_name'       : custName,
                        'customer_address'    : custAddr,
                        'customer_contact_phone'      : custContactPhone,
                        'customer_contact_mob'        : custContactMob,
                        'customer_contact_email'      : custContactEmail,
                        'frequency'           : frq,
                        'sales_person'        :   salesPerson

                    }         
                    verifyJobCard(jobObj.id, {reoccurring : reoccur, frequency : frq},jobObj.job_card_code,respData)
                }

                setClientID(null)
                if(!initLoad)
                    setJobCardModal(false)
                setTypeofWaste('')
                if(setJobCardLoader!==null){
                    setJobCardLoader(false)
                }
                if(fallBackFn!==null){
                    fallBackFn()
                }
                
                setTypeOfWasteId(null)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title : `Job Card Updated${verifyJobCard!==null ? ' and Verified' : ''}.`,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            const failed = () => {
                if(!initLoad)
                    setJobCardModal(false)
                    if(setJobCardLoader!==null){
                        setJobCardLoader(false)
                    }
                setButtonLoader(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            
            editJob(postData, jobObj.id, success, failed)
        }
    }

    const jobCardRename = () =>{
        let data = new FormData();
        data.append('job_card_name', jobCardName)
        const success = () =>{
            if(setJobCardLoader!==null){
                setJobCardLoader(false)
            }
            
            if(fallBackFn!==null){
                fallBackFn()
            }
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Job Card Name Updated.`,
                showConfirmButton: false,
                timer: 1500
            })
            setEditOpt(false)
        }
        const failed = () => {
            setButtonLoader(false)
            setJobCardModal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        
        

        editJobCardName(data, jobObj.id, success, failed)
    }


    const delJobCard = (jobId) =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Job Card deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            setJobCardModal(false)
            if(setJobCardLoader!==null){
                setJobCardLoader(false)
                // else{
                //     fetch_jobCards()
                // }
            }
            
            if(fallBackFn!==null){
                fallBackFn()
            }
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = `${NEWJOBCARD_DELETE}${jobId}/`
                Api('delete', api_url, '', success, failed)
            }
        })
    
    }

    const sameDetails = () =>{
        return (
            siteName===custName && siteAddr===custAddr && siteContactEmail===custContactEmail
            && siteContactMob===custContactMob && siteContactPhone===custContactPhone
        )
    }

    const typeOfWasteValid = (val, idx) => {
   
        let tempVal = '' 
        if(val && val.includes('(')){
            tempVal = val.substring(parseInt(val.lastIndexOf('('))+1,parseInt(val.lastIndexOf(')')))
        }
        else 
            tempVal = val 
        // console.log(val,idx,tempVal,perm && perm.find(el => el.name===tempVal))
        if(perm && perm.find(el => el.name===tempVal))
            return perm && perm.find(el => el.name===tempVal).perms[idx]
        else 
            return 1;
    }

    const updateFiles = (id, fnVal, fn, isMultiImage=true) =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'File deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            let newArr = fnVal.filter((el) => el.id!==id)
            fn(newArr)
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "File will be deleted from job card forever. You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = isMultiImage ? `${OHS_AND_S_FILE_DELETE}${id}/${jobObj.folder}/` : `${SALES_JOBCARD_DELETE_MULTIFILE}${id}/`
                Api('delete', api_url, '', success, failed)
            }
        })
    
    }

    const file_upload = (data, postData) => {
        
        Swal.fire({
            title : 'Files being uploaded, please wait.',
            icon : 'info',
            showConfirmButton: false,
        })
        Swal.showLoading()

        
        const success = (res) => {

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'File uploaded successful!',
                showConfirmButton: false,
                timer: 1500
            })
            setInitLoad(false)
            sendJobBtnFn(postData)
        }

        const failed = (res) => {
            console.log('upload failed',res)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!',
                confirmButtonColor: cl_blue,
                confirmButtonText: 'OK'
            })
            .then(() => {
                console.log('closeErr')
                setInitLoad(false)
            })
            
        }
        console.log('uploading blah')
        let data_val = new FormData()
        let i;
        
        for(i=0; i<data.length; i++) {
            let file = data[i];
            let file_name = data[i].name
            data_val.append(jobObj && jobObj.folder===null ? 'images' : 'file', file, file_name)
            console.log(file,file_name)
        }
        data_val.append('folder', jobObj.folder)
        if(jobObj && jobObj.folder===null){
            Api('post-form', `${NEWJOBCARD_ADD_FOLDER}${jobObj && jobObj.id}/`, data_val, success, failed)
        }
        else 
            Api('post-form', OHS_AND_S_FILE_ADD, data_val, success, failed)
    }

    const FileListing = ({val, setVal, filePre, disable, multiple=false, multiList=null, setMultiList=null, keyId}) =>{
        console.log('VAL=>',val)

        console.log('FILELISTINg=>','val',val,'filePre',filePre,'multi',multiple,'multiList',multiList,'KeyID',keyId)
        const updateTempMultiFiles = (arr) =>{
            setTempImgFileList({...tempImgFileList, [setMultiList] : arr})
        }

        const importMultifiles = ({e=null, fileObj=null}) =>{
            console.log('E=>',e,'FILEObj=>',fileObj)
            let obj = e && e.target.files ? e.target.files : fileObj
            if(multiple && modalType!=='add'){
                let files = multiList ? [...multiList] : []
                for(let i=0; i < obj.length; ++i)
                    files.push(obj[i])
                updateTempMultiFiles(files)
            }else if(multiple){
                let files = val ? [...val] : []
                for(let i=0; i < obj.length; ++i)
                    files.push(obj[i])
                setVal(files)
            }
            else 
                setVal(obj[0])
        }

        const SingleFile = ({item, id, temp=false, itemObj}) => {
            const { name, url } = item
            console.log('SINGLEFILE',item,'ID=>',id,'TEMP=>',temp,'MULTI=>',multiList)
            let fileUrl = item.url && item.url.id ? item.url.file : HOST_WITHOUT_SLASH + url
            let split_arr = fileUrl.split('.')
            let name_spliArr = name ? name.split('.') : []
            let currId =  item.url && item.url.id ? item.url.id : id 
            const cropFileName = (str) =>{
                let lastIdx = str ? str.length : null
                if(lastIdx)
                    return lastIdx > 20 ? `${str.substr(0,20)}...${str.substr(str.length-3, str.length)}` : str 
                else 
                    return 'Untitled'
            }
        
            
            return (
                <Tooltip
                    title={name} 
                    arrow={true} placement={'top'}
                    >
                    
                    <div 
                        className={"intranet-main-file-card" }
                        style={{ minHeight: '12vh',
                            maxHeight: '16vh',
                            width: '14vw',
                            marginTop: '1%' }}
                        onClick={(e)=>{ 
                            e.stopPropagation(); 
                            e.preventDefault(); 
                        }}>
                        { modalType==='view' ? null :
                        <div 
                            className="singleFileDeleteIcon" 
                            onClick={(e)=>{ 
                                e.preventDefault(); 
                                e.stopPropagation(); 
                                if(multiple && temp){
                                    console.log('TEMP',temp)
                                    
                                    let newArr = multiList.filter((_,k) => k!==currId)
                                    updateTempMultiFiles(newArr)
                                }
                                else if(multiple && jobObj && jobObj.folder && typeof jobObj.folder!=='undefined'){
                                    console.log('ID=>',currId,val)
                                    updateFiles(currId, val, setVal, !(item.url && item.url.id))
                                    
                                }else if(multiple)
                                {
                                    console.log('VAL=>',val)
                                    let newArr = val.filter((_,k) => k!==currId)
                                    setVal(newArr)
                                }else{
                                    setVal(null)
                                }
                            }} >
                            <DeleteForever style={{ fontSize:18 }} />
                        </div>
                        }
                        <div onClick={() =>{ window.open(fileUrl, "_blank"); }}  className="main-file-icon" style={{ maxHeight : '5rem' }}>
                            {
                                split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif' || split_arr[split_arr.length-1]==='svg' 
                                ||
                                (name && 
                                (name_spliArr[name_spliArr.length-1]==='jpg' || name_spliArr[name_spliArr.length-1]==='png' || name_spliArr[name_spliArr.length-1]==='jpeg' || name_spliArr[name_spliArr.length-1]==='gif' || name_spliArr[name_spliArr.length-1]==='svg'))
                                ?
                                    <img src={fileUrl} alt="ImageError" style={{ objectFit:'cover', maxHeight : '4rem' }} />
                                :
                                    <DescriptionRounded style={{ fontSize: 40 }} />
                            }
                        </div>
                        <div className="main-file-title" style={{ height:'auto' }}>
                            <div style={{ display: 'block', height:'auto' }}>
                                <p style={{ height: 17, width: 192, overflow: 'hidden' }}>{cropFileName(name)}</p>
                                {
                                    itemObj && itemObj.created_at &&
                                    <p style={{ fontSize: 12, marginTop: 4 }}>Created : {moment(itemObj?.created_at).format('DD MMM YYYY HH:mm a')}</p>
                                }
                            </div>
                        </div>
                    </div>
                </Tooltip>
            )
        }

        
        return (
            <div>
                {
                    ((val && typeof val!=='undefined' && ( val && val.length!==0 )) || 
                    ((val && typeof val!=='undefined' && ( val && val.length===0 )) && (multiList && multiList.length!==0)))
                     ?
                    // val.length!==0 ?
                        <div className='jobCardFileDiv' style={{flexDirection:'column-reverse', alignItems:'flex-end', height:'20vh', width:"100%", backgroundColor:'aliceblue' }}>
                            <div className='jobCardFileListDiv' style={{ width:'100%' }}>
                                {
                                    multiple ?
                                        jobObj && jobObj.folder && typeof jobObj.folder!=='undefined' ?
                                            val && val.length>0 && val.map((item, k)=>{
                                                console.log(item.id)
                                        return(
                                            <SingleFile key={k} item={{name : item.name, url : item.url ? item.url : item}} id={item.id} itemObj={item}/>
                                        )
                                            })
                                        :
                                        val && val.length>0 && val.map((item,k)=>{
                                        return(
                                            <SingleFile key={k} item={{
                                                    name : item?.name??"Untitled",
                                                    url : (item.file && typeof item.file === 'string') ? 
                                                            item.file : 
                                                            item.url ? item.url :
                                                            URL.createObjectURL(item)
                                                    }} id={k} itemObj={item}/>
                                        )
                                    })
                                    :
                                    val ?
                                    <SingleFile item={{name : filePre ? val.split('/')[val.split('/').length-1] : val.name, 
                                    url : filePre ? val : val.file && typeof val.file==='string' ? val.file : URL.createObjectURL(val)
                                    }} id={0}  itemObj={null}/>
                                    : null
                                }
                                {
                                    multiple ? 
                                    multiList && multiList.map((item,k)=>{
                                        return(
                                            <SingleFile key={k} item={{name : item.name, url : item.file && typeof item.file === 'string' ? item.file : URL.createObjectURL(item)}} id={k} temp={true} itemObj={item}/>
                                        )
                                    })
                                    : null
                                }
                            </div>
                                
                            {
                                modalType!=='view' &&
                            <div className={`jobCardFileAddBtn ${ disable ? 'jobCardFileAddBtnInactive' : ''}`}>
                                <label htmlFor="file-input">
                                    <input type='file'  id="file-input"  multiple={multiple}
                                        onChange={(e) => {
                                            if(e!==null)
                                                importMultifiles({e : e, fileObj : null})
                                          }} />
                                    <button>Add File</button>
                                </label>
                            </div>
                            }
                            
                            
                        </div>
                    :
                        modalType!=='view' &&
                        <div className='jobCardFileDiv' style={{flexDirection:'column-reverse', alignItems:'flex-end', width:"100%", backgroundColor:'aliceblue' }}>
                            <div className={`jobCardFileAddBtn ${ disable ? 'jobCardFileAddBtnInactive' : ''}`}>
                            <label htmlFor="file-input">
                                    <input type='file'  id="file-input"  multiple={multiple}
                                        onChange={(e) => {
                                                if(e!==null)
                                                    importMultifiles({e : e, fileObj : null})
                                          }} />
                                    <button>Add File</button>
                                </label>
                            </div>
                        </div>
                    // : null
                }
                {
                    modalType!=='view' &&
                    <FilesDragAndDrop 
                    keyId={keyId}
                    onUpload={(val) => {
                        console.log('FILESDragNDrop=>',val)
                        importMultifiles({ e : null, fileObj : val })
                    }} 
                />
                }
                
            </div>
        )}

    const updateTxtAreaValue = (val, obj) =>{
        if(textAreaValid && textAreaValid[obj]!==val)
            setTextAreaValid({...textAreaValid, [obj] : val})
        else if(textAreaValid===null)
            setTextAreaValid({ [obj] : val })
        else return 0;
    }

    const setupReoccurWeek = (val) => {
        if(val===null)
            setreoccurWeek([])
        else if(reoccurWeek && reoccurWeek.includes(val)){
            let newArr = reoccurWeek.filter(el => el!==val)
            setreoccurWeek(newArr)
        }else{
            setreoccurWeek([...reoccurWeek, val])
        }
    }

    function containsFalse(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] === false) {
            return true;
          }
        }
        return false;
      }
      const convsetupReoccurWeek = (val) => {
        if(val===null)
            setconvreoccurWeek([])
        else if(convreoccurWeek && convreoccurWeek.includes(val)){
            let newArr = convreoccurWeek.filter(el => el!==val)
            setconvreoccurWeek(newArr)
        }else{
            setconvreoccurWeek([...convreoccurWeek, val])
        }
    }
      const convertJobType = () =>{
        let data = new FormData()
        data.append('frequency', convFreq)
        data.append('schedule_days', convreoccurWeek)
        data.append('conversion_datetime', moment(convDates).format('YYYY-MM-DD'))
        data.append('daily_schedule', contypeOfReoccur==='daily' ? true : false)
        Api('post',`${CONVERTJOB}${jobObj.id}/`,data).then(
            res =>{
                console.log(res)
                setButtonLoader(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title : `Job Card Converted`,
                    showConfirmButton: false,
                    timer: 1500
                })
                setconvreoccurWeek([])
                setConvFreq(0)
                setConvJBType(false)
            }        
        ).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            setButtonLoader(false)
        })
        
           
    }


    return (
        <div className="jobCardLayout" style={{ position:'relative' }} >
            { modalType!=='add' ? 
                <>
                    <h2 style={{ marginLeft:'1%' }}>Job Card</h2>
                </> : null}
            {
                convJBType ?
                <>
                <h2 style={{ marginLeft:'1%' }}>Change One-off Job card to Recurring</h2>
            <div className="jobCardContentDiv">
                <div className="jobCardDivInpContentDiv">
                    <div>Frequency</div>
                    <div>
                        <span>:</span>
                        <input type="text" className="jobCardDivInp" placeholder="Set Frequency" value={convFreq} onChange={(e)=>setConvFreq(e.target.value)} />
                    </div>
                </div>
                <div className="jobCardDivInpContentDiv">
                        <div>Start Reoccurring on Date</div>
                        <div id="jobCardDate">
                            <span>:</span>
                            <DateTimeInput
                            dateSetFn={setConvDates}
                            dateValue={convDates}
                            clsName={'jobCardDivInp'}
                            
                            />
                            {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv" style={{ marginBottom : '1%' }}>
                        <div>Type of Reoccurring</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="radio" value={'weekly'} checked={ contypeOfReoccur==='weekly' }
                             onChange={()=>{setConvTypeOfReoccur('weekly'); convsetupReoccurWeek(null) }}/><span>in Weeks Only</span>
                            <input type="radio" value={'daily'} checked={ contypeOfReoccur==='daily' }
                             onChange={()=>{setConvTypeOfReoccur('daily') }}/><span>in Daily</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{typeOfReoccur==='weekly' ?'in Weeks Only':'in Daily'}</p>
                        </div>
                        }
                    </div>
                {      contypeOfReoccur==='daily' ?
                <div className="jobCardDivInpContentDiv" style={{ marginBottom : '1%' }}>
                    <div>Reoccurring Week</div>
                    <div>
                        <div>:</div>
                        <div></div>
                    </div>
                </div> : null}
                {   contypeOfReoccur==='daily' ?
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} className="weekDayCheckBox">
                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(0) } 
                            onChange={()=>{ convsetupReoccurWeek(0) }}/><span>Monday</span>
                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(1) } 
                            onChange={()=>{ convsetupReoccurWeek(1) }}/><span>Tuesday</span>
                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(2) } 
                            onChange={()=>{ convsetupReoccurWeek(2) }}/><span>Wednesday</span>
                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(3) } 
                            onChange={()=>{ convsetupReoccurWeek(3) }}/><span>Thursday</span>

                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(4) } 
                            onChange={()=>{ convsetupReoccurWeek(4) }}/><span>Friday</span>
                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(5) } 
                            onChange={()=>{ convsetupReoccurWeek(5) }}/><span>Saturday</span>
                        <input type="checkbox"  checked={ convreoccurWeek && convreoccurWeek.includes(6) } 
                            onChange={()=>{ convsetupReoccurWeek(6) }}/><span>Sunday</span>
                        </div> : null}
            </div>
            <div style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'space-evenly', marginTop:'2%'  }}>
            <div className="header-button-top" onClick={()=>  setConvJBType(false) }>
                    Back
                </div>
                <div className="header-button-top delBtnTop newJobCardBtnActive" style={{ color:'white'}} 
                    onClick={()=>  {
                        if(!buttonLoader){
                        setButtonLoader(true)
                        convertJobType()}} }>
                        { buttonLoader ? <CircularProgress/>  : 'Convert One-Off to Recurring'}
                    </div>
            </div>
                </>
                :
                <div className="header-button-layout" style={{ justifyContent:'space-between'}}>
                {   (modalType==='add' || modalType==='view') || !editOpt ?
                <span className="jobCardNameDiv" >
                    <span style={{ margin: '10px', fontWeight:'500', fontSize:'1.3rem' }}>{jobCardName==='' ? 
                    `${
                            (typeof AddInfoButton==='string' ? parseInt(AddInfoButton)===5 : AddInfoButton===5) ? 'Grease Trap':
                            (typeof AddInfoButton==='string' ? parseInt(AddInfoButton)===4 : AddInfoButton===4) ? 'Liquid Waste':
                            (typeof AddInfoButton==='string' ? parseInt(AddInfoButton)===3 : AddInfoButton===3) ? 'Product Destruction':
                            (typeof AddInfoButton==='string' ? parseInt(AddInfoButton)===2 : AddInfoButton===2) ? 'Packaged Waste':
                            (typeof AddInfoButton==='string' ? parseInt(AddInfoButton)===1 : AddInfoButton===1) ? 'Disposal':
                            'Standard'
                    } Job Card` 
                    : jobCardName}</span>
                    {
                        !(modalType==='add' || modalType==='view') ?
                        <EditOutlined className="editPencilIcon"  onClick={()=> setEditOpt(true) } />
                    :
                        null
                    }
                </span>
                :
                null 
                }
                {
                editOpt ?
                    <span className="jobCardNameDiv" >
                        <input type="text" className="jobCardDivInp"                
                            placeholder="Job Card Name" value={jobCardName} onChange={(e)=>setJobCardName(e.target.value)} />    
                        <CheckCircleOutlineRounded className="checkCircIcon" onClick={()=> jobCardRename()} />
                    </span>
                    
                :
                    null
                }
                {
                    modalType==='add' ? null : (jobObj && jobObj.connected) ? null :
                    modalType==='view' ?
                    <div className="header-button-top delBtnTop" style={{ backgroundColor:'red', color:'white' }} onClick={()=>  delJobCard(jobObj.id) }>
                        Delete
                    </div>    
                    : null
                }
                { UpdatingFormerData ? 
                <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', textAlign:'center', 
                borderRadius:'20px', padding:'1%', backgroundColor:'rgba(15, 153, 208, 0.8)', color:'white', fontSize:'1rem', fontWeight:'600',
                width:'65%' }} >
                    <CircularProgress size={20} color={'white'}/>
                    <p>Populating Data Please Wait...</p>
                </div> : 
                !reoccur && modalType==='edit' ?
                <div className="header-button-top delBtnTop newJobCardBtnActive" style={{ color:'white', borderRadius:'10px', width: '20vw' }} 
                onClick={()=>  {setConvJBType(true)} }>
                    Convert One-Off to Recurring
                </div>    
                : null 
                }
                
                <div className="header-button-top" onClick={()=>  setJobCardModal(false) }>
                    Back
                </div>
                
            </div>
            }
            
            <form className="jobCardContentDiv" style={{ display : convJBType ? 'none' : 'flex' }} onSubmit={(e)=>{ e.preventDefault(); }}>
            
                <Scrollbars style={{ height:'60vh' }}>
                <div className="jobCardDiv1">
                <div className="jobCardDivHead">Site and Company Details</div>
                <div className="jobCardDivInpContent">
                    
                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Name<span style={{ color:'red' }}>*</span></div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteName}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={clientID} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                            onClick={()=>{ setAllClientsLoader(true);
                                Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                                    res =>{
                                        // console.log("DATAAAAA",res)
                                        setAllClientsLoader(false)
                                        if(res.length>0)
                                            setAllClientsList(res)
                                        else 
                                            setAllClientsList([])
                                    }
                                        
                                )
                            }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                
                                    setAllTOWListLoader(true)
                                setClientID(e.target.value);
                                Api('get', `${SITE_TYPE_OF_WASTE_LIST}details/${e.target.value}/`,'')
                                    .then(res => {
                                        console.log(res)
                                        setAllTOWList(res.filter(el => { return{ waste_type_str : el.waste_type_str, original_name : el.type_of_waste_str_profile_name, id : el.id } }))
                                        setAllTOWListLoader(false)
                                    })
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                          
                                        setJobCardName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name)
                                        setSiteName(response.client_name)
                                        setSiteAddr(response.site_address)
                                        console.log('ADDRESS UPDATED IN CLIENt LIST')
                                        setSitePost(response.site_post_code)
                                        setSiteContactName(response.site_contact_person)
                                        setSiteContactPhone(response.site_phone_no)
                                        setSiteContactEmail(response.company_email==='' || response.company_email===null ? response.client_email : response.company_email)
                                        setSiteContactMob(response.site_contact_mob)
                                        // setPrice(response.price)
                                        // setAccountStatus(response.invoice_account_status)
                                        setInductionType(response.induction_type)
                                        setMarkers([{ lat: parseFloat(response.location_latitude), lng: parseFloat(response.location_logitude) }])

                                        setCustName(response?.company_name??'')
                                        setCustAddr(response?.company_address??'')
                                        setCustContactEmail(response?.company_email??'')
                                        setCustContactPhone(response?.company_contact_phone??'')
                                        setCustContactMob(response?.company_mobile_number??'')

                                })
                                
                            ; }
                                 }}>
                                     <option value={''}>Select Client</option>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            :
                            <p>{siteName}</p>
                            }
                        </div>
                        
                    </div>
                    {   tabType==='waste' ?
                    <div className={ modalType==='view' || modalType==='verify' ? 'jobCardDivInpContentDiv' : 'jobCardDivInpContentDivTOW'  }>
                        <div>Type of Waste (Site Profile)<span style={{ color:'red' }}>*</span></div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                                allTOWListLoader ?
                                <p>Loading...</p>
                                :
                            <select className="jobCardDivInp" value={typeOfWasteId} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}

                                 onChange={val =>{ 
                                    let currObj =  allTOWList.find(el => el.id === parseInt(val.target.value))
                                    setTypeofWaste(currObj.original_name!==null && currObj.original_name ? 
                                        `${currObj.waste_type_str}(${currObj.original_name})` : `${currObj.waste_type_str}`);
                                    setTypeOfWasteId(allTOWList.find(el => el.id === parseInt(val.target.value)).id);
                                    setCapacity(currObj && currObj.device_capacity ? currObj.device_capacity : '')
                                    setBarcode(currObj && currObj.barcode ? currObj.barcode : '')
                                    setEstJobDuration(currObj && currObj.job_duration ? currObj.job_duration : '')
                                  
                                    setAddInfo(currObj && currObj.information ? currObj.information : '')
                                    setPitLoc(currObj && currObj.pit_location ? currObj.pit_location : '')
                                    setAccess(currObj && currObj.access_restriction ? currObj.access_restriction : '')
                                    }}>
                                     <option value={''}>Select a Types of Waste</option>
                                     {
                                         allTOWList && allTOWList.length!==0 ?
                                            allTOWList.map((el, k) => {
                                                return(
                                                    <option key={k} value={el.id} >
                                                        {el.original_name!==null && el.original_name ? 
                                                        `${el.waste_type_str}(${el.original_name})` :
                                                        el.type_of_waste_str_profile_name!==null && el.type_of_waste_str_profile_name ?
                                                        `${el.type_of_waste_str_profile_name}(${el.waste_type_str})` :
                                                         `${el.waste_type_str}`}</option>
                                                )
                                            })
                                            :
                                            <option value={''}>No Available Types of Waste</option>
                                     }
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    :   null
                    }
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteAddr}</p>   :
                               !mapInit.status || UpdatingFormerData ?
                                <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>Loading Google Map...</p>
                                :
                            <SearchGoogleAddress
                                center={{ lat : HOME_LATITUDE, lng : HOME_LONGITUDE }}
                                inputPlaceHolder={'Site Address'}
                                inputFn={(e)=>{ setSiteAddr(e); console.log('GOOGLEMAPS-E:',e); console.log('ADDRESS UPDATED IN GOOGLE')}}
                                inputVal={siteAddr}
                                setMarkers={setMarkers}
                                moreInfo={(r) => { 
                                                        
                                    let res = r.results && r.results.address_components ? r.results.address_components : null
                                    console.log(res)
                                    if(res){ 
                                         
                                        res.find(o =>{
                                            if( o.types.includes('postal_code'))
                                                setSitePost(o.long_name)
                                                else{
                                                    setSitePost('')
                                                }
                                                
                                            console.log(o,o.types.includes('postal_code'))
                                            return 0
                                        })
                                 } }}
                                
                                cls={{ outerBox : 'jobsearchAddressSite', innerBox : 'jobCardDivInp'}}
                            />
                            
                            // <input type="text" className="jobCardDivInp" placeholder="Site Address" value={siteAddr} onChange={(e)=>setSiteAddr(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Postcode</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{sitePost}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Site Postcode" value={sitePost} onChange={(e)=>setSitePost(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Contact Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteContactName}</p>    :
                            <input type="text" className="jobCardDivInp" placeholder="Site Contact Name" value={siteContactName} onChange={(e)=>setSiteContactName(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Contact Phone</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteContactPhone}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Site Contact Phone" value={siteContactPhone}
                             onChange={(e)=>{
                                if(e.target.value==='')
                                setSiteContactPhone('')
                            else if(!LETTERS_VALIDATION.test(e.target.value))
                                setSiteContactPhone(e.target.value) 
                                }} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Contact Mob</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteContactMob}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Site Contact Mob" value={siteContactMob}
                             onChange={(e)=>{
                                if(e.target.value==='')
                                setSiteContactMob('')
                            else if(!LETTERS_VALIDATION.test(e.target.value))
                                setSiteContactMob(e.target.value) 
                                }} />
                            }
                        </div>
                    </div>
                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Contact Email<span style={{ color:'red' }}>*</span></div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteContactEmail}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Site Contact Email" value={siteContactEmail}
                             onChange={(e)=>{ setSiteContactEmail(e.target.value)  }} />
                            }
                        </div>
                    </div>

                    <div className="jobCardDivInpContentDiv" style={{ margin : '4% 0' }}>
                        <div>Site and Company Details are same</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ sameDetails() } onChange={()=>{ 
                                if(siteName!=='')
                                    setCustName(siteName)
                                if(siteAddr!=='')
                                    setCustAddr(siteAddr)
                                else if(custAddr!=='')
                                    setSiteAddr(custAddr)

                                if(siteContactEmail!=='')
                                    setCustContactEmail(siteContactEmail)
                                else if(custContactEmail!=='')
                                    setSiteContactEmail(custContactEmail)

                                if(siteContactMob!=='')
                                    setCustContactMob(siteContactMob)
                                else if(custContactMob!=='')
                                    setSiteContactMob(custContactMob)
                                
                                if(siteContactPhone!=='')
                                    setCustContactPhone(siteContactPhone)
                                else if(custContactPhone!=='')
                                    setSiteContactPhone(custContactPhone)

                            }}/>
                                <span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !sameDetails() } onChange={()=>{ 
                                setCustName('')
                                setCustAddr('')
                                setCustContactEmail('')
                                setCustContactMob('')
                                setCustContactPhone('')
                            }}/>
                                <span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{sameDetails() ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>

                    <div className="jobCardDivInpContentDiv">
                        <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{custName}</p>    :
                            <input type="text" className="jobCardDivInp" placeholder="Company Name" value={custName} onChange={(e)=>setCustName(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Company Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{custAddr}</p>   :
                               !mapInit.status || UpdatingFormerData ?
                                <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>Loading Google Map...</p>
                                :
                            <SearchGoogleAddress
                                center={{ lat : HOME_LATITUDE, lng : HOME_LONGITUDE }}
                                inputPlaceHolder={'Company Address'}
                                inputFn={(e)=>{ setCustAddr(e); console.log('GOOGLEMAPS-E:',e); console.log('ADDRESS UPDATED IN GOOGLE')}}
                                inputVal={custAddr}
                                moreInfo={(r) => { 
                                                        
                                    let res = r.results && r.results.address_components ? r.results.address_components : null
                                    console.log(res)
                                }}
                                
                                cls={{ outerBox : 'jobsearchAddressSite', innerBox : 'jobCardDivInp'}}
                            />
                            
                            // <input type="text" className="jobCardDivInp" placeholder="Site Address" value={siteAddr} onChange={(e)=>setSiteAddr(e.target.value)} />
                            }
                        </div>
                    </div>               
                    <div className="jobCardDivInpContentDiv">
                        <div>Company Contact Phone</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{custContactPhone}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Company Contact Phone" value={custContactPhone}
                             onChange={(e)=>{
                                if(e.target.value==='')
                                setCustContactPhone('')
                            else if(!LETTERS_VALIDATION.test(e.target.value))
                                setCustContactPhone(e.target.value) 
                                }} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Company Contact Mob</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{custContactMob}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Company Contact Mob" value={custContactMob}
                             onChange={(e)=>{
                                if(e.target.value==='')
                                setCustContactMob('')
                            else if(!LETTERS_VALIDATION.test(e.target.value))
                                setCustContactMob(e.target.value) 
                                }} />
                            }
                        </div>
                    </div>
                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Company Contact Email</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{custContactEmail}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Company Contact Email" value={custContactEmail}
                             onChange={(e)=>{ setCustContactEmail(e.target.value)  }} />
                            }
                        </div>
                    </div>

                    <div className="jobCardDivInpContentDiv">
                        <div>Additional Email</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            null   :
                            <div style={{ display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', width:"100%" }}>
                                <input type="text" className="jobCardDivInp" placeholder="Email" value={additionalEmail}
                                    onChange={(e)=>{ setAdditionalEmail(e.target.value)  }} 
                                    onKeyUp={(e)=>{
                                        if(e.key==='Enter' && additionalEmail!==''){
                                            setAdditionalEmailArr([
                                                ...additionalEmailArr,
                                                additionalEmail
                                            ])
                                            setAdditionalEmail('')
                                        }
                                    }}
                                    />
                                <AddCircleOutlineRounded className="checkCircIcon" onClick={()=> {
                                        if(additionalEmail!==''){
                                            setAdditionalEmailArr([
                                                ...additionalEmailArr,
                                                additionalEmail
                                            ])
                                            setAdditionalEmail('')
                                        }
                                        }} />
                            </div>
                            }                           
                        </div>
                    </div>
                    <div className='jobCardMultilineEntryManage'>
                        {
                            additionalEmailArr?.map((el,k)=>{
                                return (
                                    <div>
                                        <div>{el}</div>
                                        {
                                            modalType!=='view' &&
                                            <div>
                                            <EditOutlined onClick={()=>{ setAdditionalEmail(el) }}/>
                                            <DeleteForever onClick={()=>{
                                                let oldArr = additionalEmailArr.filter((el,idx,arr)=> idx!==k)
                                                setAdditionalEmailArr(oldArr)
                                            }} />
                                        </div>
                                        }
                                        
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="jobCardDivInpContentDiv">
                        <div>Additional Contact Phone</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            null   :
                            <div style={{ display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', width:"100%" }}>
                                <input type="text" className="jobCardDivInp" placeholder="Contacts" value={additionalCont}
                                    onChange={(e)=>{ setAdditionalCont(e.target.value)  }} 
                                    onKeyUp={(e)=>{
                                        if(e.key==='Enter' && additionalCont!==''){
                                            setAdditionalContArr([
                                                ...additionalContArr,
                                                additionalCont
                                            ])
                                            setAdditionalCont('')
                                        }
                                    }}
                                    />
                                <AddCircleOutlineRounded className="checkCircIcon" onClick={()=> {
                                        if(additionalCont!==''){
                                            setAdditionalContArr([
                                                ...additionalContArr,
                                                additionalCont
                                            ])
                                            setAdditionalCont('')
                                        }
                                        }} />
                            </div>
                            }                           
                        </div>
                    </div>
                    <div className='jobCardMultilineEntryManage'>
                        {
                            additionalContArr?.map((el,k)=>{
                                return (
                                    <div>
                                        <div>{el}</div>
                                        {
                                            modalType!=='view' &&
                                            <div>
                                            <EditOutlined onClick={()=>{ setAdditionalCont(el) }}/>
                                            <DeleteForever onClick={()=>{
                                                let newArr = additionalContArr.filter((el,idx,arr)=> idx!==k)
                                                setAdditionalContArr(newArr)
                                            }} />
                                        </div>
                                        }
                                        
                                    </div>
                                )
                            })
                        }
                    </div>

                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Induction Type</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{inductionType}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Induction Type" value={inductionType} onChange={(e)=>setInductionType(e.target.value)} />
                            }
                        </div>
                    </div>
                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Industry Type<span style={{ color:'red' }}>*</span></div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{
                                IndustryTypeLoader ? 'Loading...'    :
                                (IndustryTypeList && IndustryTypeList.find(el=>el.id===parseInt(industryTypeID))) ? 
                                IndustryTypeList.find(el=>el.id===parseInt(industryTypeID)).industry_name :
                                industryTypeID}</p>  :
                            <select className="jobCardDivInp" value={industryTypeID} 
                                
                                 onChange={val =>{ 
                                    setindustryTypeID(val.target.value)
                                     }}>
                                     <option value=''>Select a Industry Type</option>      
                                     {
                                         IndustryTypeLoader ?
                                         <option value=''>Loading...</option>
                                         :
                                         IndustryTypeList.map((item)=>{
                                             return(
                                                <option value={item.id}>{item.industry_name}</option>
                                             )
                                         })
                                     }                         
                                    
                                    
                            </select> 
                            }
                        </div>
                    </div>
                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Sales Person<span style={{ color:'red' }}>*</span></div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{
                                TeamSalespersonLoader ? 'Loading...'    :
                                (TeamSalespersonList && TeamSalespersonList.find(el=>el.id===parseInt(salesPerson))) ? 
                                TeamSalespersonList.find(el=>el.id===parseInt(salesPerson)).name :
                                salesPerson}</p>  :
                            <select className="jobCardDivInp" value={salesPerson} 
                                // style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                //          cursor: preSelect===null ? 'pointer' : 'default' }}

                                 onChange={val =>{ 
                                    //  console.log(val.target.value,typeof val.target.value, TeamSalespersonList,TeamSalespersonList.findIndex(el => el.id===parseInt(val.target.value)))
                                     setSalesPerson(val.target.value)
                                     }}>
                                     <option value=''>Select a Sales Person</option>      
                                     {
                                         TeamSalespersonLoader ?
                                         <option value=''>Loading...</option>
                                         :
                                         TeamSalespersonList.map((item)=>{
                                             return(
                                                <option value={item.id}>{item.name}</option>
                                             )
                                         })
                                     }                         
                                    
                                    
                            </select>
                            
                            // <input type="text" className="jobCardDivInp" placeholder="Sales Person" value={salesPerson} onChange={(e)=>setSalesPerson(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Date</div>
                        <div id="jobCardDate">
                            <span>:</span>
                            {
                                modalType==='view' ? <p>{moment(jobDate).format('DD-MMM-YYYY')}</p>
                                :
                                <DateTimeInput
                            dateSetFn={setJobDate}
                            dateValue={jobDate}
                            clsName={'jobCardDivInp'}
                            
                            />
                            }
                            
                            {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                        </div>
                    </div>
                    {  
                    <div className="jobCardDivInpContentDiv" style={{ marginBottom : '1%' }}>
                        <div>Is this an Outsourced Job</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ outsourced } onChange={()=>{ setOutsourced(true)}}/>
                                <span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !outsourced } onChange={()=>{ setOutsourced(false)}}/>
                                <span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{outsourced ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    }
                    {   jobObj && jobObj.connected ?    null :
                    <div className="jobCardDivInpContentDiv" style={{ marginBottom : '1%' }}>
                        <div>Is this a Reoccurring Quote</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ reoccur } onChange={()=>setReoccur(true)}/><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !reoccur } onChange={()=>{
                                setReoccur(false);  setFrq(0);}
                                
                                }/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{reoccur ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    }
                    {   jobObj && jobObj.connected ?    null :
                    reoccur ?
                    <div className="jobCardDivInpContentDiv" style={{ marginBottom : '1%' }}>
                        <div>Type of Reoccurring</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="radio"  disabled={modalType==='view' } value={'weekly'} checked={ typeOfReoccur==='weekly' }
                             onChange={()=>{setTypeOfReoccur('weekly'); setupReoccurWeek(null) }}/><span>in Weeks Only</span>
                            <input type="radio"  disabled={modalType==='view' } value={'daily'} checked={ typeOfReoccur==='daily' }
                             onChange={()=>{setTypeOfReoccur('daily') }}/><span>in Daily</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{typeOfReoccur==='weekly' ?'in Weeks Only':'in Daily'}</p>
                        </div>
                        }
                    </div>
                    :   null
                    }
                    {   (jobObj && jobObj.connected) || (typeOfReoccur!=='daily') ?    null :
                    reoccur ?
                    <div className="jobCardDivInpContentDiv" style={{ marginBottom : '1%' }}>
                        <div>Days in Which Job to Reoccur</div>
                        {   modalType!=='view'  ?
                        <div>
                            <div>:</div>
                            <div></div>
                        </div>
                        :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{reoccur ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>  :   null
                    }
                    {   (jobObj && jobObj.connected) || (typeOfReoccur!=='daily') || modalType==='view' ?    null :
                        reoccur ?
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} className="weekDayCheckBox">
                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(0) } 
                            onChange={()=>{ setupReoccurWeek(0) }}/><span>Monday</span>
                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(1) } 
                            onChange={()=>{ setupReoccurWeek(1) }}/><span>Tuesday</span>
                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(2) } 
                            onChange={()=>{ setupReoccurWeek(2) }}/><span>Wednesday</span>
                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(3) } 
                            onChange={()=>{ setupReoccurWeek(3) }}/><span>Thursday</span>

                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(4) } 
                            onChange={()=>{ setupReoccurWeek(4) }}/><span>Friday</span>
                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(5) } 
                            onChange={()=>{ setupReoccurWeek(5) }}/><span>Saturday</span>
                        <input type="checkbox"  disabled={modalType==='view' } checked={ reoccurWeek && reoccurWeek.includes(6) } 
                            onChange={()=>{ setupReoccurWeek(6) }}/><span>Sunday</span>
                        </div>
                        :   null
                    }
                    {   jobObj && jobObj.connected ? null :
                    <div className={`jobCardDivInpContentDiv ${ !reoccur ? 'jobCardFieldDisabled' : ''}`}>
                        <div>Frequency in Weeks</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{frq}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Frquency in Weeks" value={frq} onChange={(e)=>
                                {
                                    if(e.target.value===''|| e.target.value===0 || e.target.value==='0')
                                        setFrq('')
                                    else if(NUMBER_VALIDATION_REGEX.test(e.target.value)){
                                        setFrq(e.target.value)
                                    }
                                }
                            } />
                            }
                        </div>
                    </div>
                    }
                    {
                        jobObj && jobObj.connected ?
                    <div className={`jobCardDivInpContentDiv jobCardFieldDisabled`}>
                        <div>
                            This is {reoccur ? '' : <strong>&nbsp;&nbsp;NOT&nbsp;&nbsp;</strong>} a reoccurring Quote{reoccur ? `, with a frequency of ${frq} weeks.` : '.'}
                        </div>
                    </div>
                    : null
                    }
                </div>
            </div>
            <div className="jobCardDiv4">
                <div className="jobCardDivHead" style={{ marginBottom:0 }}>Additional Information</div>
                {/* <div className='jobCardDivHeadButtonSection' style={{ pointerEvents : modalType==='view' ? 'none' : 'auto' }}>
                    <div onClick={()=>{ setAddInfoButton(5) }}  className={`${AddInfoButton===5 ? 'jobCardDivHeadButtonSectionDiv' : ''}`}>Grease Trap</div>
                    <div onClick={()=>{ setAddInfoButton(4) }}  className={`${AddInfoButton===4 ? 'jobCardDivHeadButtonSectionDiv' : ''}`}>Liquid Waste</div>
                    <div onClick={()=>{ setAddInfoButton(3) }}  className={`${AddInfoButton===3 ? 'jobCardDivHeadButtonSectionDiv' : ''}`}>Product Destruction</div>
                    <div onClick={()=>{ setAddInfoButton(2) }}  className={`${AddInfoButton===2 ? 'jobCardDivHeadButtonSectionDiv' : ''}`}>Packaged Waste</div>
                    <div onClick={()=>{ setAddInfoButton(1) }}  className={`${AddInfoButton===1 ? 'jobCardDivHeadButtonSectionDiv' : ''}`}>Disposal</div>
                    <div onClick={()=>{ setAddInfoButton(0) }}  className={`${AddInfoButton===0 ? 'jobCardDivHeadButtonSectionDiv' : ''}`}>Standard</div>
                </div> */}
                <div className="jobCardDivInpContent">
                    <div className='jobCardDivInpContentDiv' style={{ alignItems:'flex-start', marginTop:'1vh', display : AddInfoButton===1 ? 'none' : 'flex' }} >
                        <div style={{ marginTop:'1vh' }}>Access Restriction</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            <TextAreaLimiter isValid={(e) => { updateTxtAreaValue(e, 'Access_Restriction') }}
                            v={access}
                            max={1500}
                                configs={{
                                    className : "jobCardDivInpTextArea",
                                    disabled : modalType==='view',
                                    placeholder : "Access Restriction Comment",
                                    value : access, 
                                    onChange : (e)=>setAccess(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    {
                        AddInfoButton!==5 &&
                        <>
                            <>
                            <div className={`jobCardDivInpContentDiv ${fileAtt && fileAtt.tcreq && fileAtt.tcreq.length!==0 ? 'jobCardFileExt' : ''}`} 
                                style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                        <div style={{ marginTop:'1vh' }}>TC Required | Comments</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            <TextAreaLimiter
                            isValid={(e) => { updateTxtAreaValue(e, 'TC_Required') }}
                            max={1500}
                            configs={{
                                className : "jobCardDivInpTextArea",
                                disabled : modalType==='view', 
                                placeholder : "Enter TC Required Comment here",
                                value : tcReq===null ? '' : tcReq,
                                onChange : (e)=>setTcreq(e.target.value)
                            }}
                            v={tcReq} />
                        </div>
                    </div>
                    <FileListing keyId={'0'} val={fileAtt.tcreq} setVal={(v)=>{ setFileAtt({...fileAtt, tcreq : v}) }}  disable={modalType==='view'}
                        filePre={modalType!=='add' ? jobObj.tc_required_file===fileAtt.tcreq : false} multiple={true}
                        multiList={tempImgFileList.tcreq} setMultiList={'tcreq'}  />    
                            </>
                        
                            <>
                            <div className={`jobCardDivInpContentDiv ${fileAtt && fileAtt.manifest && fileAtt.manifest.length!==0 ? 'jobCardFileExt' : ''}`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                        <div style={{ marginTop:'1vh' }}>Manifest | Comments</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            <TextAreaLimiter
                            isValid={(e) => { updateTxtAreaValue(e, 'Manifest') }}
                            max={1500}
                            configs={{
                                className : "jobCardDivInpTextArea",
                                disabled : modalType==='view', 
                                placeholder : "Enter Manifest Comment here",
                                value : manifest===null ? '' : manifest,
                                onChange : (e)=>setManifest(e.target.value)
                            }}
                            v={manifest} />
                        </div>
                    </div>
                    <FileListing keyId={'1'} val={fileAtt.manifest} setVal={(v)=>{ setFileAtt({...fileAtt, manifest : v}) }}  disable={modalType==='view'}
                        filePre={modalType!=='add' ? jobObj.manifest_file===fileAtt.manifest : false} multiple={true}
                        multiList={tempImgFileList.manifest} setMultiList={'manifest'} />
                            </>
                       <>
                            <div className={`jobCardDivInpContentDiv ${fileAtt && fileAtt.chemappr && fileAtt.chemappr.length!==0 ? 'jobCardFileExt' : ''}`}>
                        <div>Chemist Approval</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={ChemAppr} onChange={()=>setChemAppr(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!ChemAppr} onChange={()=>setChemAppr(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{ChemAppr ?'YES':'NO'}</p>
                        </div>
                        }
                        
                    </div>
                    <FileListing keyId={'2'} val={fileAtt.chemappr} setVal={(v)=>{ setFileAtt({...fileAtt, chemappr : v}) }}
                     disable={modalType==='view'}
                     filePre={modalType!=='add' ? jobObj.chemist_approval_multiple_file===fileAtt.chemappr : false} multiple={true}
                     multiList={tempImgFileList.chemappr} setMultiList={'chemappr'}  />
                            </>
                        
                            <>
                            <div className={`jobCardDivInpContentDiv ${fileAtt && fileAtt.safety && fileAtt.safety.length!==0 ? 'jobCardFileExt' : ''}`}>
                        <div>Safety Data Sheet Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={safetyDataReq} onChange={()=>setSafetyDataReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!safetyDataReq} onChange={()=>setSafetyDataReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{safetyDataReq ?'YES':'NO'}</p>
                        </div>
                        }
                        
                    </div>

                    <FileListing keyId={'3'} val={fileAtt.safety} setVal={(v)=>{ setFileAtt({...fileAtt, safety : v}) }} disable={modalType==='view'}
                     filePre={modalType!=='add' ? jobObj.safety_data_sheet_files===fileAtt.safety : false} multiple={true}
                     multiList={tempImgFileList.safety} setMultiList={'safety'}  />
                            </>
                        
                    
                        </>
                    }
                    
                    
                            <>
                            <div className={`jobCardDivInpContentDiv ${fileAtt && ((fileAtt.imgFile && fileAtt.imgFile.length!==0) || (tempImgFileList.imgFile && tempImgFileList.imgFile.length!==0)) ? 'jobCardFileExt' : ''}`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                        <div style={{ marginTop:'1vh' }}>Additional Images </div>
                        <div style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                            <span style={{ marginTop:'0vh' }}>:</span>{ initLoad ? <span><CircularProgress size={'2rem'} /></span> : null}
                            <TextAreaLimiter
                            isValid={(e) => { updateTxtAreaValue(e, 'Additional_Images') }}
                            max={1500}
                            configs={{
                                className : "jobCardDivInpTextArea",
                                disabled : modalType==='view', 
                                placeholder : "Enter Comments if any",
                                value : imgReq===null ? '' : imgReq,
                                onChange : (e)=>setImgReq(e.target.value)
                            }}
                            v={imgReq} />
                        </div>
                    </div>
                    <FileListing keyId={'4'} val={fileAtt.imgFile} setVal={(v)=>{ setFileAtt({...fileAtt, imgFile : v}) }}  disable={modalType==='view'}
                        filePre={modalType!=='add' ? jobObj.imgFile===fileAtt.imgFile : false} multiple={true}
                        multiList={tempImgFileList.imgFile} setMultiList={'imgFile'} />
                            </>
                       
                    
                    {
                        AddInfoButton!==5 &&
                        
                            <>
                        <div className={`jobCardDivInpContentDiv ${fileAtt && fileAtt.dataform && fileAtt.dataform.length!==0 ? 'jobCardFileExt' : ''}`}>
                        <div>Data Form Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ dataForm } onChange={()=>setDataForm(true)}/><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !dataForm } onChange={()=>setDataForm(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{dataForm ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <FileListing keyId={'5'} val={fileAtt.dataform} setVal={(v)=>{ setFileAtt({...fileAtt, dataform : v}) }} disable={modalType==='view'}
                     filePre={modalType!=='add' ? jobObj.data_form_required_file===fileAtt.dataform : false} multiple={true}
                     multiList={tempImgFileList.dataform} setMultiList={'dataform'}  />
                        </>
                        
                        
                    }
                    
                       
                            <>
                            <div className={`jobCardDivInpContentDiv ${fileAtt && ((fileAtt.purchaseOrderFile && fileAtt.purchaseOrderFile.length!==0) || (tempImgFileList.purchaseOrderFile && tempImgFileList.purchaseOrderFile.length!==0)) ? 'jobCardFileExt' : ''}`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                        <div style={{ marginTop:'1vh' }}>Purchase Order Comment </div>
                        <div style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                            <span style={{ marginTop:'0vh' }}>:</span>{ initLoad ? <span><CircularProgress size={'2rem'} /></span> : null}
                            <TextAreaLimiter
                            isValid={(e) => { updateTxtAreaValue(e, 'Purchase_Order') }}
                            max={1500}
                            configs={{
                                className : "jobCardDivInpTextArea",
                                disabled : modalType==='view', 
                                placeholder : "Enter Comments if any",
                                value : purchaseComment===null ? '' : purchaseComment,
                                onChange : (e)=>setPurchaseComment(e.target.value)
                            }}
                            v={purchaseComment} />
                        </div>
                    </div>
                    <FileListing keyId={'6'} val={fileAtt.purchaseOrderFile} setVal={(v)=>{ setFileAtt({...fileAtt, purchaseOrderFile : v}) }}  disable={modalType==='view'}
                        filePre={modalType!=='add' ? jobObj.purchaseOrderFile===fileAtt.purchaseOrderFile : false} 
                        multiple={true} multiList={tempImgFileList.purchaseOrderFile} setMultiList={'purchaseOrderFile'} />
                            </>
                        
                     

                    {
                        (AddInfoButton===1 || AddInfoButton===0)&&
                        <>
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div>How is the Waste Packaged</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span>:</span>
                                    {   modalType==='view'   ?
                                    <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{wastePackage}</p>   :
                                    <TextAreaLimiter
                                        isValid={(e) => { updateTxtAreaValue(e, 'How_is_the_Waste_Packaged') }}
                                        max={1500}
                                        configs={{
                                            className : "jobCardDivInpTextArea",
                                            disabled : modalType==='view', 
                                            placeholder : "Enter Comments if any",
                                            value : wastePackage===null ? '' : wastePackage,
                                            onChange : (e)=>setWastePackage(e.target.value)
                                        }}
                                        v={wastePackage} />
                                    }
                                </div>
                            </div>
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div>Volume Expected</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span>:</span>
                                    {   modalType==='view'   ?
                                    <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{volumeExpected}</p>   :
                                    <TextAreaLimiter
                                        isValid={(e) => { updateTxtAreaValue(e, 'Volume_Expected') }}
                                        max={1500}
                                        configs={{
                                            className : "jobCardDivInpTextArea",
                                            disabled : modalType==='view', 
                                            placeholder : "Enter Comments if any",
                                            value : volumeExpected===null ? '' : volumeExpected,
                                            onChange : (e)=>setVolumeExpected(e.target.value)
                                        }}
                                        v={volumeExpected} />                                    }
                                </div>
                            </div>
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div>Number of Loads expected</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span>:</span>
                                    {   modalType==='view'   ?
                                    <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{noofLoads}</p>   :
                                    <TextAreaLimiter
                                        isValid={(e) => { updateTxtAreaValue(e, 'Number_of_Loads_Expected') }}
                                        max={1500}
                                        configs={{
                                            className : "jobCardDivInpTextArea",
                                            disabled : modalType==='view', 
                                            placeholder : "Enter Comments if any",
                                            value : noofLoads===null ? '' : noofLoads,
                                            onChange : (e)=>setNoOfLoads(e.target.value)
                                        }}
                                        v={noofLoads} />                                    
                                    }
                                </div>
                            </div>
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div>Sample of Waste to be collected</div>
                                
                                    {   modalType!=='view'  ?
                                        <div>
                                            <input type="checkbox" disabled={modalType==='view' } checked={sampleOfWaste} onChange={()=>setSampleOfWaste(true)}/><span>YES</span>
                                            <input type="checkbox" disabled={modalType==='view' } checked={!sampleOfWaste} onChange={()=>setSampleOfWaste(false)}/><span>NO</span>
                                        </div>  :
                                        <div><span>:</span>
                                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{sampleOfWaste ?'YES':'NO'}</p>
                                        </div>
                                    }
                            </div>
                            {
                                sampleOfWaste ?
                                <div className={`jobCardDivInpContentDiv`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div style={{ marginTop:'1vh' }}>Sample of Waste to be collected | Comments</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span style={{ marginTop:'1vh' }}>:</span>
                                    <TextAreaLimiter
                                    isValid={(e) => { updateTxtAreaValue(e, 'Sample_of_Waste') }}
                                    max={1500}
                                    configs={{
                                        className : "jobCardDivInpTextArea",
                                        disabled : modalType==='view', 
                                        placeholder : "Enter Sample of Waste to be collected Comment here",
                                        value : sampleOfWasteComment===null ? '' : sampleOfWasteComment,
                                        onChange : (e)=>setSampleOfWasteComment(e.target.value)
                                    }}
                                    v={sampleOfWasteComment} />
                                </div>
                                </div>
                            :   null
                            }
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div>Estimated Date and Time of Disposal</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span>:</span>
                                    {   modalType==='view'   ?
                                    <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{estDateAndTime}</p>   :
                                    <TextAreaLimiter
                                        isValid={(e) => { updateTxtAreaValue(e, 'Estimated_Date_Time') }}
                                        max={1500}
                                        configs={{
                                            className : "jobCardDivInpTextArea",
                                            disabled : modalType==='view', 
                                            placeholder : "Enter Comments if any",
                                            value : estDateAndTime===null ? '' : estDateAndTime,
                                            onChange : (e)=>setEstDateAndTime(e.target.value)
                                        }}
                                        v={estDateAndTime} />
                                    }
                                </div>
                            </div>
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div>Flow Meter Required</div>
                                
                                    {   modalType!=='view'  ?
                                        <div>
                                            <input type="checkbox" disabled={modalType==='view' } checked={flowMeterReq} onChange={()=>setFlowMeterReq(true)}/><span>YES</span>
                                            <input type="checkbox" disabled={modalType==='view' } checked={!flowMeterReq} onChange={()=>setFlowMeterReq(false)}/><span>NO</span>
                                        </div>  :
                                        <div><span>:</span>
                                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{flowMeterReq ?'YES':'NO'}</p>
                                        </div>
                                    }
                            </div>
                        </>
                    }
                    
                    <div className="jobCardDivInpContentDiv" style={{ display : AddInfoButton===1 ? 'none' : 'flex', marginTop:'1vh'  }}>
                        <div>Access Height</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{accessHeight}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Access Height" value={accessHeight} onChange={(e)=>setAccessHeight(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className={`jobCardDivInpContentDiv ${ !typeOfWasteValid(TypeofWaste, 3) ? 'jobCardFieldDisabled' : ''}`}
                        style={{ display : AddInfoButton===5 || AddInfoButton===0 ? 'flex' : 'none', marginTop:'1vh' }}>
                        <div>Key Required</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{keyReqNew}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Key Required" value={keyReqNew} onChange={(e)=>setKeyReqNew(e.target.value)} />
                            }
                        </div>
                    </div>    
                    {
                        (AddInfoButton===5 || AddInfoButton===4 || AddInfoButton===0) &&
                        <div className="jobCardDivInpContentDiv" style={{ marginTop:'1vh' }}>
                        <div>Pitt Distance from Truck Location</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitDist}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Pitt Distance from Truck Location" value={pitDist} onChange={(e)=>setPitDist(e.target.value)} />
                            }
                        </div>
                    </div>
                    }                
                    
                    <div className="jobCardDivInpContentDiv" style={{ display : AddInfoButton===5 || AddInfoButton===0 ? 'flex' : 'none', marginTop:'1vh' }}>
                        <div>Water tap Location</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{waterTap}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Water tap Location" value={waterTap} onChange={(e)=>setWaterTap(e.target.value)} />
                            }
                        </div>
                    </div>
                    {
                        (AddInfoButton===5 || AddInfoButton===4 || AddInfoButton===0) &&
                        <>
                        <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div>Gurney Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={gumReq} onChange={()=>setGumReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!gumReq} onChange={()=>setGumReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{gumReq ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div>Confined Space Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={confSpace} onChange={()=>setConfSpace(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!confSpace} onChange={()=>setConfSpace(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{confSpace ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                        </>
                    }
                    {
                        AddInfoButton===1 ? null :
                        <>
                        <div className="jobCardDivInpContentDiv" style={{ marginTop:'1vh' }}>
                        <div>Number of Trucks</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{noTruck}</p>    :
                            <input type="text" className="jobCardDivInp" placeholder="Number of Trucks Required" value={noTruck} onChange={(e)=>setNoTruck(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className={`jobCardDivInpContentDiv ${!typeOfWasteValid(TypeofWaste, 2) ? 'jobCardFieldDisabled' : ''}`} style={{ marginTop:'1vh' }}>
                        <div>Estimated Job Duration</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{estJobDuration}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Estimated Job Duration" value={estJobDuration} onChange={(e)=>setEstJobDuration(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv" style={{ marginTop:'1vh' }}>
                        <div>Best time for service</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{servTime}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Best Time for Service" value={servTime} onChange={(e)=>setServTime(e.target.value)} />
                            }
                        </div>
                    </div>
                        </>
                    }
                    
                    <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div>Specific PPE</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={spcPPE} onChange={()=>setSpcPPE(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!spcPPE} onChange={()=>setSpcPPE(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{spcPPE ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    {
                        spcPPE ?
                        <div className={`jobCardDivInpContentDiv`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                        <div style={{ marginTop:'1vh' }}>Specific PPE | Comments</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            <TextAreaLimiter
                            isValid={(e) => { updateTxtAreaValue(e, 'Specific_PPE') }}
                            max={1500}
                            configs={{
                                className : "jobCardDivInpTextArea",
                                disabled : modalType==='view', 
                                placeholder : "Enter Specific PPE Comment here",
                                value : spcPPEComment===null ? '' : spcPPEComment,
                                onChange : (e)=>setSpcPPEComment(e.target.value)
                            }}
                            v={spcPPEComment} />
                        </div>
                    </div>
                    :   null
                    }
                    {
                        AddInfoButton!==5 &&
                        <>
                            
                                <>
                                <div className={`jobCardDivInpContentDiv ${fileAtt && fileAtt.weighBreq && fileAtt.weighBreq.length!==0 ? 'jobCardFileExt' : ''} ${!typeOfWasteValid(TypeofWaste, 6) ? 'jobCardFieldDisabled' : ''}`}
                                style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div>Weigh Bridge Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={weighBridgeReq==="true" || weighBridgeReq===true} onChange={()=>setWeighBridgeReq("true")}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={weighBridgeReq==="false" || weighBridgeReq===false} onChange={()=>setWeighBridgeReq("false")}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{weighBridgeReq==="true" ?'YES':'NO'}</p>
                        </div>
                        }
                        
                    </div>
                    <FileListing keyId={'7'} val={fileAtt.weighBreq} setVal={(v)=>{ setFileAtt({...fileAtt, weighBreq : v}) }} disable={modalType==='view' || !typeOfWasteValid(TypeofWaste, 6)}
                     filePre={modalType!=='add' ? jobObj.weigh_bridge_required_file===fileAtt.weighBreq : false} multiple={true}
                     multiList={tempImgFileList.weighBreq} setMultiList={'weighBreq'} 
                     />
                                </>
                           
                        
                    <div className='jobCardDivInpContentDiv' style={{ marginTop:'1vh' }}
                    // {`jobCardDivInpContentDiv ${!typeOfWasteValid(TypeofWaste, 7) ? 'jobCardFieldDisabled' : ''}`}
                    >
                        <div>Number and type of pallets to be exchanged</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{noPallets}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Number and type of pallets to be exchanged" value={noPallets} onChange={(e)=>setNoPallets(e.target.value)} />
                            }
                        </div>
                    </div>
                        </>
                    }
                    
                                        
                    <div className='jobCardDivInpContentDiv' style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div style={{ marginTop:'1vh' }}>Additional Information</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            <TextAreaLimiter isValid={(e) => { updateTxtAreaValue(e, 'Additional_Info') }}
                            max={1500}
                            configs={{
                                className : "jobCardDivInpTextArea", disabled : modalType==='view', placeholder : "Additional Information",
                                value : addInfo===null ? '' : addInfo, onChange : (e)=>setAddInfo(e.target.value)
                            }}
                            v={addInfo} />
                        </div>
                    </div>

                    {
                        (AddInfoButton===3 || AddInfoButton===0)&&
                        <>
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                                <div>Photo Required</div>
                                {   modalType!=='view'  ?
                                <div>
                                    <input type="checkbox" disabled={modalType==='view' } checked={photoRequired} onChange={()=>setPhotoRequired(true)}/><span>YES</span>
                                    <input type="checkbox" disabled={modalType==='view' } checked={!photoRequired} onChange={()=>setPhotoRequired(false)}/><span>NO</span>
                                </div>  :
                                <div><span>:</span>
                                <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{photoRequired ?'YES':'NO'}</p>
                                </div>
                                }
                            </div>
                            {
                                photoRequired ?
                                <div className={`jobCardDivInpContentDiv`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div style={{ marginTop:'1vh' }}>Photo Required | Comments</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span style={{ marginTop:'1vh' }}>:</span>
                                    <TextAreaLimiter
                                    isValid={(e) => { updateTxtAreaValue(e, 'b') }}
                                    max={1500}
                                    configs={{
                                        className : "jobCardDivInpTextArea",
                                        disabled : modalType==='view', 
                                        placeholder : "Enter Photo Required Comment here",
                                        value : photoRequiredComment===null ? '' : photoRequiredComment,
                                        onChange : (e)=>setPhotoRequiredComment(e.target.value)
                                    }}
                                    v={photoRequiredComment} />
                                </div>
                            </div>
                            :   null
                            }
                                                        
                            <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                                <div>Certification of Destruction Required</div>
                                {   modalType!=='view'  ?
                                <div>
                                    <input type="checkbox" disabled={modalType==='view' } checked={certOfDistReq} onChange={()=>setCertOfDistReq(true)}/><span>YES</span>
                                    <input type="checkbox" disabled={modalType==='view' } checked={!certOfDistReq} onChange={()=>setCertOfDistReq(false)}/><span>NO</span>
                                </div>  :
                                <div><span>:</span>
                                <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{certOfDistReq ?'YES':'NO'}</p>
                                </div>
                                }
                            </div>
                            {
                                certOfDistReq ?
                                <div className={`jobCardDivInpContentDiv`} style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                                <div style={{ marginTop:'1vh' }}>Certification of Destruction | Comments</div>
                                <div style={{ alignItems:'flex-start' }}>
                                    <span style={{ marginTop:'1vh' }}>:</span>
                                    <TextAreaLimiter
                                    isValid={(e) => { updateTxtAreaValue(e, 'Certification') }}
                                    max={1500}
                                    configs={{
                                        className : "jobCardDivInpTextArea",
                                        disabled : modalType==='view', 
                                        placeholder : "Enter Certification of Destruction Comment here",
                                        value : certOfDistReqComment===null ? '' : certOfDistReqComment,
                                        onChange : (e)=>setCertOfDistReqComment(e.target.value)
                                    }}
                                    v={certOfDistReqComment} />
                                </div>
                            </div>
                            :   null
                            }
                            
                        </>
                    }

                    <div className={`jobCardDivInpContentDiv ${!typeOfWasteValid(TypeofWaste, 0) ? 'jobCardFieldDisabled' : ''}`}
                    style={{ display : AddInfoButton===4 || AddInfoButton===5 || AddInfoButton===0 ? 'flex' : 'none', marginTop:'1vh' }}>
                        <div>Capacity</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{capacity}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Capacity" value={capacity} onChange={(e)=>setCapacity(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className={`jobCardDivInpContentDiv ${!typeOfWasteValid(TypeofWaste, 1) ? 'jobCardFieldDisabled' : ''}`}
                    style={{ display : AddInfoButton===5 || AddInfoButton===0 ? 'flex' : 'none', marginTop:'1vh' }}>
                        <div>Barcode</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{barcode}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Barcode" value={barcode} onChange={(e)=>setBarcode(e.target.value)} />
                            }
                        </div>
                    </div>
                    
                    {
                        (AddInfoButton===5 || AddInfoButton===4 || AddInfoButton===0) &&
                        <>
                        <div className='jobCardDivInpContentDiv' style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div style={{ marginTop:'1vh' }}>Pit Location</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            
                            <TextAreaLimiter
                            max={1500}
                            isValid={(e) => { updateTxtAreaValue(e, 'Pit_Loc') }}
                                configs={{
                                    className : "jobCardDivInpTextArea", disabled : modalType==='view', placeholder : "Pit Location",
                                    value : pitLoc===null ? '' : pitLoc, onChange : (e)=>setPitLoc(e.target.value)
                                }}
                            v={pitLoc} />
                        </div>
                    </div>
                    
                    <div className="jobCardDivInpContentDiv" style={{ marginTop:'1vh' }}>
                        <div>Smoke Alarm</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{smokeAlarm}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Smoke Alarm" value={smokeAlarm} onChange={(e)=>setSmokeAlarm(e.target.value)}/>
                            }
                        </div>
                    </div>
                        </>
                    }
                    
                    
                </div>
            </div>            
                </Scrollbars>
                {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center', placeContent: 'center', placeItems: 'center', height: '10vh' }}>
                <div 
                className={ 
                    (textAreaValid===null || !containsFalse(textAreaValid)) && pattern.test(siteContactEmail) && 
                    (clientID && !isNaN(parseInt(salesPerson))) &&
                    ( ((tabType==='waste') && (TypeofWaste!=='')) || (tabType!=='waste') ) &&
                    !(typeOfReoccur==='daily' && reoccurWeek.length===0) &&
                    ( siteAddr && sitePost && siteContactEmail && industryTypeID!==null && industryTypeID!=='' )
                    ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify'){
                        setButtonLoader(true)
                        addJobCard()
                        
                    }else if(!jobObj.connected){
                        setButtonLoader(true)
                        addJobCard()
                    }}} 
                  style={{ width:'15vw', height:'5vh', textAlign:'center' }}>

                {   
                    buttonLoader ?
                    <CircularProgress />
                    :
                    !siteAddr ? 'Select Address' : !sitePost ? 'Select Site Postcode' : 
                !pattern.test(siteContactEmail) ? 'Improper Email entered' :
                !clientID ? 'Client Details Error. Please close and try again' :
                !( ((tabType==='waste') && (TypeofWaste!=='')) || (tabType!=='waste') ) ? 'Type of Waste needed Waste' :
                typeOfReoccur==='daily' && reoccurWeek.length===0 ? 'Select Atleast one Week' :
                isNaN(parseInt(salesPerson)) ?  'Select a Sales Person' :
                (industryTypeID===null || industryTypeID==='') ? "Select Industry Type" :
                modalType==='add' ? 'Create Job Card' : modalType==='verify' ? jobObj.connected ?
                `This job card is already in use `
                :   'Update and Verify' : 'Update Job Card'} 
                </div>
            </div>
            :   null
            }
            </form>
        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.userLogin
    return { user }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        addJob : (data, success, failed) => dispatch(addNewJobCard(data, success, failed)),
        editJob : (data, job, success, failed) => dispatch(editNewJobCard(data, job, success, failed)),
        editJobCardName : (data, job, success, failed) => dispatch(editNewJobCardName(data, job, 'waste', success, failed)),
        fetch_jobCards      : ()    => dispatch(listJobCards()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Wastejobcard);
