import React from 'react'

import Sidebar from '../../common/sidebar'
import '../intranet/components/style.css'
// import { ACCOUNTS_FILES_FOLDER_GET, OHS_AND_S_FILE_ADD, OHS_AND_S_FOLDER_DELETE, INTRANET_FOLDER_EDIT,
//     ACCOUNTS_FOLDER_CREATE, OHS_AND_S_FILE_DELETE, INTRANET_FILES_RENAME
// } from '../../store/endpoint';
import Header from '../intranet/components/Header'

// import IntranetLayout from '../../common/filesNFolder/FileFoldersLayout'
import { connect } from 'react-redux';
import { team_leaveApply } from '../team/action';
import './style.css';
import { fetch_Employee_avilabilities } from '../scheduling/common/action';
import LeaveApplicantsList from './LeaveApplicantsList';
import { useState } from 'react';
import TimeSheetList from './TimeSheetList';

function AccountsIndexApp({ 
    permissions
 }) {

    const [tabVal, setTabVal] = useState(1)
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='main'/>
                
                <div className="accountsTableDiv">
                    <div className='accountsTableNavDiv'>
                        <div className={`accountsTableNavBtn ${tabVal ? 'accountsTableNavBtnActive' : ''}`}
                            onClick={()=> setTabVal(1) }
                        >Leave Applications</div>
                        <div className={`accountsTableNavBtn ${tabVal ? '' : 'accountsTableNavBtnActive'}`}
                            onClick={()=> setTabVal(0) }
                        >Time Sheets List</div>
                    </div>
                    {
                        tabVal ?
                        <LeaveApplicantsList />
                        :
                        <TimeSheetList/>
                    }
                                        
                </div>
                
                <div style={{ height:'1vh' }}></div>
                <div style={{ padding:'0 1%' }}>
                {/* <IntranetLayout APIURL={ACCOUNTS_FILES_FOLDER_GET} title={'Accounts'} 
                FILEEDIT={ permissions.accounts.edit ? INTRANET_FILES_RENAME : null}
                FOLDEREDIT={ permissions.accounts.edit ? INTRANET_FOLDER_EDIT : null} 
                FILEADD={ permissions.accounts.add ? OHS_AND_S_FILE_ADD : null} 
                FOLDERCREATE={ permissions.accounts.add ? ACCOUNTS_FOLDER_CREATE : null} 
                FOLDERDELETE={ permissions.accounts.delete ? OHS_AND_S_FOLDER_DELETE : null} 
                FILEDELETE={ permissions.accounts.delete ? OHS_AND_S_FILE_DELETE : null}
                /> */}
                </div>
                
            </div>
        </div>
    )
}

const mapStateToProps = (state) =>{
    const { permissions } = state.userLogin 
    const { initialLeaveList, leaveApplyListLoader, leaveListPage  } = state.team
    return { permissions, initialLeaveList, leaveApplyListLoader, leaveListPage }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        team_leaveApplyFx: (data, page, success, failed) => dispatch(team_leaveApply(data, page, success, failed)),
        fetch_availabilityEmp : (filter) => dispatch(fetch_Employee_avilabilities(filter))
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (AccountsIndexApp)
