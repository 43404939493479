import React, {useEffect, useState} from 'react';
import DateTimeInput from '../../../../common/DateTimeInput'
// import {connect} from 'react-redux'
// import Swal from 'sweetalert2'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { CLIENT_SINGLE_FETCH, SALES_ALL_CLIENTS_LIST } from '../../../../store/endpoint';
import Api from '../../../../store/api'
import { TextAreaLimiter } from '../../../../common/TextAreaLimiter';

function CustomerReport({ jobObj, modalType, tabType=null, preSelect=null, setCustomerReport, client_name, setClientName, clientId, setClientId, jobObjConnected,
    preSelectVal, //TypeofWaste, setTypeofWaste
    // clientList, addJob, editJob, verifyJobCard=null, user
  }) {

    
    const [compName, setCompName]           = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.company_name ? preSelect.company_name : preSelect.client_name : jobObj===null ? '' : jobObj.company_name ? jobObj.company_name : jobObj.client_name  )
    const [compAddr, setCompAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.company_address : jobObj===null ? '' : jobObj.company_address  )
    const [suburb, setSuburb]               = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.site_suburb : jobObj===null ? '' : jobObj.suburb   )
    const [contactNo, setContactNo]         = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.site_phone_no : jobObj===null ? '' : jobObj.contact_number )
    const [contactEmail, setContactEmail]   = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.invoice_email : jobObj===null ? '' : jobObj.contact_email_address)
    const [siteAddr, setSiteAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.site_address : jobObj===null ? '' : jobObj.job_site_address   )
        const [textAreaValid, setTextAreaValid] = useState({a:true, b:true, c:true})
    const [jobDate, setJobDate]             = useState(modalType!=='add' ? jobObj===null ? new Date() : jobObj.date : new Date())
    const [jobTechnician, setJobTechnician] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.technician : '')
    const [departDepot, setDepartDepot]     = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.dept_depot : '')
    const [departSite, setDepartSite]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.dept_site : '')
    const [arriveDepot, setArriveDepot]     = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.arrive_depot : '')
    const [arriveSite, setArriveSite]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.arrive_site : '')
    const [totalTime, setTotalTime]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.total_time : '')
    const [reasonForAtt, setReasonForAtt]   = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.reason_for_attendance : '')
    const [jobRefNo, setJobRefNo]           = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.job_ref_no : '')
    const [workPerformed, setWorkPerformed] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.work_performed : '')
    const [additionalInfo, setAdditionalInfo] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.additional_info : '')
    const [custUnavail, setCustUnavail]     = useState(modalType!=='add' ? jobObj===null ? false : jobObj.cust_unavailable : false)

    const [assetsTable, setAssetsTable]       = useState( modalType!=='add' ? jobObj===null ?
        [{no : 1, assets_id : '', part_used : '', part_quantity : ''}, 
        {no : 2, assets_id : '', part_used : '', part_quantity : ''}, 
        {no : 3, assets_id : '', part_used : '', part_quantity : ''}] :
    jobObj.assets_table
        :   [{no : 1, assets_id : '', part_used : '', part_quantity : ''}, 
            {no : 2, assets_id : '', part_used : '', part_quantity : ''}, 
            {no : 3, assets_id : '', part_used : '', part_quantity : ''}])
            
    const [allClientsList, setAllClientsList] = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)

    const addRem = (val) =>{
        if(val){
            setAssetsTable(data => [...data, {
                no              :   assetsTable.length+1,
                assets_id       :   '',
                part_used       :   '',
                part_quantity   :   ''
            }])
        }
        else{
            let newArr = assetsTable.filter((_,key) => key!==(assetsTable.length-1))
            setAssetsTable(newArr)
        }
    }

    


        useEffect(()=>{
            Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                res =>{
                    console.log(res)
                    setAllClientsLoader(false)
                    if(res.length>0){
                        setAllClientsList(res)
                        if((modalType==='edit' || modalType==='add') && clientId!==null && clientId){
                            Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
                                .then(response => {
                                    setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                    setClientName(response.client_name)
                                    setCompAddr(response.company_address)
                                    setSuburb(response.site_suburb)
                                    setSiteAddr(response.site_address)
                                    setContactNo(response.company_contact_number)
                                    setContactEmail(response.company_email)
                            })
                             
                        ; }
                    }else 
                        setAllClientsList([])
                }
                    
            )
        },[tabType, clientId, modalType, setClientName])
        
    const addJobCard = () =>{
        let data = {
            card_id               : '1',
            client                : clientId,               
            client_name           : client_name,
            company_name          : compName,
            company_address       : compAddr,
            suburb                : suburb,
            contact_number        : contactNo,
            contact_email_address : contactEmail,
            job_site_address      : siteAddr,
            job_date              : jobDate,
            technician            : jobTechnician,
            dept_depot            : departDepot,
            dept_site             : departSite,
            arrive_depot          : arriveDepot,
            arrive_site           : arriveSite,
            total_time            : totalTime,
            reason_for_attendance : reasonForAtt,
            job_ref_no            : jobRefNo,
            work_performed        : workPerformed,
            additional_info       : additionalInfo,
            cust_unavailable      : custUnavail,
            assets_table          : assetsTable,
        };
        setCustomerReport(data)
    }

    return (
        <div className="jobCardDiv1">
            <div className="jobCardDivHead" >Customer Report</div>
            <div className="jobCardDivInpContent">
                    <div className="jobCardInputDivSec" >
                        <div>Date</div>
                        <div>
                            <span>:</span>
                            <DateTimeInput
                            dateSetFn={setJobDate}
                            dateValue={jobDate}
                            />
                    {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                        </div>
                    </div>

                    <div className="jobCardInputDivSec" >
                        <div>Job No. /Ref. No.</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobRefNo}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Job No. / Ref. No." value={jobRefNo} onChange={(e)=>setJobRefNo(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit' || preSelectVal!==null   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compName}</p>   :
                            <select className="jobCardDivInp" value={clientId} 
                            style={{ pointerEvents: preSelectVal===null ? 'auto' : 'none',
                            cursor: preSelectVal===null ? 'pointer' : 'default' }}
                            onClick={()=>{ setAllClientsLoader(true);
                                Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                                    res =>{
                                        // console.log("DATAAAAA",res)
                                        setAllClientsLoader(false)
                                        if(res.length>0)
                                            setAllClientsList(res)
                                        else 
                                            setAllClientsList([])
                                    }
                                        
                                )
                            }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                setClientId(e.target.value);
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                                        setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                        setClientName(response.client_name)
                                        setCompAddr(response.company_address)
                                        setSuburb(response.site_suburb)
                                        setSiteAddr(response.site_address)
                                        setContactNo(response.company_contact_number)
                                        setContactEmail(response.company_email)
                                })
                                 
                            ; }
                                 }}>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    {/* <div className="jobCardDivInpContentDiv">
                        <div>Type of Waste</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={TypeofWaste} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                                 onChange={val =>{ setTypeofWaste(val.target.value.toUpperCase());  }}>
                                    <option value='' >Select Option</option>
                                    <option value='AMMONIA'>AMMONIA</option>
                                    <option value='DILUTION PIT'>DILUTION PIT</option>
                                    <option value='DRUMS'>DRUMS</option>
                                    <option value='FOOD WASTE'>FOOD WASTE</option>
                                    <option value='GERNIE WASHES'>GERNIE WASHES</option>
                                    <option value='GREASE'>GREASE</option>
                                    <option value='GROUND WATER'>GROUND WATER</option>
                                    <option value='J120'>J120</option> 
                                    <option value='LEVY'>LEVY</option>
                                    <option value='M250'>M250</option>
                                    <option value='N205'>N205</option> 
                                    <option value='OIL SEPERATOR'>OIL SEPERATOR</option>
                                    <option value='PACKAGED WASTE'>PACKAGED WASTE</option>
                                    <option value='PRODUCT DESTRUCTION'>PRODUCT DESTRUCTION</option>
                                    <option value='PUMP SERVICE'>PUMP SERVICE</option>
                                    <option value='RETENTION PIT'>RETENTION PIT</option>
                                    <option value='SEWER'>SEWER</option>
                                    <option value='STORMWATER'>STORMWATER</option>
                                    <option value='TANK WASH OUT'>TANK WASH OUT</option>      
                                    <option value='TRADE WASTE'>TRADE WASTE</option>                                              
                                    <option value='TRANSPORT'>TRANSPORT</option>                            
                                    <option value='Z140'>Z140</option>
                                    <option value='WIEGH BRIDGE'>WEIGH BRIDGE</option>                                    
                                    <option value='DESTRUCTION CERTIFICATE'>DESTRUCTION CERTIFICATE</option>
                                    <option value='NOT APPLICABLE'>NOT APPLICABLE</option>                                
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                        </div>
                        
                    </div>
                     */}
                    <div className="jobCardInputDivSec" >
                        <div>Company Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compAddr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Company Address" value={compAddr} onChange={(e)=>setCompAddr(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Suburb</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suburb}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Suburb" value={suburb} onChange={(e)=>setSuburb(e.target.value)}/>
                            }
                        </div>
                    </div>
                    
                    <div className="jobCardInputDivSec" >
                        <div>Site Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteAddr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Site Address" value={siteAddr} onChange={(e)=>setSiteAddr(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Contact Number</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{contactNo}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Contact Number" value={contactNo} onChange={(e)=>setContactNo(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Contact Email Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{contactEmail}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Contact Email Address" value={contactEmail} onChange={(e)=>setContactEmail(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Technician</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Technician" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                            }
                        </div>
                    </div>

                    <div className="jobCardInputDivSec" >
                        <div>Depart Depot</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{departDepot}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Depart Depot" value={departDepot} onChange={(e)=>setDepartDepot(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Depart Site</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{departSite}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Depart Site" value={departSite} onChange={(e)=>setDepartSite(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Arrive Depot</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{arriveDepot}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Arrive Depot" value={arriveDepot} onChange={(e)=>setArriveDepot(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Arrive Site</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{arriveSite}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Arrive Site" value={arriveSite} onChange={(e)=>setArriveSite(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Total Time</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{totalTime}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Total Time" value={totalTime} onChange={(e)=>setTotalTime(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardTextAreaDiv">
                        <div><span>Reason for Attendance</span></div>
                        <div>
                        {   modalType==='view'   ?   
                            <p>{reasonForAtt}</p>   :
                            <TextAreaLimiter isValid={(e) => setTextAreaValid({...textAreaValid, a : e})}
                        configs={{
                            rows : '10', className : "jobCardTextArea", placeholder : "Reason for attendance", value : reasonForAtt,
                             onChange : (e)=>setReasonForAtt(e.target.value)
                        }}
                        v={reasonForAtt} />
                        }
                        </div>
                    </div>
                    <div className="jobCardTextAreaDiv">
                        <div><span>Work Performed</span></div>
                        <div>
                        {   modalType==='view'   ?   
                            <p>{workPerformed}</p>   :
                            <TextAreaLimiter isValid={(e) => setTextAreaValid({...textAreaValid, b : e})}
                        configs={{
                            rows : '10', className : "jobCardTextArea",
                            placeholder : "Work Performed", value : workPerformed,
                            onChange : (e)=>setWorkPerformed(e.target.value)
                        }}
                        v={workPerformed} />
                        
                        }
                        </div>
                    </div>

                    <div className="jobCardDiv3">
                        <div className="jobCardDivTableContent jobCardTableStyle">
                            <div className="jobCardDivTHead">
                                <div style={{ width:'20px' }}>Sl.No</div>
                                <div>Asset ID</div>
                                <div>Part Used</div>
                                <div>Part Quantity</div>
                            </div>
                            {   assetsTable.map((item, key) => { 
                            return(
                                <div className="jobCardDivTHead">
                                    <div style={{ width:'20px' }}>{key+1}</div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Asset ID"  disabled={modalType==='view' }
                                         value={item.assets_id} onChange={(e)=>{ 
                                            let temp = {...item, assets_id : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text" className="jobCardDivInp" placeholder="Part Used"  disabled={modalType==='view' }
                                         value={item.part_used} onChange={(e)=>{ 
                                            let temp = {...item, part_used : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Part Quantity"  disabled={modalType==='view' }
                                         value={item.part_quantity} onChange={(e)=>{ 
                                            let temp = {...item, part_quantity : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    
                                </div>)
                    })
                    }
                        {   modalType==='view'  ?   null    :
                        <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'flex-end', alignItems:'center', margin:'20px 0' }}>
                            <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(true)} ><AddCircleOutline style={{ fontSize:'16px' }}/> Add Row</div>
                            {   assetsTable.length>3 ?
                                <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(false)}><RemoveCircleOutline style={{ fontSize:'16px' }}/> Remove Row</div>  :   null
                            }
                            </div>
                        }
                        </div>
                    </div>

                    <div className="jobCardTextAreaDiv">
                        <div><span>Additional Information</span></div>
                        <div>
                        {   modalType==='view'   ?   
                            <p>{additionalInfo}</p>   :
                            
                        <TextAreaLimiter isValid={(e) => setTextAreaValid({...textAreaValid, c : e})}
                        configs={{
                            rows:'10', className:"jobCardTextArea", placeholder:"Additional Information", 
                            value : additionalInfo, onChange : (e)=>setAdditionalInfo(e.target.value)
                        }}
                        v={additionalInfo} />
                        }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" style={{ width:'50%', flexDirection:'row-reverse' }} >
                        <div >Customer Unavailable</div>
                        <div style={{ width:'20%' }} >
                            <input type="checkbox" className="jobCardDivInp" style={{ height:'1.5vh' }} disabled={modalType==='view'? true : false}
                             checked={custUnavail} onChange={()=>setCustUnavail(!custUnavail)} />
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Customer Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{client_name}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Customer Name" value={client_name} onChange={(e)=>setClientName(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Technician Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Technician Name" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                            }
                        </div>
                    </div>
                   
                    
                </div>
                {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                <div 
                className={ clientId && textAreaValid.a && textAreaValid.b && textAreaValid.c ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else if(!jobObjConnected)
                        addJobCard()
                    }} 
                  style={{ minWidth:'15vw', minHeight:'5vh', textAlign:'center', height:'auto', width:'auto' }}>
                {modalType==='add' ? 'Add Job Card to Collection' : modalType==='verify' ? jobObjConnected ?
                `This job card is already in use `
                :   'Update Job Card (Deselect the red checkbox to remove from collection)' :
                 'Update Job Card ( To remove from collection, deselect the red checkbox)'} 
                </div>
            </div>
            :   null
            }
            
        </div>
    );
}

export default CustomerReport;