import React from 'react'
// import { Detail, Sale, Works, Stock } from './client_details'
import Detail from './Details'
import './singleclientstyle.css'
import ClientFileChart from './client-file-chart'

function ClientDetail({tabType}) {
    return (
        <div className="client-add-layout">
            <div className="add-client-left">
                <div className="add-client-left-top">
                  {/* <p className="add-client-heading">12 June 2020</p> */}
                </div>
                <div className="add-client-left-center-main">                    
                      <Detail  tabType={tabType}/>                  
                </div>                                                      
            </div>

            <div className="add-client-right">
                <ClientFileChart  tabType={tabType}/>
            </div>
        </div>
    )
}

export default ClientDetail
