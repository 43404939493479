import moment from "moment"

let initialState = {
    timesheetList : [],
    timeSheetListPage : 1,
    timeSheetListLoader : true,

    timeSheetLogList : [],
    timeSheetLogLoader : true,
    stopPaging      :   false,
    
    initialEmpTimeSheet: [],
    initialEmpTimeSheetObj: null,
    empTimeSheetLoader: false,
    initialEmpTimeSheetId: null,
    filterDate: new Date(),
    
}

const accountsData = (state = initialState, actions) =>{
    switch (actions.type) {
        case "TIMESHEETLISTLOADER_UPDATE":
            return{
                ...state,
                timeSheetListLoader : actions.loader
            }
        case "TIMESHEETACCESSLOADER_UPDATE":
            return{
                ...state,
                timeSheetLogLoader : actions.loader
            }
        case "TIMESHEETLIST_UPDATE" : 
            let newTimeSheetList = actions.page===1 ? actions.data : [...state.timesheetList, ...actions.data]
            return {
                ...state,
                timesheetList : newTimeSheetList,
                timeSheetListPage : actions.page,
                timeSheetListLoader : false,
                stopPaging  :   actions.data < 20 ? true : false
            }
        case "CLEAR_TIMESHEET" : 
            return{
                ...state,
                initialEmpTimeSheet : [],
                initialEmpTimeSheetId : null,
                empTimeSheetLoader: false,
                filterDate: new Date(),
            }

        case "INIT_EMPLOYEE_TIMESHEET" :
            let dummyTimeSheet = actions.timeSheet && typeof actions.timeSheet!=='undefined' ? 
            actions.timeSheet :
            Object.assign({}, { employee_id		       : actions.userId,
                comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
                whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                week			              :	[
                { annual		: 0, date		: moment(actions.filterDate).format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },

                { annual		: 0, date		: moment(actions.filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                
                  ]
            })
            console.log('ACTIONSTIMESHEET=>',actions.timeSheet,dummyTimeSheet,actions.timeSheet && typeof actions.timeSheet!=='undefined')
            return {
                ...state,
                initialEmpTimeSheet: dummyTimeSheet,
                filterDate      : actions.filterDate,
                empTimeSheetLoader : true,
                initialEmpTimeSheetId : actions.timeSheet && typeof actions.timeSheet!=='undefined' ? actions.timeSheet.id : null
            }

        case "UPDATE_EMPLOYEE_TIMESHEET" :
           
            return {
                ...state,
                initialEmpTimeSheet : actions.timeSheet
            }

        case "REMOVE_TIMESHEET" : 
            let oldList = state.timesheetList
            let newList = oldList.filter(item => item.id !== actions.id)
            return {
                ...state,
                timesheetList : newList
            }
        
        case "TIMESHEET_ACCESS_LOG" : 
            return {
                ...state,
                timeSheetLogList : actions.page===1 ? actions.timeSheet : [...state.timeSheetLogList, ...actions.timeSheet],
                timeSheetLogLoader : false
            }
        default:
            return state
    }
}

export default accountsData