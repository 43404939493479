import { HOME_GET_ALL, HOME_WEATHER } from '../../store/endpoint'
import Api from '../../store/api'

export const fetch_home_get = (type, yr,pageNo) =>{
    return function (dispatch) {
        dispatch({
            type : 'UPDATE_HOMELOADING',
            loadStatus : true
        })
        let get_url = `${HOME_GET_ALL}${type}/${yr}/${pageNo}/?limit=6`
        Api('get', get_url).then(response => {
            console.log('HOMEGETAPI',response)
            dispatch({ type: 'HOME_GET_API', home: response })
        })
    }
}


export const fetch_home_weather = () =>{
    return function (dispatch) {
        Api('get', HOME_WEATHER).then(response => {
            dispatch({ type: 'HOME_WEATHER_API', weather: response })
        })
    }
}
