import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Scrollbar from '../../../common/ImprovedScrollBar.jsx';
import moment from 'moment';
import { SALES_QUOTE_HOSTORY } from '../../../store/endpoint';
import Api from '../../../store/api'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    quoteHistoryInfos,
    setQuoteHistoryInfos,
    quoteModalAction,
    quoteId
}) {

    const classes = useStyles();

    const handleClose = () => {
        modalAction(false)
        if(quoteModalAction) {
            quoteModalAction(true)
        }
    };

    const fetch_quote_history = () => {

        
        Api('get',`${SALES_QUOTE_HOSTORY}${quoteId}/`).then(res => {
            console.log(res)
            if(res.length > 0) {
                setQuoteHistoryInfos([...quoteHistoryInfos, ...res])
            }
            
        })
    }

    return (
      <Modal
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        // disableBackdropClick={jobCardModal}
        // onBackdropClick={()=> { handleClose() }}
      >
        <div style={{ height: '51vh', width: '50vw', padding: 20, backgroundColor: '#fff', outline: 'none', borderRadius: 20 }}>
            <h1>Quote History</h1>
            <Scrollbar style={{ height: '47vh', marginTop: 20 }} 
            onScrollFrame={fetch_quote_history}
            >
                {
                    quoteHistoryInfos && quoteHistoryInfos.map((item, key) => {
                        const { message, date, time } = item
                        return (
                            <div
                                key={key}
                                style={{
                                    backgroundColor: '#cdcdcd', 
                                    borderRadius: 20, 
                                    marginBottom: 10,
                                    padding: 20
                                }}
                            >
                                <div style={{  
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center', 
                                }} >
                                    <p>{message}</p>
                                </div>
                                
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 12 }} >
                                    <p>{moment(date).isValid() ? moment(date).format('DD-MM-YYYY') : date}</p>
                                    <p>{time}</p>
                                </div>
                               
                            </div>
                            
                        )
                    })
                }
            </Scrollbar>
        </div>
    </Modal>
  );
}


 export default TransitionsModal
 
