import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import VehicleModal from './VehicleModal'
import VehicleImageModal from './VehicleImageModal'
// import { takeAllPrint } from '../../../client/action'

function VehicleNavBar({ 
    page, 
    modal_status, 
    modal_action, 
    img_modal_status, 
    img_modal_action, 
    select_vehicle, 
    selected_vehicle, 
    edit_vehicle, 
    create_vehicle,
    // response_page,
    tabType,
    permAdd,
}) {

    const { path } = useRouteMatch()

    const RenderReportButtons = () => {
        // if(page === 'truck') {
            return (
                <div className="vech-navigation-main-layout">
                    {   permAdd ?
                    <button 
                        onClick={() => {
                            select_vehicle({})
                            modal_action(true)
                        }} 
                        className="vechile-details-btn-add">Add New Vehicle</button>    :   null
                    }
                    {/* <button onClick={()=> takeAllPrint(page === 'forklift' ? 'fork-lift' : page,tabType) }
                     className="vechile-details-btn-add">Print All</button> */}
                    <Link to={`/vehicle/${page}/preinspection`} className="vechile-details-btn">Pre Inspection Check</Link>
                    <Link to={`/vehicle/${page}/maintanace`} className="vechile-details-btn">Maintanance Report</Link>
                    <Link to={`/vehicle/${page}/fuel-exp`} className="vechile-details-btn">Fuel Expense</Link>
                </div>
            )
        // } else if(page === 'car') {
        //     return (
        //         <div className="vech-navigation-main-layout">
        //             <button 
        //                 onClick={() => {
        //                     select_vehicle({})
        //                     modal_action(true)
        //                 }} 
        //                 className="vechile-details-btn-add">Add New Vehicle</button>
        //             <Link to="/vehicle/car/preinspection" className="vechile-details-btn">Pre Inspection Check</Link>
        //             <Link to="/vehicle/car/maintanace" className="vechile-details-btn">Maintanance Report</Link>
        //             <Link to="/vehicle/car/fuel-exp" className="vechile-details-btn">Fuel Expense</Link>
        //         </div>
        //     )
        // } else {
        //     return (
        //         <div className="vech-navigation-main-layout">
        //             <button 
        //                 onClick={() => {
        //                     select_vehicle({})
        //                     modal_action(true)
        //                 }} 
        //                 className="vechile-details-btn-add">Add New Vehicle</button>
        //             <Link to="/vehicle/forklift/preinspection" className="vechile-details-btn">Pre Inspection Check</Link>
        //             <Link to="/vehicle/forklift/maintanace" className="vechile-details-btn">Maintanance Report</Link>
        //             <Link to="/vehicle/forklift/fuel-exp" className="vechile-details-btn">Fuel Expense</Link>
        //         </div>
        //     )
        // }
        
    }
    return (
        <div className="vech-navigation-bar" >
            {   tabType === 'waste' || tabType === 'all' ?
            <div className="vech-navigation-main-layout"> 
                <Link 
                    to="/vehicle/truck"
                    className={path === '/vehicle/truck' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Master-Trucks</Link>
                <Link 
                    to="/vehicle/car"
                    className={path === '/vehicle/car' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Master-Cars</Link>
                <Link 
                    to="/vehicle/forklift"
                    className={path === '/vehicle/forklift' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Semi Trailers/Others</Link>
            </div>
            :   tabType === 'pumps' ?
            <div className="vech-navigation-main-layout"> 
                <Link 
                    to="/vehicle/truck"
                    className={path === '/vehicle/truck' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Master-Trucks</Link>
                <Link 
                    to="/vehicle/utes"
                    className={path === '/vehicle/utes' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Master-Utes</Link>
                <Link 
                    to="/vehicle/forklift"
                    className={path === '/vehicle/forklift' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Semi Trailers/Others</Link>
            </div>
            :   tabType === 'hills' ?
            <div className="vech-navigation-main-layout"> 
                <Link 
                    to="/vehicle/machinery"
                    className={path === '/vehicle/machinery' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Master-Machinery</Link>
            </div>
            :
            <div className="vech-navigation-main-layout"> 
                <Link 
                    to="/vehicle/forklift"
                    className={path === '/vehicle/forklift' ? 'siv-vech-select-btn siv-vech-select-btn-active' : 'siv-vech-select-btn'} 
                >Semi Trailers/Others</Link>
            </div>
            }
            <RenderReportButtons />
            <VehicleModal 
                page={page}
                modalStatus={modal_status} 
                modalAction={modal_action} 
                selected_vehicle={selected_vehicle}
                edit_vehicle={edit_vehicle}
                create_vehicle={create_vehicle}
                // response_page={response_page}
                tabType={tabType}
            />
            <VehicleImageModal 
                page={page}
                modalStatus={img_modal_status} 
                modalAction={img_modal_action} 
                selected_vehicle={selected_vehicle}
                edit_vehicle={edit_vehicle}
                // response_page={response_page}
                tabType={tabType}

            />
        </div>
    )
}


export default VehicleNavBar
