import React from 'react';
import DateTimeInput from '../../../../../common/DateTimeInput'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchTab from '../../common/SearchTab'
import { Search } from '@material-ui/icons'
import { fetch_fuel_list, fetch_all_forklift_list, __vehicle_modal, __select_fuel, filter_fuel_list, fuelListCSVPrint } from '../common/action'
import { connect } from 'react-redux';
import moment from 'moment';


function Navbar ({
    fetch_fuels, 
        fetch_all_trucks,
        vehicle_modal_action,
        select_fuel,
        tabType,
    all_forklift_list,
    fetch_filter_forklift,
    filterKey,
    clearFilterDate,
    setClearFilterDate,
    
    filterFromDate, setFilterFromDate,
    filterToDate, setFilterToDate,
    invokeDate, setInvokeDate,
    fuel_search_qry
}) {

    // const [clearFilterDate, setClearFilterDate] = useState(false)
    // const [filterDate, setFilterDate] = useState(new Date())
    // const [invokeDate, setInvokeDate]   =   useState(false)

    const useStyles = makeStyles((theme) => ({
        formControl: {
          minWidth: 120,
          maxHeight: 20,
          marginLeft: 10,
          marginTop: -8,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
        resize:{
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }
      }));

    const failedFilter = () =>{
        fetch_fuels(tabType, 1)
    }

    const SortDropdown = () => {
        const classes = useStyles();
        return (
            <FormControl className={classes.formControl} style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around' }}>
                <div>Filter</div> 
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14 }}
                    value={filterKey}
                        className={classes.resize}
                        style={{ color:'black' }}
                        onChange={(val) => {
                            
                            
                            if(val.target.value!=='all'){
                                let postData = new FormData()
                                postData.append('registration',val.target.value)
                                postData.append('vehicle_type', tabType==='hills' ? 'machinery' : 'fork-lift')
                                fetch_filter_forklift(tabType, null, failedFilter, postData, 'vehicle', val.target.value,1,'filter')
                                setInvokeDate(false); setClearFilterDate(false);
                            }else{
                                fetch_filter_forklift( tabType, null, null, null, 'all', 'all',1,'filter')
                                fetch_fuels(tabType, 1)
                            }
                        }}
                    >
                    
                    <MenuItem value={'all'}>All</MenuItem>
                    {   
                        all_forklift_list && all_forklift_list.map((item,key)=>{
                            
                            return(
                                <MenuItem key={key} value={item.registration}>{item.registration}</MenuItem>
                            )
                        })
                    }
                                        
                    </Select>
                </FormControl>
        )
    }

    const FromDateFilterFn = (date) => {
        let obj = { val : date, status : true}
        setFilterFromDate(obj)
        let date_time = date
        let postData = new FormData()
        postData.append('from_date', date_time)
        postData.append('to_date',filterToDate.val)
        postData.append('vehicle_type', tabType==='hills' ? 'machinery' : 'fork-lift')
        postData.append('tab_type', tabType)
        if(filterToDate.status)
            fetch_filter_forklift( tabType, null, failedFilter, postData, 'day', 'all',1,'filter')
        setClearFilterDate(true)
    }
    const ToDateFilterFn = (date) => {
        let obj = { val : date, status : true}
        setFilterToDate(obj)
        let date_time = date
        let postData = new FormData()
        postData.append('from_date', filterFromDate.val)
        postData.append('to_date', date_time)
        postData.append('vehicle_type', tabType==='hills' ? 'machinery' : 'fork-lift')
        postData.append('tab_type', tabType)
        if(filterFromDate.status)
            fetch_filter_forklift( tabType, null, failedFilter, postData, 'day', 'all',1,'filter')
        setClearFilterDate(true)
    }


    return (
        <div className="sgr-mntnce-rprt-topbar">
                    <button className="sgr-mntnce-rprt-topbarBtn2">Master - { tabType==='hills' ? 'Machinery' : 'Semi Trailers/Others'}</button>
                    <button className="sgr-mntnce-rprt-topbarBtn2">Fuel Exp</button>
                    <button 
                        onClick={() => {
                            select_fuel({})
                            vehicle_modal_action(true)
                        }} 
                        className="sgr-mntnce-rprt-addNew"
                    >ADD NEW</button>
                    <div className="sgr-mntnce-rprt-search-layout" style={{ width : '200px' }}>
                <SearchTab
                    tabType={tabType} vType={tabType==='hills' ? 'machinery' : 'fork-lift'} subType={'fuel'}
                />
                    <Search style={{ fontSize: 20 }} className="search-icon" />
            </div>
                    <div className="sgr-mntnce-rprt-addNew2" style={{ display:'flex', flexDirection:'row',
                     alignItems:'center', justifyContent:'space-around', height:30, paddingTop:'10px', }}>
                        <SortDropdown />
                    </div>
                    <div className="sgr-mntnce-rprt-addNew2" style={{ display:'flex', flexDirection:'row',
                     alignItems:'center', justifyContent:'space-around', height:40, paddingLeft:'30px' }}>
                        <div style={{ padding: '0 20px', display: 'flex', alignItems: 'center', width: invokeDate ? '25rem' : 'auto' }}>
                        <div>Filter : </div>
                        {
                            invokeDate ?
                                <div style={{ display : 'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'90%' }}>
                                    <span style={{ marginLeft:'1%' }}>From :</span> 
                                    <DateTimeInput dateValue={filterFromDate.val} clsName={'sgr-fuel-date-input'} rangeDateMax={filterToDate.status ? moment(filterToDate.val).format('YYYY-MM-DD') : null} dateSetFn={FromDateFilterFn} />
                                    <span style={{ paddingLeft:'1%', borderLeft:'.2px solid grey' }}>
                                    To :
                                    </span>
                                    <DateTimeInput clsName={'sgr-fuel-date-input'} dateValue={filterToDate.val}  rangeDate={filterFromDate.status ? moment(filterFromDate.val).format('YYYY-MM-DD') : null} dateSetFn={ToDateFilterFn} />
                                </div>
                            :
                                <span style={{ padding: '0 20px', display: 'flex', alignItems: 'center', borderRadius: '30px' }}
                                    onClick={() => { setInvokeDate(true) }}>
                                    Select Date
                                </span>
                        }
                    </div>
                        {/* <input type="date" className="sgr-fuel-date-input" /> */}
                    </div>
                    {
                        clearFilterDate || invokeDate ?   
                        <button
                        onClick={()=>{ setInvokeDate(false); setClearFilterDate(false); fetch_fuels(tabType, 1);}}
                         className="sgr-mntnce-rprt-addNew">Revert</button>
                        :   null
                    }
                    
                    <button onClick={()=> fuelListCSVPrint(tabType, filterKey, fuel_search_qry,
            clearFilterDate || invokeDate ? filterFromDate.val : null,
            clearFilterDate || invokeDate ? filterToDate.val : null) }
                        className="sgr-mntnce-rprt-addNew">EXPORT REPORT</button>
                    
                </div>
    );
}


const mapStateToProps = (state) => {
    const { forklift_fuel_list, forklift_fuel_page, all_forklift_list, filterKey, fuel_search_qry } = state.vehicle__forklift
    return { forklift_fuel_list, forklift_fuel_page, all_forklift_list, filterKey, fuel_search_qry }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_fuels: (tabType, page) => dispatch(fetch_fuel_list(tabType, page)),
        fetch_all_trucks: (tabType) => dispatch(fetch_all_forklift_list(tabType)),
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        select_fuel: (fuel) => dispatch(__select_fuel(fuel)),
        fetch_filter_forklift: (tabType, success, failed, data, type, id, page,fetchtype) => dispatch(filter_fuel_list(tabType, success, failed, data, type, id, page,fetchtype))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (Navbar);
