export const cl_black = '#000'
export const cl_white = '#fff'
export const cl_grey = '#707070'
export const cl_blue = '#0F99D0'
export const cl_green = '#13CD6A'
export const cl_brown = '#774835'
export const cl_red = '#FF0000'
export const cl_magenta = '#CD1396'
export const cl_orange = '#FF8A23'
export const cl_dark_orange = '#EF871F'
export const cl_light_grey = '#F5F5F5'
export const cl_light_ivery = '#F5F7F7'
export const cl_ivery = '#E6E6E6'

export const sidebar_bgc = '#2B2C30'
export const body_bgc = '#F7F8FA'
