import React, { useState } from 'react'
import { connect } from 'react-redux'
import {get_safety, update_safety} from '../common/action'

import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function EditSafetyData ({ safetyData, fetch_data, update_data, modalStatus, permissions,
    modalAction,
    createType
 }) {

    
    // useEffect(() => {
    //     fetch_data()
    // }, []);

    console.log('SAFETY:', safetyData)
    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };

    const [ltiMTD, setLtiMTD] = useState(safetyData!==null ? safetyData.lti_mtd:'')
    const [mtiMTD, setMtiMTD] = useState(safetyData!==null ? safetyData.mti_mtd:'')
    const [ftiMTD, setFtiMTD] = useState(safetyData!==null ? safetyData.fti_mtd:'')
    const [atFaultMTD, setAtFaultMTD] = useState( safetyData!==null ? safetyData.atFault_mtd : '')
    const [notFaultMTD, setNotFaultMTD] = useState( safetyData!==null ? safetyData.notFault_mtd : '')

    const [ltiYTD, setLtiYTD] = useState(safetyData!==null ? safetyData.lti_ytd:'')
    const [mtiYTD, setMtiYTD] = useState(safetyData!==null ? safetyData.mti_ytd:'')
    const [ftiYTD, setFtiYTD] = useState(safetyData!==null ? safetyData.fti_ytd:'')
    const [atFaultYTD, setAtFaultYTD] = useState( safetyData!==null ? safetyData.atFault_ytd : '')
    const [notFaultYTD, setNotFaultYTD] = useState( safetyData!==null ? safetyData.notFault_ytd : '')


    
    const update_safetyData = () =>{
        
            let postData = new FormData()
            postData.append('lti_mtd',ltiMTD)
            postData.append('lti_ytd',ltiYTD)
            postData.append('mti_mtd',mtiMTD)
            postData.append('mti_ytd',mtiYTD)
            postData.append('fti_mtd',ftiMTD)
            postData.append('fti_ytd',ftiYTD)
            postData.append('atFault_mtd',atFaultMTD)
            postData.append('atFault_ytd',atFaultYTD)
            postData.append('notFault_mtd',notFaultMTD)
            postData.append('notFault_ytd',notFaultYTD)
            const success = () => {
                
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Safety Data editted !',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
    
            const failed = () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            update_data(postData, success, failed)
            handleClose()
            fetch_data()
            
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >
            <div className="intranet-folder-modal-layout" style={{ overflowY:'auto' }}>
                <div className="intranet-modal-title-layout">
                    
                        <p>{createType}</p>
                    
                    
                    <Close onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </div>
                
                <div className="ohs-saftey-body-layout">
                <div className="ohs-safety-tab">
                    <div className="ohs-safety-tabRow">
                        <div className="ohs-safety-tabHead">People</div>
                        <div className="ohs-safety-tabHead ohs-safety-right">MTD</div>
                        <div className="ohs-safety-tabHead ohs-safety-right">YTD</div>
                    </div>
                    {   safetyData ?
                    <div className="ohs-safety-tabContent">
                        <div className="ohs-safety-tabRow">
                            <div className="ohs-safety-left">LTI</div>
                                <div><input type="number" min='0' className="ohs-safety-right safetyDataInp" value={ltiMTD} onChange={(v)=>setLtiMTD(v.target.value)} placeholder="Enter LTI" /></div>
                                <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={ltiYTD} onChange={(v)=>setLtiYTD(v.target.value)} placeholder="Enter LTI" /></div>              
                        </div>
                        <div className="ohs-safety-tabRow">
                            <div className="ohs-safety-left">MTD</div>
                            
                            <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={mtiMTD} onChange={(v)=>setMtiMTD(v.target.value)} placeholder="Enter MTI" /></div>
                            <div><input type="number" min='0'  className="ohs-safety-right safetyDataInp" value={mtiYTD} onChange={(v)=>setMtiYTD(v.target.value)} placeholder="Enter MTI" /></div>
                            
                        </div>
                        <div className="ohs-safety-tabRow">
                            <div className="ohs-safety-left">FTI</div>
                            
                            <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={ftiMTD} onChange={(v)=>setFtiMTD(v.target.value)} placeholder="Enter FTI" /></div> 
                            <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={ftiYTD} onChange={(v)=>setFtiYTD(v.target.value)} placeholder="Enter FTI" /></div>
                            
                        </div>
                    </div>
                    :   null
                    }
                </div>
                <div className="ohs-safety-tab">
                    <div className="ohs-safety-tabHead">Vehicle</div>
                    {   safetyData ?
                    <div className="ohs-safety-tabContent">
                        <div className="ohs-safety-tabRow">
                            <div className="ohs-safety-left">At Fault</div>
                            
                                    <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={atFaultMTD} onChange={(v)=>setAtFaultMTD(v.target.value)} placeholder="Enter At Fault" /></div>   
                                    <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={atFaultYTD} onChange={(v)=>setAtFaultYTD(v.target.value)} placeholder="Enter At Fault"/></div>
                            
                        </div>
                        <div className="ohs-safety-tabRow">
                            <div className="ohs-safety-left">Not a Fault</div>
                            
                                    <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={notFaultMTD} onChange={(v)=>setNotFaultMTD(v.target.value)} placeholder="Enter Not At Fault" /></div>   
                                    <div><input type="number" min='0' className="ohs-safety-right safetyDataInp"  value={notFaultYTD} onChange={(v)=>setNotFaultYTD(v.target.value)} placeholder="Enter Not At Fault" /></div>
                            
                        </div>
                    </div>
                    :   null
                    }
                </div>
            </div>
            <div className="intranet-btn-layout">
                <button onClick={() => handleClose()} className="intranet-cancel-btn">CANCEL</button>
                {
                    permissions.ohs.edit ?
                <button 
                    onClick={() => update_safetyData() }
                    className="intranet-create-btn">DONE</button>   :   null
                }
            </div>
            </div>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { safetyData } = state.ohs
    const { permissions } = state.userLogin
    return { safetyData, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return{
        fetch_data : () => dispatch( get_safety() ),
        update_data: (data, success, failed) => dispatch( update_safety( data, success, failed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSafetyData)

