import React, {useState, useEffect, Suspense, useRef} from 'react';
import { connect } from 'react-redux';
import Scrollview from '../../../../common/ImprovedScrollBar.jsx';
import { CircularProgress, Tooltip } from '@material-ui/core'
import NoDatasScreen from '../../../../common/no_datas'
import moment from 'moment'
import { AnnouncementRounded, Edit, 
    //InfoOutlined, 
    //ReportProblemRounded, 
    StreetviewRounded } from '@material-ui/icons'
import { //SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER, 
    SCHEDULE_ADDITIONAL_VEHICLE, SCHEDULE_QUOTE_INFOS_NEW } from '../../../../store/endpoint'
import Api from '../../../../store/api'
import JobModal from '../jobModalB'
import QuoteModal from '../../../../common/CommonQuoteDetailModal'
import Swal from 'sweetalert2'
import QuoteHistoryModalView from '../quoteHistoryModal'
import EnviroLogoLoader from '../../../../assets/Images/eniviro_logo.gif'

import { // editAfterCompleteElements, add_team_to_job, add_vehicle_to_job, 
    fetch_Employee_avilabilities, fetch_job_list_readyForSchd, fetch_OutSourced_schedules,
    fetch_OutSourced_schedules_sorted, fetch_Vehicle_avilabilities, schedule_job_delete, schedule_job_edit, 
    //fetch_schedules_sorted, fetch_job_pending_schd, fetch_job_reoccur_schd, schedule_image_add, schedule_imageSign_add, schedule_video_add,
} from '../../common/action'
import { sales_job_view } from '../../../jobs/action'
import { ScheduleBoxShimmer, SchedulelistShimmer, SchedulelistVehicleshimmer } from '../ScheduleListShimmer';
import { HOME_LATITUDE, HOME_LONGITUDE } from '../../../../store/config';
// import { IsVideoFileUpload } from '../../../../common/VideoFilePreview';

const StreetViewModal = React.lazy(() => import ('../StreetViewModal.jsx'));

const Schedulecontent = ({ screen_type,
    schedulesOut_list,
    // schedulesOut_page,
    fetch_schedule_list,
    fetch_filterd_schedules,
    schedule_edit_job,
    delete_schedule,
    stopOutLoading,
    fetch_jobs,
    rldSchd,
    savedJobDataFx,
    sales_job_viewFx,
    fetch_availabilityEmp,
    fetch_availabilityVeh,
    scheduleLoader, setScheduleLoader,
    //invokeDateFrom, invokeDateTo, 
    fromDate, toDate, zoomVal,
    maximize,
    zoomWidth, success_sch_fx,
    setStartDate, StartDate,
    setStartTime, StartTime,
    setEndTime, EndTime,
    setEndDate, EndDate,
    setScheduleStatus, schduleStatus,
    setSelectedJob, selectedJob, 
    // container_width,
    // setFromFilterDate,
    // setToFilterDate,
    filterLoader,
    jobTab,
    weekDaysArr, setWeekDaysArr,
    setInputToDate,
    // setInputFromDate
     }) => {

        const [modal, setModal]                     = useState(false)
        const [quoteModal, setquoteModal]           = useState(false)
        const [quoteInfos, setQuoteInfos]           = useState({ job: null, quote: null, schedul: null })
        const [companyName, setCompanyName]                  = useState('')
        const [scheduleId, setScheduleId]           = useState('')
    
        const [vehicleVal, setVehicleVal]           = useState([])
    
        // const [primeVehicleDriver, setPrimeVehicleDriver]       = useState(null)
        // const [additionalVehTeamArr, setAdditionalVehTeamArr]   = useState([])
        
        // const [allVehicleArrObj, setAllVehicleArrObj] = useState(null)
        
    
        // const [CommentsArr, setCommentsArr]         = useState([])
        const [typeOfQuoteModal, setTypeOfQuoteModal]           = useState(false)
    
        const [QuoteHistoryModal, setQuoteHistoryModal]         = useState(false)
        const [quoteHistoryInfos, setQuoteHistoryInfos]         = useState([])
    
        const [contentLoader, setContentLoader]                 = useState(null)

        
        const [markers, setMarkers] = useState({lat: parseFloat(HOME_LATITUDE), lng: parseFloat(HOME_LONGITUDE) });
        const [streetViewModal, setStreetViewModal] = useState(false)
        const [siteInfo, setSiteInfo] = useState(null)

        const scrollRef = useRef(null)

        useEffect(() => {
            let fDate = moment(fromDate).isValid() ? fromDate : moment(Date()).format('YYYY-MM-DD')
            let tDate = moment(toDate).isValid() ? toDate : moment(Date()).add(10,'day').format('YYYY-MM-DD')
            // let timeNow = moment(Date()).format('HH:mm:ss')
            
            const success = () => {
                setScheduleLoader(false)
            }
            setScheduleLoader(true)
            let filter = `from=${fDate}&to=${tDate}`
           
            fetch_filterd_schedules(screen_type, 1, filter, [], fDate, tDate, success)
            
        }, [screen_type, setScheduleLoader, fetch_filterd_schedules, fromDate, toDate])
    

        let frmd = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(4, 'w').format('YYYY-MM-DD')}`

        let applyButtonDisabled = true
        // let initial_cell_width = 5.29;

    let changeStatusDate = false

    if (selectedJob) {
        if (selectedJob.start_date !== StartDate || selectedJob.start_time !== StartTime || selectedJob.end_time !== EndTime || selectedJob.end_date !== EndDate || selectedJob.status !== schduleStatus) {
            changeStatusDate = true
        } else {
            changeStatusDate = false
        }
    }

    if (changeStatusDate) {
        applyButtonDisabled = false
    }

    const schedule_edit_btn_fx = (val) => {
        const success = (res) => {
            setModal(false)
            setSelectedJob('')
            setContentLoader(null)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Scheduled Job Updated.',
                showConfirmButton: false,
                timer: 1500
            })
            //recall joblist api

        }

        const failed = () => {
            setContentLoader(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        let data = {
            'id' : selectedJob.id,
            'start_date' : StartDate,
            'start_time' : StartTime + ':00',
            'end_time'   : EndTime + ':00',
            'end_date'   : EndDate ,
            'status'     : schduleStatus,
            'outsourced_company_name' : val ? val : companyName,
            
            
        }
        // let data = new FormData()
        // data.append('id', selectedJob.id)
        // data.append('start_date', StartDate)
        // data.append('start_time', StartTime + ':00')
        // data.append('end_time', EndTime + ':00')
        // data.append('end_date', EndDate)
        // data.append('status', schduleStatus)
        console.log(data, screen_type, 'SCHEDULEEDIT')

        schedule_edit_job(screen_type, data, selectedJob.id, success, failed)
        fetch_jobs(screen_type, frmd, jobTab)
    }

   
    const deleteScheduleApiFx = (scheduleId) => {
        const success = () => {
            setContentLoader(null)
            fetch_jobs(screen_type, frmd, jobTab)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Scheduled Job Deleted.',
                showConfirmButton: false,
                timer: 1500
            })
        }
        const failed = () => {
            setContentLoader(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        delete_schedule(screen_type, scheduleId, null, success, failed)

    }

    const updateSchedule = (state1, state2, state3, cmp=null, fn) => {
        console.log("SCHEDIT", state3, vehicleVal)
        if (state1 || changeStatusDate) {
            schedule_edit_btn_fx(cmp)
        }
            setContentLoader(null)
        fetch_jobs(screen_type, frmd, jobTab)
        fn(false)
    }

    const openJobDetailModal = (id) => {

        const succ = (res) =>{
            console.log('JobDetailModal', res.data)
            if(res.data){
            setQuoteInfos(res.data)
            setModal(false)
            setquoteModal(true)}
            else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            setContentLoader(null)
        }
        }


        const fail = (err) =>{
            Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: err && err.app_data ? err.app_data : 'Something went wrong!'
           })
            setContentLoader(null)
        }

        Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`,null, succ, fail)
    }

    const reopenScheduleModal = () => {
        setModal(true)
    }


const getVehicleVal = (val) => {
    Api('get', `${SCHEDULE_ADDITIONAL_VEHICLE}${val}/`).then(res => {
        console.log(res)
        if (res.additional_vehicles)
            setVehicleVal(res.additional_vehicles.map(item => { return item.id }))
        else
            setVehicleVal([])

    })
}

    const ScrollPos = (val) => {
        const { top } = val
        console.log(val,'TOP=>',top,stopOutLoading,scheduleLoader)
        // if((top > 0.9 && top<1) && stopOutLoading)
        if ((top === 1) && stopOutLoading && !scheduleLoader) {
            rldSchd()
            setScheduleLoader(true)
            let newFrmDay = moment(toDate).add(1,'day')
            let newToDay = moment(toDate).add(10,'days')

            let filter = `from=${moment(newFrmDay).format('YYYY-MM-DD')}&to=${moment(newToDay).format('YYYY-MM-DD')}`
            let weekArr = []
            for(let i=0; i<moment(newToDay).diff(moment(newFrmDay),'days')+1; ++i){ 
                weekArr.push({
                    weekday : moment(newFrmDay).add(i,'day').format('dddd'),
                    date : moment(newFrmDay).add(i,'day').format('DD-MM-YYYY')
                })
            }
            let oldWeek = weekDaysArr
            setWeekDaysArr([...oldWeek, ...weekArr])
            const success = () =>{
                setScheduleLoader(false)
            }
            fetch_filterd_schedules(screen_type, 1, filter, schedulesOut_list, moment(newFrmDay).format('YYYY-MM-DD'), moment(newToDay).format('YYYY-MM-DD'), success)
            let availFilter = 'start=' + moment(newFrmDay).format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(newFrmDay).format('HH:mm:ss') + '&&end=' + moment(newToDay).format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(newToDay).format('HH:mm:ss')
                fetch_availabilityVeh(availFilter, screen_type)
                fetch_availabilityEmp(availFilter)
                setInputToDate(moment(toDate).add(10,'days'))
        }
    }

    const TimeMeridium = (v) =>{
        return moment(v,'HH:mm:ss').format('hh:mm A')
    }


    const SingleFilled2 = ({ item, name, day, date, vehicleId }) => {
        // console.log('==============>',item)
        // console.log('==============>',name)
        // console.log('==============>',day)

        const { address, //vehicle, primary_vehicle_driver, 
            status, tab_type, client, job, frequency, start_date, end_date, start_time, end_time,
            id, waste_type_str, type_of_waste_str_profile_name, outsourced_company_name, //outsourced_job, quote, job_card_code,
            coordinates 
         } = item;
        
        if (zoomVal === 0) {
            return (
                <div className="shd-single-filled-container" >

                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', height: 14, width: '100%', backgroundColor: 'transparent', color: '#fff' }}>
                        <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                setMarkers({
                                    lat : parseFloat(coordinates?.latitude),
                                    lng : parseFloat(coordinates?.longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                        
                        </Tooltip>
                        <Edit
                            onClick={() => {
                                setContentLoader(id)
                                sales_job_viewFx(job)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(start_date)
                                setStartTime(start_time)
                                setEndTime(end_time)
                                setEndDate(end_date)
                                setScheduleStatus(status)
                                setScheduleId(id)
                                setCompanyName(outsourced_company_name)
                                savedJobDataFx(item)
                                setModal(true)
                            }}
                            style={{ fontSize: '.8rem', marginRight: 5, cursor: 'pointer' }} />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            getVehicleVal(id);
                            setContentLoader(id)
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer', fontSize: '.8rem', height: 20, padding: '4px 0 ' }}>Job {job}</button>

                    {/* <p style={{ fontSize: '.8rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    
                    <div className='shd-employee-names' >
                        <p className='shd-employees-tooltip'>{name}</p>
                    </div>
                    
                    <p style={{
                        fontWeight: 'bold', fontSize: '.8rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px', textTransform : 'capitalize',
                        backgroundColor: status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }} >{
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    {
                        item?.unread_comments_exists &&
                        <div className='floatingCommentIconRedDot'>
                            <AnnouncementRounded style={{ color : 'red', stroke : 'white' }} />
                        </div>
                    }
                    <Tooltip title={ tab_type==='waste' ? <p style={{ wordBreak : 'break-all' }}>Waste Type : {type_of_waste_str_profile_name ?
                                                                 `${type_of_waste_str_profile_name}(${waste_type_str})` 
                                                                : waste_type_str},<br/> Frequency : {frequency} Weeks </p> : ''}>
                    <p style={{ fontWeight: 'bold', fontSize: '.8rem', margin: '7px 0' }} >{client}</p>                                               
                                                                </Tooltip>
                    
                    <p style={{ fontWeight: 'bold', fontSize: '.8rem', color: '#6363c2', textTransform:'capitalize', position:'relative' }} >Enviro {tab_type}</p>
                    <p style={{ fontSize: '.8rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    
                    {
                        contentLoader===id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }
                </div>
            )
        } else if (zoomVal === 1) {
            return (
                <div className="shd-single-filled-container">
                                      
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', height: 16, alignItems: 'center', width: '100%', backgroundColor: 'transparent', color: '#fff' }}>
                    <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                setMarkers({
                                    lat : parseFloat(coordinates?.latitude),
                                    lng : parseFloat(coordinates?.longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                        </Tooltip>
                        <Edit
                            onClick={() => {
                                sales_job_viewFx(job)
                                setContentLoader(id)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(start_date)
                                setStartTime(start_time)
                                setEndTime(end_time)
                                setEndDate(end_date)
                                setCompanyName(outsourced_company_name)
                                setScheduleStatus(status)
                                setScheduleId(id)
                                let availFilter = 'start=' + moment(start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + start_time + '&&end=' + moment(end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + end_time
                                fetch_availabilityVeh(availFilter, screen_type)
                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = []
                                if (item.team_employees) {
                                    item.team_employees.map((item, key) => {
                                        employee_data.push(item.id)
                                        return null;
                                    })
                                }
                                savedJobDataFx(item)
                                setModal(true)
                            }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }} />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContentLoader(id)
                            getVehicleVal(id);
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer', fontSize: '1rem' }}>Job {job}</button>
                    {/* <p style={{ fontSize: '1rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    <div className='shd-employee-names' >
                        <p className='shd-employees-tooltip'>{name}</p>
                    </div>
                    
                    
                    <p style={{
                        fontWeight: 'bold', fontSize: '1rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px', textTransform : 'capitalize',
                        backgroundColor:status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }} >{
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    {
                        item?.unread_comments_exists &&
                        <div className='floatingCommentIconRedDot'>
                            <AnnouncementRounded style={{ color : 'red', stroke : 'white' }} />
                        </div>
                    }
                    <p style={{ fontWeight: 'bold', fontSize: '1rem', margin: '7px 0' }} >{client}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1rem', color: '#6363c2', textTransform : 'capitalize' }} >Enviro {tab_type}</p>
                    {   tab_type === 'waste' ?
                        <p style={{ fontWeight: 'normal', fontSize: '1rem', color: '#6363c2', wordWrap:'break-word' }} >Waste Type : {type_of_waste_str_profile_name ?
                            `${type_of_waste_str_profile_name}(${waste_type_str})` 
                           : waste_type_str}</p> : null
                    }
                    <p style={{ fontWeight: 'normal', fontSize: '1rem', color: '#6363c2' }} >Frequency : {frequency} Weeks</p>
                    <p style={{ fontSize: '1rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                  
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }
                </div>
            )
        } else if (zoomVal === 2) {
            return (
                <div className="shd-single-filled-container">
                   
                    
                    <div style={{ justifyContent: 'space-between', height: 28, backgroundColor: 'transparent', color: 'black', width: '100%', padding: '0' }} className="shd-flex-center">
                        <p style={{ marginLeft: 10 }}>{date} {day}</p>
                        <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                setMarkers({
                                    lat : parseFloat(coordinates?.latitude),
                                    lng : parseFloat(coordinates?.longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 10, cursor: 'pointer' }}
                        /></Tooltip>
                        
                        <Edit
                            onClick={() => {
                                sales_job_viewFx(job)
                                setContentLoader(id)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(start_date)
                                setStartTime(start_time)
                                setEndTime(end_time)
                                setEndDate(end_date)
                                setScheduleStatus(status)
                                setScheduleId(id)
                                setCompanyName(outsourced_company_name)
                                // setCommentsArr(comments)
                                let employee_data = []
                                if (item.team_employees) {
                                    item.team_employees.map((item, key) => {
                                        employee_data.push(item.id)
                                        return null;
                                    })
                                }
                                savedJobDataFx(item)
                                setModal(true)
                            }}
                            style={{ fontSize: '1rem', marginRight: 10, cursor: 'pointer', color: 'white' }} />
                    </div>


                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContentLoader(item.id)
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer' }}>Job {job}</button>

                    {/* <p style={{ fontSize: '1.1rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    <div className='shd-employee-names' >
                        <p className='shd-employees-tooltip'>{name}</p>
                    </div>
                    
                    {
                        item?.unread_comments_exists &&
                        <div className='floatingCommentIconRedDot'>
                            <AnnouncementRounded style={{ color : 'red', stroke : 'white' }} />
                        </div>
                    }
                    <p style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: '7px' }} >{client}</p>
                    <p style={{ fontSize: '1.1rem', margin: '7px 0' }} >{address}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#6363c2', textTransform : 'capitalize' }} >Enviro {tab_type}</p>

                    {   tab_type === 'waste' ?
                        <p style={{ fontWeight: 'normal', fontSize: '1.1rem', color: '#6363c2', wordWrap:'break-word' }} >Waste Type : {type_of_waste_str_profile_name ?
                            `${type_of_waste_str_profile_name}(${waste_type_str})` 
                           : waste_type_str}</p> : null
                    }
                    <p style={{ fontWeight: 'normal', fontSize: '1.1rem', color: '#6363c2' }} >Frequency : {frequency} Weeks</p>
                    <p style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '7px' }} >{start_date} to {end_date}</p>
                    <p style={{ fontSize: '1rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    <p style={{
                        fontSize: '1.1rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px',
                        textTransform : 'capitalize',
                        backgroundColor: status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }}>Status: {
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    
                    {
                        contentLoader===id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }

                </div>
            )
        }
        else{
            return <CircularProgress size={20} />
        }
    }

    
    return (
        <div className="shdl-main-body">

                {
                    (weekDaysArr && weekDaysArr?.length===0 && !(scheduleLoader || filterLoader)) ?
                        (
                        <div style={{ display: 'flex', height: '70.4vh', justifyContent: 'center', alignItems: 'center' }} >
                            <NoDatasScreen />
                        </div>
                        )
                    :
                    
                        scheduleLoader || filterLoader ? 
                        (
                            <Scrollview
                                autoHide
                                style={{ height: '70.4vh', width: maximize ? '100%' : '48vw', position: 'relative' }}
                                className='scheduleScrollInsdDiv'
                                onUpdate={ScrollPos}
                            >
                                <SchedulelistVehicleshimmer/>
                                <SchedulelistShimmer/>
                            </Scrollview>
                        ) 
                        : (

                            <Scrollview
                                autoHide
                                style={{ height: '70.4vh', width: maximize ? '100%' : '48vw', position: 'relative' }}
                                className='scheduleScrollInsdDiv'
                                ref={scrollRef}
                                onUpdate={ScrollPos}
                            >

                                <div
                                    className="shd-new-scheduler-col-layout shd-new-scheduler-col-layout-head"
                                    style={{
                                        gridTemplateColumns: `80px repeat(${24}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`,
                                        width:'100%'
                                    }}
                                >
                                    <div className="shd-new-scheduler-item-head"></div>
                                    {
                                        [...Array(24)].map((item, key) => {
                                            return (
                                                <div key={key} style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }} className="shd-new-scheduler-item-head">
                                                    {key===0 ? '12:00 AM' : 
                                                     key===12 ? '12:00 PM' :
                                                        key < 12 ? `${key<10 ? `0${key}` : key} AM` 
                                                            : `${(parseInt(key))-12 < 10 ? `0${(parseInt(key))-12} PM` : `${(parseInt(key))-12} PM`}`}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <>
                                    {
                                        weekDaysArr && weekDaysArr?.length!==0 ?
                                        weekDaysArr.map((item, k)=>{
                                            console.log('weekdaysarr',item)
                                            return(
                                                <div key={k}
                                                    className="shd-new-scheduler-col-layout"
                                                    style={{
                                                        gridTemplateColumns: `80px repeat(${24}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`
                                                    }}
                                                >
                                                    <div key={k} style={{ height: '100%', minHeight:'7.1vh', position: 'sticky', left: 0, zIndex: 3, backgroundColor: '#fff' }} className="shd-new-scheduler-item">
                                                        <p>{item.weekday}</p>
                                                        <p>{item.date}</p>
                                                    </div>
                                                    {
                                                        schedulesOut_list && [...Array(24)].map((el, key)=>{
                                                            return(
                                                                <div key={key} className="shd-new-scheduler-item-container"
                                                                    style={{ backgroundColor:`rgba(15, 153, 208,${k%2===0 ? '0':'0.2'})` }}
                                                                >
                                                                    <table>
                                                                        <tbody style={{ height: '100%' }}>
                                                                            {
                                                                                scheduleLoader ?
                                                                                    <ScheduleBoxShimmer zoomWidth={zoomWidth} shimmerType={'o'}/>
                                                                                :
                                                                                schedulesOut_list && schedulesOut_list?.filter(em => 
                                                                                    moment(em.start_date, 'YYYY-MM-DD').isValid() &&
                                                                                        moment(em.start_date).format('DD-MM-YYYY')===item.date
                                                                                ).map((itemShit, key) => {
                                                                                        return(
                                                                                            <tr style={{ verticalAlign:'top' }}>
                                                                                                <td style={{ width: `${zoomWidth}vw`}}>
                                                                                                    <SingleFilled2 item={itemShit} key={key} 
                                                                                                        name={itemShit.outsourced_company_name} 
                                                                                                        day={item.weekday} 
                                                                                                        date={item.date} 
                                                                                                        vehicleId={itemShit.id} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    // }else return(
                                                                                    // <tr style={{ maxHeight: '7.2vh' }}>
                                                                                    //     <td style={{ width: `${zoomWidth}vw` }}>
                                                                                    //         <div className="shd-new-scheduler-item shd-new-scheduler-item-filler"
                                                                                    //             style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16, 
                                                                                    //             color : 'black !important' }}
                                                                                    //         >
                                                                                    //             {key}{schedulesOut_list.length}
                                                                                    //         </div>
                                                                                    //     </td>
                                                                                    // </tr>
                                                                                    // )
                                                                                })
                                                                                    // <tr style={{ maxHeight: '7.2vh' }}>
                                                                                    //     <td style={{ width: `${zoomWidth}vw` }}>
                                                                                    //         <div className="shd-new-scheduler-item shd-new-scheduler-item-filler"
                                                                                    //             style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }}
                                                                                    //         >
                                                                                    //             - -
                                                                                    //         </div>
                                                                                    //     </td>
                                                                                    // </tr>
                                                                                
                                                                            }                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div style={{ height: 20, width: '100%' }} />
                                                </div>
                                            )
                                        })
                                        :
                                        <CircularProgress/>
                                    }
                                    
                                    
                                        <SchedulelistShimmer total_column_length={24} zoomWidth={zoomWidth} shimmerType={'o'} listShort={true}/>
                                   

                                </>


                            </Scrollview>

                        )
                }
            {   modal &&
            <JobModal
                modalStatus={modal}
                modalAction={setModal}
                selectedJob={selectedJob}

                openJobDetailModal={openJobDetailModal}

                StartDate={StartDate}
                setStartDate={setStartDate}
                StartTime={StartTime}
                setStartTime={setStartTime}
                EndTime={EndTime}
                setEndTime={setEndTime}
                EndDate={EndDate}
                setEndDate={setEndDate}

                scheduleId={scheduleId}

                schduleStatus={schduleStatus}
                setScheduleStatus={setScheduleStatus}

                buttonDisabled={applyButtonDisabled}

                updateScheduleFx={updateSchedule}
                deleteScheduleFx={deleteScheduleApiFx}
                fetch_schedule_list={fetch_schedule_list}
                screen_type={screen_type}
                prevData={schedulesOut_list}
                setContentLoader={setContentLoader}
                companyName={companyName}
                setCompanyName={setCompanyName}
    
            />
            }
            {   quoteModal &&
            <QuoteModal
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                reopenScheduleModal={typeOfQuoteModal ? null : reopenScheduleModal}
                vehicleArr={vehicleVal}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                setContentLoader={setContentLoader}
                commentRefreshFn={()=>{
                    fetch_jobs(screen_type, frmd, jobTab)
                }}
            />
            }
            {   QuoteHistoryModal &&
            <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
            }
            { streetViewModal ?
            <Suspense fallback={
                <div style={{ background: `var(--cl_ivery)`, color: `black`, padding: 5, borderRadius: `50%` }}>
                <img src={EnviroLogoLoader} alt="Logo_loader" style={{ width:'20%' }} />
            </div>
            }>
            <StreetViewModal 
                modalStatus={streetViewModal}
                modalAction={setStreetViewModal}
                latLngMarks={markers}
                siteInfo={siteInfo}
            />
            </Suspense>
            : null
            }
            </div>
    );
}

const mapStateToProps = (state) => {
    const { schedulesOut_list, schedulesOut_page, vehicle_list, stopOutLoading, schdOut_loader, availOutStrDate, availOutEndDate, job_list_weekFilter, jobTab,
        vehicle_listDateTime } = state.schedule
    const { selected_job } = state.jobView

    return { schedulesOut_list, schedulesOut_page, vehicle_list, stopOutLoading, schdOut_loader, availOutStrDate, availOutEndDate, job_list_weekFilter, jobTab, selected_job,
        vehicle_listDateTime }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_schedule_list     : (type, page, success, prevData) => dispatch(fetch_OutSourced_schedules(type, page, success, prevData)),
        fetch_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fn) => dispatch(
            fetch_OutSourced_schedules_sorted(type, page, filter, prevData, StartDate, EndDate, fn)
            ),
        schedule_edit_job       : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        // team_setting            : (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        // vehicle_setting         : (screen_type, data, primeData, shdId, schType, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, schType, success, failed)),
        delete_schedule         : (type, data, schType, success, failed) => dispatch(schedule_job_delete(type, data, schType, success, failed)),
        // add_image_schedule      : (type, data, schType, success, failed) => dispatch(schedule_image_add(type, data, schType, success, failed)),
        // add_video_schedule      : (type, data, schType, success, failed) => dispatch(schedule_video_add(type, data, schType, success, failed)),
        // add_image_signschedule  : (type, data, schType, success, failed) => dispatch(schedule_imageSign_add(type, data, schType, success, failed)),
        
        fetch_jobs              : (type, frmd, jobTab) => { dispatch(fetch_job_list_readyForSchd(type, 1, frmd, jobTab)); },

        fetch_availabilityVeh   : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp   : (filter) => dispatch(fetch_Employee_avilabilities(filter)),
        rldSchd                 : () => dispatch({ type: 'RELOAD_SCHD_LIST' }),
        sales_job_viewFx        : (id) => dispatch(sales_job_view(id)),
        savedJobDataFx          : (dataObj) => dispatch({ type: "SAVING_JOBDATA_TEMP", data: dataObj })
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Schedulecontent);
