import React from 'react';

const Endoflist = () => {
    return (
        <div className="endOfListDiv">
                <div></div>
                <span></span><span></span>
                <div></div>
            </div>
    );
}

export default Endoflist;
