import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux';
import { __vehicle_modal, __edit_fuel, __create_fuel } from '../common/action'
import Swal from 'sweetalert2'
import DateTimeInput from '../../../../../common/DateTimeInput';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: theme.shadows[5],
    },
    paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
    },
}));

function TransitionsModal({ 
    vehicle_modal, 
    vehicle_modal_action, 
    car_fuel_page,
    selected_car_fuel,
    all_car_list,
    fuel_edit,
    fuel_create,
    tabType
}) {
    const classes = useStyles();
    const handleClose = () => {
        vehicle_modal_action(false)
    };

    let vehicle_id = ''
    if(all_car_list.length > 0) {
        vehicle_id = all_car_list[0].id
    }
    let fuelDate = ''
    let fuelTime = ''
    let truckRegno = ''
    let curRdBefore = ''
    let readAftFilling = ''
    let filledBy = ''
    let volInLitres = ''
    let fuel_id = ''
    let nwDate = new Date()

    if(selected_car_fuel.id){
        const { time, id, registration, date, truck_rego, current_reading_before, reading_after_filling, filled_by, volume_usedIn_liter } = selected_car_fuel
        if(registration) {
            let vehicle_data = all_car_list.find(item => item.registration === registration)
            if(vehicle_data) {
                vehicle_id = vehicle_data.id
            }
        }
        fuelDate = date
        fuelTime = time
        truckRegno = truck_rego
        curRdBefore = current_reading_before
        readAftFilling = reading_after_filling
        filledBy =  filled_by
        volInLitres = volume_usedIn_liter
        fuel_id = id
    }

    const [Vehicle, setVehicle] = useState(vehicle_id)
    const [FuelDate, setfuelDate] = useState(fuelDate)
    const [FuelTime, setfuelTime] = useState(moment(fuelTime,'HH:mm').isValid() ? fuelTime : moment(nwDate).format('HH:mm'))
    const [TruckRegno, setTruckRegno] = useState(truckRegno)
    const [CurrRdBfr, setCurrRdBfr] = useState(curRdBefore)
    const [RdAfrFilling, setRdAfrFilling] = useState(readAftFilling)
    const [FilledBy, setFilledBy] = useState(filledBy)
    const [VolInLitres, setvolInLitres] = useState(volInLitres)
    
    const add_fuel_click = () => {
        let form_body = new FormData()

        form_body.append('vehicle', Vehicle)      
        form_body.append('date', FuelDate)
        form_body.append('time', moment(FuelTime).format('HH:mm'))
        form_body.append('truck_rego', TruckRegno)
        form_body.append('current_reading_before', CurrRdBfr)
        form_body.append('reading_after_filling', RdAfrFilling)
        form_body.append('filled_by', FilledBy)
        form_body.append('volume_usedIn_liter', VolInLitres)
        form_body.append('tab_type',tabType)
        form_body.append('vehicle_type', tabType==='pumps' ? 'utes' : 'car')
        const success = (response) => {
            handleClose()
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Report added successfully!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            handleClose()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        fuel_create(tabType, form_body, success, failed)

        // let endkey = `v1/vehicles/addFuelExpense/`
        // post_request(endkey, form_body, success, failed)

    }

    const edit_fuel_click = () => {
        let form_body = new FormData()

        form_body.append('vehicle', Vehicle)      
        form_body.append('date', FuelDate)
        form_body.append('time', FuelTime)
        form_body.append('truck_rego', TruckRegno)
        form_body.append('current_reading_before', CurrRdBfr)
        form_body.append('reading_after_filling', RdAfrFilling)
        form_body.append('filled_by', FilledBy)
        form_body.append('volume_usedIn_liter', VolInLitres)
        form_body.append('vehicle_type', tabType==='pumps' ? 'utes' : 'car')
        const success = (response) => {
            handleClose()
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Report Editted successfull!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            handleClose()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        fuel_edit(tabType, fuel_id, form_body, success, failed, car_fuel_page)
        // let endkey = `v1/vehicles/editFuelExpense/${fuel_id}/`
        // patch_request(endkey, form_body, success, failed)

    }

    let add_btn_style = 'regi-vehc-btn disabled-btn'
    let edit_btn_style = 'regi-vehc-btn disabled-btn'

    if(FuelTime && TruckRegno && CurrRdBfr && RdAfrFilling && FilledBy && VolInLitres) {
        add_btn_style = 'regi-vehc-btn'
    }
        
    if(Vehicle !== vehicle_id || FuelTime !== fuelTime || TruckRegno !== truckRegno || CurrRdBfr !== curRdBefore || RdAfrFilling !== readAftFilling || FilledBy !== filledBy || VolInLitres !== volInLitres ) {
        edit_btn_style = 'regi-vehc-btn'
     }

    return (
            <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-fuel_time"
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    className={classes.modal}
                    open={vehicle_modal}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <div className="mntnce-addNew-Modal-layout">
                        <div className="mntnce-addNew-Modal-main">
                            <p className="mntnce-addNew-Modal-title">Add New Fuel Expense Record</p>
                            <div className="modal-display-content">
                                <div className="modal-disPart1">

                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Vehicle</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <select
                                                value={Vehicle}
                                                onChange={(txt) =>{ setVehicle(txt.target.value); setTruckRegno(txt.target.value); }}
                                                className="mntnce-modal-input" 
                                            >
                                                {
                                                    all_car_list.map((item, key) => {
                                                        const { registration, id } = item
                                                        return (
                                                            <option key={key} value={id}>{registration}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Date</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            
                                            <DateTimeInput
                                                dateValue={FuelDate}
                                                dateSetFn={setfuelDate}
                                                clsName={'mntnce-modal-input'}
                                            /> 
                                        
                                            {/* <input 
                                                value={FuelDate}
                                                onChange={(txt) => setfuelDate(txt.target.value)}
                                                className="mntnce-modal-input" 
                                                type="date" 
                                                id=""
                                            /> */}
                                        </div>
                                    </div>
                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Time</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <DateTimeInput
                                                dateSetFn={setfuelTime}
                                                dateValue={FuelTime}
                                                pickerType={'time'}
                                                clsName={'mntnce-modal-input'}
                                            />
                                            {/* <input 
                                                value={FuelTime}
                                                onChange={(txt) => setfuelTime(txt.target.value)}
                                                className="mntnce-modal-input" 
                                                type="text" 
                                                id=""
                                            /> */}
                                        </div>
                                    </div>
                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Car RegNo</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <input value={TruckRegno}
                                            onChange={(txt) => setTruckRegno(txt.target.value)}
                                            className="mntnce-modal-input" 
                                            type="text" 
                                            id=""/>
                                        </div>
                                    </div>
                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Current Reading</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <input 
                                                value={CurrRdBfr}
                                                onChange={(txt) =>{ if((/^\d{0,}(\.[0-9]{0,})?$/).test(txt.target.value)){
                                                    setCurrRdBfr(txt.target.value); 
                                                    setvolInLitres(RdAfrFilling-txt.target.value < 0 ? 0 : RdAfrFilling-txt.target.value)
                                                }
                                            }}
                                                className="mntnce-modal-input" 
                                                type="text" 
                                                id=""
                                            />
                                        </div>
                                    </div>

                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Reading After Filling</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <input
                                                value={RdAfrFilling}
                                                onChange={(txt) =>{ if((/^\d{0,}(\.[0-9]{0,})?$/).test(txt.target.value)){
                                                    setRdAfrFilling(txt.target.value); 
                                                    setvolInLitres(txt.target.value-CurrRdBfr < 0 ? 0 : txt.target.value-CurrRdBfr)
                                                }}}
                                                className="mntnce-modal-input" 
                                                type="text" 
                                                id=""
                                            />
                                        </div>
                                    </div>
                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Filled By</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <input 
                                                value={FilledBy}
                                                onChange={(txt) => setFilledBy(txt.target.value)}
                                                className="mntnce-modal-input" 
                                                type="text" 
                                                id=""
                                            />
                                        </div>
                                    </div>
                                    <div className="mntnce-addNew-Modal-noraml">
                                        <div className="mntnce-addNew-Modal-edit-left">
                                            <p className="p-0 m-0">Volume Used in Litres</p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-center">
                                            <p className="p-0 m-0">: </p>
                                        </div>
                                        <div className="mntnce-addNew-Modal-edit-right">
                                            <input value={VolInLitres}
                                                onChange={(txt) => setvolInLitres(txt.target.value)}
                                                className="mntnce-modal-input" 
                                                type="text" 
                                                id=""
                                            />
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                                
                            {
                     selected_car_fuel.id ? (
                        <div className="box-two-add-items">      
                           <button 
                              onClick={() => edit_fuel_click()}
                              className={edit_btn_style}>Update</button>                   
                        </div>
                     ) : (
                        <div className="box-two-add-items">      
                           <button 
                              onClick={() => add_fuel_click()} 
                              className={add_btn_style}>Submit</button>                   
                        </div>
                     )
                  }
                        </div>
                    </div>

            </Modal>
    );
}


const mapStateToProps = (state) => {
    const { vehicle_modal, selected_car_fuel, all_car_list, car_fuel_page } = state.vehicle__car
    return { vehicle_modal, selected_car_fuel, all_car_list, car_fuel_page }
}

const mapDispatchToProps = (dispatch) => {
    return {
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        fuel_edit: (tabType, fuel_id, data, success, failed, page_no) => dispatch(__edit_fuel(tabType, fuel_id, data, success, failed, page_no)),
        fuel_create: (tabType, data, success, failed) => dispatch(__create_fuel(tabType, data, success, failed))
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal)
