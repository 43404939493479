import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Navbar from './Navbar'
import List from './MaintanaceTable'
import { fetch_forklift_maintanace_list, fetch_all_forklift_list } from '../common/action'
import { connect } from 'react-redux'

function App({ fetch_maintanace, fetch_all_forklifts, tabType, perms }) {

    useEffect(() => {
        fetch_maintanace(tabType, 1)
        fetch_all_forklifts(tabType)
        return () => fetch_maintanace.remove
        
    }, [fetch_maintanace, fetch_all_forklifts, tabType])

    const { path } = useRouteMatch()

    const AppMain = () => {
        const [pageLoader, setPageLoader] = useState(false)
        const [clearFilterDate, setClearFilterDate] = useState(false)

        const [filterFromDate, setFilterFromDate] = useState({ val : new Date(), status : false})
        const [filterToDate, setFilterToDate] = useState({ val : new Date(), status : false})
        const [invokeDate, setInvokeDate]   =   useState(false)

        return (
            <div className="sgr-mntnce-rprt-layout">
                <Navbar tabType={tabType} setPageLoader={setPageLoader} 
                setClearFilterDate={setClearFilterDate} clearFilterDate={clearFilterDate} setFilterFromDate={setFilterFromDate} setFilterToDate={setFilterToDate}
                filterFromDate={filterFromDate} filterToDate={filterToDate} setInvokeDate={setInvokeDate} invokeDate={invokeDate}/>
                <List tabType={tabType} pageLoader={pageLoader} setPageLoader={setPageLoader} perms={perms}
                setClearFilterDate={setClearFilterDate} clearFilterDate={clearFilterDate} filterFromDate={filterFromDate} filterToDate={filterToDate}
                 setInvokeDate={setInvokeDate} invokeDate={invokeDate}/>
            </div>
        )
    }

    return (
       <Switch>
           <Route path={path} component={AppMain} />
       </Switch>
    )
}

const mapStateToProps = () => {
    return { }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_maintanace: (tabType, page) => dispatch(fetch_forklift_maintanace_list(tabType, page)),
        fetch_all_forklifts: (tabType) => dispatch(fetch_all_forklift_list(tabType))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (App)
