import React, {useState} from 'react';
import Scrollview from '../../../common/ImprovedScrollBar';
// import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Fragment } from 'react';
import { DeleteForeverRounded,
    // Search 
} from '@material-ui/icons';
import Swal from 'sweetalert2';
import { CircularProgress } from '@material-ui/core'
import { SearchInputTab } from '../../../common/SearchInputTab';
import { CLIENT_DELETE, CLIENT_SINGLE_FETCH, SALES_CREATE_TEMP_CLIENT, SALES_QUOTE_CLIENTS_SEARCH, SITE_TYPE_OF_WASTE_LIST } from '../../../store/endpoint';
import Api from '../../../store/api';
import { cl_green, cl_red } from '../../../common/Colors';
import TypeOfWasteModalBox from './TypeOfWasteModal'

function TempPermCls ({
    setClientName,
    setClientSelection,
    setClientDet,
    setClientId,
    fetch_drafts,
    clientSelected,
    create_tempClient,
    setSearchQuery,
    fetch_allClients,
    temp_client,
    perm_Clients,
    tabType,
    setSiteMailID,
    typeOfWaste,
    setTypeOfWaste,
    jobCardListFn,
    setClientCardCSS,
    typeOfWasteId,
    setTypeOfWasteId
}) {
    //  console.log(perm_Clients, temp_client)
    // const [searchData, setSearchData] = useState(false)
    const [typeOfWasteModal, setTypeOfWasteModal] = useState(false)
    const [typeOfWasteList, setTypeOfWasteList] = useState([])
    const [tempPermLoader, setTempPermLoader] = useState(false)

    // const cropFileName = (str, len, frnt, lst) => {
    //     let lastIndx = str ? str.length : 0
    // return lastIndx >len ? str.substring(0,frnt)+ '...' + str.substring(lastIndx-lst,lastIndx): str;
    // }


    const delete_btn_click = (id) => {
        const success = () => {
            fetch_allClients(tabType)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Site deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure want to delete this Site?',
            text: "If you delete this site, all the quotes, jobs and related content will also get deleted. This action cannot be reversed.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let delete_url = CLIENT_DELETE + id + '/'
                Api('delete', delete_url)
                    .then(response => {
                        success()
                    })
                    .catch(err => {
                        failed()
                })
                
            }
        })
    }


    const SingleClient = ({index, item, temp=false}) => {
            const { client_name, id, site_address } = item
            // console.log('singleClient=>',item)
            return (
                <div 
                    onClick={() => {
                        setTempPermLoader(id)
                        Api('get',`${CLIENT_SINGLE_FETCH}${id}/`,'')
                            .then(response => {
                                console.log('Temporary response', response)
                                setClientDet(response)
                                setSiteMailID(response.client_email)
                        })

                        if(tabType==='waste'){
                            Api('get',`${SITE_TYPE_OF_WASTE_LIST}${id}/`,'')
                            .then(response => {
                                setClientName(client_name)
                        setClientSelection(id)
                        setClientId(id)
                        
                                console.log(response)
                                setTempPermLoader(null)
                                setTypeOfWasteList(response)
                                setClientCardCSS(1)

                                setTypeOfWasteModal(true)
                                
                            })
                                                            
                        }else{
                            setClientName(client_name)
                        setClientSelection(id)
                        setClientId(id)
                        
                            setTempPermLoader(null) 
                            jobCardListFn(id,null,null)
                        }// fetch_drafts('', tabType, id, null, null)
                        
                        // setTimeout(()=>{
                        //     setClientCardCSS(0)
                        // }, 2000)
                        
                    }}
                    className={id === clientSelected ? "sales-qr-client-single sales-qr-client-single-selected" : "sales-qr-client-single"}
                    style={{ display:'flex', flexDirection:'row', alignItems:'flex-start', justifyContent:'space-between' }}
                >
                    <div className='sales-qr-singl-client-mn-titleDiv'>
                        <p className="sales-qr-singl-client-mn-title">{client_name}{tempPermLoader===id ? '(Loading data please wait...)' : null}</p>
                        <p className="sales-qr-singl-client-mn-desc">{site_address}{tempPermLoader===id ? '(Loading data please wait...)' : null}</p>
                    </div>
                    {   temp ?
                        <div onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); delete_btn_click(id) }}>
                            <DeleteForeverRounded />
                        </div>
                        :   null
                    }
                    {
                        tempPermLoader===id ?
                            <CircularProgress size={20} />
                        :
                            null
                    }
                    {/* <p>{client_id}</p> */}
                </div>
            )
        }

        const [ ClientNameV, setClientNameV ] = useState('')
        const [ ClientEmailV, setClientEmailV ] = useState('')
        // const [ ClientIdV, setClientIdV ] = useState('')
        const [ tempClientLoader, setTempClientLoader ] = useState(false)

        const createClientFx = () => {
            const success = (res) => {
                fetch_allClients(tabType)
                setTempClientLoader(false)
                setClientNameV('')
                setClientEmailV('')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Client created successful!',
                    showConfirmButton: false,
                    timer: 1500
                })
                
            }

            const failed = (res) => {
                setTempClientLoader(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
                })
            }

            let formBody = new FormData()
            // formBody.append('client_id', ClientIdV)
            formBody.append('client_email', ClientEmailV)
            formBody.append('client_name', ClientNameV)
            formBody.append('tab_type', tabType)
            
            if(ClientEmailV && ClientNameV) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (pattern.test(ClientEmailV)) {
                    setTempClientLoader(true)
                    Api('post-form', SALES_CREATE_TEMP_CLIENT, formBody, success, failed )
                    // create_tempClient(formBody, tabType, success, failed)
                }
            }
            
        }

 
        return (
            <div className="sales-qt-client-info">
                <p>Create Site</p>
                <div className="sales-qt-client-reg-box">
                    {/* <input 
                        required
                        type="text"
                        value={ClientIdV}
                        placeholder="Site ID"
                        onChange={txt => setClientIdV(txt.target.value)}
                    /> */}
                    <input 
                        required
                        type="text"
                        value={ClientNameV}
                        placeholder="Site Name"
                        id="newSiteName"
                        onChange={txt => setClientNameV(txt.target.value)}
                    />
                    <input 
                        required
                        type="email"
                        value={ClientEmailV}
                        placeholder="Email"
                        id="newSiteEmail"
                        onChange={txt => setClientEmailV(txt.target.value)}
                    />
                    <button id="createNewSite" onClick={() => createClientFx()}>
                        {
                            tempClientLoader ? (
                                <CircularProgress size={18} />
                            ) : ('Create')
                        }
                    </button>
                </div>

                <div className="sales-qt-clinet-table-lay">
                    <div className="sales-qt-clinet-table-hd">
                        <div className='sales-qt-clinet-table-hd-searchDiv'>
                                <div style={{ display:'flex', flexDirection:'row', width:'100%' }} >
                                <SearchInputTab cls={'searchInpSalesQuote'} txt={'Search Clients'}
                                    styleHndle={{ width : '100%', borderRadius : '5px' }}
                                    url={SALES_QUOTE_CLIENTS_SEARCH} 
                                    dispatchFn={(res) => 

                                    ({    
                                        type: 'QUOTE_GENERATE_DETAIL_CLIENT_SEARCH',
                                        temp:   res.temporary_client,
                                        perm:   res.permenent
                                    })
                                }
                                    fn={()=>{ fetch_allClients(tabType); }}
                                />
                                    {/* <input className="searchInpSalesQuote" placeholder='Search Clients' onChange={(e) => setSearchQuery(e.target.value)} /> */}
                                
                                {/* <HighlightOffIcon style={{ fontSize: '16px' }} onClick={()=>{ fetch_allClients(tabType); setSearchData(false) }} /> */}
                                </div>
                            
                        
                        </div>
                        <div style={{ display : 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '90%' }}>
                            <p>Temporary Sites</p>
                            <p>Permanent Sites</p>
                        </div>
                        
                    </div>

                    <Scrollview style={{ height: '45vh' }}>
                        <div className="sales-qt-client-body">
                            <div className="sales-qt-perment-client-lay">

                                {
                                    temp_client.length > 0 ? (
                                        <Fragment>
                                            {
                                                temp_client.map((item, key) => {
                                                    return (
                                                        <SingleClient key={key} item={item} index={key} temp={true}/>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    ) : (<p style={{ fontSize: 11 }}>No Temporary Sites Found</p>)
                                }
                                
                                
                            </div>
                            <div className="sales-qt-temp-client-lay">
                                {
                                    perm_Clients && perm_Clients.length > 0 ?
                                    perm_Clients.map((item, key) => {
                                        return (
                                            <SingleClient key={key} item={item} index={key} />
                                        )
                                    })
                                    :
                                    (<p style={{ fontSize: 11 }}>No Permanent Sites Found</p>)
                                }
                            </div>
                        </div>
                    </Scrollview>

                </div>
                <TypeOfWasteModalBox modalStatus={typeOfWasteModal} modalAction={setTypeOfWasteModal} typeOfWasteList={typeOfWasteList}
                typeOfWaste={typeOfWaste} setTypeOfWaste={setTypeOfWaste} clientId={clientSelected} jobCardListFn={jobCardListFn}
                setClientDet={setClientDet} setSiteMailID={setSiteMailID} setClientName={setClientName} setClientSelection={setClientSelection}
                setClientId={setClientId} setTypeOfWasteList={setTypeOfWasteList} setClientCardCSS={setClientCardCSS}
                typeOfWasteId={typeOfWasteId} setTypeOfWasteId={setTypeOfWasteId}
                 />
            </div>
        )
    

}

export default TempPermCls;
