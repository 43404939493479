import React, {useState, useEffect, Suspense, useRef} from 'react';
import { connect } from 'react-redux';
import Scrollview from '../../../common/ImprovedScrollBar.jsx';
import { CircularProgress, Tooltip } from '@material-ui/core'
import NoDatasScreen from '../../../common/no_datas'
import moment from 'moment'
import { Edit, 
    //InfoOutlined, 
    //ReportProblemRounded, 
    StreetviewRounded } from '@material-ui/icons'
import { SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER, SCHEDULE_ADDITIONAL_VEHICLE, SCHEDULE_QUOTE_INFOS_NEW } from '../../../store/endpoint'
import Api from '../../../store/api'
import JobModal from './jobModalB'
import QuoteModal from '../../../common/CommonQuoteDetailModal'
import Swal from 'sweetalert2'
import QuoteHistoryModalView from './quoteHistoryModal'
import EnviroLogoLoader from '../../../assets/Images/eniviro_logo.gif'

import { // editAfterCompleteElements,
    add_team_to_job, add_vehicle_to_job, fetch_Employee_avilabilities, fetch_job_list_readyForSchd, fetch_schedules,
    //fetch_schedules_sorted, 
    fetch_schedules_sorted_NewMode, fetch_Vehicle_avilabilities, schedule_image_add, schedule_imageSign_add, schedule_job_delete, schedule_job_edit, schedule_video_add,
    //fetch_job_pending_schd, fetch_job_reoccur_schd
} from '../common/action'
import { sales_job_view } from '../../jobs/action'
import { SchedulelistShimmer, SchedulelistVehicleshimmer } from './ScheduleListShimmer';
import { HOME_LATITUDE, HOME_LONGITUDE } from '../../../store/config';
import { IsVideoFileUpload } from '../../../common/VideoFilePreview';

const StreetViewModal = React.lazy(() => import ('./StreetViewModal.jsx'));

const Schedulecontent = ({ screen_type,
    schedules_list,
    vehicle_list,
    schedules_page,
    fetch_schedule_list,
    fetch_filterd_schedules,
    schedule_edit_job,
    team_setting,
    vehicle_setting,
    delete_schedule,
    add_image_schedule,
    add_video_schedule,
    add_image_signschedule,
    stopLoading,
    // schd_loader,
    fetch_jobs,
    rldSchd,
    savedJobDataFx,
    // setMaximize,
    // applyButtonDisabled,
    sales_job_viewFx,
    fetch_availabilityEmp,
    fetch_availabilityVeh,

    scheduleLoader, setScheduleLoader,
    invokeDateFrom, invokeDateTo, fromDate, toDate, zoomVal,
    maximize,
    zoomWidth, success_sch_fx,
    setStartDate, StartDate,
    setStartTime, StartTime,
    setEndTime, EndTime,
    setEndDate, EndDate,
    setPrimeVehicle, primeVehicle,
    setScheduleStatus, schduleStatus,
    setSelectedTeamArray, selectedTeamArray,
    setSelectedJob, selectedJob, 
    container_width,


    setFromFilterDate,
    setToFilterDate,
    filterLoader,
    jobTab, selected_job
    // changeStatusDate, changeStatusTeam, changeStatusVehicle,
    
    // vehicles_arr, total_column_length, container_width, 
    

    //availStrDate, availEndDate, job_list_weekFilter, 
     }) => {

        const [modal, setModal]                     = useState(false)
        const [quoteModal, setquoteModal]           = useState(false)
        const [quoteInfos, setQuoteInfos]           = useState({ job: null, quote: null, schedul: null })
    
        const [scheduleId, setScheduleId]           = useState('')
    
        const [vehicleVal, setVehicleVal]           = useState([])
    
        const [primeVehicleDriver, setPrimeVehicleDriver]       = useState(null)
        const [additionalVehTeamArr, setAdditionalVehTeamArr]   = useState([])
        
        const [allVehicleArrObj, setAllVehicleArrObj] = useState(null)
        
    
        const [CommentsArr, setCommentsArr]         = useState([])
        const [typeOfQuoteModal, setTypeOfQuoteModal]           = useState(false)
    
        const [QuoteHistoryModal, setQuoteHistoryModal]         = useState(false)
        const [quoteHistoryInfos, setQuoteHistoryInfos]         = useState([])
    
        const [contentLoader, setContentLoader]                 = useState(null)

        
        const [markers, setMarkers] = useState({lat: parseFloat(HOME_LATITUDE), lng: parseFloat(HOME_LONGITUDE) });
        const [streetViewModal, setStreetViewModal] = useState(false)
        const [siteInfo, setSiteInfo] = useState(null)

        // const [isGrabTool, setIsGrabTool] = useState(false)

        const scrollRef = useRef(null)

        useEffect(() => {
            const success = () => {
                setScheduleLoader(false)
            }
            setScheduleLoader(true)
            let filter = `from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
            // if(invokeDateFrom){
            //     let filter = `from=${fromDate}&to=${toDate}`
            //     fetch_filterd_schedules(screen_type, 1, filter, [], fromDate, toDate)    
            // }else if(invokeDateTo){
            //     let filter = `from=${fromDate}&to=${toDate}`
            //     if (moment(fromDate, 'YYYY-MM-DD').isValid()) {
            //         console.log("FN6")
            //         fetch_filterd_schedules(screen_type, 1, filter, [], fromDate, toDate)
            //     }
            //     else{
            //         fetch_schedule_list(screen_type, fromDate, success, [])
            //     }

            // }else{
            //     fetch_schedule_list(screen_type, 1, success, [])
            // }
            fetch_filterd_schedules(screen_type, 1, filter, [], fromDate, toDate, success)
            
        }, [fetch_schedule_list, screen_type, setScheduleLoader, fromDate, toDate, fetch_filterd_schedules, invokeDateFrom, invokeDateTo])
    

        let frmd = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(4, 'w').format('YYYY-MM-DD')}`

        let applyButtonDisabled = true
        // let initial_cell_width = 5.29;

    let changeStatusDate = false
    let changeStatusTeam = false
    let changeStatusVehicle = false


    if (selectedJob) {
        if (selectedJob.start_date !== StartDate || selectedJob.start_time !== StartTime || selectedJob.end_time !== EndTime || selectedJob.end_date !== EndDate || selectedJob.status !== schduleStatus) {
            changeStatusDate = true
        } else {
            changeStatusDate = false
        }
        if (selectedJob.team_employees && selectedJob.team_employees.length !== selectedTeamArray.length) {
            changeStatusTeam = true
        } else {
            changeStatusTeam = false
        }
        let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === selectedJob.vehicle)
        if (vehcl_data) {

            if (vehcl_data.id !== primeVehicle) {
                changeStatusVehicle = true
            } else {
                changeStatusVehicle = false
            }

        } else {
            if (primeVehicle) {
                changeStatusVehicle = true
            } else {
                changeStatusVehicle = false
            }
        }
    }

    if (changeStatusDate || changeStatusTeam || changeStatusVehicle) {
        applyButtonDisabled = false
    }

    let vehicles_arr = []
    let total_column_length = 0;
    // let container_width = maximize ? 90 : 64;
    // let one_cell_width = 5.2;
    if (schedules_list && schedules_list.length > 0) {
        vehicles_arr = schedules_list[0]&& schedules_list[0].vehicles ? schedules_list[0].vehicles : [];
        total_column_length = vehicles_arr.length;
        // one_cell_width = (container_width / (total_column_length));
        // initial_cell_width = one_cell_width;
    }

        const schedule_edit_btn_fx = (compleObj) => {

            const success = (res) => {
                setModal(false)
                setSelectedJob('')
                setPrimeVehicleDriver(null)
                setAdditionalVehTeamArr([])
                setContentLoader(null)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Scheduled Job Updated.',
                    showConfirmButton: false,
                    timer: 1500
                })
                //recall joblist api
    
            }
    
            const failed = () => {
                setContentLoader(null)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            let data = {
                'id' : selectedJob.id,
                'start_date' : StartDate,
                'start_time' : StartTime + ':00',
                'end_time'   : EndTime + ':00',
                'end_date'   : EndDate ,
                'additional_vehicle_drivers' : additionalVehTeamArr,
                'status'     : schduleStatus,
                'primary_vehicle_driver' : primeVehicleDriver ? primeVehicleDriver : '',
                'vehicle'    : primeVehicle,
                'purchase_order_number'     : compleObj ? compleObj.ponumber : '',
                'signature_name'            : compleObj ? compleObj.signName : '',
                'extracted_waste_type'      : compleObj ? compleObj.chngWasteType : '',
                'extracted_litres_of_waste' : compleObj ? compleObj.chngWasteLtr : '',
                'compleObj'                 : compleObj
            }                
                if(compleObj.jobStatusTime){
                    let currDate = moment(StartDate)
                    let i = 0
                    let obj = {}
                    for(const vals in compleObj.jobStatusTime){
                        console.log(compleObj.jobStatusTime[vals],moment(`${compleObj.jobStatusTime[vals]}`).isValid())
                        if(moment(`${compleObj.jobStatusTime[vals]}`).isValid()){
                        let currStatusDate = moment(`${compleObj.jobStatusTime[vals]}`)
                        let setupDate = null
                        if(i===0){
                            currDate.set({ 'hour' : currStatusDate.get('hour'), 'minute' : currStatusDate.get('minute'), 'second' : currStatusDate.get('second') })
                            setupDate = currDate
                        }else{
                            currStatusDate.set({
                                'year' : currDate.get('year'), 'month' : currDate.get('month'), 'date' : currDate.get('date')
                            })
                            if(currStatusDate.diff(currDate) >= 0){
                                setupDate = currStatusDate
                            }else{
                                setupDate = currStatusDate.add(1,'day')
                            }

                        }
                        Object.assign(obj, { [`${vals}`] : `${moment(setupDate).format('YYYY-MM-DD HH:mm:ss')}`})
                        
                        console.log('LOOPDATA=>',obj)
                        // data.append(`${vals}`,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'))
                        i++

                        console.log('I=>',i,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'), vals)
                        }
                    }

                    Object.assign(data, obj)

                }
            console.log('FINAL=>',data)
                
            // // }
            // console.log(data, screen_type, 'SCHEDULEEDIT')
            if(compleObj && ((compleObj.before_pics && compleObj.before_pics.length>0) 
            || (compleObj && compleObj.videoGallery && compleObj.videoGallery.length>0)
            || (compleObj && compleObj.signImage && typeof compleObj.signImage!=="string")
            ||  (compleObj.after_pics && compleObj.after_pics.length>0) || ( compleObj.gallery_pics && compleObj.gallery_pics.length>0))){

                Swal.fire({
                    position : 'top-end',
                    icon : 'info',
                    title : 'Uploading Media',
                    showLoading : true
                })
                const cloeseSwalFn = () =>{
                    Swal.close()
                }
                addScheduleImg(selectedJob.id, 
                    ((compleObj.before_pics && compleObj.before_pics.length>0) 
            ||  (compleObj.after_pics && compleObj.after_pics.length>0) || ( compleObj.gallery_pics && compleObj.gallery_pics.length>0)) ?
                    compleObj : null, 
                    (compleObj && compleObj.videoGallery && compleObj.videoGallery.length>0) ? compleObj.videoGallery : null, 
                    (compleObj && compleObj.signImage && typeof compleObj.signImage!=="string") ? compleObj.signImage : null,
                    cloeseSwalFn
                    , null, "image")
            }
            // if(selectedJob.id && 
            //     ((compleObj.chngWasteLtr && compleObj.chngWasteLtr!=="") || 
            //     (compleObj.chngWasteType && compleObj.chngWasteType!=="") || (compleObj.ponumber && compleObj.ponumber!=="") ||
            //     (compleObj.signName && compleObj.signName!==""))){
            //     let data2 = {
            //         "id"                         : selectedJob.id,
            //         "extracted_waste_type"       : compleObj.chngWasteType,
            //         "extracted_litres_of_waste"  : compleObj.chngWasteLtr,
            //         "purchase_order_number"      : compleObj.ponumber,
            //         "signature_name"             : compleObj.signName
            //     }  
            //     editAfterCompleteElements(data2, null, null)
            // }


            schedule_edit_job(screen_type, data, null, success, failed)

            fetch_jobs(screen_type, frmd, jobTab)
        }
    
    
        const setTeamApi = () => {
            const success = (res) => {
                setModal(false)
                setContentLoader(null)
                fetch_jobs(screen_type, frmd, jobTab)
                setSelectedJob('')
            }
            const failed = (res) => {
                console.log(res)
                setContentLoader(null)
            }
            let data = new FormData()
            data.append('id', selectedJob.id)
            selectedTeamArray.map((item, key) => {
                data.append('members', item)
                return null
            })
                team_setting(screen_type, data, null, success, failed)
            
        }
    
    
        const setVehicleApi = () => {
            const success = (res) => {
                setModal(false)
                setContentLoader(null)
                fetch_jobs(screen_type, frmd, jobTab)
                setSelectedJob('')
            }
            const failed = (res) => {
                setContentLoader(null)
                //    console.log(res)
            }
            let primeData = new FormData()
            primeData.append('id', scheduleId)
            primeData.append('vehicle', primeVehicle)
            let data = new FormData()
            for (let i = 0; i < vehicleVal.length; i++) {
                // vehicleVal.map((item,_)=>{
                //     console.log(item, typeof item)
                data.append('vehicles', vehicleVal[i])
            }
            console.log("DATA-PREVDATA-SCHD", vehicleVal, primeVehicle)
            vehicle_setting(screen_type, data, primeData, scheduleId, null, success, failed)
        }

        const deleteScheduleApiFx = (scheduleId) => {
            const success = () => {
                setContentLoader(null)
                fetch_jobs(screen_type, frmd, jobTab)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Scheduled Job Deleted.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            const failed = () => {
                setContentLoader(null)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            delete_schedule(screen_type, scheduleId, null, success, failed)
    
        }
    
        const updateSchedule = (state1=null, state2=null, state3=null, fn=null, compleObj=null) => {
            console.log("SCHEDIT", state3, vehicleVal)
            if (state1 || changeStatusDate || compleObj) {
                schedule_edit_btn_fx(compleObj)
            }
            if (state2 || changeStatusTeam) {
                setTeamApi()
            }
            if (state3 || changeStatusVehicle)
                setVehicleApi()
                setContentLoader(null)
            fetch_jobs(screen_type, frmd, jobTab)
            if(fn)
            fn(false)
        }
    
        const addScheduleSignImg = (id, shdlImages, handleClose, setLoader=null) => {
            const success = (response) => {
                // console.log('the respo', response)
                // setSelectedJob(response)
                // fetch_jobs(screen_type, frmd, jobTab)
                // if(setLoader)
                // setLoader(false)
                // Swal.fire({
                //     position: 'top-end',
                //     icon: 'success',
                //     title: 'Signature added.',
                //     showConfirmButton: false,
                //     timer: 1500
                // })
            }
    
            const failed = (res) => {
                console.log('upload failed', res)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.app_data ? res.app_data : 'Something went wrong!'
                })
                if(setLoader)
                setLoader(false)
            }
    
            let data = new FormData()
            data.append('id', id)
            console.log('Files',shdlImages)
            // let i;
            // for (i = 0; i < shdlImages.length; i++) {
                let imgData = shdlImages
                // let imgName = imgData.name ? imgData.name : `signatureJob${id}` 
                data.append('image', imgData, `signatureJob${id}`)
            // }
            if(setLoader)
            setLoader(true)
            add_image_signschedule(screen_type, data, null, success, failed)
        }
    
        const addScheduleImg = (id, shdlImages, shdlVid, shdlSign, handleClose, setLoader=null, fileType="image") => {
            let currFn = 0
            const success = (response) => {
                if(setLoader)
                setLoader(false)
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }
            }
            const failed = (res) => {
                if(setLoader)
                setLoader(false)
                console.log('upload failed', res)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.app_data ? res.app_data : 'Something went wrong in Images uploading!'
                })
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }
            }
            const vidsuccess = (response) => {
                if(setLoader)
                setLoader(false)
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }
            }
            const vidfailed = (res) => {
                if(setLoader)
                setLoader(false)
                console.log('upload failed', res)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.app_data ? res.app_data : 'Something went wrong in videos uploading!'
                })
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }

            }
                let data = new FormData()
                data.append('id', id)
                data.append('schedule_id', selected_job?.schedule?.id)
                if(shdlImages){
                    if(shdlImages.gallery_pics && shdlImages.gallery_pics.length>0){
                        let i;
                        for (i = 0; i < shdlImages.gallery_pics.length; i++) {
                            let imgData = shdlImages.gallery_pics[i]
                            let imgName = imgData.name
                            data.append('image', imgData, imgName)
                        }
                    
                    }
                    if(shdlImages.before_pics && shdlImages.before_pics.length>0){
                        let i;
                        for (i = 0; i < shdlImages.before_pics.length; i++) {
                            let imgData = shdlImages.before_pics[i]
                            let imgName = imgData.name
                            data.append('before_pics', imgData, imgName)
                        }
                    
                    }
                    if(shdlImages.after_pics && shdlImages.after_pics.length>0){
                        let i;
                        for (i = 0; i < shdlImages.after_pics.length; i++) {
                            let imgData = shdlImages.after_pics[i]
                            let imgName = imgData.name
                            data.append('after_pics', imgData, imgName)
                        }
                    
                    }    
                }

                if(shdlSign){
                    data.append('signature', shdlSign, `signatureJob${id}`)
                }
                if((shdlImages && 
                    ((shdlImages.gallery_pics && shdlImages.gallery_pics.length>0) || (shdlImages.before_pics && shdlImages.before_pics.length>0) ||
                    (shdlImages.after_pics && shdlImages.after_pics.length>0))) || (shdlSign && shdlSign?.length>0)){
                    Api('post-form',SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER,data,success,failed)
                }


                if(shdlVid){
                    let data2 = new FormData()
                    data2.append('schedule_id', selected_job?.schedule?.id)
                    let i;
                    for (i = 0; i < shdlVid.length; i++) {
                        let imgData = shdlVid[i]
                        let imgName = imgData.name
                        if(IsVideoFileUpload(imgData.size))
                            data.append('videos', imgData, imgName)
                    }
                    if(setLoader)
                        setLoader(true)
                    add_video_schedule(screen_type, data, true, vidsuccess, vidfailed)
                    
                }
            console.log('ADDSCHEDULEIMG',id,shdlImages,shdlVid)
        }

        const openJobDetailModal = (id) => {

            const succ = (res) =>{
                console.log('JobDetailModal', res.data)
                if(res.data){
                setQuoteInfos(res.data)
                setModal(false)
                setquoteModal(true)}
                else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                
                setContentLoader(null)
            }
            }

            const fail = (err) =>{
                setContentLoader(null)
                    Swal.fire({
                       icon: 'error',
                       title: 'Oops...',
                       text: err && err.app_data ? err.app_data : 'Something went wrong!'
                   })
            }

            Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`,null, succ, fail)
        }
    
        const reopenScheduleModal = () => {
            setModal(true)
        }
    

    const getVehicleVal = (val) => {
        Api('get', `${SCHEDULE_ADDITIONAL_VEHICLE}${val}/`).then(res => {
            console.log(res)
            if (res.additional_vehicles)
                setVehicleVal(res.additional_vehicles.map(item => { return item.id }))
            else
                setVehicleVal([])

        })
    }

    const ScrollPos = (val) => {
        const { top } = val
        //console.log(val)
        // if((top > 0.9 && top<1) && stopLoading)
        if ((top === 1) && stopLoading) {
            rldSchd()
            if (!invokeDateFrom && invokeDateTo) {
                console.log("FN1")
                //fetch_schedule_list(screen_type, moment(toDate).subtract(10,'day').format('YYYY-MM-DD'), success_sch_fx, schedules_list)
            } else if (invokeDateFrom && !invokeDateTo) {
                console.log("FN2")
                setScheduleLoader(true)
                fetch_schedule_list(screen_type, moment(schedules_list[schedules_list.length - 1].date).add(1, 'day').format('YYYY-MM-DD'), success_sch_fx, schedules_list)
                let availFilter = 'start=' + moment(schedules_list[schedules_list.length - 1].date).add(1, 'day').format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(schedules_list[schedules_list.length - 1].date).format('HH:mm:ss') + '&&end=' + moment(schedules_list[schedules_list.length - 1].date).add(2, 'day').format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(schedules_list[schedules_list.length - 1].date).format('HH:mm:ss')
                fetch_availabilityVeh(availFilter, screen_type)
                fetch_availabilityEmp(availFilter)
            } else if (invokeDateFrom && invokeDateTo) {
                console.log("FN3")
                setScheduleLoader(true)
                let filter = `from=${fromDate}&to=${toDate}`
                fetch_filterd_schedules(screen_type, schedules_page + 1, filter, schedules_list, fromDate, toDate)
                let availFilter = 'start=' + moment(fromDate).format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(fromDate).format('HH:mm:ss') + '&&end=' + moment(toDate).format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(toDate).format('HH:mm:ss')
                fetch_availabilityVeh(availFilter, screen_type)
                fetch_availabilityEmp(availFilter)
            } else {
                console.log("FN4")
                setScheduleLoader(true)
                // console.log(schedules_list[schedules_list.length-1].date)
                // console.log(moment(schedules_list[schedules_list.length-1].date).add(10,'day').format('YYYY-MM-DD'))
                //fetch_schedule_list(screen_type, moment(schedules_list[schedules_list.length-1].start_date).add(10,'day').format('YYYY-MM-DD'), success_sch_fx, schedules_list)
                fetch_schedule_list(screen_type, moment(schedules_list[schedules_list.length - 1].date).add(1, 'day').format('YYYY-MM-DD'), success_sch_fx, schedules_list)
                let availFilter = 'start=' + moment(schedules_list[schedules_list.length - 1].date).add(1, 'day').format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(schedules_list[schedules_list.length - 1].date).format('HH:mm:ss') + '&&end=' + moment(schedules_list[schedules_list.length - 1].date).add(2, 'day').format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(schedules_list[schedules_list.length - 1].date).format('HH:mm:ss')
                fetch_availabilityVeh(availFilter, screen_type)
                fetch_availabilityEmp(availFilter)
            }
        }
    }

    const TimeMeridium = (v) =>{
        return moment(v,'HH:mm:ss').format('hh:mm A')
    }


    const SingleFilled2 = ({ item, name, day, date, vehicleId }) => {
        // console.log('==============>',item)
        // console.log('==============>',name)
        // console.log('==============>',day)

        const { 
            //quote_id,
             client, job, tab_type, address, start_date, end_date, start_time, end_time, status, 
            latitude, longitude,
            //shift,
            //amount,
            // team_employees,
             additional_vehicle_driver, prime_vehicle_driver } = item;
        if (zoomVal === 0) {
            return (
                <div className="shd-single-filled-container" >
                    {/* {   ((item.extracted_waste_type && item.extracted_waste_type!=='') ||
                        (item.extracted_litres_of_waste && item.extracted_litres_of_waste!==''))
                         ? 
                        <div className='reportPrblmFloat'>
                            <ReportProblemRounded style={{ color : 'var(--cl_red)', fontSize : '1.2rem'}}/>
                        </div> :   null
                    } */}
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', height: 14, width: '100%', backgroundColor: 'transparent', color: '#fff' }}>
                        <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                setMarkers({
                                    lat : parseFloat(latitude),
                                    lng : parseFloat(longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                        
                        </Tooltip>
                        <Edit
                            onClick={() => {
                                getVehicleVal(item.id)
                                setContentLoader(item.id)
                                sales_job_viewFx(item.job)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(item.start_date)
                                setStartTime(item.start_time)
                                setEndTime(item.end_time)
                                setEndDate(item.end_date)
                                setScheduleStatus(item.status)
                                setScheduleId(item.id)
                                setCommentsArr(item.comments)
                                setAdditionalVehTeamArr(item.additional_vehicle_driver)
                                setPrimeVehicleDriver(item.prime_vehicle_driver && item.prime_vehicle_driver[0].driver_id)
                                setAllVehicleArrObj(item.vehicles)
                                let availFilter = 'start=' + moment(item.start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + item.start_time + '&&end=' + moment(item.end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + item.end_time
                                fetch_availabilityVeh(availFilter, screen_type)

                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = []
                                if (item.team_employees) {
                                    item.team_employees.map((item, key) => {
                                        employee_data.push(item.id)
                                        return null;
                                    })
                                }
                                // if (vehcl_data) {
                                    setPrimeVehicle(item.prime_vehicle_driver && item.prime_vehicle_driver[0].vehicle_id)
                                // }
                                savedJobDataFx(item)
                                setSelectedTeamArray(employee_data)
                                setModal(true)
                            }}
                            style={{ fontSize: '.8rem', marginRight: 5, cursor: 'pointer' }} />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            getVehicleVal(item.id);
                            setContentLoader(item.id)
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer', fontSize: '.8rem', height: 20, padding: '4px 0 ' }}>Job {job}</button>

                    {/* <p style={{ fontSize: '.8rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    {
                    ((prime_vehicle_driver && prime_vehicle_driver[0].vehicle_id === vehicleId) || (additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId))) ?
                    <div className='shd-employee-names' >
                            {   prime_vehicle_driver && prime_vehicle_driver[0].vehicle_id === vehicleId ?
                                <p style={{ fontSize: '.8rem' }}>{prime_vehicle_driver && prime_vehicle_driver[0].name ? prime_vehicle_driver[0].name : null }</p>
                                :
                                additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId) ?
                                <p style={{ fontSize: '.8rem' }}>{additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId).name}</p>
                                :   null
                            }
                                <p style={{ fontSize: '.8rem', cursor: 'pointer' }}>{additional_vehicle_driver && additional_vehicle_driver.length > 1 ? `+ ${additional_vehicle_driver.length - 1} more` : null}</p> 
                            

                        <p className='shd-employees-tooltip'>{prime_vehicle_driver && prime_vehicle_driver[0].name}<br />{additional_vehicle_driver && additional_vehicle_driver.map((employees) => { return <span>{employees.name}<br /></span> })}</p>

                    </div>
                    :   null }
                    <p style={{
                        fontWeight: 'bold', fontSize: '.8rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px', textTransform : 'capitalize',
                        backgroundColor: status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }} >{
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    <Tooltip title={ tab_type==='waste' ? <p style={{ wordBreak : 'break-all' }}>Waste Type : {item.type_of_waste_str_profile_name ?
                                                                 `${item.type_of_waste_str_profile_name}(${item.waste_type_str})` 
                                                                : item.waste_type_str},<br/> Frequency : {item.frequency} Weeks </p> : ''}>
                    <p style={{ fontWeight: 'bold', fontSize: '.8rem', margin: '7px 0' }} >{client}</p>                                               
                                                                </Tooltip>
                    
                    {/* <Tooltip title={ tab_type==='waste' ? <p style={{ wordBreak : 'break-all' }}>Waste Type : {item.type_of_waste_str_profile_name ?
                                                                 `${item.type_of_waste_str_profile_name}(${item.waste_type_str})` 
                                                                : item.waste_type_str},<br/> Frequency : {item.frequency} Weeks </p> : ''}> */}
                    <p style={{ fontWeight: 'bold', fontSize: '.8rem', color: '#6363c2', textTransform:'capitalize', position:'relative' }} >Enviro {tab_type}
                        {/* {   tab_type==='waste' ? 
                            <span><InfoOutlined style={{ color:'blue', position:'absolute', top:'0', fontSize: '1rem' }} /></span> : null
                        } */}
                        </p>
                    {/* </Tooltip> */}
                    <p style={{ fontSize: '.8rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    {/* <div style={{ backgroundColor: 'yellow', }} className="shd-flex-center">
                        <p>{amount}</p>
                    </div>
                    <div style={{ backgroundColor: 'green', color: 'white' }} className="shd-flex-center">
                        <p>{0}</p>
                    </div> */}
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }
                </div>
            )
        } else if (zoomVal === 1) {
            return (
                <div className="shd-single-filled-container">
                    {/* {   ((item.extracted_waste_type && item.extracted_waste_type!=='') ||
                        (item.extracted_litres_of_waste && item.extracted_litres_of_waste!==''))
                         ? 
                        <div className='reportPrblmFloat'>
                            <ReportProblemRounded style={{ color : 'var(--cl_red)', fontSize : '1.2rem'}}/>
                        </div> :   null
                    } */}
                    
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', height: 16, alignItems: 'center', width: '100%', backgroundColor: 'transparent', color: '#fff' }}>
                    <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                setMarkers({
                                    lat : parseFloat(latitude),
                                    lng : parseFloat(longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                        </Tooltip>
                        <Edit
                            onClick={() => {
                                sales_job_viewFx(item.job)
                                setContentLoader(item.id)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(item.start_date)
                                setStartTime(item.start_time)
                                setEndTime(item.end_time)
                                setEndDate(item.end_date)
                                setScheduleStatus(item.status)
                                setScheduleId(item.id)
                                setCommentsArr(item.comments)
                                setAdditionalVehTeamArr(item.additional_vehicle_driver)
                                setPrimeVehicleDriver(item.prime_vehicle_driver && item.prime_vehicle_driver[0].driver_id)
                                setAllVehicleArrObj(item.vehicles)

                                getVehicleVal(item.id)
                                let availFilter = 'start=' + moment(item.start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + item.start_time + '&&end=' + moment(item.end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + item.end_time
                                fetch_availabilityVeh(availFilter, screen_type)
                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = []
                                if (item.team_employees) {
                                    item.team_employees.map((item, key) => {
                                        employee_data.push(item.id)
                                        return null;
                                    })
                                }
                                // if (vehcl_data) {
                                    setPrimeVehicle(item.prime_vehicle_driver && item.prime_vehicle_driver[0].vehicle_id)
                                // }
                                savedJobDataFx(item)
                                setSelectedTeamArray(employee_data)
                                setModal(true)
                            }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }} />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContentLoader(item.id)
                            getVehicleVal(item.id);
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer', fontSize: '1rem' }}>Job {job}</button>
                    {/* <p style={{ fontSize: '1rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    {
                    ((prime_vehicle_driver && prime_vehicle_driver[0].vehicle_id === vehicleId) || (additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId))) ?
                    <div className='shd-employee-names' >
                            {   prime_vehicle_driver && prime_vehicle_driver[0].vehicle_id === vehicleId ?
                                <p style={{ fontSize: '.8rem', marginTop: 7 }}>{prime_vehicle_driver && prime_vehicle_driver[0].name ? prime_vehicle_driver[0].name : null }</p>
                                :
                                additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId) ?
                                <p style={{ fontSize: '.8rem', marginTop: 7 }}>{additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId).name}</p>
                                :   null
                            }
                                <p style={{ fontSize: '.8rem', cursor: 'pointer' }}>{additional_vehicle_driver && additional_vehicle_driver.length > 1 ? `+ ${additional_vehicle_driver.length - 1} more` : null}</p> 
                            

                        <p className='shd-employees-tooltip'>{prime_vehicle_driver && prime_vehicle_driver[0].name}<br />{additional_vehicle_driver && additional_vehicle_driver.map((employees) => { return <span>{employees.name}<br /></span> })}</p>

                    </div>
                    :   null }
                    
                    <p style={{
                        fontWeight: 'bold', fontSize: '1rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px', textTransform : 'capitalize',
                        backgroundColor:status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }} >{
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1rem', margin: '7px 0' }} >{client}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1rem', color: '#6363c2', textTransform : 'capitalize' }} >Enviro {tab_type}</p>
                    {   tab_type === 'waste' ?
                        <p style={{ fontWeight: 'normal', fontSize: '1rem', color: '#6363c2', wordWrap:'break-word' }} >Waste Type : {item.type_of_waste_str_profile_name ?
                            `${item.type_of_waste_str_profile_name}(${item.waste_type_str})` 
                           : item.waste_type_str}</p> : null
                    }
                    <p style={{ fontWeight: 'normal', fontSize: '1rem', color: '#6363c2' }} >Frequency : {item.frequency} Weeks</p>
                    <p style={{ fontSize: '1rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    {/* <div style={{ backgroundColor: 'yellow', fontWeight: 'bold', fontSize: '1rem', }} className="shd-flex-center">
                        <p>{amount}</p>
                    </div>
                    <div style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold', fontSize: 10, }} className="shd-flex-center">
                        <p>{0}</p>
                    </div> */}
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }
                </div>
            )
        } else if (zoomVal === 2) {
            return (
                <div className="shd-single-filled-container">
                    {/* {   ((item.extracted_waste_type && item.extracted_waste_type!=='') ||
                        (item.extracted_litres_of_waste && item.extracted_litres_of_waste!==''))
                         ? 
                        <div className='reportPrblmFloat'>
                            <ReportProblemRounded style={{ color : 'var(--cl_red)', fontSize : '1.2rem'}}/>
                        </div> :   null
                    } */}
                    
                    <div style={{ justifyContent: 'space-between', height: 28, backgroundColor: 'transparent', color: 'black', width: '100%', padding: '0' }} className="shd-flex-center">
                        <p style={{ marginLeft: 10 }}>{date} {day}</p>
                        <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                setMarkers({
                                    lat : parseFloat(latitude),
                                    lng : parseFloat(longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 10, cursor: 'pointer' }}
                        /></Tooltip>
                        
                        <Edit
                            onClick={() => {
                                sales_job_viewFx(item.job)
                                setContentLoader(item.id)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(item.start_date)
                                setStartTime(item.start_time)
                                setEndTime(item.end_time)
                                setEndDate(item.end_date)
                                setScheduleStatus(item.status)
                                setScheduleId(item.id)
                                setCommentsArr(item.comments)
                                setAdditionalVehTeamArr(item.additional_vehicle_driver)
                                setPrimeVehicleDriver(item.prime_vehicle_driver && item.prime_vehicle_driver[0].driver_id)
                                setAllVehicleArrObj(item.vehicles)

                                getVehicleVal(item.id)
                                let availFilter = 'start=' + moment(item.start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + item.start_time + '&&end=' + moment(item.end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + item.end_time
                                fetch_availabilityVeh(availFilter, screen_type)
                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = []
                                if (item.team_employees) {
                                    item.team_employees.map((item, key) => {
                                        employee_data.push(item.id)
                                        return null;
                                    })
                                }
                                // if (vehcl_data) {
                                    setPrimeVehicle(item.prime_vehicle_driver && item.prime_vehicle_driver[0].vehicle_id)
                                // }
                                savedJobDataFx(item)
                                setSelectedTeamArray(employee_data)
                                setModal(true)
                            }}
                            style={{ fontSize: '1rem', marginRight: 10, cursor: 'pointer', color: 'white' }} />
                    </div>


                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContentLoader(item.id)
                            getVehicleVal(item.id);
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer' }}>Job {job}</button>

                    {/* <p style={{ fontSize: '1.1rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    {
                    ((prime_vehicle_driver && prime_vehicle_driver[0].vehicle_id === vehicleId) || (additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId))) ?
                    <div className='shd-employee-names' >
                            {   prime_vehicle_driver && prime_vehicle_driver[0].vehicle_id === vehicleId ?
                                <p style={{ fontSize: '1rem', marginTop: 7 }}>{prime_vehicle_driver && prime_vehicle_driver[0].name ? prime_vehicle_driver[0].name : null }</p>
                                :
                                additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId) ?
                                <p style={{ fontSize: '1rem', marginTop: 7 }}>{additional_vehicle_driver && additional_vehicle_driver.find((el) => el.vehicle_id === vehicleId).name}</p>
                                :   null
                            }
                                <p style={{ fontSize: '1rem', marginTop: 7, cursor: 'pointer' }}>{additional_vehicle_driver && additional_vehicle_driver.length > 1 ? `+ ${additional_vehicle_driver.length - 1} more` : null}</p> 
                            

                        <p className='shd-employees-tooltip'>{prime_vehicle_driver && prime_vehicle_driver[0].name}<br />{additional_vehicle_driver && additional_vehicle_driver.map((employees) => { return <span>{employees.name}<br /></span> })}</p>

                    </div>
                    :   null }
                    
                    <p style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: '7px' }} >{client}</p>
                    <p style={{ fontSize: '1.1rem', margin: '7px 0' }} >{address}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#6363c2', textTransform : 'capitalize' }} >Enviro {tab_type}</p>

                    {   tab_type === 'waste' ?
                        <p style={{ fontWeight: 'normal', fontSize: '1.1rem', color: '#6363c2', wordWrap:'break-word' }} >Waste Type : {item.type_of_waste_str_profile_name ?
                            `${item.type_of_waste_str_profile_name}(${item.waste_type_str})` 
                           : item.waste_type_str}</p> : null
                    }
                    <p style={{ fontWeight: 'normal', fontSize: '1.1rem', color: '#6363c2' }} >Frequency : {item.frequency} Weeks</p>
                    <p style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '7px' }} >{start_date} to {end_date}</p>
                    <p style={{ fontSize: '1rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    <p style={{
                        fontSize: '1.1rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px',
                        textTransform : 'capitalize',
                        backgroundColor: status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }}>Status: {
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    {/* <div style={{ width: '100%' }} className="shd-flex">
                        <div style={{ flex: 1, height: 20, backgroundColor: 'yellow', fontWeight: 'bold', fontSize: '1.1rem', }} className="shd-flex-center">
                            <p>{amount}</p>
                        </div>
                        <div style={{ flex: 1, height: 20, backgroundColor: 'green', color: 'white', fontWeight: 'bold', fontSize: '1.1rem', }} className="shd-flex-center">
                            <p>{0}</p>
                        </div>
                    </div> */}
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }

                </div>
            )
        }
        else{
            return <CircularProgress size={20} />
        }
    }

    // const onMouseDownFn = (e) =>{
    //     if(e.button===1)
    //         setIsGrabTool(true)
    // }

    // const onMouseUpFn = (e) =>{
    //     setIsGrabTool(false)
    // }

    // const onMouseMoveFn = (e) =>{
    //     if(!isGrabTool)
    //         return 
    //     if(document && document.getElementById('scrollCalGrid')){
    //         let currWidth = scrollRef.current.getValues().scrollWidth
    //         scrollRef.current.view.scroll({ left : currWidth - scrollRef.current.getValues().clientWidth - e.pageX, behavior : 'smooth'})
            
    //     }
    // }


    return (
        <div className="shdl-main-body">

                {
                    (vehicles_arr && vehicles_arr.length === 0) || filterLoader ?
                    (
                        scheduleLoader || filterLoader ? 
                        (
                            <Scrollview
                                autoHide
                                style={{ height: '70.4vh', width: maximize ? '100%' : '48vw', position: 'relative' }}
                                className='scheduleScrollInsdDiv'
                                onUpdate={ScrollPos}
                            >
                                <SchedulelistVehicleshimmer/>
                                <SchedulelistShimmer/>
                            </Scrollview>
                        ) :
                            <div style={{ display: 'flex', height: '70.4vh', justifyContent: 'center', alignItems: 'center' }} >
                                <NoDatasScreen />
                            </div>)

                        : (

                            <Scrollview
                                autoHide
                                style={{ height: '70.4vh', width: maximize ? '100%' : '48vw', position: 'relative' }}
                                className='scheduleScrollInsdDiv'
                                ref={scrollRef}
                                onUpdate={ScrollPos}
                                // onScrollFrame={(values)=>{ console.log('scrollframe',values)}}
                                // renderTrackHorizontal={props => <div {...props} className='track-horizontal customScrollBarThumbGreyTrack' />}
                                // renderThumbHorizontal={props => <div {...props} className="thumb-horizontal customScrollBarThumbGrey"/>}
                                // renderThumbVertical={props => <div {...props} className="thumb-vertical customScrollBarThumbGrey"/>}
                            >

                                <div
                                    className="shd-new-scheduler-col-layout shd-new-scheduler-col-layout-head"
                                    id="scrollCalGrid"
                                    style={{
                                        gridTemplateColumns: `80px repeat(${total_column_length}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`,
                                        width:'100%'
                                    }}
                                >
                                    <div className="shd-new-scheduler-item-head"></div>
                                    {
                                        vehicles_arr && vehicles_arr.map((item, key) => {
                                            return (
                                                <div key={key} style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }} className="shd-new-scheduler-item-head"
                                                    // onMouseDown={onMouseDownFn}
                                                    // onMouseUp={onMouseUpFn}
                                                    
                                                    // onMouseMove={onMouseMoveFn}
                                                    >
                                                {item.registration}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <>
                                    {   schedules_list && schedules_list.length!==0 ?
                                        schedules_list && schedules_list.map((item, key) => {
                                            const { weekday, date, vehicles } = item
                                            return (
                                                <div key={key}
                                                    className="shd-new-scheduler-col-layout"
                                                    style={{
                                                        gridTemplateColumns: `80px repeat(${total_column_length}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`
                                                    }}
                                                >
                                                    <div key={key} style={{ height: '100%', minHeight:'7.1vh', position: 'sticky', left: 0, zIndex: 3, backgroundColor: '#fff' }} className="shd-new-scheduler-item">
                                                        <p>{weekday}</p>
                                                        <p>{moment(date).format('DD-MM-YYYY')}</p>
                                                    </div>
                                                    {
                                                        vehicles && vehicles.map((itemMember, key2) => {
                                                            const { job_schedules, registration } = itemMember

                                                            // let first_shift = job_schedules.filter((val) => val.shift === '1');
                                                            // let second_sift = job_schedules.filter((val) => val.shift === '2');
                                                            // let third_shift = job_schedules.filter((val) => val.shift === '3');

                                                            return (
                                                                <div key={key2} className="shd-new-scheduler-item-container"
                                                                    style={{ backgroundColor:`rgba(15, 153, 208,${key%2===0 ? '0':'0.2'})` }}
                                                                    // onMouseDown={onMouseDownFn}
                                                                    // onMouseUp={onMouseUpFn}
                                                                    // onMouseMove={onMouseMoveFn}
                                                                >
                                                                    <table>
                                                                        <tbody style={{ height: '100%' }}>
                                                                            {
                                                                                job_schedules.slice(0).reverse().map((itemShit, key) => {
                                                                                    return(
                                                                                        <tr style={{ verticalAlign:'top' }}>
                                                                                            <td style={{ width: `${zoomWidth}vw`}} className='schCalHoverElevate'>
                                                                                                <SingleFilled2 item={itemShit} key={key} name={registration} day={weekday} date={date} vehicleId={itemMember.id} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                job_schedules && job_schedules.length===0 ?
                                                                                    <tr style={{ height: '7.2vh' }}>
                                                                                        <td style={{ width: `${zoomWidth}vw` }}>
                                                                                            <div className="shd-new-scheduler-item shd-new-scheduler-item-filler"
                                                                                                style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }}
                                                                                            >
                                                                                                {registration}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                : null
                                                                            }
                                                                            {/* {
                                                                                first_shift.length > 0 ? (
                                                                                    first_shift.map((itemShit, key) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td style={{ width: `${zoomWidth}vw` }}>
                                                                                                    <SingleFilled2 item={itemShit} key={key} name={registration} day={weekday} date={date} vehicleId={itemMember.id} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <tr style={{ height: '100%' }}>
                                                                                        <td style={{ width: `${zoomWidth}vw` }}>
                                                                                            <div className="shd-new-scheduler-item">
                                                                                                {registration}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                )
                                                                            }

                                                                            {
                                                                                second_sift.length > 0 ? (
                                                                                    second_sift.map((itemShit, key) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td style={{ width: `${zoomWidth}vw` }}>
                                                                                                    <SingleFilled2 item={itemShit} key={key} name={registration} day={weekday} date={date} vehicleId={itemMember.id} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <tr style={{ height: '100%' }}>
                                                                                        <td style={{ width: `${zoomWidth}vw` }}>
                                                                                            <div className="shd-new-scheduler-item">
                                                                                                {registration}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                )
                                                                            }

                                                                            {
                                                                                third_shift.length > 0 ? (
                                                                                    third_shift.map((itemShit, key) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td style={{ width: `${zoomWidth}vw` }}>
                                                                                                    <SingleFilled2 item={itemShit} key={key} name={registration} day={weekday} date={date} vehicleId={itemMember.id} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    })
                                                                                ) : (
                                                                                    <tr style={{ height: '100%' }}>
                                                                                        <td style={{ width: `${zoomWidth}vw` }}>
                                                                                            <div className="shd-new-scheduler-item">
                                                                                                {registration}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                )
                                                                            } */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div style={{ height: 20, width: '100%' }} />
                                                </div>

                                            )
                                        })
                                        :   null
                                    }
                                    {scheduleLoader && (
                                        <SchedulelistShimmer total_column_length={total_column_length} zoomWidth={zoomWidth}/>
                                    )
                                    }

                                </>


                            </Scrollview>

                        )
                }
            {   modal && selected_job &&
            <JobModal
                modalStatus={modal}
                modalAction={setModal}
                selectedJob={selectedJob}

                openJobDetailModal={openJobDetailModal}

                StartDate={StartDate}
                setStartDate={setStartDate}
                StartTime={StartTime}
                setStartTime={setStartTime}
                EndTime={EndTime}
                setEndTime={setEndTime}
                EndDate={EndDate}
                setEndDate={setEndDate}

                scheduleId={scheduleId}

                vehicleVal={vehicleVal}
                setVehicleVal={setVehicleVal}

                primeVehicle={primeVehicle}
                setPrimeVehicle={setPrimeVehicle}

                schduleStatus={schduleStatus}
                setScheduleStatus={setScheduleStatus}

                selectedArr={selectedTeamArray}
                setSelectedArr={setSelectedTeamArray}

                buttonDisabled={applyButtonDisabled}

                updateScheduleFx={updateSchedule}
                deleteScheduleFx={deleteScheduleApiFx}

                addScheduleImg={addScheduleImg}
                addScheduleSignImg={addScheduleSignImg}

                CommentsArr={CommentsArr}
                setCommentsArr={setCommentsArr}
                fetch_schedule_list={fetch_schedule_list}
                screen_type={screen_type}
                prevData={schedules_list}

                fetch_availabilityEmp={fetch_availabilityEmp}
                fetch_availabilityVeh={fetch_availabilityVeh}

                primeVehicleDriver={primeVehicleDriver}
                setPrimeVehicleDriver={setPrimeVehicleDriver}
                additionalVehTeamArr={additionalVehTeamArr}
                setAdditionalVehTeamArr={setAdditionalVehTeamArr}
                setContentLoader={setContentLoader}
                allVehArrObj={allVehicleArrObj}

            />
            }
            {   quoteModal &&
            <QuoteModal
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                reopenScheduleModal={typeOfQuoteModal ? null : reopenScheduleModal}
                vehicleArr={vehicleVal}
                vehicleList={vehicle_list}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                setContentLoader={setContentLoader}

            />
            }
            {   QuoteHistoryModal &&
            <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
            }
            { streetViewModal ?
            <Suspense fallback={
                <div style={{ background: `var(--cl_ivery)`, color: `black`, padding: 5, borderRadius: `50%` }}>
                <img src={EnviroLogoLoader} alt="Logo_loader" style={{ width:'20%' }} />
            </div>
            }>
            <StreetViewModal 
                modalStatus={streetViewModal}
                modalAction={setStreetViewModal}
                latLngMarks={markers}
                siteInfo={siteInfo}
            />
            </Suspense>
            : null
            }
            </div>
    );
}

const mapStateToProps = (state) => {
    const { schedules_list, schedules_page, vehicle_list, stopLoading, schd_loader, availStrDate, availEndDate, job_list_weekFilter, jobTab } = state.schedule
    const { selected_job } = state.jobView

    return { schedules_list, schedules_page, vehicle_list, stopLoading, schd_loader, availStrDate, availEndDate, job_list_weekFilter, jobTab, selected_job }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_schedule_list     : (type, page, success, prevData) => dispatch(fetch_schedules(type, page, success, prevData)),
        fetch_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fn) => dispatch(
            fetch_schedules_sorted_NewMode(type, page, filter, prevData, StartDate, EndDate, fn)
            //fetch_schedules_sorted(type, page, filter, prevData, StartDate, EndDate)
            ),
        schedule_edit_job       : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_setting            : (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        vehicle_setting         : (screen_type, data, primeData, shdId, schType, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, schType, success, failed)),
        delete_schedule         : (type, data, schType, success, failed) => dispatch(schedule_job_delete(type, data, schType, success, failed)),
        add_image_schedule      : (type, data, schType, success, failed) => dispatch(schedule_image_add(type, data, schType, success, failed)),
        add_video_schedule      : (type, data, schType, success, failed) => dispatch(schedule_video_add(type, data, schType, success, failed)),
        add_image_signschedule  : (type, data, schType, success, failed) => dispatch(schedule_imageSign_add(type, data, schType, success, failed)),
        //  fetch_jobs: (type) => dispatch(fetch_job_list(type,1)) ,

        fetch_jobs              : (type, frmd, jobTab) => { dispatch(fetch_job_list_readyForSchd(type, 1, frmd, jobTab)); },

        fetch_availabilityVeh   : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp   : (filter) => dispatch(fetch_Employee_avilabilities(filter)),
        rldSchd                 : () => dispatch({ type: 'RELOAD_SCHD_LIST' }),
        sales_job_viewFx        : (id) => dispatch(sales_job_view(id)),
        savedJobDataFx          : (dataObj) => dispatch({ type: "SAVING_JOBDATA_TEMP", data: dataObj })
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Schedulecontent);
