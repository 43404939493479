import React, { useState } from 'react'
import Scrollview from '../../../../common/ImprovedScrollBar'
import { Link, useHistory } from 'react-router-dom'
import { cl_red, cl_green } from '../../../../common/Colors'
import Swal from 'sweetalert2'
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint'
import GoogleMapLayout from './GoogleMap/ShortMap'
import MapModal from './MapModal'
import { connect } from 'react-redux'
import { delete_client, fetch_client_totalNo, getTypeOfWasteDataList } from '../../action'
import DateTimeInput from '../../../../common/DateTimeInput'
import Failerrdevmsg from '../../../../common/failErrDevMsg'



function ClientDetail({ client, dispatch, tabType, permissions, getTypeWasteData, delClient, updateSiteNo }) {

    let route_history = useHistory();
    const { selected_client } = client


    let client_name = ''
    let client_email = ''

    let client_latitude = ''
    let client_longitude = ''
    let client_image = ''

    let client_capacity = ''
    let client_waste = ''
    let client_barcode = ''
    // let client_next_service = ''
    // let client_last_service = ''

    let site_address = ''
    let site_phone = ''
    let site_contact_person = ''
    let site_contact_mob = ''

    let client_company_name = ''
    let client_purchase_order = ''
   
    
    if (selected_client.id) {
        
        client_name = selected_client.client_name || ''
        client_email = selected_client.client_email || ''

        client_latitude = selected_client.location_latitude || ''
        client_longitude = selected_client.location_logitude || ''

        client_capacity = selected_client.device_capacity || ''
        client_waste = selected_client.waste_type_str || ''
        if(tabType!=='pumps'){
            client_barcode = selected_client.barcode || ''
        }
        
        client_purchase_order = selected_client.invoice_purchase_no || ''
        // client_next_service = selected_client.next_service_2 || null   
        // client_last_service = selected_client.last_service_1 || null
        
        site_address             = selected_client.site_address || ''
        site_phone               = selected_client.site_phone_no || ''
        site_contact_person      = selected_client.site_contact_person || ''
        site_contact_mob         = selected_client.site_contact_mob || ''
    
        client_company_name      = selected_client.company_name || ''    

    }

    if (selected_client.dp) {
        client_image = HOST_WITHOUT_SLASH + selected_client.dp
    }



    
    const [modalStatus, setModalStatus] = useState(false)
    const [markers, setMarkers] = useState([{lat: parseFloat(client_latitude), lng: parseFloat(client_longitude) }]);


    

    const delete_btn_click = () => {
        const success = () => {
            updateSiteNo(tabType)
            route_history.push('/client')
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Site deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }

        Swal.fire({
            title: 'Are you sure want to delete this Site?',
            text: "If you delete this site, all the quotes, jobs and related content will also get deleted. This action cannot be reversed.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                delClient(selected_client.id, tabType, success, failed)
            }
        })
    }


    const RenderProfileImage = () => {
        return (
            <>

                <div className="dummy-img">
                    <img className="client-img" src={client_image} alt="enviro client" />
                </div>
                
            </>
        )
    }



    return (
        <div className="client-detail-layout">
            <MapModal
                modalStatus={modalStatus}
                modalAction={setModalStatus}
                markers={markers}
                setMarkers={setMarkers}
            // values={values} 
            />
            <div className="detail-top">
                <div className="client-img-layout">
                    <RenderProfileImage />
                </div>

                <div className="client-map-layout">
                    <GoogleMapLayout
                        modalAction={setModalStatus}
                        markers={markers}
                    // values={values} 
                    />
                </div>
            </div>

            <div className="detail-forms-container">
                <div className="detailed-forms-main">
                    <Scrollview style={{ height: '58vh' }}>
                        {/* <InputField
                            disabled
                            title="Site ID"
                            value={client_id}
                            onChange={val => {}}
                        /> */}

                        <InputField
                            disabled
                            title="Site Name"
                            value={client_name}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Site Email"
                            value={client_email}
                            onChange={val => {}}
                        />

                        {/* <InputField
                            disabled
                            title="Next Service"
                            type="date"
                            value={client_next_service}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Last Service"
                            type="date"
                            value={client_last_service}
                            onChange={val => {}}
                        /> */}


                        <InputField
                            disabled
                            title="Capacity"
                            value={client_capacity}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Waste"
                            value={client_waste}
                            onChange={val => {}}
                        />
                    {   tabType!=='pumps' ?
                        <InputField
                            disabled
                            title="Barcode"
                            value={client_barcode}
                            onChange={val => {}}
                        />  :   null
                    }
                        
                        <InputField
                            disabled
                            title="Site Address"
                            value={site_address}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Site Contact/Person"
                            value={site_contact_person}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Site Phone"
                            value={site_phone}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Site Contact Mobile"
                            value={site_contact_mob}
                            onChange={val => {}}
                        />

                        <InputField
                            disabled
                            title="Company Name"
                            value={client_company_name}
                            onChange={val => {}}
                        />
                    {   tabType==='pumps' ?
                        <InputField
                            disabled
                            title="Purchase/Work Order No"
                            value={client_purchase_order}
                            onChange={val => {}}
                        />  :   null
                    }                        

                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Link to="detail-expanded" style={{ width: 200, textDecoration: 'none' }}
                                 className="client-add-btn" >
                                <p className="p-0 m-0">Expand</p>
                            </Link>
                        {   permissions.site.edit ?
                            <div style={{ width: 200 }} onClick={() => delete_btn_click()} className="client-delete-btn">
                                <p className="p-0 m-0">Delete</p>
                            </div>
                            :   null
                        }
                        </div>

                    </Scrollview>
                </div>
                
            </div>

        </div>
    )
}

function InputField({ title, value, onChange, type = 'text',  }) {

    if(type==='date'){
        return (
            <div className="data-single">
                <div className="detail-headings">
                    <p className="p-0 m-0">{title}</p>
                </div>
                <div className="detail-input-dot">
                    <p className="p-0 m-0">:</p>
                </div>
                <div className="detail-input-layout">
                {
                    value===null ?
                    <p>Date not provided</p>
                    :
                    <DateTimeInput
                    dateValue={value}
                    dateSetFn={""}
                    clsName={'client-input'}
                    />                    
                }
                    {/* <input
                        disabled
                        type={type}
                        placeholder={title}
                        className="client-input"
                        value={value}
                        onChange={onChange}
                    /> */}
                </div>
            </div>
        )
    }
    else
    return (
        <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}</p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <input
                    disabled
                    type={type}
                    placeholder={title}
                    className="client-input"
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { client } = state
    const { permissions } = state.userLogin
    return { client, permissions }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        getTypeWasteData    : (id) => dispatch(getTypeOfWasteDataList(id)),
        delClient           : (id, tab, success, failed) => dispatch(delete_client(id, tab, success, failed)),
        updateSiteNo        : (tab) => dispatch(fetch_client_totalNo(tab))
        
    }
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail)
