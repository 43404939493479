import React from 'react'
import { connect } from 'react-redux';

import { EnviroBrandLogo, EnviroDestructionLogo, EnviroHillsLogo, EnviroPumpLogo, EnviroWasteLogo } from '../../../../common/Images'
import { useLocation, Link } from 'react-router-dom'
import './styles.css'

function Header({ permissions, page, tabType, setTabType }) {
    
    const { pathname } = useLocation()
    let root_link = `/vehicle/${page}`
    let navHeadStatus = true
    let preInsp = false
    
    //TODO:Uncomment this part when fileds of Utes and Machinery are confirmed
    ////////////////////////////////////////////////////
    if(pathname === '/vehicle/truck' || pathname === '/vehicle/car' || pathname === '/vehicle/forklift'
        || pathname === '/vehicle/utes' || pathname === '/vehicle/machinery') {
        navHeadStatus = true
    } else {
        navHeadStatus = false
    }
    ///////////////////////////////////////

    // let chk_rout_o = pathname.split('/')
    
    // let checking_value = '/' + chk_rout_o[1]  
    // let chk_route = pathname.split(checking_value)
    // if(chk_route[0] === '' && chk_route[1]) {
    //     navHeadStatus = false
    // }
    if(pathname === '/vehicle/truck/preinspection-detail' || pathname === '/vehicle/car/preinspection-detail' || pathname === '/vehicle/forklift/preinspection-detail'
        || pathname === '/vehicle/machinery/preinspection-detail' || pathname === '/vehicle/utes/preinspection-detail' || pathname === '/vehicle/truck/preinspection-edit' || pathname === '/vehicle/car/preinspection-edit' || pathname === '/vehicle/forklift/preinspection-edit'
        || pathname === '/vehicle/machinery/preinspection-edit' || pathname === '/vehicle/utes/preinspection-edit'){
        preInsp = true
    }
    else 
        preInsp = false

    
    // const EnviroWaste = () => {

    //     if( permissions && permissions.vehicle.waste ) {
    //         return (
    //             <div className={tabType === 'waste' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('waste') }
    //             >
    //                 <p>Enviro Waste</p>
    //             </div>
    //         )
    //     } else return null
        
    // }

    // const EnviroPump = () => {
    //     if(permissions && permissions.vehicle.pumps) {
    //         return (
    //             <div className={tabType === 'pumps' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('pumps') }
    //             >
    //                 <p>Enviro Pumps</p>
    //             </div>
    //         )
    //     } else return null
        
    // }


    // const EnviroDestruction = () => {
    //     if(permissions && permissions.vehicle.destruction) {
    //         return (
    //             <div className={tabType === 'destruction' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('destruction') }
    //             >
    //                 <p>Enviro Destruction</p>
    //             </div>
    //         )
    //     } else return null

    //     // return (
    //     //     <div className="header-button-top disabled-btn">
    //     //         <p>Enviro Destruction</p>
    //     //     </div>
    //     // )
        
    // }

    // const EnviroHills = () => {
    //     if(permissions && permissions.vehicle.hills) {
    //         return (
    //             <div className={tabType === 'hills' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('hills') }
    //             >
    //                 <p>Enviro Hills</p>
    //             </div>
    //         )
    //     } else return null
        
    // }

    return (
        <div className="common-header">
            <div className="common-brand-layout">
                <img className="team-brand-img" src={
                    tabType === 'waste' ?
                    EnviroWasteLogo
                    :
                    tabType === 'pumps' ?
                    EnviroPumpLogo
                    :
                    tabType === 'hills' ?
                    EnviroHillsLogo
                    :
                    tabType === 'destruction' ?
                    EnviroDestructionLogo
                    :
                    EnviroBrandLogo
                } style={{ height:'100%' }}  alt='enviro logo' />
            </div>
            
            {
                navHeadStatus ? (
                    <div className="header-button-layout">
                        {/* <EnviroWaste /> */}
                        {/* <EnviroHills />
                        <EnviroPump />
                        <EnviroDestruction /> */}
                    </div>
                ) :  (
                    <Link to={ !preInsp ? root_link : "preinspection"} className="team-back-btn-layout">
                        <div className="header-back-btn">
                            <p className="p-0 m-0">Back</p>
                        </div>
                    </Link>

                    // <div onClick={()=> history.goBack() } className="header-button-layout">
                    //     <div className="header-button-top">
                    //         <p>Back</p>
                    //     </div>
                    // </div>
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user, permissions } = state.userLogin
    return { user, permissions }
}

export default connect(mapStateToProps) (Header)
