import React from 'react'
import {connect} from 'react-redux'
import { CircularProgress } from '@material-ui/core'
// import JoditEditor from 'jodit-react';
import Scrollview from '../../common/ImprovedScrollBar';
// import { HOST } from '../../store/endpoint';
import { useState } from 'react';
import { update_curr_template } from './common/action';
import Swal from 'sweetalert2';
import { CancelRounded, 
    //ExpandLessRounded, ExpandMoreRounded 
} from '@material-ui/icons';
import MainEditorTable from '../../common/mainEditorTable';
// import { CircularProgress } from '@material-ui/core'


function EditableEditorTableModal({
    jobId,
    Tempname,
    modalStatus, 
    modalAction,
    editorRef,
    editorAction,
    compl_template, 
    compl_table,
    set_quote_content,
    quoteAmt,
    set_quote_amount, clientId, user,
    set_quote_mainpage_content,
    quote_mainpage_content,
    reboundFn=null
}) {

    const [editableQuoteContent, setEditableQuoteContent] = useState(quote_mainpage_content ? quote_mainpage_content : null)
    const [submitLoader, setSubmitLoader] = useState(false)
    // const [openMod, setOpenMod] = useState(false)

    console.log('JOBID=>',jobId)
    // console.log('ModalOPenAGn',compl_table,compl_template)
    const handleClose = () => {
        if(reboundFn)
            reboundFn()
        modalAction(false)
    };    
    
    const addAttachment = () =>{
        console.log('NEWTEMPLATE',quote_mainpage_content)
        const successFx = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Template updated.`,
                showConfirmButton: false,
                timer: 1500
            })
            if(reboundFn)
                reboundFn()
            setSubmitLoader(false)
            modalAction(false)
        }
        const failedFx = () =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            setSubmitLoader(false)
        }
        update_curr_template(jobId, {
            updated_template : editableQuoteContent,
            table_data : compl_table
        }, successFx, failedFx)
        // set_quote_content(editorRef.current.value)
            // editorAction()
    }
        
  return (
      <div className='complJobTemplateModal'>
            <div className="cer-cerificate-modal-layout" style={{ width : '60vw', height : '80vh' }} >
                <div className="cer-modal-header-layout" style={{ justifyContent : 'space-between' }}>
                    <p style={{ fontSize: 16 }} className="cer-modal-main-title">{Tempname}</p>
                    <CancelRounded className="checkCircIcon" style={{ color:'red' }} onClick={()=> handleClose()} />
                </div>
                <Scrollview style={{ height : '80vh' }} hidden={false}>
                <div className="cer-modal-body-layout">
                    {
                        quote_mainpage_content ?
                        <MainEditorTable content={quote_mainpage_content} editorRef={editorRef} clientId={null} typeOfWaste={''}                        
                        setupSalesPerson={null} setTableDataObj={null} reEditting={true}
                        nonUpdateFn={setEditableQuoteContent}
                        />
                    :   <CircularProgress />
                    }
            {/* <JoditEditor
                ref={editorRef}
                value={ editableQuoteContent ? editableQuoteContent : 
                    editorRef ? editorRef.current && editorRef.current.value ? editorRef.current.value : '' : ''}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => { 
                    setEditableQuoteContent(newContent)
                }}
            /> */}
                </div>
                </Scrollview>
               <div style={{ display:'flex', flexDirection:'row', fontSize:'16px', alignItems:'center', justifyContent:'space-around' }}>
                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center' }}
                            onClick={()=> addAttachment() }
                        >
                            {   submitLoader ?
                                    <CircularProgress />
                                :
                                    'Save Changes'
                            }
                        </div>
                        
                    </div>
            </div>
      </div>
    
  );
}

const mapStateToProps = (state) => {
    const { compl_template, compl_table } = state.invoiceData
    const { quote_mainpage_content, quote_date, quote_page } = state.mainQuote

    const { user } = state.userLogin 

    return { compl_template, compl_table, user, quote_mainpage_content, quote_date, quote_page }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_quote_mainpage_content: (content) => dispatch({ type: 'SET_MAIN_CONTENT_QUOTE', content: content }),
        set_quote_amount: (amount) => dispatch({ type: 'SET_MAIN_QUOTE_AMOUNT', amount: amount })
      }
}

 export default connect( mapStateToProps, mapDispatchToProps) (EditableEditorTableModal)
 
