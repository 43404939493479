import axios from 'axios';
import { store } from '../../../../store/store';

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VEHICLE_FUEL_SEARCH, VEHICLE_MAINTANACE_SEARCH, VEHICLE_PREINSPECTION_SEARCH } from '../../../../store/endpoint';
import { fetch_fuel_list, fetch_truck_maintanace_list, preinspection_truck_list } from '../truck/common/action';
import { fetch_car_maintanace_list, preinspection_car_list } from '../car/common/action';
import { car_fuel_fetch } from '../car/common/action';
import { forkLift_fuel_fetch, preinspection_forklift_list } from '../forklift/common/action';
import { fetch_forklift_maintanace_list } from '../forklift/common/action';
import { CircularProgress } from '@material-ui/core';
import { failed_validation } from '../../../../common/SearchErrorDestructure';

function SearchTab({ tabType, vType, subType, setSearchDataFx=null}) {

    const [searchTxt, setSearchTxt] = useState({ prev: null, now: ''})
    const [loadSearch, setLoadSearch] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        const request = axios.CancelToken.source()
        let data = new FormData()
        if(subType==='mntc')
            data.append('key',searchTxt.now)
        else 
            data.append('registration',searchTxt.now)
        console.log("searching user",searchTxt.now,request)
        if((searchTxt.now!=='')&&(searchTxt.prev !== searchTxt.now)){

            if(vType === 'truck'){
                if(subType === 'mntc')
                    dispatch({ type: 'TRUCK_MAINTANACE_LIST_FETCHING', keyword: searchTxt.now,page:1 })
                else if(subType === 'pre'){
                    dispatch({ type: 'FETCHING_PREINSP_LIST',keyword: searchTxt.now,page:1 })
                }else  if(subType === 'fuel')
                    dispatch({ type: 'TRUCK_FUEL_LIST_FETCHING',keyword: searchTxt.now,page:1})
                else 
                    return null
            }else if(vType === 'car' || vType === 'utes'){
                if(subType === 'mntc')
                    dispatch({ type: 'CAR_MAINTANACE_LIST_FETCHING', keyword: searchTxt.now,page:1})
                else if(subType === 'pre')
                    dispatch({
                        type: 'FETCHING_CAR_PREINSP_LIST', keyword: searchTxt.now,page:1})
                else  if(subType === 'fuel')
                    dispatch({ type: 'CAR_FUEL_LIST_FETCHING', keyword: searchTxt.now ,page:1})
                else 
                    return null
            }else{
                if(subType === 'mntc')
                    dispatch({ type: 'FORKLIFT_MAINTANACE_LIST_FETCHING', keyword: searchTxt.now,page:1})
                else if(subType === 'pre')
                    dispatch({
                        type: 'FETCH_FORKLIFT_PREINSP_LIST', keyword: searchTxt.now ,page:1})
                else  if(subType === 'fuel')
                    dispatch({ type: 'FORKLIFT_FUEL_LIST_FETCHING', keyword: searchTxt.now,page:1})
                else 
                    return null
            }
        
        let state = store.getState()
        let token = state.userLogin.authToken
        
        var config = {
            method: 'post',
            url: subType==='mntc' ? VEHICLE_MAINTANACE_SEARCH.replace('VTYPE',`${tabType}/${vType}`) + `?page=1&limit=15` :   
                 subType==='pre'  ? VEHICLE_PREINSPECTION_SEARCH.replace('VTYPE',`${tabType}/${vType}`) + `?page=1&limit=15` :
                    VEHICLE_FUEL_SEARCH.replace('VTYPE',`${tabType}/${vType}`) + `?page=1&limit=15`,
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

            axios(config)
            .then((res) => {
                setLoadSearch(false)
                if(setSearchDataFx!==null)
                    setSearchDataFx(searchTxt.now)
                if(vType === 'truck'){
                    if(subType === 'mntc')
                        dispatch({ type: 'TRUCK_MAINTANACE_LIST', maintanace: res.data,page:1 })
                    else if(subType === 'pre'){
                        dispatch({type: 'PREINSP_LIST_SUCCESS',trucks: res.data,page: 1})
                    }else  if(subType === 'fuel')
                        // dispatch({ type: 'TRUCK_FILTERED_FUEL_LIST', trucks: res.data, filterKey: 'all'})
                        dispatch({ type: 'TRUCK_FUEL_LIST', fuel: res.data, page: 1})
                    else 
                        return null
                }else if(vType === 'car' || vType === 'utes'){
                    if(subType === 'mntc')
                        dispatch({ type: 'CAR_MAINTANACE_LIST', maintanace: res.data,page:1 })
                    else if(subType === 'pre')
                        dispatch({ type: 'CAR_PREINSP_LIST_SUCCESS',trucks: res.data,page: 1})
                    else  if(subType === 'fuel')
                        dispatch({ type: 'CAR_FUEL_LIST', fuel: res.data, page: 1 })
                    else 
                        return null
                }else{
                    if(subType === 'mntc')
                        dispatch({ type: 'FORKLIFT_MAINTANACE_LIST', maintanace: res.data,page:1 })
                    else if(subType === 'pre')
                        dispatch({type: 'FORKLIFT_PREINSP_LIST_SUCCESS',trucks: res.data,page: 1})
                    else  if(subType === 'fuel')
                        dispatch({ type: 'FORKLIFT_FUEL_LIST', fuel: res.data, page: 1 })
                    else 
                        return null
                }
            })
            .catch((error) => {
                let err = failed_validation(error)
                console.log(err);
                setLoadSearch(false)
                if(vType === 'truck'){
                    if(subType === 'mntc')
                        dispatch({ type: 'TRUCK_MAINTANACE_LIST_FAILED', error: err })
                    else if(subType === 'pre'){
                        dispatch({type: 'PREINSP_LIST_ERROR', error: err})
                    }else  if(subType === 'fuel')
                        // dispatch({ type: 'TRUCK_FILTERED_FUEL_LIST', trucks: res.data, filterKey: 'all'})
                        dispatch({ type: 'TRUCK_FUEL_LIST_ERROR', error: err})
                    else 
                        return null
                }else if(vType === 'car' || vType === 'utes'){
                    if(subType === 'mntc')
                        dispatch({ type: 'CAR_MAINTANACE_LIST_FAILED', error: err })
                    else if(subType === 'pre')
                        dispatch({
                            type: 'CAR_PREINSP_LIST_ERROR', error: err})
                    else  if(subType === 'fuel')
                        dispatch({ type: 'CAR_FUEL_LIST_ERROR', error: err})
                    else 
                        return null
                }else{
                    if(subType === 'mntc')
                        dispatch({ type: 'FORKLIFT_MAINTANACE_LIST_FAILED', error: err })
                    else if(subType === 'pre')
                        dispatch({
                            type: 'FORKLIFT_PREINSP_LIST_ERROR', error: err})
                    else  if(subType === 'fuel')
                        dispatch({ type: 'FORKLIFT_FUEL_LIST_ERROR', error: err})
                    else 
                        return null
                }
            });
        }
        else{
            setLoadSearch(false)
            if(searchTxt.prev!==null){
            if(vType === 'truck'){
                if(subType === 'mntc')
                    dispatch(fetch_truck_maintanace_list(tabType,1))
                else if(subType === 'pre')
                    dispatch(preinspection_truck_list(tabType,1))
                else if(subType === 'fuel')
                    dispatch(fetch_fuel_list(tabType,1))
                else 
                        return null
            }else if(vType === 'car' || vType === 'utes'){
                if(subType === 'mntc')
                    dispatch(fetch_car_maintanace_list(tabType, 1))
                else if(subType === 'pre')
                    dispatch(preinspection_car_list(tabType, 1))
                else  if(subType === 'fuel')
                    dispatch(car_fuel_fetch(tabType, 1))
                else 
                        return null
            }else{
                if(subType === 'mntc')
                    dispatch(fetch_forklift_maintanace_list(tabType, 1))
                else if(subType === 'pre')
                    dispatch(preinspection_forklift_list(tabType, 1))
                else  if(subType === 'fuel')
                    dispatch(forkLift_fuel_fetch(tabType, 1))
                else 
                        return null
            }}
        }
        
        return () => request.cancel()
    }, [searchTxt, vType, dispatch, tabType, subType, setSearchDataFx])


    return (
        <>
        <input 
        type="text" 
        className="sgr-mntnce-rprt-search" 
        placeholder={ subType==='mntc' ? `Search by Invoice, License PLate Number` : 'Search By Vehicle Registration'} 
        value={searchTxt.now}
        onChange={txt => {
            setSearchTxt({ prev : searchTxt.now, now : txt.target.value})
            setLoadSearch(true)
        }}
        />
        { loadSearch ?
                <CircularProgress size='15px' color='inherit' />
                : null
        }
        </>
    );
}

export default SearchTab;