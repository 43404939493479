import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import Swal from 'sweetalert2'
import DateTimeInput from '../../../../common/DateTimeInput';
// import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import moment from 'moment';
// import { post_request, patch_request } from '../../../Store/api'
import { takeAllPrint } from '../../../client/action'
import Scrollbars from '../../../../common/ImprovedScrollBar';
import Failerrdevmsg from '../../../../common/failErrDevMsg';
// import { ArrowDropDownCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: theme.shadows[5],
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function TransitionsModal({ 
      page, 
      modalStatus, 
      modalAction, 
      selected_vehicle, 
      edit_vehicle, 
      create_vehicle,
      tabType,
      response_page 
}) {

  const classes = useStyles();
  const handleClose = () => {
    modalAction(false)
  };

  
  let registration_data = ''
  let type_data = ''
  let year_rego_data = ''
  let transmission_rego_data = ''
  let fuel_data = ''
  let height_data = ''
  let width_data = ''
  let length_data = ''
  let liters_data = ''
  let axles_rego_data = ''
  let vinNumber_data = ''
  let regoDate_data = moment(new Date()).format('YYYY-MM-DD')
  let engine_no_data = ''
  let insurance_data = ''
  let fuelCard_data = ''
  let tollGate_data = ''
  let FTB_data = ''
  let vehicle_id = ''
  let vehicle_image_arr = ''

  if(selected_vehicle.id) {
     const { id, registration, types, year, transmission, fuel, height, width, length, 
               litres, axies, vin_number, due_rego, engine_numbers, insurance, fuel_card, 
               e_tag, fbt, image1 
            } = selected_vehicle
     console.log("SELEcTED VEHICLE",selected_vehicle)
      // prev_rego_data = previous_rego
      registration_data = registration
      type_data = types
      year_rego_data = year
      transmission_rego_data = transmission
      fuel_data = fuel
      height_data = height
      width_data = width
      length_data = length
      liters_data = litres
      axles_rego_data = axies
      vinNumber_data = vin_number
      regoDate_data = due_rego
      engine_no_data = engine_numbers
      insurance_data = insurance
      fuelCard_data = fuel_card
      tollGate_data = e_tag
      FTB_data = fbt
      vehicle_id = id
      vehicle_image_arr = image1

  }

//   const [PrvRego, setPrvRego] = useState(prev_rego_data)
  const [Registration, setRegistration] = useState(registration_data)
  const [Type, setType] = useState(type_data)
  const [Year, setYear] = useState(year_rego_data)
  const [Transmission, setTransmission] = useState(transmission_rego_data)
  const [Fuel, setFuel] = useState(fuel_data)
  const [Height, setHeight] = useState(height_data)
  const [Width, setWidth] = useState(width_data)
  const [Length, setLength] = useState(length_data)
  const [Liters, setLiters] = useState(liters_data)
  const [Axles, setAxles] = useState(axles_rego_data)
  const [VinNumber, setVinNumber] = useState(vinNumber_data)
  const [RegoDue, setRegoDue] = useState(regoDate_data)
  const [EngineNo, setEngineNo] = useState(engine_no_data)
  const [Insurance, setInsurance] = useState(insurance_data)
  const [FuelCard, setFuelCard] = useState(fuelCard_data)
  const [TollGate, setTollGate] = useState(tollGate_data)
  const [FTB, setFTB] = useState(FTB_data)

  const [preInspReq, setPreInspReq] = useState(selected_vehicle?.preinspection_required??true)

  const [uploadLoader, setUploadLoader] = useState(false)
//   const [profileImage, setprofileImage] = useState([])
//   const [profileImageTemp, setprofileImageTemp] = useState( vehicle_image!=='' ? HOST_WITHOUT_SLASH+vehicle_image:null )
  const [VehicleImages, setVehicleImages] = useState(vehicle_image_arr)
  const [multiImages, setMultiImages] = useState( vehicle_image_arr==='' ? false : true)

  const add_vehicle_fx = () => {
     setUploadLoader(true)
   let form_body = new FormData()
   let page_type = ''
   if(page === 'truck') {
      page_type = 'truck'
   } else if(page === 'car') {
      page_type = 'car'
   } else if(page === 'utes') {
      page_type = 'utes'
   } else if(page === 'machinery') {
      page_type = 'machinery'
   } else {
      page_type = 'fork-lift'
   }

   form_body.append('vehicle_type', page_type)
   // form_body.append('previous_rego', PrvRego)
   form_body.append('registration', Registration)
   form_body.append('types', Type)
   form_body.append('year', Year)

   form_body.append('transmission', Transmission)
   form_body.append('height', Height)
   form_body.append('width', Width)
   form_body.append('length', Length)
   form_body.append('litres', Liters)

   form_body.append('vin_number', VinNumber)
   form_body.append('axies', Axles)
   form_body.append('due_rego', RegoDue)
   form_body.append('engine_numbers', EngineNo)
   form_body.append('fuel', Fuel)

   form_body.append('insurance', Insurance)
   form_body.append('fuel_card', FuelCard)
   form_body.append('e_tag', TollGate)
   form_body.append('fbt', FTB)
   form_body.append('image1', VehicleImages)
   form_body.append('tab_type', tabType)

   form_body.append('preinspection_required', preInspReq)


//    if(profileImageTemp) {
//       let splitData = profileImageTemp.split(':')
//       if(splitData.length > 0) {
//           if(splitData[0] === 'blob') {
//               let file_name = profileImage.name
//               form_body.append('image1', profileImage, file_name)
//           }
//       }
//   }
   

   const success = (response) => {
      setUploadLoader(false)
       modalAction(false)
       Swal.fire({
         position: 'top-end',
         icon: 'success',
         title: 'Vehicle added successfull!',
         showConfirmButton: false,
         timer: 1500
     })
   }

   const failed = (res) => {
      setUploadLoader(false)
      modalAction(false)
      
      
      //  Swal.fire({
      //      icon: 'error',
      //      title: 'Oops...',
      //      text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!',
      //  })
      Failerrdevmsg(res)
   }

   create_vehicle(tabType, form_body, success, failed)
}


const edit_vehicle_fx = () => {
   setUploadLoader(true)
   let form_body = new FormData()
   let page_type = ''

   if(page === 'truck') {
      page_type = 'truck'
   } else if(page === 'car') {
      page_type = 'car'
   } else if(page === 'utes') {
      page_type = 'utes'
   } else if(page === 'machinery') {
      page_type = 'machinery'
   } else {
      page_type = 'fork-lift'
   }

   form_body.append('tab_type', tabType)
   form_body.append('vehicle_type', page_type)
   
   // form_body.append('previous_rego', PrvRego)
   form_body.append('registration', Registration)
   form_body.append('types', Type)
   if(moment(Year).isValid())
      form_body.append('year', Year)

   form_body.append('transmission', Transmission)
   form_body.append('height', Height)
   form_body.append('width', Width)
   form_body.append('length', Length)
   form_body.append('litres', Liters)

   form_body.append('vin_number', VinNumber)
   form_body.append('axies', Axles)
   if(moment(RegoDue).isValid())
      form_body.append('due_rego', RegoDue)
   form_body.append('engine_numbers', EngineNo)
   form_body.append('fuel', Fuel)

   form_body.append('insurance', Insurance)
   form_body.append('fuel_card', FuelCard)
   form_body.append('e_tag', TollGate)
   form_body.append('fbt', FTB)
   form_body.append('preinspection_required', preInspReq)
   if(VehicleImages!==selected_vehicle.image1)
      form_body.append('image1',VehicleImages)
   
//    if(profileImageTemp) {
//       let splitData = profileImageTemp.split(':')
//       if(splitData.length > 0) {
//           if(splitData[0] === 'blob') {
//               let file_name = profileImage.name
//               form_body.append('image1', profileImage, file_name)
//           }
//       }
//   }

   const success = (response) => {
      setUploadLoader(false)
       modalAction(false)
       Swal.fire({
         position: 'top-end',
         icon: 'success',
         title: 'Vehicle edited successfull!',
         showConfirmButton: false,
         timer: 1500
     })
   }

   const failed = (res) => {
      setUploadLoader(false)
      modalAction(false)
       Swal.fire({
           icon: 'error',
           title: 'Oops...',
           text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
       })
   }
   edit_vehicle(
      tabType,
      vehicle_id, 
      form_body, 
      success, 
      failed, 
   //   response_page
   )
}

let add_btn_style = 'regi-vehc-btn disabled-btn'
let edit_btn_style = 'regi-vehc-btn disabled-btn'

if(page === 'truck') {
   if(Registration && Type) {
      add_btn_style = 'regi-vehc-btn'
   }
} else if(page === 'car') {
   if(Registration && Type) {
      add_btn_style = 'regi-vehc-btn'
   }
} else {
   if(Registration && Type ) {
      add_btn_style = 'regi-vehc-btn'
   }
}

if(   registration_data!== Registration || type_data !== Type            || year_rego_data !== Year       || transmission_rego_data !== Transmission|| 
      fuel_data !== Fuel            || height_data !== Height        || width_data !== Width          || length_data !== Length                 || 
      liters_data !== Liters        || axles_rego_data !== Axles     || vinNumber_data !== VinNumber  || regoDate_data !== RegoDue              || 
      engine_no_data !== EngineNo   || insurance_data !== Insurance  || fuelCard_data !== FuelCard    || tollGate_data !== TollGate             ||
      FTB_data !== FTB              || multiImages ) {
   
   edit_btn_style = 'regi-vehc-btn'
}

const importMultipleImages = (e) =>{
   let file = e.target.files[0]
      setVehicleImages(file)
      setMultiImages(true)
}

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={modalStatus}
      onClose={handleClose}
      closeAfterTransition
    >
      <div className="team-edit-setting-layout">
      {  selected_vehicle.id ?
      <div
      style={{
         display:'flex',
         flexDirection: 'row',
         justifyContent:'space-between'
      }}
      >
      <p className="team-edit-title">Update Vehicle</p>
         <div  style={{ display: 'flex', flexDirection:'row' }}
         >      
            <button onClick={()=> takeAllPrint(vehicle_id,tabType,'v_id') } className="regi-vehc-btn">Print</button>               
            <button 
              onClick={() => edit_vehicle_fx()}
              className={edit_btn_style}>Update
            </button>
         </div>
                        
      </div>
      :
      <p className="team-edit-title">Add New Vehicle</p>
      }
                <div className="team-edit-setting-main">
                <Scrollbars autoHide style={{ height:'50vh' }} >

                  <div className="box-one-add-items">
                      <div className="box-one-add-items-split-left">
                        {
                           selected_vehicle.id ? (
                              <div className="team-data-noraml ">
                                 <div className="team-data-edit-left">
                                    <p className="p-0 m-0">Registration
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                    </p>
                                 </div>
                                 <div className="team-data-edit-center">
                                 <p className="p-0 m-0">: </p>
                                 </div>
                                 <div className="team-data-edit-right">
                                    <input 
                                       value={Registration}
                                       onChange={(txt) => setRegistration(txt.target.value)}
                                       type="text" 
                                       placeholder="Registration"
                                       className="team-input" 
                                    />
                                 </div>
                              </div>
                           ) : (
                              <div className="team-data-noraml">
                                 <div className="team-data-edit-left">
                                    <p className="p-0 m-0">Registration
                                    
                                       <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                    
                                    </p>
                                 </div>
                                 <div className="team-data-edit-center">
                                 <p className="p-0 m-0">: </p>
                                 </div>
                                 <div className="team-data-edit-right">
                                    <input 
                                       value={Registration}
                                       onChange={(txt) => setRegistration(txt.target.value)}
                                       type="text" 
                                       placeholder="Registration"
                                       className="team-input" 
                                    />
                                 </div>
                              </div>
                           )
                        }                    

                        {/* {
                           page !== 'car' && (
                              <div className="team-data-noraml">
                                 <div className="team-data-edit-left">
                                    <p className="p-0 m-0">Previous REGO</p>
                                 </div>
                                 <div className="team-data-edit-center">
                                 <p className="p-0 m-0">: </p>
                                 </div>
                                 <div className="team-data-edit-right">
                                    
                                    <DateTimeInput
                                       dateValue={PrvRego}
                                       dateSetFn={setPrvRego}
                                       clsName={'team-input'}
                                    />
                                    
                                    {/* <input 
                                       value={PrvRego}
                                       onChange={(txt) => setPrvRego(txt.target.value)}
                                       type="date" 
                                       placeholder="Previous REGO"
                                       className="team-input" 
                                    /> *
                                 </div>
                              </div>
                           ) 
                        } */}
                          

                           

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Type
                                 <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                 </p>
                              </div>
                              <div className="team-data-edit-center">
                                <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Type}
                                    onChange={(txt) => setType(txt.target.value)}
                                    type="text" 
                                    placeholder="Type"
                                    className="team-input" 
                                   />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Year</p>
                              </div>
                              <div className="team-data-edit-center">
                                <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Year}
                                    onChange={(txt) => setYear(txt.target.value)}
                                    type="number" 
                                    maxLength='6'
                                    placeholder="Year"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Transmission</p>
                              </div>
                              <div className="team-data-edit-center">
                                <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Transmission}
                                    onChange={(txt) => setTransmission(txt.target.value)}
                                    type="text" 
                                    placeholder="Transmission"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Fuel</p>
                              </div>
                              <div className="team-data-edit-center">
                                <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Fuel}
                                    onChange={(txt) => setFuel(txt.target.value)}
                                    type="text" 
                                    placeholder="Fuel"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           {/* {
                              page !== 'car' ? (
                                 <>
                                 <div className="team-data-noraml">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Height</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          value={Height}
                                          onChange={(txt) => setHeight(txt.target.value)}
                                          type="text" 
                                          placeholder="Height"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>

                                 <div className="team-data-noraml">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Width</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          value={Width}
                                          onChange={(txt) => setWidth(txt.target.value)}
                                          type="text" 
                                          placeholder="Width"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>

                                 <div className="team-data-noraml">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Length</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          value={Length}
                                          onChange={(txt) => setLength(txt.target.value)}
                                          type="text" 
                                          placeholder="Length"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>
                                 </>
                              ) : (
                                 <>
                                 <div className="team-data-noraml inactive">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Height</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          disabled
                                          value={Height}
                                          onChange={(txt) => setHeight(txt.target.value)}
                                          type="text" 
                                          placeholder="Height"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>

                                 <div className="team-data-noraml inactive">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Width</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          disabled
                                          value={Height}
                                          onChange={(txt) => setHeight(txt.target.value)}
                                          type="text" 
                                          placeholder="Width"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>

                                 <div className="team-data-noraml inactive">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Length</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          disabled
                                          value={Length}
                                          onChange={(txt) => setLength(txt.target.value)}
                                          type="text" 
                                          placeholder="Length"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>
                                 </>
                              )
                           } */}

{/* ======================UPDATED================= */} 

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Height</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Height}
                                    onChange={(txt) => setHeight(txt.target.value)}
                                    type="text" 
                                    placeholder="Height"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Width</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Width}
                                    onChange={(txt) => setWidth(txt.target.value)}
                                    type="text" 
                                    placeholder="Width"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Length</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Length}
                                    onChange={(txt) => setLength(txt.target.value)}
                                    type="text" 
                                    placeholder="Length"
                                    className="team-input" 
                                 />
                              </div>
                           </div>
                           
{/* ======================UPDATED================= */}

                           {/* {
                              page === 'truck' && (
                              <div className="team-data-noraml">
                                 <div className="team-data-edit-left">
                                    <p className="p-0 m-0">Litres</p>
                                 </div>
                                 <div className="team-data-edit-center">
                                 <p className="p-0 m-0">: </p>
                                 </div>
                                 <div className="team-data-edit-right">
                                    <input 
                                       value={Liters}
                                       onChange={(txt) => setLiters(txt.target.value)}
                                       type="text" 
                                       placeholder="Litres"
                                       className="team-input" 
                                    />
                                 </div>
                              </div>
                              )
                           } */}


                           {/* ======================UPDATED================= */}

                              <div className="team-data-noraml">
                                 <div className="team-data-edit-left">
                                    <p className="p-0 m-0">Litres</p>
                                 </div>
                                 <div className="team-data-edit-center">
                                 <p className="p-0 m-0">: </p>
                                 </div>
                                 <div className="team-data-edit-right">
                                    <input 
                                       value={Liters}
                                       onChange={(txt) => setLiters(txt.target.value)}
                                       type="text" 
                                       placeholder="Litres"
                                       className="team-input" 
                                    />
                                 </div>
                              </div>


                           {/* ======================UPDATED================= */}

                           

                      </div>

                      <div className="box-one-add-items-split-right">
                           {/* <div className="box-one-add-items-split-right-img">
                                <div className="title-box-one-add-items-split-right-img">
                                 <p className="vehicle-add-img-title">Vehicle Images</p>
                                </div>
                                <div className="add-box-one-add-items-split-right-img">
                                        <div className="select-imag-add-one">
                                           <img className="select-img-1" src="https://base.imgix.net/files/base/ebm/fleetowner/image/2020/04/ZECT_Truck_Picture__3___Side_View.5eaac921905a0.png?auto=format&fit=max&w=1200" alt="img"/>
                                        </div >

                                        <div className="select-imag-add-one">
                                           <img className="select-img-1" src="https://base.imgix.net/files/base/ebm/fleetowner/image/2020/04/ZECT_Truck_Picture__3___Side_View.5eaac921905a0.png?auto=format&fit=max&w=1200" alt="img"/>
                                        </div>

                                        <div className="select-imag-add-one">
                                           <img className="select-img-1" src="https://base.imgix.net/files/base/ebm/fleetowner/image/2020/04/ZECT_Truck_Picture__3___Side_View.5eaac921905a0.png?auto=format&fit=max&w=1200" alt="img"/>
                                        </div>
                                </div>
                           </div> */}

                           <div className="box-one-add-items-split-right-content">
                             {/* {
                                page === 'truck' && (
                                 <div className="team-data-noraml">
                                    <div className="team-data-edit-left">
                                       <p className="p-0 m-0">Axles</p>
                                    </div>
                                    <div className="team-data-edit-center">
                                    <p className="p-0 m-0">: </p>
                                    </div>
                                    <div className="team-data-edit-right">
                                       <input 
                                          value={Axles}
                                          onChange={(txt) => setAxles(txt.target.value)}
                                          type="text" 
                                          placeholder="Axles"
                                          className="team-input" 
                                       />
                                    </div>
                                 </div>
                                )
                             }  */}

                           {/* ======================UPDATED================= */}
                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Axles</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Axles}
                                    onChange={(txt) => setAxles(txt.target.value)}
                                    type="text" 
                                    placeholder="Axles"
                                    className="team-input" 
                                 />
                              </div>
                           </div>
                           
                           {/* ======================UPDATED================= */}

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Vin Number</p>
                              </div>
                              <div className="team-data-edit-center">
                                <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={VinNumber}
                                    onChange={(txt) => setVinNumber(txt.target.value)}
                                    type="text"
                                    placeholder="VIN NUMBER"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Rego Due</p>
                              </div>
                              <div className="team-data-edit-center">
                                <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 
                                 <DateTimeInput
                                    dateValue={RegoDue}
                                    dateSetFn={setRegoDue}
                                    clsName={'team-input'}
                                 />
                                 
                                 {/* <input 
                                    value={RegoDue}
                                    onChange={(txt) => setRegoDue(txt.target.value)}
                                    type="date" 
                                    placeholder="REGO DUE"
                                    className="team-input" 
                                 /> */}
                              </div>
                           </div>
{/* 
                           {
                              page === 'car' ? (
                                 <>
                                    <div className="team-data-noraml">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">Engine No</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input 
                                             value={EngineNo}
                                             onChange={(txt) => setEngineNo(txt.target.value)}
                                             type="text" 
                                             placeholder="Engine No"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">Insurance</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input 
                                             value={Insurance}
                                             onChange={(txt) => setInsurance(txt.target.value)}
                                             type="text" 
                                             placeholder="Insurance"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">Fuel Card</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input 
                                             value={FuelCard}
                                             onChange={(txt) => setFuelCard(txt.target.value)}
                                             type="text" 
                                             placeholder="Fuel Card"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">E Tag</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input 
                                             value={TollGate}
                                             onChange={(txt) => setTollGate(txt.target.value)}
                                             type="text" 
                                             placeholder="E Tag"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">FBT</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input 
                                             value={FTB}
                                             onChange={(txt) => setFTB(txt.target.value)}
                                             type="text" 
                                             placeholder="FBT"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>
                                 </>
                              ) : (
                                 <>
                                    <div className="team-data-noraml inactive">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">Engine No</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input
                                             disabled 
                                             value={EngineNo}
                                             onChange={(txt) => setEngineNo(txt.target.value)}
                                             type="text" 
                                             placeholder="Engine No"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml inactive">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">Insurance</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input
                                             disabled 
                                             value={Insurance}
                                             onChange={(txt) => setInsurance(txt.target.value)}
                                             type="text" 
                                             placeholder="Insurance"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml inactive">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">Fuel Card</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input
                                             disabled 
                                             value={FuelCard}
                                             onChange={(txt) => setFuelCard(txt.target.value)}
                                             type="text" 
                                             placeholder="Fuel Card"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml inactive">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">E Tag</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input
                                             disabled 
                                             value={TollGate}
                                             onChange={(txt) => setTollGate(txt.target.value)}
                                             type="text" 
                                             placeholder="E Tag"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>

                                    <div className="team-data-noraml inactive">
                                       <div className="team-data-edit-left">
                                          <p className="p-0 m-0">FBT</p>
                                       </div>
                                       <div className="team-data-edit-center">
                                       <p className="p-0 m-0">: </p>
                                       </div>
                                       <div className="team-data-edit-right">
                                          <input
                                             disabled 
                                             value={FTB}
                                             onChange={(txt) => setFTB(txt.target.value)}
                                             type="text" 
                                             placeholder="FBT"
                                             className="team-input" 
                                          />
                                       </div>
                                    </div>
                                 </>
                              )
                           } */}



{/* ======================UPDATED================= */}
                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Engine No</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={EngineNo}
                                    onChange={(txt) => setEngineNo(txt.target.value)}
                                    type="text" 
                                    placeholder="Engine No"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Insurance</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={Insurance}
                                    onChange={(txt) => setInsurance(txt.target.value)}
                                    type="text" 
                                    placeholder="Insurance"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">Fuel Card</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={FuelCard}
                                    onChange={(txt) => setFuelCard(txt.target.value)}
                                    type="text" 
                                    placeholder="Fuel Card"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">E Tag</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={TollGate}
                                    onChange={(txt) => setTollGate(txt.target.value)}
                                    type="text" 
                                    placeholder="E Tag"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml">
                              <div className="team-data-edit-left">
                                 <p className="p-0 m-0">FBT</p>
                              </div>
                              <div className="team-data-edit-center">
                              <p className="p-0 m-0">: </p>
                              </div>
                              <div className="team-data-edit-right">
                                 <input 
                                    value={FTB}
                                    onChange={(txt) => setFTB(txt.target.value)}
                                    type="text" 
                                    placeholder="FBT"
                                    className="team-input" 
                                 />
                              </div>
                           </div>

                           <div className="team-data-noraml" style={{ paddingLeft:'0%', paddingRight:'0%', justifyContent:'flex-start' }}>
                              <div className="team-data-edit-right" style={{ width : 'auto', marginRight:'2%' }}>
                                 <input
                                    type="checkbox"
                                    className="team-input vehicle1"
                                    checked={preInspReq}
                                    onClick={()=>{ setPreInspReq(!preInspReq) }}
                                 />
                              </div>
                              <div className="team-data-edit-left" style={{ width : 'auto' }}>
                                 <p className="p-0 m-0">Daily Pre-inspection Required</p>
                              </div>
                           </div>
{/* ======================UPDATED================= */}

                           {/* <div className="client-crt-top"> */}
                           
                           <div className="vehicleMultiImages">   
                              <label htmlFor="file-input">
                           {  multiImages ?
                              <div className="client-crt-dp Vehicle-multi-crt-dp" >
                                 <img className="client-crt-dp-img Vehicle-multi-crt-dp-img" src={
                                 selected_vehicle.id && selected_vehicle.image1 && VehicleImages===selected_vehicle.image1 ?
                                 `${HOST_WITHOUT_SLASH}${selected_vehicle.image1}`
                                 :
                                 URL.createObjectURL(VehicleImages)   
                                 } alt="" />
                                 <div className="document-delete" onClick={()=>{setMultiImages(false);} } >
                                    <div style={{textDecoration:'none'}}>
                                       <EditRoundedIcon style={{ fontSize: 18,  margin: 0, padding: 0 }}/>
                                    </div>
                                 </div>
                              </div>
                              :
                              
                                 <div className="client-crt-dp Vehicle-multi-crt-dp">
                                    <AddAPhotoOutlinedIcon style={{ fontSize: 24, padding: 0, margin: 0 }}/>
                                    <p style={{ fontSize: 14, padding: 0, margin: 0 }}>Add Photo</p>
                                 </div>   
                           }
                                 <input id="file-input" style={{ display: 'none' }} type="file" accept={"image/*"} multiple={false}
                                 onChange={(image) => {
                                    importMultipleImages(image)
                                  }}
                                 />
                              </label>
                              </div>      
                           
                           </div>
                      </div>
                  </div> 
                  
                  </Scrollbars>
                  {
                     selected_vehicle.id ? (
                        null
                     ) : (
                        <div className="box-two-add-items">      
                           <button 
                              onClick={() =>{
                                 if(!uploadLoader)
                                    add_vehicle_fx()
                                 }} 
                              className={add_btn_style}>{ uploadLoader ? <CircularProgress size={10} /> : 'Submit'}</button>                   
                        </div>
                     )
                  }
                                     
              </div>
            </div>


    </Modal>
);
}