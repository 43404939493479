import React, { useState, useEffect } from 'react'
import Scrollbar from '../../../../../common/ImprovedScrollBar'
import { __create_preinspection, fetch_all_car_list } from '../common/action'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import DateTimeInput from '../../../../../common/DateTimeInput'
import moment from 'moment'
import { NUMBER_VALIDATION_REGEX } from '../../../../../store/config'
import { ReactComponent as Tick } from '../../../../../assets/Images/check.svg'
import { TextAreaLimiter } from '../../../../../common/TextAreaLimiter'

function App({
    all_car_list,
    fetch_all_cars,
    tabType
}) {

    useEffect(() => {
        fetch_all_cars()
        return () => fetch_all_cars.remove
    }, [fetch_all_cars])

    let driver_id = '12'
    let vehicle_id

    if (all_car_list.length > 0) {
        vehicle_id = all_car_list[0].id
    }

    let route_history = useHistory();

    const [Vehicle, setVehicle] = useState(vehicle_id)
    const [Odometer, setOdometer] = useState('')
    const [DriverSignature, setDriverSignature] = useState('')
    const [HourMeterStart, setHourMeterStart] = useState('')

    const [FitForWork, setFitForWork] = useState(false)
    const [ValidDrivingLicense, setValidDrivingLicense] = useState(false)
    const [AppropriatePPE, setAppropriatePPE] = useState(false)

    const [EngineOilLevel, setEngineOilLevel] = useState({ box: false, val: null })
    const [WarningSystem, setWarningSystem] = useState({ box: false, val: null })
    const [Steering, setSteering] = useState({ box: false, val: null })
    const [SafetyEmrgStop, setSafetyEmrgStop] = useState({ box: false, val: null })
    const [HandBreakAlram, setHandBreakAlram] = useState({ box: false, val: null })
    const [PTOVacPump, setPTOVacPump] = useState({ box: false, val: null })

    const [Horn, setHorn] = useState({ box: false, val: null })
    const [RevAlarmCamera, setRevAlarmCamera] = useState({ box: false, val: null })
    const [LightsHead, setLightsHead] = useState({ box: false, val: null })
    const [LightsTail, setLightsTail] = useState({ box: false, val: null })
    const [LightsBeacon, setLightsBeacon] = useState({ box: false, val: null })
    const [HazardLight, setHazardLight] = useState({ box: false, val: null })
    const [HouseKeeping, setHouseKeeping] = useState({ box: false, val: null })

    const [RimsWheels, setRimsWheels] = useState({ box: false, val: null })
    const [Coolant, setCoolant] = useState({ box: false, val: null })
    const [Wheels, setWheels] = useState({ box: false, val: null })
    const [MirrorWindows, setMirrorWindows] = useState({ box: false, val: null })
    const [StructureBody, setStructureBody] = useState({ box: false, val: null })
    const [Wipers, setWipers] = useState({ box: false, val: null })

    const [FuelLevelPump, setFuelLevelPump] = useState({ box: false, val: null })
    const [FuelLevelTruck, setFuelLevelTruck] = useState({ box: false, val: null })
    const [SeatSeatbelt, setSeatSeatbelt] = useState({ box: false, val: null })
    const [ParkBreakTrailer, setParkBreakTrailer] = useState({ box: false, val: null })
    const [FootBrake, setFootBrake] = useState({ box: false, val: null })
    const [Electrical, setElectrical] = useState({ box: false, val: null })
    const [PinRetainer, setPinRetainer] = useState({ box: false, val: null })

    const [Hoses, setHoses] = useState(false)
    const [Fittings, setFittings] = useState(false)
    const [FirstAidKit, setFirstAidKit] = useState(false)
    const [PPE, setPPE] = useState(false)
    const [FireExtingusre, setFireExtingusre] = useState(false)
    const [FireExtingusreDate, setFireExtingusreDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [GardenHose, setGardenHose] = useState(false)
    const [GaticLiters, setGaticLiters] = useState(false)
    const [BucketRag, setBucketRag] = useState('')
    const [SpillKit, setSpillKit] = useState(false)

    const [FaultReportz, setFaultReportz] = useState('')

    const [ReadyToOperate, setReadyToOperate] = useState(false)
    const [ReportedFaults, setReportedFaults] = useState(false)
    const [ReviewdForm, setReviewdForm] = useState(false)

    const [Corrected, setCorrected] = useState(false)
    const [ScheduledForRepair, setScheduledForRepair] = useState(false)
    const [NoAction, setNoAction] = useState(false)
    const [DoNotAffectSafeOperation, setDoNotAffectSafeOperation] = useState(false)
    const [textAreaValid, setTextAreaValid] = useState(true)

    let submit_btn = false;

    if (Odometer && DriverSignature && HourMeterStart && FitForWork && ValidDrivingLicense && AppropriatePPE && ReadyToOperate && ReportedFaults && ReviewdForm) {
        submit_btn = true
    }

    const submit_form = () => {
        let form_body = new FormData()

        form_body.append("vehicle", Vehicle)
        form_body.append("odometer", Odometer)
        form_body.append("driver_name", driver_id)
        form_body.append("odometdriver_signature", DriverSignature)
        form_body.append("hour_meter_start", HourMeterStart)

        form_body.append("fit_for_work", FitForWork)
        form_body.append("Valid_driving_license", ValidDrivingLicense)
        form_body.append("appropriate_ppe", AppropriatePPE)

        form_body.append("engine_oil_level", EngineOilLevel.val)
        form_body.append("warning_system", WarningSystem.val)
        form_body.append("steering", Steering.val)
        form_body.append("safety_emerg_stop", SafetyEmrgStop.val)
        form_body.append("handbreak_alarm", HandBreakAlram.val)
        form_body.append("pto_vacpump", PTOVacPump.val)

        form_body.append("horn", Horn.val)
        form_body.append("rev_alarm_camera", RevAlarmCamera.val)
        form_body.append("lights_head", LightsHead.val)
        form_body.append("lights_tail", LightsTail.val)
        form_body.append("light_beacons", LightsBeacon.val)
        form_body.append("hazard_light", HazardLight.val)
        form_body.append("house_keeping", HouseKeeping.val)

        form_body.append("rims_wheelnut", RimsWheels.val)
        form_body.append("coolant", Coolant.val)
        form_body.append("wheels", Wheels.val)
        form_body.append("mirror_windowscreen", MirrorWindows.val)
        form_body.append("structure_bodywork", StructureBody.val)
        form_body.append("wipers", Wipers.val)

        form_body.append("fuel_levelpump", FuelLevelPump.val)
        form_body.append("fuel_leveltruck", FuelLevelTruck.val)
        form_body.append("seat_seatbelt", SeatSeatbelt.val)
        form_body.append("parkbrake_trailer", ParkBreakTrailer.val)
        form_body.append("foot_brake", FootBrake.val)
        form_body.append("electrical", Electrical.val)
        form_body.append("pin_retainers", PinRetainer.val)

        form_body.append("hoses", Hoses)
        form_body.append("fittings", Fittings)
        form_body.append("first_aid_kit", FirstAidKit)
        form_body.append("ppe", PPE)
        form_body.append("fire_extinguisher", FireExtingusre)
        form_body.append("fire_extinguisher_date", FireExtingusreDate)

        form_body.append("garden_hose", GardenHose)
        form_body.append("gatic_lifters", GaticLiters)
        form_body.append("bucket_rags", BucketRag)
        form_body.append("spill_kit", SpillKit)

        form_body.append("reported_faults", ReportedFaults)
        form_body.append("reported_fault_string", FaultReportz)
        form_body.append("reviewed_form", ReviewdForm)
        form_body.append("corrected", Corrected)
        form_body.append("scheduled_for_repair", ScheduledForRepair)

        form_body.append("no_action", NoAction)
        form_body.append("do_not_affect_safe_operation", DoNotAffectSafeOperation)

        form_body.append('safe_ready_to_operate', ReadyToOperate);
        form_body.append('signature', DriverSignature);
        form_body.append('vehicle_type', tabType === 'pumps' ? 'utes' : 'car')
        form_body.append('tab_type', tabType)

        // form_body.append('name', 'Jithin');
        // form_body.append('date_time', '2020-12-1');
        // form_body.append('driver_name', '1');


        const success = () => {
            route_history.push(`/vehicle/${tabType === 'pumps' ? 'utes' : 'car'}/preinspection`)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Pre-inspection added successfull!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        __create_preinspection(tabType, form_body, success, failed)
    }

    return (
        <div className="PreInspectionCheck">
            <div className="pre-topbar">
                <div className="sgr-mntnce-rprt-topbar">
                    <button className="sgr-mntnce-rprt-topbarBtn2">Master - {tabType === 'pumps' ? 'Utes' : 'Car'}</button>
                    <button className="sgr-mntnce-rprt-topbarBtn2">Pre-Inspection Check</button>
                </div>


                {/* <div className="sgr-mntnce-rprt-topbar">
                    <Link className="sgr-mntnce-rprt-topbar" to="vehicle/truck/preinspection">
                        <button className="sgr-mntnce-rprt-addNew">Pre Inpection List</button>
                    </Link>
                </div> */}


            </div>
            <p style={{ fontSize: 14, marginTop: 10, marginLeft: 5 }} className="p-0 m-0">Vehicle Safety Pre-Start Inspection Checklist</p>
            <Scrollbar style={{ height: '75vh', marginTop: 10 }}>

                <div className="pre-box-1">
                    <div className="pre-box-1-top">
                        <div className="pre-box-1-left">

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Date & Time</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    <p style={{ color: 'red' }} className="p-0 m-0">{moment().format('YYYY-MM-DD hh:mm:ss')}</p>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Vehicle Registration</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <select
                                        value={Vehicle}
                                        onChange={txt => setVehicle(txt.target.value)}
                                        className="pre-box-1-select"
                                    >
                                        {
                                            all_car_list.map((item, key) => {
                                                const { id, registration } = item
                                                return (
                                                    <option key={key} value={id}>{registration}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Odometer</p>
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <input
                                        className="pre-box-1-input"

                                        placeholder="Odometer"
                                        value={Odometer}
                                        onChange={txt => {
                                            if (txt.target.value === '')
                                                setOdometer('')
                                            else if (NUMBER_VALIDATION_REGEX.test(txt.target.value))
                                                setOdometer(txt.target.value)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="pre-box-1-right">


                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Drivers Name</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    <p style={{ color: 'red' }} className="p-0 m-0">Auto Fetch</p>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0" >Drivers Signature</p>
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <input
                                        className="pre-box-1-input"
                                        type="text"
                                        placeholder="Signature"
                                        value={DriverSignature}
                                        onChange={txt => setDriverSignature(txt.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Hour Meter Start</p>
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <input
                                        className="pre-box-1-input"
                                        type="text"
                                        placeholder="Hour Meter Start"
                                        value={HourMeterStart}
                                        onChange={txt => setHourMeterStart(txt.target.value)}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="pre-box-1-bottom">
                        <div className="pre-box-1-bottom-check">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={FitForWork}
                                onChange={() => setFitForWork(!FitForWork)}
                            />
                            <label> I am Fit for Work</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>

                        <div className="pre-box-1-bottom-check">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={ValidDrivingLicense}
                                onChange={() => setValidDrivingLicense(!ValidDrivingLicense)}
                            />
                            <label> I have the appropriate valid driving license</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>

                        <div className="pre-box-1-bottom-check">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={AppropriatePPE}
                                onChange={() => setAppropriatePPE(!AppropriatePPE)}
                            />
                            <label> Appropriate PPE for operation of this vehicle</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>
                    </div>
                </div>

                <p style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }} className="p-0 m-0">Pre-Start Checklist-All Fields are  Mandatory (No Obivous Defect)(X-Fault Identified) & N/A not applicable</p>

                <div className="pre-box-2">

                    <p
                        style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }}
                        className="p-0 m-0">
                        Category "A" Fault: Vehicle
                        <span style={{ color: 'red' }}> MUST NOT be operated  </span>
                        until fault is rectified
                    </p>

                    <p
                        style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }}
                        className="p-0 m-0">
                        Category "B" Fault: Vehicle
                        <span style={{ color: '#0F99D0' }}> May be operated  </span>
                        Corrective action is required
                    </p>

                </div>
                <div className="pre-box-3">
                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Engine Oil Level</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Warning System</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Steering</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Safety / Emerg Stops</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Hand Brake Alarm</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">PTO / Vac. Pump</p>
                            </div>

                            <div className="pre-box-3-row-body">

                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={EngineOilLevel}
                                    onChange={() => setEngineOilLevel(!EngineOilLevel)}
                                />       */}
                                <div className={EngineOilLevel.val === null ? "checkmark"
                                    : EngineOilLevel.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setEngineOilLevel({ box: true, val: null })}>{EngineOilLevel.val === null ? <Tick />: <Tick />}</div>
                                <div className={EngineOilLevel.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setEngineOilLevel({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setEngineOilLevel({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setEngineOilLevel({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={WarningSystem}
                                    onChange={() => setWarningSystem(!WarningSystem)}
                                />       */}
                                <div className={WarningSystem.val === null ? "checkmark"
                                    : WarningSystem.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setWarningSystem({ box: true, val: null })}>{WarningSystem.val === null ? <Tick />: <Tick />}</div>
                                <div className={WarningSystem.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setWarningSystem({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setWarningSystem({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setWarningSystem({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Steering}
                                    onChange={() => setSteering(!Steering)}
                                />       */}
                                <div className={Steering.val === null ? "checkmark"
                                    : Steering.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setSteering({ box: true, val: null })}>{Steering.val === null ? <Tick />: <Tick />}</div>
                                <div className={Steering.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setSteering({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setSteering({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setSteering({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={SafetyEmrgStop}
                                    onChange={() => setSafetyEmrgStop(!SafetyEmrgStop)}
                                />       */}
                                <div className={SafetyEmrgStop.val === null ? "checkmark"
                                    : SafetyEmrgStop.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setSafetyEmrgStop({ box: true, val: null })}>{SafetyEmrgStop.val === null ? <Tick />: <Tick />}</div>
                                <div className={SafetyEmrgStop.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setSafetyEmrgStop({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setSafetyEmrgStop({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setSafetyEmrgStop({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={HandBreakAlram}
                                    onChange={() => setHandBreakAlram(!HandBreakAlram)}
                                />       */}
                                <div className={HandBreakAlram.val === null ? "checkmark"
                                    : HandBreakAlram.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setHandBreakAlram({ box: true, val: null })}>{HandBreakAlram.val === null ? <Tick />: <Tick />}</div>
                                <div className={HandBreakAlram.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setHandBreakAlram({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setHandBreakAlram({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setHandBreakAlram({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={PTOVacPump}
                                    onChange={() => setPTOVacPump(!PTOVacPump)}
                                />       */}
                                <div className={PTOVacPump.val === null ? "checkmark"
                                    : PTOVacPump.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setPTOVacPump({ box: true, val: null })}>{PTOVacPump.val === null ? <Tick />: <Tick />}</div>
                                <div className={PTOVacPump.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setPTOVacPump({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setPTOVacPump({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setPTOVacPump({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">

                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Horn</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Rev alarm / Camera</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Lights-Head</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Lights-Tail</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Light-beacons</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Hazards-Light</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className=" p-0 m-0">House keeping</p>
                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Horn}
                                    onChange={() => setHorn(!Horn)}
                                />       */}
                                <div className={Horn.val === null ? "checkmark"
                                    : Horn.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setHorn({ box: true, val: null })}>{Horn.val === null ? <Tick />: <Tick />}</div>
                                <div className={Horn.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setHorn({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setHorn({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setHorn({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={RevAlarmCamera}
                                    onChange={() => setRevAlarmCamera(!RevAlarmCamera)}
                                />      */}
                                <div className={RevAlarmCamera.val === null ? "checkmark"
                                    : RevAlarmCamera.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setRevAlarmCamera({ box: true, val: null })}>{RevAlarmCamera.val === null ? <Tick />: <Tick />}</div>
                                <div className={RevAlarmCamera.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setRevAlarmCamera({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setRevAlarmCamera({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setRevAlarmCamera({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={LightsHead}
                                    onChange={() => setLightsHead(!LightsHead)}
                                />      */}
                                <div className={LightsHead.val === null ? "checkmark"
                                    : LightsHead.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setLightsHead({ box: true, val: null })}>{LightsHead.val === null ? <Tick />: <Tick />}</div>
                                <div className={LightsHead.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setLightsHead({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setLightsHead({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setLightsHead({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={LightsTail}
                                    onChange={() => setLightsTail(!LightsTail)}
                                />      */}
                                <div className={LightsTail.val === null ? "checkmark"
                                    : LightsTail.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setLightsTail({ box: true, val: null })}>{LightsTail.val === null ? <Tick />: <Tick />}</div>
                                <div className={LightsTail.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setLightsTail({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setLightsTail({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setLightsTail({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={LightsBeacon}
                                    onChange={() => setLightsBeacon(!LightsBeacon)}
                                />      */}
                                <div className={LightsBeacon.val === null ? "checkmark"
                                    : LightsBeacon.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setLightsBeacon({ box: true, val: null })}>{LightsBeacon.val === null ? <Tick />: <Tick />}</div>
                                <div className={LightsBeacon.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setLightsBeacon({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setLightsBeacon({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setLightsBeacon({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={HazardLight}
                                    onChange={() => setHazardLight(!HazardLight)}
                                />      */}
                                <div className={HazardLight.val === null ? "checkmark"
                                    : HazardLight.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setHazardLight({ box: true, val: null })}>{HazardLight.val === null ? <Tick />: <Tick />}</div>
                                <div className={HazardLight.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setHazardLight({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setHazardLight({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setHazardLight({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={HouseKeeping}
                                    onChange={() => setHouseKeeping(!HouseKeeping)}
                                />      */}
                                <div className={HouseKeeping.val === null ? "checkmark"
                                    : HouseKeeping.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setHouseKeeping({ box: true, val: null })}>{HouseKeeping.val === null ? <Tick />: <Tick />}</div>
                                <div className={HouseKeeping.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setHouseKeeping({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setHouseKeeping({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setHouseKeeping({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Rims & Wheel Nuts</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Coolant</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Wheels & Tyres</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Mirrors & Windscreen</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Structure & Bodywork</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Wipers</p>
                            </div>

                            <div className="pre-box-3-row-body">

                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={RimsWheels}
                                    onChange={() => setRimsWheels(!RimsWheels)}
                                />    */}
                                <div className={RimsWheels.val === null ? "checkmark"
                                    : RimsWheels.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setRimsWheels({ box: true, val: null })}>{RimsWheels.val === null ? <Tick />: <Tick />}</div>
                                <div className={RimsWheels.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setRimsWheels({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setRimsWheels({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setRimsWheels({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Coolant}
                                    onChange={() => setCoolant(!Coolant)}
                                />    */}
                                <div className={Coolant.val === null ? "checkmark"
                                    : Coolant.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCoolant({ box: true, val: null })}>{Coolant.val === null ? <Tick />: <Tick />}</div>
                                <div className={Coolant.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCoolant({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setCoolant({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCoolant({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Wheels}
                                    onChange={() => setWheels(!Wheels)}
                                />    */}
                                <div className={Wheels.val === null ? "checkmark"
                                    : Wheels.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setWheels({ box: true, val: null })}>{Wheels.val === null ? <Tick />: <Tick />}</div>
                                <div className={Wheels.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setWheels({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setWheels({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setWheels({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={MirrorWindows}
                                    onChange={() => setMirrorWindows(!MirrorWindows)}
                                />    */}
                                <div className={MirrorWindows.val === null ? "checkmark"
                                    : MirrorWindows.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setMirrorWindows({ box: true, val: null })}>{MirrorWindows.val === null ? <Tick />: <Tick />}</div>
                                <div className={MirrorWindows.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setMirrorWindows({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setMirrorWindows({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setMirrorWindows({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={StructureBody}
                                    onChange={() => setStructureBody(!StructureBody)}
                                />    */}
                                <div className={StructureBody.val === null ? "checkmark"
                                    : StructureBody.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setStructureBody({ box: true, val: null })}>{StructureBody.val === null ? <Tick />: <Tick />}</div>
                                <div className={StructureBody.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setStructureBody({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setStructureBody({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setStructureBody({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Wipers}
                                    onChange={() => setWipers(!Wipers)}
                                />    */}
                                <div className={Wipers.val === null ? "checkmark"
                                    : Wipers.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setWipers({ box: true, val: null })}>{Wipers.val === null ? <Tick />: <Tick />}</div>
                                <div className={Wipers.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setWipers({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setWipers({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setWipers({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">

                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Fuel Level Pump</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Fuel Level Truck</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Seat / Seat Belt</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Park Brake/Trailer</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Foot Brake</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Electrical</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Pin Retainers</p>
                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FuelLevelPump}
                                    onChange={() => setFuelLevelPump(!FuelLevelPump)}
                                />    */}


                                <div className={FuelLevelPump.val === null ? "checkmark"
                                    : FuelLevelPump.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setFuelLevelPump({ box: true, val: null })}>{FuelLevelPump.val === null ? <Tick />: <Tick />}</div>
                                <div className={FuelLevelPump.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setFuelLevelPump({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setFuelLevelPump({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setFuelLevelPump({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FuelLevelTruck}
                                    onChange={() => setFuelLevelTruck(!FuelLevelTruck)}
                                />      */}

                                <div className={FuelLevelTruck.val === null ? "checkmark"
                                    : FuelLevelTruck.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setFuelLevelTruck({ box: true, val: null })}>{FuelLevelTruck.val === null ? <Tick />: <Tick />}</div>
                                <div className={FuelLevelTruck.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setFuelLevelTruck({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setFuelLevelTruck({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setFuelLevelTruck({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={SeatSeatbelt}
                                    onChange={() => setSeatSeatbelt(!SeatSeatbelt)}
                                />      */}

                                <div className={SeatSeatbelt.val === null ? "checkmark"
                                    : SeatSeatbelt.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setSeatSeatbelt({ box: true, val: null })}>{SeatSeatbelt.val === null ? <Tick />: <Tick />}</div>
                                <div className={SeatSeatbelt.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setSeatSeatbelt({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setSeatSeatbelt({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setSeatSeatbelt({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={ParkBreakTrailer}
                                    onChange={() => setParkBreakTrailer(!ParkBreakTrailer)}
                                />      */}

                                <div className={ParkBreakTrailer.val === null ? "checkmark"
                                    : ParkBreakTrailer.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setParkBreakTrailer({ box: true, val: null })}>{ParkBreakTrailer.val === null ? <Tick />: <Tick />}</div>
                                <div className={ParkBreakTrailer.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setParkBreakTrailer({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setParkBreakTrailer({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setParkBreakTrailer({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FootBrake}
                                    onChange={() => setFootBrake(!FootBrake)}
                                />      */}

                                <div className={FootBrake.val === null ? "checkmark"
                                    : FootBrake.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setFootBrake({ box: true, val: null })}>{FootBrake.val === null ? <Tick />: <Tick />}</div>
                                <div className={FootBrake.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setFootBrake({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setFootBrake({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setFootBrake({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Electrical}
                                    onChange={() => setElectrical(!Electrical)}
                                />      */}

                                <div className={Electrical.val === null ? "checkmark"
                                    : Electrical.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setElectrical({ box: true, val: null })}>{Electrical.val === null ? <Tick />: <Tick />}</div>
                                <div className={Electrical.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setElectrical({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setElectrical({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setElectrical({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={PinRetainer}
                                    onChange={() => setPinRetainer(!PinRetainer)}
                                />    */}
                                <div className={PinRetainer.val === null ? "checkmark"
                                    : PinRetainer.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setPinRetainer({ box: true, val: null })}>{PinRetainer.val === null ? <Tick />: <Tick />}</div>
                                <div className={PinRetainer.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setPinRetainer({ box: false, val: null })} >No Issues</div>
                                        <div onClick={() => setPinRetainer({ box: false, val: true })} style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setPinRetainer({ box: false, val: false })} style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="pre-box-3-2">
                    <div className="pre-box-3-2-left">
                        <p className="p-0 m-0">Accesseories and Fittings</p>
                    </div>
                    <div className="pre-box-3-2-right">
                        <div className="pre-box-3-2-right-top">
                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={Hoses}
                                    onChange={() => setHoses(!Hoses)}
                                />
                                <label> Hoses</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={Fittings}
                                    onChange={() => setFittings(!Fittings)}
                                />
                                <label> Fittings</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={FirstAidKit}
                                    onChange={() => setFirstAidKit(!FirstAidKit)}
                                />
                                <label> First Aid Kit</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={PPE}
                                    onChange={() => setPPE(!PPE)}
                                />
                                <label> PPE</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={FireExtingusre}
                                    onChange={() => setFireExtingusre(!FireExtingusre)}
                                />
                                <label> Fire Extinguisher(Date Caibrated)</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">

                                <DateTimeInput
                                    dateValue={FireExtingusreDate}
                                    dateSetFn={setFireExtingusreDate}
                                    clsName={"vehicle1"}
                                />

                                {/* <input 
                                    type="date" 
                                    className="vehicle1"
                                    value={FireExtingusreDate}
                                    onChange={txt => setFireExtingusreDate(txt.target.value)}
                                />                     */}
                            </div>

                        </div>
                        <div className="pre-box-3-2-right-bottom">
                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={GardenHose}
                                    onChange={() => setGardenHose(!GardenHose)}
                                />
                                <label>Garden Hoses</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={GaticLiters}
                                    onChange={() => setGaticLiters(!GaticLiters)}
                                />
                                <label> Gattic Lifters</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={BucketRag}
                                    onChange={() => setBucketRag(!BucketRag)}
                                />
                                <label> Bucket / Rags</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={SpillKit}
                                    onChange={() => setSpillKit(!SpillKit)}
                                />
                                <label> Spill Kitt</label>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="pre-box-4">
                    <div className="pre-box-4-left">
                        <p className="p-0 m-0">Any Fault to Report (any Category) & any additional information</p>
                    </div>

                    <div className="pre-box-4-right">
                        <TextAreaLimiter isValid={setTextAreaValid}
                        configs={{
                            className : "pre-box-4-textarea",
                            placeholder : "Text Here...",
                            value : FaultReportz,
                            onChange : txt => setFaultReportz(txt.target.value)
                        }}
                        v={FaultReportz} />
                    </div>
                </div>

                <div className="pre-box-5">
                    <div className="pre-box-5-top">
                        <div className="pre-box-5-top-head">
                            <p className="p-0 m-0">Action Taken</p>
                        </div>
                        <div className="pre-box-5-top-head">
                            <p className="p-0 m-0">Authorized By</p>
                        </div>
                    </div>
                    <div className="pre-box-5-bottom">
                        <div className="pre-box-5-top-body">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={ReadyToOperate}
                                onChange={() => setReadyToOperate(!ReadyToOperate)}
                            />
                            <label> I have conducted the above pre-start checklist and satisfied that the vehicle is safe and ready to operate.</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>
                        <div className="pre-box-5-top-body">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={ReportedFaults}
                                onChange={() => setReportedFaults(!ReportedFaults)}
                            />
                            <label> I have reported all faults in appropriate section and notified the authorized personnel.</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>
                        <div className="pre-box-5-top-body">
                            <p style={{ textAlign: 'right', marginLeft: 'auto' }} className="p-0 m-0">Driver's  Signature</p>
                        </div>
                    </div>
                </div>

                <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>Manager Brief and Review</p>
                <div className="pre-box-6">
                    <div className="pre-box-6-top">
                        <input
                            type="checkbox"
                            className="vehicle1"
                            checked={ReviewdForm}
                            onChange={() => setReviewdForm(!ReviewdForm)}
                        />
                        <label> I have reviewed this form and satisfied that required maintenance or safety related items have been addressed .</label>
                        <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                    </div>
                    <div className="pre-box-6-center">
                        <div className="pre-box-6-center-left">
                            <p className="p-0 m-0">I Certify that faults reported have been</p>
                        </div>
                        <div className="pre-box-6-center-right">
                            <div className="pre-box-6-center-right-top">
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={Corrected}
                                        onChange={() => setCorrected(!Corrected)}
                                    />
                                    <label> Corrected</label>
                                </div>
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={ScheduledForRepair}
                                        onChange={() => setScheduledForRepair(!ScheduledForRepair)}
                                    />
                                    <label> Schedule  for repair</label>
                                </div>
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={NoAction}
                                        onChange={() => setNoAction(!NoAction)}
                                    />
                                    <label>No Action</label>
                                </div>
                            </div>
                            <div className="pre-box-6-center-right-bottom">
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={DoNotAffectSafeOperation}
                                        onChange={() => setDoNotAffectSafeOperation(!DoNotAffectSafeOperation)}
                                    />
                                    <label> Issues scheduled for maintenance or repair do not affect the safe operation of this vehicle</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pre-box-6-bottom">

                    </div>
                </div>

                <div className="pre-box-6-submit">
                    {
                        submit_btn && textAreaValid ? (
                            <button onClick={() => submit_form()} className="pre-box-submit-btn">Submit</button>
                        ) : (
                            <button className="pre-box-submit-btn disabled-btn">Submit</button>
                        )
                    }

                </div>

            </Scrollbar>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { all_car_list } = state.vehicle__car
    return { all_car_list }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_all_cars: () => dispatch(fetch_all_car_list()),
        // create_preinspection: (data, success, failed) => dispatch(__create_preinspection(data, success, failed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

