import React from 'react'
import Sidebar from '../../../../common/sidebar'
import Header from '../common/Header'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ClientDetail from './ClientDetail'
import { CircularProgress } from '@material-ui/core';

function App({ client, tabType }) {
    const { selected_client, singleClientLoad } = client
    if(selected_client === undefined && !singleClientLoad) return (<Redirect to='/client' />)
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='detail' />
            {   singleClientLoad ?
                <div style={{ position: 'absolute', marginTop: '20%', marginLeft: '50%' }}>
                <CircularProgress />
                </div>
            :
                <ClientDetail tabType={tabType}/>
            }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { client } = state
    return { client }
}

export default connect(mapStateToProps) (App)
