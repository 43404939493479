import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux';
import { __vehicle_modal, __edit_maintanace, __create_maintanace } from '../common/action'
import Swal from 'sweetalert2'
import DateTimeInput from '../../../../../common/DateTimeInput';
import { NUMBER_VALIDATION_REGEX, PRICE_VALIDATION } from '../../../../../store/config';
import { HOST_WITHOUT_SLASH } from '../../../../../store/endpoint';
import { DeleteForever, DescriptionRounded } from '@material-ui/icons';
import { TextAreaLimiter } from '../../../../../common/TextAreaLimiter';

// import { post_request, patch_request } from '../../../Store/api'

const useStyles = makeStyles((theme) => ({
    modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: theme.shadows[5],
    },
    paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
    },
}));

function TransitionsModal({ 
    selected_car_maintanance,
    vehicle_modal_action,
    vehicle_modal,
    all_car_list,
    maintanace_edit,
    car_maintanance_page,
    maintanace_create,
    tabType
}) {
    const classes = useStyles();

    const handleClose = () => {
        vehicle_modal_action(false)
    };

    // let selected_car_maintanance={}
    // let all_car_list=[]

    // let vehicle_id = all_car_list[0].id
    let license = ''

    if(all_car_list.length > 0) {
        license = all_car_list[0].id
    }
    let descp = ''
    let invDate = ''
    let serviceDate = ''
    let Omtr = ''
    let invNo = ''
    let serviceProv = ''
    let hrs = ''
    let lCost = ''
    let sPart = ''
    let Gst = ''
    let ttlCost = ''
    let maintance_id = ''

    if(selected_car_maintanance.id){
        const { id, registration, description, invoice_date, service_date, ometer, invoice_number, service_provided, hours, l_cost, s_part, gst, total_cost} = selected_car_maintanance
        let vehicle_data = all_car_list.find(item => item.registration === registration)
        if(vehicle_data) {
            license = vehicle_data.id
        }
        descp = description
        invDate = invoice_date
        serviceDate = service_date
        Omtr = ometer
        invNo = invoice_number
        serviceProv = service_provided
        hrs = hours
        lCost = l_cost
        sPart = s_part
        Gst = gst
        ttlCost = total_cost 
        maintance_id = id
        // console.log(license, descp, invDate, serviceDate, Omtr, invNo, serviceProv, hrs, lCost, sPart, Gst, ttlCost, maintance_id, "MODAL")
    }

    const [License, setLicense] = useState(license)
    const [Description, setDescription] = useState(descp)
    const [InvoiceDate, setInvDate] = useState(invDate)
    const [ServiceDate, setServDate] = useState(serviceDate)
    const [Ometer, setOmeter] = useState(Omtr)
    const [InvoiceNo, setInvNo] = useState(invNo)
    const [ServiceProvd, setServProvd] = useState(serviceProv)
    const [Hours, setHours] = useState(hrs)
    const [LCost, setLCost] = useState(lCost)
    const [SPart, setSPart] = useState(sPart)
    const [GST, setGst] = useState(Gst)
    const [TotalCost, setTotalCost] = useState(ttlCost)
    const [vehicleFiles, setVehicleFiles] = useState([])
    const [multiFiles, setMultiFiles] = useState(false)
    const [uploadLoader, setUploadLoader] = useState(false)
    const [textAreaValid, setTextAreaValid] = useState(true)
    const importMultifiles = (e) =>{
        let files = []
        for(var i=0; i < e.target.files.length; ++i)
            files.push(e.target.files[i])
        setVehicleFiles(files)
           setMultiFiles(true)
     }
     

    const add_mntnce_click = () => {
        setUploadLoader(true)
        let form_body = new FormData()
        form_body.append('vehicle', License)
        form_body.append('description', Description)
        form_body.append('invoice_date', InvoiceDate)
        form_body.append('ometer', Ometer)
        form_body.append('service_date', ServiceDate)
        form_body.append('invoice_number', InvoiceNo)
        form_body.append('service_provided', ServiceProvd)
        form_body.append('hours', Hours)
        form_body.append('l_cost', LCost)
        form_body.append('gst', GST)
        form_body.append('s_part', SPart)
        form_body.append('total_cost', TotalCost)
        form_body.append('tab_type',tabType)
        form_body.append('vehicle_type',tabType==='pumps'?'utes':'car')
        for(var i=0; i<vehicleFiles.length; ++i)
            form_body.append('file', vehicleFiles[i])
        
        const success = (response) => {
            handleClose()
            setUploadLoader(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Report added successfully!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            handleClose()
            setUploadLoader(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }


        maintanace_create(tabType, form_body, success, failed)

    }

    const edit_mntnce_click = () => {
        setUploadLoader(true)
        let form_body = new FormData()
        form_body.append('vehicle', License)
        form_body.append('description', Description)
        form_body.append('invoice_date', InvoiceDate)
        form_body.append('ometer', Ometer)
        form_body.append('service_date', ServiceDate)
        form_body.append('invoice_number', InvoiceNo)
        form_body.append('service_provided', ServiceProvd)
        form_body.append('hours', Hours)
        form_body.append('l_cost', LCost)
        form_body.append('gst', GST)
        form_body.append('s_part', SPart)
        form_body.append('total_cost', TotalCost)
        form_body.append('tab_type',tabType)

        const success = (response) => {
            setUploadLoader(false)
            handleClose()
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Report Editted successfull!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            setUploadLoader(false)
            handleClose()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }
         maintanace_edit(tabType, maintance_id, form_body, success, failed, car_maintanance_page )

    }


    const SingleFile = ({item, key}) => {
        const { name, url } = item
        let fileUrl = HOST_WITHOUT_SLASH + url
        let split_arr = fileUrl.split('.')

        const cropFileName = (str) =>{
            let lastIdx = str ? str.length : null
            if(lastIdx)
                return lastIdx > 15 ? `${str.substr(0,10)}...${str.substr(str.length-3, str.length)}` : str 
            else 
                return 'Untitled'
        }
    
        
        return (
            <Tooltip
                title={name} 
                arrow={true} placement={'top'}
                >
                
                <div 
                    className={"intranet-main-file-card mntc-file-mini" }
                    onClick={(e)=>{ 
                        e.stopPropagation(); 
                        e.preventDefault(); 
                    }}>
                    
                    <div 
                        className="singleFileDeleteIcon" 
                        onClick={(e)=>{ 
                            e.preventDefault(); 
                            e.stopPropagation(); 
                            let newArr = vehicleFiles.filter((_,k) => k!==key)
                            setVehicleFiles(newArr)
                            
                        }}>
                        <DeleteForever style={{ fontSize:18 }} />
                    </div>
                    <div onClick={() =>{ window.open(fileUrl, "_blank"); }}  className="main-file-icon">
                        {
                            split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif' ?
                                <img src={fileUrl} alt="ImageError" style={{ objectFit:'cover', width: 100, height: 100 }} />
                            :
                                <DescriptionRounded style={{ fontSize: 40 }} />
                        }
                    </div>
                    <div className="main-file-title">
                        <div style={{ display: 'block' }}>
                            <p style={{ height: 17, width: 192, overflow: 'hidden' }}>{cropFileName(name)}</p>
                            
                        </div>
                    </div>
                </div>
            </Tooltip>
        )
    }


    let add_btn_style = 'regi-vehc-btn disabled-btn'
    let edit_btn_style = 'regi-vehc-btn disabled-btn'

    if(License && Description && InvoiceDate && ServiceDate && Ometer && ServiceProvd && Hours && LCost && SPart && GST && TotalCost && textAreaValid) {
        add_btn_style = 'regi-vehc-btn'
    }
        
    if(textAreaValid && (License !== license || Description !== descp || InvoiceDate !== invDate || ServiceDate !== serviceDate || Ometer !== Omtr || InvoiceNo !== invNo || ServiceProvd !== serviceProv || Hours !== hrs || LCost !== lCost || SPart !== sPart || GST !== Gst  || TotalCost !== ttlCost)) {
        edit_btn_style = 'regi-vehc-btn'
    }

    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={vehicle_modal}
        onClose={handleClose}
        closeAfterTransition
    >
            <div className="mntnce-addNew-Modal-layout">
                <div className="mntnce-addNew-Modal-main">
                    <p className="mntnce-addNew-Modal-title"> {selected_car_maintanance.id ? 'Maintanance Details' : 'Add New Maintanance Record'} </p>
                    <div className="modal-display-content">
                        <div className="modal-disPart1">
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">License Plate Number</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <select
                                        value={License}
                                        onChange={(txt) => setLicense(txt.target.value)}
                                        className="mntnce-modal-input"  
                                    >
                                        {
                                            all_car_list.map((item, key) => {
                                                const { id, registration } = item
                                                return (
                                                    <option key={key} value={id}>{registration}</option>
                                                )
                                            })
                                        }
                                        
                                    </select>
                                </div>
                            </div>
                            
                            <div className="mntnce-addNew-Modal-noraml" style={{
                                        height : '10vh', alignItems : 'flex-start'
                                    }}>
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Description</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                            <TextAreaLimiter
                                                v={Description}
                                                isValid={setTextAreaValid}
                                                max={600}
                                                configs={{
                                                    className   : "mntnce-modal-input",
                                                    value       :   Description,
                                                    style       :   { resize : 'none', height : '8vh' },
                                                    onChange    :   (txt) => setDescription(txt.target.value)      
                                                }}
                                            />
                                    {/* <input 
                                        value={Description}
                                        onChange={(txt) => setDescription(txt.target.value)}
                                        className="mntnce-modal-input" 
                                        type="text" 
                                        name="" 
                                        id="" 
                                    /> */}
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Invoice Date</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    
                                    <DateTimeInput
                                        dateValue={InvoiceDate}
                                        dateSetFn={setInvDate}
                                        clsName={'mntnce-modal-input'}
                                    />
                                    
                                    {/* <input 
                                        value={InvoiceDate}
                                        onChange={(txt) => setInvDate(txt.target.value)}
                                        className="mntnce-modal-input" 
                                        type="date" 
                                        name="" 
                                        id="" 
                                    /> */}
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Ometer</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={Ometer}
                                        onChange={(txt) => setOmeter(txt.target.value)}
                                        className="mntnce-modal-input" 
                                        type="text" 
                                        name="" 
                                        id="" />
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Service Date</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    
                                    <DateTimeInput
                                        dateValue={ServiceDate}
                                        dateSetFn={setServDate}
                                        clsName={'mntnce-modal-input'}
                                    />
                                    
                                    {/* <input value={ServiceDate}
                                        onChange={(txt) => setServDate(txt.target.value)}
                                        className="mntnce-modal-input" 
                                        type="date" 
                                        name="" 
                                        id="" /> */}
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Invoice No</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={InvoiceNo}
                                        onChange={(txt) =>{
                                            if(txt.target.value==='')
                                                setInvNo('')
                                            else if(NUMBER_VALIDATION_REGEX.test(txt.target.value))
                                                setInvNo(txt.target.value)}}
                                        className="mntnce-modal-input" 
                                         
                                        name="" 
                                        id="" />
                                </div>
                            </div>
                        </div>

                        <div className="modal-dis-Part2">
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Service Provided</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={ServiceProvd}
                                    onChange={(txt) => setServProvd(txt.target.value)}
                                    className="mntnce-modal-input" 
                                    type="text" 
                                    name="" 
                                    id="" />
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Hours</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={Hours}
                                    onChange={(txt) => setHours(txt.target.value)}
                                    className="mntnce-modal-input" 
                                    type="text" 
                                    name="" 
                                    id="" />
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">L Cost</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={LCost}
                                    onChange={(txt) => setLCost(txt.target.value)}
                                    className="mntnce-modal-input" 
                                    type="text" 
                                    name="" 
                                    id="" />
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">S Parts</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={SPart}
                                    onChange={(txt) => setSPart(txt.target.value)}
                                    className="mntnce-modal-input" 
                                    type="text" 
                                    name="" 
                                    id="" />
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">GST</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={GST}
                                    onChange={(txt) => setGst(txt.target.value)}
                                    className="mntnce-modal-input" 
                                    type="text" 
                                    name="" 
                                    id="" />
                                </div>
                            </div>
                            <div className="mntnce-addNew-Modal-noraml">
                                <div className="mntnce-addNew-Modal-edit-left">
                                    <p className="p-0 m-0">Total Cost</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">: </p>
                                </div>

                                <div className="mntnce-addNew-Modal-edit-center">
                                    <p className="p-0 m-0">$</p>
                                </div>
                                <div className="mntnce-addNew-Modal-edit-right">
                                    <input value={TotalCost}
                                    onChange={(txt) =>{
                                        if(txt.target.value==='')
                                            setTotalCost(txt.target.value)
                                        else if(PRICE_VALIDATION.test(txt.target.value))
                                            setTotalCost(txt.target.value)
                                        }}
                                    className="mntnce-modal-input" 
                                     
                                    name="" 
                                    id="" />
                                </div>
                            </div>

                        </div>

                    </div>
                            {   selected_car_maintanance.id ? null :
                            <div className='mntnce-fileList-grid'>
                                {
                                    multiFiles && vehicleFiles && vehicleFiles.length!==0 ?
                                        vehicleFiles && vehicleFiles.map((item, k) => {
                                            return(
                                                <SingleFile item={{name : item.name, url : URL.createObjectURL(item)}} key={k} />
                                            )
                                        })
                                        : null
                                }
                            </div>
                            }
                            <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around' }}>
                                    {   selected_car_maintanance.id ? null :
                                    <div className="box-two-add-items add-file-mntnce">  
                                        <label htmlFor="file-input">
                                        <input type='file'  id="file-input"  multiple={true}
                                         onChange={(e) => {
                                            importMultifiles(e)
                                  }} />
                                        <button 
                                            className='regi-vehc-btn'>Add Files</button>       
                                        </label>            
                                    </div>
                                    }
                                    {
                        selected_car_maintanance.id ? (
                            <div className="box-two-add-items">      
                                <button 
                                    onClick={() =>{
                                        if(!uploadLoader)
                                            edit_mntnce_click()}}
                                    className={edit_btn_style}>{ uploadLoader ? <CircularProgress size={10}/> : 'Update'}</button>                   
                            </div>
                        ) : (
                            <div className="box-two-add-items">      
                                <button 
                                    onClick={() =>{
                                        if(!uploadLoader)
                                            add_mntnce_click()}} 
                                    className={add_btn_style}>{ uploadLoader ? <CircularProgress size={10} /> : 'Submit'}</button>                   
                            </div>
                            )
                    }
                            
                                    
                            </div>
                    
                    
                </div>
            </div>


    </Modal>
    );
}

const mapStateToProps = (state) => {
    const { vehicle_modal, selected_car_maintanance, all_car_list, car_maintanance_page } = state.vehicle__car
    return { vehicle_modal, selected_car_maintanance, all_car_list, car_maintanance_page }
}

const mapDispatchToProps = (dispatch) => {
    return {
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        maintanace_edit: (tabType, maint_id, data, success, failed, page_no) => dispatch(__edit_maintanace(tabType, maint_id, data, success, failed, page_no)),
        maintanace_create: (tabType, data, success, failed) => dispatch(__create_maintanace(tabType, data, success, failed))
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal)
