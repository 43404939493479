
let initialState = {
    job_list: [],
    job_list_page: 1,

    job_list_RFSchd_loader: false,
    job_list_RFSchd: [],
    job_list_RFSchd_page: 1,
    job_list_weekFilter: 4,
    stopPagingJob: false,

    job_list_RFSchd_loader_ALL: false,
    job_list_RFSchd_ALL: [],
    job_list_RFSchd_page_ALL: 1,
    job_list_weekFilter_ALL: 4,
    stopPagingJob_ALL: false,

    job_list_reOccur: [],
    job_list_reOccur_page: 1,

    job_list_RFSchd_pending: [],
    job_list_RFSchd_pending_page: 1,

    employee_list: [],
    vehicle_list: [],

    employee_listDateTime: [],
    empFilterStr: '',
    vehicle_listDateTime: [],
    vehFilterStr: '',
    availStrDate: '',
    availEndDate: '',

    schedules_list: [],
    schedulesMem_list: [],
    schedulesOut_list: [],
    schedules_page: 1,
    schedulesMem_page: 1,
    schedulesOut_page: 1,
    schd_loader: true,
    schdMem_loader: true,
    schdOut_loader: true,
    stopMemLoading: true,
    stopOutLoading: true,
    availMemStrDate: '',
    availMemEndDate: '',
    availOutStrDate: '',
    availOutEndDate: '',

    selected_team: [],
    tableLoader: true,
    tableLoader_ALL: true,

    stopLoading: true,

    last_fetching_schedules: [],
    savedJobData: {},

    schdlListType: 'v',
    vehicleAvailLoader: false,
    empAvailLoader: false,
    jobTab: null,
    jobTab_ALL: null,

    oneOffJobsCount: '0',
    outsourcedJobsCount: '0',
    reoccuredJobsCount: '0',
    deletedJobsCount: '0',
    oneOffJobsCount_ALL: '0',
    outsourcedJobsCount_ALL: '0',
    reoccuredJobsCount_ALL: '0',
    deletedJobsCount_ALL: '0'

}



const schedule = (state = initialState, actions) => {

    switch (actions.type) {
        case "VEHICLE_AVAIL_LOADER":
            return {
                ...state,
                vehicleAvailLoader: actions.loader
            }
        case "EMPL_AVAIL_LOADER":
            return {
                ...state,
                empAvailLoader: actions.loader
            }
        case "SAVING_JOBDATA_TEMP":
            return {
                ...state,
                savedJobData: actions.data
            }
        case "RELOAD_PAGE":
            return {
                ...state,
                tableLoader: true,
                tableLoader_ALL: true,
                schd_loader: true
            }
        
        case "RELOAD_PAGE_modal":
            return {
                ...state,
                tableLoader_ALL: true
            }
                
        case 'RELOAD_SCHD_LIST':
            return {
                ...state,
                schd_loader: true
            }
        case "JOB_LIST_SCHEDULE":
            return {
                ...state,
                job_list: actions.jobs,
                job_list_page: actions.page
            }
        case "JOB_LIST_SCHEDULE_readyForSchd_FETCHING":
            return {
                ...state,
                job_list_RFSchd_loader: true
            }
        case "JOB_LIST_SCHEDULE_readyForSchd":
            let oldList = (actions.page === 1 && (actions.jobs && actions.jobs.length !== 0)) ? actions.jobs :
                (actions.page === 1 && (actions.jobs && actions.jobs.length === 0)) ? [] :
                    actions.jobs && actions.jobs.length !== 0 ? [...state.job_list_RFSchd, ...actions.jobs] : state.job_list_RFSchd
            let oldList2 = (actions.page === 1 && (actions.jobs && actions.jobs.length !== 0)) ? actions.jobs :
                    (actions.page === 1 && (actions.jobs && actions.jobs.length === 0)) ? [] :
                        actions.jobs && actions.jobs.length !== 0 ? [...state.job_list_RFSchd_ALL, ...actions.jobs] : state.job_list_RFSchd_ALL
            var isToStopVal =  (actions.jobs && actions.jobs.length === 0) || (actions.jobs && actions.jobs.length < actions.limitVal) 
            return {
                ...state,
                job_list_RFSchd_loader: false,
                job_list_RFSchd: oldList,
                job_list_RFSchd_page: actions.page,

                job_list_RFSchd_loader_ALL: false,
                job_list_RFSchd_ALL: oldList2,
                job_list_RFSchd_page_ALL: actions.page,
                
                job_list_weekFilter: actions.filterVal,
                stopPagingJob: isToStopVal,
                tableLoader: false,
                jobTab: actions.jobType
            }
        case "JOB_LIST_SCHEDULE_readyForSchd_ALL":
            let oldList_ALL = (actions.page === 1 && (actions.jobs && actions.jobs.length !== 0)) ? actions.jobs :
                (actions.page === 1 && (actions.jobs && actions.jobs.length === 0)) ? [] :
                    actions.jobs && actions.jobs.length !== 0 ? [...state.job_list_RFSchd_ALL, ...actions.jobs] : state.job_list_RFSchd_ALL
            var isToStopVal2 =  (actions.jobs && actions.jobs.length === 0) || (actions.jobs && actions.jobs.length < actions.limitVal) 

            return {
                ...state,
                job_list_RFSchd_loader_ALL: false,
                job_list_RFSchd_ALL: oldList_ALL,
                job_list_RFSchd_page_ALL: actions.page,
                job_list_weekFilter_ALL: actions.filterVal,
                stopPagingJob_ALL: isToStopVal2,
                tableLoader_ALL: false,
                tableLoader : false,
                jobTab_ALL: actions.jobType
            }

        case "JOB_LIST_SCHD_PENDING":
            return {
                ...state,
                job_list_RFSchd_pending: actions.jobs,
                job_list_RFSchd_pending_page: actions.page,
                tableLoader: false
            }

        case "JOB_LIST_SCHD_REOCCUR":
            return {
                ...state,
                job_list_reOccur: actions.jobs,
                job_list_reOccur_page: actions.page,
                tableLoader: false
            }

        case "EMPLOYEE_VEHICLES":
            return {
                ...state,
                employee_list: actions.employees,
                vehicle_list: actions.vehicles,
            }

        case "EMPLOYEE_DATE_TIME_LISTING":
            return {
                ...state,
                employee_listDateTime: actions.employees,
                empFilterStr: actions.filter,
                empAvailLoader: false
            }

        case "VEHICLES_DATE_TIME_LISTING":
            return {
                ...state,
                vehicle_listDateTime: actions.vehicles,
                vehFilterStr: actions.filter,
                vehicleAvailLoader: false
            }


        case "SCHEDULES_LIST":

            let tempList = typeof actions.schedules !== 'undefined' ? actions.schedules : state.schedules_list

            return {
                ...state,
                schedules_list: tempList,
                schedules_page: actions.page,
                stopLoading: actions.loadAct,
                last_fetching_schedules: actions.last_fethced,
                schd_loader: false,
                availStrDate: actions.strD,
                availEndDate: actions.endD,
                schdlListType: 'v'
            }

        case "SCHEDULES_MEM_LIST":

            let tempMemList = typeof actions.schedules !== 'undefined' ? actions.schedules : state.schedulesMem_list

            return {
                ...state,
                schedulesMem_list: tempMemList,
                schedulesMem_page: actions.page,
                stopMemLoading: actions.loadAct,
                last_fetching_schedules: actions.last_fethced,
                schdMem_loader: false,
                availMemStrDate: actions.strD,
                availMemEndDate: actions.endD,
                schdlListType: 'm'
            }

        case "SCHEDULES_OUTSOURCED_LIST":

            let tempOutList = typeof actions.schedules !== 'undefined' ? actions.schedules : state.schedulesOut_list

            return {
                ...state,
                schedulesOut_list: tempOutList,
                schedulesOut_page: actions.page,
                stopOutLoading: actions.loadAct,
                last_fetching_schedules: actions.last_fethced,
                schdOut_loader: false,
                availOutStrDate: actions.strD,
                availOutEndDate: actions.endD,
                schdlListType: 'o'
            }

        case "SELECT_TEAM":
            return {
                ...state,
                selected_team: actions.team
            }

        case "JOBLIST_JOB_DELETE":
            let job_list_RFSchd_oldArr = state.job_list_RFSchd
            let jobIdxDel = job_list_RFSchd_oldArr.filter(el => el.id !== actions.id)
            return {
                ...state,
                job_list_RFSchd: jobIdxDel,
            }

        case "JOBLIST_JOB_DELETE_ALL":
            let job_list_RFSchd_oldArr_ALL = state.job_list_RFSchd_ALL
            let jobIdxDel_ALL = job_list_RFSchd_oldArr_ALL.filter(el => el.id !== actions.id)
            return {
                ...state,
                job_list_RFSchd_ALL: jobIdxDel_ALL,
            }

        case "JOB_LIST_COUNT":
            return {
                ...state,
                oneOffJobsCount: actions.count.one_off_jobs_count > 99999 ? '99999+' : actions.count.one_off_jobs_count.toString(),
                outsourcedJobsCount: actions.count.outsourced_jobs_count > 99999 ? '99999+' : actions.count.outsourced_jobs_count.toString(),
                reoccuredJobsCount: actions.count.mock_up_jobs_count > 99999 ? '99999+' : actions.count.mock_up_jobs_count.toString(),
                deletedJobsCount: actions.count.deleted_jobs_count > 99999 ? '99999+' : actions.count.deleted_jobs_count.toString()
            }
        case "JOB_LIST_COUNT_ALL":
            return {
                ...state,
                oneOffJobsCount_ALL: actions.count.one_off_jobs_count > 99999 ? '99999+' : actions.count.one_off_jobs_count.toString(),
                outsourcedJobsCount_ALL: actions.count.outsourced_jobs_count > 99999 ? '99999+' : actions.count.outsourced_jobs_count.toString(),
                reoccuredJobsCount_ALL: actions.count.mock_up_jobs_count > 99999 ? '99999+' : actions.count.mock_up_jobs_count.toString(),
                deletedJobsCount_ALL: actions.count.deleted_jobs_count > 99999 ? '99999+' : actions.count.deleted_jobs_count.toString()
            }

        default:
            return state

    }
}

export default schedule