import React from 'react'
import { Edit, DeleteForever } from '@material-ui/icons'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { cl_red, cl_green } from '../../../../../common/Colors'
import { fetch_fuel_list, __select_fuel, __vehicle_modal, __delete_fuel, filter_fuel_list } from '../common/action'
import Scrollbars from '../../../../../common/ImprovedScrollBar'
import moment from 'moment'
import ErrorHandling from '../../../../../common/ErrorHandling'

function FuelList({
    truck_fuel_list,
    truck_fuel_page,
    fetch_fuels,
    vehicle_modal_action,
    select_fuel,
    fuel_delete,
    tabType,
    pageLoader,
    setPageLoader, truck_fuel_error, truck_fuel_loader, truck_fuel_stoppagination,filterKey,
    clearFilterDate,
    setClearFilterDate,
    filterFromDate, setFilterFromDate,
    filterToDate, setFilterToDate,
    invokeDate, setInvokeDate,
    fuel_search_qry,
    fetch_filter_truck
}) {


    let balance_row = 0
    if (truck_fuel_list.length < 8) {
        balance_row = 8 - truck_fuel_list.length
    }

    // let page_no = 0
    // if (truck_fuel_page < 10) {
    //     page_no = '0' + truck_fuel_page
    // } else {
    //     page_no = truck_fuel_page
    // }

    const delete_btn_click = (fuel_id) => {
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Report added successfully!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                fuel_delete(tabType, fuel_id, success, failed, truck_fuel_page)
            }
        })
    }

    const fetch = () => {
        if (!truck_fuel_loader) {
            if (filterKey !== 'all') {
                let postData = new FormData()
                postData.append('registration', filterKey)
                postData.append('vehicle_type', 'truck')
                postData.append('tab_type', tabType)
                fetch_filter_truck(tabType, null, null, postData, 'vehicle', filterKey, truck_fuel_page, 'filter')
            } else if (clearFilterDate || invokeDate) {
                let postData = new FormData()
                postData.append('from_date', filterFromDate.val)
                postData.append('to_date',filterToDate.val)
                postData.append('vehicle_type', 'truck')
                postData.append('tab_type', tabType)
                fetch_filter_truck(tabType, null, null, postData, 'day', 'all', truck_fuel_page, 'date')
            } else if (fuel_search_qry !== '') {
                let postData = new FormData()
                postData.append('registration', fuel_search_qry)
                postData.append('vehicle_type', 'truck')
                postData.append('tab_type', tabType)
                fetch_filter_truck(tabType, null, null, postData, 'vehicle', filterKey, truck_fuel_page, 'search')
            }
            else {
                fetch_fuels(tabType, truck_fuel_page)

            }
            // fetch_fuels(tabType, truck_fuel_page + 1)
        }

    }
    const ScrollUp = (val) => {
        const { top } = val
        // if (!searchFnOpen)
        if (top === 1 && !truck_fuel_stoppagination && !truck_fuel_loader && truck_fuel_error === null) {
            console.log('SCROLL CALLED')
            if (filterKey !== 'all') {
                let postData = new FormData()
                postData.append('registration', filterKey)
                postData.append('vehicle_type', 'truck')
                postData.append('tab_type', tabType)
                fetch_filter_truck(tabType, null, null, postData, 'vehicle', filterKey, truck_fuel_page + 1, 'filter')
            } else if (clearFilterDate || invokeDate) {
                let postData = new FormData()
                postData.append('from_date', filterFromDate.val)
                postData.append('to_date',filterToDate.val)
                postData.append('vehicle_type', 'truck')
                postData.append('tab_type', tabType)
                fetch_filter_truck(tabType, null, null, postData, 'day', 'all', truck_fuel_page + 1, 'date')
            } else if (fuel_search_qry !== '') {
                let postData = new FormData()
                postData.append('registration', fuel_search_qry)
                postData.append('vehicle_type', 'truck')
                postData.append('tab_type', tabType)
                fetch_filter_truck(tabType, null, null, postData, 'vehicle', filterKey, truck_fuel_page + 1, 'search')
            }
            else {
                fetch_fuels(tabType, truck_fuel_page + 1)

            }
        }
    }
    return (
        <div className="sgr-fuel-main-layout">
            <div className="sgr-fuel-tablez-layout">
                <p className="sgr-fuel-hd-title">FUEL (DIESEL) CONSUPTION RECORD - DIESEL TANK</p>
                <Scrollbars style={{ height: '70vh' }} className="table-details-truck" onUpdate={ScrollUp}>
                    <table className="table-details-truck-tbl" style={{ maxHeight: '80vh' }}>
                        <thead>
                            <tr className="heading-truck-table">
                                <th>Vehicle</th>
                                <th>Record Date</th>
                                <th>Time</th>
                                <th>Truck Rego</th>
                                <th>Current Reading Before</th>
                                <th>Reading After Filling</th>
                                <th>Filled By</th>
                                <th>Volume used in Liters</th>
                                <th>Actions</th>

                            </tr>
                        </thead>
                        <tbody>


                            {!truck_fuel_loader && truck_fuel_list && truck_fuel_error === null && truck_fuel_list.length === 0 ? <tr><td colSpan='9'>No Records</td></tr> : null}
                            {
                                truck_fuel_list.map((item, key) => {
                                    const { id, registration, date, time, truck_rego, current_reading_before, reading_after_filling, filled_by, volume_usedIn_liter } = item
                                    return (
                                        <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'transparent', height : '5.3vh' }}>
                                            <td>{registration}</td>
                                            <td>{moment(date).isValid() ? moment(date).format('DD-MM-YYYY') : "Date Not Provided"}</td>
                                            <td>{time}</td>
                                            <td>{truck_rego}</td>
                                            <td>{current_reading_before}</td>
                                            <td>{reading_after_filling}</td>
                                            <td>{filled_by}</td>
                                            <td>{volume_usedIn_liter}</td>

                                            <td>
                                                <div className="table-action-field">
                                                    <div
                                                        onClick={() => {
                                                            vehicle_modal_action(true)
                                                            select_fuel(item)
                                                        }}
                                                        className="table-action-btn"
                                                    >
                                                        <Edit style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                    </div>
                                                    <div onClick={() => delete_btn_click(id)} className="table-action-btn">
                                                        <DeleteForever style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                            {   (!truck_fuel_loader && truck_fuel_error === null)&&
                                [...Array(balance_row)].map((_, key) => {
                                    return (
                                        <tr key={key}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        {truck_fuel_loader && <tbody className='skeleton'>
                            {
                                [...Array(8)].map((_, key) => {
                                    return (
                                        <tr >
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td></td><td></td><td></td>

                                        </tr>
                                    )
                                })
                            }
                        </tbody>}
                        {
                            truck_fuel_error && <ErrorHandling error={truck_fuel_error} fetch={fetch} />
                        }
                    </table>
                    {/* {
                        truck_fuel_error && <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: '200px', justifyContent: 'center' }}>
                            <span style={{ textAlign: 'center' }}>{truck_fuel_error.text}</span>
                            <button className='vechile-details-btn-add' onClick={fetch}>Retry</button>
                        </div>
                    } */}
                </Scrollbars>

                {/* <div className="truck-table-back-previous-btn">
                    <div className="pagination-btn-layout">
                        {
                            truck_fuel_page > 1 ? (
                                <button
                                    onClick={() => fetch_fuels(tabType, truck_fuel_page - 1, setPageLoader)}
                                    className="table-list-next-btn">PREVIOUS</button>
                            ) : (
                                <button className="table-list-next-btn disabled-btn">PREVIOUS</button>
                            )
                        }
                        <div className="page-no">
                            <p className="p-0 m-0">{page_no}</p>
                        </div>
                        {
                            truck_fuel_list.length === 8 ? (
                                <button
                                    onClick={() => fetch_fuels(tabType, truck_fuel_page + 1, setPageLoader)}
                                    className="table-list-next-btn">NEXT</button>
                            ) : (
                                <button className="table-list-next-btn disabled-btn">NEXT</button>
                            )
                        }
                    </div>
                </div> */}

            </div>

            {/* <div className="sgr-fuel-actions-layout">
                <div className="sgr-fuel-actions-box">
                    <p className="sgr-fuel-action-title">Showing Results</p>
                    
                    
                </div>
            </div> */}

        </div>
    )
}


const mapStateToProps = (state) => {
    const { truck_fuel_list, truck_fuel_page, all_truck_list, filterKey, truck_fuel_error, 
        truck_fuel_loader, truck_fuel_stoppagination,fuel_search_qry } = state.vehicle__truck
    return { truck_fuel_list, truck_fuel_page, all_truck_list, filterKey, 
        truck_fuel_error, truck_fuel_loader, truck_fuel_stoppagination,fuel_search_qry }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_fuels: (tabType, page, callBk = null) => dispatch(fetch_fuel_list(tabType, page, callBk)),
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        select_fuel: (fuel) => dispatch(__select_fuel(fuel)),
        fuel_delete: (tabType, fuel_id, success, failed, page_no) => dispatch(__delete_fuel(tabType, fuel_id, success, failed, page_no)),
        fetch_filter_truck: (tabType, success, failed, data, type, id, page, fetchtype) => dispatch(filter_fuel_list(tabType, success, failed, data, type, id, page, fetchtype))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelList)

