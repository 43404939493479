import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { EnviroWasteLogo, LoginCoverImage  } from '../../../common/Images'
import { signin, profile, profile_permissions } from '../action'
import { ReactComponent as EmailIcon } from '../../../assets/Images/email.svg'
import { ReactComponent as PasswordIcon } from '../../../assets/Images/password.svg'
import EnviroLogoLoader from '../../../assets/Images/eniviro_logo.gif'
import './style.css'
import { CircularProgress, Tooltip } from '@material-ui/core'
import { STORAGE_KEY_VALS } from '../../../store/config'
import PasswordInputFormatter from '../../../common/PasswordInputFormatter'

function App(props) {

    const dispatch = props.dispatch

    // const [hoverVal, setHover] = useState(false)

    useEffect(()=>{
        if(localStorage.getItem(STORAGE_KEY_VALS.logStatus)){
            dispatch({ type: 'AUTH_FETCHING' })
            dispatch({ type: 'AUTH_FETCHING_SUCCESS', token: localStorage.getItem(STORAGE_KEY_VALS.tk)})
            // NotificationTokenGen()
            localStorage.setItem(STORAGE_KEY_VALS.tk, localStorage.getItem(STORAGE_KEY_VALS.tk));
            localStorage.setItem(STORAGE_KEY_VALS.logStatus, true);
            dispatch(profile())
            dispatch(profile_permissions())
        }
    })

    

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)

    const onSignIn = () => {
        setLoader(true)
        if(username && password) {
            const user_data = {
                username: username,
                password: password
            }

            const failed = () => {
                setLoader(false)
                setPassword('')
                setUsername('')
                setError('Invalid Credentials')
            }
            dispatch(signin(user_data, failed))
        } else {
            setLoader(false)
            setError('Please enter username & password')
        }
        
    }

    return (
         localStorage.getItem(STORAGE_KEY_VALS.logStatus) ?
        <div className="login-layout">
            <div style={{ display: 'flex', height:'100%', width:'100%', position:'absolute', justifyContent: 'center', alignItems: 'center' }} >
                <img src={EnviroLogoLoader} alt="Logo_loader" style={{ width:'20%' }} />
            </div>
        </div>
        :
        // <div className="login-layout" >
        //     <div className="login-team-brand-layout">
        //         <img className="login-team-brand-img" src={EnviroBrandLogo} alt='enviro logo' />
        //     </div>
            
        //     <div className="login-form-layout">
        //         <div className="login-head">Login</div>
        //         <div className="login-form-div">
        //             <div className="login-form-section">
                        // <input 
                        //     type="text" 
                        //     placeholder="Username" 
                        //     className="login-Username-input"
                        //     value={username}
                        //     onChange={txt => setUsername(txt.target.value)}
                        // />
                        // <input 
                        //     type="password" 
                        //     placeholder="Password" 
                        //     className="login-Username-input"
                        //     value={password}
                        //     onChange={txt => setPassword(txt.target.value)}
                        //     onKeyUp={(e)=>{ if(e.key==="Enter" && password!=='' && username!=='') { setError(''); onSignIn(); } }}
                        // />
        //                 <p style={{ color: 'red', fontSize: 12, marginTop: 10, marginBottom: 10 }}>{error}</p>
        //                 <button 
        //                     style={{ border: 0 }}
        //                     onClick={() =>{ setError(''); onSignIn();}} className="login-form-btn"
        //                     type="submit">
        //                         Login
        //                 </button>
                        
        //             </div>
                    
        //         </div>
        //     </div>
        // </div>
        <div className="newLoginLayout">
            <div className="newLoginLayoutDiv1">
                <img src={LoginCoverImage} alt="stock_image" style={{ width:'100%', objectFit:'cover', height:'100%' }} />
            </div>
            <div className="newLoginLayoutDiv2">
                <div className="loginTopBarLogo" >
                    <img src={EnviroWasteLogo} alt="Enviro_Logo" style={{ height:'100%' }} />
                </div>
                <div className="loginCredsScreen">
                    <div className="newLoginPanelHead">
                        <p>Welcome Back!</p>
                        <span>Please login to your official account</span>
                    </div>
                    <div className="newLoginPanelForm">
                        <div>
                            <span><EmailIcon className='newLoginPanelFormsvg'/></span>
                            <label>Email ID</label>
                            <input 
                                type="text" 
                                placeholder="Username" 
                                className="login-Username-input"
                                value={username}
                                data-testid="username"
                                onChange={txt =>{ setError(''); setUsername(txt.target.value)}}
                            />
                        </div>
                        <div>
                            <span><PasswordIcon className='newLoginPanelFormsvg'/></span>
                            <label>Password</label>
                            <PasswordInputFormatter
                                placeholder="Password" 
                                className="login-Username-input"
                                value={password}
                                iconStyle={{
                                    position:'absolute',
                                    top:'40%',
                                    right:'5%', fontSize:'1rem'
                                }}
                                data-testid="password"
                                onChange={txt =>{ setError(''); setPassword(txt.target.value)}}
                                onKeyUp={(e)=>{ if(e.key==="Enter" && password!=='' && username!=='') { setError(''); onSignIn(); } }}
                            />
                            {/* <input 
                                type="password" 
                                placeholder="Password" 
                                className="login-Username-input"
                                value={password}
                                data-testid="password"
                                onChange={txt =>{ setError(''); setPassword(txt.target.value)}}
                                onKeyUp={(e)=>{ if(e.key==="Enter" && password!=='' && username!=='') { setError(''); onSignIn(); } }}
                            /> */}
                        </div>
                        <p style={{ color: 'red', fontSize: 12, marginTop: 10, marginBottom: 10 }}>{error}</p>
                    </div>
                    <div className="newLoginPanelBtnDiv">
                        <div className="newLoginPanelBtn" data-testid="loginButton"  onClick={() =>{ 
                            setLoader(true)
                            setError(''); onSignIn();}}>
                            {
                                loader ?
                                    <CircularProgress size={18} color={'white'} />
                                :
                                    'Login'
                            }
                        </div>
                        <Tooltip 
                        //open={hoverVal}
                          title={'Please contact your admin, manager or director to change your credentials'} 
            arrow={true} placement={'bottom'}>
                            <p 
                            // onMouseEnter={() => { setHover(true)}}
                            //     onMouseLeave={() => { setHover(false)}}
                            >Forgot Password
                            </p>
                        </Tooltip>
                    </div>
                </div>
                <div className="loginFooterDiv">
                    <span>If you experience any issues in logging in, contact your manager.</span>
                    <span>If you have any feed back please click on the below link.</span>
                    <p>
                        <a href={'https://docs.google.com/forms/d/e/1FAIpQLSfaOOqoQl3pmGC93N0SjDqkbDURsOUavKkD-WizZ_BDzJEuuA/viewform?usp=sf_link'}
                            style={{ textDecoration:'none' }}
                        >
                            Feedback
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

const mapsStateProps = (state) => {
    const { isLoading, errorLoginMessage, user } = state.userLogin
    return {
        user: user,
        error: errorLoginMessage,
        isLoading: isLoading,
    }
}

export default connect(mapsStateProps)(App)