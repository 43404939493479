import React, { useState, useEffect } from 'react'
import Scrollbar from '../../../../../common/ImprovedScrollBar'
import { edit_preinspection, fetch_all_forklift_list } from '../common/action'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import DateTimeInput from '../../../../../common/DateTimeInput'
import moment from 'moment'
import { NUMBER_VALIDATION_REGEX } from '../../../../../store/config'
import { ReactComponent as Tick } from '../../../../../assets/Images/check.svg'
import { TextAreaLimiter } from '../../../../../common/TextAreaLimiter'

function App({
    all_forklift_list,
    fetch_all_forklifts,
    tabType,
    selected_pre_inspect,
    edit_pre_inspObj

}) {

    useEffect(() => {
        fetch_all_forklifts(tabType)
        return () => fetch_all_forklifts.remove
    }, [fetch_all_forklifts, tabType])

    let route_history = useHistory();

    const [preInspObj, setPreInspObj] = useState(edit_pre_inspObj)
    const [checkBoxList, setCheckBoxList] = useState(
    {
        EngineOilLevel      :       { box : false, val : edit_pre_inspObj.EngineOilLevel}, 
        WarningSystem       :       { box : false, val : edit_pre_inspObj.WarningSystem}, 
        Steering            :       { box : false, val : edit_pre_inspObj.Steering}, 
        SafetyEmrgStop      :       { box : false, val : edit_pre_inspObj.SafetyEmrgStop},
        HandBreakAlram      :       { box : false, val : edit_pre_inspObj.HandBreakAlram}, 
        PTOVacPump          :       { box : false, val : edit_pre_inspObj.PTOVacPump}, 
        Horn                :       { box : false, val : edit_pre_inspObj.Horn}, 
        RevAlarmCamera      :       { box : false, val : edit_pre_inspObj.RevAlarmCamera},
        LightsHead          :       { box : false, val : edit_pre_inspObj.LightsHead}, 
        LightsTail          :       { box : false, val : edit_pre_inspObj.LightsTail}, 
        LightsBeacon        :       { box : false, val : edit_pre_inspObj.LightsBeacon}, 
        HazardLight         :       { box : false, val : edit_pre_inspObj.HazardLight},
        HouseKeeping        :       { box : false, val : edit_pre_inspObj.HouseKeeping}, 
        RimsWheels          :       { box : false, val : edit_pre_inspObj.RimsWheels}, 
        Coolant             :       { box : false, val : edit_pre_inspObj.Coolant}, 
        Wheels              :       { box : false, val : edit_pre_inspObj.Wheels}, 
        MirrorWindows       :       { box : false, val : edit_pre_inspObj.MirrorWindows}, 
        StructureBody       :       { box : false, val : edit_pre_inspObj.StructureBody},
        Wipers              :       { box : false, val : edit_pre_inspObj.Wipers}, 
        FuelLevelPump       :       { box : false, val : edit_pre_inspObj.FuelLevelPump}, 
        FuelLevelTruck      :       { box : false, val : edit_pre_inspObj.FuelLevelTruck}, 
        SeatSeatbelt        :       { box : false, val : edit_pre_inspObj.SeatSeatbelt},
        ParkBreakTrailer    :       { box : false, val : edit_pre_inspObj.ParkBreakTrailer}, 
        FootBrake           :       { box : false, val : edit_pre_inspObj.FootBrake}, 
        Electrical          :       { box : false, val : edit_pre_inspObj.Electrical}, 
        PinRetainer         :       { box : false, val : edit_pre_inspObj.PinRetainer}
    }
    )

    const [textAreaValid, setTextAreaValid] = useState(true)

    let submit_btn = false;

    if ( (typeof preInspObj.Odometer==='number' ? preInspObj.Odometer.toString() : preInspObj.Odometer)
        && preInspObj.DriverSignature && 
        (typeof preInspObj.HourMeterStart==='number' ? preInspObj.HourMeterStart.toString() : preInspObj.HourMeterStart)
        && preInspObj.FitForWork && preInspObj.ValidDrivingLicense && preInspObj.AppropriatePPE 
        && preInspObj.ReadyToOperate && preInspObj.ReportedFaults && preInspObj.ReviewdForm) {
        submit_btn = true
    }

    const submit_form = () => {
        let form_body = new FormData()

        form_body.append("vehicle", preInspObj.Vehicle)
        form_body.append("odometer", preInspObj.Odometer)
        form_body.append("driver_name", preInspObj.DriverName)
        form_body.append("odometdriver_signature", preInspObj.DriverSignature)
        form_body.append("hour_meter_start", preInspObj.HourMeterStart)

        form_body.append("fit_for_work", preInspObj.FitForWork)
        form_body.append("Valid_driving_license", preInspObj.ValidDrivingLicense)
        form_body.append("appropriate_ppe", preInspObj.AppropriatePPE)

        form_body.append("engine_oil_level", checkBoxList.EngineOilLevel.val)
        form_body.append("warning_system", checkBoxList.WarningSystem.val)
        form_body.append("steering", checkBoxList.Steering.val)
        form_body.append("safety_emerg_stop", checkBoxList.SafetyEmrgStop.val)
        form_body.append("handbreak_alarm", checkBoxList.HandBreakAlram.val)
        form_body.append("pto_vacpump", checkBoxList.PTOVacPump.val)

        form_body.append("horn", checkBoxList.Horn.val)
        form_body.append("rev_alarm_camera", checkBoxList.RevAlarmCamera.val)
        form_body.append("lights_head", checkBoxList.LightsHead.val)
        form_body.append("lights_tail", checkBoxList.LightsTail.val)
        form_body.append("light_beacons", checkBoxList.LightsBeacon.val)
        form_body.append("hazard_light", checkBoxList.HazardLight.val)
        form_body.append("house_keeping", checkBoxList.HouseKeeping.val)

        form_body.append("rims_wheelnut", checkBoxList.RimsWheels.val)
        form_body.append("coolant", checkBoxList.Coolant.val)
        form_body.append("wheels", checkBoxList.Wheels.val)
        form_body.append("mirror_windowscreen", checkBoxList.MirrorWindows.val)
        form_body.append("structure_bodywork", checkBoxList.StructureBody.val)
        form_body.append("wipers", checkBoxList.Wipers.val)

        form_body.append("fuel_levelpump", checkBoxList.FuelLevelPump.val)
        form_body.append("fuel_leveltruck", checkBoxList.FuelLevelTruck.val)
        form_body.append("seat_seatbelt", checkBoxList.SeatSeatbelt.val)
        form_body.append("parkbrake_trailer", checkBoxList.ParkBreakTrailer.val)
        form_body.append("foot_brake", checkBoxList.FootBrake.val)
        form_body.append("electrical", checkBoxList.Electrical.val)
        form_body.append("pin_retainers", checkBoxList.PinRetainer.val)

        form_body.append("hoses", preInspObj.Hoses)
        form_body.append("fittings", preInspObj.Fittings)
        form_body.append("first_aid_kit", preInspObj.FirstAidKit)
        form_body.append("ppe", preInspObj.PPE)
        form_body.append("fire_extinguisher", preInspObj.FireExtingusre)
        form_body.append("fire_extinguisher_date", moment(preInspObj.FireExtingusreDate).isValid() ? 
        preInspObj.FireExtingusreDate 
        : moment().format('YYYY-MM-DD'))
        form_body.append("garden_hose", preInspObj.GardenHose)
        form_body.append("gatic_lifters", preInspObj.GaticLiters)
        form_body.append("bucket_rags", preInspObj.BucketRag)
        form_body.append("spill_kit", preInspObj.SpillKit)

        form_body.append("reported_faults", preInspObj.ReportedFaults)
        form_body.append("reported_fault_string", preInspObj.FaultReportz)
        form_body.append("reviewed_form", preInspObj.ReviewdForm)
        form_body.append("corrected", preInspObj.Corrected)
        form_body.append("scheduled_for_repair", preInspObj.ScheduledForRepair)

        form_body.append("no_action", preInspObj.NoAction)
        form_body.append("do_not_affect_safe_operation", preInspObj.DoNotAffectSafeOperation)

        form_body.append("safe_ready_to_operate", preInspObj.ReadyToOperate);
        form_body.append("signature", preInspObj.DriverSignature);
        form_body.append('vehicle_type', tabType === 'hills' ? 'machinery' : 'fork-lift')
        form_body.append("tab_type", selected_pre_inspect?.tab_type)
        // form_body.append('name', 'Jithin');
        // form_body.append('date_time', '2020-12-1');
        // form_body.append('driver_name', '1');


        const success = () => {
            route_history.push(`/vehicle/${tabType === 'hills' ? 'machinery' : 'forklift'}/preinspection`)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Pre-inspection updation successfull!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        edit_preinspection(tabType, form_body, selected_pre_inspect.id, success, failed)
    }

    return (
        <div className="PreInspectionCheck">
            <div className="pre-topbar">
                <div className="sgr-mntnce-rprt-topbar">
                    <button className="sgr-mntnce-rprt-topbarBtn2">Master - Truck</button>
                    <button className="sgr-mntnce-rprt-topbarBtn2">Pre-Inspection Check</button>
                </div>


                {/* <div className="sgr-mntnce-rprt-topbar">
                    <Link className="sgr-mntnce-rprt-topbar" to="vehicle/truck/preinspection">
                        <button className="sgr-mntnce-rprt-addNew">Pre Inpection List</button>
                    </Link>
                </div> */}


            </div>
            <p style={{ fontSize: 14, marginTop: 10, marginLeft: 5 }} className="p-0 m-0">Vehicle Safety Pre-Start Inspection Checklist</p>
            <Scrollbar style={{ height: '75vh', marginTop: 10 }}>

                <div className="pre-box-1">
                    <div className="pre-box-1-top">
                        <div className="pre-box-1-left">

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Date & Time</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    <p style={{ color: 'red' }} className="p-0 m-0">
                                        {moment(preInspObj.Date_time).format('DD-MM-YYYY hh:mm:ss')}
                                    </p>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Vehicle Registration</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <select
                                        value={preInspObj.Vehicle}
                                        onChange={txt => setPreInspObj({ ...preInspObj, Vehicle : txt.target.value})}
                                        className="pre-box-1-select"
                                    >
                                        {
                                            all_forklift_list.map((item, key) => {
                                                const { id, registration } = item
                                                return (
                                                    <option className="pre-box-1-select_option" key={key} value={id}>{registration}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Odometer</p>
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <input
                                        className="pre-box-1-input"

                                        placeholder="Odometer"
                                        value={preInspObj.Odometer}
                                        onChange={txt => {
                                            if (txt.target.value === '')
                                                setPreInspObj({ ...preInspObj, Odometer : ''})
                                            else if (NUMBER_VALIDATION_REGEX.test(txt.target.value))
                                                setPreInspObj({ ...preInspObj, Odometer : txt.target.value})
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="pre-box-1-right">


                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Drivers Name</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                <p style={{ color: 'red' }} className="p-0 m-0">{edit_pre_inspObj.DriverNameStr}</p>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Drivers Signature</p>
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <input
                                        className="pre-box-1-input"
                                        type="text"
                                        placeholder="Signature"
                                        value={preInspObj.DriverSignature}
                                        onChange={txt => setPreInspObj({ ...preInspObj, DriverSignature : txt.target.value})}
                                    />
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Hour Meter Start</p>
                                    <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {/* <p className="p-0 m-0">Auto Fetch</p> */}
                                    <input
                                        className="pre-box-1-input"
                                        type="text"
                                        placeholder="Hour Meter Start"
                                        value={preInspObj.HourMeterStart}
                                        onChange={txt => setPreInspObj({ ...preInspObj, HourMeterStart : txt.target.value})}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="pre-box-1-bottom">
                        <div className="pre-box-1-bottom-check">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={preInspObj.FitForWork}
                                onChange={() => setPreInspObj({ ...preInspObj, FitForWork : !preInspObj.FitForWork })}
                            />
                            <label> I am Fit for Work</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>

                        <div className="pre-box-1-bottom-check">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={preInspObj.ValidDrivingLicense}
                                onChange={() => setPreInspObj({ ...preInspObj, ValidDrivingLicense : !preInspObj.ValidDrivingLicense })}
                            />
                            <label> I have the appropriate valid driving license</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>

                        <div className="pre-box-1-bottom-check">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={preInspObj.AppropriatePPE}
                                onChange={() => setPreInspObj({ ...preInspObj, AppropriatePPE : !preInspObj.AppropriatePPE})}
                            />
                            <label> Appropriate PPE for operation of this vehicle</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>
                    </div>
                </div>

                <p style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }} className="p-0 m-0">Pre-Start Checklist-All Fields are  Mandatory (No Obivous Defect)(X-Fault Identified) & N/A not applicable</p>

                <div className="pre-box-2">

                    <p
                        style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }}
                        className="p-0 m-0">
                        Category "A" Fault: Vehicle
                        <span style={{ color: 'red' }}> MUST NOT be operated  </span>
                        until fault is rectified
                    </p>

                    <p
                        style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }}
                        className="p-0 m-0">
                        Category "B" Fault: Vehicle
                        <span style={{ color: '#0F99D0' }}> May be operated  </span>
                        Corrective action is required
                    </p>

                </div>

                <div className="pre-box-3">
                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Engine Oil Level</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Warning System</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Steering</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Safety / Emerg Stops</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Hand Brake Alarm</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">PTO / Vac. Pump</p>
                            </div>

                            <div className="pre-box-3-row-body">

                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={EngineOilLevel}
                                    onChange={() => setEngineOilLevel(!EngineOilLevel)}
                                />       */}
                                <div className={ checkBoxList.EngineOilLevel.val === null ? "checkmark"
                                    : checkBoxList.EngineOilLevel.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, EngineOilLevel : { box: true, val: null }})} >{checkBoxList.EngineOilLevel.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.EngineOilLevel.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, EngineOilLevel : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, EngineOilLevel : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, EngineOilLevel : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={WarningSystem}
                                    onChange={() => setWarningSystem(!WarningSystem)}
                                />       */}
                                <div className={checkBoxList.WarningSystem.val === null ? "checkmark"
                                    : checkBoxList.WarningSystem.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, WarningSystem : { box: true, val: null }})} >{ checkBoxList.WarningSystem.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.WarningSystem.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, WarningSystem : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, WarningSystem : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, WarningSystem : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Steering}
                                    onChange={() => setSteering(!Steering)}
                                />       */}
                                <div className={checkBoxList.Steering.val === null ? "checkmark"
                                    : checkBoxList.Steering.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, Steering : { box: true, val: null }})} >{ checkBoxList.Steering.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.Steering.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Steering : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Steering : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Steering : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={SafetyEmrgStop}
                                    onChange={() => setSafetyEmrgStop(!SafetyEmrgStop)}
                                />       */}
                                <div className={checkBoxList.SafetyEmrgStop.val === null ? "checkmark"
                                    : checkBoxList.SafetyEmrgStop.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, SafetyEmrgStop : { box: true, val: null }})} >{ checkBoxList.SafetyEmrgStop.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.SafetyEmrgStop.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, SafetyEmrgStop : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, SafetyEmrgStop : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, SafetyEmrgStop : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div>
                                    </div>

                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={HandBreakAlram}
                                    onChange={() => setHandBreakAlram(!HandBreakAlram)}
                                />       */}
                                <div className={checkBoxList.HandBreakAlram.val === null ? "checkmark"
                                    : checkBoxList.HandBreakAlram.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, HandBreakAlram : { box: true, val: null }})} >{ checkBoxList.HandBreakAlram.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.HandBreakAlram.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HandBreakAlram : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HandBreakAlram : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HandBreakAlram : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={PTOVacPump}
                                    onChange={() => setPTOVacPump(!PTOVacPump)}
                                />       */}
                                <div className={checkBoxList.PTOVacPump.val === null ? "checkmark"
                                    : checkBoxList.PTOVacPump.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, PTOVacPump : { box: true, val: null }})} >{ checkBoxList.PTOVacPump.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.PTOVacPump.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, PTOVacPump : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, PTOVacPump : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, PTOVacPump : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div>
                                    </div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">

                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Horn</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Rev alarm / Camera</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Lights-Head</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Lights-Tail</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Light-beacons</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Hazards-Light</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className=" p-0 m-0">House keeping</p>
                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Horn}
                                    onChange={() => setHorn(!Horn)}
                                />       */}
                                <div className={checkBoxList.Horn.val === null ? "checkmark"
                                    : checkBoxList.Horn.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, Horn : { box: true, val: null }})} >{ checkBoxList.Horn.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.Horn.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Horn : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Horn : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Horn : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={RevAlarmCamera}
                                    onChange={() => setRevAlarmCamera(!RevAlarmCamera)}
                                />      */}
                                <div className={checkBoxList.RevAlarmCamera.val === null ? "checkmark"
                                    : checkBoxList.RevAlarmCamera.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, RevAlarmCamera : { box: true, val: null }})} >{ checkBoxList.RevAlarmCamera.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.RevAlarmCamera.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, RevAlarmCamera : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, RevAlarmCamera : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, RevAlarmCamera : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={LightsHead}
                                    onChange={() => setLightsHead(!LightsHead)}
                                />      */}
                                <div className={checkBoxList.LightsHead.val === null ? "checkmark"
                                    : checkBoxList.LightsHead.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, LightsHead : { box: true, val: null }})} >{ checkBoxList.LightsHead.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.LightsHead.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsHead : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsHead : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsHead : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={LightsTail}
                                    onChange={() => setLightsTail(!LightsTail)}
                                />      */}
                                <div className={checkBoxList.LightsTail.val === null ? "checkmark"
                                    : checkBoxList.LightsTail.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, LightsTail : { box: true, val: null }})} >{ checkBoxList.LightsTail.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.LightsTail.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsTail : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsTail : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsTail : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={LightsBeacon}
                                    onChange={() => setLightsBeacon(!LightsBeacon)}
                                />      */}
                                <div className={checkBoxList.LightsBeacon.val === null ? "checkmark"
                                    : checkBoxList.LightsBeacon.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, LightsBeacon : { box: true, val: null }})} >{ checkBoxList.LightsBeacon.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.LightsBeacon.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsBeacon : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsBeacon : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, LightsBeacon : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={HazardLight}
                                    onChange={() => setHazardLight(!HazardLight)}
                                />      */}
                                <div className={checkBoxList.HazardLight.val === null ? "checkmark"
                                    : checkBoxList.HazardLight.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, HazardLight : { box: true, val: null }})} >{ checkBoxList.HazardLight.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.HazardLight.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HazardLight : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HazardLight : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HazardLight : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={HouseKeeping}
                                    onChange={() => setHouseKeeping(!HouseKeeping)}
                                />      */}
                                <div className={checkBoxList.HouseKeeping.val === null ? "checkmark"
                                    : checkBoxList.HouseKeeping.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, HouseKeeping : { box: true, val: null }})} >{ checkBoxList.HouseKeeping.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.HouseKeeping.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HouseKeeping : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HouseKeeping : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, HouseKeeping : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Rims & Wheel Nuts</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Coolant</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Wheels & Tyres</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Mirrors & Windscreen</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Structure & Bodywork</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Wipers</p>
                            </div>

                            <div className="pre-box-3-row-body">

                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={RimsWheels}
                                    onChange={() => setRimsWheels(!RimsWheels)}
                                />    */}
                                <div className={checkBoxList.RimsWheels.val === null ? "checkmark"
                                    : checkBoxList.RimsWheels.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, RimsWheels : { box: true, val: null }})} >{checkBoxList.RimsWheels.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.RimsWheels.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, RimsWheels : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, RimsWheels : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, RimsWheels : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Coolant}
                                    onChange={() => setCoolant(!Coolant)}
                                />    */}
                                <div className={checkBoxList.Coolant.val === null ? "checkmark"
                                    : checkBoxList.Coolant.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, Coolant : { box: true, val: null }})} >{checkBoxList.Coolant.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.Coolant.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Coolant : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Coolant : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Coolant : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Wheels}
                                    onChange={() => setWheels(!Wheels)}
                                />    */}
                                <div className={checkBoxList.Wheels.val === null ? "checkmark"
                                    : checkBoxList.Wheels.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, Wheels : { box: true, val: null }})} >{checkBoxList.Wheels.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.Wheels.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Wheels : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Wheels : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Wheels : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={MirrorWindows}
                                    onChange={() => setMirrorWindows(!MirrorWindows)}
                                />    */}
                                <div className={checkBoxList.MirrorWindows.val === null ? "checkmark"
                                    : checkBoxList.MirrorWindows.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, MirrorWindows : { box: true, val: null }})} >{checkBoxList.MirrorWindows.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.MirrorWindows.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, MirrorWindows : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, MirrorWindows : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, MirrorWindows : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={StructureBody}
                                    onChange={() => setStructureBody(!StructureBody)}
                                />    */}
                                <div className={checkBoxList.StructureBody.val === null ? "checkmark"
                                    : checkBoxList.StructureBody.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, StructureBody : { box: true, val: null }})} >{checkBoxList.StructureBody.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.StructureBody.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, StructureBody : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, StructureBody : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, StructureBody : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Wipers}
                                    onChange={() => setWipers(!Wipers)}
                                />    */}
                                <div className={checkBoxList.Wipers.val === null ? "checkmark"
                                    : checkBoxList.Wipers.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, Wipers : { box: true, val: null }})} >{checkBoxList.Wipers.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.Wipers.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Wipers : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Wipers : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Wipers : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">

                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Fuel Level Pump</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Fuel Level Truck</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Seat / Seat Belt</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Park Brake/Trailer</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Foot Brake</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Electrical</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Pin Retainers</p>
                            </div>

                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FuelLevelPump}
                                    onChange={() => setFuelLevelPump(!FuelLevelPump)}
                                />    */}


                                <div className={checkBoxList.FuelLevelPump.val === null ? "checkmark"
                                    : checkBoxList.FuelLevelPump.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelPump : { box: true, val: null }})} >{checkBoxList.FuelLevelPump.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.FuelLevelPump.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelPump : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelPump : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelPump : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FuelLevelTruck}
                                    onChange={() => setFuelLevelTruck(!FuelLevelTruck)}
                                />      */}

                                <div className={checkBoxList.FuelLevelTruck.val === null ? "checkmark"
                                    : checkBoxList.FuelLevelTruck.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelTruck : { box: true, val: null }})} >{checkBoxList.FuelLevelTruck.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.FuelLevelTruck.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelTruck : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelTruck : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FuelLevelTruck : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={SeatSeatbelt}
                                    onChange={() => setSeatSeatbelt(!SeatSeatbelt)}
                                />      */}

                                <div className={checkBoxList.SeatSeatbelt.val === null ? "checkmark"
                                    : checkBoxList.SeatSeatbelt.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, SeatSeatbelt : { box: true, val: null }})} >{checkBoxList.SeatSeatbelt.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.SeatSeatbelt.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, SeatSeatbelt : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, SeatSeatbelt : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, SeatSeatbelt : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={ParkBreakTrailer}
                                    onChange={() => setParkBreakTrailer(!ParkBreakTrailer)}
                                />      */}

                                <div className={checkBoxList.ParkBreakTrailer.val === null ? "checkmark"
                                    : checkBoxList.ParkBreakTrailer.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, ParkBreakTrailer : { box: true, val: null }})} >{checkBoxList.ParkBreakTrailer.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.ParkBreakTrailer.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, ParkBreakTrailer : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, ParkBreakTrailer : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, ParkBreakTrailer : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FootBrake}
                                    onChange={() => setFootBrake(!FootBrake)}
                                />      */}

                                <div className={checkBoxList.FootBrake.val === null ? "checkmark"
                                    : checkBoxList.FootBrake.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, FootBrake : { box: true, val: null }})} >{checkBoxList.FootBrake.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.FootBrake.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FootBrake : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FootBrake : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, FootBrake : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Electrical}
                                    onChange={() => setElectrical(!Electrical)}
                                />      */}

                                <div className={checkBoxList.Electrical.val === null ? "checkmark"
                                    : checkBoxList.Electrical.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, Electrical : { box: true, val: null }})} >{checkBoxList.Electrical.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.Electrical.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Electrical : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Electrical : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, Electrical : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                {/* <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={PinRetainer}
                                    onChange={() => setPinRetainer(!PinRetainer)}
                                />    */}
                                <div className={checkBoxList.PinRetainer.val === null ? "checkmark"
                                    : checkBoxList.PinRetainer.val ? "checkmark checkedRedmark" : "checkmark checkedBlmark"}
                                    onClick={() => setCheckBoxList({ ...checkBoxList, PinRetainer : { box: true, val: null }})} >{checkBoxList.PinRetainer.val === null ? <Tick />: <Tick />}</div>
                                <div className={checkBoxList.PinRetainer.box ? "pre-options-div-box" : "pre-options-div-boxClose"}>
                                    <div>
                                        <span className='pre-options-div-box_triangle'></span>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, PinRetainer : { box: false, val: null }})}  >No Issues</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, PinRetainer : { box: false, val: true }})}  style={{ color: 'red' }}>Category "A" Fault</div>
                                        <div onClick={() => setCheckBoxList({ ...checkBoxList, PinRetainer : { box: false, val: false }})}  style={{ color: '#0F99D0' }}>Category "B" Fault</div></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="pre-box-3-2">
                    <div className="pre-box-3-2-left">
                        <p className="p-0 m-0">Accesseories and Fittings</p>
                    </div>
                    <div className="pre-box-3-2-right">
                        <div className="pre-box-3-2-right-top">
                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.Hoses}
                                    onChange={() => setPreInspObj({ ...preInspObj, Hoses : !preInspObj.Hoses})}
                                />
                                <label> Hoses</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.Fittings}
                                    onChange={() => setPreInspObj({ ...preInspObj, Fittings : !preInspObj.Fittings})}
                                />
                                <label> Fittings</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.FirstAidKit}
                                    onChange={() => setPreInspObj({ ...preInspObj, FirstAidKit : !preInspObj.FirstAidKit})}
                                />
                                <label> First Aid Kit</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.PPE}
                                    onChange={() => setPreInspObj({ ...preInspObj, PPE : !preInspObj.PPE })}
                                />
                                <label> PPE</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.FireExtingusre}
                                    onChange={() => setPreInspObj({ ...preInspObj, FireExtingusre : !preInspObj.FireExtingusre})}
                                />
                                <label> Fire Extinguisher(Date Caibrated)</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">

                                <DateTimeInput
                                    dateValue={preInspObj.FireExtingusreDate}
                                    dateSetFn={(v) => setPreInspObj({...preInspObj, FireExtingusreDate : v})}
                                    clsName={"vehicle1"}
                                />

                                {/* <input 
                                    type="date" 
                                    className="vehicle1"
                                    value={FireExtingusreDate}
                                    onChange={txt => setFireExtingusreDate(txt.target.value)}
                                />                     */}
                            </div>

                        </div>
                        <div className="pre-box-3-2-right-bottom">
                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.GardenHose}
                                    onChange={() => setPreInspObj({ ...preInspObj, GardenHose : !preInspObj.GardenHose})}
                                />
                                <label>Garden Hoses</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.GaticLiters}
                                    onChange={() => setPreInspObj({ ...preInspObj, GaticLiters : !preInspObj.GaticLiters})}
                                />
                                <label> Gattic Lifters</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.BucketRag}
                                    onChange={() => setPreInspObj({ ...preInspObj, BucketRag : !preInspObj.BucketRag})}
                                />
                                <label> Bucket / Rags</label>
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input
                                    type="checkbox"
                                    className="vehicle1"
                                    checked={preInspObj.SpillKit}
                                    onChange={() => setPreInspObj({ ...preInspObj, SpillKit : !preInspObj.SpillKit})}
                                />
                                <label> Spill Kitt</label>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="pre-box-4">
                    <div className="pre-box-4-left">
                        <p className="p-0 m-0">Any Fault to Report (any Category) & any additional information</p>
                    </div>

                    <div className="pre-box-4-right">
                        
                        <TextAreaLimiter isValid={setTextAreaValid}
                        configs={{
                            className : "pre-box-4-textarea",
                            placeholder : "Text Here...",
                            value : preInspObj.FaultReportz,
                            onChange : txt => setPreInspObj({ ...preInspObj, FaultReportz : txt.target.value})
                        }}
                        v={preInspObj.FaultReportz} />
                    </div>
                </div>

                <div className="pre-box-5">
                    <div className="pre-box-5-top">
                        <div className="pre-box-5-top-head">
                            <p className="p-0 m-0">Action Taken</p>
                        </div>
                        <div className="pre-box-5-top-head">
                            <p className="p-0 m-0">Authorized By</p>
                        </div>
                    </div>
                    <div className="pre-box-5-bottom">
                        <div className="pre-box-5-top-body">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={preInspObj.ReadyToOperate}
                                onChange={() => setPreInspObj({ ...preInspObj, ReadyToOperate : !preInspObj.ReadyToOperate})}
                            />
                            <label> I have conducted the above pre-start checklist and satisfied that the vehicle is safe and ready to operate.</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>
                        <div className="pre-box-5-top-body">
                            <input
                                type="checkbox"
                                className="vehicle1"
                                checked={preInspObj.ReportedFaults}
                                onChange={() => setPreInspObj({ ...preInspObj, ReportedFaults : !preInspObj.ReportedFaults})}
                            />
                            <label> I have reported all faults in appropriate section and notified the authorized personnel.</label>
                            <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                        </div>
                        <div className="pre-box-5-top-body">
                            <p style={{ textAlign: 'right', marginLeft: 'auto' }} className="p-0 m-0">Driver's  Signature</p>
                        </div>
                    </div>
                </div>

                <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>Manager Brief and Review</p>
                <div className="pre-box-6">
                    <div className="pre-box-6-top">
                        <input
                            type="checkbox"
                            className="vehicle1"
                            checked={preInspObj.ReviewdForm}
                            onChange={() => setPreInspObj({ ...preInspObj, ReviewdForm : !preInspObj.ReviewdForm})}
                        />
                        <label> I have reviewed this form and satisfied that required maintenance or safety related items have been addressed .</label>
                        <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                    </div>
                    <div className="pre-box-6-center">
                        <div className="pre-box-6-center-left">
                            <p className="p-0 m-0">I Certify that faults reported have been</p>
                        </div>
                        <div className="pre-box-6-center-right">
                            <div className="pre-box-6-center-right-top">
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={preInspObj.Corrected}
                                        onChange={() => setPreInspObj({ ...preInspObj, Corrected : !preInspObj.Corrected})}
                                    />
                                    <label> Corrected</label>
                                </div>
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={preInspObj.ScheduledForRepair}
                                        onChange={() => setPreInspObj({ ...preInspObj, ScheduledForRepair : !preInspObj.ScheduledForRepair })}
                                    />
                                    <label> Schedule  for repair</label>
                                </div>
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={preInspObj.NoAction}
                                        onChange={() => setPreInspObj({ ...preInspObj, NoAction : !preInspObj.NoAction})}
                                    />
                                    <label>No Action</label>
                                </div>
                            </div>
                            <div className="pre-box-6-center-right-bottom">
                                <div className="pre-box-1-bottom-check">
                                    <input
                                        type="checkbox"
                                        className="vehicle1"
                                        checked={preInspObj.DoNotAffectSafeOperation}
                                        onChange={() => setPreInspObj({ ...preInspObj, DoNotAffectSafeOperation : !preInspObj.DoNotAffectSafeOperation})}
                                    />
                                    <label> Issues scheduled for maintenance or repair do not affect the safe operation of this vehicle</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pre-box-6-bottom">

                    </div>
                </div>

                <div className="pre-box-6-submit">
                    {
                        submit_btn && textAreaValid ? (
                            <button onClick={() => submit_form()} className="pre-box-submit-btn">Submit</button>
                        ) : (
                            <button className="pre-box-submit-btn disabled-btn">Submit</button>
                        )
                    }

                </div>

            </Scrollbar>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { all_forklift_list, edit_pre_inspObj, selected_pre_inspect } = state.vehicle__forklift
    return { all_forklift_list, edit_pre_inspObj, selected_pre_inspect }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_all_forklifts: (tabType) => dispatch(fetch_all_forklift_list(tabType)),
        // create_preinspection: (data, success, failed) => dispatch(edit_preinspection(data, success, failed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

