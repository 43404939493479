import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Scrollbars from '../../../../common/ImprovedScrollBar'
import { Modal } from '@material-ui/core'
import { Description, Cancel } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip';
import { HOST } from '../../../../store/endpoint'

// import { team_add_file } from '../action'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({modalStatus, modalAction }) {
    const classes = useStyles();

    const handleClose = () => {
        modalAction(false)
    };

    const [FilesData, setFilesData] = useState([])

    // console.log('filessssssssss', files)

    let files = {
        files: [
            {
                id: 33,
                file_item: "uploads/employee/file-archive/Employment_Agreement_-_Signed_1.pdf"
            },
            {
                id: 33,
                file_item: "uploads/employee/file-archive/Employment_Agreement_-_Signed_1.pdf"
            },
        ],
        folder_name: 'General Folder'
    }

    const upload_file = () => {
        let formBody = new FormData()
        let i=0;
        formBody.append('employee', 'id')
        formBody.append('employee_folder', 1)
        for(i=0; i<FilesData.length; i++) {
            formBody.append('file_item', FilesData[i], FilesData[i].name)
        }
        // add_files_api(formBody)
        // console.log(formBody)
    }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="team-cerificate-modal-layout">
                <div className="cer-modal-header-layout">
                    <p className="cer-modal-main-title">{files.folder_name}</p>
                </div>
                <div className="cer-modal-body-layout">
                    <Scrollbars style={{ height: '45vh' }}>
                        <div className="cer-main-file-layout">
                        
                            {
                               files.files && files.files.map((item, key) => {
                                   const { file_item } = item
                                   let file_name = file_item.split('uploads/employee/file-archive/')[1]
                                   let newPageUrl = HOST + 'media/' + file_item
                                    return (
                                        <div onClick={() => window.open(newPageUrl, "_blank")} key={key} className="cer-single-file-certificate">
                                            <div className="cer-single-body">
                                                <Description />
                                            </div>
                                            <div className="cer-single-header">
                                                <div className="cer-test">
                                                    <p className="p-0 m-0 cer-text">{file_name}</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </Scrollbars>
                </div>
                <div className="cer-modal-footer-layout">
                    <div className="cer-modal-footer-layout-inner">
                        <div className="cer-footer-left">
                            <p className="cer-modal-footer-title">Upload File</p>
                        </div>
                        
                        <label htmlFor="file-input-cer">
                            <div className="cer-footer-right">
                                <p className="p-0 m-0">Choose Files</p>
                            </div>
                        </label>
                        <input 
                            id="file-input-cer" 
                            style={{ display: 'none' }} 
                            type="file" 
                            multiple
                            onChange={(image) => {
                                console.log(image.target.files)
                                var image_files = image.target.files
                                setFilesData(data => [...data, ...image_files])
                            }}
                        />
                    </div>

                    <div className="cer-modal-footer-layout-inner">
                        <div className="cer-footer-left-file">
                            {
                                FilesData.map((item, key) => {
                                    console.log(item)
                                    const { name } = item
                                    return (
                                        <div key={key}>
                                            <div 
                                                onClick={() => {
                                                    let update_data = FilesData.filter(itemz => itemz !== item )
                                                    setFilesData(update_data)
                                                }} 
                                                className="cer-file-remove">
                                                <Cancel style={{ fontSize: 16, color: 'red' }} />
                                            </div>
                                            <Tooltip title={name}>
                                                <div className="cer-file-single">
                                                    <Description />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            FilesData.length > 0 && (
                                <div onClick={() => upload_file()} className="cer-footer-right">
                                    <p className="p-0 m-0">Upload</p>
                                </div>
                            )
                        }
                        
                    </div>
                    
                </div>
            </div>
      </Modal>
  );
}


// const mapStateProps = (state) => {
//     const { id } = state.team.selected_employee
//     return { id }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         // add_files_api: (data) => dispatch(team_add_file(data))
//       }
// }

export default TransitionsModal
