import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
// import InfiniteScroll from 'react-infinite-scroll-component'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import SingleMember from './SingleMember'
import { CircularProgress } from '@material-ui/core'
// import { Folder } from '@material-ui/icons'
// import { Link } from 'react-router-dom'
import { all_vehicle_folders, team_list_paginated } from '../action'
import IntranetLayout from '../../../common/filesNFolder/FileFoldersLayout'
import {  
   // OHS_AND_S_FILE_ADD, 
    INTRANET_FOLDER_EDIT, 
    //OHS_AND_S_FILE_DELETE, 
    OHS_AND_S_FOLDER_DELETE,
    TEAM_GET_FOLDERS, 
   // INTRANET_FILES_RENAME,
   TEAM_INNER_FOLDER_CREATE
} from '../../../store/endpoint'
import SearchTab from './SearchTab'
import Endoflist from '../../../common/EndOfList'



function Members({ 
    team_list, 
    isLoading, 
    search_employee, 
    all_folders_api, 
    all_folders,
    getTeamCurr,
    getTeamTerm,
    team_page,
    stopPagination,
    permissions,
    error 
}) {

    useEffect(() => {
        all_folders_api()
        getTeamCurr(1)
        return () => all_folders_api.remove
    }, [all_folders_api, getTeamCurr])

    const [tabVal, setTabVal] = useState(true)
    const [pageNo, setPageNo] = useState({ p:1, t:true })

    const RenderLoading = () => {
        if(isLoading) {
            return (
                <div style={{ position: 'absolute', marginTop: '20%', marginLeft: '50%' }}>
                    <CircularProgress />
                </div>
            )
        } else {
            if(team_list.length === 0) {
                return (
                    <div style={{ position: 'absolute', marginTop: '5%', marginLeft: '50%' }}>
                         <div className="team-empty-layout">
                            <p className="m-0 p-0">No Team Data Found</p>
                        </div>
                    </div>
                )
            } else if(!stopPagination) 
                return (
                    <div style={{ marginTop: '2%', marginLeft: '50%' }}>
                        <CircularProgress />
                    </div>
                )
            else return null
        }
    }

    const ScrollUp = (val) =>{
        const {top} = val
        if(top === 1 && !stopPagination && !error && ((pageNo.p===team_page) || (team_page===1 && pageNo.p!==1 && pageNo.t!==tabVal))){
            setPageNo({ p : team_page === 1 ? 2 : pageNo.p+1, t : tabVal})
            if(tabVal)
                getTeamCurr(team_page+1)
            else 
                getTeamTerm(team_page+1)
            console.log('SCROLL','top:',top ,'stop:',stopPagination ,'err:',error ,'page',pageNo ,'team',team_page)
        }
    }

    return (
        <div className="team-members-layout">
            <div className="team-members-layout-main">           
               <div className="team-members-layout-top">
                   <div className="team-members-layout-top1">
                      <p className="employe-details">Employees details</p>
                      <div  className='Search-an-employeTabDiv' >
                          <div className={tabVal ? "empDetTabsL" : "empDetTabsL empDetTabsInactive"}
                          onClick={()=>{setTabVal(true); getTeamCurr(1); }}
                          >Current Employees</div>
                          <div className={!tabVal ? "empDetTabsR" : "empDetTabsR empDetTabsInactive"}
                          onClick={()=>{setTabVal(false); getTeamTerm(1);}}
                          >Terminated Employees</div>
                      </div>
                      <SearchTab    tabVal={tabVal}
                      />
                      {/* <div className='Search-an-employe'>
                         <input 
                            className='Search-an-employe-input'
                            placeholder="Search an employee"
                            onChange={txt => search_employee(txt.target.value, tabVal ? 'curr' : 'term')}
                        />
                      </div> */}
                   </div>
                   <div className="team-members-layout-top2">  
                      <Scrollbars autoHide style={{ height: '35vh', width: '100%' }}
                            className="team-members-main-layout"
                            onUpdate={ScrollUp}
                      >
                        <div className="team-members-main-layout">
                        {   team_list && team_list.length!==0 ?
                                team_list && team_list.map((item, key) => {
                                    // console.log(item)
                                    // if((tabVal && item.termination_date===null) || (!tabVal && item.termination_date!==null))
                                    return (
                                        <SingleMember 
                                            key={key} 
                                            data={item} 
                                        />
                                    )
                                    // else
                                    //     return null
                                })
                                :   null
                        }
                        </div>
                        {
                            team_list && team_list.length!==0 && stopPagination ?
                                <Endoflist />
                            :   null
                        }
                        <RenderLoading />

                        
                     </Scrollbars>
                     
                </div> 
                </div>  
                <div className="team-members-layout-bottom">
                    
                       <Scrollbars autoHide style={{ height: '33vh' }}>
                        
                             <IntranetLayout
                              APIURL={TEAM_GET_FOLDERS} title={'Employee Folders'}
                              FOLDEREDIT={
                                permissions?.current_user_permission==='director' ?
                                    INTRANET_FOLDER_EDIT 
                                    : null
                                }
                              FILEADD={null}
                                  //OHS_AND_S_FILE_ADD} 
                              FOLDERCREATE={
                                permissions?.current_user_permission==='director' ?
                                    TEAM_INNER_FOLDER_CREATE
                                    :   null
                              }
                              FILEEDIT={null}
                              //{INTRANET_FILES_RENAME}
                              FOLDERDELETE={permissions?.current_user_permission==='director' ?
                                    OHS_AND_S_FOLDER_DELETE
                                :   null} 
                              FILEDELETE={null}
                              //{OHS_AND_S_FILE_DELETE}
                             />         

                       </Scrollbars>
                    {/* </div>                */}
                </div>
            </div>
        </div>
    )
}

const mapStateProps = (state) => {
    const { team_list, isLoading, all_folders, team_page, stopPagination, error } = state.team
    const { permissions } = state.userLogin
    return {team_list, isLoading, all_folders, team_page, stopPagination, error, permissions}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeamCurr: (page) => dispatch(team_list_paginated('curr', page)),
        getTeamTerm: (page) => dispatch(team_list_paginated('term', page)),
        all_folders_api: () => dispatch(all_vehicle_folders())
      }
}

export default connect(mapStateProps, mapDispatchToProps) (Members)

