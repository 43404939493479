import React, {useRef, useState} from 'react'
import { connect } from 'react-redux'
import Scrollview from '../../../common/ImprovedScrollBar.jsx';
import JoditEditor from "jodit-react";
import EditTable from './DualAlterCWBModal.jsx'
import EditAssetTable from './AssetCheckQModel.jsx'
// import moment from 'moment';
import { PUMPASSETRATES } from '../../../store/endpoint';
import Api from '../../../store/api'
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import EditableEditorModal from '../component/editableEditorModal.jsx';

function EditorMain({ quote_content, set_quote_content, set_quote_amount, editorRef, clientId, user, tmplType, setTableDataObj }) {
    const config = {
        readonly: true,
        toolbar : false
    }

    const [editTable, setEditTable] = useState(false)
    const [clientDet, setClientDet] = useState(null)
    const [assetList, setAssetList] = useState({ loader: true, data : []})
    const [editorModal, setEditorModal] = useState(false)
    const editorModalRef = useRef()
    const [editableQuoteContent, setEditableQuoteContent] = useState(quote_content ? quote_content : 'Content not loaded, Please try again.')

    const getFetchData = () =>{
        Api('get', PUMPASSETRATES).then(res => {
            console.log(res)
            setAssetList({
                loader : false,
                data : res
            })
        })
    }
    
    if(clientDet!==clientId){
        setClientDet(clientId)
        console.log(clientId)
        let quoteDet1 = [
            `<!--##PUMP1_QUOTE--><table class="c35" style="margin-left: -72pt;border-spacing: 0;border-collapse: collapse;margin-right: auto;">
            <tbody><tr class="c103" style="height: 74.4pt;"><td class="c60" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #ffffff;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 329.2pt;border-top-color: #ffffff;border-bottom-style: solid;">
            <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;"><br>
            </p><br></td><td class="c30 c70" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #ffffff;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 282.8pt;border-top-color: #ffffff;border-bottom-style: solid;background-color: #ffffff;">
            <p class="c12 c26" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;orphans: 2;widows: 2;">
            <span class="c63" style="font-size: 13pt;font-weight: 700;">Date:</span><span class="c75" style="font-size: 13pt;">${moment(new Date()).format('DD/MM/YYYY')}`,
            
            `&nbsp; </span></p><p class="c12 c26 c53" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;orphans: 2;widows: 2;margin-right: 34.3pt;">
            <span class="c63" style="font-size: 13pt;font-weight: 700;">Quote No: AUTOFETCH
            </span></p><p class="c12 c26 c53" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;orphans: 2;widows: 2;margin-right: 34.3pt;">
            <span class="c34 c73 c75" style="color: #000000;text-decoration: none;vertical-align: baseline;font-family:Arial, sans-serif;font-style: normal;font-size: 13pt;font-weight: 700;">Salesperson: `,
            
            `</span></p><p class="c15" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;">
            <span class="c34 c75 c73" style="color: #000000;text-decoration: none;vertical-align: baseline;font-family:Arial, sans-serif;font-style: normal;font-size: 13pt;font-weight: 700;">`,`Site: `,
            
            `</span></p><p class="c15" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;">
            <span class="c63" style="font-size: 13pt;font-weight: 700;">Valid For:
            </span><span class="c7 c34" style="color: #000000;text-decoration: none;vertical-align: baseline;font-family:Arial, sans-serif;font-style: normal;font-size: 10pt;font-weight: 700;">&nbsp;</span>
            </p></td></tr></tbody></table>`
        ]
        let outPut1 = quote_content.substring(0,quote_content.indexOf('<!--##PUMP1_QUOTE-->'))
        let outPut2 = quote_content.substring(quote_content.indexOf('<!--##PUMP1_QUOTE_E-->'))

        let TitleTxt = ((clientId.site_address===null || clientId.site_address==='null') && (clientId.company_contact_number===null || clientId.company_contact_number==='null') && (clientId.company_email===null || clientId.company_email==='null')) ? '' : quoteDet1[3]
        let siteAddrrTxt = clientId.site_address===null || clientId.site_address==='null' ? '' : clientId.site_address
        let compEmail = clientId.company_email===null || clientId.company_email==='null' ? '' : clientId.company_email
        let contNo = clientId.company_contact_number===null || clientId.company_contact_number==='null' ? '' : clientId.company_contact_number
        
        set_quote_content(outPut1 + quoteDet1[0] + quoteDet1[1] + user.name + quoteDet1[2] + TitleTxt + siteAddrrTxt + ' ' + contNo + ' ' + compEmail + quoteDet1[4] + outPut2)
        
    }
    return (
        <div className="sales-qt-client-info">
            <Scrollview style={{ height: '65vh'}}>
            {typeof clientId === 'object' && clientId!==null ?
                <button 
                    className={editTable ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn"}
                    onClick={() =>{  if(tmplType){ getFetchData(); } setEditTable(true); }}  style={{ height:'3vh', width:'15vw', margin:'8px 0' }}
                >Update { tmplType ? 'Asset Check' : 'Dual Alter Cold Water Booster'}</button>
                :   <p style={{ color:'red' }}>Choose the client to edit the template</p>
            }
           {   typeof clientId === 'object' && clientId!==null ?
            <button 
                    className={editorModal ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn"}
                    onClick={() =>{ setEditorModal(true) }}  style={{ height:'3vh', width:'10vw', margin:'8px 0', float:'right' }}
                >Edit Main Quote</button>
                : null
            }
                 
            <JoditEditor
                ref={editorRef}
                value={quote_content}
                config={config}
                tabIndex={1}
                onBlur={ () => { 
                    set_quote_content(editorRef.current.value)
                }}
            />
            </Scrollview>
            { tmplType ?

            assetList.loader && !assetList.data ?
            <CircularProgress />
        :
            <EditAssetTable
                modalStatus={editTable} 
                modalAction={setEditTable} 
                templateStr={quote_content} 
                setTemplateStr={set_quote_content} 
                clientDet={clientId}
                setQuoteAmount={set_quote_amount}
                assetList={assetList.data}
                setTableDataObj={setTableDataObj}
            />
            :
            <EditTable 
                modalStatus={editTable} 
                modalAction={setEditTable} 
                templateStr={quote_content} 
                setTemplateStr={set_quote_content} 
                clientDet={clientId}
                setQuoteAmount={set_quote_amount}
                setTableDataObj={setTableDataObj}
            />
            } 

            <EditableEditorModal
                quote_content={editableQuoteContent}
                set_quote_content={set_quote_content}
                set_quote_amount={set_quote_amount}
                setEditableQuoteContent={setEditableQuoteContent}
                editorRef={editorModalRef}
                clientId={clientId}
                user={user}
                modalStatus={editorModal}
                modalAction={setEditorModal}
                Tempname={'Enviro Pump Template'}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { quote_content } = state.sales
    const { user } = state.userLogin 

    return { quote_content, user }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_quote_content: (content) => dispatch({ type: 'SALES_SET_CONTENT_QUOTE', content: content }),
        set_quote_amount: (amount) => dispatch({ type: 'SALES_SET_QUOTE_AMOUNT', amount: amount })
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (EditorMain)
