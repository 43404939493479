import moment from 'moment'
import Api from '../../../store/api'
import { TIMESHEET_CRUD, ACCOUNTS_TIMESHEET_LIST, ACCOUNTS_TIMESHEET_STATUS_UPDATE, ACCOUNTS_TIMESHEET_LOG } from '../../../store/endpoint'

export const fetch_all_timesheetList = (page) =>{
    return function (dispatch){
        let get_url = `${ACCOUNTS_TIMESHEET_LIST}?limit=20&page=${page}`
        dispatch({
            type : 'TIMESHEETLISTLOADER_UPDATE',
            loader : true
        })
        Api('get',get_url).then(res => {
            if(res){
                dispatch({
                    type : 'TIMESHEETLIST_UPDATE',
                    data : res,
                    page : page
                })
            }
            else{
                dispatch({
                    type : 'TIMESHEETLISTLOADER_UPDATE',
                    loader : false
                })
            }
        })
    }
}

export const timeSheet_status = (data, id, success, failed) => {
    return function (dispatch){
        let get_url = `${ACCOUNTS_TIMESHEET_STATUS_UPDATE}${id}/`
        Api('post',get_url,data, success, failed).then(res => {
            if(res){
                dispatch({
                    type : 'REMOVE_TIMESHEET',
                    id   : id
                })
            }
        })
    }
}


export const team_empl_timeSheet = (content=null, success=null, failed=null, content_Date=null, type=null) =>{
    // console.log('TIMEAPI',TIMESHEET_CRUD)

if(content)
return function (dispatch){
    // for(var k of content.entries())
    //             console.log(k[0]," ",k[1], typeof k[1])
    
    Api(
        'post-form',
        TIMESHEET_CRUD,
        content,
        success,
        failed
    )
}
else if(content_Date)
return function (dispatch){
    Api(
        'get',
        `${TIMESHEET_CRUD}?date=${content_Date}`
    ).then(response =>{
        console.log(response)
        dispatch(
            {
                type:'INIT_EMPLOYEE_TIMESHEET',
                timeSheet: response,
                filterDate: content_Date,
                userId : type,
            }
        )
    })
    .catch(err => {
        dispatch(
            {
                type:'INIT_EMPLOYEE_TIMESHEET',
                timeSheet: null,
                filterDate: content_Date,
                userId : type,
            }
        )
    })
}
 
else{
return function (dispatch){
    Api(
        'get',
        TIMESHEET_CRUD
    ).then(response =>{
        dispatch(
            {
                type:'INIT_EMPLOYEE_TIMESHEET',
                timeSheet: response,
                todayObjEx : response.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD')))
            }
        )
    })
}
}
}

export const team_timeSheet_edit = (content, id, success, failed) =>{
    return function (dispatch){
        Api(
            'put-json',
            `${TIMESHEET_CRUD}${id}/`,
            content,
            success,
            failed
        ).then(response => {
            dispatch({
                type : 'UPDATE_EMPLOYEE_TIMESHEET',
                timeSheet : response
            })
        })
    }
}

export const team_timeSheetLogList = (id, page=null, success, failed) => {
    return function (dispatch){
        let get_url = `${ACCOUNTS_TIMESHEET_LOG}${id}/?limit=20&page=${page?1:page}`
        dispatch({
            type : 'TIMESHEETACCESSLOADER_UPDATE',
            loader : true
        })
        Api('get',get_url).then(res => {
            if(res){
                dispatch({
                    type : 'TIMESHEET_ACCESS_LOG',
                    timeSheet : res,
                    page : page ? 1 : page
                })
                success()
            }
        })
        .catch(err => {
            failed(err)
        })
    }
}