import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import './styles.css'
import { connect } from 'react-redux';


function RefetchPerms({
    modalAction,
    refFn
}) {

    const handleClose = () => {
        modalAction(false)
    };

    const [refetch, setRefetch] = useState(false)

    const ChngFn = () =>{
      setTimeout(() => {
        setRefetch(true)
      }, 5000)
      if(refetch){
        return(
          <div className="refetchPermsContent">
                <p>Permissions Updated</p>
                
                <div onClick={()=>{ 
                  refFn()
                  handleClose();
                  window.location.reload();

                }}>Ok</div>
            </div>
        )
        }
        else{
          return(
            <div className="refetchPermsContent">
                <p>Please be patient till we update your permissions</p>
                <span>
                    <CircularProgress />
                </span>
            </div>
          )
        }
      
    }

  return (
        <div className="refetchPermsModal">
            <div className="refetchPermsTitle">You have changed your permission settings</div>
            { 
              ChngFn()
            }
        </div>
  );
}

const mapStateToProps = (state) => {
  const { user, permissions } = state.userLogin
  return { user, permissions}
}


const mapDispatchToProps = (dispatch) => {
  return {

    }
}



 export default connect(mapStateToProps, mapDispatchToProps) (RefetchPerms)
 
