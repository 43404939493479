import React, {useState} from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { Truck, Car, Forklift } from './component'

function App({permissions}) {
    const { path } = useRouteMatch()
    const [tabType, setTabType] = useState( permissions.vehicle.waste ? 'waste'
    :   permissions.vehicle.pumps ? 'pumps' : permissions.vehicle.hills ? 'hills' : 'destruction')

    if(permissions.vehicle.waste && tabType==='waste')
    return (
        <Switch>
            <Route path={path + '/truck'} name="client-create"><Truck  tabType={tabType} setTabType={setTabType}/></Route>
            <Route path={path + '/car'} name="client-detail"><Car  tabType={tabType} setTabType={setTabType}/></Route>
            <Route path={path + '/forklift'} name="client-detail"><Forklift  tabType={tabType} setTabType={setTabType}/></Route>
            <Redirect from={path + '/'} to={path + '/truck'} />
        </Switch>
    )
    else if(permissions.vehicle.pumps && tabType==='pumps')
        return(
        <Switch>
            <Route path={path + '/truck'} name="client-create"><Truck  tabType={tabType} setTabType={setTabType}/></Route>
            <Route path={path + '/utes'} name="client-detail"><Car  tabType={tabType} setTabType={setTabType}/></Route>
            <Route path={path + '/forklift'} name="client-detail"><Forklift  tabType={tabType} setTabType={setTabType}/></Route>
            <Redirect from={path + '/'} to={path + '/truck'} />
        </Switch>)
    else if(permissions.vehicle.hills && tabType==='hills')
            return (
        <Switch>
            <Route path={path + '/machinery'} name="client-create"><Forklift  tabType={tabType} setTabType={setTabType}/></Route>
            <Redirect from={path + '/'} to={path + '/machinery'} />
        </Switch>)
    else 
            return(
            <Switch>
                <Route path={path + '/forklift'} name="client-detail"><Forklift  tabType={tabType} setTabType={setTabType}/></Route>
                <Redirect from={path + '/'} to={path + '/forklift'} />
            </Switch>    
            )
        
}

const mapStateToProps = (state) => {
    const { permissions } = state.userLogin
    return { permissions }
}

export default connect(mapStateToProps, null) (App)
