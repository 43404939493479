import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { add_employee, designation_list } from '../action'
// import { AddAPhoto } from '@material-ui/icons'
import DummyDpIcon from '../../../assets/Images/Group 18356.png'
import DateTimeInput from '../../../common/DateTimeInput'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import { NUMBER_VALIDATION_REGEX } from '../../../store/config'

import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import { CancelRounded, CheckCircleRounded } from '@material-ui/icons'
import Api from '../../../store/api';
import { TEAM_EMPID_EXIST, TEAM_USRNAME_EXIST } from '../../../store/endpoint'
import PasswordInputFormatter from '../../../common/PasswordInputFormatter'

function Settings({ dispatch }) {

    const [Designations, setDesignations] = useState([])
    const [UserType, setUserType] = useState('')

    const initalApiCallBack = (response) => {
        setDesignations(response.designations)
        setUserType(response.designations[0].user_type)
    }

    useEffect(() => {
        designation_list(initalApiCallBack)
        return () => designation_list.remove
    }, [])

    let route_history = useHistory();
    // to recieve the input data from user
    const [TeamId, setTeamId] = useState('')
    const [Name, setName] = useState('')

    const [profileImage, setProfileImage] = useState('')
    const [tempImage, setTempImage] = useState('')
    const [Address, setAddress] = useState('')
    const [ContactNo, setContactNo] = useState('')
    const [EmailId, setEmailId] = useState('')
    const [PersonalEmailAddress, setPersonalEmailAddress] = useState('')
    // const [IdProof, setIdProof] = useState('driving-license')
    // const [IdNumber, setIdNumber] = useState('')
    const [DOB, setDOB] = useState('')
    // const [TerminationDate, setTerminationDate] = useState('')
    const [JoiningDate, setJoiningDate] = useState('')

    const [Attachment, setAttachment] = useState('')

    const [LicenseExpiry, setLicenseExpiry] = useState('')
    const [LicenseAlert, setLicenseAlert] = useState('')
    const [Username, setUsername] = useState('')
    const [Password, setPassword] = useState('')
    const [CPassword, setCPassword] = useState('')
    //loading button status
    const [btnLoader, setBtnLoader] = useState(false)
    const [error, setError] = useState('')
    const [EmContactName, setEmContactName] = useState('')
    const [EmContactNo, setEmContactNo] = useState('')
    const [EmpStatus, setEmpStatus] = useState('full_time')

    const [DateEditDOB, setDateEditDOB] = useState(false)
    const [DateEditJoinDate, setDateEditJoinDate] = useState(false)
    const [DateEditLExpiry, setDateEditLExpiry] = useState(false)
    const [DateEditLAlert, setDateEditLAlert] = useState(false)

    const [validEmpId, setValidEmpId] = useState(null)
    const [validEmpEmail, setValidEmpEmail] = useState(null)
    //submit btn status
    let btn_status = TeamId && Name && UserType && ContactNo && EmailId && JoiningDate && Username && Password && CPassword
        && EmContactName && EmContactNo && validEmpEmail && validEmpId
        ? true : false

    // if(Attachment) {
    //     if(LicenseExpiry && LicenseAlert) {
    //         btn_status = true
    //     } else {
    //         btn_status = false
    //     }
    // }
    //working of submit butn after click
    const submit_btn = () => {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        // let isValid;
        if (!pattern.test(EmailId) || (!pattern.test(PersonalEmailAddress) && PersonalEmailAddress !== '')) {
            //   isValid = false;
            if (!pattern.test(EmailId)) {
                setError("Please enter valid email address.")
                setEmailId('')
            }
            if (!pattern.test(PersonalEmailAddress) && PersonalEmailAddress !== '') {
                setError("Please enter valid email address.")
                setPersonalEmailAddress('')
            }

        } else

            if (Password === CPassword) {
                if (Password.length > 7) {

                    setBtnLoader(true)
                    let form_body = new FormData()

                    let attachment_file_name = Attachment.name


                    form_body.append('employee_id', TeamId)
                    //console.log('emp id', TeamId)
                    form_body.append('name', Name)
                    form_body.append('user_type', UserType)
                    form_body.append('contact_number', ContactNo)
                    form_body.append('personal_email', PersonalEmailAddress)
                    form_body.append('email', EmailId)
                    form_body.append('date_of_birth', DOB)
                    form_body.append('date_joined', JoiningDate)
                    // form_body.append('termination_date', TerminationDate)
                    form_body.append('address', Address)
                    form_body.append('emergency_contact_name', EmContactName)
                    form_body.append('emergency_contact', EmContactNo)
                    form_body.append('employement_status', EmpStatus)
                    //console.log(Attachment)

                    if (Attachment) {
                        form_body.append('driving_license', Attachment, attachment_file_name)
                        form_body.append('expiry_date', LicenseExpiry)
                        form_body.append('alert_before', LicenseAlert)
                    }

                    // form_body.append('id_proof_type', IdProof)
                    // form_body.append('id_proof_number', IdNumber)
                    // form_body.append('id_proof_attachment', Attachment, attachment_file_name)
                    form_body.append('username', Username)
                    form_body.append('password', Password)

                    if (profileImage) {
                        let profile_img_name = profileImage.name
                        form_body.append('dp', profileImage, profile_img_name)
                    }
                    setDateEditDOB(false)
                    setDateEditJoinDate(false)
                    setDateEditLAlert(false)
                    setDateEditLExpiry(false)

                    // changes after btn submit
                    const success = (response) => {
                        route_history.push('/team')
                        document.getElementById('file-input').value = ''
                        document.getElementById('license-file-input').value = ''
                        setBtnLoader(false)
                        setTeamId('')
                        setName('')
                        setUserType('')

                        setAddress('')
                        setPersonalEmailAddress('')
                        // setTerminationDate('')
                        setContactNo('')
                        setEmailId('')
                        setDOB('')
                        setJoiningDate('')

                        setLicenseExpiry('')
                        setLicenseAlert('')
                        setUsername('')
                        setPassword('')
                        setCPassword('')
                        setProfileImage('')
                        setTempImage('')
                        setError('')
                        setEmContactName('')
                        setEmContactNo('')
                        setEmpStatus('')
                        setAttachment('')
                        setValidEmpId(null)
                        setValidEmpEmail(null)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Empoyee added successfull!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    //changes after fail
                    const failed = (error) => {
                        setBtnLoader(false)
                        // console.log("FAILEDERR",error)
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.app_data ? error.app_data : 'Something went wrong!'
                        })
                    }

                    dispatch(add_employee(form_body, success, failed))

                } else {
                    setError('Password length should be greater than 7')
                }
            } else {
                setError('Password and confirm password not match')
                setPassword('')
                setCPassword('')
            }


    }

    function RenderSubmitBtn() {
        if (btnLoader) {
            return (
                <div className="team-add-btn disabled-btn">
                    <CircularProgress size={20} color='inherit' />
                </div>
            )
        } else if (btn_status) {
            return (
                <div onClick={() => submit_btn()} className="team-add-btn">
                    <p className="p-0 m-0">CREATE</p>
                </div>
            )
        } else {

            return (
                <div className="team-add-btn disabled-btn">
                    <p className="p-0 m-0">CREATE</p>
                </div>
            )
        }
    }

    const CheckIfExist = (val, id) =>{
        if(val){
            Api('get',`${TEAM_EMPID_EXIST}?user_id=${id}`).then(res => {
                if(res)
                    setValidEmpId(true)
                else 
                    setValidEmpId(false)
            })
        }
        else{
            Api('get',`${TEAM_USRNAME_EXIST}?username=${id}`).then(res => {
                if(res)
                    setValidEmpEmail(true)
                else 
                    setValidEmpEmail(false)
            })
        }

    }

    return (
        <div className="team-settings-layout">
            <div className="teamDpSetting">
                <label htmlFor="file-input">
                    <div>
                        {
                            tempImage ? (
                                <img style={{ height: 65, width: 65, borderRadius: '10px', cursor: 'pointer' }} src={tempImage} alt="" />
                            ) : (
                                <div style={{
                                    height: 70, width: 70, borderRadius: '8px', backgroundColor: 'lightgray', display: 'flex',
                                    justifyContent: 'center', alignItems: 'center', color: 'white', cursor: 'pointer', position: 'relative'
                                }}>
                                    <img style={{
                                        height: 65, width: 65, cursor: 'pointer', backgroundColor: 'transparent', borderRadius: '8px',
                                        
                                    }} src={DummyDpIcon} alt="" />
                                </div>
                            )
                        }
                    </div>
                </label>
                <input id="file-input" style={{ display: 'none' }} type="file" multiple={false}
                    onChange={(image) => {
                        var image_file = image.target.files[0]
                        let extension = image_file.type
                        if (extension === 'image/jpeg' || extension === 'image/png') {
                            var image_blob = URL.createObjectURL(image_file)
                            setTempImage(image_blob)
                            setProfileImage(image_file)
                        } else {
                            alert('File should be an Image. Only jpg and png files are accepted')
                        }

                    }}
                />

            </div>

            <Scrollbars autoHide style={{ height: '80vh' }}>
                <div className="team-setting-main">

                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }} >
                        <p className="team-setting-main-title">Create a new Employee</p>
                        {/* <img className="team-detail-img" src={dp_image} alt="enviro team" /> */}

                    </div>

                    <div style={{ paddingTop: 0 }} className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">EMP ID <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span> </p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="text"
                                placeholder="Employee ID"
                                className="team-input"
                                value={TeamId}
                                onChange={txt => {
                                    if (txt.target.value === '') {
                                        setTeamId('')
                                    } else if (NUMBER_VALIDATION_REGEX.test(txt.target.value)) {
                                        setTeamId(txt.target.value)
                                    }
                                    setValidEmpId(null)
                                    if(txt.target.value!=='' && TeamId && NUMBER_VALIDATION_REGEX.test(txt.target.value)){ CheckIfExist(true, txt.target.value) }
                                }}
                            />
                            <span className='team-data-check'>
                                {
                                    validEmpId === null && TeamId==='' ?
                                        null
                                    :
                                    validEmpId === null && TeamId!=='' ?
                                        <CircularProgress size={15} style={{ color:'blue' }} />
                                    :
                                    validEmpId ?
                                        <CheckCircleRounded size={15} style={{ color:'green' }} />
                                    :
                                        <CancelRounded size={15} style={{ color:'red' }} />
                                }
                            </span>
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Name <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="text"
                                placeholder="Name"
                                className="team-input"
                                value={Name}
                                onChange={txt => setName(txt.target.value)}
                            />
                        </div>
                    </div>


                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Address <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="text"
                                placeholder="Address"
                                className="team-input"
                                value={Address}
                                onChange={txt => setAddress(txt.target.value)}
                            />
                        </div>
                    </div>




                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Position Title <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            {/* <input 
                        type="text"
                        placeholder="User Type"
                        className="team-input"
                        value={UserType}
                        onChange={txt => setUserType(txt.target.value)}
                    /> */}
                            <select
                                className="team-input"
                                style={{ textTransform: 'uppercase' }}
                                value={UserType}
                                onChange={txt => setUserType(txt.target.value)}
                            >
                                {
                                    Designations.map((item, key) => {
                                        return (
                                            <option key={key} value={item.user_type}
                                                style={{ textTransform: 'uppercase' }}
                                            >{item.user_type}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Employment Status <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            {/* <input 
                        type="text"
                        placeholder="User Type"
                        className="team-input"
                        value={UserType}
                        onChange={txt => setUserType(txt.target.value)}
                    /> */}
                            <select
                                className="team-input"
                                value={EmpStatus}
                                onChange={txt => setEmpStatus(txt.target.value)}
                            >

                                <option key='1' value='full_time'>Full-Time</option>
                                <option key='2' value='part_time'>Part-Time</option>
                                <option key='3' value='casual'>Casual</option>
                            </select>
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Contact No <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="text"
                                placeholder="Number"
                                className="team-input"
                                value={ContactNo}
                                onChange={txt => {
                                    if (txt.target.value === '') {
                                        setContactNo('')
                                    } else if (NUMBER_VALIDATION_REGEX.test(txt.target.value)) {
                                        setContactNo(txt.target.value)
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Email Address <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="email"
                                placeholder="Email Address"
                                className="team-input"
                                value={EmailId}
                                onChange={txt => {
                                    let mailVal = txt.target.value
                                    setEmailId(mailVal)
                                    setUsername(txt.target.value)
                                    setValidEmpEmail(null)
                                    if(txt.target.value!=='' && EmailId){ CheckIfExist(false, txt.target.value) }
                                }}
                            />
                            <span className='team-data-check'>
                                {
                                    validEmpEmail === null && EmailId==='' ?
                                        null
                                    :
                                    validEmpEmail === null && EmailId!=='' ?
                                        <CircularProgress size={15} style={{ color:'blue' }} />
                                    :
                                    validEmpEmail ?
                                        <CheckCircleRounded size={15} style={{ color:'green' }} />
                                    :
                                        <CancelRounded size={15} style={{ color:'red' }} />
                                }
                            </span>
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Personal Email Address </p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="email"
                                placeholder="Personal Email Address"
                                className="team-input"
                                value={PersonalEmailAddress}
                                onChange={txt => {
                                    let mailVal = txt.target.value
                                    setPersonalEmailAddress(mailVal)
                                }}
                            />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Date Of Birth <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span> </p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            {
                                DOB === "" && !DateEditDOB ?
                                    <CalendarTodayRoundedIcon style={{ fontSize: '16px' }} onClick={() => setDateEditDOB(true)} />
                                    :
                                    <DateTimeInput
                                        dateValue={DOB}
                                        dateSetFn={setDOB}
                                        clsName={'team-input'}
                                        future={true}
                                        openEdit={DateEditDOB}
                                    />

                            }
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Joining Date <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span> </p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            {
                                JoiningDate === "" && !DateEditJoinDate ?
                                    <CalendarTodayRoundedIcon style={{ fontSize: '16px' }} onClick={() => setDateEditJoinDate(true)} />
                                    :
                                    <DateTimeInput
                                        dateValue={JoiningDate}
                                        dateSetFn={setJoiningDate}
                                        clsName={'team-input'}
                                        rangeDate={DOB}
                                        openEdit={DateEditJoinDate}
                                    />
                            }
                        </div>
                    </div>

                    {/* <div className="team-data-noraml">
                    <div className="team-data-left">
                        <p className="p-0 m-0">Termination Date </p>
                    </div>
                    <div className="team-data-center">
                        <p className="p-0 m-0">: </p>
                    </div>
                   <div className="team-data-right">
                    
                    <DateTimeInput
                        dateValue={TerminationDate}
                        dateSetFn={setTerminationDate}
                        clsName={'team-input'}
                    />
                   </div>
                </div> */}


                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">License file
                                {
                                    UserType.includes('driver') ? <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                        : null
                                }</p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="file"
                                id="license-file-input"
                                className="team-input"
                                onChange={file => {

                                    setAttachment(file.target.files[0])
                                    // let extension = doc.type
                                    // if(extension === 'image/jpeg' || extension === 'image/png') {
                                    //     var image_blob = URL.createObjectURL(image_file)
                                    //     setClientImage(image_blob)
                                    //     setClientImageFile(image_file)
                                    // } else {
                                    //     alert('File should be an Image. Only jpg and png files are accepted')
                                    // }
                                }}
                            />
                            {
                                (LicenseExpiry !== '' || LicenseAlert !== '') && Attachment === '' ?
                                    <p style={{ fontSize: 11, color: 'red', padding: 0, marginLeft: 20, marginTop: 5, marginBottom: 5 }} >File Required</p>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">License Expiry
                                {
                                    UserType.includes('driver') ? <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                        : null
                                }</p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            {
                                LicenseExpiry === "" && !DateEditLExpiry ?
                                    <CalendarTodayRoundedIcon style={{ fontSize: '16px' }} onClick={() => setDateEditLExpiry(true)} />
                                    :

                                    <DateTimeInput
                                        dateValue={LicenseExpiry}
                                        dateSetFn={setLicenseExpiry}
                                        clsName={'team-input'}
                                        openEdit={DateEditLExpiry}
                                    />
                            }
                        </div>
                    </div>
                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">License Alert
                                {
                                    UserType.includes('driver') ? <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>
                                        : null
                                }</p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            {
                                LicenseAlert === "" && !DateEditLAlert ?
                                    <CalendarTodayRoundedIcon style={{ fontSize: '16px' }} onClick={() => setDateEditLAlert(true)} />
                                    :
                                    <DateTimeInput
                                        dateValue={LicenseAlert}
                                        dateSetFn={setLicenseAlert}
                                        clsName={'team-input'}
                                        openEdit={DateEditLAlert}
                                    />
                            }
                        </div>
                    </div>
                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Emergency Contact <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="text"
                                placeholder="Name"
                                className="team-input"
                                value={EmContactName}
                                onChange={txt => setEmContactName(txt.target.value)}
                            />
                        </div>
                    </div>
                    <div className="team-data-noraml">
                        <div className="team-data-left">
                            <p className="p-0 m-0">Emergency Contact No <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span></p>
                        </div>
                        <div className="team-data-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                            <input
                                type="text"
                                placeholder="Number"
                                className="team-input"
                                value={EmContactNo}
                                onChange={txt => {
                                    if (txt.target.value === '') {
                                        setEmContactNo('')
                                    } else if (NUMBER_VALIDATION_REGEX.test(txt.target.value)) {
                                        setEmContactNo(txt.target.value)
                                    }
                                }}
                            />
                        </div>
                    </div>




                    <div className="team-credential-layout">
                        {/* <p className="p-0 m-0">Credentials for Enviro</p> */}
                        <div className="team-form-cred">
                            <div className="team-credential-form">
                                <div className="team-credential-left">
                                    <p className="p-0 m-0">Username <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span> </p>
                                </div>
                                <div className="team-credential-center" >
                                    <input
                                        type="text"
                                        className="credential-input"
                                        value={Username}
                                        readOnly
                                        onChange={txt => setUsername(txt.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="team-credential-form">
                                <div className="team-credential-left">
                                    <p className="p-0 m-0">Password <span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span> </p>
                                </div>
                                <PasswordInputFormatter
                                        className="credential-input"
                                        value={Password}
                                        outerDiv={{ className : "team-credential-center" }}
                                        onChange={txt => setPassword(txt.target.value)}
                                    />
                                    {/* <input
                                        type="password"
                                        className="credential-input"
                                        value={Password}
                                        onChange={txt => setPassword(txt.target.value)}
                                    /> */}
                                
                            </div>

                            <div className="team-credential-form">
                                <div className="team-credential-left">
                                    <p className="p-0 m-0">Confirm Password  </p>
                                </div>
                                <PasswordInputFormatter
                                        className="credential-input"
                                        value={CPassword}
                                        outerDiv={{ className : "team-credential-center" }}
                                        onChange={txt => setCPassword(txt.target.value)}
                                    />
                                    {/* <input
                                        type="password"
                                        className="credential-input"
                                        value={CPassword}
                                        onChange={txt => setCPassword(txt.target.value)}
                                    /> */}
                            </div>
                        </div>
                    </div>
                    <p style={{ fontSize: 11, color: 'red', padding: 0, marginLeft: 20, marginTop: 5, marginBottom: 5 }} >{error}</p>
                    <div className="team-add-submit-layout">
                        <RenderSubmitBtn />
                    </div>

                </div>
            </Scrollbars>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { team } = state
    return { team }
}


export default connect(mapStateToProps)(Settings)

