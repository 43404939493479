import React, { useState, useRef, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Scrollview from '../../../common/ImprovedScrollBar';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import JoditEditor from "jodit-react";
import { CircularProgress, Tooltip } from '@material-ui/core'
import moment from 'moment'
import { connect } from 'react-redux';
import { create_temp_clients, del_single_draft, edit_single_draft, fetch_quote_client_draft, sales_quote_client,
    fetch_quote_template_new, fetch_single_draft, sales_quoter_registration, fetch_all_clientsList } from '../common/action';
import Swal from 'sweetalert2';

import FolderModal from './folderModal'
import QuoteConfirmModal from './quote_confirm_modal'
// import Editor from '../waste/editorMain'
import EditorPump from '../pump/editorMain'
import { cl_red, cl_green, cl_blue } from '../../../common/Colors'
import ClientInfo from './TempPermCls'
import { CheckCircle, DeleteForever, EditRounded, ExpandMoreRounded } from '@material-ui/icons';
import NewJobCard from '../../jobs/components/WasteJobCard.jsx';
// import NewPumpJobCard from '../../jobs/components/PumpJobCards'
// import NewJobCardForNewTemp from '../../jobs/components/JobCardForNewTemp'
import { AddCircleOutlined } from '@material-ui/icons';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
// import ReoccurModalBox from './reoccur_date_modal.jsx'
import { CLIENT_SINGLE_FETCH, NEWJOBCARD_CLIENT_LIST, PREV_JOBCARD_DATA, SALES_QUOTE_DRAFT_GEN_CAUTION } from '../../../store/endpoint';
import Api from '../../../store/api';
import MainEditorTable from '../../../common/mainEditorTable';
import JobCardTypeModal from './JobCardTypeModal.jsx';

function GenerateQuote({ 
    quote_templates, 
    temp_client, 
    perm_Clients, 
    fetchAllClients,
    create_tempClient,
    // fetch_attachFolders,
    set_quote_content,
    fetch_drafts,
    sale_client_drafts_list,
    fetch_template_drafts,
    sale_eight_template,
    fetch_single_draft,
    sale_client_draft,
    quoteSavedDraft,
    edit_saved_draft,
    setSearchQuery,
    quote_content,
    setQuoteSavedDraft,
    selClientDet,
    delSingleDraft,
    quote_amount,
    // jobCard_list,
    tabType,
    templateLoading,
    // permissions,
    tmplType=0, user,

    quote_mainpage_content
 }) {
    console.log('CLIENT',selClientDet,temp_client,perm_Clients, quoteSavedDraft)

    const [editor, setEditor] = useState(false)
    const [clientSelected, setClientSelection] = useState(quoteSavedDraft===null ? '' : quoteSavedDraft.client)
    const [clientName, setClientName] = useState(quoteSavedDraft===null ? '' : quoteSavedDraft.client_name)
    const [clientId, setClientId] = useState(quoteSavedDraft===null ? '' : quoteSavedDraft.client)
    const [clientDet, setClientDet] = useState(quoteSavedDraft===null ? null :
    { 
      client_id : quoteSavedDraft.client,
      client_name: quoteSavedDraft.client_name, 
      site_address: quoteSavedDraft.site_address ? quoteSavedDraft.site_address : '',
      company_contact_number: quoteSavedDraft.company_contact_number ? quoteSavedDraft.company_contact_number : '',
      company_email: quoteSavedDraft.company_email ? quoteSavedDraft.company_email : '' 
    })
    const [Modal, setModal] = useState(false)
    const [QuoteCofirmModal, setQuoteCofirmModal] = useState(false)
    const [fileSelection, setFileSelection] = useState([])
    const [attachFiles, setAttachFiled] = useState([])
    const [reoccuringStatus, setReoccuringStatus] = useState(quoteSavedDraft&& quoteSavedDraft.reoccurring ? quoteSavedDraft.reoccurring : false)
    const [buttonLoaderA, setButtonLoaderA] = useState(false)
    const [buttonLoaderB, setButtonLoaderB] = useState(false)
    const [buttonLoaderC, setButtonLoaderC] = useState(false)

    const [MainCCText, setMainCCText] = useState('')
    const [MailBCCText, setMailBCCText] = useState('')
    const [MailBCC, setMailBCC] = useState([])
    const [MailCC, setMailCC] = useState([])
    const [MailSubject, setMailSubject] = useState('')
    const [MailBody, setMailBody] = useState('')

    const [modalType, setModalType] = useState('')
    const [draftLoader, setDraftLoader] = useState(false)
    const [attachHover, setAttachHover] = useState(null)
    const [selTempId, setSelTempId] = useState(null)

    const [tempEdit, setTempEdit] = useState("")
    const [tempNames, setTempNames] = useState([])
    const [jobConnect, setJobConnect] = useState(quoteSavedDraft && quoteSavedDraft.job_card_info ? quoteSavedDraft.job_card_info :
        quoteSavedDraft && quoteSavedDraft.job_card_id ? quoteSavedDraft.job_card_id : null)
    const [jobModal, setJobModal] = useState(false)
    const [jobObj, setJobObj] = useState(null)
    const [jobModalType, setJobModalType] = useState('')

    // const [reoccurModal, setReoccurModal] = useState(false)
    const [preObj, setPreObj] = useState(null)
    const [fileAttachLoad, setFileAttachLoad] = useState([])
    const [jobCardLoader, setJobCardLoader] = useState(false)
    const [frqWeeks, setFrqWeeks] = useState(quoteSavedDraft&& quoteSavedDraft.frequency ? quoteSavedDraft.frequency : 0);
    const [siteMailId, setSiteMailID] = useState(selClientDet===null ? '' : selClientDet.client_email);
    
    const [sendMailToClient, setSendMailToClient] = useState(quoteSavedDraft && quoteSavedDraft.send_mail_status ? quoteSavedDraft.send_mail_status : false)
    const [typeOfWaste, setTypeOfWaste] = useState(
        quoteSavedDraft && quoteSavedDraft.type_of_waste_str ? quoteSavedDraft.type_of_waste_str : null 
    )
    const [typeOfWasteId, setTypeOfWasteId] = useState(quoteSavedDraft ? 
        quoteSavedDraft.type_of_waste_str_id ? quoteSavedDraft.type_of_waste_str_id :
        quoteSavedDraft.type_of_waste_id : null)

    const [jobCard_list, setJobCardList] = useState([])
    const [clientCardCSS, setClientCardCSS] = useState(0)
    const [contErr, setContErr] = useState(null)
    const [stopJobCardList, setStopJobCardList] = useState(false)
    const [scrollToBtm, setScrollToBtm] = useState(false)

    const [setupSalesPerson, setSetupSalesPerson] = useState(user)
    const [addNVerify, setAddNVerify] = useState(false)
    const [tableDataObj, setTableDataObj] = useState(quoteSavedDraft && quoteSavedDraft.table_data ? JSON.parse(quoteSavedDraft.table_data) : null)
    const [selectedDraft, setSelectedDraft] = useState(null)
    const [newjobcodename, setNewjobcodename] = useState(quoteSavedDraft && quoteSavedDraft.job_card_code ? quoteSavedDraft.job_card_code : '')

    const [prevJobCardObj, setPrevJobCardObj] = useState(null)

    const [jobCardTypeStatus, setJobCardTypeStatus] = useState(false)
    const [jobCardTypeK, setJobCardTypeK] = useState(0)
    const [connectedJobCardData, setConnectedJobCardData] = useState(null)

    // const [quoteAmount, setQuoteAmnt]   =   useState(0)
   
    let route_history = useHistory();
    const { id } = useParams();
    useEffect(() => {

        const setQuoteContent = () => {
            let selected_template = quote_templates.filter(item => item.id === parseInt(id))[0]
            if(selected_template) {
                if(selected_template.html_content) {
                    set_quote_content(selected_template.html_content)
                }
            }
        }
        fetchAllClients(tabType)
        // fetch_attachFolders()
        setQuoteContent()
        fetch_template_drafts('', tabType, '', null, null)
        if(quoteSavedDraft){
            Api('get',
    `${NEWJOBCARD_CLIENT_LIST}${tabType}/${quoteSavedDraft.client}${tabType==='waste' ? 
    `/?type_of_waste=${quoteSavedDraft.type_of_waste_str}&&` : '/?'}limit=20&&page=1`)
            .then(response => {
                console.log(response)
                setJobCardLoader(false)
                if(response.length%20!==0 || response.length===0)
                    setStopJobCardList(true)
                else 
                    setStopJobCardList(false)
                
                setJobCardList(response)
                setScrollToBtm(true)
            })
        }
        
    }, [ fetchAllClients, quote_templates, id, set_quote_content, fetch_template_drafts, tabType, quoteSavedDraft])
    
    let selected_template = quote_templates.filter(item => item.id === parseInt(id))[0]
    const editorRef = useRef()
    let template_val = ''
    if(selected_template) {
        if(selected_template.html_content) {
            template_val = selected_template.html_content
        }
    }
    const [content, setContent] = useState(quoteSavedDraft===null ? template_val : quoteSavedDraft.template)

    const jobCardTypeAction = (k) =>{
        console.log('CURRJOBOBJ',prevJobCardObj,'CURRENTK',k,parseInt(prevJobCardObj?.add_info_button)===k)
        console.log('jobcardinfo_files',prevJobCardObj?.jobcardinfo_files)
        setJobCardTypeK(k)
        setJobCardTypeStatus(false)

        setContErr(null)     
        setJobObj(
            parseInt(prevJobCardObj?.add_info_button)===k || (prevJobCardObj?.add_info_button===null && k===0) ? 
            { ...prevJobCardObj, folder : undefined, pre_autofill : true
            } :
            {
                tab_type    :   prevJobCardObj?.tab_type??'waste',
                client      :   prevJobCardObj?.client && prevJobCardObj?.client!=='null' && prevJobCardObj?.client!=='undefined' ? prevJobCardObj?.client : clientId,
                site_name   :   prevJobCardObj?.site_name??prevJobCardObj?.client_name??'',
                site_address    :   prevJobCardObj?.site_address??'',
                site_post_code  :   prevJobCardObj?.site_post_code??'',
                site_contact_person : prevJobCardObj?.site_contact_person??'',
                site_contact_email  : prevJobCardObj?.site_contact_email??'',
                site_contact_phone  :   prevJobCardObj?.site_contact_phone??'',
                site_phone_no       :   prevJobCardObj?.site_phone_no??'',
                site_contact_mob    :   prevJobCardObj?.site_contact_mob??'',

                additional_phone_number : prevJobCardObj?.additional_phone_number??'',
                additional_email        : prevJobCardObj?.additional_email??'',

                customer_name       :   prevJobCardObj?.customer_name??'',
                customer_address    :   prevJobCardObj?.customer_address??'',
                customer_contact_phone  :   prevJobCardObj?.customer_contact_phone??'',
                customer_contact_mob    :   prevJobCardObj?.customer_contact_mob??'',
                customer_contact_email  :   prevJobCardObj?.customer_contact_email??'',

                industry_type_info       :   prevJobCardObj?.industry_type_info??'',
                type_of_induction        :   prevJobCardObj?.type_of_induction??'',
                sales_person            :   prevJobCardObj?.sales_person??'',
                date    :   prevJobCardObj?.date??'',
                type_of_waste_str       :   prevJobCardObj?.type_of_waste_str??'',
                type_of_waste_str_id    :   prevJobCardObj?.type_of_waste_str_id??'',
                frequency   :   prevJobCardObj?.frequency??'',
                reoccurring :   prevJobCardObj?.reoccurring??'',
                daily_schedule : prevJobCardObj?.daily_schedule??'',
                schedule_days : prevJobCardObj?.schedule_days??'',
                outsourced_job : prevJobCardObj?.outsourced_job??'',
                latitude    :   prevJobCardObj?.latitude??'',
                longitude   :   prevJobCardObj?.longitude??'',
                
            })
        setJobCardLoader(true)
        getClientObj('add')
        setAddNVerify(true)
    }

    const connectJobCard = (jobCardID, frq=null, jobcodename='', jobCardResp=null) =>{
        setJobConnect(jobCardID)
        setNewjobcodename(jobcodename)
        console.log('jobcardresp',jobCardResp)
        if(frq){
            setReoccuringStatus(frq.reoccurring)
            setFrqWeeks(frq.frequency)
        }
        if(jobCardResp)
            setConnectedJobCardData(jobCardResp)

        setTimeout(() => {
            Swal.fire({
                title : 'Updating Client Details. Please wait...',
                icon : 'info',
                position: 'top-end',
                showConfirmButton: false,
            })
            Swal.showLoading()    
            Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
            .then(response => {
                console.log('ClientUpdate response', response)
                setSiteMailID(response.client_email)
                let obj = { 
                    ...response,
                    client_id : response?.client,
                    client_name: response?.site_name??response.client_name, 
                    site_address: response?.site_address ? response.site_address : '',
                    company_contact_number: response?.company_contact_number ? response.company_contact_number : '',
                    client_email : response?.client_email,
                    company_email: response?.company_email ? response.company_email : '' 
                  }
                setClientDet(obj)
                Swal.close()
            })    
        }, 1000);

        

        // Swal.fire({
        //     position: 'top-end',
        //     icon: 'success',
        //     title: 'Job Card Connected!',
        //     showConfirmButton: false,
        //     timer: 1500
        // })
    }
    const quoteRegisterationApi = (status) => {

        let formData = new FormData()

        let recurStatus = 'False'
        reoccuringStatus ? recurStatus = 'True' : recurStatus = 'False'
        
        formData.append('client', clientId);
        formData.append('status', status===null ? 'accepted' : status);
        formData.append('reoccurring', recurStatus);
        formData.append('template_id',id)
        if(selectedDraft!==null)
            formData.append('draft_template_id',selectedDraft)
        // formData.append('auto_create', recurDays);
        formData.append('tab_type', tabType);
        formData.append('frequency', frqWeeks);
        formData.append('url', 'null');
        formData.append('job_type', 'null');
        formData.append('invoice_amt', isNaN(quote_amount) ? 0 : quote_amount);
        formData.append('invoice_amount', isNaN(quote_amount) ? 0 : quote_amount);
        formData.append('company_name', 'null');
        formData.append('amount', isNaN(quote_amount) ? 0 : quote_amount);
        formData.append('job_card_id',jobConnect)
        formData.append('send_mail_status', sendMailToClient)
        formData.append('table_data', JSON.stringify(tableDataObj))
        formData.append('scope_of_work_type','')
        if(tabType==='waste'){
            formData.append('type_of_waste', typeOfWaste)
            formData.append('type_of_waste_id', typeOfWasteId)
        }
        if(MailSubject) {
            formData.append('mail_subject', MailSubject);
            console.log('mail_subject', MailSubject);
        }

        if(MailBody) {
            formData.append('mail_body', MailBody);
            console.log('mail_body', MailBody);
        }

      
        MailCC.map((item) => {
            formData.append('mail_cc', item);
            console.log('mail_cc',item)
            return null;
        })

        MailBCC.map((item) => {
            formData.append('mail_bcc', item);
            console.log('mail_bcc',item)
            return null;
        })

        // console.log('client:', clientId);
        // console.log('status:', 'pending');
        // console.log('reoccurring:', recurStatus);
        // console.log('auto_create:', recurDays);
        // console.log('tab_type:', tabType);

        // console.log('url:', 'null');
        // console.log('job_type:', 'null');
        // console.log('invoice_amt:', 'null');
        // console.log('company_name:', 'null');


        fileSelection.map((item) => {
            formData.append('quote_attachment', item.id);
            return null;
        })

        attachFiles.map((item) => {
            formData.append('attachments_list', item, item.name)
            return null;
        })

        if(quote_mainpage_content) {
            formData.append('template', quote_mainpage_content);
            //formData.append('safety_data',"<h1></h1>")
        }


        const success = (res) => {
            setButtonLoaderA(false)
            setButtonLoaderB(false)
            setButtonLoaderC(false)
            setQuoteCofirmModal(false)
            setJobObj(null)
            if(quoteSavedDraft){
                let postdata = { 'job_card_info' : null }
                Api('patch', `${SALES_QUOTE_DRAFT_GEN_CAUTION}${quoteSavedDraft.id}/`,postdata)
            }
            route_history.push(`/sales/${tabType}/quote-register`)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Quote Generated successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            setButtonLoaderA(false)
            setButtonLoaderB(false)
            setButtonLoaderC(false)
            setQuoteCofirmModal(false)
            
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
        }
        if(status === 'pending') {
            setButtonLoaderA(true)
        } else if(status === null){
            setButtonLoaderC(true)
        }
        else {
            setButtonLoaderB(true)
        }
        
        sales_quoter_registration(formData, tabType, status, selectedDraft, success, failed)
    }

    const cropFileName = (str, len, frnt, lst) => {
        let lastIndx = str ? str.length : 0
    return lastIndx >len ? str.substring(0,frnt)+ '...' + str.substring(lastIndx-lst,lastIndx): str;
    }

    const delDraftCl = (val) =>{

        const success = () =>{
            fetch_drafts('', tabType, clientId, null, null)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Draft deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                delSingleDraft(val,   
                success,                                
                failed)
        }
    });
    }

    const fetchAllFilteredJobCards = (val=null, tow=null, pg=1) =>{
        console.log('fetchAllFilteredJobCards',val, tow)
        fetch_drafts('', tabType, val===null ? clientId : val, null, null)
        Api('get',`${NEWJOBCARD_CLIENT_LIST}${tabType}/${val===null ? clientId : val}${tabType==='waste' ? tow===null ? `/?type_of_waste=${typeOfWaste}&&` : `/?type_of_waste=${tow}&&` : '/?'}limit=20&&page=${pg}`)
            .then(response => {
                console.log(response)
                setJobCardLoader(false)
                if(response.length%20!==0 || response.length===0)
                    setStopJobCardList(true)
                else 
                    setStopJobCardList(false)
                if(pg===1 || pg===null){
                    setJobCardList(response)
                    setScrollToBtm(true)
                }else 
                    setJobCardList([...jobCard_list, ...response])
        })   
        if(typeOfWasteId)
        Api('get',`${PREV_JOBCARD_DATA}${val===null ? clientId : val}/?child_site_id=${typeOfWasteId}`)
            .then(response => {
                console.log('PREV-JOBCARD',response)
                console.log('ClientDetResp',clientDet)
                if(response)
                    setPrevJobCardObj({
                        tab_type    :   clientDet?.tab_type??'waste',
                        client      :   clientDet?.client_id??clientDet?.id??'',
                        site_name   :   clientDet?.site_name??clientDet?.client_name??'',
                        site_address    :   clientDet?.site_address??'',
                        site_post_code  :   clientDet?.site_post_code??'',
                        site_contact_person : clientDet?.site_contact_person??'',
                        site_contact_email  : clientDet?.site_contact_email??clientDet?.client_email??'',
                        site_contact_phone  :   clientDet?.site_contact_phone??'',
                        site_phone_no       :   clientDet?.site_phone_no??'',
                        site_contact_mob    :   clientDet?.site_contact_mob??'',
            
                        customer_name       :   clientDet?.company_name??'',
                        customer_address    :   clientDet?.company_address??'',
                        customer_contact_phone  :   clientDet?.company_contact_number??'',
                        customer_contact_mob    :   clientDet?.company_mobile_number??'',
                        customer_contact_email  :   clientDet?.company_email??'',
        
                        industry_type_info       :   clientDet?.industry_type??'',
                        sales_person            :   clientDet?.sales_person??'',
                                            
                        latitude    :   clientDet?.latitude??'',
                        longitude   :   clientDet?.longitude??'',
                        ...response
                    })
                else{
                    setPrevJobCardObj({
                        tab_type    :   clientDet?.tab_type??'waste',
                        client      :   clientDet?.client_id??clientDet?.id??'',
                        site_name   :   clientDet?.site_name??clientDet?.client_name??'',
                        site_address    :   clientDet?.site_address??'',
                        site_post_code  :   clientDet?.site_post_code??'',
                        site_contact_person : clientDet?.site_contact_person??'',
                        site_contact_email  : clientDet?.site_contact_email??clientDet?.client_email??'',
                        site_contact_phone  :   clientDet?.site_contact_phone??'',
                        site_phone_no       :   clientDet?.site_phone_no??'',
                        site_contact_mob    :   clientDet?.site_contact_mob??'',
            
                        customer_name       :   clientDet?.company_name??'',
                        customer_address    :   clientDet?.company_address??'',
                        customer_contact_phone  :   clientDet?.company_contact_number??'',
                        customer_contact_mob    :   clientDet?.company_mobile_number??'',
                        customer_contact_email  :   clientDet?.company_email??'',
        
                        industry_type_info       :   clientDet?.industry_type??'',
                        sales_person            :   clientDet?.sales_person??'',
                                            
                        latitude    :   clientDet?.latitude??'',
                        longitude   :   clientDet?.longitude??'',
                        
                    })
                }
            })
            .catch(()=>{
                console.log('CATCHErr',clientDet)
                setPrevJobCardObj({
                    tab_type    :   clientDet.tab_type,
                    client      :   clientDet.client_id??clientDet.id,
                    site_name   :   clientDet.site_name??clientDet.client_name,
                    site_address    :   clientDet.site_address,
                    site_post_code  :   clientDet.site_post_code,
                    site_contact_person : clientDet.site_contact_person,
                    site_contact_email  : clientDet.site_contact_email,
                    site_contact_phone  :   clientDet.site_contact_phone,
                    site_phone_no       :   clientDet.site_phone_no,
                    site_contact_mob    :   clientDet.site_contact_mob,
        
                    customer_name       :   clientDet.company_name,
                    customer_address    :   clientDet.company_address,
                    customer_contact_phone  :   clientDet.company_contact_number,
                    customer_contact_mob    :   clientDet.company_mobile_number,
                    customer_contact_email  :   clientDet.company_email,
    
                    industry_type_info       :   clientDet.industry_type,
                    sales_person            :   clientDet.sales_person,
                                        
                    latitude    :   clientDet.latitude,
                    longitude   :   clientDet.longitude,
                    
                })
            })
        else {
            setPrevJobCardObj({
                tab_type    :   clientDet?.tab_type??'waste',
                client      :   clientDet?.client_id??clientDet?.id??'',
                site_name   :   clientDet?.site_name??clientDet?.client_name??'',
                site_address    :   clientDet?.site_address??'',
                site_post_code  :   clientDet?.site_post_code??'',
                site_contact_person : clientDet?.site_contact_person??'',
                site_contact_email  : clientDet?.site_contact_email??clientDet?.client_email??'',
                site_contact_phone  :   clientDet?.site_contact_phone??'',
                site_phone_no       :   clientDet?.site_phone_no??'',
                site_contact_mob    :   clientDet?.site_contact_mob??'',
    
                customer_name       :   clientDet?.company_name??'',
                customer_address    :   clientDet?.company_address??'',
                customer_contact_phone  :   clientDet?.company_contact_number??'',
                customer_contact_mob    :   clientDet?.company_mobile_number??'',
                customer_contact_email  :   clientDet?.company_email??'',

                industry_type_info       :   clientDet?.industry_type??'',
                sales_person            :   clientDet?.sales_person??'',
                                    
                latitude    :   clientDet?.latitude??'',
                longitude   :   clientDet?.longitude??'',
                
            })
        }
    }
    
    const SingleFolder = ({item, title, index, type, editable=true}) => {

        
        return (
            <Tooltip
            title={ attachHover===`${index}${type}` && type!=='attachment' ?
                <p>
                {type==='jobCard' ? 
                    jobConnect===item.id ? 
                        <span style={{ backgroundColor:'var(--cl_blue)', borderRadius: '5px',  padding:'1%', fontSize:'.8rem' }} >
                            Connected to this Quote.</span> :
                        !item.connected ? 
                            <span style={{ backgroundColor:'var(--cl_green)', borderRadius: '5px',  padding:'1%', fontSize:'.8rem' }} >
                                Available for use.</span> : 
                        <span style={{ backgroundColor:'var(--cl_red)',  borderRadius: '5px', padding:'1%', fontSize:'.8rem' }} >
                            Not Available.</span> : null
                }
                {type==='jobCard' ? <br/> : null}
                Created: {moment(item.created_date_time).format('DD-MM-YYYY HH:mm:ss')},<br/> 
                Modified: {moment(item.edited_date_time).format('DD-MM-YYYY HH:mm:ss')}<br />
                {title && title.length>25 ? title : null}</p> : ``} 
            arrow={true} placement={'top'}
            >
            <div 
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setContErr(null)
                    setAttachHover(null)                
                    setModalType(type)
                    setJobCardTypeK(item.add_info_button)

                    if(type==='attachment' && editable){    
                        templateLoading()
                        fetch_single_draft(null, item.id, null, null, tabType)
                        setSelTempId(item.id)
                        setModal(true)
                    }else if(type==='attachment' && !editable){
                        if(!fileSelection.some(item => item.tmpName===title)){    
                            setFileSelection(data=>[...data, {id: item.id, tmpName: title}])
                            setTempNames(data=>[...data, item.id])

                        }else{
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Template already added',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            
                        }
                        
                    }
                    else if(type==='drafts'){
                        fetch_single_draft(null, item.id, null, null, tabType)
                    setAttachHover(null)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Client draft copied!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        // set_quote_content(sale_client_draft.template)
                        setEditor(true)
                    }
                    else{
                        setJobCardLoader(true)
                        setJobObj(item)
                        setPreObj(null)
                        getClientObj(item.connected ? 'view' : 'verify')
                        
                    }
                }} 
                onMouseEnter={() => { if(type!=='attachment') setAttachHover(`${index}${type}`)}}
                onMouseLeave={() => { if(type!=='attachment') setAttachHover(null)}}
                style={{ marginLeft: 0, height: 35 }} 
                className={ type==='jobCard' && item.connected ? "intranet-single-file-layout inavlb-jobCard" : "intranet-single-file-layout"} >
                <div className="intranet-single-index">
                    <p>{index + 1}</p>
                </div>
                <div className="intraner-single-label" style={{ whiteSpace:'nowrap', width: type==='attachment' ? '100%' : '8vw', textOverflow:'ellipsis', overflow:'hidden' }}>
                    <p>{ type==='jobCard' && jobConnect===item.id && attachHover===`${index}${type}` && item.reoccurring ?
                    `Frequency : ${item.frequency ? item.frequency : '0 Weeks'}`
                    :
                    cropFileName(title, 25, 5, 5)}</p>
                </div>
                {   type==='drafts' ?
                <DeleteForever
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                            delDraftCl(item.id)                     
                    }}
                    style={{ fontSize: 18, marginRight: 10, color: 'red', cursor: 'pointer' }} 
                />  :   null
                }
                {   type==='jobCard' && jobConnect===item.id ?
                <CheckCircle style={{ color:'var(--cl_blue)' }} />
                :   type==='jobCard' ?  !item.connected ?
                <CheckCircle style={{ color:  'var(--cl_green)' }} />
                :
                <CancelRoundedIcon style={{ color: 'var(--cl_red)' }} /> 
                // <div  className={ !item.connected ? "cardActiveQuoteBtn cardQtGenSml" : "cardNotActiveQuoteBtn cardQtGenSml"}></div> 
                : null
                }    
                    
            </div>
            </Tooltip>
        )
    }

    // const Editor = () => {
    //     return (
    //         <div className="sales-qt-client-info">
    //             <Scrollview style={{ height: '70vh'}}>
    //             <JoditEditor
    //                 ref={editorRef}
    //                 value={content}
    //                 config={config}
    //                 tabIndex={1}
    //             />
    //             </Scrollview>
    //         </div>
            
    //     )
    // }

    const editDraft = ()=>{
        let postData = new FormData()
        postData.append('client', clientId)
        postData.append('client_name', clientDet ? clientDet.client_name : '')
        postData.append('site_address',clientDet ? clientDet.site_address : '')
        postData.append('company_contact_number', clientDet ? clientDet.company_contact_number : '')
        postData.append('company_email', clientDet ? clientDet.company_email : '')
        postData.append('template',editorRef.current.value)
        postData.append('tab_type',tabType)
        postData.append('table_data', tableDataObj)
        postData.append('job_card_info', jobConnect)
        postData.append('template_id',id)

        let recurStatus = 'False'
        reoccuringStatus ? recurStatus = 'True' : recurStatus = 'False'
        
        postData.append('reoccurring', recurStatus);
        postData.append('tab_type', tabType);
        postData.append('frequency', frqWeeks);
        postData.append('invoice_amt', isNaN(quote_amount) ? 0 : quote_amount);
        postData.append('invoice_amount', isNaN(quote_amount) ? 0 : quote_amount);
        postData.append('amount', isNaN(quote_amount) ? 0 : quote_amount);
        postData.append('send_mail_status', sendMailToClient)
        postData.append('table_data', JSON.stringify(tableDataObj))
        postData.append('scope_of_work_type',typeof tableDataObj!=='undefined' ? '' : null)
        if(tabType==='waste'){
            postData.append('type_of_waste', typeOfWaste)
            postData.append('type_of_waste_id', typeOfWasteId)
        }
        
        fileSelection.map((item) => {
            postData.append('quote_attachment', item.id);
            return null;
        })

        attachFiles.map((item) => {
            postData.append('attachments_list', item, item.name)
            return null;
        })

        if(content) {
            postData.append('template', quote_content);
            //formData.append('safety_data',"<h1></h1>")
        }

        setDraftLoader(true)
        const success = (res) => {
            setDraftLoader(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Template editted as draft successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            setDraftLoader(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
        }
        edit_saved_draft(postData, quoteSavedDraft.id, success, failed)
    }


    const saveAsDraft = () =>   {
        
        let postData = new FormData()
        postData.append('client', clientId)
        postData.append('client_name', clientDet ? clientDet.client_name : '')
        postData.append('site_address',clientDet ? clientDet.site_address : '')
        postData.append('company_contact_number', clientDet ? clientDet.company_contact_number : '')
        postData.append('company_email', clientDet ? clientDet.company_email : '')
        postData.append('template',editorRef.current.value)
        postData.append('table_data', tableDataObj)
        postData.append('job_card_info', jobConnect)
        postData.append('template_id',id)


        let recurStatus = 'False'
        reoccuringStatus ? recurStatus = 'True' : recurStatus = 'False'
        
        postData.append('reoccurring', recurStatus);
        postData.append('tab_type', tabType);
        postData.append('frequency', frqWeeks);
        postData.append('invoice_amt', isNaN(quote_amount) ? 0 : quote_amount);
        postData.append('invoice_amount', isNaN(quote_amount) ? 0 : quote_amount);
        postData.append('amount', isNaN(quote_amount) ? 0 : quote_amount);
        postData.append('send_mail_status', sendMailToClient)
        postData.append('table_data', JSON.stringify(tableDataObj))
        postData.append('scope_of_work_type',typeof tableDataObj!=='undefined' ? '' : null)
        if(tabType==='waste'){
            postData.append('type_of_waste', typeOfWaste)
            postData.append('type_of_waste_id', typeOfWasteId)
        }
        
        fileSelection.map((item) => {
            postData.append('quote_attachment', item.id);
            return null;
        })

        attachFiles.map((item) => {
            postData.append('attachments_list', item, item.name)
            return null;
        })

        if(content) {
            postData.append('template', quote_content);
            //formData.append('safety_data',"<h1></h1>")
        }


        setDraftLoader(true)
        const success = (res) => {
            setDraftLoader(false)

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Template saved as draft successful!',
                showConfirmButton: false,
                timer: 1500
            })

            route_history.push(`/sales/${tabType}/generate-quote/`)
        }

        const failed = (res) => {
            setDraftLoader(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
        }
        fetch_drafts(postData, tabType, clientId, success, failed)
    }

    const TemplateAdd = () =>{
        let nowDate = new Date();
        setFileAttachLoad(data => [...data, nowDate])
        let postData = new FormData()
        postData.append('quote_attach_template', sale_client_draft)
        postData.append('template_id',selTempId)
        // console.log("POSTDATA",sale_client_draft)
        const success = (res) =>{
            setTempEdit("")
            setFileSelection(data=>[...data, {id: res.template_id, tmpName: res.file_name}])
            let arr = fileAttachLoad.filter(item => item!==nowDate)
            setFileAttachLoad(arr)
        }
        const failed = (res) => {
            setFileAttachLoad(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
        }
        // console.log("APICALL",postData)
        fetch_template_drafts(postData, tabType, tempEdit, success, failed)
        
    }

    const getClientObj = (txt) =>{
        if(perm_Clients.find(item => item.id===clientId)){
            Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
            .then(response => {
                console.log('response=>',response)
                let x = {
                ...response,
                client_type     :   'Permenant',
                }
                setJobCardLoader(false)
                setPreObj(x)
                setJobModalType(txt)
                setJobModal(true);
        })
        }
        else {

            Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
            .then(response => {
                console.log('response=>',response)
                let x = {
                ...response,
                client_type     :   'Temporary',
                }
                setJobCardLoader(false)
                setPreObj(x)
                setJobModalType(txt)
                setJobModal(true);
        })
        }
    }
    
    const ScrollUp = (val) => {
        const { left } = val
        if(left===1 && !stopJobCardList){
            fetchAllFilteredJobCards(clientId, typeOfWaste, parseInt(jobCard_list.length/20) + 1)
        }
    }

    // const isNewTemp = () => {
    //     if(jobObj!==null)
    //         return false 
    //     else 
    //         if(preObj && preObj.site_address && preObj.site_post_code && preObj.location_latitude && preObj.location_logitude)
    //             return false 
    //         else 
    //             return true

    // }

    const readyToGen = () =>{
        Swal.fire({
            title : 'Updating Client Details. Please wait...',
            icon : 'info',
            showConfirmButton: false,
        })
        Swal.showLoading()

        Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
            .then(response => {
                console.log('ClientUpdate response', response)
                setSiteMailID(response.client_email)
                setContent(editorRef.current.value); setQuoteCofirmModal(true);
                Swal.close()
        })
    }
    
    return (
        <div className="sales-qt-gnrt-detail" id="sales-qt-gnrt-detail-id"
        onScroll={()=> setScrollToBtm(false) }
        >
            <div className="sales-qt-gnrt-dtl-left" id="sales-qt-gnrt-dtl-left-id">

                <div className="sales-qt-rgstr-info-btn-layout">
                       
                    <div style={{ position : 'relative', display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'space-between'}}>
                        {   clientSelected && newjobcodename!=='' && newjobcodename ?
                        <div className="sales-qt-rgstr-info-btn-client" style={{ marginBottom:'1%' }}>
                            <div className='sales-qt-rgstr-info-btn-clientBox'>
                                {clientCardCSS ? <span className='sales-qt-rgstr-info-btn-clientBoxRipple'></span> : null}
                                <p className="sales-qr-singl-client-mn-title">
                                    {newjobcodename}
                                </p>
                            </div>
                        </div>
                        : null
                        }
                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                            <button 
                                id="siteInfoButton"
                                className={editor === false ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn"}
                                onClick={() => {
                                    setContErr(null);
                                    setContent(editorRef.current.value)
                                    setEditor(false)
                                }}
                            >Site info</button>

                            <button 
                                id="quoteEditorButton"
                                className={editor ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn"}
                                
                                onClick={() =>{ 
                                    if(jobConnect===null && tabType==='waste'){
                                        setContErr('job')
                                    }else{
                                        if(tableDataObj===null)
                                            setTableDataObj([{
                                                waste_description   : '',
                                                waste_code          : '',
                                                qty                 : '',
                                                uom                 : '',
                                                unit_cost_total     : '',
                                                additional_info     : '',
                                                tbhead              : true
                                            }])
                                    setContErr(null); setEditor(true);
                                }}}
                            >Quote</button>
                            {
                        !draftLoader   ?
                    content!==quote_content && clientId && jobConnect ? quoteSavedDraft===null ?
                        <button 
                            id="saveDraftButton"
                            className={content!==quote_content && clientId ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn disabled-btn"}
                            style={{ width:'10vw' }}
                            onClick={() =>{ if(content!==quote_content && clientId) { setContErr(null); saveAsDraft(); }}}
                        >Save Draft</button>
                        :
                        <button 
                            className={content!==quote_content && clientId && jobConnect  ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn disabled-btn" }
                            style={{ minWidth:'10vw' }}
                            onClick={() =>{ if(content!==quote_content && clientId) { setContErr(null); editDraft(); }}}
                        >Save changes to Draft</button>
                        :
                        <button 
                            className="sales-qt-rgstr-editor-sts-btn disabled-btn" 
                            style={{ minWidth:'10vw' }}
                        >Save changes to Draft</button>
                        :
                        <button 
                            className="sales-qt-rgstr-editor-sts-btn"
                            style={{ width:'10vw' }}
                        ><CircularProgress size={15}/></button>
                    }  
                        </div>
                        {   contErr==='quote' ?
                            <div className='helperTextHovering' style={{ top:50 }} onClick={()=> setContErr(null) }>
                                You haven't editted or interracted with the main quote. Click on 'Quote' button to preview your main quote template.
                            </div>
                        : null    }
                        {   tabType==='waste' && jobConnect===null && contErr==='job' ?
                            <div className='helperTextHovering' style={{ top:50 }} onClick={()=> setContErr(null) }>
                                You haven't yet chosen a Job Card and Sales Person.
                            </div>
                        : null    }
                      
                    </div>
                    {   clientSelected ?
                    <div className="sales-qt-rgstr-info-btn-client">
                        <div className='sales-qt-rgstr-info-btn-clientBox'>
                            {clientCardCSS ? <span className='sales-qt-rgstr-info-btn-clientBoxRipple'></span> : null}
                            <p className="sales-qr-singl-client-mn-title">{cropFileName( clientName, 25, 10, 10)}</p>
                            <p>{clientSelected}{typeOfWaste!=='' ? ` (${typeOfWaste})` : null}</p>
                            
                        </div>
                    </div>
                    : null
                    }
                </div>

                {
                    editor ?
                        tabType==='pumps' ?
                        <EditorPump  content={content} editorRef={editorRef} clientId={clientDet} tmplType={tmplType}
                        setTableDataObj={setTableDataObj}
                        />
                        :
                        <MainEditorTable content={content} editorRef={editorRef} 
                        clientId={clientDet} 
                        typeOfWaste={typeOfWaste}                        
                        setupSalesPerson={setupSalesPerson} setTableDataObj={setTableDataObj} tableDataObj={tableDataObj}
                        newjobcodename={newjobcodename} connectedJobCardData={connectedJobCardData}
                        />
                        // <Editor  content={content} editorRef={editorRef} clientId={clientDet} typeOfWaste={typeOfWaste}                        
                        // setupSalesPerson={setupSalesPerson} setTableDataObj={setTableDataObj}
                        // />
                     : (
                        <ClientInfo 
                            setClientName={setClientName}
                            setClientSelection={setClientSelection}
                            setClientDet={setClientDet}
                            setClientId={setClientId}
                            fetch_drafts={fetch_drafts}
                            clientSelected={clientSelected}
                            create_tempClient={create_tempClient}
                            setSearchQuery={setSearchQuery}
                            fetch_allClients={fetchAllClients}
                            temp_client={temp_client}
                            perm_Clients={perm_Clients}
                            tabType={tabType}
                            setSiteMailID={setSiteMailID}
                            typeOfWaste={typeOfWaste}
                            setTypeOfWaste={setTypeOfWaste}
                            typeOfWasteId={typeOfWasteId}
                            setTypeOfWasteId={setTypeOfWasteId}
                            jobCardListFn={fetchAllFilteredJobCards}
                            setClientCardCSS={setClientCardCSS}
                        />
                    )
                }

            </div>
            <div className="sales-qt-gnrt-dtl-right">
                <div className="sales-horizScroll-List">
                    <p>Attach File</p>
                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
                        <Scrollview style={{ height:'8vh',width:"100%", border:'1px solid lightgrey', borderRadius:'10px'}}>
                            <div className="sales-client-drafts-list"  id='attach-file-list'>
                            {
                                sale_eight_template && sale_eight_template.map((item, key) => {
                                    return (
                                        <SingleFolder item={item} title={item.name} key={key} index={key} editable={item.editable}  type={'attachment'}/>
                                    )
                                })
                            }
                            {
                                sale_eight_template && sale_eight_template.length===0 ?
                                    'No Templates uploaded' : null
                            }
                            </div>
                        </Scrollview>
                    </div>
                </div>
                {   clientId && sale_client_drafts_list  ?
                        sale_client_drafts_list.length>0 && sale_client_drafts_list.filter(itemJB => parseInt(itemJB.client)===clientId).length!==0 ?
                <div className="sales-horizScroll-List" >
                    <p>Client Drafts</p>

                <Scrollview style={{ height:'8vh',width:"40vw", border:'1px solid lightgrey', borderRadius:'10px'}}>
                    <div className="sales-client-drafts-list" id='client-drafts-list'>
                    {
                        sale_client_drafts_list.filter(itemJB => parseInt(itemJB.client)===clientId).map((item, key) => {
                            return(
                                <SingleFolder item={item} title={item.sales_person?item.sales_person:item.employee_name} key={key} index={key} type={'drafts'}/>
                            )
                        })
                    }
                    </div>
                </Scrollview>
                </div>   :   null  :   null
                }
                {
                    clientId ?
                    <div className="sales-horizScroll-List" id="jobCardDivSection" >
                    <p><span style={{ paddingRight:'10px' }}>Job Cards</span>
                    {   contErr==='job' ?
                    <div className='helperTextHovering' onClick={()=> setContErr(null) }>
                        You haven't Connected a Job Card to this Quote. Select an Avialable Job Card then verify it to connect. Upon connecting the &nbsp;&nbsp;<CheckCircle style={{ color:'var(--cl_green)' }} />&nbsp;&nbsp;will change to&nbsp;&nbsp; <CheckCircle style={{ color:  'var(--cl_blue)' }} />.                
                    </div>
                    : null
                    }
                    {
                        jobCardLoader ? <span><CircularProgress size={12} color={cl_blue} /> Loading...</span> : null
                    }
                    </p>
                    
                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between' }}>
                <Scrollview style={{ height:'8vh',minWidth:"25vw", border:'1px solid lightgrey', borderRadius:'10px'}}
                    onUpdate={ScrollUp}
                >
                    <div className="sales-client-drafts-list"  id='job-cards-list'>
                        
                    {
                        jobCard_list.filter(itemJB => parseInt(itemJB.client)===clientId).map((item, key) => {
                            return(
                                <SingleFolder item={item} title={cropFileName( item.job_card_name ? item.job_card_name : clientName, 25, 10, 10)}
                                //title={item.company_name || item.client_name} 
                                key={key} index={key} type={'jobCard'}/>
                            )
                            
                        })
                    }
                    {
                        jobCard_list.filter(item => parseInt(item.client)===clientId).length===0 ?
                            'No Job cards created' : null
                    }
                    </div>
                </Scrollview>
                        <div className="qt-gnrt-jobCardAddBtn" onClick={()=>{    
                                
                                setJobCardTypeStatus(true)
                            }}>
                            <p style={{ display:'flex', alignItems:'center' }}>
                                <AddCircleOutlined /><span>Create Job Card</span>
                            </p>
                        </div>
                        </div>
                </div>
                : null
                }
                <div className="sales-selection-box">
                    <div className="sales-selection-box-header">
                        <p>Files Selected  to Attach</p>
                        <div>
                            <label htmlFor="file-input">
                                <div style={{ cursor: 'pointer', padding: '5px 20px', backgroundColor: '#fff', borderRadius: 20 }} className="choose-file-btn" >Attach File</div>
                            </label>
                            <input id="file-input" 
                                style={{ display: 'none' }} 
                                type="file"
                                multiple
                                onChange={(image) => {
                                    let nowDate = new Date();
                                    setFileAttachLoad(data => [...data, nowDate])
                                    var images = image.target.files
                                    setAttachFiled(dataVal => [...dataVal, ...images])
                                    let arr = fileAttachLoad.filter(item => item!==nowDate)
                                    setFileAttachLoad(arr)
                                    
                                }}
                            />
                        </div>
                    </div>
                    <Scrollview style={{ height: '20vh' }}>

                        {   attachFiles && 
                            attachFiles.map((item, key) => {
                                const { name } = item
                                return (
                                    <div key={key} className="sales-attach-single-file">
                                        <HighlightOffIcon
                                            onClick={() => {
                                                let temp_items = attachFiles.filter(itemzVall => itemzVall !== item)
                                                setAttachFiled(temp_items)
                                            }}
                                            style={{ fontSize: 18, marginRight: 10, color: 'blue', cursor: 'pointer' }} 
                                        />
                                        <p>{name}</p>
                                        
                                    </div>
                                )
                            })
                        }

                        {
                            fileSelection.map((item, key) => {
                                
                                return (
                                    <div key={key} className="sales-attach-single-file">
                                        <HighlightOffIcon 
                                            onClick={() => {
                                                let temp_items = fileSelection.filter(itemzVall => itemzVall.id !== item.id)
                                                setFileSelection(temp_items)
                                            }}
                                            style={{ fontSize: 18, marginRight: 5, color: 'red', cursor: 'pointer' }} 
                                        />
                                        {   !tempNames.includes(item.id) ?
                                        <EditRounded
                                        style={{ fontSize: 18, marginLeft: 5, marginRight: 5, color: 'blue', cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setTempEdit(item.id)
                                            fetch_single_draft(null, item.id, null, null, tabType)
                                            
                                            setModalType("attachment")
                                                setSelTempId(item.id)
                                                setModal(true)
                                            
                                        }} 
                                        />  :   null
                                        }
                                        <p>{item.tmpName}</p>
                                        
                                    </div>
                                )
                            })
                        }

                        {
                            fileAttachLoad ?
                            fileAttachLoad.map((_,k) => {
                                return (
                                    <div key={k} className="sales-attach-single-file">
                                        <CircularProgress size={10} />
                                        <p style={{ padding:'10px' }}>File Loading...</p>
                                    </div>
                                )
                            })
                            : null
                        }

                        {
                            attachFiles.length === 0 && fileSelection.length === 0 && (
                                <p style={{ padding: 10 }}>No files selected!</p>
                            )
                        }

                        
                    </Scrollview>
                </div>

                {/* <div className="sales-qt-amnt-field">
                    <input style={{ width: 200, fontWeight: 'bold' }} 
                    type="number" placeholder="Amount in AUD" 
                    value={quoteAmount} className="salesAmntField" onChange={(val)=>{ setQuoteAmnt(val.target.value) }} />
                    <p style={{ width: 200, fontWeight: 'bold' }}>AUD</p>
                </div> */}

                <div className="sales-qt-rgstr-btn-layout">
                    {/* <button style={{ width: 150 }} onClick={() => quoteRegisterationApi('pending')}> */}
                    {/* <button style={{ width: 150 }} onClick={() => setQuoteCofirmModal(true)}>
                        {
                            buttonLoaderA ? (
                                <CircularProgress size={14} color={'#fff'} />
                            ) : ('Generate & Send')
                        }
                    </button>
                    <button style={{ width: 150 }} onClick={() => quoteRegisterationApi('accepted')}>
                        {
                            buttonLoaderB ? (
                                <CircularProgress size={14} color={'#fff'} />
                            ) : ('Approved Quote')
                        }
                    </button> */}

                    {
                        (editorRef.current) && jobConnect!=='' && jobConnect!==null ? (
                            <button id="continueGNRTQT" 
                                style={{ width: 150, marginLeft: '12px' }} 
                                onClick={() =>{ 
                                    readyToGen()
                                }}>
                                    Continue
                            </button>
                        ) : (
                            <button className="disabled-btn" style={{ width: 150, pointerEvents:'auto', marginLeft: '12px' }}  
                            onClick={()=>{
                                console.log('CONTERR',contErr,editorRef.current,!editorRef.current || typeof editorRef.current==='undefined',!(jobConnect!=='' && jobConnect!==null))
                                if(!(jobConnect!=='' && jobConnect!==null))
                                    setContErr('job')
                                else if(!editorRef.current || typeof editorRef.current==='undefined')
                                    setContErr('quote')
                            }}
                            >Continue</button>
                        )
                    }
                    
                </div>

            </div>
            <FolderModal 
                Tempname={'Templates'}
                modalStatus={Modal}
                modalAction={setModal}
                editorRef={editorRef}
                modalType={modalType}
                editorAction={TemplateAdd}
            />
            <QuoteConfirmModal 
                modalStatus={QuoteCofirmModal}
                modalAction={setQuoteCofirmModal}
                MailBCC={MailBCC}
                setMailBCC={setMailBCC}
                MailCC={MailCC}
                setMailCC={setMailCC}
                MailSubject={MailSubject}
                setMailSubject={setMailSubject}
                MailBody={MailBody}
                setMailBody={setMailBody}
                MainCCText={MainCCText}
                setMainCCText={setMainCCText}
                MailBCCText={MailBCCText}
                setMailBCCText={setMailBCCText}
                quoteRegisterationApi={quoteRegisterationApi}
                buttonLoaderA={buttonLoaderA}
                buttonLoaderB={buttonLoaderB}
                buttonLoaderC={buttonLoaderC}
                siteMailId={clientDet ? siteMailId : null}
                sendMailToClient={sendMailToClient}
                setSendMailToClient={setSendMailToClient}
                sale_draft_template_list={sale_client_drafts_list}
                clientId={clientId}
                setSelectedDraft={setSelectedDraft}
                selectedDraft={selectedDraft}
            />
            {/* <ReoccurModalBox
                modalStatus={reoccurModal}
                modalAction={setReoccurModal}
                frqWeeks = {frqWeeks}
                setFrqWeeks = {setFrqWeeks}
                
                perm = {permissions.site.edit}
                clientId = {clientId}
                setReoccuringStatus={setReoccuringStatus}
                reoccuringStatus={reoccuringStatus}
            /> */}
            

            {   jobModal    ?
        <div className={!jobModal ? "jobCardModalBgDiv" : "jobCardModalBgDiv jobCardModalBgDivClose"}>
            {   
            <NewJobCard 
                setJobCardModal={setJobModal} addNVerify={addNVerify} setAddNVerify={setAddNVerify}
                setupSalesPerson={setSetupSalesPerson}
                jobObj={ jobObj===null ? 
                    {
                        client                  :   preObj.id,
                        type_of_waste_str       :   typeOfWaste,
                        type_of_waste_str_id    :   typeOfWasteId,
                        job_card_name           :   preObj.company_name ? preObj.company_name : preObj.client_name,
                        
                        site_name               :   preObj.site_name ? preObj.site_name : preObj.client_name,
                        ...preObj,
                        site_contact_phone      :   preObj.site_contact_number,
                        site_contact_email      :   preObj.client_email,
                        account_status          :   preObj.invoice_account_status,
                        type_of_induction       :   preObj.induction_type,
                        sales_person            :   preObj.sales_person ? preObj.sales_person : user.name,
                        date                    :   new Date(),
                        capacity                :   preObj.devicy_capacity,
                        barcode                 :   preObj.barcode,
                        estimated_job_duration  :   preObj.job_duration,
                        key_required            :   preObj.key_required_type_str,
                        key_required_new            :   preObj.key_required_type_str,
                        additional_information  :   preObj.information,
                        
                        tc_required_comment     :   '',
                        manifest_comment        :   '',
                        waste_data_form         :   false,
                        access_height           :   '',
                        pit_distance_from_truck_location    :   '',
                        water_tap_location      :   '',
                        gurney_required         :   false,
                        confined_space          :   false,
                        number_of_trucks_required   :   '',
                        time_for_service            :   '',
                        specific_ppe_reqired    :   false,
                        specific_ppe_reqired_comment : '',
                        
                        purchase_order_comment  :   '',
                        no_and_type_pallet_to_be_exchanged  :   '',
                        safety_data_sheet_required          :   false,
                        chemist_approval        :   false,
                        smoke_alarms            :   '',
                        additional_images       :   '',

                        jobcardinfo_files   :   {
                            tc_required_multiple_file : null,
                            data_form_required_multiple_file     : null,
                            weigh_bridge_required_multiple_file  : null,
                            safety_data_sheet_files      : null,
                            chemist_approval_multiple_file       : null,
                            manifest_multiple_file               : null,
                            purchase_order          :   null,
                        },
                        
                        files                       : null,
                        latitude                :   preObj.location_latitude ? parseFloat(preObj.location_latitude) : null,
                        longitude               :   preObj.location_logitude ? parseFloat(preObj.location_logitude) : null,

                        outsourced_job          :   false,
                        daily_schedule          :   false,
                        schedule_days           :   [],
                        industry_type           :   null,
                        add_info_button         :   jobCardTypeK
                    }
                    :
                    {...jobObj, 
                        add_info_button         :   jobCardTypeK,
                        type_of_waste_str       :   typeOfWaste,
                        type_of_waste_str_id    :   typeOfWasteId } } 
                modalType={jobModalType} tabType={tabType}  typeOfWaste={typeOfWaste} typeOfWaste_Id={typeOfWasteId} fallBackFn={fetchAllFilteredJobCards}
                clientList={null} preSelect={jobModalType==='verify' ? null : preObj} setJobCardLoader={setJobCardLoader}
                prevJobFill={jobModalType==='add'}
                verifyJobCard={connectJobCard} />
                }
        </div>  :   null
        }
        {
            document.getElementById("sales-qt-gnrt-dtl-left-id") && document.getElementById("sales-qt-gnrt-detail-id") ?
                (document.getElementById("sales-qt-gnrt-dtl-left-id").offsetWidth > document.getElementById("sales-qt-gnrt-detail-id").offsetWidth/2) &&
                scrollToBtm ?
                !((document.getElementById('jobCardDivSection').getBoundingClientRect().top >= 0) && (document.getElementById('jobCardDivSection').getBoundingClientRect().bottom <= window.innerHeight))
                ?
                    <div className='scrollDownRounded'
                        onClick={()=>{ 
                            const element = document.getElementById('jobCardDivSection');
                            element.scrollIntoView();                            
                            setScrollToBtm(false); }}
                    >Scroll Down <ExpandMoreRounded /></div>
                    :   null : null
                :
            null
        }
            <JobCardTypeModal modalStatus={jobCardTypeStatus} modalAction={jobCardTypeAction} />
        </div>
    )
}


const mapStateToProps = (state) => {
    const { quote_templates, temp_client, quoteSavedDraft, val, quote_content, perm_Clients,
        //attach_folders, 
        sale_client_drafts_list, sale_eight_template, sale_client_draft, quote_amount } = state.sales
    // const { jobCard_list } = state.jobView
    const { permissions, user } = state.userLogin
    const { quote_mainpage_content } = state.mainQuote

    return { quote_templates, temp_client, perm_Clients, sale_client_drafts_list, sale_eight_template, quote_content, 
        sale_client_draft, quoteSavedDraft, val, quote_amount, quote_mainpage_content,
        //jobCard_list,
        user,
         permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllClients         : (tabType) => dispatch(fetch_all_clientsList(tabType)),
        // fetch_attachFolders: () => dispatch(fetch_attach_folders()),
        create_tempClient       : (data, tabType, success, failed) => dispatch(create_temp_clients(data, tabType, success, failed)),
        set_quote_content       : (content) =>{
            dispatch({ type: 'SET_MAIN_CONTENT_QUOTE', content: content})
            dispatch({ type: 'SALES_SET_CONTENT_QUOTE', content: content })},
        fetch_drafts            : (content, type, id, success, failed) => dispatch(fetch_quote_client_draft(content, type, id, success, failed)),
        fetch_template_drafts   : (content, type, editId, success, failed) => dispatch(fetch_quote_template_new(content, type, editId, success, failed)),
        fetch_single_draft      : (content, id, success, failed, type) => dispatch(fetch_single_draft(content, id, success, failed, type)),
        edit_saved_draft        : (content, id, success, failed) => dispatch(edit_single_draft(content, id, success, failed)),
        setQuoteSavedDraft      : (obj) => dispatch({ type:'SET_SAVED_DRAFT_CLIENT', content:obj }),
        delSingleDraft          : (id, success, failed) => dispatch(del_single_draft(id, success, failed)),
        setSearchQuery          : (val) => dispatch(sales_quote_client(val)),
        templateLoading         : () => dispatch({ type: 'RELOAD_TEMPLATE_LOADER' })
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (GenerateQuote)
