let initialState = {
    jobComplList    : [],
    jobComplLoader  : true,
    jobComplPage    : 1,
    jobCompStop     : false,
    compl_template  : null,
    compl_table     : null,
    compl_temp_amount : 0,

    jobComplSearchKey : null,
    jobComplFilterFrom : null,
    jobComplFilterTo : null,

    jobCompletedCurrentApi : null,

    commentsList    : []
}

const invoiceData = (state = initialState, actions) =>{
    switch (actions.type) {
        case "TURNON_LOADER_COMPL_JOBS" :
            return {
                ...state,
                jobComplLoader  :   actions.loader
            }
        case "GET_ALL_COMPL_JOBS" :
            let newList = actions.page===1 ? actions.list : [...state.jobComplList, ...actions.list]   
            return{
                ...state,
                jobComplList    : newList,
                jobComplLoader  : false,
                jobComplPage    : actions.page,
                jobComplSearchKey : actions.search,
                jobComplFilterFrom : actions.from,
                jobComplFilterTo : actions.to,
                jobCompStop     : actions.stopPage
            }

        case "REFRESHL_COMPL_JOBS" :
            let currList = state.jobComplList
            currList.splice(currList.length-(actions.list.length+1),1,actions.list)

            return{
                ...state,
                jobComplList    : currList.flat(1),
            }

        case "UPDATE_COMPL_JOB" :
            let oldList = state.jobComplList
            oldList[actions.pos].paid_status = actions.content.paid_status
            return {
                ...state,
                jobComplList    :   oldList
            } 

        case "SET_COMPL_TEMP" : 
            return {
                ...state,
                compl_template  :   `<style>td{ text-align : left; }</style>${actions.content}`,
                compl_table     :   actions.content_table
            }

        case "SET_COMPL_AMT" :
            return {
                ...state,
                compl_temp_amount : actions.amount
            }

        case "SET_COMMENTS_ARRAY" : 
            let oldCompList = state.jobComplList
            oldCompList[actions.pos].total_job_comments += actions.commentNo
            return {
                ...state,
                jobComplList : oldCompList,
                commentsList : actions.comments.reverse()
            }

        case "SET_JOB_FNCALLED_INMEMORY" :
            return {
                ...state,
                jobCompletedCurrentApi : actions.currentApiObj
            }
        
        default:
            return state
    }
}

export default invoiceData