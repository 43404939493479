import { AddCircleRounded, DeleteForeverOutlined, EditOutlined, LocalPrintshopRounded } from '@material-ui/icons';
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { delSiteAttach, getAllSiteAttach, takeAllPrint } from '../../../action';
import { cl_red, cl_green } from '../../../../../common/Colors'
import Swal from 'sweetalert2'
import NewClientAttach from './NewClientAttach';

function ClientAttach({empId, fetch_attach, attachments, permissions}) {
    const [jobModal, setJobModal] = useState(false)
    const [attObj, setAttObj] = useState(null)
    const [jobModalType, setJobModalType] = useState(false)

    
   useEffect(() => {
    fetch_attach(empId)
    return () => fetch_attach.remove
    }, [fetch_attach, empId])

    const delAttach = (id) =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Site Attachment deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            fetch_attach(empId)
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                delSiteAttach(id, success, failed)
            }
        })
    }

    return (
        <table className="clientAttachTableDiv">
            <div className="clientAttachTableNavDiv">
                {   permissions.site.add ?
                <div className="folder-btn folder-btn-active" style={{ width:'8vw', justifyContent:'space-between', padding:'0 10px', marginBottom:'10px' }}
                    onClick={()=> setJobModal(true) }
                >
                    <AddCircleRounded/>
                    <span>Add Asset</span>
                </div>
                : null  }
                
                <div className="folder-btn folder-btn-active" style={{ width:'8vw', justifyContent:'space-between', padding:'0 10px', marginBottom:'10px' }}
                    onClick={()=>{ takeAllPrint(empId, 'pumps','asset_id') }}
                >
                    <LocalPrintshopRounded/>
                    <span>Print Asset</span>
                </div>
            </div>
            <thead className="clientAttachTableHeadDiv">
                <th>Owner</th>
                <th>Pump Asset No</th>
                <th>Location</th>
                <th>Frequency</th>
                <th>Last Service</th>
                <th>Next Service</th>
                <th>Service Description</th>
                <th>Action</th>
            </thead>
            
            <tbody className="clientAttachTableBodyDiv">    
                {
                    attachments.map((item,key) => {
                        return(
                            <tr className="clientAttachTableBodyRowDiv" key={key}>
                                <td>{item.owner}</td>
                                <td>{item.pump_assetNo}</td>
                                <td>{item.location}</td>
                                <td>{item.frequency}</td>
                                <td>{ moment(item.last_service).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{ moment(item.next_service).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{item.service_description}</td>
                                <td>
                                    {   permissions.site.edit ?
                                    <div className='clientAttachActBtns'
                                        onClick={()=>{
                                            setAttObj(item)
                                            setJobModal(true)
                                        }}
                                    >
                                        <EditOutlined style={{ fontSize:'16px' }}/>
                                    </div>  :   null }
                                    {   permissions.site.delete ?
                                    <div className='clientAttachActBtns'
                                        onClick={()=>delAttach(item.id)}
                                    >
                                        <DeleteForeverOutlined style={{ fontSize:'16px' }}/>
                                    </div>  : null
                                    }
                                    <div className='clientAttachActBtns clientAttachViewBtn'  style={{ borderRadius:'10px' }}
                                    onClick={()=>{
                                        setAttObj(item)
                                        setJobModalType(true)
                                        setJobModal(true)
                                    }}
                                    ><span style={{ fontSize:'14px' }}>View</span></div>  
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
            {   jobModal    ?
        <div className={!jobModal ? "jobCardModalBgDiv" : "jobCardModalBgDiv jobCardModalBgDivClose"}>
            <NewClientAttach setJobCardModal={setJobModal} attachObj={attObj} setAttachObj={setAttObj} clientId={empId} setModalType={setJobModalType} modalType={jobModalType}/>
        </div>  :   null
            }
        </table>
    );
}

const mapStateToProps = (state) =>{
    const {attachments} = state.client 
    const { permissions } = state.userLogin
    return {attachments, permissions}
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetch_attach : (id) => dispatch(getAllSiteAttach(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ClientAttach);