import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import { Close  } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperImg: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

function AddEditFileFolder ({
    modalStatus,
    modalAction,
    newFolder,
    folderName,
    folderNameAction,
    createAction,
    updateAction,
    folderId,
    entity,
    loadingFolder,
    setLoadingFolder
}) {
    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >
            <div className="intranet-folder-modal-layout" style={{ overflowY:'auto' }}>
                <div className="intranet-modal-title-layout">
                    {
                        newFolder ? (<p>New Folder</p>) : (<p>Rename</p>)
                    }
                    
                    <Close
                     onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </div>

                <input 
                    placeholder="Untitled folder"
                    className="intranet-input"
                    value={folderName}
                    onChange={txt => folderNameAction(txt.target.value)}
                />

                <div className="intranet-btn-layout">
                    <button
                     onClick={() => handleClose()} className="intranet-cancel-btn">CANCEL</button>
                    {
                        newFolder ? (
                            <button 
                                onClick={() => {
                                    if(!loadingFolder){
                                        setLoadingFolder(true)
                                        createAction()
                                    }
                                }}
                                className={ loadingFolder ? "intranet-create-btn disabled-btn" : "intranet-create-btn"}>CREATE</button>
                        ) : (
                            <button 
                                onClick={() =>{ 
                                    if(!loadingFolder){
                                    setLoadingFolder(true)
                                    updateAction(folderId, entity);
                                    }
                                }}
                                className={ loadingFolder ? "intranet-create-btn disabled-btn" : "intranet-create-btn"}>RENAME</button>
                        )
                    }
                    
                </div>
                
            </div>

        </Modal>
    )
}

export default AddEditFileFolder