import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { Modal, CircularProgress, Tooltip } from '@material-ui/core'
import { edit_employee, designation_list } from '../action'
import DateTimeInput from '../../../common/DateTimeInput'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import Scrollbar from '../../../common/ImprovedScrollBar.jsx';
import RefetchPerms from './RefetchPerms'
import { refetch_profile } from '../../login/action'
import PasswordInputFormatter from '../../../common/PasswordInputFormatter'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({modalStatus, team, modalAction, editEmployee_fx, refresh_employee, permissions, user, refetchFn}) {
    const classes = useStyles();
    const { selected_employee } = team
    const { employee_id, name, user_type, contact_number, email, alert_before, expiry_date,
        personal_email, termination_date, address, emergency_contact_name, 
        emergency_contact, employement_status, date_of_birth, date_joined,
        username } = selected_employee

    const [Designations, setDesignations] = useState([])
    const [UserType, setUserType] = useState(user_type)

    const [EmpId, setEmpId] = useState(employee_id)
    const [Name, setName] = useState(name)
    // const [Bio, setBio] = useState(bio)
    // const [Designation, setDesignation] = useState(designation)
    const [ContactNo, setContactNo] = useState(contact_number)
    const [EmailID, setEmailID] = useState(personal_email)
    const [WorkEmailID, setWorkEmailID] = useState(email)
    const [Address, setAddress] = useState(address)
    // const [IdProof, setIdProof] = useState(id_proof_type)
    // const [IdNumber, setIdNumber] = useState(id_proof_number)
    // const [IdAttachment, setIdAttachment] = useState('')
    const [btnLoader, setBtnLoader] = useState(false)
    const [terminationDate, setTerminationDate] = useState(termination_date==="" ? null : termination_date)

    const [LicenseAlert, setLicenseAlert] = useState(alert_before==="" && !moment(alert_before).isValid() ? null : alert_before)
    const [LicenseExpiry, setLicenseExpiry] = useState(expiry_date==="" && !moment(expiry_date).isValid() ? null : expiry_date)

    const [EmContactName, setEmContactName] = useState(emergency_contact_name)
    const [EmContactNo, setEmContactNo] = useState(emergency_contact)
    const [EmpStatus, setEmpStatus] = useState(employement_status)

    const [DOB, setDOB] = useState(date_of_birth==="" ? null : date_of_birth)
    const [JoiningDate, setJoiningDate] = useState(date_joined==="" ? null : date_joined)


    const [DateEditTerminate, setDateEditTerminate] = useState(false)
    const [DateEditDOB, setDateEditDOB] = useState(false)
    const [DateEditJoinDate, setDateEditJoinDate] = useState(false)
    const [DateLicenseAlert, setDateLicenseAlert] = useState(false)
    const [DateLicenseExpiry, setDateLicenseExpiry] = useState(false)

    const [Username, setUsername] = useState(username)
    const [newPassword, setNewPassword] = useState('')
    const [error, setError] = useState('')

    const [modaActionPerms, setModalActionPerms] = useState(false)


    const initalApiCallBack = (response) => {
        setDesignations(response.designations)
    }

    useEffect(() => {
        designation_list(initalApiCallBack)
        return () => designation_list.remove
    }, [])

    let submit_btn_status = false

    if(employee_id !== EmpId || name !== Name ||  user_type !== UserType || contact_number !== ContactNo || LicenseAlert !== alert_before || 
        personal_email !== EmailID || email !== WorkEmailID || terminationDate !== termination_date || Address !== address || LicenseExpiry !== expiry_date
        || EmContactName !== emergency_contact_name || EmContactNo !== emergency_contact || EmpStatus !== employement_status
        || DOB !== date_of_birth || JoiningDate !== date_joined || Username !== username || newPassword ) {
        submit_btn_status = true
    } else {
        submit_btn_status = false
    }

    const handleClose = () => {
        modalAction(false)
    };

    const update_datas = () => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        console.log('LicenseAlert Expiry ',LicenseAlert, LicenseExpiry)
        if (!pattern.test(WorkEmailID) || (!pattern.test(EmailID) && EmailID!=='')) {
            //   isValid = false;
                if(!pattern.test(WorkEmailID)) {
                    setError("Please enter valid email address.")
                    setWorkEmailID(email)
                } 
                if(!pattern.test(EmailID) && EmailID!=='') {
                    setError("Please enter valid email address.")
                    setEmailID(personal_email)
                } 
              
            }
        else{
            setError('')
            setBtnLoader(true)
        let form_body = new FormData()
        form_body.append('employee_id', EmpId)
        form_body.append('name', Name)
        // form_body.append('bio', Bio)
        form_body.append('user_type', UserType)
        form_body.append('permission_type',UserType)
        form_body.append('contact_number', ContactNo)
        form_body.append('personal_email', EmailID)
        form_body.append('email', WorkEmailID)
        // form_body.append('termination_date', '')
        form_body.append('emergency_contact_name', EmContactName)
        form_body.append('emergency_contact', EmContactNo)
        form_body.append('address', Address)
        form_body.append('employement_status', EmpStatus)
        form_body.append('termination_date', terminationDate===null || terminationDate==='null' ? '' : terminationDate) 
        if(LicenseExpiry!==expiry_date && LicenseExpiry)
            form_body.append('expiry_date', LicenseExpiry===null || LicenseExpiry==='null' ? '' : LicenseExpiry)
        if(LicenseAlert!==alert_before && LicenseAlert)
            form_body.append('alert_before', LicenseAlert===null || LicenseAlert==='null' ? '' : LicenseAlert)
        // console.log('TERMIN',terminationDate, terminationDate===null || terminationDate==='null' ? '' : terminationDate)
        if(Username!==username)
            form_body.append('username', Username)
        if(newPassword)
            form_body.append('password', newPassword)
        
        form_body.append('date_of_birth', DOB)
        form_body.append('date_joined', JoiningDate)

        const success_fx = (response) => {
            setBtnLoader(false)
            setError('')
            refresh_employee(response.data)

            setDateEditTerminate(false)
            console.log(user.id, response.data.id, permissions.current_user_permission, response.data.user_type)
            if(user.id===response.data.id && permissions.current_user_permission!==response.data.user_type){
                
                setModalActionPerms(true)
            }
            else{
                modalAction(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Empoyee updated successfull!`,
                showConfirmButton: false,
                timer: 1500
            })
            
            }
        }

        const failed_fx = (res) => {
            setBtnLoader(false)
            modalAction(false)
            console.log(res)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.data && res.data.app_data ? res.data.app_data : 'Something went wrong!' : 'Something went wrong!' 
            })
        }

        editEmployee_fx(form_body, selected_employee.id, success_fx, failed_fx)
        }
    
        
    }
    const RenderSubmitBtn = () => {
        if(btnLoader) {
            return (
                <div className="team-add-btn disabled-btn">
                    <CircularProgress size={20} color='inherit' />
                </div>
            )
        } else if (submit_btn_status) {
            
            return (
                <div onClick={() => update_datas()} className="team-add-btn">
                    <p className="p-0 m-0">UPDATE</p>
                </div>
            )
        } else return (
            <div className="team-add-btn disabled-btn">
                <p className="p-0 m-0">UPDATE</p>
            </div>
            
        )
    }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
          { !modaActionPerms ?
              
            <div className="team-edit-settings-layout">
            <Scrollbar style={{ height: '70vh' }}>

                <div className="team-edit-setting-main">
                    <p className="team-edit-title">Update Team Member</p>
                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">EMP ID</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                            <input 
                            type="number"
                            placeholder="Employee ID"
                            className="team-input"
                            value={EmpId}
                            onChange={txt => setEmpId(txt.target.value)}
                        />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Name</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="text"
                            placeholder="Name"
                            className="team-input"
                            value={Name}
                            onChange={txt => setName(txt.target.value)}
                        />
                        </div>
                    </div>

                    {/* <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Bio</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="text"
                            placeholder="Bio"
                            className="team-input"
                            value={Bio}
                            onChange={txt => setBio(txt.target.value)}
                        />
                        </div>
                    </div> */}

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Address</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="text"
                            placeholder="Address"
                            className="team-input"
                            value={Address}
                            onChange={txt => setAddress(txt.target.value)}
                        />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Position Title</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        {/* <input 
                            type="text"
                            placeholder="Designation"
                            className="team-input"
                            value={Designation}
                            onChange={txt => setDesignation(txt.target.value)}
                        /> */}
                        <select 
                            className="team-input"
                            style={{ textTransform:'uppercase' }}
                            value={UserType}
                            onChange={txt => setUserType(txt.target.value)}
                        >
                            {
                                Designations.map((item, key) => {
                                    return (
                                        <option key={key}
                                        style={{ textTransform:'uppercase' }} value={item.user_type}>{item.user_type}</option>
                                    )
                                })
                            }
                        </select>
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">License Expiry</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right" style={{ display:'flex', flexDirection:'row' }}>
                        {
                            (LicenseExpiry==='null' || LicenseExpiry===null || LicenseExpiry==='') && !DateLicenseExpiry ?
                            <CalendarTodayRoundedIcon onClick={()=> setDateLicenseExpiry(true)} style={{ fontSize:'16px' }}/>
                            : 
                            <DateTimeInput
                                dateValue={LicenseExpiry}
                                dateSetFn={setLicenseExpiry}
                                clsName="team-input"
                                openEdit={DateLicenseExpiry}
                            />
                        }
                        
                        </div>
                        
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">License Alert</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right" style={{ display:'flex', flexDirection:'row' }}>
                        {
                            (LicenseAlert==='null' || LicenseAlert===null || LicenseAlert==='') && !DateLicenseAlert ?
                            <CalendarTodayRoundedIcon onClick={()=> setDateLicenseAlert(true)} style={{ fontSize:'16px' }}/>
                            : 
                            <DateTimeInput
                                dateValue={LicenseAlert}
                                dateSetFn={setLicenseAlert}
                                clsName="team-input"
                                openEdit={DateLicenseAlert}
                            />
                        }
                        
                        </div>
                        
                    </div>


                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Employment Status</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-right">
                    {/* <input 
                        type="text"
                        placeholder="User Type"
                        className="team-input"
                        value={UserType}
                        onChange={txt => setUserType(txt.target.value)}
                    /> */}
                    <select 
                        className="team-input"
                        value={EmpStatus}
                        onChange={txt => setEmpStatus(txt.target.value)}
                    >

                        <option key='1' value='full_time' >Full-Time</option>
                        <option key='2' value='part_time'>Part-Time</option>
                        <option key='3' value='casual'>Casual</option>
                    </select>
                   </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Contact No</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="number"
                            placeholder="Number"
                            className="team-input"
                            value={ContactNo}
                            onChange={txt => setContactNo(txt.target.value)}
                        />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Email Address</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="email"
                            placeholder="Email Address"
                            className="team-input"
                            value={EmailID}
                            onChange={txt => setEmailID(txt.target.value)}
                        />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Work Email Address</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="email"
                            placeholder="Email Address"
                            className="team-input"
                            value={WorkEmailID}
                            onChange={txt => setWorkEmailID(txt.target.value)}
                        />
                        </div>
                    </div>
                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Emergency Contact</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="text"
                            placeholder="Name"
                            className="team-input"
                            value={EmContactName}
                            onChange={txt => setEmContactName(txt.target.value)}
                        />
                        </div>
                    </div>
                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Emergency Contact No</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="number"
                            placeholder="Number"
                            className="team-input"
                            value={EmContactNo}
                            onChange={txt => setEmContactNo(txt.target.value)}
                        />
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Termination Date</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right" style={{ display:'flex', flexDirection:'row' }}>
                        {
                            (terminationDate==='null' || terminationDate===null || terminationDate==='') && !DateEditTerminate ?
                            <CalendarTodayRoundedIcon onClick={()=> setDateEditTerminate(true)} style={{ fontSize:'16px' }}/>
                            : 
                            <DateTimeInput
                                dateValue={terminationDate}
                                dateSetFn={setTerminationDate}
                                clsName="team-input"
                                openEdit={DateEditTerminate}
                            />
                        }
                        
                        {
                            (terminationDate!=='null' && terminationDate!==null && terminationDate!=='') ?
                            <CancelRoundedIcon  style={{ paddingLeft:'10px', fontSize:'16px' }}
                             onClick={()=>{ setTerminationDate(null); setDateEditTerminate(false); console.log(terminationDate)}}/>
                            : 
                            null
                        }
                        </div>
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Date of Birth</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right" style={{ display:'flex', flexDirection:'row' }}>
                        {
                            (DOB==='null' || DOB===null || DOB==='') && !DateEditDOB ?
                            <CalendarTodayRoundedIcon onClick={()=> setDateEditDOB(true)} style={{ fontSize:'16px' }}/>
                            : 
                            <DateTimeInput
                                dateValue={DOB}
                                dateSetFn={setDOB}
                                clsName="team-input"
                                future={true}
                                openEdit={DateEditDOB}
                            />
                        }
                        
                        </div>
                        
                    </div>

                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Joining Date</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right" style={{ display:'flex', flexDirection:'row' }}>
                        {
                            (JoiningDate==='null' || JoiningDate===null || JoiningDate==='') && !DateEditJoinDate ?
                            <CalendarTodayRoundedIcon onClick={()=> setDateEditJoinDate(true)} style={{ fontSize:'16px' }}/>
                            : 
                            <DateTimeInput
                                dateValue={JoiningDate}
                                dateSetFn={setJoiningDate}
                                clsName="team-input"
                                rangeDate={Date(DOB)}
                                openEdit={DateEditJoinDate}
                            />
                        }
                        
                        </div>
                        
                    </div>
                    {   permissions.team.profile_edit &&
                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Username</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="text"
                            placeholder="Username"
                            className="team-input"
                            value={Username}
                            onChange={txt => setUsername(txt.target.value)}
                        />
                        </div>
                    </div>
                    }
                    {   permissions.team.profile_edit &&
                    <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">New Password</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <Tooltip 
                        open={newPassword===selected_employee.password} 
                        title={'New Password cannot be same as Old Password'} 
                        arrow={true} placement={'top'}
                        >
                            <PasswordInputFormatter
                                placeholder="New Password"
                                className="team-input"
                                value={newPassword}
                                outerDiv={{ className : "team-data-edit-right" }}
                                onChange={txt => setNewPassword(txt.target.value)}
                            />
                        {/* <input 
                            type="password"
                            placeholder="New Password"
                            className="team-input"
                            value={newPassword}
                            onChange={txt => setNewPassword(txt.target.value)}
                        /> */}
                        </Tooltip>
                    </div>
                    }
                    {/* <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">ID Proof</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <select 
                            className="team-data-option"
                            value={IdProof}
                            onChange={txt => setIdProof(txt.target.value)}
                        >
                            <option value="driving-license">Driving License</option>
                            <option value="passport">Passport</option>
                        </select>
                        </div>
                    </div> */}

                    {/* <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">ID Number</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="text"
                            placeholder="ID Number"
                            className="team-input"
                            value={IdNumber}
                            onChange={txt => setIdNumber(txt.target.value)}
                        />
                        </div>
                    </div> */}

                    {/* <div className="team-data-noraml">
                        <div className="team-data-edit-left">
                            <p className="p-0 m-0">Attachment</p>
                        </div>
                        <div className="team-data-edit-center">
                            <p className="p-0 m-0">: </p>
                        </div>
                        <div className="team-data-edit-right">
                        <input 
                            type="file"
                            className="team-input"
                            onChange={file => {
                                var doc = file.target.files[0]
                                setIdAttachment(doc)
                                // let extension = doc.type
                                // if(extension === 'image/jpeg' || extension === 'image/png') {
                                //     var image_blob = URL.createObjectURL(image_file)
                                //     setClientImage(image_blob)
                                //     setClientImageFile(image_file)
                                // } else {
                                //     alert('File should be an Image. Only jpg and png files are accepted')
                                // }
                            }}
                        />
                        </div>
                    </div> */}
                    <p style={{ fontSize: 11, color: 'red', padding: 0, marginLeft: 20, marginTop: 5, marginBottom: 5 }} >{error}</p>


                    <div className="team-edit-submit-layout" style={{ paddingBottom:'20px' }}>
                        <RenderSubmitBtn />
                    </div>
                </div>
            </Scrollbar>
            </div>
            :
            <RefetchPerms modalAction={setModalActionPerms} refFn={refetchFn} />
            }
      </Modal>
  );
}

const mapPropState = (state) => {
    const { team } = state
    const { permissions, user } = state.userLogin
    return {team, permissions, user}
}

const mapDispatchProps = (dispatch) =>{
    return {
        editEmployee_fx : (form_body, employee_id, success_fx, failed_fx) => dispatch(edit_employee(form_body, employee_id, success_fx, failed_fx)),
        
        refresh_employee : (res) => dispatch({ type: 'RESET_EMPLOYEE_DATA', employee: res}),
        refetchFn : () => dispatch(refetch_profile()) 
    }
}

export default connect(mapPropState, mapDispatchProps) (TransitionsModal)


