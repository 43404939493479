import moment from "moment"

let initialState = {
    team_list: [],
    team_dangerous_data: null,
    dataId: null,
    dangerous_loader: false,
    isLoading: false,
    error: false,
    loading:false,
    selected_employee: undefined,
    all_folders: [],
    folder_details: [],
    team_folders_info: null,
    folder_emp: [],
    team_page: 1,
    stopPagination: false,
    singleTeam: true, 

    initialTimeSheet: [],
    initialTimeSheetObj: null,
    timeSheetLoader: false,
    initialTimeSheetId: null,
    initialLeaveList: [],
    leaveListPage:1,
    leaveApplyListLoader: false,
    filterDate: new Date(),
}

const team = (state = initialState, actions) => {
    switch (actions.type) {

        case "SELECT_EMPLOYEE_LOADING":
            return{
                ...state,
                singleTeam : true
            }
        case "TEAM_DANGEROUS_DATA":
            return{
                ...state,
                team_dangerous_data: typeof actions.data==="string" ? JSON.parse(actions.data) : actions.data,
                dangerous_loader: true,
                dataId: actions.dataId
            }

        case "TEAM_FOLDERS_INFO":
            return{
                ...state,
                team_folders_info: actions.data
            }

        case "TEAM_FETCHING":
            return {
                ...state, 
                dangerous_loader: false,
                isLoading: true,
            }

        case "TEAM_FETCHING_SUCCESS":
            return { 
                ...state, 
                isLoading: false, 
                error: false, 
                team_list: actions.teams, 
            }
        
        case "TEAM_FETCHING_SUCCESS_PAGINATED":
            let pagingList = actions.teamPage===1 ? actions.teams : state.team_list.concat(actions.teams)
            let newPage = actions.teams ? actions.teamPage : state.teamPage
            console.log('TEAM FETCHING!!!')
            return { 
                ...state, 
                // singleTeam: true,
                isLoading: false, 
                error: false, 
                team_list: pagingList,
                team_page: newPage,
                stopPagination: actions.teams && actions.teams.length===20 ? false : true,
                initialTimeSheet: [],
                filterDate: new Date(), 
            }

        case "FOLDER_FETCHING_SUCCESS":
            return { 
                ...state,
                all_folders: actions.folders, 
            }

            
        case "FOLDER_EMP_FETCHING_SUCCESS":
            return { 
                ...state,
                loading:false,
                folder_emp:actions.folders
                
            }   
        case "FOLDER_EMP_FETCHING_FAILED":
            return { 
                ...state, 
                loading:false, 
                error: true, 
            }


        case "FOLDER_DETAILS_ETCHING_SUCCESS":
            return { 
                ...state,
                folder_details: actions.folders, 
            }
        
        case "TEAM_FETCHING_FAILED":
            return { 
                ...state, 
                isLoading: false, 
                error: true, 
                stopPagination: true,
            }

        case "SELECT_EMPLOYEE":
            console.log('SELECTEMP, SINGLETEAM CHECK')
            return { 
                ...state, 
                singleTeam: false,
                selected_employee: actions.employee
            }

        case "RESET_EMPLOYEE_DATA":
            let temp_list = state.team_list
            let updated_data = actions.employee
            let prevData = temp_list.find(item =>item.employee_id === updated_data.employee_id)
            let index = temp_list.indexOf(prevData)
            temp_list[index] = updated_data
            return { 
                ...state, 
                team_list: temp_list, 
                selected_employee: actions.employee
            }

        case "INIT_TIMESHEET" :
            console.log('REDUCERTimesheet',actions.timeSheet,actions.filterDate)
            let dummyTimeSheet = actions.timeSheet && typeof actions.timeSheet!=='undefined' ? 
            actions.timeSheet :
            Object.assign({}, { employee_id		       : actions.userId,
                comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
                whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                week			              :	[
                    { annual		: 0, date		: moment(actions.filterDate).format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },

                { annual		: 0, date		: moment(actions.filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                { annual		: 0, date		: moment(actions.filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                
                  ]
            })
            return {
                ...state,
                initialTimeSheet: dummyTimeSheet,
                filterDate      : actions.filterDate,
                timeSheetLoader : true,
                initialTimeSheetId : actions.timeSheet && typeof actions.timeSheet!=='undefined' ? actions.timeSheet.id : null
            }

        case "UPDATE_TIMESHEET" :
           
            return {
                ...state,
                initialTimeSheet : actions.timeSheet
            }

        case "INIT_LEAVE_APPLYLIST" :
            let listPaging = []
            if(actions.page===1)
                listPaging = actions.leaveApply
            else if(actions.leaveApply && actions.leaveApply.length!==0) 
                listPaging = [...state.initialLeaveList, ...actions.leaveApply]
            else 
                listPaging = state.initialLeaveList

            return {
                ...state,
                initialLeaveList: listPaging,
                leaveListPage: actions.leaveApply && actions.leaveApply.length!==0 ? actions.page : 1,
                leaveApplyListLoader: true
            }

        case "LEAVEAPPLYLISTLOADERACTION" : 
            return{
                ...state,
                leaveApplyListLoader : actions.loader
            }

        case "CLOSE_TEMPMODAL" : 
            return {
                ...state,
                initialTimeSheet: [],
                timeSheetLoader: false,
                initialTimeSheetId : null,
                
            }
        default:
            return state

    }
}

export default team