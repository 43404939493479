import Swal from 'sweetalert2'
import { FileDownloader } from '../../../../../common/file_downloader'
import Api from '../../../../../store/api'
import { 
    VEHICLE_GET, 
    VEHICLE_EDIT_MULTIPLE, VEHICLE_ADD,

    VEHICLE_MAINTANACE_GET, 
    // VEHICLE_MAINTANACE_SEARCH,
    VEHICLE_MAINTANACE_EDIT,
    VEHICLE_MAINTANACE_ADD,
    VEHICLE_MAINTANACE_DELETE,
    VEHICLE_MAINTANACE_FILTER,
    VEHICLE_ALL_GET,

    VEHICLE_FUEL_GET,
    VEHICLE_FUEL_EDIT,
    VEHICLE_FUEL_DELETE,
    VEHICLE_FUEL_ADD,
    VEHICLE_FUEL_FILTER,

    VEHICLE_PREINSPECT_FILTER,
    VEHICLE_PREINSPECTION,
    VEHICLE_FUEL_PRINT_ALL_CSV,
    VEHICLE_MAINTANACE_PRINT_CSV,

    VEHICLE_SEARCH,
    VEHICLE_PREINSP_PRINT_CSV,
    VEHICLE_UNARCHIVE,
    VEHICLE_ARCHIVE,
    
} from '../../../../../store/endpoint'

export const fetch_car_list = (tabType, page, callBk=null) => {
    return function (dispatch) {
        dispatch({type:'CAR_LIST_FETCHING',page: page})
        const success = (response) => {
            if (response) {
                dispatch({ type: 'CAR_LIST', cars: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'CAR_LIST_FAILED', error: error })
            }
        }
        if(callBk!==null){
            // callBk(true)
        }
        let get_url = VEHICLE_GET + tabType + `${tabType==='pumps' ? '/utes/all/' : '/car/all/'}` + page + '/?limit=15'
        Api('get', get_url,null,success,failed)
            // .then(response => {
            //     console.log(response)
            //     if(callBk!==null){
            //         // callBk(false)
            //     }
            //     if(response) {
            //         dispatch({ type: 'CAR_LIST', cars: response, page: page })
            //     }
            // })
    }
}
export const fetch_search_car = (tabType, page, txt) => {
    return function (dispatch) {
        dispatch({ type: 'CAR_LIST_FETCHING', keyword: txt,page:page})
        // if(callBk!==null){
        //     callBk(true)
        // }

        const success = (response) => {
            if (response) {
                dispatch({ type: 'CAR_LIST', cars: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'CAR_LIST_FAILED', error: {
                    status: null,
                    text: 'Something went wrong!',
                    // text: null,
                    dev_data: null,
                    app_data: null
                } })
            }
        }
        let formbody = new FormData()
        formbody.append('key', txt)
        formbody.append('types', 'car')
        formbody.append('tab_type', tabType)

        let url1 = `${VEHICLE_SEARCH.replace('VTYPE', `${tabType}/car`)}`
        let limit = `?page=${page}&limit=15`
        let url = url1 + limit

        Api('post-form', url, formbody,success,failed)
            // .then(response => {
            //     // if(callBk!==null){
            //     //     callBk(false)
            //     // }
            //     console.log(response)
            //     if (response) {
            //         dispatch({ type: 'TRUCK_LIST', trucks: response, page: page })

            //     }
            // })
    }
}




export const fetch_all_car_list = (tabType) => {
    return function (dispatch) {
        let get_url = VEHICLE_ALL_GET.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        Api('get', get_url)
            .then(response => {
                console.log(response)

                if(response) {
                    dispatch({ type: 'ALL_CAR_LIST', cars: response })
                }
            })
    }
}


export const __vehicle_modal = (status) => {
    return function (dispatch) {
        dispatch({ type: 'VEHCILE_MODAL', modal: status})
    }
}

export const __vehicle_img_modal = (status) => {
    return function (dispatch) {
        dispatch({ type: 'VEHCILE_IMG_MODAL', modal: status})
    }
}


export const __select_vehicle = (car) => {
    return function (dispatch) {
        dispatch({ type: 'SELECT_CAR', car: car})
    }
}

export const __edit_vehicle = (tabType, vehicle_id, data, success, failed) => {
    return function (dispatch) {
        let str = VEHICLE_GET + `${tabType}`
        let edit_url = `${str + '/edit/' + vehicle_id}/`

        const success_res = () => {
            success()
        }
        Api('patch-form', edit_url, data, success_res, failed)
        .then(response => {
            dispatch({
                type: 'CAR_EDIT_LIST',
                id: response.id,
                content: response
            })
            
            // console.log(response)
        })
    }
}

export const __edit_vehicle_multi_images = (vehicle_id, data, page_no, success, failed) => {
    return function (dispatch){
        
        let edit_url = `${VEHICLE_EDIT_MULTIPLE + vehicle_id}/`
        Api('post-form', edit_url, data, success, failed).then(
            dispatch(fetch_car_list(page_no))
        )
    }
}


export const __create_vehicle = (tabType, data, success, failed) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_car_list(tabType, 1))
        }
        Api('post-form', VEHICLE_ADD, data, success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}

export const __delete_vehicle = (tabType, vehicle_id, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_car_list(tabType, page_no))
        }
        let str = VEHICLE_GET + `${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`
        let delete_url = `${str + '/delete/' + vehicle_id}/`
        Api('delete', delete_url, '', success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}

export const __archive_vehicle = (tabType, vehicle_id, archiveStatus, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_car_list(tabType, page_no))
        }
        let delete_url =  archiveStatus ? `${VEHICLE_UNARCHIVE}${vehicle_id}/` : `${VEHICLE_ARCHIVE}${vehicle_id}/`
        Api('post', delete_url, '', success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}



// ******************************************************
//                  M A I N T A N A N C E
// ******************************************************

export const fetch_car_maintanace_list = (tabType, page, callBk=null) => {
    return function (dispatch) {

        if(callBk!==null){
            callBk(true)
        }
        dispatch({type:'CAR_MAINTANACE_LIST_FETCHING',page: page})
        const success = (response) => {
            if (response) {

        if(callBk!==null){
            callBk(false)
        }
                dispatch({ type: 'CAR_MAINTANACE_LIST', maintanace: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {

        if(callBk!==null){
            callBk(false)
        }
                dispatch({ type: 'CAR_MAINTANACE_LIST_FAILED', error: error })
            }
        }
        let get_url = VEHICLE_MAINTANACE_GET.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`) + page + '/?limit=15'
        Api('get', get_url,null,success,failed)
            // .then(response => {
            //     if(callBk!==null){
            //         callBk(false)
            //     }
            //     if(response) {
            //         dispatch({ type: 'CAR_MAINTANACE_LIST', maintanace: response, page: page })
            //     }
            // })
    }
}



// export const search_maintanance_list = (tabType, query) => {
//     return function (dispatch) {
//         if(query) {
//             let formbody = new FormData()
//             formbody.append('key', query)
//             formbody.append('vehicle_key', tabType==='pumps' ? 'utes' : 'car')
//             Api('post', VEHICLE_MAINTANACE_SEARCH.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`), formbody)
//                 .then(response => {
//                     console.log(response)
//                     if(response)
//                     dispatch({ type: 'CAR_MAINTANACE_LIST', maintanace: response })
//                 })
//         } else {
//             dispatch(fetch_car_maintanace_list(tabType, 1))
//         }
//     }
// }

export const __select_maintanace = (maintanace) => {
    return function (dispatch) {
        dispatch({ type: 'SELECT_CAR_MAINTANACE', maintanace: maintanace })
    }
}



export const __edit_maintanace = (tabType, maintanace_id, data, success, failed, page_no) => {
    return function (dispatch) {
        let edit_url = `${VEHICLE_MAINTANACE_EDIT.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`) + maintanace_id}/`

        const success_res = () => {
            success()
            dispatch(fetch_car_maintanace_list(tabType, page_no))
        }
        Api('patch-form', edit_url, data, success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}



export const __create_maintanace = (tabType, data, success, failed) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_car_maintanace_list(tabType, 1))
        }
        Api('post-form', VEHICLE_MAINTANACE_ADD.replace('VTYPE','car'), data, success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}



export const __delete_maintanace = (tabType, maintanace_id, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_car_maintanace_list(tabType, page_no))
        }
        let delete_url = `${VEHICLE_MAINTANACE_DELETE.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`) + maintanace_id}/`
        Api('delete', delete_url, '', success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}

export const fetch_filter_maintance_list = (tabType, success, failed, data, type, id, page, fetchtype) =>{

    if(type==='all' && id==='all'){
        return function(dispatch) {

            dispatch({
                type: 'CAR_REFRESH_FILTER'
            })
            dispatch(fetch_car_maintanace_list(tabType,1))
        }
    }
    else{
    return function (dispatch) {
        if(fetchtype === 'filter' || fetchtype === 'date'){
                
            dispatch({type:'CAR_MAINTANACE_LIST_FETCHING',filter: id,page:page})
        }else{
            
            dispatch({type:'CAR_MAINTANACE_LIST_FETCHING',keyword: id,page:page})
        }
        let str = VEHICLE_MAINTANACE_FILTER.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let post_url = str+type+`/?page=${page}&limit=15`
        const success_res = (response) =>{
            if(fetchtype === 'filter' || fetchtype === 'date'){
                    
                dispatch({ type: 'CAR_FILTERED_MNTN_LIST', trucks: response, filterKey: id,page:page })
            }else{
                dispatch({ type: 'CAR_FILTERED_MNTN_LIST', trucks: response, keyword: id,page:page })
            }
            // dispatch({ type: 'CAR_FILTERED_MNTN_LIST', maintanace: resp, filterKey: id})
        }
        const failed_res = (error) =>{
            dispatch({ type: 'CAR_MAINTANACE_LIST_FAILED', error:error})
        }
        Api('post', post_url, data, success_res, failed_res)
            // .then(response => {
            //     if(callBk!==null){
            //         callBk(false)
            //     }
            //  }) 
    }
    }
}


// ******************************************************
//                       F U E L   E X P
// ******************************************************

export const car_fuel_fetch = (tabType, page, callBk=null) => {
    return function (dispatch) {
        if(callBk!==null){
            callBk(true)
        }
        dispatch({type:'CAR_FUEL_LIST_FETCHING',page: page})
        const success = (response) => {
            if (response) {
                dispatch({ type: 'CAR_FUEL_LIST', fuel: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'CAR_FUEL_LIST_ERROR', error: error })
            }
        }
        let str = VEHICLE_FUEL_GET.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let get_url = str + page + '/?limit=15'
        Api('get', get_url,null,success,failed)
            // .then(response => {
            //     if(callBk!==null){
            //         callBk(false)
            //     }
            //     // console.log(response)
            //     if(response) {
            //         dispatch({ type: 'CAR_FUEL_LIST', fuel: response, page: page })
            //     }
            // })
    }
}
export const fetch_fuel_list = (tabType, page, callBk=null) => {
    return function (dispatch) {
        dispatch({type:'CAR_FUEL_LIST_FETCHING',page: page})
        const success = (response) => {
            if (response) {
                dispatch({ type: 'CAR_FUEL_LIST', fuel: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'CAR_FUEL_LIST_ERROR', error: error })
            }
        }
        if(callBk!==null){
            callBk(true)
        }
        let str = VEHICLE_FUEL_GET.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let get_url = str + page + '/?limit=15'
        Api('get', get_url,null,success,failed)
            // .then(response => {
            //     if(callBk!==null){
            //         callBk(false)
            //     }
            //     // console.log(response)
            //     if(response) {
            //         dispatch({ type: 'CAR_FUEL_LIST', fuel: response, page: page })
            //     }
            // })
    }
}


export const __select_fuel = (fuel) => {
    return function (dispatch) {
        dispatch({ type: 'SELECT_CAR_FUEL', fuel: fuel })
    }
}


export const __edit_fuel = (tabType, maintanace_id, data, success, failed, page_no) => {
    return function (dispatch) {
        let str = VEHICLE_FUEL_EDIT.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let edit_url = `${str + maintanace_id}/`

        const success_res = () => {
            success()
            dispatch(fetch_fuel_list(tabType, page_no))
        }
        Api('patch-form', edit_url, data, success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}


export const __create_fuel = (tabType, data, success, failed) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_fuel_list(tabType, 1))
        }
        Api('post-form', VEHICLE_FUEL_ADD, data, success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}



export const __delete_fuel = (tabType, maintanace_id, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_fuel_list(tabType, page_no))
        }
        let str = VEHICLE_FUEL_DELETE.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let delete_url = `${str + maintanace_id}/`
        Api('delete', delete_url, '', success_res, failed)
        .then(response => {
            // console.log(response)
        })
    }
}

export const filter_fuel_list = (tabType, success, failed, data, type, id, page, fetchtype) =>{

    if(type==='all' && id==='all'){
        return function(dispatch) {

            dispatch({
                type: 'CAR_REFRESH_FILTER'
            })
            dispatch(fetch_fuel_list(tabType,1))
        }
    }
    else{
    return function (dispatch) {
        if(fetchtype === 'filter' || fetchtype === 'date'){
                
            dispatch({type:'CAR_FUEL_LIST_FETCHING',filter: id,page:page})
        }else{
            
            dispatch({type:'CAR_FUEL_LIST_FETCHING',keyword: id,page:page})
        }
        // dispatch({type:'CAR_FUEL_LIST_FETCHING',filter: id})
        let str = VEHICLE_FUEL_FILTER.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let post_url = str+type+`/?page=${page}&limit=15`
        const success_res = (response) =>{
            if(fetchtype === 'filter' || fetchtype === 'date'){
                dispatch({ type: 'CAR_FILTERED_FUEL_LIST', cars: response, filterKey: id,page:page })
            }else{
                dispatch({ type: 'CAR_FILTERED_FUEL_LIST', cars: response, keyword: id,page:page })
            }
            // dispatch({ type: 'CAR_FILTERED_FUEL_LIST', cars: resp, filterKey: id})
        }
        const failed_res = (error) =>{
            dispatch({ type: 'CAR_FUEL_LIST_ERROR', error:error})
        }
        Api('post', post_url, data, success_res, failed_res)
            // .then(response => {
            //     if(callBk!==null){
            //         callBk(false)
            //     }
            //  }) 
    }
    }
}


// ******************************************************
//           P R E I N S P E C T I O N   C H E C K
// ******************************************************

export const __create_preinspection = (tabType, data, success, failed) => {
    const success_res = () => {
        success()
    }
    Api('post-form', VEHICLE_PREINSPECTION.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
    , data, success_res, failed)
    .then(response => {
        console.log(response)
    })
}

export const edit_preinspection = (tabType, data, id, success, failed) => {
    const success_res = () => {
        success()
    }
    Api('put-json', `${VEHICLE_PREINSPECTION.replace('VTYPE', `${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)}edit/${id}/`, data, success_res, failed)
        .then(response => {
            console.log(response)
        })
}


export const preinspection_car_list = (tabType, page) => {
    return function (dispatch){
        dispatch({type:'FETCHING_CAR_PREINSP_LIST',page: page})
        const success = (response) => {
            if (response) {
                dispatch({
                    type: 'CAR_PREINSP_LIST_SUCCESS',
                    trucks: response, 
                    page: page
                })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'CAR_PREINSP_LIST_ERROR', error: error })
            }
        }
        Api('get', `${VEHICLE_PREINSPECTION.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)}${page}/?limit=15`
    , '', success, failed)
    // .then(response => {
    //     dispatch({
    //         type: 'FETCH_CAR_PREINSP_LIST',
    //         trucks: response, 
    //         page: page
    //     })
    //     console.log(response)
    // })
    }
}

export const fetch_filter_car_list = (tabType, success, failed, data, type, id, page, fetchtype) =>{

   
    if(type==='all' && id==='all'){
        return function(dispatch) {
            dispatch({
                type: 'CAR_REFRESH_FILTER'
            })
            dispatch(preinspection_car_list(tabType, 1))
        }
    }
    else{
    return function (dispatch) {
        if(fetchtype === 'filter' || fetchtype === 'date'){
            
            dispatch({type:'FETCHING_CAR_PREINSP_LIST',filter: id,page: page})
        }else{
            dispatch({type:'FETCHING_CAR_PREINSP_LIST',keyword: id,page: page})
        }
        
        const success1 = (response)=>{
            if(fetchtype === 'filter' || fetchtype === 'date'){
                dispatch({ type: 'CAR_FILTERED_LIST', trucks: response, filterKey: id,page: page })
            }else{
                dispatch({ type: 'CAR_FILTERED_LIST', trucks: response, keyword: id,page: page })
            }
    
        }
        const failed1 =(error)=>{
            dispatch({ type: 'CAR_PREINSP_LIST_ERROR', error: error })
        }
        let str = VEHICLE_PREINSPECT_FILTER.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
        let post_url = str+type+ `/?page=${page}&limit=15`
        console.log(data.get('date_time'), typeof data.get('date_time'))    
        Api('post-form', post_url, data, success1, failed1)
            // .then(response => {
            //     if(callBk!==null){
            //         callBk(false)
            //     }
            //     dispatch({ type: 'CAR_FILTERED_LIST', cars: response, filterKey: id})
            // }) 
        }
    }
}


export const updateSelectedPreInspect = (obj) =>{
    return function (dispatch) {
        dispatch({
            type:'SELECTED_CAR_PRE-INSPECT',
            selObj: obj
        })
    }
}

const failedMsg = () =>{
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
    })
}

export const fuelListCSVPrint = (tabType, filterKey='all',search_qry='', filterFromDate=null, filterToDate=null) =>{
    let str = VEHICLE_FUEL_PRINT_ALL_CSV.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
    let get_url = str
    if(filterKey!=='all' || search_qry!=='' || filterFromDate || filterToDate){
        get_url = get_url+'?'
    }
    if(filterKey!=='all'){
        get_url = `${get_url}registration=${filterKey}`
    }if(search_qry!==''){
        if(filterKey!=='all')
            get_url = get_url + '&'
        get_url = `${get_url}key=${search_qry}`
    }if(filterFromDate){
        if(filterKey!=='all' || search_qry!=='')
            get_url = get_url + '&'
        get_url = `${get_url}from_date=${filterFromDate}&`
    }if(filterToDate){
        if(filterKey!=='all' || search_qry!=='' || filterFromDate)
            get_url=get_url+'&'
        get_url = `${get_url}to_date=${filterToDate}`
    }


    const successMsg = (res) =>{
        FileDownloader(res,`FUEL_EXPENSE_${tabType==='pumps' ? 'utes' : 'car'}`,'.csv')
    }

    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res => 
    //     FileDownloader(res,`FUEL_EXPENSE_${tabType==='pumps' ? 'utes' : 'car'}`,'.csv')
    //      ).catch(err =>{ 
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Something went wrong!'
    //         })
    //     })
}

export const mntnceListCSVPrint = (tabType, filterKey='all',maintanace_search_qry='', filterFromDate=null, filterToDate=null) =>{
    let str = VEHICLE_MAINTANACE_PRINT_CSV.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
    let get_url = str
    if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate || filterToDate){
        get_url = get_url+'?'
    }
    if(filterKey!=='all'){
        get_url = `${get_url}registration=${filterKey}`
    }if(maintanace_search_qry!==''){
        if(filterKey!=='all')
            get_url = get_url + '&'
        get_url = `${get_url}key=${maintanace_search_qry}`
    }if(filterFromDate){
        if(filterKey!=='all' || maintanace_search_qry!=='')
            get_url = get_url + '&'
        get_url = `${get_url}from_date=${filterFromDate}&`
    }if(filterToDate){
        if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate)
            get_url=get_url+'&'
        get_url = `${get_url}to_date=${filterToDate}`
    }

    const successMsg = (res) =>{
        FileDownloader(res,`MNTNCE_REPORT_${tabType==='pumps' ? 'utes' : 'car'}`,'.csv')
    }

    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res => 
    //     FileDownloader(res,`MNTNCE_REPORT_${tabType==='pumps' ? 'utes' : 'car'}`,'.csv')
    //      ).catch(err =>{ 
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Something went wrong!'
    //         })
    //     })
}

export const preInspListCSVPrint = (tabType, filterKey='all',maintanace_search_qry='', filterFromDate=null, filterToDate=null) =>{
    let str = VEHICLE_PREINSP_PRINT_CSV.replace('VTYPE',`${tabType}/${tabType==='pumps' ? 'utes' : 'car'}`)
    let get_url = str
    if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate || filterToDate){
        get_url = get_url+'?'
    }
    if(filterKey!=='all'){
        get_url = `${get_url}registration=${filterKey}`
    }if(maintanace_search_qry!==''){
        if(filterKey!=='all')
            get_url = get_url + '&'
        get_url = `${get_url}key=${maintanace_search_qry}`
    }if(filterFromDate){
        if(filterKey!=='all' || maintanace_search_qry!=='')
            get_url = get_url + '&'
        get_url = `${get_url}from_date=${filterFromDate}&`
    }if(filterToDate){
        if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate)
            get_url=get_url+'&'
        get_url = `${get_url}to_date=${filterToDate}`
    }

    const successMsg = (res) =>{
        FileDownloader(res,`PREINSP_REPORT_${tabType==='pumps' ? 'utes' : 'car'}`,'.csv')
    }

    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res => 
    //     FileDownloader(res,`PREINSP_REPORT_${tabType==='pumps' ? 'utes' : 'car'}`,'.csv')
    //      ).catch(err =>{ 
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Something went wrong!'
    //         })
    //     })
}