import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import GoogleMap from './GoogleMap/GoogleMap'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function MapModal({ modalStatus, modalAction, markers, setMarkers, UpdateSiteAddress=null }) {
    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="map-modal-layout" style={{ overflowY:'auto' }}>
                <GoogleMap markers={markers} setMarkers={setMarkers} modalAction={modalAction} 
                  UpdateSiteAddress={UpdateSiteAddress}
                />
            </div>
      </Modal>
  );
}

export default MapModal

