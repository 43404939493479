import { TEAM_VEHICLE_SCHEDULE_EXIST } from '../../../../store/endpoint';
import Api from '../../../../store/api'

const Preventdelete = (val, apiResFn, modalFn, fallBackFn, errFn) => {

    apiResFn(null)
        modalFn(true)
        Api('get',`${TEAM_VEHICLE_SCHEDULE_EXIST}${val}/vehicle/`).then(res => {
            console.log(res)
            if(res && res.length>0){
                apiResFn(res)
            }
            else{
                modalFn(false)
                fallBackFn()
            }
        })
        .catch(()=>{
            modalFn(false);
            errFn()
        })
}

export default Preventdelete;
