import React from 'react'
import ErrorIcon from '@material-ui/icons/Error';


function NoDatasScreen() {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontSize: 12 }}>
            <ErrorIcon style={{ fontSize: 30 }} />
            <p>Sorry! No Data Found</p>
        </div>
    )
}

export default NoDatasScreen
