import React, { useState } from 'react'
import Scrollbars from '../../../../common/ImprovedScrollBar';
import { CircularProgress } from '@material-ui/core';
import SingleMember from './SingleMember'
import { connect } from 'react-redux'
import { fetch_clients_paginated, select_client } from '../../action'
import Endoflist from '../../../../common/EndOfList';
import DeletedMember from './DeletedMember';

function Members({ client, dispatch, tabState, temp_client, tableLoader, tabType }) {
    const { client_list, isLoading, 
        stopPagination, sort, ind_sort,
        clientType, clientPage } = client

    const select_client_data = (data) => {
        dispatch(select_client(data.id))
    }

    const [pageNo, setPageNo] = useState(1)

    const RenderLoading = () => {
        if(isLoading) {
            return (
                <div style={{ position: 'absolute', marginTop: '20%', marginLeft: '50%' }}>
                    <CircularProgress />
                </div>
            )
        } else {
            if(client_list && client_list.length === 0) 
                return (
                    <div style={{ position: 'absolute', marginTop: '5%', marginLeft: '50%' }}>
                        <div className="team-empty-layout">
                            <p className="m-0 p-0">No Site Found</p>
                        </div>
                    </div>
                )
            else if(!stopPagination)
            return (
                <div style={{ marginTop: '2%', marginLeft: '50%' }}>
                    <CircularProgress />
                </div>
            )
            else 
            return null
        }
    }

    const ScrollUp = (val) =>{
        const {top} = val
        // console.log('SCROLL',top, pageNo, clientPage)
        if((top >= 0.999) && ((pageNo===clientPage) || (pageNo!==1 && clientPage===1)) && !stopPagination){
            setPageNo(clientPage+1)
            dispatch(fetch_clients_paginated(sort, tabType, clientPage+1, clientType, ind_sort))
        }
    }

    return (
        <div className="client-members-layout">
            <Scrollbars autoHide style={{ height: '70vh' }}
                onUpdate={ScrollUp}
            >
            <div className="members-main-layout">
                
                    {
                        client_list && client_list.map((item, key) => {
                            if(clientType==='deleted')
                            return(
                                <DeletedMember 
                                key={key} 
                                data={item} 
                                select_client={select_client_data}
                                tabType={tabType}
                                />
                            )
                            else
                            return (
                                <SingleMember 
                                    key={key} 
                                    data={item} 
                                    select_client={select_client_data}
                                    tabType={tabType}
                                />
                            )
                        })
                    }
                        {/* :
                        temp_client.map((item, key) => {
                            return (
                                <SingleMember 
                                    key={key} 
                                    data={item} 
                                    select_client={select_client_data}
                                />
                            )
                        })
                    } */}
                    <RenderLoading />
                
            </div>
            { stopPagination && client_list && client_list.length !== 0 && <Endoflist />}
            </Scrollbars>
            
        </div>
    )
}

const mapStateProps = (state) => {
    const { client } = state
    const { temp_client, tableLoader } = state.sales
    return {client, temp_client, tableLoader}
}

export default connect(mapStateProps) (Members)
