import React from 'react'
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { connect } from 'react-redux';

function TeamActivity({team_folders_info}) {
  // console.log(team_folders_info)
    return (
        <div className="team-activity-layout" style={{ height:'20vh' }}>
           <div className="certificate-header">
                <div className="certificate-head-layout">
                    <p className="certify-title">Alert</p>
                </div>               
            </div>
            {
              team_folders_info && team_folders_info.length!==0 ?
              team_folders_info && team_folders_info.map((item, key) => {
                return(
                  <div className='notification-team' key={key}>
                    <div className='checkicon-team'>
                      <CancelIcon style={{color:'red'}}/>
                    </div>
                        {item.alert}
                  </div>
            
                )
              })
              :
              <div className='notification-team'>
              <div style={{ fontSize: 12, width: 200 }}>
                <p>No Folders Found!</p>
              </div>
              </div>
            }
            {/* <div className='notification-team'>
                <div className='checkicon-team'>
                  <CancelIcon style={{color:'red'}}/>
                </div>
                 Driving Licience expires in 25 days
            </div>
            <div className='notification-team'>
                <div className='checkicon-team'>
                  <CheckCircleIcon/>
                </div>
                 Transportation expires in 2 months
            </div>
            <div className='notification-team'>
                <div className='checkicon-team'>
                  <CheckCircleIcon/>
                </div>
                 Driving Licience expires in 25 days
            </div>
            <div className='notification-team'>
                <div className='checkicon-team'>
                  <CheckCircleIcon/>
                </div>
                 Transportation expires in 2 months
            </div> */}
        </div>
    )
}

const mapStateProps = (state) =>{
  const {team_folders_info} = state.team
  return {team_folders_info}
}

export default connect(mapStateProps) (TeamActivity)