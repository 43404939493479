import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'
import { store } from './app/store/store'
import './App.css';

// import LoginPageTst from './app/screens/login'
import AppRouter from './app/screens/Router'
import ErrorCatcher from './app/common/ErrorCatcher';
import { ERROR_CATCHER_SWITCH } from './app/store/config';

function App() {
  return (
    <ErrorCatcher errorCatcherSwitch={ERROR_CATCHER_SWITCH}>
      <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
        <Router>
          <AppRouter />
        </Router>
      {/* </PersistGate> */}
    </Provider>
    </ErrorCatcher>
        
  );
}

export default App;
