import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { MainStack, EditStack, CreateStack, ClientPhotoStack, EditClientDetails, 
    //JobDetail
 } from './component'
import { fetch_clients_paginated, fetch_client_totalNo, 
    //fetch_client_list
 } from './action'
// import { fetch_temp_clients } from '../sales/common/action'
// import DevModal from '../../common/developerMessage'

function App({ fetch_clients, permissions }) {
    const { path } = useRouteMatch()
    // const {pathname} = useLocation()
    // const [modalOpen, setModalOpen] = useState(pathname)
    const [tabType, setTabType] = useState( permissions.site.waste ? 'waste'
        :   permissions.site.pumps ? 'pumps' : permissions.site.hills ? 'hills' : 'destruction')

    useEffect(() => {
        fetch_clients(null, tabType)
        console.log('CALLED-RE')
        return () => fetch_clients.remove
    }, [fetch_clients, tabType])

    return (
        <Switch>
            {/* <DevModal modalUrl={modalOpen} modalAction={setModalOpen}/> */}
            <Route path={path + '/create'} name="client-create"><CreateStack tabType={tabType} /></Route>
            <Route path={path + '/detail'} name="client-detail"><EditStack tabType={tabType} /></Route>
            <Route path={path + '/detail-expanded'} name="client-detail-expand"><EditClientDetails tabType={tabType} /></Route>
            <Route path={path + '/ClientPhoto'} name="client-photo" component={ClientPhotoStack } />
            {/* <Route path={path + '/job-details/:jobId'} name="job-detail" component={JobDetail} /> */}
            <Route path={path} name="client-main"><MainStack tabType={tabType} setTabType={setTabType} /></Route>
        </Switch>
    )
}

const mapStateToProps = (state) => {
    const { permissions } = state.userLogin
    return { permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_clients: (par, tabType) => { 
            //dispatch(fetch_temp_clients(tabType)); dispatch(fetch_client_list(par, tabType));
            dispatch(fetch_clients_paginated(par, tabType, 1, 'perm'),
            dispatch(fetch_client_totalNo(tabType)))
        }
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
