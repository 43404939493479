import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import Sidebar from '../../common/sidebar'
import Header from './component/header'
import Waste from './waste'
import Pump from './pump'
import Hills from './hills.js'
import Destruction from './destruction.js'
import AllTab from './alltab.js'

import './component/style.css'
import { CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'

function App({permissions}) {
    const { path } = useRouteMatch()
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header />
                {
                    permissions ? 
                <Switch>
                    <Route path={path + '/waste'} component={Waste} />
                    <Route path={path + '/pumps'} component={Pump} />
                    <Route path={path + '/hills'} component={Hills} />
                    <Route path={path + '/destruction'} component={Destruction} />
                    <Route path={path + '/all'} component={AllTab} />
                    {
                        permissions && permissions.home.waste ?
                            <Redirect from={path} to={path +'/waste'}/>    :
                        permissions && permissions.home.pumps ?
                            <Redirect from={path} to={path +'/pumps'}/>     :
                        permissions && permissions.home.hills ?
                            <Redirect from={path} to={path + '/hills'}/>    :
                            <Redirect from={path} to={path + '/destruction'}/>
                    }
                </Switch>
                :
                <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%' }} >
                    <CircularProgress/>
                </div>
                }
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { permissions } = state.userLogin
    return { permissions }
}

export default connect(mapStateToProps, null) (App)