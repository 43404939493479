import { Avatar } from '@material-ui/core';
import React from 'react';

export const SchedulelistVehicleshimmer = ({shimmerType='v'}) => {
    return (
            <div
                className="shd-new-scheduler-col-layout shd-new-scheduler-col-layout-head"
                style={{
                    gridTemplateColumns: `80px repeat(20, 5.29vw)`,
                    width:'100%', backgroundColor:'white'
                }}
            >
                <div className="shd-new-scheduler-item-head shimmerHead shimAdj"></div>
                {
                    [...Array(20)].map((_, key) => {
                        return (
                                shimmerType==='v' ?
                                <div key={key} style={{ fontSize: 5.29 * 1.5 }} className="shd-new-scheduler-item-head shimmerHead shimAdj">
                                    Vehicle 
                                </div>
                                :
                                shimmerType==='m' ?
                                <div key={key} style={{ fontSize: 5.29 * 1.5 }} className="shd-new-scheduler-item-head shimmerHead shimAdj">
                                <span>
                                    <Avatar style={{ width: 5.29 * 4, height: 5.29 * 4 }}/>
                                </span>
                                <span style={{ marginTop:'2%' }}>
                                    Members
                                </span>
                                                    
                                </div>
                                :
                                <div key={key} style={{ fontSize: 5.29 * 1.5 }} className="shd-new-scheduler-item-head shimmerHead shimAdj">
                                    Time 
                                </div>
                        )
                    })
                }
            </div>
            );
}

export const ScheduleBoxShimmer = ({zoomWidth=5.29, shimmerType='v'}) => {
    return(
        <tr style={{ height: '100%' }}>
            <td style={{ width: `${zoomWidth}vw` }}>
                {
                    shimmerType==='v' ?
                    <div className="shd-new-scheduler-item shimmerList">
                        Vehicle
                    </div>
                    :
                    shimmerType==='m' ?
                    <div className="shd-new-scheduler-item shimmerList">
                        Member
                    </div>
                    :
                    <div className="shd-new-scheduler-item shimmerList">
                        Time
                    </div>
                }
                
            </td>
        </tr>
    )
}

export const SchedulelistShimmer = ({total_column_length=20, zoomWidth=5.29, shimmerType='v', listShort=false}) =>{
    return(
           [...Array(listShort ? 3 : 10)].map((_, key) => {
                return (
                    <div key={key}
                        className="shd-new-scheduler-col-layout"
                        style={{
                            gridTemplateColumns: `80px repeat(${total_column_length? total_column_length : 20}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`
                        }}
                    >
                        <div key={key} style={{ height: '100%', position: 'sticky', left: 0, zIndex: 3 }} 
                        className="shd-new-scheduler-item shimmerList">
                            <p>Day</p>
                            <p>dd/MM/YYYY</p>
                        </div>
                        {
                            [...Array(total_column_length? total_column_length : 20)].map((_, key2) => {
                                return (
                                    <div key={key2} className="shd-new-scheduler-item-container">
                                        <table>
                                            <tbody style={{ height: '100%' }}>
                                                <ScheduleBoxShimmer zoomWidth={zoomWidth} shimmerType={shimmerType}/>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })
                        }
                        <div style={{ height: 20, width: '100%' }} />
                    </div>

                )
            })
    )
}