import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Sidebar from '../../../../common/sidebar'
import Navbar from '../common/VehicleNavBar'
import Images from '../common/VehicleImages'
import List from './CarListTable'
import AppHeader from '../common/Header'
import { fetch_car_list, __vehicle_modal, __vehicle_img_modal, __select_vehicle, __edit_vehicle, __create_vehicle, __edit_vehicle_multi_images } from './common/action'
import Maintanace from './maintanace'
import FuelExpense from './fuel'
import PreInspectionCheck from './preInspection/inspection_list'
import PreInspectionCreate from './preInspection/inspection_create'
import PreInspectionView from './preInspection/inspection_view'
import PreInspectionEdit from './preInspection/edit_preinspection'

function App({ 
    fetch_cars, 
    vehicle_modal_action, 
    vehicle_modal, 
    vehicle_img_modal_action, 
    vehicle_img_modal, 
    select_vehicle, 
    selected_car, 
    edit_vehicle, 
    edit_vehicle_image,
    create_vehicle,
    tabType,
    setTabType,
    permissions
}) {
   
    useEffect(() => {
        fetch_cars(tabType, 1)
        return () => fetch_cars.remove
    }, [fetch_cars, tabType])
    const [searchFnOpen, setSearchFnOpen] = useState(false)


    const { path } = useRouteMatch()

    const CarMain = () => {
        return (
            <>
            <div className="flex-row">
                <Navbar 
                    page={ tabType==='pumps' ? 'utes' : 'car' } 
                    modal_status={vehicle_modal}
                    modal_action={vehicle_modal_action}
                    img_modal_action={vehicle_img_modal_action}
                    img_modal_status={vehicle_img_modal}
                    select_vehicle={select_vehicle}
                    selected_vehicle={selected_car}
                    edit_vehicle={edit_vehicle}
                    edit_vehicle_image={edit_vehicle_image}
                    create_vehicle={create_vehicle}
                    // response_page={car_page}
                    tabType={tabType} 
                    permAdd={permissions.vehicle.add}
                />
                <Images page={ tabType==='pumps' ? 'utes' : 'car' } tabType={tabType} setSearchDataFx={setSearchFnOpen}
                    searchDataFx={searchFnOpen} />
            </div>
            <List 
                select_vehicle={select_vehicle} 
                modal_action={vehicle_modal_action}
                img_modal_action={vehicle_img_modal_action}
                fetch_cars={fetch_cars}
                tabType={tabType}
                perms={permissions} 
                searchDataFx={searchFnOpen}
            />
            </>
        )
    }

    return (
        <div className="App">
        <Sidebar />
        <div className="main-layout">
            <AppHeader page={ tabType==='pumps' ? 'utes' : 'car' } tabType={tabType} setTabType={setTabType} />
            <Switch>
                <Route path={ path + '/maintanace'}><Maintanace tabType={tabType} perms={permissions} /></Route>
                <Route path={ path + '/fuel-exp'}><FuelExpense tabType={tabType} perms={permissions}  /></Route>
                <Route path={ path + '/preinspection'}><PreInspectionCheck tabType={tabType} perms={permissions}  /></Route>
                <Route path={ path + '/preinspection-create'}><PreInspectionCreate  tabType={tabType}  /></Route>
                <Route path={ path + '/preinspection-detail'}><PreInspectionView  tabType={tabType}  /></Route>
                    <Route path={path + '/preinspection-edit'}><PreInspectionEdit tabType={tabType} /></Route>
                        
                <Route path={ path } component={CarMain} />
            </Switch>
        </div>
        </div>
    )
    
}


const mapStateToProps = (state) => {
    const { vehicle_modal,vehicle_img_modal, selected_car } = state.vehicle__car
    const {permissions} = state.userLogin
    return { vehicle_modal, vehicle_img_modal, selected_car, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_cars: (tabType, page, callBk=null) => dispatch(fetch_car_list(tabType, page, callBk)),
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        vehicle_img_modal_action: (status) => dispatch(__vehicle_img_modal(status)),
        select_vehicle: (car) => dispatch(__select_vehicle(car)),
        edit_vehicle: (tab, v_id, data, success, failed) => dispatch(__edit_vehicle(tab, v_id, data, success, failed)),
        edit_vehicle_image: (tab, v_id, content, page_no) => dispatch(__edit_vehicle_multi_images(tab, v_id, content, page_no)),
        create_vehicle: (tab, data, success, failed) => dispatch(__create_vehicle(tab, data, success, failed))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (App)
