import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Scrollbars from '../../../../common/ImprovedScrollBar';
import './sales_tableStyle.css';
import { cl_blue } from '../../../../common/Colors';
import { Cancel } from '@material-ui/icons';
import { TextAreaLimiter } from '../../../../common/TextAreaLimiter';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    MailCC,
    MailBCC,
    setMailCC,
    setMailBCC,
    resendFn,
    setMailBody,
    setMailSubj,
    mailBody,
    mailSubj
                
}) {

    const classes = useStyles();
    const [MainCCText, setMainCCText] = useState('')
    const [MainBCCText, setMainBCCText] = useState('')
    const [ccErrText, setCCErrText] = useState(false)
    const [bccErrText, setBCCErrText] = useState(false)
    const [textAreaValid, setTextAreaValid] = useState(true)
    const handleClose = () => {
        setMailBody('')
        setMailSubj('')
        modalAction({ status : false, qObj : null, qK : null})
    };


    const add_item_to_cc = () => {
        if(MainCCText) {
            let chek_already_added = MailCC.find(item => item === MainCCText)
            console.log('added check', chek_already_added)
            if(!chek_already_added) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (pattern.test(MainCCText)) {
                    setMailCC(item => [...item, MainCCText])
                    setMainCCText('')
                    setCCErrText(false)
                } else {
                    setCCErrText(true)
                }
            }
        } 
    }

    const delete_item_to_cc = (txt) => {
            let updatedArr = MailCC.filter(item => item !== txt)
            setMailCC(updatedArr)
    }

    const add_item_to_bcc = () => {
        if(MainBCCText) {
            let chek_already_added = MailBCC.find(item => item === MainBCCText)
            console.log('added check', chek_already_added)
            if(chek_already_added) {

            } else {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (pattern.test(MainBCCText)) {
                    setMailBCC(item => [...item, MainBCCText])
                    setMainBCCText('')
                    setBCCErrText(false)
                } else {
                    setBCCErrText(true)
                }
            }
        } 
    }

    const delete_item_to_bcc = (txt) => {
            let updatedArr = MailBCC.filter(item => item !== txt)
            setMailBCC(updatedArr)
    }

    // console.log(quoteInfos)

    return (
    <Modal
        className={classes.modal}
        open={modalStatus.status}
        onClose={handleClose}
    >
        <div className="jobCardLayout" style={{ overflowY:'auto', width:'70vw' }}>
            <div className="header-button-layout" style={{ justifyContent:'space-between'}}
             onClick={()=>  handleClose() }
            >
                <h1 style={{ margin: '10px' }}>Re-Send Mail</h1>
                <div className="header-button-top">
                    Back
                </div>
                
            </div>
            <div className="jobCardContentDiv">

                <Scrollbars style={{ height:'65vh' }}>
                    <div className="jobCardDiv1">
                        <div className="jobCardDivHead">Add Mail Body</div>
                        <div className="resendMailContent">
                            <div className="resendMailId">
                                <span>To (CC):</span>
                                <span style={{ width:'90%', height:'100%' }}>
                                    
                                    <input type="text"  style={{ width:'100%', height:'100%',
                                        textDecoration : ccErrText ? 'underline' : 'none',
                                        textDecorationStyle : 'wavy',
                                        textDecorationColor : ccErrText ? 'red' : 'transparent',
                                        textDecorationThickness: '3px'      
                                    }}
                                        placeholder="Enter email"
                                        value={MainCCText}
                                        onChange={txt =>{ setCCErrText(false); setMainCCText(txt.target.value); }}
                                        onKeyUp={(e)=>{ if(e.key==="Enter" && MainCCText) {  setCCErrText(false);  add_item_to_cc(); } }}
                                    />
                                </span>
                            </div>
                            <div className="resendMailIdBtns">
                                    {
                                        MainCCText ? (
                                            <button 
                                                onClick={() => add_item_to_cc()}
                                                style={{ width: 100, height:'3vh',
                                                 backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} >Add</button>
                                        ) : (
                                            <button 
                                                style={{ width: 100, height:'3vh',
                                                backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} className="disabled-btn" >Add</button>
                                        )
                                    }
                            </div>
                            <div className="resend-emails-layout">
                    {
                        MailCC.map((item, key) => {
                            return (
                                <div key={key} style={{ position:'relative' }}>
                                    <Cancel onClick={() => delete_item_to_cc(item)} style={{ position: 'absolute', fontSize: 18, cursor: 'pointer' }}/>
                                    <p className="resend-emails" key={key}>{item}</p>
                                </div>
                                
                            )
                        })
                    }
                </div>
                <div className="resendMailId">
                                <span>To (BCC):</span>
                                <span style={{ width:'90%', height:'100%' }}>
                                    
                                    <input type="text"  style={{ width:'100%', height:'100%',
                                        textDecoration : bccErrText ? 'underline' : 'none',
                                        textDecorationStyle : 'wavy',
                                        textDecorationColor : bccErrText ? 'red' : 'transparent',
                                        textDecorationThickness: '3px'
                                    }}
                                        placeholder="Enter email"
                                        value={MainBCCText}
                                        onChange={txt =>{ setBCCErrText(false); setMainBCCText(txt.target.value); }}
                                        onKeyUp={(e)=>{ if(e.key==="Enter" && MainBCCText) { setBCCErrText(false);  add_item_to_bcc(); } }}
                                    />
                                </span>
                            </div>
                            <div className="resendMailIdBtns">
                                    {
                                        MainBCCText ? (
                                            <button 
                                                onClick={() => add_item_to_bcc()}
                                                style={{ width: 100, height:'3vh',
                                                 backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} >Add</button>
                                        ) : (
                                            <button 
                                                style={{ width: 100, height:'3vh',
                                                backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} className="disabled-btn" >Add</button>
                                        )
                                    }
                            </div>
                            <div className="resend-emails-layout">
                    {
                        MailBCC.map((item, key) => {
                            return (
                                <div key={key} style={{ position:'relative' }}>
                                    <Cancel onClick={() => delete_item_to_bcc(item)} style={{ position: 'absolute', fontSize: 18, cursor: 'pointer' }}/>
                                    <p className="resend-emails" key={key}>{item}</p>
                                </div>
                                
                            )
                        })
                    }
                </div>
                            <div className="resendMailSubject">
                                <span>Subject:</span>
                                <span style={{ width:'90%', height:'100%' }}>
                                    <input type="text"  style={{ width:'100%', height:'100%', padding:'2px' }}
                                        placeholder="Add new Subject, if kept empty old subject will be sent in email."
                                        value={mailSubj}
                                        onChange={(e)=> setMailSubj(e.target.value) }
                                    />
                                </span>
                            </div>
                            <div className="resendMailBody">
                                <TextAreaLimiter isValid={setTextAreaValid}
                                divBodyClass={"textAreaDivBody"}
                                configs={{
                                    style : { resize:'none', fontFamily:'sans-serif' },
                                    placeholder : "Add body...",
                                    value : mailBody,
                                    className : 'resendMailBodyTextArea',
                                    onChange : (e)=> setMailBody(e.target.value) 
                                }}
                                max={1000}
                                v={mailBody} />
                            </div>
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%' }}>
                                <div className="header-button-top newJobCardBtnActive"
                                    onClick={()=>{
                                        if(modalStatus.qObj!==null && textAreaValid){
                                            resendFn(modalStatus.qObj.id, modalStatus.qK, mailSubj, mailBody)
                                            
                                            handleClose();
                                        }
                                    }}
                                >
                                    Resend Mail
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Scrollbars>
            </div>
        </div>
    </Modal>
  );
}

 export default TransitionsModal
 
