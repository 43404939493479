import { AddCircle, CheckCircle, DirectionsBusRounded, StarsRounded } from '@material-ui/icons'
import React from 'react'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core';
// import { HOST } from '../../../store/endpoint'

function TeamLayout({ employee_listDateTime, selectedArr, setSelectedArr, additionalVehDriver=[], primeVehicleDriver=null}) {
    return (
        <div className="shdl-modal-center-lay">

            <div style={{ height: '58vh' }} className="shdl-modal-center-x-layout">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p>Team</p>
                    <AddCircle 
                        style={{ fontSize: 22, cursor: 'pointer' }} 
                    />
                </div>
                
                <Scrollbars autoHide style={{ height: '53vh' }}>
                <div className="shdl-team-lay">

                {
                    employee_listDateTime.map((item, key) => {
                        const { id, name, dp, is_available, terminated_status } = item
                        let urlll =  dp
                         if(!terminated_status) 
                            return (
                                <Tooltip 
                                    followCursor
                                    title={
                                    <div style={{ padding:'.5%', textAlign:'center', width:'auto', wordWrap:'normal', whiteSpace:'normal' }}>
                                        <p style={{ fontSize:'.9rem', wordBreak:'keep-all'}}>{name}</p>
                                    </div>
                                    } 
                                    placement='top'>
                                <div 
                                    key={key}
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                        if(is_available && !terminated_status){
                                            if(selectedArr.find(itmzz => itmzz === id)) {
                                                let removed = selectedArr.filter(itemzx => itemzx !== id)
                                                setSelectedArr(removed)
                                            } else if(!additionalVehDriver.find(itmzz => itmzz.driver_id === id || itmzz.id===id) && primeVehicleDriver!==id ){
                                                setSelectedArr(itm => [...itm, id])
                                            }
                                        }
                                    }}
                                >
                                    {
                                        terminated_status ? 
                                        <p style={{ textAlign: 'center', fontWeight: 400, fontSize: 12 }}>Terminated</p>
                                        :null
                                    }
                                    {
                                        selectedArr.find(itmzz => itmzz === id) && (
                                        <div style={{ position: 'absolute', marginLeft: 50 }}>
                                            <CheckCircle style={{ cursor: 'pointer' }} />
                                        </div>
                                        )
                                    }
                                    {
                                        additionalVehDriver.find(itmzz => itmzz.driver_id === id || itmzz.id===id) && (
                                        <div style={{ position: 'absolute', marginLeft: 50, borderRadius:'50%', backgroundColor:'black', display:"flex",
                                        alignItems:'center', justifyContent:'center', padding:'1%' }}>
                                            <DirectionsBusRounded style={{ cursor: 'default', color:'white', fontSize:'1rem' }} />
                                        </div>
                                        )
                                    }
                                    {
                                        (primeVehicleDriver === id) && (
                                        <div style={{ position: 'absolute', marginLeft: 50 }}>
                                            <StarsRounded style={{ cursor: 'default' }} />
                                        </div>
                                        )
                                    }
                                
                                <div key={key} className={is_available && !terminated_status ? "shdl-single-team-circle" : "shdl-single-team-circle inactiveCircle"}>
                                    <img src={urlll} alt="" />
                                </div>
                                <p style={{ textAlign: 'center', fontWeight: 400, fontSize: 12, width:'98%', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap' }}>{name}</p>
                                </div>
                                </Tooltip>
                            )
                        else return null
                        // } else return null
                       
                    })
                }
                {
                    employee_listDateTime.length === 0 && (
                        <div style={{ width: 200 }}>
                            <p style={{ fontWeight: 400, fontSize: 12 }}>Set a Team for the job</p>
                        </div>
                    )
                }
                </div>
                </Scrollbars>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { selected_team, employee_listDateTime } = state.schedule
    return { selected_team, employee_listDateTime }
}

export default connect(mapStateToProps) (TeamLayout)
