import React from 'react'

import Sidebar from '../../common/sidebar'
import '../intranet/components/style.css'
import Header from '../intranet/components/Header'
import CompletedJobsList from './CompletedJobsList';

function Invoice() {

    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='main'/>
                
                <div className="accountsTableDiv">
                    <p style={{ fontSize: '1.5rem', padding: '.2%', fontWeight : 'bold' }}>Completed Scheduled Jobs</p>
                    <CompletedJobsList />
                </div>
            </div>
        </div>
    )
}

export default Invoice
