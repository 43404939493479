import { SCHEDULE_ADD_IMAGE, SCHEDULE_ADD_JOB, SCHEDULE_ADD_TEAM, SCHEDULE_ADD_VEHICLE, SCHEDULE_JOB_LIST_COUNT_URL,
    SCHEDULE_AVAILABILITY, SCHEDULE_DELETE_JOB, SCHEDULE_EDIT_JOB, SCHEDULE_JOBS, SCHEDULE_SCHEDULE_LIST, SCHEDULE_SCHEDULE_MEM_LIST,
    SALES_JOB_ReSCHD, PENDING_SCHD_JOBS, SCHEDULE_ADDITIONAL_VEHICLE, SCHEDULE_ADD_SIGNATURE_IMAGE, REOCURING_SCHD_JOBS, REOCURRING_SCHD_JOBS_FILTER, 
    REOCURING_JOB_FREQUENCY, SCHEDULE_DUMMY_ADD_JOB, SCHEDULE_DELETE_JOBLIST_JOB, SCHEDULE_RESCHEDULE_COMPLETED, SCHEDULE_NORMAL_JOBS, 
    SCHEDULE_REOCCURRING_JOBS, SCHEDULE_OUTSOURCE_JOBS, SCHEDULE_ADD_OUTJOB, 
    //SCHEDULE_SCHEDULE_OUTSOURCED_LIST, 
    SALES_JOB_ReSCHD_ALL, 
    // SCHEDULE_REOCCURRING_JOBS_ALL, SCHEDULE_OUTSOURCE_JOBS_ALL, 
    SCHEDULE_NORMAL_JOBS_ALL, SCHEDULE_JOB_LIST_COUNT_URL_ALL, SCHEDULE_JOB_LIST_PRINT_NEW, SCHEDULE_COMPLETED_JOB, SCHEDULE_ADD_VIDEO, SCHEDULE_EDIT_AFTERJOBCOMPLETE, SCHEDULE_CAL_NEW_JOB_VIEW, SCHEDULE_CAL_NEW_OUT_JOB_VIEW, SCHEDULE_JOB_COMMENT_VALIDATION, 
    SCHEDULE_JOB_CARD_CERT_OF_DEST_VALIDATION} from "../../../store/endpoint"
import Api from '../../../store/api'
import moment from "moment"
import { store } from "../../../store/store"
import axios from "axios"
import { FileDownloader } from "../../../common/file_downloader"
import Swal from "sweetalert2"
// import { FileDownloader } from "../../../common/file_downloader"
// import Swal from "sweetalert2"

export const fetch_all_jobLists_counts = () =>{
    return function (dispatch){
        let url = SCHEDULE_JOB_LIST_COUNT_URL
        Api('get', url).then(response => {
            console.log('countRes',response)
                dispatch({ type: 'JOB_LIST_COUNT', count: response })
        })
    }
}

export const fetch_job_list = (type, page_no) => {
    return function (dispatch) {
       let url =  `${SCHEDULE_JOBS}${type}/?page=${page_no}&limit=10`
        Api('get', url).then(response => {
            if(response.data ? response.data.length > 0 : response.length > 0) {
                dispatch({ type: 'JOB_LIST_SCHEDULE', jobs: response.data ? response.data : response, page: page_no })
            } else {
                if(page_no === 1) {
                    dispatch({ type: 'JOB_LIST_SCHEDULE', jobs: response.data ? response.data : response, page: 1 })
                } else {
                    dispatch({ type: 'JOB_LIST_SCHEDULE', jobs: response.data ? response.data : response })
                }
                
            }
            dispatch(fetch_all_jobLists_counts())
        })
    }
}

export const fetch_job_list_readyForSchd = (type, page_no, filterData, jobType=null, callBk=null) => {
    return function (dispatch) {
        if(callBk!==null){
            callBk(true)
        }
       let url =  jobType==='del' ? `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=40&${filterData}` :
       jobType === 'rec' ? `${SCHEDULE_REOCCURRING_JOBS}${type}/?page=${page_no}&limit=40&${filterData}` :
       jobType === 'out' ? `${SCHEDULE_OUTSOURCE_JOBS}${type}/?page=${page_no}&limit=40&${filterData}` :
       `${SCHEDULE_NORMAL_JOBS}${type}/?page=${page_no}&limit=40&${filterData}` 
       

       let frm = moment(filterData.substring(10,20));
       let tod = moment(filterData.substring(30,40));
       let filterVal = moment.duration(tod.diff(frm)).asWeeks();
        Api('get', url).then(response => {
            console.log("SCHD-rdy: ",url,response)
            if(callBk!==null){
                callBk(false)
            }
            if(response.data ? response.data.length > 0 : response.length > 0) {

                dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd', limitVal: 40, jobs: response.data ? response.data : response, page: page_no, filterVal: filterVal, jobType: jobType })
            } else {
                if(page_no === 1) {
                    dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd', limitVal: 40, jobs: response.data ? response.data : response, page: 1, filterVal: filterVal, jobType: jobType })
                } else {
                    dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd', limitVal: 40, jobs: response.data ? response.data : response, filterVal: filterVal, jobType: jobType })
                }
                
            }
            dispatch(fetch_all_jobLists_counts())
        })
    }
}

export const fetch_job_list_RFSchd_append_pagination = (type, page_no, prevData,filterData,jobType) => {
    return function (dispatch) {
        dispatch({type:'JOB_LIST_SCHEDULE_readyForSchd_FETCHING'})
    //    let url =  `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=20&${filterData}`

       let url =  jobType==='del' ? `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=40&${filterData}` :
       jobType === 'rec' ? `${SCHEDULE_REOCCURRING_JOBS}${type}/?page=${page_no}&limit=40&${filterData}` :
       jobType === 'out' ? `${SCHEDULE_OUTSOURCE_JOBS}${type}/?page=${page_no}&limit=40&${filterData}` :
       `${SCHEDULE_NORMAL_JOBS}${type}/?page=${page_no}&limit=40&${filterData}`

       let frm = moment(filterData.substring(10,20));
       let tod = moment(filterData.substring(30,40));
       let filterVal = moment.duration(tod.diff(frm)).asWeeks();
        Api('get', url).then(response => {
            // if(response.length > 0 ) {
                // let finalData = prevData ? prevData.concat(response) : response
                dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd', limitVal: 40, jobs: response.data ? response.data : response, page: page_no, filterVal: filterVal, jobType: jobType })
            // } 
            dispatch(fetch_all_jobLists_counts())
        })
    }
}

export const fetch_job_list_RFSchdSearch_append_pagination = (type, page_no, prevData,filterData, txt) => {
    return function (dispatch) {
        dispatch({type:'JOB_LIST_SCHEDULE_readyForSchd_FETCHING'})
       let url =  `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=40&${filterData}`
       let frm = moment(filterData.substring(10,20));
       let tod = moment(filterData.substring(30,40));
       let filterVal = moment.duration(tod.diff(frm)).asWeeks();

            let state = store.getState()
            let token = state.userLogin.authToken
            var config = 
            {
                method: 'get',
                url: url,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                params: {
                    key: txt
                }
            }
            axios(config)
            .then((res) => {
                dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd', limitVal: 40, jobs: 
                res.data.data ? res.data.data : res.data, 
                page: page_no, filterVal: filterVal })
                if(res.data.count){
                    dispatch({ type: 'JOB_LIST_COUNT', count: res.data.count })
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const fetch_job_pending_schd = (type, page_no, prevData) =>{
    return function (dispatch) {
        let url = `${PENDING_SCHD_JOBS}${type}/?page=${page_no}&limit=10`
        Api('get',url).then(response => {
            console.log(response)
            if(response.data ? response.data.length > 0 : response.length > 0){
                let finalData = prevData && prevData.length!==0 ? 
                prevData.concat(response.data ? response.data : response) : response.data ? response.data : response
                dispatch({
                    type: 'JOB_LIST_SCHD_PENDING',
                    jobs:  finalData,
                    page:   page_no
                })
            }
            dispatch(fetch_all_jobLists_counts())
        })
    }
}

export const fetch_job_reoccur_schd = (type, page_no, prevData, frmD) =>{
    return function (dispatch) {
        let url = ``;
        if(frmD!==null)
            url = `${REOCURRING_SCHD_JOBS_FILTER}${type}/${page_no}/?limit=10&${frmD}`
        else 
            url = `${REOCURING_SCHD_JOBS}`
        //${type}/?page=${page_no}&limit=10`
        Api('get',url).then(response => {
            console.log(response)
            if(response.data ? response.data.length > 0 : response.length > 0){
                let finalData = prevData && prevData.length!==0 ?
                 prevData.concat(response.data ? response.data : response) : response.data ? response.data : response
                dispatch({
                    type: 'JOB_LIST_SCHD_REOCCUR',
                    jobs:  finalData,
                    page:   page_no
                })
            }
            dispatch(fetch_all_jobLists_counts())
        })
    }
}

export const updateJobReoccurList = (type, id, key, success, failed) => {
    return function (dispatch) {
        let url = `${REOCURING_SCHD_JOBS}${type}/recurring/${id}/?key=${key}`;
        Api('get', url)
        .then(response => {
            if(response.data_data)
                success(response.data_data)
            else 
                failed()
        //     dispatch(fetch_job_reoccur_schd(type, 1, [], null))
        })
    }
}

export const updateJobReoccurFrqList = ( id, content, success, failed) =>{
    return function (){
        let url = `${REOCURING_JOB_FREQUENCY}${id}/`;
        Api('post-form', url, content, success, failed)
        .then(response => {
            // console.log(response)
            if(response.app_data)
                success(response.app_data)
            else 
                failed()
        //     dispatch(fetch_job_reoccur_schd(type, 1, [], null))
        })
    }
}


export const fetch_job_list_append_pagination = (type, page_no, prevData,) => {
    return function (dispatch) {
       let url =  `${SCHEDULE_JOBS}${type}/?page=${page_no}&limit=10`
        Api('get', url).then(response => {
            if(response.data ? response.data.length > 0 : response.length > 0) {
                let finalData = prevData ? prevData.concat(response.data ? response.data : response) : response.data ? response.data : response
                dispatch({ type: 'JOB_LIST_SCHEDULE', jobs: finalData, page: page_no })
            } 
        })
    }
}

export const fetch_avilabilities = () => {
    return function (dispatch) {
        Api('get', SCHEDULE_AVAILABILITY).then(response => {
            dispatch({ type: 'EMPLOYEE_VEHICLES', employees: response.team, vehicles: response.vehicle })
        })
    }
}

export const fetch_Employee_avilabilities = (filter, callBackFn=null) => {
    return function (dispatch) {
        dispatch({
            type : 'EMPL_AVAIL_LOADER',
            loader : true
        })
        if(callBackFn!==null)
            callBackFn(true)
        Api('get', `${SCHEDULE_AVAILABILITY}team/?${filter}`).then(response => {
            console.log(response)
            if(callBackFn!==null)
                callBackFn(false)
            dispatch({ type: 'EMPLOYEE_DATE_TIME_LISTING', employees: response, filter: filter })
        })
    }
}

export const fetch_Vehicle_avilabilities = (filter, tabType, callBackFn=null) => {
    return function (dispatch) {
        dispatch({
            type : 'VEHICLE_AVAIL_LOADER',
            loader : true
        })
        if(callBackFn!==null)
            callBackFn(true)
        Api('get',  `${SCHEDULE_AVAILABILITY}vehicle/${tabType}/?${filter}`).then(response => {
            console.log(response)
            if(callBackFn!==null)
                callBackFn(false)
            dispatch({ type: 'VEHICLES_DATE_TIME_LISTING', vehicles: response?.filter(el => el.vehicle_type==='truck'), filter: filter })
        })
    }
}


export const fetch_schedules = (type, page, success, prevData, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }

        let url = `${SCHEDULE_CAL_NEW_JOB_VIEW}?limit=600&from=${moment().format('YYYY-MM-DD')}&&to=${moment().add(10,'day').format('YYYY-MM-DD')}`
        // let url = ''
        // if(page===1)
        //     url =  `${SCHEDULE_SCHEDULE_LIST}${type}/?limit=10`
        // else
        //     url =  `${SCHEDULE_SCHEDULE_LIST}${type}/?from=${page}`
        Api('get', url).then(response => {
            console.log("SCHEDULE-LIST",url,response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response) {
                if(page===1){
                    dispatch({ type: 'SCHEDULES_LIST', schedules: response, loadAct: false, 
                    strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
                }else{
                    let finalData = prevData ? prevData.concat(response) : response
               dispatch({ type: 'SCHEDULES_LIST', schedules: finalData, loadAct: true, 
                strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
                }
               if(success) {
                success()
               }
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_LIST', schedules: response, loadAct: false, 
                        strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
                } else {
                    dispatch({ type: 'SCHEDULES_LIST', loadAct: false , 
                        strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD') })
                }
                
            }
        })
    }
}

export const fetch_Mem_schedules = (type, page, success, prevData, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }
        let url = `${SCHEDULE_CAL_NEW_JOB_VIEW}?limit=600&from=${moment().format('YYYY-MM-DD')}&&to=${moment().add(10,'day').format('YYYY-MM-DD')}`
        // if(page===1)
        //     url =  `${SCHEDULE_SCHEDULE_MEM_LIST}${type}/?limit=10`
        // else
        //     url =  `${SCHEDULE_SCHEDULE_MEM_LIST}${type}/?from=${page}`
        Api('get', url).then(response => {
            console.log("SCHEDULE-LIST",url,response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response) {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_MEM_LIST', schedules: response, loadAct: false, 
                    strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
                } else {
                let finalData = prevData ? prevData.concat(response) : response
               dispatch({ type: 'SCHEDULES_MEM_LIST', schedules: finalData, loadAct: true, 
               strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
            }
               if(success) {
                success()
               }
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_MEM_LIST', schedules: response, loadAct: false, 
                    strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
                } else {
                    dispatch({ type: 'SCHEDULES_MEM_LIST', loadAct: false , 
                    strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD') })
                }
                
            }
        })
    }
}


export const fetch_schedules_sorted = (type, page, filter, prevData, StrDate, EndDate, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }
        console.log(page)
        let formattedFilter = ''
        if(filter!==''){
            formattedFilter = `&from=${moment(StrDate).format('YYYY-MM-DD')}&&to=${moment(EndDate).format('YYYY-MM-DD')}`
        }
        let url =  `${SCHEDULE_SCHEDULE_LIST}${type}/?limit=600&${formattedFilter}`
        
        Api('get', url).then(response => {
            console.log("SCH-SORT",response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response) {
                
                let finalData = prevData ? prevData.concat(response) : response
               dispatch({ type: 'SCHEDULES_LIST', schedules: finalData, page: page, loadAct: true, strD: StrDate, endD: EndDate })
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_LIST', schedules: response, page: 1, loadAct: false, strD: StrDate, endD: EndDate })
                } else {
                    dispatch({ type: 'SCHEDULES_LIST', loadAct: false, strD: StrDate, endD: EndDate })
                }
                
            }
        })
    }
}

export const fetch_schedules_sorted_NewMode = (type, page, filter, prevData, StrDate, EndDate, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }
        console.log(page)
        let formattedFilter = ''
        if(filter!==''){
            formattedFilter = `&from=${moment(StrDate).format('YYYY-MM-DD')}&&to=${moment(EndDate).format('YYYY-MM-DD')}`
        }
        let url =  `${SCHEDULE_CAL_NEW_JOB_VIEW}?limit=600&${formattedFilter}`
        
        Api('get', url).then(response => {
            console.log("SCH-SORT",response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response) {
                let finalData = prevData ? prevData.concat(response) : response
               dispatch({ type: 'SCHEDULES_LIST', schedules: finalData, page: page, loadAct: true, strD: StrDate, endD: EndDate })
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_LIST', schedules: response, page: 1, loadAct: false, strD: StrDate, endD: EndDate })
                } else {
                    dispatch({ type: 'SCHEDULES_LIST', loadAct: false, strD: StrDate, endD: EndDate })
                }
                
            }
        }).catch(err => {
            console.log(err)
            dispatch({ type: 'SCHEDULES_LIST', schedules: prevData ? prevData : [], page: page, loadAct: true, strD: StrDate, endD: EndDate })
        })
    }
}

export const fetch_Mem_schedules_sorted = (type, page, filter, prevData, StrDate, EndDate, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }
        console.log(page)
        let formattedFilter = ''
        if(filter!==''){
            formattedFilter = `&from=${moment(StrDate).format('YYYY-MM-DD')}&&to=${moment(EndDate).format('YYYY-MM-DD')}`
        }
        let url =  `${SCHEDULE_SCHEDULE_MEM_LIST}${type}/?limit=20&${formattedFilter}`
        
        Api('get', url).then(response => {
            console.log("SCH-SORT",response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response && response.schedules) {
                let finalData = prevData ? prevData.concat(response.schedules) : response.schedules
               dispatch({ type: 'SCHEDULES_MEM_LIST', schedules: finalData, page: page, loadAct: true, strD: StrDate, endD: EndDate })
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_MEM_LIST', schedules: response.schedules, page: 1, loadAct: false, strD: StrDate, endD: EndDate })
                } else {
                    dispatch({ type: 'SCHEDULES_MEM_LIST', loadAct: false, strD: StrDate, endD: EndDate })
                }
                
            }
        })
    }
}



export const fetch_OutSourced_schedules = (type, page, success, prevData, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }
        let url = `${SCHEDULE_CAL_NEW_OUT_JOB_VIEW}?limit=600&from=${moment().format('YYYY-MM-DD')}&&to=${moment().add(10,'day').format('YYYY-MM-DD')}`
        // if(page===1)
        //     url =  `${SCHEDULE_SCHEDULE_OUTSOURCED_LIST}${type}/?limit=10`
        // else
        //     url =  `${SCHEDULE_SCHEDULE_OUTSOURCED_LIST}${type}/?from=${page}`
        Api('get', url).then(response => {
            console.log("SCHEDULE-LIST",url,response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response && response.schedules) {

                let finalData = prevData ? prevData.concat(response.schedules) : response.schedules
               dispatch({ type: 'SCHEDULES_OUTSOURCED_LIST', schedules: finalData, loadAct: true, 
                strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
               if(success) {
                success()
               }
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_OUTSOURCED_LIST', schedules: response.schedules, loadAct: false, 
                        strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD')  })
                } else {
                    dispatch({ type: 'SCHEDULES_OUTSOURCED_LIST', loadAct: false , 
                        strD: moment().format('YYYY-MM-DD'), endD: moment().add(10,'day').format('YYYY-MM-DD') })
                }
                
            }
        })
    }
}


export const fetch_OutSourced_schedules_sorted = (type, page, filter, prevData, StrDate, EndDate, fallBackFn=null) => {
    return function (dispatch) {
        if(fallBackFn!==null){
            fallBackFn(true)
        }
        console.log(page)
        let formattedFilter = ''
        if(filter!==''){
            formattedFilter = `&from=${moment(StrDate).format('YYYY-MM-DD')}&&to=${moment(EndDate).format('YYYY-MM-DD')}`
        }
        let url =  `${SCHEDULE_CAL_NEW_OUT_JOB_VIEW}?limit=600&${formattedFilter}`
        
        Api('get', url).then(response => {
            console.log("SCH-SORT",response)
            if(fallBackFn!==null){
                fallBackFn(false)
            }
            if(response && response.schedules) {
                let finalData = prevData ? prevData.concat(response.schedules) : response.schedules
               dispatch({ type: 'SCHEDULES_OUTSOURCED_LIST', schedules: finalData, page: page, loadAct: true, strD: StrDate, endD: EndDate })
            } else {
                if(page === 1) {
                    dispatch({ type: 'SCHEDULES_OUTSOURCED_LIST', schedules: response.schedules, page: 1, loadAct: false, strD: StrDate, endD: EndDate })
                } else {
                    dispatch({ type: 'SCHEDULES_OUTSOURCED_LIST', loadAct: false, strD: StrDate, endD: EndDate })
                }
                
            }
        })
    }
}





export const schedule_job = (type, data, jobType, schType=null, isCompletedJob, success, failed) => {
    return function (dispatch) {
        // for(let v of data.entries())
        //     console.log(v[0],':',v[1],typeof v[1])
        console.log('NEW JOB SCHEDULED:',data)
        const success_fx = (res) => {
            console.log('res res res res', res)
            if(schType===null)
                dispatch(fetch_schedules(type, 1))
            else 
                dispatch(fetch_Mem_schedules(type, 1))
            dispatch(fetch_avilabilities())
            success(res)
        }
        Api(
            isCompletedJob ? 'post-form' : 'post', 
        isCompletedJob ?
            SCHEDULE_COMPLETED_JOB
        :
        jobType===null ? 
            SCHEDULE_ADD_JOB : 
            typeof jobType!=='object' ? `${SCHEDULE_DUMMY_ADD_JOB}${jobType}/` : 
            `${SCHEDULE_RESCHEDULE_COMPLETED}${jobType.id}/`, 
            
            data, success_fx, failed ).then(res => {
            console.log(res)
        }).catch(err => {
            failed()
        })
    }
}

export const schedule_job_edit = (type, data, schType=null, success, failed) => {
    return function (dispatch) {
        // const failedEditAfter = (err) =>{
        //     Swal.fire({
        //         icon: 'error',
        //         position: 'top-end',
        //         timer: 10000,
        //         timerProgressBar: true,
        //         title: 'Oops...',
        //         text: err.app_data ? err.app_data : 'Something went wrong!'
        //     })
        // }
        const success_fx = (res) => {
        if(schType===null){
            if(data && data.start_date){
                let fDate = moment(data.start_date).format('YYYY-MM-DD')
                let tDate = moment(data.start_date).add(10,'day').format('YYYY-MM-DD')
                let filter = `from=${fDate}&to=${tDate}`
                dispatch(fetch_schedules_sorted_NewMode(type, 1, filter, [], fDate, tDate))
            }else
                dispatch(fetch_schedules(type, 1))
        }else{ 
            if(data && data.start_date){
                let fDate = moment(data.start_date).format('YYYY-MM-DD')
                let tDate = moment(data.start_date).add(10,'day').format('YYYY-MM-DD')
                let filter = `from=${fDate}&to=${tDate}`
                dispatch(fetch_Mem_schedules_sorted(type, 1, filter, [], fDate, tDate))
            }else
                dispatch(fetch_Mem_schedules(type, 1))
        }
            dispatch(fetch_avilabilities())
            success(res)
        }
        
        
        console.log(type, data)
        
        Api('put-json', SCHEDULE_EDIT_JOB, data, success_fx, failed )
    }
}


export const schedule_Out_job = (type, data, jobType, schType=null, success, failed) => {
    return function (dispatch) {
        // for(let v of data.entries())
        //     console.log(v[0],':',v[1],typeof v[1])
        console.log('NEW JOB SCHEDULED:',data)
        const success_fx = (res) => {
            console.log('res res res res', res)
            if(schType===null)
                dispatch(fetch_schedules(type, 1))
            else 
                dispatch(fetch_Mem_schedules(type, 1))
            dispatch(fetch_avilabilities())
            success(res)
        }
        Api('post', SCHEDULE_ADD_OUTJOB, data, success_fx, failed ).then(res => {
            console.log(res)
        })
    }
}

export const schedule_Out_job_edit = (type, data, id, success, failed) => {
    return function (dispatch) {
        const success_fx = (res) => {
            dispatch(fetch_schedules(type, 1))
            dispatch(fetch_Mem_schedules(type, 1))
            dispatch(fetch_avilabilities())
            success(res)
        }
        console.log(type, data)
        Api('put-json', `${SCHEDULE_ADD_OUTJOB}update/${id}/`, data, success_fx, failed )
    }
}


export const schedule_job_delete = (type, data, schType=null, success, failed) => {
    return function (dispatch) {
        const success_fx = (res) => {
            if(schType===null)
            dispatch(fetch_schedules(type, 1))
        else 
            dispatch(fetch_Mem_schedules(type, 1))
                    dispatch(fetch_avilabilities())
            success(res)
        }
        let url_delete = SCHEDULE_DELETE_JOB + data + '/';
        Api('delete', url_delete, data, success_fx, failed )
    }
}


export const add_team_to_job = (type, data, schType=null, success, failed) => {
    return function (dispatch) {
        const success_fx = (res) => {
            if(schType===null)
            dispatch(fetch_schedules(type, 1))
        else 
            dispatch(fetch_Mem_schedules(type, 1))
                    success(res)
        }
        Api('post', SCHEDULE_ADD_TEAM, data, success_fx, failed )
    }
}


export const add_vehicle_to_job = (type, data, prevData, shId, schType=null, success, failed) => {
     for(var i of data.entries())
        console.log(i[0],i[1])

    return function (dispatch) {
        const success_fx = (res) => {
            if(schType===null)
            dispatch(fetch_schedules(type, 1))
        else 
            dispatch(fetch_Mem_schedules(type, 1))
                    success(res)
        }
        Api('post', SCHEDULE_ADD_VEHICLE, prevData, success_fx, failed )
        if(shId)
            Api('post-form', `${SCHEDULE_ADDITIONAL_VEHICLE}${shId}/`, data, success_fx, failed )         
        
    }
}


export const schedule_image_add = (type, data, schType=null, success=null, failed=null) => {
    return function (dispatch) {
        const success_fx = (res) => {
        //     if(schType===null)
        //     dispatch(fetch_schedules(type, 1))
        // else 
        //     dispatch(fetch_Mem_schedules(type, 1))
                    if(success)
                        success(res)
        }
        const failedFx = (err) =>{
            if(failed)
                failed(err)
        }
        Api('post-form', SCHEDULE_ADD_IMAGE, data, success_fx, failedFx )
    }
}

export const schedule_video_add = (type, data, schType=null, success=null, failed=null) => {
    return function (dispatch) {
        const success_fx = (res) => {
        //     if(schType===null)
        //     dispatch(fetch_schedules(type, 1))
        // else 
        //     dispatch(fetch_Mem_schedules(type, 1))
                    if(success)
                        success(res)
        }
        const failedFx = (err) =>{
            if(failed)
                failed(err)
        }
        Api('post-form', SCHEDULE_ADD_VIDEO, data, success_fx, failedFx )
    }
}

export const schedule_imageSign_add = (type, data, schType=null, success=null, failed=null) => {
    return function (dispatch) {
        const success_fx = (res) => {
        //     if(schType===null)
        //     dispatch(fetch_schedules(type, 1))
        // else 
        //     dispatch(fetch_Mem_schedules(type, 1))
                    if(success)
                        success(res)
        }
        const failedFx = (err) =>{
            if(failed)
                failed(err)
        }
        Api('post-form', SCHEDULE_ADD_SIGNATURE_IMAGE, data, success_fx, failedFx )
    }
}

export const delete_job_joblist = (type, id, jobType, param, success, failed) =>{
    return function (dispatch) {
        
        Api('delete',`${SCHEDULE_DELETE_JOBLIST_JOB}${type}/?${jobType ? 'mock_job' : 'job'}=${id}${param ? '&&back_up=del' : `&&back_up=${id}`}`)
        .then(res => {
            success()
            console.log(res)
            dispatch({
                type:'JOBLIST_JOB_DELETE',
                id:id,
                param:param
            })
        })
        .catch(err => {
            failed()
        })
    }
}

export const fetch_all_jobLists_countsNew = () =>{
    return function (dispatch){
        let url = SCHEDULE_JOB_LIST_COUNT_URL_ALL+'?total_count'
        Api('get', url).then(response => {
            console.log('countRes',response)
                dispatch({ type: 'JOB_LIST_COUNT_ALL', count: response })
        })
    }
}

export const fetch_job_list_readyForSchdNew = (type, page_no, filterData, jobType=null, callBk=null) => {
    return function (dispatch) {
        if(callBk!==null){
            callBk(true)
        }

    //     let url =  jobType==='del' ? `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=20&${filterData}` :
    //    jobType === 'rec' ? `${SCHEDULE_REOCCURRING_JOBS}${type}/?page=${page_no}&limit=20&${filterData}` :
    //    jobType === 'out' ? `${SCHEDULE_OUTSOURCE_JOBS}${type}/?page=${page_no}&limit=20&${filterData}` :
    //    `${SCHEDULE_NORMAL_JOBS}${type}/?page=${page_no}&limit=20&${filterData}` 
       

       let url =  
       //jobType==='del' ? `${SALES_JOB_ReSCHD_ALL}${type}/${page_no}/?limit=20&${filterData}` :
       jobType==='del' ? `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=20&${filterData}` :
       jobType === 'rec' ? `${SCHEDULE_NORMAL_JOBS_ALL}${type}/recurring/?page=${page_no}&limit=20&${filterData}` :
       jobType === 'out' ? `${SCHEDULE_NORMAL_JOBS_ALL}${type}/outsourced/?page=${page_no}&limit=20&${filterData}` :
       `${SCHEDULE_NORMAL_JOBS_ALL}${type}/one-off/?page=${page_no}&limit=20&${filterData}` 
       

       let frm = moment(filterData.substring(10,20));
       let tod = moment(filterData.substring(30,40));
       let filterVal = moment.duration(tod.diff(frm)).asWeeks();
        Api('get', url).then(response => {
            console.log("SCHD-rdy: ",url,response)
            if(callBk!==null){
                callBk(false)
            }
            if(response.data ? response.data.length > 0 : response.length > 0) {

                dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd_ALL', limitVal: 20, jobs: response.data ? response.data : response, page: page_no, filterVal: filterVal, jobType: jobType })
            } else {
                if(page_no === 1) {
                    dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd_ALL', limitVal: 20, jobs: response.data ? response.data : response, page: 1, filterVal: filterVal, jobType: jobType })
                } else {
                    dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd_ALL', limitVal: 20, jobs: response.data ? response.data : response, filterVal: filterVal, jobType: jobType })
                }
                
            }
            dispatch(fetch_all_jobLists_countsNew())
        })
    }
}

export const delete_job_joblist_ALL = (type, id, jobType, param, success, failed) =>{
    return function (dispatch) {
        
        Api('delete',`${SCHEDULE_DELETE_JOBLIST_JOB}${type}/?${jobType ? 'mock_job' : 'job'}=${id}${param ? '&&back_up=del' : `&&back_up=${id}`}`)
        .then(res => {
            success()
            console.log(res)
            dispatch({
                type:'JOBLIST_JOB_DELETE_ALL',
                id:id,
                param:param
            })
        })
        .catch(err => {
            failed()
        })
    }
}

export const fetch_job_list_RFSchd_append_paginationNew = (type, page_no, prevData,filterData,jobType) => {
    return function (dispatch) {
        dispatch({type:'JOB_LIST_SCHEDULE_readyForSchd_FETCHING_ALL'})
    //    let url =  `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=20&${filterData}`

    let url = jobType==='del' ? `${SALES_JOB_ReSCHD}${type}/${page_no}/?limit=20&${filterData}` :  
    //jobType==='del' ? `${SALES_JOB_ReSCHD_ALL}${type}/${page_no}/?limit=20&${filterData}` :
    jobType === 'rec' ? `${SCHEDULE_NORMAL_JOBS_ALL}${type}/recurring/?page=${page_no}&limit=20&${filterData}` :
    jobType === 'out' ? `${SCHEDULE_NORMAL_JOBS_ALL}${type}/outsourced/?page=${page_no}&limit=20&${filterData}` :
    `${SCHEDULE_NORMAL_JOBS_ALL}${type}/one-off/?page=${page_no}&limit=20&${filterData}` 

       let frm = moment(filterData.substring(10,20));
       let tod = moment(filterData.substring(30,40));
       let filterVal = moment.duration(tod.diff(frm)).asWeeks();
        Api('get', url).then(response => {
            // if(response.length > 0 ) {
                // let finalData = prevData ? prevData.concat(response) : response
                dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd_ALL', limitVal: 20, jobs: response.data ? response.data : response, page: page_no, filterVal: filterVal, jobType: jobType })
            // } 
            dispatch(fetch_all_jobLists_countsNew())
        })
    }
}

export const fetch_job_list_RFSchdSearch_append_paginationNew = (type, page_no, prevData,filterData, txt) => {
    return function (dispatch) {
        dispatch({type:'JOB_LIST_SCHEDULE_readyForSchd_FETCHING_ALL'})
       let url =  `${SALES_JOB_ReSCHD_ALL}${type}/${page_no}/?limit=20&${filterData}`
       let frm = moment(filterData.substring(10,20));
       let tod = moment(filterData.substring(30,40));
       let filterVal = moment.duration(tod.diff(frm)).asWeeks();

            let state = store.getState()
            let token = state.userLogin.authToken
            var config = 
            {
                method: 'get',
                url: url,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                params: {
                    key: txt
                }
            }
            axios(config)
            .then((res) => {
                dispatch({ type: 'JOB_LIST_SCHEDULE_readyForSchd_ALL', limitVal: 20, jobs: 
                res.data.data ? res.data.data : res.data, 
                page: page_no, filterVal: filterVal })
                if(res.data.count){
                    dispatch({ type: 'JOB_LIST_COUNT_ALL', count: res.data.count })
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
}

export const takeAllJobsPrintNEW = (type, page_no, filterData, success=null, failed=null) =>{

    const successFx = (res) =>{
        FileDownloader(res,'All_Jobs_List','.csv');
                if(success)
                    success()
    }
    const failedFx = () =>{
        if(failed){ failed() }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                }
    }

    Api('get',`${SCHEDULE_JOB_LIST_PRINT_NEW}${type}/?${filterData}`, null, successFx, failedFx)
}

export const editAfterCompleteElements = (data, success=null, failed=null) => {
    Api('put-json',SCHEDULE_EDIT_AFTERJOBCOMPLETE, data, success, failed)
}

export const updateCommentsSection = (id, success=null) =>{
    const successFx = () =>{
        if(success){
            success()
        }
    }
    Api('put',`${SCHEDULE_JOB_COMMENT_VALIDATION}${id}/`, null, successFx)
}

export const updateCertOfDestJobCard = (id, success=null) =>{
    const successFx = () =>{
        if(success){
            success()
        }
    }
    Api('post',`${SCHEDULE_JOB_CARD_CERT_OF_DEST_VALIDATION}${id}/`, '', successFx)
}