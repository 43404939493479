import React, { useRef, useCallback } from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import { GOOGLE_API_KEY, HOME_LATITUDE, HOME_LONGITUDE } from "../../../../../store/config";

const libraries = ["places"];
const mapContainerStyle = {
  height: '100%',
  width: '100%',
  borderRadius: 10,
};

const options = {
  disableDefaultUI: true,
};

export function GoogleMapInit() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  return { status :  (loadError||!isLoaded) ? false : true , value : loadError ? 'Error' : !isLoaded ? 'Loading...' : null}
}


export default function GoogleMapLayout({ markers, setMarkers, modalAction, UpdateSiteAddress=null }) {

  const [buttonVal, setButtonVal] = React.useState({val:'Choose', type:'text'})

  let lat_value = HOME_LATITUDE
  let long_value = HOME_LONGITUDE
// console.log(markers)
  if(markers.length > 0) {
    if(!(Number.isNaN(markers[0].lat))){
      lat_value = markers[0].lat
      long_value = markers[0].lng
    }
  }
  let center = { lat: lat_value, lng: long_value }
  let marker = markers
  if (markers.length > 0) {
    if(markers[0].lat !== null){
      if(!(Number.isNaN(markers[0].lat))){
        center = markers[0]
      }else{
        marker= [center]
      }
    }else{
      marker= [center]
    }
  }else{
    marker= [center]
  }
  //since length is there but no values only NaN


console.log(center)
  // console.log('map markers', markers)

  const mapRef = useRef();
  
  // const panTo = useCallback(({ lat, lng }) => {
  //   setMarkers([{ lat, lng }]);
  //   mapRef.current.panTo({ lat, lng });
  //   mapRef.current.setZoom(15);

  // }, []);

  const onlyPaning = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);

  }, []);

  const onMapClick = useCallback((e) => {
    setButtonVal({val:'Choose', type:'text'})
    setMarkers([{
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    }]);
    onlyPaning({ lat: e.latLng.lat(), lng: e.latLng.lng() })
  }, [setMarkers, onlyPaning]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // if (loadError) return "Error";
  // if (!isLoaded) return "Loading...";

  const mapInit = GoogleMapInit()

  return (
    // loadError ? 'Error' :
    //   !isLoaded ? 'Loading... ' 
      !mapInit.status ?
        mapInit.value
      :
        <div onClick={() => modalAction(true)} style={{ height: '100%', borderRadius: 20 }}>
          <SearchGoogleAddress onlyPaning={onlyPaning} center={center} buttonVal={buttonVal} setButtonVal={setButtonVal} setMarkers={setMarkers} UpdateSiteAddress={UpdateSiteAddress}/>

          {
            markers.length > 0 && (
              <div onClick={(e) =>{ e.stopPropagation(); modalAction(false)}} className={ buttonVal.type==='error' ? "map-choose-btn map-error-btn" : "map-choose-btn"} >
                <p className="p-0 m-0">{buttonVal.val}</p>
              </div>
            )
          }

          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            ref={mapRef}
            zoom={12}
            center={center}
            options={options}
            onClick={onMapClick}
            onLoad={onMapLoad}
          >
            {marker.map((mark) => (
              <Marker
                key={`${mark.lat}-${mark.lng}`}
                position={{ lat: mark.lat, lng: mark.lng }}
              />
            ))}

          </GoogleMap>
        </div>
  );
}


export function SearchGoogleAddress(
  { onlyPaning=null, center, buttonVal=null, setButtonVal=null, UpdateSiteAddress=null,
  cls=null, setMarkers=null, inputFn=null, inputVal=null, inputPlaceHolder=null, moreInfo=null }) {
  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => center.lat, lng: () => center.lng },
      radius: 100 * 1000,
      //   components: 'country:in'
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {

    setValue(e.target.value);
    if(buttonVal!==null && buttonVal.val!=='Choose'){
      setButtonVal({val:'Choose', type:'text'})
    }
    if(inputFn!==null){
      inputFn(e.target.value)
    }
    console.log(e.target.value, status, data)
  };

  const handleSelect = async (address) => {

    setValue(address, false);

    if(buttonVal!==null && buttonVal.val!=='Choose'){
      setButtonVal({val:'Choose', type:'text'})
    }
    if(inputFn!==null){
      inputFn(address)
    }
    clearSuggestions();
    console.log('\n Suggestions :\n',status, data,'\nData Entered :\n', address)
    
      let results = [];
      await getGeocode({ address }).then(
        res =>{ 
          results = res; 
          if(UpdateSiteAddress!==null){
            UpdateSiteAddress(res[0],address)
          }
        } 
      ).catch(err => { 
        console.log('getGeocodeErr=>',err);
        if(buttonVal!==null)
          setButtonVal({val:err, type:'error'})
       })
      console.log('getGeocodeResults=>',results)
      if(moreInfo!==null){
        moreInfo({ results : results ? results[0] : null, data : data ? data : null})
      }
      let GeoLat = null;
      let GeoLng = null;

      if(results && results.length!==0 && setMarkers!==null){
      await getLatLng(results[0]).then(
        res => { 
          GeoLat = res.lat; 
          GeoLng = res.lng; }
      ).catch(err => { console.log('getLatLngErr=>',err); 
        if(buttonVal!==null)
          setButtonVal({val:err, type:'error'})
      })
      console.log('handleSelcect=>',GeoLat, GeoLng, results)
      
      if(GeoLat && GeoLng){
      if(onlyPaning!==null)
        onlyPaning({ lat: GeoLat, lng: GeoLng })
        // onlyPaning({ GeoLat, GeoLng })
        
        if(setMarkers!==null){
          setMarkers([{
            lat: GeoLat,
            lng: GeoLng
          }]);
        }
        }
      }
      
      // setButtonVal({val:error, type:'error'})
      
      // console.log("😱 Error: ", error);
    
  };

  return (
    <div className={cls===null ? "search" : cls.outerBox}>
      {/* {console.log('VALUE',value)} */}
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={((value==='' || value===null)&&(inputVal!==null)) ? inputVal : value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={inputPlaceHolder===null ? "Search customer location" : inputPlaceHolder}
          className={ cls===null ? "searchbar-input" : cls.innerBox}
        />
        <ComboboxPopover style={{ zIndex: 5000000 }}>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }, key) =>{
                return(
                <ComboboxOption key={place_id} value={description}/>
              )})}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}


// export const AddressToLatLng = async (address) =>{
//   try {
//     const results = await getGeocode({ address });
//     const { lat, lng } = await getLatLng(results[0]);
//     console.log(lat,lng)
//     return { status : 'success', data : { lat : lat, lng : lng } }
//   } catch (error) {
//     console.log("😱 Error: ", error);

//     return { status : 'failed', data : error }
//   }
// }
