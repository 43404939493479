import Client from './client'
import Team from './team'
import Vehicle from './vehicle'
import Sales from './sales'
import Scheduling from './scheduling'
import OHS from './ohs'
import Home from './home'
import IntranetDev from './intranet'
import AccountsComp from './accountsComp'
import Invoice from './invoice'

export const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Home,
        status: true
    },
    {
        path: '/sales',
        name: 'Sales',
        component: Sales,
        status: true
    },
    {
        path: '/client',
        name: 'Site',
        component: Client,
        status: true
    },
    {
        path: '/team',
        name: 'Team',
        component: Team,
        status: true
    },
    {
        path: '/scheduling',
        name: 'Scheduling',
        component: Scheduling,
        status: true
    },
    {
        path: '/vehicle',
        name: 'Vehicle',
        component: Vehicle,
        status: true
    },
    {
        path: '/ohs',
        name: 'OH & S',
        component: OHS,
        status: true
    },
    {
        path: '/intranet',
        name: 'Intranet',
        component: IntranetDev,
        status: true
    },
    {
        path: '/accounts',
        name: 'Accounts',
        component: AccountsComp,
        status: true
    },
    {
        path: '/invoice',
        name: 'Invoice',
        component: Invoice,
        status: true
    },
    // {
    //     path: '/scheduling',
    //     name: 'Scheduling',
    //     component: Roaster,
    //     status: true
    // },
]