import React, {useEffect, useState} from 'react';
import DateTimeInput from '../../../../common/DateTimeInput'
// import {connect} from 'react-redux'
// import Swal from 'sweetalert2'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { CLIENT_SINGLE_FETCH, SALES_ALL_CLIENTS_LIST } from '../../../../store/endpoint';
import Api from '../../../../store/api'
import PartListPumpDetail from './PartListPumpDetail';
import PartListPanelDetail from './PartListPanelDetails';
import PartListPitClean from './PartListPitClean';
import PartListSuctionFlange from './PartListSuctionFlange';
import PartListDischargeFlange from './PartListDischargeFlange';
import { TextAreaLimiter } from '../../../../common/TextAreaLimiter';

function PartList({ jobObj, modalType, tabType=null, preSelect=null, setPartList, setClientName, clientId, setClientId, jobObjConnected, preSelectVal, //TypeofWaste, setTypeofWaste
    // clientList, addJob, editJob, verifyJobCard=null, user 
}) {

    const [compName, setCompName]           = useState(modalType==='add' ? preSelect===null ? '' :
    preSelect.company_name ? preSelect.company_name : preSelect.client_name : jobObj===null ? '' : jobObj.company_name ? jobObj.company_name : jobObj.client_name  )
    const [siteAddr, setSiteAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.site_address : jobObj===null ? '' : jobObj.job_site_address   )
        const [textAreaValid, setTextAreaValid] = useState({a:true, b:true, c:true})
    const [jobDate, setJobDate]             = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.date : new Date())
    const [jobTechnician, setJobTechnician] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.technician : '')

    const [assetLocation, setAssetLocation] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_loc : '')
    const [assetNo, setAssetNo]             = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_no : '')
    const [assetType, setAssetType]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_type : '')
    const [faultIdentified, setFaultIdentified] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.fault_identified : '') 
    const [corrective, setCorrective]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.corrective : '')
    const [equipments, setEquipments]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.equipments : '')

    const [pumpDet, setPumpDet]             = useState(modalType!=='add' ? jobObj===null ?
        {
        pumpMake:'', pumpModel: '', static: '', motorCurr: '', volt: '', kilowatts: '',
        autoManual: '', guideRail:'', cablePull: false, resin: false, dist: ''
    } : {
        pumpMake: jobObj.pump_make, pumpModel:  jobObj.pump_model, static:  jobObj.static, motorCurr:  jobObj.motor_curr, volt:  jobObj.volt,
        kilowatts:  jobObj.kilowatts, autoManual:  jobObj.auto_manual, guideRail: jobObj.guide_rail, cablePull: jobObj.cable_pull, resin: jobObj.resin, dist: jobObj.distance
    } : {
        pumpMake:'', pumpModel: '', static: '', motorCurr: '', volt: '', kilowatts: '',
        autoManual: '', guideRail:'', cablePull: false, resin: false, dist: ''
    })

    const [panelDet, setPanelDet]             = useState(modalType!=='add' ? jobObj===null ? {
        contractor:'', coil: '', overload: '', range: '', auxiliary: '', panelDimension: '',
        bms: '', autoDia:'', panelMake: '', indoor: '', controlSensor: ''
    } : {
        contractor: jobObj.contractor, coil: jobObj.coil, overload: jobObj.overload, range: jobObj.range, auxiliary: jobObj.auxiliary, panelDimension: jobObj.panel_dimension,
        bms: jobObj.bms, autoDia: jobObj.auto_dia, panelMake: jobObj.panel_make, indoor: jobObj.indoor_outdoor, controlSensor: jobObj.control_sensor
    } : {
        contractor:'', coil: '', overload: '', range: '', auxiliary: '', panelDimension: '',
        bms: '', autoDia:'', panelMake: '', indoor: '', controlSensor: ''
    })

    const [pitClean, setPitClean]             = useState(modalType!=='add' ? jobObj===null ? {
        depth:'', width: '', length: '', hose: '', truckAccess: '', truckCapacity: '',
        estimate: '', wasteType:'', confined: false
    } :
    {
        depth: jobObj.depth, width:  jobObj.width, length:  jobObj.length, hose:  jobObj.hose, truckAccess:  jobObj.truck_access, truckCapacity:  jobObj.truck_capacity,
        estimate:  jobObj.estimate, wasteType: jobObj.waste_type, confined: jobObj.confined
    }: {
        depth:'', width: '', length: '', hose: '', truckAccess: '', truckCapacity: '',
        estimate: '', wasteType:'', confined: false
    })

    const [suctionFlg, setSuctionFlg]         = useState(modalType!=='add' ? jobObj===null ? {
        table:'', diameter:'', pcd:'', pr:'', noOfHoles:'', bolt:'', boss:'', pipe:''
    } :
    {    table: jobObj.suction_table, diameter: jobObj.suction_diameter, pcd: jobObj.suction_pcd, pr: jobObj.suction_pr, noOfHoles: jobObj.suction_no_of_holes, bolt: jobObj.suction_bolt, boss: jobObj.suction_boss, pipe: jobObj.suction_pipe
    } :
    {  table:'', diameter:'', pcd:'', pr:'', noOfHoles:'', bolt:'', boss:'', pipe:''
    })

    const [dischargeFlg, setDischargeFlg]         = useState(modalType!=='add' ? jobObj===null ? {
        table:'', diameter:'', pcd:'', pr:'', noOfHoles:'', bolt:'', boss:'', pipe:''
    } :
    {    table: jobObj.discharge_table, diameter: jobObj.discharge_diameter, pcd: jobObj.discharge_pcd, pr: jobObj.discharge_pr, noOfHoles: jobObj.discharge_no_of_holes, bolt: jobObj.discharge_bolt, boss: jobObj.discharge_boss, pipe: jobObj.discharge_pipe
    } :
    {  table:'', diameter:'', pcd:'', pr:'', noOfHoles:'', bolt:'', boss:'', pipe:''
    })

    const [tradesPerson, setTradesPerson]   = useState(modalType!=='add' ? jobObj===null ? 
    [   { tradesP : 'Tradesman#1', req: false, time: ''  },
        { tradesP : 'Tradesman#2', req: false, time: ''  },
        { tradesP : 'Apprentice', req: false, time: ''  },
    ]
    : jobObj.tradesPerson
    :
    [   { tradesP : 'Tradesman#1', req: false, time: ''  },
        { tradesP : 'Tradesman#2', req: false, time: ''  },
        { tradesP : 'Apprentice', req: false, time: ''  },
    ])


    const [partsList, setPartsList]         = useState(modalType!=='add' ? jobObj===null ? 
        [   {no : 1, partName : '', part_qty : '', part_quantity : ''}, 
            {no : 2, partName : '', part_qty : '', part_quantity : ''}, 
            {no : 3, partName : '', part_qty : '', part_quantity : ''}
        ]   :   jobObj.parts_list 
        :
        [   {no : 1, partName : '', part_qty : '', part_quantity : ''}, 
            {no : 2, partName : '', part_qty : '', part_quantity : ''}, 
            {no : 3, partName : '', part_qty : '', part_quantity : ''}
        ])


    const [allClientsList, setAllClientsList] = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)

    const addRem = (val) =>{
        if(val){
            setPartsList(data => [...data, {
                no              :   partsList.length+1,
                partName        :   '',
                part_qty        :   ''
            }])
        }
        else{
            let newArr = partsList.filter((_,key) => key!==(partsList.length-1))
            setPartsList(newArr)
        }
    }


        useEffect(()=>{
            Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                res =>{
                    console.log(res)
                    setAllClientsLoader(false)
                    if(res.length>0){
                        setAllClientsList(res)
                        if((modalType==='edit' || modalType==='add') && clientId!==null && clientId!==''){
                            Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
                                .then(response => {
                                    setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                    setSiteAddr(response.site_address)
                                    setClientName(response.client_name);
                            })
                             
                        ; }
                    }else 
                        setAllClientsList([])
                }
                    
            )
        },[tabType, modalType, clientId, setClientName])    

    const addJobCard = () =>{
        let data1 = {
            card_id             :   '3',
            client              :   clientId,
            company_name        :   compName,
            job_site_address    :   siteAddr,   
            job_date            :   jobDate,
            technician          :   jobTechnician,
            asset_loc           :   assetLocation,
            asset_no            :   assetNo,
            asset_type          :   assetType,
            fault_identified    :   faultIdentified,
            corrective          :   corrective,
            equipments          :   equipments,
            tradesPerson        :   tradesPerson,
            parts_list          :   partsList
        }

        let data2 = {
            pump_make   :   pumpDet.pumpMake,           
            pump_model  :   pumpDet.pumpModel,
            static      :   pumpDet.static,
            motor_curr  :   pumpDet.motorCurr,
            volt        :   pumpDet.volt,
            kilowatts   :   pumpDet.kilowatts,
            auto_manual :   pumpDet.autoManual,
            guide_rail  :   pumpDet.guideRail,
            cable_pull  :   pumpDet.cablePull,
            resin       :   pumpDet.resin,
            distance    :   pumpDet.dist            
        }

        let data3 = {
            contractor      :   panelDet.contractor,
            coil            :   panelDet.coil,
            overload        :   panelDet.overload,
            range           :   panelDet.range,
            auxiliary       :   panelDet.auxiliary,
            panel_dimension :   panelDet.panelDimension,
            bms             :   panelDet.bms,
            auto_dia        :   panelDet.autoDia,
            panel_make      :   panelDet.panelMake,
            indoor_outdoor  :   panelDet.indoor,
            control_sensor  :   panelDet.controlSensor       
        }

        let data4 = {
            depth           :   pitClean.depth,
            width           :   pitClean.width,
            length          :   pitClean.length,
            hose            :   pitClean.hose,
            truck_access    :   pitClean.truckAccess,
            truck_capacity  :   pitClean.truckCapacity,
            estimate        :   pitClean.estimate,
            waste_type      :   pitClean.wasteType,
            confined        :   pitClean.confined 
        }

        let data5 = {
            suction_table       :   suctionFlg.table,
            suction_diameter    :   suctionFlg.diameter,
            suction_pcd         :   suctionFlg.pcd,
            suction_pr          :   suctionFlg.pr,
            suction_no_of_holes :   suctionFlg.noOfHoles,
            suction_bolt        :   suctionFlg.bolt,
            suction_boss        :   suctionFlg.boss,
            suction_pipe        :   suctionFlg.pipe            
        }

        let data6 = {
            discharge_table         :   dischargeFlg.table,
            discharge_diameter      :   dischargeFlg.diameter,
            discharge_pcd           :   dischargeFlg.pcd,
            discharge_pr            :   dischargeFlg.pr,
            discharge_no_of_holes   :   dischargeFlg.noOfHoles,
            discharge_bolt          :   dischargeFlg.bolt,
            discharge_boss          :   dischargeFlg.boss,
            discharge_pipe          :   dischargeFlg.pipe  
        }

        let data = { ...data1, ...data2, ...data3, ...data4, ...data5, ...data6}

        setPartList(data)

        }
    

    return (
        <div className="jobCardDiv1">
            <div className="jobCardDivHead" >Asset Part List</div>
                <div className="jobCardDivInpContent">
                    <div className="jobCardInputDivSec" >
                        <div>Date</div>
                        <div>
                            <span>:</span>
                            <DateTimeInput
                            dateSetFn={setJobDate}
                            dateValue={jobDate}
                            />
                    {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'|| preSelectVal!==null   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compName}</p>   :
                            <select className="jobCardDivInp" value={clientId} 
                            style={{ pointerEvents: preSelectVal===null ? 'auto' : 'none',
                                         cursor: preSelectVal===null ? 'pointer' : 'default' }}
                            onClick={()=>{ setAllClientsLoader(true);
                                Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                                    res =>{
                                        // console.log("DATAAAAA",res)
                                        setAllClientsLoader(false)
                                        if(res.length>0)
                                            setAllClientsList(res)
                                        else 
                                            setAllClientsList([])
                                    }
                                        
                                )
                            }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                setClientId(e.target.value);
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                                        setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                        setSiteAddr(response.site_address)
                                        setClientName(response.client_name);
                                })
                                 
                            ; }
                                 }}>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    {/* <div className="jobCardDivInpContentDiv">
                        <div>Type of Waste</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={TypeofWaste} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                                 onChange={val =>{ setTypeofWaste(val.target.value.toUpperCase());  }}>
                                    <option value='' >Select Option</option>
                                    <option value='AMMONIA'>AMMONIA</option>
                                    <option value='DILUTION PIT'>DILUTION PIT</option>
                                    <option value='DRUMS'>DRUMS</option>
                                    <option value='FOOD WASTE'>FOOD WASTE</option>
                                    <option value='GERNIE WASHES'>GERNIE WASHES</option>
                                    <option value='GREASE'>GREASE</option>
                                    <option value='GROUND WATER'>GROUND WATER</option>
                                    <option value='J120'>J120</option> 
                                    <option value='LEVY'>LEVY</option>
                                    <option value='M250'>M250</option>
                                    <option value='N205'>N205</option> 
                                    <option value='OIL SEPERATOR'>OIL SEPERATOR</option>
                                    <option value='PACKAGED WASTE'>PACKAGED WASTE</option>
                                    <option value='PRODUCT DESTRUCTION'>PRODUCT DESTRUCTION</option>
                                    <option value='PUMP SERVICE'>PUMP SERVICE</option>
                                    <option value='RETENTION PIT'>RETENTION PIT</option>
                                    <option value='SEWER'>SEWER</option>
                                    <option value='STORMWATER'>STORMWATER</option>
                                    <option value='TANK WASH OUT'>TANK WASH OUT</option>      
                                    <option value='TRADE WASTE'>TRADE WASTE</option>                                              
                                    <option value='TRANSPORT'>TRANSPORT</option>                            
                                    <option value='Z140'>Z140</option>
                                    <option value='WIEGH BRIDGE'>WEIGH BRIDGE</option>                                    
                                    <option value='DESTRUCTION CERTIFICATE'>DESTRUCTION CERTIFICATE</option>
                                    <option value='NOT APPLICABLE'>NOT APPLICABLE</option>                                
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                        </div>
                        
                    </div> */}
                    
                    <div className="jobCardInputDivSec" >
                        <div>Site Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteAddr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Site Address" value={siteAddr} onChange={(e)=>setSiteAddr(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Technician</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Technician" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Asset Location</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetLocation}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Asset Location" value={assetLocation} onChange={(e)=>setAssetLocation(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Asset Number</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetNo}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Asset Number" value={assetNo} onChange={(e)=>setAssetNo(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Asset Type</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetType}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Asset Type" value={assetType} onChange={(e)=>setAssetType(e.target.value)} />
                            }
                        </div>
                    </div>

                    <div className="jobCardTextAreaDiv">
                        <div><span>Fault Identitfied</span></div>
                        <div>
                        {   modalType==='view'   ?   
                            <p>{faultIdentified}</p>   :
                            
                        <TextAreaLimiter
                        isValid={(e) => setTextAreaValid({...textAreaValid, a : e})}
                        configs={{
                            rows:'10', className:"jobCardTextArea", placeholder:"Fault Identified", 
                            value : faultIdentified, onChange : (e)=>setFaultIdentified(e.target.value)
                        }}
                        v={faultIdentified} />
                        }
                        </div>
                    </div>

                    <div className="jobCardTextAreaDiv">
                        <div><span>Corrective Action</span></div>
                        <div>
                        {   modalType==='view'   ?   
                            <p>{corrective}</p>   :
                            <TextAreaLimiter 
                            isValid={(e) => setTextAreaValid({...textAreaValid, b : e})}
                        configs={{
                            rows : '10', className : "jobCardTextArea", placeholder : "Corrective Action", value : corrective,
                            onChange : (e)=>setCorrective(e.target.value)
                        }}
                        v={corrective} />
                        
                        }
                        </div>
                    </div>
                    
                    <div className="jobCardDiv3">
                    <table className="jobCardDivTableContent jobCardTableStyle jobCardTableWidth50">
                        <tbody >
                            <th style={{ width:'auto' }}></th><th>Required (tick)</th>
                            <th>Total Time (including travel)</th>
                            {   tradesPerson.map((item, key) => { 
                        return(
                            <tr>
                                <td style={{ fontSize:'.7em', fontWeight:'bold', width:'auto' }} >{item.tradesP}</td>
                                <td>
                                    <input type="checkbox" className="jobCardDivInp"  disabled={modalType==='view' }
                                     checked={item.req} onClick={()=>{ 
                                        let temp = {...item, req : !item.req}; 
                                        let tempArr = tradesPerson
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setTradesPerson(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="Total Time"  disabled={modalType==='view' }
                                     value={item.time} onChange={(e)=>{ 
                                        let temp = {...item, time : e.target.value}; 
                                        let tempArr = tradesPerson
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setTradesPerson(Object.assign([],tempArr)) }} />
                                </td>
                                
                            </tr>)}
                            )}
                        </tbody>
                    </table>
                    </div>

                    <div className="jobCardTextAreaDiv">
                        <div><span>Equipments</span></div>
                        <div>
                        {   modalType==='view'   ?   
                            <p>{equipments}</p>   :
                            <TextAreaLimiter
                            isValid={(e) => setTextAreaValid({...textAreaValid, c : e})}
                        configs={{
                            rows : '10', className : "jobCardTextArea", placeholder : "Equipments", value : equipments, onChange : (e)=>setEquipments(e.target.value)
                        }}
                        v={equipments} />
                        
                        }
                        </div>
                    </div>

                    <PartListPumpDetail modalType={modalType} pumpDet={pumpDet} setPumpDet={setPumpDet} />                    
                    <PartListPanelDetail  modalType={modalType} panelDet={panelDet} setPanelDet={setPanelDet} />
                    <PartListPitClean modalType={modalType} pitClean={pitClean} setPitClean={setPitClean} />
                    <PartListSuctionFlange modalType={modalType} suctionFlange={suctionFlg} setSuctionFlange={setSuctionFlg}/>
                    <PartListDischargeFlange modalType={modalType} dischargeFlange={dischargeFlg} setDischargeFlange={setDischargeFlg}/>
                        
                    <div className="jobCardDiv3">
                        <div className="jobCardDivHead" >Parts List</div>
                        <div className="jobCardDivTableContent jobCardTableStyle">
                            <div className="jobCardDivTHead">
                                <div style={{ width:'20px' }}>Sl.No</div>
                                <div>Parts</div>
                                <div>Quantity</div>
                            </div>
                            {   partsList.map((item, key) => { 
                            return(
                                <div className="jobCardDivTHead">
                                    <div style={{ width:'20px' }}>{key+1}</div>
                                    <div style={{ width:'40%' }}>
                                        <input type="text"  className="jobCardDivInp" placeholder="Parts"  disabled={modalType==='view' }
                                         value={item.partName} onChange={(e)=>{ 
                                            let temp = {...item, partName : e.target.value}; 
                                            let tempArr = partsList
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setPartsList(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div style={{ width:'40%' }}>
                                        <input type="text" className="jobCardDivInp" placeholder="Quantity"  disabled={modalType==='view' }
                                         value={item.part_qty} onChange={(e)=>{ 
                                            let temp = {...item, part_qty : e.target.value}; 
                                            let tempArr = partsList
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setPartsList(Object.assign([],tempArr)) }} />
                                    </div>
                                    
                                </div>)
                    })
                    }
                        {   modalType==='view'  ?   null    :
                        <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'flex-end', alignItems:'center', margin:'20px 0' }}>
                            <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(true)} ><AddCircleOutline style={{ fontSize:'16px' }}/> Add Row</div>
                            {   partsList.length>3 ?
                                <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(false)}><RemoveCircleOutline style={{ fontSize:'16px' }}/> Remove Row</div>  :   null
                            }
                            </div>
                        }
                        </div>
                    </div>
                </div>
                {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                <div 
                className={ clientId && textAreaValid.a && textAreaValid.b && textAreaValid.c ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else if(!jobObjConnected)
                        addJobCard()
                    }} 
                  style={{ width:'15vw', height:'5vh', textAlign:'center' }}>
                {modalType==='add' ? 'Add Job Card to Collection' : modalType==='verify' ? jobObjConnected ?
                `This job card is already in use `
                :   'Update Job Card (Deselect the red checkbox to remove from collection)' :
                 'Update Job Card (Deselect the red checkbox to remove from collection)'} 
                </div>
            </div>
            :   null
            }
        </div>
    );
}

export default PartList;
