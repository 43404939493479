import React, {useEffect, useState} from 'react'

import '../intranet/components/style.css'
import { LEAVEAPPLY_CRUD, LEAVESTATUS_UPDATE
} from '../../store/endpoint';
import Swal from 'sweetalert2'

import { connect } from 'react-redux';
import Scrollbars from '../../common/ImprovedScrollBar';
import JobReschedule from './JobReschedule';
import { team_leaveApply } from '../team/action';
import { Avatar, CircularProgress } from '@material-ui/core';
import './style.css';
import moment from 'moment';
import { fetch_Employee_avilabilities } from '../scheduling/common/action';
import Api from '../../store/api'
import { DescriptionOutlined } from '@material-ui/icons';
import Failerrdevmsg from '../../common/failErrDevMsg';
import { cl_red, cl_green } from '../../common/Colors'


function LeaveApplicantsList({ team_leaveApplyFx, initialLeaveList, leaveApplyListLoader, fetch_availabilityEmp, leaveListPage }) {
    const [modalStatus, setModalStatus] = useState(false)
    const [selectedEmp, setSelectedEmp] = useState(null)
    const [selectedJobArr, setSelectedJobArr] = useState([])
    const [nextPage, setNextPage] = useState(leaveListPage)

    useEffect(()=>{
        team_leaveApplyFx(null, 1, null, null);
    }, [team_leaveApplyFx]);


    const approveLeave = (val, id) =>{
        if(val){
            let get_url = `${LEAVESTATUS_UPDATE}${id}/?status=approved`
        const success = () => {
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Leave Approved',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
            
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }
        
        Api('get', get_url).then(res => {
                success()
            team_leaveApplyFx();
            })
            .catch(err => failed() )
        }
        else{
            
            let get_url = `${LEAVESTATUS_UPDATE}${id}/?status=rejected`
        const success = () => {
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Leave Rejected',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
        
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }
        
        Api('get', get_url).then(res => {
                success()
            team_leaveApplyFx();
            })
            .catch(err => failed())
        }
        
    }

    const DeleteLeave = (id) =>{
        
        const success = () => {
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Leave Application Deleted',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
            
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
              console.log(result)
            if (result.value) {
              
        Api('delete', `${LEAVEAPPLY_CRUD}${id}/`).then(res => {
            success()
        team_leaveApplyFx();
        })
        .catch(err => failed() )
            }
          })
        
        
    }

    const ScrollUp = (val) =>{
        const {top} = val
        // console.log('SCROLL',top, pageNo, clientPage)
        if((top >= 0.999)&&(nextPage===leaveListPage)){
            setNextPage(leaveListPage+1)
            team_leaveApplyFx(null, leaveListPage+1, null, null)
        }
    }

    return  initialLeaveList && initialLeaveList.length!==0 ?
                    <Scrollbars style={{ height:'68vh', width:'100%', border:'1px solid lightgray', backgroundColor:'white' }} 
                    autoHide   onUpdate={ScrollUp} >
                    <table className="accountsTableSec">
                            <th>Employee</th>
                            <th>Leave From</th>
                            <th>Leave To</th>
                            <th>Assigned Jobs</th>
                            <th>Leave Type and Form</th>
                            <th>Attachments</th>
                            <th>Action</th>

                        <tbody style={{ width:'100%' }}>
                            {
                            initialLeaveList.map((item,k) => {
                                return(
                                  
                            <tr key={k}
                                style={{
                                    backgroundColor: k%2===0 ? 'white' : 'var(--cl_ivery)'
                                }}
                            >
                                <td className="shdl-Leave-emp-body">
                                    <Avatar src={item.employee_dp} variant={'rounded'} style={{ paddingLeft:'5%' }} />
                                    <p style={{ paddingLeft:'5%', wordBreak:'break-all' }}>{item.employee_name}</p>
                                </td>
                                <td>{moment(item.leave_from).format('DD-MMM-YYYY')}</td>
                                <td>{moment(item.leave_to).format('DD-MMM-YYYY')}</td>
                                <td valign={'middle'} >
                                    {item.job_ids && item.job_ids.length!==0 ? 
                                        
                                        <div className="jobCodeChipDiv">{item.job_ids.length} Job{item.job_ids.length > 1 ? 's' : ''} Assigned</div>
                                        
                                    :   'No Jobs Assigned.'
                                    }
                                </td>
                                <td onClick={()=> window.open(item.leave_form) } 
                                    valign={'middle'} >
                                    
                                    <span style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                                        <DescriptionOutlined style={{ fontSize: 20 }} />
                                        <p>{item.leave_type}</p> 
                                    </span>
                                </td>
                                <td onClick={()=>{ if(item.attachment) window.open(item.attachment) }}>
                                    {item.attachment ? <DescriptionOutlined style={{ fontSize: 20 }} /> : null }
                                    <span>
                                        {item.attachment ? 'Attachment' : 'No Attachment'}
                                    </span>
                                </td>
                                <td>{   
                                    item.approved_status===null ?
                                item.job_ids && item.job_ids.length!==0 ?

                                <div style={{ display:'flex', flexDirection:'row' }}>
                                    <div className="accountTableStatusBtn" onClick={()=>{
                                        if(item.job_ids && item.job_ids.length!==0){
                                            setSelectedEmp(item)
                                            setSelectedJobArr(item.job_ids)
                                            fetch_availabilityEmp(`start=${moment(item.leave_from).format('YYYY-MM-DD')} ${moment(item.leave_from).format('HH:mm:ss')}&&end=${moment(item.leave_to).format('YYYY-MM-DD')} ${moment(item.leave_to).format('HH:mm:ss')}`)      
                                            setModalStatus(true)
                                        }
                                         }}>
                                        Pending
                                    </div>
                                    <div className="accountTableStatusBtn"
                                              onClick={()=> DeleteLeave(item.id)}
                                        >Delete</div>
                                </div>
                                    :
                                    <div style={{ display:'flex', flexDirection:'row' }}>
                                        <div className="accountTableStatusBtn"  onClick={()=> approveLeave(true, item.id) }
                                         style={{ backgroundColor:'var(--cl_green)' }}>Approve</div>
                                        <div className="accountTableStatusBtn"
                                              onClick={()=> approveLeave(false, item.id)}
                                        >Reject</div>
                                        <div className="accountTableStatusBtn"
                                              onClick={()=> DeleteLeave(item.id)}
                                        >Delete</div>
                                    </div>
                                :
                                    <div>
                                        <div className="accountTableStatusBtnDis"
                                        
                                         style={{ backgroundColor: item.approved_status ? 'var(--cl_green)' : 'red' }}>
                                             { item.approved_status ? 'Approved' : 'Rejected'}
                                        </div>
                                        <div className="accountTableStatusBtn"
                                              onClick={()=> DeleteLeave(item.id)}
                                        >Delete</div>
                                    </div> 
                                    }
                                </td>
                            </tr>
                              
                              )
                            })    
                        }
                        </tbody>
                        {   !leaveApplyListLoader ?
                        <div style={{ display: 'flex', height: '100px', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center',padding:'10px 0' }} >
                            <CircularProgress style={{height:'30px',width:'30px'}}/>
                        </div>
                        :   null
                        }
                    </table>
                    <JobReschedule modalStatus={modalStatus} modalAction={setModalStatus} employeeDet={selectedEmp} jobIdList={selectedJobArr} />
                    </Scrollbars>
                    :
                    initialLeaveList && initialLeaveList.length===0 && leaveApplyListLoader ?
                    <table className="accountsTableSec">
                    <thead>
                        <th>No Leave Applications</th>
                        
                    </thead>
                    </table>
                    :
                    !leaveApplyListLoader ?
                    <div style={{ display: 'flex', height: '100px', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center',padding:'10px 0' }} >
                        <CircularProgress style={{height:'30px',width:'30px'}}/>
                    </div>
                    :   null
                
                    
            
    
}

const mapStateToProps = (state) =>{
    const { initialLeaveList, leaveApplyListLoader, leaveListPage  } = state.team
    return { initialLeaveList, leaveApplyListLoader, leaveListPage }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        team_leaveApplyFx: (data, page, success, failed) => dispatch(team_leaveApply(data, page, success, failed)),
        fetch_availabilityEmp : (filter) => dispatch(fetch_Employee_avilabilities(filter))
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (LeaveApplicantsList)
