import React, {useEffect, useState} from 'react'

import '../intranet/components/style.css'
import QuoteModal from '../../common/CommonQuoteDetailModal'
import QuoteHistoryModalView from '../scheduling/component/quoteHistoryModal'

import { connect, useDispatch } from 'react-redux';
import Scrollbars from '../../common/ImprovedScrollBar';
import './style.css';
import { fetch_all_completed_jobs, fetch_comments_of_job, fetch_curr_template, refresh_completed_jobs, update_job_status } from './common/action';
import Api from '../../store/api'
import { CircularProgress } from '@material-ui/core';
import { ACCOUNTS_JOB_COMPLETED_LIST, PUMPASSETRATES, SCHEDULE_QUOTE_INFOS_NEW, SCOPOFWORK_CRUD_ADDITIONAL_INFO } from '../../store/endpoint';
import { SearchInputTab } from '../../common/SearchInputTab';
import Swal from 'sweetalert2';
import EditableEditorModal from './editableEditorModal';
import { useRef } from 'react';
import EditTableModal from '../../common/mainEditorTable/standaloneQuoteTableData.js'
import { SCOPEOFWORK_CRUD_TYPE_OF_WASTE } from '../../store/endpoint';
import { cl_blue, cl_green } from '../../common/Colors';
import DateTimeInput from '../../common/DateTimeInput';
import moment from 'moment';
import ReactSelect from 'react-select'
import CommentsBoxModal from './CommentsBoxModal';
import NoDatasScreen from '../../common/no_datas';
import { Tooltip } from 'antd';

// import EditNotWasteTableModal from './editNotWasteTableModal';



function CompletedJobsList({ job_completedListFx, fetch_quote_temp, jobComplList, jobComplPage, jobComplLoader, updateStatus, jobCompStop,
     jobComplSearchKey, jobComplFilterFrom, jobComplFilterTo, set_quote_mainpage_content, quote_mainpage_content, quote_table_obj, fetchAllComments }) {
    const [nextPage, setNextPage] = useState(2)
    const [quoteModal, setquoteModal] = useState(false)
    const [quoteInfos, setQuoteInfos] = useState({ job: null, quote: null, schedul: null })
    const [mainTemplate, setMainTemplate] = useState(false)
    const [sOWModal, setSOWModal] = useState(false)
    // const [notWasteSOWModal, setNotWasteSOWModal] = useState(false)
    const [salesJobSpinner, setSalesJobSpinner] = useState(false)
    const [salesJobSpinnerBtnIndex, setSalesJobSpinnerBTnIndex] = useState(null)
    const [salesQuoteSpinnerBtnIndex, setSalesQuoteSpinnerBTnIndex] = useState(null)
    const [sOWSpinnerBtnIndex, setSOWSpinnerBTnIndex] = useState(null)
    const [QuoteHistoryModal, setQuoteHistoryModal] = useState(false)
    const [quoteHistoryInfos, setQuoteHistoryInfos] = useState([])
    const [jobVal, setJobVal] = useState(null)
    const [searchDataFx, setSearchDataFx] = useState(null)
    const [salesJobPaidSpinner, setSalesJobPaidSpinner] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [typeOfWaste, setTypeofWaste] = useState([])
    const [addInfoList, setAddInfoList] = useState([])
    const templateRef = useRef(null)
    const [pageEndHeight, setPageEndHeight] = useState(0)

    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())
    const [switchableSearchFnVal, switchableSearchFn] = useState({ label : 'Clients', value : 'clients' })

    const [openCommentsBox, setOpenCommentsBox] = useState(false)
    const [commentBoxLoader, setCommentBoxLoader] = useState(null)
    const [quoteId, setQuoteId] = useState(null)
    const dispatch = useDispatch();


    useEffect(()=>{
        job_completedListFx(1, null);
    }, [job_completedListFx]);

    const ScrollUp = (val) =>{
        const {top} = val
        console.log('SCROLL',top,'nextPage', nextPage,'jobComplPage',jobComplPage)
        if(!jobCompStop){
            if((searchDataFx!==null)&&(top >= 0.999)&&(nextPage === jobComplPage+1)){
                setNextPage(nextPage+1)
                job_completedListFx( nextPage, searchDataFx, jobComplFilterFrom, jobComplFilterTo, switchableSearchFnVal.value)
            }
            else if((top >= 0.999)&&(nextPage===jobComplPage+1)){
                setNextPage(nextPage+1)
                job_completedListFx( nextPage, null, jobComplFilterFrom, jobComplFilterTo)
            }
        }
    }

    const fetchQuoteInfos = (id, key) => {
        setSalesJobSpinner(true)
        setSalesJobSpinnerBTnIndex(key)

        const succ = (res) =>{
            console.log(res.data)
            setSalesJobSpinner(false)
            setSalesJobSpinnerBTnIndex(null)
            if(res.data){
            setQuoteInfos(res.data)
            setquoteModal(true)
            setJobVal(res.data.quote.job_card_id)}
            else
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        const fail = (err) =>{
            Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: err && err.app_data ? err.app_data : 'Something went wrong!'
           })
            setSalesJobSpinner(false)
            setSalesJobSpinnerBTnIndex(null)
            
        }

        Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`,null, succ, fail)
    }

    const fetchQuoteTemplate = (id,k,val=true, tab=true) =>{
        const successFx = () =>{

            if(val)
                setMainTemplate(true)
            else if(tab)
                setSOWModal(true)
            // else 
            //     setNotWasteSOWModal(true)

            if(val)
                setSalesQuoteSpinnerBTnIndex(null)
            else
                setSOWSpinnerBTnIndex(null)
        }
        const failedFx = () =>{
            
            if(val)
                setSalesQuoteSpinnerBTnIndex(null)
            else 
                setSOWSpinnerBTnIndex(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.'
            }) 
        
        }
        fetch_quote_temp(id, successFx, failedFx)
        
        if(val)
            setSalesQuoteSpinnerBTnIndex(k)
        else 
            setSOWSpinnerBTnIndex(k)
    }

    const changePaidStatus = (id, k, currStatus=true) =>{

        setSalesJobPaidSpinner(k)
        // const successFx = () =>{
        //     updateStatus(id, k, success, failed)
        // }
        const success = () =>{
            setSalesJobPaidSpinner(null)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Job paid status changed!`,
                showConfirmButton: false,
                timer: 1500
            })

        }
        const failed = ()=>{
            setSalesJobPaidSpinner(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.'
            })
        }
        Swal.fire({
            title: `Changing status to Complete`,
            text: `This action will change the status to Completed. You won't be able to revert this!`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: cl_blue,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Change Status'
        }).then((result) => {
            if (result.value) {
                // if(currStatus){
                //     updateStatus(id, k, successFx, null)
                // }else
                // updateStatus(id, k, success, failed)
                let data = { "paid_status" : "completed" }
                updateStatus(id, k, data, success, failed)
            }
            else{
                setSalesJobPaidSpinner(null)
            }
        })
          
    }

    const getTypeOfWaste =(id, k, val=true)=>{
        setSOWSpinnerBTnIndex(k)
        if(val){
        Api('get',`${SCOPEOFWORK_CRUD_TYPE_OF_WASTE}`).then( res => {
            console.log(res)
            setTypeofWaste(res);
        }
        )
        Api('get',`${SCOPOFWORK_CRUD_ADDITIONAL_INFO}`).then( res => {
            console.log(res)
            setAddInfoList(res);
            fetchQuoteTemplate(id, k, false, val)
        })
    }
        else{
            Api('get', PUMPASSETRATES).then(res => {
                console.log(res)
                setTypeofWaste({
                    loader : false,
                    data : res
                })
                fetchQuoteTemplate(id, k, false, val)
            })
        }
        
    }
    
    const filterDateFnFrom = (e) =>{
        console.log('FROMDATEFN',toDate,'e=>',moment(e).format('DD-MM-YYYY'))
        if(moment(toDate).diff(moment(e),'days')>=0){
            dispatch({
                type : 'TURNON_LOADER_COMPL_JOBS',
                loader : true
            })
            job_completedListFx( 1, searchDataFx, moment(e).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'), switchableSearchFnVal.value)
        }
    }
    const filterDateFnTo = (e) =>{
        console.log('TODATEFN',fromDate,'e=>',moment(e).format('DD-MM-YYYY'))
        if(moment(e).diff(moment(fromDate),'days')>=0){
            dispatch({
                type : 'TURNON_LOADER_COMPL_JOBS',
                loader : true
            })
            job_completedListFx( 1, searchDataFx, moment(fromDate).format('YYYY-MM-DD'), moment(e).format('YYYY-MM-DD'), switchableSearchFnVal.value)
        }
    }
    const refreshDateFn = () =>{
        job_completedListFx( 1, searchDataFx, null, null, switchableSearchFnVal.value)
        setFromDate(moment(new Date()).format('YYYY-MM-DD'))
        setToDate(moment(new Date()).format('YYYY-MM-DD'))
        console.log('REFRSHDATE=>',moment(new Date()).format('YYYY-MM-DD'))
    }
    const refreshPage = () => {
        setFromDate(moment(new Date()).format('YYYY-MM-DD'))
        setToDate(moment(new Date()).format('YYYY-MM-DD'))
        job_completedListFx( 1, null, jobComplFilterFrom, jobComplFilterTo)
    }


    const customStyles = {
        control : (styles) => ({...styles, borderRadius : '30px 10px 10px 30px', padding : 0, 
            borderTop:'none', borderBottom:'none', borderLeft:'none', fontSize:'.8rem', minHeight: 'unset',
            width:'6vw', }),
        // input : (styles) => ({...styles, height:'.2vh'})
    }
    // console.log(jobComplList)

    const completedJobCommentSuccess = () =>{
        setOpenCommentsBox(true)
    }

    console.log('jobcomplloader',jobComplLoader)
    return  (
            <div className='invoiceLayout'>

            <Scrollbars style={{ height:'65vh', width:'100%', marginBottom : '.2%', border:'1px solid lightgray', backgroundColor:'white', position:'relative' }} 
                       onUpdate={ScrollUp} >
                    <table className="accountsTableSec invoiceTableTdTh"
                        onWheel={(e)=>{
                            console.log('ONWHEEL',jobComplLoader,e.deltaY)
                            if(!jobComplLoader && e.deltaY<6 && e.deltaY>0){
                                setPageEndHeight(e.deltaY)
                            }else{
                                setPageEndHeight(0)
                            }
                        }}
                    >
                            <th>Job Code</th>
                            <th>Quote No</th>
                            <th>Completed Date</th>
                            <th>Client Id</th>
                            <th>Client Name</th>
                            <th>Quoted by</th>
                            <th>Quote Amt</th>
                            <th>Details</th>
                            <th>View Quote</th>
                            <th>Current Job Status</th>
                            <th>Status</th>
                            <th>Comments</th>
                        <tbody style={{ width:'100%' }}>
                            {
                            jobComplList && jobComplList.length!==0 ?
                            jobComplList.map((item,k) => {
                                return(
                                  
                            <tr key={k}
                                style={{
                                    backgroundColor: k%2===0 ? 'white' : 'var(--cl_ivery)'
                                }}
                            >
                                <td>{item.code}</td>
                                <td>QUOTE {item.job_card_code}</td>
                                <td>{item.schedule ?  
                                `${moment(item.schedule.completed_datetime).isValid() ?
                                    moment(item.schedule.completed_datetime).format('DD-MM-YYYY HH:mm a') 
                                    : '-'}` : '-' }</td>
                                <td>{item.client_id}</td>
                                <td>{item.client_name}</td>
                                <td>{item.sales_person}</td>
                                <td>{item.quote_invoice_amount}</td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }}>
                                        {
                                            salesJobSpinner && salesJobSpinnerBtnIndex === k ? (
                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                            ) : (
                                                <Tooltip title={item?.job_card_keys?.destruction_certificate_unread ? 'Certificate of destruction added' : ''}>
                                                    <button onClick={() => fetchQuoteInfos(item.id, k)} className="viewBtn" style={{ position:"relative" }}>
                                                    {
                                                        item?.schedule?.unread_comments_exists &&
                                                        <div className='invoiceButtonNotifyDrop'></div>
                                                    }
                                                    {
                                                        item?.job_card_keys?.destruction_certificate_unread &&
                                                        <div className='invoiceButtonNotifyDropBlue'></div>
                                                    }
                                                    View
                                                </button>
                                                </Tooltip>
                                                
                                            )
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-evenly', width: '100%', height: '80%' }}>
                                        {
                                            salesQuoteSpinnerBtnIndex === k ? (
                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                            ) : (
                                                <button onClick={() =>{
                                                    setJobId(item.id)
                                                    setQuoteId(item.quote_id)
                                                     fetchQuoteTemplate(item.quote_id, k)}} className="viewBtn">View Template</button>
                                            )
                                        }
                                        {
                                            sOWSpinnerBtnIndex === k ? (
                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                            ) : (
                                                <button onClick={() =>{
                                                    setJobId(item.id)
                                                    setQuoteId(item.quote_id)
                                                    getTypeOfWaste(item.quote_id, k, item.tab_type==='waste')
                                                     }} className="viewBtn">Update Pricing</button>
                                            )
                                        }
                                    </div>
                                </td>
                                <td>
                                    {
                                        item.schedule_status ?
                                        item.schedule ?
                                                item.schedule?.status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                                                :   item.schedule?.status === 'job_started'    ? 'Job Started'
                                                :   item.schedule?.status === 'job_finished'   ?   'Job Finished'
                                                :   item.schedule?.status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                                                :   item.schedule?.status === 'departed_waste_depot' ? 'Departed Waste Depot'
                                                :   item.schedule?.status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                                                :   item.schedule?.status === 'completed' ? 'Job Completed'
                                                :   '-'
                                            :   '-'
                                        :
                                        'Not Scheduled'
                                    }
                                </td>
                                
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }}>
                                        {
                                            item.paid_status ?
                                            salesJobPaidSpinner === k ? (
                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                            ) : (
                                                <button
                                                 onClick={() =>{
                                                    if(item.paid_status==='un-paid' || item.paid_status==='invoice-sent')
                                                        changePaidStatus(item.quote,k, item.paid_status==='un-paid' ? true : false)}} 
                                                    className="viewBtn"  
                                                    
                                                 style={{ fontWeight : '600',
                                                  fontSize : '.9rem',
                                                  textTransform : 'capitalize',
                                                  textShadow:'1% 1% black',
                                                  color : item.paid_status==='un-paid' || item.paid_status==='invoice-sent' ? 'darkgoldenrod' : 'green',
                                                  pointerEvents : item.paid_status==='completed' ? 'none' : 'auto', 
                                                  cursor : item.paid_status==='completed' ? 'default' : 'pointer'
                                                   }}>
                                                    {item.paid_status==='completed' ? item.paid_status : 'Invoice to be sent'}
                                                </button>
                                            )
                                            :
                                            '-'
                                        }
                                    </div>
                                </td>
                                
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }}>
                                        <button
                                            className="viewBtn" 
                                            style={{ fontWeight : '600',
                                            fontSize : '.9rem',
                                            backgroundColor : 'var(--cl_blue)',
                                            textTransform : 'capitalize',
                                            color : 'white',
                                            pointerEvents : 'auto', position:'relative',
                                            cursor : 'pointer', display:'flex', flexDirection:'row', justifyContent:'space-between'
                                             }}
                                            onClick={()=>{ 
                                                setCommentBoxLoader(k)
                                                setJobId(item.id)
                                                fetchAllComments(item.id, null,k, completedJobCommentSuccess,
                                                 ()=>{
                                                    setCommentBoxLoader(null)
                                                 })
                                                }}
                                        >
                                            {
                                                commentBoxLoader===k ?
                                                <span>
                                                    <CircularProgress size={'.9rem'} style={{ color : 'white' }} />
                                                </span>
                                                :
                                                <>
                                                    <span>Comments</span> 
                                                    {
                                                        item.total_job_comments ?
                                                        <span
                                                     style={{ display:'grid', placeItems:'center', fontSize:'.8rem', width:'1rem', height:'1rem', marginLeft:'1%',
                                                     backgroundColor:'white', color:'var(--cl_red)', position:'absolute', top:'-30%', left:'80%',
                                                     padding:'1%', borderRadius:'50%', border:'1px solid var(--cl_red)' }}>
                                                        {item.total_job_comments}
                                                     </span>
                                                        :   null
                                                    }    
                                                </>
                                            }
                                                                                        
                                        </button>
                                    </div>
                                </td>
                            </tr>
                              
                              )
                            })    
                            :
                            jobComplLoader  ?   null    
                            :
                            <tr>
                                <td colSpan={12}>
                                    <NoDatasScreen />
                                </td>
                            </tr>
                        }
                        {
                            jobComplLoader ?
                                <tr>
                                    <td colSpan={12}>
                                        <CircularProgress />
                                    </td>
                                </tr>
                            :   null
                        }
                        {
                            jobComplList && jobComplList.length!==0 && jobCompStop ?
                                <tr>
                                    <td colSpan={12}>
                                        <div 
                                            style={{
                                                borderRadius : '10px', padding:'2%', textAlign:'center', display:'grid', placeItems:'center',
                                                color:'white', fontWeight: '700', backgroundColor:'var(--cl_blue)'
                                            }}>
                                            You have reached the end of list
                                        </div>
                                    </td>
                                </tr>
                            :
                                null
                        }
                        </tbody>

                        {   jobComplLoader ? null :
                            <div className='pageEndScrollEffect' style={{ height : `${pageEndHeight}vh` }}></div>}
                    </table>
                    
            <QuoteModal
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                jobVal={jobVal}
                jobCardRefresfFn={()=>{
                    console.log('CALLEDJObRefres2');
                    refresh_completed_jobs(
                        jobComplPage, jobComplSearchKey, jobComplFilterFrom, jobComplFilterTo, 
                        jobComplSearchKey===null ? '' : switchableSearchFnVal.value
                    )
                }}
                commentRefreshFn={()=>{
                    refresh_completed_jobs(
                        jobComplPage, jobComplSearchKey, jobComplFilterFrom, jobComplFilterTo, 
                        jobComplSearchKey===null ? '' : switchableSearchFnVal.value
                    )
                }}
            />

            <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
            {
                mainTemplate ?
            <EditableEditorModal     
                jobId={quoteId}       
                Tempname={'Main Quote'}
                modalStatus={mainTemplate}
                modalAction={setMainTemplate}
                editorRef={templateRef}     
            />
                :   null
            }
            {
                sOWModal ?
                    <EditTableModal
                        jobId={quoteId}
                        modalStatus={sOWModal}
                        modalAction={setSOWModal}
                        QAmt={0}
                        typeOfWaste={typeOfWaste}
                        setTypeofWaste={setTypeofWaste}
                        addInfoList={addInfoList}
                        setAddInfoList={setAddInfoList}
                        setTemplateStr={set_quote_mainpage_content}
                        templateStr={quote_mainpage_content}
                        tableDataObj={quote_table_obj}
                    />
                    : null
            }
            {
                openCommentsBox &&
                <CommentsBoxModal
                    modalAction={()=>
                        {
                            setCommentBoxLoader(null)
                            setOpenCommentsBox(false)
                        }}
                    modalStatus={openCommentsBox}
                    jobId={jobId}
                    pos={commentBoxLoader}
                />
            }
            
            </Scrollbars>
            <div className='stickyFooterTable'>
                <div className='accDateFilterTab' style={{ width:'25%', justifyContent:'flex-start'}}>
                <ReactSelect
                        styles={customStyles}
                        isClearable={false}
                        isSearchable={false}
                        menuPlacement='top'
                        classNamePrefix={'react-select-customStyle'}
                        defaultValue={
                            { label : 'Clients', value : 'clients' }
                        }
                        options={
                            [
                                { label : 'Clients', value : 'clients' },
                                { label : 'Job Code', value : 'job' },
                                // { label : 'Quote Code', value : 'quote' },
                                { label : 'Quote No', value : 'ref_code' }
                            ]
                        }
                        value={switchableSearchFnVal}
                        onChange={(newValue)=>{
                            switchableSearchFn(newValue)
                        }}
                    />
                <SearchInputTab
                    cls={'searchInpSalesQuote'} styleHndle={{ width: '80%', minHeight:'2rem', padding: '0 20px' }} 
                    txt={`Search ${switchableSearchFnVal && switchableSearchFnVal.label}`}
                    url={`${ACCOUNTS_JOB_COMPLETED_LIST}?page=1&limit=25&search_type=${switchableSearchFnVal.value==='clients' ? 'client' : 
                    switchableSearchFnVal.value==='job' ? 'job_code' :
                    switchableSearchFnVal.value==='ref_code' ? 'ref_code' : 'quote'}`}
                    methodType={'get'}
                    searchKey={'key'}
                    dipatchforloading={()=>{
                        return({
                            type : 'TURNON_LOADER_COMPL_JOBS',
                            loader : true
                        })
                    }}
                    dispatchFn={(response) => {
                            return({
                                type : 'GET_ALL_COMPL_JOBS',
                                list : response,
                                page : 1,
                                search : searchDataFx,
                                from : jobComplFilterFrom,
                                to : jobComplFilterTo
                            })
                    }
                    }
                    fn={() => { setSearchDataFx(null); refreshPage(1); }}
                    setSearchDataFx={setSearchDataFx}
                />
                </div>
                {
                <div className='accDateFilterTab'>
                    <div className='accDateFilterDateTab'>
                        <span>From : </span>
                        <DateTimeInput
                            dateValue={fromDate}  dateSetFn={setFromDate}  rangeDateMax={toDate && moment(toDate).diff(moment(new Date()), 'days')!==0 ? moment(toDate).format('YYYY-MM-DD') : null} 
                            acceptFunc={filterDateFnFrom}
                        />
                    </div>
                    <div className='accDateFilterDateTab'>
                        <span>To : </span>
                        <DateTimeInput
                            dateValue={toDate}  dateSetFn={setToDate}  rangeDate={fromDate && moment(fromDate).diff(moment(new Date()), 'days')!==0 ? moment(fromDate).format('YYYY-MM-DD') : null}
                            acceptFunc={filterDateFnTo}
                        />
                    </div>    
                    <div style={{ borderRadius : '20px', color:'white', backgroundColor:'grey', padding:'.9%', cursor:'pointer' }} onClick={()=> refreshDateFn()}>
                        Clear
                    </div>
                </div>      
                }                  
            </div>
            </div>
    )
            
    
}

const mapStateToProps = (state) =>{
    const { jobComplList, jobComplLoader, jobComplPage, jobComplSearchKey, jobComplFilterFrom, jobComplFilterTo, jobCompStop  } = state.invoiceData
    const { quote_mainpage_content, quote_table_obj } = state.mainQuote

    return { jobComplList, jobComplLoader, jobComplPage, jobComplSearchKey, jobComplFilterFrom, jobComplFilterTo, quote_mainpage_content, quote_table_obj, jobCompStop }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        job_completedListFx: (page, search, from, to, searchVal) => dispatch(fetch_all_completed_jobs(page, search, from, to, searchVal)),
        fetch_quote_temp: (id, successFx, failedFx) => dispatch(fetch_curr_template(id, successFx, failedFx)),
        updateStatus: (id, k, data, success, failed) => dispatch(update_job_status(id,k,data,success,failed)),
        set_quote_mainpage_content: (content) => dispatch({ type: 'SET_MAIN_CONTENT_QUOTE', content: content }),
        fetchAllComments: (id, act, pos, success, failed) => dispatch(fetch_comments_of_job(id, act, pos, success, failed))

        
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (CompletedJobsList)
