import React from 'react';

function PartListDischargeFlange({modalType, dischargeFlange, setDischargeFlange}) {

    return (
        <>
            <div className="jobCardDivHead" >Discharge Flange</div>
                    <div className="jobCardInputDivSec" >
                        <div>Table</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.table}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Table" value={dischargeFlange.table} 
                            onChange={(e)=>setDischargeFlange({ ...dischargeFlange, table : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Diameter</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.diameter}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Diameter" value={dischargeFlange.diameter} 
                            onChange={(e)=>setDischargeFlange({...dischargeFlange, diameter : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pitch Circle Diameter (PCD)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.pcd}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pitch Circle Diameter (PCD)" value={dischargeFlange.pcd} 
                            onChange={(e)=>setDischargeFlange({...dischargeFlange, pcd : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pressuring Rating (PR)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.pr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pressuring Rating (PR)" value={dischargeFlange.pr}
                             onChange={(e)=>setDischargeFlange({...dischargeFlange, pr : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Number of holes</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.noOfHoles}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Number of holes" value={dischargeFlange.noOfHoles} 
                            onChange={(e)=>setDischargeFlange({...dischargeFlange, noOfHoles : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Bolt Length</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.bolt}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Bolt Length" value={dischargeFlange.bolt} 
                            onChange={(e)=>setDischargeFlange({...dischargeFlange, bolt : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Bossed Flange</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.boss}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Bossed Flange" value={dischargeFlange.boss} 
                            onChange={(e)=>setDischargeFlange({...dischargeFlange, boss : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pipe Diameter (PD)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{dischargeFlange.pipe}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pipe Diameter" value={dischargeFlange.pipe} 
                            onChange={(e)=>setDischargeFlange({...dischargeFlange, pipe : e.target.value})} />
                            }
                        </div>
                    </div>
                    

        </>
    );
}

export default PartListDischargeFlange;