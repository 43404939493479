import React, { useState } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
// import Jobs from './component/jobs'
import Home from './component/sales_table_components/sales_job_index'
import {GenerateQuote, GenerateQuoteDetail, QuoteRegister, WasteDescription, Marketing, PowerPoint, Pricing, Tenders, Salesperformancedetail } from './hills/index'

function Waste({ setSearchQuery }) {

    const { path } = useRouteMatch()
    const [selClientDet, setSelClient] = useState(null)

    return (
        <Switch>
            <Route path={path + '/generate-quote/:id'}>
                <GenerateQuoteDetail setSearchQuery={setSearchQuery} selClientDet={selClientDet} tabType={'hills'} />
            </Route>
            <Route path={path + '/generate-quote'}>
                <GenerateQuote setSelClient={setSelClient} />
            </Route>
            <Route path={path + '/quote-register'}><QuoteRegister tabType={'hills'} /></Route>
            <Route path={path + '/waste-description'} component={WasteDescription} />
            <Route path={path + '/marketing'} component={Marketing} />
            <Route path={path + '/power-point'} component={PowerPoint} />
            <Route path={path + '/pricing'} component={Pricing} />
            <Route path={path + '/tenders'} component={Tenders} />
            {/* <Route path={path + '/jobs/:jobId'} component={Jobs} /> */}
            <Route path={path + '/sales-performance/:urlId'}><Salesperformancedetail tabType={'hills'} /></Route>
            <Route path={path}>
                <Home tabType={'hills'}/>
            </Route>
        </Switch>
    )
}

export default Waste
