import React from 'react';

function PartListPanelDetail({modalType, panelDet, setPanelDet}) {

    return (
        <>
            <div className="jobCardDivHead" >Panel / Electrical Details</div>
                    <div className="jobCardInputDivSec" >
                        <div>Contractors</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.contractor}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Contractors" value={panelDet.contractor} 
                            onChange={(e)=>setPanelDet({ ...panelDet, contractor : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Coil Voltage</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.coil}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Coil Voltage" value={panelDet.coil} 
                            onChange={(e)=>setPanelDet({...panelDet, coil : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Overload</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.overload}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Overload" value={panelDet.overload} 
                            onChange={(e)=>setPanelDet({...panelDet, overload : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Range</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.range}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Range" value={panelDet.range}
                             onChange={(e)=>setPanelDet({...panelDet, range : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Auxiliaries</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.auxiliary}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Auxiliaries" value={panelDet.auxiliary} 
                            onChange={(e)=>setPanelDet({...panelDet, auxiliary : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Panel Dimensions</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.panelDimension}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Panel Dimensions" value={panelDet.panelDimension} 
                            onChange={(e)=>setPanelDet({...panelDet, panelDimension : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>BMS</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.bms}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="BMS" value={panelDet.bms} 
                            onChange={(e)=>setPanelDet({...panelDet, bms : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Auto Dialler</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.autoDia}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Auto Dialler" value={panelDet.autoDia} 
                            onChange={(e)=>setPanelDet({...panelDet, autoDia : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Panel Make</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.panelMake}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Panel Make" value={panelDet.panelMake} 
                            onChange={(e)=>setPanelDet({...panelDet, panelMake : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Indoor/Outdoor</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.indoor}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Indoor/Outdoor" value={panelDet.indoor} 
                            onChange={(e)=>setPanelDet({...panelDet, indoor : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Control Sensor</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{panelDet.controlSensor}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Control Sensor" value={panelDet.controlSensor} 
                            onChange={(e)=>setPanelDet({...panelDet, controlSensor : e.target.value})} />
                            }
                        </div>
                    </div>
                    

        </>
    );
}

export default PartListPanelDetail;