import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Scrollview from '../ImprovedScrollBar';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import QuoteTableData from './quoteTableData';
import { CircularProgress } from '@material-ui/core';
import Api from '../../store/api'
import { SCOPEOFWORK_CRUD_TYPE_OF_WASTE, SCOPOFWORK_CRUD_ADDITIONAL_INFO } from '../../store/endpoint';
import moment from 'moment';


const Index = ({ quote_mainpage_content, set_quote_mainpage_content, editorRef, clientId, set_quote_amount, setTableDataObj, setupSalesPerson=null, user, reEditting=false, tableDataObj=null, set_table_obj, newjobcodename='', nonUpdateFn=null,
connectedJobCardData=null }) => {
    const getSunEditorInstance = (sunEditor) =>{
        editorRef.current = sunEditor
    }

    const [editTable, setEditTable] = useState(false)
    const [clientDet, setClientDet] = useState(null)
    const [loader, setLoader] = useState(false)

    const [typeOfWaste, setTypeofWaste] = useState([])
    const [addInfoList, setAddInfoList] = useState([])

    const [quoteContent, setQuoteContent] = useState(quote_mainpage_content ? quote_mainpage_content : '')

    let apiStatus = 0

    useEffect(()=>{
        console.log('QUOTEUSEFFECTCALLED!!!!',clientDet,clientId,reEditting,connectedJobCardData)
        if(clientDet!==clientId && !reEditting){
            console.log('CLIENTDET',clientId)
            setClientDet(clientId)
                let qNo     = `<!--Quotenumberstart--><p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;">
                <span class="c11" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 15px;font-family:Arial, sans-serif;font-style: normal;">${newjobcodename}</span></p><!--Quotenumberend-->`
                let qDate   = `<!--Quotedatestart--><p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;">
                <span class="c11" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 15px;font-family:Arial, sans-serif;font-style: normal;">${moment().format('DD/MM/YYYY')}</span></p><!--Quotedateend-->`
                let cName   = `<!--companynamestart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.company_name ?
                    connectedJobCardData?.company_name : connectedJobCardData?.customer_name ? connectedJobCardData?.customer_name : clientId.client_name===null ? '' : clientId.client_name}</span></p><!--companynameend-->`
                let cEmail  = `<!--companyemailstart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.company_email ? connectedJobCardData?.company_email : connectedJobCardData?.customer_contact_email ?
                    connectedJobCardData?.customer_contact_email : clientId.client_email ? clientId.client_email : (clientId.company_email===null || clientId.company_email==='null') ? '' : clientId.company_email}</span></p><!--companyemailend-->`
                let cAddr   = `<!--companyaddressstart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.company_address ? connectedJobCardData?.company_address : connectedJobCardData?.customer_address ? 
                    connectedJobCardData?.customer_address :  (clientId.site_address===null || clientId.site_address==='null') ? '' : clientId.site_address}</span></p><!--companyaddressend-->`
                let cPhone  = `<!--companyphonestart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c17" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.company_contact_phone ? connectedJobCardData?.company_contact_phone : connectedJobCardData?.customer_contact_phone ?
                    connectedJobCardData?.customer_contact_phone : (clientId.company_contact_number===null || clientId.company_contact_number==='null') ? '' : clientId.company_contact_number}</span></p><!--companyphoneend-->`
                let sAddr   = `<!--siteaddressstart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.site_address ?? ''}</span></p><!--siteaddressend-->`
                let sConName    = `<!--sitecontactstart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.site_contact_person ?? ''}</span></p><!--sitecontactend-->`
                let sConEmail   = `<!--sitecontactemailstart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.site_contact_email ?? ''}</span></p><!--sitecontactemailend-->`
                let sConPhone   = `<!--sitecontactphonestart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${connectedJobCardData?.site_contact_phone ? connectedJobCardData?.site_contact_phone : connectedJobCardData?.site_phone_no ? connectedJobCardData?.site_phone_no : ''}</span></p><!--sitecontactphoneend-->`
                let salesExe    = `<!--salesexecutivestart--><p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left; min-height: 15px;">
                <span class="c5" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${setupSalesPerson ? setupSalesPerson.name : user.name}</span></p><!--salesexecutiveend-->`
    
                let currTemp0 = quote_mainpage_content.substring(0,quote_mainpage_content.indexOf('<!--Quotenumberstart-->')).replace('<!--Quotenumberstart-->','') 
                currTemp0 += qNo + quote_mainpage_content.substring(quote_mainpage_content.indexOf('<!--Quotenumberend-->'),quote_mainpage_content.length).replace('<!--Quotenumberend-->','')
    
                let currTemp = currTemp0.substring(0,currTemp0.indexOf('<!--Quotedatestart-->')).replace('<!--Quotedatestart-->','')
                // console.log('CURRTEMP',currTemp)
                currTemp += qDate + currTemp0.substring(currTemp0.indexOf('<!--Quotedateend-->'),currTemp0.length).replace('<!--Quotedateend-->','')
                // console.log('CURRTEMP2',currTemp)
    
                let currStart = currTemp.substring(0,currTemp.indexOf('<!--companydetailsstart-->')).replace('<!--companydetailsstart-->','')
                let currEnd = currTemp.substring(currTemp.indexOf('<!--sitedetailsend-->'),currTemp.length).replace('<!--sitedetailsend-->','')
                // console.log('currStart',currStart)
                // console.log('currEnd',currEnd)
    
                let temp = `<!--companydetailsstart-->
                <table class="c8" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;width:100%;"><tbody><tr class="c29" style="min-height: 27px;">
                <td class="c4" colspan="4" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;vertical-align: top;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 746px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-left-color: #00b0f0 !important;border-right-color: #00b0f0 !important;background-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c16" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 19px;font-family:Arial, sans-serif;font-style: normal;">Company Details</span></p></td></tr><tr class="c28" style="min-height: 0px;">
                <td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Company Name</span></p>
                </td><td class="c3" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;background-color: #ffffff;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${cName}
                </td><td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Company Email</span></p>
                </td><td class="c3" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;background-color: #ffffff;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${cEmail}
                </td></tr><tr class="c28" style="min-height: 0px;"><td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Company Address</span></p>
                </td><td class="c3" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;background-color: #ffffff;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${cAddr}
                </td><td class="c14 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Company Phone</span></p>
                </td><td class="c3" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: middle;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;background-color: #ffffff;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${cPhone}
                </td></tr></tbody></table><!--companydetailsend--><p class="c9 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.15;orphans: 2;widows: 2;text-align: left;height: 15px;"><span class="c21" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 13px;font-family:Arial, sans-serif;font-style: normal;"></span></p><a id="t.df96ed6ef118e5a1039f558cc1eae2d2781beb7d"></a><a id="t.3"></a>
                <!--sitedetailsstart--><table class="c8" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;width:100%;"><tbody><tr class="c29" style="min-height: 27px;">
                <td class="c4" colspan="4" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;vertical-align: top;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 746px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-left-color: #00b0f0 !important;border-right-color: #00b0f0 !important;background-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c16" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 19px;font-family:Arial, sans-serif;font-style: normal;">Site Details</span></p>
                </td></tr><tr class="c29" style="min-height: 27px;"><td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Site Address</span></p>
                </td><td class="c27" colspan="3" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 559px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${sAddr}
                </td></tr><tr class="c28" style="min-height: 0px;"><td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Site Contact Name</span></p>
                </td><td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${sConName}
                </td><td class="c15 c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Site Contact Email</span></p>
                </td><td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${sConEmail}
                </td></tr><tr class="c28" style="min-height: 0px;"><td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Site Contact Phone</span></p>
                </td><td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                ${sConPhone}
                </td><td class="c22 c15" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;background-color: #d7eff8;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;">
                <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Sales Executive</span></p>
                </td><td class="c22" colspan="1" rowspan="1" style="padding: 16px 16px 16px 16px;border-right-style: solid;border-top-width: 2px;border-right-width: 2px;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 2px;border-top-style: solid;border-left-style: solid;border-bottom-width: 2px;width: 186px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #00b0f0 !important;border-top-color: #00b0f0 !important;"> 
                ${salesExe}
                </td></tr></tbody></table><!--sitedetailsend-->`
    
                currTemp = currStart + temp + currEnd;
    
                // console.log('MAINQUOTEUSEEFFECTCALLED',currTemp)
                if(reEditting){
                    if(nonUpdateFn)
                        nonUpdateFn(currTemp)
                    setQuoteContent(currTemp)
                }else if(currTemp!==quote_mainpage_content)
                    set_quote_mainpage_content(currTemp)
            }
    },[
        clientDet, clientId, nonUpdateFn, newjobcodename, quote_mainpage_content, reEditting, set_quote_mainpage_content, setupSalesPerson, user.name, connectedJobCardData
    ])
        
    const getTypeOfWaste =()=>{
                            
        Api('get',`${SCOPEOFWORK_CRUD_TYPE_OF_WASTE}`).then( res => {
            console.log(res)
            setTypeofWaste(res);
            apiStatus = apiStatus+1
            if(apiStatus=== 2){
                apiStatus = 0
                setLoader(false)
                setEditTable(true);
            }
            
        })
        Api('get',`${SCOPOFWORK_CRUD_ADDITIONAL_INFO}`).then( res => {
            console.log(res)
            setAddInfoList(res);
            apiStatus = apiStatus+1
            if(apiStatus=== 2){
                apiStatus = 0
                setLoader(false)
                setEditTable(true);
            }
        })
    }
    // console.log('QUOTECONTENT_MAIN=>',quote_mainpage_content && quote_mainpage_content.includes('hey'))
    // console.log('QUOTECONTENT=>',quoteContent && quoteContent.includes('hey'))
    // console.log('REEDDITTINg=>',reEditting)

    const updateTableObj = (val) =>{
        setTableDataObj(val)
        set_table_obj(val)
    }


    return (
        <div className="sales-qt-client-info suppressScrollZindex" style={{ marginRight:'unset', position:'relative' }}>
            <Scrollview style={{ height: '60vh', width: '100%'}}>
            {
                reEditting ? null :
                typeof clientId === 'object' && clientId!==null ?
                <div className='salesQtBtnFloat'>
                    <button 
                    id="updateScopeOfWorkButton"
                    className={editTable ? "sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" : "sales-qt-rgstr-editor-sts-btn"}
                    onClick={() =>{ 
                        setLoader(true)
                        getTypeOfWaste(); }}  style={{ height:'3vh', width:'15vw', margin:'8px 0' }}
                >{
                    loader ?
                    <CircularProgress size={10} /> :
                'Update Pricing'}</button>
                </div>
                
                :   
                <p style={{ color:'red' }}>Choose the client to edit the template</p>
            }
            
            {   quote_mainpage_content!=='' && quote_mainpage_content && typeof quote_mainpage_content!=='undefined' &&
                (quote_mainpage_content?.indexOf('<style>')===0 || quoteContent?.indexOf('<style>')===0) ?
                    <p style={{ color:'red' }}>This is an old Quote Template. Updation is not possible.</p>
                :   null
            }
            {
                quote_mainpage_content!=='' && quote_mainpage_content && typeof quote_mainpage_content!=='undefined' ?
                <div style={{ marginRight:'1%', marginTop:'3vh' }}>
                <SunEditor
                    defaultValue={'<p>Loading content...</p>'}
                    
                    setContents={reEditting ? quoteContent : quote_mainpage_content}
                    getSunEditorInstance={getSunEditorInstance}
                    placeholder='Content loading...'
                    onChange={(e)=>{
                        console.log('ONCHANGEDCALLED!!!!!!!!')
                        if(quote_mainpage_content?.indexOf('<style>')===0 || quoteContent?.indexOf('<style>')===0)
                            console.log('OLDQUOTE')
                        else if(reEditting && e!==quoteContent){
                            setQuoteContent(e)
                            if(nonUpdateFn)
                                nonUpdateFn(e)
                        }else if(e!==quote_mainpage_content){
                            set_quote_mainpage_content(e)
                        }
                    }}
                    onBlur={(_,e)=>{
                        console.log('ONBLURCALEED!!!!!!!!')
                        if(quote_mainpage_content?.indexOf('<style>')===0 || quoteContent?.indexOf('<style>')===0)
                            console.log('OLDQUOTE')
                        else if(!reEditting && e!==quote_mainpage_content){
                            set_quote_mainpage_content(e)
                        }
                    }}
                    height='50%'
                    width='100%'
                    setDefaultStyle={`<style>
                    /* Font styles */
                    *{
                      font-family: 'Arial', 'sans-serif';
                    }
                    tbody {
                        font-family: 'Arial', 'sans-serif';
                    }
                
                    /* Table styles */
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        border: 1px solid #ccc;
                    }
                
                    th, td {
                        padding: 8px;
                        border: 1px solid #ccc;
                    }
                
                    th {
                        background-color: #f2f2f2;
                    }
                
                    /* Set font for table */
                    table {
                        font-family:Arial, sans-serif;
                    }
                </style>`}
                    readOnly={quote_mainpage_content?.indexOf('<style>')===0 || quoteContent?.indexOf('<style>')===0}
                    setOptions={{
                        defaultStyle: `font-fmily: 'Arial','Open Sans'. 'sans-serif'`,
                        resizingBar : false,
                        resizeEnable : false,
                        addTagsWhitelist : '*',
                        font : [
                            'Arial', "Open Sans", 'sans-serif'
                        ],
                        attributesWhitelist: {
                            'all' : 'style|data-.+',
                            'input' : 'checked|name',
                            '???' : '*'
                        },
                        stickyToolbar: 0,
                        buttonList : [
                            ['undo','redo'], 
                            ['font','fontSize','fontColor','textStyle','link'],
                            ['bold','underline','italic','strike','subscript','superscript'],
                            ['outdent', 'indent'], ['fullScreen'],
                            ['codeView']
                        ],
                        alignItems : ['left', 'right', 'center', 'justify']
                    }}
                    
                />
                </div>
                :
                <p>ERROR IN LOADING QUOTE</p>
            }
            {   editTable && 
            <QuoteTableData
                modalStatus={editTable}
                setModalStatus={setEditTable}

                templateStr={quote_mainpage_content} 
                typeOfWaste={typeOfWaste}
                setTableDataObj={updateTableObj}
                setTemplateStr={set_quote_mainpage_content} 
                clientDet={clientId}
                setQuoteAmount={set_quote_amount}
                setTypeofWaste={setTypeofWaste}
                addInfoList={addInfoList}
                setAddInfoList={setAddInfoList}
                tableDataObj={tableDataObj}
            />
            }
            </Scrollview>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { quote_mainpage_content, quote_date, quote_page } = state.mainQuote
    const { user } = state.userLogin 

    return { quote_mainpage_content, user, quote_date, quote_page }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_quote_mainpage_content: (content) => dispatch({ type: 'SET_MAIN_CONTENT_QUOTE', content: content }),
        set_quote_amount: (amount) => dispatch({ type: 'SET_MAIN_QUOTE_AMOUNT', amount: amount }),
        set_table_obj: (tableObj) => dispatch({ type : 'SET_MAIN_QUOTE_TABLE_OBJ', tableObj: tableObj})
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (Index);