import React from 'react'
import Sidebar from '../../../../common/sidebar'
import { Members, Settings, Header } from '../index'

const __MainStack = () => {
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='main'/>
                <div className="team-main-layout">
                    <Members 
                        // team_list={team_list} 
                        // select_employee={select_employee} 
                        // isLoading={isLoading} 
                        // search_employee={search_employee}
                    />
                    <Settings />
                </div>
            </div>
        </div>
       
    )
}

export default __MainStack
