import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Scrollbars from '../../../../common/ImprovedScrollBar';
import { CancelOutlined, EditRounded } from '@material-ui/icons';
import Api from '../../../../store/api'
import { SITE_INDUSTRY_ADD, SITE_INDUSTRY_EDIT, SITE_INDUSTRY_LIST } from '../../../../store/endpoint';
import Swal from 'sweetalert2';
import Failerrdevmsg from '../../../../common/failErrDevMsg';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalLow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '7 !important'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

function IndustryModal({modalStatus, modalAction}) {
    const classes = useStyles();
    const [industryList, setIndustryList] = useState([])
    const [inpVal, setInpVal] = useState('')
    const [indId, setIndId] = useState(null)

    useEffect(() => {
        Api('get',SITE_INDUSTRY_LIST,'').then(response => {
            console.log('INDUSTRY',response)
            setIndustryList(response)
        })
        
    }, []);

    const handleClose = () => {
        modalAction(false)
    };

    const addIndType = () =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Industry Type ${indId!==null ? 'Edited' : 'Added'} successful!`,
                showConfirmButton: false,
                timer: 1500
            })
            Api('get',SITE_INDUSTRY_LIST,'').then(response => {
                console.log('INDUSTRY',response)
                setIndustryList(response)
            })
        }
        const failed = (err) => {
            Failerrdevmsg(err)
        }
        let postdata = { 'industry_name' : inpVal }
        Api(indId!==null ? 'put-json' : 'post', indId!==null ? `${SITE_INDUSTRY_EDIT}${indId}/` : SITE_INDUSTRY_ADD,postdata).then((res) => {
            console.log(res)
            success()
            setInpVal('')
            setIndId(null)
            handleClose()
        }).catch((err) => {
            failed(err)
        })
    }

    return (
        <Modal
            className={ classes.modal}
            open={modalStatus}
            onClose={handleClose}
        >
            <div className='industryModalDiv' style={{ overflowY:'auto' }}>
                <div className='industryModalDivHeader'>
                    <p>Industry Type</p>
                    <CancelOutlined onClick={handleClose}/>
                </div>
                <div className='industryModalList'>
                    <Scrollbars>
                        <table style={{ position: 'relative', height: 'auto' }}>
                            <tbody>
                                <tr style={{ backgroundColor:'var(--cl_blue)' }}>
                                    <th>Industry Type</th><th>Edit</th>
                                </tr>
                                {   industryList && industryList.length!==0 && industryList.map(el => {

                                        return (
                                            <tr>
                                                <td>{el.industry_name}</td>
                                                <td><EditRounded onClick={()=>{ setIndId(el.id); setInpVal(el.industry_name); }}/></td>
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </table>
                    </Scrollbars>
                </div>
                <div className='industryTypeFooter'>
                    <input type='text' value={inpVal} onChange={(e)=>{ setInpVal(e.target.value) }}/>
                    <div className='industryTypeAddBtn' onClick={()=> addIndType()}>
                        {indId!==null ? 'Edit' : 'Add'} Industry Type
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default IndustryModal;