import { Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useState } from 'react'

const PasswordInputFormatter = (props) => {
    const [hide, setHide] = useState(false)
    if(props?.outerDiv)
  return (
    <div className={props.outerDiv?.className} style={{ position:'relative' }}>
        <input 
            type={!hide ? 'password' : 'text'}
            {...props}
        />
        <span   onClick={()=>setHide(!hide)}
            style={{
                position : 'absolute',
                right : 10,
                top : 0,
                display: 'grid', placeContent : 'center',
            }}
        >
            {
                !hide ?
                    <Visibility style={{fontSize:'1rem'}}/>
                :
                    <VisibilityOff style={{fontSize:'1rem'}}/>
            }
        </span>
    </div>
  )
  else{
    return(
        <>
        <input 
            type={!hide ? 'password' : 'text'}
            {...props}
            style={props?.style ? 
                {
                ...props?.style, position : 'relative' 
                }   :
                {
                    position : 'relative'
                }}
        />
        <span   onClick={()=>setHide(!hide)}
            style={{
                position : 'absolute',
                right : 30,
                height: '100%',
                top:0,
                display: 'grid', placeContent : 'center',
            }}
        >
            {
                !hide ?
                    <Visibility style={{fontSize:'1rem'}}/>
                :
                    <VisibilityOff style={{fontSize:'1rem'}}/>
            }
        </span>
    </>
    )
  }
}

export default PasswordInputFormatter