import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import SendIcon from '@material-ui/icons/Send';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CancelIcon from '@material-ui/icons/Cancel';
import { CircularProgress, Modal } from '@material-ui/core'
import { connect } from 'react-redux'
// import VehicleLayout from './VehicleLayout'
// import TeamLayout from './team_layout_available_only'
import Swal from 'sweetalert2'
import { cl_green, cl_red } from '../../../common/Colors'

import Scrollbars from '../../../common/ImprovedScrollBar.jsx';

// import { Fragment } from 'react';
// import { HOST_WITHOUT_SLASH, SCHEDULE_ADD_IMAGE, SCHEDULE_COMMENT } from '../../../store/endpoint';
// import Api from '../../../store/api'
import DateTimeInput from '../../../common/DateTimeInput';
import moment from 'moment'
import { fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../common/action';
// import { fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../common/action';

import Draggable from 'react-draggable';
import { CancelRounded, DragIndicatorRounded } from '@material-ui/icons'
// import SignaturePadModal from '../../../common/SignaturePadModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalLow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },  
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    selectedJob,

    openJobDetailModal,

    StartDate,
    setStartDate,
    StartTime,
    setStartTime,
    EndTime,
    setEndTime,
    EndDate,
    setEndDate,
    scheduleId,

    schduleStatus,
    setScheduleStatus,

    updateScheduleFx,
    deleteScheduleFx,
    
    companyName, 
    setCompanyName,
    
    screen_type,

    // fetch_availabilityVeh,
    // fetch_availabilityEmp,
    setContentLoader=null,

    allVehArrObj,
}) {

    const classes = useStyles();
    const handleClose = () => {
        if(setContentLoader!==null)
            setContentLoader(null)

        // fetch_availabilityVeh('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'), screen_type);
        // fetch_availabilityEmp('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'));
        
        modalAction(false)
    };
    const { amount, client, address, job, quote_id } = selectedJob
    
    const [startHours, setStartHours] = useState(moment(StartTime,'HH:mm').hours())
    const [startMinutes, setStartMinutes] = useState(moment(StartTime,'HH:mm').minutes())
    const [endHours, setEndHours] = useState(moment(EndTime,'HH:mm').hours())
    const [endMinutes, setEndMinutes] = useState(moment(EndTime,'HH:mm').minutes())


    const [isLoader, setIsLoader] = useState(false)
        
    let applyButtonDisabled = true
    let changeStatusDate = false
    const deleteScheduleing = () => {
        handleClose()
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                deleteScheduleFx(scheduleId)
            }
        })
    }

        if(selectedJob.start_date !== StartDate || selectedJob.start_time !== StartTime || selectedJob.end_time !== EndTime || selectedJob.end_date !== EndDate || selectedJob.status !== schduleStatus) {
            changeStatusDate= true
        } else {
            changeStatusDate= false
        }
    

    if(changeStatusDate || companyName!==selectedJob.name ) {
        applyButtonDisabled= false 
    }

    // const setDefaultDates = (e) =>{
    //     let startSetTime = moment(StartTime).isValid() ? moment(StartTime).format('HH:mm:ss') : moment().format('HH:mm:ss')
    //     let endSetTime = moment(EndTime).isValid() ? moment(EndTime).format('HH:mm:ss') : moment().format('HH:mm:ss')
    // }


    const isTimeInCorrect = () =>{
        console.log( moment(StartTime,'HH:mm').isValid(),moment(EndTime,'HH:mm').isValid(),StartTime,EndTime)
        if(StartTime && EndTime && moment(StartTime,'HH:mm').isValid() && moment(EndTime,'HH:mm').isValid()
        && (moment(StartDate).format('YYYY-MM-DD')===moment(EndDate).format('YYYY-MM-DD'))){
            var strTm = moment(StartTime, 'HH:mm');
            var endTm = moment(EndTime, 'HH:mm');
            var duration = moment.duration(endTm.diff(strTm))
            var hr = duration.asHours();
            var min = parseInt(duration.asMinutes())-hr*60;
            console.log(strTm,endTm,duration,hr,min)
            return (hr<0 || min<0);
        }
        else
            return false
        
    }

    
    return (
        <Draggable handle="strong"
        bounds={{top: -parseInt(window.innerHeight/12),
             left: -parseInt(window.innerWidth/2), 
             right: parseInt(window.innerWidth/2), 
             bottom: parseInt(window.innerHeight - (window.innerHeight/6))}} 
         >
      <Modal
        className={classes.modalLow}
        open={modalStatus}
        onClose={handleClose}
        hideBackdrop={true}
      >
          <div className="shdl-modal-layout-mainn">
          <strong className="draggerHead" id="draggerHeadId">
              <div style={{ width:'50%' }}>
                  <DragIndicatorRounded style={{ transform:'rotate(-90deg)', cursor:'all-scroll' }} />
              </div>
              <div>
                  <CancelRounded style={{ color:'red', cursor:'pointer' }} onClick={handleClose} />
              </div>
            </strong>
            <div className="shdl-modal-layout">
            <Scrollbars style={{ height:'65vh', width:'380px', marginRight:'10px' }}>
                <div className="shdl-modal-left-lay">
                    
                    <h4>Scheduled Job <span style={{ cursor: 'pointer'}} onClick={() => openJobDetailModal(quote_id, false)}><u># {job}</u></span></h4>
                    <div className="shdl-modal-status">
                        
                    </div>
                    {/* {console.log(StartDate,StartTime,EndTime,EndDate,moment(EndTime, 'HH:mm:ss',true).isValid())} */}
                    <p className="shdl-modal-titles">{client}</p>
                    <p className="shdl-modal-titles">{address}</p>
                    <p className="shdl-modal-titles">{amount}$</p>

                    <div className="shdl-modal-first-layout">
                    <p>Start Date</p>
                    <div style={{ padding: '10px 0px' }}>
                        <DateTimeInput
                            dateValue={StartDate}
                            dateSetFn={setStartDate}
                            clsName={'client-input'}
                            past={true}
                        />
                    </div>

                    <p>Start Time</p>

                    <div className="schdl-timing-box-main">
                    
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setStartHours(parseInt(e.target.value)); 
                                    
                                    setStartTime(moment({hours:parseInt(e.target.value), minutes:isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes }).format('HH:mm'));
                            }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        
                                        return (<option 
                                            selected={isNaN(startHours) ?
                                                (moment(StartTime,'HH:mm').hours()>12 && moment(StartTime,'HH:mm').hours()-12 === 12-key) || (moment(StartTime,'HH:mm').hours()===0 && 12-key===12) || moment(StartTime,'HH:mm').hours()===12-key ? true : false
                                                :   (startHours>12 && startHours-12 === 12-key) || (startHours===0 && 12-key===12) || startHours===12-key ? true : false}
                                                value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        
                        </div>
                        <div className="schdl-timing-box">
                            <select
                                className="schdl-timing-input"  
                                onChange={(e) => { setStartMinutes(parseInt(e.target.value)); 
                                    
                                setStartTime(moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes:parseInt(e.target.value)}).format('HH:mm'));
                            }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '0' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option 
                                            selected={ isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes()===key ? true : false 
                                            : startMinutes===key ? true : false}
                                            
                                            value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input"
                                id="startTimeMeridian"
                                onChange={(e)=>{    
                                                if(e.target.value==='pm')
                                                    if(isNaN(startHours) ? moment(StartTime,'HH:mm').hours()!==12 : startHours!==12)
                                                    {
                                                        let temp = parseInt(isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours)+12
                                                        setStartTime(moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'));
                                                    }
                                                    else{
                                                        
                                                        setStartTime(moment({hours:0, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))
                                                    }
                                                else 
                                                    if(isNaN(startHours) ? moment(StartTime,'HH:mm').hours()>11 : startHours>11)
                                                    {
                                                        let temp = parseInt(isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours)-12
                                                                    setStartTime(moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'));
                                                    }
                                                else{
                                                        setStartTime(moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))
                                                    }
                                                    // setStartTime(moment({hours:  isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))

                                                
                                                }}
                            >
                                <option selected={isNaN(startHours) ? moment(StartTime,'HH:mm').hours()<12 : startHours<12} value={'am'}>AM</option>
                                <option selected={isNaN(startHours) ? moment(StartTime,'HH:mm').hours()>11 : startHours>11} value={'pm'}>PM</option>
                            </select>
                            
                        </div>
                    
                    </div>
                        
                    <p>End Date</p>
                    <div style={{ padding: '10px 0px' }}>
                        <DateTimeInput
                            dateValue={EndDate}
                            dateSetFn={setEndDate}
                            clsName={'client-input'}
                            past={true}
                            rangeDate={StartDate}
                        />
                    </div>

                    <p>End Time</p>
                    <div className="schdl-timing-box-main">
                    
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setEndHours(parseInt(e.target.value)); 
                                    setEndTime(moment({hours:parseInt(e.target.value), minutes:isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes }).format('HH:mm'));
                                }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        return (<option 
                                            selected={isNaN(endHours) ?
                                                (moment(EndTime,'HH:mm').hours()>12 && moment(EndTime,'HH:mm').hours()-12 === 12-key) || (moment(EndTime,'HH:mm').hours()===0 && 12-key===12) || moment(EndTime,'HH:mm').hours()===12-key ? true : false
                                                :   (endHours>12 && endHours-12 === 12-key) || (endHours===0 && 12-key===12) || endHours===12-key ? true : false}
                                            value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        
                        </div>
                        <div className="schdl-timing-box">
                            <select
                                className="schdl-timing-input"  
                                onChange={(e) => { setEndMinutes(parseInt(e.target.value)); 
                                setEndTime(moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes:parseInt(e.target.value)}).format('HH:mm')); 
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '0' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option 
                                            selected={ isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes()===key ? true : false 
                                            : endMinutes===key ? true : false}
                                            
                                            value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input"
                                id="endTimeMeridian"
                                onChange={(e)=>{    
                                    if(e.target.value==='pm')
                                        if(isNaN(endHours) ? moment(EndTime,'HH:mm').hours()!==12 : endHours!==12)
                                        {
                                            let temp = parseInt(isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours)+12
                                            setEndTime(moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'));
                                        }
                                        else{
                                            
                                            setEndTime(moment({hours:0, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'))
                                        }
                                    else 
                                        if(isNaN(endHours) ? moment(EndTime,'HH:mm').hours()>11 : endHours>11)
                                        {
                                            let temp = parseInt(isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours)-12
                                                        setEndTime(moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'));
                                        }
                                    else{
                                            setEndTime(moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'))
                                        }
                                                                            
                                    }}
                            >
                                <option selected={isNaN(endHours) ? moment(EndTime,'HH:mm').hours()<12 : endHours<12} value={'am'}>AM</option>
                                <option selected={isNaN(endHours) ? moment(EndTime,'HH:mm').hours()>11 : endHours>11} value={'pm'}>PM</option>
                            </select>
                            
                        </div>
                       
                    </div>
                        
                        <p>Job Status</p>
                        <select
                            value={schduleStatus}
                            onChange={val => {
                                setScheduleStatus(val.target.value)
                            }}
                        >
                            <option value="pending">Pending</option>
                            <option value='departed_enviro_facility'>Departed Enviro Facility</option>
                           <option value='job_started'>Job Started</option>
                           <option value='job_finished'>Job Finished</option>
                           <option value="completed">Job Completed</option>
                           <option value='arrived_at_waste_depot'>Arrived At Waste Depot</option>
                           <option value='departed_waste_depot'>Departed Waste Depot</option>
                           <option value='arrived_at_enviro_facility'>Arrived At Enviro Facility</option>
                           
                        </select>
                        <p>Company Name</p>
                    <div style={{ padding: '10px 0px' }}>
                    <input
                        type="text"
                        value={companyName}
                        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 5px', justifyContent:'center', borderBottom: '1px solid grey' }}
                        className='client-input'
                        onChange={(e) =>{ setCompanyName(e.target.value); }}
                    />
                    </div>
                    </div>

                </div>
                </Scrollbars>
                
            </div>

            <div style={{ display: 'flex', justifyContent: 'center'  }}>
            {   schduleStatus!=='completed' ?
            <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn-dlt">
                <button 
                    onClick={() => deleteScheduleing()}
                >Delete</button>
            </div>
            :   null
            }
            {
                isLoader ?
                <button className="disabled-btn">
                    <CircularProgress size={20}/>
                </button>
            :
                isTimeInCorrect() ?
                <button className="disabled-btn"
                    style={{ color:'red' }}
                >Time entered is incorrect.</button>
                :
                applyButtonDisabled ? (
                    <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                        <button className="disabled-btn">Apply</button>
                    </div>

                ) : (
                    <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                        <button 
                            onClick={() =>{ 
                                setIsLoader(true)
                                updateScheduleFx( true,
                                    true, true, setIsLoader);}}
                        >Apply</button>
                    </div>
                )
            }

            </div>
        </div>
        </Modal>
        </Draggable>
  );
}

const mapStateToProps = (state) => {
    const { vehicle_listDateTime } = state.schedule
    return { vehicle_listDateTime }
}

const mapDispatchProps = (dispatch) =>{
    return{
        fetch_availabilityVeh : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp : (filter) => dispatch(fetch_Employee_avilabilities(filter))
    }
}

 export default connect(mapStateToProps, mapDispatchProps) (TransitionsModal)
 
