import axios from 'axios'
import Swal from 'sweetalert2'
import { store } from './store'

export default function request(type, url, params, success, failed){
    
    let state = store.getState()
    let token = state.userLogin.authToken

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    const failed_validation = (failed=null, error=null, data=null) => {
        
        console.log('FAILED VALIDATION', typeof failed, failed, error)
        // type, url, params, success, 

        if(error && error.response && error.response.status){
            if(error.response.status===429){
                console.log('ERR429:',error)
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Too many requests!!!',
                    text: 'Network traffic is high. Please wait a minute or two.',
                    target: '#custom-target',
                    customClass: {
                      container: 'position-absolute'
                    },
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
        }
        else if(error === 'Error: Network Error'){
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'No Internet',
                text: 'Something wrong with your network. Please check your connection.',
                target: '#custom-target',
                customClass: {
                  container: 'position-absolute'
                },
                toast: true,
                showConfirmButton: false,
                timer: 1500
              })
        }

        if(error!==null && failed!==null){
            failed(error ? error.response ? error.response.data ? error.response.data : error.response : error : '')
        }
        else if(data!==null && failed!==null){
            failed(data)
        }
        else if (failed!==null && error.response && error.response.data) {
            if (error.response.status === 401) {
                console.log('401')
                // AuthErrorValidation(Recall)
                if(failed){
                failed({
                    status: error ? error.response ? error.response.status ? error.response.status : null : null : null,
                    text: 'Permission denied',
                    dev_data: null,
                    app_data: null
                })}
            } else {

                let statusCode = error.response.status;
                let statusText = error.response.statusText;
                let error_dev_data = error.response.data.dev_data;
                let error_app_data = error.response.data.app_data;
                let res = {
                    status: statusCode,
                    text: statusText,
                    dev_data: error_dev_data,
                    app_data: error_app_data
                }
                if(failed)
                failed(res)
            }
        } else if(failed!==null){
            if (error.message && failed) {
                failed({
                    status: null,
                    text: error.message,
                    dev_data: null,
                    app_data: null
                })
            } else if(failed) {
                failed({
                    status: null,
                    text: 'Unknown error',
                    // text: null,
                    dev_data: null,
                    app_data: null
                })
            }
            else return 
        } else return

    }

    switch (type) {
        case 'get':
            return axios.get(url, params)
                .then(response => {
                    console.log('Get Response', response)
                    if(success) {success(response.data)}
                    return response.data
                })
                .catch(error => {
                    console.log("Get-Server Error", error)
                    console.log("get-server Response", error.response)
                    console.log('FAILED=>',failed, error)
                    if(failed){ 
                        console.log('FAILED=>',failed, error)
                        failed_validation(failed,error)
                    }else{
                        failed_validation(null,error,null)
                    }
                    return []
                })

        case 'post':
            return axios.post(url, params)
                .then(response => {
                    console.log('Post Response', response)
                    if(success) {
                        success(response.data);
                    }
                    return response.data
                })
                .catch(error => {
                    console.log("Post Server Error", error.response)
                    console.log("POST server Response",url, params, error.response)
                    if(failed) {failed_validation(failed,error)}
                    else
                    return error.response ? error.response.data : error
                })

        case 'post-form':
                return axios.post(url, params, {headers: {"Content-Type": "multipart/form-data"}})
                    .then(response => {
                        console.log('Post-form Response', response)
                        if(success) {success(response.data)}
                        return response.data
                    })
                    .catch(error => {
                        console.log("Post-form Server Error", error)
                        console.log("Post-form server Response", error.response)
                        // console.log('serv',error.response.data)
                        if(failed) {
                            if(error.response)
                                if(error.response.data){
                                    failed_validation(failed, null, error.response.data)
                                    // failed(error.response.data)
                                }else 
                                    failed({app_data : 'Something went wrong!'})
                            else 
                                failed({app_data : 'Something went wrong!'})
                        }
                        if(error.response) {
                            return error.response.data
                        } else return null;
                        
                    })

        case 'patch':
            return axios.patch(url + '/' + params)
                .then(response => {
                    console.log('Patch Response', response)
                    if(success) {success()}
                    return response.data
                })
                .catch(error => {
                    console.log("Patch Server Error", error)
                    console.log("Patch server Response", error.response)
                    if(failed) {failed()}
                    return error.response.data
                })

        case 'patch-json':
            return axios.patch(url, params, {header: {"Content-Type" : "application/json"}})
                .then(response => {
                    console.log('Patch-json Response', response)
                    if(success) {success()}
                    return response.data
                })
                .catch(error => {
                    console.log("Patch-json Server Error", error)
                    console.log("Patch-json server Response", error.response)
                    if(failed) {failed()}
                    return error.response.data
                })
        case 'put-json':
            return axios.put(url, params, {headers: {"Content-Type": "application/json"}})
                .then(response => {
                    console.log('Put-json Response', response)
                    if(success) {success(response)}
                    return response
                })
                .catch(error => {

                    console.log("Put-json Server Error", error)
                    console.log("Put-json server Response", error.response)
                    if(failed) {failed()}
                    return error.response.data
                })
        case 'put':
            return axios.put(url, params, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    console.log('Put Response', response)
                    if(success) {success(response)}
                    return response
                })
                .catch(error => {

                    console.log("Put Server Error", error)
                    console.log("Put server Response", error.response)
                    if(failed) {failed()}
                    return error.response.data
                })
                // .catch(error => {
                //     console.log("Server Error", error)
                //     console.log("server Response", error.response)
                //     if(failed) {failed()}
                //     if(error.response) {
                //         return error.response.data
                //     } else {
                //         return error
                //     }
                // })

        case 'patch-form':
            return axios.patch(url, params, {headers: {"Content-Type": "multipart/form-data"}})
                .then(response => {
                    console.log('Patch-form Response', response)
                    if(success) {success(response)}
                    return response.data
                })
                .catch(error => {
                    console.log("Patch-form Server Error", error)
                    console.log("Patch-form server Response", error.response)
                    if(failed) {failed(error.response)}
                    return error ? error.response ? error.response.data ? 
                        error.response.data : error.response : error : null
                })

        case 'delete':
            return axios.delete(url)
                .then(response => {
                    console.log('Delete Response', response)
                    if(success) {success(response.data)}
                    return response.data
                })
                .catch(error => {
                    console.log("Delete Server Error", error)
                    console.log("delete server Response", error.response)
                    if(failed) {failed()}
                    return error.response ? error.response.data : error
                })

        default: 
            break;
    }

}

export function SearchApi(type, url, params, cancelTk, success, failed){
    
    let state = store.getState()
    let token = state.userLogin.authToken
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

    switch (type) {

        case 'search-get':
            return axios.get(url)
                .then(response => {
                    console.log('Get Search Response', response)
                    if(success){ success(response) }
                    return response.data
                })
                .catch(error => {
                    console.log("Get Search Server Error", error)
                    console.log("Get Search server Response", error.response)
                    if(axios.isCancel(error)) {
                        console.log("CancelledToken",error.message);
                    }
                    return []
                })

        case 'search-post':
            return axios( { method:'post', url : url, data : params, cancelToken : cancelTk, headers: {"Content-Type": "multipart/form-data"} })
                .then(response => {
                    console.log('Post Search Response', response)
                    if(success){ success(response) }
                    return response.data
                })
                .catch(error => {
                    console.log("Post SEarch Server Error", error.response)
                    // console.log("server Response", error.response)
                    if(axios.isCancel(error)) {
                        console.log("CancelledToken",error.message);
                    }
                    if(failed){ failed(error) }
                    return error.response ? error.response.data : error
                })
        default :   break;
    }
}