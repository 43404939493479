import React, { useState } from 'react';
import NotificationModalComponent from './notification_modal'
import CreateModal from '../../ohs/components/create_notify_news'
import {connect} from 'react-redux'
import NoDatasScreen from '../../../common/no_datas'
import { fetch_home_get } from '../action'
import {get_safety} from '../../ohs/common/action'
import { cl_blue } from '../../../common/Colors'

const NotificationBox = ({ fetch_home_fx, fetch_safety_data, screen_type, notificationList, permissions, isLoading }) => {
    let limitNotification = 8;
    let balanceNotification = 0;
    if(notificationList && notificationList.length < limitNotification) {
        balanceNotification = limitNotification - notificationList.length
    }

    const [NotificationModal, setNotificationModal] = useState(false)
    const [createModal, setCreateModal] = useState(false)
    const [openArch, setOpenArch]       = useState(false)
    const [modalType, setModalType]     = useState("")
    const [dataObj, setDataObj]         = useState({ id:'', title:'', description:'', fileName:'', mem:[], editStatus:false })
    const refreshData = () =>{
        fetch_home_fx(screen_type, 2021,1)
        fetch_safety_data()
    }

    const cropDesc = (txt) =>{
        return txt && txt.length>15 ? `${txt.substring(0,5)}...${txt.substring(txt.length-5, txt.length)}` : txt;
    }


    return (
        <div className="home-box-component">
            <div className="home-box-header">
                <p>Notifications</p>
                {   permissions && permissions.ohs.add ?
                <div style={{ display:'flex', width:'70%', justifyContent:'flex-end' }}>
                    <button className="ohs-pagi-add-btn" style={{ padding:'.2rem', justifySelf:'flex-end' }} 
                        onClick={()=> {   setModalType("Add Notification"); setDataObj(null); setCreateModal(true); }} >Add New</button>
                    <button className="ohs-pagi-add-btn shdl-pagi-m-b" onClick={()=>{ setOpenArch(true); }}
                 style={{ paddingLeft: '1px', paddingt: '1px' }}>
                    <p>Archive</p>
                </button>
                    {/* <button className="ohs-pagi-add-btn" style={{ padding:'.2rem', justifySelf:'flex-end' }}>Archive</button> */}
                </div> : null}
                {/* <Link style={{ textDecoration: 'none' }} to="/ohs"> */}
                    <p onClick={() => setNotificationModal(true)} style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
                {/* </Link> */}
            </div>

            {
                isLoading ?
                <table>
                    <tbody>
                        <tr>
                            <td className="home-table-head">Code</td>
                            <td className="home-table-head">Title</td>
                            <td className="home-table-head">Description</td>
                            <td className="home-table-head">action</td>
                        </tr>
                        {
                            [...Array(5)].map((_,k)=>{
                                return(
                                    <tr>
                                        <td  className='skeltonLoaderTable'></td>
                                        <td  className='skeltonLoaderTable'></td>
                                        <td  className='skeltonLoaderTable'></td>
                                        <td  className='skeltonLoaderTable'></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                :
                notificationList && notificationList.length > 0 ? (

                    <table>
                        <tbody>
                            <tr>
                                <td className="home-table-head">Code</td>
                                <td className="home-table-head">Title</td>
                                <td className="home-table-head">Description</td>
                                <td className="home-table-head">action</td>
                            </tr>
                            {
                                notificationList.map((item, key) => {
                                    return (
                                        <tr key={key} style={{ backgroundColor: key%2 ? 'rgb(240, 241, 242)' : 'white' }} >
                                            <td>{item.id}</td>
                                            <td>{item.title}</td>
                                            <td >
                                                {/* <p style={{ width: 150, height: 15, overflow: 'hidden', textAlign:'center' }} > */}
                                                    {cropDesc(item.description)}
                                                {/* </p> */}
                                            </td>
                                            <td>
                                            {/* <Link style={{ textDecoration: 'none' }} to="/ohs"> */}
                                                <button className={!item.user_read_status ? "ohs-table-action-btn" : "ohs-table-action-btn-read"}
                                                    onClick={()=>{
                                                        setDataObj({id:item.id, title:item.title, description:item.description, fileName:item.file_attachment, comments:item.comments_list, mem:item.members_list, editStatus:item.edit_status}); 
                                    setModalType("Notification");
                                    setCreateModal(true);
                                                    }}
                                                >View</button>
                                            {/* </Link> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                notificationList.length<6 &&
                                    [...Array(balanceNotification)].map((_,k) => {
                                        return(
                                            <tr key={k}>
                                                <td></td><td></td><td></td><td></td>
                                            </tr>
                                        )
                                    })
                            }

                        </tbody>
                    </table>
                ) : (
                    <NoDatasScreen />
                )
            }
            <NotificationModalComponent 
                modalStatus={NotificationModal}
                modalAction={setNotificationModal}
                fetchData={refreshData}
                openArch={openArch}
            />
            <CreateModal
            modalStatus = {createModal}
            modalAction = {setCreateModal}
            createType  = {modalType}
            setModalType = {setModalType}
            dataObj     =   {dataObj}
            pageNo      =   {1}
            fnCallBk    =   {()=>{fetch_home_fx(screen_type, 2021,1)}}
        />
        </div> 
    )
}

const mapStateToProps = (state) => {
    const { jobList, scheduleOfTheDay, notificationList, graphData, graph_peak, weather, isLoading } = state.homeGetData
    const { safetyData } = state.ohs
    const { permissions } = state.userLogin
    return { jobList, scheduleOfTheDay, notificationList, graphData, graph_peak, weather, safetyData, permissions, isLoading }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_home_fx: (type, yr, page_no) => dispatch(fetch_home_get(type, yr, page_no)),
        fetch_safety_data: () => dispatch(get_safety())
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (NotificationBox)
