import React, {useEffect} from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Scrollbar from '../../../../common/ImprovedScrollBar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core'
import './sales_tableStyle.css';

import { fetch_waste_sale_performance } from '../../common/action';
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint';
import NoDatasScreen from '../../../../common/no_datas';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 80,
      maxHeight: 20,
      marginLeft: 10,
      marginTop: -8,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    resize:{
        fontSize: 14,
        fontWeight: 600,
        color: 'white'
    }
  }));

function SalesExcecutieves({ sale_performance, sale_prfrm_month, sale_prfrm_year, fetch_sales_performance, sale_performance_loader, initiate_loader }) {

    const classes = useStyles();

    useEffect(() => {
        let current_date = new Date()
        fetch_sales_performance(current_date.getFullYear(), current_date.getMonth() + 1)
        return () => fetch_sales_performance.remove
    }, [fetch_sales_performance])

    const { path } = useRouteMatch();
    let routing_path = path.split('/')
    const route_path = '/' + routing_path[1] + '/' + routing_path[2]

    const monthData = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]

    const yearData = [
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
        2025
    ]


    const fetchDataSelection = (year, month) => {

        initiate_loader();
        fetch_sales_performance(year, month)
    }


    const MonthDropdown = () => {
        return (
            <FormControl className={classes.formControl}>
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14 }}
                    value={monthData[sale_prfrm_month - 1]}
                    className={classes.resize}
                    onChange={(val) => {
                        let index = monthData.indexOf(val.target.value)
                       fetchDataSelection(sale_prfrm_year, index + 1)
                    }}
                >
                
                {
                    monthData.map((item, key) => {
                        return (
                            <MenuItem key={key} value={item}>{item}</MenuItem>
                        )
                    })
                }
                
                </Select>
            </FormControl>
        )
    }

    const YearDropdown = () => {
        return (
            <FormControl className={classes.formControl}>
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14 }}
                    value={sale_prfrm_year}
                    className={classes.resize}
                    onChange={(val) => {
                      fetchDataSelection(val.target.value, sale_prfrm_month)
                    }}
                >
                    {
                        yearData.map((item, key) => {
                            return (
                                <MenuItem key={key} value={item}>{item}</MenuItem>
                            )
                        })
                    }
                
                </Select>
            </FormControl>
        )
    }

    // function Scroll(e) {
        
    //     // const elRef = useRef();
    //     // useEffect(() => {
    //     //   const el= elRef.current;
    //     //   if (el) {
    //     //     const onWheel = (e) => {
    //     //       if (e.deltaY === 0) return;
    //     //       e.preventDefault();
    //     //       el.scrollTo({
    //     //         left: el.scrollLeft + e.deltaY,
    //     //         behavior: "smooth"
    //     //       });
    //     //     };
    //     //     el.addEventListener("wheel", onWheel);
    //     //     return () => el.removeEventListener("wheel", onWheel);
    //     //   }
    //     // }, []);
    //     // return elRef;
    // }
    // const scrollRef = useHorizontalScroll();

    
    return (
        <div className="sales-excecutive-layout">
            <div className="sales-exce-head">
                <p style={{ fontSize: 14, fontWeight: '500' }}>Sales Performance</p>
                <div className="sales-exce-head-sort">
                    <div className="sales-exce-head-sort-label">
                        <p>Sales by month:</p>
                        <MonthDropdown />
                    </div>
                    
                    <div className="sales-exce-head-sort-label">
                        <p>Sales by year:</p>
                        <YearDropdown />
                    </div>
                    
                </div>
            </div>

            <Scrollbar autoHide style={{ width: '100%' }}  
            
            >
                {   sale_performance_loader ?
                <div style={{ display: 'flex', height:'100%', width:'100%', position:'absolute', justifyContent: 'center', alignItems: 'center' }} >
                <CircularProgress />
                </div>
                :
                <div 
                // ref={scrollRef}
                 className="sales-exce-card-layout" 
                //  style={{overflowX:'auto',overflowY:'hidden',scrollbarWidth:'5px'}}
                 >
                    {
                        sale_performance ?
                        sale_performance.map((item, key) => {
                            const { name, lost, pending, profile, total, won } = item;
                            return (
                            <Link
                                to={route_path+ `/sales-performance/${item.id}`} 
                                style={{textDecoration:'none'}}
                                key={key}
                            >
                                <div key={key} className="sales-exce-card">
                                    <div className="sales-exce-card-left">
                                        <img className="sales-exce-img" src={HOST_WITHOUT_SLASH + profile} alt="" />
                                        <p>{name}</p>
                                    </div>
                                    <div className="sales-exce-card-right">
                                        <p>Total Quote Made {total}</p>
                                        <p style={{ color: '#0F99D0' }}>Total Quote Won {won}</p>
                                        <p style={{ color: '#FF8A23' }}>Total Quote Pending {pending}</p>
                                        <p style={{ color: '#FF0000' }}>Total Quote Lost {lost}</p>
                                    </div>
                                </div>
                            </Link>
                            )
                        })
                        :
                        <NoDatasScreen />
                    }
                </div>
                }
            </Scrollbar>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { sale_performance, sale_prfrm_month, sale_prfrm_year, sale_performance_loader } = state.sales
    return { sale_performance, sale_prfrm_month, sale_prfrm_year, sale_performance_loader }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_sales_performance: (year, month) => dispatch(fetch_waste_sale_performance(year, month)),
        initiate_loader: () => dispatch({type: 'RELOAD_SALES_PERFM'}),
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (SalesExcecutieves)
