import React, { useState } from 'react';
import '../style.css'
import './style.css';
import Scrollbars from '../../../../common/ImprovedScrollBar';
import CustomerReport from './CustomerReport';
import JobSiteAssesment from './JobSiteAssesment';
import PartList from './PartList';
import AssetCheck from './AssetCheck';
import AssetRegister from './AssetRegister';
import { connect } from 'react-redux';
import { addNewPumpJobCard, editNewJobCardName, editNewPumpJobCard, listJobCards } from '../../action';
import Swal from 'sweetalert2';
import NoDatasScreen from '../../../../common/no_datas';
import { CheckCircleOutlineRounded, EditOutlined } from '@material-ui/icons';
import { cl_green, cl_red } from '../../../../common/Colors';
import { PUMPJOBCARDS } from '../../../../store/endpoint';
import Api from '../../../../store/api'

function App({  setJobCardModal, jobObj, modalType, clientList, addPumpJob, editPumpJobCard, user, editJobCardName,fetch_jobCards,
    //tabType=null, 
    verifyJobCard=null, setJobCardLoader=null, fallBackFn=null,
    preSelect=null, addNVerify=false, setAddNVerify=null 
}){


    const [clientId, setClientId]              = useState( modalType==='add' ? preSelect===null ? null :
        preSelect.id : jobObj.client ? jobObj.client : null  )

    const [jobCardName, setJobCardName]        = useState(modalType==='add' ? preSelect===null ? '' : 
        preSelect.company_name ? preSelect.company_name : preSelect.client_name : jobObj.job_card_name ? jobObj.job_card_name : jobObj.company_name ? 
        jobObj.company_name : jobObj.client_name)

    const [tabSel, setTabSel]                  = useState( modalType==='view' ? jobObj.customer_report ? 0 : jobObj.job_site_assesment ? 1 :
        jobObj.part_list ? 2 : jobObj.asset_check ? 3 : jobObj.asset_register ? 4 : 5 : 0)

    const [customer_report, setCustomerReport] = useState( modalType!=='add' ? jobObj.customer_report ? jobObj.customer_report : null : null)
    const [jsa_card, setJSACard]               = useState( modalType!=='add' ? jobObj.job_site_assesment ? jobObj.job_site_assesment : null : null)
    const [partList, setPartList]              = useState( modalType!=='add' ? jobObj.part_list ? jobObj.part_list : null : null)
    const [assetCheck, setAssetCheck]          = useState( modalType!=='add' ? jobObj.asset_check ? jobObj.asset_check : null : null)
    const [assetRegister, setAssetRegister]    = useState( modalType!=='add' ? jobObj.asset_register ? jobObj.asset_register : null : null)
    
    const [client_name, setClientName]         = useState(modalType==='add' ? preSelect===null ? null :
        preSelect.client_name : jobObj.client_name ? jobObj.client_name : null  )
    const [frq, setFrq]                        = useState( modalType!=='add' ? jobObj.frequency ? jobObj.frequency : 0 : 0)
    const [reoccur, setReoccur]                = useState( modalType!=='add' ? jobObj.reoccurring ? jobObj.reoccurring : false : false)

    const [editOpt, setEditOpt]                = useState(false)

    console.log('PUMPJOBCARD',modalType,jobObj,preSelect)

    const addJobCard = () =>{
        
        let data = 
        {
            customer_report      :  customer_report,
            job_site_assesment   :  jsa_card,                            //missplelled possible errors occurences
            part_list            :  partList,
            asset_check          :  assetCheck,
            asset_register       :  assetRegister,
            client_name          :  client_name,
            client_id            :  clientId,
            reoccurring          :  frq,
            frequency            :  reoccur,
            employee_name        :  modalType!=='add' ? jobObj.employee_name : user.name
        };

        const success = (res) =>{
            if(addNVerify){
                verifyJobCard(res.id,{reoccurring : reoccur, frequency : frq}, res.job_card_code)
            }
            setTabSel(0)
            setCustomerReport(null)
            setJSACard(null)
            setPartList(null)
            setAssetCheck(null)
            setAssetRegister(null)
            setJobCardModal(false)
            if(setJobCardLoader!==null){
                setJobCardLoader(false)
                
            }
            if(fallBackFn!==null){
                fallBackFn()
            }
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Job Cards Collection ${modalType==='add' ? 'Created' : 'Updated'}.`,
                showConfirmButton: false,
                timer: 1500
            })
        }
        const failed = () => {
            setJobCardModal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        
        if(modalType==='add'){
            if(setJobCardLoader!==null){
                setJobCardLoader(false)
                
            }
            if(fallBackFn!==null){
                fallBackFn()
            }
            addPumpJob(data, success, failed)
        }else if(modalType==='edit' || (modalType==='verify' && !jobObj.connected)){
            if(verifyJobCard!==null){
                verifyJobCard(jobObj.id,{reoccurring : reoccur, frequency : frq},jobObj.job_card_code)
                setJobCardModal(false)
            }
            
            editPumpJobCard(data, jobObj.id, success, failed)
        }
    }

    console.log("JOBCARDS=?",customer_report,jsa_card,partList,assetCheck,assetRegister,jobObj,modalType)

    const btnTxt = () =>{
        let txt=''
        if(modalType==='add'){
            txt = `Create Job Card Collection `;            
        }
        else if(modalType==='verify'){
            if(jobObj.connected)
                txt = 'This job card collection is already in use'
            else 
                txt = 'Complete Verification'
        }
        else{
            txt = 'Complete Updation'
        } 
        return txt;
    }

    const jobCardRename = () =>{
        let data = new FormData();
        data.append('job_card_name', jobCardName)
        const success = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Job Card Name Updated.`,
                showConfirmButton: false,
                timer: 1500
            })
            if(setJobCardLoader!==null){
                setJobCardLoader(false)
                
            }
            if(fallBackFn!==null){
                fallBackFn()
            }
            setEditOpt(false)
        }
        const failed = () => {
            setJobCardModal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        
        

        editJobCardName(data, jobObj.id, success, failed)
    }

    const delJobCard = (jobId) =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Job Card deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            setJobCardModal(false)
            if(setJobCardLoader!==null){
                setJobCardLoader(false)
                
            }
            if(fallBackFn!==null){
                fallBackFn()
            }
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = `${PUMPJOBCARDS}${jobId}/`
                Api('delete', api_url, '', success, failed)
            }
        })
    
    }


    return(
        <div className="pumpJobCardLayout">
            { modalType!=='add' ? <h2  style={{ marginLeft:'1%' }}>Job Card</h2> : null}
            <div className="header-button-layout" style={{ justifyContent:'space-between', marginBottom: '1%'}}>
                
                <div style={{ margin: '1%', fontWeight:'600', fontSize:'1.2rem', display:'flex', flexDirection:'column', width:'70%' }}>
                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width:'80%' }} >
                    {   modalType==='add' || modalType==='view' || !editOpt ?
                        <span>{jobCardName==='' ? 'Job Card' : jobCardName}</span>
                        :
                        <input type="text" className="jobCardDivInp" style={{ fontWeight:'600', fontSize:'1.2rem' }}
                            placeholder="Job Card Name" value={jobCardName} onChange={(e)=>setJobCardName(e.target.value)} />
                    }
                    {
                        (modalType==='add' || modalType==='view') ? null :
                         !editOpt ?
                        <EditOutlined className="editPencilIcon"
                         style={{ fontSize:'1.1rem', borderRadius:'50%', marginLeft:'1%', marginBottom: '1%' }}  onClick={()=> setEditOpt(true) } />
                        :
                        <CheckCircleOutlineRounded className="checkCircIcon"
                         onClick={()=> jobCardRename()} />
                    }
                    </div>
                    <span style={{ fontWeight:'300', fontSize:'1.5rem', width:'80%' }}>
                        { modalType==='add' ? `(${customer_report ? 'Customer Report, ' : ''}
            ${jsa_card ? 'Job Site Assesment, ' : ''}${partList ? 'Part List, ' : ''}
            ${assetCheck ? 'Asset Check, ' : '' }${assetRegister ? 'Asset Register ': ''})` : null}
                    </span>
                </div>
                

                {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center', marginTop:'5px' }}>
                <div 
                className={ customer_report || jsa_card || partList || assetCheck || assetRegister
                     ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else
                        addJobCard()
                    }} 
                  style={{ minWidth:'15vw', maxHeight:'5vh', textAlign:'center', height:'auto', width:'auto' }}>
                     {btnTxt()} 
                </div>
            </div>
            :   null
            }

                    {
                        modalType==='add' ? null :
                        (jobObj && jobObj.connected) ? null :
                        modalType==='view' ?
                        <div className="header-button-top delBtnTop" style={{ marginTop: '1%', backgroundColor:'red', color:'white' }}
                            onClick={()=>  delJobCard(jobObj.id) }>
                           Delete Job Card
                       </div>
                       : null
                    }
                    

                <div className="header-button-top" style={{ marginTop: '1%' }}
                 onClick={()=>  setJobCardModal(false) }>
                    Back
                </div>
                
            </div>
            <div className="jobCardContentDiv">
            {/* <SelectField
                        title="Type of Waste"
                        mandatory={true}
                        options={[
                            { name: 'Select Option', value: ''},
                            {   name : 'AMMONIA', value : 'AMMONIA'},
                            {   name : 'DILUTION PIT', value : 'DILUTION PIT'},
                            {   name : 'DRUMS', value : 'DRUMS'},
                            {   name : 'FOOD WASTE', value : 'FOOD WASTE'},
                            {   name : 'GERNIE WASHES', value : 'GERNIE WASHES'},
                            {   name : 'GREASE', value : 'GREASE'},
                            {   name : 'GROUND WATER', value : 'GROUND WATER'},
                            {   name : 'J120', value : 'J120'}, 
                            {   name : 'LEVY', value : 'LEVY' },
                            {   name : 'M250', value :'M250'},
                            {   name : 'N205', value : 'N205'}, 
                            {   name : 'OIL SEPERATOR', value : 'OIL SEPERATOR'},
                            {   name : 'PACKAGED WASTE', value : 'PACKAGED WASTE'},
                            {   name : 'PRODUCT DESTRUCTION', value : 'PRODUCT DESTRUCTION'},
                            {   name : 'PUMP SERVICE', value : 'PUMP SERVICE'},
                            {   name : 'RETENTION PIT', value : 'RETENTION PIT'},
                            {   name : 'SEWER', value :'SEWER'},
                            {   name : 'STORMWATER', value :'STORMWATER'},
                            {   name : 'TANK WASH OUT', value : 'TANK WASH OUT'},      
                            {   name : 'TRADE WASTE', value : 'TRADE WASTE'},                                              
                            {   name : 'TRANSPORT', value : 'TRANSPORT'},                            
                            {   name : 'Z140', value : 'Z140'},
                            // {   name : 'WIEGH BRIDGE', value : 'WEIGH BRIDGE'}
                            // {   name : 'DESTRUCTION CERTIFICATE', value : 'DESTRUCTION CERTIFICATE'},
                            { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                        ]}
                            value={TypeofWaste}
                            cls={'selectBoxHead'}
                            onChange={val =>{ setTypeofWaste(val.target.value.toUpperCase());  }}
                    /> */}
            
                    {   modalType!=='view' ?
                    <div className="jobCardSelectTabDiv">
                        <div className={ tabSel===0 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(0) }>
                            <div onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setCustomerReport(null)}} 
                                className={ customer_report!==null ? "jobCardsCheckBox jobCardsCheckBoxChecked" : "jobCardsCheckBox"}></div>
                            <p>Customer Report</p>
                        </div>
                        <div className={ tabSel===1 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(1) }>
                            <div onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setJSACard(null)}} 
                                className={ jsa_card!==null ? "jobCardsCheckBox jobCardsCheckBoxChecked" : "jobCardsCheckBox"}></div>
                            <p>Job Site Assessment</p>                        
                        </div>
                        <div className={ tabSel===2 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(2) }>
                            <div onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setPartList(null)}} 
                                className={ partList!==null ? "jobCardsCheckBox jobCardsCheckBoxChecked" : "jobCardsCheckBox"}></div>    
                            <p>Part List</p>                    
                        </div>
                        <div className={ tabSel===3 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(3) }>
                            <div onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setAssetCheck(null)}} 
                                className={ assetCheck!==null ? "jobCardsCheckBox jobCardsCheckBoxChecked" : "jobCardsCheckBox"}></div>         
                            <p>Asset Check</p>               
                        </div>
                        <div className={ tabSel===4 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(4) }>
                            <div onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setAssetRegister(null)}} 
                                className={ assetRegister!==null ? "jobCardsCheckBox jobCardsCheckBoxChecked" : "jobCardsCheckBox"}></div>      
                            <p>Asset Register</p>                  
                        </div>
                    </div>
                    :
                    <div className="jobCardSelectTabDiv">
                        {   customer_report &&
                        <div className={ tabSel===0 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(0) }>
                            <p>Customer Report</p>
                        </div> }
                        { jsa_card &&
                        <div className={ tabSel===1 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(1) }>
                            <p>Job Site Assessment</p>                        
                        </div> }
                        { partList &&
                        <div className={ tabSel===2 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(2) }>
                            <p>Part List</p>                    
                        </div> }
                        { assetCheck &&
                        <div className={ tabSel===3 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(3) }>
                            <p>Asset Check</p>               
                        </div> }
                        { assetRegister && 
                        <div className={ tabSel===4 ? 'jobCardSelectedTab' : 'jobCardInactiveTab' } onClick={()=> setTabSel(4) }>
                            <p>Asset Register</p>                  
                        </div> }
                    </div>
                    }
                <Scrollbars style={{ height:'60vh' }}>
                    {   jobObj && jobObj.connected ? 
                    <div className="jobCardInputDivSec" >
                    <div style={{ display:'flex', flexDirection:'row', width:'50%', margin:'1% 0', justifyContent:'space-between' }}>
                        <div>
                            This is {reoccur ? '' : <strong>&nbsp;&nbsp;NOT&nbsp;&nbsp;</strong>} a reoccurring Quote{reoccur ? `, with a frequency of ${frq} weeks.` : '.'}
                        </div>
                    </div>
                    </div>
                    
                    :
                    <div className="jobCardInputDivSec" >
                        <div style={{ display:'flex', flexDirection:'row', width:'50%', margin:'1% 0', justifyContent:'space-between' }}>
                            <div>Is this a reoccurring quote </div>
                            {   modalType!=='view'  ?
                        <div style={{  width:'50%', alignItems:"center", display:"flex", flexDirection:'row', justifyContent:'flex-start'}}>
                            <div style={{ marginRight:'1%', display:'flex', flexDirection:'row', width:'30%' }}>
                            <input type="checkbox" className="jobCardDivInp" style={{ height:'1.5vh' }}  disabled={modalType==='view' } checked={ reoccur } onChange={()=>setReoccur(true)}/><span>YES</span>
                            </div><div style={{ marginRight:'1%', display:'flex', flexDirection:'row', width:'30%' }}>
                            <input type="checkbox" className="jobCardDivInp" style={{ height:'1.5vh' }}  disabled={modalType==='view' } checked={ !reoccur } onChange={()=>setReoccur(false)}/><span>NO</span>
                            </div>
                        </div>  :
                        <div style={{  width:'50%', alignItems:"center", display:"flex", flexDirection:'row', justifyContent:'space-between' }}>
                            <div>:</div>
                            <div className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >&nbsp;&nbsp;{reoccur ?'YES':'NO'}</div>
                        </div>
                        }
                            {/* <div style={{  width:'30%', alignItems:"center" }} >
                                <input type="checkbox" className="jobCardDivInp" style={{ height:'1.5vh' }} disabled={modalType==='view'? true : false}
                                checked={reoccur} onChange={()=>setReoccur(!reoccur)} />
                            </div> */}
                        </div>
                        {   reoccur ?
                    <div className="jobCardInputDivSec" style={{ width:'50%', borderLeft : '.5px dotted black', paddingLeft:'1%' }}>
                        <div>Frequency in Weeks</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{frq}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Job No. / Ref. No." value={frq} onChange={(e)=>setFrq(e.target.value)} />
                            }
                        </div>
                    </div>
                    : null}
                    </div>
                    }
                    {
                        
                        tabSel===0 ?
                            <CustomerReport setCustomerReport={setCustomerReport} client_name={client_name} setClientName={setClientName} clientId={clientId} setClientId={setClientId}
                            setJobCardModal={setJobCardModal} jobObj={modalType!=='add' ? jobObj===null ? null : jobObj.customer_report : null}
                             modalType={modalType} tabType={'pumps'} clientList={clientList} jobObjConnected={jobObj===null ? false : jobObj.connected}
                             preSelectVal={preSelect} 
                            />
                        :   tabSel===1 ?
                            <JobSiteAssesment setJSACard={setJSACard} client_name={client_name} setClientName={setClientName} clientId={clientId} setClientId={setClientId}
                            setJobCardModal={setJobCardModal} jobObj={modalType!=='add' ? jobObj===null ? null : jobObj.job_site_assesment : null}
                             modalType={modalType} tabType={'pumps'} clientList={clientList} jobObjConnected={jobObj===null ? false : jobObj.connected}
                             preSelectVal={preSelect}   />
                        :   tabSel===2 ?
                            <PartList setPartList={setPartList} client_name={client_name} setClientName={setClientName} clientId={clientId} setClientId={setClientId}
                            setJobCardModal={setJobCardModal} jobObj={modalType!=='add' ? jobObj===null ? null : jobObj.part_list : null}
                             modalType={modalType} tabType={'pumps'} clientList={clientList} jobObjConnected={jobObj===null ? false : jobObj.connected}
                             preSelectVal={preSelect}  />
                        :   tabSel===3 ?
                            <AssetCheck setAssetCheck={setAssetCheck} client_name={client_name} setClientName={setClientName} clientId={clientId} setClientId={setClientId}
                            setJobCardModal={setJobCardModal} jobObj={modalType!=='add' ? jobObj===null ? null : jobObj.asset_check : null}
                           modalType={modalType} tabType={'pumps'} clientList={clientList} jobObjConnected={jobObj===null ? false : jobObj.connected}
                           preSelectVal={preSelect}  />
                        :   tabSel===4 ?
                            <AssetRegister setAssetRegister={setAssetRegister} client_name={client_name} setClientName={setClientName} clientId={clientId} setClientId={setClientId}
                            setJobCardModal={setJobCardModal} jobObj={modalType!=='add' ? jobObj===null ? null : jobObj.asset_register : null}
                             modalType={modalType} tabType={'pumps'} clientList={clientList} jobObjConnected={jobObj===null ? false : jobObj.connected}
                             preSelectVal={preSelect} 
                            />
                        :   <div style={{ display:'flex', alignItems:'center', justifyContent:"center", width:'90%', height:'20vh', position:'absolute' }}>
                                <NoDatasScreen />
                            </div>

                        
                    }
                </Scrollbars>
            </div>
            
        </div>
    );
}

const mapStateToProps = (state) =>{
    const { user } = state.userLogin
    return { user }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        addPumpJob : (data, success, failed) => dispatch(addNewPumpJobCard(data, success, failed)),
        editPumpJobCard : (data, id, success, failed) => dispatch(editNewPumpJobCard(data, id, success, failed)),
        editJobCardName : (data, job, success, failed) => dispatch(editNewJobCardName(data, job, 'pumps', success, failed)),
        fetch_jobCards      : ()    => dispatch(listJobCards('pumps')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (App);