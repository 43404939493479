import moment from 'moment'
import Api from '../../store/api'
import { 
    TEAM_LIST, 
    TEAM_DELETE, 
    TEAM_EDIT, 
    TEAM_ADD, 
    TEAM_CERTIFICATE,
    // TEAM_SEARCH,
    TEAM_ALL_FOLDERS_LIST,
    TEAM_FOLDER_FILES, 
    TEAM_FOLDER_SEARCH,
    TEAM_FOLDER_ADD_FILE,
    TEAM_DESIGNATIONS,
    TEAM_FOLDER_WITH_FILES,
    TEAM_FILE_UPLOAD,
    TEAM_FILE_DELETE,
    TEAM_CURR_LIST,
    TEAM_TERM_LIST,
    TEAM_DANGEROUS,
    TEAM_FOLDER_INFO,
    TEAM_SINGLE_EMPLOYEE,
    LEAVEAPPLY_CRUD,
    TIMESHEET_CRUD

} from '../../store/endpoint'


export const team_dangerous_data = (id, content, dataId, success, failed) =>{
    return function (dispatch){
        if(content===null){
            Api('get', `${TEAM_DANGEROUS}1/${id}/`)
            .then(response => {
                console.log("GET: ",response, response.data)
        
                dispatch({
                    type: 'TEAM_DANGEROUS_DATA',
                    data: response.data,
                    dataId: response.id
                })
            })
        }
        else{
            let editUrl = TEAM_DANGEROUS +dataId+'/'+id+'/'
            Api('patch-json', editUrl, content, success, failed)
            .then(response => {
                console.log("PATCH: ",response, content)
                dispatch({
                    type: 'TEAM_DANGEROUS_DATA',
                    data: response.data,
                    dataId: response.id
                })
            })   
        }
        
    }
}

export const team_folders_info = (id) =>{
    return function (dispatch){
        Api('get',`${TEAM_FOLDER_INFO}${id}/`)
            .then(response => {
                dispatch({
                    type: 'TEAM_FOLDERS_INFO',
                    data: response.message
                })
            })
    }
}

export const team_list = () => {
    return function (dispatch) {
        dispatch({ type: 'TEAM_FETCHING' })
        Api('get', TEAM_LIST)
            .then(response => {
                dispatch({ type: 'TEAM_FETCHING_SUCCESS', teams: response})
            })
            .catch(error => {
                dispatch({ type: 'TEAM_FETCHING_FAILED'})
            })
    }
}

export const team_list_current = () => {
    return function (dispatch) {
        dispatch({ type: 'TEAM_FETCHING' })
        Api('get', TEAM_CURR_LIST)
            .then(response => {
                dispatch({ type: 'TEAM_FETCHING_SUCCESS', teams: response})
            })
            .catch(error => {
                dispatch({ type: 'TEAM_FETCHING_FAILED'})
            })
    }
}

export const team_list_term = () => {
    return function (dispatch) {
        dispatch({ type: 'TEAM_FETCHING' })
        Api('get', TEAM_TERM_LIST)
            .then(response => {
                dispatch({ type: 'TEAM_FETCHING_SUCCESS', teams: response})
            })
            .catch(error => {
                dispatch({ type: 'TEAM_FETCHING_FAILED'})
            })
    }
}

export const team_list_paginated = (type, page=1) =>{
    return function (dispatch) {
        console.log('TEAMPAGINATION!!')
        dispatch({ type: 'TEAM_FETCHING' })
        let getUrl = ''
            if(type==='curr')
                getUrl = TEAM_CURR_LIST + '?page=' + page + '&limit=20';
            else 
                getUrl = TEAM_TERM_LIST + '?page=' + page + '&limit=20';
        Api('get', getUrl)
            .then(response => {
                if(response)
                    dispatch({ type: 'TEAM_FETCHING_SUCCESS_PAGINATED', teams: response, teamPage: page})
                else
                    dispatch({ type: 'TEAM_FETCHING_FAILED'})
            })
    }
}

export const add_employee = (data, success, failed) => {
    for(let v of data.entries())
        console.log(v[0],'=>',v[1])
    return function (dispatch) {

        let successRes = () => {
            dispatch(team_list_paginated('curr',1))
            success()
        }
       
        Api('post-form', TEAM_ADD, data, successRes, failed)
    }
}

export const designation_list = (success) => {
    Api('get', TEAM_DESIGNATIONS, '')
    .then(response => {
        success(response)
    })
}

export const select_employee = (data) => {
    return function (dispatch) {
        dispatch({ type : 'SELECT_EMPLOYEE_LOADING' })
        Api('get',`${TEAM_SINGLE_EMPLOYEE}${data}/`,'').then(response => {
            // console.log('SINGLERESP',response)
            dispatch({ type: 'SELECT_EMPLOYEE', employee: response})
        })
    }
}

export const edit_employee = (data, employee_id, success, failed) => {
    for(var v of data.entries()){
        console.log(v[0],v[1])
    }
    return function (dispatch) {
     dispatch({ type: 'TEAM_FETCHING' })
        let edit_url = TEAM_EDIT + employee_id + '/'
        Api('patch-form', edit_url, data, success, failed)
            .then(response => {
                console.log(response)
                dispatch(select_employee(employee_id))
                
            })
    }
}

export const delete_employee = (employee_id,success, failed) => {
    return function (dispatch) {
        dispatch({ type: 'DELETE_LOADING', loading: true })
        let delete_url = TEAM_DELETE + employee_id + '/'
        Api('delete', delete_url)
            .then(response => {
                success()
                // dispatch({ type: 'DELETE_LOADING', loading: false })
                // dispatch({ type: 'SELECT_EMPLOYEE', employee: undefined })
                dispatch(team_list_paginated('curr',1))
            })
            .catch(error => {
                failed()
                dispatch({ type: 'TEAM_FETCHING_FAILED'})
            })
    }
}

// export const search_employees = (keyword, type, cancelTk) => {
//     return function (dispatch) {
//         if(keyword!=='' || keyword){
//             let formBody = new FormData()
//             formBody.append('key', keyword)
//             Api({
//             type : 'search-post', 
//             url  : TEAM_SEARCH,
//             params : formBody,
//             cancelTk : cancelTk
//             }
//             ).then(response => {
//             dispatch({ type: 'TEAM_FETCHING_SUCCESS_PAGINATED', teams: response, teamPage: 1})
//             // dispatch({ type: 'TEAM_FETCHING_SUCCESS', teams: response})
//             })
//         }
//         else{
//             dispatch(team_list_paginated(type,1))
//         }
//     }
// }

//Emp Home Folder Api
export const all_vehicle_folders = () => {
    return function (dispatch) {
        Api(
            'get',
            TEAM_ALL_FOLDERS_LIST
        ).then(response => {
            dispatch({ type: 'FOLDER_FETCHING_SUCCESS', folders: response })
        })
    }
}

export const folder_details = (id) => {
    return function (dispatch) {
        let url = TEAM_FOLDER_FILES + id + '/'
        Api('get',url).then(response => {
            dispatch({ type: 'FOLDER_DETAILS_ETCHING_SUCCESS', folders: response})
        })
    }
}

export const search_folder_details = (keyword) => {
    return function (dispatch) {
        let formBody = new FormData()
        formBody.append('key', keyword)
        Api(
            'post-form', 
            TEAM_FOLDER_SEARCH,
            formBody
        ).then(response => {
            dispatch({ type: 'FOLDER_DETAILS_ETCHING_SUCCESS', folders: response})
        })
    }
}

//Each Emp Folder Api inner
// export const certificate_list = (id ,success, failed) => {
//     return function (dispatch) {
//         const req_url = TEAM_CERTIFICATE + id + '/'
//         Api(
//             'get',
//             req_url//, '',success, failed
//         ).then(response => {
//             success()
//             dispatch({ type: 'FOLDER_EMP_FETCHING_SUCCESS', folders: response })
//         })
//         console.log('folder resp',success)
//         }
// }


export const certificate_list = (id) => {

   return function (dispatch) { 
       const req_url = TEAM_CERTIFICATE + id + '/'
       console.log(req_url)
       Api('get', req_url, '', )

    //    .then(response => {
    //         dispatch({ type: 'FOLDER_EMP_FETCHING_SUCCESS', folders: response })
    //         success()
    //     })

        // .catch(error => {
        //     failed()
        //     dispatch({ type: 'FOLDER_FETCHING_FAILED'})
        // })
   }
}

export const team_folders = (employee_id, apiResponseCallBack) => {
    const req_url = TEAM_FOLDER_WITH_FILES + employee_id + '/'
       console.log(req_url)
       Api('get', req_url, '', ).then(response => {
        if(response.length > 0) {
            apiResponseCallBack({
                response: response, 
                loader: false, 
                error: false, 
                status: 'Folder Api Fetching Successful'
            })

        } else {
            apiResponseCallBack({
                response: [], 
                loader: false, 
                error: true, 
                status: 'No Folder Data Found'
            })
        }
       })
}


export const team_file_upload = (employee_id, data, apiResponseCallBack) => {
    const req_url = TEAM_FILE_UPLOAD + employee_id + '/'
       console.log(req_url)
       Api('post-form', req_url, data, ).then(response => {
        apiResponseCallBack()
       })
}


export const team_file_delete = (file_id, success, failed) => {
    const req_url = TEAM_FILE_DELETE + file_id + '/'
       console.log(req_url)
       Api('delete', req_url, '', success, failed).then(response => {
        console.log('file delete response', response)
       })
}




// export const certificate_list = (id, success, failed) => {
//     const req_url = TEAM_CERTIFICATE + id + '/'
//     console.log('request url',req_url)
//     console.log('request success',success)
//     Api('get', req_url, '', success, failed)
// }




export const team_add_file = (data) => {
    return function (dispatch) {
        Api(
            'post-form', 
            TEAM_FOLDER_ADD_FILE,
            data
        ).then(response => {
            console.log(response)
        })
    }
}

export const team_leaveApply = ( data=null, page=1, success=null, failed=null) => {
    if(data)
    return function (dispatch){
        Api(
            'post-form',
            LEAVEAPPLY_CRUD,
            data,
            success,
            failed

        ).then(response => {
            console.log('Leave_apply',response)
        })
    }
    else 
    return function (dispatch){

        dispatch({
            type : 'LEAVEAPPLYLISTLOADERACTION',
            loader : false
        })
        Api(
            'get',
            `${LEAVEAPPLY_CRUD}?page=${page}&limit=20`,
        ).then(response => {
            dispatch({
                type: 'INIT_LEAVE_APPLYLIST',
                leaveApply: response,
                page: page
            })
        })
    }
}

export const team_timeSheet = (content=null, success=null, failed=null, content_Date=null, type=null) =>{
        // console.log('TIMEAPI',TIMESHEET_CRUD)
        
    if(content)
    return function (dispatch){
        // for(var k of content.entries())
        //             console.log(k[0]," ",k[1], typeof k[1])
        
        Api(
            'post-form',
            TIMESHEET_CRUD,
            content,
            success,
            failed
        )
    }
    else if(content_Date)
    return function (dispatch){
        Api(
            'get',
            `${TIMESHEET_CRUD}?date=${content_Date}`
        ).then(response =>{
            console.log(response)
            dispatch(
                {
                    type:'INIT_TIMESHEET',
                    timeSheet: response,
                    filterDate: content_Date,
                    userId : type,
                }
            )
        })
        .catch(err => {
            dispatch(
                {
                    type:'INIT_TIMESHEET',
                    timeSheet: null,
                    filterDate: content_Date,
                    userId : type,
                }
            )
        })
    }
     
    else{
    return function (dispatch){
        Api(
            'get',
            TIMESHEET_CRUD
        ).then(response =>{
            dispatch(
                {
                    type:'INIT_TIMESHEET',
                    timeSheet: response,
                    todayObjEx : response.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD')))
                }
            )
        })
    }
    }
}

export const delete_timeSheet = (timesheet_id,success, failed) =>{

    return function (dispatch) {
        let delete_url = TIMESHEET_CRUD + timesheet_id + '/'
        Api('delete', delete_url)
            .then(response => {
                success()
                dispatch({ type: 'CLOSE_TEMPMODAL'})
            })
            .catch(error => {
                failed()
            })
    }
} 