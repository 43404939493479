import React from 'react'
import Sidebar from '../../../../common/sidebar'
import { Link } from 'react-router-dom'
import './clientphotoStyle.css';
import Scrollbars from '../../../../common/ImprovedScrollBar';

function App() {
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <div className='client-photo-header'>
                   <Link to="/client/detail" className="team-back-btn-layout">
                        <div className="header-back-btn" >
                            <p className="p-0 m-0">Back</p>
                        </div>
                    </Link>
                </div>
                <div className='client-photo-bottom'>
                     <div className='client-photo-bottom-left'>
                       <div className='client-photo-table'>
                           <div  className='client-photo-table-head'>
                            
                                 <div className='head1'>Dated</div >
                                 <div className='head2'>Job code</div >
                                 <div className='head3'>Job Type</div >
                                 <div className='head4'>Supervised By</div >
                            
                           </div >
                           <Scrollbars autoHide style={{ height: '60vh', width: '100%' }}>
                           {
                            [...Array(21)].map((item, key) => {
                                return (
                             <div className='client-photo-table-body'>                          
                                 <div className='head1'>11-2-2020</div >
                                 <div className='head2'>QT@ER</div >
                                 <div  className='head3'>Liqui Waste</div >
                                 <div className='head5'>
                                     <div className="img-responsive">
                                        <img src='' alt='dp' />
                                     </div>
                                     <div className="name-responsive">
                                      <p style={{marginLeft:'10px'}}> Peter </p>
                                     </div>
                               </div >
                            
                             </div >)
                            })
                           }
                           </Scrollbars>
                       </div >
                    </div>
                    <div className='client-photo-bottom-right'>
                        <div className='client-photo-bottom-right-top'>
                           <div className='client-photo-bottom-right-top1'>
                             <img src='' alt="img"/>
                          </div>
                       </div>
                       <div className='client-photo-bottom-right-bottom'>
                            <div className='client-photo-bottom-right-bottom1'>
                               <img src='' alt="img"/>
                           </div>
                           <div className='client-photo-bottom-right-bottom1'>
                             <img src='' alt="img"/>
                           </div>
                           <div className='client-photo-bottom-right-bottom1'>
                             <img src='' alt="img"/>
                           </div>
                       </div>
                   </div>
               </div>
               
            </div>
        </div>
    )
}


export default App
