import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Scrollview from '../../../common/ImprovedScrollBar'
// import FormControl from '@material-ui/core/FormControl';
// import { cl_blue } from '../../../common/Colors';
import { AddCircleOutlineRounded, RemoveCircleOutlineRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function TransitionsModal({

    modalStatus, 
    modalAction,
    templateStr,
    setTemplateStr,
    assetList,
    setQuoteAmount,
    setTableDataObj,
    otherFn
}) {

    const classes = useStyles();

    const [totalAmt, setTotalAmt] = useState(0)
    const [inpField, setInpField] = useState('')
    const [inpDrp, setInpDrp]   = useState('')
    const [gst, setGst] = useState('')
    const [finalAmt, setFinalAmt] = useState('')
    const [inpDataK, setInpDataK] = useState(null)
    
    let arr_initial = [
        {
            asset_no    : '1',
            description : 'Choose or type description',
            location    : 'Type location',
            price       : ''
        }
    ]

    const [tableContent, setTableContent] = useState(arr_initial)
    // const updateBtnClick = () => {
    //     let appended_html_string = '';
    //     tableContent.map((item, key) => {
    //         let table_row_content = `<td>Desription${item.item_description}</td>`
    //         appended_html_string = appended_html_string + table_row_content
    //     })

    //     //we get final html from 'appended_html_string'
    // }
        
        const changFn = (txt, ite, k, price) =>{
            setInpField(txt)
            let obj = { ...ite, description: txt, price: parseFloat(price) }
            let newArr = tableContent
            newArr.splice(k, 1, obj)
            setTableContent(newArr)     
            updateTotal()      
        }

    const handleClose = () => {
        if(inpDrp){
            setInpDrp('')
        }
        modalAction(false)
    };

    const addRow = (val) =>{
        if(val){
            let single_item = {
                asset_no    : ' ',
                description : 'Choose or type description',
                location    : 'Type location',
                price       : ''
            }
            setTableContent(item => [...item, single_item])
        }
        else{
            let newArr = tableContent.filter((_, key) => key!==(tableContent.length-1))
            setTableContent(newArr)
        }
    }

    const updateTotal = () =>{
        let amt = 0
        for(let i=0; i<tableContent.length; i++ )
            amt = amt + parseFloat(tableContent[i].price)
        let roundedNum = Math.round((amt + Number.EPSILON)*100)/100
        setTotalAmt(isNaN(roundedNum) ? 0 : roundedNum)
    }
        
    const addAttachment = () => {
        setTableDataObj(tableContent)
    
        let htmlStrStart = `<!--##PUMP1_ASSET_TABLE-->
        <table class="c42 c83" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;margin-left: 36pt; height: 100%">
            <tbody>
                <tr class="c47" style="height: 0pt;">

                    <td class="c32" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 128.2pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c25" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: center;">
                            <span class="c7" style="font-size: 10pt;font-weight: 700;">ASSET NUMBER</span>
                        </p>
                    </td>
                    <td class="c37" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 131.2pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c25" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: center;">
                            <span class="c7" style="font-size: 10pt;font-weight: 700;">DESCRIPTION</span>
                        </p>
                    </td>
                    <td class="c8" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 138.8pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c25" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: center;">
                            <span class="c7" style="font-size: 10pt;font-weight: 700;">LOCATION</span>
                        </p>
                    </td>
                    <td class="c28" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 130.5pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c25" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;orphans: 2;widows: 2;text-align: center;">
                            <span class="c7" style="font-size: 10pt;font-weight: 700;">PRICE</span>
                        </p>
                    </td>
                </tr>`

        let htmlStr1 =  [`<tr class="c47" style="height: 0pt;">
            <td class="c32" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 128.2pt;border-top-color: #000000;border-bottom-style: solid;">
                <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">
            `,`</p>
                <br>
            </td>
            <td class="c37" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 131.2pt;border-top-color: #000000;border-bottom-style: solid;">
                <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">
            `,`</p>
                <br>
            </td>
            <td class="c8" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 138.8pt;border-top-color: #000000;border-bottom-style: solid;">
                <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">
            `,`</p>
                <br>
            </td>
            <td class="c28" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 130.5pt;border-top-color: #000000;border-bottom-style: solid;">
                <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">
            `,`</p>
                <br>
            </td></tr>`]
                                
        let htmlStrEnd =   `</tbody></table><!--##PUMP1_ASSET_TABLE_E-->
        </div><p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">
            <br></p></td></tr>
<tr class="c47" style="height: 0pt;">
    <td class="c31" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #ffffff;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 611.2pt;border-top-color: #ffffff;border-bottom-style: solid;">
        <p class="c15 c24" id="h.30j0zll-1" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;height: 11pt;">

            <br>
        </p><a id="t.b353894be358f193ece80921d22df012a3ec140d"></a><a id="t.3"></a>
        `

        let htmlStr2 = `<!--##PUMP1_AMOUNT--><div>
        <table class="c81" style="margin-left: 67.5pt;border-spacing: 0;border-collapse: collapse;margin-right: auto;">
            <tbody>
                <tr class="c47" style="height: 0pt;">
                    <td class="c28" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 130.5pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;">
                            <span class="c22 c7" style="-webkit-text-decoration-skip: none;color: #000000;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-family:Arial, sans-serif;font-style: normal;font-size: 10pt;font-weight: 700;">AMOUNT</span>
                        </p>
                    </td>
                    <td class="c27" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 78pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">

                          $  ${totalAmt}
                        </p>
                        <br>
                    </td>
                </tr>
                <tr class="c47" style="height: 0pt;">
                    <td class="c28" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 130.5pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;">
                            <span class="c22 c7" style="-webkit-text-decoration-skip: none;color: #000000;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-family:Arial, sans-serif;font-style: normal;font-size: 10pt;font-weight: 700;">GST</span>
                        </p>
                    </td>
                    <td class="c27" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 78pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">

                          $  ${gst}
                        </p>
                        <br>
                    </td>
                </tr>
                <tr class="c47" style="height: 0pt;">
                    <td class="c28" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 130.5pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c12" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;">
                            <span class="c7 c22" style="-webkit-text-decoration-skip: none;color: #000000;text-decoration: underline;vertical-align: baseline;text-decoration-skip-ink: none;font-family:Arial, sans-serif;font-style: normal;font-size: 10pt;font-weight: 700;">TOTAL</span>
                        </p>
                    </td>
                    <td class="c27" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 78pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c12 c24" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;">

                           $ ${finalAmt}
                        </p>
                        <br>
                    </td>
                </tr>
            </tbody>
        </table></div>
        <!-- ##PUMP1_AMOUNT_E -->`

        let outPut1 = templateStr.substring(0,templateStr.indexOf('<!--##PUMP1_ASSET_TABLE-->'))
        let outPut4 = templateStr.substring(templateStr.indexOf('<!-- ##PUMP1_AMOUNT_E -->'))
        let tempStr = ''
        for(let i=0; i<tableContent.length; i++){
            tempStr = tempStr + htmlStr1[0]+tableContent[i].asset_no + htmlStr1[1]+tableContent[i].description+htmlStr1[2]+tableContent[i].location+htmlStr1[3]+' $ '+tableContent[i].price+htmlStr1[4]
        }
        setTemplateStr( outPut1 + htmlStrStart + tempStr + htmlStrEnd + htmlStr2 + outPut4)
        setQuoteAmount(finalAmt)
        if(otherFn){
            otherFn(tableContent, `${outPut1}${htmlStrStart}${tempStr}$${htmlStrEnd[0]}${totalAmt}${htmlStrEnd[1]}${outPut4}`)
        }
        
        modalAction(false)
    }

    
        
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="cer-cerificate-modal-layout" onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setInpDataK('')}}>
            <div className="cer-modal-header-layout">
                    <p style={{ fontSize: 16 }} className="cer-modal-main-title">Dual Alter Cold Water Booster</p>
                </div>
                <div className="sales-quote-editTable" >

                <Scrollview autoHide style={{ height: '45vh'}} className="salesQuoteTableScrollDiv">    
                            <table className="sales-template-table">
                                <thead className="sales-template-tableHead">
                                    <th style={{ width:'25%' }}>Asset .No</th>
                                    <th style={{ width:'25%' }}>Description</th>
                                    <th style={{ width:'25%' }}>Location</th>
                                    <th style={{ width:'25%' }}>Price</th>
                                </thead>
                                <tbody onClick={(e) =>{ e.preventDefault(); e.stopPropagation();}}>
                                {
                                    tableContent &&
                                    tableContent.map((item, key) => {
                                        return (
                                            <tr className="sales-template-tableRow" key={key}
                                                style={{ backgroundColor: key%2===0 ? 'rgba(0,0,255,0.1)' : 'transparent' }}
                                            >
                                                <td style={{ width:'25%' }}><div>
                                                    {
                                                        inpDataK === key+'slno' ?
                                                        <input type="text" value={item.asset_no}
                                                        onChange={(e)=>{
                                                            setInpField(e.target.value)
                                                           let obj = { ...item, asset_no: e.target.value }
                                                           let newArr = tableContent
                                                           newArr.splice(key, 1, obj)
                                                              setTableContent(newArr) }} />
                                                        :
                                                        <p
                                                         onMouseEnter={()=>{ setInpDataK(key+'slno'); setInpField(item.asset_no); }}
                                                         onClick={()=>{ setInpDataK(key+'slno'); setInpField(item.asset_no); }}>{item.asset_no}</p>
                                                    }
                                                    </div></td>
                                                <td style={{ width:'25%' }}><div>
                                                    {   inpDataK === key+'des' ?
                                                        <div className="assetAutocompleteDiv">
                                                            <input type="text" value={item.description}
                                                             onChange={(e)=>{
                                                                 setInpDrp(e.target.value)
                                                                let obj = Object.assign({}, { ...item, description: e.target.value })
                                                                let newArr = tableContent
                                                                newArr.splice(key, 1, obj)
                                                                setTableContent(newArr)
                                                             }}  />
                                                            <div className="assetAutocompleteDrop"
                                                             onClick={(e)=> { e.preventDefault(); e.stopPropagation(); }}>
                                                            <Scrollview style={{ height:'10vh' }}  onClick={(e)=> { e.preventDefault(); e.stopPropagation(); }}>
                                                            {
                                                                assetList ?
                                                                assetList.map((ite, itk) => {
                                                                    return(
                                                                        <div className="assetHoverDiv" key={itk}
                                                                         onClick={(e)=>{
                                                                             e.preventDefault()
                                                                             e.stopPropagation();
                                                                             changFn(ite.Asset_description, item, key, ite.new_price) }}>
                                                                            {ite.Asset_description}
                                                                        </div>
                                                                    )
                                                                })
                                                                : <div className="assetHoverDiv" >No Data</div>
                                                            }
                                                            </Scrollview>
                                                            </div>
                                                        </div>
                                                        
                                                    :
                                                        <p onClick={()=>{ setInpDataK(key+'des'); setInpField(item.description); }}>{item.description}</p>
                                                    
                                                    }
                                                </div></td>
                                                <td style={{ width:'25%' }}><div>
                                                    {   inpDataK === key+'loc' ?
                                                        <input type="text" value={item.location}
                                                        onChange={(e)=>{
                                                            setInpField(e.target.value)
                                                           let obj = { ...item, location: e.target.value }
                                                           let newArr = tableContent
                                                           newArr.splice(key, 1, obj)
                                                              setTableContent(newArr) }} />
                                                    :
                                                        <p
                                                        onMouseEnter={()=>{  setInpDataK(key+'loc'); setInpField(item.location);  }}
                                                        onClick={()=>{ setInpDataK(key+'loc'); setInpField(item.location); }}>{item.location}</p>
                                                    
                                                    }
                                                </div></td>
                                                
                                                <td style={{ width:'25%' }}><div>
                                                    {   inpDataK === key+'pri' ?
                                                        <><span>$</span>
                                                        <input type="text" value={item.price} placeholder='0'
                                                        style={{ textAlign:'center', fontSize:'1rem', width:'40%' }}
                                                        onChange={(e)=>{
                                                            if((/^\d{0,}(\.\d{0,})?$/).test(e.target.value)){
                                                            setInpField(e.target.value)
                                                            let obj = null
                                                            
                                                                obj = { ...item, price: parseFloat(e.target.value) }
                                                            let newArr = tableContent
                                                           newArr.splice(key, 1, obj)
                                                              setTableContent(newArr)
                                                              updateTotal()
                                                            }
                                                            } } />
                                                        </>
                                                    :
                                                        <p
                                                        onMouseEnter={()=>{ setInpDataK(key+'pri'); setInpField(item.price);   }}
                                                        onClick={()=>{ setInpDataK(key+'pri'); setInpField(item.price); }}  style={{ justifyContent:'center' }}>
                                                            ${item.price}
                                                        </p>
                                                    
                                                    }
                                                </div></td>
                                                
                                            </tr>
                                            
                                        )
                                    })
                                }
                                <tr  className="sales-template-tableRowTotal">
                                    <td colSpan={2}>
                                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                                            style={{ width:'5vw', display:'flex', alignItems:'center', fontSize:'16px', justifyContent:'space-evenly', marginTop:'10px' }}
                                            onClick={()=>{ addRow(true); if(inpField){ setInpDataK('') } } }>
                                            <div><AddCircleOutlineRounded style={{ fontSize:'16px' }}/></div><div>Add</div>
                                        </div>
                                    {   tableContent && tableContent.length>1 ?
                                    
                                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                                            style={{ width:'5vw', display:'flex', alignItems:'center', fontSize:'16px', justifyContent:'space-evenly', marginTop:'10px' }}
                                            onClick={()=> addRow(false) }>
                                            <div><RemoveCircleOutlineRounded style={{ fontSize:'16px' }}/></div><div>Remove</div>
                                        </div>
                                       :   
                                        <div></div>
                                    }
                                        </div>
                                    
                                    </td>
                                    <td colSpan={2}>
                                        <div id="tableTotalDiv">
                                        <div style={{ margin:'10px 5px'}} className="TotalInpDiv">
                                            <p className="totalInp">Total</p>
                                        </div>
                                        <div className="TotalInpDiv" style={{ margin:'10px 5px', backgroundColor:'rgba(0,0,0,0.1)'}} >
                                            <p className="totalInp" style={{ justifyContent:'center' }}>${totalAmt}</p>
                                        </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr className="sales-template-tableHead" style={{ marginTop:'10%' }}>
                                    <th style={{ width:'30%' }}>Amount</th>
                                    <th style={{ width:'30%' }}>GST</th>
                                    <th style={{ width:'30%' }}>Total</th>
                                </tr>
                                    <tr className="sales-template-tableRow" style={{ backgroundColor: 'rgba(0,0,255,0.1)'}}>
                                        <td style={{ width:'30%' }}>
                                            <span style={{ fontSize:'16px' }}>$</span>
                                            <input type="text" value={`${totalAmt}`} 
                                                style={{ backgroundColor:'transparent', border:'none', outline:'none', borderBottom:'0.5px solid grey' }}
                                                onChange={(e)=> setTotalAmt(e.target.value) } />
                                        </td>
                                        <td style={{ width:'30%' }}>
                                            <span style={{ fontSize:'16px' }}>$</span>
                                            <input type="text" value={gst} 
                                                style={{ backgroundColor:'transparent', border:'none', outline:'none', borderBottom:'0.5px solid grey' }}
                                                onChange={(e)=> setGst(e.target.value) } />
                                        </td>
                                        <td style={{ width:'30%' }}>
                                            <span style={{ fontSize:'16px' }}>$</span>
                                            <input type="text" value={`${finalAmt}`} 
                                                style={{ backgroundColor:'transparent', border:'none', outline:'none', borderBottom:'0.5px solid grey' }}
                                                onChange={(e)=> setFinalAmt(e.target.value) } />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <table className="sales-template-table">
                                
                            
                            </table>
                            
                        </Scrollview>
                    
                    <div style={{ display:'flex', flexDirection:'row', fontSize:'16px', alignItems:'center', justifyContent:'space-around' }}>
                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active"
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center' }}
                            onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); addAttachment(); } }
                        >
                            Update Table
                        </div>
                        
                    </div>
                    
                </div>
               
            </div>
      </Modal>
  );
}


 export default TransitionsModal
 
