import React, { useEffect, useState, Fragment } from 'react'

import DescriptionIcon from '@material-ui/icons/Description';

import '../../screens/intranet/components/style.css'
import { HOST_WITHOUT_SLASH} from '../../store/endpoint';

import { Tooltip } from '@material-ui/core'
import { CheckBoxOutlineBlankOutlined, Close, DeleteForever, DeleteRounded, Edit, ScheduleRounded, Search  } from '@material-ui/icons'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import moment from 'moment';
// import FilesFoldersSearchTab from './FilesFoldersSearchTab';

export default function SingleFile(
    { item, folderId,  setFileSelect, fileSelect, setDelFiles, delFiles, FILEDELETE, CALLBACKFUNCMODAL, deleteSingleFile,
        setFileArr, setCarouselOpen,
        setRenameEntity, setModalType, setFolderName, setFolderId, setFolderModal,
        setDeleteExpire,
        carouselHead, filesData, folderType, FILEEDIT
                                                        
    }
) {
    const { name, url, expiry_date } = item
    let fileUrl = HOST_WITHOUT_SLASH + url
    let split_arr = fileUrl.split('.')
    
    return (
        <Tooltip
            title={name} 
            arrow={true} placement={'top'}
            >
            
            <div 
                className={ (delFiles && delFiles.includes(item.id)) ? "intranet-main-file-card intranet-main-file-card-selected" : "intranet-main-file-card" }
                onMouseEnter={() =>{ setFileSelect(item.id); 
                    // setTooltipSel(`file#${item.id}`); 
                }}
                onMouseLeave={() =>{ setFileSelect(null); 
                    // setTooltipSel(null); 
                }}
                style={{ position:'relative', zIndex:1 }}
                onClick={(e)=>{ 
                    e.stopPropagation(); 
                    e.preventDefault(); 
                }}>
                {   !(delFiles && delFiles.includes(item.id)) && FILEDELETE!==null ?
                <div 
                    className="singleFileDeleteIcon" 
                    onClick={(e)=>{ 
                        e.preventDefault(); 
                        e.stopPropagation(); 
                        deleteSingleFile(folderId, item.id);
                        if(CALLBACKFUNCMODAL!==null){
                            CALLBACKFUNCMODAL(false)
                        }
                    }}>
                    <DeleteForever style={{ fontSize:18 }} />
                </div>  :   null
                }
                {   FILEDELETE!==null ?
                    (delFiles && delFiles.includes(item.id)) ?
                    
                <div 
                    className="singleFileSelectIcon" 
                    onClick={(e)=>{ 
                        e.preventDefault(); 
                        e.stopPropagation(); 
                        let newArr = delFiles.filter(itemData => itemData!==item.id)
                        setDelFiles(newArr)
                    }}>
                    <CheckBoxOutlinedIcon style={{ fontSize:18 }} />
                </div>    
                :
                    
                <div 
                    className="singleFileSelectIcon" 
                    onClick={(e)=>{ 
                        e.preventDefault(); 
                        e.stopPropagation(); 
                        let newArr = delFiles
                        newArr.push(item.id)
                        setDelFiles(newArr);
                    }}>
                    <CheckBoxOutlineBlankOutlined style={{ fontSize:18 }} />
                </div>  :   null
                }
                <div onClick={() =>{ 
                    if(split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif'){
                        setFileArr({ name: carouselHead, 
                            arr: filesData.filter((el)=>{ 
                                let spl = el.url.split('.')
                                if((spl[spl.length-1]==='jpg' || spl[spl.length-1]==='png' || spl[spl.length-1]==='jpeg' || spl[spl.length-1]==='gif')&&
                                (folderType==='team-individual' ? el.id===item.id : true ))
                                    return el
                                else
                                    return null }) })
                        setCarouselOpen(true); 
                    }else window.open(fileUrl, "_blank"); }}  className="main-file-icon">
                    {
                        split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif' ?
                            <img src={fileUrl} alt="ImageError" style={{ objectFit:'cover', width: 100, height: 100 }} />
                        :
                        split_arr[split_arr.length-1]==='pdf' ?
                            <iframe src={fileUrl} scrolling='no' allowFullScreen frameborder={"0"} 
                            allowTransparency style={{ height: 100, width: 100, overflow : 'hidden', overflowY: 'hidden' }}/>
                        :
                            <DescriptionIcon style={{ fontSize: 40 }} />
                    }
                </div>
                <div className="main-file-title">
                    {
                        fileSelect !== item.id && expiry_date ? 
                        <div className='fileExpiryChip'>
                            File Expires on : {moment(expiry_date).isValid() ? moment(expiry_date).format('DD-MM-YYYY') : expiry_date}
                        </div> 
                        : null
                    }
                    {
                        fileSelect === item.id && FILEEDIT!==null && (
                            <div className="singleFileDeleteIcon" style={{ backgroundColor:'transparent', marginLeft:'160px' }}>
                            <Edit 
                                style={{ fontSize: 16 }}
                                onClick={() => {
                                    setRenameEntity('File')
                                    setModalType(false)
                                    setFolderName(name)
                                    setFolderId(item.id)
                                    setFolderModal(true)
                                    
                                }}
                            />
                            </div>
                        )
                    }
                    {
                        fileSelect === item.id && FILEEDIT!==null && ("expiry_date" in item) &&(
                            
                <div 
                className="singleFileDeleteIcon" style={{ marginLeft:'130px' }}
                onClick={(e)=>{ 
                    e.preventDefault(); 
                    e.stopPropagation(); 
                    setDeleteExpire({ fileModal:true, folderId:folderId, item:item});
                    // if(CALLBACKFUNCMODAL!==null){
                    //     CALLBACKFUNCMODAL(false)
                    // }
                }}>
                <DeleteRounded style={{ fontSize:18 }}/>
                <ScheduleRounded style={{ position:'absolute', fontSize:14, right:'1%', bottom:'1%', borderRadius:'50%', backgroundColor:'var(--cl_ivery)' }}  />
            </div>
                        )
                    }
                        
                    <div style={{ display: 'block' }}>
                        <p style={{ height: 17, width: 192, overflow: 'hidden', whiteSpace : 'nowrap', textOverflow : 'ellipsis' }}>
                            {folderType==='team-individual' ? item.team_member : name}
                        </p>
                        {/* <p style={{ height: 17, width: 192, overflow: 'hidden' }}>
                            {cropFileName( folderType==='team-individual' ? item.team_member : name)}
                        </p> */}
                        
                    </div>
                </div>
            </div>
        </Tooltip>
    )
}
