import React from 'react'
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint'

function DeletedMember({ data, select_client, tabType }) {
    const { client_name, site_address, dp_thumbnail, invoice_purchase_no } = data
    let dp_image = HOST_WITHOUT_SLASH + dp_thumbnail
    const cropFileName = (str) =>{
        let lastIdx = str ? str.length : null
        if(lastIdx)
            return lastIdx > 20 ? `${str.substr(0,10)}...${str.substr(str.length-3, str.length)}` : str 
        else 
            return 'Untitled'
    }

    return (
        <div className="single-member">
            <div className="member-thumbnail-layout">
                <img className="member-thumb-img" src={dp_image} alt="enviro employee" />
            </div>
            <div className="member-detail-layout">
                <div className="member-detail-head">
                    { tabType==='pumps' ?  <p>Order No</p> : null}
                    <p>Site Name</p>
                    <p>Site Address</p>
                </div>
                <div className="member-detail-data">
                    { tabType==='pumps' ?  <p>{cropFileName(invoice_purchase_no)}</p> : null }
                    <p>{cropFileName(client_name)}</p>
                    <p>{cropFileName(site_address)}</p>
                </div>
            </div>
        </div>
    )
}

export default DeletedMember
