import React, {useState} from 'react'
import { connect } from 'react-redux'
import { 
    //ArrowForwardIos,
    Edit, DeleteForever, FolderOpen } from '@material-ui/icons';
import { fetch_filter_maintance_list,fetch_car_maintanace_list, __vehicle_modal, __select_maintanace, __delete_maintanace, mntnceListCSVPrint } from '../common/action'
import Swal from 'sweetalert2'
import { cl_red, cl_green } from '../../../../../common/Colors'
import moment from 'moment';
import Scrollbars from '../../../../../common/ImprovedScrollBar';
import ErrorHandling from '../../../../../common/ErrorHandling';
import Modal from '../../truck/Modelfleet'
import { VEHICLE_MNTCE_FILESFOLDER, VEHICLE_MNTCE_FILESFOLDER_CREATE } from '../../../../../store/endpoint';

function MaintanaceTable({
    fetch_filter_car,
    filterKey,
    car_maintanace_list,
    car_maintanance_page,
    fetch_maintanace,
    vehicle_modal_action,
    select_maintanace,
    maintanace_delete,
    isLoading,
    tabType,
    pageLoader,
    setPageLoader, car_maintanace_stoppagination, car_maintanace_error, 
    car_maintanace_loader,maintanace_search_qry,
    clearFilterDate,
    setClearFilterDate,
    
    filterFromDate, filterToDate, setFilterDate,
    invokeDate, setInvokeDate,
    perms
}) {

    let balance_row = 0
    if (car_maintanace_list && car_maintanace_list.length < 8) {
        balance_row = 8 - car_maintanace_list.length
    }


  const [modal, setModal] = useState(false)
  const [selectedCar, setSelectedCar] = useState(null)
  const [selectedCarId, setSelectedCarId] = useState(null)
  const [selectedFolderId, setSelectedFolderId] = useState(null)

    // let page_no = 0
    // if (car_maintanance_page < 10) {
    //     page_no = '0' + car_maintanance_page
    // } else {
    //     page_no = car_maintanance_page
    // }

    const delete_btn_click = (maint_id) => {

        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Report Deleted successfully!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                maintanace_delete(tabType, maint_id, success, failed, car_maintanance_page)
            }
        })


    }
    const fetch = () => {
        if (!car_maintanace_loader) {
            if (filterKey !== 'all') {
                let postData = new FormData()
                postData.append('registration', filterKey)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey, car_maintanance_page, 'filter')
            } else if (clearFilterDate || invokeDate) {
                let postData = new FormData()
                postData.append('from_date', filterFromDate.val)
                postData.append('to_date',filterToDate.val)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'day', 'all', car_maintanance_page , 'date')
            } else if (maintanace_search_qry !== '') {
                let postData = new FormData()
                postData.append('registration', maintanace_search_qry)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey, car_maintanance_page , 'search')
            }
            else {
                fetch_maintanace(tabType, car_maintanance_page)

            }
            // fetch_maintanace(tabType, car_maintanance_page )
        }

    }
    const ScrollUp = (val) => {
        const { top } = val
        // if (!searchFnOpen)
        if (top === 1 && !car_maintanace_stoppagination && !car_maintanace_loader && car_maintanace_error === null) {
            console.log('SCROLL CALLED')
            if (filterKey !== 'all') {
                let postData = new FormData()
                postData.append('registration', filterKey)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey, car_maintanance_page + 1, 'filter')
            } else if (clearFilterDate || invokeDate) {
                let postData = new FormData()
                postData.append('from_date', filterFromDate.val)
                postData.append('to_date',filterToDate.val)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'day', 'all', car_maintanance_page + 1, 'date')
            } else if (maintanace_search_qry !== '') {
                let postData = new FormData()
                postData.append('registration', maintanace_search_qry)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey, car_maintanance_page + 1, 'search')
            }
            else {
                fetch_maintanace(tabType, car_maintanance_page + 1)

            }
        }
    }

    return (
        <div className="TruckListTable">
            <div className="truck-maintance-tbl-list">
                <Scrollbars style={{ height: '60vh' }} onUpdate={ScrollUp} className="table-details-truck">
                    <table className={isLoading || pageLoader ? "pre-inspc-table-layout table-details-truck-tblLoader" : "pre-inspc-table-layout"} style={{ maxHeight: '80vh' }}>

                        <thead>
                            <tr className="heading-truck-table">
                                <th>License Plate Number</th>
                                <th>Description</th>
                                <th>Invoice Date</th>
                                <th>Service Date</th>
                                <th>Ometer</th>
                                <th>Invoice No</th>
                                <th>Service Provider</th>
                                <th>Hours</th>
                                <th>L - Cost</th>
                                <th>S- Part</th>
                                <th>GST</th>
                                <th>Total Cost</th>
                                <th>Actions</th>
                            </tr>

                        </thead>
                        <tbody>



                            {

                                car_maintanace_list.map((item, key) => {
                                    const { id, registration, description, invoice_date, service_date, ometer, folder,
                                        invoice_number, service_provided, hours, l_cost, s_part, gst, total_cost } = item
                                    return (
                                        <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'transparent', height : '5.3vh' }}>
                                            <td>{registration}</td>
                                            <td>{description}</td>
                                            <td>{moment(invoice_date).isValid() ? moment(invoice_date).format('DD-MM-YYYY') : "Date Not Provided"}</td>
                                            <td>{moment(service_date).isValid() ? moment(service_date).format('DD-MM-YYYY') : "Date Not Provided"}</td>
                                            <td>{ometer}</td>
                                            <td>{invoice_number}</td>
                                            <td>{service_provided}</td>
                                            <td>{hours}</td>
                                            <td>{l_cost}</td>
                                            <td>{s_part}</td>
                                            <td>{gst}</td>
                                            <td>{total_cost}</td>


                                            <td>
                                                <div className="table-action-field">
                                                <div className="table-action-btn"
                                                    onClick={() => {
                                                      setSelectedCar(registration)
                                                      setSelectedCarId(id)
                                                      setSelectedFolderId(folder)
                                                      setModal(true)
                                                    }}
                                                  >
                                                    <FolderOpen style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                  </div>
                          
                                                    <div
                                                        onClick={() => {
                                                            vehicle_modal_action(true)
                                                            select_maintanace(item)
                                                        }}
                                                        className="table-action-btn"
                                                    >
                                                        <Edit style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                    </div>
                                                    <div onClick={() => delete_btn_click(id)} className="table-action-btn">
                                                        <DeleteForever style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            {
                                car_maintanace_list && car_maintanace_list.length === 0 && (
                                    (!car_maintanace_loader && car_maintanace_error === null) &&
                                    <tr>
                                        <td colSpan='14'><p className="p-0 m-0">No Record Found</p></td>
                                    </tr>
                                )
                            }



                        </tbody>
                        {car_maintanace_loader && <tbody className='skeleton'>
                            {
                                [...Array(8)].map((_, key) => {
                                    return (
                                        <tr key={key}>
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>}
                        {(!car_maintanace_loader && car_maintanace_error === null) &&
                            [...Array(balance_row)].map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                    </tr>
                                )
                            })

                        }
                        {
                            car_maintanace_error && <ErrorHandling error={car_maintanace_error} fetch={fetch} />
                        }
                    </table>
                    {/* {
                        car_maintanace_error && <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: '200px', justifyContent: 'center' }}>
                            <span style={{ textAlign: 'center' }}>{car_maintanace_error.text}</span>
                            <button className='vechile-details-btn-add' onClick={fetch}>Retry</button>
                        </div>
                    } */}
                </Scrollbars>
                {/* <div className="truck-table-back-previous-btn">
                    <div className="pagination-btn-layout">
                        {
                            car_maintanance_page > 1 ? (
                                <button
                                    onClick={() => fetch_maintanace(tabType, car_maintanance_page - 1, setPageLoader)}
                                    className="table-list-next-btn">PREVIOUS</button>
                            ) : (
                                <button className="table-list-next-btn disabled-btn">PREVIOUS</button>
                            )
                        }
                        <div className="page-no">
                            <p className="p-0 m-0">{page_no}</p>
                        </div>
                        {
                            car_maintanace_list && car_maintanace_list.length === 8 ? (
                                <button
                                    onClick={() => fetch_maintanace(tabType, car_maintanance_page + 1, setPageLoader)}
                                    className="table-list-next-btn">NEXT</button>
                            ) : (
                                <button className="table-list-next-btn disabled-btn">NEXT</button>
                            )
                        }
                    </div>
                </div> */}
                <div className="sgr-maintanace-export-btn-layout">
                    <button
                        onClick={() => mntnceListCSVPrint(tabType, filterKey,maintanace_search_qry,clearFilterDate || invokeDate ? filterFromDate.val : null, clearFilterDate || invokeDate ? filterToDate.val : null)}
                        className="table-list-next-btn2">EXPORT REPORT</button>
                    {/* <button className="table-list-next-btn2">GO TO REPORTS <ArrowForwardIos style={{ fontSize: 14 }} /> </button> */}
                </div>
            </div>
            <Modal
        modalStatus={modal}
        modalAction={setModal}
        tabType={tabType}
        vehicle_reg={selectedCar}
        vehicle_id={selectedCarId}
        vehicleType={tabType === 'pumps' ? 'utes' : 'cars'}
        spl={'mntnc'}
        perms={perms}
        selectedFolderId={selectedFolderId}
        urlToAddFile={`${VEHICLE_MNTCE_FILESFOLDER_CREATE}car/${selectedCarId}/`}
        dispatchFn={() =>{
            let pg = 1;
            if(car_maintanace_list.findIndex(el => el.id===selectedCarId)===0)
                pg = 1;
            else 
                pg = Math.ceil((parseInt(car_maintanace_list.findIndex(el => el.id===selectedCarId))+1)/15)
            // console.log(pg,car_maintanace_list.findIndex(el => el.id===selectedCarId),el => el.id===selectedCarId, Math.ceil((parseInt(truck_maintanace_list.findIndex(el => el.id===selectedCarId))+1)/15))
            fetch_maintanace(tabType, pg)
        }}
        url={`${VEHICLE_MNTCE_FILESFOLDER}car/${selectedCarId}/${selectedFolderId}`}
      />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { filterKey,car_maintanace_list, car_maintanance_page, isLoading, 
        car_maintanace_stoppagination, car_maintanace_error, car_maintanace_loader,maintanace_search_qry } = state.vehicle__car
    return { filterKey,car_maintanace_list, car_maintanance_page, 
        isLoading, car_maintanace_stoppagination, car_maintanace_error, car_maintanace_loader,maintanace_search_qry }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_maintanace: (tabType, page, callBk = null) => dispatch(fetch_car_maintanace_list(tabType, page, callBk)),
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        select_maintanace: (maintanace) => dispatch(__select_maintanace(maintanace)),
        maintanace_delete: (tabType, maint_id, success, failed, page_no) => dispatch(__delete_maintanace(tabType, maint_id, success, failed, page_no)),
        fetch_filter_car: (tab, success, failed, data, type, id, fetchtype) => dispatch(fetch_filter_maintance_list(tab, success, failed, data, type, id, fetchtype)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintanaceTable)

