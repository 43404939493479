import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Scrollview from '../../../common/ImprovedScrollBar.jsx';
import { Avatar } from '@material-ui/core'
import { fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../common/action'
import moment from 'moment'

function Availabilities({ employee_listDateTime, vehicle_listDateTime, fetch_Emp_availability, fetch_Veh_availability, screen_type }) {

    const [employeeRender, setEmployeeRender] = useState(true)
    const [employeeLoader, setEmployeeeLoader] = useState(true)
    const [vehicleLoader, setVehicleLoader] = useState(true)
    // const [hoverVal, setHoverVal] = useState(null)

    useEffect(() => {
        let filter = 'start='+ moment().format('YYYY-MM-DD')
        filter = filter + ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD')
        filter = filter + ' ' + moment().add(1,'day').format('HH:mm:ss')
        fetch_Emp_availability(filter, setEmployeeeLoader)
        console.log("UPDATE AVAILABILITIES")
        fetch_Veh_availability(filter, screen_type, setVehicleLoader)
    }, [fetch_Emp_availability, fetch_Veh_availability, screen_type])

    const RenderEmployees = () => {
        return (
            <Scrollview style={{ height: '25vh' }}>
                {   employeeLoader ?
                    [...Array(10)].map((_,k) => {
                        return(
                            <div key={k} className="shdl-emp-body shdl-emp-bodyLoader">
                                <Avatar/>
                                <p style={{ width: 150 }}></p>
                                <div style={{ height: 20, width: 20, backgroundColor: 'green',position:'relative' }} className='shdl-emp-body-tooltipbody'></div>
                                <p style={{ width: 80 }}></p>
                            </div>
                        )
                    })
                    :
                    employee_listDateTime && employee_listDateTime.filter(el => el.terminated_status===true).map((item, key) => {
                        const { name, user_type, is_available, 
                            //terminated_status  
                        } = item
                        return (
                            <div key={key} className="shdl-emp-body"
                            style={{ backgroundColor: key%2 ? 'white' : 'rgba(0,0,255,.05)' }}
                            // onMouseEnter={()=>{ setHoverVal(item.id) }}
                            // onMouseLeave={()=>{ setHoverVal(null) }}
                            >
                                <Avatar src={item.dp} />
                                <p style={{ width: '30%' }}>{name}</p>

                                {
                                    is_available ? (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'green',position:'relative' }} className='shdl-emp-body-tooltipbody' 
                                        >
                                            <p className='shdl-quote-box-item-tooltip' style={{width:'80px'}}>Available</p>
                                        </div>
                                    ) : (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'orange',position:'relative' }} className='shdl-emp-body-tooltipbody' 
                                        >
                                            <p className='shdl-quote-box-item-tooltip' style={{width:'100px'}}>Not Available</p>
                                        </div>
                                    )
                                }
                                {/* <Tooltip open={hoverVal===item.id} 
                                            title={is_available ? 'Available' : 'Not Avilable'} 
                                            arrow={true} placement={'left'}  >
                                {
                                    is_available && !terminated_status ? (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'green' }}></div>
                                    ) : (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'orange' }}></div>
                                    )
                                }
                                </Tooltip> */}
                                <p style={{ width: '30%' }}>{user_type}</p>
                            </div>
                        )
                    })
                }
            </Scrollview>
        )
    }

    const stringToColor = (val) =>{
        let hash = 0;
        let i;

  /* eslint-disable no-bitwise */
        for (i = 0; i < val.length; i += 1) {
            hash = val.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
  /* eslint-enable no-bitwise */
        console.log('%c color',`color:${color}`,color, )
        return color;
    }

    const RenderVehicles = () => {
        // const [hoverVal, setHoverVal] = useState(null)
        return (
            <Scrollview style={{ height: '25vh' }}>
                {
                    vehicleLoader ?
                    [...Array(10)].map((_,k) => {
                        return(
                            <div key={k} className="shdl-emp-body shdl-emp-bodyLoader">
                                <Avatar/>
                                <p style={{ width: 150 }}></p>
                                <div style={{ height: 20, width: 20, backgroundColor: 'green',position:'relative' }} className='shdl-emp-body-tooltipbody'></div>
                                <p style={{ width: 80 }}></p>
                            </div>
                        )
                    })
                    
                    :
                    vehicle_listDateTime.filter(el => el.vehicle_type==='truck').map((item, key) => {
                        const { vehicle_type, registration, is_available } = item
                        return (
                            <div key={key} className="shdl-emp-body"
                            style={{ backgroundColor: key%2 ? 'white' : 'rgba(0,0,255,.05)' }}
                            // onMouseEnter={()=>{ setHoverVal(item.id) }}
                            // onMouseLeave={()=>{ setHoverVal(null) }}
                            >
                                <Avatar style={{ backgroundColor : stringToColor(vehicle_type) }}>{vehicle_type.toUpperCase().charAt(0)}</Avatar>
                                <p style={{ width: '30%' }}>{registration}</p>
                                {
                                    is_available ? (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'green',position:'relative' }} className='shdl-emp-body-tooltipbody' 
                                        >
                                            <p className='shdl-quote-box-item-tooltip' style={{width:'80px'}}>Available</p>
                                        </div>
                                    ) : (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'orange',position:'relative' }} className='shdl-emp-body-tooltipbody' 
                                        >
                                            <p className='shdl-quote-box-item-tooltip' style={{width:'100px'}}>Not Available</p>
                                        </div>
                                    )
                                }
                                {/* <Tooltip open={hoverVal===item.id} 
                                            title={is_available ? 'Available' : 'Not Avilable'} 
                                            arrow={true} placement={'left'}  >
                                {
                                    is_available ? (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'green' }}></div>
                                    ) : (
                                        <div style={{ height: 20, width: 20, backgroundColor: 'orange' }}></div>
                                    )
                                }
                                </Tooltip> */}
                                <p style={{ width: '30%' }}>{vehicle_type}</p>
                            </div>
                        )
                    })
                }
            </Scrollview>
        )
    }

    return (
        <div className="shdl-emp-layout">
            <div className="shdl-emp-header">
                <button 
                    onClick={() => setEmployeeRender(true)}
                    className={employeeRender ? 'shdl-emp-btn shdl-emp-btn-active' : 'shdl-emp-btn '}
                >Employees</button>
                <button 
                    className={!employeeRender ? 'shdl-emp-btn shdl-emp-btn-active' : 'shdl-emp-btn '}
                    onClick={() => setEmployeeRender(false)}
                >Vehicles</button>
            </div>
            {
                employeeRender ? (
                    <RenderEmployees />
                ) : (
                    <RenderVehicles />
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { employee_listDateTime, vehicle_listDateTime } = state.schedule
    return { employee_listDateTime, vehicle_listDateTime }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_Emp_availability: (filter, callBackFn) => dispatch(fetch_Employee_avilabilities(filter, callBackFn)),
        fetch_Veh_availability: (filter,screen_type, callBackFn) => dispatch(fetch_Vehicle_avilabilities(filter, screen_type, callBackFn)),
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (Availabilities)
