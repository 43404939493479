import React, {useState} from 'react'
import { connect } from 'react-redux'

import Sidebar from '../../../../common/sidebar'
import Header from '../common/Header'
import Topbar from './Topbar'
import Members from './Members'

import { search_client_list, search_temp_client_list } from '../../action'


function App({searchQuery, tabType, setTabType, searchTempQuery, permissions}) {
    const [tabState, setTabState] = useState(0)
    // const [tabType, setTabType] = useState('waste')
    
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='main' tabType={tabType} setTabType={setTabType}/>
                <Topbar searchQuery={searchQuery} searchTempQuery={searchTempQuery} tabType={tabType} addData={permissions.site.add}  tabState={tabState}
                setTabState={setTabState}/>
                <Members tabState={tabState} tabType={tabType} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { permissions } = state.userLogin
    return { permissions }
}


const mapDispatchToProps = (dispatch) => {
    return {
        searchQuery: (val, tabType) => dispatch(search_client_list(val, tabType)),
        searchTempQuery: (val, tabType) => dispatch(search_temp_client_list(val, tabType))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (App)
