import React from "react";

export default class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorObj: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ ...this.state, errorObj : errorInfo})
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError && this.props.errorCatcherSwitch) {
      // You can render any custom fallback UI
      return(
        <div className="errorDivParentModal">
            <div className="errorDivModal" onClick={()=> { 
          window.location.reload() }}>
          <div className="errorDivHeadTab">
            Sorry for the inconvenience. 
          </div>
          <div className="errorDivDescPara">
            <div>
            <p>
              Sorry, this was not supposed to happen. Please refresh to continue your work.
            </p>
            </div>
            {/* {
              this.state.errorObj!==null ?
              <div className="errorConsole">
                <span>Error:</span>
                <div>
                  {JSON.stringify(this.state.errorObj)}
                </div>
              </div>
            :
              null
            } */}
            
          </div>
        </div>
        </div>
        
      );
    }

    return this.props.children; 
  }
}