import Api from '../../store/api'
import { STORAGE_KEY_VALS } from '../../store/config'
import { ACCOUNT_PERMISSIONS, ACCOUNT_PROFILE, AUTHENTICATION, PUSH_NOTIFICATION, TEAM_REFRESH_LOGIN } from '../../store/endpoint'
import { messaging } from '../../store/firebase'

export const signin = (user_data, failed) => {
    return function (dispatch) {
        
        dispatch({ type: 'AUTH_FETCHING' })

        const success = (response) => {
            dispatch({ type: 'AUTH_FETCHING_SUCCESS', token: response.access})
            NotificationTokenGen()
            localStorage.setItem(STORAGE_KEY_VALS.tk, response.access);
            localStorage.setItem(STORAGE_KEY_VALS.refTk, response.refresh);
            localStorage.setItem(STORAGE_KEY_VALS.logStatus, true);
            dispatch(profile())
            dispatch(profile_permissions())
        }

        const failedSign = (failedRes) =>{
            console.log('AUTHFETCHFAILED',failedRes)
            
            if(failedRes.status===401 && localStorage.getItem(STORAGE_KEY_VALS.refTk)){
                refresh_signin(failed)
            }else if(failedRes.status===401){
                dispatch({ type: "SIGNOUT" })
                dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
                failed()
            }
        }

        const NotificationTokenGen = () => {
            console.log('login successful response')
            if(messaging) {
                messaging.requestPermission()
                .then(async function() {
                        const token = await messaging.getToken();
                        console.log('token', token)
                        let token_key = token.toString()
                        dispatch({ type: 'FCM_TOKEN_SET', fcm: token_key})
                        let appendData = new FormData()
                        appendData.append('keys', token_key)
                        //appendData.append('keys', 'jithin-test')
                        console.log('push notification api call before')
                        Api('post-form', PUSH_NOTIFICATION, appendData)
                        console.log('push notification api call after')
                })
                .catch(function(err) {
                console.log("Unable to get permission to notify.", err);
                });
                navigator.serviceWorker.addEventListener("message", (message) => NotifiactionAction(message))
            }
        }

        const NotifiactionAction = (message) => {
            console.log(message)
            console.log('notification recieved')
        }

        
        Api('post', AUTHENTICATION, user_data, success, failedSign)
    }
}

export const profile = () => {
    console.log('ProfileFnAction')
    return function (dispatch) {
        const redirectingRoute = () =>{
            console.log('REDIRECTINGROUTECLEARANCE')
            localStorage.removeItem(STORAGE_KEY_VALS.logStatus);
            localStorage.removeItem(STORAGE_KEY_VALS.tk)
            localStorage.removeItem(STORAGE_KEY_VALS.refTk)
        }
        Api('get', ACCOUNT_PROFILE).then(res => {
            console.log(res)
            if(res && res.length===0)
                dispatch(refresh_signin(redirectingRoute))
            else
            dispatch({ type: 'PROFILE_DATAS_FETCH', user: res})
        })
        .catch(err => {
            dispatch(refresh_signin(redirectingRoute))
        })
    }
}


export const profile_permissions = () => {
    return function (dispatch) {
        Api('get', ACCOUNT_PERMISSIONS).then(res => {
            // console.log(res)
            
                dispatch({ type: 'PROFILE_PERMISSION', permissions: res})
        })
    }
}

export const update_profile = (data, success, failed) => {
    return function (dispatch) {
        Api('patch-form', ACCOUNT_PROFILE, data, success, failed).then(res => {
            console.log(res)
            dispatch(profile())
        })
    }
}

export const refetch_profile = () =>{
    return function (dispatch){
        console.log('REFRESHING DATA')
        const success = (response) => {
            console.log('SUCCESS!!!!')
            dispatch({ type: 'AUTH_FETCHING_SUCCESS', token: response.access})
            NotificationTokenGen()
            localStorage.setItem(STORAGE_KEY_VALS.tk, response.access);
            localStorage.setItem(STORAGE_KEY_VALS.refTk, response.refresh);
            localStorage.setItem(STORAGE_KEY_VALS.logStatus, true);
            dispatch(profile())
            dispatch(profile_permissions())
        }

        const NotificationTokenGen = () => {
            console.log('login successful response')
            if(messaging) {
                messaging.requestPermission()
                .then(async function() {
                        const token = await messaging.getToken();
                        console.log('token', token)
                        let token_key = token.toString()
                        dispatch({ type: 'FCM_TOKEN_SET', fcm: token_key})
                        let appendData = new FormData()
                        appendData.append('keys', token_key)
                        //appendData.append('keys', 'jithin-test')
                        console.log('push notification api call before')
                        Api('post-form', PUSH_NOTIFICATION, appendData)
                        console.log('push notification api call after')
                })
                .catch(function(err) {
                console.log("Unable to get permission to notify.", err);
                });
                navigator.serviceWorker.addEventListener("message", (message) => NotifiactionAction(message))
            }
        }

        const NotifiactionAction = (message) => {
            console.log(message)
            console.log('notification recieved')
        }

            const failed = () =>{
                console.log('CALLING FAILED!!')
                dispatch({ type: "SIGNOUT" })
                dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
            }
        Api('get', TEAM_REFRESH_LOGIN).then(res => {
            console.log(res)
            if(res && res.length===0){
                failed()
            }else{
                success(res)
            }
        }) 
    }
}

export const refresh_signin = (failed) => {
    let user_data = new FormData()
    user_data.append('refresh', localStorage.getItem(STORAGE_KEY_VALS.refTk))
    console.log('refreshAction',localStorage.getItem(STORAGE_KEY_VALS.refTk))
    return function (dispatch) {
        
        dispatch({ type: 'AUTH_FETCHING' })

        const success = (response) => {
            console.log('refresh-success')
            dispatch({ type: 'AUTH_FETCHING_SUCCESS', token: response.access})
            NotificationTokenGen()
            localStorage.setItem(STORAGE_KEY_VALS.tk, response.access);
            localStorage.setItem(STORAGE_KEY_VALS.logStatus, true);
            dispatch(profile())
            dispatch(profile_permissions())
        }

        const NotificationTokenGen = () => {
            console.log('login successful response')
            if(messaging) {
                messaging.requestPermission()
                .then(async function() {
                        const token = await messaging.getToken();
                        console.log('token', token)
                        let token_key = token.toString()
                        dispatch({ type: 'FCM_TOKEN_SET', fcm: token_key})
                        let appendData = new FormData()
                        appendData.append('keys', token_key)
                        //appendData.append('keys', 'jithin-test')
                        console.log('push notification api call before')
                        Api('post-form', PUSH_NOTIFICATION, appendData)
                        console.log('push notification api call after')
                })
                .catch(function(err) {
                console.log("Unable to get permission to notify.", err);
                });
                navigator.serviceWorker.addEventListener("message", (message) => NotifiactionAction(message))
            }
        }

        const NotifiactionAction = (message) => {
            console.log(message)
            console.log('notification recieved')
        }

        const FailedFn = () =>{
            dispatch({ type: "SIGNOUT" })
                dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
            failed()
        }

        
        Api('post', `${AUTHENTICATION}refresh/`, user_data, success, FailedFn)
    }
}