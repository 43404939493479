import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { fetch_job_list_readyForSchd, schedule_Out_job, schedule_Out_job_edit } from '../common/action'
import DateTimeInput from '../../../common/DateTimeInput'
import moment from 'moment'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';

import Draggable from 'react-draggable';
import { CancelRounded, DragIndicatorRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    screen_type,
    modalStatus, 
    modalAction,
    selectedJob,
    schedule_a_job,
    fetch_jobs,
    job_list_weekFilter,
    scheduled,
    isMock=false,
    schdlListType,
    reSchedule=false,
    jobTab
}) {
    const classes = useStyles();

    const handleClose = () => {
        setApplyButton(false)
        modalAction(false)
        setStartDate(moment().format('YYYY-MM-DD'))
        setStartTime(moment({hours:0, minutes:0}).format('HH:mm'))
        setEndDate(moment().format('DD-MM-YYYY'))
        setEndTime(moment({hours:0, minutes:0}).format('HH:mm'))
    };

    const { code, client_name, amount, id, schedule } = selectedJob

    let prevStartDate = moment().format('YYYY-MM-DD')
    let prevStartTime = moment({hours:0, minutes:0}).format('HH:mm')
    let prevEndTime = moment({hours:0, minutes:0}).format('HH:mm')
    let prevEndDate = moment().format('YYYY-MM-DD')

    if(schedule) {
        prevStartDate = schedule.start_date
        prevStartTime = schedule.start_time
        prevEndTime = schedule.end_time
        prevEndDate = schedule.end_date
    }

    const [StartDate, setStartDate]                      = useState(prevStartDate)
    const [StartTime, setStartTime]                      = useState(prevStartTime)
    const [EndTime, setEndTime]                          = useState(prevEndTime)
    const [EndDate, setEndDate]                          = useState(prevEndDate)
    const [applyButton, setApplyButton]                  = useState(false)
    const [startHours, setStartHours]                    = useState(moment(prevStartTime,'HH:mm').hours())
    const [startMinutes, setStartMinutes]                = useState(moment(prevStartTime,'HH:mm').minutes())
    const [endHours, setEndHours]                        = useState(moment(prevEndTime,'HH:mm').hours())
    const [endMinutes, setEndMinutes]                    = useState(moment(prevEndTime,'HH:mm').minutes())
    const [companyName, setCompanyName]                  = useState('')
    const [openDTEditor,setOpenDTEditor]                 = useState(false)
    const [EndDateChng, setEndDateChng]                  = useState(false)
    
    // console.log("PARSETIME ",parseInt(StartTime.substring(0,2))===0+1 || (parseInt(StartTime.substring(0,2))-12)===0+1)
    const schedule_btn_fx = (jobType) => {

       const success = (res) => {
        let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(job_list_weekFilter,'w').format('YYYY-MM-DD')}`;
        fetch_jobs(screen_type, 1, fromD, jobTab);

        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Job Scheduled!',
            showConfirmButton: false,
            timer: 1500
        })
        handleClose()
       }

       const failed = () => {
        handleClose()
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
        })
       }
        let data = {
            'id' : id,
            'start_date' : moment(StartDate).format('YYYY-MM-DD'),
            'start_time' : StartTime + ':00',
            'outsourced_company_name' : companyName,
            'end_time'   : EndTime + ':00',
            'end_date'   : EndDate ? moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD'),
        }
       schedule_a_job(screen_type, data, reSchedule ? { id : jobType } : jobType, schdlListType==='v' ? null : 'm', success, failed)
    }

    const isTimeInCorrect = () =>{
        console.log( moment(StartTime,'HH:mm').isValid(),moment(EndTime,'HH:mm').isValid(),StartTime,EndTime)
        if(StartTime && EndTime && moment(StartTime,'HH:mm').isValid() && moment(EndTime,'HH:mm').isValid()
            && (moment(StartDate).format('YYYY-MM-DD')===moment(EndDate).format('YYYY-MM-DD'))
        ){
            var strTm = moment(StartTime, 'HH:mm');
            var endTm = moment(EndTime, 'HH:mm');
            var hr = moment.duration(endTm.diff(strTm),'hours')
            var min = parseInt(moment.duration(endTm.diff(strTm),'minutes'))-(hr*60);
            console.log(strTm,endTm,hr,min)
            return (hr<0 || min<=0);
        }
        else
            return false
        
    }
    const setEndDefaultDates = (e) =>{
        setEndDateChng(true)
    }


    const setDefaultDates = (e) =>{
        if(!EndDateChng)
            setEndDate(moment(e).format('YYYY-MM-DD'))
    }


  return (
      <Draggable handle="strong"  
      bounds={{top: -parseInt(window.innerHeight/12),
           left: -parseInt(window.innerWidth/2), 
           right: parseInt(window.innerWidth/2), 
           bottom: parseInt(window.innerHeight - (window.innerHeight/6))}} 
      //bounds="parent"
      >
      <Modal
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        hideBackdrop={true}
      >
        <div className="shdl-modal-layout-mainn">
            <strong className="draggerHead">
              <div style={{ width:'50%' }}>
                  <DragIndicatorRounded style={{ transform:'rotate(-90deg)', cursor:'all-scroll' }} />
              </div>
              <div>
                  <CancelRounded style={{ color:'red', cursor:'pointer' }} onClick={handleClose} />
              </div>
            </strong>
          
          <div className="shdl-modal-layout">
          <Scrollbars autoHide style={{ height:'65vh', width:'380px', marginRight:'10px' }}>
                <div className="shdl-modal-left-lay">
                <h3>Job {code}</h3>
                <div className="shdl-modal-status">
                    <h3>Status</h3>
                    {
                        scheduled ? (
                            <div className="shdl-modal-status-box" />
                        ) : (
                            <div style={{ backgroundColor: 'red' }} className="shdl-modal-status-box" />
                        )
                    }
                    
                </div>

                <p className="shdl-modal-titles">{client_name}</p>
                <p className="shdl-modal-titles">{amount}$</p>

                <div className="shdl-modal-first-layout">
                    <p>Start Date</p>
                    <div style={{ padding: '10px 0px' }}>
                        <DateTimeInput
                            dateValue={StartDate}
                            dateSetFn={setStartDate}
                            clsName={'client-input'}
                            past={true}
                            acceptFunc={setDefaultDates}

                        />
                    </div>

                    <p>Start Time</p>
                    <div className="schdl-timing-box-main">
                        
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setStartHours(parseInt(e.target.value)); setStartTime(moment({hours:parseInt(e.target.value), minutes:startMinutes}).format('HH:mm'));
                                console.log('StrHour',moment(EndDate).format('YYYY-MM-DD'),moment(EndTime).format('HH:mm:ss'),moment(EndTime, 'HH:mm:ss').isValid())
                                }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        
                                        return (<option  value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select
                            value={startMinutes}
                                className="schdl-timing-input"  
                                onChange={(e) => { 
                                    setStartMinutes(parseInt(e.target.value)); setStartTime(moment({hours:startHours, minutes:parseInt(e.target.value)}).format('HH:mm')); 
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '0' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                            className="schdl-timing-input"
                            id="startTimeMeridian"
                                onChange={(e)=>{  if(e.target.value==='pm')
                                                    if(startHours!==12)
                                                    {
                                                        let temp = parseInt(startHours)+12
                                                        setStartTime(moment({hours:temp, minutes:startMinutes}).format('HH:mm'));
                                                    }
                                                    else{
                                                        setStartTime(moment({hours:0, minutes:startMinutes}).format('HH:mm'))
                                                    }
                                                else {
                                                    setStartTime(moment({hours: startHours, minutes:startMinutes}).format('HH:mm'))
                                                }
                                
                                                }}
                            >
                                <option value={'am'}>AM</option>
                                <option value={'pm'}>PM</option>
                            </select>
                        </div>
                    </div>

                    <p>End Date</p>
                    <div style={{ padding: '10px 0px' }}>
                    {   !openDTEditor   ?
                        <input
                        type="text"
                        value={moment(EndDate).isValid() ? moment(EndDate).format('DD-MM-YYYY') : EndDate}
                        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 5px', justifyContent:'center' }}
                        className='client-input'
                        onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setOpenDTEditor(!openDTEditor) }}
                      />
                    :
                        <DateTimeInput
                            dateValue={EndDate}
                            dateSetFn={setEndDate}
                            clsName={'client-input'}
                            rangeDate={StartDate}
                            past={true}
                            acceptFunc={setEndDefaultDates}
                            openEdit={openDTEditor}
                        />
                    }
                    </div>

                    <p>End Time</p>
                    <div className="schdl-timing-box-main">
                        
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setEndHours(parseInt(e.target.value)); setEndTime(moment({hours:parseInt(e.target.value), minutes:endMinutes}).format('HH:mm')); 
                                }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        
                                        return (<option value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                                value={endMinutes}
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setEndMinutes(parseInt(e.target.value)); setEndTime(moment({hours:endHours, minutes:parseInt(e.target.value)}).format('HH:mm')); 
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '0' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                            className="schdl-timing-input"
                            id="endTimeMeridian"
                                onChange={(e)=>{    
                                    console.log('StD',StartDate,'EdD',EndDate)
                                                if(e.target.value==='pm')
                                                    if(endHours!==12)
                                                    {
                                                        let temp = parseInt(endHours)+12
                                                        setEndTime(moment({hours:temp, minutes:endMinutes}).format('HH:mm'));
                                                    }
                                                    else{
                                                        
                                                        setEndTime(moment({hours:0, minutes:endMinutes}).format('HH:mm'))
                                                    }
                                                else{ 
                                                    setEndTime(moment({hours: endHours, minutes:endMinutes}).format('HH:mm'))
                                                }    console.log(StartDate," ",StartTime," ",EndDate," ",EndTime)
                                                
                                                }}
                            >
                                <option value={'am'}>AM</option>
                                <option value={'pm'}>PM</option>
                            </select>
                        </div>
                    </div>

                    <p>Company Name</p>
                    <div style={{ padding: '10px 0px' }}>
                    <input
                        type="text"
                        value={companyName}
                        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 5px', justifyContent:'center', borderBottom: '1px solid grey' }}
                        className='client-input'
                        onChange={(e) =>{ e.preventDefault(); e.stopPropagation(); setCompanyName(e.target.value); }}
                    />
                    </div>

                </div>
                
            </div>
            </Scrollbars>
            
           
            </div>
            <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                {
                    isTimeInCorrect() ?
                    <button className="disabled-btn"
                        style={{ color:'red' }}
                    >Time entered is incorrect.</button>
                    :
                    (
                        <button 
                            disabled={applyButton}
                            onClick={() => {
                                setApplyButton(true)
                                schedule_btn_fx(isMock)
                            }}
                        >Schedule</button>
                    )
                }
                
            </div>
        </div>
        </Modal>
        </Draggable>
  );
}

const mapStateToProps = (state) => {
    const { vehicle_listDateTime, job_list_weekFilter, schdlListType, jobTab } = state.schedule
    return { vehicle_listDateTime, job_list_weekFilter, schdlListType, jobTab }
}

const mapDispatchToProps = (dispatch) => {
    return {
        schedule_a_job: (type, data, jobType, schType, success, failed) => dispatch(schedule_Out_job(type, data, jobType, schType, success, failed)),
        schedule_edit_job: (type, data, schType, success, failed) => dispatch(schedule_Out_job_edit(type, data, schType, success, failed)),
        fetch_jobs: (type, page, frmD, jobTab) => dispatch(fetch_job_list_readyForSchd(type, page, frmD, jobTab)),

        //setSelectTeam: (item) => dispatch(setToTeam(item))
    }
}


 export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal)
 
