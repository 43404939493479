import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Home, 
    NotificationsActive, 
    Group, 
    LocalShipping, 
    Person, 
    Timeline, 
    ControlCamera, 
    Business, 
    Stars, 
    Today,
    Language,
    AccountBalance,
    MonetizationOn
} from '@material-ui/icons';
// import { FaceDummy } from './Images'
// import AppRoute from '../../Screens/route'
import { routes } from '../screens/route'
import { HOST_WITHOUT_SLASH } from '../store/endpoint';
import { connect } from 'react-redux';
import ProfileModal from './profile_modal'
import { update_profile } from '../screens/login/action';
import Swal from 'sweetalert2';
import Scrollbars from './ImprovedScrollBar';
import { select_employee, team_dangerous_data, team_folders_info } from '../screens/team/action';

function Sidebar({ user, permissions, update_profile, select_employee_data, team_folders, team_dangerous_data }) {
    const { path } = useRouteMatch()
    let split_data = path.split('/')
    let path_name = '/' +  split_data[1]
    let profile_image;
    if(user) {
        profile_image = HOST_WITHOUT_SLASH + user.dp        
    }

    const [ProfileModalStatus, setProfileModal] = useState(false)

    let name = ''
    let username = ''
    let email = ''
    let password = ''
    
    let user_type = ''


    if(user) {
      name = user.name || ''
      username = user.username || ''
      email = user.email || ''
    //   dp = HOST_WITHOUT_SLASH + user.dp || ''
      user_type = user.user_type || ''
    }

    // console.log('PERM',permissions, user)


    const [Name, setName] = useState(name)
    const [Username, setUsername] = useState(username)
    const [Email, setEmail] = useState(email)
    const [Password, setPassword] = useState(password)
    const [DpImage, setDpImage] = useState(user ? HOST_WITHOUT_SLASH + user.dp : '')
    const [TempDp, setTempDp] = useState('https://enviro-drive.s3.amazonaws.com/media/default/user/dp.jpg')


    const profile_update_api = () => {
        const success = () => {
            setProfileModal(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your profile has been updated!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () => {
            setProfileModal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        let data = new FormData()
        if(Name !== name && Name!=='' && Name) {
            data.append('name', Name)
        }
        if(Username !== username && Username!=='' && Username) {
            data.append('username', Username)
        }
        if(Password!=='' && Password) {
            data.append('password', Password)
        }
        if(Email !== email && Email) {
            data.append('email', Email)
        }
        if(DpImage !== '' && (user && DpImage !== user.dp)) {
            let dp_file_name = DpImage.name
            data.append('dp', DpImage, dp_file_name)
        }

        update_profile(data, success, failed)
    }

    const cropUsrName = (txt) =>{
        return txt && txt.length > 30 ? `${txt.substring(0,10)}...${txt.substring(txt.length-5, txt.length)}` : txt;
    }

    return (
        <div className={permissions && permissions.web_app_login_access ? "sidebar-layout" : "sidebar-layout blurEffct"}>
            <div 
            data-testid="profileDp"
            // to={split_data[0]+'/team/detail'}
            // onClick={() => {
            //         team_dangerous_data(user.id); team_folders(user.id); select_employee_data(user.id);
            // }}
            onClick={() => setProfileModal(true)} 
            className="sidebar-profile-layout">
                <img src={profile_image} alt="" className="sidebar-profile-img" />
                <p className="sidebar-profile-layoutName">
                    { user && user.name ? cropUsrName(user.name) : ''}
                    </p> 

            </div>
            <Scrollbars style={{ height:'85vh' }} autoHide id="sideBar">
            {
                routes.map((item, key) => {
                    const { path, name, status } = item
                    const IconRender = () => {
                        switch(name){
                            case 'Home'         : return <Home style={{ fontSize: 21 }}/>;
                            case 'Scheduling'   : return <Today style={{ fontSize: 21 }} />;
                            case 'Notification' : return <NotificationsActive style={{ fontSize: 21 }}/>;
                            case 'Team'         : return <Group style={{ fontSize: 21 }}/>;
                            case 'Vehicle'      : return <LocalShipping style={{ fontSize: 21 }}/>;
                            case 'Site'         : return <Person style={{ fontSize: 21 }}/>;
                            case 'Report'       : return <Timeline style={{ fontSize: 21 }}/>;
                            case 'Factory'      : return <Business style={{ fontSize: 21 }}/>;
                            case 'Sales'        : return <Stars style={{ fontSize: 21 }}/>;
                            case 'OH & S'       : return <ControlCamera style={{ fontSize: 21 }}/>;
                            case 'Intranet'     : return <Language style={{ fontSize: 21 }}/>;
                            case 'Accounts'     : return <AccountBalance  style={{ fontSize: 21 }}/>;
                            case 'Invoice'      : return <MonetizationOn  style={{ fontSize: 21 }}/>;
                            default             : return null
                        }
                    }

                    const MainComponent = ({viewStat=true, detailPage=null}) => {
                        let class_name = path_name === path ? 'sidebar-menu-active' : 'sidebar-menu'
                        return (
                                <div className="sidebar-menu-layout">
                                {
                                    status ? (
                                        <Link to={ detailPage || !viewStat  ? path+'/detail' : path} className={class_name}
                                            id={key}
                                            data-testid={name}
                                            onClick={() => {
                                                if(detailPage || !viewStat){
                                                    team_dangerous_data(user.id); team_folders(user.id); select_employee_data(user.id);
                                                }
                                            }}
                                        >
                                            <IconRender />
                                            <p className="sidebar-text">{name}</p>
                                        </Link>
                                    ) : (
                                        <div className="sidebar-menu-inactive" data-testid={name}>
                                            <IconRender />
                                            <p className="sidebar-text">{name}</p>
                                        </div>
                                    )
                                }
                                </div>                            
                        )
                    }
                   
                
                    if(permissions) {
                        const { home, ohs, sales, intranet, scheduling, site, team, vehicle, accounts } = permissions
                        switch(name){
                            case 'Home'       : return home.view         ? <MainComponent key={key}/> : null;
                            case 'Scheduling' : return scheduling.view   ? <MainComponent key={key}/> : null;
                            case 'Team'       : return                     <MainComponent key={key} viewStat={team.view} detailPage={team.personal_profile_only} />;
                            case 'Vehicle'    : return vehicle.view      ? <MainComponent key={key}/> : null;
                            case 'Site'       : return site.view         ? <MainComponent key={key}/> : null;
                            case 'Sales'      : return sales.view        ? <MainComponent key={key}/> : null;
                            case 'OH & S'     : return ohs.view          ? <MainComponent key={key}/> : null;
                            case 'Intranet'   : return intranet.view     ? <MainComponent key={key}/> : null;
                            case 'Accounts'   : return accounts.view     ? <MainComponent key={key}/> : null;
                            case 'Invoice'    : return accounts.view     ? <MainComponent key={key}/> : null;
                            default           : return null
                        }
                    } else return <MainComponent key={key}/>

                })
            }
            </Scrollbars>
            <ProfileModal 
                modalStatus={ProfileModalStatus}
                modalAction={setProfileModal}
                Name={user ? user.name : Name}
                setName={setName}
                Username={user ? user.username : Username}
                setUsername={setUsername}
                Email={user ? user.email : Email}
                setEmail={setEmail}
                Password={Password}
                setPassword={setPassword}
                DpImage={user ? user.dp : DpImage}
                setDpImage={setDpImage}
                TempDp={user ? user.dp : TempDp}
                setTempDp={setTempDp}
                user_type={user_type}
                user={user}
                profile_update_api={profile_update_api}
            />

        </div>
    )
}


const mapStateToProps = (state) => {
    const { user, permissions } = state.userLogin
    return { user, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update_profile: (data, success, failed) => dispatch(update_profile(data, success, failed)),
        select_employee_data: (employee) => dispatch(select_employee(employee)),
        team_folders: (id) => dispatch(team_folders_info(id)),
        team_dangerous_data: (id) => dispatch(team_dangerous_data(id, null, null, null, null))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (Sidebar)
