import { OHS_NEWS_LIST, OHS_NOTIFY_LIST, OHS_NEWS_CREATE, OHS_NEWS_EDIT, OHS_NEWS_DELETE, 
    OHS_NOTIFY_CREATE, OHS_NOTIFY_DELETE, OHS_NEWS_READ, OHS_NOTIFICATION_READ, OHS_AND_S_SAFETYDATA, OHS_NOTIFICATION_REPLY } from '../../../store/endpoint'
import Api from '../../../store/api'



// *****************************************************
//                         N E W S
// *****************************************************

export const fetch_news_list = (page_no) => {
    return function (dispatch) {
        let get_url = `${OHS_NEWS_LIST}${page_no}/?limit=8`
        Api('get', get_url).then(response => {
            if(response) {
                dispatch({ type: 'OHS_NEWS_LIST', news: response, page: page_no })
            }
        })
    }
}

export const fetch_notify_list = (page_no) => {
    return function (dispatch) {
        let get_url = `${OHS_NOTIFY_LIST}${page_no}/?limit=8`
        Api('get', get_url).then(response => {
            console.log(response)
            if(response) {
                dispatch({ type: 'OHS_NOTIFICATION_LIST', notifications: response, page: page_no })
            }
        })
    }
}

export const fetch_archive_list = (page_no) => {
    return function (dispatch) {
        let get_url = `${OHS_NOTIFY_LIST}${page_no}/?limit=8&archive=read`
        Api('get', get_url).then(response => {
            console.log(response)
            if(response) {
                dispatch({ type: 'OHS_NOTIFICATION_ARCHIVE_LIST', notifications_arch_list: response, page: page_no })
            }
        })
    }
}


export const create_news = (content, success, failed) =>{
    return function(dispatch){
        let get_url = `${OHS_NEWS_CREATE}`
        Api('post', get_url, content, success, failed ).then(response => {
            
            success(response)
            console.log("news created")
            dispatch(fetch_news_list(1))
        })
        .catch(err => {
            failed(err)
        })
    }
}

export const create_notify = (content, success, failed) =>{
    return function(dispatch){ 
        let get_url = `${OHS_NOTIFY_CREATE}`
        Api('post', get_url, content, success, failed).then(response => {
            
            success(response)
            console.log("notification created")
            dispatch(fetch_notify_list(1))
        })
        .catch(err => {
            failed(err)
        })
    }
}

export const edit_news = (pageNo, id, content, success, failed) =>{
    return function(dispatch){
        //let get_url = `https://deep.envirowasteadmin.com.au/api/v1/oh_and_s/news/edit/${id}/`
        let get_url = `${OHS_NEWS_EDIT}${id}/`
        Api('patch-form', get_url, content, success, failed ).then(response => {
            success(response)
            dispatch({
                type:'UPDATE_NEWS',
                id: id,
                content: response
            })
        })
        .catch(err => {
            failed(err)
        })
    }
}

export const delete_news = (pageNo, id, success, failed) =>{
    return function(dispatch){
        let get_url = `${OHS_NEWS_DELETE}${id}/`
        Api('delete', get_url,'', success, failed).then(response => {
            
            if(response){
                console.log("News Deleted")
                dispatch({
                    type : 'DELETE_NEWS',
                    id   : id
                })
            }
        })
    }
}

export const delete_notify = (pageNo, id, success, failed) =>{
    return function(dispatch){
        let get_url = `${OHS_NOTIFY_DELETE}${id}/`
        Api('delete', get_url,'', success, failed).then(response => {
            
            if(response){
                console.log("Notify Deleted")
                dispatch({
                    type : 'DELETE_NOTIFY',
                    id   : id
                })
            }
        })
    }
}

export const read_news = (pageNo, id, val) =>{
    return function(dispatch){
    let get_url = `${OHS_NEWS_READ}${id}/`
    Api('get', get_url).then(response => {
        
        if(response){
            console.log("News Updated",response)
            dispatch({
                type : 'READ_NEWS',
                id   : id,
                content : val
            })
        }
    })
    }
}

export const read_notify = (pageNo, id, val) =>{
    return function(dispatch){
    let get_url = `${OHS_NOTIFICATION_READ}${id}/`
    Api('get', get_url).then(response => {
        
        if(response){
            console.log("Notification Updated",response)
            dispatch({
                type : 'READ_NOTIFY',
                id   : id,
                content : val
            })
        }
    })
    }
}

export const get_safety = () =>{
    return function(dispatch){
        let get_url = `${OHS_AND_S_SAFETYDATA}`
        Api('get', get_url).then(response => {
            if(response){
                console.log("DATA", response)
                dispatch({
                    type:'OHS_SAFETY',
                    safetyData: response
                })
            }
        })
    }
}

export const update_safety = (data, success, failed) =>{
    return function(dispatch){
        let get_url = `${OHS_AND_S_SAFETYDATA}1/`
        Api('patch-form', get_url, data, success, failed).then(response => {
            if(response){
                success(response)
                dispatch({
                    type:'OHS_SAFETY',
                    safetyData: response
                })
            }
        })
        .catch(err=>
            failed(err)
        )
    }
}

export const reply_notify = (data, id, success, failed) => {
    return function(dispatch){
        let get_url = `${OHS_NOTIFICATION_REPLY}${id}/`
        Api('post-form', get_url, data, success, failed)
            .then(response =>{ console.log(response); success(); })
            .catch(err => failed())
    }
}