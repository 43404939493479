import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal, Tooltip } from '@material-ui/core'
import './style.css'
import { connect } from 'react-redux';
import { cl_green, cl_blue } from '../../../common/Colors';
import { Cancel, CheckCircle } from '@material-ui/icons';
import Swal from 'sweetalert2';
import BufferModal from './BufferModal';
import { TextAreaLimiter } from '../../../common/TextAreaLimiter';
import Scrollview from '../../../common/ImprovedScrollBar';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    MailBCC,
    setMailBCC,
    MailCC,
    setMailCC,
    MailSubject,
    setMailSubject,
    MailBody,
    setMailBody,
    MainCCText,
    setMainCCText,
    MailBCCText,
    setMailBCCText,
    quoteRegisterationApi,
    buttonLoaderA,
    buttonLoaderB,
    buttonLoaderC,
    siteMailId,
    sendMailToClient,
    setSendMailToClient,
    sale_draft_template_list,
    clientId,
    setSelectedDraft,
    selectedDraft
}) {

    const classes = useStyles();
    const [loadQuote, setLoadQuote] = useState(false)
    const [textAreaValid, setTextAreaValid] = useState(true)
    const handleClose = () => {
        modalAction(false)
    };

    const SingleFolder = ({item, title, index, type, editable=true}) => {
        const cropFileName = (str, len, frnt, lst) => {
            let lastIndx = str ? str.length : 0
        return lastIndx >len ? str.substring(0,frnt)+ '...' + str.substring(lastIndx-lst,lastIndx): str;
        }
    
        
        return (
            <Tooltip
            title={<p>
                Created: {moment(item.created_date_time).format('DD-MM-YYYY HH:mm:ss')},<br/> 
                Modified: {moment(item.edited_date_time).format('DD-MM-YYYY HH:mm:ss')}<br />
                {title && title.length>25 ? title : null}</p>} 
            arrow={true} placement={'top'}
            >
            <div 
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setSelectedDraft(item.id)
                }} 
                style={{ marginLeft: 0, height: 35 }} 
                className="intranet-single-file-layout">
                <div className="intranet-single-index">
                    <p>{index + 1}</p>
                </div>
                <div className="intraner-single-label" style={{ whiteSpace:'nowrap', width: type==='attachment' ? '100%' : '8vw' }}>
                    <p>{cropFileName(title, 25, 5, 5)}</p>
                </div>    
                {   selectedDraft===item.id ?
                    <CheckCircle style={{ color:'var(--cl_blue)' }} />  : null
                }
            </div>
            </Tooltip>
        )
    }

    const add_item_to_cc = () => {
        if(MainCCText) {
            let chek_already_added = MailCC.find(item => item === MainCCText)
            console.log('added check', chek_already_added)
            if(chek_already_added) {

            } else {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (pattern.test(MainCCText)) {
                    setMailCC(item => [...item, MainCCText])
                    setMainCCText('')
                } else {
                    setMainCCText('')
                }
            }
        } 
    }

    const delete_item_to_cc = (txt) => {
            let updatedArr = MailCC.filter(item => item !== txt)
            setMailCC(updatedArr)
    }

    const add_item_to_bcc = () => {
        if(MailBCCText) {
            let chek_already_added = MailBCC.find(item => item === MailBCCText)
            console.log('added check', chek_already_added)
            if(chek_already_added) {

            } else {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (pattern.test(MailBCCText)) {
                    setMailBCC(item => [...item, MailBCCText])
                    setMailBCCText('')
                } else {
                    setMailBCCText('')
                }
            }
        } 
    }

    const delete_item_to_bcc = (txt) => {
            let updatedArr = MailBCC.filter(item => item !== txt)
            setMailBCC(updatedArr)
    }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        {   !loadQuote ?
          <div className="quote-confirm-modal-layout" style={{ overflowY:'auto' }}>
            <h5>Share Quotation</h5>
            <div className="quote-cnrf-to-layout">
                <p className="quote-cnrf-field-title">To (CC)</p>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <input 
                        className="quote-cnrf-emails-input" 
                        type="text" 
                        placeholder="Email"
                        value={MainCCText}
                        onChange={txt => setMainCCText(txt.target.value)}
                        onKeyUp={(e)=>{ if(e.key==="Enter" && MainCCText) { add_item_to_cc() } }}
                    />
                    {
                        MainCCText ? (
                            <button 
                                onClick={() => add_item_to_cc()}
                                style={{ width: 100, backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} >Add</button>
                        ) : (
                            <button 
                                style={{ width: 100, backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} className="disabled-btn" >Add</button>
                        )
                    }
                    
                </div>
                
                <div className="quote-cnrf-emails-layout">
                    {
                        MailCC.map((item, key) => {
                            return (
                                <div key={key}>
                                    <Cancel onClick={() => delete_item_to_cc(item)} style={{ position: 'absolute', marginLeft: 110, fontSize: 18, cursor: 'pointer' }}/>
                                    <p className="quote-cnrf-emails" key={key}>{item}</p>
                                </div>
                                
                            )
                        })
                    }
                </div>
            </div>


            <div className="quote-cnrf-to-layout">
                <p className="quote-cnrf-field-title">To (BCC)</p>
                <div style={{ display: 'flex', justifyContent: 'space-between ' }} >
                    <input 
                        className="quote-cnrf-emails-input" 
                        type="text" 
                        placeholder="Email" 
                        value={MailBCCText}
                        onChange={txt => setMailBCCText(txt.target.value)} 
                        onKeyUp={(e)=>{ if(e.key==="Enter" && MailBCCText) { add_item_to_bcc() } }}
                    />

                    {
                        MailBCCText ? (
                            <button 
                                onClick={() => add_item_to_bcc()}
                                style={{ width: 100, backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} >Add</button>
                        ) : (
                            <button 
                                style={{ width: 100, backgroundColor: cl_blue, borderRadius: 20, border: 0, color: 'white' }} className="disabled-btn" >Add</button>
                        )
                    }

                </div>
                
                <div className="quote-cnrf-emails-layout">
                    {
                        MailBCC.map((item, key) => {
                            return (
                                <div key={key}>
                                    <Cancel onClick={() => delete_item_to_bcc(item)} style={{ position: 'absolute', marginLeft: 110, fontSize: 18, cursor: 'pointer' }}/>
                                    <p className="quote-cnrf-emails" key={key}>{item}</p>
                                </div>
                                
                            )
                        })
                    }
                </div>
            </div>


            <div className="quote-cnrf-to-layout">
                <p className="quote-cnrf-field-title">Subject</p>
                <input 
                    className="quote-cnrf-emails-input" 
                    type="text" 
                    placeholder="Subject" 
                    value={MailSubject}
                    onChange={txt => setMailSubject(txt.target.value)} 
                />
            </div>
            
            <div className="quote-cnrf-to-layout">
                <p className="quote-cnrf-field-title">Body</p>
                
                <TextAreaLimiter isValid={setTextAreaValid}
                configs={{
                    className : "quote-cnrf-emails-textarea" ,
                    type : "text" ,
                    placeholder : "Body", 
                    value : MailBody,
                    onChange : (txt) => setMailBody(txt.target.value) 
                }}
                max={1000}
                v={MailBody} />
            </div>


            <div className="sales-horizScroll-ListSpl" >
                    <p className="quote-cnrf-field-title">Client Drafts</p>
                    {console.log('DRSFTS',sale_draft_template_list)}
                {sale_draft_template_list && sale_draft_template_list.length>0?
                <Scrollview style={{ height:'8vh',width:"auto", border:'1px solid lightgrey', borderRadius:'10px'}}>
                    <div className="sales-client-drafts-list" id='client-drafts-list'>
                    {
                        sale_draft_template_list && 
                        sale_draft_template_list.filter(itemJB => parseInt(itemJB.client)===clientId).map((item, key) => {
                            return(
                                <SingleFolder item={item} title={item.sales_person?item.sales_person:item.employee_name} key={key} index={key} type={'drafts'}/>
                            )
                        })
                    }
                    </div>
                </Scrollview> : <p style={{ fontSize:'.7rem' }}>No drafts</p>
                }</div>


            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 20px' }}>
                <input
                    checked={sendMailToClient}
                    style={{ marginLeft: 10, transform: 'scale(1.5)' }}
                    type="checkbox"
                    onChange={val => {
                        setSendMailToClient(!sendMailToClient)
                    }}
                />
                <p  className="quote-cnrf-field-title" style={{ paddingLeft:'10px' }}>
                    Send copy of mail to Site Email ({siteMailId}).
                </p>
                
            </div>
                    

            <div className="sales-qt-rgstr-btn-layout"
                style={{
                    justifyContent : ((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) || sendMailToClient ? 'space-between' : 'center'
                }}
            >
                {   ((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) || sendMailToClient ?
                <button
                    id="generateandsend"
                style={{ width: '45%', 
                    pointerEvents: sendMailToClient || (((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) && textAreaValid) ? 'auto' : 'none',
                    opacity : textAreaValid ? 1 : 0.4,
                    cursor: sendMailToClient || (((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) && textAreaValid) ?'pointer' : 'default' }} onClick={() =>{ 
                        modalAction(false)
                        Swal.fire({
                            title: 'Are you sure?',
                            text: `A mail copy would ${sendMailToClient ? '' : 'NOT' } be sent to Site Mail.`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: cl_blue,
                            cancelButtonColor: cl_green,
                            confirmButtonText: 'Generate and Send Email'
                        }).then((result) => {
                            if (result.value) {
                                quoteRegisterationApi('pending')
                                modalAction(true)
                                setLoadQuote(true)
                            }
                            else{
                                modalAction(true)
                            }
                    });
                }}>
                    {
                        buttonLoaderA ? (
                            <CircularProgress size={14} color={'#fff'} />
                        ) : ('Generate Quote & Send E-Mail')
                    }
                </button>   :   null
                }
                {   ((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) || sendMailToClient ?
                <button 
                id="approvedQuote"
                style={{ width: '45%', 
                    pointerEvents: sendMailToClient || (((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) && textAreaValid) ? 'auto' : 'none',
                    opacity : textAreaValid ? 1 : 0.4,
                    cursor: sendMailToClient || (((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) && textAreaValid) ?'pointer' : 'default'  }} onClick={() =>{ 
                        modalAction(false)
                        Swal.fire({
                            title: 'Are you sure?',
                            text: `A mail copy would ${sendMailToClient ? '' : 'NOT' } be sent to Site Mail.`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: cl_blue,
                            cancelButtonColor: cl_green,
                            confirmButtonText: 'Approve and Send Email'
                        }).then((result) => {
                            
                            if (result.value) {
                                quoteRegisterationApi('accepted')

                                modalAction(true)
                                setLoadQuote(true)
                            }
                            else{
                                modalAction(true)
                            }
                    });
                    }}>
                    {
                        buttonLoaderB ? (
                            <CircularProgress size={14} color={'#fff'} />
                        ) : ('Approve Quote & Send E-Mail')
                    }
                </button>   :   null
                }
                {   ((MailCC && MailCC.length!==0) || (MailBCC && MailBCC.length!==0)) || sendMailToClient ? null :
                <button
                    id="generateOnly"
                style={{ width: '60%', 
                    pointerEvents: textAreaValid ? 'auto' : 'none',
                    opacity : textAreaValid ? 1 : 0.4,
                    cursor: textAreaValid ?'pointer' : 'default' }} onClick={() =>{ 
                        quoteRegisterationApi(null)
                        modalAction(true)
                        setLoadQuote(true)
                        
                }}>
                    {
                        buttonLoaderC ? (
                            <CircularProgress size={14} color={'#fff'} />
                        ) : ('Generate an Approved Quote')
                    }
                </button>
                }
            </div>
            
          </div>
          :
          <BufferModal />
        }
        
    </Modal>
  );
}

const mapStateToProps = (state) => {
    const { sale_client_drafts_list } = state.sales
  const { user } = state.userLogin
  return { user, sale_client_drafts_list }
}

 export default connect(mapStateToProps) (TransitionsModal)
 
