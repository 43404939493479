import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { add_team_to_job, add_vehicle_to_job, fetch_Employee_avilabilities, fetch_job_list_readyForSchd, fetch_Vehicle_avilabilities, schedule_job, schedule_job_edit } from '../common/action'
import TeamLayout from './team_layout_available_only'
import DateTimeInput from '../../../common/DateTimeInput'
import moment from 'moment'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';

import VehicleLayout from './VehicleLayout';
import Draggable from 'react-draggable';
import { CancelRounded, DragIndicatorRounded } from '@material-ui/icons'
import CompletedJobModal from './completedJobModal'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    screen_type,
    modalStatus, 
    modalAction,
    selectedJob,
    schedule_a_job,
    // schedule_edit_job,
    vehicle_listDateTime,
    team_setting,
    vehicle_setting,
    fetch_availabilityVeh,
    fetch_availabilityEmp,
    fetch_jobs,
    job_list_weekFilter,
    scheduled,
    isMock=false,
    schdlListType,
    reSchedule=false,
    jobTab,
    vehicleAvailLoader
}) {
    const classes = useStyles();

    const handleClose = () => {
        setSelectedArr([])
        fetch_availabilityVeh('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'), screen_type)
        fetch_availabilityEmp('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'))
        setSelectedVehclArr([])
        setApplyButton(false)
        modalAction(false)
        setPrimeVehicle(true)
        setAdditionalVehTeamArr([])
        setPrimeVehicleDriver(null)
        setStartDate(moment().format('YYYY-MM-DD'))
        setStartTime(moment({hours:0, minutes:0}).format('HH:mm'))
        setEndDate(moment().format('DD-MM-YYYY'))
        setEndTime(moment({hours:0, minutes:0}).format('HH:mm'))
    };

    const { code, client_name, amount, id, 
        //schedule 
    } = selectedJob

    let prevStartDate = moment().format('YYYY-MM-DD')
    let prevStartTime = moment({hours:0, minutes:0}).format('HH:mm')
    let prevEndTime = moment({hours:0, minutes:0}).format('HH:mm')
    let prevEndDate = moment().format('YYYY-MM-DD')
    // let vehicle_default = ''

    // if(vehicle_listDateTime && vehicle_listDateTime.length > 0) {
    //     vehicle_default = vehicle_listDateTime[0].id
    // }

    // console.log('vehicle_default', vehicle_default)

    // if(schedule) {
    //     prevStartDate = schedule.start_date
    //     prevStartTime = schedule.start_time
    //     prevEndTime = schedule.end_time
    //     prevEndDate = schedule.end_date
    // }

    const [StartDate, setStartDate]                      = useState(prevStartDate)
    const [StartTime, setStartTime]                      = useState(prevStartTime)
    const [EndTime, setEndTime]                          = useState(prevEndTime)
    const [EndDate, setEndDate]                          = useState(prevEndDate)
    const [vehicle, setVehicle]                          = useState(null)
    const [primeVehicleDriver, setPrimeVehicleDriver]    = useState(null)
    const [selectedArr, setSelectedArr]                  = useState([]) //Team Selection
    const [selectedVehclArr, setSelectedVehclArr]        = useState([])
    const [additionalVehTeamArr, setAdditionalVehTeamArr] = useState([])
    const [applyButton, setApplyButton]                  = useState(false)
    const [startHours, setStartHours]                    = useState(moment(prevStartTime,'HH:mm').hours())
    const [startMinutes, setStartMinutes]                = useState(moment(prevStartTime,'HH:mm').minutes())
    const [endHours, setEndHours]                        = useState(moment(prevEndTime,'HH:mm').hours())
    const [endMinutes, setEndMinutes]                    = useState(moment(prevEndTime,'HH:mm').minutes())

    const [openDTEditor,setOpenDTEditor]                 = useState(false)
    const [EndDateChng, setEndDateChng]                  = useState(false)
    const [StartTimeChng, setStartTimeChng]              = useState(false)
    const [EndTimeChng, setEndTimeChng]                  = useState(false)
    const [startMeridian, setStartMeridian]              = useState('am')
    const [endMeridian, setEndMeridian]                  = useState('am')
    const [primeVehicle, setPrimeVehicle]                = useState(true)

    const [signImage, setSignImage]                      = useState(null)
    const [after_pics, setAfterPics]                     = useState([])
    const [before_pics, setBeforePics]                   = useState([])
    const [videoGallery, setvideoGallery]                = useState([])
    const [gallery_pics, setGalleryPics]                 = useState([])
    const [schduleStatus, setScheduleStatus]             = useState('pending')
    const [jobStatusTime, setJobStatusTime]              = useState({
        depart_enviro_facility : '00:00', start_job : '00:00', finish_job : '00:00',
        completed : '00:00', arrive_at_waste_depot : '00:00', depart_waste_depot : '00:00', arrive_enviro_facility : '00:00'
    })
    const [chngWasteType, setChngWasteType]              = useState('')
    const [chngWasteLtr, setChngWasteLtr]                = useState('')
    const [ponumber, setPonumber]                        = useState('')
    const [signName, setSignName]                        = useState('')

    const [isSchedulerBtnLoader, setIsSchedulerBtnLoader] = useState(false)


    // console.log("PARSETIME ",parseInt(StartTime.substring(0,2))===0+1 || (parseInt(StartTime.substring(0,2))-12)===0+1)
    const schedule_btn_fx = (jobType) => {
        setIsSchedulerBtnLoader(true)
       const success = (res) => {
           console.log('JOB-SCHEDULED',res)
        fetch_availabilityVeh('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'), screen_type)
        fetch_availabilityEmp('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'))

        let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(job_list_weekFilter,'w').format('YYYY-MM-DD')}`;
        fetch_jobs(screen_type, 1, fromD, jobTab);
        setVehicleApi(res.id)
        
        if(selectedArr.length > 0) {
            setTeamApi(res.id)
        }

        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Job Scheduled!',
            showConfirmButton: false,
            timer: 1500
        })
        setIsSchedulerBtnLoader(false)
        handleClose()
       }

       const failed = () => {
        setIsSchedulerBtnLoader(false)
        handleClose()
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
        })
       }
        let data = {
            'id' : id,
            'start_date' : moment(StartDate).format('YYYY-MM-DD'),
            'start_time' : StartTime + ':00',
            'end_time'   : EndTime + ':00',
            'end_date'   : EndDate ? moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD'),
            'additional_vehicle_drivers' : additionalVehTeamArr,
            'primary_vehicle_driver' : primeVehicleDriver ? primeVehicleDriver : '',
            'vehicle'    : vehicle,
        }
        
        if(isDateTimeInPast()){
            let data2 = new FormData()
            data2.append('id',id)
            data2.append('start_date',moment(StartDate).format('YYYY-MM-DD'))
            data2.append('start_time',StartTime + ':00')
            data2.append('end_time'  ,EndTime + ':00')
            data2.append('end_date'  ,EndDate ? moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD'))
            data2.append('additional_vehicle_drivers',JSON.stringify(additionalVehTeamArr))
            data2.append('primary_vehicle_driver',primeVehicleDriver ? primeVehicleDriver : '')
            data2.append('vehicle'   ,vehicle)
            data2.append('status', schduleStatus)
            data2.append('image', signImage)
            data2.append('purchase_order_number', ponumber)
            data2.append('signature_name', signName)
            data2.append('extracted_waste_type', chngWasteType)
            data2.append('extracted_litres_of_waste',chngWasteLtr)
            if(before_pics && before_pics?.length>0){
                for(let i=0; i<before_pics?.length; ++i)
                    data2.append('before_pic', before_pics[i], before_pics[i].name)
            }
            if(after_pics && after_pics?.length>0){
                for(let i=0; i<after_pics?.length; ++i)
                    data2.append('after_pic', after_pics[i], after_pics[i].name)
            }
            if(gallery_pics && gallery_pics?.length>0){
                for(let i=0; i<gallery_pics?.length; ++i)
                    data2.append('images', gallery_pics[i], gallery_pics[i].name)
            }
            if(videoGallery && videoGallery?.length>0){
                for(let i=0; i<videoGallery?.length; ++i)
                    data2.append('videos', videoGallery[i], videoGallery[i].name)
            }
            if(jobStatusTime){
                let currDate = moment(StartDate)
                let i = 0
                for(const vals in jobStatusTime){
                    
                    if(moment(`${jobStatusTime[vals]}`).isValid()){
                        let currStatusDate = moment(`${jobStatusTime[vals]}`)
                        let setupDate = null
                        if(i===0){
                            currDate.set({ 'hour' : currStatusDate.get('hour'), 'minute' : currStatusDate.get('minute'), 'second' : currStatusDate.get('second') })
                            setupDate = currDate
                        }else{
                            currStatusDate.set({
                                'year' : currDate.get('year'), 'month' : currDate.get('month'), 'date' : currDate.get('date')
                            })
                            if(currStatusDate.diff(currDate) >= 0){
                                setupDate = currStatusDate
                            }else{
                                setupDate = currStatusDate.add(1,'day')
                            }

                        }
                        data2.append(`${vals}`,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'))
                        i++
                    }
                }
            }
            for (var p of data2) {
                let name = p[0];
                let value = p[1];
            
                console.log('SCHEDULECOMPLETEDJOBFORM=>',name, value)
            }
            console.log('SCHADDTMVEH',additionalVehTeamArr,'STRINGIFY=>',JSON.stringify(additionalVehTeamArr))
            schedule_a_job(screen_type, data2,         
                reSchedule ? 
                    { id : jobType } : 
                    jobType, 
                    schdlListType==='v' ? null : 'm', 
                    isDateTimeInPast(),
                    success, failed)          
        }else{
            schedule_a_job(screen_type, data,         
                reSchedule ? 
                    { id : jobType } : 
                    jobType, 
                    schdlListType==='v' ? null : 'm', 
                    isDateTimeInPast(),
                    success, failed)
        }
    //    let data = new FormData()
    //    data.append('id', id)
    //    data.append('start_date', moment(StartDate).format('YYYY-MM-DD'))
    //    data.append('start_time', StartTime + ':00')
    //    data.append('end_time', EndTime + ':00')
    //    data.append('end_date', EndDate ? moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD') : moment(StartDate).format('YYYY-MM-DD'))

       
    }

    const isTimeInCorrect = () =>{
        console.log( moment(StartTime,'HH:mm').isValid(),moment(EndTime,'HH:mm').isValid(),StartTime,EndTime)
        if(StartTime && EndTime && moment(StartTime,'HH:mm').isValid() && moment(EndTime,'HH:mm').isValid()
            && (moment(StartDate).format('YYYY-MM-DD')===moment(EndDate).format('YYYY-MM-DD'))
        ){
            var strTm = moment(StartTime, 'HH:mm');
            var endTm = moment(EndTime, 'HH:mm');
            var hr = moment.duration(endTm.diff(strTm),'hours')
            var min = parseInt(moment.duration(endTm.diff(strTm),'minutes'))-(hr*60);
            console.log(strTm,endTm,hr,min)
            return (hr<0 || min<=0);
        }
        else
            return false
        
    }

    const isDateTimeInPast = () =>{
        // let startTm = StartTime && moment(StartTime,'HH:mm').isValid() ? moment(StartTime,'HH:mm').format('HH:mm:ss') : '' 
        if(StartDate && EndDate && moment(StartDate,['YYYY-MM-DD']).isValid() && moment(EndDate,['YYYY-MM-DD']).isValid()
            // && StartTime && moment(StartTime,'HH:mm').isValid()
            // && (moment(`${StartDate} ${startTm}`,'YYYY-MM-DD HH:mm:ss').diff(moment().format('YYYY-MM-DD HH:mm:ss'))<0)
            && (moment(StartDate,'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'))<0)
        ){
            // var strTm = moment(StartTime, 'HH:mm');
            // var endTm = moment(EndTime, 'HH:mm');
            // var hr = moment.duration(endTm.diff(strTm),'hours')
            // var min = parseInt(moment.duration(endTm.diff(strTm),'minutes'))-(hr*60);
            // console.log(startTm,moment(`${StartDate} ${startTm}`,'YYYY-MM-DD HH:mm:ss'),moment(`${StartDate} ${startTm}`,'YYYY-MM-DD HH:mm:ss').diff(moment().format('YYYY-MM-DD HH:mm:ss')))
            return true
        }
        else
            return false
        
    }

    //  let schedule_btn_statusz = false
    //  if(selectedArr.length > 0) {
    //     schedule_btn_statusz = true
    //  }
     

     const setTeamApi = (shdl_id) => {
         const success = (res) => {
             console.log('API FOR SETTING TEAM',res)
            let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(job_list_weekFilter,'w').format('YYYY-MM-DD')}`;
            fetch_jobs(screen_type, 1, fromD, jobTab);
             console.log(res)
         }
         const failed = (res) => {
            console.log(res)
        }
        let data = new FormData()
        data.append('id', shdl_id)
        selectedArr.map((item, key) => {
            data.append('members', item)
            return null
        })
        team_setting(screen_type, data,  schdlListType==='v' ? null : 'm', success, failed)
     }


     const setVehicleApi = (shdl_id) => {
        const success = (res) => {
            console.log('API FOR SETTING VEHICLE',res)
            let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(job_list_weekFilter,'w').format('YYYY-MM-DD')}`;
        fetch_jobs(screen_type, 1, fromD, jobTab);
            handleClose()
        }

        const failed = (res) => {
           console.log(res)
        }
        let primeData = new FormData()
        let data = new FormData()
        primeData.append('id', shdl_id)
        // primeData.append('vehicle', vehicle)
        for(let i=0; i<selectedVehclArr.length; i++)
            data.append('vehicles', selectedVehclArr[i])
        console.log("DATA-PREVDATA-JBM",selectedVehclArr,vehicle)
        vehicle_setting(screen_type, data, primeData, shdl_id,  schdlListType==='v' ? null : 'm', success, failed)
    }

    // const setStartDateNTime = (v) =>{
    //     setStartDate(moment(v).format('DD-MM-YYYY'))
    //     setStartTime(moment(v).format('hh:mm'))
    // }

    // const setEndDateNTime = (v) =>{
    //     setEndDate(moment(v).format('DD-MM-YYYY'))
    //     setEndTime(moment(v).format('hh:mm'))
    // }
    const setEndDefaultDates = (e) =>{
        console.log('setEndDefaultDatesFn',e,StartDate,EndDateChng,EndDate, StartTime, EndTime)
        setEndDateChng(true)
        setPrimeVehicle(true)
        // fetch_availabilityVeh(`start=${moment(StartDate,'YYYY-MM-DD',true).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(e).format('YYYY-MM-DD')} ${moment(e).format('HH:mm:ss')}`, screen_type)
        fetch_availabilityEmp(`start=${moment(StartDate,'YYYY-MM-DD',true).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(e).format('YYYY-MM-DD') } ${ EndTimeChng ? moment(EndTime,'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss') : EndTime+':00' : moment(e).format('HH:mm:ss')}`)
        clearRefresh()
        // fetch_availability('start='+StartDate+' '+StartTime+':00&&end='+EndDate+' '+EndTime+':00')
    }


    const setDefaultDates = (e) =>{
        console.log('setDefaultDatesFn',e,StartDate,EndDateChng,EndDate, StartTime, EndTime)
        setPrimeVehicle(true)
        if(!EndDateChng)
            setEndDate(moment(e).format('YYYY-MM-DD'))
        // fetch_availabilityVeh(`start=${moment(e).format('YYYY-MM-DD')} ${moment(e).format('HH:mm:ss')}&&end=${moment(!EndDateChng ? e : EndDate).format('YYYY-MM-DD')} ${!EndDateChng ? moment(e).format('HH:mm:ss') : moment(EndTime,'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss') : EndTime+':00'}`, screen_type)
        fetch_availabilityEmp(`start=${moment(e).format('YYYY-MM-DD')} ${ StartTimeChng ?   moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'   :   moment(e).format('HH:mm:ss')}&&end=${moment(!EndDateChng ? e : EndDate).format('YYYY-MM-DD') } ${EndTimeChng ? moment(EndTime,'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss') : EndTime+':00' : moment(e).format('HH:mm:ss')}`)
        clearRefresh()
        // fetch_availability('start='+StartDate+' '+StartTime+':00&&end='+EndDate+' '+EndTime+':00')
    }

    const clearRefresh = () =>{
        setVehicle(null)
        setPrimeVehicleDriver(null)
        setSelectedArr([])
        setSelectedVehclArr([])
        setAdditionalVehTeamArr([])

    }

  return (
      <Draggable handle="strong"  
      bounds={{top: -parseInt(window.innerHeight/12),
           left: -parseInt(window.innerWidth/2), 
           right: parseInt(window.innerWidth/2), 
           bottom: parseInt(window.innerHeight - (window.innerHeight/6))}} 
      //bounds="parent"
      >
      <Modal
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        hideBackdrop={true}
      >
        <div className="shdl-modal-layout-mainn">
            <strong className="draggerHead">
              <div style={{ width:'50%' }}>
                  <DragIndicatorRounded style={{ transform:'rotate(-90deg)', cursor:'all-scroll' }} />
              </div>
              <div>
                  <CancelRounded style={{ color:'red', cursor:'pointer' }} onClick={handleClose} />
              </div>
            </strong>
          
          <div className="shdl-modal-layout">
          <Scrollbars autoHide style={{ height:'65vh', width:'380px', marginRight:'10px' }}>
                <div className="shdl-modal-left-lay">
                <h3>Job {code}</h3>
                {   selectedJob && selectedJob.job_card_code &&
                <h6># {selectedJob.job_card_code}</h6>
                }
                <div className="shdl-modal-status">
                    <h3>Status</h3>
                    {
                        scheduled ? (
                            <div className="shdl-modal-status-box" />
                        ) : (
                            <div style={{ backgroundColor: 'red' }} className="shdl-modal-status-box" />
                        )
                    }
                    
                </div>

                <p className="shdl-modal-titles">{client_name}</p>
                <p className="shdl-modal-titles">{amount}$</p>

                <div className="shdl-modal-first-layout">
                    <p>Start Date</p>
                    <div style={{ padding: '10px 0px' }}>
                        {/* <DateTimeInput
                        dateValue={StartDate}
                        dateSetFn={setStartDate}
                        acceptFunc={ setStartDateNTime }
                        pickerType={"dateTime"}
                        clsName={'shdl-timeDate-field'}
                        /> */}
                        <DateTimeInput
                            dateValue={StartDate}
                            dateSetFn={setStartDate}
                            clsName={'client-input'}
                            // past={true}
                            acceptFunc={setDefaultDates}

                        />
                    </div>

                    <p>Start Time</p>
                    <div className="schdl-timing-box-main">
                        
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setStartTimeChng(true)
                                    setStartHours(parseInt(e.target.value)); setStartTime(moment({hours:parseInt(e.target.value), minutes:startMinutes}).format('HH:mm'));
                                console.log('StrHour',moment(EndDate).format('YYYY-MM-DD'),moment(EndTime).format('HH:mm:ss'),moment(EndTime, 'HH:mm:ss').isValid())
                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`, screen_type)
                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate, 'YYYY-MM-DD', true).isValid() ? EndDate : moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`)
                setPrimeVehicle(true)
                clearRefresh()
                                }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        
                                        return (<option  value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select
                            value={startMinutes}
                                className="schdl-timing-input"  
                                onChange={(e) => { 
                                    setStartTimeChng(true)
                                    setStartMinutes(parseInt(e.target.value)); setStartTime(moment({hours:startHours, minutes:parseInt(e.target.value)}).format('HH:mm')); 
                                console.log('StrMin',moment(EndDate).format('YYYY-MM-DD'),moment(EndTime).format('HH:mm:ss'),moment(EndTime, 'HH:mm:ss',true).isValid())

                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:startHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`, screen_type)
                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:startHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}&&end=${moment(EndDate, 'YYYY-MM-DD', true).isValid() ? EndDate : moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`)
                setPrimeVehicle(true)
                clearRefresh()
                                console.log(StartTime," STARTTIME ",EndDate," ",EndTime)
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                            className="schdl-timing-input"
                            id="startTimeMeridian"
                                onChange={(e)=>{  setStartTimeChng(true)
                                                setStartMeridian(e.target.value)  
                                                console.log('StrMerid',moment(EndDate).format('YYYY-MM-DD'),moment(EndTime).format('HH:mm:ss'))
                                                setPrimeVehicle(true)
                                                clearRefresh()
                                                if(e.target.value==='pm')
                                                    if(startHours!==12)
                                                    {
                                                        let temp = parseInt(startHours)+12
                                                        setStartTime(moment({hours:temp, minutes:startMinutes}).format('HH:mm'));
                                                        // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes:startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`, screen_type)
                                                        fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes:startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate, 'YYYY-MM-DD', true).isValid() ? EndDate : moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`)
                                                    }
                                                    else{
                                                        setStartTime(moment({hours:0, minutes:startMinutes}).format('HH:mm'))
                                                        // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes:startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`, screen_type)
                                                        fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes:startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate, 'YYYY-MM-DD', true).isValid() ? EndDate : moment(EndDate).format('YYYY-MM-DD') } ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`)
                                                    }
                                                else {
                                                    // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(startHours).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`, screen_type)
                                                    fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(startHours).format('HH:mm:ss')}&&end=${moment(EndDate, 'YYYY-MM-DD', true).isValid() ? EndDate : moment(EndDate).format('YYYY-MM-DD') } ${moment(EndTime, 'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss'):EndTime+':00'}`)
                                                    setStartTime(moment({hours: startHours, minutes:startMinutes}).format('HH:mm'))
                                                }
                                
                                                }}
                            >
                                <option value={'am'}>AM</option>
                                <option value={'pm'}>PM</option>
                            </select>
                        </div>
                    </div>

                    <p>End Date</p>
                    <div style={{ padding: '10px 0px' }}>
                    {   !openDTEditor   ?
                        <input
                        type="text"
                        value={moment(EndDate).isValid() ? moment(EndDate).format('DD-MM-YYYY') : EndDate}
                        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 5px', justifyContent:'center' }}
                        className='client-input'
                        onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setOpenDTEditor(!openDTEditor) }}
                      />
                    :
                        <DateTimeInput
                            dateValue={EndDate}
                            dateSetFn={setEndDate}
                            clsName={'client-input'}
                            rangeDate={StartDate}
                            // past={true}
                            acceptFunc={setEndDefaultDates}
                            openEdit={openDTEditor}
                        />
                    }
                    </div>

                    <p>End Time</p>
                    <div className="schdl-timing-box-main">
                        
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setEndTimeChng(true)
                                    setEndHours(parseInt(e.target.value)); setEndTime(moment({hours:parseInt(e.target.value), minutes:endMinutes}).format('HH:mm')); 
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:endMinutes}).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:endMinutes}).format('HH:mm:ss')}`)
                                setPrimeVehicle(true) 
                                clearRefresh()
                                }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        
                                        return (<option value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                                value={endMinutes}
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setEndTimeChng(true)
                                    setEndMinutes(parseInt(e.target.value)); setEndTime(moment({hours:endHours, minutes:parseInt(e.target.value)}).format('HH:mm')); 
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:endHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime,'HH:mm:ss',true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:endHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}`)
                                setPrimeVehicle(true)
                                clearRefresh()
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                            className="schdl-timing-input"
                            id="endTimeMeridian"
                                onChange={(e)=>{    
                                    console.log('StD',StartDate,'EdD',EndDate)
                                    setEndTimeChng(true)
                                                setEndMeridian(e.target.value)
                                                setPrimeVehicle(true)
                                                clearRefresh()
                                                if(e.target.value==='pm')
                                                    if(endHours!==12)
                                                    {
                                                        let temp = parseInt(endHours)+12
                                                        setEndTime(moment({hours:temp, minutes:endMinutes}).format('HH:mm'));

                                                        // fetch_availabilityVeh(`start=${moment(StartDate).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes:endMinutes}).format('HH:mm:ss')}`, screen_type)
                                                        fetch_availabilityEmp(`start=${moment(StartDate).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes:endMinutes}).format('HH:mm:ss')}`)
                                                    }
                                                    else{
                                                        
                                                        setEndTime(moment({hours:0, minutes:endMinutes}).format('HH:mm'))
                                                        // fetch_availabilityVeh(`start=${moment(StartDate).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes:endMinutes}).format('HH:mm:ss')}`, screen_type)
                                                        fetch_availabilityEmp(`start=${moment(StartDate).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes:endMinutes}).format('HH:mm:ss')}`)
                                                    }
                                                else{ 
                                                    setEndTime(moment({hours: endHours, minutes:endMinutes}).format('HH:mm'))
                                                    // fetch_availabilityVeh(`start=${moment(StartDate).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:endHours, minutes:endMinutes}).format('HH:mm:ss')}`, screen_type)
                                                    fetch_availabilityEmp(`start=${moment(StartDate).isValid() ? moment(StartDate).format('YYYY-MM-DD') : StartDate} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:endHours, minutes:endMinutes}).format('HH:mm:ss')}`)
                                                }    console.log(StartDate," ",StartTime," ",EndDate," ",EndTime)
                                                
                                                }}
                            >
                                <option value={'am'}>AM</option>
                                <option value={'pm'}>PM</option>
                            </select>
                        </div>
                    </div>
                     
                </div>
                        {        
                             primeVehicle ?
                             <div
                                className="vehicleFinderBtn"
                                style={{ marginTop:'1%' }}
                                onClick={()=>{
                                    setPrimeVehicle(false)
                                    console.log(StartDate, EndDate, StartTime, EndTime)
                                    fetch_availabilityVeh(`start=${moment(StartDate, 'YYYY-MM-DD', true).isValid() ? StartDate : moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00'}&&end=${moment(!EndDateChng ? StartDate : EndDate).format('YYYY-MM-DD') } ${!EndDateChng ? moment(StartTime, 'HH:mm:ss', true).isValid() ? moment(StartTime).format('HH:mm:ss') : StartTime+':00' : moment(EndTime,'HH:mm:ss',true).isValid() ? moment(EndTime).format('HH:mm:ss') : EndTime+':00'}`, screen_type)
                                }}
                             >
                                Find Vehicles Available
                            </div>
                            :
                            vehicleAvailLoader ?
                            <div className="vehicleFinderBtn" style={{ marginTop:'1%' }}>
                                <CircularProgress size={20} />
                            </div>
                            :
                            vehicle_listDateTime.filter(item => item.is_available===true).length === 0 ?
                            <div style={{ fontSize:'14px' }}> No Vehicles are available for {moment(StartDate).format('DD/MMM/YYYY')} - {moment(moment(EndDate).isValid() ? EndDate : StartDate).format('DD/MMM/YYYY')}
                            ({startHours<10 ? `0${startHours}`: startHours}:{startMinutes<10 ? `0${startMinutes}` : startMinutes}{startMeridian}-
                            {endHours<10 ? `0${endHours}` : endHours}:{endMinutes<10 ? `0${endMinutes}` : endMinutes}{endMeridian})</div>
                            :      
                            <VehicleLayout
                            selectedVehicleArr={selectedVehclArr}
                            setSelectedVehicleArr={setSelectedVehclArr}
                    selectedTeamArr={selectedArr}
                    setSelectedTeamArr={setSelectedArr}
                    additonalDriv={additionalVehTeamArr}
                    setAdditionalVehTeamArr={setAdditionalVehTeamArr}
                    primeVehDriver={primeVehicleDriver}
                    setPrimeVehicleDriver={setPrimeVehicleDriver}
                    primeVehicle={vehicle}
                    setPrimeVehicle={setVehicle}
                />                  
                 } 
                
            </div>
            </Scrollbars>
                    
            <TeamLayout 
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
                additionalVehDriver={additionalVehTeamArr}
                primeVehicleDriver={primeVehicleDriver}
            />
           {
                isDateTimeInPast() ?
                <CompletedJobModal
                    signImage={signImage}           setSignImage={setSignImage}
                    after_pics={after_pics}         setAfterPics={setAfterPics}
                    before_pics={before_pics}       setBeforePics={setBeforePics}
                    schduleStatus={schduleStatus}   setScheduleStatus={setScheduleStatus}
                    jobStatusTime={jobStatusTime}   setJobStatusTime={setJobStatusTime}
                    chngWasteType={chngWasteType}   setChngWasteType={setChngWasteType}
                    chngWasteLtr={chngWasteLtr}     setChngWasteLtr={setChngWasteLtr}
                    ponumber={ponumber}             setPonumber={setPonumber}
                    signName={signName}             setSignName={setSignName}
                    videoGallery={videoGallery}     setvideoGallery={setvideoGallery}
                    gallery_pics={gallery_pics}     setGalleryPics={setGalleryPics}
                />
                :   null
           }
            </div>
            <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                {
                    isSchedulerBtnLoader ?
                        <button className="disabled-btn">
                            <CircularProgress size={20}/>
                        </button>
                    :
                    isTimeInCorrect() ?
                    <button className="disabled-btn"
                        style={{ color:'red' }}
                    >Time entered is incorrect.</button>
                    :
                    (vehicle && primeVehicleDriver && additionalVehTeamArr && ((additionalVehTeamArr.length) === (selectedVehclArr && selectedVehclArr.length))) ? (
                        <button 
                            disabled={applyButton}
                            onClick={() => {
                                setApplyButton(true)
                                schedule_btn_fx(isMock)
                            }}
                        >
                            Schedule
                            {
                                isDateTimeInPast() ? ` Completed Job` : ''
                            }
                        </button>
                    ) : 
                    
                    (
                        <button className="disabled-btn">Schedule{
                            isDateTimeInPast() ? ` Completed Job` : ''
                        }</button>
                    )
                }
                
            </div>
        </div>
        </Modal>
        </Draggable>
  );
}

const mapStateToProps = (state) => {
    const { vehicle_listDateTime, job_list_weekFilter, schdlListType, jobTab, vehicleAvailLoader } = state.schedule
    return { vehicle_listDateTime, job_list_weekFilter, schdlListType, jobTab, vehicleAvailLoader }
}

const mapDispatchToProps = (dispatch) => {
    return {
        schedule_a_job: (type, data, jobType, schType, success, failed) => dispatch(schedule_job(type, data, jobType, schType, success, failed)),
        schedule_edit_job: (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_setting: (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        vehicle_setting: (screen_type, data, primeData, shdId, schType, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, schType, success, failed)),
        fetch_availabilityVeh: (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp: (filter) => dispatch(fetch_Employee_avilabilities(filter)),
        fetch_jobs: (type, page, frmD, jobTab) => dispatch(fetch_job_list_readyForSchd(type, page, frmD, jobTab)),

        //setSelectTeam: (item) => dispatch(setToTeam(item))
    }
}


 export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal)
 
