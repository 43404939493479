import React from 'react'
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import JoditEditor from 'jodit-react';
import Scrollview from '../../../common/ImprovedScrollBar';
import { HOST } from '../../../store/endpoint';
import { useState } from 'react';
import { ExpandLessRounded, ExpandMoreRounded } from '@material-ui/icons';
// import { CircularProgress } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    Tempname,
    modalStatus, 
    modalAction,
    editorRef,
    editorAction,
    quote_content, 
    set_quote_content,
    setEditableQuoteContent,
    set_quote_amount, clientId, user,
    injectChange
}) {

    const classes = useStyles();
    console.log('ModalOPenAGn')
    // const [textCol , setTextCol] = useState('black');
    const [textSz, setTextSz] = useState('16')
    const [content, setContent] = useState('')
    const [openMod, setOpenMod] = useState(false)
    // const [editorVal, setEditorVal] = useState(quote_content)
    const handleClose = () => {
        modalAction(false)
    };


    const config = {
        readonly: false,
        spellcheck  :   true,
        defaultFontSizePoints   : "pt",
        style:  {
            td : 'text-align:left !important;' 
        },
        removeButtons : HOST.includes('staging') ? ["copyFormat"] : ["source", "copyFormat"],
        buttons : "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,classSpan,lineHeight,superscript,subscript,image,video,file,spellcheck,copyformat,brush,undo,redo,source",
        placeholder : 'Template loading ...',
        saveModeInStorage: true,
        inline: false,
    }
    
    const addAttachment = () =>{
        set_quote_content(editorRef.current.value)
            // editorAction()
        modalAction(false)
    }
    
    const joditCSS_Override = `
    
    .jodit_fullsize-box_true {
        background-color : var(--cl_ivery);
        pointer-events : none;
    }
    .jodit_fullsize {
        bottom: 0;
        left: 20vw;
        max-width: 60vw !important;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100000;
        background-color: white;
        pointer-events : auto;
    }
    @media only screen and (max-width: 1500px){
        .jodit_fullsize {
            bottom: 0;
            left: 17.5vw;
            max-width: 65vw !important;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100000;
            background-color: white;
            pointer-events : auto;
        }
    }
    @media only screen and (max-width: 1400px){
        .jodit_fullsize {
            bottom: 0;
            left: 15vw;
            max-width: 70vw !important;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100000;
            background-color: white;
            pointer-events : auto;
        }
    }
    @media only screen and (max-width: 1200px){
        .jodit_fullsize {
            bottom: 0;
            left: 0;
            max-width: 80vw !important;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100000;
            background-color: white;
            pointer-events : auto;
        }
    }
    `;

    
        console.log('VAL=>',quote_content)
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="cer-cerificate-modal-layout" style={{ width : '60vw', height : '85vh', overflowY:'auto' }} >
                <div className="cer-modal-header-layout">
                    <p style={{ fontSize: 16 }} className="cer-modal-main-title">{Tempname}</p>
                </div>
                <Scrollview style={{ height : '80vh' }} hidden={false}>
                <div className={openMod ? 'scopOfWorkEditorPop' : 'scopOfWorkEditorPopclose'}
                    onClick={()=>{ if(!openMod){ setOpenMod(!openMod); }}}
                >
                    {/* <div>
                        <p>Text Color :</p>
                        <input type="text" placeholder='colors in lowercase' value={textCol} onChange={(e)=> setTextCol(e.target.value)} />
                    </div> */}
                    {  !openMod ? <p>Add Description Below Scope of Work</p> :
                    <div>
                        <div style={{ width:'100%', height:'90%' }}>
                        <p>Content</p>
                        <textarea style={{ width:'95%', height:'90%', borderRadius:'10px', padding:'1%', overflow:'auto', resize:'none' }}
                        type="number" placeholder='Enter Description' value={content} onChange={(e)=> setContent(e.target.value)} />
                    </div>
                    <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between' }}>
                    <div style={{ width:'20%', display:'flex', flexDirection:'row' }}>
                        <p>Text Size</p>
                        <input type="text" placeholder='numbers only' value={textSz} onChange={(e)=> setTextSz(e.target.value)} />
                    </div>
                    <div className='scopeOfWorkEditorPopBtn' onClick={()=>{ injectChange(textSz,'black',content); }}>Apply to Description Below Scope of Work</div>
                    </div>
                    </div>
                    }
                    {!openMod ? <ExpandMoreRounded onClick={()=> setOpenMod(true)} /> : <ExpandLessRounded  onClick={()=> setOpenMod(false)}/>}
                </div>
                <div className="cer-modal-body-layout">
                    {/* {console.log("FROM REDUCER ",sale_client_draft)} */}
                    <div className="sales-qt-client-info" style={{height:'60vh'}}>
                        <style>{joditCSS_Override}</style>
                        {                        
                        <Scrollview style={{ height: '55vh'}}>
            <JoditEditor
                ref={editorRef}
                value={quote_content}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => { 
                    // setEditorVal(newContent)
                    setEditableQuoteContent(newContent)
                }}
            />
            </Scrollview>
                        }
                    </div>
                    
                    
                </div>
                </Scrollview>
                <div style={{ display:'flex', flexDirection:'row', fontSize:'16px', alignItems:'center', justifyContent:'space-around' }}>
                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center' }}
                            onClick={()=> addAttachment() }
                        >
                            Save Changes
                        </div>
                        
                    </div>
            </div>
      </Modal>
  );
}

const mapStateToProps = (state) => {
    // const { quote_content } = state.sales
    const { user } = state.userLogin 

    return { user }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_quote_content: (content) => dispatch({ type: 'SALES_SET_CONTENT_QUOTE', content: content }),
        set_quote_amount: (amount) => dispatch({ type: 'SALES_SET_QUOTE_AMOUNT', amount: amount })
      }
}

 export default connect( mapStateToProps, mapDispatchToProps) (TransitionsModal)
 
