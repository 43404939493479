import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { team_list_paginated } from './action'
import __MainStack from './component/innerScreens/mainStack'
import __DetailedStackInner from './component/innerScreens/detailedPhotosStack'
import __DetailedStack from './component/innerScreens/detailedStack'


function App({team_list_api}) {
    const { path } = useRouteMatch()

    useEffect(() => {
        team_list_api()

        return () => team_list_api.remove
    }, [team_list_api]);


    return (
        <Switch>
            <Route path={path + '/detail'} name="team-detail" component={__DetailedStack} />
            <Route path={path + '/detail-folder/:id/:folder'} name="team-detailz" component={__DetailedStackInner} />
            <Route path={path} name="team-main" component={__MainStack} />
        </Switch>
    )
}

const mapStateProps = (state) => {
    const { team } = state
    return {team}
}

const mapDispatchToProps = (dispatch) => {
    return {
        team_list_api: () => dispatch(team_list_paginated('curr',1))
      }
}

export default connect(mapStateProps, mapDispatchToProps)(App)
