import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import SendIcon from '@material-ui/icons/Send';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CancelIcon from '@material-ui/icons/Cancel';
import { //IconButton, 
    CircularProgress, Modal, 
    //Tooltip 
} from '@material-ui/core'
import { connect } from 'react-redux'
import VehicleLayout from './VehicleLayout'
import TeamLayout from './team_layout_available_only'
import Swal from 'sweetalert2'
import { cl_green, cl_red } from '../../../common/Colors'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
// import { Fragment } from 'react';
// import { HOST_WITHOUT_SLASH, SCHEDULE_ADD_IMAGE, SCHEDULE_ADD_VIDEO, SCHEDULE_COMMENT } from '../../../store/endpoint';
// import Api from '../../../store/api'
import DateTimeInput from '../../../common/DateTimeInput';
import moment from 'moment'
import { //editAfterCompleteElements, 
    fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../common/action';
// import { fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../common/action';

import Draggable from 'react-draggable';
import { //Edit, ExpandLess, ExpandMore
    CancelRounded, DragIndicatorRounded } from '@material-ui/icons'
// import SignaturePadModal from '../../../common/SignaturePadModal';
// import VideoFilePreview from '../../../common/VideoFilePreview';
import JobModalBRightSidePanel from './jobModalBRightSidePanel';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalLow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },  
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    selectedJob,

    openJobDetailModal,

    StartDate,
    setStartDate,
    StartTime,
    setStartTime,
    EndTime,
    setEndTime,
    EndDate,
    setEndDate,
    scheduleId,

    vehicleVal,
    setVehicleVal,

    primeVehicle,
    setPrimeVehicle,

    primeVehicleDriver, setPrimeVehicleDriver,
    // additionalVehTeamArr, 
    setAdditionalVehTeamArr,
    // gallery_pics, setGalleryPics,


    schduleStatus,
    setScheduleStatus,

    selectedArr,
    setSelectedArr,

    updateScheduleFx,
    deleteScheduleFx,

    addScheduleImg,
    addScheduleSignImg,

    // CommentsArr,
    // setCommentsArr,
    fetch_schedule_list,
    screen_type,
    prevData,
    vehicle_listDateTime,

    fetch_availabilityVeh,
    fetch_availabilityEmp,
    setContentLoader=null,

    allVehArrObj,
    vehicleAvailLoader,
    selected_job
}) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const handleClose = () => {
        if(setContentLoader!==null)
            setContentLoader(null)

        fetch_availabilityVeh('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'), screen_type);
        fetch_availabilityEmp('start='+ moment().format('YYYY-MM-DD')+ ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD') + ' ' + moment().add(1,'day').format('HH:mm:ss'));
        
        setSelectedArr([])
        setPrimeVehicle(null)
        setAdditionalVehicleDr([])
        setPrimeVehicleDriver(null)
        setStartDate(moment().format('YYYY-MM-DD'))
        setStartTime(moment({hours:0, minutes:0}).format('HH:mm'))
        setEndDate(moment().format('DD-MM-YYYY'))
        setEndTime(moment({hours:0, minutes:0}).format('HH:mm'))
        setCommentsArr([])
        dispatch({ type: 'SALES_JOB_VIEW', selectedJob: null })


        modalAction(false)
    };
    console.log('JBSTATUsTime',selected_job)
    const { amount, client, address, job, quote_id } = selectedJob
    
    const [startHours, setStartHours] = useState(moment(StartTime,'HH:mm').hours())
    const [startMinutes, setStartMinutes] = useState(moment(StartTime,'HH:mm').minutes())
    const [endHours, setEndHours] = useState(moment(EndTime,'HH:mm').hours())
    const [endMinutes, setEndMinutes] = useState(moment(EndTime,'HH:mm').minutes())

    const [startMeridian, setStartMeridian] = useState('am')
    const [endMeridian, setEndMeridian] = useState('am')
    const [findPrimeVehicle, setFindPrimeVehicle] = useState(false)
    const [CommentsArr, setCommentsArr]         = useState([])

    const [isSchedulerBtnLoader, setIsSchedulerBtnLoader] = useState(false)
    const [additionalVehDr, setAdditionalVehicleDr] = useState([])

    const [jobStatusTime, setJobStatusTime]              = useState(
        selected_job && selected_job.schedule ?
        {
            depart_enviro_facility  : selected_job.schedule.depart_enviro_facility, 
            start_job               : selected_job.schedule.start_job, 
            finish_job              : selected_job.schedule.finish_job,
            completed               : selected_job.schedule.completed, 
            arrive_at_waste_depot   : selected_job.schedule.arrive_at_waste_depot, 
            depart_waste_depot      : selected_job.schedule.depart_waste_depot, 
            arrive_enviro_facility  : selected_job.schedule.arrive_enviro_facility
        } : 
        {
            depart_enviro_facility  : '12:00 am', 
            start_job               : '12:00 am', 
            finish_job              : '12:00 am',
            completed               : '12:00 am', 
            arrive_at_waste_depot   : '12:00 am', 
            depart_waste_depot      : '12:00 am', 
            arrive_enviro_facility  : '12:00 am'
        })
    const [chngWasteType, setChngWasteType]              = useState(selected_job && selected_job.schedule.extracted_waste_type ? selected_job.schedule.extracted_waste_type : '')
    const [chngWasteLtr, setChngWasteLtr]                = useState(selected_job && selected_job.schedule.extracted_litres_of_waste!=='' ? selected_job.schedule.extracted_litres_of_waste : '')
    const [ponumber, setPonumber]                        = useState(selected_job && selected_job.schedule.purchase_order_number!=='' ? selected_job.schedule.purchase_order_number : '')
    const [signName, setSignName]                        = useState(selected_job && selected_job.schedule.signature_name!=='' ? selected_job.schedule.signature_name : '')
    
    const [signImage, setSignImage] = useState( selected_job && selected_job.image ? selected_job.image : selected_job && selected_job.schedule.signature ? selected_job.schedule.signature : '') 
    const [after_pics, setAfterPics] = useState([]) 
    const [before_pics, setBeforePics] = useState([])
    const [videoGallery, setvideoGallery] = useState([])
    const [gallery_pics, setGalleryPics] = useState([])
    const [jobBRStatus, setJobBRStatus] = useState(false)

    useEffect(() => {
        setStartHours(moment(StartTime,'HH:mm').hours())
        setStartMinutes(moment(StartTime,'HH:mm').minutes())
        setEndHours(moment(EndTime,'HH:mm').hours())
        setEndMinutes(moment(EndTime,'HH:mm').minutes())
        setStartMeridian('am')
        setEndMeridian('am')
        setFindPrimeVehicle(false)
        console.log('USEEFFECT')
    }, [StartTime, EndTime]);

    useEffect(() => {
        if(selected_job && selected_job.schedule){
            setJobStatusTime(
            {
                depart_enviro_facility  : moment(selected_job.schedule.depart_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.depart_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',
                start_job               : moment(selected_job.schedule.start_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.start_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',                
                finish_job              : moment(selected_job.schedule.finish_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.finish_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',
                completed               : moment(selected_job.schedule.completed,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.completed,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',
                arrive_at_waste_depot   : moment(selected_job.schedule.arrive_at_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.arrive_at_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',
                depart_waste_depot      : moment(selected_job.schedule.depart_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.depart_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',
                arrive_enviro_facility  : moment(selected_job.schedule.arrive_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).isValid() ? 
                    moment(selected_job.schedule.arrive_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DD HH:mm:ss','HH:mm A', 'hh:mm a']).format('hh:mm a') : '12:00 am',
            })
            setChngWasteType(selected_job.schedule.extracted_waste_type)
            setChngWasteLtr(selected_job.schedule.extracted_litres_of_waste)
            setPonumber(selected_job.schedule.purchase_order_number)
            setSignName(selected_job.schedule.signature_name)
            setSignImage(selected_job.schedule.signature)
            setCommentsArr(selected_job.schedule.comments)
            setAdditionalVehicleDr(selected_job?.schedule?.additional_vehicle_driver)
            setAdditionalVehTeamArr(selected_job?.schedule?.additional_vehicle_driver)
        }
    }, [selected_job, setAdditionalVehTeamArr]);

    let applyButtonDisabled = true
    let changeStatusDate = false
    let changeStatusTeam = false
    // let changeStatusVehicle = false
    const deleteScheduleing = () => {
        handleClose()
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                deleteScheduleFx(scheduleId)
            }
        })
    }

    console.log(primeVehicleDriver, additionalVehDr, selectedJob)

    
    if(selectedJob && selectedJob.team_employees) {
        if(selectedJob.start_date !== StartDate || selectedJob.start_time !== StartTime || selectedJob.end_time !== EndTime || selectedJob.end_date !== EndDate || selectedJob.status !== schduleStatus) {
            changeStatusDate= true
        } else {
            changeStatusDate= false
        }
        if( selectedJob.team_employees.length !== selectedArr && selectedArr.length) {
            changeStatusTeam= true
        } else {
            changeStatusTeam= false
        }
        
    }

    if(changeStatusDate || changeStatusTeam || vehicleVal || jobBRStatus ) {
        applyButtonDisabled= false 
    }

    const setDefaultDates = (e) =>{
        let startSetTime = moment(StartTime).isValid() ? moment(StartTime).format('HH:mm:ss') : moment().format('HH:mm:ss')
        let endSetTime = moment(EndTime).isValid() ? moment(EndTime).format('HH:mm:ss') : moment().format('HH:mm:ss')
        setFindPrimeVehicle(true)
        clearRefresh()
        // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${startSetTime}&&end=${moment(e).format('YYYY-MM-DD')} ${endSetTime}`, screen_type)
        fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${startSetTime}&&end=${moment(e).format('YYYY-MM-DD')} ${endSetTime}`)
        // fetch_availability('start='+StartDate+' '+StartTime+':00&&end='+EndDate+' '+EndTime+':00')
    }

    const clearRefresh = () =>{
        setPrimeVehicle(null)
        setPrimeVehicleDriver(null)
        setSelectedArr([])
        setVehicleVal([])
        setAdditionalVehicleDr([])

    }

    const isTimeInCorrect = () =>{
        console.log( moment(StartTime,'HH:mm').isValid(),moment(EndTime,'HH:mm').isValid(),StartTime,EndTime)
        if(StartTime && EndTime && moment(StartTime,'HH:mm').isValid() && moment(EndTime,'HH:mm').isValid()
        && (moment(StartDate).format('YYYY-MM-DD')===moment(EndDate).format('YYYY-MM-DD'))){
            var strTm = moment(StartTime, 'HH:mm');
            var endTm = moment(EndTime, 'HH:mm');
            var duration = moment.duration(endTm.diff(strTm))
            var hr = duration.asHours();
            var min = parseInt(duration.asMinutes())-hr*60;
            console.log(strTm,endTm,duration,hr,min)
            return (hr<0 || min<0);
        }
        else
            return false
        
    }

    const updateAllAddVehDrs = (val) =>{
        setAdditionalVehTeamArr(val)
        setAdditionalVehicleDr(val)
    }

    console.log('APPLYBTN=>',applyButtonDisabled,primeVehicle,additionalVehDr,vehicleVal,!jobBRStatus)
    
    return (
        <Draggable handle="strong"
        bounds={{top: -parseInt(window.innerHeight/12),
             left: -parseInt(window.innerWidth/2), 
             right: parseInt(window.innerWidth/2), 
             bottom: parseInt(window.innerHeight - (window.innerHeight/6))}} 
         >
      <Modal
        className={classes.modalLow}
        open={modalStatus}
        onClose={handleClose}
        hideBackdrop={true}
      >
          <div className="shdl-modal-layout-mainn">
          <strong className="draggerHead" id="draggerHeadId">
              <div style={{ width:'50%' }}>
                  <DragIndicatorRounded style={{ transform:'rotate(-90deg)', cursor:'all-scroll' }} />
              </div>
              <div>
                  <CancelRounded style={{ color:'red', cursor:'pointer' }} onClick={handleClose} />
              </div>
            </strong>
            <div className="shdl-modal-layout">
            <Scrollbars style={{ height:'65vh', width:'380px', marginRight:'10px' }}>
                <div className="shdl-modal-left-lay">
                    
                    <h4>Scheduled Job <span style={{ cursor: 'pointer'}} onClick={() => openJobDetailModal(quote_id, false)}><u># {job}</u></span></h4>
                    {   selectedJob.job_card_code &&
                        <h6># {selectedJob.job_card_code}</h6>}
                    <div className="shdl-modal-status">
                        
                    </div>
                    {/* {console.log(StartDate,StartTime,EndTime,EndDate,moment(EndTime, 'HH:mm:ss',true).isValid())} */}
                    <p className="shdl-modal-titles">{client}</p>
                    <p className="shdl-modal-titles">{address}</p>
                    <p className="shdl-modal-titles">{amount}$</p>

                    <div className="shdl-modal-first-layout">
                    <p>Start Date</p>
                    <div style={{ padding: '10px 0px' }}>
                        {/* <DateTimeInput
                        dateValue={StartDate}
                        dateSetFn={setStartDate}
                        acceptFunc={ setStartDateNTime }
                        pickerType={"dateTime"}
                        clsName={'shdl-timeDate-field'}
                        /> */}
                        <DateTimeInput
                            dateValue={StartDate}
                            dateSetFn={setStartDate}
                            clsName={'client-input'}
                            past={true}
                            acceptFunc={setDefaultDates}
                        />
                    </div>

                    <p>Start Time</p>

                    <div className="schdl-timing-box-main">
                    {/* {   timeChng==="start"?
                        <> */}
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setStartHours(parseInt(e.target.value)); 
                                    setFindPrimeVehicle(true)
                                    clearRefresh()
                                    setStartTime(moment({hours:parseInt(e.target.value), minutes:isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes }).format('HH:mm'));
        // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes }).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`, screen_type)
        fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes }).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`)
                                 }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        
                                        return (<option 
                                            selected={isNaN(startHours) ?
                                                (moment(StartTime,'HH:mm').hours()>12 && moment(StartTime,'HH:mm').hours()-12 === 12-key) || (moment(StartTime,'HH:mm').hours()===0 && 12-key===12) || moment(StartTime,'HH:mm').hours()===12-key ? true : false
                                                :   (startHours>12 && startHours-12 === 12-key) || (startHours===0 && 12-key===12) || startHours===12-key ? true : false}
                                                value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        
                        </div>
                        <div className="schdl-timing-box">
                            <select
                                className="schdl-timing-input"  
                                onChange={(e) => { setStartMinutes(parseInt(e.target.value)); 
                                    setFindPrimeVehicle(true)
                                    clearRefresh()
                                setStartTime(moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes:parseInt(e.target.value)}).format('HH:mm'));
        // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`, screen_type)
        fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}&&end=${moment(EndDate).isValid() ? moment(EndDate).format('YYYY-MM-DD') : EndDate} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`)
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option 
                                            selected={ isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes()===key ? true : false 
                                            : startMinutes===key ? true : false}
                                            
                                            value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input"
                                id="startTimeMeridian"
                                onChange={(e)=>{    
                                                setStartMeridian(e.target.value)
                                                clearRefresh()
                                                if(e.target.value==='pm')
                                                    if(isNaN(startHours) ? moment(StartTime,'HH:mm').hours()!==12 : startHours!==12)
                                                    {
                                                        let temp = parseInt(isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours)+12
                                                        setStartTime(moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'));
                                                        setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`)
                                                    }
                                                    else{
                                                        
                                                        setStartTime(moment({hours:0, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))
                                                        setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`)
                                                    }
                                                else 
                                                    if(isNaN(startHours) ? moment(StartTime,'HH:mm').hours()>11 : startHours>11)
                                                    {
                                                        let temp = parseInt(isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours)-12
                                                                    setStartTime(moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'));
                                                                    setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`)
                                                    }
                                                else{
                                                        setStartTime(moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))
                                                        setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime : moment(EndTime,'HH:mm',true).isValid() ? `${EndTime}:00` : moment(EndTime).format('HH:mm:ss')}`)
                                                    }
                                                    // setStartTime(moment({hours:  isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))

                                                
                                                }}
                            >
                                <option selected={isNaN(startHours) ? moment(StartTime,'HH:mm').hours()<12 : startHours<12} value={'am'}>AM</option>
                                <option selected={isNaN(startHours) ? moment(StartTime,'HH:mm').hours()>11 : startHours>11} value={'pm'}>PM</option>
                            </select>
                            
                        </div>
                        
                    </div>
                        
                    <p>End Date</p>
                    <div style={{ padding: '10px 0px' }}>
                        <DateTimeInput
                            dateValue={EndDate}
                            dateSetFn={setEndDate}
                            clsName={'client-input'}
                            past={true}
                            rangeDate={StartDate}
                            acceptFunc={setDefaultDates}
                        />
                    </div>

                    <p>End Time</p>
                    <div className="schdl-timing-box-main">
                    
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input" 
                                onChange={(e) => { 
                                    setEndHours(parseInt(e.target.value)); 
                                    setEndTime(moment({hours:parseInt(e.target.value), minutes:isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes }).format('HH:mm'));
                                    setFindPrimeVehicle(true)
                                    clearRefresh()
                    // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes }).format('HH:mm:ss')}`, screen_type)
                    fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:parseInt(e.target.value), minutes:isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes }).format('HH:mm:ss')}`)
                                    }}>
                                {
                                    [...Array(12)].map((item, key) => {
                                        return (<option 
                                            selected={isNaN(endHours) ?
                                                (moment(EndTime,'HH:mm').hours()>12 && moment(EndTime,'HH:mm').hours()-12 === 12-key) || (moment(EndTime,'HH:mm').hours()===0 && 12-key===12) || moment(EndTime,'HH:mm').hours()===12-key ? true : false
                                                :   (endHours>12 && endHours-12 === 12-key) || (endHours===0 && 12-key===12) || endHours===12-key ? true : false}
                                            value={12-key}>{12-key < 10 ? '0' : null}{12-key}</option>)
                                    })
                                }
                            </select>
                        
                        </div>
                        <div className="schdl-timing-box">
                            <select
                                className="schdl-timing-input"  
                                onChange={(e) => { setEndMinutes(parseInt(e.target.value)); 
                                setEndTime(moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes:parseInt(e.target.value)}).format('HH:mm')); 
                                setFindPrimeVehicle(true)
                                    clearRefresh()
                    // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}`, screen_type)
                    fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes:parseInt(e.target.value)}).format('HH:mm:ss')}`)
                                }}>
                                {
                                    [...Array(60)].map((item, key) => {
                                        let num = '';
                                        if(key < 10) {
                                            num = '' + (key);
                                        } else {
                                            num = key
                                        }
                                        return (<option 
                                            selected={ isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes()===key ? true : false 
                                            : endMinutes===key ? true : false}
                                            
                                            value={key}>{num}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className="schdl-timing-box">
                            <select 
                                className="schdl-timing-input"
                                id="endTimeMeridian"
                                onChange={(e)=>{    
                                    setEndMeridian(e.target.value)
                                    clearRefresh()
                                    if(e.target.value==='pm')
                                        if(isNaN(endHours) ? moment(EndTime,'HH:mm').hours()!==12 : endHours!==12)
                                        {
                                            let temp = parseInt(isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours)+12
                                            setEndTime(moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'));
                                            setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`)
                                        }
                                        else{
                                            
                                            setEndTime(moment({hours:0, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'))
                                            setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:0, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`)
                                        }
                                    else 
                                        if(isNaN(endHours) ? moment(EndTime,'HH:mm').hours()>11 : endHours>11)
                                        {
                                            let temp = parseInt(isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours)-12
                                                        setEndTime(moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'));
                                                        setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:temp, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`)
                                        }
                                    else{
                                            setEndTime(moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm'))
                                            setFindPrimeVehicle(true)
                                // fetch_availabilityVeh(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`, screen_type)
                                fetch_availabilityEmp(`start=${moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss').isValid() ? StartTime : moment(StartTime, 'HH:mm', true).isValid() ? `${StartTime}:00` : moment(StartTime).format('HH:mm:ss')}&&end=${moment(EndDate).format('YYYY-MM-DD')} ${moment({hours:isNaN(endHours) ? moment(EndTime,'HH:mm').hours() : endHours, minutes: isNaN(endMinutes) ? moment(EndTime,'HH:mm').minutes() : endMinutes}).format('HH:mm:ss')}`)
                                        }
                                        // setStartTime(moment({hours:  isNaN(startHours) ? moment(StartTime,'HH:mm').hours() : startHours, minutes: isNaN(startMinutes) ? moment(StartTime,'HH:mm').minutes() : startMinutes}).format('HH:mm'))

                                    
                                    }}
                            >
                                <option selected={isNaN(endHours) ? moment(EndTime,'HH:mm').hours()<12 : endHours<12} value={'am'}>AM</option>
                                <option selected={isNaN(endHours) ? moment(EndTime,'HH:mm').hours()>11 : endHours>11} value={'pm'}>PM</option>
                            </select>
                            
                        </div>
                       
                    </div>
                         

                        <p>Job Status</p>
                        <select
                            value={schduleStatus}
                            onChange={val => {
                                setScheduleStatus(val.target.value)
                            }}
                        >
                            <option value="pending">Pending</option>
                            <option value='departed_enviro_facility'>Departed Enviro Facility</option>
                            <option value='job_started'>Job Started</option>
                            <option value='job_finished'>Job Finished</option>
                            <option value="completed">Job Completed</option>
                            <option value='arrived_at_waste_depot'>Arrived At Waste Depot</option>
                            <option value='departed_waste_depot'>Departed Waste Depot</option>
                            <option value='arrived_at_enviro_facility'>Arrived At Enviro Facility</option>
                           
                        </select>
                    </div>

                        
                        {
                        
                            findPrimeVehicle ?
                             <div
                                className="vehicleFinderBtn"
                                style={{ marginTop:'1%' }}
                                onClick={()=>{
                                    setFindPrimeVehicle(false)
                                    // console.log(StartDate,moment(StartDate, 'YYYY-MM-DD', true).isValid(),StartTime,moment(StartTime, 'HH:mm:ss', true).isValid(),EndDate,moment(EndDate, 'YYYY-MM-DD', true).isValid(),EndTime,moment(EndTime, 'HH:mm:ss',true).isValid())
                                    fetch_availabilityVeh(`start=${moment(StartDate, 'YYYY-MM-DD', true).isValid() ? StartDate : moment(StartDate).format('YYYY-MM-DD')} ${moment(StartTime, 'HH:mm:ss', true).isValid() ? StartTime : StartTime+':00'}&&end=${moment(EndDate, 'YYYY-MM-DD', true).isValid() ? EndDate : moment(EndDate).format('YYYY-MM-DD')} ${moment(EndTime, 'HH:mm:ss',true).isValid() ? EndTime :EndTime+':00'}`, screen_type)
                                }}
                             >
                                Find Vehicles Available 
                            </div>
                            :
                            vehicleAvailLoader ?
                            <div className="vehicleFinderBtn" style={{ marginTop:'1%' }}>
                                <CircularProgress size={20} />
                            </div>
                             :   
                            vehicle_listDateTime.filter(item => item.is_available===true).length === 0 ?
                            <div style={{ fontSize:'14px' }}> No Vehicles are available for {moment(StartDate).format('DD/MM/YYYY')} - {moment(moment(EndDate).isValid() ? EndDate : StartDate).format('DD/MM/YYYY')}
                            ({StartTime}{startMeridian}-
                            {EndTime}{endMeridian})</div>
                            :  
                            <VehicleLayout
                                selectedVehicleArr={vehicleVal}
                                setSelectedVehicleArr={setVehicleVal}

                                selectedTeamArr={selectedArr}
                                setSelectedTeamArr={setSelectedArr}
                                additonalDriv={additionalVehDr}
                                setAdditionalVehTeamArr={updateAllAddVehDrs}
                                primeVehDriver={primeVehicleDriver}
                                setPrimeVehicleDriver={setPrimeVehicleDriver}
                                primeVehicle={primeVehicle}
                                setPrimeVehicle={setPrimeVehicle}

                                primeVehObj={allVehArrObj ? allVehArrObj.find(el => el.type==='primary') : null}
                                additionalVehArrObj={allVehArrObj ? allVehArrObj.filter(el => el.type!=='primary') : null}
                            />                      
                        }
                </div>
                </Scrollbars>
                <TeamLayout 
                    selectedArr={selectedArr}
                    setSelectedArr={setSelectedArr}
                    additionalVehDriver={additionalVehDr}
                    primeVehicleDriver={primeVehicleDriver}
                />
                {
                    selected_job &&
                <JobModalBRightSidePanel
                    selectedJob={selectedJob}
                    scheduleId={scheduleId}
                    schduleStatus={schduleStatus}
                    setScheduleStatus={setScheduleStatus}
                    addScheduleImg={addScheduleImg}
                    addScheduleSignImg={addScheduleSignImg}
                    CommentsArr={CommentsArr}
                    setCommentsArr={setCommentsArr}
                    fetch_schedule_list={fetch_schedule_list}
                    screen_type={screen_type}
                    prevData={prevData}
                    selected_job={selected_job}
                    modalAction={modalAction}
                    handleClose={handleClose}
                    jobStatusTime={jobStatusTime} setJobStatusTime={setJobStatusTime}
                    chngWasteType={chngWasteType} setChngWasteType={setChngWasteType}
                    chngWasteLtr={chngWasteLtr} setChngWasteLtr={setChngWasteLtr}
                    ponumber={ponumber} setPonumber={setPonumber}
                    signName={signName} setSignName={setSignName}
                    signImage={signImage} setSignImage={setSignImage} 
                    after_pics={after_pics} setAfterPics={setAfterPics} 
                    before_pics={before_pics} setBeforePics={setBeforePics}
                    videoGallery={videoGallery} setvideoGallery={setvideoGallery}
                    gallery_pics={gallery_pics} setGalleryPics={setGalleryPics}
                    jobBRStatus={setJobBRStatus}
                />
                }
            </div>

            <div style={{ display: 'flex', justifyContent: 'center'  }}>
            {   schduleStatus!=='completed' ?
            <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn-dlt">
                <button 
                    onClick={() => deleteScheduleing()}
                >Delete</button>
            </div>
            :   null
            }
            {
                isSchedulerBtnLoader ?
                <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                        <button className="disabled-btn">
                            <CircularProgress size={20}/>
                        </button>
                </div>
            :
                isTimeInCorrect() ?
                <button className="disabled-btn"
                    style={{ color:'red' }}
                >Time entered is incorrect.</button>
                :
                applyButtonDisabled || !(primeVehicle && primeVehicleDriver) || (additionalVehDr && ((additionalVehDr.length) !== (vehicleVal && vehicleVal.length)))
                ? (
                    <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                        <button className="disabled-btn">Apply</button>
                    </div>

                ) : (
                    <div style={{ justifyContent: 'center' }} className="shdl-modal-apply-btn">
                        <button 
                            onClick={() =>{ 
                                updateAllAddVehDrs(additionalVehDr)
                                setIsSchedulerBtnLoader(true)
                                let compleObj = {
                                        signImage   :   signImage,
                                        ponumber    :   ponumber,
                                        signName    :   signName,
                                        chngWasteType   :   chngWasteType,
                                        chngWasteLtr    :   chngWasteLtr,
                                        before_pics     :   before_pics,
                                        after_pics      :   after_pics,
                                        gallery_pics    :   gallery_pics,
                                        videoGallery    :   videoGallery,
                                        jobStatusTime   :   jobStatusTime
                                    }

                                updateScheduleFx( true,
                                    true, (vehicleVal && vehicleVal.length!==0), setIsSchedulerBtnLoader, compleObj
                                    );}}
                        >Apply</button>
                    </div>
                )
            }

            </div>
            
        </div>
        </Modal>
        </Draggable>
  );
}

const mapStateToProps = (state) => {
    const { vehicle_listDateTime, vehicleAvailLoader } = state.schedule
    const { selected_job } = state.jobView
    return { vehicle_listDateTime, selected_job, vehicleAvailLoader }
}

const mapDispatchProps = (dispatch) =>{
    return{
        fetch_availabilityVeh : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp : (filter) => dispatch(fetch_Employee_avilabilities(filter))
    }
}

 export default connect(mapStateToProps, mapDispatchProps) (TransitionsModal)
 
