import Swal from 'sweetalert2'
import { FileDownloader } from '../../../../../common/file_downloader'
import Api from '../../../../../store/api'
import {
    VEHICLE_GET,

    VEHICLE_EDIT_MULTIPLE,
    VEHICLE_ADD,

    VEHICLE_ALL_GET,

    VEHICLE_MAINTANACE_GET,
    // VEHICLE_MAINTANACE_SEARCH,
    VEHICLE_MAINTANACE_EDIT,
    VEHICLE_MAINTANACE_DELETE,
    VEHICLE_MAINTANACE_ADD,

    VEHICLE_FUEL_GET,
    VEHICLE_FUEL_EDIT,
    VEHICLE_FUEL_DELETE,
    VEHICLE_FUEL_ADD,

    VEHICLE_PREINSPECT_FILTER,
    VEHICLE_PREINSPECTION,

    // VEHICLE_SEARCH,
    VEHICLE_FOLDERS,
    VEHICLE_FILE_UPLOAD,
    VEHICLE_FILE_DELETE,
    VEHICLE_MAINTANACE_FILTER,
    VEHICLE_FUEL_FILTER,
    VEHICLE_FUEL_PRINT_ALL_CSV,
    VEHICLE_MAINTANACE_PRINT_CSV,
    VEHICLE_SEARCH,
    VEHICLE_PREINSP_PRINT_CSV,
    VEHICLE_MASTER_PRINT_ALL_CSV,
    VEHICLE_UNARCHIVE,
    VEHICLE_ARCHIVE,

} from '../../../../../store/endpoint'

// import { fetch_car_list } from '../../car/common/action'
// import { fetch_forklift_list } from '../../forklift/common/action'


// ******************************************************
//                      T R U C K
// ******************************************************

export const fetch_truck_list = (tabType, page, callBk = null) => {
    return function (dispatch) {
        dispatch({ type: 'TRUCK_LIST_FETCHING',page: page })
        // if(callBk!==null){
        //     callBk(true)
        // }
        // let get_url =  VEHICLE_GET+ tabType + '/truck/all/' + page + '/'+'?limit=15'
        let get_url = `${VEHICLE_GET}${tabType}/truck/all/${page}/?limit=15`
        const success = (response) => {
            if (response) {
                dispatch({ type: 'TRUCK_LIST', trucks: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'TRUCK_LIST_ERROR', error: error })
            }
        }
        Api('get', get_url, null, success, failed)
        // .then(response => {
        //     // if(callBk!==null){
        //     //     callBk(false)
        //     // }
        //      console.log(response)
        //     if(response) {
        //         dispatch({ type: 'TRUCK_LIST', trucks: response, page: page })

        //     }
        // })
    }
}

export const fetch_search_trucks = (tabType, page, txt) => {
    return function (dispatch) {
        dispatch({ type: 'TRUCK_LIST_FETCHING', keyword: txt,page:page})
        // if(callBk!==null){
        //     callBk(true)
        // }

        const success = (response) => {
            if (response) {
                dispatch({ type: 'TRUCK_LIST', trucks: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'TRUCK_LIST_ERROR', error: {
                    status: null,
                    text: 'Something went wrong!',
                    // text: null,
                    dev_data: null,
                    app_data: null
                } })
            }
        }
        let formbody = new FormData()
        formbody.append('key', txt)
        formbody.append('types', 'truck')
        formbody.append('tab_type', tabType)

        let url = `${VEHICLE_SEARCH.replace('VTYPE', `${tabType}/truck`)}?page=${page}&limit=15`

        Api('post-form', url, formbody,success,failed)
            // .then(response => {
            //     // if(callBk!==null){
            //     //     callBk(false)
            //     // }
            //     console.log(response)
            //     if (response) {
            //         dispatch({ type: 'TRUCK_LIST', trucks: response, page: page })

            //     }
            // })
    }
}


export const fetch_all_truck_list = (tabType) => {
    return function (dispatch) {
        let get_url = VEHICLE_ALL_GET.replace('VTYPE', `${tabType}/truck`)
        Api('get', get_url)
            .then(response => {
                console.log(response)
                if (response) {
                    dispatch({ type: 'ALL_TRUCK_LIST', trucks: response })
                }
            })
    }
}


export const __vehicle_modal = (status) => {
    return function (dispatch) {
        dispatch({ type: 'VEHCILE_MODAL', modal: status })
    }
}

export const __vehicle_img_modal = (status) => {
    return function (dispatch) {
        dispatch({ type: 'VEHCILE_IMG_MODAL', modal: status })
    }
}



export const __select_vehicle = (truck) => {
    return function (dispatch) {
        dispatch({ type: 'SELECT_TRUCK', truck: truck })
    }
}



export const __edit_vehicle = (tabType, vehicle_id, data, success, failed
    //, page_no
) => {
    return function (dispatch) {
        // dispatch({ type: 'SELECT_TRUCK', truck: truck})
        // console.log('vehicleid', vehicle_id)
        // for(var k of data.entries())
        //     console.log(k[0],k[1])

        let edit_url = `${VEHICLE_GET + tabType + '/edit/' + vehicle_id}/`

        const success_res = () => {
            success()
            // dispatch(fetch_truck_list(tabType, page_no))
        }
        Api('patch-form', edit_url, data, success_res, failed)
            .then(response => {
                console.log("VEHICLE EDITTED", response)
                dispatch({
                    type: 'TRUCK_EDIT_LIST',
                    id: response.id,
                    content: response
                })
            })
    }
}

export const __edit_vehicle_multi_images = (vehicle_id, data, page_no, success, failed) => {
    // for(var k of data.entries())
    //     console.log(k[0],k[1])
    return function (dispatch) {

        let edit_url = `${VEHICLE_EDIT_MULTIPLE + vehicle_id}/`
        Api('post-form', edit_url, data, success, failed).then(res => {
            dispatch(fetch_truck_list(page_no))
        }
        )
    }
}

export const __create_vehicle = (tabType, data, success, failed) => {
    // for(var k of data.entries())
    //     console.log(k[0],k[1])
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_truck_list(tabType, 1))
        }
        Api('post-form', VEHICLE_ADD, data, success_res, failed)
            .then(response => {
                console.log("VEHICLE CREATED")
            })
    }
}


export const __delete_vehicle = (tabType, vehicle_id, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_truck_list(tabType, page_no))
        }
        let delete_url = `${VEHICLE_GET + tabType + '/truck/delete/' + vehicle_id}/`
        Api('delete', delete_url, '', success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}

export const __archive_vehicle = (tabType, vehicle_id, archiveStatus, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_truck_list(tabType, page_no))
        }
        let delete_url =  archiveStatus ? `${VEHICLE_UNARCHIVE}${vehicle_id}/` : `${VEHICLE_ARCHIVE}${vehicle_id}/`
        Api('post', delete_url, '', success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}




// ******************************************************
//                  M A I N T A N A N C E
// ******************************************************


export const fetch_truck_maintanace_list = (tabType, page, callBk = null) => {
    return function (dispatch) {
        dispatch({type:'TRUCK_MAINTANACE_LIST_FETCHING',page:page})
        const success = (response) => {
            if (response) {
                dispatch({ type: 'TRUCK_MAINTANACE_LIST', maintanace: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'TRUCK_MAINTANACE_LIST_FAILED', error: error })
            }
        }
        if (callBk !== null) {
            callBk(true)
        }

        let str = VEHICLE_MAINTANACE_GET.replace('VTYPE', `${tabType}/truck`)
        let get_url = str + page + '/?limit=15'
        Api('get', get_url,null,success,failed)
            // .then(response => {
            //     if (callBk !== null) {
            //         callBk(false)
            //     }

            //     if (response) {
            //         dispatch({ type: 'TRUCK_MAINTANACE_LIST', maintanace: response, page: page })
            //     }
            // })
    }
}


// export const search_maintanance_list = (tabType, query) => {
//     return function (dispatch) {
//         if(query) {
//             let formbody = new FormData()
//             formbody.append('key', query)
//             Api('post', VEHICLE_MAINTANACE_SEARCH.replace('VTYPE',`${tabType}/truck`), formbody)
//                 .then(response => {
//                     dispatch({ type: 'TRUCK_MAINTANACE_LIST', maintanace: response })
//                 })
//         } else {
//             dispatch(fetch_truck_maintanace_list(tabType,1))
//         }
//     }
// }


export const __select_maintanace = (maintanace) => {
    return function (dispatch) {
        dispatch({ type: 'SELECT_TRUCK_MAINTANACE', maintanace: maintanace })
    }
}


export const __edit_maintanace = (tabType, maintanace_id, data, success, failed, page_no) => {
    for (let v of data.entries())
        console.log(v[0], " => ", v[1])
    return function (dispatch) {
        let edit_url = `${VEHICLE_MAINTANACE_EDIT.replace('VTYPE', `${tabType}/truck`) + maintanace_id}/`

        const success_res = () => {
            success()
            dispatch(fetch_truck_maintanace_list(tabType, page_no))
        }
        Api('patch-form', edit_url, data, success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}


export const __create_maintanace = (tabType, data, success, failed) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_truck_maintanace_list(tabType, 1))
        }
        Api('post-form', VEHICLE_MAINTANACE_ADD.replace('VTYPE', `truck`), data, success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}



export const __delete_maintanace = (tabType, maintanace_id, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_truck_maintanace_list(tabType, page_no))
        }
        let delete_url = `${VEHICLE_MAINTANACE_DELETE.replace('VTYPE', `${tabType}/truck`) + maintanace_id}/`
        Api('delete', delete_url, '', success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}

export const fetch_filter_maintance_list = (tabType, success, failed, data, type, id, page, fetchtype) => {


    if (type === 'all' && id === 'all') {
        return function (dispatch) {

            dispatch({
                type: 'TRUCK_REFRESH_FILTER'
            })
            dispatch(fetch_truck_maintanace_list(tabType,1))
        }
    }
    else {
        return function (dispatch) {
            if(fetchtype === 'filter' || fetchtype === 'date'){
                
                dispatch({type:'TRUCK_MAINTANACE_LIST_FETCHING',filter: id,page:page})
            }else{
                
                dispatch({type:'TRUCK_MAINTANACE_LIST_FETCHING',keyword: id,page:page})
            }
            let str = VEHICLE_MAINTANACE_FILTER.replace('VTYPE', `${tabType}/truck`)
            let post_url = str + type + `/?page=${page}&limit=15`
            const success_res = (response) => {
                if(fetchtype === 'filter' || fetchtype === 'date'){
                    
                    dispatch({ type: 'TRUCK_FILTERED_MNTN_LIST', trucks: response, filterKey: id,page:page })
                }else{
                    dispatch({ type: 'TRUCK_FILTERED_MNTN_LIST', trucks: response, keyword: id,page:page })
                }
                // dispatch({ type: 'TRUCK_FILTERED_MNTN_LIST', trucks: resp, filterKey: id })
            }
            const failed_res = (error) => {
                dispatch({ type: 'TRUCK_MAINTANACE_LIST_FAILED', error: error })
            }
            Api('post', post_url, data, success_res, failed_res)
                // .then(response => {
                //     if (callBk !== null) {
                //         callBk(false)
                //     }

                // })
        }
    }
}



// ******************************************************
//                       F U E L   E X P
// ******************************************************


export const fetch_fuel_list = (tabType, page, callBk = null) => {
    return function (dispatch) {
        dispatch({type:'TRUCK_FUEL_LIST_FETCHING',page:page})
        const success = (response) => {
            if (response) {
                dispatch({ type: 'TRUCK_FUEL_LIST', fuel: response, page: page })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'TRUCK_FUEL_LIST_ERROR', error: error })
            }
        }
        if (callBk !== null) {
            callBk(true)
        }

        let str = VEHICLE_FUEL_GET.replace('VTYPE', `${tabType}/truck`)
        let get_url = str + page + '/?limit=15'
        Api('get', get_url,null,success,failed)
            // .then(response => {
            //     // console.log(response)
            //     if (callBk !== null) {
            //         callBk(false)
            //     }

            //     if (response) {
            //         dispatch({ type: 'TRUCK_FUEL_LIST', fuel: response, page: page })
            //     }
            // })
    }
}


export const __select_fuel = (fuel) => {
    return function (dispatch) {
        dispatch({ type: 'SELECT_TRUCK_FUEL', fuel: fuel })
    }
}


export const __edit_fuel = (tabType, maintanace_id, data, success, failed, page_no) => {
    return function (dispatch) {
        let str = VEHICLE_FUEL_EDIT.replace('VTYPE', `${tabType}/truck`)
        let edit_url = `${str + maintanace_id}/`

        const success_res = () => {
            success()
            dispatch(fetch_fuel_list(tabType, page_no))
        }
        Api('patch-form', edit_url, data, success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}


export const __create_fuel = (tabType, data, success, failed) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            console.log(tabType)
            dispatch(fetch_fuel_list(tabType, 1))
        }
        Api('post-form', VEHICLE_FUEL_ADD, data, success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}



export const __delete_fuel = (tabType, maintanace_id, success, failed, page_no) => {
    return function (dispatch) {
        const success_res = () => {
            success()
            dispatch(fetch_fuel_list(tabType, page_no))
        }
        let str = VEHICLE_FUEL_DELETE.replace('VTYPE', `${tabType}/truck`)
        let delete_url = `${str + maintanace_id}/`
        Api('delete', delete_url, '', success_res, failed)
            .then(response => {
                // console.log(response)
            })
    }
}

export const filter_fuel_list = (tabType, success, failed, data, type, id, page, fetchtype) => {


    if (type === 'all' && id === 'all') {
        return function (dispatch) {
            dispatch({
                type: 'TRUCK_REFRESH_FILTER'
            })
            dispatch(fetch_fuel_list(tabType,1))
        }
    }
    else {
        return function (dispatch) {
            if(fetchtype === 'filter' || fetchtype === 'date'){
                
                dispatch({type:'TRUCK_FUEL_LIST_FETCHING',filter: id,page:page})
            }else{
                
                dispatch({type:'TRUCK_FUEL_LIST_FETCHING',keyword: id,page:page})
            }
            let str = VEHICLE_FUEL_FILTER.replace('VTYPE', `${tabType}/truck`)
            let post_url = str + type + `/?page=${page}&limit=15`
            const success_res = (response) => {
                if(fetchtype === 'filter' || fetchtype === 'date'){
                    dispatch({ type: 'TRUCK_FILTERED_FUEL_LIST', trucks: response, filterKey: id,page:page })
                }else{
                    dispatch({ type: 'TRUCK_FILTERED_FUEL_LIST', trucks: response, keyword: id,page:page })
                }
                // dispatch({ type: 'TRUCK_FILTERED_FUEL_LIST', trucks: resp, filterKey: id })
            }
            const failed_res = (error) => {
                dispatch({ type: 'TRUCK_FUEL_LIST_ERROR', error: error })
            }
            Api('post', post_url, data, success_res, failed_res)
                // .then(response => {
                //     if (callBk !== null) {
                //         callBk(false)
                //     }

                //     console.log(response)
                // })
        }
    }
}


// ******************************************************
//                      F L E E T
// ******************************************************


// export const fetch_fleet_list = (page, id) => {
//     return function (dispatch) {
//         let get_url = VEHICLE_FLEET_GET + id + '/' + page + '/' 
//         Api('get', get_url)
//             .then(response => {
//                 if(response) {
//                     dispatch({ type: 'TRUCK_FLEET_LIST', fleet: response, page: page })
//                 }
//             })
//     }
// }


// export const __set_fleet_vehicle = (id) => {
//     return function (dispatch) {
//         dispatch({ type: 'SET_FLEET_VEHICLE_ID', vehicle: id })
//     }
// }


// export const __select_fleet = (fleet) => {
//     return function (dispatch) {
//         dispatch({ type: 'SELECT_TRUCK_FLEET', fleet: fleet })
//     }
// }

// export const __clear_fleet_list = () => {
//     return function (dispatch) {
//         dispatch({ type: 'CLEAR_TRUCK_FLEET_LIST' })
//     }
// }




// export const __edit_fleet = (fleet_id, data, success, failed, page_no, vehicle_id) => {
//     return function (dispatch) {
//         let edit_url = `${VEHICLE_FLEET_EDIT + fleet_id}/`

//         const success_res = () => {
//             success()
//             dispatch(fetch_fleet_list(page_no, vehicle_id))
//         }
//         Api('patch-form', edit_url, data, success_res, failed)
//         .then(response => {
//             // console.log(response)
//         })
//     }
// }


// export const __create_fleet = (data, success, failed, vehicle_id) => {
//     return function (dispatch) {
//         const success_res = () => {
//             success()
//             dispatch(fetch_fleet_list(1, vehicle_id))
//         }
//         Api('post-form', VEHICLE_FLEET_ADD, data, success_res, failed)
//         .then(response => {
//             // console.log(response)
//         })
//     }
// }



// export const __delete_fleet = (fleet_id, success, failed, page_no, vehicle_id) => {
//     return function (dispatch) {
//         const success_res = () => {
//             success()
//             dispatch(fetch_fleet_list(page_no, vehicle_id))
//         }
//         let delete_url = `${VEHICLE_FLEET_DELETE + fleet_id}/`
//         Api('delete', delete_url, '', success_res, failed)
//         .then(response => {
//             // console.log(response)
//         })
//     }
// }


// ******************************************************
//           P R E I N S P E C T I O N   C H E C K
// ******************************************************

export const __create_preinspection = (tabType, data, success, failed) => {
    const success_res = () => {
        success()
    }
    Api('post-form', VEHICLE_PREINSPECTION.replace('VTYPE', `${tabType}/truck`), data, success_res, failed)
        .then(response => {
            console.log(response)
        })
}

export const edit_preinspection = (tabType, data, id, success, failed) => {
    const success_res = () => {
        success()
    }
    Api('put-json', `${VEHICLE_PREINSPECTION.replace('VTYPE', `${tabType}/truck`)}edit/${id}/`, data, success_res, failed)
        .then(response => {
            console.log(response)
        })
}

export const preinspection_truck_list = (tabType, page) => {

    return function (dispatch) {
        dispatch({type:'FETCHING_PREINSP_LIST',page:page})
        const success = (response) => {
            if (response) {
                dispatch({
                    type: 'PREINSP_LIST_SUCCESS',
                    trucks: response,
                    page: page
                })
            }
        }
        const failed = (error) => {
            if (error) {
                dispatch({ type: 'PREINSP_LIST_ERROR', error: error })
            }
        }
        Api('get', `${VEHICLE_PREINSPECTION.replace('VTYPE', `${tabType}/truck`)}${page}/?limit=15`, '', success, failed)
        // .then(response => {
        //     dispatch({
        //         type: 'FETCH_PREINSP_LIST',
        //         trucks: response, 
        //         page: page
        //     })
        //     console.log(response)
        // })
    }
}

export const fetch_filter_truck_list = (tabType, success, failed, data, type, id, page, fetchtype) => {

    if (type === 'all' && id === 'all') {
        return function (dispatch) {
            dispatch({
                type: 'TRUCK_REFRESH_FILTER'
            })
            dispatch(preinspection_truck_list(tabType, 1))
        }
    }
    else {
        return function (dispatch) {
            if(fetchtype === 'filter' || fetchtype === 'date'){
                dispatch({type:'FETCHING_PREINSP_LIST',filter: id,page: page})
            }else{
                dispatch({type:'FETCHING_PREINSP_LIST',keyword: id,page: page})
            }
            
            const success1 = (response)=>{
                if(fetchtype === 'filter' || fetchtype === 'date'){
                    dispatch({ type: 'TRUCK_FILTERED_LIST', trucks: response, filterKey: id,page: page })
                }else{
                    dispatch({ type: 'TRUCK_FILTERED_LIST', trucks: response, keyword: id,page: page })
                }
                
            }
            const failed1 =(error)=>{
                dispatch({ type: 'PREINSP_LIST_ERROR', error: error })
            }
            let str = VEHICLE_PREINSPECT_FILTER.replace('VTYPE', `${tabType}/truck`) 
            let post_url = str + type + `/?page=${page}&limit=15`
            console.log(data.get('date_time'), typeof data.get('date_time'), data.get('registration'))
            Api('post-form', post_url, data, success1, failed1)
                // .then(response => {
                //     if (callBk !== null) {
                //         callBk(false)
                //     }

                //     console.log(response)
                //     dispatch({ type: 'TRUCK_FILTERED_LIST', trucks: response, filterKey: id })
                // })
        }
    }
}


export const updateSelectedPreInspect = (obj) => {
    return function (dispatch) {
        console.log('OBJ=>',obj)
        dispatch({
            type: 'SELECTED_TRUCK_PRE-INSPECT',
            selObj: obj
        })
    }
}


// ******************************************************
//           V E H I C L E    S E A R C H
// ******************************************************

// export const vehicle_search = (query, page) => {
//    return function (dispatch) {
//         let get_url = VEHICLE_GET + 'truck/' + page + '/'
//         Api('get', VEHICLE_SEARCH)
//            .then(response => {
//                console.log(response)
//                 if(response) {
//                     dispatch({ type: 'TRUCK_LIST', trucks: response, page: page })
//                 }
//            })
//    }
// }

// export const vehicle_search = (tabType, query, type) => {

//     return function (dispatch) {
//         if(query) {
//             let formbody = new FormData()
//             formbody.append('key', query)
//             formbody.append('types', type)
//             formbody.append('tab_type', tabType)

//             Api('post',  VEHICLE_SEARCH.replace('VTYPE',`${tabType}/${type}`), formbody)
//               .then(response => {
//                 console.log(response)

//                   if(type === 'truck') {
//                     dispatch({ type: 'TRUCK_LIST', trucks: response,page: 1 })
//                   } 
//                   if(type === 'car' || type === 'utes') {
//                     dispatch({ type: 'CAR_LIST', cars: response, page: 1 })
//                   }
//                   if(type === 'fork-lift' || type === 'machinery') {
//                     dispatch({ type: 'FORKLIFT_LIST', forklifts: response,page: 1 })
//                   }

//                })
//         } else {

//             if(type === 'truck') {
//                 dispatch(fetch_truck_list(tabType, 1))
//               } 
//               if(type === 'car' || type === 'utes') {
//                 dispatch(fetch_car_list(tabType,1))
//               }
//               if(type === 'fork-lift' || type === 'machinery') {
//                 dispatch(fetch_forklift_list(tabType,1))
//               }


//         }
//     }
// }




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const truck_folders = (id, apiResponseCallBack) => {
    const req_url = VEHICLE_FOLDERS + id + '/'
    console.log(req_url)
    Api('get', req_url, '',).then(response => {
        console.log('blah', response)
        if (response) {
            apiResponseCallBack({
                response: response,
                loader: false,
                error: false,
                status: 'Folder Api Fetching Successful'
            })

        } else {
            apiResponseCallBack({
                response: [],
                loader: false,
                error: true,
                status: 'No Folder Data Found'
            })
        }
    })
}


export const truck_file_upload = (vehicle_id, data, apiResponseCallBack) => {
    const req_url = VEHICLE_FILE_UPLOAD + vehicle_id + '/'
    console.log(req_url)
    Api('post-form', req_url, data,).then(response => {
        console.log('file upload response', response.blah)
        apiResponseCallBack()
    })
}


export const truck_file_delete = (file_id, success, failed) => {
    const req_url = VEHICLE_FILE_DELETE + file_id + '/'
    console.log(req_url)
    Api('delete', req_url, '', success, failed).then(response => {
        console.log('file delete response', response)
    })
}

const failedMsg = () =>{
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
    })
}

export const fuelListCSVPrint = (tabType, filterKey='all',search_qry='', filterFromDate=null, filterToDate=null) => {
    let str = VEHICLE_FUEL_PRINT_ALL_CSV.replace('VTYPE', `${tabType}/truck`)
    let get_url = str
    if(filterKey!=='all' || search_qry!=='' || filterFromDate || filterToDate){
        get_url = get_url+'?'
    }
    if(filterKey!=='all'){
        get_url = `${get_url}registration=${filterKey}`
    }if(search_qry!==''){
        if(filterKey!=='all')
            get_url = get_url + '&'
        get_url = `${get_url}key=${search_qry}`
    }if(filterFromDate){
        if(filterKey!=='all' || search_qry!=='')
            get_url = get_url + '&'
        get_url = `${get_url}from_date=${filterFromDate}&`
    }if(filterToDate){
        if(filterKey!=='all' || search_qry!=='' || filterFromDate)
            get_url=get_url+'&'
        get_url = `${get_url}to_date=${filterToDate}`
    }

    const successMsg = (res) =>{
        FileDownloader(res, `FUEL_EXPENSE_truck`, '.csv')
    }

    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res =>
    //     FileDownloader(res, `FUEL_EXPENSE_truck`, '.csv')
    // ).catch(err =>{ 
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: 'Something went wrong!'
    //     })
    // })
}


export const mntnceListCSVPrint = (tabType, filterKey='all',maintanace_search_qry='', filterFromDate=null, filterToDate=null) => {
    let str = VEHICLE_MAINTANACE_PRINT_CSV.replace('VTYPE', `${tabType}/truck`)
    let get_url = str
    if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate || filterToDate){
        get_url = get_url+'?'
    }
    if(filterKey!=='all'){
        get_url = `${get_url}registration=${filterKey}`
    }if(maintanace_search_qry!==''){
        if(filterKey!=='all')
            get_url = get_url + '&'
        get_url = `${get_url}key=${maintanace_search_qry}`
    }if(filterFromDate){
        if(filterKey!=='all' || maintanace_search_qry!=='')
            get_url = get_url + '&'
        get_url = `${get_url}from_date=${filterFromDate}&`
    }if(filterToDate){
        if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate)
            get_url=get_url+'&'
        get_url = `${get_url}to_date=${filterToDate}`
    }
    const successMsg = (res) =>{
        FileDownloader(res, `MNTNCE_REPORT_truck`, '.csv')
    }
    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res =>
    //     FileDownloader(res, `MNTNCE_REPORT_truck`, '.csv')
    // ).catch(err =>{ 
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: 'Something went wrong!'
    //     })
    // })
}

export const preInspListCSVPrint = (tabType, filterKey='all',maintanace_search_qry='', filterFromDate=null, filterToDate=null) => {
    let str = VEHICLE_PREINSP_PRINT_CSV.replace('VTYPE', `${tabType}/truck`)
    let get_url = str
    if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate || filterToDate){
        get_url = get_url+'?'
    }
    if(filterKey!=='all'){
        get_url = `${get_url}registration=${filterKey}`
    }if(maintanace_search_qry!==''){
        if(filterKey!=='all')
            get_url = get_url + '&'
        get_url = `${get_url}key=${maintanace_search_qry}`
    }if(filterFromDate){
        if(filterKey!=='all' || maintanace_search_qry!=='')
            get_url = get_url + '&'
        get_url = `${get_url}from_date=${filterFromDate}&`
    }if(filterToDate){
        if(filterKey!=='all' || maintanace_search_qry!=='' || filterFromDate)
            get_url=get_url+'&'
        get_url = `${get_url}to_date=${filterToDate}`
    }
    const successMsg = (res) =>{
        FileDownloader(res, `PREINSP_REPORT_truck`, '.csv')
    }
    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res =>
    //     FileDownloader(res, `PREINSP_REPORT_truck`, '.csv')
    // ).catch(err =>{ 
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: 'Something went wrong!'
    //     })
    // })
}

export const masterVehicleListCSVPrint = (tabType, search_qry='') => {
    let str = VEHICLE_MASTER_PRINT_ALL_CSV.replace('VTYPE', `${tabType}`)
    let get_url = str
    if(search_qry!==''){
        get_url = `${get_url}?key=${search_qry}`
    }
    const successMsg = (res) =>{
        FileDownloader(res, `MASTER_REPORT_${tabType}`, '.csv')
    }
    Api('get', get_url, null, successMsg, failedMsg)
    // .then(res =>
    //     FileDownloader(res, `MASTER_REPORT_${tabType}`, '.csv')
    // ).catch(err =>{ 
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: 'Something went wrong!'
    //     })
    // })
}