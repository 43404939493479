import React from 'react'
import Sidebar from '../../common/sidebar'
import Header from './components/header'
import './components/style.css'
// import FolderApp from '../../common/folder/folder_main'
// import { folderData } from '../../common/folder/data'
import { NotificationList, SafetyData, NewsList } from './components'
import IntranetLayout  from '../../common/filesNFolder/FileFoldersLayout'
import { OHS_AND_S_FOLDERS, OHS_AND_S_FILE_ADD, OHS_AND_S_FILE_DELETE, OHS_AND_S_FOLDER_CREATE, OHS_AND_S_FOLDER_DELETE,
INTRANET_FOLDER_EDIT, 
INTRANET_FILES_RENAME} from '../../store/endpoint'
import Scrollbars from '../../common/ImprovedScrollBar'
import { connect } from 'react-redux'

function App({permissions}) {
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header />
                
                <Scrollbars style={{ height: '80vh' }}>
                <div className="ohs-main-layout">
                    <div style={{ marginBottom: 30 }} className="ohs-top-layout">
                        <NotificationList />
                        <SafetyData />
                        <NewsList />
                    </div>

                    {/* <div className="ohs-bottom-layout">
                        <FolderApp 
                            data={folderData}
                            updation={true}
                            updationFunction={() => {}}
                        />
                    </div> */}
                {
                    permissions.ohs.edit || permissions.ohs.add ?
                    <IntranetLayout APIURL={OHS_AND_S_FOLDERS} title={'OHS & S'} 
                    FILEEDIT    ={ permissions.ohs.edit ? INTRANET_FILES_RENAME : null }
                    FOLDEREDIT  ={ permissions.ohs.edit ? INTRANET_FOLDER_EDIT : null } 
                    FILEADD   ={ permissions.ohs.add ? OHS_AND_S_FILE_ADD : null } 
                    FOLDERCREATE={ permissions.ohs.add ? OHS_AND_S_FOLDER_CREATE : null } 
                    FOLDERDELETE={ permissions.ohs.delete ? OHS_AND_S_FOLDER_DELETE : null} 
                    FILEDELETE  ={ permissions.ohs.delete ? OHS_AND_S_FILE_DELETE : null }
                    />
                :   null
                }
                </div>
                
                {/* <Switch>
                    <Route path={path + '/waste'} component={WasteApp} />
                    <Route path={path + '/hills'} component={HillsApp} />
                    <Route path={path + '/pump'} component={PumpsApp} />
                    <Route path={path + '/destruction'} component={DestructionApp} />
                    <Redirect from={path} to={path + '/waste'} />
                </Switch> */}

                </Scrollbars>
            </div>
        </div>
    )
}

const mapStateToProps = (state) =>{
    const { permissions } = state.userLogin
    return { permissions }
}

export default connect(mapStateToProps) (App)
