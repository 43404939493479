import { CircularProgress, makeStyles, Modal } from '@material-ui/core';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import Scrollbars from '../../common/ImprovedScrollBar';
import { connect } from 'react-redux';
import { team_timeSheetLogList } from './common/actions';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

function TimeSheetAccessModal({
    modalStatus, 
    modalAction,
    timeSheetItem,
    timeSheetLogList, timeSheetLogLoader,
    teamTimeSheetLog
}) {
    const classes = useStyles();
    const [page, setPage] = useState(2)
    const [loader, setLoader] = useState(false)
    const handleClose = () => {
        modalAction(false)
    };

    const ScrollPage = () =>{
        const success = () =>{
            setLoader(false)
            setPage(page+1)
        }
        const failed = () =>{
            setLoader(false)
        }
        if(timeSheetLogList && timeSheetLogList.length===10){
            setLoader(true)
            teamTimeSheetLog(timeSheetItem.id,page,success,failed)
        }
    }


  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        <div style={{ height: '51vh', width: '50vw', padding: 20, backgroundColor: '#fff', outline: 'none', borderRadius: 20, overflowY:'auto' }}>
            <div>
                {`Timesheet ${timeSheetItem ? timeSheetItem.employee.name : 'Employee '} ${timeSheetItem ? moment(timeSheetItem.week_startdate).format('DD-MM-YYYY') : '-'} log`}
            </div>
            {
                timeSheetLogLoader  ?
                <CircularProgress />
            :
            <Scrollbars style={{ height: '47vh', marginTop: 20 }} onScroll={ScrollPage}>
                {
                    timeSheetLogList.map((item, key) => {
                        return (
                            <div
                                key={key}
                                style={{
                                    backgroundColor: '#cdcdcd', 
                                    borderRadius: 20, 
                                    marginBottom: 10,
                                    padding: 20
                                }}
                            >
                                <div style={{  
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center', fontSize: '1rem'
                                }} >
                                    <p>{item.message}</p>
                                </div>
                                
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 12 }} >
                                    <p>{moment(item.date).format('DD-MM-YYYY')}</p>
                                    <p>{item.time}</p>
                                </div>
                               
                            </div>
                            
                        )
                    })
                }
                {
                    loader ?
                    <CircularProgress />
                    : null
                }
            </Scrollbars>
            }
        </div>
      </Modal>
  )
}

const mapStateToProps = (state) =>{
    const { timeSheetLogList, timeSheetLogLoader } = state.accountsData;
    return { timeSheetLogList, timeSheetLogLoader }
  }
  
const mapDispatchToProps = (dispatch) =>{
    return{
        teamTimeSheetLog : (id, page, success, failed) => dispatch(team_timeSheetLogList(id, page, success, failed))   
    }
}
   export default connect(mapStateToProps,mapDispatchToProps) (TimeSheetAccessModal)
   
  