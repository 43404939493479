import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import { AddCircleOutlineRounded, Close } from '@material-ui/icons'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


  const InformationModal = ({
    modalStatus,
    modalAction,
    jsonObjArr,
    modalType,
    update
}) => {
    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };

    const [jsonTempKeyName, setJsonTempKeyName] = useState('')
    const [jsonTempValue, setJsonTempValue] = useState(null)
    const [jsonObjKey, setJsonObjKey]   = useState(null)
    const [addJsonKey, setAddJsonKey] = useState('')
    const [addJsonVal, setAddJsonVal] = useState(null)
    const [jsonTempObjArr, setJsonTempObjArr] = useState(jsonObjArr)
    // console.log("MODAL: ", jsonObjArr, modalStatus)
    const addKeyVal = () =>{
        var tempObj = jsonTempObjArr
        tempObj[addJsonKey] = addJsonVal
        // setJsonTempObjArr(tempObj)
        setAddJsonKey('')
        setAddJsonVal('')
    }

    const remJsonObj = (el) =>{
        var tempObj = jsonTempObjArr
        // delete tempObj[el]
        const newObj = Object.keys(tempObj).reduce((object, key) => {
            if (key !== el) {
              object[key] = tempObj[key]
            }
            return object
          }, {}) 
        setJsonTempObjArr(newObj)
        
    }

    const updateJsonKeyValue = (oldKey) =>{
        // Object.keys(jsonTempObjArr).find((key) =>  
        //     (key === oldKey))
        if(jsonTempKeyName!==''){
            var tempObj1 = jsonTempObjArr ? jsonTempObjArr : jsonObjArr
            Object.defineProperty(tempObj1, jsonTempKeyName,
                Object.getOwnPropertyDescriptor(tempObj1, oldKey));
            delete tempObj1[oldKey];
            setJsonTempObjArr(tempObj1)
        }
        if(jsonTempValue!==null){
            var tempObj2 = jsonTempObjArr
            if(jsonTempKeyName!=='')
                tempObj2[jsonTempKeyName] = jsonTempValue
            else
                tempObj2[oldKey] = jsonTempValue
            setJsonTempObjArr(tempObj2)
        }
    }
    

    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >
            <div className="intranet-folder-modal-layout" style={{ width:'30vw', overflowY:'auto' }}>
            
                <div style={{ marginBottom: 30 }} className="intranet-modal-title-layout">
                    Update Information
                    <Close onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </div>
                <Scrollbars style={{ height:'40vh' }}>
                {   
                    jsonTempObjArr && Object.keys(jsonTempObjArr).map((item, k) => {
                        return(
                            <div style={{ display: 'flex', marginTop:'20', height:'4vh', alignItems:'center' }}>
                                <p style={{ fontSize: 16, flex: 4 }}>
                                    <input type="text" value={jsonTempKeyName!==''&&jsonTempKeyName && jsonObjKey===k ? jsonTempKeyName :  item} 
                                    style={{ border:'none', fontWeight:'600' }}
                                    onChange={(e) =>{ setJsonTempKeyName(e.target.value); setJsonObjKey(k); }}/>
                                </p>
                                <p style={{ fontSize: 16, flex: 0.3 }}>:</p>
                                <div style={{ fontSize: 16, flex: 4 }}>
                                    <input type="text" value={jsonTempValue!==''&&jsonTempValue && jsonObjKey===k ? jsonTempValue : jsonTempObjArr[item]} 
                                    style={{ border:'none' }}
                                    onChange={(e) =>{ setJsonTempValue(e.target.value);  setJsonObjKey(k); } }/>
                                </div>
                    {   (jsonTempKeyName!=='' || jsonTempValue!==null) && jsonObjKey===k   ?
                    <button onClick={() => {
                        setJsonTempKeyName('')
                        setJsonTempValue('')
                        setJsonObjKey(null)
                    }} className="intranet-cancel-btn" style={{ width:'30px', margin:'1px 5px' }}><CancelIcon style={{ fontSize: 18 }}/></button>
                    :
                    <button onClick={() => {
                        remJsonObj(item)
                    }} className="intranet-cancel-btn" style={{ width:'30px', margin:'1px 5px' }}><DeleteIcon style={{ fontSize: 18, color:'var(--cl_red)' }}/></button>
                    }
                    {   (jsonTempKeyName!=='' || jsonTempValue!==null) && jsonObjKey===k   ?
                    <button 
                        onClick={() => {
                            //createAction()
                            updateJsonKeyValue(item)
                        }}
                        className="intranet-cancel-btn" style={{ width:'30px', margin:'1px 5px' }}><CheckCircleOutlineOutlined style={{ fontSize: 18, color:'var(--cl_green)' }}/></button>
                    :   null
                    }
                    </div>
                        )
                })
                              
                }
                </Scrollbars>
                {   modalType==="add"   ?
                <div style={{ display: 'flex',  height:'4vh', alignItems:'center' }}>
                    <p style={{ fontSize: 16, flex: 4 }}>
                        <input type="text" value={addJsonKey}
                        placeholder="Title"
                        onChange={(e) => setAddJsonKey(e.target.value)}/>
                    </p>
                    <p style={{ fontSize: 16, flex: 0.3 }}>:</p>
                    <div style={{ flex: 4, fontSize: 12 }}>
                        <input type="text" value={addJsonVal}
                        placeholder="Add Value"
                        onChange={(e) => setAddJsonVal(e.target.value)}/>
                    </div>
                    
                    <button onClick={() => {
                        setAddJsonKey('')
                        setAddJsonVal('')
                    }} className="intranet-cancel-btn" style={{ width:'30px', margin:'1px 5px' }}><CancelIcon style={{ fontSize: 18 }}/></button>
                    <button 
                        onClick={() => {
                            //createAction()
                            addKeyVal()
                        }}
                        className="intranet-cancel-btn" style={{ width:'30px', margin:'1px 5px' }}><AddCircleOutlineRounded  style={{ fontSize: 18, color:'var(--cl_blue)' }}/></button>
                    
                    
                
                </div>   
                :   null
                }           
                <div className="intranet-btn-layout">
                    <button onClick={() => handleClose()} className="intranet-cancel-btn">CANCEL</button>
                    
                    <button 
                        onClick={() => {
                            //createAction()
                            update(jsonTempObjArr)
                        }}
                        className="intranet-create-btn">UPDATE</button>
                    
                </div>
                
            </div>

        </Modal>
    )
}


export default InformationModal
