import React, { useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
// import { HOST } from '../../../../store/endpoint'
import { GOOGLE_API_KEY, HOME_LATITUDE, HOME_LONGITUDE } from '../../../store/config'
import {
  GoogleMap,
  Marker,
  OverlayView,
  useLoadScript,
} from "@react-google-maps/api";
import EnviroLogoLoader from '../../../assets/Images/eniviro_logo.gif'
import {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { StreetViewPanorama } from '@react-google-maps/api';
// import { team_add_file } from '../action'

const libraries = ["places"];


export function GoogleMapInit() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });

  return { status :  (loadError||!isLoaded) ? false : true , value : loadError ? 'Error' : !isLoaded ? 'Loading...' : null}
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({modalStatus, modalAction, latLngMarks, siteInfo}) {
    const classes = useStyles();

    const handleClose = () => {
        modalAction(false)
    };

    const [currVal, setCurrVal] = React.useState({ status : false, value : { lat : HOME_LATITUDE, lng : HOME_LONGITUDE } })
  // console.log('map markers', markers)

  const mapRef = useRef();
    
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  React.useEffect(() => {
    checkLatLang({ latLngMarksVal : latLngMarks, siteAddr : siteInfo&&siteInfo.address ? siteInfo.address : null })
  }, [latLngMarks, siteInfo]);
  


  const mapContainerStyle = {
    height: '100%',
    width: '100%',
    borderRadius: 10,
  };
  
  const options = {
    disableDefaultUI: true,
    fullscreenControl : false
  };

  const checkLatLang = async ({ latLngMarksVal, siteAddr}) => {

    console.log(latLngMarksVal, siteAddr, (latLngMarksVal.lat!==null && latLngMarksVal.lng!==null) || (!isNaN(latLngMarksVal.lat) && !isNaN(latLngMarksVal.lng)))
    let retVar = null
    if((latLngMarksVal.lat!==null && latLngMarksVal.lng!==null) && (!isNaN(latLngMarksVal.lat) && !isNaN(latLngMarksVal.lng))){
      console.log('FN1')
      retVar = ({ status : true, value : latLngMarksVal}) 
    }else if(siteAddr){
      console.log('FN2',siteAddr)
      try{
      const parameter = { address : siteAddr }
      const results = await getGeocode(parameter);
        const resLatLng = await getLatLng(results[0]);
        console.log('FN21',results,resLatLng)
         retVar = { status : true, value : { lat : parseFloat(resLatLng.lat), lng : parseFloat(resLatLng.lng)  }}
        // console.log(lat,lng, results)
      } catch (error) {
        console.log('FN22',error)
        retVar = { status : false, value : { lat : HOME_LATITUDE, lng : HOME_LONGITUDE  }}
    }
      
    }
    else{ 
      console.log('FN3')
      retVar = { status : false, value : { lat : HOME_LATITUDE, lng : HOME_LONGITUDE  }}
  }
    setCurrVal(retVar)

  }

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: GOOGLE_API_KEY,
  // });

  const mapInit = GoogleMapInit()


  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  
  
  // const currVal = Promise.resolve(checkLatLang({ latLngMarksVal : latLngMarks, siteAddr : siteInfo&&siteInfo.address ? siteInfo.address : null }))
  //                   .then(res => { console.log("FNPromise",res); return res} )


  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="team-cerificate-modal-layout">
                <div className="cer-modal-header-layout">
                    <p className="cer-modal-main-title">Google Street View of Site</p>
                </div>
                {console.log(currVal)}
                <div className="cer-modal-body-layout">
                    {
                      !mapInit.status ?
                        mapInit.value
                    :
                    <GoogleMap
                        id="map"
                        mapContainerStyle={mapContainerStyle}
                        ref={mapRef}
                        zoom={12}
                        options={options}
                        onLoad={onMapLoad}
                        center={latLngMarks.lat!==null&&latLngMarks.lng!==null&&!isNaN(latLngMarks.lat)&&!isNaN(latLngMarks.lng)
                           ? latLngMarks : currVal&&currVal.status ? currVal.value : { lat: HOME_LATITUDE, lng: HOME_LONGITUDE }}
                        fullscreenControl={false}
                        defaultCenter={{ lat: HOME_LATITUDE, lng: HOME_LONGITUDE }}
                    >
                    <Marker
                key={ currVal&&currVal.status ? `${currVal.value.lat}-${currVal.value.lng}` : 
                latLngMarks.lat!==null&&latLngMarks.lng!==null&&!isNaN(latLngMarks.lat)&&!isNaN(latLngMarks.lng) ?
                `${latLngMarks.lat}-${latLngMarks.lng}` : `${HOME_LATITUDE}-${HOME_LONGITUDE}`}
                position={
                  currVal&&currVal.status ?
                  { lat: currVal.value.lat, lng: currVal.value.lng } :
                   latLngMarks.lat!==null&&latLngMarks.lng!==null&&!isNaN(latLngMarks.lat)&&!isNaN(latLngMarks.lng) ?
                  { lat: latLngMarks.lat, lng: latLngMarks.lng } : { lat: HOME_LATITUDE, lng: HOME_LONGITUDE }}
                fullscreenControl={false}
              />
                      <StreetViewPanorama 
                      defaultPosition={currVal&&currVal.status ? currVal.value : 
                        latLngMarks.lat!==null&&latLngMarks.lng!==null&&!isNaN(latLngMarks.lat)&&!isNaN(latLngMarks.lng) ? latLngMarks : { lat: HOME_LATITUDE, lng: HOME_LONGITUDE }} 
                      visible 
                      options={{
                        fullscreenControl : false
                      }}
                      position={currVal&&currVal.status ? currVal.value : 
                      latLngMarks.lat!==null&&latLngMarks.lng!==null&&!isNaN(latLngMarks.lat)&&!isNaN(latLngMarks.lng) ? latLngMarks : { lat: HOME_LATITUDE, lng: HOME_LONGITUDE }} fullscreenControl={false}>
                        <OverlayView
                            position={{ lat: HOME_LATITUDE, lng: HOME_LONGITUDE }}
                            mapPaneName={OverlayView.OVERLAY_LAYER}
                            getPixelPositionOffset={getPixelPositionOffset}
                        fullscreenControl={false}
                        >
                            <div style={{ background: `var(--cl_ivery)`, color: `black`, padding: 5, borderRadius: `50%` }}>
                                <img src={EnviroLogoLoader} alt="Logo_loader" style={{ width:'20%' }} />
                            </div>
                        </OverlayView>
                      </StreetViewPanorama>
                    </GoogleMap>
                    }
                    {/* <Streetview apiKey={GOOGLE_API_KEY} 
                        streetViewPanoramaOptions={{ position : latLngMarks, fullscreenControl : false}}
                     /> */}
                     <div 
                        style={{ 
                            backgroundColor:'var(--cl_blue)',
                            width:'auto',
                            height: '8vh',
                            padding:'2%',
                            margin:'1%',
                            overflowY:'auto',
                            overflowX:'hidden',
                            borderRadius:'10px',
                            fontWeight:'400',
                            fontSize:'1rem',
                        }}
                     >
                         <p>
                             {
                                 siteInfo ? siteInfo.address : 'No Address Provided'
                             }
                         </p>
                     </div>
                     
                </div>
            </div>
      </Modal>
  );
}


export default TransitionsModal
