import { ArrowForwardIos } from '@material-ui/icons';
import React, { useState } from 'react';
import Scrollbars from '../../../../common/ImprovedScrollBar';
import { connect } from 'react-redux';
import DateTimeInput from '../../../../common/DateTimeInput'
import { editTypeOfWaste, getTypeOfWasteDataList } from '../../action';
import Swal from 'sweetalert2'
import Failerrdevmsg from '../../../../common/failErrDevMsg';
import { TextAreaLimiter } from '../../../../common/TextAreaLimiter';
// import { SITE_TYPE_OF_WASTE_LIST } from '../../../../store/endpoint';
// import Api from '../../../../store/api'
// import { CircularProgress } from '@material-ui/core';

const perm =    [   
    { name : 'DILUTION PIT',        perms : [1,0,1,1,1,1,0,0,0]},
    { name : 'DRUMS',               perms : [1,0,1,1,1,1,1,1,1]},
    { name :  'FOOD WASTE',         perms : [1,0,1,1,1,1,1,1,1]},
    { name :  'GREASE',             perms : [1,1,1,1,1,1,0,0,0]},
    { name :  'GREASE ROOM CLEAN',  perms : [1,1,1,1,1,1,0,0,0]},
    { name :  'IBC',                perms : [1,0,1,1,1,1,1,1,1]},
    { name :  'J120',               perms : [1,0,1,1,1,1,1,0,0]}, 
    { name :  'M250',               perms : [1,0,1,1,1,1,1,1,1]},
    { name :  'N205',               perms : [1,0,1,1,1,1,1,1,1]}, 
    { name :  'OIL SEPERATOR',      perms : [1,0,1,1,1,1,0,0,0]},
    { name :  'PIT WASH AND CLEAN', perms : [1,0,1,1,1,1,0,0,0]},
    { name :  'PRODUCT DESTRUCTION', perms : [1,0,1,1,1,1,1,1,1]},
    { name :  'RETENTION PITS',     perms : [1,0,1,1,1,1,0,0,0]},
    { name :  'SEWER',              perms : [1,0,1,1,1,1,0,0,0]},
    { name :  'SLUDGE',             perms : [1,0,1,1,1,1,1,0,0]},
    { name :  'STORMWATER',         perms : [1,0,1,1,1,1,0,0,0]},
    { name :  'TRANSPORT',          perms : [0,0,0,0,0,0,0,0,0]},   
    { name :  'Z140',               perms : [1,0,1,1,1,1,0,0,0]},
    { name :    '',                 perms : [1,1,1,1,1,1,1,1,1]}
]

const Typeofwaste = ({tabType, clientId, typeOfWasteObj, updateTypeOfWaste, dataObj, dataObjArr, setDataObj, setDataObjArr}) => {
    console.log('DataObj',typeOfWasteObj,)

//  const [dataObj, setDataObj]                  = useState(
//      {
//         'waste_type_str'    :   '',                      
//         'device_capacity'   :   '',                      //0
//         'barcode'           :   '',                      //1
//         'job_duration'      :   '',                      //2
//         'key_required_type_str' : 'FALSE',               //3
//         'job_status'        :   '',                      //4
//         'alarm_code'        :   '',                      //5
//         'weigh_bridge_required' :   '',                  //6
//         'pellets_to_be_exchanged_str' : 'FALSE',         //7
//         'quantity'          :   '',                      //8
//         'information'       :   '',                      
//         'pit_location'      :   '',                      
//         'access_restriction':   ''                       
//     } 
//  )
//  const [dataObjArr, setDataObjArr] = useState(
//     [{
//         'waste_type_str'    :   '',
//         'device_capacity'   :   '',
//         'barcode'           :   '',
//         'job_duration'      :   '',
//         'key_required_type_str' : 'FALSE',
//         'job_status'        :   '',
//         'alarm_code'        :   '',
//         'weigh_bridge_required' :   '',
//         'pellets_to_be_exchanged_str' : 'FALSE',
//         'quantity'          :   '',
//         'information'       :   '',
//         'pit_location'      :   '',
//         'access_restriction':   ''
//     }])

    // useEffect(() => {
    //     getTypeWasteData(clientId)
    //     Api('get',`${SITE_TYPE_OF_WASTE_LIST}details/${clientId}/`,'')
    //     .then(response => {
    //         console.log(response)
    //         if(response && response.length!==0){
                
    //             setDataObjArr(response)     
    //             setDataObj((({ id, ...o }) => o)(response[0]))       
    //         }
    //     })
                
    // }, [getTypeWasteData, clientId]);
    const [textAreaValid, setTextAreaValid] = useState(true)
 const typeOfWasteDataFn = (data) =>{
     
    if(dataObjArr.find(el => el.id===data.id)){
        let oldData = dataObjArr
        let oldDataIdx = dataObjArr.indexOf(el => el.id===data.id)
        oldData.splice(oldDataIdx, 1, data)
        setDataObjArr(Object.assign([], oldData))
    }
    else{
        setDataObjArr([...dataObjArr, data])
    }
 }

//  let selOPts = [{   name : 'AMMONIA', value : 'AMMONIA'},
//  {   name : 'DESTRUCTION CERTIFICATE', value : 'DESTRUCTION CERTIFICATE'},
//  {   name : 'DILUTION PIT', value : 'DILUTION PIT'},
//  {   name : 'DRUMS', value : 'DRUMS'},
//  {   name : 'FOOD WASTE', value : 'FOOD WASTE'},
//  {   name : 'GERNIE WASHES', value : 'GERNIE WASHES'},
//  {   name : 'GREASE', value : 'GREASE'},
//  {   name : 'GROUND WATER', value : 'GROUND WATER'},
//  {   name : 'J120', value : 'J120'}, 
//  {   name : 'LEVY', value : 'LEVY' },
//  {   name : 'M250', value :'M250'},
//  {   name : 'N205', value : 'N205'}, 
//  {   name : 'OIL SEPERATOR', value : 'OIL SEPERATOR'},
//  {   name : 'PACKAGED WASTE', value : 'PACKAGED WASTE'},
//  {   name : 'PRODUCT DESTRUCTION', value : 'PRODUCT DESTRUCTION'},
//  {   name : 'PUMP SERVICE', value : 'PUMP SERVICE'},
//  {   name : 'RETENTION PIT', value : 'RETENTION PIT'},
//  {   name : 'SEWER', value :'SEWER'},
//  {   name : 'STORMWATER', value :'STORMWATER'},
//  {   name : 'TANK WASH OUT', value : 'TANK WASH OUT'},      
//  {   name : 'TRADE WASTE', value : 'TRADE WASTE'},                                              
//  {   name : 'TRANSPORT', value : 'TRANSPORT'},  
//  {   name : 'WIEGH BRIDGE', value : 'WEIGH BRIDGE'},                          
//  {   name : 'Z140', value : 'Z140'},                            
//  { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}]
    

const typeOfWasteValid = (val, idx) => {
    // console.log('typeWaste',val,idx,perm, typeof val, typeof idx, typeof perm)
    // console.log(perm.find(el => el.name===val))
    // console.log(perm.find(el => el.name===val).perms)
    // console.log(perm.find(el => el.name===val).perms[idx])
    if(perm && perm.find(el => el.name===val))
        return perm && perm.find(el => el.name===val).perms[idx]
    else 
        return 1
}
    
    console.log('DataObj',dataObj)

    return (
            <div className="client-ctr-btm-cntr">
            {   typeOfWasteObj && typeOfWasteObj[0]==='load' && dataObj  ?
                
                <Scrollbars  autoHide style={{ display: 'flex', height: '72vh', marginBottom: '1%' }}>
                    <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Waste Service</div>
                    {
                        [...Array(20)].map((_,k)=>{
                            return(
                                <div key={k} className='textLineSkelton'>
                                    <span></span>
                                    <span></span>
                                </div>
                            )
                        })
                    }    
                </Scrollbars>
                :
                typeOfWasteObj && typeOfWasteObj[0]!=='load' && typeOfWasteObj.length!==0 && dataObj['waste_type_str']!=='' ?
                <Scrollbars  autoHide style={{ display: 'flex', height: '72vh', marginBottom: '1%' }}>
                <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Waste Service</div>

                    {/* <SelectField
                        title="Type of Waste"
                        mandatory={true}
                        options={[
                            {   name: 'Select Option', value: ''},
                            ...selOPts.filter( el => dataObjArr.some(x => x.waste_type_str===el.name))
                        ]}
                            value={dataObj['waste_type_str']}
                            cls={'selectBoxHead'}
                            onChange={val =>{
                                if(dataObjArr && dataObjArr.find(el => el['waste_type_str']===val.target.value.toUpperCase())){
                                    setDataObj(dataObjArr.find(el => el['waste_type_str']===val.target.value.toUpperCase()))
                                }
                                else{
                                    setDataObj(
                                        {
                                            'waste_type_str'    :   val.target.value.toUpperCase(),
                                            'device_capacity'   :   '',
                                            'barcode'           :   '',
                                            'job_duration'      :   '',
                                            'key_required_type_str' : 'FALSE',
                                            'job_status'        :   '',
                                            'alarm_code'        :   '',
                                            'weigh_bridge_required' :   '',
                                            'pellets_to_be_exchanged_str' : 'FALSE',
                                            'quantity'          :   '',
                                            'information'       :   '',
                                            'pit_location'      :   '',
                                            'access_restriction':   ''
                                        })
                                }
                              }}
                    /> */}
                {   typeOfWasteValid(dataObj['waste_type_str'],0) ?
                    <InputField
                        title="Capacity"
                        value={dataObj['device_capacity']}
                        mandatory={true}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['device_capacity'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)
                        }}
                    />
                :   null}
                    {/* <InputField
                        title="Frequency (weeks)"
                        mandatory={true}
                        value={Frequency}
                        onChange={val => {
                            if(val.target.value==='')
                                setFrequency('')
                            else if(!LETTERS_VALIDATION.test(val.target.value))
                                setFrequency(val.target.value.toUpperCase())
                            }} 
                    /> */}

                    {   tabType!=='pumps' && typeOfWasteValid(dataObj['waste_type_str'],1) ?
                    <InputField
                        title="Barcode"
                        value={dataObj['barcode']}
                        onChange={val =>{ 
                            
                            let oldData = dataObj
                            oldData['barcode'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)
  }}
                    />
                    :   null}

                    {/* <InputField
                        title="Last Service"
                        type="date"
                        placeholder="dd-mm-yyyy"
                        value={LastService}
                        onChange={setLastService}
                    />
                        */}
                    {/* <InputField
                        title="Next Service"
                        type="date"
                        placeholder="dd-mm-yyyy"
                        value={NextService}
                        onChange={setNextService}
                    />  */}

                    {   typeOfWasteValid(dataObj['waste_type_str'],2) ?
                    <InputField
                        title="Job Duration"
                        value={dataObj['job_duration']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['job_duration'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData) }}
                    /> : null}
                    {   typeOfWasteValid(dataObj['waste_type_str'],3) ?
                    <SelectField
                        title="Key Required Type"
                        options={[
                            { name: 'YES', value: 'TRUE' },
                            { name: 'NO', value: 'FALSE' },
                        ]}
                        value={dataObj['key_required_type_str']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['key_required_type_str'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }}
                    />
                    :   null}
                    {   typeOfWasteValid(dataObj['waste_type_str'],4) ?
                    <SelectField
                        title="Job Status"
                        options={[
                            { name : 'CALL', value : 'CALL'},
                            { name : 'CANCELLED', value : 'CANCELLED'},
                            { name : 'COVID', value : 'COVID'},
                            { name : 'ON HOLD', value : 'ON HOLD'},
                            { name : 'ONE OFF', value : 'ONE OFF'},
                            { name : 'RECURRING', value : 'RECURRING' },
                            { name : 'Select Option', value : ''},
                            { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                        ]}
                        value={dataObj['job_status']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['job_status'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }}
                    />
                    :   null}
                    {   typeOfWasteValid(dataObj['waste_type_str'],5) ?
                    <InputField
                        title={ tabType!=='pumps' ? "Alarm Code" : "Lock Box"}
                        value={dataObj['alarm_code']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['alarm_code'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }}
                    /> : null }
                    {   tabType!=='pumps' && typeOfWasteValid(dataObj['waste_type_str'],6) ?
                    <InputField
                        title="Weigh Bridge Required"
                        value={dataObj['weigh_bridge_required']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['weigh_bridge_required'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }}
                    />
                    :   null}
                    {   tabType!=='pumps' && typeOfWasteValid(dataObj['waste_type_str'],7) ?
                    <SelectField
                        title="Pallets To Be Exchanged"
                        options={[
                            { name: 'YES', value: 'TRUE' },
                            { name: 'NO', value: 'FALSE' },
                            { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                        ]}
                        value={dataObj['pellets_to_be_exchanged_str']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['pellets_to_be_exchanged_str'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }}
                    />
                    :   null}
                    {   tabType!=='pumps' && typeOfWasteValid(dataObj['waste_type_str'],8) ?
                    <InputField
                        title="Quantity"
                        value={dataObj['quantity']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['quantity'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }}
                    />
                    :   null}



                    <InputField
                        title="Additional Information"
                        type='textarea'
                        value={dataObj['information']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['information'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData) }}
                        setTextAreaValid={setTextAreaValid}
                    />
                    {   tabType!=='pumps' ?
                    <InputField
                        title="Pit Location"
                        type='textarea'
                        value={dataObj['pit_location']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['pit_location'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData)  }} setTextAreaValid={setTextAreaValid}
                    />  : null }
                    <InputField
                        title="Access Restriction"
                        type='textarea'
                        value={dataObj['access_restriction']}
                        onChange={val =>{ 
                            let oldData = dataObj
                            oldData['access_restriction'] = val.target.value.toUpperCase()
                            setDataObj(oldData);
                            typeOfWasteDataFn(oldData) }} setTextAreaValid={setTextAreaValid}
                    />
                    <div style={{ display:'flex', justifyContent:'center' }} >
                    <button className="client-ctr-btn" 
                        style={{ height:'50px', fontSize:'1rem', pointerEvents : textAreaValid ? 'auto' : 'none' }}
                        onClick={() =>{ 
                            const successFx = (res) => {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Client Edited successful!',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                    
                            const failedFx = (res) => {
                                // Swal.fire({
                                //     icon: 'error',
                                //     title: 'Oops...',
                                //     text: res.app_data ? res.app_data : 'Something went wrong!'
                                // })
                                Failerrdevmsg(res)
                    
                            }
                            let contentObj = { ...dataObj, child_id : dataObj.id }
                            updateTypeOfWaste(dataObj['waste_type_str'], clientId, contentObj, successFx, failedFx); 
                        }}>
                        <p style={{ padding: 0, margin: '0 2%' }} >Update Site Profile</p>   
                        <ArrowForwardIos style={{ padding: 0, margin: 0, marginLeft: 10, fontSize:'1rem' }} />
                    </button>
                    </div>
                </Scrollbars>
                :
                <div style={{ display: 'flex', height: '72vh', alignItems:'center', justifyContent:'center' }}>
                    <div  style={{ display: 'flex', fontSize:16, padding:'0 10px', alignItems:'center', justifyContent:'center', height:'100%', fontWeight:'600' }}>
                        No Types of Wastes Available
                    </div>
                </div>
                
                }
            </div>
    );
}

function InputField({ title, value, onChange, type = 'text', mandatory, setTextAreaValid=null }) {


    if(type==='date'){
        return (
            <div className="data-single">
                <div className="detail-headings">
                    <p className="p-0 m-0">{title}
                        {
                            mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                        }
                    </p>
                </div>
                <div className="detail-input-dot">
                    <p className="p-0 m-0">:</p>
                </div>
                <div className="detail-input-layout">

                    <DateTimeInput
                    dateValue={value}
                    dateSetFn={onChange}
                    clsName={'client-input'}
                    />
                    {/* <input
                        type={type}
                        placeholder={title}
                        className="client-input"
                        value={value}
                        onChange={onChange}
                    /> */}
                </div>
            </div>
        )
    }
    else if(type==='textarea'){
        return (
            <div className="data-single" style={{ height:'20vh', alignItems:'flex-start', marginBottom : '5vh' }}>
                <div className="detail-headings">
                    <p className="p-0 m-0">{title}
                        {
                            mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                        }
                    </p>
                </div>
                <div className="detail-input-dot">
                    <p className="p-0 m-0">:</p>
                </div>
                <div className="detail-input-layout">
                    <TextAreaLimiter isValid={setTextAreaValid}
                        configs={{
                            placeholder : title,
                            rows : '10',
                            className : "client-input",
                            style : {
                                resize:'none',
                                backgroundColor:'aliceblue'
                            },
                            value : value===null ? '' : value,
                            onChange : onChange
                        }}
                        max={1500}
                        v={value}/>
                        
                    {/* <input
                        type={type}
                        placeholder={title}
                        className="client-input"
                        value={value}
                        onChange={onChange}
                    /> */}
                </div>
            </div>
        )    
    }
    else
    return (
        <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}
                    {
                        mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                    }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <input
                    type="text"
                    placeholder={type==='number' ? `${title}(number)` :  title  }
                    className="client-input"
                    value={value===null ? '' : value}
                    onChange={onChange}
                    style={{textTransform: type==='text' ? 'uppercase' : 'none'}}
                />
            </div>
        </div>
    )
}

function SelectField({ title, value, options = [], onChange, mandatory, cls='' }) {
    return (
        <div className={`data-single ${cls}`}>
            <div className={`detail-headings ${cls}`}>
                <p className="p-0 m-0">{title}
                {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <select
                    value={value}
                    className="client-input"
                    onChange={onChange}
                >
                    {
                        options.map((item, key) => {
                            return (
                                <option key={key} value={item.value}>{item.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { typeOfWasteObj } = state.client
    const { permissions } = state.userLogin

    return { typeOfWasteObj, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTypeOfWaste   : (wasteType, id, content, success, failed) => dispatch(editTypeOfWaste(wasteType, id, content, success, failed)),
        getTypeWasteData : (id) => dispatch(getTypeOfWasteDataList(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Typeofwaste);
