import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'

export default function ImprovedScrollBar(props) {
    const [active, setActive] = useState(false)
    const [activeV, setActiveV] = useState(false)

  return (
    <Scrollbars
        {...props}
        id="improvedScroll"
        renderTrackVertical={props => <div {...props} 
            onMouseEnter={()=>setActive(true)}
            onMouseLeave={()=>setActive(false)} 
            className={`${active ? 'newVerticalTrackActive' : 'newVerticalTrack'}`}/>}
        renderTrackHorizontal={props => <div {...props} 
            onMouseEnter={()=>setActiveV(true)}
            onMouseLeave={()=>setActiveV(false)} 
            className={`${activeV ? 'newHorizontalTrackActive' : 'newHorizontalTrack'}`}/>}
        renderThumbVertical={props => <div {...props} 
            className='newVerticalTrackThumb' />}
        renderThumbHorizontal={props => <div {...props} 
            className='newHorizontalTrackThumb' />}
    />
  )
}
