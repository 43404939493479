let initialState = {
    user: null,
    permissions: null,
    authToken: null,
    error: false,
    isLoading: false,
    errorLoginMessage: null,
    isLoggedIn: false,
    fcm_token: '',
    signingOutProgress: false,
}

const user = (state = initialState, actions) => {
    switch (actions.type) {
        case "AUTH_FETCHING":
            return {
                ...state, 
                isLoading: true,
                user: null,
                errorLoginMessage: null,
            }

        case "AUTH_FETCHING_SUCCESS":
            return { 
                ...state, 
                isLoading: false, 
                error: false, 
                isLoggedIn: true,
                authToken: actions.token, 
                errorLoginMessage: null 
            }

        case "PROFILE_DATAS_FETCH":
            return { 
                ...state, 
                user: Object.assign({}, actions.user), 
            }

        case "PROFILE_PERMISSION":
            return { 
                ...state, 
                permissions: actions.permissions, 
            }

        case "SIGNIN_FETCHING_SUCCESS":
            return { 
                ...state, 
                isLoading: false, 
                error: false, 
                isLoggedIn: true,
                user: actions.user, 
                errorLoginMessage: null 
            }

        case "SIGNIN_FETCHING_FAILED":
            return { 
                ...state, 
                isLoading: false, 
                error: true, 
                user: null, 
                errorLoginMessage: actions.errorLoginMessage 
            }

        case "SIGNIN_RESET":
            return { 
                ...state, 
                user: null, 
                isLoading: false, 
                error: false, 
                errorLoginMessage: null 
            }

        case "FCM_TOKEN_SET":
            return { 
                ...state, 
                fcm_token: actions.fcm
            }

        case "SIGNINGOUTPROGRESS" : 
            return {
                ...state,
                signingOutProgress: actions.status
            }

        case "SIGNOUT":
            return { 
                ...state, 
                user: null, 
                isLoggedIn: false,
                authToken: null, 
                fcm_token: '',
                signingOutProgress: true
            }

        default:
            return state

    }
}

export default user