import React, {
    // useEffect, 
    useState } from 'react'
import { connect } from 'react-redux'
import {
    add_team_to_job, add_vehicle_to_job, fetch_Employee_avilabilities, fetch_job_list_readyForSchd, fetch_schedules,
    fetch_schedules_sorted, fetch_Vehicle_avilabilities, schedule_image_add, schedule_imageSign_add, schedule_job_delete, schedule_job_edit, fetch_Mem_schedules, fetch_Mem_schedules_sorted, fetch_OutSourced_schedules, fetch_OutSourced_schedules_sorted, schedule_video_add,
    //fetch_job_pending_schd, fetch_job_reoccur_schd
} from '../common/action'
import { AspectRatioOutlined, ZoomInOutlined, ZoomOutOutlined } from '@material-ui/icons'
// import JobModal from './jobModalB'
// import QuoteModal from '../../../common/CommonQuoteDetailModal.jsx'
// import Swal from 'sweetalert2'
import DateTimeInput from '../../../common/DateTimeInput'
// import { 
//     //SCHEDULE_ADDITIONAL_VEHICLE,
//      SCHEDULE_QUOTE_INFOS_NEW } from '../../../store/endpoint'
// import Api from '../../../store/api'
import moment from 'moment'
import './scheduleListStyle.css'
// import QuoteHistoryModalView from './quoteHistoryModal'
import { sales_job_view } from '../../jobs/action'
import Schedulecontent from './scheduleContent'
import ScheduleContentMem from './scheduleContentMem'
import { GoogleMapInit } from './StreetViewModal'
import ScheduleContentOutsourced from './scheduleContentOutsourced'

// import { fetch_job_list } from '../../sales/common/action'


function SchedulesList(
    {
        screen_type,
        schedules_list, schedulesMem_list,
        // vehicle_list,
        // schedules_page,
        fetch_schedule_list,
        fetch_filterd_schedules,
        fetch_Mem_schedule_list,
        fetch_Mem_filterd_schedules,
        fetch_Out_schedule_list,
        fetch_Out_filterd_schedules,
        // schedule_edit_job,
        // team_setting,
        // vehicle_setting,
        // delete_schedule,
        // add_image_schedule,
        // add_image_signschedule,
        // stopLoading, stopMemLoading,
        // schd_loader,
        // fetch_jobs,
        // rldSchd,
        // savedJobDataFx,
        maximize,
        setMaximize,

        // sales_job_viewFx,
        fetch_availabilityEmp,
        fetch_availabilityVeh,
        availStrDate, availEndDate, 
        isTabletWindow=false
        //job_list_weekFilter
    }) {

        const [selectedJob, setSelectedJob]         = useState('')

    const [StartDate, setStartDate]             = useState('')
    const [StartTime, setStartTime]             = useState('')
    const [EndTime, setEndTime]                 = useState('')
    const [EndDate, setEndDate]                 = useState('')

    const [primeVehicle, setPrimeVehicle]       = useState('')
    const [schduleStatus, setScheduleStatus]                = useState('')

    const [selectedTeamArray, setSelectedTeamArray]         = useState([])

    const [fromDate, setFromDate]               = useState(availStrDate === '' ? Date() : availStrDate)
    const [toDate, setToDate]                   = useState(availEndDate === '' ? Date() : availEndDate)

    // const [CommentsArr, setCommentsArr]         = useState([])
    const [invokeDateTo, setInvokeDateTo]       = useState(false)
    const [invokeDateFrom, setInvokeDateFrom]   = useState(false)
    const [scheduleLoader, setScheduleLoader]   = useState(false)
    // const [typeOfQuoteModal, setTypeOfQuoteModal]           = useState(false)

    // const [QuoteHistoryModal, setQuoteHistoryModal]         = useState(false)
    // const [quoteHistoryInfos, setQuoteHistoryInfos]         = useState([])
    const [toggleActive, setToggleActive]       = useState('v')
    const [filterLoader, setFilterLoader]       = useState(false)

    let mapInit = GoogleMapInit()
    let initial_cell_width = isTabletWindow ? 10.6 : 6;
    let zoomWidthArrVal = [isTabletWindow ? 10.6 : 6, isTabletWindow ? 20.6 : 16, isTabletWindow ? 30.6 : 26, isTabletWindow ? 40.6 : 36]
    
    let vehicles_arr = []
    let total_column_length = 0;
    let container_width = maximize ? 90 : 48;
    let one_cell_width = 5.29;
    if (((!toggleActive) && schedules_list && schedules_list.length > 0)||(toggleActive && schedulesMem_list && schedulesMem_list.length > 0)) {
        if(!toggleActive)
            vehicles_arr = schedules_list[0] && schedules_list[0].vehicles ? schedules_list[0].vehicles : [];
        else 
            vehicles_arr = schedulesMem_list[0] && schedulesMem_list[0].members ? schedulesMem_list[0].members : [];
        total_column_length = vehicles_arr.length;
        one_cell_width = isTabletWindow ? (container_width / (total_column_length+1)) : (container_width / (total_column_length));
        initial_cell_width = isTabletWindow ? one_cell_width + ((total_column_length+1) * 1 / 4) : one_cell_width;
                                                
    }

    const [zoomVal, setZoomVal] = useState(isTabletWindow ? 1 : 0);
    const [zoomWidth, setZoomWidth] = useState( isTabletWindow ? initial_cell_width : initial_cell_width<5.29 ? 6 : initial_cell_width);


    const success_sch_fx = () => {
        setScheduleLoader(false)
    }

    
    const setFromFilterDate = (val) => {
        setScheduleLoader(true)
        let filter = `from=${val}&to=${toDate}`
        if(toggleActive==='v')
            fetch_filterd_schedules(screen_type, 1, filter, [], val, toDate, setFilterLoader)
        else if(toggleActive==='m')
            fetch_Mem_filterd_schedules(screen_type, 1, filter, [], val, toDate, setFilterLoader)
        else 
            fetch_Out_filterd_schedules(screen_type, 1, filter, [], val, toDate, setFilterLoader)
        let availFilter = 'start=' + moment(val).format('YYYY-MM-DD')
        availFilter = availFilter + moment(val).format('HH:mm:ss') + '&&end=' + moment(toDate).format('YYYY-MM-DD')
        availFilter = availFilter + moment(toDate).format('HH:mm:ss')
        fetch_availabilityVeh(availFilter, screen_type)
        fetch_availabilityEmp(availFilter)
        setFromDate(val)


        setScheduleLoader(false)
        console.log("FN5")
    }
    const setToFilterDate = (val) => {
        setScheduleLoader(true)
        let filter = `from=${fromDate}&to=${val}`
        if (moment(fromDate, 'YYYY-MM-DD').isValid()) {
            console.log("FN6")
            if(toggleActive==='v')
             fetch_filterd_schedules(screen_type, 1, filter, [], fromDate, val, setFilterLoader)
            else if(toggleActive==='m')
                fetch_Mem_filterd_schedules(screen_type, 1, filter, [], fromDate, val, setFilterLoader)
            else 
                fetch_Out_filterd_schedules(screen_type, 1, filter, [], fromDate, val, setFilterLoader)

            let availFilter = 'start=' + moment(fromDate).format('YYYY-MM-DD')
            availFilter = availFilter + moment(fromDate).format('HH:mm:ss') + '&&end=' + moment(val).format('YYYY-MM-DD')
            availFilter = availFilter + moment(val).format('HH:mm:ss')
            fetch_availabilityVeh(availFilter, screen_type)
            fetch_availabilityEmp(availFilter)


        } else {
            console.log("FN7")
            if(toggleActive==='v')
                fetch_schedule_list(screen_type, val, success_sch_fx, [], setFilterLoader)
            else if(toggleActive==='m')
                fetch_Mem_schedule_list(screen_type, val, success_sch_fx, [], setFilterLoader)
            else 
                fetch_Out_schedule_list(screen_type, val, success_sch_fx, [], setFilterLoader)
            let availFilter = 'start=' + moment().format('YYYY-MM-DD')
            availFilter = availFilter + moment().format('HH:mm:ss') + '&&end=' + moment().add(1, 'day').format('YYYY-MM-DD')
            availFilter = availFilter + moment().add(1, 'day').format('HH:mm:ss')
            fetch_availabilityVeh(availFilter, screen_type)
            fetch_availabilityEmp(availFilter)


        }
         setToDate(val)
        setScheduleLoader(false)
    }


    // const openJobDetailModal = (id) => {
    //     Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`).then(res => {
    //         console.log('JobDetailModal', res.data)
    //         setQuoteInfos(res.data)
    //         setModal(false)
    //         setquoteModal(true)
    //     })
    // }

    // const reopenScheduleModal = () => {
    //     setModal(true)
    // }


    return (
        <>
            <div className="schdl-main-head">
                <p style={{ fontWeight: 'bold' }}>Schedule List </p>
                <div className='toggleDiv'>
                    <div className='toggleButton' style={{ color: toggleActive!=='v' ? 'black' : 'white' }} onClick={()=>{ setScheduleLoader(true); 
                        setToggleActive('v') }}>Vehicles</div>
                    <div className='toggleButton' style={{ color: toggleActive!=='m' ? 'black' : 'white' }} onClick={()=>{ setScheduleLoader(true);
                         setToggleActive('m'); }}>Members</div>
                    <div className='toggleButton' style={{ color: toggleActive!=='o' ? 'black' : 'white' }} onClick={()=>{ setScheduleLoader(true);
                         setToggleActive('o'); }}>Outsourced</div>
                    <div className={ toggleActive==='o' ? 'toggleButton toggleButtonActiveRight' : 
                    toggleActive==='m' ? 'toggleButton toggleButtonActiveMiddle' : 'toggleButton toggleButtonActiveLeft'}>
                        {toggleActive==='m' ? 'Members' : toggleActive==='v' ? 'Vehicles' : 'Outsourced'}
                    </div>
                </div>
                <div className="shdl-sortings-main-layout" style={{ padding: '0 10px' }}>
                    <p style={{ padding: '0 15px' }}>From : </p>
                    {
                        invokeDateFrom ?
                            <DateTimeInput
                                dateValue={fromDate}
                                dateSetFn={setFromFilterDate}

                            /> :
                            <p style={{ cursor: 'pointer' }} onClick={() => setInvokeDateFrom(true)}>Select Date</p>
                    }
                    {/* <input 
                    value={fromDate}
                    type="date" 
                    placeholder="yyyy-mm-dd"
                    onChange={val => {
                        let date = val.target.value
                        if(date && toDate) {
                            let filter = `from=${toDate}&to=${date}`
                            fetch_filterd_schedules(screen_type, 1, filter)
                        }
                        setFromDate(date)
                    }}
                /> */}

                    <p style={{ padding: '0 15px' }}>To : </p>
                    {
                        invokeDateTo ?
                            <DateTimeInput
                                dateValue={toDate}
                                dateSetFn={setToFilterDate}
                            /> :
                            <p style={{ cursor: 'pointer' }} onClick={() => setInvokeDateTo(true)}>Select Date</p>
                    }
                    {/* <input 
                    value={toDate}
                    type="date" 
                    placeholder="yyyy-mm-dd"
                    onChange={val => {
                        let date = val.target.value
                        if(fromDate && date) {
                            let filter = `from=${date}&to=${fromDate}`
                            fetch_filterd_schedules(screen_type, 1, filter)
                        }
                        setToDate(date)
                    }}
                /> */}

                    {
                        (invokeDateFrom || invokeDateTo) && (
                            <button
                                onClick={() => {
                                    let filter = 'start=' + moment().format('YYYY-MM-DD')
                                    filter = filter + moment().format('HH:mm:ss') + '&&end=' + moment().add(1, 'day').format('YYYY-MM-DD')
                                    filter = filter + moment().add(1, 'day').format('HH:mm:ss')
                                    if(toggleActive==='v')
                                        fetch_schedule_list(screen_type, 1, success_sch_fx, [], setFilterLoader)
                                    else if(toggleActive==='m') 
                                        fetch_Mem_schedule_list(screen_type, 1, success_sch_fx, [], setFilterLoader)
                                    else 
                                        fetch_OutSourced_schedules(screen_type, 1, success_sch_fx, [], setFilterLoader)
                                        fetch_availabilityVeh(filter, screen_type)
                                    fetch_availabilityEmp(filter)
                                    setFromDate('')
                                    setToDate('')
                                    setInvokeDateTo(false)
                                    setInvokeDateFrom(false)

                                }} style={{ marginLeft: '10px' }}
                            >Clear</button>
                        )
                    }

                    {
                        maximize && (
                            <>
                                {(window.innerWidth < 1200 && zoomVal > 1) || (window.innerWidth >= 1200 && zoomVal > 0) ?
                                    <ZoomOutOutlined
                                        onClick={() => {
                                            if(window.innerWidth < 1200){
                                                if (zoomVal > 1) {
                                                    let zoom_value = zoomVal - 1;
                                                    setZoomVal(zoom_value);
                                                    let total_number_cells = total_column_length + 1;
                                                    let one_cell_width = (container_width / (total_number_cells));
                                                    let defined_width = one_cell_width + (total_number_cells * zoom_value / 4);
                                                    // setZoomWidth(defined_width<5.29 ? 5.29 : defined_width);
                                                    setZoomWidth(defined_width<5.29 ? 6 : defined_width > 36 ?
                                                        zoomWidthArrVal[zoom_value] : defined_width)

                                                }
                                            }
                                            else if (zoomVal > 0) {
                                                let zoom_value = zoomVal - 1;
                                                setZoomVal(zoom_value);
                                                let total_number_cells = total_column_length + 1;
                                                let one_cell_width = (container_width / (total_number_cells));
                                                let defined_width = one_cell_width + (total_number_cells * zoom_value / 4);
                                                // setZoomWidth(defined_width<5.29 ? 5.29 : defined_width);
                                                setZoomWidth(defined_width<5.29 ? 6 : defined_width > 36 ?
                                                    zoomWidthArrVal[zoom_value] : defined_width)
                                            }
                                        }}
                                        style={{ marginLeft: '20px', color: 'black', fontSize: 25, cursor: 'pointer' }} />
                                    : null}
                                {zoomVal < 2 ?
                                    <ZoomInOutlined
                                        onClick={() => {
                                            if (zoomVal < 2) {
                                                let zoom_value = zoomVal + 1;
                                                setZoomVal(zoom_value);
                                                // setZoomWidth(zoomWidth + 100);
                                                let total_number_cells = total_column_length + 1;
                                                let one_cell_width = (container_width / (total_number_cells));
                                                let defined_width = one_cell_width + (total_number_cells * zoom_value / 4);
                                                // setZoomWidth(defined_width<5.29 ? 5.29 : defined_width);
                                                setZoomWidth(defined_width<5.29 ? 6 : defined_width > 36 ?
                                                    zoomWidthArrVal[zoom_value] : defined_width)
                                            }
                                        }}
                                        style={{ marginLeft: '20px', color: 'black', fontSize: 25, cursor: 'pointer' }} />
                                    : null}
                            </>
                        )
                    }



                    {/* {   magnify > 0 && maximize ?
                <ZoomOutOutlined  
                    onClick={() => setMagnify(magnify-40)} 
                    style={{ marginLeft: '20px', color: 'black', fontSize: 25, cursor: 'pointer' }}/>
                :   null
                } */}


                    {/* {   magnify < 100 && maximize?
                <ZoomInOutlined  onClick={() => setMagnify(magnify+40)} style={{ marginLeft: '20px', color: 'black', fontSize: 25, cursor: 'pointer' }}/>
                :   null
                } */}
                    {
                        window.innerWidth >= 1200 ? 
                        maximize ? (
                            <AspectRatioOutlined onClick={() =>{
                                setZoomWidth(5.29)
                                setMaximize(false)}} style={{ marginLeft: '20px', color: 'black', fontSize: 18, cursor: 'pointer' }} />
                        ) : (
                            <AspectRatioOutlined onClick={() =>{ 
                                 setMaximize(true)}} style={{ marginLeft: '20px', color: 'grey', fontSize: 18, cursor: 'pointer' }} />
                        )
                        :   null
                    }
                </div>



            </div>

            {
                toggleActive==='m' ?
                <ScheduleContentMem 
                scheduleLoader={scheduleLoader} 
                setScheduleLoader={setScheduleLoader}
                invokeDateFrom={invokeDateFrom} 
                invokeDateTo={invokeDateTo} 
                toDate={toDate} 
                fromDate={fromDate} 
                zoomVal={zoomVal}
                maximize={maximize}
                container_width={container_width}
                //applyButtonDisabled={applyButtonDisabled} 
                zoomWidth={isFinite(zoomWidth) ? zoomWidth : 5.29} 
                success_sch_fx={success_sch_fx}
                StartDate={StartDate} 
                StartTime={StartTime} 
                EndDate={EndDate} 
                EndTime={EndTime} 
                setStartDate={setStartDate} 
                setStartTime={setStartTime} 
                setEndTime={setEndTime} 
                setEndDate={setEndDate}
                primeVehicle={primeVehicle}    
                setPrimeVehicle={setPrimeVehicle} 
                screen_type={screen_type}
                setScheduleStatus={setScheduleStatus} 
                schduleStatus={schduleStatus}
                selectedTeamArray={selectedTeamArray} 
                setSelectedTeamArray={setSelectedTeamArray} 
                selectedJob={selectedJob} 
                setSelectedJob={setSelectedJob}
                setFromFilterDate={setFromFilterDate}
                setToFilterDate={setToFilterDate}
                filterLoader={filterLoader}
                mapInit={mapInit}
                />
                :   toggleActive==='v' ?
                <Schedulecontent 
                scheduleLoader={scheduleLoader} 
                setScheduleLoader={setScheduleLoader}
                invokeDateFrom={invokeDateFrom} 
                invokeDateTo={invokeDateTo} 
                toDate={toDate} 
                fromDate={fromDate} 
                zoomVal={zoomVal}
                maximize={maximize}
                container_width={container_width}
                //applyButtonDisabled={applyButtonDisabled} 
                zoomWidth={isFinite(zoomWidth) ? zoomWidth : 5.29} 
                success_sch_fx={success_sch_fx}
                StartDate={StartDate} 
                StartTime={StartTime} 
                EndDate={EndDate} 
                EndTime={EndTime} 
                setStartDate={setStartDate} 
                setStartTime={setStartTime} 
                setEndTime={setEndTime} 
                setEndDate={setEndDate}
                primeVehicle={primeVehicle}    
                setPrimeVehicle={setPrimeVehicle} 
                screen_type={screen_type}
                setScheduleStatus={setScheduleStatus} 
                schduleStatus={schduleStatus}
                selectedTeamArray={selectedTeamArray} 
                setSelectedTeamArray={setSelectedTeamArray} 
                selectedJob={selectedJob} 
                setSelectedJob={setSelectedJob}
                setFromFilterDate={setFromFilterDate}
                setToFilterDate={setToFilterDate}
                filterLoader={filterLoader} 
                mapInit={mapInit}
                />
                :
                <ScheduleContentOutsourced 
                scheduleLoader={scheduleLoader} 
                setScheduleLoader={setScheduleLoader}
                invokeDateFrom={invokeDateFrom} 
                invokeDateTo={invokeDateTo} 
                toDate={toDate} 
                fromDate={fromDate} 
                zoomVal={zoomVal}
                maximize={maximize}
                container_width={container_width}
                //applyButtonDisabled={applyButtonDisabled} 
                zoomWidth={isFinite(zoomWidth) ? zoomWidth : 5.29} 
                success_sch_fx={success_sch_fx}
                StartDate={StartDate} 
                StartTime={StartTime} 
                EndDate={EndDate} 
                EndTime={EndTime} 
                setStartDate={setStartDate} 
                setStartTime={setStartTime} 
                setEndTime={setEndTime} 
                setEndDate={setEndDate}
                primeVehicle={primeVehicle}    
                setPrimeVehicle={setPrimeVehicle} 
                screen_type={screen_type}
                setScheduleStatus={setScheduleStatus} 
                schduleStatus={schduleStatus}
                selectedTeamArray={selectedTeamArray} 
                setSelectedTeamArray={setSelectedTeamArray} 
                selectedJob={selectedJob} 
                setSelectedJob={setSelectedJob}
                setFromFilterDate={setFromFilterDate}
                setToFilterDate={setToFilterDate}
                filterLoader={filterLoader} 
                mapInit={mapInit}
                />
                
                
            }

            <div className="shdl-main-footer">
                {/* <div className="shdl-pagination-layout">
            {
                schedules_page > 1 ? (
                    <div 
                    onClick={() => {
                        fetch_schedules(screen_type, schedules_page - 1)

                        if(fromDate && toDate) {
                            let filter = `from=${fromDate}&to=${toDate}`
                            fetch_filterd_schedules(screen_type, schedules_page - 1, filter)
                        } else {
                            fetch_schedules(screen_type, schedules_page - 1, success_sch_fx, [])
                        }
                    }} 
                    className="shdl-pagi-a">
                        <ArrowBack style={{ fontSize: 16 }} />
                    </div>
                ) : (
                    <div className="shdl-pagi-a disabled-btn">
                        <ArrowBack style={{ fontSize: 16 }} />
                    </div>
                )
            }
            <div className="shdl-pagi-b">
                <p>{schedules_page}</p>
            </div>
            {
                schedules_list.length === 20 ? (
                    <div 
                    onClick={() => {
                        if(fromDate && toDate) {
                            let filter = `from=${fromDate}&to=${toDate}`
                            fetch_filterd_schedules(screen_type, schedules_page + 1, filter)
                        } else {
                            fetch_schedules(screen_type, schedules_page + 1)
                        }
                    }} 
                    className="shdl-pagi-a">
                        <ArrowForward style={{ fontSize: 16 }} />
                    </div>
                ) : (
                    <div className="shdl-pagi-a disabled-btn">
                        <ArrowForward style={{ fontSize: 16 }} />
                    </div>
                )
            }
            
            </div> */}
            </div>
            
        </>
    )
}

const mapStateToProps = (state) => {
    const { schedules_list, schedulesMem_list, schedules_page, schedulesMem_page,
         vehicle_list, stopLoading, stopMemLoading, schd_loader, schdMem_loader,
          availStrDate, availEndDate, availMemStrDate, availMemEndDate, job_list_weekFilter, jobTab } = state.schedule
    return { schedules_list, schedules_page, vehicle_list, stopLoading, schd_loader, availStrDate, availEndDate, job_list_weekFilter,
        schedulesMem_list, schedulesMem_page, stopMemLoading, schdMem_loader, availMemStrDate, availMemEndDate, jobTab }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_schedule_list         : (type, page, success, prevData, fallBackFn) => dispatch(fetch_schedules(type, page, success, prevData, fallBackFn)),
        fetch_Mem_schedule_list     : (type, page, success, prevData, fallBackFn) => dispatch(fetch_Mem_schedules(type, page, success, prevData, fallBackFn)),
        fetch_filterd_schedules     : (type, page, filter, prevData, StartDate, EndDate, fallBackFn) => dispatch(fetch_schedules_sorted(type, page, filter, prevData, StartDate, EndDate, fallBackFn)),
        fetch_Mem_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fallBackFn) => dispatch(fetch_Mem_schedules_sorted(type, page, filter, prevData, StartDate, EndDate, fallBackFn)),
        fetch_Out_schedule_list     : (type, page, success, prevData, fallBackFn) => dispatch(fetch_OutSourced_schedules(type, page, success, prevData, fallBackFn)),
        fetch_Out_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fallBackFn) => dispatch(fetch_OutSourced_schedules_sorted(type, page, filter, prevData, StartDate, EndDate, fallBackFn)),


        schedule_edit_job           : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_setting                : (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        vehicle_setting             : (screen_type, data, primeData, shdId, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, success, failed)),
        delete_schedule             : (type, data, schType, success, failed) => dispatch(schedule_job_delete(type, data, schType, success, failed)),
        add_image_schedule          : (type, data, schType, success, failed) => dispatch(schedule_image_add(type, data, schType, success, failed)),
        add_video_schedule          : (type, data, schType, success, failed) => dispatch(schedule_video_add(type, data, schType, success, failed)),
        add_image_signschedule      : (type, data, schType, success, failed) => dispatch(schedule_imageSign_add(type, data, schType, success, failed)),
        //  fetch_jobs: (type) => dispatch(fetch_job_list(type,1)) ,

        fetch_jobs                  : (type, frmd, jobTab) => { dispatch(fetch_job_list_readyForSchd(type, 1, frmd, jobTab)); },

        fetch_availabilityVeh       : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp       : (filter) => dispatch(fetch_Employee_avilabilities(filter)),
        rldSchd                     : () => dispatch({ type: 'RELOAD_SCHD_LIST' }),
        sales_job_viewFx            : (id) => dispatch(sales_job_view(id)),
        savedJobDataFx              : (dataObj) => dispatch({ type: "SAVING_JOBDATA_TEMP", data: dataObj })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SchedulesList)
