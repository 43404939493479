import React from 'react'
import './sales_tableStyle.css';

function SalesHomeList({children}) {
    return (
        <div className="sales-home-list-layout">
            {children}
        </div>
    )
}

export default SalesHomeList
