import axios from 'axios';
import { store } from '../../../../store/store'

import React, { useState, useEffect } from 'react';
import { CLIENT_SEARCH, DELETED_CLIENT_SEARCH, TEMP_CLIENT_SEARCH } from '../../../../store/endpoint';
import { useDispatch } from 'react-redux';
import { fetch_clients_paginated, reloadPage } from '../../action';
import { CircularProgress } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';

function SearchTab({tabVal , tabType, sortType, ind_sort=null}) {

    const [searchTxt, setSearchTxt] = useState('')
    const [loadSearch, setLoadSearch] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        const request = axios.CancelToken.source()
        let data = new FormData()
        data.append('key',searchTxt)
        // console.log("searching user",searchTxt,request)
        if(searchTxt!=='' || searchTxt){
        
        let state = store.getState()
        let token = state.userLogin.authToken
        
        var config = {
            method: 'post',
            url: tabVal===0 ? `${CLIENT_SEARCH}${tabType}/` : tabVal===1 ? `${TEMP_CLIENT_SEARCH}${tabType}/` : `${DELETED_CLIENT_SEARCH}${tabType}/`,
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

            axios(config)
            .then((res) => {
                console.log("SEARCH",res)
                setLoadSearch(false)

                dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: res.data, clientType: tabVal===0 ? 'perm' : tabVal===1 ? 'temp' : 'deleted', clientPage: 1})
            })
            .catch((error) => {
                setLoadSearch(false)

                console.log(error);
            });
        }
        else{
            setLoadSearch(false)
            // dispatch(fetch_clients_paginated(sortType, tabType, 1, tabVal===0 ? 'perm' : tabVal===1 ? 'temp' : 'deleted', ind_sort))
        }
        
        return () => request.cancel()

    }, [searchTxt, tabVal, dispatch, tabType, sortType, ind_sort])


    return (
        <div className="search-bar-layout">
        
        <input 
            onChange={txt => {
                reloadPage()
                setSearchTxt(txt.target.value)
                setLoadSearch(true)

            }}
            className="search-input"
            placeholder="Site Name"
        />
        {
            searchTxt==='' ?
        <div className="search-btn" onClick={()=>{ 
            dispatch(fetch_clients_paginated(sortType, tabType, 1, tabVal===0 ? 'perm' : tabVal===1 ? 'temp' : 'deleted', ind_sort))
         }}>
            { loadSearch ?
            <CircularProgress size='20px' color='inherit' />
            :
            <p className="p-0 m-0">Clear</p>
            }
        </div>
        :
        <div className="search-btn">
            { loadSearch ?
            <CircularProgress size='20px' color='inherit' />
            :
            <p className="p-0 m-0">Search</p>
            }
        </div>
        }
        <div className="search-btn-thinnerScreen">
            { loadSearch ?
            <CircularProgress size='20px' color='inherit' />
            :
            <SearchRounded/>
            }
        </div>
        </div>
    );
}

export default SearchTab;