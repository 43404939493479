import React, { useState } from 'react'

import SendIcon from '@material-ui/icons/Send';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { //Modal
    CircularProgress, IconButton } from '@material-ui/core'
import { //connect, 
    useDispatch } from 'react-redux'

import Swal from 'sweetalert2'
import { cl_green, cl_red } from '../../../common/Colors'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';

import { Fragment } from 'react';
import { HOST_WITHOUT_SLASH, SCHEDULE_ADD_VIDEO, SCHEDULE_COMMENT, SCHEDULE_IMAGE_MULTIPLE } from '../../../store/endpoint';
import Api from '../../../store/api'
import DateTimeInput from '../../../common/DateTimeInput';
// import moment from 'moment'
// import { editAfterCompleteElements, fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../common/action';

import { AddPhotoAlternateRounded, CheckCircleRounded, Edit, ExpandLess, ExpandMore } from '@material-ui/icons'
import SignaturePadModal from '../../../common/SignaturePadModal';
import VideoFilePreview, { IsVideoFileUpload } from '../../../common/VideoFilePreview';
import moment from 'moment';
import { sales_job_view } from '../../jobs/action';


export default function JobModalBRightSidePanel({
    // selectedJob,

    scheduleId,

    schduleStatus,
    // setScheduleStatus,

    // addScheduleImg,
    // addScheduleSignImg,

    CommentsArr,
    setCommentsArr,
    fetch_schedule_list,
    screen_type,
    prevData,

    selected_job,

    modalAction,
    // handleClose,

    jobStatusTime, setJobStatusTime,
    chngWasteType, setChngWasteType,
    chngWasteLtr, setChngWasteLtr,
    ponumber, setPonumber,
    signName, setSignName,

    signImage, setSignImage, after_pics, setAfterPics, before_pics, setBeforePics,
    videoGallery, setvideoGallery, gallery_pics, setGalleryPics,
    jobBRStatus
}) {

    console.log('JOBSTATUSTIME=>',jobStatusTime)
    console.log('JOBATAT')
    const { id, schedule } = selected_job
    const dispatch = useDispatch()
    
    const [TheComment, setTheComment] = useState('')
    
    const [galleryLoader, setGalleryLoader]     = useState(false)
    const [vgalleryLoader, setVGalleryLoader]     = useState(false)
    const [signLoader, setSignLoader]     = useState(false)
    const [signModal, setSignModal] = useState(false)
    
    const [openGallery, setOpenGallery] = useState(false)
    const [openVidGallery, setOpenVidGallery] = useState(false)
    const [openCommentSection, setOpenCommentSection] = useState(false)
    const [openTime, setOpenTime] = useState(null)

    const [commentEditId, setCommentEditId] = useState(null)

    const [delImgArr, setDelImgArr] = useState([])

    const addScheduleImg = (val, setLoader, status=1) =>{
        console.log('val',val)
        
        setLoader(true)
                
        if(status===2){
            setAfterPics([...after_pics,...val])            
        }else if(status===1){
            setBeforePics([...before_pics,...val])
        }else{
            setGalleryPics([...gallery_pics,...val])
        }
        if(jobBRStatus)
            jobBRStatus(true)
        setLoader(false)
    }
    const addScheduleSignImg = (e) =>{
        setSignLoader(true)
        for (let i = 0; i < e.length; i++) {
            let imgData = e[i]
            setSignImage(imgData)
            // let imgName = imgData.name
            // data.append('image', imgData, imgName)
        }
        if(jobBRStatus)
            jobBRStatus(true)
        setSignLoader(false)
    }

    const addNewComment = () => {
        const success = (res) => {
            let arr = CommentsArr
            arr.unshift(res.comment)
            setCommentsArr(arr)
            setTheComment('')
            fetch_schedule_list(screen_type, 1, null, prevData)
        }

        const failed = (res) => {
            modalAction(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
            modalAction(true)
        }

        let data = new FormData()
        data.append('schedule_id', scheduleId)
        data.append('comment', TheComment)
        Api('post-form', SCHEDULE_COMMENT, data, success, failed)
    }


    const deleteComment = (id) => {
        const success = (res) => {
            let temp_item = CommentsArr
            
            let itemObject = temp_item.filter(valz => valz.id !== id)
            setCommentsArr(itemObject)
            fetch_schedule_list(screen_type, 1, null, prevData)
        }

        const failed = (res) => {
            modalAction(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
            modalAction(true)
        }

        let delete_url = SCHEDULE_COMMENT + id + '/'

        Api('delete', delete_url, '', success, failed)
    }

    const editComment = (val) => {
        console.log('EDITCOMMENT=>',val)
        console.log('COMMents',CommentsArr)
        const success = (res) => {
            console.log('commentRes=>',res)
            let temp_item = CommentsArr
            let resObj = res?.data?.comments[0]
            
            let itemObject = temp_item.findIndex(valz => valz.id === val)
            temp_item[itemObject] = resObj
            console.log('NEWARR',itemObject,temp_item)
            // let arr = res && res?.data?.comments
            setCommentsArr(temp_item)
            setTheComment('')
            fetch_schedule_list(screen_type, 1, null, prevData)
            setCommentEditId(null)
        }

        const failed = (res) => {
            modalAction(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
            modalAction(true)
        }

        let delete_url = SCHEDULE_COMMENT + val + '/'

        let data = new FormData()
        data.append('schedule_id', scheduleId)
        data.append('comment', TheComment)
        Api('patch-form', delete_url, data, success, failed)
    }

    const deleteImageApi = () => {
        const success = () => {
            modalAction(true)
            dispatch(sales_job_view(id))
        }

        const failed = (res) => {
            modalAction(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
            modalAction(true)
        }

        let data = new FormData()
        data.append('id', selected_job?.schedule?.id)
        for (let i = 0; i < delImgArr.length; ++i)
            data.append('images_to_delete', delImgArr[i])

        let delete_url = SCHEDULE_IMAGE_MULTIPLE 
        Api('post-form', delete_url, data, success, failed)
    }

    const deleteVideoApi = (val) => {
        let form_data = new FormData()
        form_data.append('job_video_id',val)
                
        Swal.fire({
            title: 'Are you sure?',
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes, delete it!`
        }).then((result) => {
            const success = () => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Video deletion successful!`,
                    showConfirmButton: false,
                    timer: 1500
                })

                modalAction(true)
                dispatch(sales_job_view(id))
    
            }

            const failed = (res) => {
                modalAction(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
            modalAction(true)

            }

            if (result.value) {

                let delete_url = SCHEDULE_ADD_VIDEO + 'delete/'
                Api('post', delete_url, form_data, success, failed)            }
        })

    }

    const checkStatusLevel = (v) =>{
        const checkIfTimeAdded = (tm) =>{
            console.log('checkiftimeadded',tm,moment(`${tm}`).isValid())
            return tm && moment(`${tm}`).isValid()
        }
        console.log('checkStatusLevel',jobStatusTime)
        let obj = {
            "pending"                       :   0,
            'departed_enviro_facility'      :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.depart_enviro_facility) ? 'was Departing from Enviro Facility' : 0,
            'job_started'                   :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.start_job)              ? 'was Starting the Job' : 0,
            'job_finished'                  :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.finish_job)             ? 'had Finished the Job' : 0,
            "completed"                     :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.completed)              ? 'had Completed the Job' : 0,
            'arrived_at_waste_depot'        :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.arrive_at_waste_depot)  ? 'Arrived at the Waste Depot' : 0,
            'departed_waste_depot'          :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.depart_waste_depot)     ? 'was Departing from the Waste Depot' : 0,
            'arrived_at_enviro_facility'    :   jobStatusTime && !checkIfTimeAdded(jobStatusTime?.arrive_enviro_facility) ? 'Arrived at the Enviro Facility' : 0
        }
        return obj[v] ? `Please enter a valid time for when the Primary Driver ${obj[v]}` : v==='pending' ? 'Time is not updated' : 
        'A valid time is not entered for current Job Status'
    }


  return (
    <div className="shdl-right-layout">
    <Scrollbars>     
    <div className="shdl-comment-layout"
        style={{ backgroundColor: !openCommentSection ? 'rgba(172, 191, 231, 0.2)' : 'rgba(0,0,0,0.2)',  
            marginTop:'5%',
            borderBottom: !openCommentSection ? 'none' : '1px solid white', 
            height: openCommentSection ? '1vh' : 'auto',
            maxHeight: '35vh' }} >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
            <p style={{ fontWeight: 'bold', fontSize: 14 }}>Comments</p>
            <div>
                {
                    openCommentSection ?
                        <ExpandMore  style={{ cursor: 'pointer' }} onClick={()=>{ setOpenCommentSection(false) }}/>
                    :
                        <ExpandLess  style={{ cursor: 'pointer' }} onClick={()=>{ setOpenCommentSection(true) }} />
                }
            </div>
        </div>
       {
            openCommentSection ?
            null :
            <>
            <div className="shdl-comment-main">
           <input 
            type="text"
            placeholder="Comment here"
            value={TheComment}
            onChange={txt => setTheComment(txt.target.value)}
           />
           <IconButton onClick={() =>{

            if(commentEditId)
                editComment(commentEditId)
            else
                addNewComment()
                }} >
                <SendIcon />
           </IconButton>
       </div>

       
            {
                CommentsArr && CommentsArr?.length>0 ? 
                <Scrollbars style={{ height: '15vh'}}>
                    {
                CommentsArr.map((item, key) => {
                    const { //author, 
                        comment, created_by, id } = item

                    // if(author) {
                    //     return (
                    //         <Fragment key={key}>
                    //             <div className="shdl-comnt-righting">
                    //                 <div className="shdl-comnt-box-layout">
                    //                     <p>{comment}</p>
                    //                     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    //                         <p style={{ fontSize: 9, fontWeight: 'bold', width:'90%' }}>{created_by}</p>
                    //                         <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    //                             <Edit onClick={() =>{
                    //                                 setTheComment(comment)
                    //                                 setCommentEditId(id)
                                                    
                    //                                 }} style={{ fontSize: 15, marginRight: 10, cursor: 'pointer' }} />
                    //                             <DeleteIcon onClick={() => deleteComment(id)} style={{ fontSize: 15, marginRight: 10, cursor: 'pointer' }} />
                    //                         </div>
                    //                     </div>
                                        
                    //                 </div>
                    //             </div>
                    //         </Fragment>
                    //     )

                    // } else {
                        return (
                            <Fragment key={key}>
                                <div className="shdl-comnt-lefing">
                                    <div className="shdl-comnt-box-layout">
                                        <p>{comment}</p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <p style={{ fontSize: 9, fontWeight: 'bold', width:'90%' }}>{created_by}</p>
                                            <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                                                <Edit onClick={() => {
                                                    setTheComment(comment)
                                                    setCommentEditId(id)
                                                    
                                                    }} style={{ fontSize: 15, marginRight: 10, cursor: 'pointer' }} />
                                                <DeleteIcon onClick={() => deleteComment(id)} style={{ fontSize: 15, marginRight: 10, cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    // }
                })
            }

       </Scrollbars>
                : null
            }
    </>
       }
       
    </div>
    <div className='shdl-cmpltd-jobStatusTime' style={{ position: 'relative' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 14 }}>Job Status Times</p>
                        {
                            //checkStatusLevel(schduleStatus)!==0 &&
                            <div className='shdl-cmpltd-jobStatusTime-informant'>
                                {checkStatusLevel(schduleStatus)}
                            </div>
                        }
                        <div className='shdl-cmpltd-jobStatus-list'>
                            <div className='shdl-cmpltd-jobStatus'>
                                <p style={{ width:'50%', fontSize: 14 }}>Departed Enviro Facility</p>
                                <span style={{ fontSize: 14 }}>:</span>
                                {
                                    openTime === 1 ?
                                    <DateTimeInput
                                    pickerType="time"
                                    openEdit={openTime===1}
                                    format="hh:mm a"
                                    dateValue={jobStatusTime.depart_enviro_facility}
                                    dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, depart_enviro_facility : e})}
                                    clsName={'client-input'}
                                    acceptFunc={()=>{setOpenTime(null)}}
                                    cancelFunc={()=>{setOpenTime(null)}}
                                    />
                                    :
                                    <p onClick={()=> setOpenTime(1)}
                                        style={{ borderBottom :  
                                            moment(jobStatusTime.depart_enviro_facility).isValid() ?
                                                '1px solid black'   :   "none" }}
                                    >{moment(jobStatusTime.depart_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ?
                                        moment(jobStatusTime.depart_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                                        : '12:00 am'}</p>
                                }
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Job Started</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            {
                                openTime === 2 ?
                            
                            <DateTimeInput
                                pickerType="time"
                                openEdit={openTime===2}
                                format="hh:mm a"
                                dateValue={jobStatusTime.start_job}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, start_job : e})}
                                clsName={'client-input'}
                                acceptFunc={()=>{setOpenTime(null)}}
                                cancelFunc={()=>{setOpenTime(null)}}
                            />
                            :
                            <p onClick={()=> setOpenTime(2)}
                            style={{ borderBottom :  
                                            moment(jobStatusTime.start_job).isValid() ?
                                                '1px solid black'   :   "none" }}
                            >{moment(jobStatusTime.start_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ? 
                            moment(jobStatusTime.start_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                            : '12:00 am'}</p>
                        }
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Job Finished</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            {
                                openTime === 3 ?
                            
                            <DateTimeInput
                                pickerType="time"
                                openEdit={openTime===3}
                                format="hh:mm a"
                                dateValue={jobStatusTime.finish_job}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, finish_job : e})}
                                clsName={'client-input'}
                                acceptFunc={()=>{setOpenTime(null)}}
                                cancelFunc={()=>{setOpenTime(null)}}
                            />
                            :
                            <p onClick={()=> setOpenTime(3)}
                            style={{ borderBottom :  
                                            moment(jobStatusTime.finish_job).isValid() ?
                                                '1px solid black'   :   "none" }}
                            >{moment(jobStatusTime.finish_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ? 
                            moment(jobStatusTime.finish_job,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                            : '12:00 am'}</p>
                        }
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Job Completed</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            {
                                openTime === 4 ?
                            
                            <DateTimeInput
                                pickerType="time"
                                openEdit={openTime===4}
                                format="hh:mm a"
                                dateValue={jobStatusTime.completed}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, completed : e})}
                                clsName={'client-input'}
                                acceptFunc={()=>{setOpenTime(null)}}
                                cancelFunc={()=>{setOpenTime(null)}}
                            />
                            :
                            <p onClick={()=> setOpenTime(4)}
                            style={{ borderBottom :  
                                            moment(jobStatusTime.completed).isValid() ?
                                                '1px solid black'   :   "none" }}
                            >{moment(jobStatusTime.completed,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ? 
                            moment(jobStatusTime.completed,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                            : '12:00 am'}</p>
                        }
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Arrived Waste Depot</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            {
                                openTime === 5 ?
                            <DateTimeInput
                                pickerType="time"
                                openEdit={openTime===5}
                                format="hh:mm a"
                                dateValue={jobStatusTime.arrive_at_waste_depot}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, arrive_at_waste_depot : e})}
                                clsName={'client-input'}
                                acceptFunc={()=>{setOpenTime(null)}}
                                cancelFunc={()=>{setOpenTime(null)}}
                            />
                            :
                            <p onClick={()=> setOpenTime(5)}
                            style={{ borderBottom :  
                                            moment(jobStatusTime.arrive_at_waste_depot).isValid() ?
                                                '1px solid black'   :   "none" }}
                            >{moment(jobStatusTime.arrive_at_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ? 
                            moment(jobStatusTime.arrive_at_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                            : '12:00 am'}</p>
                        }
                        
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Departed Waste Depot</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            {
                                openTime === 6 ?
                            <DateTimeInput
                                pickerType="time"
                                openEdit={openTime===6}
                                format="hh:mm a"
                                dateValue={jobStatusTime.depart_waste_depot}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, depart_waste_depot : e})}
                                clsName={'client-input'}
                                acceptFunc={()=>{setOpenTime(null)}}
                                cancelFunc={()=>{setOpenTime(null)}}
                            />
                            :
                            <p onClick={()=> setOpenTime(6)}
                            style={{ borderBottom :  
                                            moment(jobStatusTime.depart_waste_depot).isValid() ?
                                                '1px solid black'   :   "none" }}
                            >{moment(jobStatusTime.depart_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ? 
                            moment(jobStatusTime.depart_waste_depot,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                            : '12:00 am'}</p>
                        }
                        
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Arrived Enviro Facility</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            {
                                openTime === 7 ?
                            <DateTimeInput
                                pickerType="time"
                                openEdit={openTime===7}
                                format="hh:mm a"
                                dateValue={jobStatusTime.arrive_enviro_facility}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, arrive_enviro_facility : e})}
                                clsName={'client-input'}
                                acceptFunc={()=>{setOpenTime(null)}}
                                cancelFunc={()=>{setOpenTime(null)}}
                            />
                            :
                            <p onClick={()=> setOpenTime(7)}
                            style={{ borderBottom :  
                                            moment(jobStatusTime.arrive_enviro_facility).isValid() ?
                                                '1px solid black'   :   "none" }}
                            >{moment(jobStatusTime.arrive_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).isValid() ? 
                            moment(jobStatusTime.arrive_enviro_facility,['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDHH:mm:ss','HH:mm a','hh:mm a']).format('hh:mm a') 
                            : '12:00 am'}</p>
                        }
                        
                            </div>
                        </div>
                    </div>
                    <div className='shdl-cmpltd-finalFields-Box'>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Change in extracted waste type</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <textarea value={chngWasteType} onChange={(val)=>{ setChngWasteType(val.target.value) }} rows='3' style={{ resize:'block' }} />    
                            </div>    
                        </div>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Change in extracted waste litres</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <textarea value={chngWasteLtr} onChange={(val)=>{ setChngWasteLtr(val.target.value) }} rows='3' style={{ resize:'block' }} />    
                            </div>    
                        </div>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Purchase Order Number</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <textarea value={ponumber} onChange={(val)=>{ setPonumber(val.target.value) }} rows='3' style={{ resize:'block' }} />    
                            </div>    
                        </div>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Signature Name</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <input type="text" value={signName} onChange={(val)=>  { setSignName(val.target.value) }}/>    
                            </div>    
                        </div>
                    </div>    
    <div className="shdl-sign-image-layout">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ fontWeight: 'bold', fontSize: 14 }}>Add Signature Image</p>

                {
                    signLoader ? 
                        <CircularProgress size={20} />
                    :   null
                }
                {
                    signImage ? 
                    <img onClick={() => window.open(`${typeof signImage==="string" ? signImage : URL.createObjectURL(signImage)}`, "_blank")}  
                    style={{ height: 80, width: 150, borderRadius: 5 }} 
                    
                    src={typeof signImage==="string" ? signImage : URL.createObjectURL(signImage)} alt="" />

                    : null
                }
                <div
                    onClick={()=> setSignModal(true) }
                >
                    <AddCircleIcon style={{ cursor: 'pointer' }} />
                </div>
        </div>
    </div>                  
    <div className="shdl-gallery-layout" 
    style={{ backgroundColor: !openGallery ? 'transparent' : 'rgba(0,0,0,0.2)',  
        borderBottom: !openGallery ? 'none' : '1px solid white' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom:'2%' }}>
            <div style={{ display:'flex', flexDirection:'column', width:'90%', alignItems:"flex-start", justifyContent:'space-between' }}>
                <p style={{ fontWeight: 'bold', fontSize: 14, marginBottom:'2%' }}>Gallery</p>
                {
                    delImgArr && delImgArr.length>0 ?
                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                        <div 
                        style={{
                            display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'2%',
                            backgroundColor:'var(--cl_red)', color:'white', fontSize:'.7rem', fontWeight:'700', marginRight:'2%'
                        }}
                        onClick={()=>deleteImageApi()}
                        >
                        <DeleteIcon style={{ cursor: 'pointer' }} />
                        <span>Delete</span>
                        </div>
                        <div 
                        style={{
                            display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'2%',
                            backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700', marginRight:'2%'
                        }}
                        onClick={()=>setDelImgArr([])}
                        >
                        <CancelIcon style={{ cursor: 'pointer' }} />
                        <span>Cancel</span>
                        </div>
                    </div>
                    :
                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                    <label htmlFor="file-input">
                        <div 
                        style={{
                            display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'.5%',
                            backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700', marginRight:'2%'
                        }}
                        >
                        <AddPhotoAlternateRounded style={{ cursor: 'pointer' }} />
                        <span>Before Job</span>
                        </div>
                    </label>
                    <input 
                        id="file-input"
                        style={{ display: 'none' }}
                        type="file" 
                        multiple
                        accept='image/*'
                        onChange={files => {
                            addScheduleImg(files.target.files, setGalleryLoader, 1)
                        }}
                    />
                    <label htmlFor="file-input1">
                        <div
                        style={{
                            display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'.5%',
                            backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700', marginRight:'2%'
                        }}
                        >
                        <AddPhotoAlternateRounded style={{ cursor: 'pointer' }} />
                        <span>After Job</span>
                        </div>
                    </label>
                    <input 
                        id="file-input1"
                        style={{ display: 'none' }}
                        type="file" 
                        multiple
                        accept='image/*'
                        onChange={files => {
                            addScheduleImg(files.target.files, setGalleryLoader, 2)
                        }}
                    />
                    <label htmlFor="file-input3">
                        <div
                        style={{
                            display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'.5%',
                            backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700', marginRight:'2%'
                        }}
                        >
                        <AddPhotoAlternateRounded style={{ cursor: 'pointer' }} />
                        <span>Other Images</span>
                        </div>
                    </label>
                        <input 
                        id="file-input3"
                        style={{ display: 'none' }}
                        type="file" 
                        multiple
                        accept='image/*'
                        onChange={files => {
                            addScheduleImg(files.target.files, setGalleryLoader, 3)
                        }}
                        />
                </div>
                }
                                
            </div>
            <div>
                {
                    openGallery ?
                        <ExpandMore  style={{ cursor: 'pointer' }} onClick={()=>{ setOpenGallery(false) }}/>
                    :
                        <ExpandLess  style={{ cursor: 'pointer' }} onClick={()=>{ setOpenGallery(true) }} />
                }
            </div>
            
        </div>
        {
            openGallery ? null 
            :
            (schedule.gallery && schedule.gallery?.length===0) && gallery_pics && gallery_pics?.length===0 &&
            (after_pics && after_pics.length===0) &&
            (schedule.after_pics && schedule.after_pics.length===0) && 
            (schedule.before_pics && schedule.before_pics.length===0) &&
            (before_pics && before_pics.length===0) ? 
            <p style={{ fontSize: 12, width: 100 }} >No Photos Found</p>
            :
            <Scrollbars style={{ height: '20vh' }}>
                {
                    galleryLoader ? 
                        <CircularProgress size={20} />
                    :   null
                }
            {   (schedule.before_pics && schedule.before_pics.length !==0) ||
                (before_pics && before_pics.length!==0) ?
            <div className="shdl-gallery-grid"
                style={{ gridTemplateColumns: 'repeat(auto-fill, 100px)' }}
            >

                <div className='shdl-img-splPics'>Before Job</div>
                {
                    before_pics && before_pics.map((item, key) => {
                        const img_url = URL.createObjectURL(item)
                        return (
                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                <CancelIcon 
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        let arr = before_pics.filter(el => el!==item)
                                        setBeforePics(arr)
                                    }} 
                                />
                                <img onClick={() => window.open(img_url, "_blank")}  
                                style={{ height: 100, width: 100, borderRadius: 5 }} 
                                src={img_url} alt="" />
                            </div>

                            
                        )
                    })
                }
                {
                    schedule.before_pics && schedule.before_pics.map((item, key) => {
                        const img_url = item.image ? item.image : item.file
                        return (
                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                {
                                    delImgArr && delImgArr.includes(item.id) ?
                                    <CheckCircleRounded
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        let arr = delImgArr.filter(el => el!==item.id)
                                        setDelImgArr(arr)
                                    }}
                                    />
                                    :
                                    <CancelIcon 
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        setDelImgArr([...delImgArr, item.id])
                                    }} 
                                    />
                                }
                                <img onClick={() => window.open(img_url, "_blank")}  style={{ height: 100, width: 100, borderRadius: 5 }} 
                                src={img_url} alt="" />
                            </div>

                            
                        )
                    })
                }
                
                
            </div>
            : null
            }
            {   (schedule.after_pics && schedule.after_pics.length !==0) ||
                (after_pics && after_pics.length !==0) ?
            <div className="shdl-gallery-grid" style={{ gridTemplateColumns: 'repeat(auto-fill, 100px)' }}>
                <div className='shdl-img-splPics'>After Job</div>
                {
                    after_pics && after_pics.map((item, key) => {
                        const img_url = URL.createObjectURL(item)
                        return (
                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                <CancelIcon 
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        let arr = after_pics.filter(el => el!==item)
                                        setAfterPics(arr)
                                    }} 
                                />
                                <img onClick={() => window.open(img_url, "_blank")}  
                                style={{ height: 100, width: 100, borderRadius: 5 }} src={img_url} alt="" />
                            </div>

                            
                        )
                    })
                }
                {
                    schedule.after_pics && schedule.after_pics.map((item, key) => {
                        const img_url = item.image ? item.image : item.file
                        return (
                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                {
                                    delImgArr && delImgArr.includes(item.id) ?
                                    <CheckCircleRounded
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        let arr = delImgArr.filter(el => el!==item.id)
                                        setDelImgArr(arr)
                                    }}
                                    />
                                    :
                                    <CancelIcon 
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        setDelImgArr([...delImgArr, item.id])
                                    }} 
                                    />
                                }
                                <img onClick={() => window.open(img_url, "_blank")}  
                                style={{ height: 100, width: 100, borderRadius: 5 }} src={img_url} alt="" />
                            </div>

                            
                        )
                    })
                }
            </div>
            :   null
            }
            {   (schedule.gallery && schedule.gallery.length !==0) || (gallery_pics && gallery_pics.length !==0) ?
            <div className="shdl-gallery-grid" style={{ gridTemplateColumns: 'repeat(auto-fill, 100px)' }}>
                <div className='shdl-img-splPics'>Other Images</div>
                {
                    gallery_pics && gallery_pics.map((item, key) => {
                        const img_url = URL.createObjectURL(item)
                        return (
                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                <CancelIcon 
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        let arr = gallery_pics.filter(el => el!==item)
                                        setGalleryPics(arr)
                                    }} 
                                />
                                <img onClick={() => window.open(img_url, "_blank")}  
                                style={{ height: 100, width: 100, borderRadius: 5 }} src={img_url} alt="" />
                            </div>

                            
                        )
                    })
                }
                {
                    schedule.gallery && schedule.gallery.map((item, key) => {
                        const img_url = item.image ? item.image : item.file
                        return (
                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                {
                                    delImgArr && delImgArr.includes(item.id) ?
                                    <CheckCircleRounded
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        let arr = delImgArr.filter(el => el!==item.id)
                                        setDelImgArr(arr)
                                    }}
                                    />
                                    :
                                    <CancelIcon 
                                    style={{ fontSize: 18 }} 
                                    className="shdl-img-dlt-btn"
                                    onClick={() => {
                                        setDelImgArr([...delImgArr, item.id])
                                    }} 
                                    />
                                }
                                <img onClick={() => window.open(img_url, "_blank")}  style={{ height: 100, width: 100, borderRadius: 5 }} src={img_url} alt="" />
                            </div>

                            
                        )
                    })
                }
            </div>
            :   null
            }
        </Scrollbars>
        }
                                
    </div>
    <div className="shdl-gallery-layout"
     style={{ backgroundColor: !openVidGallery ? 'transparent' : 'rgba(0,0,0,0.2)', marginBottom:'5%'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ fontWeight: 'bold', fontSize: 14 }}>Video Gallery</p>                            
            <div style={{ display:'flex', flexDirection:'row', width: '20%', alignItems:'center', justifyContent:'space-between' }}>
                <label htmlFor="file-input2">
                    <div>
                        <AddCircleIcon style={{ cursor: 'pointer' }} />
                    </div>
                </label>
                <input 
                    id="file-input2"
                    style={{ display: 'none' }}
                    type="file" 
                    multiple
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={files => {
                        setVGalleryLoader(true)
                                    let f = files.target.files
                                    let newArr = []
                                    for(let i=0; i<f.length; ++i){
                                        console.log('FILECHECK',f[i],IsVideoFileUpload(f[i]))
                                        if(IsVideoFileUpload(f[i])){
                                            newArr.push(f[i])
                                        }
                                    }
                                    setvideoGallery(newArr)
                                    setVGalleryLoader(false)
                    }}
                />
                <div>
                {
                    openVidGallery ?
                        <ExpandMore  style={{ cursor: 'pointer' }} onClick={()=>{ setOpenVidGallery(false) }}/>
                    :
                        <ExpandLess  style={{ cursor: 'pointer' }} onClick={()=>{ setOpenVidGallery(true) }} />
                }
            </div>
            </div>
        </div>
        {
            (schedule.job_video && schedule.job_video.length !== 0) ||
            (videoGallery && videoGallery.length!==0)  ?
            <Scrollbars style={{ height: '20vh' }}>
            
                <div className="shdl-gallery-grid"  style={{ gridTemplateColumns: 'repeat(auto-fill, 100px)' }}>
                
                {
                    vgalleryLoader ? 
                        <CircularProgress size={20} />
                    :   null
                }
                {
                    videoGallery && videoGallery.map((item, key) => {
                        const img_url = URL.createObjectURL(item)
                        return (
                            <div key={key} className="shdl-img-PicsDiv">
                                <VideoFilePreview
                                    deleteFn={()=>{ 
                                        let newArr = videoGallery
                                        setvideoGallery(newArr.filter(el => el!==item))
                                     }}
                                    // thumb={item.thumb}
                                    videoSrc={img_url}
                                    thumbStyle={{ height: 100, width: 100, borderRadius: 5 }}
                                />
                            </div>

                            
                        )
                    })
                }
                {
                    schedule.job_video && schedule.job_video.map((item, key) => {
                        const { video } = item
                        const img_url = HOST_WITHOUT_SLASH + video
                        return (
                            <div key={key} className="shdl-img-PicsDiv">
                                <VideoFilePreview
                                    deleteFn={()=>{ deleteVideoApi(item.id) }}
                                    // thumb={item.thumb}
                                    videoSrc={img_url}
                                    thumbStyle={{ height: 100, width: 100, borderRadius: 5 }}
                                />
                            </div>

                            
                        )
                    })
                }
                
            </div>
            
        </Scrollbars>
            :
            <div>
                {videoGallery && videoGallery.length === 0 && !vgalleryLoader &&(
                    <p style={{ fontSize: 12, width: 100 }} >No Videos Found</p>
                )}
            </div>
        }
                                
    </div>
    </Scrollbars>
    <SignaturePadModal
                modalAction={setSignModal}
                modalStatus={signModal}
                imgFn={(e) => addScheduleSignImg(e)}
            />
</div>
  )
}
