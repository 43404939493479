import React, {
    useEffect,
    // useEffect, 
    useState } from 'react'
import { connect } from 'react-redux'
import {
    add_team_to_job, add_vehicle_to_job, fetch_Employee_avilabilities, fetch_job_list_readyForSchd, fetch_schedules,
    //fetch_schedules_sorted, 
    fetch_Vehicle_avilabilities, schedule_image_add, schedule_imageSign_add, schedule_job_delete, schedule_job_edit, fetch_Mem_schedules, fetch_Mem_schedules_sorted, fetch_OutSourced_schedules, fetch_OutSourced_schedules_sorted, schedule_video_add, fetch_schedules_sorted_NewMode,
} from '../../common/action'
import { AspectRatioOutlined, ZoomInOutlined, ZoomOutOutlined } from '@material-ui/icons'

import DateTimeInput from '../../../../common/DateTimeInput'

import moment from 'moment'
import '../scheduleListStyle.css'
import { sales_job_view } from '../../../jobs/action'
import Schedulecontent from './scheduleContent'
import ScheduleContentMem from './scheduleComentMem'
import { GoogleMapInit } from '../StreetViewModal'
import ScheduleContentOutsourced from './scheduleContentOut'


function Index({
    screen_type,
    schedules_list,
    
    fetch_schedule_list,
    fetch_filterd_schedules,
    
    fetch_Out_schedule_list,
    fetch_Out_filterd_schedules,
   
    maximize,
    setMaximize,

    fetch_availabilityEmp,
    fetch_availabilityVeh,
    availStrDate, availEndDate, 
    isTabletWindow=false,
    vehicle_listDateTime, employee_listDateTime,

    rldSchd
}) {

    const [selectedJob, setSelectedJob]         = useState('')

    const [StartDate, setStartDate]             = useState('')
    const [StartTime, setStartTime]             = useState('')
    const [EndTime, setEndTime]                 = useState('')
    const [EndDate, setEndDate]                 = useState('')

    const [primeVehicle, setPrimeVehicle]       = useState('')
    const [schduleStatus, setScheduleStatus]                = useState('')

    const [selectedTeamArray, setSelectedTeamArray]         = useState([])

    const [fromDate, setFromDate]               = useState(availStrDate === '' ? moment().format('YYYY-MM-DD') : availStrDate)
    const [toDate, setToDate]                   = useState(availEndDate === '' ? moment().add(10,'day').format('YYYY-MM-DD') : availEndDate)
    const [invokeDateTo, setInvokeDateTo]       = useState(false)
    const [invokeDateFrom, setInvokeDateFrom]   = useState(false)
    const [scheduleLoader, setScheduleLoader]   = useState(false)
   
    const [toggleActive, setToggleActive]       = useState('v')
    const [filterLoader, setFilterLoader]       = useState(false)
    const [weekDaysArr, setWeekDaysArr] = useState([])

    useEffect(() => {
        let weekArr = []
        for(let i=0; i<moment(moment(toDate)).diff(moment(moment(fromDate)),'days')+1; ++i){ 
            weekArr.push({
                weekday : moment(moment(fromDate)).add(i,'day').format('dddd'),
                date : moment(moment(fromDate)).add(i,'day').format('DD-MM-YYYY')
            })
        }
        setWeekDaysArr(weekArr)
        
    }, [screen_type, setScheduleLoader, fromDate, toDate])

    useEffect(() => {
        setScheduleLoader(true)
        const successFx = () =>{
            setScheduleLoader(false)
        }
        let availFilter = 'start=' + moment(Date()).format('YYYY-MM-DD')
        availFilter = availFilter + moment(Date()).format('HH:mm:ss') + '&&end=' + moment(Date()).add(10, 'day').format('YYYY-MM-DD')
        availFilter = availFilter + moment(Date()).add(10, 'day').format('HH:mm:ss')
        fetch_availabilityVeh(availFilter, screen_type, successFx)
        fetch_availabilityEmp(availFilter, null)
        setToggleActive('v')
        console.log('schdCalUseffect',screen_type)

    }, [fetch_availabilityEmp, fetch_availabilityVeh, screen_type]);



    const updateScheduleInitScreen = (val) =>{
        // setScheduleLoader(true)
        // const successFx = () =>{
        //     setScheduleLoader(false)
        // }
        // let fDate = moment(fromDate).isValid() ? fromDate : moment(Date()).format('YYYY-MM-DD')
        // let tDate = moment(toDate).isValid() ? toDate : moment(Date()).add(10,'day').format('YYYY-MM-DD')
        // let timeNow = moment(Date()).format('HH:mm:ss')

        // let availFilter = 'start=' + fDate
        // availFilter = availFilter + timeNow + '&&end=' + tDate
        // availFilter = availFilter + timeNow
        // fetch_availabilityVeh(availFilter, screen_type, val==='v' ? successFx : null)
        // fetch_availabilityEmp(availFilter, val==='m' ? successFx : null)
        // if(val==='o'){
        //     setScheduleLoader(false)
        // }
        rldSchd()
        setToggleActive(val)
        console.log('schdCalUseffect',screen_type)
    }


    let mapInit = GoogleMapInit()
    let initial_cell_width = isTabletWindow ? 10.6 : 6;
    let zoomWidthArrVal = [isTabletWindow ? 10.6 : 6, isTabletWindow ? 20.6 : 16, isTabletWindow ? 30.6 : 26, isTabletWindow ? 40.6 : 36]
    
    let total_column_length = 0;
    let container_width = maximize ? 90 : 48;
    let one_cell_width = 5.29;
    if ((toggleActive==='v' && vehicle_listDateTime) || (toggleActive==='m' && employee_listDateTime)) {

        total_column_length = 
            toggleActive==='v' ? vehicle_listDateTime?.length : employee_listDateTime?.length;
        one_cell_width = isTabletWindow ? (container_width / (total_column_length+1)) : (container_width / (total_column_length));
        initial_cell_width = isTabletWindow ? one_cell_width + ((total_column_length+1) * 1 / 4) : one_cell_width;
                                                
    }

    const [zoomVal, setZoomVal] = useState(isTabletWindow ? 1 : 0);
    const [zoomWidth, setZoomWidth] = useState( isTabletWindow ? initial_cell_width : initial_cell_width<5.29 ? 6 : initial_cell_width);

    const success_sch_fx = () => {
        setScheduleLoader(false)
    }

    const success_filter_sch_fx = () => {
        setScheduleLoader(false)
        setFilterLoader(false)
    }

    
    const setFromFilterDate = (val) => {
        setScheduleLoader(true)
        let frmDate = moment(val).format('YYYY-MM-DD')
        let tDate = moment(toDate).format('YYYY-MM-DD')
        let filter = `from=${frmDate}&to=${tDate}`
        if(toggleActive==='v' || toggleActive==='m'){
            rldSchd()
            fetch_filterd_schedules(screen_type, 1, filter, [], frmDate, tDate, success_filter_sch_fx)
        }else{
            rldSchd() 
            fetch_Out_filterd_schedules(screen_type, 1, filter, [], frmDate, tDate, success_filter_sch_fx)
        }
        let availFilter = 'start=' + moment(val).format('YYYY-MM-DD')
        availFilter = availFilter + moment(val).format('HH:mm:ss') + '&&end=' + moment(toDate).format('YYYY-MM-DD')
        availFilter = availFilter + moment(toDate).format('HH:mm:ss')
        fetch_availabilityVeh(availFilter, screen_type)
        fetch_availabilityEmp(availFilter)
        setFromDate(val)

        console.log("FN5")
    }
    const setToFilterDate = (val) => {
        setScheduleLoader(true)
        let frmDate = moment(fromDate).format('YYYY-MM-DD')
        let toDate = moment(val).format('YYYY-MM-DD')
        let filter = `from=${frmDate}&to=${toDate}`
        if(toggleActive==='v' || toggleActive==='m'){
            rldSchd()
            fetch_filterd_schedules(screen_type, 1, filter, [], frmDate, toDate, success_filter_sch_fx)
        }else{
            rldSchd() 
            fetch_Out_filterd_schedules(screen_type, 1, filter, [], frmDate, toDate, success_filter_sch_fx)
        }
        let availFilter = 'start=' + moment(fromDate).format('YYYY-MM-DD')
        availFilter = availFilter + moment(fromDate).format('HH:mm:ss') + '&&end=' + moment(val).format('YYYY-MM-DD')
        availFilter = availFilter + moment(val).format('HH:mm:ss')
        fetch_availabilityVeh(availFilter, screen_type)
        fetch_availabilityEmp(availFilter)
        
        setToDate(val)
    }

    console.log('FRMDate=>',fromDate,toDate,moment(fromDate).diff(moment(),'day'),moment(toDate).diff(moment(),'day'))
   
    return (
        <>
            <div className="schdl-main-head">
                <p style={{ fontWeight: 'bold' }}>Schedule List </p>
                <div className='toggleDiv'>
                    <div className='toggleButton' style={{ color: toggleActive!=='v' ? 'black' : 'white' }} onClick={()=>{ 
                        updateScheduleInitScreen('v') }}>Vehicles</div>
                    <div className='toggleButton' style={{ color: toggleActive!=='m' ? 'black' : 'white' }} onClick={()=>{ updateScheduleInitScreen('m'); }}>Members</div>
                    <div className='toggleButton' style={{ color: toggleActive!=='o' ? 'black' : 'white' }} onClick={()=>{ updateScheduleInitScreen('o'); }}>Outsourced</div>
                    <div className={ toggleActive==='o' ? 'toggleButton toggleButtonActiveRight' : 
                    toggleActive==='m' ? 'toggleButton toggleButtonActiveMiddle' : 'toggleButton toggleButtonActiveLeft'}>
                        {toggleActive==='m' ? 'Members' : toggleActive==='v' ? 'Vehicles' : 'Outsourced'}
                    </div>
                </div>
                <div className="shdl-sortings-main-layout" style={{ padding: '0 10px' }}>
                    <p style={{ padding: '0 15px' }}>From : </p>
                            <DateTimeInput
                                dateValue={fromDate}
                                dateSetFn={setFromFilterDate}

                            />
                    <p style={{ padding: '0 15px' }}>To : </p>
                            <DateTimeInput
                                dateValue={toDate}
                                dateSetFn={setToFilterDate}
                            /> 
                    {
                        (
                            moment(fromDate).diff(moment(),'day')!==0 ||
                            moment(toDate).diff(moment(),'day')!==9
                        ) && (
                            <button
                                onClick={() => {
                                    let filter = 'start=' + moment().format('YYYY-MM-DD')
                                    filter = filter + moment().format('HH:mm:ss') + '&&end=' + moment().add(10, 'day').format('YYYY-MM-DD')
                                    filter = filter + moment().add(10, 'day').format('HH:mm:ss')
                                    let filter2 = `from=${moment().format('YYYY-MM-DD')}&to=${moment().add(10, 'day').format('YYYY-MM-DD')}`
                                    if(toggleActive==='v' || toggleActive==='m')
                                        fetch_filterd_schedules(screen_type, 1, filter2, [], moment().format('YYYY-MM-DD'), moment().add(10,'days').format('YYYY-MM-DD'), success_filter_sch_fx)
                                    else 
                                        fetch_OutSourced_schedules(screen_type, 1, success_sch_fx, [], setFilterLoader)
                                        fetch_availabilityVeh(filter, screen_type)
                                    fetch_availabilityEmp(filter)
                                    setFromDate(moment().format('YYYY-MM-DD'))
                                    setToDate(moment().add(10, 'day').format('YYYY-MM-DD'))
                                    setInvokeDateTo(false)
                                    setInvokeDateFrom(false)

                                }} style={{ marginLeft: '10px' }}
                            >Clear</button>
                        )
                    }

                    {
                        maximize && (
                            <>
                                {(window.innerWidth < 1200 && zoomVal > 1) || (window.innerWidth >= 1200 && zoomVal > 0) ?
                                    <ZoomOutOutlined
                                        onClick={() => {
                                            if(window.innerWidth < 1200){
                                                if (zoomVal > 1) {
                                                    let zoom_value = zoomVal - 1;
                                                    setZoomVal(zoom_value);
                                                    let total_number_cells = total_column_length + 1;
                                                    let one_cell_width = (container_width / (total_number_cells));
                                                    let defined_width = one_cell_width + (total_number_cells * zoom_value / 4);
                                                    // setZoomWidth(defined_width<5.29 ? 5.29 : defined_width);
                                                    setZoomWidth(defined_width<5.29 ? 6 : defined_width > 36 ?
                                                        zoomWidthArrVal[zoom_value] : defined_width)

                                                }
                                            }
                                            else if (zoomVal > 0) {
                                                let zoom_value = zoomVal - 1;
                                                setZoomVal(zoom_value);
                                                let total_number_cells = total_column_length + 1;
                                                let one_cell_width = (container_width / (total_number_cells));
                                                let defined_width = one_cell_width + (total_number_cells * zoom_value / 4);
                                                // setZoomWidth(defined_width<5.29 ? 5.29 : defined_width);
                                                setZoomWidth(defined_width<5.29 ? 6 : defined_width > 36 ?
                                                    zoomWidthArrVal[zoom_value] : defined_width)
                                            }
                                        }}
                                        style={{ marginLeft: '20px', color: 'black', fontSize: 25, cursor: 'pointer' }} />
                                    : null}
                                {zoomVal < 2 ?
                                    <ZoomInOutlined
                                        onClick={() => {
                                            if (zoomVal < 2) {
                                                let zoom_value = zoomVal + 1;
                                                setZoomVal(zoom_value);
                                                // setZoomWidth(zoomWidth + 100);
                                                let total_number_cells = total_column_length + 1;
                                                let one_cell_width = (container_width / (total_number_cells));
                                                let defined_width = one_cell_width + (total_number_cells * zoom_value / 4);
                                                // setZoomWidth(defined_width<5.29 ? 5.29 : defined_width);
                                                setZoomWidth(defined_width<5.29 ? 6 : defined_width > 36 ?
                                                    zoomWidthArrVal[zoom_value] : defined_width)
                                            }
                                        }}
                                        style={{ marginLeft: '20px', color: 'black', fontSize: 25, cursor: 'pointer' }} />
                                    : null}
                            </>
                        )
                    }

                    {
                        window.innerWidth >= 1200 ? 
                        maximize ? (
                            <AspectRatioOutlined onClick={() =>{
                                setZoomWidth(5.29)
                                setMaximize(false)}} style={{ marginLeft: '20px', color: 'black', fontSize: 18, cursor: 'pointer' }} />
                        ) : (
                            <AspectRatioOutlined onClick={() =>{ 
                                 setMaximize(true)}} style={{ marginLeft: '20px', color: 'grey', fontSize: 18, cursor: 'pointer' }} />
                        )
                        :   null
                    }
                </div>



            </div>
            {
                toggleActive==='m' ?
                <ScheduleContentMem 
                scheduleLoader={scheduleLoader} 
                setScheduleLoader={setScheduleLoader}
                invokeDateFrom={invokeDateFrom} 
                invokeDateTo={invokeDateTo} 
                toDate={toDate} 
                fromDate={fromDate} 
                zoomVal={zoomVal}
                maximize={maximize}
                container_width={container_width}
                //applyButtonDisabled={applyButtonDisabled} 
                zoomWidth={isFinite(zoomWidth) ? zoomWidth : 5.29} 
                success_sch_fx={success_sch_fx}
                StartDate={StartDate} 
                StartTime={StartTime} 
                EndDate={EndDate} 
                EndTime={EndTime} 
                setStartDate={setStartDate} 
                setStartTime={setStartTime} 
                setEndTime={setEndTime} 
                setEndDate={setEndDate}
                primeVehicle={primeVehicle}    
                setPrimeVehicle={setPrimeVehicle} 
                screen_type={screen_type}
                setScheduleStatus={setScheduleStatus} 
                schduleStatus={schduleStatus}
                selectedTeamArray={selectedTeamArray} 
                setSelectedTeamArray={setSelectedTeamArray} 
                selectedJob={selectedJob} 
                setSelectedJob={setSelectedJob}
                setFromFilterDate={setFromFilterDate}
                setToFilterDate={setToFilterDate}
                filterLoader={filterLoader}
                mapInit={mapInit}

                weekDaysArr={weekDaysArr}
                setWeekDaysArr={setWeekDaysArr}
                setInputToDate={setToDate}
                setInputFromDate={setFromDate}
                />
                :   toggleActive==='v' ?
                <Schedulecontent 
                scheduleLoader={scheduleLoader} 
                setScheduleLoader={setScheduleLoader}
                invokeDateFrom={invokeDateFrom} 
                invokeDateTo={invokeDateTo} 
                toDate={toDate} 
                fromDate={fromDate} 
                zoomVal={zoomVal}
                maximize={maximize}
                container_width={container_width}
                //applyButtonDisabled={applyButtonDisabled} 
                zoomWidth={isFinite(zoomWidth) ? zoomWidth : 5.29} 
                success_sch_fx={success_sch_fx}
                StartDate={StartDate} 
                StartTime={StartTime} 
                EndDate={EndDate} 
                EndTime={EndTime} 
                setStartDate={setStartDate} 
                setStartTime={setStartTime} 
                setEndTime={setEndTime} 
                setEndDate={setEndDate}
                primeVehicle={primeVehicle}    
                setPrimeVehicle={setPrimeVehicle} 
                screen_type={screen_type}
                setScheduleStatus={setScheduleStatus} 
                schduleStatus={schduleStatus}
                selectedTeamArray={selectedTeamArray} 
                setSelectedTeamArray={setSelectedTeamArray} 
                selectedJob={selectedJob} 
                setSelectedJob={setSelectedJob}
                setFromFilterDate={setFromFilterDate}
                setToFilterDate={setToFilterDate}
                filterLoader={filterLoader} 
                mapInit={mapInit}

                weekDaysArr={weekDaysArr}
                setWeekDaysArr={setWeekDaysArr}
                setInputToDate={setToDate}
                setInputFromDate={setFromDate}
                />
                :
                <ScheduleContentOutsourced 
                scheduleLoader={scheduleLoader} 
                setScheduleLoader={setScheduleLoader}
                invokeDateFrom={invokeDateFrom} 
                invokeDateTo={invokeDateTo} 
                toDate={toDate} 
                fromDate={fromDate} 
                zoomVal={zoomVal}
                maximize={maximize}
                container_width={container_width}
                //applyButtonDisabled={applyButtonDisabled} 
                zoomWidth={isFinite(zoomWidth) ? zoomWidth : 5.29} 
                success_sch_fx={success_sch_fx}
                StartDate={StartDate} 
                StartTime={StartTime} 
                EndDate={EndDate} 
                EndTime={EndTime} 
                setStartDate={setStartDate} 
                setStartTime={setStartTime} 
                setEndTime={setEndTime} 
                setEndDate={setEndDate}
                primeVehicle={primeVehicle}    
                setPrimeVehicle={setPrimeVehicle} 
                screen_type={screen_type}
                setScheduleStatus={setScheduleStatus} 
                schduleStatus={schduleStatus}
                selectedTeamArray={selectedTeamArray} 
                setSelectedTeamArray={setSelectedTeamArray} 
                selectedJob={selectedJob} 
                setSelectedJob={setSelectedJob}
                setFromFilterDate={setFromFilterDate}
                setToFilterDate={setToFilterDate}
                filterLoader={filterLoader} 
                mapInit={mapInit}

                weekDaysArr={weekDaysArr}
                setWeekDaysArr={setWeekDaysArr}
                setInputToDate={setToDate}
                setInputFromDate={setFromDate}
                />
                
                
            }
            <div className="shdl-main-footer"></div>
        </>
    );
}

const mapStateToProps = (state) => {
    const { schedules_list, schedulesMem_list, schedules_page, schedulesMem_page,
         vehicle_list, stopLoading, stopMemLoading, schd_loader, schdMem_loader, 
         vehicle_listDateTime, employee_listDateTime,
          availStrDate, availEndDate, availMemStrDate, availMemEndDate, job_list_weekFilter, jobTab } = state.schedule
    return { schedules_list, schedules_page, vehicle_list, stopLoading, schd_loader, availStrDate, availEndDate, job_list_weekFilter,
        vehicle_listDateTime, employee_listDateTime,
        schedulesMem_list, schedulesMem_page, stopMemLoading, schdMem_loader, availMemStrDate, availMemEndDate, jobTab }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_schedule_list         : (type, page, success, prevData, fallBackFn) => dispatch(fetch_schedules(type, page, success, prevData, fallBackFn)),
        fetch_Mem_schedule_list     : (type, page, success, prevData, fallBackFn) => dispatch(fetch_Mem_schedules(type, page, success, prevData, fallBackFn)),
        fetch_filterd_schedules     : (type, page, filter, prevData, StartDate, EndDate, fallBackFn) => dispatch(fetch_schedules_sorted_NewMode(type, page, filter, prevData, StartDate, EndDate, fallBackFn)),
        fetch_Mem_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fallBackFn) => dispatch(fetch_Mem_schedules_sorted(type, page, filter, prevData, StartDate, EndDate, fallBackFn)),
        fetch_Out_schedule_list     : (type, page, success, prevData, fallBackFn) => dispatch(fetch_OutSourced_schedules(type, page, success, prevData, fallBackFn)),
        fetch_Out_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fallBackFn) => dispatch(fetch_OutSourced_schedules_sorted(type, page, filter, prevData, StartDate, EndDate, fallBackFn)),


        schedule_edit_job           : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_setting                : (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        vehicle_setting             : (screen_type, data, primeData, shdId, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, success, failed)),
        delete_schedule             : (type, data, schType, success, failed) => dispatch(schedule_job_delete(type, data, schType, success, failed)),
        add_image_schedule          : (type, data, schType, success, failed) => dispatch(schedule_image_add(type, data, schType, success, failed)),
        add_video_schedule          : (type, data, schType, success, failed) => dispatch(schedule_video_add(type, data, schType, success, failed)),
        add_image_signschedule      : (type, data, schType, success, failed) => dispatch(schedule_imageSign_add(type, data, schType, success, failed)),
        //  fetch_jobs: (type) => dispatch(fetch_job_list(type,1)) ,

        fetch_jobs                  : (type, frmd, jobTab) => { dispatch(fetch_job_list_readyForSchd(type, 1, frmd, jobTab)); },

        fetch_availabilityVeh       : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp       : (filter) => dispatch(fetch_Employee_avilabilities(filter)),
        rldSchd                     : () => dispatch({ type: 'RELOAD_SCHD_LIST' }),
        sales_job_viewFx            : (id) => dispatch(sales_job_view(id)),
        savedJobDataFx              : (dataObj) => dispatch({ type: "SAVING_JOBDATA_TEMP", data: dataObj })
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Index);