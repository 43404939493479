import axios from 'axios';
import { store } from '../../../store/store'

import React, { useState, useEffect } from 'react';
import { TEAM_SEARCH } from '../../../store/endpoint';
import { useDispatch } from 'react-redux';
import { team_list_paginated } from '../action';
import { CircularProgress } from '@material-ui/core';
import { Search } from '@material-ui/icons';

function SearchTab({tabVal}) {

    const [searchTxt, setSearchTxt] = useState('')
    const [loadSearch, setLoadSearch] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const request = axios.CancelToken.source()
        let data = new FormData()
        data.append('key',searchTxt)
        console.log("searching user",searchTxt,request)
        if(searchTxt!=='' || searchTxt){
        
        let state = store.getState()
        let token = state.userLogin.authToken
        
        var config = {
            method: 'post',
            url: TEAM_SEARCH,
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

            axios(config)
            .then((res) => {
                setLoadSearch(false)
                console.log("SEARCH",res)
                dispatch({ type: 'TEAM_FETCHING_SUCCESS_PAGINATED', teams: res.data, teamPage: 1})
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else{
            setLoadSearch(false)
            dispatch(team_list_paginated(tabVal ? 'curr' : 'term', 1))
        }
        
        return () => request.cancel()

    }, [searchTxt, tabVal, dispatch])

    return (
        <div className='Search-an-employe'>
            <input 
                className='Search-an-employe-input'
                placeholder="Search an employee"
                onChange={txt =>{ setSearchTxt(txt.target.value); 
                    setLoadSearch(true)}}
            />
            { loadSearch ?
                <CircularProgress size='20px' color='inherit' />
                : 
                <Search  style={{ fontSize:'18px', display:'flex', alignItems:'center',
                justifyContent:'center' }} color='inherit' />
            }
        
        </div>
    );
}

export default SearchTab;