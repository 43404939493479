export function failed_validation(error){
    // type, url, params, success, 
    let returnvalue;
    if (error.response && error.response.data) {
        if (error.response.status === 401) {
            console.log('401')
            // AuthErrorValidation(Recall)
            returnvalue = {
                status: null,
                text: 'Permissions denied',
                dev_data: null,
                app_data: null
            }
        } else {

            let statusCode = error.response.status;
            let statusText = error.response.statusText;
            let error_dev_data = error.response.data.dev_data;
            let error_app_data = error.response.data.app_data;
            let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
            }
            returnvalue = res
        }
    } else {
        if (error.message) {
            returnvalue = {
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
            }

        } else {
            returnvalue = {
                status: null,
                text: 'Unknown error',
                // text: null,
                dev_data: null,
                app_data: null
            }
        }
    }
return returnvalue
}