import React, { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'


const LogoutLoader = ({ user_logout, resetSignout}) => {

    useEffect(() => {
        user_logout();
        setTimeout(() => {
            resetSignout()
        }, 3000);
    });

  return (
        <div className="App" style={{ backgroundColor:'whitesmoke' }} >
            <div className="main-layout blurEffct" style={{ position:'relative', pointerEvents: 'none' }}></div>
            <div className="permissionInfoModalBg">
                <div className="permissionInfoModal" style={{ border:'none' }}>
                    <div className="permissionInfoModalContent">
                        <div>
                            Clearing data... Please Wait.
                        </div>
                        <br />
                        <CircularProgress />
                    </div>
                </div>
            </div>
        </div>
  )
}

const mapDispatchToProps = (dispatch) => {
    return {
        user_logout: () => dispatch({ type: "SIGNOUT" }),
        resetSignout: () => dispatch({ type: "SIGNINGOUTPROGRESS", status : false }),
        clear_fcm: () => dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
      }
}


export default connect(null, mapDispatchToProps) (LogoutLoader)