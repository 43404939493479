import React from 'react'

import Sidebar from '../../common/sidebar'
import './components/style.css'
import { INTRANET_FILES_FOLDER_GET, OHS_AND_S_FILE_ADD, OHS_AND_S_FOLDER_DELETE, INTRANET_FOLDER_EDIT,
    SALES_AND_INTRANET_FOLDER_CREATE, OHS_AND_S_FILE_DELETE, INTRANET_FILES_RENAME
} from '../../store/endpoint';
import Header from './components/Header'

import IntranetLayout from '../../common/filesNFolder/FileFoldersLayout'
import { connect } from 'react-redux';

function IntranetIndexApp({permissions}) {
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='main'/>
                
                <IntranetLayout APIURL={INTRANET_FILES_FOLDER_GET} title={'Intranet'} 
                FILEEDIT={ permissions.intranet.edit ? INTRANET_FILES_RENAME : null}
                FOLDEREDIT={ permissions.intranet.edit ? INTRANET_FOLDER_EDIT : null} 
                FILEADD={ permissions.intranet.add ? OHS_AND_S_FILE_ADD : null} 
                FOLDERCREATE={ permissions.intranet.add ? SALES_AND_INTRANET_FOLDER_CREATE+'intranet/create/' : null} 
                FOLDERDELETE={ permissions.intranet.delete ? OHS_AND_S_FOLDER_DELETE : null } 
                FILEDELETE={ permissions.intranet.delete ? OHS_AND_S_FILE_DELETE : null}
                />
            </div>
        </div>
    )
}


const mapStateToProps = (state) =>{
    const { permissions } = state.userLogin 
    return { permissions }
}
export default connect(mapStateToProps) (IntranetIndexApp)
