import React, { useEffect, useLayoutEffect , useState } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning';
import { connect } from 'react-redux'
import { messaging } from '../store/firebase'
import Api from '../store/api'
import Login from './login'
import { routes } from './route'
import { PUSH_NOTIFICATION } from '../store/endpoint';
import Swal from 'sweetalert2';
import LogoutLoader from '../common/LogoutLoader';
// import Swal from 'sweetalert2'

const AppStack = () => {
    let location = useLocation();

    if(navigator.userAgent.indexOf('Chrome')!==-1){
        console.log('Chrome detected')
        let slowNet = 0;
        function changeHandler(e) {
   
            if(navigator.connection.downlink < 1)
    // Swal.fire({
    //     position: 'top-end',
    //     icon: 'info',
    //     title: `Slow Network`,
    //     showConfirmButton: false,
    //     timer: 1500
    // })
            slowNet = slowNet + 1;
            if(slowNet > 50){

        console.log('Expiriencing Slow Network. The Downlink Value is:',navigator.connection.downlink,'slw',slowNet)
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Expiriencing Slow Network!',
                    text: 'Please check your network connection.',
                    target: '#custom-target',
                    customClass: {
                      container: 'position-absolute'
                    },
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  slowNet = 0;
            }
  // Handle change to connection here.
        }

// Register for event changes.
        if(!((navigator.brave && navigator.brave.isBrave()) || false))
            navigator.connection.onchange = changeHandler;
        else{
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Sorry!',
                text: 'Brave browser is not fully compatible',
                target: '#custom-target',
                customClass: {
                  container: 'position-absolute'
                },
                toast: true,
                showConfirmButton: false,
                timer: 6500
              })
            
        } 

    }

    if(location.pathname === '/login' || location.pathname === '/') 
    return (<Redirect to="/home" />)
    return (
        <Switch>
            
            {
                routes.map((item, key) => {
                    const { path, name, component } = item
                    switch(name){
                        case 'Home'          : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Sales'         : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Site'          : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Team'          : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Scheduling'    : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Vehicle'       : return <Route key={key} path={path} name={name} component={component} />;
                        case 'OH & S'        : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Intranet'      : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Accounts'      : return <Route key={key} path={path} name={name} component={component} />;
                        case 'Invoice'       : return <Route key={key} path={path} name={name} component={component} />;
                        default : return null
                    }
                    
                    
                })
            }
            <Redirect from='/' to={'/home'} />
        </Switch>
    )
}

const AuthStack = () => {
    let location = useLocation();
    if(location.pathname !== '/login') return <Redirect to="/login" />
    return (
        <Switch>
            <Route exact path='/login' name='signin' component={Login} />
        </Switch>
    )
}

const MobileAlert = () => {
    return (
        <div className="mobile-alert-layout">
            <div className="mobile-alert-box">
                <WarningIcon style={{ fontSize: 35, marginBottom: 30 }} />
                <p style={{ textAlign: 'center' }}>This app is not working in mobile phone. Use computer!</p>
            </div>
        </div>
    )
}

function Router({ isLoggedIn, dispatch, signingOutProgress }) {

    const [width, setWidth] = useState(window.innerWidth)
    
    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth)
          }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])


    useEffect(() => {
        NotificationTokenGen()
    })
    
    const NotificationTokenGen = () => {
        console.log('login successful response')
        if(messaging) {
            messaging.requestPermission()
            .then(async function() {
                    const token = await messaging.getToken();
                    // console.log('token', token)
                    let token_key = token.toString()
                    dispatch({ type: 'FCM_TOKEN_SET', fcm: token_key})
                    let appendData = new FormData()
                    appendData.append('keys', token_key)
                    //appendData.append('keys', 'jithin-test')
                    console.log('push notification api call before')
                    Api('post-form', PUSH_NOTIFICATION, appendData)
                    console.log('push notification api call after')
            })
            .catch(function(err) {
            console.log("Unable to get permission to notify.", err);
            });
            navigator.serviceWorker.addEventListener("message", (message) => NotifiactionAction(message))
        }
    }

    const NotifiactionAction = (message) => {
        console.log(message)
        console.log('notification recieved')
    }

    if(width < 425) {
        return (<MobileAlert />)
    } else if(signingOutProgress) 
        return <LogoutLoader/>
    else if(isLoggedIn) return <AppStack id="custom-target"/>
        else return <AuthStack />
}

const mapsStateProps = (state) => {
    const { isLoggedIn, signingOutProgress } = state.userLogin
    return {
        isLoggedIn: isLoggedIn, signingOutProgress
    }
}


export default connect(mapsStateProps)(Router)