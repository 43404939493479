import React, {useState, useEffect, Suspense, useRef} from 'react';
import { connect } from 'react-redux';
import Scrollview from '../../../../common/ImprovedScrollBar.jsx';
import { Avatar, CircularProgress, Tooltip } from '@material-ui/core'
import NoDatasScreen from '../../../../common/no_datas'
import moment from 'moment'
import { AnnouncementRounded, Edit, 
    StarRounded, 
    //InfoOutlined, 
    //ReportProblemRounded, 
    StreetviewRounded } from '@material-ui/icons'
import { SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER, SCHEDULE_ADDITIONAL_VEHICLE, SCHEDULE_QUOTE_INFOS_NEW } from '../../../../store/endpoint'
import Api from '../../../../store/api'
import JobModal from '../jobModalB'
import QuoteModal from '../../../../common/CommonQuoteDetailModal'
import Swal from 'sweetalert2'
import QuoteHistoryModalView from '../quoteHistoryModal'
import EnviroLogoLoader from '../../../../assets/Images/eniviro_logo.gif'

import { // editAfterCompleteElements,
    add_team_to_job, add_vehicle_to_job, fetch_Employee_avilabilities, fetch_job_list_readyForSchd, fetch_schedules,
    //fetch_schedules_sorted, 
    fetch_schedules_sorted_NewMode, fetch_Vehicle_avilabilities, schedule_image_add, schedule_imageSign_add, schedule_job_delete, schedule_job_edit, schedule_video_add,
    //fetch_job_pending_schd, fetch_job_reoccur_schd
} from '../../common/action'
import { sales_job_view } from '../../../jobs/action'
import { ScheduleBoxShimmer, SchedulelistShimmer, SchedulelistVehicleshimmer } from '../ScheduleListShimmer';
import { HOME_LATITUDE, HOME_LONGITUDE } from '../../../../store/config';
import { IsVideoFileUpload } from '../../../../common/VideoFilePreview';

const StreetViewModal = React.lazy(() => import ('../StreetViewModal.jsx'));

const Schedulecontent = ({ screen_type,
    schedules_list,
    vehicle_list,
    // schedules_page,
    fetch_schedule_list,
    fetch_filterd_schedules,
    schedule_edit_job,
    team_setting,
    vehicle_setting,
    delete_schedule,
    // add_image_schedule,
    add_video_schedule,
    add_image_signschedule,
    stopLoading,
    // schd_loader,
    fetch_jobs,
    rldSchd,
    savedJobDataFx,
    // setMaximize,
    // applyButtonDisabled,
    sales_job_viewFx,
    fetch_availabilityEmp,
    fetch_availabilityVeh,

    scheduleLoader, setScheduleLoader,
    //invokeDateFrom, invokeDateTo, 
    fromDate, toDate, zoomVal,
    maximize,
    zoomWidth, //success_sch_fx,
    setStartDate, StartDate,
    setStartTime, StartTime,
    setEndTime, EndTime,
    setEndDate, EndDate,
    setPrimeVehicle, primeVehicle,
    setScheduleStatus, schduleStatus,
    setSelectedTeamArray, selectedTeamArray,
    setSelectedJob, selectedJob, 
    // container_width,


    // setFromFilterDate,
    // setToFilterDate,
    filterLoader,
    jobTab, selected_job,
    
    filtered_emp_list, vehicle_listDateTime,
    weekDaysArr, setWeekDaysArr,
    setInputToDate,
    // setInputFromDate,
    employee_listDateTime
     }) => {

        const [modal, setModal]                     = useState(false)
        const [quoteModal, setquoteModal]           = useState(false)
        const [quoteInfos, setQuoteInfos]           = useState({ job: null, quote: null, schedul: null })
    
        const [scheduleId, setScheduleId]           = useState('')
    
        const [vehicleVal, setVehicleVal]           = useState([])
    
        const [primeVehicleDriver, setPrimeVehicleDriver]       = useState(null)
        const [additionalVehTeamArr, setAdditionalVehTeamArr]   = useState([])
        
        const [allVehicleArrObj, setAllVehicleArrObj] = useState(null)
        
    
        // const [CommentsArr, setCommentsArr]         = useState([])
        const [typeOfQuoteModal, setTypeOfQuoteModal]           = useState(false)
    
        const [QuoteHistoryModal, setQuoteHistoryModal]         = useState(false)
        const [quoteHistoryInfos, setQuoteHistoryInfos]         = useState([])
    
        const [contentLoader, setContentLoader]                 = useState(null)

        
        const [markers, setMarkers] = useState({lat: parseFloat(HOME_LATITUDE), lng: parseFloat(HOME_LONGITUDE) });
        const [streetViewModal, setStreetViewModal] = useState(false)
        const [siteInfo, setSiteInfo] = useState(null)

        const scrollRef = useRef(null)

        useEffect(() => {
            let fDate = moment(fromDate).isValid() ? fromDate : moment(Date()).format('YYYY-MM-DD')
            let tDate = moment(toDate).isValid() ? toDate : moment(Date()).add(10,'day').format('YYYY-MM-DD')
            // let timeNow = moment(Date()).format('HH:mm:ss')
            
            const success = () => {
                setScheduleLoader(false)
            }
            setScheduleLoader(true)
            let filter = `from=${fDate}&to=${tDate}`
           
            fetch_filterd_schedules(screen_type, 1, filter, [], fDate, tDate, success)
            
        }, [screen_type, setScheduleLoader, fetch_filterd_schedules, fromDate, toDate])
    

        let frmd = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(4, 'w').format('YYYY-MM-DD')}`

        let applyButtonDisabled = true
        
    let changeStatusDate = false
    let changeStatusTeam = false
    let changeStatusVehicle = false


    if (selectedJob) {
        if (selectedJob.start_date !== StartDate || selectedJob.start_time !== StartTime || selectedJob.end_time !== EndTime || selectedJob.end_date !== EndDate || selectedJob.status !== schduleStatus) {
            changeStatusDate = true
        } else {
            changeStatusDate = false
        }
        if (selectedJob.team_employees && selectedJob.team_employees.length !== selectedTeamArray.length) {
            changeStatusTeam = true
        } else {
            changeStatusTeam = false
        }
        let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === selectedJob.vehicle)
        if (vehcl_data) {

            if (vehcl_data.id !== primeVehicle) {
                changeStatusVehicle = true
            } else {
                changeStatusVehicle = false
            }

        } else {
            if (primeVehicle) {
                changeStatusVehicle = true
            } else {
                changeStatusVehicle = false
            }
        }
    }

    if (changeStatusDate || changeStatusTeam || changeStatusVehicle) {
        applyButtonDisabled = false
    }

    // let vehicles_arr = []
    // let total_column_length = 0;
    // // let container_width = maximize ? 90 : 64;
    // // let one_cell_width = 5.2;
    // if (schedules_list && schedules_list.length > 0) {
    //     vehicles_arr = schedules_list[0]&& schedules_list[0].vehicles ? schedules_list[0].vehicles : [];
    //     total_column_length = vehicles_arr.length;
    //     // one_cell_width = (container_width / (total_column_length));
    //     // initial_cell_width = one_cell_width;
    // }

        const schedule_edit_btn_fx = (compleObj) => {

            const success = (res) => {
                setModal(false)
                setSelectedJob('')
                setPrimeVehicleDriver(null)
                setAdditionalVehTeamArr([])
                setContentLoader(null)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Scheduled Job Updated.',
                    showConfirmButton: false,
                    timer: 1500
                })
                //recall joblist api
    
            }
    
            const failed = () => {
                setContentLoader(null)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            let data = {
                'id' : selectedJob.id,
                'start_date' : StartDate,
                'start_time' : StartTime + ':00',
                'end_time'   : EndTime + ':00',
                'end_date'   : EndDate ,
                'additional_vehicle_drivers' : additionalVehTeamArr,
                'status'     : schduleStatus,
                'primary_vehicle_driver' : primeVehicleDriver ? primeVehicleDriver : '',
                'vehicle'    : primeVehicle,
                'purchase_order_number'     : compleObj ? compleObj.ponumber : '',
                'signature_name'            : compleObj ? compleObj.signName : '',
                'extracted_waste_type'      : compleObj ? compleObj.chngWasteType : '',
                'extracted_litres_of_waste' : compleObj ? compleObj.chngWasteLtr : '',
                'compleObj'                 : compleObj
            }                
                if(compleObj.jobStatusTime){
                    let currDate = moment(StartDate)
                    let i = 0
                    let obj = {}
                    for(const vals in compleObj.jobStatusTime){
                        console.log(compleObj.jobStatusTime[vals],moment(`${compleObj.jobStatusTime[vals]}`).isValid())
                        if(moment(`${compleObj.jobStatusTime[vals]}`).isValid()){
                        let currStatusDate = moment(`${compleObj.jobStatusTime[vals]}`)
                        let setupDate = null
                        if(i===0){
                            currDate.set({ 'hour' : currStatusDate.get('hour'), 'minute' : currStatusDate.get('minute'), 'second' : currStatusDate.get('second') })
                            setupDate = currDate
                        }else{
                            currStatusDate.set({
                                'year' : currDate.get('year'), 'month' : currDate.get('month'), 'date' : currDate.get('date')
                            })
                            if(currStatusDate.diff(currDate) >= 0){
                                setupDate = currStatusDate
                            }else{
                                setupDate = currStatusDate.add(1,'day')
                            }

                        }
                        Object.assign(obj, { [`${vals}`] : `${moment(setupDate).format('YYYY-MM-DD HH:mm:ss')}`})
                        
                        console.log('LOOPDATA=>',obj)
                        // data.append(`${vals}`,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'))
                        i++

                        console.log('I=>',i,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'), vals)
                        }
                    }

                    Object.assign(data, obj)

                }
            console.log('FINAL=>',data)
                
            // // }
            // console.log(data, screen_type, 'SCHEDULEEDIT')
            if(compleObj && ((compleObj.before_pics && compleObj.before_pics.length>0) 
            || (compleObj && compleObj.videoGallery && compleObj.videoGallery.length>0)
            || (compleObj && compleObj.signImage && typeof compleObj.signImage!=="string")
            ||  (compleObj.after_pics && compleObj.after_pics.length>0) || ( compleObj.gallery_pics && compleObj.gallery_pics.length>0))){

                Swal.fire({
                    position : 'top-end',
                    icon : 'info',
                    title : 'Uploading Media',
                    showLoading : true
                })
                const cloeseSwalFn = () =>{
                    Swal.close()
                }
                addScheduleImg(selectedJob.id, 
                    ((compleObj.before_pics && compleObj.before_pics.length>0) 
            ||  (compleObj.after_pics && compleObj.after_pics.length>0) || ( compleObj.gallery_pics && compleObj.gallery_pics.length>0)) ?
                    compleObj : null, 
                    (compleObj && compleObj.videoGallery && compleObj.videoGallery.length>0) ? compleObj.videoGallery : null, 
                    (compleObj && compleObj.signImage && typeof compleObj.signImage!=="string") ? compleObj.signImage : null,
                    cloeseSwalFn
                    , null, "image")
            }

            schedule_edit_job(screen_type, data, null, success, failed)

            fetch_jobs(screen_type, frmd, jobTab)
        }
    
    
        const setTeamApi = () => {
            const success = (res) => {
                setModal(false)
                setContentLoader(null)
                fetch_jobs(screen_type, frmd, jobTab)
                setSelectedJob('')
            }
            const failed = (res) => {
                console.log(res)
                setContentLoader(null)
            }
            let data = new FormData()
            data.append('id', selectedJob.id)
            selectedTeamArray.map((item, key) => {
                data.append('members', item)
                return null
            })
                team_setting(screen_type, data, null, success, failed)
            
        }
    
    
        const setVehicleApi = () => {
            const success = (res) => {
                setModal(false)
                setContentLoader(null)
                fetch_jobs(screen_type, frmd, jobTab)
                setSelectedJob('')
            }
            const failed = (res) => {
                setContentLoader(null)
                //    console.log(res)
            }
            let primeData = new FormData()
            primeData.append('id', scheduleId)
            primeData.append('vehicle', primeVehicle)
            let data = new FormData()
            for (let i = 0; i < vehicleVal.length; i++) {
                // vehicleVal.map((item,_)=>{
                //     console.log(item, typeof item)
                data.append('vehicles', vehicleVal[i])
            }
            console.log("DATA-PREVDATA-SCHD", vehicleVal, primeVehicle)
            vehicle_setting(screen_type, data, primeData, scheduleId, null, success, failed)
        }

        const deleteScheduleApiFx = (scheduleId) => {
            const success = () => {
                setContentLoader(null)
                fetch_jobs(screen_type, frmd, jobTab)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Scheduled Job Deleted.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            const failed = () => {
                setContentLoader(null)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            delete_schedule(screen_type, scheduleId, null, success, failed)
    
        }
    
        const updateSchedule = (state1=null, state2=null, state3=null, fn=null, compleObj=null) => {
            console.log("SCHEDIT", state3, vehicleVal)
            if (state1 || changeStatusDate || compleObj) {
                schedule_edit_btn_fx(compleObj)
            }
            if (state2 || changeStatusTeam) {
                setTeamApi()
            }
            if (state3 || changeStatusVehicle)
                setVehicleApi()
                setContentLoader(null)
            fetch_jobs(screen_type, frmd, jobTab)
            if(fn)
            fn(false)
        }
    
        const addScheduleSignImg = (id, shdlImages, handleClose, setLoader=null) => {
            const success = (response) => {
                console.log('addSchdSignImg',response)
            }
    
            const failed = (res) => {
                console.log('upload failed', res)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.app_data ? res.app_data : 'Something went wrong!'
                })
                if(setLoader)
                setLoader(false)
            }
    
            let data = new FormData()
            data.append('id', id)
            console.log('Files',shdlImages)
            // let i;
            // for (i = 0; i < shdlImages.length; i++) {
                let imgData = shdlImages
                // let imgName = imgData.name ? imgData.name : `signatureJob${id}` 
                data.append('image', imgData, `signatureJob${id}`)
            // }
            if(setLoader)
            setLoader(true)
            add_image_signschedule(screen_type, data, null, success, failed)
        }
    
        const addScheduleImg = (id, shdlImages, shdlVid, shdlSign, handleClose, setLoader=null, fileType="image") => {
            let currFn = 0
            const success = (response) => {
                if(setLoader)
                setLoader(false)
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }
            }
            const failed = (res) => {
                if(setLoader)
                setLoader(false)
                console.log('upload failed', res)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.app_data ? res.app_data : 'Something went wrong in Images uploading!'
                })
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }
            }
            const vidsuccess = (response) => {
                if(setLoader)
                setLoader(false)
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }
            }
            const vidfailed = (res) => {
                if(setLoader)
                setLoader(false)
                console.log('upload failed', res)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: res.app_data ? res.app_data : 'Something went wrong in videos uploading!'
                })
                if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                    handleClose()
                }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                    handleClose()
                }else{
                    currFn = currFn+1
                }

            }
                let data = new FormData()
                data.append('id', id)
                data.append('schedule_id', selected_job?.schedule?.id)
                if(shdlImages){
                    if(shdlImages.gallery_pics && shdlImages.gallery_pics.length>0){
                        let i;
                        for (i = 0; i < shdlImages.gallery_pics.length; i++) {
                            let imgData = shdlImages.gallery_pics[i]
                            let imgName = imgData.name
                            data.append('image', imgData, imgName)
                        }
                    
                    }
                    if(shdlImages.before_pics && shdlImages.before_pics.length>0){
                        let i;
                        for (i = 0; i < shdlImages.before_pics.length; i++) {
                            let imgData = shdlImages.before_pics[i]
                            let imgName = imgData.name
                            data.append('before_pics', imgData, imgName)
                        }
                    
                    }
                    if(shdlImages.after_pics && shdlImages.after_pics.length>0){
                        let i;
                        for (i = 0; i < shdlImages.after_pics.length; i++) {
                            let imgData = shdlImages.after_pics[i]
                            let imgName = imgData.name
                            data.append('after_pics', imgData, imgName)
                        }
                    
                    }    
                }

                if(shdlSign){
                    data.append('signature', shdlSign, `signatureJob${id}`)
                }
                if((shdlImages && 
                    ((shdlImages.gallery_pics && shdlImages.gallery_pics.length>0) || (shdlImages.before_pics && shdlImages.before_pics.length>0) ||
                    (shdlImages.after_pics && shdlImages.after_pics.length>0))) || (shdlSign && shdlSign?.length>0)){
                    Api('post-form',SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER,data,success,failed)
                }


                if(shdlVid){
                    let data2 = new FormData()
                    data2.append('schedule_id', selected_job?.schedule?.id)
                    let i;
                    for (i = 0; i < shdlVid.length; i++) {
                        let imgData = shdlVid[i]
                        let imgName = imgData.name
                        if(IsVideoFileUpload(imgData.size))
                            data.append('videos', imgData, imgName)
                    }
                    if(setLoader)
                        setLoader(true)
                    add_video_schedule(screen_type, data, true, vidsuccess, vidfailed)
                    
                }
            console.log('ADDSCHEDULEIMG',id,shdlImages,shdlVid)
        }

        const openJobDetailModal = (id) => {

            const succ = (res) =>{
                console.log('JobDetailModal', res.data)
                if(res.data){
                setQuoteInfos(res.data)
                setModal(false)
                setquoteModal(true)}
                else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                
                setContentLoader(null)
            }
            }

            const fail = (err) =>{
                setContentLoader(null)
                
                    Swal.fire({
                       icon: 'error',
                       title: 'Oops...',
                       text: err && err.app_data ? err.app_data : 'Something went wrong!'
                   })
            }

            Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`,null, succ, fail)
        }
    
        const reopenScheduleModal = () => {
            setModal(true)
        }
    

    const getVehicleVal = (val) => {
        Api('get', `${SCHEDULE_ADDITIONAL_VEHICLE}${val}/`).then(res => {
            console.log(res)
            if (res.additional_vehicles)
                setVehicleVal(res.additional_vehicles.map(item => { return item.id }))
            else
                setVehicleVal([])

        })
    }

    const ScrollPos = (val) => {
        const { top } = val
        console.log(val,'TOP=>',top,stopLoading,scheduleLoader)
        // if((top > 0.9 && top<1) && stopLoading)
        if ((top === 1) && stopLoading && !scheduleLoader) {
            rldSchd()
            setScheduleLoader(true)
            let newFrmDay = moment(toDate).add(1,'day')
            let newToDay = moment(toDate).add(10,'days')

            let filter = `from=${moment(newFrmDay).format('YYYY-MM-DD')}&to=${moment(newToDay).format('YYYY-MM-DD')}`
            let weekArr = []
            for(let i=0; i<moment(newToDay).diff(moment(newFrmDay),'days')+1; ++i){ 
                weekArr.push({
                    weekday : moment(newFrmDay).add(i,'day').format('dddd'),
                    date : moment(newFrmDay).add(i,'day').format('DD-MM-YYYY')
                })
            }
            let oldWeek = weekDaysArr
            setWeekDaysArr([...oldWeek, ...weekArr])
            const success = () =>{
                setScheduleLoader(false)
            }
            fetch_filterd_schedules(screen_type, 1, filter, schedules_list, moment(newFrmDay).format('YYYY-MM-DD'), moment(newToDay).format('YYYY-MM-DD'), success)
            let availFilter = 'start=' + moment(newFrmDay).format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(newFrmDay).format('HH:mm:ss') + '&&end=' + moment(newToDay).format('YYYY-MM-DD')
                availFilter = availFilter + ' ' + moment(newToDay).format('HH:mm:ss')
                fetch_availabilityVeh(availFilter, screen_type)
                fetch_availabilityEmp(availFilter)
                setInputToDate(moment(toDate).add(10,'days'))
        }
    }

    const TimeMeridium = (v) =>{
        return moment(v,'HH:mm:ss').format('hh:mm A')
    }


    const SingleFilled2 = ({ item, name, day, date, vehicleId, primary_vehicle_driver_name }) => {
        // console.log('==============>',item)
        // console.log('==============>',name)
        // console.log('==============>',day)

        const { address, vehicle, primary_vehicle_driver, status, tab_type, client, job, frequency, start_date, end_date, start_time, end_time,
            id, waste_type_str, type_of_waste_str_profile_name, 
            //outsourced_job, outsourced_company_name, quote, job_card_code, 
            coordinates, additionalvehicle_id, additionalvehicledrivers, 
         } = item;

        if (zoomVal === 0) {
            return (
                <div className="shd-single-filled-container" >
                                        
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', height: 14, width: '100%', backgroundColor: 'transparent', color: '#fff' }}>
                    <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                
                                setMarkers({
                                    lat : parseFloat(coordinates?.latitude),
                                    lng : parseFloat(coordinates?.longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                      </Tooltip>  
                        <Edit
                            onClick={() => {
                                getVehicleVal(id)
                                setContentLoader(id)
                                sales_job_viewFx(job)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(start_date)
                                setStartTime(start_time)
                                setEndTime(end_time)
                                setEndDate(end_date)
                                setScheduleStatus(status)
                                setScheduleId(id)
                                // setCommentsArr(comments)
                                setAllVehicleArrObj(vehicle_listDateTime?.filter(el => additionalvehicle_id?.includes(el?.id)))
                                setAdditionalVehTeamArr(employee_listDateTime?.filter(el => additionalvehicledrivers?.includes(el?.id)))
                                setPrimeVehicleDriver(primary_vehicle_driver)

                                let availFilter = 'start=' + moment(start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + start_time + '&&end=' + moment(end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + end_time
                                fetch_availabilityVeh(availFilter, screen_type)

                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = item.team_employess_id
                                // if (vehcl_data) {
                                    setPrimeVehicle(vehicle)
                                // }
                                savedJobDataFx(item)
                                setSelectedTeamArray(employee_data)
                                setModal(true)
                            }}
                            style={{ fontSize: '.8rem', marginRight: 5, cursor: 'pointer' }} />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            getVehicleVal(item.id);
                            setContentLoader(item.id)
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer', fontSize: '.8rem', height: 20, padding: '4px 0 ' }}>Job {job}</button>

                    {/* <p style={{ fontSize: '.8rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    {
                        primary_vehicle_driver_name ?
                    <div className='shd-employee-names' >
                        <p style={{ fontSize: '.8rem' }}>
                            {primary_vehicle_driver_name}
                        </p>
                        {
                                additionalvehicle_id && additionalvehicle_id?.length > 0 &&
                                vehicle_listDateTime?.filter(el => additionalvehicle_id?.includes(el?.id))?.map((el) => {
                                    return <p style={{ fontSize: '.8rem' }}>{el?.registration}</p>
                                })
                            }
                    </div>
                    :   null }
                    
                    <p style={{
                        fontWeight: 'bold', fontSize: '.8rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px', textTransform : 'capitalize',
                        backgroundColor: status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }} >{
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    {
                        item?.unread_comments_exists &&
                        <div className='floatingCommentIconRedDot'>
                            <AnnouncementRounded style={{ color : 'red', stroke : 'white' }} />
                        </div>
                    }
                    <Tooltip title={ tab_type==='waste' ? 
                        <p style={{ wordBreak : 'break-all' }}>Waste Type : {type_of_waste_str_profile_name ?
                                                                 `${type_of_waste_str_profile_name}(${waste_type_str})` 
                                                                : waste_type_str},<br/>Frequency : {frequency} Weeks</p> : ''}>
<p style={{ fontWeight: 'bold', fontSize: '.8rem', margin: '7px 0' }} >{client}</p>
                                                                </Tooltip>
                    <p style={{ fontWeight: 'bold', fontSize: '.8rem', color: '#6363c2', textTransform:'capitalize', position:'relative' }} >Enviro {tab_type}</p>
                    <p style={{ fontSize: '.8rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }
                </div>
            )
        } else if (zoomVal === 1) {
            return (
                <div className="shd-single-filled-container">
                                       
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', height: 16, alignItems: 'center', width: '100%', backgroundColor: 'transparent', color: '#fff' }}>
                    <Tooltip title={'Site Street View'}>
                        <StreetviewRounded
                            onClick={() => { 
                                
                                setMarkers({
                                    lat : parseFloat(coordinates?.latitude),
                                    lng : parseFloat(coordinates?.longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                        </Tooltip>
                        <Edit
                            onClick={() => {
                                sales_job_viewFx(job)
                                setContentLoader(id)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(start_date)
                                setStartTime(start_time)
                                setEndTime(end_time)
                                setEndDate(end_date)
                                setScheduleStatus(status)
                                setScheduleId(id)
                                // setCommentsArr(comments)
                                setAllVehicleArrObj(vehicle_listDateTime?.filter(el => additionalvehicle_id?.includes(el?.id)))
                                setAdditionalVehTeamArr(employee_listDateTime?.filter(el => additionalvehicledrivers?.includes(el?.id)))
                                setPrimeVehicleDriver(primary_vehicle_driver)
                                getVehicleVal(id)
                                
                                let availFilter = 'start=' + moment(start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + start_time + '&&end=' + moment(end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + end_time
                                fetch_availabilityVeh(availFilter, screen_type)
                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = item.team_employess_id
                                // if (vehcl_data) {
                                    setPrimeVehicle(vehicle)
                                // }
                                savedJobDataFx(item)
                                setSelectedTeamArray(employee_data)
                                setModal(true)
                            }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }} />
                    </div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContentLoader(item.id)
                            getVehicleVal(item.id);
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer', fontSize: '1rem' }}>Job {job}</button>
                    {/* <p style={{ fontSize: '1rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    {
                        primary_vehicle_driver_name ?
                    <div className='shd-employee-names' >
                        <p style={{ fontSize: '.8rem' }}>{primary_vehicle_driver_name}</p>
                        <p className='shd-employees-tooltip'>{
                            vehicle_listDateTime?.find(el => el?.id===vehicle)?.registration??''
                        }<StarRounded style={{ color:'white', fontSize:'inherit' }} /></p>
                    </div>
                    :   null }
                    {
                        additionalvehicle_id && additionalvehicle_id?.length > 0 ?
                        vehicle_listDateTime?.filter(el => additionalvehicle_id?.includes(el?.id))?.map((el) => {
                            return (<div className='shd-employee-names' >
                            <p style={{ fontSize: '.8rem' }}>{el?.registration}</p>
                        </div>)
                        })
                        : null
                    }
                   
                    <p style={{
                        fontWeight: 'bold', fontSize: '1rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px', textTransform : 'capitalize',
                        backgroundColor:status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }} >{
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                    {
                        item?.unread_comments_exists &&
                        <div className='floatingCommentIconRedDot'>
                            <AnnouncementRounded style={{ color : 'red', stroke : 'white' }} />
                        </div>
                    }
                    <p style={{ fontWeight: 'bold', fontSize: '1rem', margin: '7px 0' }} >{client}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1rem', color: '#6363c2', textTransform : 'capitalize' }} >Enviro {tab_type}</p>
                    {   tab_type === 'waste' ?
                        <p style={{ fontWeight: 'normal', fontSize: '1rem', color: '#6363c2',wordWrap:'break-word' }} >Waste Type : {type_of_waste_str_profile_name ?
                            `${type_of_waste_str_profile_name}(${waste_type_str})` 
                           : waste_type_str}</p> : null
                    }
                    <p style={{ fontWeight: 'normal', fontSize: '1rem', color: '#6363c2' }} >Frequency : {frequency} Weeks</p>
                    <p style={{ fontSize: '1rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }
                </div>
            )
        } else if (zoomVal === 2) {
            return (
                <div className="shd-single-filled-container">
                                       
                    <div style={{ justifyContent: 'space-between', height: 28, backgroundColor: 'transparent', color: 'black', width: '100%', padding: '0' }} className="shd-flex-center">
                        <p style={{ marginLeft: 10 }}>{date} {day}</p>
                        <Tooltip title={'Site Street View'}>
                            <StreetviewRounded
                            onClick={() => { 
                                
                                setMarkers({
                                    lat : parseFloat(coordinates?.latitude),
                                    lng : parseFloat(coordinates?.longitude) 
                                })
                                setSiteInfo({ address : address })
                                setStreetViewModal(true)
                             }}
                            style={{ fontSize: '1rem', marginRight: 6, cursor: 'pointer' }}
                        />
                        </Tooltip>
                        <Edit
                            onClick={() => {
                                sales_job_viewFx(job)
                                setContentLoader(id)
                                setTypeOfQuoteModal(false)
                                setSelectedJob(item)
                                setStartDate(start_date)
                                setStartTime(start_time)
                                setEndTime(end_time)
                                setEndDate(end_date)
                                setScheduleStatus(status)
                                setScheduleId(id)
                                // setCommentsArr(comments)
                                setAllVehicleArrObj(vehicle_listDateTime?.filter(el => additionalvehicle_id?.includes(el?.id)))
                                setAdditionalVehTeamArr(employee_listDateTime?.filter(el => additionalvehicledrivers?.includes(el?.id)))
                                setPrimeVehicleDriver(primary_vehicle_driver)
                                getVehicleVal(id)
                              
                                let availFilter = 'start=' + moment(start_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + start_time + '&&end=' + moment(end_date).format('YYYY-MM-DD')
                                availFilter = availFilter + ' ' + end_time
                                fetch_availabilityVeh(availFilter, screen_type)
                                // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                let employee_data = item.team_employess_id
                                // if (vehcl_data) {
                                    setPrimeVehicle(vehicle)
                                // }
                                savedJobDataFx(item)
                                setSelectedTeamArray(employee_data)
                                setModal(true)
                            }}
                            style={{ fontSize: '1rem', marginRight: 10, cursor: 'pointer', color: 'white' }} />
                    </div>


                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContentLoader(item.id)
                            getVehicleVal(item.id);
                            setTypeOfQuoteModal(true);
                            openJobDetailModal(item.job);
                        }}

                        className="shd-job-btn" style={{ cursor: 'pointer' }}>Job {job}</button>

                    {/* <p style={{ fontSize: '1.1rem', marginTop: 7 }}>Shift - {shift}</p> */}
                    {
                        primary_vehicle_driver_name ?
                    <div className='shd-employee-names' >
                            <p style={{ fontSize: '1rem', marginTop: 7 }}>
                                {primary_vehicle_driver_name}
                            </p>
                        <p className='shd-employees-tooltip'>
                            {vehicle}
                            <StarRounded style={{ color:'white', fontSize:'inherit' }} /><br />
                        </p>

                    </div>
                    :   null }
                    {
                        additionalvehicle_id && additionalvehicle_id?.length > 0 ?
                        vehicle_listDateTime?.filter(el => additionalvehicle_id?.includes(el?.id))?.map((el) => {
                            return (<div className='shd-employee-names' >
                            <p style={{ fontSize: '1rem' }}>{el?.registration}</p>
                        </div>)
                        })
                        : null
                    }
                   {
                        item?.unread_comments_exists &&
                        <div className='floatingCommentIconRedDot'>
                            <AnnouncementRounded style={{ color : 'red', stroke : 'white' }} />
                        </div>
                    }
                    <p style={{ fontWeight: 'bold', fontSize: '1.1rem', marginTop: '7px' }} >{client}</p>
                    <p style={{ fontSize: '1.1rem', margin: '7px 0' }} >{address}</p>
                    <p style={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#6363c2', textTransform : 'capitalize' }} >Enviro {tab_type}</p>
                    {   tab_type === 'waste' ?
                        <p style={{ fontWeight: 'normal', fontSize: '1.1rem', color: '#6363c2',wordWrap:'break-word' }} >Waste Type : {type_of_waste_str_profile_name ?
                            `${type_of_waste_str_profile_name}(${waste_type_str})` 
                           : waste_type_str}</p> : null
                    }
                    <p style={{ fontWeight: 'normal', fontSize: '1.1rem', color: '#6363c2' }} >Frequency : {frequency} Weeks</p>
                    <p style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '7px' }} >{start_date} to {end_date}</p>
                    <p style={{ fontSize: '1rem', margin: '7px 0' }} >{TimeMeridium(start_time)} to {TimeMeridium(end_time)}</p>
                    <p style={{
                        fontSize: '1.1rem', margin: '7px 0', padding: '.3rem', borderRadius: '20px',
                        textTransform : 'capitalize',
                        backgroundColor: status === 'completed' ? 'var(--cl_green)' 
                        : status === 'pending' || status === 'departed_enviro_facility' || status === 'job_started' || status === 'job_finished'
                        
                         ? 'blanchedalmond' : 'var(--cl_orange)'
                    }}>Status: {
                        status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                    :   status === 'job_started'    ? 'Job Started'
                    :   status === 'job_finished'   ?   'Job Finished'
                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                    :   status}</p>
                  
                    {
                        contentLoader===item.id ?
                            <div 
                                style={{ width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.5)', position:'absolute', display:'flex', alignItem:'center', justifyContent:'center' }} >
                                <CircularProgress style={{ color:'white', marginTop:'100%' }} size={25}/>
                            </div>
                        :   null
                    }

                </div>
            )
        }
        else{
            return <CircularProgress size={20} />
        }
    }

    
    return (
        <div className="shdl-main-body">

                {
                    (filtered_emp_list && filtered_emp_list.length === 0 && !(scheduleLoader || filterLoader)) ?
                        (
                        <div style={{ display: 'flex', height: '70.4vh', justifyContent: 'center', alignItems: 'center' }} >
                            <NoDatasScreen />
                        </div>
                        )
                    :
                    
                        scheduleLoader || filterLoader ? 
                        (
                            <Scrollview
                                autoHide
                                style={{ height: '70.4vh', width: maximize ? '100%' : '48vw', position: 'relative' }}
                                className='scheduleScrollInsdDiv'
                                onUpdate={ScrollPos}
                            >
                                <SchedulelistVehicleshimmer/>
                                <SchedulelistShimmer/>
                            </Scrollview>
                        ) 
                        : (

                            <Scrollview
                                autoHide
                                style={{ height: '70.4vh', width: maximize ? '100%' : '48vw', position: 'relative' }}
                                className='scheduleScrollInsdDiv'
                                ref={scrollRef}
                                onUpdate={ScrollPos}
                            >

                                <div
                                    className="shd-new-scheduler-col-layout shd-new-scheduler-col-layout-head"
                                    id="scrollCalGrid"
                                    style={{
                                        gridTemplateColumns: `80px repeat(${filtered_emp_list?.length}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`,
                                        width:'100%'
                                    }}
                                >
                                    <div className="shd-new-scheduler-item-head"></div>
                                    {
                                        filtered_emp_list && filtered_emp_list.map((item, key) => {
                                            return (
                                                <Tooltip title={<p style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }}>{item.name}</p>}>
                                                    <div key={key} className="shd-new-scheduler-item-head"
                                                 style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16, display:'flex', flexDirection:'column', flex:'2', flexWrap:'wrap' }}>
                                                     <span>
                                                        <Avatar src={item.dp} style={{ width: zoomWidth > 9 ? 35 : zoomWidth * 4, height: zoomWidth > 9 ? 35 : zoomWidth * 4 }}/>
                                                     </span>
                                                     <span style={{ marginTop:'2%', fontSize: zoomWidth > 14 ? 14 : zoomWidth * 2 > 10 ? zoomWidth * 2 : 14 }}>
                                                     {item.name && item.name.length>15 ? `${item.name.substr(0,5)} ...${item.name.substr(item.name.length-5, item.name.length)}` : item.name }
                                                     </span>
                                                    
                                                </div>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </div>
                                <>
                                    {
                                        weekDaysArr && weekDaysArr?.length!==0 ?
                                        weekDaysArr.map((item, k)=>{
                                            console.log('weekdaysarr',item)
                                            return(
                                                <div key={k}
                                                    className="shd-new-scheduler-col-layout"
                                                    style={{
                                                        gridTemplateColumns: `80px repeat(${filtered_emp_list?.length}, ${zoomWidth < 5.29 ? '1fr' : `${zoomWidth}vw`})`
                                                    }}
                                                >
                                                    <div key={k} style={{ height: '100%', minHeight:'7.1vh', position: 'sticky', left: 0, zIndex: 3, backgroundColor: '#fff' }} className="shd-new-scheduler-item">
                                                        <p>{item.weekday}</p>
                                                        <p>{item.date}</p>
                                                    </div>
                                                    {
                                                        filtered_emp_list && filtered_emp_list.map((el, key)=>{
                                                            return(
                                                                <div key={key} className="shd-new-scheduler-item-container"
                                                                    style={{ backgroundColor:`rgba(15, 153, 208,${k%2===0 ? '0':'0.2'})` }}
                                                                >
                                                                    <table>
                                                                        <tbody style={{ height: '100%' }}>
                                                                            {
                                                                                scheduleLoader ?
                                                                                    <ScheduleBoxShimmer zoomWidth={zoomWidth} shimmerType='m'/>
                                                                                :
                                                                                schedules_list.filter(em => 
                                                                                        em.primary_vehicle_driver===el.id &&
                                                                                        moment(em.start_date).format('DD-MM-YYYY')===item.date
                                                                                    ).map((itemShit, key) => {
                                                                                        return(
                                                                                            <tr style={{ verticalAlign:'top' }}>
                                                                                                <td style={{ width: `${zoomWidth}vw`}}>
                                                                                                    <SingleFilled2 item={itemShit} key={key} 
                                                                                                        name={el.name} 
                                                                                                        day={item.weekday} 
                                                                                                        date={item.date} 
                                                                                                        primary_vehicle_driver_name={
                                                                                                            vehicle_listDateTime?.find(el => el.id===itemShit.vehicle)?.registration
                                                                                                        }
                                                                                                        vehicleId={el.id} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                    // else return(
                                                                                    // <tr style={{ height: '7.2vh' }}>
                                                                                    //     <td style={{ width: `${zoomWidth}vw` }}>
                                                                                    //         <Tooltip 
                                                                                    //             enterDelay={1000}
                                                                                                
                                                                                    //             title={<p style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }}>{el.name}</p>}>
                                                                                    //         <div className="shd-new-scheduler-item shd-new-scheduler-item-filler"
                                                                                    //         style={{ fontSize: zoomWidth > 16 ? 16 : zoomWidth > 20 ? 20 : 16 }}
                                                                                    //         >
                                                                                    //             {el.name && el.name.length>10 ? `${el.name.substr(0,5)} ...${el.name.substr(el.name.length-5, el.name.length)}` : el.name }
                                                                                    //         </div>
                                                                                    //         </Tooltip>
                                                                                            
                                                                                    //     </td>
                                                                                    // </tr>
                                                                                    // )
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div style={{ height: 20, width: '100%' }} />
                                                </div>
                                            )
                                        })
                                        :
                                        <CircularProgress/>
                                    }
                                    
                                    
                                        <SchedulelistShimmer total_column_length={filtered_emp_list?.length} zoomWidth={zoomWidth} listShort={true}/>
                                    

                                </>


                            </Scrollview>

                        )
                }
            {   modal && selected_job &&
            <JobModal
                modalStatus={modal}
                modalAction={setModal}
                selectedJob={selectedJob}

                openJobDetailModal={openJobDetailModal}

                StartDate={StartDate}
                setStartDate={setStartDate}
                StartTime={StartTime}
                setStartTime={setStartTime}
                EndTime={EndTime}
                setEndTime={setEndTime}
                EndDate={EndDate}
                setEndDate={setEndDate}

                scheduleId={scheduleId}

                vehicleVal={vehicleVal}
                setVehicleVal={setVehicleVal}

                primeVehicle={primeVehicle}
                setPrimeVehicle={setPrimeVehicle}

                schduleStatus={schduleStatus}
                setScheduleStatus={setScheduleStatus}

                selectedArr={selectedTeamArray}
                setSelectedArr={setSelectedTeamArray}

                buttonDisabled={applyButtonDisabled}

                updateScheduleFx={updateSchedule}
                deleteScheduleFx={deleteScheduleApiFx}

                addScheduleImg={addScheduleImg}
                addScheduleSignImg={addScheduleSignImg}

                // CommentsArr={CommentsArr}
                // setCommentsArr={setCommentsArr}
                fetch_schedule_list={fetch_schedule_list}
                screen_type={screen_type}
                prevData={schedules_list}

                fetch_availabilityEmp={fetch_availabilityEmp}
                fetch_availabilityVeh={fetch_availabilityVeh}

                primeVehicleDriver={primeVehicleDriver}
                setPrimeVehicleDriver={setPrimeVehicleDriver}
                additionalVehTeamArr={additionalVehTeamArr}
                setAdditionalVehTeamArr={setAdditionalVehTeamArr}
                setContentLoader={setContentLoader}
                allVehArrObj={allVehicleArrObj}

            />
            }
            {   quoteModal &&
            <QuoteModal
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                reopenScheduleModal={typeOfQuoteModal ? null : reopenScheduleModal}
                vehicleArr={vehicleVal}
                vehicleList={vehicle_list}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                setContentLoader={setContentLoader}
                commentRefreshFn={()=>{
                    fetch_jobs(screen_type, frmd, jobTab)
                }}
            />
            }
            {   QuoteHistoryModal &&
            <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
            }
            { streetViewModal ?
            <Suspense fallback={
                <div style={{ background: `var(--cl_ivery)`, color: `black`, padding: 5, borderRadius: `50%` }}>
                <img src={EnviroLogoLoader} alt="Logo_loader" style={{ width:'20%' }} />
            </div>
            }>
            <StreetViewModal 
                modalStatus={streetViewModal}
                modalAction={setStreetViewModal}
                latLngMarks={markers}
                siteInfo={siteInfo}
            />
            </Suspense>
            : null
            }
            </div>
    );
}

const mapStateToProps = (state) => {
    const { schedules_list, schedules_page, vehicle_list, stopLoading, schd_loader, employee_listDateTime, vehicle_listDateTime,
        availStrDate, availEndDate, job_list_weekFilter, jobTab } = state.schedule
    const { selected_job } = state.jobView

    const filtered_emp_list = employee_listDateTime.filter(el => !el.terminated_status)

    return { schedules_list, schedules_page, vehicle_list, stopLoading, schd_loader, availStrDate, availEndDate, job_list_weekFilter, jobTab, selected_job, filtered_emp_list, vehicle_listDateTime }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_schedule_list     : (type, page, success, prevData) => dispatch(fetch_schedules(type, page, success, prevData)),
        fetch_filterd_schedules : (type, page, filter, prevData, StartDate, EndDate, fn) => dispatch(
            fetch_schedules_sorted_NewMode(type, page, filter, prevData, StartDate, EndDate, fn)
            ),
        schedule_edit_job       : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_setting            : (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        vehicle_setting         : (screen_type, data, primeData, shdId, schType, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, schType, success, failed)),
        delete_schedule         : (type, data, schType, success, failed) => dispatch(schedule_job_delete(type, data, schType, success, failed)),
        add_image_schedule      : (type, data, schType, success, failed) => dispatch(schedule_image_add(type, data, schType, success, failed)),
        add_video_schedule      : (type, data, schType, success, failed) => dispatch(schedule_video_add(type, data, schType, success, failed)),
        add_image_signschedule  : (type, data, schType, success, failed) => dispatch(schedule_imageSign_add(type, data, schType, success, failed)),
        
        fetch_jobs              : (type, frmd, jobTab) => { dispatch(fetch_job_list_readyForSchd(type, 1, frmd, jobTab)); },

        fetch_availabilityVeh   : (filter, tabType) => dispatch(fetch_Vehicle_avilabilities(filter, tabType)),
        fetch_availabilityEmp   : (filter) => dispatch(fetch_Employee_avilabilities(filter)),
        rldSchd                 : () => dispatch({ type: 'RELOAD_SCHD_LIST' }),
        sales_job_viewFx        : (id) => dispatch(sales_job_view(id)),
        savedJobDataFx          : (dataObj) => dispatch({ type: "SAVING_JOBDATA_TEMP", data: dataObj })
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Schedulecontent);
