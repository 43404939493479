import React from 'react'
import { CircularProgress } from '@material-ui/core'

function BufferModal() {

    return(
        <div className="refetchPermsModal">
            <div className="refetchPermsTitle">Your quotation is being generated. Please wait...</div>
            <div className="refetchPermsContent">
                <span>
                    <CircularProgress />
                </span>
            </div>
        </div>
          )
}

 export default BufferModal
 
