import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Scrollbars from '../../common/ImprovedScrollBar';
import { Modal } from '@material-ui/core';
import { CancelRounded, DeleteForeverRounded, EditRounded, SendRounded } from '@material-ui/icons';
import { connect } from 'react-redux';
import { comments_of_job, del_comments_of_job, fetch_comments_of_job } from './common/action';
import Swal from 'sweetalert2';
import moment from 'moment';
import { cl_blue, cl_green } from '../../common/Colors';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

function CommentsBoxModal({ modalAction, modalStatus, commentsList, fetchAllComments, updateComments, deleteComments, jobId=null, user, pos }) {
    const classes = useStyles();
    const [commentVal, setCommentVal] = useState('')
    const [onHoverK, setOnHoverK] = useState(null)
    const [editDelId, setEditDelId] = useState(null)

    const handleClose = () => {
        modalAction(false)
    };

    const updateCommentFx = () =>{
        let data = {
            'comment' : commentVal
        }
        const successFx = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Comment Added`,
                showConfirmButton: false,
                timer: 1500
            })
            setCommentVal('')

            fetchAllComments(jobId, 1, pos)
        }

        const failedFx = ()=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.'
            })
        }
        updateComments(jobId,'add',data,successFx, failedFx)
    }

    const editCommentFx = () =>{
        let data = {
            'comment' : commentVal
        }
        const successFx = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Comment Edited`,
                showConfirmButton: false,
                timer: 1500
            })
            setEditDelId(null)
            setCommentVal('')
            fetchAllComments(jobId, null, pos)
        }

        const failedFx = ()=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.'
            })
            setEditDelId(null)
        }
        updateComments(editDelId,'edit',data,successFx, failedFx)
    }


    const commentDelFn = (val) =>{
        const successFx = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Comment Deleted`,
                showConfirmButton: false,
                timer: 1500
            })
            fetchAllComments(jobId, -1, pos)
            setEditDelId(null)
        }

        const failedFx = ()=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong.'
            })
            setEditDelId(null)
        }
        Swal.fire({
            title: `Are you sure to Delete this comment ?`,
            text: `This action cannot be reverted`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: cl_blue,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Delete Comment'
        }).then((result) => {
            if (result.value) {
                deleteComments(val, successFx, failedFx)
            }
            else{
                setEditDelId(null)
            }
        })
    }

    return (
        <Modal
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        onBackdropClick={handleClose}
        hideBackdrop={false}
        >
            <div className='commentsBoxModalContent'  style={{ overflowY:'auto' }}>
                <div className='commentsBoxModalHeader'>
                    <div style={{ width:'95%', textAlign:'center', fontWeight:700 }}>Invoice Comments</div>
                    <div>
                    <CancelRounded onClick={handleClose}/>
                    </div>
                </div>
                <div className='commentsBoxCommentsListSection'>
                    <Scrollbars  style={{ height:'42vh', width:'100%', marginBottom : '.2%', position:'relative',
                        display:'flex', flexDirection:'column' }}>
                            {
                                commentsList && commentsList?.length > 0 ?
                                <>
                                    {
                                        commentsList?.map((item, k) => {
                                            let currUser = user && user.id ? 
                                                                item.employee_data.id===user.id : false 
                                            return(
                                                <div className='CMBCMessageChip'
                                                 style={{ justifyContent: currUser ? 'flex-end' : 'flex-start' }}>
                                                    <div className={ currUser ? 'CMBCUserMessageChip' : 'CMBCsentUserMessageChip'}>
                                                        <div className='CMBCMessageChipEditDelBox'
                                                            onMouseEnter={()=>setOnHoverK(item.id)}
                                                            onMouseLeave={()=>setOnHoverK(null)}
                                                            style={{
                                                                opacity : item.id===onHoverK ? 1 : 0
                                                            }}
                                                        >
                                                            <div onClick={()=>{
                                                                setEditDelId(item.id)
                                                                setCommentVal(item.comment)
                                                            }}>
                                                                <EditRounded/>
                                                            </div>
                                                            <div
                                                                onClick={()=>{
                                                                    commentDelFn(item.id)
                                                                }}
                                                            >
                                                                <DeleteForeverRounded/>
                                                            </div>
                                                        </div>
                                                        <div className={ currUser ? 'CMBCUserMessageChipName' : 'CMBCsentUserMessageChipName'}>
                                                            <p>{currUser ? 'You' : item.employee_data.name}</p>
                                                        </div>
                                                        <div className={ currUser ? 'CMBCUserMessageChipMsg' : 'CMBCsentUserMessageChipMsg'}>
                                                            {item.comment} 
                                                        </div>
                                                        <div className={ currUser ? 'CMBCUserMessageChipTime' : 'CMBCsentUserMessageChipTime'}>
                                                            <p>
                                                                {moment(item.created_date_time,['YYYY-MM-DDTHH:mm:ss']).isValid() ?
                                                                moment(item.created_date_time,['YYYY-MM-DDTHH:mm:ss']).format('DD MMM YYYY hh:mm a') : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                :
                                <div style={{ position:'absolute', top:'50%', left:'45%', color:'grey', fontWeight:'500' }}>
                                    <p>No Comments</p>
                                </div>
                            }
                    </Scrollbars>
                </div>
                <div className='commentsBoxCommentsAddSection'>
                    <div className='commentsBoxCommentsAddSectionInpdiv'>
                        <textarea
                            value={commentVal} placeholder='Enter Comment here...'
                            onChange={(txt)=>setCommentVal(txt.target.value)}
                        />
                    </div>
                    <div className='commentsBoxCommentsAddSectionSenddiv'>
                        {
                            editDelId ?
                                <EditRounded style={{ color:'white' }} onClick={()=> editCommentFx() }/>
                            :
                                <SendRounded style={{ color:'white' }} onClick={()=> updateCommentFx() }/>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}


const mapStateToProps = (state) =>{
    const { commentsList } = state.invoiceData
    const { user } = state.userLogin

    return { commentsList, user }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetchAllComments: (id, act, pos, success, failed) => dispatch(fetch_comments_of_job(id, act, pos, success, failed)),
        updateComments  : (id, type, data, success, failed) => dispatch(comments_of_job(id, type, data, success, failed)),
        deleteComments  : (id, success, failed) => dispatch(del_comments_of_job(id, success, failed))        
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (CommentsBoxModal);