import React, { useState} from 'react'
import Scrollbars from '../../common/ImprovedScrollBar';
import DateTimeInput from '../../common/DateTimeInput';
import moment from 'moment';
import { connect, 
//  useDispatch
 } from 'react-redux';
import { fetch_all_timesheetList, team_empl_timeSheet, team_timeSheet_edit } from './common/actions';
import Swal from 'sweetalert2'
import { LETTERS_VALIDATION } from '../../store/config';
import EnviroSkeltonLogo from '../../assets/Images/enviro_logo.png'
import DateWeekRange from '../../common/DateWeekRange';
import { startOfWeek } from 'date-fns';
import Api from '../../store/api'
import { TIMESHEET_CRUD } from '../../store/endpoint';
import { CircularProgress } from '@material-ui/core';
import { cl_red, cl_green } from '../../common/Colors'
import { delete_timeSheet } from '../team/action';


function EmpTimeSheetModal({
    modalAction,
    Name,
    userId,
    initialTimeSheet,
    initialTimeSheetId,
    timeSheetLoader,
    teamTimeSheet,
    filterDate,
    weekly_report, setWeeklyReport,
    timeSheetUpdate,
    delete_timeSheetFx,
    team_TimesheetFx
    // timeSheetInit
}) {

  // const dispatch = useDispatch();

  
  const [weekStart, setWeekStart] = useState(filterDate)
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [openTime, setOpenTime] = useState({ id : null, status : null})

  // console.log('FILTERDATE',filterDate,initialTimeSheet, timeSheetLoader,initialTimeSheet && timeSheetLoader,typeof initialTimeSheet!=='undefined' && timeSheetLoader,initialTimeSheet.weekly_report )
  // const [totalHrsWorked, setTotalHrsWorked] = useState(isNaN(moment(endTime).diff(startTime,'hours'))? 0 : moment(endTime).diff(startTime,'hours'));
   
  // const [normHrs, setNormHrs] = useState( !(initialTimeSheet && timeSheetLoader) ?  0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).normal_hours}` : 0)
  // const [halfTime, setHalfTime] = useState( !(initialTimeSheet && timeSheetLoader) ? 0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).half_time}` : 0)
  // const [doubleTime, setDoubleTime] = useState( !(initialTimeSheet && timeSheetLoader) ?  0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).full_time}` : 0)

  // const [publicHoliday, setPublicHoliday] = useState(!(initialTimeSheet && timeSheetLoader) ?  0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).public_holidays}` : 0)
  // const [annual, setAnnual] = useState(!(initialTimeSheet && timeSheetLoader) ?  0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).annual}` : 0)
  // const [sick, setSick] = useState(!(initialTimeSheet && timeSheetLoader) ?  0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).sick}` : 0)
  // const [other, setOther] = useState(!(initialTimeSheet && timeSheetLoader) ?  0 :
  // initialTimeSheet.some(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))) ?
  // `${initialTimeSheet.find(el => (el.date===moment(new Date()).format('YYYY-MM-DD'))).other_days}` : 0)
  const [timeShhetId, setTimeSheetId]    = useState(initialTimeSheetId)
  // const [weekly_report, setWeeklyReport] = useState( initialTimeSheet && timeSheetLoader ? initialTimeSheet.weekly_report :
  // { employee_id		       : userId,
	//   comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
	//   whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
	//   whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
	//   whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
	//   week			              :	[
	// 	  { annual		: 0, date		: moment(filterDate).format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
  //     { annual		: 0, date		: moment(filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
  //     { annual		: 0, date		: moment(filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
  //     { annual		: 0, date		: moment(filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
  //     { annual		: 0, date		: moment(filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
  //     { annual		: 0, date		: moment(filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
  //     { annual		: 0, date		: moment(filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 }
	// 	]
  // })


  const [selectedDay, setSelectedDay] = useState({ date:'', data: null})

  const teamTimeSheetAdd = () =>{
    console.log(startTime,endTime,weekly_report,weekStart)
    let data = new FormData()
    if(initialTimeSheet && timeShhetId){
      data.append('id', timeShhetId);
      data.append('week_startdate',moment(startOfWeek(new Date(weekStart), {weekStartsOn:1})).format('YYYY-MM-DD'));
      data.append('weekly_report', JSON.stringify( weekly_report));
    }
    else{      
      data.append('week_startdate',moment(startOfWeek(new Date(weekStart), {weekStartsOn:1})).format('YYYY-MM-DD'));
      data.append('weekly_report', JSON.stringify(weekly_report));
    }
    console.log('DATA',data)
    const success = () => {
      modalAction(false)
      Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'TimeSheet Updated successful!',
          showConfirmButton: false,
          timer: 1500
      })
  }

  const failed = (res) => {
    modalAction(false)
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res ? res.data && res.data.app_data ? res.data.app_data : 'Something went wrong!' : 'Something went wrong!'
      })
  }
  if(timeShhetId)
    timeSheetUpdate(JSON.stringify(data),timeShhetId,success, failed)
  else
    teamTimeSheet(data, success, failed, initialTimeSheet && timeShhetId ? timeShhetId : null)
  

  }

  const eachTimeUpdater = (e, k=null, item, type='') =>{
    if(type!==''){
      let weekArr = weekly_report.week
      weekArr[k] = { ...item, finish : moment(e).format('HH:mm:ss'), total_hours_worked : isNaN(moment(e).diff(startTime,'hours'))? 0 : moment(e).diff(startTime,'hours') }
      console.log('WEEK2',weekArr)
      setWeeklyReport( Object.assign({},
          {...weekly_report,
            week : weekArr,
            whole_hours_total_worked : { ...weekly_report.whole_hours_total_worked, 
              total_hours_worked : weekly_report.week.reduce(function(prev, curr){ return parseInt(prev) + parseInt(curr.total_hours_worked)  }, 0) },
            whole_paid_hours : { ...weekly_report.whole_paid_hours, 
              total_hours_worked : 
                weekly_report.week.reduce(function(prev, curr){ return parseInt(prev) + parseInt(curr.total_hours_worked) }, 0) - parseInt(weekly_report.whole_week_minus.total_hours_worked)   }
          }))
    }
    else{
      let weekArr = weekly_report.week
      weekArr[k] = { ...item, start : moment(e).format('HH:mm:ss'), total_hours_worked : isNaN(moment(endTime).diff(moment(e),'hours'))? 0 : moment(endTime).diff(moment(e),'hours') }
      console.log('WEEK',weekArr)
      setWeeklyReport(Object.assign({},
          {...weekly_report, 
            week : weekArr,
            whole_hours_total_worked : { ...weekly_report.whole_hours_total_worked, 
              total_hours_worked : weekly_report.week.reduce(function(prev, curr){ return prev + curr.total_hours_worked  }, 0) + weekArr[k].total_hours_worked },
            whole_paid_hours : { ...weekly_report.whole_paid_hours, 
              total_hours_worked : 
                weekly_report.week.reduce(function(prev, curr){ return prev + curr.total_hours_worked }, 0) + weekArr[k].total_hours_worked - weekly_report.whole_week_minus.total_hours_worked   }

          }))
    }
  }

  const totalUpdater = () =>{
    let weekIdx = weekly_report.week.findIndex(el => el.date===selectedDay.date);
    let weekArr = weekly_report.week
    weekArr[weekIdx] = selectedDay.data
    let TWH = { 
      annual		        : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.annual) }, 0), 
      full_time       	: weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.full_time) }, 0), 
      half_time       	: weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.half_time) }, 0), 
      normal_hours	    : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.normal_hours) }, 0), 
      other_days	      : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.other_days) }, 0), 
      public_holidays   : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.public_holidays) }, 0), 
      sick		          : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.sick) }, 0), 
      total_hours_worked: weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.total_hours_worked) }, 0) };

    let TPH = { 
      annual		        : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.annual) }, 0) - parseInt(weekly_report.whole_week_minus.annual), 
      full_time	        : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.full_time) }, 0) - parseInt(weekly_report.whole_week_minus.full_time), 
      half_time	        : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.half_time) }, 0) - parseInt(weekly_report.whole_week_minus.half_time), 
      normal_hours	    : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.normal_hours) }, 0) - parseInt(weekly_report.whole_week_minus.normal_hours), 
      other_days	      : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.other_days) }, 0) - parseInt(weekly_report.whole_week_minus.other_days), 
      public_holidays   : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.public_holidays) }, 0) - parseInt(weekly_report.whole_week_minus.public_holidays), 
      sick		          : weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.sick) }, 0) - parseInt(weekly_report.whole_week_minus.sick), 
      total_hours_worked: weekly_report.week.reduce(function(prev, curr){  return parseInt(prev) + parseInt(curr.total_hours_worked) }, 0) - parseInt(weekly_report.whole_week_minus.total_hours_worked) }
    
    setWeeklyReport(Object.assign({}, { ...weekly_report,
      week : weekArr,
      whole_hours_total_worked : TWH,
      whole_paid_hours: TPH
    }))
  }

  const dataObj = (val, key) =>{
    let dataObj = selectedDay.data 
    dataObj[key] = val
     setSelectedDay({ date : selectedDay.date, data : dataObj})
     console.log(val, key, selectedDay)
  }

  const totalDataObj = (val, key) =>{
    let totObj = weekly_report.whole_week_minus
    totObj[key] = val 
    setWeeklyReport(Object.assign({}, {...weekly_report,
    whole_week_minus : totObj}))
    console.log(val, key, weekly_report.whole_week_minus)
  }

  const checkTime = (val1,val2) =>{
    let v1 = moment(val1,["HH:mm", "HH:mm:ss"]).isValid() ? moment(val1, 'HH:mm') : moment(new Date(val1), 'HH:mm') 
    let v2 = moment(val2,["HH:mm", "HH:mm:ss"]).isValid() ? moment(val2, 'HH:mm') : moment(new Date(val2), 'HH:mm')  
    console.log(
      v1,v2,v1.format('HH:mm'),v2.format('HH:mm')
    )
    return moment(v1).isBefore(moment(v2))
  }


  const teamTimeSheetDelete = () =>{
    const success = () => {
      modalAction(false)
      team_TimesheetFx(null, 1, null, null);

      Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'TimeSheet Deleted successfully!',
          showConfirmButton: false,
          timer: 1500
      })
  }

  const failed = (res) => {
    modalAction(false)
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res ? res.data && res.data.app_data ? res.data.app_data : 'Something went wrong!' : 'Something went wrong!'
      })
  }
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: cl_red,
    cancelButtonColor: cl_green,
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
      console.log(result)
    if (result.value) {
      delete_timeSheetFx(timeShhetId, success, failed)
    }
  })

  }


  return (
            
          <div className="timeSheetLayout" style={{ height : '85vh', justifyContent : 'flex-start' }} id ="printSection">
              <div className="timeSheetHead">
                  <div className="timeSheetTab">
                      <p>Employee : </p>
                      <span>{Name}</span>
                  </div>
                  <div className="timeSheetTab printableWeekBeg">
                      <p style={{ fontWeight: 600, fontSize: '1.5rem' }}>Employee Timesheet</p>
                  </div>
                  <div className="timeSheetTab">
                      <p>Week Begining : </p>
                      <span className='printableWeekBeg'>{moment(weekStart).format('DD-MM-YYYY')}</span>
                      <span className='nonprintableWeekBeg'>
                            <DateWeekRange
                                weekStart={weekStart}
                                starterDay={5}
                                setWeekStart={setWeekStart}
                                acceptFn={(v) =>{
                                  Api('get',
                                  `${TIMESHEET_CRUD}?date=${v}`
                              ).then(response =>{
                                  console.log(response)
                                  setTimeSheetId(response && response.length!==0 ? response.id : null)
                                  setWeekStart(v)
                                  setWeeklyReport(response && response.length!==0 ? response.weekly_report :
                                  { employee_id		       : userId,
                                    comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
                                    whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                                    whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                                    whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
                                    week			              :	[
                                      { annual		: 0, date		: moment(v).format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      { annual		: 0, date		: moment(v).add(1,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      { annual		: 0, date		: moment(v).add(2,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      
                                      { annual		: 0, date		: moment(v).add(3,'day').format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      { annual		: 0, date		: moment(v).add(4,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      { annual		: 0, date		: moment(v).add(5,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      { annual		: 0, date		: moment(v).add(6,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
                                      
                                    ]
                                  }
                                  )
                              })
                                }}
                            />
                          {/* {moment(todayDate).add(1-todayDate.day(),'day').format('DD/MMM/YYYY')} */}
                        </span>
                  </div>

                  { 
                  initialTimeSheet ?
          
                    <div className="timeSheetUpdateBtn nonprintableWeekBeg"  onClick={()=>{ teamTimeSheetAdd() }}>
                      Update this Week
                    </div>
                  : null 
                  }
                  { 
                  timeShhetId ?
          
                    <div className="timeSheetUpdateBtn" style={{ backgroundColor:'var(--cl_red)' }}  onClick={()=>{ teamTimeSheetDelete() }}>
                      Delete this TimeSheet
                    </div>
                  : null 
                  }
                  { 
                  initialTimeSheet ?
          
                    <div className="timeSheetUpdateBtn nonprintableWeekBeg" style={{ backgroundColor : 'var(--cl_green)' }}  onClick={()=>{ window.print() }}>
                      Print Time-Sheet
                    </div>
                  : null 
                  }
                  
                  <div className="header-button-top nonprintableWeekBeg"  onClick={()=>  modalAction(false) }>
                    Back
                  </div>  
              </div>
              <Scrollbars autoHide >

                  <table border='2' className="timeSheetTable" style={{ height : '60vh' }}>
                      <tbody>
                      <tr className="timeSheetHeadDiv">
                          <th rowSpan='2' >Date</th>
                          <th rowSpan='2' >Day</th>
                          <th rowSpan='2' >Start</th>
                          <th rowSpan='2' >Finish</th>
                          <th rowSpan='2' >Total Hours Worked</th>
                          <th rowSpan='2' >Normal Hours</th>
                          <th>Time & Half</th>
                          <th>Double Time</th>

                          <th colSpan='4' >Leave Hours</th>                          
                      </tr>
                      <tr className="timeSheetHeadDiv">
                          <th>X 1.5</th>
                          <th>X 2</th>

                          <th>Public Holiday</th>
                          <th>Annual</th>
                          <th>Sick</th>
                          <th>Other</th>
                          
                      </tr>
                      {console.log(weekly_report)}
                      { typeof weekly_report!=='undefined' && weekly_report.week ?
                        typeof weekly_report!=='undefined' && weekly_report.week && weekly_report.week.map((item, k) => { 
                          // console.log('ITEM=>',item, selectedDay, updateWeek)
                         
                          return(    
                      
                            <tr key={k} 
                              className={ checkTime(item.start,item.finish) ? '' : "erroneousDateEnteredRow"}
                            onClick={(e)=>{  e.preventDefault();e.stopPropagation();
                              setOpenTime({ id : item.date, status : null})
                              setSelectedDay({ date:item.date, data:item })
                              
                            }}
                                    style={{ backgroundColor:'white'}}>
                                  <td>{moment(item.date).isValid() ? moment(item.date).format('DD-MM-YYYY') : item.date}</td>
                                  <td>{item.day}</td>
                                  <td className='timePickerTd'
                                  onClick={(e)=>{
                                    e.preventDefault();e.stopPropagation();
                                    if(openTime.id === item.date)
                                      setOpenTime({ id : item.date, status : 'start'})
                                    else{
                                      setOpenTime({ id : item.date, status : 'start'})
                                      setSelectedDay({ date:item.date, data:item })
                                    }
                                  }}>
                                      {
                                          selectedDay.date===item.date && openTime.status==='start' ?
                                      <DateTimeInput
                                        openEdit={openTime.id===item.date && openTime.status==='start'}
                                        pickerType="time"
                                        dateSetFn={setStartTime}
                                        dateValue={startTime}
                                        clsName='dateStyleCSS'
                                        cancelFunc={()=>{
                                          setOpenTime({ id : item.date, status : null })
                                        }}
                                        acceptFunc={(e)=>{ 
                                          eachTimeUpdater(e, k, item);
                                          setOpenTime({ id : item.date, status : null })
                                         }}
                                      />
                                      : (moment(`${item.start}`,["HH:mm", "HH:mm:ss", "YYYY-MM-DDTHH:mm:ssZ", "'ddd MMM DD YYYY HH:mm:ssZZ'"],true).isValid() && item.start!=="") ? 
                                      moment(`${item.start}`,'HH:mm:ss',true).isValid() ? 
                                        parseInt(String(item.start).slice(0,2)) > 12 ? `${(parseInt(String(item.start).slice(0,2))-12)}${String(item.start).slice(2,5)} PM` 
                                        : `${String(item.start).slice(0,5)} AM` :
                                      moment(`${item.start}`,["HH:mm", 'YYYY-MM-DDTHH:mm:ssZ'],true).isValid() ? moment(`${item.start}`,["HH:mm", 'YYYY-MM-DDTHH:mm:ssZ']).format('HH:mm A') :
                                       item.start :'-'
                                      }
                                  </td>
                                  <td  className='timePickerTd' onClick={(e)=>{
                                    e.preventDefault();e.stopPropagation();
                                    if(openTime.id === item.date)
                                      setOpenTime({ id : item.date, status : 'finish'})
                                    else{
                                      setOpenTime({ id : item.date, status : 'finish'})
                                      setSelectedDay({ date:item.date, data:item })
                                    }
                                  }}>
                                      {
                                          selectedDay.date===item.date && openTime.status==='finish' ?
                                      <DateTimeInput
                                      openEdit={openTime.id===item.date && openTime.status==='finish'}
                                        pickerType="time"
                                        dateSetFn={setEndTime}
                                        dateValue={endTime}
                                        clsName='dateStyleCSS'
                                        cancelFunc={()=>{
                                          setOpenTime({ id : item.date, status : null })
                                        }}
                                        acceptFunc={(e)=>{ 
                                          eachTimeUpdater(e, k, item, 'endtime');
                                          setOpenTime({ id : item.date, status : null })
                                        }}
                                      />
                                      : (moment(`${item.finish}`,["HH:mm", "HH:mm:ss", "YYYY-MM-DDTHH:mm:ssZ", "'ddd MMM DD YYYY HH:mm:ssZZ'"],true).isValid() && item.finish!=="") ? 
                                      moment(`${item.finish}`,'HH:mm:ss',true).isValid() ? 
                                        parseInt(String(item.finish).slice(0,2)) > 12 ? `${(parseInt(String(item.finish).slice(0,2))-12)}${String(item.finish).slice(2,5)} PM` 
                                        : `${String(item.finish).slice(0,5)} AM` :
                                      moment(`${item.finish}`,["HH:mm", 'YYYY-MM-DDTHH:mm:ssZ'],true).isValid() ? moment(`${item.finish}`,["HH:mm", 'YYYY-MM-DDTHH:mm:ssZ']).format('HH:mm A') :
                                       item.finish : '-'
                                      }
                                  </td>
                                  <td name='total_hours_worked'>
                                      {
                                          selectedDay.date===item.date ?
                                          isNaN(moment(endTime).diff(startTime,'hours'))? 0 : moment(endTime).diff(startTime,'hours') 
                                          : item.total_hours_worked==="" ? 0 : item.total_hours_worked
                                      }
                                  </td>
                                  <td name='normal_hours'>
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='normalHrs' value={selectedDay.data.normal_hours} 
                                            onChange={(e)=>{ 
                                                if(e.target.value==="")
                                                  dataObj('', 'normal_hours')
                                                else if(!LETTERS_VALIDATION.test(e.target.value)){
                                                  dataObj(e.target.value, 'normal_hours')
                                                }
                                                  totalUpdater()
                                              }} /> 
                                            : item.normal_hours==="" ? 0 : parseInt(item.normal_hours)
                                    }
                                  </td>
                                  <td name='half_time'>
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='halfTime' value={selectedDay.data.half_time} 
                                            onChange={(e)=>{ 
                                              if(e.target.value==="")
                                                dataObj('', 'half_time')
                                              else if(!LETTERS_VALIDATION.test(e.target.value))
                                                dataObj(e.target.value, 'half_time')
                                                totalUpdater()
                                              }} /> 
                                            : item.half_time==="" ? 0 : parseInt(item.half_time)
                                    }
                                  </td>
                                  <td name="full_time">
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='doubleTime' value={selectedDay.data.full_time} 
                                            onChange={(e)=>{ 
                                              if(e.target.value==="")
                                                dataObj('','full_time')
                                              else if(!LETTERS_VALIDATION.test(e.target.value))
                                                dataObj(e.target.value, 'full_time')
                                                totalUpdater()
                                              }} /> 
                                            : item.full_time==="" ? 0 : parseInt(item.full_time)
                                    }
                                  </td>
                                  <td name="public_holidays">
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='publicHoliday' value={selectedDay.data.public_holidays} 
                                            onChange={(e)=>{ 
                                              if(e.target.value==="")
                                                dataObj('','public_holidays')
                                              else if(!LETTERS_VALIDATION.test(e.target.value))
                                                dataObj(e.target.value, 'public_holidays')
                                              totalUpdater()
                                              }} /> 
                                            : item.public_holidays==="" ? 0 : parseInt(item.public_holidays)
                                    }
                                  </td>
                                  <td name="annual">
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='annual' value={selectedDay.data.annual} 
                                            onChange={(e)=>{ 
                                              if(e.target.value==="")
                                                dataObj('','annual')
                                              else if(!LETTERS_VALIDATION.test(e.target.value))
                                                dataObj(e.target.value,'annual')
                                              totalUpdater()
                                              }} /> 
                                            : item.annual==="" ? 0 : parseInt(item.annual)
                                    }
                                  </td>
                                  <td name="sick">
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='sick' value={selectedDay.data.sick} 
                                            onChange={(e)=>{ 
                                              if(e.target.value==="")
                                                dataObj('','sick')
                                              else if(!LETTERS_VALIDATION.test(e.target.value))
                                                dataObj(e.target.value, 'sick')
                                              totalUpdater()
                                              }} /> 
                                            : item.sick==="" ? 0 : parseInt(item.sick)
                                    }
                                  </td>
                                  <td name="other_days">
                                    {
                                        selectedDay.date===item.date ?
                                            <input type="number" id='other' value={selectedDay.data.other_days} 
                                            onChange={(e)=>{ 
                                              if(e.target.value==="")
                                                dataObj('', 'other_days')
                                              else if(!LETTERS_VALIDATION.test(e.target.value))
                                                dataObj(e.target.value, 'other_days')
                                              totalUpdater()
                                              }} /> 
                                            : item.other_days==="" ? 0 : parseInt(item.other_days)
                                    }
                                  </td>
                              </tr>)})
                              :
                              <CircularProgress />
                            
                        }
                      { weekly_report ?
                        <tr className="timeSheetFooterDiv">
                            <th colSpan='4' >Total Worked Hours</th>
                              <th>{weekly_report.whole_hours_total_worked.total_hours_worked}</th>
                              <th>{weekly_report.whole_hours_total_worked.normal_hours}</th>
                              <th>{weekly_report.whole_hours_total_worked.half_time}</th>
                              <th>{weekly_report.whole_hours_total_worked.full_time}</th>
                              <th>{weekly_report.whole_hours_total_worked.public_holidays}</th>
                              <th>{weekly_report.whole_hours_total_worked.annual}</th>
                              <th>{weekly_report.whole_hours_total_worked.sick}</th>
                              <th>{weekly_report.whole_hours_total_worked.other_days}</th>
                        </tr>
                      : null}
                      { weekly_report ?
                        <tr className="timeSheetFooterDiv">
                            <th colSpan='4' >Minus Breaks</th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.total_hours_worked} 
                                  onChange={(e)=>{ 
                                    console.log('OUT',e.target.value)
                                    if(e.target.value===""){
                                      totalDataObj('', 'total_hours_worked')
                                    }else if(!LETTERS_VALIDATION.test(e.target.value)){
                                      console.log('IN',e.target.value)
                                      totalDataObj(e.target.value, 'total_hours_worked')
                                    }
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.normal_hours} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','normal_hours')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'normal_hours')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.half_time} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','half_time')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'half_time')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.full_time} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','full_time')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'full_time')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.public_holidays} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','public_holidays')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'public_holidays')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.annual} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','annual')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'annual')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.sick} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','sick')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'sick')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                              <th>
                                {
                                  <input type="number" id='minus_totalHoursWorked' value={weekly_report.whole_week_minus.other_days} 
                                  onChange={(e)=>{ 
                                    if(e.target.value==="")
                                      totalDataObj('','other_days')
                                    else if(!LETTERS_VALIDATION.test(e.target.value))
                                      totalDataObj(e.target.value, 'other_days')
                                      totalUpdater()
                                    }} /> 
                                }
                              </th>
                        </tr>
                        : null
                      }
                        { weekly_report ?
                        <tr className="timeSheetFooterDiv">
                            <th colSpan='4' >Paid Hours</th>
                              <th>{weekly_report.whole_paid_hours.total_hours_worked}</th>
                              <th>{weekly_report.whole_paid_hours.normal_hours}</th>
                              <th>{weekly_report.whole_paid_hours.half_time}</th>
                              <th>{weekly_report.whole_paid_hours.full_time}</th>
                              <th>{weekly_report.whole_paid_hours.public_holidays}</th>
                              <th>{weekly_report.whole_paid_hours.annual}</th>
                              <th>{weekly_report.whole_paid_hours.sick}</th>
                              <th>{weekly_report.whole_paid_hours.other_days}</th>
                        </tr>
                        :null
                        }
                        </tbody>
                        
                  </table>
                  { weekly_report ?
                  <div className="timeSheetFooter">
                      <div>
                          <p>Comments:</p>
                          <input type="text" name="" id=""
                            value={weekly_report.comments}
                            onChange={(e)=>{

                              setWeeklyReport(Object.assign({},{
                                ...weekly_report,
                                comments : e.target.value
                              }))
                            }}
                          />
                      </div>
                      <div>
                          <p>Employee Signature:</p>
                          <input type="text" name="" id="" />
                      </div>
                      <div>
                          <p>Manager's Signature:</p>
                          <input type="text" name="" id="" />
                      </div>
                      <div className='timesheetprintLogo'>
                        <img src={EnviroSkeltonLogo} alt="Enviro"/>
                      </div>
                  </div>
                  : null
                  }
                  </Scrollbars>
                  
          </div>
      
  );
}


const mapDispatchToProps = (dispatch) =>{
  return{
    teamTimeSheet: (content, success, failed, typeAddEdit) => dispatch(team_empl_timeSheet(content, success, failed, null, typeAddEdit)),
    timeSheetInit: (content_Date, userId) => dispatch(team_empl_timeSheet(null, null, null, content_Date, userId)),
    timeSheetUpdate : (content, id, success, failed) => dispatch(team_timeSheet_edit(content, id, success, failed)),
    team_TimesheetFx: (data, page, success, failed) => dispatch(fetch_all_timesheetList(page)),     

    delete_timeSheetFx: (id, success, failed) => dispatch(delete_timeSheet(id, success, failed))
  }
}

 export default connect(null, mapDispatchToProps) (EmpTimeSheetModal)
 
