import React from 'react'

const ErrorHandling = ({ error, fetch }) => {
    console.log(error)
    return (
        error.text === 'Unknown error' ? null : (
            <tr><td colSpan='14' >
                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: '200px', justifyContent: 'center' }}>
                    <span style={{ textAlign: 'center', fontSize: '1.5rem' }}>{error.text}</span>
                    <button className='vechile-details-btn-add' style={{ fontSize: '.8rem' }} onClick={fetch}>Retry</button>
                </div>
            </td></tr>
            // <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: '200px', justifyContent: 'center' }}>
            //     <span style={{ textAlign: 'center' }}>{error.text}</span>
            //     <button className='vechile-details-btn-add' onClick={fetch}>Retry</button>
            // </div>
        )
    )
}

export default ErrorHandling
