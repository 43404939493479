import React from 'react'
import { EnviroBrandLogo } from '../../../common/Images'
import { Link } from 'react-router-dom'
import './styles.css'
import { connect } from 'react-redux'

function Header({ page, permissions }) {  
    return (
        <div className="team-header">
            <div className="team-brand-layout">
                <img className="team-brand-img" src={EnviroBrandLogo} alt='enviro logo' />
            </div>
            
            {   permissions.team.view ?
                page === 'detail' && (
                    <Link to="/team" className="team-back-btn-layout" >
                        <div className="header-back-btn">
                            <p className="p-0 m-0">Back</p>
                        </div>
                    </Link>
                )   :   null
            }
            {
                page === 'detail-inner' && (
                    <div className="team-back-btn-layout">
                        {   permissions.team.waste ?
                        <div className="header-back-btn-enviro">
                            <p className="p-0 m-0">Enviro Waste</p>
                        </div>  :   null }
                        {   permissions.team.hills ?
                        <div className="header-back-btn-enviro">
                            <p className="p-0 m-0">Enviro Hills</p>
                        </div>  :   null }
                        {   permissions.team.pumps ?
                        <div className="header-back-btn-enviro">
                            <p className="p-0 m-0">Enviro Pumps</p>
                        </div>  :   null }
                        {   permissions.team.destruction ?
                        <div className="header-back-btn-enviro">
                            <p className="p-0 m-0">Enviro Destruction</p>
                        </div>  :   null }

                    </div>
                )
            }
        </div>
    )  
}


const mapStateToProps = (state) => {
    const { user, permissions } = state.userLogin
    return { user, permissions }
}

export default connect(mapStateToProps) (Header)
