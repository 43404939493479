import React, { useState, useEffect } from 'react'
import { ArrowForwardIos, Person } from '@material-ui/icons'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Scrollbar from '../../../../common/ImprovedScrollBar'
import Header from '../common/Header'
import Sidebar from '../../../../common/sidebar'
import GoogleMapLayout from '../editStack/GoogleMap/ShortMap'
import MapModal from '../editStack/MapModal'
import { create_client, create_temp_client } from '../../action'
import Swal from 'sweetalert2'
import DateTimeInput from '../../../../common/DateTimeInput'
import { HOME_LATITUDE, HOME_LONGITUDE, LETTERS_VALIDATION } from '../../../../store/config'
import Failerrdevmsg from '../../../../common/failErrDevMsg'
import { GoogleMapInit, SearchGoogleAddress } from '../editStack/GoogleMap/GoogleMap'
import { TextAreaLimiter } from '../../../../common/TextAreaLimiter'
import { SITE_INDUSTRY_LIST } from '../../../../store/endpoint'
import Api from '../../../../store/api'
// import Typeofwaste from './typeOfWaste'


function App({ create_client_api, create_temp_client, clientType, tabType }) {

    let route_history = useHistory();

    useEffect(() => {
        
        Api('get',SITE_INDUSTRY_LIST,'').then(response => {
            setIndustryTypeList(response)  
        })

    },[]);

    const [modalStatus, setModalStatus] = useState(false)
    const [markers, setMarkers] = useState([{
        lat: HOME_LATITUDE,
        lng: HOME_LONGITUDE
    }]);

    const [CustomerName, setCustomerName] = useState('')
    const [ClientEmail, setClientEmail] = useState('')
    const [SiteAddress, setSiteAddress] = useState('')
    const [SitePostcode, setSitePostcode] = useState('')
    const [SiteContact, setSiteContact] = useState('')
    const [SitePhone, setSitePhone] = useState('')
    const [SiteMobile, setSiteMobile] = useState('')
    const [InductionReq, setInductionReq] = useState('')
    const [InductionType, setInductionType] = useState('')

    const [CompanyName, setCompanyName] = useState('')
    const [CompanyAddress, setCompanyAddress] = useState('')
    const [SalesPerson, setSalesPerson] = useState('')
    const [CompanyContact, setCompanyContact] = useState('')
    const [CompanyNumber, setCompanyNumber] = useState('') 
    const [CompanyMob, setCompanyMob] = useState('')    
    const [CompanyEmail, setCompanyEmail] = useState('')
    const [CompanyPostcode, setCompanyPostcode] = useState('')
    // const [AdditionalInfo, setAdditionalInfo] = useState('')
    // const [CompanyPitLoc, setCompanyPitLoc] = useState('')
    // const [CompanyAccess, setCompanyAccess] = useState('')

    // const [InvoiceName, setInvoiceName] = useState('')
    // const [InvoiceAddress, setInvoiceAddress] = useState('')
    // const [InvoiceEmail, setInvoiceEmail] = useState('')
    const [InvoiceAcc, setInvoiceAcc] = useState('')
    const [InvoiceAccStatus, setInvoiceAccStatus] = useState('')
    // const [InvoicePhone, setInvoicePhone] = useState('')
    const [InvoiceReason, setInvoiceReason] = useState('')
    const [PaymentType, setPaymentType] = useState('Charge')
    const [PurchaseOrderNo, setPurchaseOrderNo] = useState('')
    const [IndustryType, setIndustryType]        = useState('')

    // // const [LastService, setLastService] = useState('')
    // const [KeyRequiredType, setKeyRequiredType] = useState('FALSE')
    // // const [Frequency, setFrequency] = useState('')
    // const [TypeofWaste, setTypeofWaste] = useState('')
    // const [Capacity, setCapacity] = useState('')
    const [WastePrice, setWastePrice] = useState('')
    const [AbnNumber, setAbnNumber] = useState('')
    // const [BarCode, setBarCode] = useState('')
    // // const [NextService, setNextService] = useState('')
    // const [JobDuration, setJobDuration] = useState('')
    // const [JobStatus, setJobStatus] = useState('')
    // const [AlarmCode, setAlarmCode] = useState('')
    // const [WeighBridgeReq, setWeighBridgeReq] = useState('')
    // const [PalletsToBeReq, setPalletsToBeReq] = useState('FALSE')
    // const [Quantity, setQuantity] = useState('')
    const [profileImage, setprofileImage] = useState('')
    const [profileImageTemp, setprofileImageTemp] = useState('false')
    const [industryTypeList, setIndustryTypeList] = useState([])

    // const [typeOfWasteData, setTypeofWasteData] = useState(null)

    const [error, setError] = useState('')
    // const [searchAddr, setSearchAddr] = useState({status : false, value : ''})
    console.log('CLIENT TYPE',clientType)
    let mapInit = GoogleMapInit()
    let buttonStatus = false

    if (CustomerName && ClientEmail && SiteAddress &&  SitePostcode && SiteContact && SitePhone 
        && SiteMobile && InductionReq!=='' && InductionType && CompanyName && CompanyAddress
        && CompanyNumber && CompanyMob && CompanyEmail && CompanyPostcode && IndustryType!==''
        // && InvoiceName && InvoiceAddress && InvoiceEmail && InvoicePhone
        && InvoiceAcc && InvoiceAccStatus && PaymentType!=='' && (tabType!=='pumps' || PurchaseOrderNo) ) {
        buttonStatus = true
        // console.log('success')
    } else {
        console.log('failed')
    }

    const createClientApi = () => {

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
         if (!pattern.test(ClientEmail) || !pattern.test(CompanyEmail) 
         //|| !pattern.test(InvoiceEmail)
         ) {
           setError("Please enter valid email address.")
           if(!pattern.test(ClientEmail))
                setClientEmail('')
            if(!pattern.test(CompanyEmail))
                setCompanyEmail('')
            // if(!pattern.test(InvoiceEmail))
            //     setInvoiceEmail('')
         } else {

            var data = new FormData();
            //Site Details
            // data.append('client_id', ClientId);
            data.append('client_name', CustomerName);
            data.append('client_email', ClientEmail);

            // data.append('device_capacity', Capacity)
            // data.append('waste_type_str', TypeofWaste)

            data.append('site_address', SiteAddress);
            data.append('site_post_code', SitePostcode)
            data.append('site_phone_no',SitePhone)
            data.append('site_contact_person',SiteContact)
            data.append('site_contact_mob',SiteMobile)

            data.append('company_name', CompanyName)
            data.append('company_contact_number', CompanyNumber);
            data.append('company_contact_mob', CompanyMob);
            data.append('company_address', CompanyAddress)
            data.append('company_email', CompanyEmail);
            data.append('company_postcode', CompanyPostcode);
            if(tabType==='pumps')
                data.append('company_contact_name', CompanyContact)
            // data.append('information', AdditionalInfo)
            // data.append('pit_location', CompanyPitLoc)
            // data.append('access_restriction', CompanyAccess)
            data.append('sales_person', SalesPerson)

            // data.append('invoice_name', InvoiceName)                 // DATA MERGED WITH COMPANY-FIELDS
            // data.append('invoice_address', InvoiceAddress)
            // data.append('invoice_phone', InvoicePhone)
            // data.append('invoice_email', InvoiceEmail)
            data.append('invoice_terms_of_account', InvoiceAcc)
            data.append('invoice_account_status', InvoiceAccStatus)
            data.append('Reason_for_cancelling', InvoiceReason)
            data.append('invoice_purchase_no', PurchaseOrderNo)
            data.append('induction_required_str', InductionReq)
            // data.append('barcode', BarCode)
            data.append('price',WastePrice)
            // if(NextService!=='' && NextService!==null)
            //     data.append('last_service_1', moment(NextService).format('YYYY-MM-DD HH:mm:ss'))
            // if(NextService!=='' && NextService!==null)
            //     data.append('next_service_2', moment(NextService).format('YYYY-MM-DD HH:mm:ss'))
            // data.append('job_status', JobStatus)
            // data.append('job_duration', JobDuration)
            // data.append('alarm_code', AlarmCode)
            // data.append('weigh_bridge_required', WeighBridgeReq)
            // data.append('pellets_to_be_exchanged_str', PalletsToBeReq)
            // data.append('quantity',Quantity)
            data.append('abn', AbnNumber)
            data.append('payment_type_str', PaymentType);
            data.append('induction_type', InductionType);
            data.append('industry_type', IndustryType)

            // data.append('key_required_type_str', KeyRequiredType);
            // data.append('frequency_weeks', Frequency);
            data.append('tab_type', tabType)

            if(profileImageTemp) {
                let splitData = profileImageTemp.split(':')
                if(splitData.length > 0) {
                    if(splitData[0] === 'blob') {
                        let file_name = profileImage.name
                        data.append('dp', profileImage, file_name)
                    }
                }
            }
    
            console.log('MARKERS',markers)
            if (markers.length > 0) {
                data.append('location_logitude', markers[0].lng)
                data.append('location_latitude', markers[0].lat)
            }else{
                data.append('location_logitude', HOME_LONGITUDE)
                data.append('location_latitude', HOME_LATITUDE)
            }


            const success = (res) => {
                route_history.push('/client')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Client Addedd successful!',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
    
            const failed = (res) => {
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Oops...',
                //     text: res.app_data ? res.app_data : 'Something went wrong!'
                // })
                Failerrdevmsg(res)
            }
            if(clientType==='perm')
                create_client_api(data, tabType, success, failed)
            else 
                create_temp_client(data, tabType, success, failed)

         }

       
    }

    const UpdateSiteAddress = (val,addr) =>{
        if(val){
            setSiteAddress(addr)
            let res = val && val.address_components ? val.address_components : null
            console.log(res)
            if(res){ 
                res.find(o =>{
                    if( o.types.includes('postal_code'))
                        setSitePostcode(o.long_name)
                    console.log(o,o.types.includes('postal_code'))
                    return 0
                })
            }
        }else{
            setSiteAddress('')
            setSitePostcode('')
        }
    }

    // console.log(markers)
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='create' />
                <MapModal
                    modalStatus={modalStatus}
                    modalAction={setModalStatus}
                    markers={markers}
                    setMarkers={setMarkers}
                    UpdateSiteAddress={UpdateSiteAddress}
                />
                <div className="client-create-main-layout">
                    <p className="client-main-head">Add Site</p>
                    <div className="client-crt-container">
                        
                        
                        <div className="client-crt-bottom">

                            <div className="client-crt-btm-lft-main " style={{ flexDirection:'column' }}  >
                                    <div className="client-ctr-btm-lftSingle" style={{ display: 'flex', flexDirection:'row', height:'80vh' }}>
                                    <Scrollbar style={{ display: 'flex', height: '75vh' }}>
                                        <div style={{ display:'flex', flexDirection:'row' }} >
                                            <div style={{ flex:'3' }} >
                                            <div className="client-crt-top">

                            <label htmlFor="file-input">
                                {
                                    profileImage ? (
                                        <div className="client-crt-dp">
                                            <img className="client-crt-dp-img" src={profileImageTemp} alt="" />
                                        </div>
                                    ) : (
                                            <div className="client-crt-dp">
                                                <Person style={{ fontSize: 50 }} />
                                                <p style={{ fontSize: 14, padding: 0, margin: 0 }}>Add Photo</p>
                                            </div>
                                        )
                                }
                            </label>

                            <input id="file-input" style={{ display: 'none' }} type="file" multiple={false}
                                onChange={(image) => {
                                    var image_file = image.target.files[0]
                                    let extension = image_file.type
                                    if (extension === 'image/jpeg' || extension === 'image/png') {
                                        var image_blob = URL.createObjectURL(image_file)
                                        setprofileImageTemp(image_blob)
                                        setprofileImage(image_file)
                                    } else {
                                        alert('File should be an Image. Only jpg and png files are accepted')
                                    }

                                }}
                            />


                            {/* <div className="client-crt-dp">
                                <Person style={{ fontSize: 50 }} />
                                <p style={{ fontSize: 14, padding: 0, margin: 0 }}>Add Photo</p>
                            </div> */}
                            <div className="client-crt-map">
                                <GoogleMapLayout
                                    modalAction={setModalStatus}
                                    markers={markers}
                                // values={values} 
                                />
                            </div>
                        </div>

                                            <div style={{ fontSize:16, padding:'0 10px' }}>Company Details</div>

                                            {/* <InputField
                                                title="Site ID"
                                                mandatory={true}
                                                value={ClientId}
                                                onChange={val => setClientId(val.target.value)}
                                            /> */}
                                            
                                            <InputField
                                                title="Company Name"
                                                value={CompanyName}
                                                mandatory={true}
                                                onChange={val => setCompanyName(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Company Address"
                                                mandatory={true}
                                                value={CompanyAddress}
                                                inputComponent={
                                                !mapInit.status ? null :
                                                <SearchGoogleAddress 
                                                    cls={{ outerBox : 'searchAddressSite', innerBox : 'client-input'}} 
                                                    inputVal={CompanyAddress}
                                                    inputPlaceHolder={"Company Address"}
                                                    inputFn={(v) => { setCompanyAddress(v.toUpperCase())}}
                                                    moreInfo={(r) => { 
                                                        
                                                        let res = r.results && r.results.address_components ? r.results.address_components : null
                                                        console.log(res)
                                                        if(res){ 
                                                             
                                                            res.find(o =>{
                                                                if( o.types.includes('postal_code'))
                                                                setCompanyPostcode(o.long_name)
                                                                console.log(o,o.types.includes('postal_code'))
                                                                return 0
                                                            })
                                                     } }} 
                                                />}
                                                onChange={val =>{
                                                    setCompanyAddress(val.target.value.toUpperCase())}}
                                            />

                                        {   tabType === 'pumps' ?
                                            <InputField
                                                title="Company Contact Name"
                                                value={CompanyContact}
                                                mandatory={true}
                                                onChange={val => setCompanyContact(val.target.value.toUpperCase())}
                                            />
                                            :   null    }
                                            <InputField
                                                title="Company Contact Phone"
                                                
                                                mandatory={true}
                                                value={CompanyNumber}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setCompanyNumber('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setCompanyNumber(val.target.value)}}
                                            />

                                            <InputField
                                                title="Company Contact Mob"
                                                
                                                mandatory={true}
                                                value={CompanyMob}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setCompanyMob('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setCompanyMob(val.target.value)}}
                                            />

                                            <InputField
                                                title="Company Email"
                                                mandatory={true}
                                                type={'email'}
                                                value={CompanyEmail}
                                                onChange={val => setCompanyEmail(val.target.value)}
                                            />

                                            <InputField
                                                title="Postcode"
                                                mandatory={true}
                                                
                                                value={CompanyPostcode}
                                                onChange={val =>{
                                                        setCompanyPostcode(val.target.value)}}
                                            />
                                            

                                            <SelectField
                                                title="Induction Required"
                                                mandatory={true}
                                                options={[
                                                    { name: 'YES', value: 'TRUE' },
                                                    { name: 'NO', value: 'FALSE' },
                                                    { name : 'Select Option', value : '' },
                                                ]}
                                                value={InductionReq}
                                                onChange={val => setInductionReq(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Induction Type"
                                                mandatory={true}
                                                value={InductionType}
                                                onChange={val => setInductionType(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="ABN Number"
                                                mandatory={false}
                                                value={AbnNumber}
                                                onChange={val => setAbnNumber(val.target.value.toUpperCase())}
                                            />
                                                                                 
                                        </div>
                                        <div style={{ flex:'3', borderLeft: '1.5px solid var(--cl_ivery)' }}>

                                            <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Site and Invoicing Details</div>

                                            {/* <InputField
                                                title="Name"
                                                value={InvoiceName}
                                                mandatory={true}
                                                onChange={val => setInvoiceName(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Address"
                                                value={InvoiceAddress}
                                                mandatory={true}
                                                onChange={val => setInvoiceAddress(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Phone"
                                                
                                                value={InvoicePhone}
                                                mandatory={true}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setInvoicePhone('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setInvoicePhone(val.target.value)}}
                                            />

                                            <InputField
                                                title="Email"
                                                value={InvoiceEmail}
                                                type={'email'}
                                                mandatory={true}
                                                onChange={val => setInvoiceEmail(val.target.value)}
                                            />
                                         */}
                                         
                                            <InputField
                                                title="Site Name"
                                                mandatory={true}
                                                value={CustomerName}
                                                onChange={val => setCustomerName(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Site Email Address"
                                                mandatory={true}
                                                type={'email'}
                                                value={ClientEmail}
                                                onChange={val => setClientEmail(val.target.value)}
                                            />
                                            
                                            <InputField
                                                title="Site Address"
                                                mandatory={true}
                                                value={SiteAddress}
                                                inputComponent={
                                                !mapInit.status ? null :
                                                <SearchGoogleAddress 
                                                    cls={{ outerBox : 'searchAddressSite', innerBox : 'client-input'}} 
                                                    inputVal={SiteAddress}
                                                    inputPlaceHolder={"Site Address"}
                                                    inputFn={(v) => { setSiteAddress(v.toUpperCase())}}
                                                    moreInfo={(r) => { 
                                                        
                                                        let res = r.results && r.results.address_components ? r.results.address_components : null
                                                        console.log(res)
                                                        if(res){ 
                                                             
                                                            res.find(o =>{
                                                                if( o.types.includes('postal_code'))
                                                                    setSitePostcode(o.long_name)
                                                                console.log(o,o.types.includes('postal_code'))
                                                                return 0
                                                            })
                                                     } }}
                                                    center={markers} setMarkers={setMarkers} />}
                                                onChange={val =>{
                                                    setSiteAddress(val.target.value.toUpperCase())}}
                                            />
                                            <InputField
                                                title="Site Postcode"
                                                mandatory={true}
                                                
                                                value={SitePostcode}
                                                onChange={val =>{
                                                        setSitePostcode(val.target.value)
                                                }}
                                            />

                                            <InputField
                                                title="Site Contact/Person"
                                                mandatory={true}
                                                value={SiteContact}
                                                onChange={val => setSiteContact(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Site Phone"
                                                
                                                mandatory={true}
                                                value={SitePhone}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setSitePhone('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setSitePhone(val.target.value)
                                                    }}
                                            />

                                            <InputField
                                                title="Site Contact Mobile"
                                                mandatory={true}
                                                
                                                value={SiteMobile}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setSiteMobile('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setSiteMobile(val.target.value)}}
                                            />
                                            <SelectField
                                                title="Industry Type"
                                                value={IndustryType}
                                                
                                                options={[
                                                    { name : 'Select Option', value : '' },
                                                    ...industryTypeList.map(el =>{
                                                        return { name : el.industry_name, value : el.id }
                                                    })
                                                ]
                                                    
                                                }
                                                mandatory={true}
                                                onChange={val => setIndustryType(val.target.value)}
                                            />
                                            

                                            <InputField
                                                title="Purchase/Work Order No"
                                                value={PurchaseOrderNo}
                                                mandatory={ tabType==='pumps' ? true : false}
                                                onChange={val => setPurchaseOrderNo(val.target.value.toUpperCase())}
                                            />
                                            <SelectField
                                                title="Terms of Account"
                                                value={InvoiceAcc}
                                                options={[
                                                    { name : '7 DAYS', value : '7 DAYS' },
                                                    { name : '14 DAYS', value : '14 DAYS' },
                                                    { name : '30 DAYS', value : '30 DAYS' },
                                                    { name : '60 DAYS', value : '60 DAYS' },
                                                    { name : 'COD', value: 'COD' },
                                                    { name : 'PREPAID', value : 'PREPAID'},
                                                    { name : 'PROFORMA', value : 'PROFORMA'},
                                                    { name : 'Select Option', value : '' },
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                mandatory={true}
                                                onChange={val => setInvoiceAcc(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Account Status"
                                                value={InvoiceAccStatus}
                                                options={[
                                                    {name : 'ACTIVE', value : 'ACTIVE'},
                                                    {name : 'ON HOLD', value : 'ON HOLD'},
                                                    {name : 'CANCELLED/UNACTIVE', value : 'CANCELLED/UNACTIVE' },
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                    { name : 'Select Option', value : '' },
                                                ]}
                                                mandatory={true}
                                                onChange={val => setInvoiceAccStatus(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Reasons for Cancelling"
                                                value={InvoiceReason}
                                                options={[
                                                    { name: 'Select Option', value: ''},
                                                    { name: 'MOVED PREMISES', value: 'MOVED PREMISES'},
                                                    { name: 'NEW OWNER', value: 'NEW OWNER'},
                                                    { name: 'POOR SERVICES', value: 'POOR SERVICES' },
                                                    { name: 'SHOP CLOSED DOWN', value: 'SHOP CLOSED DOWN' },
                                                    { name: 'TOO EXPENSIVE', value: 'TOO EXPENSIVE'},
                                                    { name: 'NOT APPLICABLE', value: 'NOT APPLICABLE'}
                                                ]}                                                
                                                onChange={val => setInvoiceReason(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Payment Type"
                                                mandatory={true}
                                                options={[
                                                    { name: 'Select Option', value: ''},
                                                    { name: 'ACCOUNT', value: 'ACCOUNT'},
                                                    { name: 'COD', value: 'COD'},
                                                    { name: 'CREDIT CARD', value: 'CREDIT CARD' },
                                                    { name: 'PRE-PAID', value: 'PRE-PAID'},
                                                    { name: 'NO CHARGE', value: 'NO CHARGE'},
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={PaymentType}
                                                onChange={val => setPaymentType(val.target.value.toUpperCase())}
                                            />

                                            
                                            <InputField
                                                title="Price"
                                                value={WastePrice}
                                                onChange={val => setWastePrice(val.target.value)}
                                            />
                                            {/* <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Company Details</div> */}

                                            

                                            <InputField
                                                title="Sales Person"
                                                mandatory={false}
                                                // options={
                                                //     tabType==='waste' ? 
                                                //     [
                                                //         { name : 'RICHARD FUDGE', value : 'RICHARD FUDGE'},
                                                //         { name : 'MATTHEW JONES', value : 'MATTHEW JONES'},
                                                //         { name : 'MICHEAL YOUNGMAN', value : 'MICHEAL YOUNGMAN'},
                                                //         { name : 'MATTHEW DESIRIA', value : 'MATTHEW DESIRIA'},
                                                //         { name : 'JAKE LAWRIE', value : 'JAKE LAWRIE'},
                                                //         { name : 'JOHN PAUL HAWACH', value : 'JOHN PAUL HAWACH'},
                                                //         { name : 'EDDY HAWACH', value : 'EDDY HAWACH'},
                                                //         { name : 'SIMON SABA', value : 'SIMON SABA'},
                                                //         { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                //         { name : 'Select Option', value : '' },
                                                //     ]
                                                //     :   tabType==='pumps' ?
                                                //     [
                                                //         { name : 'Matt', value : 'Matt'},
                                                //         { name : 'MATTHEW DESIRIA', value : 'MATTHEW DESIRIA'},
                                                //         { name : 'JAKE LAWRIE', value : 'JAKE LAWRIE'},
                                                //         { name : 'JOHN PAUL HAWACH', value : 'JOHN PAUL HAWACH'},
                                                //         { name : 'EDDY HAWACH', value : 'EDDY HAWACH'},
                                                //         { name : 'SIMON SABA', value : 'SIMON SABA'},
                                                //         { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                //         { name : 'Select Option', value : '' },
                                                //     ]
                                                //     :
                                                //     [
                                                //         { name : 'MATTHEW DESIRIA', value : 'MATTHEW DESIRIA'},
                                                //         { name : 'JAKE LAWRIE', value : 'JAKE LAWRIE'},
                                                //         { name : 'JOHN PAUL HAWACH', value : 'JOHN PAUL HAWACH'},
                                                //         { name : 'EDDY HAWACH', value : 'EDDY HAWACH'},
                                                //         { name : 'SIMON SABA', value : 'SIMON SABA'},
                                                //         { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                //         { name : 'Select Option', value : '' },
                                                //     ]
                                                // }
                                                
                                                value={SalesPerson}
                                                onChange={val => setSalesPerson(val.target.value.toUpperCase())}
                                            />
                                            {/* <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Waste Service</div>


                                            <SelectField
                                                title="Type of Waste"
                                                mandatory={true}
                                                options={[
                                                    { name: 'Select Option', value: ''},
                                                    {   name : 'AMMONIA', value : 'AMMONIA'},
                                                    {   name : 'DILUTION PIT', value : 'DILUTION PIT'},
                                                    {   name : 'DRUMS', value : 'DRUMS'},
                                                    {   name : 'FOOD WASTE', value : 'FOOD WASTE'},
                                                    {   name : 'GERNIE WASHES', value : 'GERNIE WASHES'},
                                                    {   name : 'GREASE', value : 'GREASE'},
                                                    {   name : 'GROUND WATER', value : 'GROUND WATER'},
                                                    {   name : 'J120', value : 'J120'}, 
                                                    {   name : 'LEVY', value : 'LEVY' },
                                                    {   name : 'M250', value :'M250'},
                                                    {   name : 'N205', value : 'N205'}, 
                                                    {   name : 'OIL SEPERATOR', value : 'OIL SEPERATOR'},
                                                    {   name : 'PACKAGED WASTE', value : 'PACKAGED WASTE'},
                                                    {   name : 'PRODUCT DESTRUCTION', value : 'PRODUCT DESTRUCTION'},
                                                    {   name : 'PUMP SERVICE', value : 'PUMP SERVICE'},
                                                    {   name : 'RETENTION PIT', value : 'RETENTION PIT'},
                                                    {   name : 'SEWER', value :'SEWER'},
                                                    {   name : 'STORMWATER', value :'STORMWATER'},
                                                    {   name : 'TANK WASH OUT', value : 'TANK WASH OUT'},      
                                                    {   name : 'TRADE WASTE', value : 'TRADE WASTE'},                                              
                                                    {   name : 'TRANSPORT', value : 'TRANSPORT'},                                                    
                                                    {   name : 'Z140', value : 'Z140'},
                                                    // {   name : 'WIEGH BRIDGE', value : 'WEIGH BRIDGE'},
                                                    // {   name : 'DESTRUCTION CERTIFICATE', value : 'DESTRUCTION CERTIFICATE'},
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={TypeofWaste}
                                                cls={'selectBoxHead'}
                                                onChange={val => setTypeofWaste(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Capacity"
                                                value={Capacity}
                                                mandatory={true}
                                                onChange={val => setCapacity(val.target.value.toUpperCase())}
                                            /> */}

                                            {/* <InputField
                                                title="Frequency (weeks)"
                                                mandatory={true}
                                                value={Frequency}
                                                onChange={val => {
                                                    if(val.target.value==='')
                                                        setFrequency('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setFrequency(val.target.value.toUpperCase())
                                                    }} 
                                            /> */}

                                            {/* {   tabType!=='pumps' ?
                                            <InputField
                                                title="Barcode"
                                                value={BarCode}
                                                onChange={val => setBarCode(val.target.value.toUpperCase())}
                                            />
                                            :   null} */}

                                            {/* <InputField
                                                title="Last Service"
                                                type="date"
                                                placeholder="dd-mm-yyyy"
                                                value={LastService}
                                                onChange={setLastService}
                                            />
                                                */}
                                            {/* <InputField
                                                title="Next Service"
                                                type="date"
                                                placeholder="dd-mm-yyyy"
                                                value={NextService}
                                                onChange={setNextService}
                                            />  */}

                                            {/* <InputField
                                                title="Job Duration"
                                                value={JobDuration}
                                                onChange={val => setJobDuration(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Key Required Type"
                                                options={[
                                                    { name: 'YES', value: 'TRUE' },
                                                    { name: 'NO', value: 'FALSE' },
                                                ]}
                                                value={KeyRequiredType}
                                                onChange={val => setKeyRequiredType(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Job Status"
                                                options={[
                                                    { name : 'CALL', value : 'CALL'},
                                                    { name : 'CANCELLED', value : 'CANCELLED'},
                                                    { name : 'COVID', value : 'COVID'},
                                                    { name : 'ON HOLD', value : 'ON HOLD'},
                                                    { name : 'ONE OFF', value : 'ONE OFF'},
                                                    { name : 'RECURRING', value : 'RECURRING' },
                                                    { name : 'Select Option', value : ''},
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={JobStatus}
                                                onChange={val => setJobStatus(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title={ tabType!=='pumps' ? "Alarm Code" : "Lock Box"}
                                                value={AlarmCode}
                                                onChange={val => setAlarmCode(val.target.value.toUpperCase())}
                                            />
                                            {   tabType!=='pumps' ?
                                            <InputField
                                                title="Weigh Bridge Required"
                                                value={WeighBridgeReq}
                                                onChange={val => setWeighBridgeReq(val.target.value.toUpperCase())}
                                            />
                                            :   null}
                                            {   tabType!=='pumps' ?
                                            <SelectField
                                                title="Pallets To Be Exchanged"
                                                options={[
                                                    { name: 'YES', value: 'TRUE' },
                                                    { name: 'NO', value: 'FALSE' },
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={PalletsToBeReq}
                                                onChange={val => setPalletsToBeReq(val.target.value.toUpperCase())}
                                            />
                                            :   null}
                                            {   tabType!=='pumps' ?
                                            <InputField
                                                title="Quantity"
                                                value={Quantity}
                                                onChange={val => setQuantity(val.target.value.toUpperCase())}
                                            />
                                            :   null}
                                            <InputField
                                                title="Additional Information"
                                                type='textarea'
                                                value={AdditionalInfo}
                                                onChange={val => setAdditionalInfo(val.target.value)}
                                            />
                                            {   tabType!=='pumps' ?
                                            <InputField
                                                title="Pit Location"
                                                type='textarea'
                                                value={CompanyPitLoc}
                                                onChange={val => setCompanyPitLoc(val.target.value)}
                                            />  : null }
                                            <InputField
                                                title="Access Restriction"
                                                type='textarea'
                                                value={CompanyAccess}
                                                onChange={val => setCompanyAccess(val.target.value)}
                                            /> */}

                                            
                                        </div>
                                        </div>
                                        <p style={{ color: 'red', fontSize: 12, marginTop: 10 }}>{error}</p>
                                            <div style={{ display:'flex', justifyContent:'center', minHeight:'100px', marginBottom: '1%' }} >
                                            {
                                                buttonStatus ? (
                                                <button onClick={() => createClientApi()} className="client-ctr-btn">
                                                    <p style={{ padding: 0, margin: 0 }} >Create</p>   <ArrowForwardIos style={{ padding: 0, margin: 0, marginLeft: 10 }} />
                                                </button>
                                                ) : (
                                                <button className="client-ctr-btn disabled-btn">
                                                    <p style={{ padding: 0, margin: 0 }} >Create</p>   <ArrowForwardIos style={{ padding: 0, margin: 0, marginLeft: 10 }} />
                                                </button>
                                                )
                                            }
                                            </div>
                                        {/* <Typeofwaste typeOfWasteData={typeOfWasteData} setTypeofWasteData={setTypeofWasteData} tabType={tabType} /> */}
                                            </Scrollbar>
                                    </div>
                                    
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function InputField({ title, value, onChange, type = 'text', mandatory, inputIcon=null, inputComponent=null }) {


    if(type==='date'){
        return (
            <div className="data-single">
                <div className="detail-headings">
                    <p className="p-0 m-0">{title}
                        {
                            mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                        }
                    </p>
                </div>
                <div className="detail-input-dot">
                    <p className="p-0 m-0">:</p>
                </div>
                <div className="detail-input-layout">

                    <DateTimeInput
                    dateValue={value}
                    dateSetFn={onChange}
                    clsName={'client-input'}
                    />
                    {/* <input
                        type={type}
                        placeholder={title}
                        className="client-input"
                        value={value}
                        onChange={onChange}
                    /> */}
                </div>
            </div>
        )
    }
    else if(type==='textarea'){
        return (
            <div className="data-single" style={{ height:'20vh', alignItems:'flex-start' }}>
                <div className="detail-headings">
                    <p className="p-0 m-0">{title}
                        {
                            mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                        }
                    </p>
                </div>
                <div className="detail-input-dot">
                    <p className="p-0 m-0">:</p>
                </div>
                <div className="detail-input-layout">              
                    <TextAreaLimiter 
                        configs={{
                            placeholder : title,
                            rows : '10',
                            className : "client-input",
                            style : {
                                resize:'none',
                                backgroundColor:'aliceblue'
                            },
                            value : value,
                            onChange : onChange
                        }}
                        v={value}/>
                        
                    
                    {/* <input
                        type={type}
                        placeholder={title}
                        className="client-input"
                        value={value}
                        onChange={onChange}
                    /> */}
                </div>
            </div>
        )    
    }
    else if(inputComponent!==null)
        return(
            <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}
                    {
                        mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                    }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout" style={{ position : 'relative' }}>
                {inputComponent}
            </div>
        </div>
        )
    else
    return (
        <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}
                    {
                        mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                    }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout" style={{ position : 'relative' }}>
                <input
                    type="text"
                    placeholder={type==='number' ? `${title}(number)` :  title  }
                    className="client-input"
                    value={value}
                    onChange={onChange}
                    style={{textTransform: type==='text' ? 'uppercase' : 'none'}}
                />
                {inputIcon}
            </div>
        </div>
    )
}

function SelectField({ title, value, options = [], onChange, mandatory, cls='' }) {
    return (
        <div className={`data-single ${cls}`}>
            <div className={`detail-headings ${cls}`}>
                <p className="p-0 m-0">{title}
                {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <select
                    value={value}
                    className="client-input"
                    onChange={onChange}
                >
                    {
                        options.map((item, key) => {
                            return (
                                <option key={key} value={item.value}>{item.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

// function DataListField({ title, value, options = [], type = 'text', onChange, mandatory }) {
//     return (
//         <div className="data-single">
//             <div className="detail-headings">
//                 <p className="p-0 m-0">{title}
//                 {
//                     mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
//                 }
//                 </p>
//             </div>
//             <div className="detail-input-dot">
//                 <p className="p-0 m-0">:</p>
//             </div>
//             <div className="detail-input-layout">
//                 <input
//                     type={type}
//                     placeholder={title}
//                     className="client-input"
//                     value={value}
//                     list='dataListField'
//                     onChange={onChange}
//                 />
//                 <datalist
//                     id='dataListField'
//                     className="client-input"
//                 >
//                     {
//                         options.map((item, key) => {
//                             return (
//                                 <option key={key} value={item.value}/>
//                             )
//                         })
//                     }
//                 </datalist>
//             </div>
//         </div>
//     )
// }


const mapStateToProps = (state) => {
    const { clientType } = state.client
    return { clientType }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create_client_api: (data, tabType, success, failed) => dispatch(create_client(data, tabType, success, failed)),
        create_temp_client: (data, tabType, success, failed) => dispatch(create_temp_client(data, tabType, success, failed))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
