import React, { useEffect, useState } from 'react'
// import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { connect } from 'react-redux'
import JobModal from './jobModal'
import SchdModal from './jobModalB'
import QuoteModal from '../../../common/CommonQuoteDetailModal'
import { SALES_JOB_ReSCHD, SCHEDULE_ADDITIONAL_VEHICLE, SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER, SCHEDULE_JOB_VIEW, SCHEDULE_MOCK_QUOTE_INFOS, SCHEDULE_NORMAL_JOBS, SCHEDULE_OUTSOURCE_JOBS, 
    SCHEDULE_QUOTE_INFOS, 
    SCHEDULE_REOCCURRING_JOBS } from '../../../store/endpoint'
// import InfiniteScroll from 'react-infinite-scroller';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core'

import {
    add_team_to_job, add_vehicle_to_job, fetch_schedules, 
    //fetch_job_list_readyForSchd, fetch_job_list_RFSchd_append_pagination,
    //fetch_job_pending_schd , fetch_job_reoccur_schd,
    schedule_image_add, schedule_job_delete, schedule_job_edit, 
    //delete_job_joblist, 
    schedule_imageSign_add, 
    //fetch_job_list_RFSchdSearch_append_pagination, 
    fetch_Mem_schedules, fetch_job_list_readyForSchdNew, delete_job_joblist_ALL, fetch_job_list_RFSchdSearch_append_paginationNew, fetch_job_list_RFSchd_append_paginationNew, takeAllJobsPrintNEW, schedule_video_add
} from '../common/action'
import Swal from 'sweetalert2'
import { CircularProgress, MenuItem, Tooltip } from '@material-ui/core'
import Api from '../../../store/api'
import NoDatasScreen from '../../../common/no_datas'
import QuoteHistoryModalView from './quoteHistoryModal'
// import DateTimeInput from '../../../common/DateTimeInput'
import moment from 'moment'
import { SearchInputTab } from '../../../common/SearchInputTab'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import { DeleteForever, FullscreenExitRounded, RestoreFromTrashOutlined, Search } from '@material-ui/icons'
import { cl_green, cl_red } from '../../../common/Colors'
import DateTimeInput from '../../../common/DateTimeInput'
import { IsVideoFileUpload } from '../../../common/VideoFilePreview'
import ReactSelect from 'react-select'



const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  function TransitionsModal(
    {
        screen_type,
        job_list_RFSchd,
        job_list_RFSchd_page,
        fetch_jobs,
        tableLoader,
        fetch_jobs_pagination,
        refresh_page,
        job_list_RFSchd_loader,
        // fetch_jobs_pending, 
        // job_list_RFSchd_pending, 
        // job_list_RFSchd_pending_page,
        schedules_list,
        vehicle_list,
        fetch_schedule_list,
        fetch_Mem_schedule_list,
        schedule_edit_job,
        team_setting,
        vehicle_setting,
        delete_schedule,
        add_image_schedule,
        add_video_schedule,
        job_list_weekFilter,
        stopPagingJob,
        delete_job_joblistFx,
        add_image_signschedule,
        fetch_searchJob_paging,
        modalAction,
        modalStatus,
        currTab,
        setCurrTab, schdlListType,
        oneOffJobsCount_ALL, 
        outsourcedJobsCount_ALL, 
        reoccuredJobsCount_ALL, 
        deletedJobsCount_ALL,
        oneOffJobsCount, outsourcedJobsCount, reoccuredJobsCount, deletedJobsCount,
        selected_job
        //job_list_reOccur, job_list_reOccur_page,  fetch_jobs_reoccur 
    }) {
    const classes = useStyles();

    const [modal, setModal] = useState(false)
    const [schdModal, setSchdModal] = useState(false)
    const [quoteModal, setquoteModal] = useState(false)
    const [QuoteHistoryModal, setQuoteHistoryModal] = useState(false)
    const [selectedJob, setSelectedJob] = useState('')
    const [slEmployee, setSlEmployee] = useState([])
    const [quoteInfos, setQuoteInfos] = useState({ job: null, quote: null, schedul: null })
    const [quoteHistoryInfos, setQuoteHistoryInfos] = useState([])
    const [allVehicleArrObj, setAllVehicleArrObj] = useState(null)

    const [StartDate, setStartDate] = useState('')
    const [StartTime, setStartTime] = useState('')
    const [EndTime, setEndTime] = useState('')
    const [EndDate, setEndDate] = useState('')
    const [scheduleId, setScheduleId] = useState('')

    const [vehicleVal, setVehicleVal] = useState([])
    const [primeVehicle, setPrimeVehicle] = useState('')
    const [schduleStatus, setScheduleStatus] = useState('')

    
    const [selectedTeamArray, setSelectedTeamArray] = useState([])
    const [primeVehicleDriver, setPrimeVehicleDriver]    = useState(null)
    const [additionalVehTeamArr, setAdditionalVehTeamArr] = useState([])

    const [CommentsArr, setCommentsArr] = useState([])
    const [applyButtonDisabled, setApplyButtonDisabled] = useState(true)
    const [changeStatusDate, setChangeStatusDate] = useState(false)
    const [changeStatusTeam, setChangeStatusTeam] = useState(false)
    const [changeStatusVehicle, setChangeStatusVehicle] = useState(false)

    const [fromMonthYr, setFromMonthYr] = useState(job_list_weekFilter)
    const [isMock, setIsMock] = useState(false)
    const [restoreVal, setRestoreVal] = useState(null)

    const [quoteOpenLoader, setQuoteOpenLoader] = useState(null)
    const [scheduleOpenLoader, setScheduleOpenLoader] = useState(null)

    const [pageNo, setPageNo] = useState(1)

    const [openSearch, setOpenSearch] = useState(false);
    const [changeLoader, setChangeLoader] = useState(false);

    const [searchData, setSearchData] = useState(null)
    const [hideModal, setHideModal] = useState(false)
    const [showSearchLoader, setShowSearchLoader] = useState(false)
    const [customWeek, setCustomWeek] = useState({ status : false, start : new Date(), end : new Date() })
    const [jobValFilter, setJobValFilter] = useState({ value : 'week', label : 'Week Filter :' })
    const [exportLoader, setExportLoader] = useState(false)


    useEffect(() => {
        refresh_page()

        let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`

        fetch_jobs(screen_type, 1, fromD, currTab)
        // fetch_jobs_reoccur(screen_type, 1, [], fromD)

    }, [fetch_jobs, currTab,
        //fetch_jobs_reoccur,
        screen_type, refresh_page, fromMonthYr])

        const customStyles = {
            control : (styles) => ({...styles, borderRadius : '5px', padding : 0, 
                borderTop:'none', borderBottom:'none', borderLeft:'none', 
                fontSize:'.8rem', minHeight: 'unset', backgroundColor:'var(--cl_blue)', color:'white', fontWeight:'400',
                width:'100%', }),
            singleValue : (styles) => ({...styles, color:'white', fontWeight:'600'}),
            dropdownIndicator : (styles) => ({...styles, color:'white'})
            // input : (styles) => ({...styles, height:'.2vh'})
        }

    const handleClose = () => {
        modalAction(false)
    };

    const fetchQuoteInfos = (id, isMock) => {

        const succ = (res) =>{
            setQuoteOpenLoader(null)
                if(res.data){
                setQuoteInfos(res.data)
                setquoteModal(true)}
                else
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
        }

        const succ2 = (res) =>{
            setQuoteOpenLoader(null)
            setQuoteInfos(res.data)
            setquoteModal(true)
        }

        const fail = (err) =>{
                Swal.fire({
                   icon: 'error',
                   title: 'Oops...',
                   text: err && err.app_data ? err.app_data : 'Something went wrong!'
               })
        }

        if(isMock){
            Api('get', `${SCHEDULE_MOCK_QUOTE_INFOS}${id}/`,null, succ, fail)
        }
        else{ 
        Api('get', `${SCHEDULE_QUOTE_INFOS}${id}/`,null, succ2, fail)
    }
}

    const loadMore = (val) => {
        // if(currTab===0){
        const { top } = val
        // console.log('SCROLL', top)
        if (top === 1 && !stopPagingJob && ((pageNo === job_list_RFSchd_page) || (job_list_RFSchd_page === 1 && pageNo !== 1))) {
            setPageNo(job_list_RFSchd_page === 1 ? 2 : pageNo + 1)
            let frmD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`
            if(openSearch){
                console.log('LOADMORE_PAGE')
                if(searchData.prev === searchData.now)
                    fetch_searchJob_paging(screen_type, job_list_RFSchd_page + 1, job_list_RFSchd, frmD, searchData===null ? '' : searchData.now, currTab)
            }else
                fetch_jobs_pagination(screen_type, job_list_RFSchd_page + 1, job_list_RFSchd, frmD, currTab)
            

        }
        
    }



    
    const schedule_edit_btn_fx = (compleObj) => {

        const success = (res) => {
            setModal(false)
            setSelectedJob('')
            setPrimeVehicleDriver(null)
            setAdditionalVehTeamArr([])
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Scheduled Job Updated.',
                showConfirmButton: false,
                timer: 1500
            })
            //recall joblist api

        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        let data = {
            'id' : selectedJob.id,
            'start_date' : StartDate,
            'start_time' : StartTime + ':00',
            'end_time'   : EndTime + ':00',
            'end_date'   : EndDate ,
            'additional_vehicle_drivers' : additionalVehTeamArr,
            'status'     : schduleStatus,
            'primary_vehicle_driver' : primeVehicleDriver ? primeVehicleDriver : '',
            'vehicle'    : primeVehicle,
            'purchase_order_number'     : compleObj ? compleObj.ponumber : '',
            'signature_name'            : compleObj ? compleObj.signName : '',
            'extracted_waste_type'      : compleObj ? compleObj.chngWasteType : '',
            'extracted_litres_of_waste' : compleObj ? compleObj.chngWasteLtr : '',
            'compleObj'                 : compleObj
        }                
            if(compleObj.jobStatusTime){
                let currDate = moment(StartDate)
                let i = 0
                let obj = {}
                for(const vals in compleObj.jobStatusTime){
                    console.log(compleObj.jobStatusTime[vals],moment(`${compleObj.jobStatusTime[vals]}`).isValid())
                    if(moment(`${compleObj.jobStatusTime[vals]}`).isValid()){
                    let currStatusDate = moment(`${compleObj.jobStatusTime[vals]}`)
                    let setupDate = null
                    if(i===0){
                        currDate.set({ 'hour' : currStatusDate.get('hour'), 'minute' : currStatusDate.get('minute'), 'second' : currStatusDate.get('second') })
                        setupDate = currDate
                    }else{
                        currStatusDate.set({
                            'year' : currDate.get('year'), 'month' : currDate.get('month'), 'date' : currDate.get('date')
                        })
                        if(currStatusDate.diff(currDate) >= 0){
                            setupDate = currStatusDate
                        }else{
                            setupDate = currStatusDate.add(1,'day')
                        }

                    }
                    Object.assign(obj, { [`${vals}`] : `${moment(setupDate).format('YYYY-MM-DD HH:mm:ss')}`})
                    
                    console.log('LOOPDATA=>',obj)
                    // data.append(`${vals}`,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'))
                    i++

                    console.log('I=>',i,moment(setupDate).format('YYYY-MM-DD HH:mm:ss'), vals)
                    }
                }

                Object.assign(data, obj)

            }
        console.log('FINAL=>',data)
            
        // // }
        // console.log(data, screen_type, 'SCHEDULEEDIT')
        if(compleObj && ((compleObj.before_pics && compleObj.before_pics.length>0) 
        || (compleObj && compleObj.videoGallery && compleObj.videoGallery.length>0)
        || (compleObj && compleObj.signImage && typeof compleObj.signImage!=="string")
        ||  (compleObj.after_pics && compleObj.after_pics.length>0) || ( compleObj.gallery_pics && compleObj.gallery_pics.length>0))){

            Swal.fire({
                position : 'top-end',
                icon : 'info',
                title : 'Uploading Media',
                showLoading : true
            })
            const cloeseSwalFn = () =>{
                Swal.close()
            }
            addScheduleImg(selectedJob.id, 
                ((compleObj.before_pics && compleObj.before_pics.length>0) 
        ||  (compleObj.after_pics && compleObj.after_pics.length>0) || ( compleObj.gallery_pics && compleObj.gallery_pics.length>0)) ?
                compleObj : null, 
                (compleObj && compleObj.videoGallery && compleObj.videoGallery.length>0) ? compleObj.videoGallery : null, 
                (compleObj && compleObj.signImage && typeof compleObj.signImage!=="string") ? compleObj.signImage : null,
                cloeseSwalFn
                , null, "image")
        }
        // if(selectedJob.id && 
        //     ((compleObj.chngWasteLtr && compleObj.chngWasteLtr!=="") || 
        //     (compleObj.chngWasteType && compleObj.chngWasteType!=="") || (compleObj.ponumber && compleObj.ponumber!=="") ||
        //     (compleObj.signName && compleObj.signName!==""))){
        //     let data2 = {
        //         "id"                         : selectedJob.id,
        //         "extracted_waste_type"       : compleObj.chngWasteType,
        //         "extracted_litres_of_waste"  : compleObj.chngWasteLtr,
        //         "purchase_order_number"      : compleObj.ponumber,
        //         "signature_name"             : compleObj.signName
        //     }  
        //     editAfterCompleteElements(data2, null, null)
        // }


        schedule_edit_job(screen_type, data, schdlListType==='v' ? null : schdlListType, success, failed)

    }



    const setTeamApi = () => {
        const success = (res) => {
            setModal(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Schedule Edited!',
                showConfirmButton: false,
                timer: 1500
            })

            setSelectedJob('')
        }
        const failed = (res) => {
            console.log(res)
        }
        let data = new FormData()
        data.append('id', scheduleId)
        selectedTeamArray.map((item, key) => {
            data.append('members', item)
            return null
        })
        team_setting(screen_type, data, schdlListType==='v' ? null : schdlListType, success, failed)
    }

    const getVehicleVal = (val) => {
        Api('get', `${SCHEDULE_ADDITIONAL_VEHICLE}${val}/`).then(res => {
            console.log(res)
            if (res.additional_vehicles)
                setVehicleVal(res.additional_vehicles.map(item => { return item.id }))

        })
    }

    const setVehicleApi = () => {
        const success = (res) => {
            setModal(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Schedule Edited!',
                showConfirmButton: false,
                timer: 1500
            })

            setSelectedJob('')
        }
        const failed = (res) => {
            console.log(res)
        }

        let data = new FormData()
        let primeData = new FormData()
        primeData.append('id', scheduleId)
        primeData.append('vehicle', primeVehicle)
        for (let i = 0; i < vehicleVal.length; i++)
            data.append('vehicles', vehicleVal[i])
        vehicle_setting(screen_type, data, primeData, scheduleId, schdlListType==='v' ? null : schdlListType, success, failed)
    }

    const deleteScheduleApiFx = (scheduleId) => {
        const success = () => {
            let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`
            fetch_jobs(screen_type, 1, fromD, currTab)

        }
        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        
        delete_schedule(screen_type, scheduleId, schdlListType==='v' ? null : schdlListType, success, failed)
    }

    const updateSchedule = (fn1, fn2, fn3,fn4,compleObj=null) => {
        setSchdModal(false)
        if (fn1 || compleObj) {
            schedule_edit_btn_fx(compleObj)
        }
        if (fn2) {
            setTeamApi()
        }
        if (fn3) {
            setVehicleApi()
        }
    }


    const addScheduleImg = (id, shdlImages, shdlVid, shdlSign, handleClose, setLoader=null, fileType="image") => {
        let currFn = 0
        const success = (response) => {
            if(setLoader)
            setLoader(false)
            if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                handleClose()
            }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                handleClose()
            }else{
                currFn = currFn+1
            }
        }
        const failed = (res) => {
            if(setLoader)
            setLoader(false)
            console.log('upload failed', res)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong in Images uploading!'
            })
            if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                handleClose()
            }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                handleClose()
            }else{
                currFn = currFn+1
            }
        }
        const vidsuccess = (response) => {
            if(setLoader)
            setLoader(false)
            if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                handleClose()
            }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                handleClose()
            }else{
                currFn = currFn+1
            }
        }
        const vidfailed = (res) => {
            if(setLoader)
            setLoader(false)
            console.log('upload failed', res)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong in videos uploading!'
            })
            if(currFn >= 1 && (shdlImages && shdlSign && shdlVid)){
                handleClose()
            }else if(!(shdlImages && shdlSign && shdlVid) && (shdlImages || shdlSign || shdlVid)){
                handleClose()
            }else{
                currFn = currFn+1
            }

        }
            let data = new FormData()
            data.append('id', id)
            data.append('schedule_id', selected_job?.schedule?.id)
            if(shdlImages){
                if(shdlImages.gallery_pics && shdlImages.gallery_pics.length>0){
                    let i;
                    for (i = 0; i < shdlImages.gallery_pics.length; i++) {
                        let imgData = shdlImages.gallery_pics[i]
                        let imgName = imgData.name
                        data.append('image', imgData, imgName)
                    }
                
                }
                if(shdlImages.before_pics && shdlImages.before_pics.length>0){
                    let i;
                    for (i = 0; i < shdlImages.before_pics.length; i++) {
                        let imgData = shdlImages.before_pics[i]
                        let imgName = imgData.name
                        data.append('before_pics', imgData, imgName)
                    }
                
                }
                if(shdlImages.after_pics && shdlImages.after_pics.length>0){
                    let i;
                    for (i = 0; i < shdlImages.after_pics.length; i++) {
                        let imgData = shdlImages.after_pics[i]
                        let imgName = imgData.name
                        data.append('after_pics', imgData, imgName)
                    }
                
                }    
            }

            if(shdlSign){
                data.append('signature', shdlSign, `signatureJob${id}`)
            }
            if((shdlImages && shdlImages?.length>0) || (shdlSign && shdlSign?.length>0))
                Api('post-form',SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER,data,success,failed)


            if(shdlVid){
                let data2 = new FormData()
                data2.append('schedule_id', selected_job?.schedule?.id)
                let i;
                for (i = 0; i < shdlVid.length; i++) {
                    let imgData = shdlVid[i]
                    let imgName = imgData.name
                    if(IsVideoFileUpload(imgData.size))
                        data.append('videos', imgData, imgName)
                }
                if(setLoader)
                    setLoader(true)
                add_video_schedule(screen_type, data, true, vidsuccess, vidfailed)
                
            }
        console.log('ADDSCHEDULEIMG',id,shdlImages,shdlVid)
    }

    const schdFnModal = (item) => {

        const success = (res) => {
            setScheduleOpenLoader(null); 
            let allVArrObj = []
            if(res.schedule && res.schedule.vehicle)
                allVArrObj.push({ id: res.schedule.vehicle.id, registration : res.schedule.vehicle.registration, type : 'primary', vehicle_type : res.schedule.vehicle.types })
            if(res.schedule && res.schedule.additional_vehicle_driver)
                allVArrObj.push(...res.schedule.additional_vehicle_driver.filter(el => { return({ id: el.vehicle_id, registration : el.registration, vehicle_type : el.vehicle_type, type : 'additional' }) }))
            
            setSelectedJob(item)

            setStartDate(res.schedule.start_date)
            setStartTime(res.schedule.start_time)
            setEndTime(res.schedule.end_time)
            setEndDate(res.schedule.end_date)
            setScheduleStatus(res.schedule.status)
            setScheduleId(res.schedule.id)
            setCommentsArr(res.schedule.comments)

            setPrimeVehicleDriver(res.schedule.prime_vehicle_driver && res.schedule.prime_vehicle_driver[0].driver_id)
            setAdditionalVehTeamArr(res.schedule.additional_vehicle_driver)
            setAllVehicleArrObj(allVArrObj)
            // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === res.vehicle)
            let employee_data = []
            if (res.schedule.get_team_employees) {
                res.schedule.get_team_employees.map((item, key) => {
                    employee_data.push(item.id)
                    return null;
                })
            }
            if (res.vehicle) {
                setPrimeVehicle(res.vehicle.id)
            }
            getVehicleVal(res.schedule.id)
            setSelectedTeamArray(employee_data)
            assignSelectedJob()
            if (res && res.schedule && res.schedule.id) {
                setSchdModal(true);
            }
            else {
                failed('Schedule Missing.')
            }


        }

        const failed = (res) => {
            setScheduleOpenLoader(null); 
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res : `Something went wrong!`
            })

        }

        Api('get', `${SCHEDULE_JOB_VIEW}${item.is_mock_job ? item.job_code : item.id}/`).then(res => {
            console.log('JOBLIST=>schd', res)
            success(res)
        })
            .catch(err => { failed() })
    }

    const assignSelectedJob = () => {
        if (selectedJob && selectedJob.team_employees) {
            if (selectedJob.start_date !== StartDate || selectedJob.start_time !== StartTime || selectedJob.end_time !== EndTime || selectedJob.end_date !== EndDate || selectedJob.status !== schduleStatus) {
                setChangeStatusDate(true)
            } else {
                setChangeStatusDate(false)
            }
            if (selectedJob.team_employees.length !== selectedTeamArray && selectedTeamArray.length) {
                setChangeStatusTeam(true)
            } else {
                setChangeStatusTeam(false)
            }
            let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === selectedJob.vehicle)
            if (vehcl_data) {

                if (vehcl_data.id !== primeVehicle) {
                    setChangeStatusVehicle(true)
                } else {
                    setChangeStatusVehicle(false)
                }

            } else {
                if (primeVehicle) {
                    setChangeStatusVehicle(true)
                } else {
                    setChangeStatusVehicle(false)
                }
            }
        }

        if (changeStatusDate || changeStatusTeam || changeStatusVehicle) {
            setApplyButtonDisabled(false)
        }
    }

    const deleteJob = (id, isMock, param = false) => {
        setHideModal(true)
        Swal.fire({
            title: 'Are you sure?',
            // text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes, ${param ? 'restore' : 'delete'} it!`
        }).then((result) => {
            const success = () => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Job ${param ? 'restore' : 'delete'}d successful!`,
                    showConfirmButton: false,
                    timer: 1500
                })
                setHideModal(false)
            }

            const failed = () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                setHideModal(false)
            }

            if (result.value) {
                delete_job_joblistFx(screen_type, id, isMock, param, success, failed)
            }
            else{
                setHideModal(false)
            }
        })


    }

    const addScheduleSignImg = (id, shdlImages, handleClose) => {
        const success = (response) => {
            // console.log('the respo', response)
            setSelectedJob(response)
            let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`;

            fetch_jobs(screen_type, 1, fromD, currTab, setChangeLoader)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Signature added.',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            console.log('upload failed', res)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })

        }

        let data = new FormData()
        data.append('id', id)
        let i;
        for (i = 0; i < shdlImages.length; i++) {
            let imgData = shdlImages[i]
            let imgName = imgData.name
            data.append('image', imgData, imgName)
        }

        add_image_signschedule(screen_type, data, schdlListType==='v' ? null : schdlListType, success, failed)
    }

    const toggleExportLoader = () =>{
        setExportLoader(false)
    }

    const ToolTipContainer = ({val, val2}) =>{
        return(
            <div>
                <div style={{ backgroundColor:'var(--cl_green)', paddding:'5%', margin:'1% 0', fontWeight:'700', fontSize:'.8rem' }}>Not Scheduled : {val}</div>
                <div style={{ backgroundColor:'var(--cl_red)', paddding:'5%', margin:'1% 0', fontWeight:'700', fontSize:'.8rem' }}>Scheduled : {val2}</div>
            </div>
        )
    }


    if(modal || schdModal || quoteModal || QuoteHistoryModal){
        return(
            <>
            {   modal &&
                <JobModal
                    screen_type={screen_type}
                    modalStatus={modal}
                    modalAction={setModal}
                    selectedJob={selectedJob}
                    selected_team={slEmployee}
                    setSelectTeam={setSlEmployee}
                    isMock={isMock}
                    scheduled={true}
                />
                }
    {   schdModal &&            
                <SchdModal
                    modalStatus={schdModal}
                    modalAction={setSchdModal}
                    selectedJob={selectedJob}
                    openJobDetailModal={fetchQuoteInfos}
                    allVehArrObj={allVehicleArrObj}
    
                    StartDate={StartDate}
                    setStartDate={setStartDate}
                    StartTime={StartTime}
                    setStartTime={setStartTime}
                    EndTime={EndTime}
                    setEndTime={setEndTime}
                    EndDate={EndDate}
                    setEndDate={setEndDate}
    
                    scheduleId={scheduleId}
    
                    vehicleVal={vehicleVal}
                    setVehicleVal={setVehicleVal}
    
                    primeVehicle={primeVehicle}
                    setPrimeVehicle={setPrimeVehicle}
    
                    schduleStatus={schduleStatus}
                    setScheduleStatus={setScheduleStatus}
    
                    selectedArr={selectedTeamArray}
                    setSelectedArr={setSelectedTeamArray}
    
                    buttonDisabled={applyButtonDisabled}
    
                    updateScheduleFx={updateSchedule}
                    deleteScheduleFx={deleteScheduleApiFx}
    
                    addScheduleImg={addScheduleImg}
                    addScheduleSignImg={addScheduleSignImg}
    
                    CommentsArr={CommentsArr}
                    setCommentsArr={setCommentsArr}
                    fetch_schedule_list={ schdlListType==='v' ? fetch_schedule_list : fetch_Mem_schedule_list}
                    screen_type={screen_type}
                    prevData={schedules_list}
    
                    primeVehicleDriver={primeVehicleDriver}
                    setPrimeVehicleDriver={setPrimeVehicleDriver}
                    additionalVehTeamArr={additionalVehTeamArr}
                    setAdditionalVehTeamArr={setAdditionalVehTeamArr}
                />
    }
    {   quoteModal &&
                <QuoteModal
                    modalStatus={quoteModal}
                    modalAction={setquoteModal}
                    quoteInfos={quoteInfos}
                    setQuoteHistoryInfos={setQuoteHistoryInfos}
                    quoteHistoryModalAction={setQuoteHistoryModal}
                />
    }
    {   QuoteHistoryModal &&
                <QuoteHistoryModalView
                    modalStatus={QuoteHistoryModal}
                    modalAction={setQuoteHistoryModal}
                    quoteHistoryInfos={quoteHistoryInfos}
                    setQuoteHistoryInfos={setQuoteHistoryInfos}
                    quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                    quoteModalAction={setquoteModal}
                />
    }
    </>
        )
    }
    else
    return (
        hideModal ? null :
        <Modal
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
        >
            <div className="jobListMaxModal" style={{ overflowY:'auto' }}>
                <div className="jobListMoaxModalTitle">
                    <p>Job Lists</p>
                </div>

                <div className="shdl-jobs-layout" style={{ width:'95%', justifyContent : 'space-between' }}>
                <div className='shdl-jobs-layout-tabMainDiv'>
                    <Tooltip title={<ToolTipContainer val={oneOffJobsCount} val2={parseInt(oneOffJobsCount_ALL)-parseInt(oneOffJobsCount)} />}>
                    <div onClick={() => {
                    let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}`;
                    fetch_jobs(screen_type, 1, fromD,'norm', setChangeLoader); setCurrTab('norm')
                }}
                    className={currTab === 'norm' ? "shdl-jobs-tabDiv shdl-jobs-tabDivActive" : "shdl-jobs-tabDiv"}>One-Off
                    <span className='counterChip'>{oneOffJobsCount_ALL}</span>
                </div>
                
                    </Tooltip>
                    <Tooltip title={<ToolTipContainer val={reoccuredJobsCount} val2={parseInt(reoccuredJobsCount_ALL)-parseInt(reoccuredJobsCount)} />}>
                <div onClick={() => {
                    let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}`;
                    fetch_jobs(screen_type, 1, fromD,'rec', setChangeLoader); setCurrTab('rec')
                }}
                    className={currTab === 'rec' ? "shdl-jobs-tabDiv shdl-jobs-tabDivActive" : "shdl-jobs-tabDiv"}>Reocurring
                    <span className='counterChip'>{reoccuredJobsCount_ALL}</span>
                    </div>
                    </Tooltip>
                    <Tooltip title={<ToolTipContainer val={outsourcedJobsCount} val2={parseInt(outsourcedJobsCount_ALL)-parseInt(outsourcedJobsCount)} />}>
                <div onClick={() => {
                    let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}`;
                    fetch_jobs(screen_type, 1, fromD,'out', setChangeLoader); setCurrTab('out')
                }}
                    className={currTab === 'out' ? "shdl-jobs-tabDiv shdl-jobs-tabDivActive" : "shdl-jobs-tabDiv"}>Outsourced
                    <span className='counterChip'>{outsourcedJobsCount_ALL}</span>
                    </div>
                    </Tooltip>
                <div onClick={() => {
                    let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}&&deleted_status=del`;
                    fetch_jobs(screen_type, 1, fromD,'del', setChangeLoader); setCurrTab('del')
                }}
                    className={currTab === 'del' ? "shdl-jobs-tabDiv shdl-jobs-tabDivActive" : "shdl-jobs-tabDiv"}>Deleted
                    <span className='counterChip'>{deletedJobsCount_ALL}</span>
                    </div>
                </div>
                <div className='shdl-jobs-layout-tabMainDiv'>
                {!openSearch ?
                    <div className="shdl-jobList-search" style={{ padding: '0' }} onClick={() => setOpenSearch(true)}>
                        <Search />
                    </div>
                    :
                    <div className="shdl-jobList-search">
                        <SearchInputTab txt={'Search Jobs'}
                            url={`${
                                currTab==='del' ?    SALES_JOB_ReSCHD    :
                                currTab==='rec' ?   SCHEDULE_REOCCURRING_JOBS :
                                currTab==='norm' ?   SCHEDULE_NORMAL_JOBS    :
                                SCHEDULE_OUTSOURCE_JOBS
                                }${screen_type}/?page=1&limit=20&${`from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}`}${currTab === 'del' ? '&&deleted_status=del' : ''}`}
                            methodType={'get'}
                            dipatchforloading={{type:'JOB_LIST_SCHEDULE_readyForSchd_FETCHING'}}
                            dispatchFn={(v) => ({
                                type: 'JOB_LIST_SCHEDULE_readyForSchd', limitVal: 20, jobs: v, page: 1,
                                filterVal: fromMonthYr
                            })}
                            fn={() => {
                                let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`;
                                fetch_jobs(screen_type, 1, fromD, currTab); setOpenSearch(false);
                            }}
                            setSearchDataObj={setSearchData}
                            searchLoadFn={setShowSearchLoader}
                        />
                       
                    </div>
                }
                <div className="shdl-jobList-search" style={{ padding: '0 1%', borderRadius:'2px', margin:'0 1%' }} onClick={() => handleClose()}>
                    <FullscreenExitRounded />
                </div>
                </div>
            </div>

            
                <div className="jobListMaxTable" style={{ width:'95%', height:'50%', position:'relative' }}>
                {
                showSearchLoader ?
                <div className='shdl-jobList-search-anime'></div>
                : null
            }
                    <Scrollbars 
                        onScrollFrame={loadMore}
                        style={{ height: '53vh', backgroundColor:'whitesmoke', pointerEvents: modal ? 'unset' : 'auto' }}
                        renderThumbVertical={props => <div {...props} className="rsc-thumb-vertical" />}>
                    <table style={{ position: 'relative', height: 'auto' }}>
                        <thead style={{ backgroundColor:'white', top:0, position:'sticky', zIndex:1 }}>
                            {
                                <tr>
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Job Code</td>
                                    {/* <td style={{ fontSize: 14, fontWeight: 'bold' }}>Client Id</td> */}
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Client Name</td>
                                    {
                                        screen_type==='waste' ? 
                                            <td style={{ fontSize: 14, fontWeight: 'bold' }}>Waste Type</td> : null
                                    }
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Frequency Weeks</td>
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Address</td>
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Created Date</td>
                                    {   currTab==='out' || currTab==='rec'  ?
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Reoccuring Date</td>   :   null
                                    }
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Capacity</td>
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Paid Amount</td>
                                    <td style={{ fontSize: 14, fontWeight: 'bold' }}>Paid Status</td>
                                    {currTab !== 'del' ?
                                        <td style={{ fontSize: 14, fontWeight: 'bold' }}>Status</td>
                                        :
                                        null
                                    }
                                    {   currTab !== 'out' ?
                                    currTab !== 'del' ?
                                        <td style={{ fontSize: 14, fontWeight: 'bold' }}>Action</td>
                                        :
                                        <td style={{ fontSize: 14, fontWeight: 'bold' }}>Restore Job</td>   : null
                                    }
                                </tr>
                                
                            }
                        </thead>
                        <tbody>
                            {tableLoader || changeLoader ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '90%', height: '20vh', position: 'absolute' }}>
                                    <CircularProgress />
                                </div>
                                :
                                (job_list_RFSchd && job_list_RFSchd.length === 0) ?

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '90%', height: '20vh', position: 'absolute' }}>
                                        <NoDatasScreen />
                                    </div>

                                    : 
                                    <>
                                        {
                                            !changeLoader && job_list_RFSchd && job_list_RFSchd.map((item, key) => {
                                                const { schedule_status, client_name, is_mock_job } = item
                                                return (
                                                    <tr key={key} style={{ position: 'relative', fontSize:14, backgroundColor: key%2 ? 'white' : 'transparent', height : '2.6vh' }}>
                                                        <Tooltip
                                                            title={ is_mock_job ?
                                                                `Reoccuring on ${moment(item.recurring_date).format('DD-MM-YYYY')} ${
                                                                    //item.parent_quote_id ? `from Quote ${item.parent_quote_id}` : 
                                                                    ''}`
                                                                :   `Created on ${moment(item.created_date_time).format('DD-MM-YYYY')}`}
                                                            arrow={true} placement={'right'}>
                                                            <td>
                                                                {   (quoteOpenLoader===item.quote && !is_mock_job) || (quoteOpenLoader===item.id && is_mock_job)  ?
                                                                <button style={{
                                                                    height: 'auto', padding: '.2rem',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: is_mock_job ? 'rgb(206, 206, 206, 0.5)' : 'rgb(206, 206, 206)',
                                                                }}
                                                                    className="shdl-jobs-action-btn-active" >
                                                                    <CircularProgress size={10} />
                                                                </button>
                                                                :
                                                                <button style={{
                                                                    height: 'auto', padding: '.5rem',
                                                                    cursor: 'pointer',
                                                                    backgroundColor: is_mock_job ? 'rgb(206, 206, 206, 0.5)' : 'rgb(206, 206, 206)',
                                                                }}
                                                                    className="shdl-jobs-action-btn-active"
                                                                    onClick={() => { 
                                                                        setQuoteOpenLoader(is_mock_job ? item.id : item.quote); 
                                                                        fetchQuoteInfos(
                                                                            is_mock_job ? 
                                                                        item.id 
                                                                        // :
                                                                        // item.job_card_id
                                                                        : 
                                                                        item.quote
                                                                            , is_mock_job); 
                                                                    }} >
                                                                    {item.job_code??item.code}
                                                                </button>
                                                                }
                                                                {is_mock_job ? <span className="indicatedDot" style={{ top: '30%' }}></span> : null}
                                                            </td>
                                                        </Tooltip>
                                                        {/* <td>{item.client ? item.client : '-'}</td> */}
                                                        <td style={{ fontSize:14 }}>{client_name}</td>
                                                        {   screen_type==='waste' ? 
                                                        <td style={{ wordWrap:'break-word', width:'15%', fontSize:14 }}>
                                                            {item.type_of_waste_str_profile_name ?
                                                                 `${item.type_of_waste_str_profile_name}(${item.waste_type_str})` 
                                                                : item.waste_type_str}
                                                        </td> : null
                                                        }
                                                        <td style={{ wordWrap:'break-word', width:'5%', fontSize:14 }}>{item.frequency}</td>
                                                        
                                                        {/* <td style={{ fontSize:14 }}>{item.quoted_by ? item.quoted_by : '-'}</td> */}

                                                        <td style={{ fontSize:14 }}>{item?.job_card_keys?.site_address}</td>

                                                        <td style={{ fontSize:14 }}>{item.created_date_time ? moment(item.created_date_time).format('DD-MM-YYYY hh:mm:ss a') : '-'}</td>
                                                        {
                                                            (currTab==='out' || currTab==='rec') &&
                                                            <td style={{ fontSize:14 }}>{item.recurring_date ? moment(item.recurring_date).format('DD-MM-YYYY') : '-'}</td>
                                                        }
                                                        
                                                        {/* <td style={{ fontSize:14 }}>{item.quote ? item.quote : item.parent_quote_id ? `Reoccuring Quote ${item.parent_quote_id}` : '-'}</td> */}
                                                        
                                                        <td style={{ fontSize:14 }}>{item?.job_card_keys?.capacity}</td>
                                                        
                                                        <td style={{ fontSize:14 }}>{item.paid_amount ? item.paid_amount : '-'}</td>
                                                        <td style={{ fontSize:14 }}>{item.paid_status ? item.paid_status : '-'}</td>

                                                        {
                                                            currTab !== 'out' ?
                                                            currTab !== 'del' ?
                                                                <td>
                                                                    {
                                                                        scheduleOpenLoader===item ?
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <button
                                                                                    className="shdl-jobs-action-btn-inactive"
                                                                                    style={{ cursor: 'pointer', pointerEvents: 'auto', backgroundColor:'lightgray' }}
                                                                                >
                                                                                    <CircularProgress size={10} />
                                                                                </button>
                                                                    
                                                                        </div>
                                                                    :
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        {
                                                                            schedule_status ? (
                                                                                <button
                                                                                    className="shdl-jobs-action-btn-inactive"
                                                                                    style={{ cursor: 'pointer', pointerEvents: modal ? 'unset' : 'auto' }}
                                                                                    onClick={() =>{ setScheduleOpenLoader(item); schdFnModal(item);}}
                                                                                >
                                                                                    {
                                                                                        item?.schedule && item.schedule?.scheduled_date_time ?
                                                                                        moment(item.schedule.scheduled_date_time).format('DD-MMM-YYYY'):''
                                                                                    }
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    className="shdl-jobs-action-btn-active"
                                                                                    onClick={() => {
                                                                                        setScheduleOpenLoader(item); 
                                                                                        if (!schedule_status) {
                                                                                            let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === item.vehicle)
                                                                                            let employee_data = []
                                                                                            if (item.team_employees) {
                                                                                                item.team_employees.map((item, key) => {
                                                                                                    employee_data.push(item.id)
                                                                                                    return null;
                                                                                                })
                                                                                            }
                                                                                            if (vehcl_data) {
                                                                                                setPrimeVehicle(vehcl_data.id)
                                                                                            }

                                                                                            setSelectedJob(item)
                                                                                            setIsMock(is_mock_job ? item.id : null)
                                                                                            setModal(true)
                                                                                            getVehicleVal(0)
                                                                                            assignSelectedJob()
                                                                                            setScheduleOpenLoader(null); 
                                                                                        }
                                                                                    }}
                                                                                >Schedule</button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    }
                                                                </td>
                                                                : null : null
                                                        }
                                                        {   currTab!== 'out' ?
                                                        <td>
                                                            {currTab !== 'del' ?
                                                                <button
                                                                    className="shdl-jobs-action-btn-inactive"
                                                                    style={{ cursor: 'pointer', pointerEvents: modal ? 'unset' : 'auto', width: 'auto', backgroundColor: 'transparent', color: 'red' }}
                                                                    onClick={() => deleteJob(item.id, is_mock_job)}
                                                                ><DeleteForever /></button>
                                                                :
                                                                <button
                                                                    className="shdl-jobs-action-btn-inactive"
                                                                    style={{ cursor: 'pointer', pointerEvents: modal ? 'unset' : 'auto', width: 'auto', backgroundColor: 'transparent', color: 'green' }}
                                                                    onClick={() => deleteJob(item.id, is_mock_job, true)}
                                                                >
                                                                    <Tooltip open={restoreVal === item.id}
                                                                        title={`Restore this Job`}
                                                                        arrow={true} placement={'left'}>
                                                                        <RestoreFromTrashOutlined
                                                                            onMouseEnter={() => { setRestoreVal(item.id) }}
                                                                            onMouseLeave={() => { setRestoreVal(null) }}

                                                                        />
                                                                    </Tooltip>
                                                                </button>

                                                            }
                                                        </td>
                                                        :   null 
                                                    }
                                                    </tr>
                                                )

                                            })
                                        }
                                        {
                                            job_list_RFSchd_loader && <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '90%', height: '30px', position: 'absolute' }}>
                                                <CircularProgress style={{height:'20px',width:'20px'}}/>
                                            </div>
                                        }
                                    </>

                                
                            }
                        </tbody>
                    </table>
                    </Scrollbars>
                    <div className='shdl-jobs-footerDivTab'>
                    {
                    currTab==='out' || currTab==='norm' ?
                <div className="shdl-jobs-footer-sort"
                style={{ height:'5vh', backgroundColor:'lightgrey', justifyContent:'flex-start', alignItems:'center' }}>
                    <span style={{ marginLeft:'2%', marginRight:'2%' }}>Week Filter :</span>
                            <FormControl className={classes.formControl}>
                                <Select
                                    disableUnderline
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    SelectDisplayProps={{ fontSize: 14, color: 'black' }}
                                    value={fromMonthYr !== 4 && fromMonthYr !== 3 && fromMonthYr !== 2 && fromMonthYr !== 1 ? 0 : fromMonthYr}
                                    className={classes.resize}
                                    style={{ color: 'black' }}
                                    onChange={(val) => {
                                        if (val.target.value === 0)
                                            setCustomWeek({ ...customWeek, status: true })
                                        else
                                            setFromMonthYr(val.target.value)
                                    }}
                                >
                                    <MenuItem value={1}>1 Weeks</MenuItem>
                                    <MenuItem value={2}>2 Weeks</MenuItem>
                                    <MenuItem value={3}>3 Weeks</MenuItem>
                                    <MenuItem value={4}>4 Weeks</MenuItem>
                                    {/* <MenuItem value={0}>Custom Week</MenuItem> */}

                                </Select>
                            </FormControl>
                </div>
                    :
                    <>
                    <div style={{ width:'45%', display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'space-between' }}>
                    <div className="shdl-jobs-footer-sort"
                     style={{ height:'3vh', backgroundColor:'white', justifyContent:'flex-start', alignItems:'center', marginLeft:'2%' }}>
                    <ReactSelect
                        styles={customStyles}
                        isClearable={false}
                        isSearchable={false}
                        menuPlacement='top'
                        classNamePrefix={'react-select-customStyle'}
                        defaultValue={
                            { value : 'week', label : 'Week Filter :' }
                        }
                        options={[
                            { value : 'week', label : 'Week Filter :' },
                            { value : 'date', label : 'Date Filter :'},
                            { value : 'month', label : 'Month Filter'}
                        ]}
                        value={jobValFilter}
                        onChange={(newValue)=>{
                            setJobValFilter(newValue)
                        }}
                    />
                    {
                        jobValFilter.value==="week" ?
                        <FormControl className={classes.formControl}>
                            <Select
                                disableUnderline
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                SelectDisplayProps={{ fontSize: 14, color: 'black' }}
                                value={fromMonthYr!==4 && fromMonthYr!==3 && fromMonthYr!==2 && fromMonthYr!==1 ? 0 : fromMonthYr}
                                className={classes.resize}
                                style={{ color: 'black' }}
                                onChange={(val) => {
                                    if(val.target.value===0)
                                        setCustomWeek({ ...customWeek, status : true})
                                    else
                                        setFromMonthYr(val.target.value)
                                }}
                            >
                                <MenuItem value={1}>1 Weeks</MenuItem>
                                <MenuItem value={2}>2 Weeks</MenuItem>
                                <MenuItem value={3}>3 Weeks</MenuItem>
                                <MenuItem value={4}>4 Weeks</MenuItem>
                            </Select>
                        </FormControl>
                            :   
                            jobValFilter.value==="date" ?
                            <>
                                <div style={{ width: '20%' }}>
                                    <DateTimeInput
                                        dateValue={customWeek.start}
                                        dateSetFn={(e) => { setCustomWeek({ ...customWeek, start: e }) }}
                                    />
                                </div>
                                <span style={{ padding: '0 1%' }}>To</span>
                                <div style={{ width: '20%' }}>
                                    <DateTimeInput
                                        dateValue={customWeek.end}
                                        dateSetFn={(e) => { setCustomWeek({ ...customWeek, end: e }) }}
                                    />
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: "row" }}>
                                    <button className="shdl-jobs-action-btn-active"
                                        onClick={() => {
                                            let fromD = `from_date=${moment(customWeek.start).format('YYYY-MM-DD')}&&to_date=${moment(customWeek.end).format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`
                                            fetch_jobs(screen_type, 1, fromD, currTab, setChangeLoader)
                                        }}
                                    >
                                        Filter
                                    </button>
                                    <button className="shdl-jobs-action-btn-active" style={{ marginLeft: '1%', backgroundColor: 'var(--cl_blue)', color: 'white' }}
                                        onClick={() => {
                                            let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`;
                            fetch_jobs(screen_type, 1, fromD, currTab);
                                            setCustomWeek({ status: false, start: new Date(), end: new Date() }) }}
                                    >
                                        Revoke
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ width: '20%' }}>
                                    <DateTimeInput
                                        dateValue={customWeek.start}
                                         viewOnly={["month","year"]}
                                        dateSetFn={(e) => { setCustomWeek({ ...customWeek, start: moment(e).startOf('M') }) }}
                                    />
                                </div>
                                <span style={{ padding: '0 1%' }}>To</span>
                                <div style={{ width: '20%' }}>
                                    <DateTimeInput
                                        dateValue={customWeek.end}
                                         viewOnly={["month","year"]}
                                        dateSetFn={(e) => { setCustomWeek({ ...customWeek, end: moment(e).endOf('M') }) }}
                                    />
                                </div>
                                <div style={{ width: '30%', display: 'flex', flexDirection: "row" }}>
                                    <button className="shdl-jobs-action-btn-active"
                                        onClick={() => {
                                            let fromD = `from_date=${moment(customWeek.start).format('YYYY-MM-DD')}&&to_date=${moment(customWeek.end).format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`
                                            fetch_jobs(screen_type, 1, fromD, currTab, setChangeLoader)
                                        }}
                                    >
                                        Filter
                                    </button>
                                    <button className="shdl-jobs-action-btn-active" style={{ marginLeft: '1%', backgroundColor: 'var(--cl_blue)', color: 'white' }}
                                        onClick={() => { 
                                            let fromD = `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}`;
                            fetch_jobs(screen_type, 1, fromD, currTab);
                                            setCustomWeek({ status: false, start: new Date(), end: new Date() }) }}
                                    >
                                        Revoke
                                    </button>
                                </div>
                            </>
                    }
                </div>
                    </div>              
                    </>
                }
                    
                    <div className='shdl-jobs-footer-btn' style={{ textAlign:'center' }}
                        onClick={()=>{
                            setExportLoader(true)
                            let frmD = jobValFilter.value==="date" ?
                                `from_date=${moment(customWeek.start).format('YYYY-MM-DD')}&&to_date=${moment(customWeek.end).format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}${searchData ? `&key=${searchData.now}` : ''}`
                            :
                            `from_date=${moment(new Date()).format('YYYY-MM-DD')}&&to_date=${moment(new Date()).add(fromMonthYr, 'w').format('YYYY-MM-DD')}${currTab === 'del' ? '&&deleted_status=del' : ''}${searchData ? `&&key=${searchData.now}` : ''}`
                            takeAllJobsPrintNEW(screen_type, 1, frmD, toggleExportLoader, toggleExportLoader) }}
                    >
                        {
                            exportLoader ?
                            <CircularProgress  size={'1.5rem'} color='white' />
                            :
                            'Export Report as CSV'
                        }
                        
                    </div>
                    </div>
                    
                </div>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    const { job_list_RFSchd_ALL, job_list_RFSchd_page_ALL, selected_team, tableLoader_ALL, stopPagingJob_ALL,
        job_list_RFSchd_pending, job_list_RFSchd_pending_page, schedules_list, vehicle_list, schdlListType,
        oneOffJobsCount, outsourcedJobsCount, reoccuredJobsCount, deletedJobsCount,
        oneOffJobsCount_ALL, outsourcedJobsCount_ALL, reoccuredJobsCount_ALL, deletedJobsCount_ALL,
        job_list_reOccur, job_list_reOccur_page, job_list_weekFilter_ALL, job_list_RFSchd_loader_ALL } = state.schedule
    const { selected_job } = state.jobView
    return {
        job_list_RFSchd : job_list_RFSchd_ALL, 
        job_list_RFSchd_page : job_list_RFSchd_page_ALL, selected_team, 
        tableLoader : tableLoader_ALL, 
        stopPagingJob : stopPagingJob_ALL,
        job_list_RFSchd_pending, job_list_RFSchd_pending_page, schedules_list, vehicle_list, schdlListType,

        oneOffJobsCount, 
        outsourcedJobsCount, 
        reoccuredJobsCount, 
        deletedJobsCount,
        oneOffJobsCount_ALL, 
        outsourcedJobsCount_ALL, 
        reoccuredJobsCount_ALL, 
        deletedJobsCount_ALL,

        job_list_reOccur, job_list_reOccur_page, 
        job_list_weekFilter : job_list_weekFilter_ALL, 
        job_list_RFSchd_loader : job_list_RFSchd_loader_ALL,
        selected_job
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_jobs: (type, page, frmD, jobType, callBk=null) => dispatch(fetch_job_list_readyForSchdNew(type, page, frmD, jobType, callBk)),

        fetch_schedule_list : (type, page, success, prevData) => dispatch(fetch_schedules(type, page, success, prevData)),
        fetch_Mem_schedule_list     : (type, page, success, prevData) => dispatch(fetch_Mem_schedules(type, page, success, prevData)),

        refresh_page: () => dispatch({ type: 'RELOAD_PAGE' }),
        fetch_jobs_pagination: (type, page, prevData, frmD, jobType) => dispatch(fetch_job_list_RFSchd_append_paginationNew(type, page, prevData, frmD, jobType)),
        
        schedule_edit_job       : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_setting            : (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        vehicle_setting         : (screen_type, data, primeData, shdId, schType, success, failed) => dispatch(add_vehicle_to_job(screen_type, data, primeData, shdId, schType, success, failed)),
        delete_schedule         : (type, data, schType, success, failed) => dispatch(schedule_job_delete(type, data, schType, success, failed)),
        add_image_schedule      : (type, data, schType, success, failed) => dispatch(schedule_image_add(type, data, schType, success, failed)),
        add_video_schedule      : (type, data, schType, success, failed) => dispatch(schedule_video_add(type, data, schType, success, failed)),
        add_image_signschedule  : (type, data, schType, success, failed) => dispatch(schedule_imageSign_add(type, data, schType, success, failed)),
        
        delete_job_joblistFx: (type, id, jobType, param, success, failed) => dispatch(delete_job_joblist_ALL(type, id, jobType, param, success, failed)),
        fetch_searchJob_paging: (type, page, prevData, frmD, txt, jobType) => dispatch(fetch_job_list_RFSchdSearch_append_paginationNew(type, page, prevData, frmD, txt, jobType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal);