import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import Scrollview from '../../common/ImprovedScrollBar';
import { connect } from 'react-redux';
import { team_leaveApply } from '../team/action';
import { add_team_to_job, fetch_Employee_avilabilities, schedule_job_edit } from '../scheduling/common/action';
import { LEAVESTATUS_UPDATE, SCHEDULE_JOB_VIEW } from '../../store/endpoint';
import Api from '../../store/api';
import Swal from 'sweetalert2'
import moment from 'moment';
import Failerrdevmsg from '../../common/failErrDevMsg';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


function JobReschedule({ modalStatus, team_leaveApplyFx, modalAction, employee_listDateTime, jobIdList, employeeDet, team_setting, fetch_availabilityEmp, schedule_edit_job }) {
    
    const classes = useStyles();

    const [selectedTeamArr, setSelectedTeamArr] = useState([])
    const [selected_job, setSelectedJob] = useState(null)
    const [rescheduledJobs, setResheduledJob] = useState([])
    const [replaceEmp, setReplaceEmp ] = useState(null)
    const [scheduleLoader, setScheduleOpenLoader] = useState(false)
    const [allVehicleArrObj, setAllVehicleArrObj] = useState(null)
    const [primeDrv, setPrimeDrv] = useState(null)
    const [reschdType, setReschdType] = useState(false)
    const [selectedJobId, setSelectedJobId] = useState(null)
    const [reschdLoader, setReschdLoader] = useState(false)

    const handleClose = () => {
        setSelectedJob(null)
        setSelectedTeamArr([])
        setScheduleOpenLoader(false)
        setAllVehicleArrObj(null)
        modalAction(false)
        setSelectedJobId(null)
    }

    const setTeamApi = () => {
        setReschdLoader(true)
        if(reschdType){
            const success = (res) => {
                team_leaveApplyFx()
                if(!rescheduledJobs.includes(selectedJobId))
                setResheduledJob(data => [...data, selectedJobId])
                approveLeave(true)            
                setReplaceEmp(null)
                setSelectedJobId(null)
                setReschdLoader(false)
            }
    
            const failed = (res=null) => {
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Oops...',
                //     text: 'Something went wrong!'
                // })
                Failerrdevmsg(res)
                setReschdLoader(false)
            }

            let tempArr = []
            if(primeDrv!==employeeDet.employee){
                tempArr = allVehicleArrObj.map(el => { 
                    if(el.driver_id===employeeDet.employee){
                        return { vehicle_id : el.vehicle_id, driver_id : replaceEmp }
                    }else{
                        return { vehicle_id : el.vehicle_id, driver_id : el.driver_id } 
                    }
                })
                    
            }
            // console.log(tempArr, replaceEmp, primeDrv, employeeDet.employee)

            let data = {
                'id' : selected_job.id,
                'start_date' : selected_job.start_date,
                'start_time' : selected_job.start_time,
                'end_time'   : selected_job.end_time,
                'end_date'   : selected_job.end_date,
                'additional_vehicle_drivers' : primeDrv!==employeeDet.employee ? tempArr : allVehicleArrObj,
                'status'     : selected_job.status,
                'primary_vehicle_driver' : primeDrv!==employeeDet.employee ? primeDrv : replaceEmp,
                'vehicle'    : selected_job.prime_vehicle_driver[0].vehicle_id,
                
            }
            // let data = new FormData()
            // data.append('id', selectedJob.id)
            // data.append('start_date', moment(StartDate).format('YYYY-MM-DD'))
            // data.append('start_time', StartTime + ':00')
            // data.append('end_time', EndTime + ':00')
            // data.append('end_date', moment(EndDate).format('YYYY-MM-DD'))
            // data.append('status', schduleStatus)
            schedule_edit_job(selected_job.tab_type, data, null,success, failed)

    }
    else{

        let temp = selectedTeamArr
            temp.splice(temp.findIndex(el => el === employeeDet.employee), 1, replaceEmp)
        setSelectedTeamArr(temp)

        const success = (res) => {
            team_leaveApplyFx()
            if(!rescheduledJobs.includes(selectedJobId))
                setResheduledJob(data => [...data, selectedJobId])
            approveLeave(true)
            setSelectedJobId(null)

        }
        const failed = (res=null) => {
            handleClose()
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
       }
       let data = new FormData()
       data.append('id', selected_job.id)
       selectedTeamArr.map((item, _) => {
           data.append('members', item)
           return null
       })
       team_setting(selected_job.tab_type, data, null, success, failed)
    }
    }

    const fetchScheduleData = (id) =>{
        
        let get_url = `${SCHEDULE_JOB_VIEW}${id}/`
        Api('get', get_url).then(res => {
            if(res){
                console.log('RESPONSE',res)
                setSelectedJob( res && res.schedule ? res.schedule : null)
                setSelectedJobId(res && res.id)
            if(res.schedule && res.schedule.additional_vehicle_driver && res.schedule.additional_vehicle_driver.length!==0)
                setAllVehicleArrObj([...res.schedule.additional_vehicle_driver])
            else 
                setAllVehicleArrObj([])
            setPrimeDrv(res.schedule.prime_vehicle_driver[0].driver_id)
            // let vehcl_data = vehicle_list && vehicle_list.find(itemzz => itemzz.registration === res.vehicle)
                    
                if(res.schedule.get_team_employees && res.schedule.get_team_employees.length!==0)
                    setSelectedTeamArr([...res.schedule.get_team_employees.map(el => el.id)])
                else 
                    setSelectedTeamArr([])
                setReplaceEmp(null)
                setScheduleOpenLoader(true); 
                console.log(allVehicleArrObj)
                fetch_availabilityEmp(`start=${res.schedule.start_date} ${res.schedule.start_time}&&end=${res.schedule.end_date} ${res.schedule.end_time}`)      
            }
        })
    }

    const approveLeave = (val) =>{
        if(rescheduledJobs.length===jobIdList.length && val){
            
            let get_url = `${LEAVESTATUS_UPDATE}${employeeDet.id}/?status=approved`
        const success = () => {
            team_leaveApplyFx()
            handleClose()
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Leave Approved',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
            handleClose()
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }
        
        Api('get', get_url,'', success, failed)
        }
        else if(!val){
            
            let get_url = `${LEAVESTATUS_UPDATE}${employeeDet.id}/?status=rejected`
        const success = () => {
            team_leaveApplyFx()
            handleClose()
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Leave Rejected',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res=null) => {
            handleClose()
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: 'Something went wrong!'
            // })
            Failerrdevmsg(res)
        }
        
        Api('get', get_url,'', success, failed)   
        }
        
    }

    return (
        <Modal
            className={classes.modal}
            open={modalStatus}
            onClose={() => handleClose(false)}
        >    
        <div className="jobRescheduleDivLayout" style={{ overflowY:'auto' }}>
            <div style={{ height:'5%', display:'flex', flexDirection:'row' }}>
                <div className="header-button-top"  onClick={()=>  handleClose() }>
                    Back
                </div>
                <div className="header-button-top"  onClick={()=>  approveLeave(false) }>
                    Reject Leave
                </div>
            
            </div>
            <div className="jobRescheduleDividedSection" >
            <div className="jobRescheduleContentDiv">
                <div className="jobRescheduleTopDiv">
                    <div>Jobs to be rescheduled</div>
                </div>
                <Scrollview style={{ height: '55vh' }} autoHide >
                <div className="jobRescheduleJobCodeList">
                    
                {   
                jobIdList.map(( item, k) => {
                        if(selected_job && item.id===selectedJobId){
                            return(<div key={k} className="jobRschdJobCodeListBtn jobRschdJobCodeListBtnSelected">Job : {item.id} ({moment(item.created_date).format('DD-MM-YYYY')})</div>)
                        }else if(rescheduledJobs.includes(item.id)){
                            return(<div key={k} className="jobRschdJobCodeListBtn jobRschdJobCodeListBtnSelected">Rescheduled Job : {item.id} ({moment(item.created_date).format('DD-MM-YYYY')})</div>)
                        }
                        else 
                        return(    
                    <div key={k} className="jobRschdJobCodeListBtn" onClick={()=> {
                        setSelectedJob(null)
                        setSelectedTeamArr([])
                        setScheduleOpenLoader(false)
                        setAllVehicleArrObj(null)
                        setReschdType(false)
                        setPrimeDrv(null)
                        fetchScheduleData(item.id)
                    }} >JobCode: {item.id} ({moment(item.created_date).format('DD-MM-YYYY')})</div>
                    )
                })
                }    
                </div>
                </Scrollview>
            </div>
            <div className="jobRescheduleContentDiv">
                <div className="jobRescheduleTopDiv">
                    <div>Team Available{reschdType ? 'true' : 'false'}{replaceEmp}</div>
                    
                    {   selected_job!==null ?
                    <span>
                        {allVehicleArrObj && allVehicleArrObj.find(el => el.driver_id === employeeDet.employee ) ? ` (Additional Vehicle Driver) ` : primeDrv===employeeDet.employee ? ' (Prime Vehicle Driver) ' : null }
                        {employee_listDateTime && scheduleLoader ? `Available : ${employee_listDateTime.filter(el => el.is_available===true).length}` : '(loading...)' }
                    </span>
                    : null
                    }
                </div>
                {   selected_job!==null ?
                <Scrollview style={{ height: '55vh' }}>
                    <div className="jobRescheduleTeamList">
                    {   employee_listDateTime && employee_listDateTime.length!==0 ?
                        employee_listDateTime && employee_listDateTime.map(( item, key ) => {
                        const { id, name, dp, is_available, terminated_status } = item
                        let urlll = dp
                        if(!terminated_status)
                        return (
                            <div 
                                    key={key}
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => {
                                        console.log(allVehicleArrObj)
                                        if((is_available && !terminated_status) && (id!==employeeDet.employee)){
                                            setReplaceEmp(id)
                                            if(
                                                (allVehicleArrObj && allVehicleArrObj.find(el => el.driver_id === employeeDet.employee )) ||
                                                primeDrv===employeeDet.employee
                                            ){
                                                setReschdType(true)
                                            }
                                            else
                                            {
                                                setReschdType(false)
                                            }
                                        }
                                            
                                    }}
                                >
                                    {
                                        terminated_status ? 
                                        <p style={{ textAlign: 'center', fontWeight: 400, fontSize: 12 }}>Terminated</p>
                                        :null
                                    }                                
                                <div key={key} className={is_available && !terminated_status && (id!==employeeDet.employee)
                                     ? "shdl-single-team-circle" : "shdl-single-team-circle inactiveCircle"}
                                     style={{ backgroundColor: replaceEmp === id ? 'var(--cl_blue)' : 'transparent' }}
                                     >
                                    <img src={urlll} alt="" />
                                </div>
                                    <p style={{ textAlign: 'center', fontWeight: 400, fontSize: 12, wordBreak:'break-all' }}>{name}</p>
                                </div>
                            )
                        else return null
                        })
                        :
                        <div className="selectJobWarn">Checking for available team...</div>
                    }
                    </div>
                </Scrollview>   :   null
                }
                {
                    selected_job===null ?
                    <div className="selectJobWarn">
                        Select a job to Reschedule
                    </div>
                    :   
                    
                        rescheduledJobs.includes(selectedJobId) ? null :
                        <div className="jobRescheduleBtn" style={{ cursor:'pointer' }} onClick={()=> setTeamApi() }>
                            { rescheduledJobs.length===jobIdList.length ? 'Approve Leave' : 
                                reschdLoader ? 
                                <CircularProgress size={20} />
                                :
                            'Reschedule Job'}
                        </div>
                    
                }
                
            </div>
                
            </div>
            
        </div>
        </Modal>
    );
}

const mapStateToProps = (state) =>{
    const { employee_listDateTime } = state.schedule
    return{
        employee_listDateTime
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        team_setting: (screen_type, data, schType, success, failed) => dispatch(add_team_to_job(screen_type, data, schType, success, failed)),
        schedule_edit_job       : (type, data, schType, success, failed) => dispatch(schedule_job_edit(type, data, schType, success, failed)),
        team_leaveApplyFx: () => dispatch(team_leaveApply()),
        fetch_availabilityEmp : (filter) => dispatch(fetch_Employee_avilabilities(filter))

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (JobReschedule);