import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Scrollview from '../../../../common/ImprovedScrollBar';
// import FolderApp from '../../../../common/folder/folder_main';
// import { CircularProgress } from '@material-ui/core'
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IntranetLayout from '../../../../common/filesNFolder/FileFoldersLayout';
import { HOST_WITHOUT_SLASH, INTRANET_FILES_RENAME, INTRANET_FOLDER_EDIT, OHS_AND_S_FILE_ADD, OHS_AND_S_FILE_DELETE, OHS_AND_S_FOLDER_DELETE, VEHICLE_FILESFOLDERS_GET, VEHICLE_FOLDER_CREATE } from '../../../../store/endpoint';
import { AddCircleOutlineOutlined, DeleteForever, DescriptionRounded } from '@material-ui/icons';
import { CircularProgress, Tooltip } from '@material-ui/core';
import Swal from 'sweetalert2'
import Api from '../../../../store/api'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom:'10px',
    marginRight:'10px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({
  modalStatus, 
  modalAction, 
  // mainFolder, 
  // folderLoader,
  // folderResponse,
  // folderError,
  // folderStatus,
  // updationFunction,
  // deletion,
  // setFolderCreateModal,
  // setFolderName,
  // setModalType,
  // selectedFolderId,
  // setSelectedFolderId,
  // deleteFolderApiCall,
  perms,
  vehicle_id,
  vehicle_reg,
  tabType,
  vehicleType,
  spl=null,
  url=null,
  selectedFolderId=null,
  urlToAddFile=null,
  dispatchFn=null
}) {
    const classes = useStyles();
    const [tempModal, setTempModal] = useState(true)
    const [vehicleFiles, setVehicleFiles] = useState([])
    const [fileUploadLoader, setFileUploadLoader] = useState(false)
    const handleClose = () => {
        modalAction(false)
        setVehicleFiles([])
    };

   const importMultifiles = (e) =>{
        let files = []
        for(var i=0; i < e.target.files.length; ++i)
            files.push(e.target.files[i])
        setVehicleFiles(files)
     }
    // const openFolder = () => {
    //   modalAction(false)
    //   mainFolder(true)
    // }

   //  const editClickBtn = (folder_name, folder_id) => {
   //    setFolderName(folder_name)
   //    setModalType(false)
   //    setSelectedFolderId(folder_id)
   //    setFolderCreateModal(true)
   // }

   

   //  const FoldersWidget = () => {
   //    if(folderLoader) {
   //       return (
   //          <div className="folder-spinner-layout">
   //             <CircularProgress />
   //          </div>
   //       )
   //    } else if(folderError) {
   //       return (
   //          <div className="folder-spinner-layout">
   //             <p>{folderStatus}</p>
   //          </div>
   //       )
   //    } else return (
   //       <FolderApp  
   //          data={folderResponse}
   //          updation={true}
   //          updationFunction={updationFunction}
   //          deletion={deletion}
   //          vehicleModalActionController={true}
   //          vehicleModalAction={modalAction}

   //          edit_folder_click={editClickBtn}
   //          selectedFolderId={selectedFolderId}
   //          setSelectedFolderId={setSelectedFolderId}
   //          delete_folder={deleteFolderApiCall}
   //       />
   //    ) 
   // }
   const addMNTCFilestoVehicle = () => {
     setFileUploadLoader(true)
    let form_body = new FormData()
    for(var i=0; i<vehicleFiles.length; ++i)
      form_body.append('file', vehicleFiles[i])
    const success = () =>{
      setFileUploadLoader(false)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'File uploaded successful!',
        showConfirmButton: false,
        timer: 1500
    })
    if(dispatchFn!==null)
      dispatchFn()
      handleClose()
    }


    const failed = (res) =>{
      setFileUploadLoader(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.app_data ? res.app_data : 'Something went wrong!'
    })
    handleClose()
    }
    Api('post-form', urlToAddFile, form_body, success, failed)
  }

   const SingleFile = ({item, k}) => {
    const { name, url } = item
    let fileUrl = HOST_WITHOUT_SLASH + url
    let split_arr = fileUrl.split('.')

    const cropFileName = (str) =>{
        let lastIdx = str ? str.length : null
        if(lastIdx)
            return lastIdx > 15 ? `${str.substr(0,10)}...${str.substr(str.length-3, str.length)}` : str 
        else 
            return 'Untitled'
    }

    
    
    return (
        <Tooltip
            title={name} 
            arrow={true} placement={'top'}
            >
            
            <div 
                className={"intranet-main-file-card mntc-file-mini" }
                onClick={(e)=>{ 
                  e.stopPropagation(); 
                  e.preventDefault(); 
              }}>
                
                <div 
                    className="singleFileDeleteIcon" 
                    onClick={(e)=>{ 
                        // e.preventDefault(); 
                        // e.stopPropagation(); 
                        if(!fileUploadLoader){
                        let newArr = vehicleFiles.filter((_,key) => key!==k)
                        setVehicleFiles(newArr)}
                        
                    }}>
                    <DeleteForever style={{ fontSize:18 }} />
                </div>
                <div onClick={() =>{ window.open(fileUrl, "_blank"); }}  className="main-file-icon">
                    {
                        split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif' ?
                            <img src={fileUrl} alt="ImageError" style={{ objectFit:'cover', width: 100, height: 100 }} />
                        :
                            <DescriptionRounded style={{ fontSize: 40 }} />
                    }
                </div>
                <div className="main-file-title">
                    <div style={{ display: 'block' }}>
                        <p style={{ height: 17, width: 192, overflow: 'hidden' }}>{cropFileName(name)}</p>
                        
                    </div>
                </div>
            </div>
        </Tooltip>
    )
}

  
  return (
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={ tempModal ? modalStatus : tempModal}
      onClose={handleClose}
      closeAfterTransition
      >
        <div className="Model-fleet-layout">
          
          <Scrollview style={{ height: '60vh' }}>
             {/* <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 20px' }}>
               <div 
                     style={{ width: 80, height: 40 }}
                     onClick={() => {
                           // setModalType(true)
                           setFolderName('')
                           setModalType(true)
                           setFolderCreateModal(true)
                     }} 
                     className="add-folder-btn">
                     <AddCircleOutlineIcon style={{ marginRight: 7 }}/>
                     <p>Folder</p>
                  </div>
             </div> */}
             {  selectedFolderId===null && spl!==null ? 
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 20px' }}>
                  { vehicleFiles && vehicleFiles.length!==0 ?
                    <div 
                      className="add-folder-btn"
                      style={{ height: 40, display:'flex', alignItems:"center", justifyContent:"space-between" }}
                      onClick={() => {
                        if(!fileUploadLoader)
                          addMNTCFilestoVehicle()
                      }} >
                      { fileUploadLoader ?
                      <CircularProgress size={15} style={{ marginRight: 7 }} /> :
                      <AddCircleOutlineOutlined style={{ marginRight: 7 }}/>
                      }
                      <p>Save Files</p>
                   </div> : null
                   }
              </div>
             :
             <IntranetLayout
             APIURL={url===null ? `${VEHICLE_FILESFOLDERS_GET}${vehicleType}/${vehicle_id}/` : url} title={`Vehicle ${vehicle_reg}`}
             SELECTID={vehicle_id}
             FILEEDIT     = { perms.vehicle.edit   ? INTRANET_FILES_RENAME   : null}
             FOLDEREDIT   = { spl===null ? perms.vehicle.edit   ? INTRANET_FOLDER_EDIT    : null : null} 
             FILEADD      = { perms.vehicle.add    ? selectedFolderId===null ? spl!==null ? urlToAddFile : OHS_AND_S_FILE_ADD : OHS_AND_S_FILE_ADD  : null} 
             FOLDERCREATE = { spl===null ? perms.vehicle.add    ? VEHICLE_FOLDER_CREATE   : null : null} 
             FOLDERDELETE = { spl===null ? perms.vehicle.delete ? OHS_AND_S_FOLDER_DELETE : null : null}
             FILEDELETE   = { perms.vehicle.delete ? OHS_AND_S_FILE_DELETE   : null}
             CALLBACKFUNCMODAL={setTempModal}
             tabType={tabType}
             subTabType={vehicleType}
             disableRoot={spl===null ? false : true} disableSearch={spl===null ? false : true}
             VMNTCFID={selectedFolderId}
             filesOnly={spl===null ? false : true}
             />
             }  
             {
               selectedFolderId===null && spl!==null ?
               <div 
                className={`addFileInitial ${vehicleFiles && vehicleFiles.length!==0 ? '' : 'addFileNormal'}`}
               >
                 
               {
                  vehicleFiles && vehicleFiles.length!==0 ?
                    <div className='mntnce-fileList-grid' style={{ maxHeight:'100%' }}>{
                       vehicleFiles && vehicleFiles.map((item, k) => {
                           return(
                               <SingleFile item={{name : item.name, url : URL.createObjectURL(item)}} k={k} />
                           )
                       })}
                    </div>
                       : 
                      <div className='mntnce-fileList-grid' style={{ maxHeight:'100%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <p style={{ fontSize:'1rem' }}>No Files in Vehicle {vehicle_reg}</p>
                      </div>
               }
                <div className="box-two-add-items add-file-mntnce">  
                    <label htmlFor="file-input">
                    <input type='file'  id="file-input"  multiple={true}
                     onChange={(e) => {
                        importMultifiles(e)
                      }} />
                            <button 
                                className='regi-vehc-btn'>Add Files</button>       
                            </label>            
                        </div>
                </div>
               
               : null
             }
            {/* <FoldersWidget /> */}
          </Scrollview>
        </div>
      </Modal>

      
  );
}