import React, {useEffect, useState} from 'react'
import { Link} from 'react-router-dom'
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './components/styles.css'
import { cl_blue } from '../../common/Colors'
import {connect} from 'react-redux'
import { fetch_home_get } from './action'
import { HOST_WITHOUT_SLASH } from '../../store/endpoint';
import { IconButton } from '@material-ui/core';
import WeatherBox from './components/weather'
// import NotificationModalComponent from './components/notification_modal'
import {SafetyContentBody, SafetyNavHead} from '../ohs/components/safey_data'
import {get_safety} from '../ohs/common/action'
import { Tooltip } from '@material-ui/core'
// import CreateModal from '../ohs/components/create_notify_news'
import NoDatasScreen from '../../common/no_datas';
import NotificationBox from './components/NotificationBox';
import { roundNumTo2 } from '../../store/config';

const WasteApp = ({ fetch_home_fx, jobList, scheduleOfTheDay, notificationList, graph_peak, graphData, weather, safetyData, fetch_safety_data, permissions, isLoading }) => {
    let screen_type = 'pumps'
    useEffect(()=>{
        let d = new Date()
        fetch_home_fx(screen_type, d.getFullYear(),1)
                fetch_safety_data()
        return () => fetch_home_fx.remove
    }, [fetch_home_fx, screen_type, fetch_safety_data])

    // const refreshData = () =>{
    //     fetch_home_fx(screen_type, 2021,1)
    //     fetch_safety_data()
    // }


    // const NotificationBox = ({ screen, apiCall }) => {
    //     let limitNotification = 8;
    //     let balanceNotification = 0;
    //     if(notificationList && notificationList.length < limitNotification) {
    //         balanceNotification = limitNotification - notificationList.length
    //     }

    //     const [NotificationModal, setNotificationModal] = useState(false)
    //     const [createModal, setCreateModal] = useState(false)

    //     return (
    //         <div className="home-box-component">
    //             <div className="home-box-header">
    //                 <p>Notifications</p>
    //                 {   permissions && permissions.ohs.add ?
    //                 <div style={{ display:'flex', width:'70%', justifyContent:'flex-end' }}>
    //                     <button className="ohs-pagi-add-btn" style={{ padding:'.2rem', justifySelf:'flex-end' }} 
    //                         onClick={()=> { setCreateModal(true); }} >Add New</button>
    //                     {/* <button className="ohs-pagi-add-btn" style={{ padding:'.2rem', justifySelf:'flex-end' }}>Archive</button> */}
    //                 </div> : null}
    //                 {/* <Link style={{ textDecoration: 'none' }} to="/ohs">
    //                     <p style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
    //                 </Link> */}
    //                 <p onClick={() => setNotificationModal(true)} style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
    //             </div>

    //             <table>
    //                 {   notificationList && notificationList.length!==0 ?
    //                 <tbody>
    //                 <tr>
    //                                 <td className="home-table-head">Code</td>
    //                                 <td className="home-table-head">Title</td>
    //                                 <td className="home-table-head">Description</td>
    //                                 <td className="home-table-head">action</td>
    //                             </tr>
    //                     {
    //                     notificationList && notificationList.map((item, key) => {
    //                             return (
    //                                 <tr key={key} style={{ backgroundColor: key%2 ? 'rgb(240, 241, 242)' : 'white' }}>
    //                                     <td>{item.id}</td>
    //                                     <td>{item.title}</td>
    //                                     <td>{item.description}</td>
    //                                     <td>
    //                                     <Link style={{ textDecoration: 'none' }} to="/ohs">
    //                                         <button className={!item.user_read_status ? "ohs-table-action-btn" : "ohs-table-action-btn-read"}>View</button>
    //                                     </Link>
    //                                     </td>
    //                                 </tr>
    //                             )
    //                         })
    //                     }
    //                     {
    //                        notificationList && notificationList.length<6 ?
    //                         [...Array(balanceNotification)].map((_,k) => {
    //                             return(
    //                                 <tr key={k}>
    //                                     <td></td><td></td><td></td><td></td>
    //                                 </tr>
    //                             )
    //                         })
    //                         :
    //                         null
    //                     }
    //                 </tbody>
    //                 :
    //                 <NoDatasScreen />
    //                 }
    //             </table>

    //             <NotificationModalComponent 
    //                 modalStatus={NotificationModal}
    //                 modalAction={setNotificationModal}
    //                 fetchData={refreshData}
    //             />
    //             <CreateModal
    //             modalStatus = {createModal}
    //             modalAction = {setCreateModal}
    //             createType  = {"Add Notification"}
    //             setModalType = {null}
    //             dataObj     =   {null}
    //             pageNo      =   {1}
    //             fnCallBk    =   {()=>{fetch_home_fx(screen_type, 2021,1)}}
    //         />
    //         </div> 
    //     )
    // }


    const ListOfJobs = ({ screen }) => {
        let limitJobList = 8;
        let balanceJobList = 0;
        if(jobList && jobList.length < limitJobList) {
            balanceJobList = limitJobList - jobList.length
        }
        return (
            <div className="home-box-component">
                <div className="home-box-header">
                    <p>List of all Jobs</p>
                    <Link style={{ textDecoration: 'none' }} to={`/sales/${screen}`}>
                        <p style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
                    </Link>
                </div>

                <table>
                    {
                        isLoading ? (
                                <tbody>
                                    <tr>
                                        <td className="home-table-head">Code</td>
                                        <td className="home-table-head">Title</td>
                                        <td className="home-table-head">Description</td>
                                        <td className="home-table-head">Status</td>
                                    </tr>
                                    {
                                        [...Array(5)].map((_,k)=>{
                                            return(
                                                <tr>
                                                    <td  className='skeltonLoaderTable'></td>
                                                    <td  className='skeltonLoaderTable'></td>
                                                    <td  className='skeltonLoaderTable'></td>
                                                    <td  className='skeltonLoaderTable'></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                        )
                            :
                        jobList && jobList.length !==0 ?
                    <tbody>
                        <tr>
                            <td className="home-table-head">Code</td>
                            <td className="home-table-head">Title</td>
                            <td className="home-table-head">Description</td>
                            <td className="home-table-head">Status</td>
                        </tr>
                        {
                        jobList && jobList.map((item, key) => {
                                return(
                                    key<=5 ?
                                
                                    <tr key={key} style={{ backgroundColor: key%2 ? 'rgb(240, 241, 242)' : 'white' }}>
                                        <td>{item.code}</td>
                                        <td>{item.job_type}</td>
                                        <td>{item.client_name}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                :
                                null
                                )
                            })
                        }
                        {
                           jobList && jobList.length<6 ?
                            [...Array(balanceJobList)].map((_,k) => {
                                return(
                                    <tr key={k}>
                                        <td></td><td></td><td></td><td></td>
                                    </tr>
                                )
                            })
                            :
                            null
                        }
                    </tbody>
                    :
                    <NoDatasScreen />
                    }
                </table>

            </div> 
        )
    }


    const SalesLayoutBox = () => {

        let graph_data_val = []
        let i;

        if(graphData && graphData.length === 12) {
            for(i=0; i<7; i++) {
                let month_data = graphData[i]
                graph_data_val.push(month_data)
            }
        }
        
        const [graphDataValue, setgraphDataValue] = useState(graph_data_val)
        // const [attachHover, setAttachHover] = useState(null)

        const nextBtn = () => {
            let tempData = []
            for(i=7; i<12; i++) {
                let month_data = graphData[i]
                tempData.push(month_data)
            }
            setgraphDataValue(tempData)
        }

        const prevBtn = () => {
            let tempData = []
            for(i=0; i<7; i++) {
                let month_data = graphData[i]
                tempData.push(month_data)
            }
            setgraphDataValue(tempData)
        }

        return (
            <div className="home-box-component">
                <div className="home-box-header">
                    <p>Sales</p>
                    <Link style={{ textDecoration: 'none' }} to="/sales">
                        <p style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
                    </Link>
                </div>

                <div className="sales-graph-main-indicator">
                    <div className="sales-graph-indicatior-layout">
                        {/* <div className="sales-graph-indicatior">
                            <div style={{ height: 16, width: 16, backgroundColor: 'orange', marginRight: 5 }} />
                            <p>Target Sales</p>
                        </div> */}
                        <div className="sales-graph-indicatior">
                            <div style={{ height: 16, width: 16, backgroundColor: cl_blue, marginRight: 5 }} />
                            <p>Actual Sales</p>
                        </div>
                    </div>
                    <div className="sales-graph-indicatior-actions">
                        <IconButton style={{ padding: 5, borderRadius: 5 }} onClick={() => prevBtn()}>
                            <ChevronLeftIcon />
                            <p style={{ marginRight: 20, fontSize: 12 }}>PREV</p>
                        </IconButton>
                        
                        <IconButton style={{ padding: 5, borderRadius: 5 }} onClick={() => nextBtn()}>
                            <p style={{ fontSize: 12 }}>NEXT</p>
                            <ChevronRightIcon />
                        </IconButton>
                        
                    </div>

                </div>

                <div className="home-box-sales-graph-main">
                    <div className="home-graph-index">
                        <div className="home-graph-index-body">
                            <p style={{ marginRight: 10, fontSize: 11 }}>{roundNumTo2(graph_peak/2)}$</p>
                        </div>
                        <div className="home-graph-index-base">

                        </div>
                    </div>

                    {
                        graphDataValue.map((item, key) => {
                            const { month, sale, 
                                //target 
                            } = item
                            let sale_height = (sale / graph_peak) * 100
                            // let target_height = (target / graph_peak ) * 100

                            if(sale_height === 0) {
                                sale_height = 3
                            }

                            return (
                                <div key={key} className="home-graph-bars-main">
                                    <div className="home-graph-bar-body">
                                    {/* <Tooltip 
                                    //open={attachHover===`${key}TARGET`} 
                                                    title={roundNumTo2(target)} arrow={true} placement={'top'}>
            
                                                    <div style={{ height: `${target_height}%` }} className="home-graph-bar-line"
                                                    // onMouseEnter={() => { setAttachHover(`${key}TARGET`)}}
                                                    // onMouseLeave={() => { setAttachHover(null)}}
                                                    
                                                    >
                                                        {
                                                            target_height > 25 && (
                                                                <p className="home-graph-vertical-text">{target}</p>
                                                            )
                                                        }
                                                    
                                                    </div>
                                                </Tooltip> */}
                                                <Tooltip 
                                                //open={attachHover===`${key}SALES`} 
                                                    title={roundNumTo2(sale)} arrow={true} placement={'top'}>
                                                    <div style={{ height: `${sale_height}%` }} className="home-graph-bar-lineB"
                                                    // onMouseEnter={() => { setAttachHover(`${key}SALES`)}}
                                                    // onMouseLeave={() => { setAttachHover(null)}}
                                                    >
                                                        {
                                                            sale_height > 25 && (
                                                                <p className="home-graph-vertical-text">{roundNumTo2(sale)}</p>
                                                            )
                                                        }
                                                    </div>
                                                </Tooltip>
                                                
                                    </div>
                                    <div className="home-graph-bar-base">
                                        <p style={{ textTransform: 'capitalize' }}>{month}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                

            </div> 
        )
    }


    const SafetyDataBox = () => {
        return (
            <div className="home-box-component">
                <div className="home-box-header">
                    <p>Safety Data</p>
                    <Link style={{ textDecoration: 'none' }} to="/ohs">
                        <p style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
                    </Link>
                    
                </div>
                <SafetyNavHead/>
                <SafetyContentBody safetyData={safetyData}/>
                

            </div> 
        )
    }

    const ScheduleBox = () => {
        const ScheduleComponent = ({type, data}) => {
            let color_green = 'rgb(97, 214, 97)';
            let color_yellow = 'rgba(224, 241, 70, 0.877)'
            let color_grey = 'rgba(163, 190, 163, 0.877)'

            if(type==='vecant') {
                return (
                    <div 
                        style={{ backgroundColor: type=== 'vecant'? color_grey : type === 'completed' ? color_green : color_yellow }} 
                        className="home-schedule-single-comp-vec"
                    >
                            <Link  className="home-schedule-vecant" style={{ textDecoration: 'none' }} to="/scheduling/pump">
                                <AddBoxIcon />
                            </Link>
                     
                        <div>
                            <p style={{ fontWeight: 'bold' }}>Vecant</p>
                        </div>
                    </div>
                )
            } else {
                const { dp, start_time, end_time, name, status} = data
                let img_url = HOST_WITHOUT_SLASH + dp
                return (
                    <Link style={{ textDecoration: 'none', color: 'black' }} to="/scheduling/pump">
                        <div 
                            style={{ backgroundColor: type=== 'vecant'? color_grey : type === 'completed' ? color_green : color_yellow }} 
                            className="home-schedule-single-comp"
                        >
                            <div className="home-schedule-single-comp-img">
                                <img src={img_url} alt=""/>
                            </div>
                            
                            <div>
                                <p>{start_time} - {end_time}</p>
                                <p style={{ textTransform: 'capitalize' }} >{
                                    status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                                    :   status === 'job_started'    ? 'Job Started'
                                    :   status === 'job_finished'   ?   'Job Finished'
                                    :   status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                                    :   status === 'departed_waste_depot' ? 'Departed Waste Depot'
                                    :   status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                                    :   status
                                }</p>
                                <p style={{ fontWeight: 'bold' }}>{name}</p>
                            </div>
                        </div>
                    </Link>
                   
                )
            }
        }

        return (
            <div className="home-box-component">
                <div className="home-box-header">
                    <p>Schedule for the day</p>
                    <Link style={{ textDecoration: 'none' }} to="/scheduling/waste">
                        <p style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p>
                    </Link>
                </div>

                {
                    scheduleOfTheDay ?
                    (
                        <div className="home-box-schedule-main">
                            <div className="home-box-schedule-row">
                                {
                                    scheduleOfTheDay.length > 0 && scheduleOfTheDay.map((item,key)=>{
                                        
                                        return(
                                            key<4 ?
                                            <ScheduleComponent type="" key={key} data={item} />
                                            :
                                            null
                                        )
                                    })
                                }

                                {
                                    scheduleOfTheDay.length < 4 &&  [...Array(4 - scheduleOfTheDay.length)].map((item, key) => {
                                        return <ScheduleComponent type="vecant" key={key} data={item} />
                                    })
                                }
                                
                            </div>
                        </div>
                    ) : (<NoDatasScreen />)
                }

            </div> 
        )
    }


    return (
        <div className="home-main-layout">
            <div className="home-box-layout">
                <NotificationBox screen={screen_type} />
                <SalesLayoutBox />
                <SafetyDataBox />
            </div>
            <div className="home-box-layout">
                <ListOfJobs screen={screen_type} />
                <ScheduleBox />
                <WeatherBox />
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const { jobList, scheduleOfTheDay, notificationList, graphData, graph_peak, weather, isLoading } = state.homeGetData
    const { safetyData } = state.ohs
    const { permissions } = state.userLogin

    return { jobList, scheduleOfTheDay, notificationList, graphData, graph_peak, weather, safetyData, permissions, isLoading }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_home_fx: (type, yr, page_no) => dispatch(fetch_home_get(type, yr, page_no)),
        fetch_safety_data: () => dispatch(get_safety())
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (WasteApp)
