import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {get_safety} from '../common/action'
import EditSafetyData from './edit_safety_data'


export function SafetyNavHead (){
    return(
        <div className="ohs-safety-top-btns" style={{ fontSize: '14px' }}>
            <div className="ohs-safety-top-btns-tabActive"  >MTD</div>
            <div className="ohs-safety-top-btns-tabActive">YTD</div>
        </div>
    )
}

export function SafetyContentBody ({safetyData}){
    return(
        <div className="ohs-saftey-body-layout">
        <div className="ohs-safety-tab">
            <div className="ohs-safety-tabHead">People</div>
            {   safetyData ?
            <div className="ohs-safety-tabContent">
                <div className="ohs-safety-tabRow">
                    <div className="ohs-safety-left">LTI</div>
                        <div className="ohs-safetyData-contentCols">
                            <div className="ohs-safety-right">{safetyData.lti_mtd ? safetyData.lti_mtd : "no Data"}</div> 
                               
                            <div  className="ohs-safety-right">{safetyData.lti_ytd ? safetyData.lti_ytd : "no Data"}</div>
                        </div>
                                                
                </div>
                <div className="ohs-safety-tabRow">
                    <div className="ohs-safety-left">MTD</div>
                    <div className="ohs-safetyData-contentCols">
                            <div className="ohs-safety-right">{safetyData.mti_mtd ? safetyData.mti_mtd : "no Data"}</div> 
                            
                            <div className="ohs-safety-right">{safetyData.mti_ytd ? safetyData.mti_ytd : "no Data"}</div> 
                    </div>
                </div>
                <div className="ohs-safety-tabRow">
                    <div className="ohs-safety-left">FTI</div>
                    <div className="ohs-safetyData-contentCols">
                            <div className="ohs-safety-right">{safetyData.fti_mtd ? safetyData.fti_mtd : "no Data"}</div>
                               
                            <div className="ohs-safety-right">{safetyData.fti_ytd ? safetyData.fti_ytd : "no Data"}</div>
                    </div>
                </div>
            </div>
            :   null
            }
        </div>
        <div className="ohs-safety-tab">
            <div className="ohs-safety-tabHead">Vehicle</div>
            {   safetyData ?
            <div className="ohs-safety-tabContent">
                <div className="ohs-safety-tabRow">
                    <div className="ohs-safety-left">At Fault</div>
                    <div className="ohs-safetyData-contentCols">
                            <div className="ohs-safety-right">{safetyData.atFault_mtd ? safetyData.atFault_mtd : "no Data" }</div>
                           
                            <div className="ohs-safety-right">{safetyData.atFault_ytd ? safetyData.atFault_ytd : "no Data" }</div>
                    </div>
                </div>
                <div className="ohs-safety-tabRow">
                    <div className="ohs-safety-left">Not a Fault</div>
                    <div className="ohs-safetyData-contentCols">
                            <div className="ohs-safety-right">{safetyData.notFault_mtd ? safetyData.notFault_mtd : "no Data"}</div>
                           
                            <div className="ohs-safety-right">{safetyData.notFault_ytd ? safetyData.notFault_ytd : "no Data"}</div>
                    </div>
                </div>
            </div>
            :   null
            }
        </div>
    </div>
    
    )
}



function SafetyData({ safetyData, fetch_data, permissions }) {

    const [createModal, setCreateModal] = useState(false)
    const [modalType, setModalType]     = useState("")

    useEffect(() => {
        fetch_data()
        
    }, [fetch_data]);
    
    const update_safetyData = (v) =>{
        setCreateModal(true); 
        setModalType("Edit Safety Data");
    }

    
    return (
        <div className="ohs-box-layout">
            <div className="ohs-box-header">
                <p>Safety Data</p>
                <div className="ohs-pagination-layout">
                    {   permissions.ohs.edit ?
                    <button className="ohs-pagi-add-btn" style={{ height:'18px' }}  onClick={()=> update_safetyData()} >
                        Update
                    </button>   :   null
                    }
                        {/* <div  className="ohs-pagi-a">
                            <ArrowBack style={{ fontSize: 16 }} />
                        </div>
                        <div className="shdl-pagi-m-b">
                            <p>{1}</p>
                        </div>
                        <div className="ohs-pagi-a">
                            <ArrowForward style={{ fontSize: 16 }} />
                        </div> */}
                </div>
            </div>
            
            <SafetyNavHead />
            <SafetyContentBody safetyData={safetyData}/>
            <EditSafetyData
            modalStatus = {createModal}
            modalAction = {setCreateModal}
            createType  = {modalType}
            setModalType = {setModalType}
            />
        </div>
    )
}

const mapStateToProps = (state) =>{
    const { safetyData } = state.ohs
    const { permissions } = state.userLogin
    return { safetyData, permissions }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetch_data : () => dispatch( get_safety() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SafetyData)
