import React, { useState, useEffect } from 'react'
import { ArrowForwardIos, Person,
    // LocalPrintshopRounded
 } from '@material-ui/icons'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Scrollbar from '../../../../common/ImprovedScrollBar'
import Header from '../common/Header'
import Sidebar from '../../../../common/sidebar'
import GoogleMapLayout from '../editStack/GoogleMap/ShortMap'
import MapModal from '../editStack/MapModal'
import { edit_client, getTypeOfWasteDataList,
    //takeAllPrint
 } from '../../action'
import Swal from 'sweetalert2'
import DateTimeInput from '../../../../common/DateTimeInput'
import { HOME_LATITUDE, HOME_LONGITUDE, LETTERS_VALIDATION } from '../../../../store/config'
import Typeofwaste from './typeOfWaste'
import { SITE_INDUSTRY_LIST, SITE_TYPE_OF_WASTE_LIST, TEAM_SALESPERSON_GET } from '../../../../store/endpoint';
import Api from '../../../../store/api'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Failerrdevmsg from '../../../../common/failErrDevMsg'
// import moment from 'moment'
import { GoogleMapInit, SearchGoogleAddress } from '../editStack/GoogleMap/GoogleMap'
import { TextAreaLimiter } from '../../../../common/TextAreaLimiter'


function App({ selected_client, edit_client_fx, tabType, permissions, getTypeWasteData }) {

    let route_history = useHistory();

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        maxHeight: 20,
        marginLeft: 10,
        marginTop: -8,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resize: {
        fontSize: 14,
        fontWeight: 600,
        color: 'white'
    }
 }));
 
    
    let client_name = ''
    let client_email = ''
    let client_latitude = ''
    let client_longitude = ''
    let client_image = ''
 
    // let client_capacity = ''
    // let client_waste = ''

    // let client_barcode = ''
    // // let client_next_service = ''

    let site_address = ''
    let site_post_code = ''
    let site_phone = ''
    let site_contact_person = ''
    let site_contact_mob = ''

    let client_company_name = ''
    let client_company_contact_number = ''
    let client_company_contact_mob = ''
    let client_company_contact = ''
    let client_company_email = ''
    let client_company_postcode = ''
    let client_company_address = ''
    let client_sales_person = ''

    // let invoice_name = ''
    // let invoice_address = ''
    // let invoice_phone = ''
    // let invoice_email = ''
    let invoice_terms_of_account = ''
    let invoice_account_status = ''
    let invoice_reason_for_cancelling = ''
    let invoice_purchase_no = ''
    let induction_req = ''
    let invoice_price = ''
    let industry_type = ''
    // // let last_service = ''
    // let job_status = ''
    // let job_duration = ''
    // let alarm_code = ''
    // let weigh_bridge_req = ''
    // let pallets = ''
    // let quantity = ''
        
    let client_payment_type = ''
    let client_induction_type = ''
    let client_abn = ''
    // let client_key_required_type = ''
    // // let client_frequency = ''

    // let additional_information = ''
    // let pit_location = ''
    // let access_restriction = ''

    if (selected_client.id) {
        client_name              = selected_client.client_name
        client_email             = selected_client.client_email
        client_latitude          = selected_client.location_latitude
        client_longitude         = selected_client.location_logitude
        client_image             = selected_client.dp
        industry_type            = selected_client.industry_type
        // client_capacity          = selected_client.device_capacity
        // client_waste             = selected_client.waste_type_str
    
        // if(tabType!=='pumps'){
        //     client_barcode               = selected_client.barcode
        //     weigh_bridge_req             = selected_client.weigh_bridge_required
        //     pallets                      = selected_client.pellets_to_be_exchanged_str
        //     quantity                     = selected_client.quantity
        
        // }
        // client_next_service      = selected_client.next_service_2
        if(tabType==='pumps')
            client_company_contact = selected_client.company_contact_name
        site_address             = selected_client.site_address
        site_post_code           = selected_client.site_post_code
        site_phone               = selected_client.site_phone_no
        site_contact_person      = selected_client.site_contact_person
        site_contact_mob         = selected_client.site_contact_mob
    
        client_company_name      = selected_client.company_name
        client_company_contact_number    = selected_client.company_contact_number
        client_company_contact_mob    = selected_client.company_contact_mob
        client_company_email             = selected_client.company_email
        client_company_postcode          = selected_client.company_postcode
        client_company_address           = selected_client.company_address
        client_sales_person              = selected_client.sales_person
    
        // invoice_name                     = selected_client.invoice_name
        // invoice_address                  = selected_client.invoice_address
        // invoice_phone                    = selected_client.invoice_phone
        // invoice_email                    = selected_client.invoice_email
        invoice_terms_of_account         = selected_client.invoice_terms_of_account
        invoice_account_status           = selected_client.invoice_account_status
        invoice_reason_for_cancelling    = selected_client.Reason_for_cancelling
        invoice_purchase_no              = selected_client.invoice_purchase_no
        induction_req                    = selected_client.induction_required_str
        invoice_price                    = selected_client.price
    
        // // last_service                     = selected_client.last_service_1
        // job_status                       = selected_client.job_status
        // job_duration                     = selected_client.job_duration
        // alarm_code                       = selected_client.alarm_code
            
        client_payment_type              = selected_client.payment_type_str
        client_induction_type            = selected_client.induction_type
        client_abn                       = selected_client.abn
        // client_key_required_type         = selected_client.key_required_type_str
        // // client_frequency                 = selected_client.frequency_weeks

        // additional_information           = selected_client.information
        // pit_location                     = selected_client.pit_location
        // access_restriction               = selected_client.access_restriction
    }

    // console.log('all client data **************', selected_client)

    if (selected_client.dp) {
        client_image = selected_client.dp
    }


    const [CustomerName, setCustomerName]        = useState(client_name)
    const [ClientEmail, setClientEmail]          = useState(client_email)
    const [SiteAddress, setSiteAddress]          = useState(site_address)
    const [SitePostcode, setSitePostcode]        = useState(site_post_code)
    const [SiteContact, setSiteContact]          = useState(site_contact_person)
    const [SitePhone, setSitePhone]              = useState(site_phone)
    const [SiteMobile, setSiteMobile]            = useState(site_contact_mob)
    const [InductionReq, setInductionReq]        = useState(induction_req)
    const [InductionType, setInductionType]      = useState(client_induction_type)

    const [CompanyName, setCompanyName]          = useState(client_company_name)
    const [CompanyAddress, setCompanyAddress]    = useState(client_company_address)
    const [SalesPerson, setSalesPerson]          = useState(!LETTERS_VALIDATION.test(client_sales_person) && selected_client.sales_person_name
     ? selected_client.sales_person_name : client_sales_person)
    const [CompanyNumber, setCompanyNumber]      = useState(client_company_contact_number)  
    const [CompanyMob, setCompanyMob]      = useState(client_company_contact_mob)    
    const [CompanyEmail, setCompanyEmail]        = useState(client_company_email)
    const [CompanyPostcode, setCompanyPostcode]  = useState(client_company_postcode)
    const [CompanyContact, setCompanyContact]    = useState(client_company_contact)
    const [industryTypeList, setIndustryTypeList] = useState([])
    const [IndustryType, setIndustryType]        = useState(industry_type)
    // const [AdditionalInfo, setAdditionalInfo]    = useState(additional_information)
    // const [CompanyPitLoc, setCompanyPitLoc]      = useState(pit_location)
    // const [CompanyAccess, setCompanyAccess]      = useState(access_restriction)


    // const [InvoiceName, setInvoiceName]          = useState(invoice_name)
    // const [InvoiceAddress, setInvoiceAddress]    = useState(invoice_address)
    // const [InvoiceEmail, setInvoiceEmail]        = useState(invoice_email)
    const [InvoiceAcc, setInvoiceAcc]            = useState(invoice_terms_of_account)
    const [InvoiceAccStatus, setInvoiceAccStatus]= useState(invoice_account_status)
    // const [InvoicePhone, setInvoicePhone]        = useState(invoice_phone)
    const [InvoiceReason, setInvoiceReason]      = useState(invoice_reason_for_cancelling)
    const [PaymentType, setPaymentType]          = useState(client_payment_type)
    const [PurchaseOrderNo, setPurchaseOrderNo]  = useState(invoice_purchase_no)
    const [AbnNumber, setAbnNumber]              = useState(client_abn)
   // // const [LastService, setLastService]          = useState(last_service)
    // const [KeyRequiredType, setKeyRequiredType]  = useState(client_key_required_type)
   // // const [Frequency, setFrequency]              = useState(client_frequency)
    // const [TypeofWaste, setTypeofWaste]          = useState(client_waste)
    // const [Capacity, setCapacity]                = useState(client_capacity)
    const [WastePrice, setWastePrice]            = useState(invoice_price)
    // const [BarCode, setBarCode]                  = useState(client_barcode)
    // // const [NextService, setNextService]          = useState(client_next_service)
    // const [JobDuration, setJobDuration]          = useState(job_duration)
    // const [JobStatus, setJobStatus]              = useState(job_status)
    // const [AlarmCode, setAlarmCode]              = useState(alarm_code)
    // const [WeighBridgeReq, setWeighBridgeReq]    = useState(weigh_bridge_req)
    // const [PalletsToBeReq, setPalletsToBeReq]    = useState(pallets)
    // const [Quantity, setQuantity]                = useState(quantity)
    const [profileImage, setprofileImage]        = useState('')
    const [profileImageTemp, setprofileImageTemp]    = useState(client_image)
    

    const [modalStatus, setModalStatus] = useState(false)
    const [markers, setMarkers] = useState([{lat: parseFloat(client_latitude), lng: parseFloat(client_longitude) }]);
    

    const [dataObj, setDataObj]                  = useState(
        {
           'id'                :   '', 
           'waste_type_str'    :   '',
           'type_of_waste_str_profile_name' : '',
           'device_capacity'   :   '',
           'barcode'           :   '',
           'job_duration'      :   '',
           'key_required_type_str' : 'FALSE',
           'job_status'        :   '',
           'alarm_code'        :   '',
           'weigh_bridge_required' :   '',
           'pellets_to_be_exchanged_str' : 'FALSE',
           'quantity'          :   '',
           'information'       :   '',
           'pit_location'      :   '',
           'access_restriction':   ''
       } 
    )
    const [dataObjArr, setDataObjArr] = useState(
       [{
           'id'                :   '',
           'waste_type_str'    :   '',
           'type_of_waste_str_profile_name' : '',
           'device_capacity'   :   '',
           'barcode'           :   '',
           'job_duration'      :   '',
           'key_required_type_str' : 'FALSE',
           'job_status'        :   '',
           'alarm_code'        :   '',
           'weigh_bridge_required' :   '',
           'pellets_to_be_exchanged_str' : 'FALSE',
           'quantity'          :   '',
           'information'       :   '',
           'pit_location'      :   '',
           'access_restriction':   ''
       }])
       let mapInit = GoogleMapInit()

    useEffect(() => {
        Api('get',SITE_INDUSTRY_LIST,'').then(response => {
            setIndustryTypeList(response)
        })
        if(tabType==='waste'){
            getTypeWasteData(selected_client.id)
        Api('get',`${SITE_TYPE_OF_WASTE_LIST}details/${selected_client.id}/`,'')
        .then(response => {
            console.log(response)
            if(response && response.length!==0){
                
                setDataObjArr(response)     
                setDataObj(response[0])       
            }
        })
        
        }
        Api('get',`${TEAM_SALESPERSON_GET}`).then(
            res =>{
                console.log(res)
                if(res.length>0 && client_sales_person && client_sales_person!=='' && 
                !LETTERS_VALIDATION.test(client_sales_person)){
                    setSalesPerson(res && res[res.findIndex(el => el.id===parseInt(client_sales_person))] ? 
                        res[res.findIndex(el => el.id===parseInt(client_sales_person))].name
                        :
                        client_sales_person)
                }
                    
            }
        )
        
    }, [getTypeWasteData, selected_client.id, tabType, client_sales_person]);

    

    let buttonStatus = false

    let case1 = false;
    if( CustomerName !== client_name || ClientEmail !== client_email ||  SiteAddress !== site_address || SitePostcode !== site_post_code || SiteMobile !== site_contact_mob ) {
        case1 = true
    }

    

    let case3 = false;
    if(
        //InvoiceName !== invoice_name || InvoiceAddress !== invoice_address || InvoiceEmail !== invoice_email |||| InvoicePhone !== invoice_phone
         WastePrice !== invoice_price || InvoiceAcc !== invoice_terms_of_account || InvoiceAccStatus !== invoice_account_status
     || InvoiceReason !== invoice_reason_for_cancelling){
        case3 = true
    }


    let case4 = false;
    if( CompanyName !== client_company_name || CompanyAddress !== client_company_address || SalesPerson !== client_sales_person || CompanyNumber !== client_company_contact_number || CompanyMob !== client_company_contact_mob || CompanyEmail !== client_company_email || CompanyPostcode !== client_company_postcode || PaymentType !== client_payment_type ) {
        case4 = true
    }


    let case6 = false;
    if(InductionType !== client_induction_type || InductionReq !== induction_req || (tabType==='pumps' && PurchaseOrderNo !== invoice_purchase_no)) {
        case6 = true
    }

    
    let case7 = false;
    if(profileImageTemp !== client_image) {
        case7 = true
    }

    let case8 = false;
    if( markers &&  JSON.stringify(markers) !== JSON.stringify([{lat: parseFloat(client_latitude), lng: parseFloat(client_longitude) }])) {
        if(markers[0].lat !== parseFloat(client_latitude) || markers[0].lng !== parseFloat(client_longitude)) {
            case8 = true
        }
    }
    
    if (case1 || case3 || case4 || case6 || case7 || case8 || (tabType==='pumps' && CompanyContact !== client_company_contact) ) {
        buttonStatus = true
    } 

    


    const edit_client_click = () => {
        var data = new FormData();
        data.append('client_name', CustomerName);
        data.append('client_email', ClientEmail);

        // data.append('device_capacity', Capacity)
        // data.append('waste_type_str', TypeofWaste)

        data.append('site_address', SiteAddress);
        data.append('site_post_code', SitePostcode)
        data.append('site_phone_no',SitePhone)
        data.append('site_contact_person',SiteContact)
        data.append('site_contact_mob',SiteMobile)

        data.append('company_name', CompanyName)
        data.append('company_contact_number', CompanyNumber);
        data.append('company_contact_mob', CompanyMob);
        data.append('company_address', CompanyAddress)
        data.append('company_email', CompanyEmail);
        data.append('company_postcode', CompanyPostcode);
        // data.append('information', AdditionalInfo)
        // data.append('pit_location', CompanyPitLoc)
        // data.append('access_restriction', CompanyAccess)
        data.append('sales_person', SalesPerson)
        if(tabType==='pumps')
                data.append('company_contact_name', CompanyContact)
        // data.append('invoice_name', InvoiceName)
        // data.append('invoice_address', InvoiceAddress)
        // data.append('invoice_phone', InvoicePhone)
        // data.append('invoice_email', InvoiceEmail)
        data.append('invoice_terms_of_account', InvoiceAcc)
        data.append('invoice_account_status', InvoiceAccStatus)
        data.append('Reason_for_cancelling', InvoiceReason)
        data.append('invoice_purchase_no', PurchaseOrderNo)
        data.append('induction_required_str', InductionReq)
        data.append('abn', AbnNumber)
        // data.append('barcode', tabType==='pumps' ? '' : BarCode)
        data.append('price',WastePrice)
        // if(LastService!=='' && LastService!==null)
        //     data.append('last_service',LastService)
        // if(NextService!=='' && NextService!==null)
        //     data.append('next_service_2', moment(NextService).format('YYYY-MM-DD HH:mm:ss'))
        // data.append('job_status', JobStatus)
        // data.append('job_duration', JobDuration)
        // data.append('alarm_code', AlarmCode)
        // data.append('weigh_bridge_required', tabType==='pumps' ? '' :  WeighBridgeReq)
        // data.append('pellets_to_be_exchanged_str', tabType==='pumps' ? '' :  PalletsToBeReq)
        // data.append('quantity', tabType==='pumps' ? '' : Quantity)

        data.append('payment_type_str', PaymentType);
        data.append('induction_type', InductionType);
        data.append('industry_type', typeof IndustryType==='string' ? industryTypeList?.find(el => el.industry_name===InductionType)?.id : IndustryType)
        // data.append('key_required_type_str', KeyRequiredType);
        // data.append('frequency_weeks', Frequency);

        if(profileImageTemp) {
            let splitData = profileImageTemp.split(':')
            if(splitData.length > 0) {
                if(splitData[0] === 'blob') {
                    let file_name = profileImage.name
                    data.append('dp', profileImage, file_name)
                }
            }
        }
        

        if (markers.length > 0 && !(isNaN(markers[0].lng) && isNaN(markers[0].lat))) {
            data.append('location_logitude', markers[0].lng)
            data.append('location_latitude', markers[0].lat)
        }
        else if( isNaN(selected_client.location_latitude) && isNaN(selected_client.location_logitude)){
            data.append('location_logitude', HOME_LONGITUDE)
            data.append('location_latitude', HOME_LATITUDE)
        }
        // console.log(isNaN(selected_client.location_latitude), isNaN(selected_client.location_logitude))
        const success = (res) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Client Edited successful!',
                showConfirmButton: false,
                timer: 1500
            })
            route_history.push('/client')
        }

        const failed = (res) => {
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: res.app_data ? res.app_data : 'Something went wrong!'
            // })
            Failerrdevmsg(res)
            route_history.push('/client')

        }

        edit_client_fx(data, selected_client.id, success, failed)
        // dispatch(fetch_client_list(null,tabType))
    }


//  let selOPts = [{   name : 'AMMONIA', value : 'AMMONIA'},
//  {   name : 'DESTRUCTION CERTIFICATE', value : 'DESTRUCTION CERTIFICATE'},
//  {   name : 'DILUTION PIT', value : 'DILUTION PIT'},
//  {   name : 'DRUMS', value : 'DRUMS'},
//  {   name : 'FOOD WASTE', value : 'FOOD WASTE'},
//  {   name : 'GERNIE WASHES', value : 'GERNIE WASHES'},
//  {   name : 'GREASE', value : 'GREASE'},
//  {   name : 'GROUND WATER', value : 'GROUND WATER'},
//  {   name : 'J120', value : 'J120'}, 
//  {   name : 'LEVY', value : 'LEVY' },
//  {   name : 'M250', value :'M250'},
//  {   name : 'N205', value : 'N205'}, 
//  {   name : 'OIL SEPERATOR', value : 'OIL SEPERATOR'},
//  {   name : 'PACKAGED WASTE', value : 'PACKAGED WASTE'},
//  {   name : 'PRODUCT DESTRUCTION', value : 'PRODUCT DESTRUCTION'},
//  {   name : 'PUMP SERVICE', value : 'PUMP SERVICE'},
//  {   name : 'RETENTION PIT', value : 'RETENTION PIT'},
//  {   name : 'SEWER', value :'SEWER'},
//  {   name : 'STORMWATER', value :'STORMWATER'},
//  {   name : 'TANK WASH OUT', value : 'TANK WASH OUT'},      
//  {   name : 'TRADE WASTE', value : 'TRADE WASTE'},                                              
//  {   name : 'TRANSPORT', value : 'TRANSPORT'},  
//  {   name : 'WIEGH BRIDGE', value : 'WEIGH BRIDGE'},                          
//  {   name : 'Z140', value : 'Z140'},                            
//  { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}]


const SortDropdown = () => {                          
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl}>
            <Select
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                SelectDisplayProps={{ fontSize: 14 }}
                value={dataObj&&dataObj.id}
                className={classes.resize}
                disabled={dataObjArr && dataObjArr.length===0}
                label='Type of Waste'
                onChange={(val) => {
                    setDataObj(dataObjArr.find(el => el.id===parseInt(val.target.value)))
                    if(dataObjArr.find(el => el.id===parseInt(val.target.value))['sales_person'])
                        setSalesPerson(dataObjArr.find(el => el.id===parseInt(val.target.value))['sales_person'])
                }}
            >
             {/* <MenuItem value={'/0'}>Choose by Site Profiles</MenuItem> */}
            {/* <MenuItem value={'all'}>{ dataObjArr && dataObjArr.length!==0 ? '' : '-No Site Profiles Available-'}</MenuItem>  */}
            {
                dataObjArr.map(x =>{ 
                return(
                    <MenuItem value={x.id}>{x.type_of_waste_str_profile_name ? `${x.type_of_waste_str_profile_name}(${x.waste_type_str})` : `${x.waste_type_str}`}</MenuItem>
                 )})
            }

            </Select>
        </FormControl>
    )
} 

const UpdateSiteAddress = (val,addr) =>{
    if(val){
        setSiteAddress(addr)
        let res = val && val.address_components ? val.address_components : null
        console.log('UpdateSiteAddress',res)
        if(res){ 
            res.find(o =>{
                if( o.types.includes('postal_code'))
                    setSitePostcode(o.long_name)
                console.log(o,o.types.includes('postal_code'))
                return 0
            })
        }
    }else{
        setSiteAddress(site_address)
        setSitePostcode(site_post_code)
    }
}




    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='create' />
                <MapModal
                    modalStatus={modalStatus}
                    modalAction={setModalStatus}
                    markers={markers}
                    setMarkers={setMarkers}
                    UpdateSiteAddress={UpdateSiteAddress}
                />
                <div className="client-create-main-layout">
                    <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'flex-start' }} >
                        <div style={{ width:'50%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
                            <p className="client-main-head">Site Details</p>
                        </div>
                        {   tabType==='waste' ?
                        <div style={{ width:'48%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
                            <div className="client-main-head">Site Profiles</div>
                                <div className='siteProfTitle' style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                    <div className={`detail-headings siteProfTitle`}>
                                        <p className="p-0 m-0">Type of Waste
                                        </p>
                                    </div>
            
                                <SortDropdown/>
                                </div>
                            </div>
                         : null
                        }
                    </div>                    
                    <div className="client-crt-container">

                    
                        
                        <div className="client-crt-bottom">

                            <div className="client-crt-btm-lft-main" style={{ flexDirection:'column' }}>

                                

                                <div style={{ display: 'flex', flexDirection:'row', height:'85vh' }}>
                                        <div className="client-ctr-btm-lft" >
                                        <Scrollbar autoHide style={{ display: 'flex', height: '72vh', marginBottom: '1%' }}>
                                            <div 
                                                style={{ display:'flex', 
                                                    flexDirection: tabType!=='waste' ? 'row' : 'column', width: '100%' }}>
                                            <div style={{ display:"flex", flexDirection:'column', width: tabType!=='waste' ? '50%' : '100%'}} >
                                            <div className="client-crt-top">
                            <label htmlFor="file-input">
                                {
                                    profileImageTemp ? (
                                        <div className="client-crt-dp">
                                            <img className="client-crt-dp-img" src={profileImageTemp} alt="" />
                                        </div>
                                    ) : (
                                            <div className="client-crt-dp">
                                                <Person style={{ fontSize: 50 }} />
                                                <p style={{ fontSize: 14, padding: 0, margin: 0 }}>Add Photo</p>
                                            </div>
                                        )
                                }
                            </label>
                            {   permissions.site.edit ?
                            <input id="file-input" style={{ display: 'none' }} type="file" multiple={false}
                                onChange={(image) => {
                                    var image_file = image.target.files[0]
                                    let extension = image_file.type
                                    if (extension === 'image/jpeg' || extension === 'image/png') {
                                        var image_blob = URL.createObjectURL(image_file)
                                        setprofileImageTemp(image_blob)
                                        setprofileImage(image_file)
                                    } else {
                                        alert('File should be an Image. Only jpg and png files are accepted')
                                    }

                                }}
                            />
                            :   null    }

                            {/* <div className="client-crt-dp">
                                <Person style={{ fontSize: 50 }} />
                                <p style={{ fontSize: 14, padding: 0, margin: 0 }}>Add Photo</p>
                            </div> */}
                            <div className="client-crt-map">
                                <GoogleMapLayout
                                    modalAction={setModalStatus}
                                    markers={markers}
                                // values={values} 
                                />
                            </div>
                        </div>

                                            <div style={{ fontSize:16, padding:'0 10px' }}>Company Details</div>

                                            {/* <InputField
                                                title="Site ID"
                                                mandatory={true}
                                                value={ClientId}
                                                onChange={val => setClientId(val.target.value)}
                                            /> */}

                                            <InputField
                                                title="Company Name"
                                                value={CompanyName}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCompanyName(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Company Address"
                                                mandatory={true}
                                                value={CompanyAddress}
                                                permEdit={permissions.site.edit}
                                                inputComponent={
                                                !mapInit.status ? null :
                                                <SearchGoogleAddress 
                                                    cls={{ outerBox : 'searchAddressSite', innerBox : 'client-input'}} 
                                                    inputVal={CompanyAddress}
                                                    inputPlaceHolder={"Company Address"}
                                                    inputFn={(v) => { setCompanyAddress(v.toUpperCase())}}
                                                    moreInfo={(r) => { 
                                                        
                                                        let res = r.results && r.results.address_components ? r.results.address_components : null
                                                        console.log(res)
                                                        if(res){ 
                                                             
                                                            res.find(o =>{
                                                                if( o.types.includes('postal_code'))
                                                                setCompanyPostcode(o.long_name)
                                                                console.log(o,o.types.includes('postal_code'))
                                                                return 0
                                                            })
                                                     } }} 
                                                     center={markers}
                                                />}
                                                onChange={val =>{
                                                    setCompanyAddress(val.target.value.toUpperCase())}}
                                            />

                                        {   tabType === 'pumps' ?
                                            <InputField
                                                title="Company Contact Name"
                                                value={CompanyContact}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCompanyContact(val.target.value.toUpperCase())}
                                            />
                                            :   null    }
                                            <InputField
                                                title="Company Contact Phone"
                                                
                                                mandatory={true}
                                                value={CompanyNumber}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setCompanyNumber('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setCompanyNumber(val.target.value)}}
                                            />
                                            <InputField
                                                title="Company Contact Mob"
                                                
                                                mandatory={true}
                                                value={CompanyMob}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setCompanyMob('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setCompanyMob(val.target.value)}}
                                            />

                                            <InputField
                                                title="Company Email"
                                                mandatory={true}
                                                type={'email'}
                                                value={CompanyEmail}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCompanyEmail(val.target.value)}
                                            />

                                            <InputField
                                                title="Company Postcode"
                                                mandatory={true}
                                                
                                                value={CompanyPostcode}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                        setCompanyPostcode(val.target.value)}}
                                            />

                                            <SelectField
                                                title="Induction Required"
                                                mandatory={true}
                                                options={[
                                                    { name: 'YES', value: 'TRUE' },
                                                    { name: 'NO', value: 'FALSE' },
                                                    { name : 'Select Option', value : '' },
                                                ]}
                                                value={InductionReq}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInductionReq(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Induction Type"
                                                mandatory={true}
                                                value={InductionType}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInductionType(val.target.value.toUpperCase())}
                                            />
                                            <InputField
                                                title="ABN Number"
                                                mandatory={false}
                                                value={AbnNumber}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setAbnNumber(val.target.value.toUpperCase())}
                                            />
                                            </div>
                                            <div style={{ display:'flex', flexDirection:'column', width: tabType!=='waste' ? '50%' : '100%',
                                        borderLeft: tabType!=='waste' ? '1.5px solid var(--cl_ivery)' : 'none'
                                        }}>
                                            <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Site and Invoicing Details</div>

                                            {/* <InputField
                                                title="Name"
                                                value={InvoiceName}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInvoiceName(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Address"
                                                value={InvoiceAddress}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInvoiceAddress(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Phone"
                                                
                                                value={InvoicePhone}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setInvoicePhone('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setInvoicePhone(val.target.value)}}
                                            />

                                            <InputField
                                                title="Email"
                                                value={InvoiceEmail}
                                                type={'email'}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInvoiceEmail(val.target.value)}
                                            /> */}

                                            
                                            <InputField
                                                title="Site Name"
                                                mandatory={true}
                                                value={CustomerName}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCustomerName(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Site Email Address"
                                                mandatory={true}
                                                type={'email'}
                                                value={ClientEmail}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setClientEmail(val.target.value)}
                                            />

                                            <InputField
                                                title="Site Address"
                                                mandatory={true}
                                                value={SiteAddress}
                                                permEdit={permissions.site.edit}
                                                inputComponent={
                                                    !mapInit.status ? null :
                                                    <SearchGoogleAddress 
                                                        cls={{ outerBox : 'searchAddressSite', innerBox : 'client-input'}} 
                                                        inputVal={SiteAddress}
                                                        inputPlaceHolder={"Site Address"}
                                                        inputFn={(v) => { setSiteAddress(v.toUpperCase())}}
                                                        moreInfo={(r) => { 
                                                        
                                                            let res = r.results && r.results.address_components ? r.results.address_components : null
                                                            console.log(res)
                                                            if(res){ 
                                                                 
                                                                res.find(o =>{
                                                                    if( o.types.includes('postal_code'))
                                                                        setSitePostcode(o.long_name)
                                                                    console.log(o,o.types.includes('postal_code'))
                                                                    return 0
                                                                })
                                                         } }}
                                                        center={markers} setMarkers={setMarkers} />}
                                                    
                                                onChange={val => setSiteAddress(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Site Postcode"
                                                mandatory={true}
                                                
                                                value={SitePostcode}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{ 
                                                        setSitePostcode(val.target.value)}}
                                            />
                                            
                                            <InputField
                                                title="Site Contact/Person"
                                                mandatory={true}
                                                value={SiteContact}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setSiteContact(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Site Phone"
                                                mandatory={true}
                                                
                                                value={SitePhone}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setSitePhone('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setSitePhone(val.target.value)
                                                    }}
                                            />

                                            <InputField
                                                title="Site Contact Mobile"
                                                mandatory={true}
                                                
                                                value={SiteMobile}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setSiteMobile('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setSiteMobile(val.target.value)}}
                                            />
                                            
                                            <SelectField
                                                title="Industry Type"
                                                value={IndustryType}
                                                permEdit={permissions.site.edit}
                                                options={
                                                    industryTypeList.map(el =>{
                                                        return { name : el.industry_name, value : el.id }
                                                    })
                                                }
                                                mandatory={true}
                                                onChange={val => setIndustryType(val.target.value)}
                                            />



                                            <SelectField
                                                title="Terms of Account"
                                                value={InvoiceAcc}
                                                options={[
                                                    { name : '7 DAYS', value : '7 DAYS' },
                                                    { name : '14 DAYS', value : '14 DAYS' },
                                                    { name : '30 DAYS', value : '30 DAYS' },
                                                    { name : '60 DAYS', value : '60 DAYS' },
                                                    { name : 'COD', value: 'COD' },
                                                    { name : 'PREPAID', value : 'PREPAID'},
                                                    { name : 'PROFORMA', value : 'PROFORMA'},
                                                    { name : 'Select Option', value : '' },
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInvoiceAcc(val.target.value.toUpperCase())}
                                            />
                                            <InputField
                                                title="Purchase/Work Order No"
                                                value={PurchaseOrderNo}
                                                mandatory={ tabType==='pumps' ? true : false}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setPurchaseOrderNo(val.target.value.toUpperCase())}
                                            /> 
                                            
                                            <SelectField
                                                title="Account Status"
                                                value={InvoiceAccStatus}
                                                options={[
                                                    {name : 'ACTIVE', value : 'ACTIVE'},
                                                    {name : 'ON HOLD', value : 'ON HOLD'},
                                                    {name : 'CANCELLED/UNACTIVE', value : 'CANCELLED/UNACTIVE' },
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                    { name : 'Select Option', value : '' },
                                                ]}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInvoiceAccStatus(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Reasons for Cancelling"
                                                value={InvoiceReason}
                                                options={[
                                                    { name: 'Select Option', value: ''},
                                                    { name: 'MOVED PREMISES', value: 'MOVED PREMISES'},
                                                    { name: 'NEW OWNER', value: 'NEW OWNER'},
                                                    { name: 'POOR SERVICES', value: 'POOR SERVICES' },
                                                    { name: 'SHOP CLOSED DOWN', value: 'SHOP CLOSED DOWN' },
                                                    { name: 'TOO EXPENSIVE', value: 'TOO EXPENSIVE'},
                                                    { name: 'NOT APPLICABLE', value: 'NOT APPLICABLE'}
                                                ]}                                                
                                                permEdit={permissions.site.edit}
                                                onChange={val => setInvoiceReason(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Payment Type"
                                                mandatory={true}
                                                options={[
                                                    { name: 'Select Option', value: ''},
                                                    { name: 'ACCOUNT', value: 'ACCOUNT'},
                                                    { name: 'COD', value: 'COD'},
                                                    { name: 'CREDIT CARD', value: 'CREDIT CARD' },
                                                    { name: 'PRE-PAID', value: 'PRE-PAID'},
                                                    { name: 'NO CHARGE', value: 'NO CHARGE'},
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={PaymentType}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setPaymentType(val.target.value.toUpperCase())}
                                            />

                                            
                                            <InputField
                                                title="Price"
                                                value={WastePrice}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setWastePrice(val.target.value)}
                                            />
                                            {/* <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Company Details</div> */}

                                            
                                            <InputField
                                                title="Sales Person"
                                                mandatory={false}
                                                // options={
                                                //     tabType==='waste' ? 
                                                //     [
                                                //         { name : 'RICHARD FUDGE', value : 'RICHARD FUDGE'},
                                                //         { name : 'MATTHEW JONES', value : 'MATTHEW JONES'},
                                                //         { name : 'MICHEAL YOUNGMAN', value : 'MICHEAL YOUNGMAN'},
                                                //         { name : 'MATTHEW DESIRIA', value : 'MATTHEW DESIRIA'},
                                                //         { name : 'JAKE LAWRIE', value : 'JAKE LAWRIE'},
                                                //         { name : 'JOHN PAUL HAWACH', value : 'JOHN PAUL HAWACH'},
                                                //         { name : 'EDDY HAWACH', value : 'EDDY HAWACH'},
                                                //         { name : 'SIMON SABA', value : 'SIMON SABA'},
                                                //         { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                //         { name : 'Select Option', value : '' },
                                                //     ]
                                                //     :   tabType==='pumps' ?
                                                //     [
                                                //         { name : 'Matt', value : 'Matt'},
                                                //         { name : 'MATTHEW DESIRIA', value : 'MATTHEW DESIRIA'},
                                                //         { name : 'JAKE LAWRIE', value : 'JAKE LAWRIE'},
                                                //         { name : 'JOHN PAUL HAWACH', value : 'JOHN PAUL HAWACH'},
                                                //         { name : 'EDDY HAWACH', value : 'EDDY HAWACH'},
                                                //         { name : 'SIMON SABA', value : 'SIMON SABA'},
                                                //         { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                //         { name : 'Select Option', value : '' },
                                                //     ]
                                                //     :
                                                //     [
                                                //         { name : 'MATTHEW DESIRIA', value : 'MATTHEW DESIRIA'},
                                                //         { name : 'JAKE LAWRIE', value : 'JAKE LAWRIE'},
                                                //         { name : 'JOHN PAUL HAWACH', value : 'JOHN PAUL HAWACH'},
                                                //         { name : 'EDDY HAWACH', value : 'EDDY HAWACH'},
                                                //         { name : 'SIMON SABA', value : 'SIMON SABA'},
                                                //         { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'},
                                                //         { name : 'Select Option', value : '' },
                                                //     ]
                                                // }
                                                
                                                value={SalesPerson}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setSalesPerson(val.target.value.toUpperCase())}
                                            />
                                            </div>
                                            </div>
                                                <div style={{ display:'flex', justifyContent:'center' }} >
                                    {
                                    buttonStatus && permissions.site.edit ? (
                                        <button onClick={() => edit_client_click()} className="client-ctr-btn" 
                                        style={{ height:'50px', fontSize:'1rem' }}>
                                            <p style={{ padding: 0, margin: '0 2%' }} >Update Site Details</p>   <ArrowForwardIos style={{ padding: 0, margin: 0, marginLeft: 10, fontSize:'1rem' }} />
                                        </button>
                                    ) : (
                                        <button className="client-ctr-btn disabled-btn" 
                                        style={{ height:'50px', fontSize:'1rem' }}>
                                            <p style={{ padding: 0, margin: '0 2%' }} >Update Site Details</p>   <ArrowForwardIos style={{ padding: 0, margin: 0, marginLeft: 10, fontSize:'1rem' }} />
                                        </button>
                                    )
                                    }

                                </div>
                                            </Scrollbar>
                                        </div>
                                        {/* <div className="client-ctr-btm-cntr">

                                            <div  style={{ fontSize:16, padding:'0 10px', marginTop:'10px' }}>Waste Service</div>


                                            <SelectField
                                                title="Type of Waste"
                                                mandatory={true}
                                                options={[
                                                    { name: 'Select Option', value: ''},
                                                    {   name : 'AMMONIA', value : 'AMMONIA'},
                                                    {   name : 'DILUTION PIT', value : 'DILUTION PIT'},
                                                    {   name : 'DRUMS', value : 'DRUMS'},
                                                    {   name : 'FOOD WASTE', value : 'FOOD WASTE'},
                                                    {   name : 'GERNIE WASHES', value : 'GERNIE WASHES'},
                                                    {   name : 'GREASE', value : 'GREASE'},
                                                    {   name : 'GROUND WATER', value : 'GROUND WATER'},
                                                    {   name : 'J120', value : 'J120'}, 
                                                    {   name : 'LEVY', value : 'LEVY' },
                                                    {   name : 'M250', value :'M250'},
                                                    {   name : 'N205', value : 'N205'}, 
                                                    {   name : 'OIL SEPERATOR', value : 'OIL SEPERATOR'},
                                                    {   name : 'PACKAGED WASTE', value : 'PACKAGED WASTE'},
                                                    {   name : 'PRODUCT DESTRUCTION', value : 'PRODUCT DESTRUCTION'},
                                                    {   name : 'PUMP SERVICE', value : 'PUMP SERVICE'},
                                                    {   name : 'RETENTION PIT', value : 'RETENTION PIT'},
                                                    {   name : 'SEWER', value :'SEWER'},
                                                    {   name : 'STORMWATER', value :'STORMWATER'},
                                                    {   name : 'TANK WASH OUT', value : 'TANK WASH OUT'},      
                                                    {   name : 'TRADE WASTE', value : 'TRADE WASTE'},                                              
                                                    {   name : 'TRANSPORT', value : 'TRANSPORT'},                                                    
                                                    {   name : 'Z140', value : 'Z140'},
                                                    // {   name : 'WIEGH BRIDGE', value : 'WEIGH BRIDGE'},
                                                    // {   name : 'DESTRUCTION CERTIFICATE', value : 'DESTRUCTION CERTIFICATE'},
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={TypeofWaste}
                                                permEdit={permissions.site.edit}
                                                cls={'selectBoxHead'}
                                                onChange={val => setTypeofWaste(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title="Capacity"
                                                value={Capacity}
                                                mandatory={true}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCapacity(val.target.value.toUpperCase())}
                                            /> */}

                                            {/* <InputField
                                                title="Frequency (weeks)"
                                                mandatory={true}
                                                value={Frequency}
                                                permEdit={permissions.site.edit}
                                                onChange={val =>{
                                                    if(val.target.value==='')
                                                        setFrequency('')
                                                    else if(!LETTERS_VALIDATION.test(val.target.value))
                                                        setFrequency(val.target.value.toUpperCase())
                                                    }} 
                                                />*/}

                                            {/* {   tabType!=='pumps' ?
                                            <InputField
                                                title="Barcode"
                                                value={BarCode}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setBarCode(val.target.value.toUpperCase())}
                                            />
                                            :   null} */}

                                            {/* <InputField
                                                title="Last Service"
                                                type="date"
                                                placeholder="dd-mm-yyyy"
                                                value={last_service}
                                                permEdit={false}
                                                onChange={()=>{}}
                                            />

                                            <InputField
                                                title="Next Service"
                                                type="date"
                                                placeholder="dd-mm-yyyy"
                                                value={NextService}
                                                permEdit={permissions.site.edit}
                                                onChange={setNextService}
                                            /> */}

                                           {/* <InputField
                                                title="Job Duration"
                                                value={JobDuration}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setJobDuration(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Key Required Type"
                                                options={[
                                                    { name: 'YES', value: 'TRUE' },
                                                    { name: 'NO', value: 'FALSE' },
                                                ]}
                                                value={KeyRequiredType}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setKeyRequiredType(val.target.value.toUpperCase())}
                                            />

                                            <SelectField
                                                title="Job Status"
                                                options={[
                                                    { name : 'CALL', value : 'CALL'},
                                                    { name : 'CANCELLED', value : 'CANCELLED'},
                                                    { name : 'COVID', value : 'COVID'},
                                                    { name : 'ON HOLD', value : 'ON HOLD'},
                                                    { name : 'ONE OFF', value : 'ONE OFF'},
                                                    { name : 'RECURRING', value : 'RECURRING' },
                                                    { name : 'Select Option', value : ''},
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={JobStatus}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setJobStatus(val.target.value.toUpperCase())}
                                            />

                                            <InputField
                                                title={ tabType!=='pumps' ? "Alarm Code" : "Lock Box"}
                                                value={AlarmCode}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setAlarmCode(val.target.value.toUpperCase())}
                                            />
                                            {   tabType!=='pumps' ?
                                            <InputField
                                                title="Weigh Bridge Required"
                                                value={WeighBridgeReq}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setWeighBridgeReq(val.target.value.toUpperCase())}
                                            />
                                            :   null}
                                            {   tabType!=='pumps' ?
                                            <SelectField
                                                title="Pallets To Be Exchanged"
                                                options={[
                                                    { name: 'YES', value: 'TRUE' },
                                                    { name: 'NO', value: 'FALSE' },
                                                    { name : 'NOT APPLICABLE', value : 'NOT APPLICABLE'}
                                                ]}
                                                value={PalletsToBeReq}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setPalletsToBeReq(val.target.value.toUpperCase())}
                                            />
                                            :   null}
                                            {   tabType!=='pumps' ?
                                            <InputField
                                                title="Quantity"
                                                value={Quantity}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setQuantity(val.target.value.toUpperCase())}
                                            />
                                            :   null}
                                            <InputField
                                                title="Additional Information"
                                                type='textarea'
                                                value={AdditionalInfo}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setAdditionalInfo(val.target.value)}
                                            />
                                            {   tabType!=='pumps' ?
                                            <InputField
                                                title="Pit Location"
                                                type='textarea'
                                                value={CompanyPitLoc}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCompanyPitLoc(val.target.value)}
                                            />  : null }
                                            <InputField
                                                title="Access Restriction"
                                                type='textarea'
                                                value={CompanyAccess}
                                                permEdit={permissions.site.edit}
                                                onChange={val => setCompanyAccess(val.target.value)}
                                            />


                                        </div> */}
                                    {   tabType==='waste' ?
                                        <Typeofwaste tabType={tabType} clientId={selected_client.id}
                                            dataObj={dataObj} setDataObj={setDataObj} dataObjArr={dataObjArr} setDataObjArr={setDataObjArr}
                                        /> : null
                                    }
                                    </div>
                                    
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export function InputField({ title, value, onChange, type = 'text', permEdit=true, mandatory=false, inputComponent=null, setTextAreaValid=null,
    disablePast=false, disableFuture=false }) {

    if(type==='date')
        return(
            <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}
                {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <DateTimeInput
                    dateValue={value}
                    dateSetFn={ permEdit ? onChange : ''}
                    clsName={'client-input'}
                    past={disablePast}
                    future={disableFuture}
                    />
                    
                {/* <input
                    type={type}
                    placeholder={title}
                    className="client-input"
                    value={value}
                    onChange={onChange}
                /> */}
            </div>
        </div>
        )
        else if(type==='textarea'){
            return (
                <div className="data-single" style={{ height:'20vh', alignItems:'flex-start' }}>
                    <div className="detail-headings">
                        <p className="p-0 m-0">{title}
                        {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                        </p>
                    </div>
                    <div className="detail-input-dot">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="detail-input-layout">
                        <TextAreaLimiter isValid={setTextAreaValid}
                        configs={{
                            placeholder : title,
                            disabled : permEdit ? false : true,
                            rows : '10',
                            className : "client-input",
                            style : {
                                resize:'none',
                                backgroundColor:'aliceblue'
                            },
                            value : value,
                            onChange : onChange
                        }}
                        v={value}/>
                        {/* <input
                            type={type}
                            placeholder={title}
                            className="client-input"
                            value={value}
                            onChange={onChange}
                        /> */}
                    </div>
                </div>
            )    
        }
    else if(inputComponent!==null)
        return(
            <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}
                {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout" style={{ position : 'relative' }}>
                {inputComponent}
            </div>
        </div>
        )    
    else
    return (
        <div className="data-single">
            <div className="detail-headings">
                <p className="p-0 m-0">{title}
                {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <input
                    disabled={permEdit ? false : true}
                    type={type}
                    placeholder={title}
                    className="client-input"
                    value={value}
                    onChange={onChange}
                    style={{textTransform: type==='text' ? 'uppercase' : 'none'}}
                />
            </div>
        </div>
    )
}

export function SelectField({ title, value, options = [], onChange, mandatory, permEdit, cls='' }) {
    return (
        <div className={`data-single ${cls}`}>
            <div className={`detail-headings ${cls}`}>
                <p className="p-0 m-0">{title}
                {
                    mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
                }
                </p>
            </div>
            <div className="detail-input-dot">
                <p className="p-0 m-0">:</p>
            </div>
            <div className="detail-input-layout">
                <select
                    value={value}
                    className="client-input"
                    disabled={permEdit ? false : true}
                    onChange={onChange}
                >
                    {
                        options.map((item, key) => {
                            return (
                                <option key={key} value={item.value}>{item.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

// function DataListField({ title, value, options = [], type = 'text', onChange, mandatory }) {
//     return (
//         <div className="data-single">
//             <div className="detail-headings">
//                 <p className="p-0 m-0">{title}
//                 {
//                     mandatory && (<span style={{ color: 'red', fontSize: 18, marginLeft: 5 }}>*</span>)
//                 }
//                 </p>
//             </div>
//             <div className="detail-input-dot">
//                 <p className="p-0 m-0">:</p>
//             </div>
//             <div className="detail-input-layout">
//                 <input
//                     type={type}
//                     placeholder={title}
//                     className="client-input"
//                     value={value}
//                     list='dataListField'
//                     onChange={onChange}
//                 />
//                 <datalist
//                     id='dataListField'
//                     className="client-input"
//                 >
//                     {
//                         options.map((item, key) => {
//                             return (
//                                 <option key={key} value={item.value}/>
//                             )
//                         })
//                     }
//                 </datalist>
//             </div>
//         </div>
//     )
// }



const mapStateToProps = (state) => {
    const { selected_client } = state.client
    const { permissions } = state.userLogin

    return { selected_client, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        edit_client_fx: (data, id, success, failed) => dispatch(edit_client(data, id, success, failed)),
        getTypeWasteData : (id) => dispatch(getTypeOfWasteDataList(id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(App)
