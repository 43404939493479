import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Scrollview from '../../../common/ImprovedScrollBar'
// import { cl_blue } from '../../../common/Colors';
import { AddCircleOutlineRounded, RemoveCircleOutlineRounded } from '@material-ui/icons'
import { TextAreaLimiter } from '../../../common/TextAreaLimiter'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    minWidth: 120,
    maxHeight: 20,
    marginLeft: 10,
    marginTop: -8,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resize:{
      fontSize: 14,
      fontWeight: 600,
      color: 'white'
  }
}));

function TransitionsModal({

    modalStatus, 
    modalAction,
    templateStr,
    setTemplateStr,
    typeOfWaste,
    setQuoteAmount,
    setTableDataObj,
    otherFn=null
}) {

    const classes = useStyles();

    const [location, setLocation] = useState('')
    const [asset, setAsset] = useState('')
    const [issue, setIssue] = useState('')
    const [scopeOfwork, setScopOfWork] = useState('')
    const [textAreaValid, setTextAreaValid] = useState(true)

    const [totalAmt, setTotalAmt] = useState(0)
    const [inpField, setInpField] = useState('')
    const [inpDataK, setInpDataK] = useState(null)
    
    let arr_initial = [
        {
            item_description: 'Description',
            price: ''
        }
    ]

    const [tableContent, setTableContent] = useState(arr_initial)
    // const updateBtnClick = () => {
    //     let appended_html_string = '';
    //     tableContent.map((item, key) => {
    //         let table_row_content = `<td>Desription${item.item_description}</td>`
    //         appended_html_string = appended_html_string + table_row_content
    //     })

    //     //we get final html from 'appended_html_string'
    // }


    const handleClose = () => {
        modalAction(false)
    };

    const addRow = (val) =>{
        if(val){
            let single_item = {
                item_description: 'Description',
                price: '',
                
            }
            setTableContent(item => [...item, single_item])
        }
        else{
            let newArr = tableContent.filter((_, key) => key!==(tableContent.length-1))
            setTableContent(newArr)
        }
    }

    const updateTotal = () =>{
        let amt = 0
        for(let i=0; i<tableContent.length; i++ )
            amt = amt + parseFloat(tableContent[i].price)
        let roundedNum = Math.round((amt + Number.EPSILON)*100)/100
        setTotalAmt(isNaN(roundedNum) ? 0 : roundedNum)
    }
        
    const addAttachment = () => {
        setTableDataObj(tableContent)
        let htmlStrStart = `<!--##PUMP1_BOOSTER--><td class="c97" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #ffffff;border-top-width: 1pt;
        border-right-width: 1pt;border-left-color: #ffffff;vertical-align: top;border-right-color: #ffffff;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;
        width: 611.2pt;border-top-color: #ffffff;border-bottom-style: solid;"><p class="c11 c16" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;
        padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;margin-left: 49.5pt;"><span class="c20 c34" style="background-color: #ffff00;
        -webkit-text-decoration-skip: none;text-decoration: underline;text-decoration-skip-ink: none;font-weight: 700;font-family: &quot;Calibri&quot;;">
        Alternating Cold Water Booster</span><span class="c20" style="font-weight: 700;text-decoration: underline;font-family: &quot;Calibri&quot;;">&nbsp;<br><br>LOCATION:<br>
        </span><span class="c53 c20" style="-webkit-text-decoration-skip: none;text-decoration-skip-ink: none;font-style: italic;font-weight: 700;font-family: &quot;Calibri&quot;;">${location}<br></span></p>
        <p class="c11 c44" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;
        margin-right: 31.5pt;text-indent: 49.5pt;"><span class="c20 c52" style="color: #000000;text-decoration: none;text-decoration: underline;vertical-align: baseline;font-size: 11pt;font-style: normal;font-weight: 700;font-family: &quot;Calibri&quot;;">ASSET: </span></p>
        <p class="c11 c16" id="h.30j0zll" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;margin-left: 49.5pt;">
        <span class="c20" style="font-weight: 700;font-family: &quot;Calibri&quot;;">${asset}</span><span class="c53 c20" style="-webkit-text-decoration-skip: none;text-decoration: underline;text-decoration-skip-ink: none;
        font-style: italic;font-weight: 700;font-family: &quot;Calibri&quot;;"><br></span><span class="c20" style="font-weight: 700;font-family: &quot;Calibri&quot;;"><br></span><span class="c20 c53" style="-webkit-text-decoration-skip: none;
        text-decoration: underline;text-decoration-skip-ink: none;font-style: italic;font-weight: 700;font-family: &quot;Calibri&quot;;">ISSUE:<br></span><span class="c20" style="font-weight: 700;font-family: &quot;Calibri&quot;;">${issue}</span>
        <span class="c53 c20" style="-webkit-text-decoration-skip: none;text-decoration: underline;text-decoration-skip-ink: none;font-style: italic;font-weight: 700;font-family: &quot;Calibri&quot;;"><br></span>
        <span class="c61" style="font-weight: 400;font-family: &quot;Calibri&quot;;"><br></span><span class="c53 c20" style="-webkit-text-decoration-skip: none;text-decoration: underline;text-decoration-skip-ink: none;font-style: italic;font-weight: 700;font-family: &quot;Calibri&quot;;">SCOPE
                OF WORKS:</span><span class="c61" style="font-weight: 400;font-family: &quot;Calibri&quot;;"><br></span><span class="c20" style="font-weight: 700;font-family: &quot;Calibri&quot;;">${scopeOfwork}</span>
        <span class="c53 c20" style="-webkit-text-decoration-skip: none;text-decoration: underline;text-decoration-skip-ink: none;font-style: italic;font-weight: 700;font-family: &quot;Calibri&quot;;"><br></span>
        <span class="c49" style="color: #000000;font-weight: 400;text-decoration: none;vertical-align: baseline;font-size: 11pt;font-family: &quot;Calibri&quot;;font-style: normal;"><br></span></p>
        <p class="c11 c16 c36" id="h.9dlbdj8zuu7o" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;height: 11pt;margin-left: 49.5pt;"><br></p>
        <p class="c11 c36 c16" id="h.clf9gmxeh3m0" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;height: 11pt;margin-left: 49.5pt;"><br></p>
        <p class="c11 c36" id="h.h2ztjzv4c48k" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;height: 11pt;"><br></p>
        <p class="c11 c70 c16" id="h.9zd40avlkiw9" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;text-indent: 54pt;margin-left: 49.5pt;"><span class="c61" style="font-weight: 400;font-family: &quot;Calibri&quot;;"><br>PRICING:</span></p>
        <p class="c11 c70 c36 c16" id="h.9024jwru9s2o" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;text-indent: 54pt;height: 11pt;margin-left: 49.5pt;"><br></p><a id="t.87131722cbd76ecd9fefaae6620fc705cb85b3b3"></a><a id="t.2"></a>
        <!--##PUMP1_BOOSTER_E--><div><!--##PUMP1_PRICING-->
        <table class="c92 c111" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;margin-left: 50.2pt;">
            <tbody>
                <tr class="c18" style="height: 0pt;">
                    <td class="c45" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 249.8pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c11" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;"><span class="c48" style="font-size: 10pt;font-family: &quot;Calibri&quot;;font-weight: 400;">PARTS </span></p>
                    </td>
                    <td class="c47" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 247.5pt;border-top-color: #000000;border-bottom-style: solid;">
                        <p class="c1 c70 c37 c16" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;text-indent: 54pt;margin-right: 31.5pt;margin-left: 49.5pt;"><br></p>
                        <br>
                    </td>
                </tr>
        `
        let htmlStr1 =  [`<tr class="c18" style="height: 0pt;"><td class="c45" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 249.8pt;border-top-color: #000000;border-bottom-style: solid;">
            <p class="c11" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;"><span class="c48" style="font-size: 10pt;font-family: &quot;Calibri&quot;;font-weight: 400;">`
            ,`</span></p></td><td class="c47" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 247.5pt;border-top-color: #000000;border-bottom-style: solid;">
            <p class="c1 c70 c37 c16" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;text-indent: 54pt;margin-right: 31.5pt;margin-left: 49.5pt;">`
            ,`<br></p><br></td></tr>`]
                                
        let htmlStrEnd =  [`<tr class="c18" style="height: 0pt;"><td class="c45" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 249.8pt;border-top-color: #000000;border-bottom-style: solid;">
            <p class="c11" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 8pt;line-height: 1.0791666666666666;orphans: 2;widows: 2;text-align: left;margin-right: 31.5pt;"><span class="c20 c121" style="font-weight: 700;font-family: &quot;Calibri&quot;;font-size: 10pt;">TOTAL</span></p>
            </td><td class="c47" colspan="1" rowspan="1" style="padding: 5pt 5pt 5pt 5pt;border-right-style: solid;border-bottom-color: #000000;border-top-width: 1pt;border-right-width: 1pt;border-left-color: #000000;vertical-align: top;border-right-color: #000000;border-left-width: 1pt;border-top-style: solid;border-left-style: solid;border-bottom-width: 1pt;width: 247.5pt;border-top-color: #000000;border-bottom-style: solid;">
            <p class="c1 c37 c16 c70" style="margin: 0;color: #000000;font-size: 11pt;font-family:Arial, sans-serif;padding-top: 0pt;padding-bottom: 0pt;line-height: 1.0;text-align: left;height: 11pt;text-indent: 54pt;margin-right: 31.5pt;margin-left: 49.5pt;">`,
            `<br></p><br></td></tr></tbody></table>`]
        let outPut1 = templateStr.substring(0,templateStr.indexOf('<!--##PUMP1_BOOSTER-->'))
        let outPut4 = templateStr.substring(templateStr.indexOf('<!--##PUMP1_PRICING_E-->'))
        let tempStr = ''
        for(let i=0; i<tableContent.length; i++){
            tempStr = tempStr + htmlStr1[0] + tableContent[i].item_description + htmlStr1[1] + ' $ ' + tableContent[i].price + htmlStr1[2]
        }
        setTemplateStr( outPut1 + htmlStrStart + tempStr + htmlStrEnd[0] + ' $' + totalAmt + htmlStrEnd[1] + outPut4)
        setQuoteAmount(totalAmt)
        if(otherFn){
            otherFn(tableContent, `${outPut1}${htmlStrStart}${tempStr}$${htmlStrEnd[0]}${totalAmt}${htmlStrEnd[1]}${outPut4}`)
        }
        modalAction(false)
    }

    
        
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="cer-cerificate-modal-layout" onClick={(e) =>{ e.preventDefault(); e.stopPropagation(); setInpDataK('')}}>
            <div className="cer-modal-header-layout">
                    <p style={{ fontSize: 16 }} className="cer-modal-main-title">Dual Alter Cold Water Booster</p>
                </div>
                <div className="sales-quote-editTable" >

                <Scrollview autoHide style={{ height: '45vh'}} className="salesQuoteTableScrollDiv">
                        <div className="sales-quote-editDiv">
                            <div className="sales-quote-editTextAreaDiv">
                                <p>Location : </p>
                                <TextAreaLimiter
                                configs={{
                                    rows : 10, resize : 'none',
                                    value : location, onChange : (e) => setLocation(e.target.value)
                                    
                                }}
                                v={location} />
                            </div>
                            <div className="sales-quote-editTextAreaDiv">
                                <p>Asset : </p>
                                
                                <TextAreaLimiter isValid={setTextAreaValid}
                                configs={{
                                    rows : 10, resize : 'none',
                                    value : asset, onChange : (e) => setAsset(e.target.value)
                                    
                                }}
                                v={asset} />
                            </div>
                            <div className="sales-quote-editTextAreaDiv">
                                <p>Issue : </p>
                                <TextAreaLimiter isValid={setTextAreaValid}
                                configs={{
                                    rows : 10, resize : 'none',
                                    value : issue, onChange : (e) => setIssue(e.target.value)                                    
                                }}
                                v={issue} />
                            </div>
                            <div className="sales-quote-editTextAreaDiv">
                                <p>Scope of Works : </p>
                                <TextAreaLimiter isValid={setTextAreaValid}
                                configs={{
                                    rows : 10, resize : 'none',
                                    value : scopeOfwork, onChange : (e) => setScopOfWork(e.target.value)
                                    
                                }}
                                v={scopeOfwork} />
                            </div>
                        </div>
                    {/* {console.log("FROM REDUCER ",sale_client_draft)} */}
                            
                            <table className="sales-template-table">
                                <tr className="sales-template-tableHead">
                                    <th style={{ width:'10%' }}>Sl.No</th>
                                    <th style={{ width:'45%' }}>Item and Description</th>
                                    <th style={{ width:'45%' }}>Price</th>
                                </tr>
                                <tbody onClick={(e) =>{ e.preventDefault(); e.stopPropagation();}}>
                                {
                                    tableContent &&
                                    tableContent.map((item, key) => {
                                        return (
                                            <tr className="sales-template-tableRow" key={key}
                                                style={{ backgroundColor: key%2===0 ? 'rgba(0,0,255,0.1)' : 'transparent' }}
                                            >
                                                <td style={{ width:'10%' }}><div><p>{key+1}</p></div></td>
                                                <td><div>
                                                    {   inpDataK === key+'des' ?
                                                        <input type="text" value={item.item_description}
                                                        onChange={(e)=>{
                                                            setInpField(e.target.value)
                                                           let obj = { ...item, item_description: e.target.value }
                                                           let newArr = tableContent
                                                           newArr.splice(key, 1, obj)
                                                              setTableContent(newArr) }} />
                                                    :
                                                        <p
                                                        onMouseEnter={()=>{ setInpDataK(key+'des'); setInpField(item.item_description); }}
                                                        onClick={()=>{ setInpDataK(key+'des'); setInpField(item.item_description); }}>{item.item_description}</p>
                                                    
                                                    }
                                                </div></td>
                                                <td><div>
                                                    {   inpDataK === key+'pri' ?
                                                        <><span>$</span>
                                                        <input type="text" value={item.pricing} placeholder='0'
                                                        style={{ textAlign:'center', fontSize:'1rem',  width:'40%' }}
                                                        onChange={(e)=>{
                                                            if((/^\d{0,}(\.\d{0,})?$/).test(e.target.value)){
                                                                setInpField(e.target.value)
                                                            let obj = null
                                                            
                                                                obj = { ...item, price: e.target.value }
                                                            let newArr = tableContent
                                                           newArr.splice(key, 1, obj)
                                                              setTableContent(newArr)
                                                              updateTotal()
                                                            }
                                                            } } />
                                                        </>
                                                    :
                                                        <p 
                                                        onMouseEnter={()=>{ setInpDataK(key+'pri'); setInpField(item.price);   }}
                                                        onClick={()=>{ setInpDataK(key+'pri'); setInpField(item.price); }}  style={{ justifyContent:'center' }}>
                                                            ${item.price}
                                                        </p>
                                                    
                                                    }
                                                </div></td>
                                                
                                            </tr>
                                            
                                        )
                                    })
                                }
                                <tr  className="sales-template-tableRowTotal">
                                    <td colSpan={2}>
                                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between' }}>
                                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                                            style={{ width:'5vw', display:'flex', alignItems:'center', fontSize:'16px', justifyContent:'space-evenly', marginTop:'10px' }}
                                            onClick={()=>{ addRow(true); if(inpField){ setInpDataK('') } } }>
                                            <div><AddCircleOutlineRounded style={{ fontSize:'16px' }}/></div><div>Add</div>
                                        </div>
                                    
                                    {   tableContent && tableContent.length>1 ?
                                    
                                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                                            style={{ width:'5vw', display:'flex', alignItems:'center', fontSize:'16px', justifyContent:'space-evenly', marginTop:'10px' }}
                                            onClick={()=> addRow(false) }>
                                            <div><RemoveCircleOutlineRounded style={{ fontSize:'16px' }}/></div><div>Remove</div>
                                        </div>
                                       :   
                                        <div></div>
                                    }
                                        </div>
                                        
                                    </td>
                                    <td>
                                        <div id="tableTotalDiv">
                                        <div style={{ margin:'10px 5px'}} className="TotalInpDiv">
                                            <p className="totalInp">Total</p>
                                        </div>
                                        <div className="TotalInpDiv" style={{ margin:'10px 5px', backgroundColor:'rgba(0,0,0,0.1)'}} >
                                            <p className="totalInp" style={{ justifyContent:'center' }}>${totalAmt}</p>
                                        </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            
                        </Scrollview>
                    
                    <div style={{ display:'flex', flexDirection:'row', fontSize:'16px', alignItems:'center', justifyContent:'space-around' }}>
                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active"
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center', 
                        pointerEvents: textAreaValid ? 'auto' : 'none' }}
                            onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); addAttachment(); } }
                        >
                            Update Table
                        </div>
                        
                    </div>
                    
                </div>
               
            </div>
      </Modal>
  );
}


 export default TransitionsModal
 
