import { ACCOUNTS_JOB_COMPLETED_LIST, 
    //ACCOUNTS_JOB_STATUS_UPDATE, 
    ACCOUNTS_JOB_TEMPLATE, INVOICE_COMPLETEDJOB_COMMENTS, INVOICE_JOB_PAID_STATUS_CHANGE } from '../../../store/endpoint'
import Api from '../../../store/api'

export const fetch_all_completed_jobs = (page, search=null, from=null, to=null, searchVal) =>{
    return function (dispatch){

        let get_url = `${ACCOUNTS_JOB_COMPLETED_LIST}?page=${page}&limit=25`
        if(search)
            get_url = `${get_url}&key=${search}&search_type=${searchVal==='clients' ? 'client' : 
            searchVal==='job' ? 'job_code' : 'quote'}`
        if(from!==null && to!==null)
            get_url = `${get_url}&from_date=${from}&to_date=${to}`

        dispatch({
            type : 'TURNON_LOADER_COMPL_JOBS',
            loader : true
        })
        Api('get', get_url).then(res => {
            // console.log(res)
            if(res){
                dispatch({
                    type : 'TURNON_LOADER_COMPL_JOBS',
                    loader : false
                })
                dispatch({
                    type : 'GET_ALL_COMPL_JOBS',
                    list : res,
                    page : page,
                    search : search,    
                    from : from,
                    to : to,
                    stopPage : res && res?.length < 25 ? true : false
                })
                dispatch({
                    type: 'SET_JOB_FNCALLED_INMEMORY',
                    currentApiObj : {
                        page    :   page, 
                        search  :   search, 
                        from    :   from, 
                        to      :   to, 
                        searchVal   :   searchVal
                    }
                })
            }else 
                dispatch({
                    type : 'TURNON_LOADER_COMPL_JOBS',
                    loader : false
                })
        })
    }
}

export const refresh_completed_jobs = (page, search=null, from=null, to=null, searchVal) =>{
    return function (dispatch){

        let get_url = `${ACCOUNTS_JOB_COMPLETED_LIST}?page=${page}&limit=25`
        if(search)
            get_url = `${get_url}&key=${search}&search_type=${searchVal==='clients' ? 'client' : 
            searchVal==='job' ? 'job_code' : 'quote'}`
        if(from!==null && to!==null)
            get_url = `${get_url}&from_date=${from}&to_date=${to}`

        Api('get', get_url).then(res => {
            // console.log(res)
            if(res){
                dispatch({
                    type : 'REFRESHL_COMPL_JOBS',
                    list : res
                })
            }
        })
    }
}

export const fetch_curr_template = (id, success, failed) => {
    return function (dispatch){
        let get_url = `${ACCOUNTS_JOB_TEMPLATE}${id}/`
        Api('get',get_url).then(res => {
            // console.log(res)
            if(res){
            dispatch({
                type : 'SET_COMPL_TEMP',
                content : res.quote_template,
                content_table : res.table_data
            })
            dispatch({
                type : 'SET_MAIN_CONTENT_QUOTE',
                content : res.quote_template
            })
            dispatch({
                type : 'SET_MAIN_QUOTE_TABLE_OBJ',
                tableObj : res.table_data ? JSON.parse(res.table_data) : null
            })
            
            success()
            }
            else{
                failed()
            }
        })
        
    }
}

export const update_curr_template = (id, data, success, failed) => {
    console.log('UPDATINGTABLEDATA',id,data)
        let get_url = `${ACCOUNTS_JOB_TEMPLATE}${id}/`
        Api('put-json',get_url, data, success, failed)
}


export const update_job_status = (id, k, data, success=null, failed=null) =>{
    return function (dispatch){
        
        let get_url = `${INVOICE_JOB_PAID_STATUS_CHANGE}${id}/`
        Api('put-json',get_url, data).then(res => {
            if(res){
                // console.log(res)
                dispatch({
                    type : 'UPDATE_COMPL_JOB',
                    pos  :  k,
                    content : res
                })
                if(success)
                    success()
            }
            else{
                if(failed)
                    failed()
            }
        })
    }
}

export const fetch_comments_of_job = (id, act=null, pos=null, success=null, failed=null,) =>{
    return function (dispatch){
        let get_url = `${INVOICE_COMPLETEDJOB_COMMENTS}list/${id}/`
        Api('get',get_url).then(res => {
            if(res){
                console.log(res)
                dispatch({
                    type : 'SET_COMMENTS_ARRAY',
                    comments : res,
                    pos : pos,
                    commentNo : act ? act : 0
                })
                if(success)
                    success()
            }else{
                if(failed)
                    failed()
            }
        })
    }
}


export const comments_of_job = (id, type='add', data, success=null, failed=null) =>{
    return function (dispatch){
        let get_url = `${INVOICE_COMPLETEDJOB_COMMENTS}${ type==='add' ? 'add/' : 'edit/'}${id}/`

        const success_Fx = (res) =>{
            if(success)
                success()
            console.log('SUCESSREsp=>',res)
        }
        const failed_Fx = (res) =>{
            if(failed)
                failed()
            console.log('FailedResp=>',res)
        }

        Api( type==='add' ? 'post' : 'put-json',get_url,data, success_Fx, failed_Fx)
    }
}

export const del_comments_of_job = (id, success=null, failed=null) =>{
    return function (dispatch){
        let get_url = `${INVOICE_COMPLETEDJOB_COMMENTS}delete/${id}/`

        const success_Fx = (res) =>{
            if(success)
                success()
            console.log('SUCESSREsp=>',res)
        }
        const failed_Fx = (res) =>{
            if(failed)
                failed()
            console.log('FailedResp=>',res)
        }

        Api('delete',get_url,'', success_Fx, failed_Fx)
    }
}