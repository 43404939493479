// import { ArrowBack, ArrowForward } from '@material-ui/icons'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetch_news_list, read_news } from '../common/action'
import CreateModal from './create_notify_news'
// import Tooltip from '@material-ui/core/Tooltip';
// import {HOST} from '../../../store/endpoint'
import NoDatasScreen from '../../../common/no_datas'
import { CircularProgress } from '@material-ui/core'
import Scrollbars from '../../../common/ImprovedScrollBar'
// import { makeStyles } from '@material-ui/core/styles';

function NewsList({ fetch_newses, news_list, news_page, read_news_fx, pageNewsContent, permissions, newsLoader, stopPagingNews }) {

    const [createModal, setCreateModal] = useState(false)
    const [pageNo, setPageNo]           = useState(1)
    const [modalType, setModalType]     = useState("")
    const [dataObj, setDataObj]         = useState({ id:'', title:'', description:'', fileName:'', mem:[], editStatus:false })
    
    useEffect(() => {
        fetch_newses(pageNo)
        return () => fetch_newses.remove
    }, [fetch_newses, pageNo])

    const newsStatus = (newsId, val) =>{
        read_news_fx(pageNo, newsId, !val)
    }

    // let limit = 8;
    //     let balance = 0;
    //     if(news_list.length < limit) {
    //         balance = limit - news_list.length
    //     }
        // const useStyles = makeStyles((theme) => ({
            
        //     customWidth: {
        //       maxWidth: 500,
        //     },
            
        //   }));

        const cropDesc = (str) =>{
            if(str)
                return str.length>200 ? `${str.substring(0,100)}...` : str;
            else 
                return 'Untitled'
        }
    
        const ScrollUp = (val) =>{
            const {top} = val
            if(top === 1 && !stopPagingNews && ((pageNo===news_page) || (news_page===1 && pageNo!==1))){
                setPageNo(news_page === 1 ? 2 : pageNo+1)
                
            }
            // console.log('SCROLL','top:',top ,'stop:',stopPagingNotify,'page',pageNo ,'NOTIF',notification_page)
        }
    

    return (
        <div className="ohs-box-layout">
            <div className="ohs-box-header">
                <p>Latest News</p>
                <div className="ohs-pagination-layout">
                    {   permissions.ohs.add ?
                    <button className="shdl-pagi-m-b ohs-pagi-add-btn" onClick={()=> { setCreateModal(true); setModalType("Add News"); setDataObj(null); }} >Add New</button>
                    : null}
                    {/* <div  className={ news_page===1 ? "ohs-pagi-b":"ohs-pagi-a"} onClick={()=>{ if(news_page!==1) { setPageNo(pageNo-1); } }}>
                        <ArrowBack style={{ fontSize: 16 }} />
                    </div> */}
                    {/* <div className="shdl-pagi-m-b" style={{ width:'auto', paddingLeft: '1px', paddingt: '1px' }}>
                        <p>Page: </p>
                        <p>{news_page}</p>
                    </div> */}
                    
                    {/* <div className={ news_list.length<8 ? "ohs-pagi-b":"ohs-pagi-a"} onClick={()=>{ if(news_page!==pageNo){ setPageNo(pageNo-1) } else{ setPageNo(pageNo+1); } }}>
                        <ArrowForward style={{ fontSize: 16 }} />
                    </div> */}
                </div>
            </div>
            
            {   newsLoader ?
                <div style={{ position:'absolute', top:'50%', left:'50%' }}>
                    <CircularProgress />
                </div>
            
                : 
                
                news_list.length > 0 ? (
                    // <table style={{ height: '40vh' }}>
                    //     <tbody>
                    //         <tr>
                    //             <td className="ohs-table-head">Title</td>
                    //             <td className="ohs-table-head">Description</td>
                    //             <td className="ohs-table-head">Updated By</td>
                    //             <td className="ohs-table-head">Action</td>
                    //         </tr>

                    //         {
                    //         news_list.map((item, key) => {
                    //             //    const { title, description } = item
                    //                 return (
                    //                     <tr key={key}  style={{ cursor:'pointer' }} 
                    //                     onClick={()=>{ 
                    //                         setDataObj({id:item.id, title:item.title, description:item.description, fileName:item.file_attachment, mem:item.members_list, editStatus: item.edit_status}); 
                    //                         setModalType("News"); 
                    //                         setCreateModal(true);}}>
                    //                         <td>
                    //                         <Tooltip title={item.title}>
                    //                             <div  style={{ display:'flex', justifyContent:'left', alignContent: 'center', marginLeft:'30px' }}>
                    //                                 {cropDesc(item.title)}
                    //                             </div>
                    //                         </Tooltip>
                    //                         </td>
                    //                         <td>
                    //                             <div  style={{ display:'flex', justifyContent:'left', alignContent: 'center' }}>
                    //                                 {cropDesc(item.description)}
                    //                             </div>
                    //                         </td>
                    //                         <td>
                    //                             <div className="ohs-table-user-field">
                    //                                 <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={item.dp} alt="" />
                    //                                 <p>{item.created_by}</p>
                    //                             </div>
                    //                         </td>
                    //                         <td>
                    //                             <button className={!item.user_read_status ? "ohs-table-action-btn" : "ohs-table-action-btn-read"} onClick={(e)=>{ e.preventDefault();
                    //                         e.stopPropagation(); newsStatus(item.id) }}>Read</button>
                    //                         </td>
                    //                     </tr>
                    //                 )
                    //             })
                    //         }
                    //         {
                    //             [...Array(balance)].map((_,k) => {
                    //                 return(
                    //                     <tr key={k}><td></td><td></td><td></td><td></td></tr>
                    //                 )
                    //             })
                    //         }
                    //     </tbody>
                    // </table>
                    <Scrollbars style={{ height: '40vh' }}
                    onScrollFrame={ScrollUp}
                >
                <div className="ohs-table-NewsListDiv">
                    {

                        news_list.map((item, key) => {
                            return (
                                <section key={key} style={{ cursor:'pointer' }}  className="ohs-table-NewsSingleDiv"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation(); 
                                    setDataObj({id:item.id, title:item.title, description:item.description, fileName:item.file_attachment, mem:item.members_list, editStatus: item.edit_status});
                                    setModalType("News");
                                    setCreateModal(true); }}
                                >
                                    <p>{item.title}</p>
                                    <span>{cropDesc(item.description)}</span>
                                    <aside>
                                        <div className="ohs-table-user-field">
                                            <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={item.dp} alt="" />
                                            <p>{item.created_by}</p>
                                        </div>
                                        <button className={!item.user_read_status ? "ohs-table-action-btn" : "ohs-table-action-btn-read"}  onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation(); newsStatus(item.id, item.user_read_status) }}>
                                            Mark as {!item.user_read_status ? 'R' : 'Unr'}ead
                                        </button>
                                    </aside>
                                </section>
                            )
                        })
                    }
                    {
                        !stopPagingNews && !newsLoader ?
                        <div style={{ marginTop: '2%', marginLeft: '50%' }}>
                            <CircularProgress size={20} />
                        </div>
                        :
                        null
                    }
                </div>
                    </Scrollbars>
                ) : ( <NoDatasScreen /> )
            }

            <CreateModal
                modalStatus = {createModal}
                modalAction = {setCreateModal}
                createType  = {modalType}
                setModalType = {setModalType}
                dataObj     =   {dataObj}
                pageNo      =   {pageNo}
            />
        </div>
    )
}


const mapStateToProps = (state) => {
    const { news_list, news_page, pageNewsContent, newsLoader, stopPagingNews } = state.ohs
    const { permissions } = state.userLogin

    return { news_list, news_page, pageNewsContent, permissions, newsLoader, stopPagingNews }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_newses: (page_no) => dispatch(fetch_news_list(page_no)),
        read_news_fx:   (pageNo, id,val) => dispatch(read_news(pageNo, id, val))
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (NewsList)
