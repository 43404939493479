import React from 'react';

function PartListPumpDetail({modalType, pumpDet, setPumpDet}) {

    return (
        <>
            <div className="jobCardDivHead" >Pump Details</div>
                    <div className="jobCardInputDivSec" >
                        <div>Pump Make</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.pumpMake}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pump Make" value={pumpDet.pumpMake} 
                            onChange={(e)=>setPumpDet({ ...pumpDet, pumpMake : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pump Model</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.pumpModel}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pump Model" value={pumpDet.pumpModel} 
                            onChange={(e)=>setPumpDet({...pumpDet, pumpModel : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Static Head</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.static}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Staic Head" value={pumpDet.static} 
                            onChange={(e)=>setPumpDet({...pumpDet, static : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Motor Current</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.motorCurr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Motor Current" value={pumpDet.motorCurr}
                             onChange={(e)=>setPumpDet({...pumpDet, motorCurr : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Volt 240/415</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.volt}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Volt 240/415" value={pumpDet.volt} 
                            onChange={(e)=>setPumpDet({...pumpDet, volt : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Kilowatts</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.kilowatts}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Kilowatts" value={pumpDet.kilowatts} 
                            onChange={(e)=>setPumpDet({...pumpDet, kilowatts : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Auto/Manual</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.autoManual}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Auto/Manual" value={pumpDet.autoManual} 
                            onChange={(e)=>setPumpDet({...pumpDet, autoManual : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Guide Rails/Free Standing</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.guideRail}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Guide Rails/Free Standing" value={pumpDet.guideRail} 
                            onChange={(e)=>setPumpDet({...pumpDet, guideRail : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Cable Pull Possible</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ pumpDet.cablePull } 
                                onChange={(e)=>setPumpDet({...pumpDet, cablePull : true})} /><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !pumpDet.cablePull } 
                                onChange={(e)=>setPumpDet({...pumpDet, cablePull : false})} /><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{pumpDet.cablePull ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Resin</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ pumpDet.resin } 
                                onChange={(e)=>setPumpDet({...pumpDet, resin : true})} /><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !pumpDet.resin } 
                                onChange={(e)=>setPumpDet({...pumpDet, resin : false})} /><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{pumpDet.resin ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Distance from Panel to pit</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpDet.dist}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Distance from Panel to pit" value={pumpDet.dist} 
                            onChange={(e)=>setPumpDet({...pumpDet, dist : e.target.value})} />
                            }
                        </div>
                    </div>
                    

        </>
    );
}

export default PartListPumpDetail;