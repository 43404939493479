import React, {useState} from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import Sidebar from '../../common/sidebar'
import Header from './component/header'
import { JobLists } from './component'
import ScheduleList from './component/ScheduleCalendar'
import './component/style.css'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons'

function App({permissions}) {
   
    const { path } = useRouteMatch()
    const [maximize, setMaximize] = useState(false)

    const WasteApp = () => {
        let screen_type = 'waste'
        if(window.innerWidth < 1200){
            return  (
                <div className="scheduling-main-layout" style={{ position : 'relative' }}>
                    {   maximize    ?
                    null    :
                    <div className="scheduing-left-layout" style={{ width : '60%' }}>
                        <JobLists screen_type={screen_type} />
                    </div>
                    }
                    
                        <div className={`swipeDsgnTop ${maximize ? 'swipeDsgnTopLeft' : ''}`}></div>
                        <div className={`swipeBtn ${maximize ? 'swipeBtnLeft' : ''}`}  onClick={()=>{
                             setMaximize(!maximize);
                             }}>
                        {
                            maximize ?  <ArrowForwardIosRounded/>   
                                :   <ArrowBackIosRounded />
                        }
                        {
                            maximize ?  <p>Job List</p>
                                :   <p>Schedule <br/>Calendar</p>
                        }
                        </div>
                        <div className={`swipeDsgnBottom ${maximize ? 'swipeDsgnBottomLeft' : ''}`}></div>
                    
                    
                    {   maximize    ?
                    <div className="scheduing-right-layout-main">
                        <ScheduleList screen_type={screen_type} maximize={maximize} setMaximize={setMaximize} isTabletWindow={true}/>
                    </div>
                        :   null
                    }                
                </div>
            )
        }
        else return (
            <div className="scheduling-main-layout">
                {   maximize    ?
                null    :
                <div className="scheduing-left-layout">
                    <JobLists screen_type={screen_type} />
                </div>
                }
                <div className="scheduing-right-layout-main">
                    <ScheduleList screen_type={screen_type} maximize={maximize} setMaximize={setMaximize} />
                </div>                
            </div>
        )
    }

    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header />
                {
                    permissions ?
                <Switch>
                    <Route path={path + '/waste'} component={WasteApp} />
                    <Route path={path + '/hills'} component={WasteApp} />
                    <Route path={path + '/pump'} component={WasteApp} />
                    <Route path={path + '/destruction'} component={WasteApp} />
                    <Route path={path + '/all'} component={WasteApp} />
                    {
                        permissions && permissions.home.waste ?
                            <Redirect from={path} to={path +'/waste'}/>    :
                        permissions && permissions.home.pumps ?
                            <Redirect from={path} to={path +'/pump'}/>     :
                        permissions && permissions.home.hills ?
                            <Redirect from={path} to={path + '/hills'}/>    :
                            <Redirect from={path} to={path + '/destruction'}/>
                    }
                    
                </Switch>
                :
                <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%' }} >
                    <CircularProgress />
                </div>
                }
            </div>
        </div>
    )
}



const mapStateToProps = (state) => {
    const { permissions } = state.userLogin
    return { permissions }
}

export default connect(mapStateToProps, null) (App)