import React from 'react'
import { Search } from '@material-ui/icons';
import { connect } from 'react-redux';
import {  __vehicle_modal, __select_maintanace, fetch_filter_maintance_list, fetch_forklift_maintanace_list } from '../common/action'
import Modal from './Modal'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateTimeInput from '../../../../../common/DateTimeInput';
import SearchTab from '../../common/SearchTab';
import moment from 'moment';

function Navbar({
    
    vehicle_modal_action,
    select_maintanace,
    fetch_filter_forklift,
    all_forklift_list,
    fetch_maintance_list,
    filterKey,
    tabType,
    setPageLoader,
    clearFilterDate,
    setClearFilterDate,
    filterFromDate, setFilterFromDate,
    filterToDate, setFilterToDate,
    invokeDate, setInvokeDate
}) {

    // const [clearFilterDate, setClearFilterDate] = useState(false)
    // const [filterDate, setFilterDate] = useState(new Date())
    // const [invokeDate, setInvokeDate]   =   useState(false)

    const useStyles = makeStyles((theme) => ({
        formControl: {
          minWidth: 120,
          maxHeight: 20,
          marginLeft: 10,
          marginTop: -8,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
        resize:{
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }
      }));

      const failedFilter = () =>{
        fetch_maintance_list(tabType)
    }

    const SortDropdown = () => {
        const classes = useStyles();
        return (
            <FormControl className={classes.formControl} style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around' }}>
                <div>Filter</div> 
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14 }}
                    value={filterKey}
                    className={classes.resize}
                    style={{ color:'black' }}
                    onChange={(val) => {
                        
                        if(val.target.value!=='all'){
                            let postData = new FormData()
                            postData.append('registration',val.target.value)
                            postData.append('tab_type',tabType)
                            postData.append('vehicle_type',tabType==='hills' ? 'machinery' : 'fork-lift')

                            fetch_filter_forklift( tabType, null, failedFilter, postData, 'vehicle', val.target.value, 1,'filter')
                            setClearFilterDate(false); setInvokeDate(false); 
                        }
                        else{
                            fetch_filter_forklift( tabType, null, null, null, 'all', 'all', 1, 'filter')
                            // fetch_maintance_list(tabType, setPageLoader)
                        }
                    }}
                >
                
                <MenuItem value={'all'}>All</MenuItem>
                {   
                    all_forklift_list.map((item,key)=>{
                        
                        return(
                            <MenuItem key={key} value={item.registration}>{item.registration}</MenuItem>
                        )
                    })
                }
                                    
                </Select>
            </FormControl>
        )
    }

    const FromDateFilterFn = (date) => {
        let obj = { val : date, status : true}
        setFilterFromDate(obj)
        let date_time = date
        let postData = new FormData()
        postData.append('from_date', date_time)
        postData.append('to_date',filterToDate.val)
        postData.append('vehicle_type',tabType==='hills' ? 'machinery' : 'fork-lift')
        postData.append('tab_type', tabType)
        if(filterToDate.status)
            fetch_filter_forklift( tabType, null, failedFilter, postData, 'day', 'all', 1, 'date')
        setClearFilterDate(true)
    }
    const ToDateFilterFn = (date) => {
        let obj = { val : date, status : true}
        setFilterToDate(obj)
        let date_time = date
        let postData = new FormData()
        postData.append('from_date', filterFromDate.val)
        postData.append('to_date', date_time)
        postData.append('vehicle_type',tabType==='hills' ? 'machinery' : 'fork-lift')
        postData.append('tab_type', tabType)
        if(filterFromDate.status)
            fetch_filter_forklift( tabType, null, failedFilter, postData, 'day', 'all', 1, 'date')
        setClearFilterDate(true)
    }

    return (
        <div className="sgr-mntnce-rprt-topbar">
            <button className="sgr-mntnce-rprt-topbarBtn2">Master - { tabType==='hills' ? 'Machinery' : 'Semi Trailers/Others'}</button>
            <button className="sgr-mntnce-rprt-topbarBtn2">Maintanance Report</button>
            <div className="sgr-mntnce-rprt-search-layout">
                <SearchTab
                    tabType={tabType} vType={tabType==='hills' ? 'machinery' : 'fork-lift'} subType={'mntc'}
                />
                    <Search style={{ fontSize: 20 }} className="search-icon" />
            </div>
            
            <button 
                onClick={() => {
                    vehicle_modal_action(true)
                    select_maintanace({})
                }} 
                className="sgr-mntnce-rprt-addNew">ADD NEW</button>

                <div className="sgr-mntnce-rprt-addNew2" style={{ display:'flex', flexDirection:'row', alignItems:'center', paddingTop:'10px' }}>
                    <SortDropdown />
                </div>
                <div className="sgr-mntnce-rprt-addNew2" style={{ display:'flex', flexDirection:'row' }}>
                    <div style={{ padding: '0 20px', display: 'flex', alignItems: 'center', width: invokeDate ? '25rem' : 'auto' }}>
                        <div>Filter By Service Date : </div>
                        {
                            invokeDate ?
                                <div style={{ display : 'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'90%' }}>
                                    <span style={{ marginLeft:'1%' }}>From :</span> 
                                    <DateTimeInput dateValue={filterFromDate.val} rangeDateMax={filterToDate.status ? moment(filterToDate.val).format('YYYY-MM-DD') : null} dateSetFn={FromDateFilterFn} />
                                    <span style={{ paddingLeft:'1%', borderLeft:'.2px solid grey' }}>
                                    To :
                                    </span>
                                    <DateTimeInput dateValue={filterToDate.val}  rangeDate={filterFromDate.status ? moment(filterFromDate.val).format('YYYY-MM-DD') : null} dateSetFn={ToDateFilterFn} />
                                </div>
                            :
                                <span style={{ padding: '0 20px', display: 'flex', alignItems: 'center', borderRadius: '30px' }}
                                    onClick={() => { setInvokeDate(true) }}>
                                    Select Date
                                </span>
                        }
                    </div>
                    {
                        clearFilterDate || invokeDate ?   
                        <span style={{ padding:'0 20px', display:'flex', alignItems:'center', borderRadius:'30px' }} 
                        onClick={()=>{ setClearFilterDate(false); fetch_maintance_list(tabType, setPageLoader); setInvokeDate(false); }}>
                            Clear
                        </span>
                        :   null
                    }
                </div>

            <Modal tabType={tabType} />
        </div>
    )
}


const mapStateToProps = (state) => {
    const { all_forklift_list, filterKey } = state.vehicle__forklift

    return {all_forklift_list, filterKey}
}

const mapDispatchToProps = (dispatch) => {
    return {
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        select_maintanace: (maintanace) => dispatch(__select_maintanace(maintanace)),
        fetch_filter_forklift: (tabType, success, failed, data, type, id, page,fetchtype) => dispatch(fetch_filter_maintance_list(tabType, success, failed, data, type, id, page,fetchtype)),
        fetch_maintance_list: (tabType,callBk=null) => dispatch(fetch_forklift_maintanace_list(tabType, 1, callBk)),
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (Navbar)

