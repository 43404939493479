import React  from 'react'
import { TeamCover } from '../../../common/Images'
// import { EployeeDetails, Certificates, TeamActivity } from './Detailed'
import EmployeeDetails from './EmployeeDetails'
// import Certificates from './Certificates'
import Certificates from './Certificates'
import TeamActivity from './TeamActivity'
import DangerousData from './dangerousData'

function TeamDetail() {
         return (
        <div className="team-detail-layout">
            <div className="team-cover-img-layout">
                <img className="team-cover-img" src={TeamCover} alt="enviro team cover" />
            </div>

            <div className="team-detailed-main-layout">
               <div className="team-detail-main-layout-left">   
                   <EmployeeDetails />
                   <DangerousData />
               </div>
                <div className="team-detail-right">
                    <Certificates />
                    <TeamActivity />
                </div>
            </div>
        </div>
    )    
}

export default TeamDetail
