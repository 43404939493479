import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
// import { persistReducer, persistStore } from 'redux-persist'
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
// import storage from 'redux-persist/lib/storage/session'
import thunk from 'redux-thunk'
// import logger from 'redux-logger'

import userLogin from '../screens/login/reducer'
import team  from '../screens/team/reducer'
import client from '../screens/client/reducer'

import vehicle__truck from '../screens/vehicle/component/truck/common/reducer'
import vehicle__car from '../screens/vehicle/component/car/common/reducer'
import vehicle__forklift from '../screens/vehicle/component/forklift/common/reducer'

import sales from '../screens/sales/common/reducer'
import schedule from '../screens/scheduling/common/reducer'
import ohs from '../screens/ohs/common/reducer'
import homeGetData from '../screens/home/reducer'
import jobView from '../screens/jobs/reducer'
import invoiceData from '../screens/invoice/common/reducer'
import accountsData from '../screens/accountsComp/common/reducer'
import mainQuote from '../common/mainEditorTable/reducer'
import { HOST } from './endpoint'

const appReducer = combineReducers({
    userLogin, team, client,
    vehicle__truck, vehicle__car, vehicle__forklift, homeGetData,
    sales, schedule, ohs, jobView,
    invoiceData, accountsData, mainQuote
})


const rootReducer = (state, action) => {
    if(action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}

// const rootPersistConfig = {
//     key: 'root',
//     storage,
//     stateReconciler: hardSet
// }

const composeEnhancer = (HOST.includes('staging') ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose


const store = createStore(
    // persistReducer(rootPersistConfig, rootReducer),
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))
)

// const persistor = persistStore(store)

export { store}