import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetch_job_list_for_all, reloadPage, status_change_sales, sales_quote_client, sales_job_filter, sales_jobSearch_client } from '../../common/action'
import Navbar from './navbar'
import SalesExcecutieves from './sales_excecutieves'
import SalesHomeList from './sales_home_list'
import TableLayout from './table_layout'
import { refresh_job_view } from '../../../jobs/action'
import QuoteModal from '../../../../common/CommonQuoteDetailModal'
import { SALES_JOB_SITE_JOBCODE_SEARCH, SALES_JOB_SITE_SEARCH, SCHEDULE_QUOTE_INFOS_NEW } from '../../../../store/endpoint'
import Api from '../../../../store/api'
import { CircularProgress, Tooltip } from '@material-ui/core'
import NoDatasScreen from '../../../../common/no_datas'
import { cl_green, cl_red } from '../../../../common/Colors'
import { Fragment } from 'react'
import QuoteHistoryModalView from '../../../scheduling/component/quoteHistoryModal'
import './sales_tableStyle.css';
import moment from 'moment'
import Swal from 'sweetalert2'

// import Scrollbars from 'react-custom-scrollbars'


function Home({ fetch_jobs, job_list, job_list_page, job_list_filter, fetch_search_data, job_stop_pagination, job_loading,
    refresh_job_viewFx, status_change_fx, tableLoader, refresh_page, fetch_data_filter, filterDataFx, tabType }) {

    useEffect(() => {
        refresh_page()
        fetch_jobs(1, tabType, 'all')
        refresh_job_viewFx()

        return fetch_jobs.remove
    }, [fetch_jobs, tabType, refresh_job_viewFx, refresh_page])

    const [quoteModal, setquoteModal] = useState(false)
    const [quoteInfos, setQuoteInfos] = useState({ job: null, quote: null, schedul: null })

    const [invokeFilter, setInvokeFilter] = useState(filterDataFx)
    const [filterData, setFilterData] = useState(filterDataFx === null ? new FormData() : filterDataFx)

    const [searchDataFx, setSearchDataFx] = useState(null)
    const [salesJobSpinner, setSalesJobSpinner] = useState(false)
    const [salesJobSpinnerBtnIndex, setSalesJobSpinnerBTnIndex] = useState(null)
    const [QuoteHistoryModal, setQuoteHistoryModal] = useState(false)
    const [quoteHistoryInfos, setQuoteHistoryInfos] = useState([])
    const [statusLoader, setStatusLoader] = useState(null)
    const [jobVal, setJobVal] = useState(null)
    const [switchableSearchFnVal, switchableSearchFn] = useState({ label : 'Clients', value : 'clients' })
    // const [hoverVal, setHoverVal] = useState(null)

    const fetchQuoteInfos = (id, key) => {
        setSalesJobSpinner(true)
        setSalesJobSpinnerBTnIndex(key)

        const succ = (res) =>{
            console.log(res.data)
            setSalesJobSpinner(false)
            setSalesJobSpinnerBTnIndex(null)
            if(res.data){
            setQuoteInfos(res.data)
            setquoteModal(true)
            setJobVal(res.data.quote.job_card_id)}
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
        }

        const fail = (err) =>{
            setSalesJobSpinner(false)
            setSalesJobSpinnerBTnIndex(null)
                Swal.fire({
                   icon: 'error',
                   title: 'Oops...',
                   text: err && err.app_data ? err.app_data : 'Something went wrong!'
               })
        }

        Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`,null, succ, fail)
    }

    const nextPageFx = () => {
        if (searchDataFx !== null){
            fetch_search_data(tabType, searchDataFx, job_list_page + 1, switchableSearchFnVal.value)
        }else if (invokeFilter !== null)
            fetch_data_filter(filterData, invokeFilter, job_list_page + 1, tabType)
        else
            fetch_jobs(job_list_page + 1, tabType, job_list_filter)
        //refresh_job_viewFx()
    }

    const prevPageFx = () => {
        // if (searchDataFx !== null)
        //     fetch_search_data(tabType, searchDataFx, job_list_page - 1)
        // else if (invokeFilter !== null)
        //     fetch_data_filter(filterData, invokeFilter, job_list_page - 1, tabType)
        // else
        //     fetch_jobs(job_list_page - 1, tabType, job_list_filter)
        //refresh_job_viewFx()
    }

    const schdStatus = (actVal, jobId) => {

        const success = () => {
            setStatusLoader(null)

            if (job_list_page === 1) {
                refresh_page()
                fetch_jobs(1, tabType, 'all')
            }
            else fetch_jobs(job_list_page, tabType, job_list_filter)
        }

        status_change_fx(actVal, jobId, success)



    }

    const refreshPage = (val) => {
        fetch_jobs(val, tabType, job_list_filter)
    }

console.log('switchableSearchFnVal.value',switchableSearchFnVal,switchableSearchFnVal.value)

    return (
        <div>
            <Navbar page={tabType} />
            <div className="sales-main-layout" >
                <SalesHomeList>
                    <TableLayout
                        row_count={job_list.length}
                        page={job_list_page}
                        nextPage={nextPageFx}
                        prevPage={prevPageFx}
                        setInvokeFilter={setInvokeFilter}
                        setFilterData={setFilterData}
                        refreshPage={refreshPage}
                        setSearchDataFx={setSearchDataFx}
                        page_type={tabType}
                        tableLoader={job_loading}
                        tableStopPaging={job_stop_pagination}
                        dateFilterFn={{
                            k    : 'date',
                            disp :  fetch_data_filter
                        }}
                        searchInpTabFn={{
                            cls : 'searchInpSalesQuote',
                            txt : switchableSearchFnVal.value==='clients' ? 'Search Clients' : 
                            // switchableSearchFnVal.value==='quote' ? 'Search Quote' :
                            switchableSearchFnVal.value==='ref_code' ? 'Search Quote' :
                            'Search Job Code',
                            url : switchableSearchFnVal.value==='clients' ? 
                                `${SALES_JOB_SITE_SEARCH}${tabType}/?limit=20&&page=1` 
                                : `${SALES_JOB_SITE_JOBCODE_SEARCH}${tabType}/?limit=20&&page=1${switchableSearchFnVal.value==='quote' ?
                                 '&search_type=quote' : switchableSearchFnVal.value==='ref_code' ? '&search_type=ref_code' : ''}`,
                            key : 'key',
                            disp: (res) => ({ type: 'JOB_LIST_CLIENT_SEARCH', jobs: res, page: 1 }),
                            methodType : switchableSearchFnVal.value==='clients' ? 'post' : 'get'
                        }}
                        sortByFilterFn={{
                            k : 'client_id',
                            name : 'client',
                            disp:  fetch_data_filter
                        }}
                        switchableSearchObj={{
                            switchableSearchFn : switchableSearchFn,
                            switchableSearchFnVal : switchableSearchFnVal,
                            options : [
                                { label : 'Clients', value : 'clients' },
                                { label : 'Job Code', value : 'job' },
                                // { label : 'Quote Code', value : 'quote' },
                                { label : 'Quote No', value : 'ref_code' }
                            ],
                            
                        }}
                    >
                        <table className="sales-table"   style={{ position: 'relative', height: 'auto' }}>
                            <thead >
                                <tr className="table-head">
                                    <td>Job Code</td>
                                    {/* <td>Client Ref. Code</td> */}
                                    <td>Quote No</td>
                                    <td>Date</td>
                                    {/* <td>Job Type</td> */}
                                    <td>Client Id</td>
                                    {/* <td>Company Name</td> */}
                                    <td>Client Name</td>
                                    {/* <td>Client Type</td> */}
                                    <td>Quoted by</td>
                                    <td>Quote Amt</td>
                                    {/* <td>Status</td> */}
                                    <td>Details</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    job_list && job_list.length === 0 ?
                                    !tableLoader &&
                                        <div style={{ display: 'flex', height: '100%', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }} >
                                            <NoDatasScreen />
                                        </div>
                                        :

                                        job_list && job_list.map((item, key) => {
                                            const { id, 
                                                //date,
                                                 client_id, client, client_name, quoted_by, amount,
                                                //paid_status,client_type,
                                                // quote, 
                                                ready_for_schedule, is_job_through_reccuuring, parent_job_id, reoccurring } = item
                                            return (
                                                <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'whitesmoke' : 'transparent', position:'relative' }}>
                                                    <Tooltip //open={hoverVal === item.id}
                                                        title={`Next Reoccur on ${moment(item.created_date_time).add(item.frequency!==null || item.frequency!=='' ? item.frequency : 0, 'weeks').format('DD-MM-YYYY')}`}
                                                        arrow={true} placement={'right'}>
                                                        <td>{'ENV00' + id}
                                                            {parent_job_id === null && reoccurring ?

                                                                <span
                                                                    // onMouseEnter={() => { setHoverVal(item.id) }}
                                                                    // onMouseLeave={() => { setHoverVal(null) }}

                                                                    className="indicatedDot"></span>

                                                                : null}
                                                        </td>
                                                    </Tooltip>
                                                    {/* <td>{item.job_card_code}</td> */}
                                                    <td>QUOTE{item.job_card_code}</td>
                                                    {/* <td>QUOTE{item.quote}</td> */}
                                                    <td>{ item.sales_approve_date && item.sales_approve_date!=='' ? moment(item.sales_approve_date).format('DD-MM-YYYY') : '-'}</td>
                                                    {/* <td>{job_type}</td> */}
                                                    <td style={{ width:'10%' }}>{client_id || client}</td>
                                                    {/* <td>{company}</td> */}
                                                    <td style={{ wordWrap : 'break-word', width:'20%' }}>{client_name}</td>
                                                    {/* <td>{client_type}</td> */}
                                                    <td style={{ wordWrap : 'break-word', width:'15%' }}>{is_job_through_reccuuring ? 'Reoccured' : quoted_by}</td>
                                                    <td>{`$ `+amount}</td>
                                                    {/* <td>{paid_status}</td>
                                                    //COMMENTED OUT FOR MYOB
                                                    */}
                                                    <td>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%' }}>
                                                            {
                                                                salesJobSpinner && salesJobSpinnerBtnIndex === key ? (
                                                                    <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                                ) : (
                                                                    <button onClick={() => fetchQuoteInfos(id, key)} className="viewBtn">View</button>
                                                                )
                                                            }
                                                        </div>
                                                    </td>

                                                    {statusLoader && statusLoader === key ?
                                                        <td><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', height: '100%', borderRadius: 20, border: '.1px solid grey', padding: 4, backgroundColor: 'white', marginRight: 1 }}>
                                                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                            </button>
                                                        </div>
                                                        </td>
                                                        :
                                                        
                                                            <Fragment>
                                                                {
                                                                ready_for_schedule === null ?
                                                                    <td><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                        <button onClick={() => { setStatusLoader(key); schdStatus(true, id) }}
                                                                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', height: '100%', borderRadius: 20, border: 0, padding: 4, backgroundColor: 'var(--cl_orange)', marginRight: 4, color: '#fff' }} >Accounts Team To Approve</button>
                                                                        {/* <button onClick={()=>{ setStatusLoader(true); schdStatus(false, id)}}
                                                    style={{ cursor:'pointer', width: '20%', height: '100%', borderRadius: 20, border: 0, padding: 4, backgroundColor: cl_red, color: '#fff' }}>Reject</button> */}
                                                                    </div>
                                                                    </td>
                                                                    : ready_for_schedule ?
                                                                        <td><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', height: '100%', borderRadius: 20, border: 0, padding: 4, backgroundColor: cl_green, marginRight: 1, opacity: '0.4', color: 'black' }} >Accounts Team Approved</button>
                                                                            {/* <button onClick={()=>{ setStatusLoader(true); schdStatus(false, id)}}
                                                    style={{ cursor:'pointer', width: 80, height: '100%', borderRadius: 30, border: 0, padding: 4, backgroundColor: cl_red, color: '#fff' }}>Reject</button> */}
                                                                        </div>
                                                                        </td>
                                                                        :
                                                                        <td><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                            {/* <button onClick={()=>{ setStatusLoader(true); schdStatus(true, id)}}
                                                                                style={{ cursor:'pointer', width: 80, height: '100%', borderRadius: 30, border: 0, padding: 4, backgroundColor: cl_blue, marginRight: 4, color: '#fff' }} >Accept</button> */}

                                                                            <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', height: '100%', borderRadius: 30, border: 0, padding: 4, backgroundColor: cl_red, color: '#fff', opacity: '0.4' }}>Rejected</button>
                                                                        </div>
                                                                        </td>
                                                                }
                                                            </Fragment>
                                                        // )
                                                    }



                                                </tr>
                                            )
                                        })

                                }

                                {/* {
                                            [...Array(20 - job_list.length)].map((item, key) => {
                                                return (
                                                    <tr key={key}><td></td></tr>
                                                )
                                            })
                                        } */}

                            </tbody>
                            {/* }
                            </Scrollbars> */}

                        </table>

                    </TableLayout>
                </SalesHomeList>
                <SalesExcecutieves />
            </div>
            <QuoteModal
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                jobVal={jobVal}
            />

            <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { job_list, job_list_page, job_list_filter, tableLoader, filterDataFx, job_stop_pagination, isLoading: job_loading } = state.sales
    return { job_list, job_list_page, job_list_filter, tableLoader, filterDataFx, job_stop_pagination, job_loading }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_jobs: (page_no, tab_type, filter) => dispatch(fetch_job_list_for_all(page_no, tab_type, filter)),
        fetch_data_filter: (content, type, page, page_type) => dispatch(sales_job_filter(content, type, page, page_type)),
        status_change_fx: (actVal, jobId, success) => dispatch(status_change_sales(actVal, jobId, success)),
        refresh_job_viewFx: () => dispatch(refresh_job_view()),
        refresh_page: () => dispatch(reloadPage()),
        setSearchQuery: (val) => dispatch(sales_quote_client(val)),
        fetch_search_data: (type, content, page, searchType) => dispatch(sales_jobSearch_client(type, content, page, searchType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
