import React, { useState } from 'react'
import { Edit, DeleteForever, FolderOpen, UnarchiveRounded, ArchiveRounded } from '@material-ui/icons'
import { connect } from 'react-redux'
import { __delete_vehicle,__archive_vehicle,fetch_search_car } from './common/action'
import Swal from 'sweetalert2'
import { cl_red, cl_green } from '../../../../common/Colors'
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint'
import moment from 'moment'
import Modal from '../truck/Modelfleet'
import Scrollbars from '../../../../common/ImprovedScrollBar'
import ErrorHandling from '../../../../common/ErrorHandling'
import Teamvehdelprevntmodal from '../../../../common/TeamVehDelPrevntModal'
import Preventdelete from '../common/PreventDelete'
import { Tooltip } from 'antd'
// import { CircularProgress } from '@material-ui/core'

function CarListTable({
  car_list,
  car_page,
  delete_vehicle,
  archive_vehicle,
  img_modal_action,
  fetch_cars,
  select_vehicle,
  modal_action,
  tabType,
  isLoading,
  perms,
  car_stoppagination,
  searchFnOpen,
  car_list_loader, car_list_error,
  car_search_keyword,
  fetch_search_carFX

}) {

  const [modal, setModal] = useState(false)
  const [selectedCar, setSelectedCar] = useState()
  const [selectedCarId, setSelectedCarId] = useState(null)
  // const [pageLoader, setPageLoader] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageHover, setImageHover] = useState(null)
  const [apiRes, setApiRes] = useState(null)


  let balance_empty_rpt = 0
  if (car_list && car_list.length < 8) {
    balance_empty_rpt = 8 - car_list.length
  }

  // let page_no = 0
  // if (car_page < 10) {
  //   page_no = '0' + car_page
  // } else {
  //   page_no = car_page
  // }

  const delete_btn_click = (vehicle_id) => {
    const success = (response) => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Vehicle deleted successful!',
        showConfirmButton: false,
        timer: 1500
      })
    }

    const failed = (res) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
      })
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: cl_red,
      cancelButtonColor: cl_green,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        delete_vehicle(tabType, vehicle_id, success, failed, car_page)
      }
    })
  }

  const archiveFn = (val, st=false) =>{
    const success = (response) => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Vehicle successfully ${st ? ` un-archived` : 'archived'}!`,
            showConfirmButton: false,
            timer: 1500
        })
    }

    const failed = (res) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
        })
    }

    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: cl_red,
        cancelButtonColor: cl_green,
        confirmButtonText: `Yes, ${st ? ` Un-archive ` : ` Archive `} it!`
    }).then((result) => {
        if (result.value) {
          archive_vehicle(tabType, val, st, success, failed, car_page)
        }
    })
}

  const fetch = () => {
    if (!car_list_loader) {

      if (car_search_keyword === ''){
        fetch_cars(tabType, car_page)
      }else{
        fetch_search_carFX(tabType, car_page, car_search_keyword)
      }
    }

  }
  const ScrollUp = (val) => {
    const { top } = val

    
      if (top === 1 && !car_stoppagination && !car_list_loader && car_list_error === null) {
        if (car_search_keyword === ''){
          fetch_cars(tabType, car_page + 1)
        }else{
          fetch_search_carFX(tabType, car_page + 1, car_search_keyword)
        }
      }

  }

  return (
    <div className="CarListTable">
      <div className="car-table-list">
        {/* <div className="table-details-car"> */}
        <Scrollbars style={{ height: '70vh' }} className='table-details-car' onUpdate={ScrollUp}>
          <table
            className="table-details-truck-tbl"
            style={{ position: 'relative', height: 'auto' }}
          >
            <thead>
              <tr style={{ height: 40 }}>
                <th>Image</th>
                <th>Registration</th>
                <th>Rego Due</th>
                <th>Type</th>
                <th>Year</th>
                <th>Transmission</th>
                <th>Fuel</th>
                <th>Engine Number</th>
                <th>VIN NUMBER</th>
                {/* <th>REGO DUE</th>  */}
                <th>E Tag</th>
                <th>Insurance</th>
                <th>Fuel Card</th>
                <th>FBT</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>

              {
                car_list && car_list.length === 0 ?
                  ((!car_list_loader && car_list_error === null) &&
                    <tr><td colSpan='14' >No Vehicles Created</td></tr>) : null
              }
              {
                car_list.map((item, key) => {
                  const { id, registration, types, image1, year, transmission, fuel, engine_numbers, vin_number, due_rego, e_tag, insurance, fuel_card, fbt } = item
                  return (
                    <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'transparent' }}>
                      <td style={{ position: 'relative', height: '6vh', objectFit: 'cover', maxWidth: "7vh" }}
                        onMouseEnter={() => setImageHover(key)}
                        onMouseLeave={() => setImageHover(null)}
                      >
                        <img style={{ height: '6vh', width: '100%' }} src={HOST_WITHOUT_SLASH + image1} alt="car" />
                        {imageHover === key ?
                          <div className="tableImageHoverTile" onClick={() => {
                            select_vehicle(item)
                            img_modal_action(true)
                          }
                          }>
                            <Edit style={{ fontSize: 18, margin: 0, padding: 0 }} />
                          </div>
                          : null
                        }
                      </td>
                      <td>{registration}</td>
                      <td>{moment(due_rego).isValid() ? moment(due_rego).format('DD-MM-YYYY') : "Date Not Provided"}</td>
                      <td>{types}</td>
                      <td>{year}</td>
                      <td>{transmission}</td>
                      <td>{fuel}</td>
                      <td>{engine_numbers}</td>
                      <td>{vin_number}</td>
                      {/* <td>{due_rego}</td>  */}
                      <td>{e_tag}</td>
                      <td>{insurance}</td>
                      <td>{fuel_card}</td>
                      <td>{fbt}</td>
                      <td>
                        <div className="table-action-field">
                          {/* <div className="table-action-btn">
                                <SettingsInputAntenna style={{ fontSize: 18,  margin: 0, padding: 0 }} />
                            </div> */}
                          <div className="table-action-btn"
                            onClick={() => {
                              setSelectedCar(registration)
                              setSelectedCarId(id)
                              setModal(true)
                            }}
                          >
                            <FolderOpen style={{ fontSize: 20, margin: 0, padding: 0 }} />
                          </div>
                          {perms.vehicle.edit ?
                          <Tooltip title="Edit vehicle">
                            <div
                              onClick={() => {
                                select_vehicle(item)
                                modal_action(true)
                              }}
                              className="table-action-btn">
                              <Edit style={{ fontSize: 20, margin: 0, padding: 0 }} />
                            </div>
                          </Tooltip>
                             : null}
                          {perms.vehicle.delete ?
                          <>
                          <Tooltip title="Delete Vehicle">
                            <div onClick={() =>{if(tabType==='waste'){
                              Preventdelete(id,  setApiRes, setDeleteModal, function(){
                                delete_btn_click(id)}, 
                           function(){
                               Swal.fire({
                                   icon: 'error',
                                   title: 'Oops...',
                                   text: 'Something went ASDFwrong!'
                               })
                           })
                          }
                          else{
                              delete_btn_click(id)
                          }}} className="table-action-btn">
                              <DeleteForever style={{ fontSize: 20, margin: 0, padding: 0 }} />
                            </div>
                          </Tooltip>
                            {
                                  item?.is_archived ?
                              <Tooltip title="Archive Vehicle">
                                  <div onClick={() =>{
                                      archiveFn(id, true)
                                      }} className="table-action-btn">
                                      <UnarchiveRounded style={{ fontSize: 20, margin: 0, padding: 0, color: 'var(--cl_red)' }} />
                                  </div>
                              </Tooltip>
                                  :
                              <Tooltip title="Archive Vehicle">
                                  <div onClick={() =>{
                                      archiveFn(id)
                                      }} className="table-action-btn">
                                      <ArchiveRounded style={{ fontSize: 20, margin: 0, padding: 0, color: 'var(--cl_blue)' }} />
                                  </div>
                              </Tooltip>
                            }
                          </>
                             : null}
                        </div>
                      </td>
                    </tr>
                  )
                })

              }


              {(!car_list_loader && car_list_error === null) &&
                [...Array(balance_empty_rpt)].map((item, key) => {
                  return (
                    <tr key={key}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )
                })
              }
            </tbody>

            {car_list_loader && <tbody className='skeleton'>
              {
                [...Array(8)].map((_, key) => {
                  return (
                    <tr >
                      <td></td><td></td><td></td><td></td><td></td><td></td>
                      <td></td><td></td><td></td><td></td><td></td><td></td>
                      <td></td><td></td><td></td>
                    </tr>
                  )
                })
              }
            </tbody>}
            {/* <>
              {car_list_error && <tr><td colSpan='14' ><div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: '200px', justifyContent: 'center' }}>
                <span style={{ textAlign: 'center',fontSize:'1.5rem' }}>{car_list_error.text}</span>
                <button className='vechile-details-btn-add' style={{fontSize:'.8rem'}} onClick={fetch}>Retry</button>
              </div></td></tr>}

            </> */}
            {
              car_list_error && <ErrorHandling error={car_list_error} fetch={fetch} />
            }
          </table>

        </Scrollbars>
        {/* </div> */}
        {/* <div className="truck-table-back-previous-btn">
          <div className="pagination-btn-layout">
            {
              car_page > 1 ? (
                <button
                  onClick={() => fetch_cars(tabType, car_page - 1, setPageLoader)}
                  className="table-list-next-btn">PREVIOUS</button>
              ) : (
                <button className="table-list-next-btn disabled-btn">PREVIOUS</button>
              )
            }
            <div className="page-no">
              <p className="p-0 m-0">{page_no}</p>
            </div>
            {
              car_list.length === 8 ? (
                <button
                  onClick={() => fetch_cars(tabType, car_page + 1, setPageLoader)}
                  className="table-list-next-btn">NEXT</button>
              ) : (
                <button className="table-list-next-btn disabled-btn">NEXT</button>
              )
            }
          </div>
        </div> */}

      </div>
      <Modal
        modalStatus={modal}
        modalAction={setModal}
        tabType={tabType}
        vehicle_reg={selectedCar}
        vehicle_id={selectedCarId}
        vehicleType={tabType === 'pumps' ? 'utes' : 'cars'}
        perms={perms}
      />
      <Teamvehdelprevntmodal modalType={'v'} modalStatus={deleteModal} modalAction={setDeleteModal} apiStatus={apiRes}  />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { car_list, car_page, isLoading, car_stoppagination, car_list_loader, car_list_error, car_search_keyword } = state.vehicle__car
  return { car_list, car_page, isLoading, car_stoppagination, car_list_loader, car_list_error, car_search_keyword }
}

const mapDispatchToProps = (dispatch) => {
  return {
    delete_vehicle: (tabType, vehicle_id, success, failed, page) => dispatch(__delete_vehicle(tabType, vehicle_id, success, failed, page)),
    archive_vehicle: (tabType, vehicle_id, archiveStatus, success, failed, page) => dispatch(__archive_vehicle(tabType, vehicle_id,archiveStatus, success, failed, page)),
    fetch_search_carFX: (tabType, page, txt)=>dispatch(fetch_search_car(tabType, page, txt))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarListTable)

