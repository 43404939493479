import moment from "moment"

let initialState = {
    forklift_list: [],
    forklift_stoppagination: false,
    forklift_list_error: null,
    forklift_list_loader: false,
    forklift_page: 1,
    forklift_search_keyword: '',

    vehicle_modal: false,
    vehicle_img_modal: false,
    selected_forklift: {},

    forklift_maintanace_list: [],
    forklift_maintanace_stoppagination: false,
    forklift_maintanace_error: null,
    forklift_maintanace_loader: false,
    selected_forklift_maintanance: {},
    forklift_maintanance_page: 1,

    maintanace_search_qry: '',


    all_forklift_list: [],
    filterKey: 'all',

    forklift_fuel_list: [],
    forklift_fuel_error: null,
    forklift_fuel_loader: false,
    forklift_fuel_stoppagination: false,
    forklift_fuel_page: 1,

    fuel_search_qry: '',

    selected_forklift_fuel: {},

    selected_pre_inspect: {},
    edit_pre_inspObj: {},
    filtered_forklift_list: [],

    forklift_preInsp_list: [],
    pre_insp_page: 1,
    pre_insp_loader: false,
    pre_insp_error: null,
    pre_insp_stoppagination: false,

    pre_insp_search_qry: '',

    isLoading: true,
    isMntnLoading: true,
    isFuelLoading: true,
    isPreLoading: true,
}

const forklift = (state = initialState, actions) => {
    switch (actions.type) {
        case "FORKLIFT_LIST_FETCHING":
            if (actions && actions.keyword) {
                return {
                    ...state,
                    forklift_list_loader: true,
                    forklift_list_error: null,
                    forklift_search_keyword: actions.keyword,
                    forklift_list: actions.page===1 ?[]: state.forklift_list,
                    forklift_page: actions.page,
                }
            } else {
                return {
                    ...state,
                    forklift_list_loader: true,
                    forklift_list_error: null,
                    forklift_search_keyword: '',
                    forklift_list: actions.page? (actions.page===1 ? []:state.forklift_list):state.forklift_list
                }
            }

        case "FORKLIFT_LIST":
            // if ((actions.page === 1 && state.forklift_list.length === 0) || actions.page !== state.forklift_page) {
            return {
                ...state,
                forklift_page: actions.page,
                forklift_list: actions.page === 1 ? actions.forklifts : state.forklift_list.concat(actions.forklifts),
                forklift_stoppagination: actions.forklifts.length === 15 ? false : true,

                forklift_list_loader: false,
                isMntnLoading: true,
                isFuelLoading: true,
                isPreLoading: true,
            }
        // } else {
        //     return {
        //         ...state,
        //         forklift_list_loader: false,

        //     }
        // }
        case "FORKLIFT_LIST_ERROR":
            return {
                ...state,
                forklift_list_loader: actions.error.text === 'Unknown error' ? state.forklift_list_loader : false,
                forklift_list_error: actions.error
            }
        case "FORKLIFT_FILTERED_MNTN_LIST":
            let tempListMNTC = state.forklift_maintanace_list
            if(actions.page===1)
                tempListMNTC = actions.maintanace
            else if(state.truck_maintanance_page === actions.page){
                tempListMNTC.splice((15*(parseInt(actions.page)-1)), tempListMNTC.length-(15*(parseInt(actions.page)-1)), ...actions.maintanace)
            }
            else 
                tempListMNTC = state.forklift_maintanace_list.concat(actions.maintanace)

            return {
                ...state,
                forklift_maintanace_list: actions.page === 1 ? actions.maintanace : state.forklift_maintanace_list.concat(actions.maintanace),
                filterKey: actions.filterKey? actions.filterKey: state.filterKey,
                maintanace_search_qry: actions.keyword? actions.keyword: state.maintanace_search_qry,
                forklift_maintanace_loader: false,
                forklift_maintanace_error: null,
                isMntnLoading: false,
                isLoading: true,
                forklift_maintanance_page: actions.page,
                forklift_maintanace_stoppagination: actions.maintanace.length === 15 ? false : true
            }

        case "FORKLIFT_REFRESH_FILTER":
            return {
                ...state,
                filterKey: 'all',
                filtered_forklift_list: [],
                forklift_preInsp_list: [],
                pre_insp_loader: true,
            }

        // case "FORKLIFT_MAINTANACE_LIST":
        //     return {
        //         ...state,
        //         forklift_maintanace_list: actions.maintanace,
        //         forklift_maintanance_page: actions.page,
        //         isMntnLoading: false,
        //         isLoading: true,
        //     }
        case "FORKLIFT_MAINTANACE_LIST_FETCHING":
            if (actions && actions.keyword) {
                return {
                    ...state,
                    forklift_maintanace_loader: true,
                    forklift_maintanace_error: null,
                    maintanace_search_qry: actions.keyword,
                    forklift_maintanace_list: actions.page === 1 ? [] : state.forklift_maintanace_list,
                    forklift_maintanance_page: actions.page,
                }
            } else if (actions && actions.filter) {
                return {
                    ...state,
                    forklift_maintanace_loader: true,
                    forklift_maintanace_error: null,
                    forklift_maintanace_list: actions.page === 1 ? [] : state.forklift_maintanace_list,
                    filtered_truck_list: [],
                    maintanace_search_qry: '',
                    filterKey: actions.filter,
                    forklift_maintanance_page: actions.page,
                }
            } else {
                return {
                    ...state,
                    forklift_maintanace_loader: true,
                    forklift_maintanace_error: null,
                    maintanace_search_qry: '',
                    forklift_maintanace_list: actions.page? (actions.page===1 ? []:state.forklift_maintanace_list):state.forklift_maintanace_list
                }
            }
        case "FORKLIFT_MAINTANACE_LIST":
            // if ((actions.page === 1 && state.forklift_maintanace_list.length === 0) || actions.page !== state.forklift_maintanance_page) {
            return {
                ...state,
                forklift_maintanace_list: actions.page === 1 ? actions.maintanace : state.forklift_maintanace_list.concat(actions.maintanace),
                forklift_maintanance_page: actions.page,
                forklift_maintanace_stoppagination: actions.maintanace.length === 15 ? false : true,
                forklift_maintanace_loader: false,
                forklift_maintanace_error: null
            }
        // } else {
        //     return {
        //         ...state,
        //         forklift_maintanace_loader: false
        //     }
        // }
        case "FORKLIFT_MAINTANACE_LIST_FAILED":
            return {
                ...state,
                forklift_maintanace_error: actions.error,
                forklift_maintanace_loader: actions.error.text === 'Unknown error' ? state.forklift_maintanace_loader : false,
            }
        case "ALL_FORKLIFT_LIST":
            return {
                ...state,
                all_forklift_list: actions.forklifts,
                isLoading: false
            }


        case "VEHCILE_MODAL":
            return {
                ...state,
                vehicle_modal: actions.modal
            }

        case "VEHCILE_IMG_MODAL":
            return {
                ...state,
                vehicle_img_modal: actions.modal
            }

        case "SELECT_FORKLIFT":
            return {
                ...state,
                selected_forklift: actions.forklift
            }

        case "SELECT_MAINTANACE":
            return {
                ...state,
                selected_forklift_maintanance: actions.maintanace
            }

        // case "FORKLIFT_FUEL_LIST":
        //     return {
        //         ...state,
        //         forklift_fuel_list: actions.fuel,
        //         forklift_fuel_page: actions.page,
        //         isFuelLoading: false,
        //         isLoading: true,
        //     }
        case "FORKLIFT_FUEL_LIST_FETCHING":
            if (actions && actions.keyword) {
                return {
                    ...state,
                    forklift_fuel_loader: true,
                    forklift_fuel_error: null,
                    fuel_search_qry: actions.keyword,
                    forklift_fuel_list: actions.page === 1 ? [] : state.forklift_fuel_list,
                    forklift_fuel_page: actions.page,
                }
            }else if(actions && actions.filter){
                return {
                    ...state,
                    forklift_fuel_loader: true,
                    forklift_fuel_error: null,
                    forklift_fuel_list: actions.page === 1 ? [] : state.forklift_fuel_list,
                    filtered_forklift_list: [],
                    fuel_search_qry: '',
                    filterKey: actions.filter ,
                    forklift_fuel_page: actions.page,
                }
            } else {
                return {
                    ...state,
                    forklift_fuel_loader: true,
                    forklift_fuel_error: null,
                    fuel_search_qry: '',
                    forklift_fuel_list: actions.page===1 ? []:state.forklift_fuel_list
                }
            }
        case "FORKLIFT_FUEL_LIST":
            // if ((actions.page === 1 && state.forklift_fuel_list.length === 0) || actions.page !== state.forklift_fuel_page) {
            return {
                ...state,
                forklift_fuel_list: actions.page === 1 ? actions.fuel : state.forklift_fuel_list.concat(actions.fuel),
                forklift_fuel_page: actions.page,
                forklift_fuel_stoppagination: actions.fuel.length === 15 ? false : true,
                forklift_fuel_loader: false,
                forklift_fuel_error: null
            }
        // } else {
        //     return {
        //         ...state,
        //         forklift_fuel_loader: false
        //     }
        // }
        case "FORKLIFT_FUEL_LIST_ERROR":
            return {
                ...state,
                forklift_fuel_loader: actions.error.text === 'Unknown error' ? state.forklift_fuel_loader : false,
                forklift_fuel_error: actions.error
            }
        case "SELECT_FORKLIFT_FUEL":
            return {
                ...state,
                selected_forklift_fuel: actions.fuel
            }

        case "SELECTED_FORKLIFT_PRE-INSPECT":
            return {
                ...state,
                selected_pre_inspect: actions.selObj,
                edit_pre_inspObj : {
                    Vehicle                 : actions.selObj.vehicle
                    , Date_time             : actions.selObj.date_time
                    , DriverName            : actions.selObj.driver_name
                    , DriverNameStr         : actions.selObj.name
                    , Odometer              : actions.selObj.odometer       
                    , DriverSignature       : actions.selObj.odometdriver_signature       
                    , HourMeterStart        : actions.selObj.hour_meter_start       
                
                    , FitForWork            : actions.selObj.fit_for_work       
                    , ValidDrivingLicense   : actions.selObj.Valid_driving_license       
                    , AppropriatePPE        : actions.selObj.appropriate_ppe       
                
                    , EngineOilLevel        : actions.selObj.engine_oil_level       
                    , WarningSystem         : actions.selObj.warning_system       
                    , Steering              : actions.selObj.steering       
                    , SafetyEmrgStop        : actions.selObj.safety_emerg_stop       
                    , HandBreakAlram        : actions.selObj.handbreak_alarm       
                    , PTOVacPump            : actions.selObj.pto_vacpump       
                
                    , Horn                  : actions.selObj.horn
                    , RevAlarmCamera        : actions.selObj.rev_alarm_camera
                    , LightsHead            : actions.selObj.lights_head
                    , LightsTail            : actions.selObj.lights_tail
                    , LightsBeacon          : actions.selObj.light_beacons
                    , HazardLight           : actions.selObj.hazard_light
                    , HouseKeeping          : actions.selObj.house_keeping
                
                    , RimsWheels            : actions.selObj.rims_wheelnut
                    , Coolant               : actions.selObj.coolant
                    , Wheels                : actions.selObj.wheels
                    , MirrorWindows         : actions.selObj.mirror_windowscreen
                    , StructureBody         : actions.selObj.structure_bodywork
                    , Wipers                : actions.selObj.wipers
                
                    , FuelLevelPump         : actions.selObj.fuel_levelpump
                    , FuelLevelTruck        : actions.selObj.fuel_leveltruck
                    , SeatSeatbelt          : actions.selObj.seat_seatbelt
                    , ParkBreakTrailer      : actions.selObj.parkbrake_trailer
                    , FootBrake             : actions.selObj.foot_brake
                    , Electrical            : actions.selObj.electrical
                    , PinRetainer           : actions.selObj.pin_retainers
                
                    , Hoses                 : actions.selObj.hoses
                    , Fittings              : actions.selObj.fittings
                    , FirstAidKit           : actions.selObj.first_aid_kit
                    , PPE                   : actions.selObj.ppe
                    , FireExtingusre        : actions.selObj.fire_extinguisher
                    , FireExtingusreDate    : moment(actions.selObj.fire_extinguisher_date).isValid() ?
                         moment(actions.selObj.fire_extinguisher_date).format('YYYY-MM-DD') : actions.selObj.fire_extinguisher_date
                    , GardenHose            : actions.selObj.garden_hose
                    , GaticLiters           : actions.selObj.gatic_lifters
                    , BucketRag             : actions.selObj.bucket_rags
                    , SpillKit              : actions.selObj.spill_kit
                
                    , FaultReportz          : actions.selObj.reported_fault_string
                
                    , ReadyToOperate        : actions.selObj.safe_ready_to_operate
                    , ReportedFaults        : actions.selObj.reported_faults
                    , ReviewdForm           : actions.selObj.reviewed_form
                
                    , Corrected             : actions.selObj.corrected
                    , ScheduledForRepair    : actions.selObj.scheduled_for_repair
                    , NoAction              : actions.selObj.no_action
                    , DoNotAffectSafeOperation    : actions.selObj.do_not_affect_safe_operation
                
                }
            }


        case "FORKLIFT_FILTERED_FUEL_LIST":
            return {
                ...state,
                forklift_fuel_list: actions.page === 1 ? actions.forklifts : state.forklift_fuel_list.concat(actions.forklifts),
                filterKey: actions.filterKey? actions.filterKey: state.filterKey,
                fuel_search_qry: actions.keyword? actions.keyword: state.fuel_search_qry,
                forklift_fuel_loader: false,
                forklift_fuel_error: null,
                isLoading: false,
                forklift_fuel_page: actions.page,
                forklift_fuel_stoppagination: actions.forklifts.length === 15 ? false : true
            }
        // case "FETCH_FORKLIFT_PREINSP_LIST":
        //     return {
        //         ...state,
        //         forklift_preInsp_list: actions.trucks,
        //         pre_insp_page: actions.page,
        //         isPreLoading: false

        //     }
        case "FORKLIFT_FILTERED_LIST":
            return {
                ...state,
                forklift_preInsp_list: actions.page === 1 ? actions.forklifts : state.forklift_preInsp_list.concat(actions.forklifts),
                isPreLoading: false,
                pre_insp_loader: false,
                pre_insp_error: null,
                filterKey: actions.filterKey? actions.filterKey: state.filterKey,
                pre_insp_search_qry: actions.keyword? actions.keyword: state.pre_insp_search_qry,
                isLoading: true,
                pre_insp_page: actions.page,
                pre_insp_stoppagination: actions.forklifts.length === 15 ? false : true
            }
        case "FETCH_FORKLIFT_PREINSP_LIST":
            if (actions && actions.keyword) {
                return {
                    ...state,
                    pre_insp_loader: true,
                    pre_insp_error: null,
                    pre_insp_search_qry: actions.keyword,
                    forklift_preInsp_list: actions.page === 1 ? [] : state.forklift_preInsp_list,
                    pre_insp_page: actions.page,
                }
            }else if(actions && actions.filter){
                return {
                    ...state,
                    pre_insp_loader: true,
                    pre_insp_error: null,
                    forklift_preInsp_list: actions.page ? (actions.page === 1 ? [] : state.forklift_preInsp_list) : state.forklift_preInsp_list,
                    filtered_forklift_list: [],
                    pre_insp_search_qry: '',
                    filterKey: actions.filter,
                    
                    pre_insp_page: actions.page,
                }
            } else {
                return {
                    ...state,
                    pre_insp_loader: true,
                    pre_insp_error: null,
                    pre_insp_search_qry: '',
                    forklift_preInsp_list: actions.page? (actions.page===1 ? []:state.forklift_preInsp_list):state.forklift_preInsp_list
                }
            }
        case "FORKLIFT_PREINSP_LIST_SUCCESS":
            // if ((actions.page === 1 && state.forklift_preInsp_list.length === 0) || actions.page !== state.pre_insp_page) {
            return {
                ...state,
                forklift_preInsp_list: actions.page === 1 ? actions.trucks : state.forklift_preInsp_list.concat(actions.trucks),
                pre_insp_page: actions.page,
                pre_insp_stoppagination: actions.trucks.length === 15 ? false : true,
                pre_insp_loader: false,
                pre_insp_error: null
            }
        // } else {
        //     return {
        //         ...state,
        //         pre_insp_loader: false
        //     }
        // }
        case "FORKLIFT_PREINSP_LIST_ERROR":
            return {
                ...state,
                pre_insp_loader: actions.error.text === 'Unknown error' ? state.pre_insp_loader : false,
                pre_insp_error: actions.error

            }
        case "FORKLIFT_EDIT_LIST":
            let oldData = state.forklift_list
            let oldDataIdx = oldData.findIndex(el => el.id === actions.id)
            oldData[oldDataIdx] = actions.content;
            return {
                ...state,
                forklift_list: oldData
            }




        default:
            return state

    }
}

export default forklift