import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import Scrollview from '../ImprovedScrollBar';
import { AddCircleOutlineRounded, ExpandMoreRounded, RemoveCircleRounded } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { update_curr_template } from '../../screens/invoice/common/action';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalLow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    minWidth: 120,
    maxHeight: 20,
    marginLeft: 10,
    marginTop: -8,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resize:{
      fontSize: 14,
      fontWeight: 600,
      color: 'white'
  } ,
  listModal:{
    backgroundColor: theme.palette.background.paper,
      fontSize: 14,
      fontWeight: 600,
      color: 'black',
      boxShadow: theme.shadows[5],
      overflowX: 'auto',
  },
  listHeader: {
      backgroundColor:'var(--cl_blue)',
      color: 'white'
  }
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    templateStr,
    setTemplateStr,
    typeOfWaste,
    setQuoteAmount,
    setTypeofWaste,
    setTableDataObj,
    setAddInfoList,
    addInfoList,

    jobId,
    QAmt,
    tableDataObj=null,
    reboundFn=null
}) {
    const classes = useStyles();

    let arr_initial = [
        {
            waste_description   : '',
            waste_code          : '',
            qty                 : '',
            uom                 : '',
            unit_cost_total     : '',
            additional_info     : '',
            tbHead              : true
        }
    ]

    const [tableContent, setTableContent] = useState(tableDataObj ? tableDataObj : arr_initial)
    const [inpField, setInpField] = useState('')
    const [inpDataK, setInpDataK] = useState(null)
    const [loader, setLoader] = useState(false)
    const [tbhead, setTbHead] = useState(tableDataObj ? tableDataObj[0].tbHead : true)
console.log('JOBID==>',jobId)
    // console.log('VALIDX=>',templateStr.indexOf('<!--wastetablestart-->'),templateStr.includes('<!--wastetablestart-->'),templateStr)

    const handleClose = () => {
        modalAction(false)
    };

    const handleChangeData = (el, val, datakey, k, ref) =>{
        let obj = null;
        let docVal = document?.getElementById(ref)?.value
        obj = {
            ...el,
            [datakey] : docVal
        }
        let newArr = tableContent
        newArr.splice(k, 1, obj)
        setTableContent(newArr)
    }

    const addAttachment = () => {
        let tempObj = `<!--wastetablestart-->
        <table class="c8" style="border-spacing: 0;border-collapse: collapse;margin-right: auto;width:100%;">
            <tbody>
                <tr class="c28" style="min-height: 0px;">
                    <td class="c19" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;background-color: #00b0f0 !important;border-top-color: #1155cc !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c2" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Waste Description</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;background-color: #00b0f0 !important;border-top-color: #1155cc !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c2" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Waste Code</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;background-color: #00b0f0 !important;border-top-color: #1155cc !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c2" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Qty</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;background-color: #00b0f0 !important;border-top-color: #1155cc !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c2" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">UOM</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;background-color: #00b0f0 !important;border-top-color: #1155cc !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c2" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tbhead ? 'Unit Cost' : 'Total Price'}</span></p>
                    </td>
                    <td class="c19" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;background-color: #00b0f0 !important;border-top-color: #1155cc !important;">
                        <p class="c0" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;"><span class="c2" style="color: #ffffff;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">Additional Information</span></p>
                    </td>
            
            </tr>`
        for(let i=0; i<tableContent.length; ++i){
            tempObj += `<tr class="c28" style="min-height: 0px;">
            <td class="c26" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;border-top-color: #1155cc !important;">
                 <!--wastedescriptionstart-->
                <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tableContent[i].waste_description}</span></p>
                 <!--wastedescriptionend-->
            </td>
            <td class="c26" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;border-top-color: #1155cc !important;">
                 <!--wastecodestart-->
                <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tableContent[i].waste_code}</span></p>
                 <!--wastecodeend-->
            </td>
            <td class="c26" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;border-top-color: #1155cc !important;">
                 <!--qtystart-->
                <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tableContent[i].qty}</span></p>
                 <!--qtyend-->
            </td>
            <td class="c26" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;border-top-color: #1155cc !important;">
                 <!--uomstart-->
                <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tableContent[i].uom}</span></p>
                 <!--uomend-->
            </td>
            <td class="c26" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;border-top-color: #1155cc !important;">
                 <!--unitcoststart-->
                <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tableContent[i].unit_cost_total}</span></p>
                 <!--unitcostend-->
            </td>
            <td class="c26" colspan="1" rowspan="1" style="padding: 7px;border-right-style: solid;border-top-width: 1px;border-right-width: 1px;vertical-align: middle;border-left-width: 1px;border-top-style: solid;border-left-style: solid;border-bottom-width: 1px;width: 124px;border-bottom-style: solid;border-color: white !important;border-bottom-color: #1155cc !important;border-left-color: #1155cc !important;border-right-color: #1155cc !important;border-top-color: #1155cc !important;">
                 <!--additionalinformationstart-->
                <p class="c0 c6" style="margin: 0;color: #000000;font-size: 15px;font-family:Arial, sans-serif;padding-top: 0px;padding-bottom: 0px;line-height: 1.0;text-align: left;min-height: 15px;"><span class="c5" style="color: #000000;font-weight: 700;text-decoration: none;vertical-align: baseline;font-size: 16px;font-family:Arial, sans-serif;font-style: normal;">${tableContent[i].additional_info}</span></p>
                 <!--additionalinformationend-->
            </td>
        </tr>`
        }
        
        let val = `${templateStr.substring(0,templateStr.indexOf('<!--wastetablestart-->')).replace('<!--wastetablestart-->','')}${tempObj}
            </tbody></table><!--wastetableend-->
            ${templateStr.substring(templateStr.indexOf('<!--wastetableend-->'),templateStr.length).replace('<!--wastetableend-->','')}`
        // console.log('VAL==>',templateStr)
        // console.log('VAL1=>',templateStr.substring(0,templateStr.indexOf('<!--wastetablestart-->')).replace('<!--wastetablestart-->',''))
        // console.log('VAL2=>',tempObj)
        // console.log('VAL3=>',templateStr.substring(templateStr.indexOf('<!--wastetableend-->'),templateStr.length).replace('<!--wastetableend-->',''))
        setTemplateStr(val)

        const successFx = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Price updated.`,
                showConfirmButton: false,
                timer: 1500
            })
            if(reboundFn)
            reboundFn()
            setLoader(false)
            modalAction(false)
        }
        const failedFx = () =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            setLoader(false)
        }

        update_curr_template(jobId, {
            updated_template : val,
            table_data : JSON.stringify(tableContent)
        }, successFx, failedFx)

        
    }

    const addRow = (val, obj=null) =>{
        if(val){
            let single_item = {
                waste_description   : '',
                waste_code          : '',
                qty                 : '',
                uom                 : '',
                unit_cost_total     : '',
                additional_info     : '',
                tbhead              : tbhead
            }
            setTableContent(item => [...item, single_item])
        }
        else{
            let newArr = tableContent.filter((el, key) => el!==obj)
            setTableContent(newArr)
        }
    }


    return(
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modalLow}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className="cer-cerificate-modal-layout"  style={{ overflowY:'auto' }}
        onClick={(e) =>{ e.preventDefault(); e.stopPropagation();}}>
            <div className="cer-modal-header-layout">
                <p style={{ fontSize: 16 }} className="cer-modal-main-title">Update Pricing</p>
            </div>
            {
                tableDataObj && tableDataObj?.length > 0 ?
                    !("qty" in tableDataObj[0]) ? <p style={{ color:'red' }}>This is an old Quote Template. Updation is not possible.</p>
                    :   null : null
            }
            <div className="sales-quote-editTable" >
                <Scrollview autoHide style={{ height: '45vh'}} className="salesQuoteTableScrollDiv">
                <table className="sales-template-table">
                <tr className="sales-template-tableHead">
                    <th style={{ width:'16.67%' }}>Waste Description</th>
                    <th style={{ width:'16.67%' }}>Waste Code</th>
                    <th style={{ width:'16.67%' }}>Qty</th>
                    <th style={{ width:'16.67%' }}>UOM</th>
                    <th style={{ width:'16.67%' }} onClick={()=> {
                        let currVal = tbhead
                        setTbHead(!currVal)
                        let currObj = tableContent
                        for(let i=0; i<tableContent.length; ++i)
                            currObj[i].tbHead = !currVal
                        setTableContent(currObj)
                    }}>
                        <div style={{ width:'100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                        <span style={{ paddingRight:'1%' }}>
                        {
                            tbhead ? 'Unit Cost' : 'Total Price'
                        }
                        </span>
                        <span>
                            <ExpandMoreRounded/>
                        </span>
                        </div>
                    </th>
                    <th style={{ width:'16.67%' }}>Additional Information</th>
                    <th></th>
                </tr>
                <tbody onClick={(e) =>{ e.preventDefault(); e.stopPropagation();}} id="editTableEditorModal">   
                    <>
                    {
                        tableContent && tableContent?.map((el,k)=>{
                            return(
                                <tr className="sales-template-tableRow" key={k} style={{ backgroundColor: k%2===0 ? 'rgba(0,0,255,0.1)' : 'transparent' }}>
                                    <td>
                                        <div>
                                                <textarea 
                                                    value={inpDataK===`${k}wd` ? inpField : el?.waste_description}
                                                    placeholder='Waste Description'
                                                    id={`${k}wd`}
                                                    autoFocus={true}
                                                    onFocus={(e)=>{ setInpDataK(k+'wd')
                                                        setInpField(el?.waste_description) }}
                                                    onChange={(e)=>{
                                                        setInpField(e.target.value)
                                                        handleChangeData(el, inpField, 'waste_description',k,`${k}wd`)

                                                        e.target.style.height = 'auto';
                                                        e.target.style.height = (e.target.scrollHeight) + 'px'
                                                    }}
                                                />
                                                
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                                    <textarea 
                                                        value={inpDataK===`${k}wc` ? inpField : el.waste_code} 
                                                        placeholder='Waste Code'
                                                        autoFocus={true}
                                                        id={`${k}wc`}
                                                        onFocus={(e)=>{ setInpDataK(k+'wc')
                                                            setInpField(el?.waste_code) }}
                                                        onChange={(e)=>{
                                                            setInpField(e.target.value)
                                                            handleChangeData(el, inpField, 'waste_code',k,`${k}wc`)

                                                            e.target.style.height = 'auto';
                                                            e.target.style.height = (e.target.scrollHeight) + 'px'
                                                        }}
                                                    />
                                                
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                                <textarea 
                                                value={inpDataK===`${k}qty` ? inpField : el.qty} 
                                                placeholder='Quantiy'
                                                autoFocus={true}
                                                id={`${k}qty`}
                                                onFocus={(e)=>{ setInpDataK(k+'qty')
                                                    setInpField(el?.qty) }}
                                                onChange={(e)=>{
                                                    setInpField(e.target.value)
                                                    handleChangeData(el, inpField, 'qty',k, `${k}qty`)

                                                    e.target.style.height = 'auto';
                                                        e.target.style.height = (e.target.scrollHeight) + 'px'
                                                }}
                                                />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                                
                                                <textarea value={inpDataK===`${k}uom` ? inpField : el.uom} 
                                                placeholder='UOM'
                                                autoFocus={true}
                                                id={`${k}uom`}
                                                onFocus={(e)=>{ setInpDataK(k+'uom')
                                                    setInpField(el?.uom) }}
                                                onChange={(e)=>{
                                                    setInpField(e.target.value)
                                                    handleChangeData(el, inpField, 'uom',k, `${k}uom`)

                                                    e.target.style.height = 'auto';
                                                        e.target.style.height = (e.target.scrollHeight) + 'px'
                                                }}
                                                />
                                                
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            
                                                <textarea value={inpDataK===`${k}unit` ? inpField : el.unit_cost_total} 
                                                placeholder={ tbhead ? 'Unit' : 'Total Price'}
                                                autoFocus={inpDataK===`${k}unit`}
                                                id={`${k}unit`}
                                                onFocus={(e)=>{ setInpDataK(k+'unit')
                                                    setInpField(el?.unit_cost_total) }}                                            
                                                onChange={(e)=>{
                                                    setInpField(e.target.value)
                                                    handleChangeData(el, inpField, 'unit_cost_total',k, `${k}unit`)

                                                    e.target.style.height = 'auto';
                                                        e.target.style.height = (e.target.scrollHeight) + 'px'
                                            }}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            
                                                <textarea value={inpDataK===`${k}add` ? inpField : el.additional_info} 
                                                placeholder='Additional Info'
                                                autoFocus={true}
                                                id={`${k}add`}
                                                onFocus={(e)=>{ setInpDataK(k+'add')
                                                    setInpField(el?.additional_info) }}
                                            
                                                onChange={(e)=>{
                                                    setInpField(e.target.value)
                                                    handleChangeData(el, inpField, 'additional_info',k, `${k}add`)

                                                    e.target.style.height = 'auto';
                                                    e.target.style.height = (e.target.scrollHeight) + 'px'
                                            }}/>
                                        </div>
                                    </td>
                                    {
                                        tableContent && tableContent.length>1 ?
                                        <td>
                                            <div onClick={()=> addRow(false, el) }>
                                                <RemoveCircleRounded style={{ color : 'red' }}
                                                />
                                            </div>
                                        </td>
                                        : 
                                        null
                                    }
                                </tr>)})
                            }
                        </>    
                    <tr  className="sales-template-tableRowTotal">
                        <td colSpan={2}>
                            <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                                id="addNewRowScopeOfWork"
                                style={{ width:'5vw', display:'flex', alignItems:'center', fontSize:'16px', justifyContent:'space-evenly', marginTop:'10px' }}
                                onClick={()=>{ addRow(true); if(inpField){ setInpDataK('') } } }>
                                <div><AddCircleOutlineRounded style={{ fontSize:'16px' }}/></div><div>Add</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
                </Scrollview>
            </div>
            <div style={{ display:'flex', flexDirection:'row', fontSize:'16px', alignItems:'center', justifyContent:'center', width:'100%' }}>
                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active"
                        id="updateTableModalButton"
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center' }}
                            onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setLoader(true); addAttachment(); } }
                        >
                            { loader ? <CircularProgress /> : 'Update Table'}
                        </div>

                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active"
                        id="updateTableModalButton"
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center' }}
                            onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); 
                                if(reboundFn)
                                    reboundFn()
                                modalAction(false)
                            } }
                        >
                            { loader ? <CircularProgress /> : 'Cancel'}
                        </div>
                        
                    </div>
        </div>

      </Modal>
    )

}


export default TransitionsModal;