let initialState = {
    client_list: [],
    isLoading: true,
    selected_client: undefined,
    sort: 'alpha_asc',
    ind_sort: '',
    prevJob: [],
    attachments: [],
    tablePage: 1,
    clientType: 'perm',
    clientPage: 1,
    stopPagination: false,
    singleClientLoad: true,
    typeOfWasteObj: ['load'],
    waste_type_filter: 'all',
    temp_client_count: 0,
    perm_client_count: 0,
    deleted_client_count: 0
}

const client = (state = initialState, actions) => {
    switch (actions.type) {

        case "RELOAD_DATA":
            return{
                ...state,
                isLoading: true,
                temp_client_count: 0,
                perm_client_count: 0,
                deleted_client_count : 0,
                ind_sort: '',
                sort: 'alpha_asc'
            }
        
        case "CLIENTS_TOTAL_NO":
            return{
                ...state,
                perm_client_count : actions.perm_clients_Count,
                temp_client_count : actions.temp_clients_Count,
                deleted_client_count : actions.deleted_clients_Count
            }

        case "CLIENT_LIST":
            return { 
                ...state, 
                client_list: actions.clients, 
                isLoading: false,
                clientType: actions.clientType
            }

        case "CLIENT_LIST_PAGINATED":
            let listClients = 
                actions.clientPage===1  ? 
                actions.clients : state.client_list.concat(actions.clients)
            let newPage = actions.clients ? actions.clientPage : state.clientPage
            return {
                ...state,
                singleClientLoad: true,
                client_list: listClients,
                stopPagination: actions.clients && actions.clients.length===40 ? false : true,
                clientType: actions.clientType,
                clientPage: newPage,
                isLoading: false,

            }

        case "SELECT_CLIENT":
            return { 
                ...state, 
                singleClientLoad: false,
                selected_client: actions.client,
                typeOfWasteObj : ['load']
            }

        case "CHANGE_SORT":
            return { 
                ...state, 
                sort: actions.sort
            }
        case "CHANGE_IND_SORT":
            return { 
                ...state, 
                ind_sort: actions.sort
            }

        case "RESET_CLIENT_DATA":
            let temp_list = state.client_list
            let updated_data = actions.client
            let prevData = temp_list.find(item =>item.id === actions.id)
            let index = temp_list.indexOf(prevData)
            temp_list[index] = updated_data
            console.log(updated_data)
            return { 
                ...state, 
                client_list: temp_list, 
                selected_client: updated_data
            }

        case "PREV_JOBS":
            let tabData = actions.tablePage===1 ? actions.prevJob : [...state.prevJob, ...actions.prevJob]
            return{
                ...state,
                prevJob: tabData,
                tablePage: actions.prevJob.length===0 || actions.prevJob.length<6 ? null : actions.tablePage,
                waste_type_filter: actions.wasteType===null ? 'all' : actions.wasteType
            }

        case "SITE_ATTACH":
            return {
                ...state,
                attachments : actions.attach
            }

        case 'WASTE_TYPE' :
            return {
                ...state,
                typeOfWasteObj  : actions.typeOfWaste
            }
        default:
            return state

    }
}

export default client