import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Cancel, PlayCircleOutlineRounded } from '@material-ui/icons';
import React, {useState} from 'react';
import Swal from 'sweetalert2';

export const IsVideoFileUpload = (props) =>{
    let fileSize = props && props.size ? parseInt(props.size) : 921600
    console.log('CHECKVID',props,Math.round((fileSize/1024)) > 921600)
    if(Math.round((fileSize/1024)) > 921600){
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'File too big to upload.',
            text: 'Please upload files less than 1GB',
            showConfirmButton: false,
            timer: 1500
        })
        return false
    }else 
        return true
}

const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
  
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = typeof file==='object' ? URL.createObjectURL(file) : file;
  
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
  
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  export const getThumbnailFromVideo = async (e) => {
    const thumbnail =  await generateVideoThumbnail(e);
    console.log("converted",thumbnail)
    return thumbnail
  }

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const VideoFilePreview = (props) => {

    const [modalStatus, setModalStatus] = useState(false)
    
    const classes = useStyles();
console.log('PROPS',props)
    const handleClose = () =>{
        setModalStatus(false)
    }

    return (
        <>
        <div className='videoFileTileMain'>
            {
                props && props.deleteFn ?
                <Cancel 
                    style={{ fontSize: 18 }} 
                    className="videoFileTileDel"
                    onClick={() => {
                        props.deleteFn()
                    }} 
                />
                :
                null
            }
            <div className='videoFileTile'>
                <PlayCircleOutlineRounded
                    style={{ fontSize: 32, cursor: 'pointer', zIndex:2 }} 
                    className="videoFileTilePlay"
                    onClick={() => {
                        console.log('CLICKED',props)
                        setModalStatus(true)
                    }} 
                />
                {
                    props && props.thumb ?
                    <img
                        className="videoFileTileThumb"
                        style={props.thumbStyle} 
                        src={props.thumb} 
                        alt="" 
                    />
                    :
                    <video controls={false} src={`${props.videoSrc}#t=2`} 
                    className='videoFilePreviewTileThumb'/>
                }
            </div>
            
        </div>
            <Modal
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                
                className={classes.modal}
                open={modalStatus}
                onClose={handleClose}
                closeAfterTransition
            >
               <div className='videoPlayerModalContent' style={{ overflow:'auto' }}>
                    <div style={{ width:'90%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:'1% 0' }}>
                        <h4>Video Player</h4> 
                        <span style={{ cursor: 'pointer'}} onClick={handleClose}>
                            <Cancel/>        
                        </span>
                    </div>
                    <video width="320" height="240" controls src={props.videoSrc} />
                </div>
                
            </Modal>
        </>
    );
};

export default VideoFilePreview;