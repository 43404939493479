import React, { useEffect, useState,
    // useState
 } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import List from './fuelList'
import { fetch_fuel_list, fetch_all_car_list,
    // __vehicle_modal, __select_fuel, filter_fuel_list, fuelListCSVPrint
 } from '../common/action'
import Modal from './Modal'
import Navbar from './navbar'
// import DateTimeInput from '../../../../../common/DateTimeInput'
// import { makeStyles } from '@material-ui/core/styles';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import SearchTab from '../../common/SearchTab'
// import { Search } from '@material-ui/icons'

function App({ 
        fetch_fuels, 
        fetch_all_cars,
        // vehicle_modal_action,
        // select_fuel,
        // all_car_list,
        // fetch_filter_truck,
        // filterKey, 
        tabType
    }) {

    const { path } = useRouteMatch()

    useEffect(() => {
        fetch_fuels(tabType, 1)
        fetch_all_cars(tabType)
        return () => fetch_fuels.remove
    }, [fetch_fuels, fetch_all_cars, tabType])

    // const [clearFilterDate, setClearFilterDate] = useState(false)
    // const [filterDate, setFilterDate] = useState(new Date())
    // const [invokeDate, setInvokeDate]   =   useState(false)


    
    // const useStyles = makeStyles((theme) => ({
    //     formControl: {
    //       minWidth: 120,
    //       maxHeight: 20,
    //       marginLeft: 10,
    //       marginTop: -8,
    //     },
    //     selectEmpty: {
    //       marginTop: theme.spacing(2),
    //     },
    //     resize:{
    //         fontSize: 14,
    //         fontWeight: 600,
    //         color: 'white'
    //     }
    //   }));

    // const failedFilter = () =>{
    //     fetch_fuels(tabType, 1)
    // }

    // const SortDropdown = () => {
    //     const classes = useStyles();
    //     return (
    //         <FormControl className={classes.formControl} style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-around' }}>
    //             <div>Filter</div> 
    //             <Select
    //                 disableUnderline
    //                 labelId="demo-simple-select-label"
    //                 id="demo-simple-select"
    //                 SelectDisplayProps={{ fontSize: 14 }}
    //                 value={filterKey}
    //                     className={classes.resize}
    //                     style={{ color:'black' }}
    //                     onChange={(val) => {
                            
                            
    //                         if(val.target.value!=='all'){
    //                             let postData = new FormData()
    //                             postData.append('registration',val.target.value)
    //                             postData.append('vehicle_type', tabType==='pumps' ? 'utes' : 'car')
    //                             fetch_filter_truck(tabType, null, failedFilter, postData, 'vehicle', val.target.value)
    //                             setInvokeDate(false); setClearFilterDate(false);
    //                         }else{
    //                             fetch_filter_truck(tabType, null, null, null, 'all', 'all')
    //                             fetch_fuels(tabType,1)
    //                         }
    //                     }}
    //                 >
                    
    //                 <MenuItem value={'all'}>All</MenuItem>
    //                 {   
    //                     all_car_list.map((item,key)=>{
                            
    //                         return(
    //                             <MenuItem key={key} value={item.registration}>{item.registration}</MenuItem>
    //                         )
    //                     })
    //                 }
                                        
    //                 </Select>
    //             </FormControl>
    //     )
    // }

    // const DateFilterFn = (date) =>{
    //     setFilterDate(date)
    //     let date_time = date
    //     let postData = new FormData()
    //     postData.append('date_time', date_time)
    //     postData.append('vehicle_type', tabType==='pumps' ? 'utes' : 'car')
    //     fetch_filter_truck( tabType, null, failedFilter, postData, 'day', 'all')
    //     setClearFilterDate(true)
    // }


    const AppMain = () => {
        const [pageLoader, setPageLoader] = useState(false);
        const [clearFilterDate, setClearFilterDate] = useState(false)
        const [filterFromDate, setFilterFromDate] = useState({ val : new Date(), status : false})
    const [filterToDate, setFilterToDate] = useState({ val : new Date(), status : false})
    
        const [invokeDate, setInvokeDate]   =   useState(false)
        return (
            <div className="sgr-mntnce-rprt-layout">
                <Navbar tabType={tabType} setPageLoader={setPageLoader} 
                setClearFilterDate={setClearFilterDate} clearFilterDate={clearFilterDate} setFilterFromDate={setFilterFromDate} setFilterToDate={setFilterToDate}
                filterFromDate={filterFromDate} filterToDate={filterToDate} setInvokeDate={setInvokeDate} invokeDate={invokeDate}/>
                <List //clearFilterDate={clearFilterDate} setClearFilterDate={setClearFilterDate}
                 tabType={tabType} pageLoader={pageLoader} setPageLoader={setPageLoader}
                 setClearFilterDate={setClearFilterDate} clearFilterDate={clearFilterDate} setFilterFromDate={setFilterFromDate} setFilterToDate={setFilterToDate}
                filterFromDate={filterFromDate} filterToDate={filterToDate} setInvokeDate={setInvokeDate} invokeDate={invokeDate}
                //filterDate={filterDate} setFilterDate={setFilterDate} invokeDate={invokeDate} setInvokeDate={setInvokeDate}
                />
                <Modal tabType={tabType}/>
            </div>
        )
    }

    return (
        <Switch>
            <Route path={path} component={AppMain} />
        </Switch>
    )
}

// const mapStateToProps = (state) => {
//     const { car_fuel_list, car_fuel_page, all_car_list, filterKey } = state.vehicle__car
//     return { car_fuel_list, car_fuel_page, all_car_list, filterKey }
// }

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_fuels: (tabType, page, callBk=null) => dispatch(fetch_fuel_list(tabType, page, callBk)),
        fetch_all_cars: (tabType) => dispatch(fetch_all_car_list(tabType)),
        // vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        // select_fuel: (fuel) => dispatch(__select_fuel(fuel)),
        // fetch_filter_truck: (tabType, success, failed, data, type, id, page) => dispatch(filter_fuel_list(tabType, success, failed, data, type, id, page))
      }
}

export default connect(null, mapDispatchToProps) (App)

