import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { select_employee, team_dangerous_data, team_folders_info } from '../action'

function SingleMember({ data, select_employee_data, team_folders, team_dangerous_data }) {
    const { dp_thumbnail, name, designation } = data
    // let dp_image = 'https://deep.envirowasteadmin.com.au/' + dp
    let dp_image = dp_thumbnail ? dp_thumbnail : data?.dp
    return (
        <Link 
            to="/team/detail" 
            onClick={() =>{ team_dangerous_data(data.id); team_folders(data.id); select_employee_data(data.id); }} 
            className="team-single-layout"
        >
            <div className="team-img-layout">
                <img className="team-img" src={dp_image} alt="" />
            </div>
            <div className="team-titles">
                <p className="team-name">{name}</p>
                <p className="team-designation">{designation}</p>
            </div>
        </Link>
    )
}

const mapStateProps = (state) => {
    const { team } = state
    return {team}
}

const mapDispatchToProps = (dispatch) => {
    return {
        select_employee_data: (employee) => dispatch(select_employee(employee)),
        team_folders: (id) => dispatch(team_folders_info(id)),
        team_dangerous_data: (id) => dispatch(team_dangerous_data(id, null, null, null, null))
      }
}

export default connect(mapStateProps, mapDispatchToProps)(SingleMember)


