import React from "react"
import {
  GoogleMap,
  useLoadScript,
  Marker
} from "@react-google-maps/api";

import { GOOGLE_API_KEY, HOME_LATITUDE, HOME_LONGITUDE } from "../../../../../store/config";

const libraries = ["places"];
const mapContainerStyle = {
  height: '100%',
  width: '100%',
  // width: '300px',
  // height: '300px'
};

const options = {
  disableDefaultUI: true,
};


export default function GoogleMapLayout({ modalAction, markers }) {

  let center = { lat: HOME_LATITUDE, lng: HOME_LONGITUDE };
  let marker = markers
  // { lat: HOME_LATITUDE, lng: HOME_LONGITUDE }

  // const { selected_client } = values.store
  // if(selected_client.location_latitude && selected_client.location_logitude) {
  //   let lat_val = parseFloat(selected_client.location_latitude)
  //   let long_val = parseFloat(selected_client.location_logitude)
  //   center = { lat: lat_val, lng: long_val }
  // }


// console.log(markers)
  if (markers.length > 0) {
    if(markers[0].lat !== null){
      if(!(Number.isNaN(markers[0].lat))){
        center = markers[0]
      }else{
        marker= [center]
      }
    }else{
      marker= [center]
    }
  }else{
    marker= [center]
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries,
  });
  return (
    loadError? 'Error': 
    !isLoaded? 'Loading... ':
      <div onClick={() => modalAction(true)} style={{ height: '100%', borderRadius: 20 }}>

        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          zoom={11}
          center={center}
          options={options}
          onClick={() => modalAction(true)}
        >
              <Marker position={{ lat: marker[0].lat, lng: marker[0].lng }} />
        </GoogleMap>
      </div>
  );
}
