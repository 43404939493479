import { Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    
  modalLow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function JobCardTypeModal({  modalStatus, modalAction }) {
    const classes = useStyles();
    const btnArr = ['Standard', 'Disposal', 'Packaged Waste', 'Product Destruction', 'Liquid Waste', 'Grease Trap']
  return (
    <Modal
        className={classes.modal}
        open={modalStatus}
    >
      <div className='jobCardTypeModalBox'>
        <div className='jobCardTypeModalBoxTitle'>Select Job Card Type</div>
        <div className='jobCardTypeModalBoxContent'>
          {
            btnArr?.map((el, k) =>{
              return(
                <div className='jobCardTypeModalBoxDiv' 
                  onClick={()=>{
                    modalAction(k)
                  }}>
                  {el}
                </div>
              )
            })
          }
        </div>
      </div>
    </Modal>
  )
}
