import React from 'react';
import { ArrowForwardIos, Edit, DeleteForever, ExpandMore } from '@material-ui/icons';
import moment from 'moment';
// import MaintananceModal from './MaintanceModal'
// import Swal from 'sweetalert2'
// import { cl_red, cl_green } from '../../Common/Colors'
// import { delete_request } from '../../../Store/api'

function Fleet() {
    
    const truck_maintanace_list = [];
    const truck_maintanance_page = 1

    let balance_row = 0
    if(truck_maintanace_list.length < 8) {
        balance_row = 8 - truck_maintanace_list.length
    }
    

    let page_no = 0
    if(truck_maintanance_page < 10) {
        page_no = '0' + truck_maintanance_page
    } else {
        page_no = truck_maintanance_page
    }

    // const delete_btn_click = (vehicle_id) => {
    //     const success = (response) => {
    //         console.log(response)
    //         get_vehicle_maintance_list('truck', truck_maintanance_page)
    //         Swal.fire({
    //             position: 'top-end',
    //             icon: 'success',
    //             title: 'Maintanance deleted successful!',
    //             showConfirmButton: false,
    //             timer: 1500
    //         })
    //     }

    //     const failed = (error) => {
    //         console.log(error)
    //         console.log(error.response)
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Something went wrong!'
    //         })
    //     }
    //     let endpoint = `v1/vehicles/maintenance/report/truck/delete/${vehicle_id}/`

    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: cl_red,
    //         cancelButtonColor: cl_green,
    //         confirmButtonText: 'Yes, delete it!'
    //       }).then((result) => {
    //         if (result.value) {
    //             delete_request(endpoint, success, failed)
    //         }
    //       })
    // }
    return (
        <div className="sgr-mntnce-rprt-layout">
            <div className="sgr-fleet-rprt-topbar">
                <div className="sgr-fleet-titles">
                    <p>Registration Inspection Booking</p>
                    <p>HVIS Inspection Booking - Wetherill Park</p>
                </div>

                <div className="sgr-fleet-sortby">
                    <p>SORT BY</p>
                    <ExpandMore />
                </div>
                
            </div>

            

            <div className="TruckListTable">
                <div className="truck-maintance-tbl-list">
                    <div className="table-details-truck">
                        <table className="table-details-truck-tbl"> 
                            
                            <tbody>
                            <tr className="heading-truck-table"> 
                                <th>Registration</th> 
                                <th>Make</th> 
                                <th>REGO Due</th> 
                                <th>Location</th> 
                                <th>Contact</th> 
                                <th>RMS Booking By</th> 
                                <th>Booked Date/Time</th> 
                                <th>Completed</th> 
                                <th>Next Due</th>
                                <th>Actions</th> 
                            </tr> 
                            <tr >
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                                <td className="add-new"></td>
                            </tr>
                                

                        {
                            truck_maintanace_list.map((item, key) => {
                                const { registration, description, invoice_date, service_date, ometer, invoice_number, service_provided, hours, l_cost } = item
                                return (
                                    <tr key={key}> 
                                        <td>{registration}</td> 
                                        <td>{description}</td> 
                                        <td>{moment(invoice_date).format('DD-MM-YYYY')}</td> 
                                        <td>{moment(service_date).format('DD-MM-YYYY')}</td> 
                                        <td>{ ometer}</td> 
                                        <td>{invoice_number}</td>
                                        <td>{service_provided}</td> 
                                        <td>{hours}</td> 
                                        <td>{l_cost}</td> 
                                        
                                        <td>
                                            <div className="table-action-field">
                                                <div className="table-action-btn">
                                                    <Edit style={{ fontSize: 18,  margin: 0, padding: 0 }} />
                                                </div>
                                                <div className="table-action-btn">
                                                    <DeleteForever style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr> 
                                )
                            })
                        }

                        {
                            [...Array(balance_row)].map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )
                            })
                        }

                            </tbody>
                            
                        </table>
                    </div> 
                    <div className="truck-table-back-previous-btn">
                        <div className="pagination-btn-layout">
                        {
                        truck_maintanance_page > 1 ? (
                            <button 
                                // onClick={() => vehicle_maintance_prevpage('truck')} 
                                className="table-list-next-btn">PREVIOUS</button>
                        ) : (
                            <button className="table-list-next-btn disabled-btn">PREVIOUS</button>
                        )
                    }
                    <div className="page-no">
                        <p className="p-0 m-0">{page_no}</p>
                    </div>
                    {
                        truck_maintanace_list.length === 8 ? (
                            <button 
                                // onClick={() => vehicle_maintance_nexpage('truck')} 
                                className="table-list-next-btn">NEXT</button>
                        ) : (
                            <button className="table-list-next-btn disabled-btn">NEXT</button>
                        )
                    }
                           </div>
                    </div>
                    <div className="sgr-maintanace-export-btn-layout">
                        <button className="table-list-next-btn2">EXPORT REPORT</button>
                        <button className="table-list-next-btn2">GO TO REPORTS <ArrowForwardIos style={{ fontSize: 14 }} /> </button>
                    </div>
                </div>
            </div>
            {/* <MaintananceModal modalStatus={truck_inner_modal} modalAction={truck_inner_modal_status} values={values}/> */}
        </div>

    )
}

export default Fleet

