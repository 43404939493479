import React from 'react'
import { Link } from 'react-router-dom'
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint'

function SingleMember({ data, select_client, tabType }) {
    const { client_name, site_address, dp_thumbnail, invoice_purchase_no } = data
    let dp_image = HOST_WITHOUT_SLASH + dp_thumbnail
    const cropFileName = (str, len=10) =>{
        let lastIdx = str ? str.length : null
        if(lastIdx)
            return lastIdx > len*2 ? `${str.substr(0,len)}...${str.substr(str.length-3, str.length)}` : str 
        else 
            return 'Untitled'
    }

    return (
        <Link to="/client/detail" onClick={() => select_client(data)} className="single-member">
            <div className="member-thumbnail-layout">
                <img className="member-thumb-img" src={dp_image} alt="enviro employee" />
            </div>
            <div className="member-detail-layout">
                <div className="member-detail-head">
                    { tabType==='pumps' ?  <p>Order No</p> : null}
                    <p>Site Name</p>
                    <p>Site Address</p>
                </div>
                <div className="member-detail-data">
                    { tabType==='pumps' ?  <p>{cropFileName(invoice_purchase_no)}</p> : null }
                    <p>{cropFileName(client_name)}</p>
                    <p>{cropFileName(site_address, 100)}</p>
                </div>
            </div>
        </Link>
    )
}

export default SingleMember
