import { HOST } from "./endpoint"

export const GOOGLE_API_KEY             = 
'AIzaSyDZg6iJJMtwscCGlu5pRrohCD9K1Vqgdas'
// 'AIzaSyDg50OZZ_O8KwNg0aS3MIx2e0hdXprhEXU'
export const HOME_LATITUDE              = -33.8688
export const HOME_LONGITUDE             = 151.2093
export const NUMBER_VALIDATION_REGEX    = /^[0-9\b]+$/;
export const PHONE_NO_VALIDATION        = /^\+?\d+$/;
export const LETTERS_VALIDATION         = /^[a-zA-Z]*$/;
// export const PRICE_VALIDATION           = /[(0-9)+.?(0-9)*]+/igm;
export const PRICE_VALIDATION           = /^\d{0,}(\.[0-9]{0,4})?$/
export const PRICE_VALIDATION2           = /^\d{0,}(\.[0-9]{0,2})?$/
export const ERROR_CATCHER_SWITCH       = true    // true => Error boundary ON, false => Error boundary OFF
export const STORAGE_KEY_VALS           = HOST.includes('staging') ? 
    { tk : 'EnvDevToken', logStatus : 'EnvDevLoggedIn', refTk : 'EnvDevRefToken' } :
    { tk : 'EnvToken', logStatus : 'EnvLoggedIn', refTk : 'EnvRefToken' }
export const roundNumTo2 = (v) =>{
    return Math.round((v+Number.EPSILON)*100)/100
}