let initialState = {
    quote_mainpage_content: '',
    quote_date : new Date(),
    quote_page : 1,
    quote_amount : 0,
    quote_table_obj : null
}

const mainQuote = (state = initialState, actions) => {

    switch (actions.type) {
        
        case "SET_MAIN_CONTENT_QUOTE":
            console.log('done=>',actions.content)
            return {
                ...state,
                quote_mainpage_content: actions.content,
                quote_date : new Date()
            }

        case "SET_MAIN_QUOTE_AMOUNT":
            return {
                ...state,
                quote_amount: actions.amount,
            }

        case "SET_MAIN_QUOTE_TABLE_OBJ":
            return {
                ...state,
                quote_table_obj: actions.tableObj
            }

        default:
            return state

    }
}

export default mainQuote