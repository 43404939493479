import React, { useEffect, useState } from 'react'
import Sidebar from '../../common/sidebar'
import Header from './components/header'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import './components/styles.css'
import WasteApp from './waste'
import PumpApp from './pumps'
import HillsApp from './hills'
import DestructionApp from './destruction'
import AllTabApp from './all_tab'
// import DevModal from '../../common/developerMessage'
import { profile, profile_permissions } from '../login/action'
import { connect } from 'react-redux'
import { fetch_home_weather } from './action'
import { CircularProgress } from '@material-ui/core'
import { PUSH_NOTIFICATION_DELETE } from '../../store/endpoint'
import Api from '../../store/api'
import EnviroLogoLoader from '../../assets/Images/eniviro_logo.gif'
import { STORAGE_KEY_VALS } from '../../store/config'


function App({ fetch_profile_api, fetch_profile_permission_api, fetch_home_weather_api, permissions, fcm_token, user_logout}) {
    const { path } = useRouteMatch()    
    // const {pathname} = useLocation()
    // const [modalOpen, setModalOpen] = useState(true)
    // console.log('PERMIS',permissions)

    const [reloadLogin, setReloadLogin] = useState(false)

    useEffect(() => {
        fetch_profile_api()
        fetch_profile_permission_api()
        fetch_home_weather_api()
    }, [fetch_profile_api, fetch_profile_permission_api, fetch_home_weather_api])


    const deleteFCMToken = () => {
        let data = new FormData()
        console.log(fcm_token)
        data.append('keys', fcm_token)
  
        const deleteCredentials = () => {
          localStorage.clear()
          window.location.reload()
          setReloadLogin(true)
        }
        let base_url = `${PUSH_NOTIFICATION_DELETE}${fcm_token}/`
        Api('delete', base_url, '', deleteCredentials, deleteCredentials)    
      }

    const checkLocalStorage = () =>{
        return localStorage.getItem(STORAGE_KEY_VALS.tk) && localStorage.getItem(STORAGE_KEY_VALS.refTk)
    }
  
    if(reloadLogin)
        return <Redirect to={'/login'} />
    return (
        permissions ?
        typeof permissions.web_app_login_access!=='boolean' && checkLocalStorage() ? 
        <div className="App" style={{ backgroundColor:'whitesmoke' }} >
        <div className="main-layout blurEffct" style={{ position:'relative', pointerEvents: 'none' }}></div>
        <div className="permissionInfoModalBg">
            <div className="permissionInfoModal">
                <div className="permissionInfoModalTitle">
                    Issue in Refreshing!
                </div>
                <div className="permissionInfoModalContent">
                    <div>
                        Oops... It seems like it's been too long since you logged-in.
                    </div>
                    <div className="permissionInfoModalBtn" style={{ width:"60%" }} onClick={() =>  { deleteFCMToken() }}>
                        Please Login Again
                    </div>
                </div>
            </div>
        </div>
    </div>
        :
        permissions && permissions.web_app_login_access ?
        <div className="App">
            <Sidebar />
            <div className="main-layout" style={{ position:'relative',
                    pointerEvents: permissions && permissions.web_app_login_access ? 'all' : 'none' }}>
                <Header />
                {/* <DevModal modalUrl={modalOpen} modalAction={setModalOpen}/> */}
                {   
            permissions ?                
                            <Switch>
                                <Route path={path + '/waste'} component={WasteApp} />
                                <Route path={path + '/hills'} component={HillsApp} />
                                <Route path={path + '/pump'} component={PumpApp} />
                                <Route path={path + '/destruction'} component={DestructionApp} />
                                <Route path={path + '/all'} component={AllTabApp} />
                                {
                                    permissions && permissions.home.waste ?
                                        <Redirect from={path} to={path +'/waste'}/>    :
                                    permissions && permissions.home.pumps ?
                                        <Redirect from={path} to={path +'/pump'}/>     :
                                    permissions && permissions.home.hills ?
                                        <Redirect from={path} to={path + '/hills'}/>    :
                                        <Redirect from={path} to={path + '/destruction'}/>
                                }

                            </Switch>
                            
                :
                <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', position:'absolute', top:'50%'}}>
                    <CircularProgress />
                </div>
                }
            </div>
            
        </div>
        :
        <div className="App" style={{ backgroundColor:'whitesmoke' }} >
            <div className="main-layout blurEffct" style={{ position:'relative', pointerEvents: 'none' }}></div>
            <div className="permissionInfoModalBg">
                <div className="permissionInfoModal">
                    <div className="permissionInfoModalTitle">
                        Access Denied!
                    </div>
                    <div className="permissionInfoModalContent">
                        <div>
                            Oops... It seems like you don't have the permission to access Enviro Web Platform. Please continue using the mobile app.
                        </div>
                        <div className="permissionInfoModalBtn"  onClick={() =>  { user_logout(); deleteFCMToken(); }}>
                            OK
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :

        <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:'100%', position:'absolute', height:'100%'}}>
            <img src={EnviroLogoLoader} alt="Logo_loader" style={{ width:'20%' }} />
        </div>
    )
}

const mapStateToProps = (state) => {
    const { permissions } = state.userLogin
    return { permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_profile_api: () => dispatch(profile()),
        fetch_profile_permission_api: () => dispatch(profile_permissions()),
        fetch_home_weather_api: () => dispatch(fetch_home_weather()),
        user_logout: () => dispatch({ type: "SIGNOUT" }),
        clear_fcm: () => dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (App)
