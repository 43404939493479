// import { ArrowBack, ArrowForward } from '@material-ui/icons'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetch_archive_list, fetch_notify_list, read_notify } from '../common/action'
import CreateModal from './create_notify_news'
// import Tooltip from '@material-ui/core/Tooltip';
// import {HOST} from '../../../store/endpoint'
import NoDatasScreen from '../../../common/no_datas'
import Scrollbars from '../../../common/ImprovedScrollBar'
import { CircularProgress } from '@material-ui/core'
import { SearchInputTab } from '../../../common/SearchInputTab'
import { OHS_NOTIFY_LIST } from '../../../store/endpoint'
import { Search } from '@material-ui/icons'

// import { makeStyles } from '@material-ui/core/styles';

function NotificationsList({ fetch_notify, notification_list, notification_page, pageNotifyContent, stopPagingNotify, notificationLoader, read_notify_fx, funCallBk=null, permissions, fetch_archive,
    openArch,
    notification_arch_list, arch_page, pageArchContent, archLoader, stopPagingArch }) {

    const [createModal, setCreateModal] = useState(false)
    const [pageNo, setPageNo]           = useState(1)
    const [archPagNo, setArchPageNo]    = useState(1)
    const [modalType, setModalType]     = useState("")
    const [dataObj, setDataObj]         = useState({ id:'', title:'', description:'', fileName:'', mem:[], editStatus:false })

    const [openSearch, setOpenSearch]   = useState(false)

    useEffect(() => {
        if(!openArch)
            fetch_notify(pageNo)
        else
            fetch_archive(archPagNo)

        // return () =>{ fetch_notify(); fetch_archive(); }
    }, [fetch_notify, pageNo, archPagNo, fetch_archive, openArch])

    const notifyStatus = (newsId, val) =>{
        read_notify_fx(pageNo, newsId, !val)
        // fetch_notify(pageNo)
    }

    
        
        // const useStyles = makeStyles((theme) => ({
            
        //     customWidth: {
        //       maxWidth: 500,
        //     },
            
        //   }));

    const cropDesc = (str) =>{
        if(str)
            return str.length>200 ? `${str.substring(0,100)}...` : str;
        else 
            return 'Untitled'
    }

    const ScrollUp = (val) =>{
        const {top} = val
        
        if((top === 1 && !stopPagingNotify && ((pageNo===notification_page) || (notification_page===1 && pageNo!==1))) && !openArch){
            setPageNo(notification_page === 1 ? 2 : pageNo+1)
            
        }
        if((top === 1 && !stopPagingArch && ((archPagNo===arch_page) || (arch_page===1 && archPagNo!==1))) && openArch){
            setArchPageNo(arch_page === 1 ? 2 : archPagNo+1)
            
        }
         console.log('SCROLL','top:',top ,'stop:',stopPagingNotify,'page',pageNo ,'NOTIF',notification_page)
    }

    return (
        !openArch ?
        <div className="ohs-box-layout">
            <div className="ohs-box-header">
                <p>Notification</p>
                <div className="ohs-pagination-layout">
                    {   permissions.ohs.add ?
                    <button className="ohs-pagi-add-btn"  onClick={()=> {
                        setCreateModal(true); setModalType("Add Notification"); setDataObj(null);
                        }} >Add New</button> : null}
                    
                    {/* <div  className={ notification_page===1 ? "ohs-pagi-b":"ohs-pagi-a"} 
                    onClick={()=>{ if(notification_page!==1) { setPageNo(pageNo-1); } }}>
                        <ArrowBack style={{ fontSize: 16 }} />
                    </div> */}
                    {/* <button className="ohs-pagi-add-btn shdl-pagi-m-b" onClick={()=>{ fetch_archive(1); setOpenArch(true); }}
                     style={{ paddingLeft: '1px', paddingt: '1px' }}>
                        <p>Archive</p>
                    </button> */}
                    {/* <div className={ notification_list.length<8  ? "ohs-pagi-b":"ohs-pagi-a"} onClick={()=>{ if(notification_page!==pageNo){ setPageNo(pageNo-1) } else{ setPageNo(pageNo+1) } }}>
                        <ArrowForward style={{ fontSize: 16 }} />
                    </div> */}
                    
                </div>
            </div>
            
            {   notificationLoader ?
                <div style={{ position:'absolute', top:'50%', left:'50%' }}>
                    <CircularProgress />
                </div>
                : 
                notification_list.length > 0 ? (
                    <Scrollbars style={{ height: '40vh' }}
                        onScrollFrame={ScrollUp}
                    >
                    <div className="ohs-table-NewsListDiv">
                        {

                            notification_list.map((item, key) => {
                                return (
                                    <section key={key} style={{ cursor:'pointer' }}  className="ohs-table-NewsSingleDiv"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation(); 
                                        setDataObj({id:item.id, title:item.title, description:item.description, fileName:item.file_attachment, comments:item.comments_list, mem:item.members_list, editStatus:item.edit_status}); 
                                        setModalType("Notification");
                                        setCreateModal(true); }}
                                    >
                                        <p>{item.title}</p>
                                        <span>{cropDesc(item.description)}</span>
                                        <aside>
                                            <div className="ohs-table-user-field">
                                                <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={item.dp} alt="" />
                                                <p>{item.created_by}</p>
                                            </div>
                                            <button className={!item.user_read_status ? "ohs-table-action-btn" : "ohs-table-action-btn-read"}  onClick={(e)=>{
                                            e.preventDefault();
                                            e.stopPropagation(); notifyStatus(item.id,item.user_read_status); }}>
                                                Mark as {!item.user_read_status ? 'R' : 'Unr'}ead
                                            </button>
                                        </aside>
                                    </section>
                                )
                            })
                        }
                        {
                            !stopPagingNotify && !notificationLoader ?
                            <div style={{ marginTop: '2%', marginLeft: '50%' }}>
                                <CircularProgress size={20} />
                            </div>
                            :
                            null
                        }
                    </div>
                    </Scrollbars>
                ) : 
                (<NoDatasScreen />)
                
            }
            <CreateModal
                modalStatus = {createModal}
                modalAction = {setCreateModal}
                createType  = {modalType}
                setModalType = {setModalType}
                dataObj     =   {dataObj}
                pageNo      =   {pageNo}
                fnCallBk    =   {funCallBk}
            />
        </div>
        :

        <div className="ohs-box-layout">
            <div className="ohs-box-header">
                <p>Notification Archive</p>
                <div className="ohs-pagination-layout" style={{ height:'100%', alignItems: 'center' }}>
                    {   openSearch ?
                    <div className="ohs-box-SearchDiv" style={{width:'60%', justifyContent:'space-between' }}>
                        <SearchInputTab url={`${OHS_NOTIFY_LIST}1/?limit=8&archive=read`} txt={'Search Notifications'}
                            methodType={'get'}
                            styleHndle={{ marginLeft:'10%' }}
                            dispatchFn={(v)=>({
                                type : 'OHS_NOTIFICATION_ARCHIVE_LIST', notifications_arch_list: v, page: 1
                            })}
                             fn={()=>{ fetch_archive(archPagNo); setOpenSearch(false); }}
                            />
                        {/* <HighlightOffOutlined onClick={()=>{ fetch_archive(archPagNo); setOpenSearch(false); }} /> */}
                    </div>
                    :
                    <div className="ohs-box-SearchDiv">
                        <Search onClick={()=> setOpenSearch(true)} />
                    </div>
                    }
                    <button className="ohs-pagi-add-btn shdl-pagi-m-b" onClick={()=>{ funCallBk() }}
                     style={{ paddingLeft: '1px', paddingt: '1px' }}>
                        <p>Back</p>
                    </button>
                    
                </div>
            </div>
            
            {   archLoader ?
                <div style={{ position:'absolute', top:'50%', left:'50%' }}>
                <CircularProgress />
            </div>
                : 
                notification_arch_list && notification_arch_list.length > 0 ? (
                    <Scrollbars style={{ height: '40vh' }}
                        onScrollFrame={ScrollUp}
                    >
                    <div className="ohs-table-NewsListDiv">
                        {

                            notification_arch_list.map((item, key) => {
                                return (
                                    <section key={key} style={{ cursor:'pointer' }}  className="ohs-table-NewsSingleDiv"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        e.stopPropagation(); 
                                        setDataObj({id:item.id, title:item.title, description:item.description, comments:item.comments_list,
                                             fileName:item.file_attachment, mem:item.members_list, editStatus:item.edit_status}); 
                                        setModalType("Notification");
                                        setCreateModal(true); }}
                                    >
                                        <p>{item.title}</p>
                                        <span>{cropDesc(item.description)}</span>
                                        <aside>
                                            <div className="ohs-table-user-field">
                                                <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={item.dp} alt="" />
                                                <p>{item.created_by}</p>
                                            </div>
                                            <button className={!item.user_read_status ? "ohs-table-action-btn" : "ohs-table-action-btn-read"}  onClick={(e)=>{
                                            e.preventDefault();
                                            e.stopPropagation(); notifyStatus(item.id,item.user_read_status); }}>
                                                Mark as {!item.user_read_status ? 'R' : 'Unr'}ead
                                            </button>
                                        </aside>
                                    </section>
                                )
                            })
                        }
                        {
                            !stopPagingArch && !archLoader ?
                            <div style={{ marginTop: '2%', marginLeft: '50%' }}>
                                <CircularProgress size={20} />
                            </div>
                            :
                            null
                        }
                    </div>
                    </Scrollbars>
                ) : 
                (<NoDatasScreen />)
                
            }
            <CreateModal
                modalStatus = {createModal}
                modalAction = {setCreateModal}
                createType  = {modalType}
                setModalType = {setModalType}
                dataObj     =   {dataObj}
                pageNo      =   {pageNo}
                fnCallBk    =   {funCallBk}
            />
        </div>
    )
}


const mapStateToProps = (state) => {
    const { notification_list, notification_page, pageNotifyContent, notificationLoader, stopPagingNotify,
        notification_arch_list, arch_page, pageArchContent, archLoader, stopPagingArch } = state.ohs
    const { permissions } = state.userLogin
    return { notification_list, notification_page, pageNotifyContent, permissions, notificationLoader, stopPagingNotify,
        notification_arch_list, arch_page, pageArchContent, archLoader, stopPagingArch }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_notify: (page_no) => dispatch(fetch_notify_list(page_no)),
        fetch_archive: (page_no) => dispatch(fetch_archive_list(page_no)),
        read_notify_fx: (pageNo, id, val) => dispatch(read_notify(pageNo, id, val))
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (NotificationsList)
