import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import Sidebar from '../../../../common/sidebar'
import { InnerPage, Header } from '../index'
import { folder_details } from '../../action'

const __DetailedStackInner = ({ folder_details_api }) => {
    // const { selected_employee } = team
    // if(selected_employee === undefined) return <Redirect to='/team' />
    const {id} = useParams()

    useEffect(() => {
        folder_details_api(id)
        return () => folder_details_api.remove
    }, [folder_details_api, id])

    
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='detail-inner' />
                <InnerPage/>
            </div>
        </div>
    )
}

const mapStateProps = (state) => {
    return { }
}

const mapDispatchToProps = (dispatch) => {
    return {
        folder_details_api: (folder_id) => dispatch(folder_details(folder_id))
      }
}

export default connect(mapStateProps, mapDispatchToProps) (__DetailedStackInner)
