import React, {useEffect, useState} from 'react';
import DateTimeInput from '../../../../common/DateTimeInput'
// import {connect} from 'react-redux'
// import Swal from 'sweetalert2'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { CLIENT_SINGLE_FETCH, SALES_ALL_CLIENTS_LIST } from '../../../../store/endpoint';
import Api from '../../../../store/api'

function JobSiteAssesment({ jobObj, modalType, tabType=null, preSelect=null, setJSACard, setClientName, clientId, setClientId, jobObjConnected,
    preSelectVal, //TypeofWaste, setTypeofWaste
    // clientList, addJob, editJob, verifyJobCard=null, user 
}) {

    const [compName, setCompName]           = useState(modalType==='add' ? preSelect===null ? '' :
    preSelect.company_name ? preSelect.company_name : preSelect.client_name : jobObj===null ? '' : jobObj.company_name ? jobObj.company_name : jobObj.client_name  )
    const [siteAddr, setSiteAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.site_address :  jobObj===null ? '' : jobObj.job_site_address   )

    const [jobDate, setJobDate]             = useState(modalType!=='add' ? jobObj===null ? new Date() : jobObj.date : new Date())
    const [jobTechnician, setJobTechnician] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.technician : '')
    const [allClientsList, setAllClientsList] = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)

    const [ohsIdf, setOhsIdf]               = useState(modalType!=='add' ? jobObj===null ?
    [   { title: 'Lone worker', idf: false },
        { title: 'Working at height', idf: false },
        { title: 'Electricity', idf: false },
        { title: 'Manual Handling', idf: false },
        { title: 'Noise', idf: false },
        { title: 'Sewage Products', idf: false },
        { title: 'Flooding', idf: false },
        { title: 'Traffic Vehicles', idf: false },
        { title: 'Restricted Access', idf: false },
        { title: 'Underground service', idf: false },
        { title: 'Uneven / slippery surface', idf: false },
        { title: 'Fire/ Explosion', idf: false },
        { title: 'Heat source', idf: false },
        { title: 'Hazardous Substances', idf: false },
        { title: 'Overhead hazards', idf: false },
        { title: 'Mech/hydraulic isolation', idf: false }
    ] : jobObj.ohs_idf
    : [   { title: 'Lone worker', idf: false },
        { title: 'Working at height', idf: false },
        { title: 'Electricity', idf: false },
        { title: 'Manual Handling', idf: false },
        { title: 'Noise', idf: false },
        { title: 'Sewage Products', idf: false },
        { title: 'Flooding', idf: false },
        { title: 'Traffic Vehicles', idf: false },
        { title: 'Restricted Access', idf: false },
        { title: 'Underground service', idf: false },
        { title: 'Uneven / slippery surface', idf: false },
        { title: 'Fire/ Explosion', idf: false },
        { title: 'Heat source', idf: false },
        { title: 'Hazardous Substances', idf: false },
        { title: 'Overhead hazards', idf: false },
        { title: 'Mech/hydraulic isolation', idf: false }
    ])
    const [confined, setConfined]           = useState(modalType!=='add' ? jobObj===null ?
    [   { title: 'Gas Monitor', idf: false },
        { title: '2 Way Radio', idf: false },
        { title: 'Safety Harness and lifeline', idf: false },
        { title: 'Fan and Ventalation', idf: false },
        { title: 'Tripod', idf: false },
        { title: 'Pressure/ Vacuum', idf: false },
    ] : jobObj.confined
    : 
    [   { title: 'Gas Monitor', idf: false },
        { title: '2 Way Radio', idf: false },
        { title: 'Safety Harness and lifeline', idf: false },
        { title: 'Fan and Ventalation', idf: false },
        { title: 'Tripod', idf: false },
        { title: 'Pressure/ Vacuum', idf: false },
    ])
    const [ppe, setPPE]                     = useState(modalType!=='add' ? jobObj===null ?
    [
        { title: 'Safety Helmet', idf: false },
        { title: 'Rubber Boots', idf: false },
        { title: 'Gloves (TYPE)', idf: false },
        { title: 'Hearing protection', idf: false },
        { title: 'Safety glasses', idf: false },
        { title: 'Barricading', idf: false },
        { title: 'Ear plugs', idf: false },
        { title: 'Safety mask', idf: false },
    ] : jobObj.ppe 
    :
    [
        { title: 'Safety Helmet', idf: false },
        { title: 'Rubber Boots', idf: false },
        { title: 'Gloves (TYPE)', idf: false },
        { title: 'Hearing protection', idf: false },
        { title: 'Safety glasses', idf: false },
        { title: 'Barricading', idf: false },
        { title: 'Ear plugs', idf: false },
        { title: 'Safety mask', idf: false },
    ])
    const [permit, setPermit]               = useState(modalType!=='add' ? jobObj===null ?
    [
        { title: 'Hot Works', idf: false },
        { title: 'Cold Works', idf: false },
        { title: 'High Access Equipment', idf: false },
        { title: 'Working at Height ', idf: false },
        { title: 'Confined Space', idf: false },
        { title: 'Electrical', idf: false },
        { title: 'Plumbing', idf: false },
        { title: 'Traffic management plans', idf: false },
        { title: 'Electrical Isolation', idf: false },
    ] : jobObj.permit :
    [
        { title: 'Hot Works', idf: false },
        { title: 'Cold Works', idf: false },
        { title: 'High Access Equipment', idf: false },
        { title: 'Working at Height ', idf: false },
        { title: 'Confined Space', idf: false },
        { title: 'Electrical', idf: false },
        { title: 'Plumbing', idf: false },
        { title: 'Traffic management plans', idf: false },
        { title: 'Electrical Isolation', idf: false },
    ])

    const [riskAssesTable, setRiskAssesTable]       = useState( modalType!=='add' ? jobObj===null ? [
        {no : 1, hazardIdentify : '', potRisk:'', riskRate1 : '', siteControl : '', riskRate2 : ''}, 
        {no : 2, hazardIdentify : '', potRisk:'', riskRate1 : '', siteControl : '', riskRate2 : ''}, 
        {no : 3, hazardIdentify : '', potRisk:'', riskRate1 : '', siteControl : '', riskRate2 : ''}] : jobObj.risk_assess_table :
        [
            {no : 1, hazardIdentify : '', potRisk:'', riskRate1 : '', siteControl : '', riskRate2 : ''}, 
            {no : 2, hazardIdentify : '', potRisk:'', riskRate1 : '', siteControl : '', riskRate2 : ''}, 
            {no : 3, hazardIdentify : '', potRisk:'', riskRate1 : '', siteControl : '', riskRate2 : ''}])


const addRiskRem = (val) =>{
    if(val){
        setRiskAssesTable(data => [...data, {
            no              :   riskAssesTable.length+1,
            hazardIdentify  :   '',
            potRisk         :   '',
            riskRate1       :   '',
            siteControl     :   '',
            riskRate2       : ''
        }])
    }
    else{
        let newArr = riskAssesTable.filter((_,key) => key!==(riskAssesTable.length-1))
        setRiskAssesTable(newArr)
    }
}


    useEffect(()=>{
        Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
            res =>{
                console.log(res,tabType, modalType, clientId)
                setAllClientsLoader(false)
                if(res.length>0){
                    setAllClientsList(res)
                    if((modalType==='edit' || modalType==='add') && clientId!==null && clientId)
                    Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
                                    .then(response => {
                                        setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                        setSiteAddr(response.site_address)
                                        setClientName(response.client_name);
                                })
                }else 
                    setAllClientsList([])
            }
                
        )
    },[tabType, modalType, clientId, setClientName])    

    const addJobCard = () =>{
        let data = {
            card_id             :   '2',
            client              :   clientId,
            company_name        :   compName,
            job_site_address    :   siteAddr,
            job_date            :   jobDate,
            technician          :   jobTechnician,
            ohs_idf             :   ohsIdf,
            confined            :   confined,
            ppe                 :   ppe,
            permit              :   permit,
            risk_assess_table   :   riskAssesTable
        }
        setJSACard(data)
    }

    return (
        <div className="jobCardDiv1">
            <div className="jobCardDivHead" >Job Site Assessment</div>
                <div className="jobCardDivInpContent">
                    <div className="jobCardInputDivSec" >
                        <div>Date</div>
                        <div>
                            <span>:</span>
                            <DateTimeInput
                            dateSetFn={setJobDate}
                            dateValue={jobDate}
                            />
                    {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'|| preSelectVal!==null   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compName}</p>   :
                            <select className="jobCardDivInp" value={clientId} 
                            style={{ pointerEvents: preSelectVal===null ? 'auto' : 'none',
                                         cursor: preSelectVal===null ? 'pointer' : 'default' }}
                            onClick={()=>{ setAllClientsLoader(true);
                                Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                                    res =>{
                                        // console.log("DATAAAAA",res)
                                        setAllClientsLoader(false)
                                        if(res.length>0)
                                            setAllClientsList(res)
                                        else 
                                            setAllClientsList([])
                                    }
                                        
                                )
                            }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                setClientId(e.target.value);
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                                        setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                        setSiteAddr(response.site_address)
                                        setClientName(response.client_name);
                                })
                                 
                            ; }
                                 }}>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    {/* <div className="jobCardDivInpContentDiv">
                        <div>Type of Waste</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={TypeofWaste} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                                 onChange={val =>{ setTypeofWaste(val.target.value.toUpperCase());  }}>
                                    <option value='' >Select Option</option>
                                    <option value='AMMONIA'>AMMONIA</option>
                                    <option value='DILUTION PIT'>DILUTION PIT</option>
                                    <option value='DRUMS'>DRUMS</option>
                                    <option value='FOOD WASTE'>FOOD WASTE</option>
                                    <option value='GERNIE WASHES'>GERNIE WASHES</option>
                                    <option value='GREASE'>GREASE</option>
                                    <option value='GROUND WATER'>GROUND WATER</option>
                                    <option value='J120'>J120</option> 
                                    <option value='LEVY'>LEVY</option>
                                    <option value='M250'>M250</option>
                                    <option value='N205'>N205</option> 
                                    <option value='OIL SEPERATOR'>OIL SEPERATOR</option>
                                    <option value='PACKAGED WASTE'>PACKAGED WASTE</option>
                                    <option value='PRODUCT DESTRUCTION'>PRODUCT DESTRUCTION</option>
                                    <option value='PUMP SERVICE'>PUMP SERVICE</option>
                                    <option value='RETENTION PIT'>RETENTION PIT</option>
                                    <option value='SEWER'>SEWER</option>
                                    <option value='STORMWATER'>STORMWATER</option>
                                    <option value='TANK WASH OUT'>TANK WASH OUT</option>      
                                    <option value='TRADE WASTE'>TRADE WASTE</option>                                              
                                    <option value='TRANSPORT'>TRANSPORT</option>                            
                                    <option value='Z140'>Z140</option>
                                    <option value='WIEGH BRIDGE'>WEIGH BRIDGE</option>                                    
                                    <option value='DESTRUCTION CERTIFICATE'>DESTRUCTION CERTIFICATE</option>
                                    <option value='NOT APPLICABLE'>NOT APPLICABLE</option>                                
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                        </div>
                        
                    </div> */}
                    
                    <div className="jobCardInputDivSec" >
                        <div>Site Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteAddr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Site Address" value={siteAddr} onChange={(e)=>setSiteAddr(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Technician</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Technician" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                            }
                        </div>
                    </div>

                    <div className="jobCardDivHead" >Risk Rating HAZPAK Checklist</div>
                    <div className="jobCardDiv3JSA">
                        <table className="jobCardDivTableContent jobCardTableStyle" >
                            <tbody style={{ fontSize:'1rem' }}>
                                <th>OH & S</th>
                                <th>Identified (Tick)</th>
                                {   ohsIdf.map((item,k) => { 
                                    return(
                                        <tr>
                                            <td>{item.title}</td>
                                            <td>
                                                <input type="checkbox" className="jobCardDivInp"  disabled={modalType==='view' }
                                                 checked={item.idf} onClick={()=>{ 
                                                    let temp = {...item, idf : !item.idf}; 
                                                    let tempArr = ohsIdf
                                                    tempArr.splice(k,1, Object.assign({}, temp))
                                                    setOhsIdf(Object.assign([],tempArr)) }} />
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                        <div className="jobCardDiv3JSASubTab" >
                        <table className="jobCardDivTableContent jobCardTableStyle" >
                            <tbody style={{ fontSize:'1rem' }}>
                                <th>Permit Required</th>
                                <th>Identified (Tick)</th>
                                {   permit.map((item,k) => { 
                                    return(
                                        <tr>
                                            <td>{item.title}</td>
                                            <td>
                                                <input type="checkbox" className="jobCardDivInp"  disabled={modalType==='view' }
                                                 checked={item.idf} onClick={()=>{ 
                                                    let temp = {...item, idf : !item.idf}; 
                                                    let tempArr = permit
                                                    tempArr.splice(k,1, Object.assign({}, temp))
                                                    setPermit(Object.assign([],tempArr)) }} />
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                        <table className="jobCardDivTableContent jobCardTableStyle" >
                            <tbody style={{ fontSize:'1rem' }}>
                                <th>Confined Space</th>
                                <th>Identified (Tick)</th>
                                {   confined.map((item,k) => { 
                                    return(
                                        <tr>
                                            <td>{item.title}</td>
                                            <td>
                                                <input type="checkbox" className="jobCardDivInp"  disabled={modalType==='view' }
                                                 checked={item.idf} onClick={()=>{ 
                                                    let temp = {...item, idf : !item.idf}; 
                                                    let tempArr = confined
                                                    tempArr.splice(k,1, Object.assign({}, temp))
                                                    setConfined(Object.assign([],tempArr)) }} />
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                        </div>
                        <table className="jobCardDivTableContent jobCardTableStyle" >
                            <tbody style={{ fontSize:'1rem' }}>
                                <th>PPE</th>
                                <th>Identified (Tick)</th>
                                {   ppe.map((item,k) => { 
                                    return(
                                        <tr>
                                            <td>{item.title}</td>
                                            <td>
                                                <input type="checkbox" className="jobCardDivInp"  disabled={modalType==='view' }
                                                 checked={item.idf} onClick={()=>{ 
                                                    let temp = {...item, idf : !item.idf}; 
                                                    let tempArr = ppe
                                                    tempArr.splice(k,1, Object.assign({}, temp))
                                                    setPPE(Object.assign([],tempArr)) }} />
                                            </td>
                                        </tr>
                                    )})
                                }
                            </tbody>
                        </table>
                        
                    </div>

                    <div className="jobCardDivTableContent jobCardTableStyle jobCardJSAIdentifyAdjust">
                            <div className="jobCardDivTHead">
                                <div style={{ width:'5%' }}>Sl.No</div>
                                <div>Identify Hazard</div>
                                <div>Potential Risk</div>
                                <div>Risk Rate</div>
                                <div>Site Controls</div>
                                <div>Risk Rate</div>
                            </div>
                            {   riskAssesTable.map((item, key) => { 
                            return(
                                <div className="jobCardDivTHead">
                                    <div style={{ width:'5%' }}>{key+1}</div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Identify Hazard"  disabled={modalType==='view' }
                                         value={item.hazardIdentify} onChange={(e)=>{ 
                                            let temp = {...item, hazardIdentify : e.target.value}; 
                                            let tempArr = riskAssesTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setRiskAssesTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Potential Risk"  disabled={modalType==='view' }
                                         value={item.potRisk} onChange={(e)=>{ 
                                            let temp = {...item, potRisk : e.target.value}; 
                                            let tempArr = riskAssesTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setRiskAssesTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    
                                    <div>
                                        <input type="text" 
                                         className={item.riskRate1==='1' ? 'jobCardDivInp riskRate1' : item.riskRate1==='2' ? 'jobCardDivInp riskRate2'
                                          : item.riskRate1==='3' ?
                                         'jobCardDivInp riskRate3' : item.riskRate1==='4' ? 'jobCardDivInp riskRate4' : item.riskRate1==='5' ? 'jobCardDivInp riskRate5'
                                          : 'jobCardDivInp riskRate6'} 
                                         
                                         placeholder="Risk Rate"  disabled={modalType==='view' }
                                         value={item.riskRate1} onChange={(e)=>{ 
                                            let temp = {...item, riskRate1 : e.target.value}; 
                                            let tempArr = riskAssesTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setRiskAssesTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text" 
                                         className="jobCardDivInp"
                                         placeholder="Site Control"  disabled={modalType==='view' }
                                         value={item.siteControl} onChange={(e)=>{ 
                                            let temp = {...item, siteControl : e.target.value}; 
                                            let tempArr = riskAssesTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setRiskAssesTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text" 
                                          className={item.riskRate2==='1' ? 'jobCardDivInp riskRate1' : item.riskRate2==='2' ? 'jobCardDivInp riskRate2'
                                           : item.riskRate2==='3' ?
                                          'jobCardDivInp riskRate3' : item.riskRate2==='4' ? 'jobCardDivInp riskRate4' : item.riskRate2==='5' ? 'jobCardDivInp riskRate5'
                                           : 'jobCardDivInp riskRate6'}
                                         placeholder="Risk Rate"  disabled={modalType==='view' }
                                         value={item.riskRate2} onChange={(e)=>{ 
                                            let temp = {...item, riskRate2 : e.target.value}; 
                                            let tempArr = riskAssesTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setRiskAssesTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    
                                </div>)
                    })
                    }
                        {   modalType==='view'  ?   null    :
                        <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'flex-end', alignItems:'center', margin:'20px 0' }}>
                            <div className="header-button-top newJobCardBtnActive" onClick={() => addRiskRem(true)} ><AddCircleOutline style={{ fontSize:'16px' }}/> Add Row</div>
                            {   riskAssesTable.length>3 ?
                                <div className="header-button-top newJobCardBtnActive" onClick={() => addRiskRem(false)}><RemoveCircleOutline style={{ fontSize:'16px' }}/> Remove Row</div>  :   null
                            }
                            </div>
                        }                          

                    </div>
                    <RiskAssessmentCheck/>
                    <div>
                        <div style={{ color:'red', fontWeight:'bold' }} > Hierarchy of Controls</div>
                        <p>
                            Eliminate the risk or, if not reasonably practicable minimize risk through<br/>
                            <p style={{ display:'flex', flexDirection:'column' }} >
                            <span>1. Substitution</span>
                            <span>2. Isolation</span>
                            <span>3. Engineering</span>
                            <span>4. Administrative Controls</span>
                            <span>5. Personal Protective Equipment or a combination</span>
                            </p>
                        </p>
                    </div>
                </div>
                {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                <div 
                className={ clientId ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else if(!jobObjConnected)
                        addJobCard()
                    }} 
                  style={{ width:'15vw', height:'5vh', textAlign:'center' }}>
                {modalType==='add' ? 'Add Job Card to Collection' : modalType==='verify' ? jobObjConnected ?
                `This job card is already in use `
                :   'Update Job Card (Deselect the red checkbox to remove from collection)' :
                 'Update Job Card (Deselect the red checkbox to remove from collection)'} 
                </div>
            </div>
            :   null
            }
        </div>
    );
}

export default JobSiteAssesment;

function RiskAssessmentCheck(){
    return(
        <table className="riskAssesmentTableDiv">
            <thead className="riskAssesmentTableDivHead">
                <th style={{ color:'white', backgroundColor:'var(--cl_blue)' }}>Risk Assessment Check</th>
                <th>Very Likely To Happen At Any Time</th>
                <th>Likely Could Happen Some Time</th>
                <th>Unlikely Could Happen Very Rarely</th>
                <th>Very Unlikely Probably Ever Would Happen</th>
            </thead>
            <tbody>
            <tr className="riskAssesmentTableDivHead">
                <td id="thDiv" >Kill or Cause Permanent Disability or ill-health</td>
                <td id="riskRate1">1</td>
                <td id="riskRate1">1</td>
                <td id="riskRate2">2</td>
                <td id="riskRate3">3</td>
            </tr>
            <tr className="riskAssesmentTableDivHead">
                <td id="thDiv" >Long term illness or Serious Injury</td>
                <td id="riskRate1">1</td>
                <td id="riskRate2">2</td>
                <td id="riskRate3">3</td>
                <td id="riskRate4">4</td>
            </tr>
            <tr className="riskAssesmentTableDivHead">
                <td id="thDiv" >Medical Attention & several days of work</td>
                <td id="riskRate2">2</td>
                <td id="riskRate3">3</td>
                <td id="riskRate4">4</td>
                <td id="riskRate5">5</td>
            </tr>
            <tr className="riskAssesmentTableDivHead">
                <td id="thDiv" >First Aid Needed</td>
                <td id="riskRate3">3</td>
                <td id="riskRate4">4</td>
                <td id="riskRate5">5</td>
                <td id="riskRate6">6</td>
            </tr>
            </tbody>         
        </table>
    )
}