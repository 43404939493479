import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import DateTimeInput from '../../../../common/DateTimeInput';
import {
    fetch_job_list_for_all,
} from '../../common/action';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SALES_ALL_CLIENTS_LIST,  
} from '../../../../store/endpoint';
import Api from '../../../../store/api'
import { SearchInputTab } from '../../../../common/SearchInputTab';
import './sales_tableStyle.css';
import { takeSalesJobsQuotePrint } from '../../common/action';
import ReactSelect from 'react-select'
import ImprovedScrollBar from '../../../../common/ImprovedScrollBar';

function TableLayout({ children, row_count, page, nextPage, prevPage, fetch_data_filter, page_type,
    tableLoader, tableStopPaging,
    searchInpTabFn=null, dateFilterFn=null, sortByFilterFn=null,
    setInvokeFilter, setFilterData, refreshPage, client_list, isLoading, fetch_job_filter, setSearchDataFx,
    switchableSearchObj=null }) {

    const { path } = useRouteMatch();
    const routing_path = path.split('/')
    const route_path = '/' + routing_path[1] + '/' + routing_path[2]


    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: 120,
            maxHeight: 20,
            marginLeft: 10,
            marginTop: -8,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        resize: {
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }
    }));

    const [filterDate, setFilterDate] = useState(new Date())
    const [invokeDate, setInvokeDate] = useState(true)
    const [filterSite, setFilterSite] = useState("empty")
    const [filterOpen, setFilterOpen] = useState(false)
    const [sortFilterOpen, setSortFilterOpen] = useState(false)
    const [searchInpVal, setSearchInpVal] = useState(null)


    const [clientsListArr, setClientsListArr] = useState({ data: [], tab: '' })
    const [clientLoader, setAllClientsLoader] = useState(true)

    const filterTextData = (val) => {
        setFilterSite(val)
        let postData = new FormData()

        // if (route_path + '/quote-register' === path)
            postData.append(sortByFilterFn.k, val)
        // else
        //     postData.append('client_id', val)
        setInvokeFilter("site")
        setFilterData(postData)
        // if (route_path + '/quote-register' === path)
            // fetch_data_filter(postData, sortByFilterFn.name, 1, page_type, 
                sortByFilterFn.disp(postData, sortByFilterFn.name, 1, page_type)
        // else
        //     fetch_job_filter(postData, "client", 1, page_type)
    }

    const filterDateFn = (val) => {
        let postData = new FormData()
        // if (route_path + '/quote-register' === path)
            postData.append(dateFilterFn.k, moment(val).format('YYYY-MM-DD'))
        // else
        //     postData.append('date', moment(val).format('YYYY-MM-DD'))

        setInvokeFilter("date")
        setFilterData(postData)
        // if (route_path + '/quote-register' === path)
            // fetch_data_filter(postData, "date", 1, page_type,
             dateFilterFn.disp(postData, "date", 1, page_type)
        // else
        //     fetch_job_filter(postData, "date", 1, page_type)
    }

    const SortDropdown = () => {
        const classes = useStyles();
        if (clientsListArr.tab !== page_type && clientLoader) {
            Api('get', `${SALES_ALL_CLIENTS_LIST}${page_type === 'all' ? '' : page_type + '/'}`).then(
                res => {
                    console.log("DATAAA", res)
                    if (res.length > 0)
                        setClientsListArr({ data: res, tab: page_type })
                    else
                        setClientsListArr({ data: [], tab: '' })
                    setAllClientsLoader(false)

                }

            )
        }
        else {
            setAllClientsLoader(false)
        }

        return (
            <FormControl className={classes.formControl}>
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14, color: 'black' }}
                    value={filterSite}
                    className={classes.resize}
                    style={{ color: 'black' }}

                    onChange={(val) => {
                        if (val.target.value !== "empty") {
                            setFilterSite(val.target.value)
                            filterTextData(val.target.value)
                            setSortFilterOpen(true);
                        }
                        else {
                            setInvokeFilter(null)
                            setSortFilterOpen(false)
                            setFilterSite("empty")
                            refreshPage(1, page_type)
                        }
                    }}
                >
                    <MenuItem value='empty'>{clientLoader ? 'Loading...' :
                        (clientsListArr.data && clientsListArr.data.length !== 0) ? 'Filter By Site' : 'No Sites'}</MenuItem>
                    {
                        clientLoader ? <p>Loading...</p>
                            :
                            clientsListArr.data.map((item, key) => {
                                return (
                                    route_path + '/quote-register' === path ?
                                        <MenuItem key={key} value={item.id}>{item.client_name}</MenuItem>
                                        :

                                        <MenuItem key={key} value={item.id}>{item.client_name}</MenuItem>
                                )

                            })
                    }

                </Select>
            </FormControl>
        )
    }

    const ScrollUp = (val) => {
        const { top } = val
        if (top === 1 && !tableStopPaging && !tableLoader) {
            console.log("\x1b[36m" + top, tableStopPaging, tableLoader)
            nextPage()
        }

    }

    const customStyles = {
        control : (styles) => ({...styles, borderRadius : '30px 10px 10px 30px', padding : 0, 
            borderTop:'none', borderBottom:'none', borderLeft:'none', fontSize:'.8rem', minHeight: 'unset',
            width:'6vw', }),
        // input : (styles) => ({...styles, height:'.2vh'})
    }


    return (
        <div className="sales-table-layout" style={{ position : 'relative' }}>
                {
                tableLoader    && 
                <div style={{ display: 'flex', height: '100%', position: 'absolute', 
                zIndex:'100', left:'45%',
                justifyContent: 'center', alignItems: 'center',padding:'10px 0', top:0 }} >
                    <CircularProgress style={{height:'30px',width:'30px'}}/>
                </div>
                }
            <ImprovedScrollBar style={{ height: '45vh' }}
                className='sales-table-body-container'
                onUpdate={ScrollUp}>
                {children}
                
            </ImprovedScrollBar>
            {   
            //route_path + '/quote-register' === path || '/sales/' + page_type === path ?
                searchInpTabFn!==null || dateFilterFn!==null || sortByFilterFn!==null ?
            <div className="sales-table-footer" style={{ justifyContent: 'space-between' }}>
                {
                //route_path + '/quote-register' === path || '/sales/' + page_type === path ?
                searchInpTabFn!==null ?
                    switchableSearchObj!==null ?
                    <div className="sales-table-filter-searchDiv" style={{ zIndex: '3', width:'30vw' }}>
                    <ReactSelect
                        styles={customStyles}
                        isClearable={false}
                        isSearchable={false}
                        classNamePrefix={'react-select-customStyle'}
                        defaultValue={switchableSearchObj?.options[0]}
                        options={switchableSearchObj?.options}
                        value={switchableSearchObj?.switchableSearchFnVal}
                        onChange={(newValue)=>{
                            switchableSearchObj?.switchableSearchFn(newValue)
                        }}
                    />
                    <SearchInputTab
                        cls={searchInpTabFn.cls} styleHndle={{ width: '70%', padding: '0 20px' }} txt={searchInpTabFn.txt}
                        url={searchInpTabFn.url}
                        dispatchFn={(response) => {
                            return searchInpTabFn.disp(response)
                        }
                        }
                        fn={() => { setSearchDataFx(null); 
                            setSearchInpVal(null);
                            refreshPage(1, page_type); }}
                        setSearchDataFx={(val)=>{
                            setSearchInpVal(val)
                            setSearchDataFx(val)
                        }}
                        methodType={searchInpTabFn.methodType}
                        searchKey={searchInpTabFn.key}
                    />
                    </div>
                    :
                    <div className="sales-table-filter-searchDiv" style={{ zIndex: '3' }}>
                        <SearchInputTab
                            cls={searchInpTabFn.cls} styleHndle={{ width: '70%', padding: '0 20px' }} txt={searchInpTabFn.txt}
                            url={searchInpTabFn.url}
                            dispatchFn={(response) => {
                                return searchInpTabFn.disp(response)
                            }
                            }
                            fn={() => { setSearchDataFx(null); 
                                setSearchInpVal(null);
                                refreshPage(1, page_type); }}
                            setSearchDataFx={(val)=>{
                                setSearchInpVal(val)
                                setSearchDataFx(val)
                            }}
                        />

                    </div>
                    : null
                }
                {   dateFilterFn!==null ?
                                invokeDate ?
                                    <div className="sales-table-filter-sites sales-table-filter-dateDiv"
                                        style={{ width: '20%', marginLeft: '1%' }}
                                        onClick={() => { setFilterOpen(true); setInvokeDate(false); }}>
                                        <span style={{ fontSize: 14 }} >Search by Date</span>
                                    </div>
                                    :
                                    <div className="sales-table-filter-sites sales-table-filter-dateDiv" style={{ width: '20%', marginLeft: '1%' }}>
                                        <DateTimeInput
                                            dateSetFn={setFilterDate}
                                            dateValue={filterDate}
                                            openEdit={!invokeDate}
                                            acceptFunc={filterDateFn}
                                            clsName={'sales-table-filter-date'}
                                        />
                                        {
                                            filterOpen ?
                                                <HighlightOffIcon style={{ marginLeft: '10px' }}
                                                    onClick={() => { setInvokeFilter(null); setInvokeDate(true); setFilterOpen(false); refreshPage(1, page_type); }} />
                                                : null
                                        }
                                    </div>
                                :   null
                            }
                            
                {
                    // route_path + '/quote-register' === path || '/sales/' + page_type === path ?
                    dateFilterFn!==null ?
                        <div className="sales-table-filterDiv">                
                            <div className="sales-table-filter-sites">
                                <div className="navbar-button sales-table-filter-sites-input"
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        setAllClientsLoader(true)
                                    }}>
                                    <SortDropdown />
                                </div>
                                {
                                    sortFilterOpen ?
                                        <HighlightOffIcon style={{ marginLeft: '10px', width: '10%' }}
                                            onClick={() => {
                                                setFilterSite("empty"); setSortFilterOpen(false); refreshPage(1, page_type);
                                            }} />
                                        : null
                                }
                            </div>
                            {
                                row_count ?
                                <div className="sales-table-filter-sites sales-table-filter-dateDiv sales-table-exportBtn"
                                onClick={() => {  
                                    takeSalesJobsQuotePrint(
                                        route_path + '/quote-register' === path,
                                        page,
                                        {
                                            dateFilter : invokeDate ? null : filterDate,
                                            siteFilter : sortFilterOpen ? filterSite : null,
                                            searchFilter : searchInpVal!=='' && searchInpVal!=='null' && searchInpVal!=='undefined' && searchInpVal ? 
                                            searchInpVal : null
                                        }
                                    )
                                }}>
                                <span style={{ fontSize: 14 }} >Export to Excel 
                                </span>
                            </div>
                                :   null
                            }
                            

                        </div>
                        : null
                }
            </div>
            :   null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { client_list, isLoading } = state.client
    const { job_list_filter, job_stop_pagination, isLoading: job_loading } = state.sales

    return { client_list, isLoading, job_list_filter, job_stop_pagination, job_loading }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_jobs: (page_no, tab_type, filter) => dispatch(fetch_job_list_for_all(page_no, tab_type, filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableLayout)
