import React, {useState} from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  // DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CalendarTodayRounded, AccessTimeRounded } from '@material-ui/icons';

function DateTimeInput ({dateValue, dateSetFn, clsName, acceptFunc=null, cancelFunc=null, pickerType="", openEdit=false, future=false, past=false, viewOnly=null,
  rangeDate=null, rangeDateMax=null, pickerVariant='dialog', pickerOrientation='potrait', showMeridian=true}) {
console.log('minmax',rangeDate,'max',rangeDateMax)
  const [openCal, setOpenCal] = useState(openEdit)
  const [selDate, setSelDate] = useState(false)

  // const [dTDateVal, setDTDateVal] = useState(dateValue ? dateValue : dayjs())
  // const [dTTimeVal, setDTTimeVal] = useState(dateValue ? dateValue : dayjs())
  const [dTVal, setDTVal]         = useState(dateValue ? dateValue : dayjs())

  const [openDateCal, setOpenDateCal] = useState(openEdit)
  const [openTimeCal, setOpenTimeCal] = useState(openEdit)


  const IconRetFn = () =>{
    if(pickerType==='' )
      return <CalendarTodayRounded style={{ fontSize:'16px', color: 'black' }}/> 
    else if(pickerType==='dateTime')
      return <span style={{ position : 'relative', display:'grid', placeItems:'center' }}>
      <CalendarTodayRounded style={{ fontSize:'18px', color: 'black', position:'absolute', stroke:'white' }}/>
      <AccessTimeRounded style={{ fontSize:'15px', position:'absolute', color: 'black', left:'-100%', backgroundColor:'white', bottom:'100%', borderRadius:'50%' }} />
    </span>
    else 
      return <AccessTimeRounded style={{ fontSize:'16px' }} />
  }

  const LNTextField = props => {
    console.log('PROPSTIMeTEXTINP=>',props)

    return (
      <span style={{ display:'flex', flexDirection:'row',alignItems:'center',
        minWidth: '100px',
        maxWidth: '150px',
        marginRight: props?.cancelIcon ? 0 : '10px',
        color: selDate ? 'black' : 'lightgray'  }}
      
      onClick={() =>{ 
        if(dateSetFn!==''){ 
          if(props.designFn)
            props.designFn(!props.designFnVal)
          else
            setOpenCal(!openCal) 
          setSelDate(true)
        } 
      }}>
        
      <input
        type="text"
        value={props.value}
        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 10%', justifyContent:'center',
          maxWidth: '80px',
          borderBottom: selDate ? 'none' : '1px solid black',
          color: selDate ? 'black' : 'gray' }}
        className={clsName}
        onClick={() =>{ if(dateSetFn!==''){ setOpenCal(!openCal) }}}
      />
        
      { dateSetFn!=='' ? <IconRetFn /> : null }
      </span>
    );
  };

  const LNTextDateField = props => {
    console.log('PROPSTIMeDATEINP=>',props)

    return (
      <span style={{ display:'flex', flexDirection:'row',alignItems:'center',
        width: '85px',
        marginRight: props?.cancelIcon ? 0 : '10px',
        color: selDate ? 'black' : 'lightgray'  }}
      
      onClick={() =>{ 
        if(dateSetFn!==''){ 
          if(props.designFn)
            props.designFn(!props.designFnVal)
          else
            setOpenCal(!openCal) 
          setSelDate(true)
        } 
      }}>
        
      <input
        type="text"
        value={props.value}
        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 1%', justifyContent:'center',
          maxWidth: '80px',
          borderBottom: selDate ? 'none' : '1px solid black',
          color: selDate ? 'black' : 'gray' }}
        className={clsName}
        onClick={() =>{ if(dateSetFn!==''){ setOpenCal(!openCal) }}}
      />
      </span>
    );
  };

  const LNTextTimeField = props => {
    console.log('PROPSTIMeINP=>',props)
    return (
      <span style={{ display:'flex', flexDirection:'row',alignItems:'center',
        width: '85px',
        marginRight: props?.cancelIcon ? 0 : '10px',
        color: selDate ? 'black' : 'lightgray'  }}
      
      onClick={() =>{ 
        if(dateSetFn!==''){ 
          if(props.designFn)
            props.designFn(!props.designFnVal)
          else
            setOpenCal(!openCal) 
          setSelDate(true)
        } 
      }}>
        
      <input
        type="text"
        value={props.value}
        style={{ fontSize: '12px', border:'none', display:'flex', alignItems:'center', margin:'0 1%', justifyContent:'center',
          maxWidth: '80px',
          borderBottom: selDate ? 'none' : '1px solid black',
          color: selDate ? 'black' : 'gray' }}
        className={clsName}
        onClick={() =>{ if(dateSetFn!==''){ setOpenCal(!openCal) }}}
      />
        
      { dateSetFn!=='' ? <IconRetFn /> : null }
      </span>
    );
  };

  const renderInput = props => <LNTextField value={props.value} label={props.lable} designFn={null} designFnVal={null}/>

  const renderDateInput = props => <LNTextDateField value={props.value} label={props.lable} designFn={setOpenDateCal} designFnVal={openDateCal} cancelIcon/>
  const renderTimeInput = props => <LNTextTimeField value={props.value} label={props.lable} designFn={setOpenTimeCal} designFnVal={openTimeCal}/>

  const [dateStr, setDateStr] = useState(dateValue ? dateValue : dayjs())

  console.log('DATEVAL',dateValue,'DATESTR',dateStr)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {
        pickerType==="" ?
      <DatePicker 
        variant={pickerVariant}
        orientation={pickerOrientation}
        value={dateStr} 
        views={viewOnly!==null ? viewOnly : ["year","month","date"]}
        disableFuture={future}
        disablePast={past}
        readOnly={dateSetFn===""? true: false} 
        minDate={ rangeDate===null ? new Date('1900-01-01') : new Date(rangeDate)}
        maxDate={ rangeDateMax===null ? new Date('2100-01-01') : rangeDateMax }
        onChange={(e) => { 
          if(viewOnly===null)
            dateSetFn(moment(e).format('YYYY-MM-DD')); 
          else 
            dateSetFn(e)
            setDateStr(e); }}
        onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenCal(false); }}
        onClose={()=>{ if(cancelFunc){ cancelFunc() }  setOpenCal(false)}}
        format={  viewOnly!==null ? 
          (viewOnly.includes('date') && viewOnly.includes('month')) ? "dd-MMM" : 
          (viewOnly.includes('month') && viewOnly.includes('year')) ? "MMM-yyyy" : 
          viewOnly.includes('month') ? 'MMM' : 'yyyy' :
          "dd-MM-yyyy"}
        open={openCal}
        TextFieldComponent={renderInput}
        
      />
      :
        pickerType==="dateTime" ?
        <>
        <DatePicker 
        variant={pickerVariant}
        orientation={pickerOrientation}
        value={dTVal} 
        readOnly={dateSetFn===""? true: false} 
        onChange={(e) => { 
          // setDTDateVal(moment(e).format('YYYY-MM-DD'))
          let dM = moment(dTVal)
          dM.set({ 'year' : moment(e).get('year'), 'month' : moment(e).get('month'), 'date' : moment(e).get('date') })
          setDTVal(dM)

          if(viewOnly===null)
            dateSetFn(moment(e).format('YYYY-MM-DD')); 
          else 
            dateSetFn(e)
            setDateStr(e); }}
        onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenDateCal(false); }}
        onClose={()=>{ if(cancelFunc){ cancelFunc() }  setOpenDateCal(false)}}
        format={"dd-MM-yyyy"}
        open={openDateCal}
        TextFieldComponent={renderDateInput}        
      />
      <TimePicker
      variant={pickerVariant}
      orientation={pickerOrientation}
      
      value={dTVal} 
      showTodayButton
      todayLabel="now"
      ampm={showMeridian}
      className={clsName}
      readOnly={dateSetFn===""? true: false} 
      onChange={(e) => { 
        // setDTTimeVal(moment(e).format('HH:mm:ss'))
        let dM = moment(dTVal)
        dM.set({ 'hour' : moment(e).get('hour'), 'minute' : moment(e).get('minute'), 'second' : moment(e).get('second') })
        setDTVal(dM)
        dateSetFn(e); setDateStr(e); }}
      onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenTimeCal(false); }}
      onClose={()=>{ if(cancelFunc){ cancelFunc() } setOpenTimeCal(false)}}
      open={openTimeCal}
      TextFieldComponent={renderTimeInput}
      />
        </>
      // <DateTimePicker
      // variant={pickerVariant}
      // orientation={pickerOrientation}
      //   value={dateStr} 
      //   disablePast={past}
      //   disableFuture={future}
      // className={clsName}
      // readOnly={dateSetFn===""? true: false} 
      // onChange={(e) => { dateSetFn(moment(e).format('YYYY-MM-DD')); setDateStr(moment(e).format('DD-MM-YYYY hh:mm')); }}
      // onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenCal(false); }}
      // onClose={()=>{ if(cancelFunc){ cancelFunc() } setOpenCal(false)}}
      // open={openCal}
      // TextFieldComponent={renderInput}
      // format={"dd-MM-yyyy hh:mm"}
      // />
      :
      <TimePicker
      variant={pickerVariant}
      orientation={pickerOrientation}
      
      value={dateStr} 
      showTodayButton
      todayLabel="now"
      ampm={showMeridian}
      className={clsName}
      readOnly={dateSetFn===""? true: false} 
      onChange={(e) => { dateSetFn(e); setDateStr(e); }}
      onAccept={(e) => { if(acceptFunc){ acceptFunc(e); } setOpenCal(false); }}
      onClose={()=>{ if(cancelFunc){ cancelFunc() } setOpenCal(false)}}
      open={openCal}
      TextFieldComponent={renderInput}
      />
      }
    </MuiPickersUtilsProvider>
  );

}

export default DateTimeInput;
