import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { previous_jobs_fetch } from '../../../action'
// import { ArrowBack, ArrowForward } from '@material-ui/icons'
// import { useRouteMatch } from 'react-router-dom'
import { 
   //HOST_WITHOUT_SLASH, 
   SCHEDULE_QUOTE_INFOS_NEW, 
   //SITE_TYPE_OF_WASTE_LIST,
    SITE_TYPE_OF_WASTE_LIST_UPDATED } from '../../../../../store/endpoint'
import NoDatasScreen from '../../../../../common/no_datas'
import QuoteModal from '../../../../../common/CommonQuoteDetailModal'
import QuoteHistoryModalView from '../../../../scheduling/component/quoteHistoryModal'
import Api from '../../../../../store/api'
import Scrollbars from '../../../../../common/ImprovedScrollBar'
import Scrollview from '../../../../../common/ImprovedScrollBar'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import JobModal from '../../../../scheduling/component/jobModal'
import { fetch_Employee_avilabilities, fetch_Vehicle_avilabilities } from '../../../../scheduling/common/action'
import Swal from 'sweetalert2'


function Work({ fetch_prev_job, prevJob, empId, setGraphdiv, tabType, waste_type_filter, tablePage, fetch_Emp_availability, fetch_Veh_availability }) {

   // const { path } = useRouteMatch();
   //  let routing_path = path.split('/')
   //  const route_path = '/' + routing_path[1] 
    
    const [quoteModal, setquoteModal] = useState(false)
    const [quoteInfos, setQuoteInfos] = useState({job: null, quote: null, schedul: null})
    const [typeOfWaste, setTypeOfWaste] = useState(waste_type_filter)
    const [typeOfWasteLoader, setTypeOfWasteLoader] = useState(true)
    const [typeOfWasteList, setTypeOfWasteList] = useState([])
    const [dataLoader, setDataLoader] = useState(true)
    const [QuoteHistoryModal, setQuoteHistoryModal]         = useState(false)
    const [quoteHistoryInfos, setQuoteHistoryInfos]         = useState([])

    const [selectedJob, setSelectedJob] = useState('')
    const [slEmployee, setSlEmployee] = useState([])
    const [jobModalStatus, setJobModalStatus] = useState(false)
    const [mockId, setMockId] = useState(null)

   useEffect(() => {
      fetch_prev_job(empId, 'all', null, setDataLoader)
      Api('get',`${SITE_TYPE_OF_WASTE_LIST_UPDATED}${empId}/`,'')
      .then(response => {
          console.log(response)
          setTypeOfWasteList(response)
          setTypeOfWasteLoader(false)
      })
      
      return () => fetch_prev_job.remove
  }, [fetch_prev_job, empId])

//   const nextPageFx = () => {
//    setTablePage(tablePage + 6)
// }

// const prevPageFx = () => {
//    setTablePage(tablePage - 6)
// }
const useStyles = makeStyles((theme) => ({
   formControl: {
       minWidth: 120,
       maxHeight: 20,
       marginLeft: 10,
       marginTop: -8,
   },
   selectEmpty: {
       marginTop: theme.spacing(2),
   },
   resize: {
       fontSize: 14,
       fontWeight: 600,
       color: 'white'
   }
}));

const fetchQuoteInfos = (id) => {

   const succ = (res) =>{
      console.log(res.data)
      if(res.data){
      setQuoteInfos(res.data)
      setquoteModal(true)}
      else
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
    })
   }

   const fail = (err) =>{
      Swal.fire({
         icon: 'error',
         title: 'Oops...',
         text: err && err.app_data ? err.app_data : 'Something went wrong!'
     })
   }

   Api('get', `${SCHEDULE_QUOTE_INFOS_NEW}${id}/`,null, succ, fail)
}



 const SortDropdown = () => {                          
         const classes = useStyles();
         return (
             <FormControl className={classes.formControl}>
                 <Select
                     disableUnderline
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     SelectDisplayProps={{ fontSize: 14 }}
                     value={typeOfWaste}
                     className={classes.resize}
                     disabled={typeOfWasteList && (typeOfWasteList.length===0 || typeOfWasteList.filter(el => el.child_exists&&(!el.hidden)).length===0)}
                     onChange={(val) => {
                           setDataLoader(true)
                          setTypeOfWaste(val.target.value==='/0' ? 'all' : val.target.value)
                          let currObj = val.target.value==='all' ? null : typeOfWasteList&&typeOfWasteList.find(el => el.id===val.target.value)
                          fetch_prev_job(empId, val.target.value==='/0' || val.target.value==='all' ? 'all' : currObj.original_name!==null ? currObj.original_name : currObj.waste_type_str,  currObj ? currObj.id : null, setDataLoader)
                     }}
                 >
                  {/* <MenuItem value={'/0'}>Choose by Site Profiles</MenuItem> */}
                 <MenuItem value={'all'}>{ typeOfWasteList && typeOfWasteList.length===0 ? '-No Site Profiles Available-' :
                 typeOfWasteList.filter(el => el.child_exists&&(!el.hidden)).length===0 ? 
                 `${typeOfWasteList.filter(el => el.child_exists&&(el.hidden)).length} Site Profiles with Unaccepted Quote` :
                 'All'}</MenuItem> 
                 {
                    typeOfWasteLoader ? <MenuItem>Loading...</MenuItem>
                    :
                    typeOfWasteList && (typeOfWasteList.length===0 || typeOfWasteList.filter(el => el.child_exists&&(!el.hidden)).length===0) ?
                    null
                    :
                    typeOfWasteList && typeOfWasteList.length!==0 && typeOfWasteList.map((item, k) => {
                       return(
                          <MenuItem value={item.id}>{item.waste_type_str}{item.original_name!==null ? `(${item.original_name})` : null}</MenuItem>
                       )
                    })
                 }
                 
                 </Select>
             </FormControl>
         )
     } 

const ScrollPos = (val) =>{
   const { top } = val
   if(top===1 && tablePage!==null){ 
      fetch_prev_job(empId, 'all', null, setDataLoader, tablePage+1)      
   } 
}

const cropName = (txt) =>{
   return txt && txt.length > 10 ? `${txt.substring(0,4)}...${txt.substring(txt.length-3, txt.length)}` : txt;
}

    return (
     <div className='client-work-layout'  style={{ flexDirection:'column', overflow: 'auto' }}>
        <div className='client-work-top-layout' style={{ width:'99%', alignSelf:'center' }}>
            <p style={{ fontSize: '1.1rem', marginTop:'1%' }}>Previous Jobs</p>
            {
               tabType==='waste' ?
            <div className="navbar-button sales-table-filter-sites-input"
               style={{ width: typeOfWasteList && (typeOfWasteList.filter(el => el.child_exists&&(!el.hidden)).length===0) ? '45%' : '25%', 
               backgroundColor: typeOfWasteList && (typeOfWasteList.length===0 || typeOfWasteList.filter(el => el.child_exists&&(!el.hidden)).length===0) ? 'var(--cl_ivery)' : 'var(--cl_blue)' }}>
               <SortDropdown />
            </div>   : null
            }
        </div>
        <Scrollview style={{ height:'60vh'}} >
         <div style={{ minWidth: '50rem', height:'100%'}} className='client-work-layout-left'>
           
           {
              prevJob.length > 0 ? (
               <table >
                  <tbody>
                     <tr  className='client-work-table-head'>
                     <td className='client-work-table-title1'>
                        Service Date
                     </td>
                     <td className='client-work-table-title2'>
                        Job code
                     </td>
                     <td className='client-work-table-title3'>
                        Frequency
                     </td>
                     <td className='client-work-table-title4'>
                        Invoice
                     </td>
                     <td className='client-work-table-title5'>
                        Sales Person
                     </td>
                     <td className='client-work-table-title6'>
                        Paid Status
                     </td>
                     {  tabType === 'waste' ?
                     <td className='client-work-table-title6'>
                        Type of Waste
                     </td> : null 
                     }
                     <td className='client-work-table-title6'>
                        Job Status
                     </td>
                     <td className='client-work-table-title6'>
                        Action
                     </td>
                     </tr>
                     <Scrollbars style={{ height:'20vh' }}  onUpdate={ScrollPos} >
                     {
                        dataLoader ?
                        
                           [...Array(5)].map((_,k)=>{
                               return(
                                   <tr key={k} className='textLineSkelton'>
                                       <td   className='client-work-table-title1'></td>
                                       <td   className='client-work-table-title2'></td>
                                       <td   className='client-work-table-title3'></td>
                                       <td  className='client-work-table-title4'></td>
                                       <td  className='client-work-table-title5'></td>
                                       <td  className='client-work-table-title6'></td>
                                       <td  className='client-work-table-title6'></td>
                                       { tabType==='waste' ? <td  className='client-work-table-title6'></td> : null }
                                       <td  className='client-work-table-title6'></td>
                                   </tr>
                               )
                           })
                       
                        :
                        prevJob.map((item,key)=>{
                           // let img_url = HOST_WITHOUT_SLASH + item.dp
                           return(
                              // (key >= tablePage-6) && (key < tablePage) ?
                              // <Link to={route_path+`/job-details/${item.id}`} style={{ textDecoration: 'none' }}> 
                              <tr className='client-work-table-body-content' 
                                 style={{ backgroundColor:key%2 ? 'rgb(240, 241, 242)' : 'white', height:'auto', alignItems:'center' }}
                              key={key}>
                                 <td  className='client-work-table-title1'>{item && item.schedule ? moment(item.schedule.start_date).format('DD-MM-YYYY') : '-'}</td>
                                 <td  onClick={()=> fetchQuoteInfos(item.id)}  
                                 className='client-work-table-title2 tdButton'>{item.code}</td>
                                 <td  className='client-work-table-title3'>{item.reoccurring ? `${item.frequency}-Weeks` : '-'}</td>
                                 <td  className='client-work-table-title4'>$&nbsp; {item.amount}</td>
                                 <td  className='client-work-table-title5'>
                                    <Tooltip title={item.sales_person}>
                                    <div className="ohs-table-user-field" style={{ height: 27 }}>
                                       {/* <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={img_url} alt="" /> */}
                                       <p>{cropName(item.sales_person)}</p>
                                    </div>
                                    </Tooltip>
                                 </td>
                                 <td  className='client-work-table-title6'>{item.paid_status}</td>
                                 { tabType==='waste' ? 
                                    <td  className='client-work-table-title6'>
                                       <Tooltip title={item.type_of_waste_str_profile_name ? `${item.type_of_waste_str_profile_name} (${item.waste_type_str})` : item.waste_type_str}>
                                       <div className="ohs-table-user-field" style={{ height: 27 }}>
                                          {/* <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={img_url} alt="" /> */}
                                          <p>{cropName(item.type_of_waste_str_profile_name ? `${item.type_of_waste_str_profile_name} (${item.waste_type_str})` : item.waste_type_str)}</p>
                                       </div>
                                       </Tooltip>
                                    </td> : null }
                                 <td  className='client-work-table-title6'>{ item && item.schedule ?  
                                 item.schedule.status === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                                 :   item.schedule.status === 'job_started'    ? 'Job Started'
                                 :   item.schedule.status === 'job_finished'   ?   'Job Finished'
                                 :   item.schedule.status === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                                 :   item.schedule.status === 'departed_waste_depot' ? 'Departed Waste Depot'
                                 :   item.schedule.status === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                                 :   item.schedule.status : '-'}</td>
                                 <td  className='client-work-table-title6'>
                                    {
                                       (!item.reoccurring && (item.schedule ? item.schedule.status==='completed' : false)) ?
                                       <div className="navbar-button sales-table-REshd"
                                          style={{ backgroundColor: item.rescheduled ? 'var(--cl_ivery)' : 'var(--cl_blue)', color: item.rescheduled ? 'black' : 'white',
                                          fontSize : 'inherit',
                                          pointerEvents : item.rescheduled ? 'none' : 'auto' }}
                                          onClick={() => {
                                             let filter = 'start='+ moment().format('YYYY-MM-DD')
                                             filter = filter + ' ' + moment().format('HH:mm:ss') + '&&end='+moment().add(1,'day').format('YYYY-MM-DD')
                                             filter = filter + ' ' + moment().add(1,'day').format('HH:mm:ss')
                                             fetch_Emp_availability(filter, null)
                                             console.log("UPDATE AVAILABILITIES")
                                             fetch_Veh_availability(filter, tabType, null)
                                                 setSelectedJob(item)
                                                 setMockId(item.id)
                                                 setJobModalStatus(true)
                                         }}
                                          
                                          >
                                             { item.rescheduled ? 'Rescheduled' :  'Reschedule'}
                                       </div>
                                       : '-'
                                    }
                                 </td>
                              </tr>
                              //</Link> 
                              // : null
                           )
                        })
                     }
                     </Scrollbars>
               </tbody>
            </table>
            ) : (<NoDatasScreen />)
           }
         
            
        </div> 
        </Scrollview>
        {/* <div className='client-work-layout-right'> */}
           {/* <div className='client-work-bt1-layout' >
              <div className='client-work-bt1' onClick={()=>setGraphdiv(1)}>Expand</div> 
           </div>  */}
           {/* <div className='client-work-bt2' >All</div> 
           <div className='client-work-bt3' >invoice</div> 
           <div className='client-work-bt4' >Quote</div>  */}
        {/* </div>                     */}
        <QuoteModal 
            modalStatus={quoteModal}
            modalAction={setquoteModal}
            quoteInfos={quoteInfos}
            setQuoteHistoryInfos={setQuoteHistoryInfos}
            quoteHistoryModalAction={setQuoteHistoryModal}
            fnRecall={()=>{
               setDataLoader(true)
               fetch_prev_job(empId, 'all', null, setDataLoader, 1)
            }}
        />
        <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}/>

         <JobModal
                screen_type={tabType}
                modalStatus={jobModalStatus}
                modalAction={setJobModalStatus}
                selectedJob={selectedJob}
                selected_team={slEmployee}
                setSelectTeam={setSlEmployee}
                isMock={mockId}
                scheduled={true}
                reSchedule={true}
            />

    </div>
    )
}



const mapStateToProps = (state) => {
   const { prevJob, waste_type_filter, tablePage } = state.client
   return { prevJob, waste_type_filter, tablePage} 
}

const mapDispatchToProps = (dispatch) => {
   return {
       fetch_prev_job: (id, wasteType, str, fn, pg) => dispatch(previous_jobs_fetch(id, wasteType, str, fn, pg)),
       fetch_Emp_availability: (filter, callBackFn) => dispatch(fetch_Employee_avilabilities(filter, callBackFn)),
       fetch_Veh_availability: (filter,screen_type, callBackFn) => dispatch(fetch_Vehicle_avilabilities(filter, screen_type, callBackFn)),
     
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (Work)