import React from 'react';

function PartListSuctionFlange({modalType, suctionFlange, setSuctionFlange}) {

    return (
        <>
            <div className="jobCardDivHead" >Suction Flange</div>
                    <div className="jobCardInputDivSec" >
                        <div>Table</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.table}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Table" value={suctionFlange.table} 
                            onChange={(e)=>setSuctionFlange({ ...suctionFlange, table : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Diameter</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.diameter}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Diameter" value={suctionFlange.diameter} 
                            onChange={(e)=>setSuctionFlange({...suctionFlange, diameter : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pitch Circle Diameter (PCD)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.pcd}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pitch Circle Diameter (PCD)" value={suctionFlange.pcd} 
                            onChange={(e)=>setSuctionFlange({...suctionFlange, pcd : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pressuring Rating (PR)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.pr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pressuring Rating (PR)" value={suctionFlange.pr}
                             onChange={(e)=>setSuctionFlange({...suctionFlange, pr : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Number of holes</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.noOfHoles}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Number of holes" value={suctionFlange.noOfHoles} 
                            onChange={(e)=>setSuctionFlange({...suctionFlange, noOfHoles : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Bolt Length</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.bolt}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Bolt Length" value={suctionFlange.bolt} 
                            onChange={(e)=>setSuctionFlange({...suctionFlange, bolt : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Bossed Flange</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.boss}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Bossed Flange" value={suctionFlange.boss} 
                            onChange={(e)=>setSuctionFlange({...suctionFlange, boss : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pipe Diameter (PD)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{suctionFlange.pipe}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pipe Diameter" value={suctionFlange.pipe} 
                            onChange={(e)=>setSuctionFlange({...suctionFlange, pipe : e.target.value})} />
                            }
                        </div>
                    </div>
                    

        </>
    );
}

export default PartListSuctionFlange;