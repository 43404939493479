import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import Scrollbars from './ImprovedScrollBar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const TimeMeridium = (v) =>{
    return moment(v,'HH:mm:ss').format('hh:mm A')
}

const DateFormatting = (v) =>{
    return moment(v,'YYYY-MM-DD').format('DD/MM/YYYY')
}

const Teamvehdelprevntmodal = ({ modalStatus, modalAction, modalType='v', apiStatus  }) => {

    const classes = useStyles();

    const handleClose = () => {
        modalAction(false)
    }


    return (
        <Modal
            className={classes.modal}
            open={modalStatus}
            onClose={() => handleClose(false)}>
                <div className='teamVehDelPrevModalBox' style={{ overflowY:'auto' }}>
                    <div style={{ height:'5%', display:'flex', flexDirection:'row' }}>
                        <div className="header-button-top"  onClick={()=>  handleClose() }>
                            Back
                        </div>     
                    </div>
                    <div className='teamVehDelPrevModalContent'>
                        {
                            !apiStatus ? 
                                <div className='teamVehDelPrevModalContentLoaderPage'>
                                    <p>
                                        Checking if any job is scheduled with this {modalType==='v' ? 'vehicle' : 'team member'}
                                    </p>
                                    <div>
                                        <CircularProgress/>
                                    </div>
                                </div>
                            :
                                apiStatus ?
                                <div className='teamVehDelPrevModalContentTable'>
                                    <div style={{ width:'100%', margin:'1%', padding:'1% 0', backgroundColor:'rgba(255,0,0,0.1)', textAlign:'center', borderRadius:'5px', border:'1px solid red' }}>
                                        This {modalType==='v' ? 'vehicle' : 'team member'} cannot be deleted (Due to existing schedules)
                                    </div>
                                    <Scrollbars style={{ height:'35vh' }}>
                                    <table style={{ height:'auto' }}>
                                        <thead>
                                            <th>Job Code</th>
                                            <th>Site</th>
                                            <th>Scheduled For<p style={{ fontSize:'.8rem', margin:'1% 0' }}>{`\n(Start date and time - End date and time)`}</p></th>
                                            <th>Scheduled As</th>
                                        </thead>
                                        <tbody>
                                            
                                            {apiStatus.map((item,k) => {
                                                return (
                                                    <tr key={k} style={{ backgroundColor: k%2 ? 'whitesmoke' : 'white' }}>
                                                        <td className="jbCode">Job Code {item.job_code}</td>
                                                        <td>{item.site}</td>
                                                        <td>{DateFormatting(item.start_date)} at {TimeMeridium(item.start_time)} to {DateFormatting(item.end_date)} at {TimeMeridium(item.end_time)}</td>
                                                        <td>
                                                            <span style={{ textTransform:'capitalize' }}>{item.scheduled_as ? item.scheduled_as.scheduled_as : null }</span>
                                                            {item.scheduled_as && (item.scheduled_as.scheduled_as==='primary' || item.scheduled_as.scheduled_as==='additional') ? 
                                                                ` ${modalType==='v' ? 'vehicle assigned to ' : 'driver assigned to '}` : null}
                                                            {item.scheduled_as ? modalType==='t' ? item.scheduled_as.vehicle_registration : item.scheduled_as.driver_name : null}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                    </Scrollbars>
                                    
                                </div>
                                :
                                <div>
                                    <CircularProgress/>
                                </div>

                        }
                    </div>
                </div>

        </Modal>
    );
}

export default Teamvehdelprevntmodal;
