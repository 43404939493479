import React, { useState } from 'react'
import { Edit, DeleteForever, FolderOpen, ArchiveRounded, UnarchiveRounded } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
    fetch_truck_list, __delete_vehicle, __archive_vehicle, fetch_search_trucks
    //, __clear_fleet_list, __set_fleet_vehicle, truck_folders, truck_file_upload, truck_file_delete 
} from './common/action';
import Swal from 'sweetalert2'
import { cl_red, cl_green } from '../../../../common/Colors'
import Modal from './Modelfleet'
import FolderModal from './FolderModal'
// import FolderCreateModal from '../../../team/component/FolderCreateModal'
import {
    HOST_WITHOUT_SLASH,
    // VEHICLE_FOLDERS_ADD, VEHICLE_FOLDERS_EDIT 
} from '../../../../store/endpoint';
//import Api from '../../../../store/api'
import moment from 'moment';
import Scrollbars from '../../../../common/ImprovedScrollBar';
import ErrorHandling from '../../../../common/ErrorHandling';
import Teamvehdelprevntmodal from '../../../../common/TeamVehDelPrevntModal';
import Preventdelete from '../common/PreventDelete';
import { Tooltip } from 'antd';
// import { CircularProgress } from '@material-ui/core'

function TruckListTable({
    select_vehicle,
    truck_list,
    truck_page,
    modal_action,
    img_modal_action,
    delete_vehicle,
    archive_vehicle,
    // clear_fleets,
    // set_fleet_vehicle,
    tabType,
    isLoading,
    perms,
    fetch_trucks,
    truck_stoppagination,
    searchFnOpen,
    fetch_search_trucksFx,
    truck_list_loader,
    truck_list_error,
    truck_search_keyword

}) {


    let balance_row = 0
    if (truck_list.length < 8) {
        balance_row = 8 - truck_list.length
    }


    const [modal, setModal] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState()
    const [selectedTruckId, setSelectedTruckId] = useState(null)
    const [folderModal, setFolderModal] = useState(false)
    // const [pageLoader, setPageLoader] = useState(false)
    const [imageHover, setImageHover] = useState(null)
    const [apiRes, setApiRes] = useState(null)

    const [deleteModal, setDeleteModal] = useState(false)

    const delete_btn_click = (vehicle_id) => {
        const success = (response) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Vehicle deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                delete_vehicle(tabType, vehicle_id, success, failed, truck_page)
            }
        })
    }



    const fetch = () => {
        if (!truck_list_loader) {
            if (truck_search_keyword === '') {
                fetch_trucks(tabType, truck_page )
            }else{
                fetch_search_trucksFx(tabType, truck_page, truck_search_keyword)
            }
            
        }

    }
    const ScrollUp = (val) => {
        const { top } = val


        if (top === 1 && !truck_stoppagination && !truck_list_loader && truck_list_error === null) {
            console.log('SCROLL CALLED')
            if (truck_search_keyword === '') {
                fetch_trucks(tabType, truck_page + 1)
            }else{
                fetch_search_trucksFx(tabType, truck_page + 1, truck_search_keyword)
            }
        }
    }

    const archiveFn = (val, st=false) =>{
        const success = (response) => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Vehicle successfully ${st ? ` un-archived` : 'archived'}!`,
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes, ${st ? ` Un-archive ` : ` Archive `} it!`
        }).then((result) => {
            if (result.value) {
                archive_vehicle(tabType, val, st, success, failed, truck_page)
            }
        })
    }


    return (
        <div className="TruckListTable">
            <div className="truck-table-list">
                <Scrollbars style={{ height: '70vh' }} className='table-details-truck' onUpdate={ScrollUp}>
                    {/* <div className="table-details-truck"> */}

                    <table className="table-details-truck-tbl"
                        style={{ position: 'relative', height: 'auto' }}>
                        <thead>
                            <tr style={{ height: 20 }} className="heading-truck-table">
                                <th>Image</th>
                                {/* <th>Previous REGO</th>  */}
                                <th>Rego Due</th>
                                <th>Registration</th>
                                <th>Type</th>
                                <th>Year</th>
                                <th>Transmission</th>
                                <th>Fuel</th>
                                <th>Height</th>
                                <th>Width</th>
                                <th>Length</th>
                                <th>Litres</th>
                                <th>Axles</th>
                                <th>E-Tag</th>
                                <th>VIN NUMBER</th>
                                <th style={{ width: '10%' }}>Daily Pre-inspection Required</th>
                                <th>Actions</th>
                            </tr>
                        </thead>


                        <tbody>
                            {
                                truck_list && truck_list.length === 0 ?
                                    ((!truck_list_loader && truck_list_error === null) &&
                                        <tr><td colSpan='14' >No Vehicles Created</td></tr>) : null
                            }
                            {

                                truck_list.map((item, key) => {
                                    const { id, registration, image1, types, year, transmission, fuel, height, width, length, litres, axies, vin_number, due_rego, e_tag } = item
                                    return (
                                        <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'transparent' }}>
                                            <td style={{ position: 'relative', height: '6vh', objectFit: 'cover', maxWidth: "7vh" }}
                                                onMouseEnter={() => setImageHover(key)}
                                                onMouseLeave={() => setImageHover(null)}
                                            >
                                                <img style={{ height: '6vh', width: '100%' }} src={HOST_WITHOUT_SLASH + image1} alt="truck" />
                                                {imageHover === key ?
                                                    <div className="tableImageHoverTile" onClick={() => {
                                                        select_vehicle(item)
                                                        img_modal_action(true)
                                                    }
                                                    }>
                                                        <Edit style={{ fontSize: 18, margin: 0, padding: 0 }} />
                                                    </div>
                                                    : null
                                                }
                                            </td>

                                            <td>{moment(due_rego).isValid() ? moment(due_rego).format('DD-MM-YYYY') : "Date Not Provided"}</td>
                                            <td>{registration}</td>
                                            <td style={{ wordBreak:'break-word', maxWidth: '7vw' }}>{types}</td>
                                            <td>{year}</td>
                                            <td>{transmission}</td>
                                            <td>{fuel}</td>
                                            <td>{height}</td>
                                            <td>{width}</td>
                                            <td>{length}</td>
                                            <td>{litres}</td>
                                            <td>{axies}</td>
                                            <td>{e_tag}</td>
                                            <td style={{ wordBreak:'break-word' }}>{vin_number}</td>
                                            <td>{item?.preinspection_required ? 'Yes' : 'No'}</td>
                                            <td>
                                                <div className="table-action-field">
                                                    <div className="table-action-btn"
                                                        onClick={() => {
                                                            // setFolderLoader(true)
                                                            setSelectedTruck(registration)
                                                            setSelectedTruckId(id)
                                                            // truck_folders(id, apiResponseCallBack)
                                                            setModal(true)
                                                        }}
                                                    >
                                                        <FolderOpen style={{ fontSize: 20, margin: 0, padding: 0 }} />
                                                    </div>
                                                    {perms.vehicle.edit ?
                                                    <Tooltip title="Edit Vehicle">
                                                        <div
                                                            onClick={() => {
                                                                select_vehicle(item)
                                                                modal_action(true)
                                                            }}
                                                            className="table-action-btn"
                                                        >
                                                            <Edit style={{ fontSize: 20, margin: 0, padding: 0 }} />
                                                        </div>
                                                    </Tooltip>
                                                         : null}
                                                    {perms.vehicle.delete ?
                                                        <>
                                                        <Tooltip title="Delete Vehicle">
                                                            <div onClick={() =>{
                                                            if(tabType==='waste'){
                                                                
                                                                Preventdelete(id,  setApiRes, setDeleteModal,
                                                                function(){
                                                                     delete_btn_click(id)}, 
                                                                function(){
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'Oops...',
                                                                        text: 'Something went wrong!'
                                                                    })
                                                                })
                                                            }
                                                            else{
                                                                delete_btn_click(id)
                                                            }
                                                            }} className="table-action-btn">
                                                            <DeleteForever style={{ fontSize: 20, margin: 0, padding: 0 }} />
                                                        </div>
                                                        </Tooltip>
                                                        {
                                                            item?.is_archived ?
                                                        <Tooltip title="Archive Vehicle">
                                                            <div onClick={() =>{
                                                                archiveFn(id, true)
                                                                }} className="table-action-btn">
                                                                <UnarchiveRounded style={{ fontSize: 20, margin: 0, padding: 0, color: 'var(--cl_red)' }} />
                                                            </div>
                                                        </Tooltip>
                                                            :
                                                        <Tooltip title="Archive Vehicle">
                                                            <div onClick={() =>{
                                                                archiveFn(id)
                                                                }} className="table-action-btn">
                                                                <ArchiveRounded style={{ fontSize: 20, margin: 0, padding: 0, color: 'var(--cl_blue)' }} />
                                                            </div>
                                                        </Tooltip>
                                                        }
                                                        
                                                        

                                                        </>
                                                        : null}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {(!truck_list_loader && truck_list_error === null) &&
                                [...Array(balance_row)].map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        {truck_list_loader && <tbody className='skeleton'>
                            {
                                [...Array(8)].map((_, key) => {
                                    return (
                                        <tr style={{height:'40px'}}>
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td></td><td></td><td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>}
                        {
                            truck_list_error && <ErrorHandling error={truck_list_error} fetch={fetch} />
                        }
                    </table>


                </Scrollbars>
                {/* </div> */}
                {/* <div className="truck-table-back-previous-btn">
                    <div className="pagination-btn-layout">
                        {
                            truck_page > 1 ? (
                                <button
                                    onClick={() => {
                                        fetch_trucks(tabType, truck_page - 1, setPageLoader)
                                    }}
                                    className="table-list-next-btn">PREVIOUS</button>
                            ) : (
                                <button className="table-list-next-btn disabled-btn">PREVIOUS</button>
                            )
                        }
                        <div className="page-no">
                            <p className="p-0 m-0">{page_no}</p>
                        </div>
                        {
                            truck_list.length === 8 ? (
                                <button
                                    onClick={() => {
                                        fetch_trucks(tabType, truck_page + 1, setPageLoader)
                                    }}
                                    className="table-list-next-btn">NEXT</button>
                            ) : (
                                <button className="table-list-next-btn disabled-btn">NEXT</button>
                            )
                        }

                    </div>
                </div> */}


            </div>
            <Modal
                modalStatus={modal}
                modalAction={setModal}
                // mainFolder={setFolderModal}
                vehicle_reg={selectedTruck}
                vehicle_id={selectedTruckId}
                // folderLoader={folderLoader}
                // folderResponse={folderResponse}
                // folderError={folderError}
                // folderStatus={folderStatus}
                // updationFunction={uploadFileFeedback}
                // deletion={deleteFile}
                // setFolderCreateModal={setFolderCreateModal}
                // setFolderName={setFolderName}
                // setModalType={setModalType}
                // selectedFolderId={selectedFolderId}
                // setSelectedFolderId={setSelectedFolderId}
                // deleteFolderApiCall={deleteFolderApiCall}
                tabType={tabType}
                vehicleType={'truck'}
                perms={perms}
            />

            <FolderModal
                modalStatus={folderModal}
                modalAction={setFolderModal}
                tabType={tabType}
            />

            <Teamvehdelprevntmodal modalType={'v'} modalStatus={deleteModal} modalAction={setDeleteModal} apiStatus={apiRes} />
            {/* <FolderCreateModal 
            modalStatus={folderCreateModal}
            modalAction={setFolderCreateModal}
            newFolder={modalType}
            folderName={folderName}
            folderNameAction={setFolderName}
            createAction={createFolderApi}
            updateAction={updateFolderNameApiCall}
            // folderId={zFolderId}
        /> */}
        </div >
    )
}

const mapStateToProps = (state) => {
    const { truck_list, truck_page, isLoading, truck_list_loader,
        truck_stoppagination, truck_list_error, truck_search_keyword } = state.vehicle__truck
    return {
        truck_list, truck_page, isLoading, truck_stoppagination,
        truck_list_loader, truck_list_error, truck_search_keyword
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        delete_vehicle: (tabType, vehicle_id, success, failed, page) => dispatch(__delete_vehicle(tabType, vehicle_id, success, failed, page)),
        archive_vehicle: (tabType, vehicle_id, archiveStatus, success, failed, page) => dispatch(__archive_vehicle(tabType, vehicle_id,archiveStatus, success, failed, page)),
        fetch_trucks: (tabType, page, callBk = null) => dispatch(fetch_truck_list(tabType, page, callBk)),
        fetch_search_trucksFx: (tabType, page, txt) => dispatch(fetch_search_trucks(tabType, page, txt))
        // clear_fleets: () => dispatch(__clear_fleet_list()),
        // set_fleet_vehicle: (id) => dispatch(__set_fleet_vehicle(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckListTable)
