import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { create_news, create_notify, edit_news, delete_news, delete_notify, fetch_news_list, fetch_notify_list, reply_notify } from '../common/action'
import { team_list } from '../../team/action'
import Swal from 'sweetalert2'
// import Scrollbar from 'react-custom-scrollbars'
import { HOST_WITHOUT_SLASH } from '../../../store/endpoint'
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core/styles'
import { Modal, Popover } from '@material-ui/core'
import { Close, HighlightOff, SendRounded } from '@material-ui/icons'
// import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import { cl_green, cl_red } from '../../../common/Colors';
import Scrollbars from '../../../common/ImprovedScrollBar'
import { TextAreaLimiter } from '../../../common/TextAreaLimiter'
// import {HOST} from '../../../store/endpoint'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const cropFileName = (str) =>{
    let lastIdx = str ? str.length : null
    if(lastIdx)
        return lastIdx > 25 ? `${str.substr(0,10)}...${str.substr(str.length-3, str.length)}` : str 
    else 
        return 'Untitled'
}


const SingleFile = ({ item, url }) => {

    let fileUrl = HOST_WITHOUT_SLASH + url
    return (
            <div className="intranet-main-file-card">
                <div onClick={() => window.open(fileUrl, "_blank")}  className="main-file-icon">
                    <DescriptionIcon style={{ fontSize: 40 }} />
                </div>
                <div className="main-file-title">
                    <div style={{ display: 'block' }}>
                        <p style={{ height: 15, overflow: 'hidden' }}>{cropFileName(item)}</p>
                    </div>
                </div>
            </div>
    )
}


function CreateNewModal ({
    modalStatus,
    modalAction,
    createType,
    setModalType,
    dataObj,
    team_list,
    team_list_fx,
    create_news_fx,
    create_notify_fx,
    delete_news_fx,
    delete_notify_fx,
    edit_news_fx,
    pageNo,
    fnCallBk=null,
    permissions,
    replyComent
}) {
    const classes = useStyles();
    
    
    useEffect(() => {
        team_list_fx()
        return () => team_list_fx.remove
    }, [team_list_fx]);

    
    const [ fileObj, setFileObj]    = useState(null)
    const [ title, setTitle ]       = useState(dataObj!==null ? dataObj.title : '')
    const [ desc, setDesc ]         = useState(dataObj!==null ? dataObj.description : '')
    const [ memList, setMemList]    = useState([])
    const [ objDataId, setObjDataId] = useState("")
    const [anchorEl, setAnchorEl]   = useState(null);
    const [ replyComm, setReplyComm] = useState('')
    const [textAreaValid, setTextAreaValid] = useState(true)

    const handleClose = () => {
        if(fnCallBk!==null){
            fnCallBk(true)
        }
        selectAllMem(false)
        setFileObj(null)
        setTitle("")
        setDesc("")
        modalAction(false)
    };
    
    const handleClosePop = () => {
        setAnchorEl(null);
      };

    const handleClickPop = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const onSelMemList = (memNo) =>{
        if(memList.some(item => memNo === item)){
            let newList = memList.filter(item => item!==memNo)
            setMemList(newList)
        }
        else{
            setMemList(data => [...data, memNo])
        }
    }

    const createAction = () =>{
        
        let postData = new FormData()
        postData.append('title',title)
        postData.append('description',desc)
        for(let i=0; i<memList.length; ++i){
            postData.append('members',memList[i])
        }
        if(fileObj)
            postData.append('file_attachment', fileObj)
        

        const success = (res) => {
            setTitle('')
            setDesc('')
            setMemList([])
            setFileObj(null)
            let typeCreate = ''
            if(createType === "Add News"){
                typeCreate = "News"
                fetch_news_list(1)
            }
            else{
                typeCreate = "Notification"
                fetch_notify_list(1)
            }
            if(fnCallBk!==null){
                fnCallBk(false)
            }
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `${typeCreate} Addedd successful!`,
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            setTitle('')
            setDesc('')
            setMemList([])
            setFileObj(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
        }

        if(createType === "Add News")
            create_news_fx(postData, success, failed)
        else
            create_notify_fx(postData, success, failed)
        
        handleClose()
    }

    const editAction = (id) =>{
        let postData = new FormData()
        postData.append('title',title)
        postData.append('description',desc)
        for(let i=0; i<memList.length; ++i){
            postData.append('members',memList[i])
            console.log(memList[i])
        }
        if(fileObj!==dataObj.fileName)
            postData.append('file_attachment', fileObj) 
                
        const success = (res) => {
            setTitle('')
            setDesc('')
            setMemList([])
            setFileObj(null)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `News Edited successfully!`,
                showConfirmButton: false,
                timer: 1500
            })
            console.log('News=>',res)
            // fetch_news_list(pageNo)

        }

        const failed = (res) => {
            setTitle('')
            setDesc('')
            setMemList([])
            setFileObj(null)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })

        }

       edit_news_fx(pageNo, id, postData, success, failed)
       handleClose()

    }

    const deleteAction = (id) =>{
        handleClose()
        if(fnCallBk!==null){
            fnCallBk(false)
        }
        
             
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            const success = () => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `${createType} deleted successful!`,
                    showConfirmButton: false,
                    timer: 1500
                })
                // if(createType==="News")
                //         fetch_news_list(pageNo)
                //     else
                //         fetch_notify_list(pageNo)
                if(fnCallBk!==null){
                                fnCallBk(false)
                            }
                            
            }
    
            const failed = () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })

            }
            
            if (result.value) {
                if(createType==="News")
                    delete_news_fx(pageNo, id, success, failed)
                else
                    delete_notify_fx(pageNo, id, success, failed)
            }
        })
        
    }

    const selectAllMem = (val) =>{
        if(val){
            let nArr = team_list.filter(el => !el.terminated_status).map(item => { return( item.id ) })
            setMemList(nArr)
        }else{
            setMemList([])
        }
    }

    const sendReply =(id)=> {
        let dataForm = new FormData()
        dataForm.append('comment',replyComm)

        const success = (res) => {
            setTitle('')
            setDesc('')
            setMemList([])
            setFileObj(null)
            setReplyComm(null)
            handleClose()

            // let typeCreate = ''
            // if(createType === "Add News"){
            //     typeCreate = "News"
            //     fetch_news_list(1)
            // }
            // else{
            //     typeCreate = "Notification"
            //     fetch_notify_list(1)
            // }
            if(fnCallBk!==null){
                fnCallBk(false)
            }
            
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Comment Addedd successful!`,
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
        }

        replyComent(dataForm, id, success, failed)
     }


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >
            <div className="intranet-folder-modal-layout" style={{ overflowY:'auto' }}>
                <div className="intranet-modal-title-layout">
                    
                        <p style={{ fontSize:'1.1rem' }}>{createType}</p>
                    
                    
                    <Close onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </div>
                {
                    createType==="News" || createType==="Notification" ?
                <p style={{ fontWeight:'500', fontSize:'20px', textAlign:'center' }}>{dataObj.title}</p>
                :
                <input 
                    placeholder="Title"
                    className="intranet-input"
                    value={title}
                    onChange={txt => setTitle(txt.target.value)}
                />
                }
                {
                    createType==="News" || createType==="Notification" ?
                <p style={{ fontWeight:'300', fontSize:'16px', padding:'10px' }}>{dataObj.description}</p>
                :
                <TextAreaLimiter isValid={setTextAreaValid}
                configs={{
                    rows : "50",
                    placeholder : "Description",
                    className : "intranet-input intranet-input-desc",
                    value : desc,
                    onChange : (txt) => setDesc(txt.target.value)
                }}
                v={desc} />
                
                }
                {
                    createType==="Notification" ?
                    <div className="notificationCommentsListDiv"  style={{ fontSize:'.9rem' }}>
                        {console.log(dataObj)}
                        {   dataObj.comments && dataObj.comments.length!==0 ?
                        <Scrollbars style={{ height:'20vh' }}>
                            {
                                dataObj.comments ? dataObj.comments.map((el, k)=>{

                                    return(
                                        <div key={k} className="notificationCommentsDiv">
                                            <p>{el.comment_by}</p>
                                            <span>{el.comment}</span>
                                        </div>
                                    )
                                })
                                :
                                <p>No comments</p>
                            }
                        </Scrollbars>
                        : <p>No comments</p>
                        }
                    </div>
                    : null
                }
                {
                     createType==="Notification" ?
                    <div className="notifyCommentReply">
                        <input 
                            placeholder="Reply"
                            className="intranet-input"
                            value={replyComm}
                            onChange={txt => setReplyComm(txt.target.value)}
                        />
                        <SendRounded onClick={()=>{ sendReply(dataObj.id) }}/>             
                    </div>
                    :
                    null
                }
                {
                    (fileObj!==null) && createType!=="Edit News" ?
                    <SingleFile item={fileObj.name} url={URL.createObjectURL(fileObj)} key={1} />  :   null
                }
                {   (createType==="News" || createType==="Notification") && dataObj.mem ?
                    <div>
                    <div className="add-files-btn" onClick={ handleClickPop }>
                        <ArrowDropDownCircleOutlinedIcon style={{ marginRight: 7 }} />
                            <p>Members</p>
                    </div>
                    <Popover 
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePop}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <div className="memList-popOver" style={{ height:'auto', maxHeight:'40vh', overflowY:'auto' }}>
                        {/* <Scrollbar style={{ height: '40vh', width:'10vw' }}> */}
    
                            {
                                dataObj.mem.map((item, key) => {
                                    if(key<4)
                                    return(
                                    <div
                                        className="ohs-table-user-field memListItem " 
                                        key={key}>
                                        <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={item.dp} alt="" />
                                        <p>{item.name}</p>
                                    </div>
                                    )
                                    else return null
                                })
                            }
                        {/* </Scrollbar> */}
                      </div>
                
                    </Popover>
                </div>  :   null
                }
                {
                    createType==="News" || createType==="Notification" || createType==="Edit News"  ?

                    fileObj===null && createType==="Edit News" ?

            <div style={{ position:'relative' }}>
                <label htmlFor="file-input">
                    <div className="add-files-btn">
                        <AddCircleOutlineIcon style={{ marginRight: 7 }} />
                        <p>Files</p>
                    </div>
                </label>

                <input id="file-input" 
                    style={{ display: 'none' }} 
                    type="file"
                    name="files"
                    onChange={(image) => {
                        console.log(typeof image.target.files, image.target.files[0])
                        // setFilesData(image.target.files)
                        setFileObj(image.target.files[0])
                    }}
                />
            </div>
            :
                    
                <div className="intranet-files-layout">
                    { dataObj.fileName ?
                        <SingleFile item={dataObj.fileName} url={dataObj.fileName} key={1} />
                        :
                        null
                    }
                    {
                        createType==="Edit News" ?
                    <HighlightOff 
                        style={{ fontSize: 18 }} 
                        className="delete-icon-btn"
                        onClick={()=>{ setFileObj(null) }}
                    />  : null
                    }
                </div>              
            
                :
                <div style={{ display:'flex', flexDirection:'row', padding:'10px' }}>
                    <div style={{ position:'relative' }}>
                        <label htmlFor="file-input">
                            <div className="add-files-btn">
                                <AddCircleOutlineIcon style={{ marginRight: 7 }} />
                                <p>Files</p>
                            </div>
                        </label>

                        <input id="file-input" 
                            style={{ display: 'none' }} 
                            type="file"
                            name="files"
                            onChange={(image) => {
                                console.log(typeof image.target.files, image.target.files[0])
                                // setFilesData(image.target.files)
                                setFileObj(image.target.files[0])
                            }}
                        />
                    </div>
                    <div>
                        <div className="add-files-btn" onClick={ handleClickPop }>
                            <AddCircleOutlineIcon style={{ marginRight: 7 }} />
                                <p>Members</p>
                        </div>
                        <Popover 
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        
                        onClose={handleClosePop}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                        {/* <Scrollbar style={{ height:'30vh', width:'10vw' }}> */}
                          <div className="memList-popOver" style={{ height:'auto', maxHeight:'40vh', overflowY:'auto' }}>
                        {   memList.length !== team_list.length? 
                            <div className="add-files-btn" style={{ padding:'10px', textAlign:'center', justifyContent:'space-around', margin:'10px 0' }} onClick={()=> selectAllMem(true)}> <p >Select All </p> </div>  :
                            <div className="add-files-btn" style={{ padding:'10px', textAlign:'center', justifyContent:'space-around', margin:'10px 0' }} onClick={()=> selectAllMem(false)}><p > Deselct All</p> </div>
                        }
        
                                {   team_list ?
                                    team_list.map((item, key) => {
                                        if(item.terminated_status) return null 
                                        else
                                        return(
                                        <div
                                    className={ memList.some(memNo => memNo === item.id) ? "ohs-table-user-field memListItem memListSelItem" : "ohs-table-user-field memListItem " }
                                        key={key}
                                         onClick={()=> onSelMemList(item.id) }>
                                            <img style={{ height: 27, width: 27, borderRadius: 4, marginRight: 4, }} src={item.dp} alt="" />
                                            <p>{item.name}</p>
                                        </div>
                                        )
                                    })  :   <p>Loading...</p>
                                }
                          </div>
                        {/* </Scrollbar> */}
                        </Popover>
                    </div>
                </div>

                }
                {
                    createType==="News" || createType==="Notification" ?
                    dataObj.editStatus && permissions.ohs.delete && permissions.ohs.edit ?
                <div className="intranet-btn-layout">
                    <button onClick={() => deleteAction(dataObj.id)} className="intranet-cancel-btn">DELETE</button>
                    {
                        createType==="Notification" || !textAreaValid?
                        null    :
                    <button 
                        onClick={() =>{ setTitle(dataObj.title); setDesc(dataObj.description);
                            setModalType("Edit News"); setObjDataId(dataObj.id); setFileObj(dataObj.fileName); }}
                        className="intranet-create-btn">UPDATE</button>
                    }
                </div>  :   null
                :
                createType==="Edit News" ?
                <div className="intranet-btn-layout">
                <button onClick={() => handleClose()} className="intranet-cancel-btn">CANCEL</button>
                {
                    createType==="Notification" || !textAreaValid?
                    null    : 
                <button 
                    onClick={() =>{
             
                        editAction(objDataId) }}
                    className="intranet-create-btn">DONE</button>
                }
            </div>
                :

                <div className="intranet-btn-layout">
                    <button onClick={() => handleClose()} className="intranet-cancel-btn">CANCEL</button>
                    <button 
                        onClick={() => {
                            if(title && desc && textAreaValid ){
                                createAction()                    
                            }            
                            }}
                        className={
                            title && desc && textAreaValid && memList.length!==0 ? 
                            "intranet-create-btn" : "intranet-create-btn disabled-btn"}>CREATE</button>
                    
                </div>
                
                }
            </div>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { team_list } = state.team
    const { permissions } = state.userLogin
    return { team_list, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create_news_fx      : (data, success, failed)       => dispatch(create_news(data, success, failed)),
        create_notify_fx    : (data, success, failed)       => dispatch(create_notify(data, success, failed)),
        edit_news_fx        : (pageNo, id, data, success, failed)   => dispatch(edit_news(pageNo, id, data, success, failed)),
        delete_news_fx      : (pageNo, id, success, failed)         => dispatch(delete_news(pageNo, id, success, failed)),
        delete_notify_fx    : (pageNo, id, success, failed)         => dispatch(delete_notify(pageNo, id, success, failed)),
        team_list_fx        : ()                            => dispatch(team_list()),
        replyComent         : (content, id, success, failed)    => dispatch(reply_notify(content, id, success, failed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewModal)

