import React, { useState } from "react";
import './style.css'
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

const { Dragger } = Upload;

const FilesDragAndDrop = ({onUpload, keyId, textCls='', iconCls='', areaCls='' }) => {
  
  console.log('KEYID',keyId)

  const [mouseVal, setMouseVal] = useState(null)
  const [loadingState, setLoadingState] = useState(null)

  const props = {
    name: `fileInputDrag${keyId}`,
    multiple: true,
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      console.log('ONCHANGe=>',info)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status !== 'error') {
        setLoadingState(null)
      }
    },
    onDrop(e) {
      e.preventDefault();
      setLoadingState(keyId)
      console.log('Dropped files', e.dataTransfer.files,);
      if(Array.from(e?.dataTransfer?.files)?.length===0){
        setLoadingState(null)
        return
      }else{
        let transferArr = Array.from(e.dataTransfer.files)
        console.log('TRANSFERarr=>',transferArr)
      var allowedExtensions = /(\.aac|\.adt|\.adts|\.avi|\.bin|\.cda|\.csv|\.dif|\.doc|\.docm|\.docx|\.eml|\.exe|\.flv|\.gif|\.jpg|\.jpeg|\.m4a|\.mov|\.mp3|\.mp4|\.mpeg|\.mpg|\.pdf|\.png|\.pps|\.ppt|\.pptx|\.txt|\.wav|\.wma|\.xls|\.xlsx|\.xps|\.zip)$/i;
      let fileArr = transferArr?.map(el =>{
        let obj = { name : el?.name??'', ...el} 
        let objSplitArr = el?.name?.split('[:.]')
        console.log('OBJ=>',obj,'OBJSPITARR',objSplitArr)
        for( let i=objSplitArr?.length; i>=0; --i){
          if(allowedExtensions.test(objSplitArr[i])){
            obj.name = `${objSplitArr[0]}.${objSplitArr[i]}`
            break
          }
        } 
        console.log('OBJNew=>',obj)
        return obj
      })
      console.log(fileArr)
      setLoadingState(null)
      onUpload(e.dataTransfer.files)
      }
      
    },
  };

  const pasteFn = async(val) =>{
    console.log('PASTEFN=>',val)
    setLoadingState(keyId)
    val.preventDefault();
    if (!val.clipboardData.files.length) {
      return;
    }
    console.log('ClipBoard=>',Array.from(val.clipboardData.files))
    setLoadingState(null)
    onUpload(Array.from(val.clipboardData.files)??[])
    Array.from(val.clipboardData.files)
    // Iterate over all pasted files.
  //   Array.from(val.clipboardData.files).forEach(async (file) => {
  //     // Add more checks here for MIME types you're interested in,
  //     // such as `application/pdf`, `video/mp4`, etc.
  //     console.log('PASTEFN-Files=>',file)
  // });
  }

  return(
    <div 
      id={`fileInputDrag_${keyId}`}
      className={`fileInputDrag ${areaCls}`}
      onClick={(e)=>{ e.stopPropagation(); }}
      onPaste={(e)=>{ console.log('OnPaste=>',e); pasteFn(e); }}
    >
      {
        loadingState===keyId && <div className="fileInputAnim"></div>
      }
      
      <Dragger {...props} style={{ width : '100%', height : '100%' }}>
        <input 
          type="text" 
          autoFocus={mouseVal ? true : false}
          id={`fileInputDragFile_${keyId}`} 
          contentEditable={true}
          onMouseEnter={(e)=>{
            setMouseVal(true)
            e.preventDefault(); e.stopPropagation();
          }}
          onMouseLeave={(e)=>{
            setMouseVal(null)
            e.preventDefault(); e.stopPropagation();
          }}
          
          onClick={(e)=>{
            console.log('onClick=>',e)
            e.preventDefault(); e.stopPropagation();
          }}        
          onPaste={(e)=>{ console.log('OnPaste=>',e); pasteFn(e); }}
      />
      <p className={`fileInputDrag-drag-icon ${iconCls}`}>
        <InboxOutlined />
      </p>
      <p className={`fileInputDrag-text ${textCls}`}>Paste file in this area to upload</p>
      </Dragger>
      
    </div>
  )
};

export default FilesDragAndDrop