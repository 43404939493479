import firebase from 'firebase/app';
import 'firebase/messaging'

const firebaseConfig = 
{
  apiKey: "AIzaSyDTuCDfTN23dcY14a-CV-n_2h3P8laM5wA",
  authDomain: "enviro-notifications.firebaseapp.com",
  projectId: "enviro-notifications",
  storageBucket: "enviro-notifications.appspot.com",
  messagingSenderId: "5175212623",
  appId: "1:5175212623:web:a980f0915a483bf96ac83a",
  measurementId: "G-PXQ3FC6676"
};

// {
//     apiKey: "AIzaSyBC3H00zgV0goVzpnRsIMTZENELrSX_OSs",
//     authDomain: "enviro-489d1.firebaseapp.com",
//     projectId: "enviro-489d1",
//     storageBucket: "enviro-489d1.appspot.com",
//     messagingSenderId: "806655638480",
//     appId: "1:806655638480:web:754f6aa0daa1cc3cd257d5",
//     measurementId: "G-YS4DXD26RK"
//   };


const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
let messaging = null; 

if(firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging();
}

export {messaging};