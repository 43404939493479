
let current_date = new Date()

let initialState = {
    isLoading: false,
    job_stop_pagination: false,

    job_list: [],
    job_list_page: 1,
    job_list_filter: 'all',


    quote_register: [],
    quote_register_page: 1,
    quote_register_loader: true,
    quote_stop_pagination: false,

    sale_performance: [],
    sale_prfrm_month: current_date.getMonth() + 1,
    sale_prfrm_year: current_date.getFullYear(),
    sale_performance_loader: true,
    sale_performance_detail_loader: true,
    sale_perf_stop_pagination: false,

    quote_templates: [],
    quote_template_Loader: false,
    under_folders: [],

    temp_client: [],
    attach_folders: [],

    sale_performance_detail: [],
    sale_perform_page: 1,
    sale_perform_filter: 'all',
    sale_perform_detail_date_filter: null,

    sale_draft_template_list: [],
    sale_draft_template_loader: false,
    sale_draft_template: '',
    sale_eight_template: '',
    sale_client_drafts_list: [],
    sale_client_draft: '',

    sales_amount: '',
    quote_content: '',
    quoteContentDtm : new Date(),
    quote_amount: 0,

    tableLoader: true,
    quoteSavedDraft: null,
    perm_Clients: [],
    searchQWrds: null,
    filterDataFx: null,
    templateLoader: true,
}



const sales = (state = initialState, actions) => {

    switch (actions.type) {

        case 'RELOAD_PAGE':
            return {
                ...state,
                tableLoader: true,
                filterDataFx: null,
                sale_draft_template_loader: true,
            }
        case 'RELOAD_SALES_PERFM':
            return {
                ...state,
                sale_performance_loader: true
            }
        case 'RELOAD_SALES_PERFM_DETAIL':
            return {
                ...state,
                sale_performance_detail_loader: true,
                sale_perf_stop_pagination: false,
                sale_perform_page : 1
            }
        case 'RELOAD_TEMPLATE_LOADER':
            return {
                ...state,
                templateLoader: true,
                tableLoader: true,
                quote_template_Loader: true,
                quote_templates: [],
                sale_draft_template_list: [],
                sale_draft_template_loader: true,
            }

        case "JOB_LIST_FETCHING":
            return {
                ...state,
                // dangerous_loader: false,
                quote_register_loader: true,
                isLoading: true,
            }
        case "JOB_LIST":
            return {
                ...state,
                isLoading: false,
                job_list: actions.page === 1 ? actions.jobs : state.job_list.concat(actions.jobs),
                job_list_page: actions.page,
                job_list_filter: actions.filter,
                tableLoader: false,
                job_stop_pagination: actions.jobs && actions.jobs.length === 20 ? false : true,
            }

        case "JOB_LIST_CLIENT_SEARCH":
            return {
                ...state,
                job_list: actions.page===1 ? actions.jobs: state.job_list.concat(actions.jobs),
                job_list_page: actions.page,
                tableLoader: false,
                isLoading: false,
                job_stop_pagination: actions.jobs && actions.jobs.length === 20 ? false : true,
            }

        case "JOB_LIST_ACTION":
            let newArr = state.job_list
            let newArrIdx = newArr.findIndex(item => item.id === actions.job_id)

            newArr[newArrIdx].ready_for_schedule = actions.btn
            return Object.assign({}, {
                ...state,
                job_list: Object.assign([], newArr),
                tableLoader: false
            })


        case "QUOTE_REGISTER":
            return {
                ...state,
                quote_register: actions.page === 1 ? actions.quotes : state.quote_register.concat(actions.quotes),
                quote_register_page: actions.page,
                tableLoader: false,
                quote_register_loader: false,
                quote_stop_pagination: actions.quotes && actions.quotes.length === 20 ? false : true,
            }

        case "SALE_PERFORMANCE":

            return {
                ...state,
                sale_performance: actions.sales,
                sale_prfrm_month: actions.month,
                sale_prfrm_year: actions.year,
                sale_performance_loader: false,
            }

        case "SALES_TEMPLATES":
            return {
                ...state,
                quote_templates: actions.templates,
                quote_template_Loader: false
            }

        case "UNDER_FOLDERS":
            return {
                ...state,
                under_folders: actions.folders
            }
        case "TEMP_CLIENTS":
            return {
                ...state,
                temp_client: actions.clients,
                tableLoader: false
            }

        case "ATTACH_FOLDERS":
            return {
                ...state,
                attach_folders: actions.folders,
            }

        case "SALES_PERFORMANCE_VIEW":
            let newArrSalesPerf = actions.page===1 ? actions.perform : (actions.perform && actions.perform.length!==0 && actions?.page===state.sale_perform_page+1) ? 
            [...state.sale_performance_detail, ...actions.perform] : state.sale_performance_detail
            
            return {
                ...state,
                sale_performance_detail: newArrSalesPerf,
                sale_perform_page:  actions.page===1 ? actions.page : (actions.perform && actions.perform.length!==0 && actions.page!==1) ? actions.page : state.sale_perform_page,
                sale_perform_filter: actions.filterType,
                sale_performance_detail_loader: false,
                sale_perform_detail_date_filter : actions.filterMY,
                sale_perf_stop_pagination: actions.perform && actions.perform.length === 20 ? false : true,
           }

        case "SALES_SET_AMOUNT":
            return {
                ...state,
                sales_amount: actions.amount,
            }

        case "SALES_SET_CONTENT_QUOTE":
            console.log('done')
            return {
                ...state,
                quote_content: actions.content,
                quoteContentDtm : new Date()
            }

        case "SALES_SET_QUOTE_AMOUNT":
            return {
                ...state,
                quote_amount: actions.amount,
            }

        case "SALES_QUOTE_SITEDATE_FILTER":
            console.log(actions)
            return {
                ...state,
                quote_register_loader: false,
                quote_register: actions.page === 1? actions.quoteList: state.quote_register.concat(actions.quoteList),
                quote_register_page: actions.page,
                tableLoader: false,
                quote_stop_pagination: actions.quoteList && actions.quoteList.length === 20 ? false : true,
            }
        case "SALES_JOB_SITEDATE_FILTER_FETCHING":
            return {
                ...state,
                isLoading: true,
            }
        case "SALES_JOB_SITEDATE_FILTER":
            console.log(actions)
            return {
                ...state,
                isLoading: false,
                job_list: actions.page === 1?  actions.jobList: state.job_list.concat(actions.jobList),
                job_list_page: actions.page,
                filterDataFx: actions.filterData,
                tableLoader: false,
                job_stop_pagination: actions.jobList && actions.jobList.length === 20 ? false : true,
            }

        case "DRAFTED_TEMPLATES":
            return {
                ...state,
                sale_draft_template_list: actions.templatesList,
                sale_draft_template_loader: false,
            }

        case "SALE_EIGHT_TEMPLATE":
            return {
                ...state,
                sale_eight_template: actions.newTemplate
            }

        case "SALE_CLIENT_DRAFTS":
            return {
                ...state,
                sale_client_drafts_list: actions.templateList
            }

        case "SINGLE_CLIENT_DRAFT":
            console.log("DATA:::", actions.clientDraft)
            return {
                ...state,
                sale_client_draft: actions.clientDraft,
                templateLoader: false
            }

        case "SET_SAVED_DRAFT_CLIENT":
            return {
                ...state,
                quoteSavedDraft: actions.content,
                quote_conten: actions.content !== null ? actions.content.template : state.quote_content //TODO: update according to new
            }

        case "SALES_QUOTE_CLIENT_SEARCH":
            return {
                ...state,
                temp_client: actions.temp,
                perm_Clients: actions.perm,
                searchQWrds: actions.val
            }

        case "QUOTE_GENERATE_DETAIL_CLIENT_SEARCH":
            return {
                ...state,
                temp_client: actions.temp,
                perm_Clients: actions.perm
            }

        case "ALL_CLIENTS_LISTING":
            // let permArr = actions.clients.filter((item) => item.client_type==='Permenant');
            console.log("ClientsAll:", actions.clients)
            let permArr = actions.clients && actions.clients.length !== 0 ? actions.clients.filter((item) => item.client_type === 'Permenant') : [];
            let tempArr = actions.clients && actions.clients.length !== 0 ? actions.clients.filter((item) => item.client_type === 'Temporary') : [];
            return {
                ...state,
                temp_client: tempArr,
                perm_Clients: permArr,
            }

        case "UPDATE_QUOTE_REGISTER":
            let oldQuoteArr = state.quote_register
            let oldQuoteFind = oldQuoteArr.findIndex(el => el.id === actions.id)
            oldQuoteArr[oldQuoteFind].sales_team_review = actions.status
            return {
                ...state,
                quote_register: Object.assign([], oldQuoteArr)
            }

        default:
            return state

    }
}

export default sales