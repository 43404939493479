import moment from "moment"

let initialState = {
    car_list: [],
    car_stoppagination: false,
    car_list_loader: false,
    car_list_error: null,
    car_page: 1,
    car_search_keyword: '',

    selected_car: {},
    vehicle_modal: false,
    vehicle_img_modal: false,

    car_maintanace_list: [],
    car_maintanace_stoppagination: false,
    car_maintanace_error: null,
    car_maintanace_loader: false,
    car_maintanance_page: 1,

    maintanace_search_qry: '',

    selected_car_maintanance: {},

    all_car_list: [],
    filterKey: 'all',

    car_fuel_list: [],
    car_fuel_stoppagination: false,
    car_fuel_error: null,
    car_fuel_loader: false,
    car_fuel_page: 1,

    fuel_search_qry: '',

    selected_car_fuel: {},

    selected_pre_inspect: {},
    edit_pre_inspObj: {},
    filtered_car_list: [],

    car_preInsp_list: [],
    pre_insp_page: 1,
    pre_insp_loader: true,
    pre_insp_error: null,
    pre_insp_stoppagination: false,

    pre_insp_search_qry:'',

    isLoading: false,

}

const car = (state = initialState, actions) => {
    switch (actions.type) {
        case "CAR_LIST_FETCHING":
            if(actions && actions.keyword){
                return {
                    ...state,
                    car_list_loader: true,
                    car_list_error: null,
                    car_search_keyword: actions.keyword,
                    car_list: actions.page ===1 ?[]:state.car_list,
                    car_page: actions.page,
                }
            }else{
                return {
                    ...state,
                    car_list_loader: true,
                    car_list_error: null,
                    car_search_keyword: '',
                    car_list: actions.page? (actions.page===1 ? []:state.car_list):state.car_list
                }
            }

        case "CAR_LIST":
            // if ((actions.page === 1 && state.car_list.length === 0) || actions.page !== state.car_page) {
                return {
                    ...state,
                    car_list: actions.page === 1 ? actions.cars : state.car_list.concat(actions.cars),
                    car_page: actions.page,
                    car_stoppagination: actions.cars.length === 15 ? false : true,
                    car_list_loader: false,
                    car_list_error: null
                }
            // } else {
            //     return {
            //         ...state,
            //         car_list_loader: false
            //     }
            // }
        case "CAR_LIST_FAILED":
            return {
                ...state,
                car_list_error: actions.error,
                car_list_loader: actions.error.text === 'Unknown error'?state.car_list_loader: false
            }
        case "CAR_FILTERED_MNTN_LIST":
            let tempListMNTC = state.car_maintanace_list
            if(actions.page===1)
                tempListMNTC = actions.trucks
            else if(state.truck_maintanance_page === actions.page){
                tempListMNTC.splice((15*(parseInt(actions.page)-1)), tempListMNTC.length-(15*(parseInt(actions.page)-1)), ...actions.trucks)
            }
            else 
                tempListMNTC = state.car_maintanace_list.concat(actions.trucks)

            return {
                ...state,
                car_maintanace_list: actions.page === 1 ? actions.trucks : state.car_maintanace_list.concat(actions.trucks),
                filterKey: actions.filterKey? actions.filterKey: state.filterKey,
                maintanace_search_qry: actions.keyword? actions.keyword: state.maintanace_search_qry,
                car_maintanace_error: null,
                car_maintanace_loader: false,
                isLoading: false,
                car_maintanance_page: actions.page,
                car_maintanace_stoppagination: actions.trucks.length === 15 ? false : true
            }

        case "CAR_FILTERED_FUEL_LIST":
            return {
                ...state,
                car_fuel_list: actions.page === 1 ? actions.cars : state.car_fuel_list.concat(actions.cars),
                filterKey: actions.filterKey? actions.filterKey: state.filterKey,
                fuel_search_qry: actions.keyword? actions.keyword: state.fuel_search_qry,
                car_fuel_loader: false,
                car_fuel_error: null,
                isLoading: false,
                car_fuel_page: actions.page,
                car_fuel_stoppagination: actions.cars.length === 15 ? false : true
            }


        case "CAR_REFRESH_FILTER":
            return {
                ...state,
                filterKey: 'all',
                filtered_car_list: [],
                car_preInsp_list: [],
                pre_insp_loader: true,
            }
        case "CAR_MAINTANACE_LIST_FETCHING":
            if(actions && actions.keyword){
                return {
                    ...state,
                    car_maintanace_loader: true,
                    car_maintanace_error: null,
                    maintanace_search_qry: actions.keyword,
                    car_maintanace_list: actions.page === 1 ? [] : state.car_maintanace_list,
                    car_maintanance_page: actions.page,
                }
            } else if (actions && actions.filter) {
                return {
                    ...state,
                    car_maintanace_loader: true,
                    car_maintanace_error: null,
                    car_maintanace_list: actions.page ? (actions.page === 1 ? [] : state.car_maintanace_list) : state.car_maintanace_list,
                    filtered_car_list: [],
                    maintanace_search_qry: '',
                    filterKey: actions.filter,
                    car_maintanance_page: actions.page,
                }
            }else{
                return {
                    ...state,
                    car_maintanace_loader: true,
                    car_maintanace_error: null,
                    maintanace_search_qry: '',
                    car_maintanace_list: actions.page? (actions.page===1 ? []:state.car_maintanace_list):state.car_maintanace_list
                }
            }
        case "CAR_MAINTANACE_LIST":
            // if ((actions.page === 1 && state.car_maintanace_list.length === 0) || actions.page !== state.car_maintanance_page) {
                return Object.assign({}, {
                    ...state,
                    car_maintanace_list: actions.page === 1 ? actions.maintanace : state.car_maintanace_list.concat(actions.maintanace),
                    car_maintanance_page: actions.page,
                    car_maintanace_stoppagination: actions.maintanace.length === 15 ? false : true,
                    car_maintanace_loader: false,
                    car_maintanace_error: null
                })
            // } else {
            //     return {
            //         ...state,
            //         car_maintanace_loader: false
            //     }
            // }
        case "CAR_MAINTANACE_LIST_FAILED":
            return {
                ...state,
                car_maintanace_error: actions.error,
                car_maintanace_loader: actions.error.text === 'Unknown error'?state.car_maintanace_loader: false,
            }
        case "ALL_CAR_LIST":
            return {
                ...state,
                all_car_list: actions.cars,
                isLoading: false
            }

        case "SELECT_CAR_MAINTANACE":
            return {
                ...state,
                selected_car_maintanance: actions.maintanace
            }

        case "VEHCILE_MODAL":
            return {
                ...state,
                vehicle_modal: actions.modal
            }

        case "VEHCILE_IMG_MODAL":
            return {
                ...state,
                vehicle_img_modal: actions.modal
            }

        case "SELECT_CAR":
            return {
                ...state,
                selected_car: actions.car
            }
        case "CAR_FUEL_LIST_FETCHING":
            if(actions && actions.keyword){
                return {
                    ...state,
                    car_fuel_loader: true,
                    car_fuel_error: null,
                    fuel_search_qry: actions.keyword,
                    car_fuel_list: actions.page === 1 ? [] : state.car_fuel_list,
                    car_fuel_page: actions.page,
                }
            }else if(actions && actions.filter){
                return {
                    ...state,
                    car_fuel_loader: true,
                    car_fuel_error: null,
                    car_fuel_list: actions.page === 1 ? [] : state.car_fuel_list,
                    filtered_car_list: [],
                    fuel_search_qry: '',
                    filterKey: actions.filter,
                    car_fuel_page: actions.page, 
                }
            }else{
                return {
                    ...state,
                    car_fuel_loader: true,
                    car_fuel_error: null,
                    fuel_search_qry: '',
                    car_fuel_list: actions.page? (actions.page===1 ? []:state.car_fuel_list):state.car_fuel_list
                }
            }
        case "CAR_FUEL_LIST":
            // if ((actions.page === 1 && state.car_fuel_list.length === 0) || actions.page !== state.car_fuel_page) {
                return {
                    ...state,
                    car_fuel_list: actions.page === 1 ? actions.fuel : state.car_fuel_list.concat(actions.fuel),
                    car_fuel_page: actions.page,
                    car_fuel_stoppagination: actions.fuel.length === 15 ? false : true,
                    car_fuel_loader: false,
                    car_fuel_error: null
                }
            // } else {
            //     return {
            //         ...state,
            //         car_fuel_loader: false
            //     }
            // }
        case "CAR_FUEL_LIST_ERROR":
            return {
                ...state,
                car_fuel_loader: actions.error.text === 'Unknown error'?state.car_fuel_loader: false,
                car_fuel_error: actions.error
            }

        case "SELECT_CAR_FUEL":
            return {
                ...state,
                selected_car_fuel: actions.fuel
            }

        case "SELECTED_CAR_PRE-INSPECT":
            return {
                ...state,
                selected_pre_inspect: actions.selObj,
                edit_pre_inspObj : {
                    Vehicle                 : actions.selObj.vehicle
                    , Date_time             : actions.selObj.date_time
                    , DriverName            : actions.selObj.driver_name
                    , DriverNameStr         : actions.selObj.name
                    , Odometer              : actions.selObj.odometer       
                    , DriverSignature       : actions.selObj.odometdriver_signature       
                    , HourMeterStart        : actions.selObj.hour_meter_start       
                
                    , FitForWork            : actions.selObj.fit_for_work       
                    , ValidDrivingLicense   : actions.selObj.Valid_driving_license       
                    , AppropriatePPE        : actions.selObj.appropriate_ppe       
                
                    , EngineOilLevel        : actions.selObj.engine_oil_level       
                    , WarningSystem         : actions.selObj.warning_system       
                    , Steering              : actions.selObj.steering       
                    , SafetyEmrgStop        : actions.selObj.safety_emerg_stop       
                    , HandBreakAlram        : actions.selObj.handbreak_alarm       
                    , PTOVacPump            : actions.selObj.pto_vacpump       
                
                    , Horn                  : actions.selObj.horn
                    , RevAlarmCamera        : actions.selObj.rev_alarm_camera
                    , LightsHead            : actions.selObj.lights_head
                    , LightsTail            : actions.selObj.lights_tail
                    , LightsBeacon          : actions.selObj.light_beacons
                    , HazardLight           : actions.selObj.hazard_light
                    , HouseKeeping          : actions.selObj.house_keeping
                
                    , RimsWheels            : actions.selObj.rims_wheelnut
                    , Coolant               : actions.selObj.coolant
                    , Wheels                : actions.selObj.wheels
                    , MirrorWindows         : actions.selObj.mirror_windowscreen
                    , StructureBody         : actions.selObj.structure_bodywork
                    , Wipers                : actions.selObj.wipers
                
                    , FuelLevelPump         : actions.selObj.fuel_levelpump
                    , FuelLevelTruck        : actions.selObj.fuel_leveltruck
                    , SeatSeatbelt          : actions.selObj.seat_seatbelt
                    , ParkBreakTrailer      : actions.selObj.parkbrake_trailer
                    , FootBrake             : actions.selObj.foot_brake
                    , Electrical            : actions.selObj.electrical
                    , PinRetainer           : actions.selObj.pin_retainers
                
                    , Hoses                 : actions.selObj.hoses
                    , Fittings              : actions.selObj.fittings
                    , FirstAidKit           : actions.selObj.first_aid_kit
                    , PPE                   : actions.selObj.ppe
                    , FireExtingusre        : actions.selObj.fire_extinguisher
                    , FireExtingusreDate    : moment(actions.selObj.fire_extinguisher_date).isValid() ?
                         moment(actions.selObj.fire_extinguisher_date).format('YYYY-MM-DD') : actions.selObj.fire_extinguisher_date
                    , GardenHose            : actions.selObj.garden_hose
                    , GaticLiters           : actions.selObj.gatic_lifters
                    , BucketRag             : actions.selObj.bucket_rags
                    , SpillKit              : actions.selObj.spill_kit
                
                    , FaultReportz          : actions.selObj.reported_fault_string
                
                    , ReadyToOperate        : actions.selObj.safe_ready_to_operate
                    , ReportedFaults        : actions.selObj.reported_faults
                    , ReviewdForm           : actions.selObj.reviewed_form
                
                    , Corrected             : actions.selObj.corrected
                    , ScheduledForRepair    : actions.selObj.scheduled_for_repair
                    , NoAction              : actions.selObj.no_action
                    , DoNotAffectSafeOperation    : actions.selObj.do_not_affect_safe_operation
                
                }
            }


        case "FETCHING_CAR_PREINSP_LIST":
            if(actions && actions.keyword){
                return {
                    ...state,
                    pre_insp_loader: true,
                    pre_insp_error: null,
                    pre_insp_search_qry: actions.keyword,
                    car_preInsp_list:  actions.page === 1 ? [] : state.car_preInsp_list,
                    pre_insp_page: actions.page,
                }
            }else if(actions && actions.filter){
                return {
                    ...state,
                    pre_insp_loader: true,
                    pre_insp_error: null,
                    car_preInsp_list: actions.page ? (actions.page === 1 ? [] : state.car_preInsp_list) : state.car_preInsp_list,
                    filtered_car_list: [],
                    pre_insp_search_qry: '',
                    filterKey: actions.filter,
                    
                    pre_insp_page: actions.page,
                }
            }else{
                return {
                    ...state,
                    pre_insp_loader: true,
                    pre_insp_error: null,
                    pre_insp_search_qry: '',
                    car_preInsp_list: actions.page? (actions.page===1 ? []:state.car_preInsp_list):state.car_preInsp_list
                }
            }
            case "CAR_FILTERED_LIST":
                return {
                    ...state,
                    filtered_car_list: actions.trucks,
                    car_preInsp_list: actions.page === 1 ? actions.trucks : state.car_preInsp_list.concat(actions.trucks),
                    pre_insp_loader: false,
                    filterKey: actions.filterKey? actions.filterKey: state.filterKey,
                    pre_insp_search_qry: actions.keyword? actions.keyword: state.pre_insp_search_qry,
                    pre_insp_error: null,
                    pre_insp_page:actions.page,
                    pre_insp_stoppagination: actions.trucks.length === 15 ? false : true
                }
        case "CAR_PREINSP_LIST_SUCCESS":
            // if ((actions.page === 1 && state.car_preInsp_list.length === 0) || actions.page !== state.pre_insp_page) {
                return {
                    ...state,
                    car_preInsp_list: actions.page === 1 ? actions.trucks : state.car_preInsp_list.concat(actions.trucks),
                    pre_insp_page: actions.page,
                    filterKey: actions.filterKey,
                    pre_insp_stoppagination: actions.trucks.length === 15 ? false : true,
                    pre_insp_loader: false,
                    pre_insp_error: null
                }
            // } else {
            //     return {
            //         ...state,
            //         pre_insp_loader: false
            //     }
            // }
        case "CAR_PREINSP_LIST_ERROR":
            return {
                ...state,
                pre_insp_loader: actions.error.text === 'Unknown error'?state.pre_insp_loader: false,
                pre_insp_error: actions.error

            }
        case "CAR_EDIT_LIST":
            let oldData = state.car_list
            let oldDataIdx = oldData.findIndex(el => el.id === actions.id)
            oldData[oldDataIdx] = actions.content;
            return {
                ...state,
                car_list: oldData
            }




        default:
            return state

    }
}

export default car