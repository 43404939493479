import React, { useState } from 'react';
import Scrollbars from '../../../common/ImprovedScrollBar';
import { CircularProgress } from '@material-ui/core'
// import SendIcon from '@material-ui/icons/Send';
// import { HOST_WITHOUT_SLASH } from '../../../store/endpoint';
import SignaturePadModal from '../../../common/SignaturePadModal';
import DateTimeInput from '../../../common/DateTimeInput';
// import moment from 'moment'

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AddPhotoAlternateRounded, DeleteForever } from '@material-ui/icons';
import VideoFilePreview, { IsVideoFileUpload } from '../../../common/VideoFilePreview';

function CompletedJobModal({
    signImage, setSignImage, after_pics, setAfterPics, before_pics, setBeforePics, schduleStatus, setScheduleStatus, jobStatusTime, setJobStatusTime,
    chngWasteType, chngWasteLtr, ponumber, signName, setChngWasteType, setChngWasteLtr, setPonumber, setSignName,
    videoGallery, setvideoGallery, gallery_pics, setGalleryPics
}) {

    const [galleryLoader, setGalleryLoader] = useState(false)
    const [signLoader, setSignLoader]     = useState(false)
    const [signModal, setSignModal] = useState(false)
    // const [videoGallery, setvideoGallery] = useState([])
    const [vgalleryLoader, setVGalleryLoader] = useState(false)
    
    const addScheduleImg = (val, setLoader, status=1) =>{
        console.log('val',val)
        let newArr = []
        setLoader(true)
        for (let i = 0; i < val.length; i++) {
                newArr[i]=val[i]
        }
        
        if(status===2){
            setAfterPics(newArr)            
        }else if(status===1){
            setBeforePics(newArr)
        }else{
            setGalleryPics(newArr)
        }
        setLoader(false)
    }
    const addScheduleSignImg = (e) =>{
        setSignLoader(true)
        for (let i = 0; i < e.length; i++) {
            let imgData = e[i]
            setSignImage(imgData)
            // let imgName = imgData.name
            // data.append('image', imgData, imgName)
        }
        setSignLoader(false)
    }

    const checkIfStatus = () =>{

        let bImages = before_pics && before_pics?.length>0 
        let aImages = after_pics && after_pics?.length>0
        let completeData = signImage || signName 

        const leafTonFn = (val1, val2='job_finished') =>{
            if(completeData)
                return `${val1}`
            else 
                return `${val2}`
        }

        const headTonFn = (val1, val2='departed_enviro_facility') =>{
            if(bImages)
                if(aImages)
                    return `${val1}`
                else 
                    return 'job_started'
            else 
                return `${val2}`
        }

        if(jobStatusTime)
            if(jobStatusTime.depart_enviro_facility!=='00:00')
                if(jobStatusTime.start_job!=='00:00')
                    if(jobStatusTime.finish_job!=='00:00')
                        if(jobStatusTime.completed!=='00:00')
                            if(jobStatusTime.arrive_at_waste_depot!=='00:00')
                                if(jobStatusTime.depart_waste_depot!=='00:00')
                                    if(jobStatusTime.arrive_enviro_facility!=='00:00' && completeData){
                                        return 'arrived_at_enviro_facility'
                                    }else{
                                        return leafTonFn('departed_waste_depot')
                                    }
                                else{
                                    return leafTonFn('arrived_at_waste_depot')
                                }
                            else{
                                return leafTonFn('completed')
                            }
                        else{
                            return 'job_finished'
                        }
                    else{
                        return headTonFn('job_started')
                    }
                else{
                    return 'departed_enviro_facility'
                }
            else {
                if(completeData && aImages && bImages){
                    return `Update times in all the fields to make Job eligible for 'Completed' Status`
                }else if(aImages && bImages){
                    return `Update times in all the fields upto Job Finished to make Job eligible for 'Job Finished' Status`
                }else if(bImages){
                    return `Update times in all the fields upto Job Started to make Job eligible for 'Job Started' Status`
                }else{
                    return `Update times in all the fields and add media in respective sections to update Job Status correctly`
                }
            }
        else{
            return 'pending'
        }

    }

    console.log('vid',videoGallery)

    return (
        <div className="shdl-right-layout">
                <Scrollbars>
                    <div className='shdl-cmpltd-jobStatus' style={{ marginTop:'3%' }}>
                        <p style={{ fontWeight: 'bold', fontSize: 14 }}>Current Job Status</p>
                        <span style={{ fontWeight: 'bold', fontSize: 14 }}>:</span>
                        <div>
                        <select
                            value={schduleStatus}
                            onChange={val => {
                                setScheduleStatus(val.target.value)
                            }}
                        >
                            <option value="pending">Pending</option>
                            <option value='departed_enviro_facility'>Departed Enviro Facility</option>
                           <option value='job_started'>Job Started</option>
                           <option value='job_finished'>Job Finished</option>
                           <option value="completed">Job Completed</option>
                           <option value='arrived_at_waste_depot'>Arrived At Waste Depot</option>
                           <option value='departed_waste_depot'>Departed Waste Depot</option>
                           <option value='arrived_at_enviro_facility'>Arrived At Enviro Facility</option>
                           
                        </select>
                        </div>
                    </div>
                    {
                        jobStatusTime && jobStatusTime.depart_enviro_facility==='00:00' ?
                        <div className='shdl-cmpltd-jobStatusInformantAttention'>
                            {checkIfStatus()}
                        </div>
                        :
                        schduleStatus===checkIfStatus() ? null 
                        :
                        <div className='shdl-cmpltd-jobStatusInformant'> 
                            Job is eligible for 
                            <p style={{ fontWeight:'700' }}>{
                            checkIfStatus() === 'departed_enviro_facility' ? 'Departed Enviro Facility'
                            :   checkIfStatus() === 'job_started'    ? 'Job Started'
                            :   checkIfStatus() === 'job_finished'   ?   'Job Finished'
                            :   checkIfStatus() === 'arrived_at_waste_depot' ? 'Arrived At Waste Depot'
                            :   checkIfStatus() === 'departed_waste_depot' ? 'Departed Waste Depot'
                            :   checkIfStatus() === 'arrived_at_enviro_facility' ? 'Arrived At Enviro Facility'
                            :   checkIfStatus()
                            }</p>
                             status
                        </div>
                    }
                    <div className='shdl-cmpltd-jobStatusTime'>
                        <p style={{ fontWeight: 'bold', fontSize: 14 }}>Add Job Status Times</p>
                        <div className='shdl-cmpltd-jobStatus-list'>
                            <div className='shdl-cmpltd-jobStatus'>
                                <p style={{ width:'50%', fontSize: 14 }}>Departed Enviro Facility</p>
                                <span style={{ fontSize: 14 }}>:</span>
                                <DateTimeInput
                                    pickerType="time"
                                    format="hh:mm a"
                                    dateValue={jobStatusTime.depart_enviro_facility}
                                    dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, depart_enviro_facility : e})}
                                    clsName={'client-input'}

                                />
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Job Started</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <DateTimeInput
                                pickerType="time"
                                format="hh:mm a"
                                dateValue={jobStatusTime.start_job}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, start_job : e})}
                                clsName={'client-input'}
                            />
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Job Finished</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <DateTimeInput
                                pickerType="time"
                                format="hh:mm a"
                                dateValue={jobStatusTime.finish_job}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, finish_job : e})}
                                clsName={'client-input'}
                            />
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Job Completed</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <DateTimeInput
                                pickerType="time"
                                format="hh:mm a"
                                dateValue={jobStatusTime.completed}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, completed : e})}
                                clsName={'client-input'}
                            />
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Arrived Waste Depot</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <DateTimeInput
                                pickerType="time"
                                format="hh:mm a"
                                dateValue={jobStatusTime.arrive_at_waste_depot}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, arrive_at_waste_depot : e})}
                                clsName={'client-input'}
                            />
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Departed Waste Depot</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <DateTimeInput
                                pickerType="time"
                                format="hh:mm a"
                                dateValue={jobStatusTime.depart_waste_depot}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, depart_waste_depot : e})}
                                clsName={'client-input'}
                            />
                            </div>
                            <div className='shdl-cmpltd-jobStatus'>
                            <p style={{ width:'50%', fontSize: 14 }}>Arrived Enviro Facility</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <DateTimeInput
                                pickerType="time"
                                format="hh:mm a"
                                dateValue={jobStatusTime.arrive_enviro_facility}
                                dateSetFn={(e)=>setJobStatusTime({...jobStatusTime, arrive_enviro_facility : e})}
                                clsName={'client-input'}
                            />
                            </div>
                        </div>
                    </div>
                    <div className='shdl-cmpltd-finalFields-Box'>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Change in extracted waste type</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <textarea value={chngWasteType} onChange={(val)=>{ setChngWasteType(val.target.value) }} rows='3' style={{ resize:'block' }} />    
                            </div>    
                        </div>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Change in extracted waste litres</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <textarea value={chngWasteLtr} onChange={(val)=>{ setChngWasteLtr(val.target.value) }} rows='3' style={{ resize:'block' }} />    
                            </div>    
                        </div>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Purchase Order Number</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <textarea value={ponumber} onChange={(val)=>{ setPonumber(val.target.value) }} rows='3' style={{ resize:'block' }} />    
                            </div>    
                        </div>
                        <div className='shdl-cmpltd-finalFields'>
                            <p style={{ width:'50%', fontSize: 14 }}>Signature Name</p>
                            <span style={{ fontSize: 14 }}>:</span>
                            <div>
                                <input type="text" value={signName} onChange={(val)=>  { setSignName(val.target.value) }}/>    
                            </div>    
                        </div>
                    </div>
                    <div className="shdl-sign-image-layout">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>Add Signature Image</p>

                                {
                                    signLoader ? 
                                        <CircularProgress size={20} />
                                    :   null
                                }
                                {
                                    signImage ? 
                                    <img onClick={() => setSignImage(null)}  
                                    style={{ height: 40, width: 50, borderRadius: 5 }} 
                                    src={URL.createObjectURL(signImage)} alt="" />

                                    : null
                                }
                                <div
                                    onClick={()=> setSignModal(true) }
                                >
                                    <AddCircleIcon style={{ cursor: 'pointer' }} />
                                </div>
                        </div>
                    </div>                        
                    <div className="shdl-gallery-layout">
                        <div style={{ display: 'flex', justifyContent: 'space-between',marginBottom:'2%' }}>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>Gallery</p>
                            <label htmlFor="file-input">
                                <div 
                                    style={{
                                        display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'.5%',
                                        backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700'
                                    }}
                                >
                                    <AddPhotoAlternateRounded style={{ cursor: 'pointer' }} />
                                    <span>Before Job</span>
                                </div>
                            </label>
                            <input 
                                id="file-input"
                                style={{ display: 'none' }}
                                type="file" 
                                multiple
                                accept='image/*'
                                onChange={files => {
                                    addScheduleImg(files.target.files, setGalleryLoader, 1)
                                }}
                            />
                            <label htmlFor="file-input1">
                                <div
                                    style={{
                                        display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'.5%',
                                        backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700'
                                    }}
                                >
                                    <AddPhotoAlternateRounded style={{ cursor: 'pointer' }} />
                                    <span>After Job</span>
                                </div>
                            </label>
                            <input 
                                id="file-input1"
                                style={{ display: 'none' }}
                                type="file" 
                                multiple
                                accept='image/*'
                                onChange={files => {
                                    addScheduleImg(files.target.files, setGalleryLoader, 2)
                                }}
                            />
                            <label htmlFor="file-input3">
                                <div
                                    style={{
                                        display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', borderRadius:'5px', padding:'.5%',
                                        backgroundColor:'var(--cl_blue)', color:'white', fontSize:'.7rem', fontWeight:'700'
                                    }}
                                >
                                    <AddPhotoAlternateRounded style={{ cursor: 'pointer' }} />
                                    <span>Other Images</span>
                                </div>
                            </label>
                            <input 
                                id="file-input3"
                                style={{ display: 'none' }}
                                type="file" 
                                multiple
                                accept='image/*'
                                onChange={files => {
                                    addScheduleImg(files.target.files, setGalleryLoader, 3)
                                }}
                            />
                        </div>
                        { 
                            galleryLoader &&
                            <CircularProgress size={25} />}
                            
                            {   before_pics && before_pics.length !==0 ?
                            <div className="shdl-gallery-grid">

                                <div className='shdl-img-splPics'>Before Job</div>
                                {
                                    before_pics && before_pics.map((item, key) => {
                                        const img_url = URL.createObjectURL(item)
                                        return (
                                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                                <div className='shdl-cmplt-job-imgDel'
                                                 onClick={() => {
                                                    let newArr = before_pics
                                                    setBeforePics(newArr.filter(el => el!==item))
                                                }}>
                                                    <DeleteForever/>
                                                </div>
                                                <img style={{ height: 40, width: 50, borderRadius: 5 }} src={img_url} alt="" />
                                            </div>

                                            
                                        )
                                    })
                                }
                                
                                
                            </div>
                            : null
                            }
                            {   after_pics && after_pics.length !==0 ?
                            <div className="shdl-gallery-grid">
                                <div className='shdl-img-splPics'>After Job</div>
                                {
                                    after_pics && after_pics.map((item, key) => {
                                        const img_url = URL.createObjectURL(item)
                                        return (
                                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                                <div className='shdl-cmplt-job-imgDel'
                                                 onClick={() => {
                                                    let newArr = before_pics
                                                    setBeforePics(newArr.filter(el => el!==item))
                                                }}>
                                                    <DeleteForever/>
                                                </div>
                                                <img style={{ height: 40, width: 50, borderRadius: 5 }} src={img_url} alt="" />
                                            </div>

                                            
                                        )
                                    })
                                }
                            </div>
                            :   null
                            }
                            {   gallery_pics && gallery_pics.length !==0 ?
                            <div className="shdl-gallery-grid">
                                <div className='shdl-img-splPics'>Other Images</div>
                                {
                                    gallery_pics && gallery_pics.map((item, key) => {
                                        const img_url = URL.createObjectURL(item)
                                        return (
                                            <div key={key} className="shdl-img-PicsDiv shdl-img-splPicsDiv">
                                                <div className='shdl-cmplt-job-imgDel'
                                                 onClick={() => {
                                                    let newArr = before_pics
                                                    setGalleryPics(newArr.filter(el => el!==item))
                                                }}>
                                                    <DeleteForever/>
                                                </div>
                                                <img style={{ height: 40, width: 50, borderRadius: 5 }} src={img_url} alt="" />
                                            </div>

                                            
                                        )
                                    })
                                }
                            </div>
                            :   null

                            }
                        
                    </div>

                    <div className="shdl-gallery-layout">
                        <div style={{ display: 'flex', justifyContent: 'space-between', position:'relative' }}>
                            <p style={{ fontWeight: 'bold', fontSize: 14 }}>Video Gallery</p>
                            
                            <label htmlFor="file-input2">
                                <div>
                                    <AddCircleIcon style={{ cursor: 'pointer' }} />
                                </div>
                            </label>
                            <input 
                                id="file-input2"
                                style={{ opacity: 0, position:'absolute', right:0, width:'2rem', cursor:'pointer' }}
                                type="file" 
                                multiple
                                accept="video/*"
                                onChange={files => {
                                    setVGalleryLoader(true)
                                    let f = files.target.files
                                    let newArr = []
                                    for(let i=0; i<f.length; ++i){
                                        console.log('FILECHECK',f[i],IsVideoFileUpload(f[i]))
                                        if(IsVideoFileUpload(f[i])){
                                            newArr.push(f[i])
                                        }
                                    }
                                    setvideoGallery(newArr)
                                    setVGalleryLoader(false)
                                }}
                            />
                        </div>
                        
                            {
                                videoGallery && videoGallery.length !== 0 ?
                                <div className="shdl-gallery-grid-vid">
                                
                                {
                                    videoGallery && videoGallery.map((item, key) => {
                                        
                                        const img_url = URL.createObjectURL(item)
                                        
                                        return (
                                            <div key={key} className="shdl-img-PicsDiv-vid" style={{ border:'none' }}>
                                                <VideoFilePreview
                                                    deleteFn={()=>{ 
                                                        let newArr = videoGallery
                                                    setvideoGallery(newArr.filter(el => el!==item))
                                                     }}
                                                    videoSrc={img_url}
                                                    // thumbStyle={{ height: 40, width: 50, borderRadius: 5 }}
                                                />
                                            </div>

                                            
                                        )
                                    })
                                }
                                {
                                    vgalleryLoader ? 
                                        <CircularProgress size={20} />
                                    :   null
                                }
                                
                            </div>
                            
                                :
                                null
                            }

                        
                    </div>
                    
                    <SignaturePadModal
                modalAction={setSignModal}
                modalStatus={signModal}
                imgFn={(e) => addScheduleSignImg(e)}
            />
                </Scrollbars>
                </div>
    );
}

export default CompletedJobModal;