import React, { useState } from 'react'
import { AddAPhoto } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { cl_red, cl_green } from '../../../common/Colors'
import EmployeeEditModal from './EmployeeEditModal'
// import { HOST } from '../../../store/endpoint'
import { delete_employee, edit_employee, team_timeSheet } from '../action'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import moment from 'moment'
import { takeAllPrint } from '../../client/action'
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import TeamEmployModal from './TeamEmployModal'
import Teamvehdelprevntmodal from '../../../common/TeamVehDelPrevntModal'
import Api from '../../../store/api'
import { TEAM_VEHICLE_SCHEDULE_EXIST } from '../../../store/endpoint'
import { startOfWeek } from 'date-fns'

function EmployeeDetailes({team, user, timeSheetInit, delete_employee_fx, edit_employee_fx}) {

    let route_history = useHistory();
    
    const [modalStatus, setModalStatus] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [timeSheetModal, setTimeSheetmodal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [apiRes, setApiRes] = useState(null)

    const { selected_employee, timeSheetLoader } = team
    const { name,  user_type,  date_joined,    contact_number, dp, 
            personal_email, email,  date_of_birth,  username,   employement_status,  emergency_contact, 
            expiry_date,    address,    emergency_contact_name, alert_before, termination_date } = selected_employee
    // console.log(selected_employee)
    // let dp_image = HOST + dp
    
        let dp_image = dp
    const update_dp = (image_file) => {
        let form_body = new FormData()
        let file_name = image_file.name
        form_body.append('dp', image_file, file_name)
        edit_employee_fx(form_body, selected_employee.id)
    }

    const delete_btn_click = () => {
        setApiRes(null)
        setDeleteModal(true)
        Api('get',`${TEAM_VEHICLE_SCHEDULE_EXIST}${selected_employee.id}/member/`).then(res => {
            console.log(res)
            if(res && res.length>0){
                setApiRes(res)
            }
            else{
                setDeleteModal(false)
                const success = () => {
                    route_history.push('/team')
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Employee deleted successful!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
        
                const failed = (err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                }
        
        
                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: cl_red,
                    cancelButtonColor: cl_green,
                    confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      console.log(result)
                    if (result.value) {
                        delete_employee_fx(selected_employee.id ,success, failed)
                    }
                  })
                
            }
        })
        .catch(()=>{
            setDeleteModal(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        })

        
        

}

    return (
        <div className="team-detail-left">
            {   selected_employee.id===user.id ?
            <div style={{ display: 'flex', flexDirection:'row', alignItems:'center', position:'absolute',
                top:'20%', right:'10%',
              justifyContent:'flex-end', float:'right' }}>
              <div className="profiler-timesheetModalBtn" 
                onClick={()=>{ 
                    let todayDate = new Date();
                    timeSheetInit(moment(startOfWeek(todayDate, { weekStartsOn : 5 })).format('YYYY-MM-DD'), user.id);
                    setModalType(true); setTimeSheetmodal(true); }}
                >
                <DateRangeRoundedIcon />
                <span>Timesheet</span>
              </div>
              <div className="profiler-timesheetModalBtn"
                onClick={()=>{ setModalType(false); setTimeSheetmodal(true); }}
              >
                <TodayRoundedIcon />
                <span>Leave Application</span>
              </div>
            </div>  :   null
            }
            <div className="team-detail-img-layout">
                <img className="team-detail-img" src={dp_image} alt="enviro team" />
                <label htmlFor="file-Imageinput">
                    <div className="team-capture-layout">
                        <AddAPhoto style={{ fontSize: 18 }} />
                    </div>
                </label>
                <input id="file-Imageinput" style={{ display: 'none' }} type="file" multiple={false}
                    onChange={(image) => {
                        image.preventDefault()
                        image.stopPropagation()
                        var image_file = image.target.files[0]
                        let extension = image_file.type
                        console.log("IMAGEFILEINPUT")
                        if(extension === 'image/jpeg' || extension === 'image/png') {
                            // var image_blob = URL.createObjectURL(image_file)
                            update_dp(image_file)
                        } else {
                            alert('File should be an Image. Only jpg and png files are accepted')
                        }
                        
                    }}
                />
                
            </div>

            <div className="team-detail-actions">
                <div className="team-edit-btn"
                    onClick={()=> takeAllPrint(selected_employee.id,'waste','team_id' )}>
                    <p>Print</p>
                </div>
                <div onClick={() =>{
                     setModalStatus(true);
                     }} className="team-edit-btn">
                    <p>Update</p>
                </div>
                {   selected_employee.id!==user.id ?
                <div onClick={() =>{
                    delete_btn_click()
                    }} className="team-edit-btn">
                    <p>Delete</p> 
                </div> : null
                }
            </div>

            <div className="team-detail-title">
                <p className="team-main-title">{name}</p>
                {/* <p className="team-main-designation">{bio}</p> */}

                {/* <div className="team-msg-btn-layout">
                    <div className="team-msg-btn">
                        <p className="p-0 m-0">Message</p>
                    </div>
                </div> */}
            </div>

            <Scrollbars style={{ height:'35vh' }} autoHide>
            <div className="team-detail-datas">
                
                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Joined Date</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{date_joined && date_joined!=="" ? 
                        moment(date_joined).format('DD-MM-YYYY')                        
                        : "Not Provided"}</p>
                    </div>
                </div>
                    {   termination_date && termination_date!=="" ?
                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Termination Date</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{termination_date && termination_date!=="" ? 
                        moment(termination_date).format('DD-MM-YYYY') : "Not Provided"}</p>
                    </div>
                </div>
                :   null    }

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Position Title</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0"
                        style={{ textTransform:'uppercase' }}>{user_type && user_type!=="" ? user_type : "Not Provided"}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Address</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{address && address!=="" ? address : "Not Provided"}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Contact No</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{contact_number && contact_number!=="" ? contact_number : "Not Provided"}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Email</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{personal_email && personal_email!=="" ? personal_email : "Not Provided"}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Work Email</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{email && email!=="" ? email : "Not Provided"}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Employment Status</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{employement_status && employement_status!=="" ? employement_status : "Not Selected"}</p>
                    </div>
                </div>
                
                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">License Expiry</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{expiry_date && expiry_date!=="" ? moment(expiry_date).isValid() ?
                        moment(expiry_date).format('DD-MM-YYYY') : expiry_date : "Not Provided"}</p>
                    </div>
                </div>
                
                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Alert Before</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{alert_before && alert_before!=="" ? moment(alert_before).isValid() ?
                        moment(alert_before).format('DD-MM-YYYY') : alert_before : "Not Provided"}</p>
                    </div>
                </div>
                

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">DOB</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{date_of_birth && date_of_birth!=="" ? 
                        moment(date_of_birth).format('DD-MM-YYYY') : "Not Provided"}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Username</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{username}</p>
                    </div>
                </div>

                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Emergency Name</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{emergency_contact_name && emergency_contact_name!=="" ? emergency_contact_name : "Not Provided" }</p>
                    </div>
                </div>


                <div className="team-main-data">
                    <div className="team-main-data-left">
                        <p className="p-0 m-0">Emergency Contact</p>
                    </div>
                    <div className="team-main-data-center">
                        <p className="p-0 m-0">:</p>
                    </div>
                    <div className="team-main-data-right">
                        <p className="p-0 m-0">{emergency_contact && emergency_contact!=="" ? emergency_contact : "Not Provided" }</p>
                    </div>
                </div>

            </div>
            </Scrollbars>

            <div className="team-detail-about">
                
            </div>
            <EmployeeEditModal modalStatus={modalStatus} modalAction={setModalStatus} />
            {   ((!modalType) ||
                (modalType && timeSheetLoader)) ?
            <TeamEmployModal modalStatus={timeSheetModal} modalAction={setTimeSheetmodal} usrName={name} usrId={selected_employee.id} modalType={modalType} />
            :
            null
            }
            <Teamvehdelprevntmodal modalStatus={deleteModal} modalAction={setDeleteModal} modalType={'t'} apiStatus={apiRes} />
        </div>
    )
}

const mapPropState = (state) => {
    const { user } = state.userLogin
    const { team } = state
    return {team, user}
}

const mapDispatchToProps = (dispatch) =>{
    return{
        timeSheetInit: (content_Date, userId) => dispatch(team_timeSheet(null, null, null, content_Date, userId)),
        delete_employee_fx: (id, success, failed) => dispatch(delete_employee(id ,success, failed)),
        edit_employee_fx: (content, id) => dispatch(edit_employee(content, id))

    }
}

export default connect(mapPropState, mapDispatchToProps) (EmployeeDetailes)
