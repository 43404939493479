let initialState = {
    notification_list: [],
    notification_arch_list: [],
    archLoader: true,
    notificationLoader: true,
    stopPagingNotify: false,
    stopPagingArch:false,
    newsLoader: true,
    stopPagingNews: false,
    notification_page: 1,
    arch_page: 1,
    pageNewsContent :   true,
    pageNotifyContent :   true,
    pageArchContent: true,
    news_list: [],
    news_page: 1,
    safetyData: null,
    safetyPage: 1,
}


const ohs = (state = initialState, actions) => {

    switch (actions.type) {

        case "OHS_NOTIFY_NEWS_REFRESH":
            return Object.assign({},{
                ...state,
                notification_list: [],
                notificationLoader: true,
                stopPagingNotify: false,
                pageNotifyContent: true,
                notification_page: 1 
            })

        case "OHS_NOTIFICATION_LIST":
            let updatedData = actions.page===1 && (actions.notifications && actions.notifications.length!==0 )  ? actions.notifications : (actions.notifications && actions.notifications.length!==0)  ? [...state.notification_list, ...actions.notifications] : state.notification_list
            return Object.assign({}, { 
                ...state, 
                notification_list: updatedData, 
                notificationLoader: false,
                notification_page: actions.page,
                pageNotifyContent      : (actions.notifications && actions.notifications.length===0)  || (actions.notifications && actions.notifications.length< 8) ,
                stopPagingNotify : (actions.notifications && actions.notifications.length===0)  || (actions.notifications && actions.notifications.length< 8) 
            })

        case "OHS_NOTIFICATION_ARCHIVE_LIST":
            let updatedArchData = actions.page===1 && (actions.notifications_arch_list && actions.notifications_arch_list.length!==0)  ? actions.notifications_arch_list :
                actions.page===1 && (actions.notifications_arch_list && actions.notifications_arch_list.length===0)  ? []
                : (actions.notifications_arch_list && actions.notifications_arch_list.length!==0 ) ? [...state.notification_arch_list, ...actions.notifications] : state.notification_arch_list
            console.log('ARCHIVE',actions.notifications_arch_list, updatedArchData)
            return Object.assign({}, { 
                ...state, 
                notification_arch_list: updatedArchData, 
                archLoader: false,
                arch_page: actions.page,
                pageArchContent      : (actions.notifications_arch_list && actions.notifications_arch_list.length===0)  || (actions.notifications_arch_list && actions.notifications_arch_list.length< 8) ,
                stopPagingArch : (actions.notifications_arch_list && actions.notifications_arch_list.length===0)  || (actions.notifications_arch_list && actions.notifications_arch_list.length< 8) 
            })

        
        case "OHS_NEWS_LIST":
            let updatedNewsData = actions.page===1 && ( actions.news && actions.news.length !==0) ? actions.news : (actions.news && actions.news.length !==0) ? state.news_list.concat(actions.news) : state.news_list
            // console.log('VAL',actions.news.length,actions.news.length === 0 || actions.news.length < 8)
            return Object.assign({}, { 
                ...state, 
                news_list: updatedNewsData, 
                newsLoader: false,
                news_page: actions.page,
                stopPagingNews: (actions.news && actions.news.length === 0) || (actions.news && actions.news.length < 8),
                pageNewsContent : (actions.news && actions.news.length === 0) || (actions.news && actions.news.length < 8)
            })

        case "UPDATE_PAGE":
            return Object.assign({},
                state)

        case "DELETE_NEWS":
            let newsFiltered = state.news_list.filter(el => el.id!==actions.id)
            return Object.assign({},{
                ...state,
                news_list: Object.assign([], newsFiltered)
            })

        case "DELETE_NOTIFY":
            let notifyFiltered = state.notification_list.filter(el => el.id!==actions.id)
            return Object.assign({},{
                ...state,
                notification_list: Object.assign([], notifyFiltered)
            })

        case "UPDATE_NEWS":
            let newsEdittedArr = state.news_list
            let newsArrIdx = newsEdittedArr.findIndex(el => el.id===actions.id)
            newsEdittedArr[newsArrIdx] = actions.content

            return Object.assign({},{
                ...state,
                news_list : Object.assign([], newsEdittedArr)
            })

        case "READ_NEWS":
            let newsREADArr = state.news_list
            let newsReadArrIdx = newsREADArr.findIndex(el => el.id===actions.id)
            newsREADArr[newsReadArrIdx].user_read_status = actions.content

            return Object.assign({},{
                ...state,
                news_list : Object.assign([], newsREADArr)
            })

        case "READ_NOTIFY":
            let notifyREADArr = state.notification_list
            let notifyArchived = state.notification_arch_list
            let notifyReadArrIdx = notifyREADArr.findIndex(el => el.id===actions.id)
            if( notifyReadArrIdx!== -1)
                notifyREADArr[notifyReadArrIdx].user_read_status = actions.content
            let notifyReadArrArchIdx = notifyArchived.findIndex(elm => elm.id===actions.id);
            if( notifyReadArrArchIdx !== -1)
                notifyArchived[notifyReadArrArchIdx].user_read_status = actions.content
            
            return Object.assign({},{
                ...state,
                notification_list : Object.assign([], notifyREADArr),
                notification_arch_list : Object.assign([], notifyArchived)
            })

        case "OHS_SAFETY":
            return Object.assign({},{
                ...state,
                safetyData: actions.safetyData,
            })
        default:
            return state

    }
}

export default ohs