import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import { useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import './styles.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  

const SignaturePadModal = ({
    modalAction,
    modalStatus,
    imgFn
}) => {
    const classes = useStyles();
    // const signPadRef = useRef()
    const [signState, setSignState] = useState(false)
    const [signPadRef, setSignPadRef] = useState(null)
    const [load, setLoad] = useState(false)
    const handleClose = () => {
        setLoad(false)
        setSignState(false)
        modalAction(false)
    };

    const convtUrlFile = async (rawData) =>{
        var res = await fetch(rawData)
        var blobRes = await res.blob()
        var fs = new File([blobRes],`Signature${moment().format('YYYY-MMM-DD')}`)
        var arr = new Array(1)
        arr[0] = fs
        console.log('RAWDATA=>',arr)
        imgFn(arr)
        handleClose()
    }

    console.log('SIGNPAD',signPadRef)
    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        {
            signState ?
        <div className='signpadModal' style={{ height:'40vh', width: '40vw', overflowY:'auto' }}>
            <div className='signpadModalHeading'>
                Sign Here.
            </div>
            <ReactSignatureCanvas
                ref={(res) => setSignPadRef(res)} 
                backgroundColor={'rgba(245, 246, 250,1.0)'}
                canvasProps={{ className : 'signpadStyle'}}
            />
            <div className='signpadModalButtonDiv'>
                <div className='signpadModalUpload'
                    onClick={()=>{
                        signPadRef.clear()
                    }}
                >
                    Clear Pad
                </div>
                {
                    load ?
                        <CircularProgress />
                    :
                    <div className='signpadModalStencil'
                    onClick={()=>{
                        setLoad(true)
                        var rawData = signPadRef.getTrimmedCanvas().toDataURL('image/png')
                        convtUrlFile(rawData)
                    }}
                    >
                        Upload Sign
                    </div>
                }
            </div>
        </div>
        :
        <div className='signpadModal' style={{ overflowY:'auto' }}>
            <div className='signpadModalHeading'>
                How do you want to add the signature ?
            </div>
            <div className='signpadModalButtonDiv'>
                <label htmlFor="file-sign-input"  className='signpadModalUpload'>
                    {
                        load ?
                            <CircularProgress />
                        :
                            <div className='signpadModalUpload'>
                                Upload Image
                            </div>
                    }
                </label>
                <input 
                    id="file-sign-input"
                    style={{ display: 'none' }}
                    type="file" 
                    accept='image/*'
                    multiple={false}
                    onChange={files => {
                        setLoad(true)
                        imgFn(files.target.files)
                        handleClose()
                    }}
                />
                {
                    load ?
                        <CircularProgress />
                    :
                        <div className='signpadModalStencil' onClick={()=>{
                            setSignState(true)
                        }}>
                            Sign with Stencil/ Finger
                        </div>
                }
            </div>
        </div>
        }
      </Modal>
    );
}

export default SignaturePadModal;
