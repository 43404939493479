import React, { useEffect, useState, Fragment } from 'react'

import FolderIcon from '@material-ui/icons/Folder'
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Swal from 'sweetalert2'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import Scrollbar from '../ImprovedScrollBar';

import Api from '../../store/api'
import '../../screens/intranet/components/style.css'
import { 
    FILES_FOLDERS_SEARCH, SITE_FILESANDFOLDERS_SEARCH, 
    HOST_WITHOUT_SLASH, FILES_FOLDERS_MULTIPLE_DEL, FILE_EXPIRY_DATE } from '../../store/endpoint';
    // , INTRANET_FOLDER_EDIT, OHS_AND_S_FILE_ADD,
    // OHS_AND_S_FOLDER_CREATE, OHS_AND_S_FOLDER_DELETE, OHS_AND_S_FILE_DELETE 

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal, Tooltip } from '@material-ui/core'
import { CheckBoxOutlineBlankOutlined, Close, DeleteForever, DeleteRounded, Edit, ScheduleRounded, Search  } from '@material-ui/icons'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
// import Tooltip from '@material-ui/core/Tooltip';
import { cl_green, cl_red } from '../Colors';
import { useLocation } from 'react-router';
import FileCarousel from './FileCarousel'; 
import AddEditFileFolder from './AddEditFileFolder';
import DateTimeInput from '../DateTimeInput'; 
import moment from 'moment';
import FilesDragAndDrop from '../DragNDropFiles';
import SingleFile from './SingleFile';
// import FilesFoldersSearchTab from './FilesFoldersSearchTab';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperImg: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const ExpireFileModal = ({
    modalStatus,
    modalAction,
    folderId,
    item,
    loadingFolder,
    setLoadingFolder,
    expiryAction
}) => {
    const classes = useStyles();
    const handleClose = () => {
        console.log('CLOSED EXPIRE')
        modalAction(false)
    };
    const [fileDate, setFileDate] = useState(new Date())
    const cropFileName = (str) =>{
        let lastIdx = str ? str.length : null
        if(lastIdx)
            return lastIdx > 25 ? `${str.substr(0,10)}...${str.substr(str.length-3, str.length)}` : str 
        else 
            return 'Untitled'
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >
            <div className="intranet-folder-modal-layout" style={{ overflowY:'auto' }}>
                <div className="intranet-modal-title-layout" style={{ marginBottom:'1%' }}>
                    <p>Set File Expiration</p>
                    <Close
                     onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </div>
                <div className='intrnet-modal-title-description'>
                    {
                        item ?
                        item.expiry_date ?
                            <p>File ({cropFileName(item.name)}) is set to expire on {item.expiry_date}</p>
                        :
                            <p>File ({cropFileName(item.name)}) expiration date is not set</p>
                        :
                            null
                    }
                    
                </div>
                    <DateTimeInput
                        dateValue={fileDate} dateSetFn={setFileDate} past={true} pickerVariant={'static'} pickerType={''} pickerOrientation={'landscape'}
                    />
                <div className="intranet-btn-layout">
                    <button
                     onClick={() => handleClose()} className="intranet-cancel-btn">CANCEL</button>
                    <button className={ loadingFolder ? "intranet-create-btn disabled-btn" : "intranet-create-btn"}
                        onClick={()=> expiryAction(item.id,fileDate) }
                    >SET EXPIRATION</button>
                </div>
                
            </div>

        </Modal>
    )
}



function IntranetLayout ({APIURL, title, disableSearch=false,
    FOLDEREDIT=null, FOLDERCREATE=null, FOLDERDELETE=null, 
    FILEADD=null, FILEDELETE=null, FILEEDIT=null, CALLBACKFUNCMODAL=null,
    VMNTCFID=null,
    SELECTID=null, tabType=null, subTabType=null, disableRoot=false, filesOnly=false }) {

    const [foldersData, setFoldersData]     = useState([])
    const [filesData, setFilesData]         = useState([])
    const [navigator, setNavigator]         = useState([])
    const [folderModal, setFolderModal]     = useState(false)
    const [folderName, setFolderName]       = useState('')
    const [zFolderId, setFolderId]          = useState('')
    const [renameEntity, setRenameEntity]   = useState('')
    const [modalType, setModalType]         = useState(true) //true for create false for edit
    //const [uploadedFilesData, setUploadedFilesData] = useState([])
    const [folderSelect, setFolderSelect]   = useState(null)
    const [fileSelect, setFileSelect]       = useState(null)
    // const [tooltipSel, setTooltipSel]       = useState(null)
    const [folderType, setFolderType]       = useState('general')
    const [ParentFolderId, setPFldrId]      = useState(1)
    const [initLoader, setInitLoader]       = useState(true)
    const [prevFolder, setPrevFolder]       = useState({id:'', name:''})
    const [delFolders, setDelFolders]       = useState([])
    const [delFiles, setDelFiles]           = useState([])

    const [loadingFolder, setLoadingFolder] = useState(false)
    const [carouselOpen, setCarouselOpen]   = useState(false)
    const [fileArr, setFileArr]             = useState({ name:null, arr:[] })
    const [carouselHead, setCarouselHead]   = useState('')
    const [deleteExpire, setDeleteExpire]   = useState({fileModal:false, folderId:null, item:null})

    useEffect(() => {
        const fetch_initail_folderz = () => {
            let api_url = disableRoot ? `${APIURL}/` : `${APIURL}${1}/`
            console.log(api_url)
            Api('get', api_url).then(response => {
                if(response && response.folders){
                console.log('FILES-FOLDERS',response)
                const { files, folders, type } = response.folders[0] 
                setFoldersData(folders)
                setFolderType(type ? type : 'general')
                setFilesData(files)
                setInitLoader(false)
                }
                else{
                    setInitLoader(false)
                }
                setNavigator([])
            })
        }
        fetch_initail_folderz()
    }, [APIURL, disableRoot])

    const cropFileName = (str) =>{
        let lastIdx = str ? str.length : null
        if(lastIdx)
            return lastIdx > 15 ? `${str.substr(0,10)}...${str.substr(str.length-3, str.length)}` : str 
        else 
            return 'Untitled'
    }

    let location = useLocation()
            
    const fetch_initail_folder = () => {
        let api_url = disableRoot ? `${APIURL}/` : `${APIURL}${1}/`
        Api('get', api_url).then(response => {
            const { files, folders, type } = response.folders[0]
            setFoldersData(folders)
            setFolderType(type ? type : 'general')
            setFilesData(files)
            setNavigator([])
            setInitLoader(false)
        })
        .catch(() => {
            setInitLoader(false)
            
        })
    }
    

    const fetch_folder_data = (folder_id, folder_name) => {
        let api_url = `${APIURL}${folder_id}/`
        setPFldrId(folder_id)

        setPrevFolder({id: folder_id, name: folder_name})
        Api('get', api_url).then(response => {
            console.log('SINGLEFOLDER',response)
            const { files, folders, type } = response.folders[0]
            setFoldersData(folders)
            setFolderType(type ? type : 'general')
            setFilesData(files)
            setInitLoader(false)
            setCarouselHead(folder_name)
            setLoadingFolder(false)
            let navData = {
                id: folder_id,
                name: folder_name,
                type: type
            }

            let checkItemAlredyPResent = navigator.find(itm => itm.id === folder_id)
            if(checkItemAlredyPResent) {
                let checkItem = navigator.indexOf(checkItemAlredyPResent)
                let itemArr = []
                let i;
                for(i=0; i<=checkItem; i++) {
                    itemArr.push(navigator[i]);
                }
                itemArr[checkItem] = navData
                setNavigator(itemArr)
            } else {
                setNavigator(item => [...item, navData])
            }
            
        })
        .catch(() => {
            setInitLoader(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            
        })
    }

    const multiple_delete = () =>{

        let prev_folder_id = ''
        let prev_folder_name = ''
    
        if(delFiles && delFiles.length!==0){
            if(navigator.length > 1) {
           
                prev_folder_id = navigator[navigator.length - 1].id
                prev_folder_name = navigator[navigator.length - 1].name
            }
            let data = new FormData()

            for(let i=0; i < delFiles.length; ++i){
                data.append('id',delFiles[i])
            }
        const success = () => {
            setDelFiles([])
            setLoadingFolder(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Files deleted successfully!',
                showConfirmButton: false,
                timer: 1500
            })
            if(navigator.length===1 && !disableRoot){
                fetch_folder_data(navigator[0].id, navigator[0].name)
            }
            else if(navigator.length > 1 && !disableRoot) {
                fetch_folder_data(prev_folder_id, prev_folder_name)
            } else {
                fetch_initail_folder()
            }

            if(CALLBACKFUNCMODAL!==null)
                CALLBACKFUNCMODAL(true)
    
        }

        const failed = () => {
            setLoadingFolder(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            if(CALLBACKFUNCMODAL!==null)
                CALLBACKFUNCMODAL(true)
    
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = `${FILES_FOLDERS_MULTIPLE_DEL}file/`
                Api('post-form', api_url, data, success, failed)
            }
        })
            // }
            
        }
        if(delFolders && delFolders.length!==0){
            if(navigator.length > 0) {
           
                prev_folder_id = navigator[navigator.length - 1].id
                prev_folder_name = navigator[navigator.length - 1].name
            }

            if(delFolders.length===1)
                deleteFolderApiLatest(delFolders[0])
            else{

            let dataF = new FormData()
            for(let i=0; i < delFolders.length; ++i)
                dataF.append('id', delFolders[i])

            const success = () => {

                if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
                setDelFolders([])
                setLoadingFolder(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Folders deleted successfully!',
                    showConfirmButton: false,
                    timer: 1500
                })
                
                if(navigator.length > 0) {
                    fetch_folder_data(prev_folder_id, prev_folder_name)
                }
                else 
                    fetch_initail_folder()
                
        
            }
    
            const failed = () => {
                setLoadingFolder(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
        
            }

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: cl_red,
                cancelButtonColor: cl_green,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    let api_url = `${FILES_FOLDERS_MULTIPLE_DEL}folder/`
                    Api('post-form', api_url, dataF, success, failed)
                }
            })
            }
            
        }
    }

    const search_files = (keyword, type) =>{
        let data = new FormData()
        data.append('key',keyword)
        data.append('folder_id', navigator&&navigator.length===0 ? !disableRoot ? 1 : VMNTCFID : ParentFolderId)
            data.append('search_type',type)
            console.log('serchType',type)
        
        if(type==='team-individual-private'){
            // let splitPath = APIURL.split('folder/')
            data.append('employee',SELECTID)
        }else if(subTabType!==null){
            // let splitPath = APIURL.split('folder/')
            data.append('vehicle_id',SELECTID)    
            data.append('vehicle_type', subTabType)
        }else if(location.pathname==='/client/detail'){
            // let splitPath = APIURL.split('folder/')
            data.append('site',SELECTID)    
        }
        console.log(keyword,ParentFolderId)
        let searchType = navigator && navigator.length > 0 ? "file" : "folder" 
        if(keyword!==""){
            let fileUrl = location.pathname==='/client/detail' ?
            SITE_FILESANDFOLDERS_SEARCH : FILES_FOLDERS_SEARCH
            Api('post-form', `${fileUrl}${searchType}/`, data).then(response =>
            { console.log('FOLDERSEARCH=>',response); 
                if( !(response && response.folders)
                    || 
                    (navigator && navigator.length === 0))
                    setFoldersData(response)
                else{
                    const { files, folders, type } = response.folders[0]
                    setFoldersData(folders)
                    setFolderType(type ? type : 'general')
                    setFilesData(files)
                    
                }
                }
            )    
        }
        
        
    }

    const set_expiry = (file, expdate) =>{
        let data = new FormData()
        data.append('date',expdate) 
        Api('put', `${FILE_EXPIRY_DATE}${file}/`, data).then(response =>
        {
            console.log(response); 
            setDeleteExpire({fileModal:false, folderId:null, item:null})
            if(navigator.length > 0) {
                let prev_folder_id = navigator[navigator.length - 1].id
                let prev_folder_name = navigator[navigator.length - 1].name
                fetch_folder_data(prev_folder_id, prev_folder_name)
            } else {
                fetch_initail_folder()
            }
                    
                setLoadingFolder(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Expiration Date Added Successfully!`,
                    showConfirmButton: false,
                    timer: 1500
                })
            
        }
            )    
        
        
    }

    const createFolderApi = () => {

        let data = new FormData()
        let folder_id
        let folder_name_data
        if(navigator.length > 0) {
            folder_id = navigator[navigator.length - 1].id
            folder_name_data = navigator[navigator.length - 1].name
        } else {
            folder_id = 1
        }

        data.append('name', folderName)
        data.append('parent_folder', folder_id)
        
        if(location.pathname==='/team/detail'){
            // let splitPath = APIURL.split('folder/')
            data.append('employee',SELECTID)
        }else if(subTabType!==null){
            // let splitPath = APIURL.split('folder/')
            data.append('vehicle_id',SELECTID)    
            data.append('vehicle_type', subTabType )
        }else if(location.pathname==='/client/detail'){
            // let splitPath = APIURL.split('folder/')
            data.append('site',SELECTID)    
        }
        const success = () => {
            setFolderName('')
            setFolderModal(false)
            setLoadingFolder(false)
            if(navigator.length > 0) {
                fetch_folder_data(folder_id, folder_name_data)
            } else {
                fetch_initail_folder()
            }
            
        }

        const failed = () => {
            if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
            setFolderName('')
            setFolderModal(false)
            setLoadingFolder(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                
        
        }

        Api('post-form', FOLDERCREATE, data, success, failed)
    }


    const updateFolderApiLatest = (folderId, entity) => {


        let api_url = ''
        if(entity==='Folder')
            api_url = `${FOLDEREDIT}${folderId}/`
        else 
            api_url = `${FILEEDIT}${folderId}/`
        console.log(api_url,entity,folderName)

        let data = new FormData()
        data.append('name', folderName)

        const success = (res) => {
            if(res.status === 200) {
                setFolderName('')
                setFolderModal(false)
                setFolderId('')
                setRenameEntity('')
                if(navigator.length > 0) {
                    let prev_folder_id = navigator[navigator.length - 1].id
                    let prev_folder_name = navigator[navigator.length - 1].name
                    fetch_folder_data(prev_folder_id, prev_folder_name)
                } else {
                    fetch_initail_folder()
                }
                
            } 
            setLoadingFolder(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `${entity} renamed successful!`,
                showConfirmButton: false,
                timer: 1500
            })
        }

        Api('put', api_url, data, success)
    }


    const deleteFolderApiLatest = (folderId) => {
        console.log(navigator);
        if(navigator.length > 0) {
            
            let prev_folder_id = navigator[navigator.length - 1].id
            let prev_folder_name = navigator[navigator.length - 1].name



            const success = () => {
                if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
                    setLoadingFolder(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Folder deleted successful!',
                    showConfirmButton: false,
                    timer: 1500
                })
                fetch_folder_data(prev_folder_id, prev_folder_name)
                
        
            }
    
            const failed = () => {
                setLoadingFolder(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
        
            }

            
            
            

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: cl_red,
                cancelButtonColor: cl_green,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    let api_url = `${FOLDERDELETE}${folderId}/`
                    Api('delete', api_url, '', success, failed)
                }
            })
        } else {


            const success = () => {
                if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
                    setLoadingFolder(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Folder deleted successful!',
                    showConfirmButton: false,
                    timer: 1500
                })
                fetch_initail_folder()
            }
    
            const failed = () => {
                setLoadingFolder(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
                if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
                
            }

        
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: cl_red,
                cancelButtonColor: cl_green,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    let api_url = `${FOLDERDELETE}${folderId}/`
                    Api('delete', api_url, '', success, failed)
                }
            })
        }
    }


    const file_upload = (data) => {
        
        setInitLoader(true)
        
        const success = (res) => {
            setInitLoader(false)
            if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
            if(navigator.length > 0) {
                let folder_id_tst = navigator[navigator.length - 1].id
                let folder_name_tst = navigator[navigator.length - 1].name
                fetch_folder_data(folder_id_tst, folder_name_tst)
            } else {
                fetch_initail_folder()
            }
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'File uploaded successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = (res) => {
            setInitLoader(false)
            console.log('upload failed',res)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.app_data ? res.app_data : 'Something went wrong!'
            })
            if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
        }
        console.log('uploading blah')
        let data_val = new FormData()
        let i;
        let folder_id
        if(navigator.length > 0) {
            folder_id = navigator[navigator.length - 1].id
        } else {
            folder_id = 1
        }
        for(i=0; i<data.length; i++) {
            let file = data[i];
            let file_name = data[i].name
            data_val.append('file', file, file_name)
            console.log(file,file_name)
        }
        if(location.pathname==='/team/detail'){      
            // let splitPath = APIURL.split('folder/')
            data_val.append('employee',SELECTID)
            console.log(SELECTID)
        }
        if(location.pathname==='/client/detail'){      
            // let splitPath = APIURL.split('folder/')
            data_val.append('site',SELECTID)
            console.log(SELECTID)
        }
        
        
        console.log(folder_id)
            data_val.append('folder', VMNTCFID===null ? folder_id : VMNTCFID)
        Api('post-form', FILEADD, data_val, success, failed)
    }

    const deleteSingleFile = (folderId, fileID) =>{

        let prev_folder_id = ''
        let prev_folder_name = ''
        
        if(navigator.length > 1) {
           
            prev_folder_id = navigator[navigator.length - 1].id
            prev_folder_name = navigator[navigator.length - 1].name
        }

        const success = () => {
            if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'File deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            if(navigator.length===1){
                fetch_folder_data(navigator[0].id, navigator[0].name)
            }
            else if(navigator.length > 1) {
                fetch_folder_data(prev_folder_id, prev_folder_name)
            } else {
                fetch_initail_folder()
            }
            
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            if(CALLBACKFUNCMODAL!==null)
                    CALLBACKFUNCMODAL(true)
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = `${FILEDELETE}${fileID}/${VMNTCFID===null ? folderId : VMNTCFID}/`
                Api('delete', api_url, '', success, failed)
            }
        })
    

    }

    const SingleFolder = ({item}) => {
        const { id, name } = item
        return (
                <Tooltip
                // open={tooltipSel === `folder#${id}`} 
                title={name} 
                arrow={true} placement={'top'}
                >
                <div 
                    onMouseEnter={() =>{ if(item.type!=='team-individual' && item.type!=='site-individual'){ setFolderSelect(id);}
                    // setTooltipSel(`folder#${id}`); 
                }}
                    onMouseLeave={() =>{ setFolderSelect(null); 
                        //setTooltipSel(null); 
                    }}
                    className={ 
                        delFolders && delFolders.includes(id) ?
                     "intranet-main-folder-card intranet-main-folder-card-selected" : "intranet-main-folder-card"}
                     >
                        { (item.type==='team-individual' || item.type==='site-individual') ?
                         <span className="infoFolderChip" >System Folder</span> : null }
                        {   
                            folderSelect === id && FOLDERDELETE!==null && !(delFolders && delFolders.includes(id)) && 
                            (
                                
                                <DeleteForever
                                    className="singleFolderDeleteIcon"
                                    style={{ fontSize: 16 }}
                                    onClick={() =>{ 
                                    if(CALLBACKFUNCMODAL!==null){
                                        CALLBACKFUNCMODAL(false)
                                    }
                                    if(!loadingFolder){
                                        setLoadingFolder(true) 
                                        deleteFolderApiLatest(id);
                                }

                                }}
                                />
                            )
                        }

                        {
                          folderSelect === id && FOLDEREDIT!==null && !(delFolders && delFolders.includes(id)) && 
                          (
                                <Edit 
                                    className="singleFolderEditIcon"
                                    style={{ fontSize: 16 }}
                                    onClick={() => {
                                        setRenameEntity('Folder')
                                        setModalType(false)
                                        setFolderName(name)
                                        setFolderId(id)
                                        setFolderModal(true)
                                        
                                    }}
                                />
                            )
                        }
                        {/* {
                            
                            folderSelect === id ?
                            delFolders && delFolders.includes(id) ?
                                <CancelIcon
                                    className="singleFolderSelectedIcon"
                                    style={{ fontSize: 16 }}
                                    onClick={() => {
                                        let newArr = delFolders.filter(item => item!==id)
                                        setDelFolders(newArr)
                                    }}
                                />
                            :
                                <CheckCircleOutlineRounded 
                                    className="singleFolderSelectIcon"
                                    style={{ fontSize: 16 }}
                                    onClick={() => {
                                        let newArr = delFolders
                                        newArr.push(id)
                                    setDelFolders(newArr)
                                
                                    }}
                                />
                            :   null
                        } */}
                        
                    
                    
                    <FolderIcon 
                        onClick={() =>{ 
                            if(!loadingFolder){
                                setLoadingFolder(true)
                                fetch_folder_data(id, name);
                            }
                        }} 
                        style={{ fontSize: 35, marginRight: 10, cursor: 'pointer' }} />
                    <p style={{ cursor: 'pointer' }} onClick={() =>{ 
                            if(!loadingFolder){
                                setLoadingFolder(true)
                                fetch_folder_data(id, name);
                            }
                        }} 
                        className="folder-title">
                            {name}
                        </p>
                </div>
                </Tooltip>
        )
    }

    // const SingleFile = ({ item, folderId }) => {
    //     const { name, url, expiry_date } = item
    //     let fileUrl = HOST_WITHOUT_SLASH + url
    //     let split_arr = fileUrl.split('.')
        
    //     return (
    //         <Tooltip
    //             title={name} 
    //             arrow={true} placement={'top'}
    //             >
                
    //             <div 
    //                 className={ (delFiles && delFiles.includes(item.id)) ? "intranet-main-file-card intranet-main-file-card-selected" : "intranet-main-file-card" }
    //                 onMouseEnter={() =>{ setFileSelect(item.id); 
    //                     // setTooltipSel(`file#${item.id}`); 
    //                 }}
    //                 onMouseLeave={() =>{ setFileSelect(null); 
    //                     // setTooltipSel(null); 
    //                 }}
    //                 style={{ position:'relative', zIndex:1 }}
    //                 onClick={(e)=>{ 
    //                     e.stopPropagation(); 
    //                     e.preventDefault(); 
    //                 }}>
    //                 {   !(delFiles && delFiles.includes(item.id)) && FILEDELETE!==null ?
    //                 <div 
    //                     className="singleFileDeleteIcon" 
    //                     onClick={(e)=>{ 
    //                         e.preventDefault(); 
    //                         e.stopPropagation(); 
    //                         deleteSingleFile(folderId, item.id);
    //                         if(CALLBACKFUNCMODAL!==null){
    //                             CALLBACKFUNCMODAL(false)
    //                         }
    //                     }}>
    //                     <DeleteForever style={{ fontSize:18 }} />
    //                 </div>  :   null
    //                 }
    //                 {   FILEDELETE!==null ?
    //                     (delFiles && delFiles.includes(item.id)) ?
                        
    //                 <div 
    //                     className="singleFileSelectIcon" 
    //                     onClick={(e)=>{ 
    //                         e.preventDefault(); 
    //                         e.stopPropagation(); 
    //                         let newArr = delFiles.filter(itemData => itemData!==item.id)
    //                         setDelFiles(newArr)
    //                     }}>
    //                     <CheckBoxOutlinedIcon style={{ fontSize:18 }} />
    //                 </div>    
    //                 :
                        
    //                 <div 
    //                     className="singleFileSelectIcon" 
    //                     onClick={(e)=>{ 
    //                         e.preventDefault(); 
    //                         e.stopPropagation(); 
    //                         let newArr = delFiles
    //                         newArr.push(item.id)
    //                         setDelFiles(newArr);
    //                     }}>
    //                     <CheckBoxOutlineBlankOutlined style={{ fontSize:18 }} />
    //                 </div>  :   null
    //                 }
    //                 <div onClick={() =>{ 
    //                     if(split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif'){
    //                         setFileArr({ name: carouselHead, 
    //                             arr: filesData.filter((el)=>{ 
    //                                 let spl = el.url.split('.')
    //                                 if((spl[spl.length-1]==='jpg' || spl[spl.length-1]==='png' || spl[spl.length-1]==='jpeg' || spl[spl.length-1]==='gif')&&
    //                                 (folderType==='team-individual' ? el.id===item.id : true ))
    //                                     return el
    //                                 else
    //                                     return null }) })
    //                         setCarouselOpen(true); 
    //                     }else window.open(fileUrl, "_blank"); }}  className="main-file-icon">
    //                     {
    //                         split_arr[split_arr.length-1]==='jpg' || split_arr[split_arr.length-1]==='png' || split_arr[split_arr.length-1]==='jpeg' || split_arr[split_arr.length-1]==='gif' ?
    //                             <img src={fileUrl} alt="ImageError" style={{ objectFit:'cover', width: 100, height: 100 }} />
    //                         :
    //                         split_arr[split_arr.length-1]==='pdf' ?
    //                             <iframe src={fileUrl} frameborder={"0"} allowTransparency style={{ objectFit:'cover', width: 100, overflow : 'hidden' }}/>
    //                         :
    //                             <DescriptionIcon style={{ fontSize: 40 }} />
    //                     }
    //                 </div>
    //                 <div className="main-file-title">
    //                     {
    //                         fileSelect !== item.id && expiry_date ? 
    //                         <div className='fileExpiryChip'>
    //                             File Expires on : {moment(expiry_date).isValid() ? moment(expiry_date).format('DD-MM-YYYY') : expiry_date}
    //                         </div> 
    //                         : null
    //                     }
    //                     {
    //                         fileSelect === item.id && FILEEDIT!==null && (
    //                             <div className="singleFileDeleteIcon" style={{ backgroundColor:'transparent', marginLeft:'160px' }}>
    //                             <Edit 
    //                                 style={{ fontSize: 16 }}
    //                                 onClick={() => {
    //                                     setRenameEntity('File')
    //                                     setModalType(false)
    //                                     setFolderName(name)
    //                                     setFolderId(item.id)
    //                                     setFolderModal(true)
                                        
    //                                 }}
    //                             />
    //                             </div>
    //                         )
    //                     }
    //                     {
    //                         fileSelect === item.id && FILEEDIT!==null && ("expiry_date" in item) &&(
                                
    //                 <div 
    //                 className="singleFileDeleteIcon" style={{ marginLeft:'130px' }}
    //                 onClick={(e)=>{ 
    //                     e.preventDefault(); 
    //                     e.stopPropagation(); 
    //                     setDeleteExpire({ fileModal:true, folderId:folderId, item:item});
    //                     // if(CALLBACKFUNCMODAL!==null){
    //                     //     CALLBACKFUNCMODAL(false)
    //                     // }
    //                 }}>
    //                 <DeleteRounded style={{ fontSize:18 }}/>
    //                 <ScheduleRounded style={{ position:'absolute', fontSize:14, right:'1%', bottom:'1%', borderRadius:'50%', backgroundColor:'var(--cl_ivery)' }}  />
    //             </div>
    //                         )
    //                     }
                            
    //                     <div style={{ display: 'block' }}>
    //                         <p style={{ height: 17, width: 192, overflow: 'hidden', whiteSpace : 'nowrap', textOverflow : 'ellipsis' }}>
    //                             {folderType==='team-individual' ? item.team_member : name}
    //                         </p>
    //                         {/* <p style={{ height: 17, width: 192, overflow: 'hidden' }}>
    //                             {cropFileName( folderType==='team-individual' ? item.team_member : name)}
    //                         </p> */}
                            
    //                     </div>
    //                 </div>
    //             </div>
    //         </Tooltip>
    //     )
    // }



    return (
        <div className="intranet-main-layout"
            onDoubleClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className="intranet-main-header">
                <Scrollbar autoHide style={{ height: 50, width: '70%' }}>
                    <div style={{ display: 'flex', height: 50, alignItems: 'center' }}>
                        <div onClick={() => fetch_initail_folder()} className="intranet-head-button">
                            <p>{title}</p>
                        </div>
                        {
                            navigator.map((item, key) => {
                                const { id, name } = item
                                return (
                                    <Fragment key={key}>
                                    <ArrowForwardIosIcon style={{ fontSize: 18, color: 'rgb(77, 77, 77)'  }} />
                                    <div 
                                        onClick={() => {
                                            console.log(item.type)
                                            fetch_folder_data(id, name)
                                                                                 
                                        }}
                                        className="intranet-head-button-b">
                                        <p>{name}</p>
                                    </div>
                                    </Fragment>
                                )
                            })
                        }
                        
                    </div>
                </Scrollbar>
                {/* <FilesFoldersSearchTab
                    navigator={navigator} fetch_folder_data={fetch_folder_data} fetch_initail_folder={fetch_initail_folder}
                    prevFolder={prevFolder} type={folderType} subTabType={subTabType} SELECTID={SELECTID} path={location.pathname}
                    setFilesData={setFilesData} setFoldersData={setFoldersData} setFolderType={setFolderType}
                /> */}
                {   !disableSearch && ((navigator && navigator.length > 0) || filesOnly) ?
                    <div className='Search-an-employe' style={{ border : '1px solid grey', width:'18vw' }}>
                         <input 
                            className='Search-an-employe-input'
                            placeholder="Search "
                            onChange={txt =>{ 
                                if(txt.target.value===''){ 
                                    console.log('CALLED',txt)
                                    fetch_folder_data(prevFolder.id, prevFolder.name )
                                }  
                                else 
                                    search_files(txt.target.value, folderType)
                            }
                        }
                        />

                { !disableSearch ? <Search   style={{ fontSize:'18px', display:'flex', alignItems:'center',
                 justifyContent:'center' }} color='inherit' /> : null
                 }
                    </div>
                      : 
                      !disableSearch ?
                      <div className='Search-an-employe' style={{ border : '1px solid grey', width:'18vw' }}>
                         <input 
                            className='Search-an-employe-input'
                            placeholder="Search folders"
                            onChange={txt =>{
                                if(txt.target.value===''){
                                    console.log('CALLED',txt)
                                    fetch_initail_folder(); 
                                }else
                                    search_files(txt.target.value, folderType)}}
                        />
                { !disableSearch ?
                        <Search   style={{ fontSize:'18px', display:'flex', alignItems:'center',
                 justifyContent:'center' }} color='inherit' /> : null}
                    </div>
                    : null
                }
                <div style={{ display: 'flex', alignItems: 'center' }}>
                
                    {  filesOnly ?
                        <label htmlFor="file-input">
                        <div className="add-files-btn">
                            <AddCircleOutlineIcon style={{ marginRight: 7 }} />
                            <p>Files</p>
                        </div>
                    </label>
                    :
                       FILEADD!==null  ?
                    navigator.length===0 ?
                    null :
                    <label htmlFor="file-input">
                        <div className="add-files-btn">
                            <AddCircleOutlineIcon style={{ marginRight: 7 }} />
                            <p>Files</p>
                        </div>
                    </label>     : null
                    }
                    {   filesOnly ?
                        <input id="file-input" 
                        style={{ display: 'none' }} 
                        type="file"
                        multiple
                        onChange={(image) => {
                        // setFilesData(image.target.files)
                        // console.log(image)
                            file_upload(image.target.files)
                        }}
                    />
                    :
                       FILEADD!==null    ?
                    navigator.length===0 ?
                    null    :
                    <input id="file-input" 
                        style={{ display: 'none' }} 
                        type="file"
                        multiple
                        onChange={(image) => {
                        // setFilesData(image.target.files)
                        // console.log(image)
                            file_upload(image.target.files)
                        }}
                    />  : null
                    }
                    {   
                       FOLDERCREATE!==null  ?
                    <div 
                    onDoubleClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                        onClick={() => {
                            setModalType(true)
                            setFolderName('')
                            setFolderModal(true)
                        }} 
                        className="add-folder-btn">
                        <AddCircleOutlineIcon style={{ marginRight: 7 }}/>
                        <p>Folder</p>
                    </div>  :   null
                    }
                    
                    {   
                    ((delFiles && delFiles.length!==0) || (delFolders && delFolders.length!==0)) ?
                    <div 
                    onDoubleClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                        onClick={() => {
                            if(CALLBACKFUNCMODAL!==null){
                                CALLBACKFUNCMODAL(false)
                            }
                            if(!loadingFolder){
                                setLoadingFolder(true)
                                multiple_delete()
                            } 
                                 
                        }} 
                        className="add-folder-btn" style={{ backgroundColor:'red', color:'white'}}>
                        <DeleteForever style={{ marginRight: 7 }}/>
                        <p>All</p>
                    </div>
                    :   null
                    }
                    {
                        ((delFiles && delFiles.length!==0) || (delFolders && delFolders.length!==0)) ?
                    <div 
                    onDoubleClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                        onClick={() => {
                            setDelFiles([]);
                            setDelFolders([])    
                        }} 
                        className="add-folder-btn" >
                        <CancelIcon style={{ marginRight: 7 }}/>
                        <p>Deselect</p>
                    </div>
                    :   null
                    }
                </div>         
            </div>
            
            {
                !initLoader ? (
                    <div className="intranet-main-body">
                        {/* <Scrollbar style={{ height: '' }}> */}
                        
                            { filesOnly ? null : <p className="body-main-title">Folders</p> }
                            { filesOnly ? null : 
                            <div className="intranet-folder-layout">
                                {
                                    foldersData.length > 0 ? (
                                        <Fragment>
                                            {
                                                foldersData.map((item, key) => {
                                                    return (<SingleFolder item={item} key={key} />)
                                                })
                                            }
                                        </Fragment>
                                    ) : (
                                        <div style={{ fontSize: 12, width: 200 }}>
                                            <p>No Folders Found!</p>
                                        </div>
                                    )
                                }
                            </div> 
                            }
                            { filesOnly ?   <p className="body-main-title">Files</p>
                                : navigator && navigator.length===0  ? null :
                            <p className="body-main-title">Files</p>
                            }
                            { 
                            filesOnly ?
                            <div className="intranet-files-layout">

                                {
                                    filesData.length > 0 ? (
                                        <Fragment>
                                            {
                                                filesData.map((item, key) => {
                                                    return (<SingleFile 
                                                        folderId={ navigator.length===0 ? "1" : ParentFolderId} 
                                                        item={item} 
                                                        key={key} 
                                                        setFileSelect={setFileSelect}
                                                        fileSelect={fileSelect}
                                                        setDelFiles={setDelFiles}
                                                        delFiles={delFiles}
                                                        FILEDELETE={FILEDELETE}
                                                        CALLBACKFUNCMODAL={CALLBACKFUNCMODAL}
                                                        deleteSingleFile={deleteSingleFile}
                                                        setFileArr={setFileArr}
                                                        setCarouselOpen={setCarouselOpen}
                                                        setRenameEntity={setRenameEntity}
                                                        setModalType={setModalType}
                                                        setFolderName={setFolderName}
                                                        setFolderId={setFolderId}
                                                        setFolderModal={setFolderModal}
                                                        setDeleteExpire={setDeleteExpire}
                                                        carouselHead={carouselHead}
                                                        filesData={filesData}
                                                        folderType={folderType}
                                                        FILEEDIT={FILEEDIT}

                                                        />)
                                                })
                                            }
                                        </Fragment>
                                    ) : (
                                        <div style={{ fontSize: 12, width: 200, position:'relative', zIndex: 1  }}>
                                            <p>No Files Found!</p>
                                        </div>
                                    )
                                }
                                {
                                    FILEADD!==null && navigator?.length!==0 &&
                                    <FilesDragAndDrop
                                        areaCls="fileFolderCNPArea"
                                        onUpload={(val) => {
                                            console.log('FILESDragNDrop=>',val)
                                            file_upload(val)
                                            
                                        }}
                                    />
                                }
                            </div>
                            
                            : (navigator && navigator.length===0)  ? null :
                            <div className="intranet-files-layout">

                                {
                                    filesData.length > 0 ? (
                                        <Fragment>
                                            {
                                                filesData.map((item, key) => {
                                                    return (<SingleFile 
                                                        setFileSelect={setFileSelect}
                                                        fileSelect={fileSelect}
                                                        setDelFiles={setDelFiles}
                                                        delFiles={delFiles}
                                                        FILEDELETE={FILEDELETE}
                                                        CALLBACKFUNCMODAL={CALLBACKFUNCMODAL}
                                                        deleteSingleFile={deleteSingleFile}
                                                        setFileArr={setFileArr}
                                                        setCarouselOpen={setCarouselOpen}
                                                        setRenameEntity={setRenameEntity}
                                                        setModalType={setModalType}
                                                        setFolderName={setFolderName}
                                                        setFolderId={setFolderId}
                                                        setFolderModal={setFolderModal}
                                                        setDeleteExpire={setDeleteExpire}
                                                        carouselHead={carouselHead}
                                                        filesData={filesData}
                                                        folderType={folderType}
                                                        FILEEDIT={FILEEDIT}
                                                        
                                                        folderId={ navigator.length===0 ? "1" : ParentFolderId} item={item} key={key} />)
                                                })
                                            }
                                        </Fragment>
                                    ) : (
                                        <div style={{ fontSize: 12, width: 200, position:'relative', zIndex: 1 }}>
                                            <p>No Files Found!</p>
                                        </div>
                                    )
                                }
                                {
                                    FILEADD!==null && navigator?.length!==0 &&
                                    <FilesDragAndDrop
                                    areaCls="fileFolderCNPArea"
                                        onUpload={(val) => {
                                            console.log('FILESDragNDrop=>',val)
                                            file_upload(val)
                                            
                                        }}
                                    />
                                }
                            </div>
                            }
                        {/* </Scrollbar> */}
                    </div>
                ) : (
                    <div 
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 200 }}>
                            <CircularProgress />
                    </div>
                )
            }
        
            <AddEditFileFolder 
                modalStatus={folderModal}
                modalAction={setFolderModal}
                newFolder={modalType}
                folderName={folderName}
                folderNameAction={setFolderName}
                createAction={createFolderApi}
                updateAction={updateFolderApiLatest}
                folderId={zFolderId}
                entity={renameEntity}
                loadingFolder={loadingFolder}
                setLoadingFolder={setLoadingFolder}
            />
            <FileCarousel
                modalStatus={carouselOpen}
                modalAction={setCarouselOpen}
                fileArr={fileArr.arr}
                folderName={fileArr.name}
            />

            <ExpireFileModal
                modalStatus={deleteExpire.fileModal}
                modalAction={setDeleteExpire}
                folderId={deleteExpire.folderId}
                item={deleteExpire.item}
                loadingFolder={loadingFolder}
                setLoadingFolder={setLoadingFolder}
                expiryAction={set_expiry}
            />
        </div>
    )
}

export default IntranetLayout