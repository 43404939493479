import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import NotificationsList from '../../ohs/components/notifications_list'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  

function NotificationModal({ modalStatus, modalAction, fetchData, openArch }) {

    const classes = useStyles();
    const handleClose = (v) => {
        fetchData()
        modalAction(v)
    }

    return (
        <Modal
            className={classes.modal}
            open={modalStatus||openArch}
            onClose={() => handleClose(false)}
        >

            <div style={{ outline: 'none', padding: 30, width: '30%',
             backgroundColor: '#fff', overflowY:'auto',
             borderRadius: 10 }}>
                <NotificationsList funCallBk={handleClose} openArch={openArch} />
            </div>
            
        </Modal>
    )
}

export default NotificationModal
