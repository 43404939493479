import React from 'react'

function Chart() {
    return (
        <div className="client-chart-mainlayout">
          <div className="chart-main-layout-top">
              <div className='chart-starting-btn-layout'>
                    <div className='chart-starting-btn'>
                       starting : june 1
                    </div>
                    <div className='chart-ending-btn'>
                       Ending  : june10
                    </div>
              </div>
              <div className='chart-sales-in-layout'>                 
                   <p> sales in 2020</p>               
              </div>
          </div>
          <div className="chart-main-layout-bottom">
               <div className='Sales-management-graph-yaxis'>
                    <div className='Sales-management-graph-yaxis-top'>
                        <div className='Sales-management-graph-yaxis-top-l'>100,000</div>
                        <div className='Sales-management-graph-yaxis-top-r'></div>
                    </div>
                    <div className='Sales-management-graph-sales-layout'>
                        <div className='Sales-management-graph-sales'> Sales </div>
                    </div>
                </div>


                <div className='Sales-management-graph-xaxis'>
                    <div className='Sales-management-graph-xaxis-top'>
                        <div className='Sales-management-line'></div>
                    </div>
                    <div className='Sales-management-graph-xaxis-center'>
                        <div className='Sales-management-graph-month' style={{height:"40%"}}> <p className='sales-management-rotate'>12000</p> </div>
                        <div className='Sales-management-graph-month' style={{height:"30%"}}><p className='sales-management-rotate'>2000</p></div>
                        <div className='Sales-management-graph-month' style={{height:"100%"}}><p className='sales-management-rotate'>100000</p> </div>
                        <div className='Sales-management-graph-month' style={{height:"56%"}}> <p className='sales-management-rotate'>12000</p></div>
                        <div className='Sales-management-graph-month' style={{height:"60%"}}><p className='sales-management-rotate'>12000</p> </div>
                        <div className='Sales-management-graph-month' style={{height:"50%"}}><p className='sales-management-rotate'>12000</p> </div>
                        <div className='Sales-management-graph-month' style={{height:"2%"}}> </div>
                        <div className='Sales-management-graph-month' style={{height:"2%"}}> </div>
                        <div className='Sales-management-graph-month' style={{height:"2%"}}> </div>
                        <div className='Sales-management-graph-month' style={{height:"2%"}}> </div>
                        <div className='Sales-management-graph-month' style={{height:"2%"}}> </div>
                        <div className='Sales-management-graph-month' style={{height:"2%"}}> </div>
                    </div>
                    <div className='Sales-management-graph-xaxis-bottom'>
                        <div className='Sales-management-graph-month-name-client'> JAN </div>
                        <div className='Sales-management-graph-month-name-client'> FEB</div>                                                                      
                        <div className='Sales-management-graph-month-name-client'> MAR</div>                                                             
                        <div className='Sales-management-graph-month-name-client'> APR</div>                                
                        <div className='Sales-management-graph-month-name-client'> MAY</div>                                                                 
                        <div className='Sales-management-graph-month-name-client'> JUN</div>                                                                    
                        <div className='Sales-management-graph-month-name-client'> JUL</div>       
                        <div className='Sales-management-graph-month-name-client'> AUG</div>                   
                        <div className='Sales-management-graph-month-name-client'> SEP</div>                                                                 
                        <div className='Sales-management-graph-month-name-client'> OCT </div>                                 
                        <div className='Sales-management-graph-month-name-client'> NOV </div>                                                      
                        <div className='Sales-management-graph-month-name-client'> DEC </div>                 
                    </div>
                </div>
                   
          </div>                     
    </div>
    )
}

export default Chart
