import React from 'react'
import Navbar from './navbar'
// import FolderApp from '../../../common/folder/folder_main'
import SalesExcecutieves from './sales_excecutieves'
// import SalesHomeList from '../component/sales_home_list'
// import { fetch_sales_folder_market } from '../common/action'
// import NoDatasScreen from '../../../common/no_datas'
// import { CircularProgress } from '@material-ui/core'
import Scrollbars from '../../../../common/ImprovedScrollBar'
import IntranetLayout from '../../../../common/filesNFolder/FileFoldersLayout'
import { INTRANET_FILES_RENAME, INTRANET_FOLDER_EDIT, OHS_AND_S_FILE_ADD, OHS_AND_S_FILE_DELETE, OHS_AND_S_FOLDER_DELETE, SALES_AND_INTRANET_FOLDER_CREATE, SALES_FILES_FOLDERS } from '../../../../store/endpoint'
import './sales_tableStyle.css';

function Tenders() {
    // const [folderData, setFolderData] = useState([])
    // const [loaderState, setLoaderState] = useState(true)
    // useEffect(() => {
    //     apiCall()
    // }, [])

    // const apiCall = () => {
    //     const success = (response) => {
    //         if(response.folders) {
    //             setFolderData(response.folders)
    //             setLoaderState(false)
    //         }
    //     }

    //     const failed = () => {
    //         setFolderData([])
    //         setLoaderState(false)
    //     }

    //     fetch_sales_folder_market('tender', success, failed)
    // }
    return (
        <div>
            <Navbar />
            <div className="sales-main-layout">
                {
                    // loaderState ?
                    // <div style={{ display: 'flex', height:'100%', width:'100%', justifyContent: 'center', alignItems: 'center' }} >
                    //     <CircularProgress />
                    // </div>
                    // :
                    <Scrollbars  style={{ height:'50vh' }} autoHide>
                        <IntranetLayout
                            APIURL={SALES_FILES_FOLDERS+'tender/'}
                            title={'TENDER'}
                            FILEEDIT={INTRANET_FILES_RENAME}
                    FOLDEREDIT={INTRANET_FOLDER_EDIT} 
                    FILEADD={OHS_AND_S_FILE_ADD} 
                    FOLDERCREATE={SALES_AND_INTRANET_FOLDER_CREATE+'tender/create/'} 
                    FOLDERDELETE={OHS_AND_S_FOLDER_DELETE} 
                    FILEDELETE={OHS_AND_S_FILE_DELETE}
                        />
                    </Scrollbars>
                    // :
                    // folderData.length > 0 ? (
                    //     <SalesHomeList>
                            
                    //         <FolderApp  
                    //             data={folderData}
                    //             updation={false}
                    //             updationFunction={() => console.log('upload')}
                    //             deletion={() => console.log('upload')}
                    //             folder_status={'normal'}
                    //     />
                    //     </SalesHomeList>
                    // ) : (
                    //     <NoDatasScreen />
                    // )
                }
                <SalesExcecutieves />
            </div>
        </div>
    )
}

export default Tenders
