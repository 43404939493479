import React from 'react'
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import JoditEditor from 'jodit-react';
import Scrollview from '../../../common/ImprovedScrollBar';
import { CircularProgress } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    Tempname,
    modalStatus, 
    modalAction,
    editorRef,
    editorAction,
    sale_client_draft,
    set_sale_draft_content, templateLoader
}) {

    const classes = useStyles();

    const handleClose = () => {
        modalAction(false)
    };

    const config = {
        readonly: false,
    }
    
    const addAttachment = () =>{
            editorAction()
        modalAction(false)
    }
        
  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
            <div className="cer-cerificate-modal-layout" style={{ overflowY:'auto' }}>
                <div className="cer-modal-header-layout">
                    <p style={{ fontSize: 16 }} className="cer-modal-main-title">{Tempname}</p>
                </div>
                <div className="cer-modal-body-layout">
                    {/* {console.log("FROM REDUCER ",sale_client_draft)} */}
                    <div className="sales-qt-client-info" style={{height:'50vh'}}>
                        {
                            templateLoader ?
                            <div style={{ display: 'flex', height:'100%', width:'100%', position:'absolute', justifyContent: 'center', alignItems: 'center' }} >
                                <CircularProgress />
                            </div>
                            :
                        
                        <Scrollview style={{ height: '45vh'}}>
                            <JoditEditor
                                ref={editorRef}
                                value={sale_client_draft}
                                config={config}
                                tabIndex={1}
                                onBlur={ () => { 
                                    set_sale_draft_content(editorRef.current.value)
                                }}
                            />
                            </Scrollview>
                        }
                    </div>
                    <div style={{ display:'flex', flexDirection:'row', fontSize:'16px', alignItems:'center', justifyContent:'space-around' }}>
                        <div className="sales-qt-rgstr-editor-sts-btn sales-qt-rgstr-editor-sts-btn-active" 
                        style={{ width:'10vw', display:'flex', alignItems:'center', justifyContent:'center' }}
                            onClick={()=> addAttachment() }
                        >
                            Add to attachment
                        </div>
                        
                    </div>
                    
                </div>
               
            </div>
      </Modal>
  );
}

const mapStateToProps = (state) =>{
    const { sale_client_draft, templateLoader } = state.sales
    return{
        sale_client_draft, templateLoader
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        set_sale_draft_content: (content) => dispatch({ type: 'SINGLE_CLIENT_DRAFT', clientDraft: content })
      }
}

 export default connect( mapStateToProps, mapDispatchToProps) (TransitionsModal)
 
