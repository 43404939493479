import React, {useEffect, useState} from 'react';
import Scrollbars from '../../../common/ImprovedScrollBar';
import './style.css'
import DateTimeInput from '../../../common/DateTimeInput'
import {connect} from 'react-redux'
import { addNewJobCard, editNewJobCard, editNewJobCardName, listJobCards } from '../action';
import Swal from 'sweetalert2'
import { AddCircleOutline, CheckCircleOutlineRounded, EditOutlined, RemoveCircleOutline } from '@material-ui/icons';
import { CLIENT_SINGLE_FETCH, NEWJOBCARD_DELETE, SALES_ALL_CLIENTS_LIST, SITE_TYPE_OF_WASTE_LIST } from '../../../store/endpoint';
import Api from '../../../store/api'
import { HOME_LATITUDE, HOME_LONGITUDE, LETTERS_VALIDATION } from '../../../store/config';
import { cl_green, cl_red } from '../../../common/Colors';
import useAlljobcard from './useAllJobCard';
import { GoogleMapInit, SearchGoogleAddress } from '../../client/component/editStack/GoogleMap/GoogleMap';
import { TextAreaLimiter } from '../../../common/TextAreaLimiter';

function NewJobCard({  setJobCardModal, jobObj, modalType, tabType=null, clientList, addJob, editJob, verifyJobCard=null, user, preSelect=null, setJobCardLoader=null, editJobCardName,
     fetch_jobCards, typeOfWaste=null, typeOfWaste_Id=null, fallBackFn=null, addNVerify=false, setAddNVerify=null }) {

        useEffect(()=>{
            Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                res =>{
                    console.log(res)
                    setAllClientsLoader(false)
                    if(res.length>0)
                        setAllClientsList(res)
                    else 
                        setAllClientsList([])
                }
                    
            )
        },[tabType])
        let mapInit = GoogleMapInit()
            
        
    const [jobObjCont, setJobObjCont] = useAlljobcard({ jobObj, preSelect, modalType })
    const [clientID, setClientID]           = useState(jobObj.client  )
    const [editOpt, setEditOpt] = useState(false)
    const [textAreaValid, setTextAreaValid] = useState({a:true,b:true})
    const [allClientsList, setAllClientsList] = useState([])
    const [allTOWList, setAllTOWList] = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)
    const [allTOWListLoader, setAllTOWListLoader] = useState(false)
    const [TypeofWaste, setTypeofWaste] = useState( modalType==='add' ? typeOfWaste===null ? '' : typeOfWaste : jobObj.type_of_waste_str)
    const [typeOfWasteId, setTypeOfWasteId] = useState(modalType==='add' ? typeOfWaste_Id===null ? '' : typeOfWaste_Id : jobObj.type_of_waste_str_id)

    const [frqReoccur, setFrqReoccur]       = useState(modalType!=='add' ? {
        frq :   jobObj.frequency ? jobObj.frequency : 0,
        reoccur :  jobObj.reoccurring ? jobObj.reoccurring : false
    }   :
    {    frq :   0,
        reoccur :  false
    });


    const [serviceDet, setServiceDet]       = useState( modalType!=='add' ?
        jobObj.service_list
        :   [{no : 1, waste_type : '', capacity : '', frequency : '', pit_location : ''}, 
            {no : 2, waste_type : '', capacity : '', frequency : '', pit_location : ''}, 
            {no : 3, waste_type : '', capacity : '', frequency : '', pit_location : ''}])
    // const [tempInput, setTempInput] = useState('')
    
    const [access, setAccess]               = useState(modalType!=='add' ? jobObj.access_restriction : preSelect===null ?  '' : 
        preSelect.access_restriction)
    const [tcReq, setTcreq]                 = useState(modalType!=='add' ? jobObj.tc_required : false)
    const [dataForm, setDataForm]           = useState(modalType!=='add' ? jobObj.waste_data_form : false)
    const [accessHeight, setAccessHeight]   = useState(modalType!=='add' ? jobObj.access_height : '')
    
    // const [keyReq, setKeyReq]               = useState(modalType!=='add' ? jobObj.key_required : preSelect===null ? false : 
    //     preSelect.key_required_type_str==='FALSE' ? false : true)
    const [keyReqNew, setKeyReqNew]               = useState(modalType!=='add' ? jobObj.key_required_new : preSelect===null ? '' : 
        preSelect.key_required_new)
    const [secReq, setSecReq]               = useState(modalType!=='add' ? jobObj.security_required : false)
    const [indReq, setIndReq]               = useState(modalType!=='add' ? jobObj.induction_required : preSelect===null ? false :
        preSelect.induction_required_str==='FALSE' ? false : true)
    const [contName, setContName]           = useState(modalType!=='add' ? jobObj.contact_name : '')
    const [contPh, setContPh]               = useState(modalType!=='add' ? jobObj.phone_number : '')
    const [pitLoc, setPitLoc]               = useState(modalType!=='add' ? jobObj.pit_distacnce_from_truck_location : '')
    const [typeOfInd, setTypeOfInd]         = useState(modalType!=='add' ? jobObj.type_of_induction : preSelect===null ? '' :
        preSelect.induction_type)
    const [waterTap, setWaterTap]           = useState(modalType!=='add' ? jobObj.water_tap_location : '')
    const [gumReq, setGumReq]               = useState(modalType!=='add' ? jobObj.gumy_required : false)
    const [confSpace, setConfSpace]         = useState(modalType!=='add' ? jobObj.confined_space : false)
    const [noTruck, setNoTruck]             = useState(modalType!=='add' ? jobObj.number_of_trucks_required : '')
    const [servTime, setServTime]           = useState(modalType!=='add' ? jobObj.service_time : '')
    const [spcPPEReq, setSpcPPEReq]         = useState(modalType!=='add' ? jobObj.specific_ppe_reqired : false)
    const [specify, setSpecify]             = useState(modalType!=='add' ? jobObj.if_yes_specify : '')
    const [compl, setCompl]                 = useState(modalType!=='add' ? jobObj.completed_by : user.name)
    const [jobDate, setJobDate]             = useState(modalType!=='add' ? jobObj.date : new Date())
    const [comments, setComments]           = useState(modalType!=='add' ? jobObj.comment : '')
    const [tccomments, setTCComments]           = useState(modalType!=='add' ? jobObj.tc_required_comment : '')
    const [smokeAlarm, setSmokeAlarm]       = useState(modalType!=='add' ? jobObj.smoke_alarms : '')
    const [markers, setMarkers]             = useState(jobObj ? 
    [{ lat: jobObj.latitude ? parseFloat(jobObj.latitude) : null,    
        lng: jobObj.longitude ? parseFloat(jobObj.longitude) : null }] : 
    [{  lat: HOME_LATITUDE, lng: HOME_LONGITUDE }])



    // const [jobCardName, setJobCardName]     = useState(jobObj.job_card_name)

    // const [compName, setCompName]           = useState(jobObj.company_name)
    // const [compAddr, setCompAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.company_address : jobObj.company_address  )
    // const [suburb, setSuburb]               = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.site_suburb : jobObj.site_suburb   )
    // const [postal, setPostal]               = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.site_post_code : jobObj.site_post_code   )
    // const [siteAddr, setSiteAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.site_address : jobObj.site_address   )
    // const [buildName, setBuildName]         = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.building : jobObj.building   )
    // const [contactName, setContactName]     = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.site_contact_person : jobObj.site_contact_person )
    // const [contactNo, setContactNo]         = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.company_contact_number : jobObj.company_contact_number )
    // const [contactEmail, setContactEmail]   = useState(modalType==='add' ? preSelect===null ? '' :
    //     preSelect.company_email : jobObj.company_email)

    // const [payment, setPayment]             = useState(modalType!=='add' ? jobObj.payment_details : preSelect===null ? 'cod' : 
    // preSelect.payment_type_str==='CREDIT CARD' ? 'credit_card' : preSelect.payment_type_str==='ACCOUNT' ? 'account' : 'cod')
    

    console.log("PREselect",preSelect, "JOBOBJ=>",jobObj)
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    
    const addJobCard = () =>{

        let dateStr = new Date(jobDate)
        
        let postData = {
        'tab_type'              :   tabType,
        "client"                :   clientID,
        "service_list"          :   serviceDet,
        "payment_details"       :   jobObjCont.payment,

        "company_name"          :   jobObjCont.compName,
        "company_address"       :   jobObjCont.compAddr,
        "site_suburb"           :   jobObjCont.suburb,
        "site_post_code"        :   jobObjCont.postal,
        "site_address"          :   jobObjCont.siteAddr,
        "building"              :   jobObjCont.buildName,
        "company_email"         :   jobObjCont.contactEmail,
        "company_contact_number":   jobObjCont.contactNo,
        "site_contact_person"   :   jobObjCont.contactName,
        "access_restriction"    :   access,

        "tc_required"           :   tcReq,
        "waste_data_form"       :   dataForm,
        "access_height"         :   accessHeight,
        // "key_required"          :   keyReq,
        "key_required_new"      :   keyReqNew,
        "security_required"     :   secReq,
        "induction_required"    :   indReq,
        "contact_name"          :   contName,
        "phone_number"          :   contPh,
        "type_of_induction"     :   typeOfInd,
        "pit_distacnce_from_truck_location" :   pitLoc,
        "water_tap_location"    :   waterTap,
        "gumy_required"         :   gumReq,
        "confined_space"        :   confSpace,
        "number_of_trucks_required" :   noTruck,
        "specific_ppe_reqired"  :   spcPPEReq,
        "service_time"          :   servTime,
        "if_yes_specify"        :   specify,
        "completed_by"          :   compl,
        "date"                  :   dateStr.toISOString(),
        "tc_required_comment"   :   tccomments,
        "comment"               :   comments,
        "type_of_waste_str"     :   tabType==='waste' ? typeOfWaste : null,
        "type_of_waste_str_id"  :   tabType==='waste' ? typeOfWasteId : null,
        "smoke_alarms"          :   smokeAlarm,
        "frequency"             :   frqReoccur.frq,
        "reoccurring"           :   frqReoccur.reoccur,
        "latitude"              :   markers[0].lat,
        "longitude"             :   markers[0].lng
        }
        // console.log(postData)
        if(modalType==='add'){
            const success = (res) =>{
                if(addNVerify){
                    verifyJobCard(res.id,{reoccurring : frqReoccur.reoccur, frequency : frqReoccur.frq},res.job_card_code)
                }
                setClientID(null)   
                // setCompName('')   
                // setCompAddr('')   
                // setSuburb('')     
                // setPostal('')     
                // setSiteAddr('')   
                // setBuildName('')  
                // setContactName('')
                // setContactNo('')  
                // setContactEmail('')
                // setPayment('')
                setJobObjCont({
        jobCardName : '',
        compName    : '',
        compAddr    : '',
        suburb      : '',
        postal      : '' ,
        siteAddr    : '' ,
        buildName   : '' ,
        contactName : '' ,
        contactNo   : '' ,
        contactEmail : '' ,
        payment     : 'cod'

                })    
                setAccess('')      
                setTcreq('')       
                setDataForm('')    
                setAccessHeight('')
                // setKeyReq('')      
                setKeyReqNew('')      
                setSecReq('')      
                setIndReq('')      
                setContName('')    
                setContPh('')      
                setPitLoc('')      
                setTypeOfInd('')   
                setWaterTap('')    
                setGumReq('')      
                setConfSpace('')   
                setNoTruck('')     
                setServTime('')    
                setSpcPPEReq('')   
                setSpecify('')     
                setCompl('')     
                setComments('')
                setTCComments('')  
                setJobDate('')    
                setTypeofWaste('') 
                setSmokeAlarm('')
                setTypeOfWasteId(null)
                if(setJobCardLoader!==null){
                    setJobCardLoader(false)
                    if(fallBackFn!==null){
                        fallBackFn()
                    }
                }
                setJobCardModal(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title : 'Job Card Created.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            const failed = () => {
                setJobCardModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            if(setJobCardLoader!==null){
                setJobCardLoader(true)
            }
            addJob(postData, success, failed)
        }
        else{
            console.log('EDITFUNC')
            const success = () =>{
                setClientID(null)   
                // setCompName('')   
                // setCompAddr('')   
                // setSuburb('')     
                // setPostal('')     
                // setSiteAddr('')   
                // setBuildName('')  
                // setContactName('')
                // setContactNo('')  
                // setContactEmail('')
                // setPayment('')    
                setJobObjCont({
                    jobCardName : '',
                    compName    : '',
                    compAddr    : '',
                    suburb      : '',
                    postal      : '' ,
                    siteAddr    : '' ,
                    buildName   : '' ,
                    contactName : '' ,
                    contactNo   : '' ,
                    contactEmail : '' ,
                    payment     : 'cod'
            
                            })    
                setAccess('')      
                setTcreq('')       
                setDataForm('')    
                setAccessHeight('')
                // setKeyReq('')      
                setKeyReqNew('')      
                setSecReq('')      
                setIndReq('')      
                setContName('')    
                setContPh('')      
                setPitLoc('')      
                setTypeOfInd('')   
                setWaterTap('')    
                setGumReq('')      
                setConfSpace('')   
                setNoTruck('')     
                setServTime('')    
                setSpcPPEReq('')   
                setSpecify('')     
                setCompl('')       
                setJobDate('')     
                setSmokeAlarm('')

                setComments('')
                setTCComments('')
                setJobCardModal(false)
                setTypeofWaste('')
                setTypeOfWasteId(null)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title : `Job Card Updated${verifyJobCard!==null ? ' and Verified' : ''}.`,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            const failed = () => {
                setJobCardModal(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            if(verifyJobCard!==null){
                verifyJobCard(jobObj.id,{reoccurring : frqReoccur.reoccur, frequency : frqReoccur.frq},jobObj.job_card_code)
            }
            editJob(postData, jobObj.id, success, failed)
        }

    }

    const jobCardRename = () =>{
        let data = new FormData();
        data.append('job_card_name', jobObjCont.jobCardName)
        const success = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : `Job Card Name Updated.`,
                showConfirmButton: false,
                timer: 1500
            })
            setEditOpt(false)
        }
        const failed = () => {
            setJobCardModal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        
        

        editJobCardName(data, jobObj.id, success, failed)
    }

    const addRem = (val) =>{
        if(val){
            setServiceDet(data => [...data, {
                no              :   serviceDet.length+1,
                waste_type      :   '',
                capacity        :   '',
                frequency       :   '',
                pit_location    :   ''
            }])
        }
        else{
            let newArr = serviceDet.filter((_,key) => key!==(serviceDet.length-1))
            setServiceDet(newArr)
        }
    }

    const delJobCard = (jobId) =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Job Card deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            setJobCardModal(false)
            fetch_jobCards()
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = `${NEWJOBCARD_DELETE}${jobId}/`
                Api('delete', api_url, '', success, failed)
            }
        })
    
    }


    return (
        <div className="jobCardLayout">
            { modalType!=='add' ? <h2 style={{ marginLeft:'1%' }}>Job Card</h2> : null}
            <div className="header-button-layout" style={{ justifyContent:'space-between'}}>
                {   (modalType==='add' || modalType==='view') || !editOpt ?
                <span className="jobCardNameDiv" >
                    <span style={{ margin: '10px', fontWeight:'500', fontSize:'1.3rem' }}>{jobObjCont.jobCardName==='' ? 'Job Card' : jobObjCont.jobCardName}</span>
                    {
                        !(modalType==='add' || modalType==='view') ?
                        <EditOutlined className="editPencilIcon"  onClick={()=> setEditOpt(true) } />
                    :
                        null
                    }
                </span>
                :
                null 
                }
                {
                editOpt ?
                    <span className="jobCardNameDiv" >
                        <input type="text" className="jobCardDivInp"                
                            placeholder="Job Card Name" value={jobObjCont.jobCardName} onChange={(e)=>setJobObjCont(e.target.value)} />    
                        <CheckCircleOutlineRounded className="checkCircIcon" onClick={()=> jobCardRename()} />
                    </span>
                    
                :
                    null
                }
                {
                    modalType==='add' ? null : (jobObj && jobObj.connected) ? null :
                    modalType==='view' ?
                    <div className="header-button-top delBtnTop" style={{ backgroundColor:'red', color:'white' }} onClick={()=>  delJobCard(jobObj.id) }>
                        Delete
                    </div>    
                    : null
                }
                <div className="header-button-top" onClick={()=>  setJobCardModal(false) }>
                    Back
                </div>
                
            </div>
            <div className="jobCardContentDiv">
            
                <Scrollbars style={{ height:'65vh' }}>
            <div className="jobCardDiv1">
                <div className="jobCardDivHead">Customer Details</div>
                <div className="jobCardDivInpContent">
                    
                    <div className="jobCardDivInpContentDiv">
                        <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.compName}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={clientID} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                            onClick={()=>{ setAllClientsLoader(true);
                                Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                                    res =>{
                                        // console.log("DATAAAAA",res)
                                        setAllClientsLoader(false)
                                        if(res.length>0)
                                            setAllClientsList(res)
                                        else 
                                            setAllClientsList([])
                                    }
                                        
                                )
                            }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                
                                    setAllTOWListLoader(true)
                                setClientID(e.target.value);
                                Api('get', `${SITE_TYPE_OF_WASTE_LIST}${e.target.value}/`,'')
                                    .then(res => {
                                        console.log(res)
                                        setAllTOWList(res.filter(el => (el.child_exists && !el.hidden)))
                                        setAllTOWListLoader(false)
                                    })
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                                        setJobObjCont({
                                            ...jobObjCont,
                                            compName : response.company_name==='' || response.company_name===null ? response.client_name : response.company_name,
                                            jobCardName : response.company_name==='' || response.company_name===null ? response.client_name : response.company_name,
                                            compAddr : response.company_address,
                                            suburb : response.site_suburb,
                                            postal : response.site_post_code,
                                            siteAddr : response.site_address,
                                            buildName : response.building,
                                            contactName : response.site_contact_person,
                                            contactNo : response.company_contact_number,
                                            contactEmail : response.company_email
                                        })
                                        setAccess(response.access_restriction)
                                        setMarkers([{ lat: parseFloat(response.location_latitude), lng: parseFloat(response.location_logitude) }])
                                })
                                
                            ; }
                                 }}>
                                     <option value={''}>Select Client</option>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            :
                            <p>{jobObjCont.compName}</p>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    {   tabType==='waste' ?
                    <div className={ modalType==='view' || modalType==='verify' ? 'jobCardDivInpContentDiv' : 'jobCardDivInpContentDivTOW'  }>
                        <div>Type of Waste (Site Profile)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                                allTOWListLoader ?
                                <p>Loading...</p>
                                :
                            <select className="jobCardDivInp" value={typeOfWasteId} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}

                                 onChange={val =>{ 
                                    let currObj =  allTOWList.find(el => el.id === parseInt(val.target.value))
                                    setTypeofWaste(currObj.original_name!==null ? 
                                        `${currObj.waste_type_str}(${currObj.original_name})` : `${currObj.waste_type_str}`);
                                    setTypeOfWasteId(allTOWList.find(el => el.id === parseInt(val.target.value)).id);  }}>
                                     <option value={''}>Select a Types of Waste</option>
                                     {
                                         allTOWList && allTOWList.length!==0 ?
                                            allTOWList.map((el, k) => {
                                                return(
                                                    <option key={k} value={el.id} >
                                                        {el.original_name!==null ? 
                                                        `${el.waste_type_str}(${el.original_name})` : `${el.waste_type_str}`}</option>
                                                )
                                            })
                                            :
                                            <option value={''}>No Available Types of Waste</option>
                                     }
{/*                                  
                                    <option value='' >Select Option</option>
                                    <option value='AMMONIA'>AMMONIA</option>
                                    <option value='DILUTION PIT'>DILUTION PIT</option>
                                    <option value='DRUMS'>DRUMS</option>
                                    <option value='FOOD WASTE'>FOOD WASTE</option>
                                    <option value='GERNIE WASHES'>GERNIE WASHES</option>
                                    <option value='GREASE'>GREASE</option>
                                    <option value='GROUND WATER'>GROUND WATER</option>
                                    <option value='J120'>J120</option> 
                                    <option value='LEVY'>LEVY</option>
                                    <option value='M250'>M250</option>
                                    <option value='N205'>N205</option> 
                                    <option value='OIL SEPERATOR'>OIL SEPERATOR</option>
                                    <option value='PACKAGED WASTE'>PACKAGED WASTE</option>
                                    <option value='PRODUCT DESTRUCTION'>PRODUCT DESTRUCTION</option>
                                    <option value='PUMP SERVICE'>PUMP SERVICE</option>
                                    <option value='RETENTION PIT'>RETENTION PIT</option>
                                    <option value='SEWER'>SEWER</option>
                                    <option value='STORMWATER'>STORMWATER</option>
                                    <option value='TANK WASH OUT'>TANK WASH OUT</option>      
                                    <option value='TRADE WASTE'>TRADE WASTE</option>                                              
                                    <option value='TRANSPORT'>TRANSPORT</option>                            
                                    <option value='Z140'>Z140</option>
                                    <option value='WIEGH BRIDGE'>WEIGH BRIDGE</option>                                    
                                    <option value='DESTRUCTION CERTIFICATE'>DESTRUCTION CERTIFICATE</option>
                                    <option value='NOT APPLICABLE'>NOT APPLICABLE</option>                                 */}
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    :   null
                    }
                    <div className="jobCardDivInpContentDiv">
                        <div>Company Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.compAddr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Company Address" value={jobObjCont.compAddr} onChange={(e)=>setJobObjCont({ ...jobObjCont, compAddr : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Suburb</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.suburb}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Suburb" value={jobObjCont.suburb} onChange={(e)=>setJobObjCont({ ...jobObjCont, suburb : e.target.value})}/>
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Postal Code</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.postal}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Postal Code" value={jobObjCont.postal} onChange={(e)=>setJobObjCont({ ...jobObjCont, postal : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Site Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.siteAddr}</p>   :
                            !mapInit.status ?
                                <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>Loading Google Map...</p>
                                :
                            <SearchGoogleAddress
                                center={{ lat : HOME_LATITUDE, lng : HOME_LONGITUDE }}
                                inputPlaceHolder={'Site Address'}
                                inputFn={(e)=>setJobObjCont({ ...jobObjCont, siteAddr : e})}
                                moreInfo={(r) => { 
                                                        
                                    let res = r.results && r.results.address_components ? r.results.address_components : null
                                    console.log(res)
                                    if(res){ 
                                         
                                        res.find(o =>{
                                            if( o.types.includes('postal_code'))
                                                setJobObjCont({ ...jobObjCont, postal : o.long_name})
                                            console.log(o,o.types.includes('postal_code'))
                                            return 0
                                        })
                                 } }}
                                inputVal={jobObjCont.siteAddr}
                                setMarkers={setMarkers}
                                cls={{ outerBox : 'jobsearchAddressSite', innerBox : 'jobCardDivInp'}}
                            />
                            
                            // <input type="text" className="jobCardDivInp" placeholder="Site Address" value={jobObjCont.siteAddr} onChange={(e)=>setJobObjCont({ ...jobObjCont, siteAddr : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Building Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.buildName}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Building Name" value={jobObjCont.buildName} onChange={(e)=>setJobObjCont({ ...jobObjCont, buildName : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Contact Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.contactName}</p>    :
                            <input type="text" className="jobCardDivInp" placeholder="Contact Name" value={jobObjCont.contactName} onChange={(e)=>setJobObjCont({ ...jobObjCont, contactName : e.target.value})} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Contact Number</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.contactNo}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Contact Number" value={jobObjCont.contactNo}
                             onChange={(e)=>{
                                if(e.target.value==='')
                                setJobObjCont({ ...jobObjCont, contactNo : ''})
                            else if(!LETTERS_VALIDATION.test(e.target.value))
                                setJobObjCont({ ...jobObjCont, contactNo : e.target.value}) 
                                }} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Contact Email Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.contactEmail}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Contact Email Address" value={jobObjCont.contactEmail}
                             onChange={(e)=>{ setJobObjCont({ ...jobObjCont, contactEmail : e.target.value})  }} />
                            }
                        </div>
                    </div>
                    {   modalType==='view'   ?
                    <div className="jobCardDivInpContentDiv">
                        <div>Payment Details</div>
                        <div>
                            <span>:</span>
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobObjCont.payment}</p>   
                        </div>
                    </div>
                    :   null
                    }
                </div>
            </div>
            
                    {   jobObj && jobObj.connected ? null :
            <div className="jobCardDivInpContentDiv">
            <div>Is this a Reoccurring Quote</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ frqReoccur.reoccur } onChange={()=>setFrqReoccur({ ...frqReoccur, reoccur : true})}/><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !frqReoccur.reoccur } onChange={()=>setFrqReoccur({ ...frqReoccur, reoccur : false})}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{frqReoccur.reoccur ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    }
                    {   jobObj && jobObj.connected ?    null :
                    <div className={`jobCardDivInpContentDiv ${ !frqReoccur.reoccur ? 'jobCardFieldDisabled' : ''}`}>
                        <div>Frequency in Weeks</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{frqReoccur.frq}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Frquency in Weeks" value={frqReoccur.frq} onChange={(e)=>{
                                if(e.target.value===''|| e.target.value===0 || e.target.value==='0')
                                    setFrqReoccur({ ...frqReoccur, frq : ''})
                                else if(!LETTERS_VALIDATION.test(e.target.value))
                                    setFrqReoccur({ ...frqReoccur, frq : e.target.value})}} />
                            }
                        </div>
                    </div>
                    }
                    {
                        jobObj && jobObj.connected ?
                    <div className={`jobCardDivInpContentDiv jobCardFieldDisabled`}>
                        <div>
                            This is {frqReoccur.reoccur ? '' : <strong>&nbsp;&nbsp;NOT&nbsp;&nbsp;</strong>} a reoccurring Quote{frqReoccur.reoccur ? `, with a frequency of ${frqReoccur.frq} weeks.` : '.'}
                        </div>
                    </div>
                    : null
                    }
            {   modalType!=='view' ?
            <div className="jobCardDiv2">
                <div className="jobCardDivHead">Payment Details</div>
                <div className="jobCardDivInpContent" style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <div><input type="radio" className="radioBtn" value={'cod'}  checked={jobObjCont.payment==="cod"} disabled={modalType==='view' } onChange={(e)=>setJobObjCont({ ...jobObjCont, payment : "cod"})} /><span style={{ marginLeft:'10px' }}>COD</span></div>
                    <div><input type="radio" className="radioBtn" value={'account'}  checked={jobObjCont.payment==="account"} disabled={modalType==='view' } onChange={(e)=>setJobObjCont({ ...jobObjCont, payment : "acco}unt"})} /><span style={{ marginLeft:'10px' }}>Account</span></div>
                    <div><input type="radio" className="radioBtn" value={'credit_card'} checked={jobObjCont.payment==="credit_card"} disabled={modalType==='view' } onChange={(e)=>setJobObjCont({ ...jobObjCont, payment : "cred}it_card"})} /><span style={{ marginLeft:'10px' }}>Credit Card</span></div>
                </div>
            </div>
            :   null
            }
            <div className="jobCardDiv3">
                <div className="jobCardDivHead">Service Details</div>
                <div className="jobCardDivTableContent">
                    <div className="jobCardDivTHead">
                        <div style={{ width:'20px' }}>Sl.No</div>
                        <div>Type</div>
                        <div>Capactiy</div>
                        <div>Frequency</div>
                        <div>Pit Location</div>
                    </div>
                    {   serviceDet.map((item, key) => { 
                    return(
                    <div className="jobCardDivTHead">
                        <div style={{ width:'20px' }}>{key+1}</div>
                        <div>
                            <input type="text"  className="jobCardDivInp" placeholder="Type"  disabled={modalType==='view' }
                             value={item.waste_type} onChange={(e)=>{
                                //  setTempInput(e.target.value) 
                                let temp = {...item, waste_type : e.target.value}; 
                                let tempArr = serviceDet
                                tempArr.splice(key,1, Object.assign({}, temp))
                                
                                setServiceDet(Object.assign([],tempArr)) }} />
                        </div>
                        <div>
                            <input type="text" className="jobCardDivInp" placeholder="Capacity"  disabled={modalType==='view' }
                             value={item.capacity} onChange={(e)=>{
                                //  setTempInput(e.target.value) 
                                let temp = {...item, capacity : e.target.value}; 
                                let tempArr = serviceDet
                                tempArr.splice(key,1, Object.assign({}, temp))
                                setServiceDet(Object.assign([],tempArr)) }} />
                        </div>
                        <div>
                            <input type="text"  className="jobCardDivInp" placeholder="Frequency"  disabled={modalType==='view' }
                             value={item.frequency} onChange={(e)=>{
                                //  setTempInput(e.target.value) 
                                let temp = {...item, frequency : e.target.value}; 
                                let tempArr = serviceDet
                                tempArr.splice(key,1, Object.assign({}, temp))
                                setServiceDet(Object.assign([],tempArr)) }} />
                        </div>
                        <div>
                            <input type="text" className="jobCardDivInp" placeholder="Pit Location"  disabled={modalType==='view' }
                             value={item.pit_location} onChange={(e)=>{
                                //  setTempInput(e.target.value) 
                                let temp = {...item, pit_location : e.target.value}; 
                                let tempArr = serviceDet
                                tempArr.splice(key,1, Object.assign({}, temp))
                                setServiceDet(Object.assign([],tempArr)) }} />
                        </div>
                    </div>)
                    })
                    }
                    {   modalType==='view'  ?   null    :
                    <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'flex-end', alignItems:'center', margin:'20px 0' }}>
                        <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(true)} ><AddCircleOutline style={{ fontSize:'16px' }}/> Add Row</div>
                        {   serviceDet.length>3 ?
                        <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(false)}><RemoveCircleOutline style={{ fontSize:'16px' }}/> Remove Row</div>  :   null
                        }
                    </div>
                    }
                </div>
            </div>
            <div className="jobCardDiv4">
                <div className="jobCardDivHead">Additional Information</div>
                <div className="jobCardDivInpContent">
                    <div className="jobCardDivInpContentDiv">
                        <div>Access & Restriction</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{access}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Access & Restriction" value={access} onChange={(e)=>setAccess(e.target.value)}/>
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>TC Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ tcReq } onChange={()=>setTcreq(true)}/><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !tcReq } onChange={()=>setTcreq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{tcReq ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }} >
                        <div style={{ marginTop:'1vh' }}>TC Required | Comments</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            <TextAreaLimiter 
                            isValid={(e) => setTextAreaValid({...textAreaValid, a : e })}
                            configs={{
                                className : "jobCardDivInpTextArea", disabled : modalType==='view', placeholder : "Enter TC Required Comment here", 
                                value : tccomments, onChange : (e)=>setTCComments(e.target.value) 
                            }}
                            v={tccomments} />
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Data Form</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ dataForm } onChange={()=>setDataForm(true)}/><span>YES</span>
                            <input type="checkbox"  disabled={modalType==='view' } checked={ !dataForm } onChange={()=>setDataForm(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{dataForm ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Access Height</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{accessHeight}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Access Height" value={accessHeight} onChange={(e)=>setAccessHeight(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Key Required</div>
                        {/* {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={keyReq} onChange={()=>setKeyReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!keyReq} onChange={()=>setKeyReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{ keyReq?'YES':'NO'}</p>
                        </div>
                        } */}
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{keyReqNew}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Key Required" value={keyReqNew} onChange={(e)=>setKeyReqNew(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Security Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={secReq} onChange={()=>setSecReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!secReq} onChange={()=>setSecReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{secReq ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Induction Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={indReq} onChange={()=>setIndReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!indReq} onChange={()=>setIndReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{indReq ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Contact Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{contName}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Contact Name" value={contName} onChange={(e)=>setContName(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Phone Number</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{contPh}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Phone Number" value={contPh} onChange={(e)=>setContPh(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Type Of Induction</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{typeOfInd}</p>  :
                            <input type="text" className="jobCardDivInp" placeholder="Type Of Induction" value={typeOfInd} onChange={(e)=>setTypeOfInd(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Pitt Distance from Truck Location</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pitLoc}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Pitt Distance from Truck Location" value={pitLoc} onChange={(e)=>setPitLoc(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Water tap Location</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{waterTap}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Water tap Location" value={waterTap} onChange={(e)=>setWaterTap(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Gernie Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={gumReq} onChange={()=>setGumReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!gumReq} onChange={()=>setGumReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{gumReq ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Confined Space</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={confSpace} onChange={()=>setConfSpace(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!confSpace} onChange={()=>setConfSpace(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{confSpace ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Number of Trucks Required</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{noTruck}</p>    :
                            <input type="text" className="jobCardDivInp" placeholder="Number of Trucks Required" value={noTruck} onChange={(e)=>setNoTruck(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Estimated Service Time</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{servTime}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Service Time" value={servTime} onChange={(e)=>setServTime(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Specific PPE Required</div>
                        {   modalType!=='view'  ?
                        <div>
                            <input type="checkbox" disabled={modalType==='view' } checked={spcPPEReq} onChange={()=>setSpcPPEReq(true)}/><span>YES</span>
                            <input type="checkbox" disabled={modalType==='view' } checked={!spcPPEReq} onChange={()=>setSpcPPEReq(false)}/><span>NO</span>
                        </div>  :
                        <div><span>:</span>
                        <p className="jobCardDivInp" style={{display:'flex', border:'none', alignItems:'center'}} >{spcPPEReq ?'YES':'NO'}</p>
                        </div>
                        }
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>If Yes! Specify</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{specify}</p>    :
                            <input type="text" className="jobCardDivInp" placeholder="" value={specify} onChange={(e)=>setSpecify(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv" style={{ alignItems:'flex-start', marginTop:'1vh' }}>
                        <div style={{ marginTop:'1vh' }}>Comments</div>
                        <div style={{ alignItems:'flex-start' }}>
                            <span style={{ marginTop:'1vh' }}>:</span>
                            
                            <TextAreaLimiter 
                            isValid={(e) => setTextAreaValid({...textAreaValid, b : e })}
                            configs={{
                                className : "jobCardDivInpTextArea", placeholder : "Enter Comment here", value : comments,
                                disabled : modalType==='view', 
                            onChange : (e)=>setComments(e.target.value) 
                            }}
                            v={comments} />
                        </div>
                    </div>
                    <div className="jobCardDivInpContentDiv">
                        <div>Smoke Alarm</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'  ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{smokeAlarm}</p> :
                            <input type="text" className="jobCardDivInp" placeholder="Smoke Alarm" value={smokeAlarm} onChange={(e)=>setSmokeAlarm(e.target.value)}/>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="jobCardFooterContent">
                <div>Completed By</div>
                <div>
                    <span>:</span>
                    {   modalType==='view'   ?
                    <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compl}</p>  :
                    <input type="text" className="jobCardDivInp" placeholder="Completed By" value={compl} onChange={(e)=>setCompl(e.target.value)} />
                    }
                </div>
            </div>
            {/* <div className="jobCardFooterContent">
                <div>Signature</div>
                <div>
                    <span>:</span>
                    {   modalType==='view'   ?
                    <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{sign}</p>   :
                    <input type="text" className="jobCardDivInp" placeholder="Signature" value={sign} onChange={(e)=>setSign(e.target.value)} />
                    }
                </div>
            </div> */}
            <div className="jobCardFooterContent">
                <div>Date</div>
                <div>
                    <span>:</span>
                    <DateTimeInput
                    dateSetFn={setJobDate}
                    dateValue={jobDate}
                    />
                    {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                </div>
            </div>
            
            {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                <div 
                className={ 
                    textAreaValid.a && textAreaValid.b &&pattern.test(jobObjCont.contactEmail) && (clientID && compl) &&
                    ( ((tabType==='waste') && (TypeofWaste!=='')) || (tabType!=='waste') ) &&
                    ( jobObjCont.compAddr && jobObjCont.suburb && jobObjCont.postal && jobObjCont.siteAddr && jobObjCont.buildName && jobObjCont.contactName && jobObjCont.contactEmail  )
                    ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else if(!jobObj.connected)
                        addJobCard()
                    }} 
                  style={{ width:'15vw', height:'5vh', textAlign:'center' }}>

                {   !pattern.test(jobObjCont.contactEmail) ? 'Improper Email entered' :
                modalType==='add' ? 'Create Job Card' : modalType==='verify' ? jobObj.connected ?
                `This job card is already in use `
                :   'Update and Verify' : 'Update Job Card'} 
                </div>
            </div>
            :   null
            }
            </Scrollbars>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    const { user } = state.userLogin
    return { user }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        addJob : (data, success, failed) => dispatch(addNewJobCard(data, success, failed)),
        editJob : (data, job, success, failed) => dispatch(editNewJobCard(data, job, success, failed)),
        editJobCardName : (data, job, success, failed) => dispatch(editNewJobCardName(data, job, 'waste', success, failed)),
        fetch_jobCards      : ()    => dispatch(listJobCards()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (NewJobCard);