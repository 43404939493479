import React, {useEffect} from 'react'
import Scrollbar from '../../../../../common/ImprovedScrollBar'
import { fetch_all_truck_list } from '../common/action'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import moment from 'moment'
import { ReactComponent as Tick } from '../../../../../assets/Images/check.svg'
import { TextAreaLimiter } from '../../../../../common/TextAreaLimiter'

function App({ 
    all_truck_list,
    selected_pre_inspect,
    fetch_all_trucks,
    tabType 
}) {

    useEffect(() => {
        fetch_all_trucks(tabType)
        return () => fetch_all_trucks.remove
    }, [fetch_all_trucks, tabType])


    let Vehicle = selected_pre_inspect.registration
    , Date_time = selected_pre_inspect.date_time
    , DriverName = selected_pre_inspect.name ? selected_pre_inspect.name : selected_pre_inspect.driver_name
    , Odometer = selected_pre_inspect.odometer       
    , DriverSignature = selected_pre_inspect.odometdriver_signature       
    , HourMeterStart = selected_pre_inspect.hour_meter_start       

    , FitForWork = selected_pre_inspect.fit_for_work       
    , ValidDrivingLicense = selected_pre_inspect.Valid_driving_license       
    , AppropriatePPE = selected_pre_inspect.appropriate_ppe       

    , EngineOilLevel = selected_pre_inspect.engine_oil_level       
    , WarningSystem = selected_pre_inspect.warning_system       
    , Steering = selected_pre_inspect.steering       
    , SafetyEmrgStop = selected_pre_inspect.safety_emerg_stop       
    , HandBreakAlram = selected_pre_inspect.handbreak_alarm       
    , PTOVacPump = selected_pre_inspect.pto_vacpump       

    , Horn    = selected_pre_inspect.horn
    , RevAlarmCamera    = selected_pre_inspect.rev_alarm_camera
    , LightsHead    = selected_pre_inspect.lights_head
    , LightsTail    = selected_pre_inspect.lights_tail
    , LightsBeacon    = selected_pre_inspect.light_beacons
    , HazardLight    = selected_pre_inspect.hazard_light
    , HouseKeeping    = selected_pre_inspect.house_keeping

    , RimsWheels    = selected_pre_inspect.rims_wheelnut
    , Coolant    = selected_pre_inspect.coolant
    , Wheels    = selected_pre_inspect.wheels
    , MirrorWindows    = selected_pre_inspect.mirror_windowscreen
    , StructureBody    = selected_pre_inspect.structure_bodywork
    , Wipers    = selected_pre_inspect.wipers

    , FuelLevelPump    = selected_pre_inspect.fuel_levelpump
    , FuelLevelTruck    = selected_pre_inspect.fuel_leveltruck
    , SeatSeatbelt    = selected_pre_inspect.seat_seatbelt
    , ParkBreakTrailer    = selected_pre_inspect.parkbrake_trailer
    , FootBrake    = selected_pre_inspect.foot_brake
    , Electrical    = selected_pre_inspect.electrical
    , PinRetainer    = selected_pre_inspect.pin_retainers

    , Hoses    =  selected_pre_inspect.hoses
    , Fittings    = selected_pre_inspect.fittings
    , FirstAidKit    = selected_pre_inspect.first_aid_kit
    , PPE    = selected_pre_inspect.ppe
    , FireExtingusre    = selected_pre_inspect.fire_extinguisher
    , FireExtingusreDate = selected_pre_inspect.fire_extinguisher_date
    , GardenHose    = selected_pre_inspect.garden_hose
    , GaticLiters    = selected_pre_inspect.gatic_lifters
    , BucketRag    = selected_pre_inspect.bucket_rags
    , SpillKit    = selected_pre_inspect.spill_kit

    , FaultReportz    = selected_pre_inspect.reported_fault_string

    , ReadyToOperate    = selected_pre_inspect.safe_ready_to_operate
    , ReportedFaults    = selected_pre_inspect.reported_faults
    , ReviewdForm    = selected_pre_inspect.reviewed_form

    , Corrected    = selected_pre_inspect.corrected
    , ScheduledForRepair    = selected_pre_inspect.scheduled_for_repair
    , NoAction    = selected_pre_inspect.no_action
    , DoNotAffectSafeOperation    = selected_pre_inspect.do_not_affect_safe_operation

    
    return (
        <div className="PreInspectionCheck">
            <div className="pre-topbar">
                <div className="sgr-mntnce-rprt-topbar">
                    <button className="sgr-mntnce-rprt-topbarBtn2">Master - Truck</button>
                    <button className="sgr-mntnce-rprt-topbarBtn2">Maintanance Report</button>
                </div>

{/*                 
                <div className="sgr-mntnce-rprt-topbar">
                    <Link className="sgr-mntnce-rprt-topbar" to="preinspection" style={{ textDecoration:'none' }}>
                        <button className="sgr-mntnce-rprt-addNew">Back</button>
                    </Link>
                </div> */}
                
                
            </div>
            <p style={{ fontSize: 14, marginTop: 10, marginLeft: 5 }} className="p-0 m-0">Vehicle Safety Pre-Start Inspection Checklist</p>
            <Scrollbar style={{ height: '75vh', marginTop: 10 }}>

                <div className="pre-box-1">
                    <div className="pre-box-1-top">
                        <div className="pre-box-1-left">

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Date & Time</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    <p style={{ color: 'red' }}  className="p-0 m-0">{moment(Date_time).format('DD-MM-YYYY hh:mm:ss')}</p>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Vehicle Registration</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    {Vehicle}
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Odometer</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    
                                        {Odometer}
                                        
                                </div>
                            </div>

                        </div>

                        <div className="pre-box-1-right">
                            

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Drivers Name</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    <p style={{ color: 'red' }} className="p-0 m-0">{DriverName}</p>
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0" >Drivers Signature</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    
                                        {DriverSignature}
                                        
                                </div>
                            </div>

                            <div className="pre-box-1-column">
                                <div className="pre-box-1-clmn-head">
                                    <p className="p-0 m-0">Hour Meter Start</p>
                                </div>
                                <div className="pre-box-1-clmn-body">
                                    
                                        {HourMeterStart}
                                        
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    <div className="pre-box-1-bottom">
                        <div className="pre-box-1-bottom-check">
                            <input 
                                type="checkbox" 
                                className="vehicle1" 
                                checked=
                                {FitForWork}
                                readOnly={true}
                            />                     
                            <label> I am Fit for Work</label> 
                        </div>

                        <div className="pre-box-1-bottom-check">
                            <input 
                                type="checkbox" 
                                className="vehicle1" 
                                checked=
                                {ValidDrivingLicense}
                                readOnly={true}
                            />                   
                            <label> I have the appropriate valid driving license</label> 
                        </div>

                        <div className="pre-box-1-bottom-check">
                            <input 
                                type="checkbox" 
                                className="vehicle1" 
                                checked=
                                {AppropriatePPE}
                                readOnly={true}
                            />                    
                            <label> Appropriate PPE for operation of this vehicle</label> 
                        </div>
                    </div>
                </div>

                <p style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }} className="p-0 m-0">Pre-Start Checklist-All Fields are  Mandatory (No Obivous Defect)(X-Fault Identified) & N/A not applicable</p>

                <div className="pre-box-2">

                    <p 
                        style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }} 
                        className="p-0 m-0">
                            Category "A" Fault: Vehicle 
                            <span style={{ color: 'red' }}> MUST NOT be operated  </span> 
                            until fault is rectified
                    </p>

                    <p 
                        style={{ fontSize: 12, fontWeight: '500', marginTop: 10, marginLeft: 10 }} 
                        className="p-0 m-0">
                            Category "B" Fault: Vehicle 
                            <span style={{ color: '#0F99D0' }}> May be operated  </span> 
                            Corrective action is required
                    </p>

                </div>

                <div className="pre-box-3">
                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Engine Oil Level</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Warning System</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Steering</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Safety / Emerg Stops</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Hand Brake Alarm</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">PTO / Vac. Pump</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                
                            </div>
                           
                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {EngineOilLevel===null ? "checkmark" : EngineOilLevel ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {WarningSystem===null ? "checkmark" : WarningSystem ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {Steering===null ? "checkmark" : Steering ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {SafetyEmrgStop===null ? "checkmark" : SafetyEmrgStop ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {HandBreakAlram===null ? "checkmark" : HandBreakAlram ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {PTOVacPump===null ? "checkmark" : PTOVacPump ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                          
                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Horn</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Rev alarm / Camera</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Lights-Head</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Lights-Tail</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Light-beacons</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Hazards-Light</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className=" p-0 m-0">House keeping</p>
                            </div>
                           
                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className=
                                    {Horn===null ? "checkmark" : Horn ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={RevAlarmCamera===null ? "checkmark" : RevAlarmCamera ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={LightsHead===null ? "checkmark" : LightsHead ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={LightsTail===null ? "checkmark" : LightsTail ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={LightsBeacon===null ? "checkmark" : LightsBeacon ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={HazardLight===null ? "checkmark" : HazardLight ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={HouseKeeping===null ? "checkmark" : HouseKeeping ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Rims & Wheel Nuts</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Coolant</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Wheels & Tyres</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Mirrors & Windscreen</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Structure & Bodywork</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Wipers</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                
                            </div>
                           
                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={RimsWheels===null ? "checkmark" : RimsWheels ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={Coolant===null ? "checkmark" : Coolant ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={Wheels===null ? "checkmark" : Wheels ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={MirrorWindows===null ? "checkmark" : MirrorWindows ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={StructureBody===null ? "checkmark" : StructureBody ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={Wipers===null ? "checkmark" : Wipers ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                 
                            </div>
                        </div>
                    </div>

                    <div className="pre-box-3-row">
                        <div className="pre-box-3-row-left">
                            <div className="pre-box-3-row-head">

                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Fuel Level Pump</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Fuel Level Truck</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Seat / Seat Belt</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Park Brake/Trailer</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Foot Brake</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Electrical</p>
                            </div>

                            <div className="pre-box-3-row-body">
                                <p className="p-0 m-0">Pin Retainers</p>
                            </div>
                           
                        </div>
                        <div className="pre-box-3-row-right">
                            <div className="pre-box-3-row-right-head">
                                <p className=" p-0 m-0">Fault Category</p>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={FuelLevelPump===null ? "checkmark" : FuelLevelPump ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={FuelLevelTruck===null ? "checkmark" : FuelLevelTruck ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={SeatSeatbelt===null ? "checkmark" : SeatSeatbelt ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={ParkBreakTrailer===null ? "checkmark" : ParkBreakTrailer ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={FootBrake===null ? "checkmark" : FootBrake ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={Electrical===null ? "checkmark" : Electrical ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>

                            <div className="pre-box-3-row-right-body">
                                <div className={PinRetainer===null ? "checkmark" : PinRetainer ? "checkmark checkedRedmark":"checkmark checkedBlmark"}><Tick/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pre-box-3-2">
                    <div className="pre-box-3-2-left">
                        <p className="p-0 m-0">Accesseories and Fittings</p>
                    </div>
                    <div className="pre-box-3-2-right">
                        <div className="pre-box-3-2-right-top">
                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Hoses}
                                    readOnly={true}
                                    />       
                                <label> Hoses</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={Fittings}
                                    readOnly={true}
                                    />         
                                <label> Fittings</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FirstAidKit}
                                    readOnly={true}
                                    />         
                                <label> First Aid Kit</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={PPE}
                                    readOnly={true}
                                    />         
                                <label> PPE</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={FireExtingusre}
                                    readOnly={true}
                                    />        
                                <label> Fire Extinguisher(Date Caibrated)</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                {/* {/* <input 
                                    type="date" 
                                    className="vehicle1"
                                    value= */}
                                    {moment(FireExtingusreDate).format('DD-MM-YYYY')} 
                                    {/* onChange={txt => setFireExtingusreDate(txt.target.value)}
                                />                     */}
                            </div>

                        </div>
                        <div className="pre-box-3-2-right-bottom">
                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={GardenHose}
                                    readOnly={true}
                                    />        
                                <label>Garden Hoses</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={GaticLiters}
                                    readOnly={true}
                                    />        
                                <label> Gattic Lifters</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={BucketRag}
                                    readOnly={true}
                                    />        
                                <label> Bucket / Rags</label> 
                            </div>

                            <div className="pre-box-3-2-right-top-check">
                                <input 
                                    type="checkbox" 
                                    className="vehicle1" 
                                    checked={SpillKit}
                                    readOnly={true}
                                    />       
                                <label> Spill Kitt</label> 
                            </div>

                        </div>
                    </div>
                </div>

                <div className="pre-box-4">
                    <div className="pre-box-4-left">
                        <p className="p-0 m-0">Any Fault to Report (any Category) & any additional information</p>
                    </div>

                    <div className="pre-box-4-right">
                        
                        <TextAreaLimiter
                        configs={{
                            className : "pre-box-4-textarea",
                            placeholder : "Text Here...",
                            value : FaultReportz,
                            readOnly : true
                        }}
                        v={FaultReportz} />
                    </div>
                </div>

                <div className="pre-box-5">
                    <div className="pre-box-5-top">
                        <div className="pre-box-5-top-head">
                            <p className="p-0 m-0">Action Taken</p>
                        </div>
                        <div className="pre-box-5-top-head">
                            <p className="p-0 m-0">Authorized By</p>
                        </div>
                    </div>
                    <div className="pre-box-5-bottom">
                        <div className="pre-box-5-top-body">
                            <input 
                                type="checkbox" 
                                className="vehicle1" 
                                checked={ReadyToOperate}
                                readOnly={true}
                                />        
                            <label> I have conducted the above pre-start checklist and satisfied that the vehicle is safe and ready to operate.</label> 
                        </div>
                        <div className="pre-box-5-top-body">
                            <input 
                                type="checkbox" 
                                className="vehicle1" 
                                checked={ReportedFaults}
                                readOnly={true}
                                />      
                            <label> I have reported all faults in appropriate section and notified the authorized personnel.</label> 
                        </div>
                        <div className="pre-box-5-top-body">
                            <p style={{ textAlign: 'right' ,marginLeft:'auto'}} className="p-0 m-0">Driver's  Signature</p>
                        </div>
                    </div>
                </div>

                <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>Manager Brief and Review</p>
                <div className="pre-box-6">
                    <div className="pre-box-6-top">
                        <input 
                                type="checkbox" 
                                className="vehicle1" 
                                checked={ReviewdForm}
                                readOnly={true}
                                />        
                        <label> I have reviewed this form and satisfied that required maintenance or safety related items have been addressed .</label> 
                    </div>
                    <div className="pre-box-6-center">
                        <div className="pre-box-6-center-left">
                            <p className="p-0 m-0">I Certify that faults reported have been</p>
                        </div>
                        <div className="pre-box-6-center-right">
                            <div className="pre-box-6-center-right-top">
                                <div className="pre-box-1-bottom-check">
                                    <input 
                                        type="checkbox" 
                                        className="vehicle1" 
                                        checked={Corrected}
                                        readOnly={true}
                                        />        
                                    <label> Corrected</label> 
                                </div>
                                <div className="pre-box-1-bottom-check">
                                    <input 
                                        type="checkbox" 
                                        className="vehicle1" 
                                        checked={ScheduledForRepair}
                                        readOnly={true}
                                        />         
                                    <label> Schedule  for repair</label> 
                                </div>
                                <div className="pre-box-1-bottom-check">
                                    <input 
                                        type="checkbox" 
                                        className="vehicle1" 
                                        checked={NoAction}
                                        readOnly={true}
                                        />       
                                    <label>No Action</label> 
                                </div>
                            </div>
                            <div className="pre-box-6-center-right-bottom">
                                <div className="pre-box-1-bottom-check">
                                    <input 
                                        type="checkbox" 
                                        className="vehicle1" 
                                        checked={DoNotAffectSafeOperation}
                                        readOnly={true}
                                        />        
                                    <label> Issues scheduled for maintenance or repair do not affect the safe operation of this vehicle</label> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pre-box-6-bottom">
                        
                    </div>
                </div>

                {/* <div className="pre-box-6-submit">
                    {
                        submit_btn ? (
                            <button onClick={() => submit_form()} className="pre-box-submit-btn">Submit</button>
                        ) : (
                            <button className="pre-box-submit-btn disabled-btn">Submit</button>
                        )
                    }
                    
                </div> */}

            </Scrollbar>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { all_truck_list, selected_pre_inspect } = state.vehicle__truck
    return { all_truck_list, selected_pre_inspect }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_all_trucks: (tabType) => dispatch(fetch_all_truck_list(tabType))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (App)

