import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import '../team/component/styles.css'
import { connect } from 'react-redux';
// import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
// import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import TimeSheetModal from './EmployeeTimeSheetModal';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    usrName,
    usrId,
    initialEmpTimeSheetId,
    initialEmpTimeSheet, empTimeSheetLoader, filterDate
}) {

    const classes = useStyles();


    const handleClose = () => {
        modalAction(false)
    };

    const [weekly_report, setWeeklyReport] = useState( initialEmpTimeSheet && empTimeSheetLoader ? 
      initialEmpTimeSheet.length!==0 && initialEmpTimeSheet ?
      initialEmpTimeSheet :
      { employee_id		       : usrId,
        comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
        whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        week			              :	[
          { annual		: 0, date		: moment(filterDate).format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 }
        ]
      }
      :
      { employee_id		       : usrId,
        comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
        whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        week			              :	[
          { annual		: 0, date		: moment(filterDate).format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 }
        ]
      })
    


  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        {
            empTimeSheetLoader && initialEmpTimeSheet ?
        <TimeSheetModal
            modalAction={handleClose}
            Name={usrName}
            userId={usrId}
            initialTimeSheet={initialEmpTimeSheet}
            timeSheetLoader={empTimeSheetLoader} 
            initialTimeSheetId={initialEmpTimeSheetId}
            filterDate={filterDate}
            weekly_report={weekly_report}
            setWeeklyReport={setWeeklyReport}
        />  : <CircularProgress />
            
        }
      </Modal>
  );
}
const mapStateToProps = (state) =>{
  const { initialEmpTimeSheet, empTimeSheetLoader, filterDate, initialEmpTimeSheetId } = state.accountsData;
  return { initialEmpTimeSheet, empTimeSheetLoader, filterDate, initialEmpTimeSheetId }
}

 export default connect(mapStateToProps) (TransitionsModal)
 
