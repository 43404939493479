import React, { useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { cl_blue } from '../../../../common/Colors';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { select_client, client_sort, temp_client_sort, fetch_clients_paginated, reloadPage, del_client_sort } from '../../action'
import SearchTab from './SearchTab';
import { AddRounded, FilterListRounded } from '@material-ui/icons';
import IndustryModal from './IndustryModal';
import { useHistory } from 'react-router-dom';
import Api from '../../../../store/api'
import { SITE_INDUSTRY_LIST } from '../../../../store/endpoint';

const useStyles = makeStyles((theme) => ({
    formControl: {
    //   margin: theme.spacing(1),
      minWidth: 120,
    },
    formControl2: {
        //   margin: theme.spacing(1),
          minWidth: 70,
        },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    resize:{
        fontSize: 16,
        fontWeight: 700,
        color: cl_blue
    },
    resize2:{
        fontSize: 16,
        fontWeight: 700,
        color: cl_blue,
        marginLeft: theme.spacing(3),
    }
  }));

function Topbar({ client, searchQuery, searchTempQuery, remove_selected_client, sortClient, tabState, setTabState, tabType, sortTempClient, sortDelClient,
    fetch_client_list, reloadClients, addData }) {
    const classes = useStyles();
    const [industryModalOpen, setIndustryModalOpen] = useState(false)
    const [addSortVal, setAddSortVal] = useState(0)
    const history = useHistory();
    const [industryList, setIndustryList] = useState([])
    // const [SortBy, setSortBy] = useState('alphabet')
                 
    useEffect(() => {
        Api('get',SITE_INDUSTRY_LIST,'').then(response => {
            console.log('INDUSTRY',response)
            setIndustryList(response)
        })
        
    }, []);

    const { sort, ind_sort, clientType, temp_client_count, perm_client_count, deleted_client_count } = client

    let sort_arr = [
        {
            val: 'alpha_asc',
            name: 'Alphabet(A->Z)'
        },
        {
            val: 'alpha_dsc',
            name: 'Alphabet(Z->A)'
        }
        // {
        //     val: 'date_asc',
        //     name: 'date asce'
        // },
        // {
        //     val: 'date_dsc',
        //     name: 'date desc'
        // },
    ]

    let add_sort = [
        {
            val: 0, name:'Site'
        },
        {
            val: 1, name:'Industry Type'
        }
    ]

    return(
        <div className='client-topbar-layout2'>
            <div className='client-topbar-subsection'>
                <div className="client-tabBtn" style={{ width:'40vw', justifyContent:'space-between', marginRight: '1%'}}>
                    <div style={{textAlign:'center', position:'relative'}} onClick={()=>{ 
                        reloadPage()
                        fetch_client_list(null, tabType, 1, 'perm',ind_sort)
                        setTabState(0) }} 
                    className={ clientType==='perm' ? "p-0 m-0 client-tabDiv" :  "p-0 m-0 client-tabDivInactive"}>
                        Permanent Sites
                        <span className={ clientType==='perm' ? "client-tabDivInactive-span" :  "client-tabDiv-span"}>{perm_client_count}</span>
                    </div>
                    <div style={{textAlign:'center', position:'relative'}} onClick={()=>{ 
                        reloadPage()
                        fetch_client_list(null, tabType, 1, 'temp',ind_sort)
                        setTabState(1) }}  
                    className={ clientType==='temp' ? "p-0 m-0 client-tabDiv-rt client-tabDiv-md" : "p-0 m-0 client-tabDivInactive" }>
                        Temporary Sites
                        <span className={ clientType==='temp' ? "client-tabDivInactive-span" : "client-tabDiv-span"}>{temp_client_count}</span>
                    </div>
                    <div style={{textAlign:'center', position:'relative'}} onClick={()=>{ 
                        reloadPage()
                        fetch_client_list(null, tabType, 1, 'deleted',ind_sort)
                        setTabState(2) }}  
                    className={ clientType==='deleted' ? "p-0 m-0 client-tabDiv-rt" : "p-0 m-0 client-tabDivInactive"}>
                        Deleted Sites
                        <span className={ clientType==='deleted' ? "client-tabDivInactive-span" : "client-tabDiv-span"}>{deleted_client_count}</span>
                    </div>
                </div>
            </div>
            <div className='client-topbar-subSection2'>
                <div className="topbar-left-layout">
                    <SearchTab
                    tabType={tabType} tabVal={tabState} sortType={sort} ind_sort={ind_sort}
                    />                
                </div>
                <div className="sort-layout" style={{ marginLeft:'1%' }}>
                    <div className="sort-txt-layout thinnerScreenSpl">
                        <p className="p-0 m-0">Sort By Industry</p>
                        <FilterListRounded/>
                    </div>

                    <div className="sort-dropdown-layout">
                        {/* <p className="p-0 m-0">Alphabet</p>
                        <ExpandMore className="sort-icon" /> */}
                        <FormControl className={classes.formControl2}>
                            <Select
                                disableUnderline
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                SelectDisplayProps={{ fontSize: 16}}
                                value={ind_sort}
                                className={classes.resize}
                                onChange={txt => {
                                    if(tabState===0)
                                        sortClient(sort, tabType, txt.target.value)
                                    else if(tabState===1)
                                        sortTempClient(sort, tabType, txt.target.value)
                                    else 
                                        sortDelClient(sort, tabType, txt.target.value)
                                }}
                            >
                                <MenuItem key={0} value={null}>None</MenuItem>
                           {
                               industryList.map((item, key) => {
                                   const { id, industry_name } = item
                                   return (
                                    <MenuItem key={key} value={id}>{industry_name}</MenuItem>
                                   )
                               })
                           }
                            
                            </Select>
                        </FormControl>
                    </div>

                        
                </div>
                <div className="sort-layout">
                    <div className="sort-txt-layout thinnerScreenSpl">
                        <p className="p-0 m-0">Sort By</p>
                        <FilterListRounded/>
                    </div>

                    <div className="sort-dropdown-layout">
                        {/* <p className="p-0 m-0">Alphabet</p>
                        <ExpandMore className="sort-icon" /> */}
                        <FormControl className={classes.formControl}>
                            <Select
                                disableUnderline
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                SelectDisplayProps={{ fontSize: 16, marginLeft : 10 }}
                                value={sort}
                                className={classes.resize}
                                onChange={txt => {
                                    if(tabState===0)
                                        sortClient(txt.target.value, tabType)
                                    else if(tabState===1)
                                        sortTempClient(txt.target.value, tabType)
                                    else 
                                        sortDelClient(txt.target.value, tabType)
                                }}
                            >
                           {
                               sort_arr.map((item, key) => {
                                   const { val, name } = item
                                   return (
                                    <MenuItem key={key} value={val}>{name}</MenuItem>
                                   )
                               })
                           }
                            
                            </Select>
                        </FormControl>
                    </div>

                        
                </div>
                <IndustryModal modalAction={setIndustryModalOpen} modalStatus={industryModalOpen} />
                {   addData ?
                <div className="sort-layout add-client-layout  thinnerScreenSpl">
                    <div className="sort-txt-layout" style={{ marginRight : '1%' }}>
                        <AddRounded/>
                        <p className="p-0 m-0">Add</p>
                    </div>

                    <div className="sort-dropdown-layout">
                        <FormControl className={classes.formControl2}>
                            <Select
                                disableUnderline
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                SelectDisplayProps={{ fontSize: 14 }}
                                value={addSortVal}
                                className={classes.resize2}
                                onChange={txt => {
                                    setAddSortVal(txt.target.value)
                                    if(txt.target.value===0)
                                        history.push('/client/create')
                                    else 
                                        setIndustryModalOpen(true)
                                }}
                            >
                           {
                               add_sort.map((item, key) => {
                                   const { val, name } = item
                                   return (
                                    <MenuItem key={key} value={val}
                                     onClick={()=>{ setAddSortVal(val);
                                        if(val===0)
                                        history.push('/client/create')
                                    else 
                                        setIndustryModalOpen(true)
                                    }}>{name}</MenuItem>
                                   )
                               })
                           }
                            
                            </Select>
                        </FormControl>
                    </div>

                        
                </div>
                :   null}
                
            </div>
        </div>
    )
    // return (
    //     <div className="client-topbar-layout">
    //         <div className="topbar-left-layout">
    //             <SearchTab
    //                 tabType={tabType} tabVal={tabState} sortType={sort} ind_sort={ind_sort}
    //             />                
    //         </div>

    //         <div className="sort-layout" style={{ marginLeft:'1%' }}>
    //                 <div className="sort-txt-layout thinnerScreenSpl">
    //                     <p className="p-0 m-0">Sort By Industry</p>
    //                     <FilterListRounded/>
    //                 </div>

    //                 <div className="sort-dropdown-layout">
    //                     {/* <p className="p-0 m-0">Alphabet</p>
    //                     <ExpandMore className="sort-icon" /> */}
    //                     <FormControl className={classes.formControl2}>
    //                         <Select
    //                             disableUnderline
    //                             labelId="demo-simple-select-label"
    //                             id="demo-simple-select"
    //                             SelectDisplayProps={{ fontSize: 16}}
    //                             value={ind_sort}
    //                             className={classes.resize}
    //                             onChange={txt => {
    //                                 if(tabState===0)
    //                                     sortClient(sort, tabType, txt.target.value)
    //                                 else if(tabState===1)
    //                                     sortTempClient(sort, tabType, txt.target.value)
    //                                 else 
    //                                     sortDelClient(sort, tabType, txt.target.value)
    //                             }}
    //                         >
    //                             <MenuItem key={0} value={null}>None</MenuItem>
    //                        {
    //                            industryList.map((item, key) => {
    //                                const { id, industry_name } = item
    //                                return (
    //                                 <MenuItem key={key} value={id}>{industry_name}</MenuItem>
    //                                )
    //                            })
    //                        }
                            
    //                         </Select>
    //                     </FormControl>
    //                 </div>

                        
    //             </div>
                

    //         {/* <div className="topbar-left-layout">
    //             <div className="add-client-layout" style={{ marginLeft:'1%', textAlign:'center' }} onClick={()=>setIndustryModalOpen(true)}>
    //                 <p className="p-0 m-0">Add Industry Type</p>
    //             </div>
    //         </div> */}
    //         <div className="topbar-right-layout">
                
    //             <div className="client-tabBtn" style={{ width:'40vw', justifyContent:'space-between', marginRight: '1%'}}>
    //                 <div style={{textAlign:'center', position:'relative'}} onClick={()=>{ 
    //                     reloadPage()
    //                     fetch_client_list(null, tabType, 1, 'perm',ind_sort)
    //                     setTabState(0) }} 
    //                 className={ clientType==='perm' ? "p-0 m-0 client-tabDiv" :  "p-0 m-0 client-tabDivInactive"}>
    //                     Permanent Sites
    //                     <span className={ clientType==='perm' ? "client-tabDivInactive-span" :  "client-tabDiv-span"}>{perm_client_count}</span>
    //                 </div>
    //                 <div style={{textAlign:'center', position:'relative'}} onClick={()=>{ 
    //                     reloadPage()
    //                     fetch_client_list(null, tabType, 1, 'temp',ind_sort)
    //                     setTabState(1) }}  
    //                 className={ clientType==='temp' ? "p-0 m-0 client-tabDiv-rt client-tabDiv-md" : "p-0 m-0 client-tabDivInactive" }>
    //                     Temporary Sites
    //                     <span className={ clientType==='temp' ? "client-tabDivInactive-span" : "client-tabDiv-span"}>{temp_client_count}</span>
    //                 </div>
    //                 <div style={{textAlign:'center', position:'relative'}} onClick={()=>{ 
    //                     reloadPage()
    //                     fetch_client_list(null, tabType, 1, 'deleted',ind_sort)
    //                     setTabState(2) }}  
    //                 className={ clientType==='deleted' ? "p-0 m-0 client-tabDiv-rt" : "p-0 m-0 client-tabDivInactive"}>
    //                     Deleted Sites
    //                     <span className={ clientType==='deleted' ? "client-tabDivInactive-span" : "client-tabDiv-span"}>{deleted_client_count}</span>
    //                 </div>
    //             </div>

    //             {/* <div onClick={()=> takeAllPrint(clientType==='perm' ? 'permanent' : 'temporary',tabType) }
    //              className="add-client-layout">
    //                 <p className="p-0 m-0">Print All Sites</p>
    //             </div> */}
    //             <div className="sort-layout">
    //                 <div className="sort-txt-layout thinnerScreenSpl">
    //                     <p className="p-0 m-0">Sort By</p>
    //                     <FilterListRounded/>
    //                 </div>

    //                 <div className="sort-dropdown-layout">
    //                     {/* <p className="p-0 m-0">Alphabet</p>
    //                     <ExpandMore className="sort-icon" /> */}
    //                     <FormControl className={classes.formControl}>
    //                         <Select
    //                             disableUnderline
    //                             labelId="demo-simple-select-label"
    //                             id="demo-simple-select"
    //                             SelectDisplayProps={{ fontSize: 16, marginLeft : 10 }}
    //                             value={sort}
    //                             className={classes.resize}
    //                             onChange={txt => {
    //                                 if(tabState===0)
    //                                     sortClient(txt.target.value, tabType)
    //                                 else if(tabState===1)
    //                                     sortTempClient(txt.target.value, tabType)
    //                                 else 
    //                                     sortDelClient(txt.target.value, tabType)
    //                             }}
    //                         >
    //                        {
    //                            sort_arr.map((item, key) => {
    //                                const { val, name } = item
    //                                return (
    //                                 <MenuItem key={key} value={val}>{name}</MenuItem>
    //                                )
    //                            })
    //                        }
                            
    //                         </Select>
    //                     </FormControl>
    //                 </div>

                        
    //             </div>
    //             <IndustryModal modalAction={setIndustryModalOpen} modalStatus={industryModalOpen} />
    //             {/* {   addData ?
    //             <Link to="/client/create" onClick={() => remove_selected_client()} className="add-client-layout  thinnerScreenSpl" style={{ marginLeft:'1%' }} >
    //                 <p className="p-0 m-0">Add Site</p>
    //                 <AddRounded/>
    //             </Link>
    //             :   null    } */}
    //             {   addData ?
    //             <div className="sort-layout add-client-layout  thinnerScreenSpl">
    //                 <div className="sort-txt-layout" style={{ marginRight : '1%' }}>
    //                     <AddRounded/>
    //                     <p className="p-0 m-0">Add</p>
    //                 </div>

    //                 <div className="sort-dropdown-layout">
    //                     <FormControl className={classes.formControl2}>
    //                         <Select
    //                             disableUnderline
    //                             labelId="demo-simple-select-label"
    //                             id="demo-simple-select"
    //                             SelectDisplayProps={{ fontSize: 14 }}
    //                             value={addSortVal}
    //                             className={classes.resize2}
    //                             onChange={txt => {
    //                                 setAddSortVal(txt.target.value)
    //                                 if(txt.target.value===0)
    //                                     history.push('/client/create')
    //                                 else 
    //                                     setIndustryModalOpen(true)
    //                             }}
    //                         >
    //                        {
    //                            add_sort.map((item, key) => {
    //                                const { val, name } = item
    //                                return (
    //                                 <MenuItem key={key} value={val}
    //                                  onClick={()=>{ setAddSortVal(val);
    //                                     if(val===0)
    //                                     history.push('/client/create')
    //                                 else 
    //                                     setIndustryModalOpen(true)
    //                                 }}>{name}</MenuItem>
    //                                )
    //                            })
    //                        }
                            
    //                         </Select>
    //                     </FormControl>
    //                 </div>

                        
    //             </div>
    //             :   null}
                
    //         </div>
            
    //     </div>
    // )
}

const mapStateToProps = (state) => {
    const { client } = state
    return { client }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reloadClients: () => dispatch(reloadPage()),
        remove_selected_client: () => dispatch(select_client({})),
        sortClient: (param, tabType, ind) => dispatch(client_sort(param, tabType, ind)),
        sortTempClient: (param, tabType, ind) => dispatch(temp_client_sort(param, tabType, ind)),
        sortDelClient: (param, tabType, ind) => dispatch(del_client_sort(param, tabType, ind)),
        fetch_client_list: (param, tabType, page, type, ind) => dispatch(fetch_clients_paginated(param, tabType, page, type, ind))
      }
}



export default connect(mapStateToProps, mapDispatchToProps) (Topbar)
