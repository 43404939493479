import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Modal } from '@material-ui/core'
import './styles.css'
import { connect } from 'react-redux';
// import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
// import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import TimeSheetModal from '../../../common/TimeSheetModal';
import LeaveApplyModal from '../../../common/LeaveApplyModal';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '7 !important'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction,
    modalType,
    usrName,
    usrId,
    initialTimeSheetId,
    initialTimeSheet, timeSheetLoader, filterDate
}) {

    const classes = useStyles();


    const handleClose = () => {
        modalAction(false)
    };

    const [weekly_report, setWeeklyReport] = useState( initialTimeSheet && timeSheetLoader ? 
      initialTimeSheet.length!==0 && initialTimeSheet.weekly_report ?
      initialTimeSheet.weekly_report :
      { employee_id		       : usrId,
        comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
        whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        week			              :	[
          { annual		: 0, date		: moment(filterDate).format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
        
          { annual		: 0, date		: moment(filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
        ]
      }
      :
      { employee_id		       : usrId,
        comments		         : '', employee_signature	 : '', manager_signature	   : '', attachment		       : '',
        whole_hours_total_worked: { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_week_minus	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        whole_paid_hours	      : { annual		: 0, full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, total_hours_worked: 0 },
        week			              :	[
          { annual		: 0, date		: moment(filterDate).format('YYYY-MM-DD'), day		: "Friday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(1,'day').format('YYYY-MM-DD'), day		: "Saturday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(2,'day').format('YYYY-MM-DD'), day		: "Sunday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
        
          { annual		: 0, date		: moment(filterDate).add(3,'day').format('YYYY-MM-DD'), day		: "Monday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(4,'day').format('YYYY-MM-DD'), day		: "Tuesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(5,'day').format('YYYY-MM-DD'), day		: "Wednesday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
          { annual		: 0, date		: moment(filterDate).add(6,'day').format('YYYY-MM-DD'), day		: "Thursday", finish		: new Date(), full_time	: 0, half_time	: 0, normal_hours	: 0, other_days	: 0, public_holidays: 0, sick		: 0, start		: new Date(), total_hours_worked: 0 },
        ]
      })
    


  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        {
            modalType ?
            timeSheetLoader && initialTimeSheet ?
        <TimeSheetModal
            modalAction={handleClose}
            Name={usrName}
            userId={usrId}
            initialTimeSheet={initialTimeSheet}
            timeSheetLoader={timeSheetLoader} 
            initialTimeSheetId={initialTimeSheetId}
            filterDate={filterDate}
            weekly_report={weekly_report}
            setWeeklyReport={setWeeklyReport}
        />  : <CircularProgress />
            :
        <LeaveApplyModal
            modalAction={handleClose}
            Name={usrName}
            userId={usrId}
        />
        }
      </Modal>
  );
}
const mapStateToProps = (state) =>{
  const { initialTimeSheet, timeSheetLoader, filterDate, initialTimeSheetId } = state.team;
  const { user, fcm_token } = state.userLogin
  return { user, fcm_token, initialTimeSheet, timeSheetLoader, filterDate, initialTimeSheetId }
}


const mapDispatchToProps = (dispatch) => {
  return {
      user_logout: () => dispatch({ type: "SIGNOUT" }),
      clear_fcm: () => dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
    }
}



 export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal)
 
