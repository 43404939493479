import React, { useEffect, useState } from 'react'
import Scrollbars from '../../../../../common/ImprovedScrollBar';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from 'react-router-dom'
import { preinspection_car_list, updateSelectedPreInspect, fetch_all_car_list, fetch_filter_car_list, preInspListCSVPrint } from '../common/action'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateTimeInput from '../../../../../common/DateTimeInput';
import { connect } from 'react-redux'
import moment from 'moment';
import SearchTab from '../../common/SearchTab';
import { Search } from '@material-ui/icons';
import ErrorHandling from '../../../../../common/ErrorHandling';

function InspectionList({ updateSelPreInsp, fetch_all_cars, all_car_list, filterKey, permissions,
    car_preInsp_list, pre_insp_loader, fetch_filter_car, tabType, preinspection_list,
    pre_insp_error, pre_insp_page, pre_insp_stoppagination,pre_insp_search_qry }) {

    const [filterFromDate, setFilterFromDate] = useState({ val : new Date(), status : false})
    const [filterToDate, setFilterToDate] = useState({ val : new Date(), status : false})
    
    const [clearFilterDate, setClearFilterDate] = useState(false)
    const [invokeDate, setInvokeDate] = useState(false)
    // const [pageLoader, setPageLoader] = useState(false)


    useEffect(() => {
        preinspection_list(tabType, 1)
        fetch_all_cars(tabType)
        return () => fetch_all_cars.remove
    }, [fetch_all_cars, tabType, preinspection_list])


    let balance_row = 0
    let limit = 10
    if (car_preInsp_list.length < limit) {
        balance_row = limit - car_preInsp_list.length
    }

    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: 120,
            maxHeight: 20,
            marginLeft: 10,
            marginTop: -8,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        resize: {
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }
    }));


    const SortDropdown = () => {
        const classes = useStyles();
        return (
            <FormControl className={classes.formControl}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                <div>Filter</div>
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14 }}
                    value={filterKey}
                    className={classes.resize}
                    style={{ color: 'black' }}
                    onChange={(val) => {

                        if (val.target.value !== 'all') {
                            let postData = new FormData()
                            postData.append('registration', val.target.value)
                            postData.append('vehicle_type', 'car')
                            postData.append('tab_type', tabType)
                            fetch_filter_car(tabType, null, null, postData, 'vehicle', val.target.value,1,'filter')
                            setClearFilterDate(false);
                            setInvokeDate(false)

                        } else {
                            fetch_filter_car(tabType, null, null, null, 'all', 'all',1,'filter')
                            // preinspection_list(tabType, 1)
                        }
                    }}
                >

                    <MenuItem value={'all'}>All</MenuItem>
                    {
                        all_car_list.map((item, key) => {

                            return (
                                <MenuItem key={key} value={item.registration}>{item.registration}</MenuItem>
                            )
                        })
                    }

                </Select>
            </FormControl>
        )
    }

    const openPreInspect = (obj) => {
        updateSelPreInsp(obj)
    }

    const FromDateFilterFn = (date) => {
        let obj = { val : date, status : true}
        setFilterFromDate(obj)
        let date_time = date
        let postData = new FormData()
        postData.append('from_date', date_time)
        postData.append('to_date',filterToDate.val)
        postData.append('vehicle_type', 'car')
        postData.append('tab_type', tabType)
        if(filterToDate.status)
            fetch_filter_car(tabType, null, null, postData, 'day', 'all',1,'date')
        setClearFilterDate(true)
    }
    const ToDateFilterFn = (date) => {
        let obj = { val : date, status : true}
        setFilterToDate(obj)
        let date_time = date
        let postData = new FormData()
        postData.append('from_date', filterFromDate.val)
        postData.append('to_date', date_time)
        postData.append('vehicle_type', 'car')
        postData.append('tab_type', tabType)
        if(filterFromDate.status)
            fetch_filter_car(tabType, null, null, postData, 'day', 'all',1,'date')
        setClearFilterDate(true)
    }
    const fetch = () => {
        if (!pre_insp_loader) {
            if (filterKey !== 'all') {
                
                let postData = new FormData()
                postData.append('registration', filterKey)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey,pre_insp_page ,'filter')
            } else if (clearFilterDate || invokeDate) {
                let postData = new FormData()
                postData.append('from_date', filterFromDate.val)
                postData.append('to_date',filterToDate.val)
                
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'day', 'all',pre_insp_page,'date')
            }else if(pre_insp_search_qry !== ''){
                let postData = new FormData()
                postData.append('registration', pre_insp_search_qry)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey,pre_insp_page,'search')
            }
            else {
                preinspection_list(tabType, pre_insp_page)
            }
        }
    }
    const ScrollUp = (val) => {
        const { top } = val

        // if (!searchFnOpen)
        if (top === 1 && !pre_insp_stoppagination && !pre_insp_loader && pre_insp_error === null) {
            console.log('SCROLL CALLED')
            if (filterKey !== 'all') {
                
                let postData = new FormData()
                postData.append('registration', filterKey)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey,pre_insp_page +1,'filter')
            } else if (clearFilterDate || invokeDate) {
                let postData = new FormData()
                postData.append('from_date', filterFromDate.val)
                postData.append('to_date',filterToDate.val)   
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'day', 'all',pre_insp_page +1,'date')
            }else if(pre_insp_search_qry !== ''){
                let postData = new FormData()
                postData.append('registration', pre_insp_search_qry)
                postData.append('vehicle_type', 'car')
                postData.append('tab_type', tabType)
                fetch_filter_car(tabType, null, null, postData, 'vehicle', filterKey,pre_insp_page +1,'search')
            }
            else {
                preinspection_list(tabType, pre_insp_page + 1)
            }
        }
    }

    return (
        <div className="pre-inspection-list-layout">
            <div className="pre-inspection-list-top-layout">
                <Link to="preinspection-create" style={{ textDecoration: 'none' }}>
                    <button className="pre-inspc-add-btn"> <AddBoxIcon style={{ marginRight: 5, fontSize: 22 }} /> Add New</button>
                </Link>
                <button className="pre-inspc-default-btn">Pre Inspection Check</button>
                <div className="sgr-mntnce-rprt-addNew2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '10px' }}>
                    <SortDropdown />
                </div>
                <div className="sgr-mntnce-rprt-search-layout">
                    <SearchTab
                        tabType={tabType} vType={tabType === 'pumps' ? 'utes' : 'car'} subType={'pre'}
                    />
                    <Search style={{ fontSize: 20 }} className="search-icon" />
                </div>
                <div className="sgr-mntnce-rprt-addNew2" style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }}>
                    <div style={{ padding: '0 20px', display: 'flex', alignItems: 'center', width: invokeDate ? '25rem' : 'auto' }}>
                        <div>Filter : </div>
                        {
                            invokeDate ?
                                <div style={{ display : 'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'90%' }}>
                                    <span style={{ marginLeft:'1%' }}>From :</span> 
                                    <DateTimeInput dateValue={filterFromDate.val} rangeDateMax={filterToDate.status ? moment(filterToDate.val).format('YYYY-MM-DD') : null} dateSetFn={FromDateFilterFn} />
                                    <span style={{ paddingLeft:'1%', borderLeft:'.2px solid grey' }}>
                                    To :
                                    </span>
                                    <DateTimeInput dateValue={filterToDate.val}  rangeDate={filterFromDate.status ? moment(filterFromDate.val).format('YYYY-MM-DD') : null} dateSetFn={ToDateFilterFn} />
                                </div>
                            :
                                <span style={{ padding: '0 20px', display: 'flex', alignItems: 'center', borderRadius: '30px' }}
                                    onClick={() => { setInvokeDate(true) }}>
                                    Select Date
                                </span>
                        }
                    </div>
                    {
                        clearFilterDate || invokeDate ?
                            <span style={{ padding: '0 20px', display: 'flex', alignItems: 'center', borderRadius: '30px' }}
                                onClick={() => { preinspection_list(tabType, 1); setClearFilterDate(false); setInvokeDate(false) }}>
                                Revert
                            </span>
                            : null
                    }
                </div>
                <button className="pre-inspc-default-btn"
                     onClick={() => preInspListCSVPrint(tabType,filterKey,pre_insp_search_qry,clearFilterDate || invokeDate ? filterFromDate.val : null, clearFilterDate || invokeDate ? filterToDate.val : null)}
                >
                    Export to Excel
                </button>
            </div>

            <div className="pre-inspc-list-main">
                <Scrollbars style={{ height: '78vh', width: '100%' }} onUpdate={ScrollUp}>

                    <table className="pre-inspc-table-layout" style={{ maxHeight: '80vh' }}>
                        <thead>
                            <th>SL NO</th>
                            <th>Date</th>
                            <th>Drivers Name</th>
                            <th>Vehicle Registration</th>
                            <th>Odometer</th>
                            <th>Hour Meter Start</th>
                            <th>Action</th>
                        </thead>
                        {
                            car_preInsp_list && car_preInsp_list.length === 0 ?
                                (!pre_insp_loader && pre_insp_error === null) &&
                                <tbody>
                                    <tr><td colSpan='7' >No Records Found</td></tr>
                                </tbody>
                                : null
                        }

                        <tbody>
                            {
                                car_preInsp_list.map((item, key) => {
                                    const { date_time, driver_name, registration, odometer, hour_meter_start } = item
                                    return (
                                        <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'transparent', height : '5.3vh' }}>
                                            <td>{key + 1}</td>
                                            <td>{moment(date_time).isValid() ? moment(date_time).format('DD-MM-YYYY') : "Date Not Provided"}</td>
                                            <td>{item.name ? item.name : driver_name}</td>
                                            <td>{registration}</td>
                                            <td>{odometer}</td>
                                            <td>{hour_meter_start}</td>
                                            <td>
                                                {
                                                    permissions.vehicle.edit_preinspection ? 
                                                <Link to="preinspection-edit" style={{ textDecoration: 'none', marginRight:'1%' }}>
                                                    <button style={{ paddingTop: '10px', paddingBottom: '10px' }} className="sgr-mntnce-rprt-addNew" 
                                                    onClick={() => openPreInspect(item)}>
                                                        Edit
                                                    </button>
                                                </Link>
                                                    :   null
                                                }
                                                
                                                <Link to="preinspection-detail" style={{ textDecoration: 'none' }}>
                                                    <button style={{ paddingTop: '10px', paddingBottom: '10px' }} className="sgr-mntnce-rprt-addNew" onClick={() => openPreInspect(item)}>View</button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            {(!pre_insp_loader && pre_insp_error === null) &&
                                [...Array(balance_row)].map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td></td>
                                        </tr>
                                    )
                                })

                            }
                        </tbody>

                        {pre_insp_loader && <tbody className='skeleton'>
                            {
                                [...Array(8)].map((_, key) => {
                                    return (
                                        <tr >
                                            <td></td><td></td><td></td><td></td><td></td><td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>}
                        {
                            pre_insp_error && <ErrorHandling error={pre_insp_error} fetch={fetch} />
                        }
                    </table>
                    {/* {
                        pre_insp_error && <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: '200px', justifyContent: 'center' }}>
                            <span style={{ textAlign: 'center' }}>{pre_insp_error.text}</span>
                            <button className='vechile-details-btn-add' onClick={fetch}>Retry</button>
                        </div>
                    } */}
                </Scrollbars>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const { all_car_list, filterKey, filtered_car_list, car_preInsp_list, pre_insp_loader, pre_insp_error, pre_insp_page, pre_insp_stoppagination,pre_insp_search_qry } = state.vehicle__car
    const {permissions} = state.userLogin

    return { all_car_list, filterKey, filtered_car_list, car_preInsp_list, pre_insp_loader, pre_insp_error, pre_insp_page, pre_insp_stoppagination,pre_insp_search_qry, permissions }
}


const mapDispatchtoProps = (dispatch) => {
    return {
        updateSelPreInsp: (obj) => dispatch(updateSelectedPreInspect(obj)),
        fetch_filter_car: (tabType, success, failed, data, type, id,page, fetchtype) => dispatch(fetch_filter_car_list(tabType, success, failed, data, type, id,page, fetchtype)),
        fetch_all_cars: (tabType) => dispatch(fetch_all_car_list(tabType)),
        preinspection_list: (tabType, page) => dispatch(preinspection_car_list(tabType, page))

    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(InspectionList)
