import React from 'react'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import { connect } from 'react-redux'
// import FolderApp from '../../../common/folder/folder_main'
// import { 
    //team_file_delete, team_file_upload, 
    // team_folders } from '../action'
// import { CircularProgress } from '@material-ui/core'
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import Swal from 'sweetalert2'
// import FolderCreateModal from './FolderCreateModal'
import { INTRANET_FILES_RENAME, INTRANET_FOLDER_EDIT, OHS_AND_S_FILE_DELETE, OHS_AND_S_FOLDER_DELETE, TEAM_FOLDER_CREATE, TEAM_GET_INNER_FOLDERS, TEAM_INNER_FILE_CREATE } from '../../../store/endpoint'
// import Api from '../../../store/api'
// import { cl_green, cl_red } from '../../../common/Colors'
import IntranetLayout from '../../../common/filesNFolder/FileFoldersLayout'

function Certificates({ selected_employee }) {

    // const [folderLoader, setFolderLoader] = useState(true)
    // const [folderResponse, setFolderResponse] = useState([])
    // const [folderError, setFolderError] = useState(false)
    // const [folderStatus, setFolderStatus] = useState('')
    // const [folderCreateModal, setFolderCreateModal] = useState(false)
    // const [modalType, setModalType] = useState(true)
    // const [folderName, setFolderName] = useState('')
    // const [selectedFolderId, setSelectedFolderId] = useState('');

    // useEffect(() => {
    //     team_folders(selected_employee.id, apiResponseCallBack)
    // }, [selected_employee.id])

    // const apiResponseCallBack = ({response, loader, error, status}) => {
    //     setFolderResponse(response)
    //     setFolderStatus(status)
    //     setFolderLoader(loader)
    //     setFolderError(error)
    //  }
    
    // const fileUploading = (FilesData, folder_id) => {
    //     let formBody = new FormData()
    //       let i=0;
    //       formBody.append('folder', folder_id)
    //       for(i=0; i<FilesData.length; i++) {
    //           formBody.append('attachments_list', FilesData[i], FilesData[i].name)
    //       }
    //       team_file_upload(selected_employee.id, formBody, apiRecall)
    //  }

    //  const deleteFile = (id) => {
    //      const success = () => {
    //         Swal.fire({
    //             position: 'top-end',
    //             icon: 'success',
    //             title: 'File Deleted successfull!',
    //             showConfirmButton: false,
    //             timer: 1500
    //         })
    //         apiRecall()
    //      }
    //      const failed = () => {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Something went wrong!'
    //         })
    //      }
    //      team_file_delete(id, success, failed)
    //  }

    //  const apiRecall = () => {
    //     team_folders(selected_employee.id, apiResponseCallBack)
    //  }

    //  const editClickBtn = (folder_name, folder_id) => {
    //     setFolderName(folder_name)
    //     setModalType(false)
    //     setSelectedFolderId(folder_id)
    //     setFolderCreateModal(true)
    //  }

    //  const updateFolderNameApiCall = () => {
    //      let data = new FormData()
    //      data.append('name', folderName)
    //      const success = () => {
    //          setFolderName('')
    //          setFolderCreateModal(false)
    //          apiRecall()
    //      }
 
    //      const failed = () => {
    //             //  setFolderName('')
    //             //  setModalType(true)
    //             //  setFolderCreateModal(true)
    //      }

    //      let base_url = `${TEAM_EDIT_FOLDER}${selectedFolderId}/`
    //      Api('patch-form', base_url, data, success, failed)
    //  }

    //  const deleteFolderApiCall = (folder_id) => {

    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You won't be able to revert this!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: cl_red,
    //         cancelButtonColor: cl_green,
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.value) {
    //             let base_url = `${TEAM_EDIT_FOLDER}${folder_id}/`
    //             Api('delete', base_url, '', apiRecall)
    //         }
    //     })

        
    // }

    // const FoldersWidget = () => {
    // if(folderLoader) {
    //     return (
    //         <div className="folder-spinner-layout">
    //             <CircularProgress />
    //         </div>
    //     )
    // } else if(folderError) {
    //     return (
    //         <div className="folder-spinner-layout">
    //             <p>{folderStatus}</p>
    //         </div>
    //     )
    // } else return (
    //     <FolderApp  
    //         data={folderResponse}
    //         updation={true}
    //         updationFunction={fileUploading}
    //         deletion={deleteFile}
            
    //         edit_folder_click={editClickBtn}
    //         selectedFolderId={selectedFolderId}
    //         setSelectedFolderId={setSelectedFolderId}
    //         delete_folder={deleteFolderApiCall}
    //     />
    // ) 
    // }


    // const createFolderApi = () => {
    //     console.log('creating')
    //     let data = new FormData()
    //     data.append('name', folderName)
    //     const success = () => {
    //         setFolderName('')
    //         setFolderCreateModal(false)
    //         apiRecall()
    //     }

    //     const failed = () => {
    //             setFolderName('')
    //             setModalType(true)
    //             setFolderCreateModal(true)
    //     }
    //     // data.append('parent_folder', folder_id)
    //     Api('post-form', TEAM_ADD_FOLDER, data, success, failed)
    // }
    
    return (
        <div className="team-certificate-layout" style={{ height:'45vh' }}>
            {/* <div className="certificate-header"> */}
                <Scrollbars style={{ height:'45vh' }}>
                <IntranetLayout 
                APIURL={`${TEAM_GET_INNER_FOLDERS}${selected_employee.id}/`} title={'Folders'}
                SELECTID={selected_employee.id}
                FILEEDIT={INTRANET_FILES_RENAME}
                FOLDEREDIT={INTRANET_FOLDER_EDIT} FILEADD={TEAM_INNER_FILE_CREATE} FOLDERCREATE={TEAM_FOLDER_CREATE} 
                FOLDERDELETE={OHS_AND_S_FOLDER_DELETE} FILEDELETE={OHS_AND_S_FILE_DELETE}
                />
                {/* <div className="certificate-head-layout">
                    <p className="certify-title">Folders</p>
                </div>
                {/* <div style={{ marginRight: 20 }} >
                    <button>Add New</button>
                </div> 
                <div 
                    onClick={() => {
                        // setModalType(true)
                        setFolderName('')
                        setModalType(true)
                        setFolderCreateModal(true)
                    }} 
                    className="add-folder-btn">
                    <AddCircleOutlineIcon style={{ marginRight: 7 }}/>
                    <p>Folder</p>
                </div> */}
                </Scrollbars>
            {/* </div> */}
            {/* <div className="certificate-list-layout">
                <Scrollbars style={{ height: '33vh' }}>
                    <FoldersWidget />
                </Scrollbars>
            </div> */}
{/* 
            <FolderCreateModal 
                modalStatus={folderCreateModal}
                modalAction={setFolderCreateModal}
                newFolder={modalType}
                folderName={folderName}
                folderNameAction={setFolderName}
                createAction={createFolderApi}
                updateAction={updateFolderNameApiCall}
                // folderId={zFolderId}
            /> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    const { selected_employee } = state.team
    return {selected_employee}
}

export default connect(mapStateToProps)(Certificates)

