import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import './styles.css'
import { connect } from 'react-redux';
import { PUSH_NOTIFICATION_DELETE } from '../store/endpoint';
import Api from '../store/api'
// import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
// import TodayRoundedIcon from '@material-ui/icons/TodayRounded';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function TransitionsModal({
    modalStatus, 
    modalAction, 
    user_logout,
    Name,
    setName,
    Username,
    setUsername,
    Email,
    setEmail,
    Password,
    setPassword,
    DpImage,
    setDpImage,
    TempDp,
    setTempDp,
    user_type,
    user,
    profile_update_api,
    fcm_token,
}) {

    const classes = useStyles();
    const [newName, setNewName] = useState(null);
    const [newUserName, setNewUserName] = useState(null);
    const [newEmail, setNewEmail] = useState(null);
    const [newPassword, setNewPAssword] = useState(null);
    const [newDp, setNewDp] = useState(null);
    let imgFile = null
    const handleClose = () => {
        modalAction(false)
    };

    const deleteFCMToken = () => {
      let data = new FormData()
      console.log(fcm_token)
      data.append('keys', fcm_token)

      const deleteCredentials = () => {
        localStorage.clear();
        sessionStorage.clear()
        window.location.reload()
      }
      let base_url = `${PUSH_NOTIFICATION_DELETE}${fcm_token}/`
      Api('delete', base_url, '', deleteCredentials, deleteCredentials)    
    }



  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={modalStatus}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className="profile-modal-main" style={{ overflowY:'auto' }}>
            <div className="profile-modal-head">
              
                {/* {console.log('USER_PROFILE_MODAL',user,DpImage)} */}
                <label htmlFor="file-input">
                  <div>
                    <img src={newDp===null ? TempDp : newDp} alt={newDp===null ? TempDp : newDp} />
                  </div>
                </label>
                <input id="file-input" style={{ display: 'none' }} type="file" multiple={false}
                    onChange={(image) => {
                        var image_file = image.target.files[0]
                        let extension = image_file.type
                        if(extension === 'image/jpeg' || extension === 'image/png') {
                            var image_blob = URL.createObjectURL(image_file)
                            setNewDp(image_blob)
                            imgFile = image_file
                        } else {
                            alert('File should be an Image. Only jpg and png files are accepted')
                        }
                        
                    }}
                />
              
             
              <div className="profiler-title-layout">
                <p className="profiler-title">{newName===null ? Name : newName}</p>
                <p className="profiler-designation-title" style={{ textTransform:'capitalize' }}>{user_type}</p>
              </div>
              

              {/* <div style={{ display: 'flex', flexDirection:'row', alignItems:'center', 
              justifyContent:'flex-end', float:'right', width:'100%' }}>
              <div className="profiler-timesheetModalBtn" onClick={()=>setTimeSheetmodal(true)}>
                <DateRangeRoundedIcon />
                <span>Timesheet</span>
              </div>
              <div className="profiler-timesheetModalBtn">
                <TodayRoundedIcon />
                <span>Leave Application</span>
              </div>
            </div> */}
            </div>

            <div className="profile-modal-body">
  
              <div className="profiler-text-input-layout">
                <p>Name</p>
                <input 
                  type='text' 
                  placeholder="Name" 
                  value={newName===null ? Name : newName} 
                  onChange={txt =>{ setNewName(txt.target.value); }}
                />
              </div>

              <div className="profiler-text-input-layout">
                <p>Username</p>
                <input 
                  type='text' 
                  placeholder="Username" 
                  value={newUserName===null ? Username : newUserName} 
                  onChange={txt => setNewUserName(txt.target.value)}
                />
              </div>

              <div className="profiler-text-input-layout">
                <p>Email</p>
                <input 
                  type='email' 
                  placeholder="Email" 
                  value={newEmail===null ? Email : newEmail} 
                  onChange={txt => setNewEmail(txt.target.value)}
                />
              </div>

              <div className="profiler-text-input-layout">
                <p>Password</p>
                <input 
                  type='password' 
                  placeholder="Password" 
                  value={newPassword===null ? Password : newPassword} 
                  onChange={txt => setNewPAssword(txt.target.value)}
                />
              </div>

             

              <div className="profiler-bottom-btn">
                <button onClick={() =>  { 
                    user_logout();
                    deleteFCMToken(); }} className="profiler-logout-btn">Logout</button>
                <button onClick={() =>{
                  if(newDp!==TempDp){
                    setTempDp(newDp)
                    setDpImage(imgFile)
                  }
                  setName(newName===null || newName==='' ? Name : newName);
                  setUsername(newUserName===null || newUserName==='' ? Username : newUserName);
                  setPassword(newPassword===null || newPassword==='' ? Password : newPassword);
                  setEmail(newEmail===null || newEmail==='' ? Email : newEmail)
                  profile_update_api()}} className="profiler-update-btn">Update</button>
              </div>
            </div>
            
          </div>
            
      </Modal>
  );
}

const mapStateToProps = (state) => {
  const { user, fcm_token } = state.userLogin
  return { user, fcm_token }
}


const mapDispatchToProps = (dispatch) => {
  return {
      user_logout: () => dispatch({ type: "SIGNOUT" }),
      clear_fcm: () => dispatch({ type: "FCM_TOKEN_SET", fcm: ""})
    }
}



 export default connect(mapStateToProps, mapDispatchToProps) (TransitionsModal)
 
