import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
// import { makeStyles } from '@material-ui/core/styles';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import { cl_blue } from '../../../../common/Colors';
// import { fetch_job_list_for_all, fetch_job_list, fetch_job_list_destruction, fetch_job_list_hills, fetch_job_list_pump } from '../../common/action';
// import { connect } from 'react-redux';
import './sales_tableStyle.css';


// const useStyles = makeStyles((theme) => ({
//     formControl: {
//       minWidth: 120,
//       maxHeight: 20,
//       marginLeft: 10,
//       marginTop: -8,
//     },
//     selectEmpty: {
//       marginTop: theme.spacing(2),
//     },
//     resize:{
//         fontSize: 14,
//         fontWeight: 600,
//         color: 'white'
//     }
//   }));

// => A SPECIFIC FILTER DEPENDING ON THE PAID STATUS WAS MOVED FROM THIS COMPONENT TO ANOTHER


function Navbar(
    //{page, fetch_jobs, fetch_jobs_waste, fetch_jobs_pump, job_list_filter, fetch_jobs_hills, fetch_jobs_destruction}
    ) {
    const { path } = useRouteMatch();
    let routing_path = path.split('/')
    const route_path = '/' + routing_path[1] + '/' + routing_path[2]

    // const SortDropdown = () => {
    //     const classes = useStyles();
    //     return (
    //         <FormControl className={classes.formControl}>
    //             <Select
    //                 disableUnderline
    //                 labelId="demo-simple-select-label"
    //                 id="demo-simple-select"
    //                 SelectDisplayProps={{ fontSize: 14 }}
    //                 value={job_list_filter}
    //                 className={classes.resize}
    //                 onChange={(val) => {
    //                     // console.log(page)
    //                     // if(page === 'waste') {
    //                     //     fetch_jobs_waste(1, val.target.value)
    //                     // }
    //                     // if(page === 'pump') {
    //                     //     fetch_jobs_pump(1, val.target.value)
    //                     // }
    //                     // if(page === 'hills') {
    //                     //     fetch_jobs_hills(1, val.target.value)
    //                     // }
    //                     // if(page === 'destruction') {
    //                     //     fetch_jobs_destruction(1, val.target.value)
    //                     // }
    //                     // if(page === 'all') {
    //                     //     fetch_jobs_destruction(1, val.target.value)
    //                     // }
    //                     fetch_jobs(1, page, val.target.value)
    //                 }}
    //             >
                
    //             <MenuItem value={'all'}>All</MenuItem>
    //             <MenuItem value={'paid'}>Paid</MenuItem>
    //             <MenuItem value={'un-paid'}>Not Paid</MenuItem>
                
    //             </Select>
    //         </FormControl>
    //     )
    // }

    
    return (
        <div className="navbar-layout">
            
            <Link 
                to={route_path}
                data-testid="sales-register" 
                className={
                    (path === route_path) ?
                    "navbar-button navbar-button-active" : "navbar-button"}>
                    Sales Register
            </Link>

            <Link 
                to={route_path+ '/quote-register'}
                data-testid="quote-register"
                className={route_path+ '/quote-register' === path ? "navbar-button navbar-button-active" : "navbar-button"}>
                    Quote Register
            </Link>
            
            
            <Link 
                to={route_path+ '/waste-description'}
                data-testid="waste-description" 
                className={route_path+ '/waste-description' === path ? "navbar-button navbar-button-active" : "navbar-button"}>
                    Description of waste
            </Link>

                
            <Link 
                to={route_path+ '/marketing'}
                data-testid="marketing" 
                className={route_path+ '/marketing' === path ? "navbar-button navbar-button-active" : "navbar-button"}>
                    Marketing
            </Link> 

                
            <Link 
                to={route_path+ '/power-point'}
                data-testid="power-point" 
                className={route_path+ '/power-point' === path ? "navbar-button navbar-button-active" : "navbar-button"}>
                    Power Point
            </Link>

            <Link 
                to={route_path+ '/pricing'}
                data-testid="pricing" 
                className={route_path+ '/pricing' === path ? "navbar-button navbar-button-active" : "navbar-button"}>
                    Pricing
            </Link> 

            <Link 
                to={route_path+ '/tenders'}
                data-testid="tenders" 
                className={route_path+ '/tenders' === path ? "navbar-button navbar-button-active" : "navbar-button"}>
                    Tenders
            </Link>


            {
                path === '/sales/waste' || path === '/sales/pump' || path === '/sales/hills' || path === '/sales/destruction' || path === '/sales/all' ? (
                    // <div style={{ backgroundColor: cl_blue, border: 0, color: 'white' }} className="navbar-button">
                    //     <SortDropdown />
                    // </div>
                    null
                ) : (
                    null
                    // <Link 
                    //     to={route_path} 
                    //     style={{ backgroundColor: 'grey', color: 'white' }}
                    //     className="navbar-button">
                    //         Back
                    // </Link>
                )
            }


            {
                route_path !== '/sales/all' && (
                    <Link 
                         to={route_path+ '/generate-quote'}
                         data-testid="generate-quote" 
                        className={route_path+ '/generate-quote' === path ?"navbar-button navbar-button-active" : "navbar-button"} 
                        style={{ gridColumnStart:'9' }}
                        //onClick={() => { setDevModal(true) }} 
                        >
                            Generate Quote
                    </Link>
                )
            }
            
        </div>
    )
}

// const mapStateToProps = (state) => {
//     const { job_list_filter } = state.sales
//     return { job_list_filter }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         fetch_jobs_waste: (page_no, filter) => dispatch(fetch_job_list(page_no, filter)),
//         fetch_jobs_pump: (page_no, filter) => dispatch(fetch_job_list_pump(page_no, filter)),
//         fetch_jobs_hills: (page_no, filter) => dispatch(fetch_job_list_hills(page_no, filter)),
//         fetch_jobs_destruction: (page_no, filter) => dispatch(fetch_job_list_destruction(page_no, filter)),
//         fetch_jobs: (page_no, tab_type, filter) => dispatch(fetch_job_list_for_all(page_no, tab_type, filter))
//       }
// }


export default 
//connect(mapStateToProps, mapDispatchToProps) (
    Navbar
    //)
