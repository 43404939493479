import React, { useEffect, useState } from 'react'
import Clock from 'react-live-clock';
import { cl_blue } from '../../../common/Colors';
import Api from '../../../store/api'
import { HOST } from '../../../store/endpoint';

function Weather() {

    const [weatherData, setWeatherData] = useState([])
    

    useEffect(() => {
        console.log('sahad')
        apiCall()
    }, [])

    const apiCall = () => {
        let api_url = HOST + 'api/v1/home/view/weather_report/'
        Api('get', api_url).then(resp => {
            console.log('resp11', resp)
            setWeatherData(resp.list)  
        })
    }

    
    
    let date = new Date()
    let dates = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let week = dates[date.getDay()]

    return (
        <div style={{ backgroundColor: 'white' }} className="home-box-component">
            <div className="home-box-header">
                <p>Weather</p>
                {/* <p style={{ color: cl_blue, fontSize: 12, cursor: 'pointer' }}>See All</p> */}
            </div>
            <div style={{ padding: 10 }}>
                {/* <div>
                    <img style={{ height: 100 }} src={weather_icon} alt="" />
                    <p style={{ fontSize: 25 }}>{weather_main_title}</p>
                    <p style={{ fontSize: 12 }}>{weather_main_description}</p>
                    <p style={{ fontSize: 20, fontWeight: 'bold' }}>{weather_temp}°C</p>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', fontSize: 16, fontWeight: 'bold' }}>
                <Clock style={{ color: cl_blue }} format={'HH:mm:ss'} ticking={true} timezone={'Australia/ACT'} />
                    <p>{week}</p>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'space-between',  paddingTop: 40, paddingBottom: 40 }}>

                {
                   weatherData && weatherData.length > 0 && weatherData.map((item, key) => {
                
                       let weather_base = 'http://openweathermap.org/img/wn/'
                        let weather_icon = weather_base + '02d.png'
                        // let weather_main_title = ''
                        //let weather_main_description = ''
                        let weather_temp = 0.0

                        if(item && item.weather.length > 0) {
                            weather_icon = weather_base + item.weather[0].icon + '.png'
                            // weather_main_title = item.weather[0].main
                           // weather_main_description = item.weather[0].description 
                           // weather_temp = (item.main.temp + 273.15).toFixed(1)
                           weather_temp = (item.main.temp).toFixed(1)
                        }   
                        let date_a = new Date()
                        let dates_a = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                        let date_index = date_a.getDay()+key
                        if(date_index > 6) {
                            date_index = date_index - 6
                        }
                        let week_a = dates_a[date_index]
                        return (
                            <div key={key} className="sales-weather-item-box">
                                <p>{week_a}</p>
                                <img style={{ height: 50 }} src={weather_icon} alt="" />
                                <p>{weather_temp}°C</p>
                            </div>
                        )   
                    })
                }

                </div>

                {
                    weatherData.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 20 }}>
                            <div style={{ fontSize: '1.2rem' }}>
                                <p style={{ fontWeight: 'bold' }}>{weatherData[0].weather[0].main}</p>
                                <p style={{ fontWeight: 'bold' }}>{weatherData[0].weather[0].description}</p>
                            </div>
                            <p style={{ fontSize: '3rem' }}>{weatherData[0].main.temp}°C</p>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', fontSize: '1rem', fontWeight: 'bold' }}>
                                <Clock style={{ color: cl_blue }} format={'HH:mm:ss'} ticking={true} timezone={'Australia/ACT'} />
                                <p>{week}</p>
                                <p><Clock style={{ color: cl_blue }} format={'DD-MMM-YYYY'} timezone={'Australia/ACT'} /></p>
                            </div>
                        </div>
                    )
                }
                
                
                
                
            </div>
            

        </div> 
    )

}

export default Weather
