import React from 'react'
import { connect } from 'react-redux';
import { EnviroBrandLogo, EnviroDestructionLogo, EnviroHillsLogo, EnviroPumpLogo, EnviroWasteLogo } from '../../../../common/Images'
import { useLocation, useHistory } from 'react-router-dom'
import './styles.css'

// function Header({ page }) {
//     return (
//         <div className="team-header">
//             <div className="team-brand-layout">
//                 <img className="team-brand-img" src={EnviroBrandLogo} alt='enviro logo' />
//             </div>
//             {
//                 page !== 'main' && (
//                     <Link to="/client" className="team-back-btn-layout">
//                         <div className="header-back-btn">
//                             <p className="p-0 m-0">Back</p>
//                         </div>
//                     </Link>
//                 )
//             }
//         </div>
//     )
// }

// export default Header

function Header({ permissions, page, tabType, setTabType }) {
    const { pathname } = useLocation()
    const history = useHistory()
    let navHeadStatus = true;
    let chk_rout_o = pathname.split('/')
    
    let checking_value = '/' + chk_rout_o[1]  
    let chk_route = pathname.split(checking_value)
    if(chk_route[0] === '' && chk_route[1]) {
        navHeadStatus = false
    }

    
    // const EnviroWaste = () => {

    //     if( permissions && permissions.site.waste ) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
            
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'waste/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
            
    //         return (
    //             <div className={tabType === 'waste' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('waste') }
    //             >
    //                 <p>Enviro Waste</p>
    //             </div>
    //         )
    //     } else return null
        
    // }

    // const EnviroPump = () => {
    //     if(permissions && permissions.site.pumps) {
    //         let routing_path = pathname.split('/')
    // let route_path = '/'
    
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'pumps/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <div className={tabType === 'pumps' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('pumps') }
    //             >
    //                 <p>Enviro Pumps</p>
    //             </div>
    //         )
    //     } else return null
        
    // }


    // const EnviroDestruction = () => {
    //     if(permissions && permissions.site.destruction) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
            
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'destruction/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <div className={tabType === 'destruction' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('destruction') }
    //             >
    //                 <p>Enviro Destruction</p>
    //             </div>
    //         )
    //     } else return null

    //     // return (
    //     //     <div className="header-button-top disabled-btn">
    //     //         <p>Enviro Destruction</p>
    //     //     </div>
    //     // )
        
    // }

    // const EnviroHills = () => {
    //     if(permissions && permissions.site.hills) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
            
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'hills/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <div className={tabType === 'hills' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('hills') }
    //             >
    //                 <p>Enviro Hills</p>
    //             </div>
    //         )
    //     } else return null
        
    // }

    // const EnviroAll = () => {
    //     if(permissions && permissions.site.waste && permissions.site.pumps && permissions.site.hills && permissions.site.destruction) {
    //     //     let routing_path = pathname.split('/')
    //     //     let route_path = '/'
    //     //     routing_path.map((item, key) => {
    //     //         if(item) {
    //     //             if(key === 2) {
    //     //                 route_path = route_path + 'all/'
    //     //             } else {
    //     //                 route_path = route_path + routing_path[key] + '/'
    //     //             }
                    
    //     //         }
    //     //         return null
    //     //     })
    //     //     return (
    //     //         <div className={tabType === 'all' ? "header-button-top header-button-top-active" : "header-button-top"}
    //     //    onClick={()=> setTabType('') }
    //     //>
    //     //             <p>Enviro All</p>
    //     //         </div>
    //     //     )
    //     // } else return null

    //     let routing_path = pathname.split('/')
    //     let route_path = '/'
        
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'all/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <div className={tabType === 'all' ? "header-button-top header-button-top-active" : "header-button-top"}
    //                 onClick={()=> setTabType('all') }
    //             >
    //                 <p>Enviro All</p>
    //             </div>
    //         )
    //     }
    //     else    return null
    // }

    return (
        <div className="common-header">
            <div className="common-brand-layout">
                <img className="team-brand-img" src={
                    tabType === 'waste' ?
                    EnviroWasteLogo
                    :
                    tabType === 'pumps' ?
                    EnviroPumpLogo
                    :
                    tabType === 'hills' ?
                    EnviroHillsLogo
                    :
                    tabType === 'destruction' ?
                    EnviroDestructionLogo
                    :
                    EnviroBrandLogo
                } style={{ height:'100%' }}  alt='enviro logo' />
            </div>
            
            {
                navHeadStatus ? (
                    <div className="header-button-layout">
                        {/* <EnviroWaste /> */}
                        {/* <EnviroHills />
                        <EnviroPump />
                        <EnviroDestruction />
                        <EnviroAll /> */}
                    </div>
                ) : (
                    <div onClick={()=> history.goBack() } className="header-button-layout">
                        <div className="header-button-top">
                            <p>Back</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user, permissions } = state.userLogin
    return { user, permissions }
}

export default connect(mapStateToProps) (Header)
