import React, { useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Sidebar from '../../../../common/sidebar'
import Navbar from '../common/VehicleNavBar'
import Images from '../common/VehicleImages'
import List from './ForkliftListTable'
import AppHeader from '../common/Header'
import { fetch_forklift_list, __vehicle_modal, __vehicle_img_modal, __select_vehicle, __edit_vehicle, __create_vehicle, __edit_vehicle_multi_images } from './common/action'
import { connect } from 'react-redux'

import Maintanace from './maintanace'
import FuelExpense from './fuel'
import PreInspectionCheck from './preInspection/inspection_list'
import PreInspectionCreate from './preInspection/inspection_create'
import PreInspectionView from './preInspection/inspection_view'
import PreInspectionEdit from './preInspection/edit_preinspection'

function App({ 
    fetch_forklifts, 
    vehicle_modal_action, 
    vehicle_modal, 
    vehicle_img_modal_action, 
    vehicle_img_modal, 
    select_vehicle, 
    selected_forklift, 
    edit_vehicle, 
    edit_vehicle_image,
    // forklift_page,
    create_vehicle,
    tabType,
    setTabType,
    permissions
}) {

    useEffect(() => {
        fetch_forklifts(tabType, 1)
        return () => fetch_forklifts.remove
    }, [fetch_forklifts, tabType])

    const [searchFnOpen, setSearchFnOpen] = useState(false)

    const { path } = useRouteMatch()
    const ForkliftMain = () => {

        return (
            <>
                <div className="flex-row">
                    <Navbar 
                        page={ tabType==='hills' ? 'machinery' : 'forklift' }
                        modal_status={vehicle_modal}
                        modal_action={vehicle_modal_action}
                        img_modal_action={vehicle_img_modal_action}
                            img_modal_status={vehicle_img_modal}
                        select_vehicle={select_vehicle}
                        selected_vehicle={selected_forklift}
                        edit_vehicle={edit_vehicle}
                        edit_vehicle_image={edit_vehicle_image}
                        create_vehicle={create_vehicle}
                        // response_page={forklift_page}
                        tabType={tabType} 
                        permAdd={permissions.vehicle.add}
                    />
                    <Images page={ tabType==='hills' ? 'machinery' : 'fork-lift' } tabType={tabType} setSearchDataFx={setSearchFnOpen}
                    searchDataFx={searchFnOpen} />
                </div>
                <List 
                    select_vehicle={select_vehicle} 
                    modal_action={vehicle_modal_action}
                    img_modal_action={vehicle_img_modal_action}
                    fetch_forklifts={fetch_forklifts}
                    tabType={tabType}
                    perms={permissions}
                    searchDataFx={searchFnOpen}
                />
            </>
        )
    }

    return (
        <div className="App">
        <Sidebar />
        <div className="main-layout">
            <AppHeader page={ tabType==='hills' ? 'machinery' : 'forklift' }  tabType={tabType} setTabType={setTabType}  />
            <Switch>
                <Route path={ path + '/maintanace'}><Maintanace tabType={tabType} perms={permissions} /></Route>
                <Route path={ path + '/fuel-exp'}><FuelExpense tabType={tabType} perms={permissions} /></Route>
                <Route path={ path + '/preinspection'}><PreInspectionCheck tabType={tabType} perms={permissions} /></Route>
                <Route path={ path + '/preinspection-create'}><PreInspectionCreate  tabType={tabType}  /></Route>
                <Route path={ path + '/preinspection-detail'}><PreInspectionView  tabType={tabType}  /></Route>
                    <Route path={path + '/preinspection-edit'}><PreInspectionEdit tabType={tabType} /></Route>               
                <Route path={ path } component={ForkliftMain} />
            </Switch>
        </div>
        </div>
    )
    
}

const mapStateToProps = (state) => {
    const { vehicle_modal,vehicle_img_modal, selected_forklift } = state.vehicle__forklift
    const {permissions} = state.userLogin

    return { vehicle_modal,vehicle_img_modal, selected_forklift, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_forklifts: (tabType, page, callBk=null) => dispatch(fetch_forklift_list(tabType, page, callBk)),
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        vehicle_img_modal_action: (status) => dispatch(__vehicle_img_modal(status)),
        select_vehicle: (forklift) => dispatch(__select_vehicle(forklift)),
        edit_vehicle: (tabType, v_id, data, success, failed) => dispatch(__edit_vehicle(tabType, v_id, data, success, failed)),
        edit_vehicle_image: (tabType, v_id, content, page_no) => dispatch(__edit_vehicle_multi_images(tabType, v_id, content, page_no)),
        create_vehicle: (tabType, data, success, failed) => dispatch(__create_vehicle(tabType, data, success, failed)),
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (App)

