import { useState } from 'react';

const useAlljobcard = ({ jobObj, preSelect, modalType }) => {


    const [jobObjCont, setJobObjCont]       = useState({
        jobCardName : jobObj.job_card_name,
        compName    : jobObj.company_name,
        compAddr    : modalType==='add' ? preSelect===null ? '' :
        preSelect.company_address : jobObj.company_address,
        suburb      : modalType==='add' ? preSelect===null ? '' :
        preSelect.site_suburb : jobObj.site_suburb,
        postal      : modalType==='add' ? preSelect===null ? '' :
        preSelect.site_post_code : jobObj.site_post_code,
        siteAddr    : modalType==='add' ? preSelect===null ? '' :
        preSelect.site_address : jobObj.site_address,
        buildName   : modalType==='add' ? preSelect===null ? '' :
        preSelect.building : jobObj.building,
        contactName : modalType==='add' ? preSelect===null ? '' :
        preSelect.site_contact_person : jobObj.site_contact_person,
        contactNo   : modalType==='add' ? preSelect===null ? '' :
        preSelect.company_contact_number : jobObj.company_contact_number,
        contactEmail : modalType==='add' ? preSelect===null ? '' :
        preSelect.company_email : jobObj.company_email,
        payment     : modalType!=='add' ? jobObj.payment_details : preSelect===null ? 'cod' : 
        preSelect.payment_type_str==='CREDIT CARD' ? 'credit_card' : preSelect.payment_type_str==='ACCOUNT' ? 'account' : 'cod'
    })

    return [jobObjCont, setJobObjCont]

}

export default useAlljobcard;
