import React, { useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Navbar from '../common/VehicleNavBar'
import Images from '../common/VehicleImages'
import List from './TruckListTable'
import Maintance from './maintanace'
import FuelExpense from './fuel'
import PreInspectionCheck from './preInspection/inspection_list'
import PreInspectionCreate from './preInspection/inspection_create'
import PreInspectionView from './preInspection/inspection_view'
import PreInspectionEdit from './preInspection/edit_preinspection'
import Fleet from './Fleet'
import AppHeader from '../common/Header'
import Sidebar from '../../../../common/sidebar'
import { fetch_truck_list, __vehicle_modal, __vehicle_img_modal, __select_vehicle, __edit_vehicle, __create_vehicle, __edit_vehicle_multi_images } from './common/action'
import { connect } from 'react-redux'


function App({
    vehicle_modal_action,
    vehicle_modal,
    vehicle_img_modal_action,
    vehicle_img_modal,
    select_vehicle,
    selected_truck,
    edit_vehicle,
    edit_vehicle_image,
    create_vehicle,
    tabType,
    setTabType,
    permissions,

    fetch_trucks
}) {

    const { path } = useRouteMatch()

    useEffect(() => {
        fetch_trucks(tabType, 1)
    }, [fetch_trucks, tabType])

    const [searchFnOpen, setSearchFnOpen] = useState(false)

    const TruckMain = () => {
        return (
            <>
                <div className="flex-row">
                    <Navbar
                        page='truck'
                        modal_status={vehicle_modal}
                        modal_action={vehicle_modal_action}
                        img_modal_action={vehicle_img_modal_action}
                        img_modal_status={vehicle_img_modal}
                        select_vehicle={select_vehicle}
                        selected_vehicle={selected_truck}
                        edit_vehicle={edit_vehicle}
                        edit_vehicle_image={edit_vehicle_image}
                        create_vehicle={create_vehicle}
                        // response_page={truck_page}
                        tabType={tabType}
                        permAdd={permissions.vehicle.add}
                    />
                    <Images page='truck' tabType={tabType} setSearchDataFx={setSearchFnOpen}
                        searchDataFx={searchFnOpen} />
                </div>
                <List
                    select_vehicle={select_vehicle}
                    modal_action={vehicle_modal_action}
                    img_modal_action={vehicle_img_modal_action}
                    tabType={tabType}
                    perms={permissions}
                    searchFnOpen={searchFnOpen}
                />
            </>
        )
    }

    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <AppHeader page="truck" tabType={tabType} setTabType={setTabType} />
                <Switch>
                    <Route path={path + '/maintanace'}><Maintance tabType={tabType} perms={permissions} /></Route>
                    <Route path={path + '/fuel-exp'}><FuelExpense tabType={tabType} perms={permissions} /></Route>
                    <Route path={path + '/preinspection'}><PreInspectionCheck tabType={tabType} perms={permissions} /></Route>
                    <Route path={path + '/preinspection-create'}><PreInspectionCreate tabType={tabType} /></Route>
                    <Route path={path + '/preinspection-detail'}><PreInspectionView tabType={tabType} /></Route>
                    <Route path={path + '/preinspection-edit'}><PreInspectionEdit tabType={tabType} /></Route>
                    <Route path={path + '/fleet'} component={Fleet} />
                    <Route path={path} component={TruckMain} />
                </Switch>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    const { vehicle_modal, vehicle_img_modal, selected_truck } = state.vehicle__truck
    const { permissions } = state.userLogin

    return { vehicle_modal, vehicle_img_modal, selected_truck, permissions }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_trucks: (tabType, page) => dispatch(fetch_truck_list(tabType, page)),
        vehicle_modal_action: (status) => dispatch(__vehicle_modal(status)),
        vehicle_img_modal_action: (status) => dispatch(__vehicle_img_modal(status)),
        select_vehicle: (truck) => dispatch(__select_vehicle(truck)),
        edit_vehicle: (tab, v_id, data, success, failed) => dispatch(__edit_vehicle(tab, v_id, data, success, failed)),
        edit_vehicle_image: (v_id, content, page_no) => dispatch(__edit_vehicle_multi_images(v_id, content, page_no)),
        create_vehicle: (tab, data, success, failed) => dispatch(__create_vehicle(tab, data, success, failed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
