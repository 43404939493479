import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux'
import { useParams } from 'react-router-dom'
import TableLayout from './table_layout'
import { HOST_WITHOUT_SLASH, SCHEDULE_QUOTE_INFOS } from '../../../../store/endpoint';
import Api from '../../../../store/api';
import { Link, useRouteMatch } from 'react-router-dom'
import { fetch_common_quote_registers_list, fetch_common_sale_performance, sales_perform_view_common, takeSalesPerformPrint } from '../../common/action';
import moment from 'moment';
import QuoteHistoryModalView from '../../../scheduling/component/quoteHistoryModal'
import QuoteModal from '../../../../common/CommonQuoteDetailModal'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CircularProgress } from '@material-ui/core';
import NoDatasScreen from '../../../../common/no_datas';
import DateTimeInput from '../../../../common/DateTimeInput';
import Swal from 'sweetalert2'


function Sales_performance_detail (
    {sale_performance, fetch_sales_performance, sale_performance_detail, sale_perform_page, sale_perf_stop_pagination, sale_perform_detail_date_filter,
         sale_perform_filter, fetch_sales_performDetails, tabType, fetch_quotes_list, sale_performance_detail_loader, sale_prfrm_month, sale_prfrm_year}) {

    let { urlId } = useParams()
    let selSale = sale_performance.filter(item => item.id === parseInt(urlId))[0]

    const [pageLoader, setPageLoader] = useState(false);
    const [quoteStatus, setQuoteStatus] = useState('all')
    const [quoteSpinner, setQuoteSpinner] = useState(null)
    const [quoteModal, setquoteModal] = useState(false)
    const [quoteInfos, setQuoteInfos] = useState({job: null, quote: null, schedul: null})

    const [QuoteHistoryModal, setQuoteHistoryModal] = useState(false)
    const [quoteHistoryInfos, setQuoteHistoryInfos] = useState([])
    const [jobVal, setJobVal] = useState(null)
    const [parentQuoteStatus, setParentQuoteStatus] = useState(null)
    const [parentQuoteId, setParentQuoteId] = useState(null)

    const [dateVal, setDateVal] = useState(moment(`${sale_prfrm_month}-${sale_prfrm_year}`,'M-YYYY').diff(moment(),'months')===0 ? new Date() :
    moment(`${sale_prfrm_month}-${sale_prfrm_year}`,'M-YYYY').toDate())
    const [openDate, setOpenDate] = useState(false)
    const [reportLoad, setReportLoad] = useState(false)
    
    const dispatch = useDispatch()
    
    useEffect(() => {
        // let current_date = new Date()
        fetch_sales_performance(openDate ? moment(dateVal).format('YYYY') : sale_prfrm_year, openDate ? moment(dateVal).format('MM') : sale_prfrm_month, tabType)
        let dateFilter = `&&month=${openDate ? moment(dateVal).format('MM') : sale_prfrm_month}&&year=${openDate ? moment(dateVal).format('YYYY') : sale_prfrm_year}`

        fetch_sales_performDetails(urlId,1, quoteStatus, tabType, dateFilter)
        return () => fetch_sales_performDetails.remove
    }, [ fetch_sales_performance, fetch_sales_performDetails, urlId, tabType, quoteStatus, dateVal, openDate, sale_prfrm_month, sale_prfrm_year ])


    const useStyles = makeStyles((theme) => ({
        formControl: {
            minWidth: 120,
            maxHeight: 20,
            marginLeft: 10,
            marginTop: 0,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        resize: {
            fontSize: 14,
            fontWeight: 600,
            color: 'white'
        }
    }));


    
    const { path } = useRouteMatch();
    let routing_path = path.split('/')
    const route_path = '/' + routing_path[1] + '/' + routing_path[2]
    
    const nextPageFx = () => {
        fetch_sales_performDetails(urlId, sale_perform_page + 1, sale_perform_filter, tabType, sale_perform_detail_date_filter)
    }

    const prevPageFx = () => {
        fetch_sales_performDetails(urlId, sale_perform_page - 1, sale_perform_filter, tabType, sale_perform_detail_date_filter)
    }

    const filterSalePerform = (val) =>{
        
        fetch_sales_performDetails(urlId, 1, val, tabType, sale_perform_detail_date_filter, setPageLoader)
        setQuoteStatus(val)
    } 

    const filterSalePerfByMonthYr = (e) =>{
        let dateFilter = `&&month=${moment(e).format('MM')}&&year=${moment(e).format('YYYY')}`
        fetch_sales_performDetails(urlId, 1, sale_perform_filter, tabType, dateFilter, setPageLoader)
        console.log(dateVal,e,moment(dateVal).format('YYYY/MM'),moment(e).format('MM/YYYY'))
    }


    const fetchQuoteInfos = (id, index, jobVal, parentStatus, parentQuoteId) => {
        setQuoteSpinner(id)  
        
        const succ = (res) =>{
            console.log(res.data)
            if(res.data){
            setQuoteInfos(res.data)
            setquoteModal(true)
            setQuoteSpinner(null)}
            else
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            setJobVal(jobVal)
            setParentQuoteStatus(parentStatus)
            setParentQuoteId(parentQuoteId)
        }

        const failed = (err) =>{
            setQuoteSpinner(null)
            
                Swal.fire({
                   icon: 'error',
                   title: 'Oops...',
                   text: err && err.app_data ? err.app_data : 'Something went wrong!'
               })
        }
        
        Api('get', `${SCHEDULE_QUOTE_INFOS}${id}/`,null, succ, failed)
    }

    const SortDropdown = () => {
        const classes = useStyles();

        return (
            <FormControl className={classes.formControl}>
                <Select
                    disableUnderline
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    SelectDisplayProps={{ fontSize: 14, color: 'black' }}
                    value={quoteStatus}
                    className={classes.resize}
                    style={{ color: quoteStatus==='all' ? 'black' : 'white', backgroundColor: quoteStatus==='accepted' ? '#0F99D0' : 
                        quoteStatus==='pending' ? '#FF8A23' :  quoteStatus==='rejected' ? '#FF0000' : 'whitesmoke', padding:'.5rem', borderRadius:'20px',
                        border:'1px solid gray' }}

                    onChange={(val) => {
                        filterSalePerform(val.target.value)
                    }}
                >
                    <MenuItem value={'all'}>View All</MenuItem>
                    <MenuItem style={{ color:'white', backgroundColor:'#0F99D0' }} value={'accepted'}>Quote Won</MenuItem>
                    <MenuItem style={{ color:'white', backgroundColor:'#FF8A23' }} value={'pending'}>Quote Pending</MenuItem>
                    <MenuItem style={{ color:'white', backgroundColor:'#FF0000' }} value={'rejected'}>Quote Lost</MenuItem>

                </Select>
            </FormControl>
        )
    }

    const setRpt = () => { setReportLoad(false) }

    
    return (
        <div style={{ height:'80vh', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start' }}>
            
            <div className="salesPerformTopDiv" >
                <div className="sales-exce-card">
                    <div className="sales-exce-card-left">
                        <img className="sales-exce-img" src={HOST_WITHOUT_SLASH+selSale.profile} alt="" />
                        <p>{selSale.name}</p>
                    </div>
                    <div className="sales-exce-card-right">
                        <p>Total Quote Made {selSale.total}</p>
                        <p style={{ color: '#0F99D0' }}>Total Quote Won {selSale.won}</p>
                        <p style={{ color: '#FF8A23' }}>Total Quote Pending {selSale.pending}</p>
                        <p style={{ color: '#FF0000' }}>Total Quote Lost {selSale.lost}</p>
                    </div>
                </div>
                <div className="salesPerformTopDivRight">
                    <Link 
                        to={route_path} 
                        onClick={()=>{ dispatch({ type:'RELOAD_SALES_PERFM_DETAIL' })  }}
                        style={{ backgroundColor: 'grey', color: 'white' }}
                        className="navbar-button">
                        Back
                    </Link>
                    <div className=''  style={{ marginBottom:'1%' }}>
                    <div className="salesPerformTopDivRightDown" style={{ marginBottom:'1%' }}>
                        <div className='salesRptBtn' onClick={()=>{
                            setReportLoad(true)
                            takeSalesPerformPrint(tabType, urlId, quoteStatus, moment(dateVal).format('YYYY'), moment(dateVal).format('MM'), setRpt, setRpt) 
                        }} 
                            >
                            { reportLoad ? <CircularProgress size={15} color={'white'} /> : 'Export Report'}
                        </div>
                        <div className='dateSalesFn'>
                            {
                                openDate ?
                                <DateTimeInput 
                                dateValue={dateVal}
                                dateSetFn={setDateVal}
                                pickerOrientation={'landscape'}
                                acceptFunc={filterSalePerfByMonthYr}
                                viewOnly={['year','month']}
                                />
                                : <span onClick={()=> setOpenDate(true) }>Filter by Month and Year</span>
                            }
                            
                        </div>
                        <SortDropdown />
                        {/* <div className="navbar-button" onClick={ () => filterSalePerform('all')}>View All</div>
                        <div className="navbar-button"
                         style={{ color: quoteStatus==='accepted' ? 'white' : '#0F99D0',
                             fontSize: '16px', backgroundColor:  quoteStatus==='accepted' ? '#0F99D0' : '' }} onClick={() => filterSalePerform('accepted')} >Quote Won</div>
                        <div className="navbar-button"
                         style={{ color: quoteStatus==='pending' ? 'white' : '#FF8A23',
                             fontSize: '16px', backgroundColor:  quoteStatus==='pending' ? '#FF8A23' : '' }} onClick={() => filterSalePerform('pending')}>Quote Pending</div>
                        <div className="navbar-button"
                         style={{ color: quoteStatus==='rejected' ? 'white' : '#FF0000',
                             fontSize: '16px', backgroundColor:  quoteStatus==='rejected' ? '#FF0000' : '' }} onClick={() => filterSalePerform('rejected')}>Quote Lost</div> */}
                    </div>    
                    </div>
                    {/* <div className="salesPerformTopDivRightDown" style={{ marginBottom:'1%' }}>
                        <SortDropdown />
                        {/* <div className="navbar-button" onClick={ () => filterSalePerform('all')}>View All</div>
                        <div className="navbar-button"
                         style={{ color: quoteStatus==='accepted' ? 'white' : '#0F99D0',
                             fontSize: '16px', backgroundColor:  quoteStatus==='accepted' ? '#0F99D0' : '' }} onClick={() => filterSalePerform('accepted')} >Quote Won</div>
                        <div className="navbar-button"
                         style={{ color: quoteStatus==='pending' ? 'white' : '#FF8A23',
                             fontSize: '16px', backgroundColor:  quoteStatus==='pending' ? '#FF8A23' : '' }} onClick={() => filterSalePerform('pending')}>Quote Pending</div>
                        <div className="navbar-button"
                         style={{ color: quoteStatus==='rejected' ? 'white' : '#FF0000',
                             fontSize: '16px', backgroundColor:  quoteStatus==='rejected' ? '#FF0000' : '' }} onClick={() => filterSalePerform('rejected')}>Quote Lost</div> */}
                    {/*</div> */}
                </div>
                
            </div>
            <div style={{ width:'90%', padding:'0 50px' }}>
            <TableLayout
                row_count={typeof sale_performance_detail!=='undefined' ? sale_performance_detail.length : 0} 
                page={sale_perform_page} 
                nextPage={nextPageFx} 
                prevPage={prevPageFx}
                tableLoader={sale_performance_detail_loader}
                tableStopPaging={sale_perf_stop_pagination}
            >
                <table className="sales-table"  style={{ position: 'relative', height: 'auto' }}>
                    <thead>
                        <tr className="table-head">
                            <td>Quote</td>
                            <td>Won / Reject Date</td>
                            
                            <td>Client Id</td>
                            {/* <td>Company Name</td> */}
                            <td>Client Name</td>
                            <td>Client Email</td>

                            {/* <td>Client Type</td> */}
                            {   tabType==='all' ?
                            <td>Type</td>
                            :   null}
                            {/* <td>Sales Person</td> */}
                            <td>Sales Team Review</td>
                            {/* <td>Invoice Amt</td> */}


                            {/* <td>Schedule Id</td>
                            <td>Scheduled Job Status</td> */}

                            <td>Paid Status</td>
                            <td>Detail</td>
                        </tr>
                    </thead>
                    {
                        pageLoader || sale_performance_detail_loader?
                        <tbody className="loadingTbody"
                            
                        >
                            {
                                [...Array(8)].map((_,k)=>{
                                    return(
                                        <tr key={k}>
                                            {
                                            [...Array(14)].map((_,ky)=>{
                                                return(<td key={ky}></td>)
                                            })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        :
                        typeof sale_performance_detail!=='undefined' && sale_performance_detail.length!==0 && !sale_performance_detail_loader?
                        <tbody>
                        {
                        
                            sale_performance_detail.map((item, key) => {
                                
                                // let statusColr = item.status==="accepted" ? '#0F99D0'
                                // : item.status==="pending" ? '#FF8A23' : '#FF0000'


                                let statusColr = item.status==="accepted" ? '#a3c2cf'
                                : item.status==="pending" ? '#f5c295' : '#faacac'

                                return (
                        <tr key={key}>
                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px' }}>{item.id}</td> */}
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px' }}>{item.job_card_code}</td>
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{ item.won_reject_date==="" ? "-" : moment(item.won_reject_date).format('DD-MM-YYYY')}</td>
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.client}</td>
                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.company_name}</td> */}
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.customer}</td>
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.client_email}</td>
                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.client_type}</td> */}
                            {   tabType==='all' ?
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.tab_type}</td>
                            :   null 
                            }

                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.sale_person}</td> */}
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.sales_tem_review ? 'Approved' : 'Pending'}</td>
                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.invoice_amt}</td> */}

                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.schedule_id ? item.schedule_id : '-'}</td> */}
                            {/* <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.schedule_id ? item.schedule_status : '-'}</td> */}
                            <td style={{ backgroundColor: `${statusColr}`, fontSize:'14px'  }}>{item.paid_status}</td>


                            <td>
                                {
                                    quoteSpinner===item.id ?
                                <CircularProgress size={10} />
                                :
                                <div onClick={() => fetchQuoteInfos(item.id, key, item.tab_type==='pumps' ? item.job_card_id : null, item.parent_quote_status, item.parent_quote_id)} 
                                className="navbar-button" >View</div>
                                }
                            </td>
                        </tr>
                                )
                            })
                        }
                        
                        </tbody>
                        :
                        <tbody>
                            <div style={{ display: 'flex', width:'95%', height:'80%', position:'absolute', justifyContent: 'center', alignItems: 'center' }} >
                                <NoDatasScreen />
                            </div>
                        </tbody>
                    }
                    
                </table>
            </TableLayout>
            </div>
            <QuoteModal 
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                jobVal ={jobVal}
                parentQuoteStatus={parentQuoteStatus}
                parentQuoteId={parentQuoteId}
                fnRecall={() => fetch_quotes_list(1, tabType)}
            />

            <QuoteHistoryModalView 
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
            
        </div>
    );
}

const mapStateToProps = (state) => {
    const { sale_performance, sale_performance_detail, sale_perform_page, sale_perform_filter, sale_perform_detail_date_filter, sale_performance_detail_loader, sale_perf_stop_pagination,
        sale_prfrm_month, sale_prfrm_year  } = state.sales
    return { sale_performance, sale_performance_detail, sale_perform_page, sale_perform_filter, sale_perform_detail_date_filter, sale_performance_detail_loader, sale_perf_stop_pagination,
        sale_prfrm_month, sale_prfrm_year }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetch_sales_performance: (year, month, tabType) => dispatch(fetch_common_sale_performance(year, month, tabType)),
        fetch_quotes_list: (page_no, tabType) => dispatch(fetch_common_quote_registers_list(page_no, tabType)),
        fetch_sales_performDetails: (id, pageNo, type, tabType, filterMY=null, callBk=null) => dispatch(sales_perform_view_common(id, pageNo, type, tabType, filterMY, callBk))
      }
}

export default connect(mapStateToProps, mapDispatchToProps) (Sales_performance_detail);
