import React, {useEffect, useState} from 'react';
import DateTimeInput from '../../../../common/DateTimeInput'
// import {connect} from 'react-redux'
// import Swal from 'sweetalert2'
// import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { CLIENT_SINGLE_FETCH, SALES_ALL_CLIENTS_LIST } from '../../../../store/endpoint';
import Api from '../../../../store/api'

function AssetRegister({ jobObj, modalType, tabType=null, preSelect=null, setAssetRegister, setClientName, clientId, setClientId, jobObjConnected, preSelectVal,
    // TypeofWaste, setTypeofWaste
    // clientList, addJob, editJob, verifyJobCard=null, user
  }) {


    const [compName, setCompName]           = useState(modalType==='add' ? preSelect===null ? '' :
    preSelect.company_name ? preSelect.company_name : preSelect.client_name : jobObj===null ? '' : jobObj.company_name ? jobObj.company_name : jobObj.client_name  )
    const [siteAddr, setSiteAddr]           = useState(modalType==='add' ? preSelect===null ? '' :
        preSelect.site_address : jobObj===null ? '' : jobObj.job_site_address   )

    const [jobDate, setJobDate]             = useState(modalType!=='add' ? jobObj===null ? new Date() : jobObj.date : new Date())
    const [jobTechnician, setJobTechnician] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.technician : '')

    const [assetLocation, setAssetLocation] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_loc : '')
    const [assetNo, setAssetNo]             = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_no : '')
    const [assetType, setAssetType]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_type : '')
    const [noOfPump, setNoOfPump]           = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.no_of_pump : '')
    const [pumpMake, setPumpMake]           = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.pump_make : '')
    const [pumpModel, setPumpModel]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.pump_model : '')
    const [motorCurrent, setMotorCurrent]   = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.motor_curr : '')
    const [volt, setVolt]                   = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.volt : '')
    const [kilowatts, setKilowatts]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.kilowatts : '')
    const [autoManual, setAutoManual]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.auto_manual : '')
    const [overloadRange, setOverloadRange] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.overload_range : '')
    const [overloadSett, setOverloadSett]   = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.overload_settings : '')
    const [depth, setDepth]                 = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.depth : '')
    const [width, setWidth]                 = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.width : '')
    const [length, setLength]               = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.length : '')
    const [guideRail, setGuideRail]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.guide_rail : '')

    const [allClientsList, setAllClientsList] = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)

        useEffect(()=>{
            Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                res =>{
                    console.log(res)
                    setAllClientsLoader(false)
                    if(res.length>0){
                        setAllClientsList(res)
                        if((modalType==='edit' || modalType==='add') && clientId!==null && clientId!==''){
                        
                            Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
                                .then(response => {
                                    setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                    setSiteAddr(response.site_address);
                                    setClientName(response.client_name);
                            })
                             
                        }
                    }else 
                        setAllClientsList([])
                }
                    
            )
        },[tabType, modalType, clientId, setClientName])    

    const addJobCard = () =>{
        let data = {
            card_id         :   '5',
            client          :   clientId,
            company_name    :   compName,
            site_address    :   siteAddr,
            job_date        :   jobDate,
            technician      :   jobTechnician,
            asset_loc       :   assetLocation,
            asset_no        :   assetNo,
            asset_type      :   assetType,
            no_of_pump      :   noOfPump,
            pump_make       :   pumpMake,
            pump_model      :   pumpModel,
            motor_curr      :   motorCurrent,
            volt            :   volt,
            kilowatts       :   kilowatts,
            auto_manual     :   autoManual,
            overload_range  :   overloadRange,
            overload_settings   :   overloadSett,
            depth           :   depth,
            width           :   width,
            length          :   length,
            guide_rail      :   guideRail
        }
        setAssetRegister(data)
    }

    return (
        <div className="jobCardDiv1">
            <div className="jobCardDivHead" >Customer Report</div>
            <div className="jobCardDivInpContent">
                    <div className="jobCardInputDivSec" >
                        <div>Date</div>
                        <div>
                            <span>:</span>
                            <DateTimeInput
                            dateSetFn={setJobDate}
                            dateValue={jobDate}
                            />
                    {/* <input type="text" className="jobCardDivInp" placeholder="Date"/> */}
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'|| preSelectVal!==null   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compName}</p>   :
                            <select className="jobCardDivInp" value={clientId} 
                            style={{ pointerEvents: preSelectVal===null ? 'auto' : 'none',
                                         cursor: preSelectVal===null ? 'pointer' : 'default' }}
                            onClick={()=>{ setAllClientsLoader(true);
                                Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
                                    res =>{
                                        // console.log("DATAAAAA",res)
                                        setAllClientsLoader(false)
                                        if(res.length>0)
                                            setAllClientsList(res)
                                        else 
                                            setAllClientsList([])
                                    }
                                        
                                )
                            }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                setClientId(e.target.value);
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                                        setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                        setSiteAddr(response.site_address);
                                        setClientName(response.client_name);
                                })
                                 
                            ; }
                                 }}>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                        </div>
                        
                    </div>
                    {/* <div className="jobCardDivInpContentDiv">
                        <div>Type of Waste</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={TypeofWaste} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                                 onChange={val =>{ setTypeofWaste(val.target.value.toUpperCase());  }}>
                                    <option value='' >Select Option</option>
                                    <option value='AMMONIA'>AMMONIA</option>
                                    <option value='DILUTION PIT'>DILUTION PIT</option>
                                    <option value='DRUMS'>DRUMS</option>
                                    <option value='FOOD WASTE'>FOOD WASTE</option>
                                    <option value='GERNIE WASHES'>GERNIE WASHES</option>
                                    <option value='GREASE'>GREASE</option>
                                    <option value='GROUND WATER'>GROUND WATER</option>
                                    <option value='J120'>J120</option> 
                                    <option value='LEVY'>LEVY</option>
                                    <option value='M250'>M250</option>
                                    <option value='N205'>N205</option> 
                                    <option value='OIL SEPERATOR'>OIL SEPERATOR</option>
                                    <option value='PACKAGED WASTE'>PACKAGED WASTE</option>
                                    <option value='PRODUCT DESTRUCTION'>PRODUCT DESTRUCTION</option>
                                    <option value='PUMP SERVICE'>PUMP SERVICE</option>
                                    <option value='RETENTION PIT'>RETENTION PIT</option>
                                    <option value='SEWER'>SEWER</option>
                                    <option value='STORMWATER'>STORMWATER</option>
                                    <option value='TANK WASH OUT'>TANK WASH OUT</option>      
                                    <option value='TRADE WASTE'>TRADE WASTE</option>                                              
                                    <option value='TRANSPORT'>TRANSPORT</option>                            
                                    <option value='Z140'>Z140</option>
                                    <option value='WIEGH BRIDGE'>WEIGH BRIDGE</option>                                    
                                    <option value='DESTRUCTION CERTIFICATE'>DESTRUCTION CERTIFICATE</option>
                                    <option value='NOT APPLICABLE'>NOT APPLICABLE</option>                                
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                        </div>
                        
                    </div>
                     */}
                    <div className="jobCardInputDivSec" >
                        <div>Site Address</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{siteAddr}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Site Address" value={siteAddr} onChange={(e)=>setSiteAddr(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Technician</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Technician" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Asset Location</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetLocation}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Asset Location" value={assetLocation} onChange={(e)=>setAssetLocation(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Asset Number</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetNo}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Asset Number" value={assetNo} onChange={(e)=>setAssetNo(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Asset Type</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetType}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Asset Type" value={assetType} onChange={(e)=>setAssetType(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Number of Pumps</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{noOfPump}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Number of Pumps" value={noOfPump} onChange={(e)=>setNoOfPump(e.target.value)} />
                            }
                        </div>
                    </div>
                    
                    <div className="jobCardDivHead" >Pump Details</div>
                    <div className="jobCardInputDivSec" >
                        <div>Pump Make</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpMake}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pump Make" value={pumpMake} onChange={(e)=>setPumpMake(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Pump Model</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pumpModel}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Pump Model" value={pumpModel} onChange={(e)=>setPumpModel(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Motor Current</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{motorCurrent}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Motor Current" value={motorCurrent} onChange={(e)=>setMotorCurrent(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Volt 240/415</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{volt}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Volt 240/415" value={volt} onChange={(e)=>setVolt(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Kilowatts</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{kilowatts}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Kilowatts" value={kilowatts} onChange={(e)=>setKilowatts(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Auto/Manual</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{autoManual}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Auto/Manual" value={autoManual} onChange={(e)=>setAutoManual(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>OverLoad Range</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{overloadRange}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="OverLoad Range" value={overloadRange} onChange={(e)=>setOverloadRange(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>OverLoad Setting</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{overloadSett}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="OverLoad Setting" value={overloadSett} onChange={(e)=>setOverloadSett(e.target.value)} />
                            }
                        </div>
                    </div>

                    <div className="jobCardDivHead" >Pit Details</div>
                    <div className="jobCardInputDivSec" >
                        <div>Depth (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{depth}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Depth" value={depth} onChange={(e)=>setDepth(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Width (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{width}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Width" value={width} onChange={(e)=>setWidth(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Length (m)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{length}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Length" value={length} onChange={(e)=>setLength(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Guide rails/Free Standing</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{guideRail}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Guide rail/Free Stanging" value={guideRail} onChange={(e)=>setGuideRail(e.target.value)} />
                            }
                        </div>
                    </div>
                    
                    
                </div>
                {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                <div 
                className={ clientId ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else if(!jobObjConnected)
                        addJobCard()
                    }} 
                  style={{ width:'15vw', height:'5vh', textAlign:'center' }}>
                {modalType==='add' ? 'Add Job Card to Collection' : modalType==='verify' ? jobObjConnected ?
                `This job card is already in use `
                :   'Update Job Card (Deselect the red checkbox to remove from collection)' :
                 'Update Job Card (Deselect the red checkbox to remove from collection)'} 
                </div>
            </div>
            :   null
            }
        </div>
    );
}

export default AssetRegister;