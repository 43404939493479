import React, { useState, useEffect } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { fetch_truck_list, masterVehicleListCSVPrint } from '../truck/common/action'
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import { store } from '../../../../store/store';
import { VEHICLE_SEARCH } from '../../../../store/endpoint';
import { fetch_car_list } from '../car/common/action';
import { fetch_forklift_list } from '../forklift/common/action';
import { CircularProgress } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { failed_validation } from '../../../../common/SearchErrorDestructure';
// import { } from '../truck/'

function VehicleImages({ page, tabType, truck_page, car_page, forklift_page, setSearchDataFx = null, searchDataFx = null }) {

  // const [searchTxt, setSearchTxt] = useState('')
  const [searchTxt, setSearchTxt] = useState({ prev: null, now: ''})
  const [loadSearch, setLoadSearch] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    const request = axios.CancelToken.source()

    if ((searchTxt.now!=='')&&(searchTxt.prev !== searchTxt.now)) {
      setLoadSearch(true)
      if (page === 'truck') {
        dispatch({ type: 'TRUCK_LIST_FETCHING', keyword: searchTxt.now,page:1})
      }
      if (page === 'car' || page === 'utes') {
        dispatch({ type: 'CAR_LIST_FETCHING', keyword: searchTxt.now,page:1})
      }
      if (page === 'fork-lift' || page === 'machinery') {
        dispatch({ type: 'FORKLIFT_LIST_FETCHING', keyword: searchTxt.now,page:1})
      }
      let formbody = new FormData()
      formbody.append('key', searchTxt.now)
      formbody.append('types', page)
      formbody.append('tab_type', tabType)
      
      let state = store.getState()
      let token = state.userLogin.authToken
      // let page_no = page === 'truck'? truck_page: (page === 'car' || page === 'utes')? car_page: forklift_page
      // + `?page=${1}&limit=15`
      var config = {
        method: 'post',
        url: VEHICLE_SEARCH.replace('VTYPE', `${tabType}/${page}`) + '?page=1&limit=15',
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: formbody
      };

      axios(config)
        .then(response => {
          console.log(response)
          setLoadSearch(false)
          if (page === 'truck') {
            dispatch({ type: 'TRUCK_LIST', trucks: response.data, page: 1 })
          }
          if (page === 'car' || page === 'utes') {
            dispatch({ type: 'CAR_LIST', cars: response.data, page: 1 })
          }
          if (page === 'fork-lift' || page === 'machinery') {
            dispatch({ type: 'FORKLIFT_LIST', forklifts: response.data, page: 1 })
          }

        })
        .catch((error)=>{
          setLoadSearch(false)
              let err = failed_validation(error)
              if (page === 'truck') {
                dispatch({ type: 'TRUCK_LIST_ERROR', error: err })
              }
              if (page === 'car' || page === 'utes') {
                dispatch({ type: 'CAR_LIST_FAILED', error: err })
              }
              if (page === 'fork-lift' || page === 'machinery') {
                dispatch({ type: 'FORKLIFT_LIST_ERROR', error: err })
              }
        })
    } else {
      // alert('empty')
      // setLoadSearch(false)
      if(searchTxt.prev!==null){
      if (page === 'truck') {
        // alert('truckcall')
        console.log('CALLED API!!!!')
        dispatch(fetch_truck_list(tabType, 1))
      }
      if (page === 'car' || page === 'utes') {
        dispatch(fetch_car_list(tabType, 1))
      }
      if (page === 'fork-lift' || page === 'machinery') {
        dispatch(fetch_forklift_list(tabType, 1))
      }
    }


    }

    return () => request.cancel()

  }, [searchTxt, page, tabType,dispatch]);
  // , page, tabType, car_page, truck_page, forklift_page, dispatch
  // },[searchTxt, page, dispatch, tabType, truck_page, car_page, forklift_page]);

  return (
    <div className="VehicleImages">
      <div className="vehicle-number" style={{ backgroundColor: 'white' }}>

        <input
          type="text"
          placeholder="Search here"
          className="Vehicle-input"
          value={searchTxt.now}
          style={{ height: '30px', paddingLeft: '5px' }}
          onChange={txt => {
            setSearchTxt({ prev : searchTxt.now, now : txt.target.value})
            // setLoadSearch(true)
            //  search_vehicle(tabType, qry, page)
          }}
        />
        
        {loadSearch ?
          <CircularProgress size='20px' color='inherit' />
          :
          searchTxt.now !== '' ?
            <CloseOutlined size='20px' color='inherit' onClick={() => { setSearchTxt(''); }} />
            :
            <SearchIcon />
        }
      </div>
      <div className='siv-vech-select-btn' style={{ borderRadius:'10px', padding:'1%', marginTop:'auto' }}
        onClick={()=>{
          masterVehicleListCSVPrint(`${tabType}/${page}`,searchTxt.now)
        }}
      >
        Export to Excel
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { truck_page } = state.vehicle__truck
  const { car_page } = state.vehicle__car
  const { forklift_page } = state.vehicle__forklift

  return { truck_page, car_page, forklift_page }
}
//  const mapDispatchToProps = (dispatch) => {
//     return {
//        search_vehicle: (tabType, qry, type) => dispatch(vehicle_search(tabType, qry, type))
//    }
//}

export default connect(mapStateToProps, null)(VehicleImages)

