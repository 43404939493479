import { CircularProgress, Tooltip } from '@material-ui/core';
import { HighlightOffOutlined, Search } from '@material-ui/icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { store } from '../store/store';

export const SearchInputTab = ({ url, cls, txt, styleHndle=null, fn=null, searchLoadFn=null,
    setSearchDataFx=null, dispatchFn, methodType='post', setSearchDataObj=null,dipatchforloading=null,searchKey=null }) => {

    const [searchTxt, setSearchTxt] = useState({ prev: null, now: ''})
    const [loadSearch, setLoadSearch] = useState(false)
    const [hoverAct, setHoverAct]   = useState(false)
    
    const dispatch = useDispatch();

    useEffect(() => {
        const requestTk = axios.CancelToken.source()
        const requestAb = new AbortController();
        
        let data = new FormData()

        data.append( searchKey===null ? 'key' : searchKey,searchTxt.now)
        // console.log("searching user",searchTxt,request)       
    // if(searchTxt.prev !== searchTxt.now){ 
        if((searchTxt.now!=='')&&(searchTxt.prev !== searchTxt.now)){

            // if(dipatchforloading!==null)
            //     dispatch(dipatchforloading)

            let state = store.getState()
            let token = state.userLogin.authToken
            var config = 
            methodType==='post' ?
            {
                method: methodType,
                url: url,
                cancelToken: requestTk.token,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                data: data
            }
            :
            {
                method: methodType,
                url: `${url}${searchKey ? `&${searchKey}=${searchTxt.now}` : `&key=${searchTxt.now}`}`,
                cancelToken: requestTk.token,
                signal : requestAb.signal,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }
            axios(config)
            .then((res) => {
                if(searchLoadFn!==null)
                    searchLoadFn(false)
                
                console.log("SUCCESS DISPATCH","SEARCH", searchTxt,res)
                setSearchTxt({ prev : searchTxt.now, now : searchTxt.now })
                if(setSearchDataFx!==null){
                    if(searchTxt.now===''){
                        setSearchDataFx(null)
                    }else{
                        setSearchDataFx(searchTxt.now)
                    }}
                    
                if(setSearchDataObj!==null){
                    setSearchDataObj({ prev : searchTxt.now, now : searchTxt.now })
                }
                dispatch(dispatchFn(res.data))
                setLoadSearch(false)

            })
            .catch((error) => {
                setLoadSearch(false)

                console.log(error);
            });
        }
        else{
            setLoadSearch(false)
            console.log("FAIL DISPATCH")
            if(searchLoadFn!==null)
                    searchLoadFn(false)
                
            // if(fn){
            //     fn()
            // }
        }
        // }
        if(methodType!=='post'){
            
            requestAb.abort();
        }
        return () => requestTk.cancel('Cancelled by Token')


    }, [searchTxt, dispatch, fn, dispatchFn, url, methodType, setSearchDataObj, searchKey,
        //dipatchforloading,
        searchLoadFn,
         setSearchDataFx])

    return (
        <>
        <input className={cls} 
            style={styleHndle} 
            placeholder={txt} 
            value={searchTxt.now}
            onChange={(e) =>{         
                if(!loadSearch)               
                    setLoadSearch(true)
                if(searchLoadFn!==null)
                    searchLoadFn(true)
                setSearchTxt({ prev : searchTxt.now, now : e.target.value});
                
            }} 
        />
        { loadSearch ?
                <CircularProgress size='20px' color='inherit' />
                : 
                <Search style={{ fontSize:'18px', display:'flex', alignItems:'center',
                 justifyContent:'center' }} color='inherit' />
        }
        {<Tooltip
            open={hoverAct} 
            title={'Click to clear Search box.'}>
            <HighlightOffOutlined  style={{ fontSize:'18px', display:'flex', alignItems:'center',
                 justifyContent:'center' }} color='inherit'
                onMouseEnter={()=>{ if(searchTxt.now!==''){ setHoverAct(true); } else{ setHoverAct(false); } }}
             onMouseLeave={()=> setHoverAct(false)}
                 onClick={()=>{ 
                    if(dipatchforloading!==null)
                        dispatch(dipatchforloading)
                     setSearchTxt({ prev : null, now : '' }); if(fn!==null){ fn(); } }} />
        </Tooltip>}
        </>
    );
}

