import Api from '../../store/api'
import { FileDownloader } from '../../common/file_downloader'
import Swal from 'sweetalert2'

import { 
    CLIENT_GET, 
    CLIENT_SEARCH, 
    CLIENT_EDIT, 
    CLIENT_DELETE, 
    CLIENT_ADD, 
    CLIENT_FOLDERS,
    CLIENT_FILE_DELETE,
    PREV_JOBS,
    SITE_ATTACH_CRUD,
    SALES_TEMP_CLIENTS,
    TEMP_CLIENT_SEARCH,
    SALES_CREATE_TEMP_CLIENT,
    PRINT_ALL_LIST,
    CLIENT_SINGLE_FETCH,
    SITE_TYPE_OF_WASTE_LIST,
    SITE_TOTAL_NO,
    DELETED_CLIENT,
    SCHEDULE_JOB_LIST_PRINT,
    // SITE_TYPE_OF_WASTE_CRUD
 } from '../../store/endpoint'

export const reloadPage = () =>{
    return function (dispatch){
        dispatch({
            type: 'RELOAD_DATA'
        })
    }
}

export const fetch_client_totalNo = (tabType) => {
    return function (dispatch){
        let get_url = SITE_TOTAL_NO + tabType + '/'
        Api('get', get_url)
            .then(response => {
                console.log(response)
                dispatch({
                    type: 'CLIENTS_TOTAL_NO',
                    perm_clients_Count: response.total_permanent_clients,
                    temp_clients_Count: response.total_temp_clients,
                    deleted_clients_Count : response.total_deleted_clients
                })
            })
    }
}

export const fetch_client_list = (params=null, tabType) => {
    return function (dispatch) {
        let get_url = CLIENT_GET
        if(params!==null) {
            get_url = get_url + params + '/'+tabType+'/'
        } else {
            get_url = get_url + 'alpha_asc/'+tabType+'/'
        }
        Api('get', get_url)
            .then(response => {
                console.log('CLIENT',response)
                dispatch({ type: 'CLIENT_LIST', clients: response, clientType: 'perm'})
            })
    }
}

export const fetch_temp_clients_list = (params=null, tabType) =>{
    return function (dispatch) {
        let get_url = SALES_TEMP_CLIENTS
        if(params!==null) {
            get_url = get_url + params + '/'+tabType+'/'
        } else {
            get_url = get_url + 'alpha_asc/'+tabType+'/'
        }
        Api('get', get_url)
            .then(response => {
                console.log('CLIENT',response)
                dispatch({ type: 'CLIENT_LIST', clients: response, clientType: 'temp'})
                if(params!==null)
                    dispatch({ type: 'CHANGE_SORT', sort: params })
            })
    }
}

export const fetch_clients_paginated = (params=null, tabType, page, type, ind=null) =>{
    return function (dispatch) {
        let get_url = ''
        if(type==='perm')
            get_url = CLIENT_GET
        else if(type==='temp')
            get_url = SALES_TEMP_CLIENTS
        else 
            get_url = DELETED_CLIENT

        if(params!==null) {
            get_url = get_url + params + '/'+tabType+'/?page='+page+`&limit=40${ind===null ? '' : `&industry_type=${ind}`}`
        } else {
            get_url = get_url + 'alpha_asc/'+tabType+'/?page='+page+`&limit=40${ind===null ? '' : `&industry_type=${ind}`}`
        }
        Api('get', get_url)
            .then(response => {
                console.log(response)
                dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: response, clientType: type, clientPage: page})
                if(params!==null)
                    dispatch({ type: 'CHANGE_SORT', sort: params })
                if(ind!==null)
                    dispatch({ type: 'CHANGE_IND_SORT', sort: ind })

            })
    }
}



export const client_sort = (params, tabType, ind=null) => {
    return function (dispatch) {
        // dispatch(fetch_client_list(params))
        dispatch({ type: 'CHANGE_SORT', sort: params })
        let get_url = CLIENT_GET + params + '/' + tabType +`/?page=1&limit=40${ind===null ? '' : `&industry_type=${ind}`}`
        Api('get', get_url)
        .then(response => {
            dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: response, clientType: 'perm', clientPage: 1})
            if(ind!==null)
                    dispatch({ type: 'CHANGE_IND_SORT', sort: ind })
        })
    }
}

export const del_client_sort = (params, tabType, ind=null) => {
    return function (dispatch) {
        // dispatch(fetch_client_list(params))
        dispatch({ type: 'CHANGE_SORT', sort: params })
        let get_url = DELETED_CLIENT + params + '/' + tabType +`/?page=1&limit=40${ind===null ? '' : `&industry_type=${ind}`}`
        Api('get', get_url)
        .then(response => {
            dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: response, clientType: 'perm', clientPage: 1})
            if(ind!==null)
                    dispatch({ type: 'CHANGE_IND_SORT', sort: ind })
        })
    }
}


export const temp_client_sort = (params, tabType, ind=null) => {
    return function (dispatch) {
        // dispatch(fetch_client_list(params))
        dispatch({ type: 'CHANGE_SORT', sort: params })
        let get_url = SALES_TEMP_CLIENTS + params + '/' + tabType +`/?page=1&limit=40${ind===null ? '' : `&industry_type=${ind}`}`
        Api('get', get_url)
        .then(response => {
            dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: response, clientType: 'temp', clientPage: 1})
            if(ind!==null)
                    dispatch({ type: 'CHANGE_IND_SORT', sort: ind })
        })
    }
}


export const search_client_list = (query, tabType) => {
    return function (dispatch) {
        if(query!=='' || query ) {
            let formbody = new FormData()
            formbody.append('key', query)
            Api('post', `${CLIENT_SEARCH}${tabType}/`, formbody, null, null)
                .then(response => {
                    dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: response, clientType: 'perm', clientPage: 1})
                    // dispatch({ type: 'CLIENT_LIST', clients: response, clientType: 'perm' })
                })
        } else {
            dispatch(fetch_clients_paginated(null,tabType,1,'perm'))
        }
    }
}

export const search_temp_client_list = (query, tabType) => {
    return function (dispatch) {
        if(query!=='' || query ) {
            let formbody = new FormData()
            formbody.append('key', query)
            Api('post', `${TEMP_CLIENT_SEARCH}${tabType}/`, formbody)
                .then(response => {
                    dispatch({ type: 'CLIENT_LIST_PAGINATED', clients: response, clientType: 'temp', clientPage: 1})
                    // dispatch({ type: 'CLIENT_LIST', clients: response, clientType: 'temp' })
                })
        } else {
            dispatch(fetch_clients_paginated(null,tabType,1,'temp'))
        }
    }
}



export const select_client = (data) => {
    return function (dispatch) {
        Api('get',`${CLIENT_SINGLE_FETCH}${data}/`,'')
        .then(response => {
            console.log(data, response)
        dispatch({ type: 'SELECT_CLIENT', client: response })
        })
        
    }
}

export const edit_client = (data, client_id, success, failed) => {
    for(let v of data.entries()){
        console.log(v[0],v[1])
    }
    return function (dispatch) {
        let edit_url = CLIENT_EDIT + client_id + '/'
        Api('patch-form', edit_url, data, success, failed)
            .then(response => {
               dispatch({ type: 'RESET_CLIENT_DATA', client: response, id: client_id })
               
            })
    }
}

export const delete_client = (client_id, tabType, success, failed) => {
    return function (dispatch) {
        let delete_url = CLIENT_DELETE + client_id + '/'
        Api('delete', delete_url)
            .then(response => {
                success()
                dispatch(fetch_clients_paginated(null,tabType,1,'perm'))
            })
            .catch(err => {
                failed()
            })
    }
}


export const create_client = (data, tabType, success, failed) => {
    for(let v of data.entries()){
        console.log(v[0],v[1])
    }
    return function (dispatch) {
        const successFx = () => {
            dispatch(fetch_clients_paginated(null,tabType,1,'perm'))
               success()
        }
        Api('post-form', CLIENT_ADD, data, successFx, failed)
    }
}

export const create_temp_client = (data, tabType, success, failed) => {
    for(let v of data.entries()){
        console.log(v[0],v[1])
    }
    return function (dispatch) {
        const successFx = () => {
            dispatch(fetch_clients_paginated(null,tabType,1,'perm'))
               success()
        }
        Api('post-form', SALES_CREATE_TEMP_CLIENT, data, successFx, failed)
    }
}


export const client_folders = (id, apiResponseCallBack) => {
    const req_url = CLIENT_FOLDERS + id + '/'
       console.log(req_url)
       Api('get', req_url, '', ).then(response => {
        console.log('blah', response)
        if(response.length > 0) {
            apiResponseCallBack({
                response: response, 
                loader: false, 
                error: false, 
                status: 'Folder Api Fetching Successful'
            })

        } else {
            apiResponseCallBack({
                response: [], 
                loader: false, 
                error: true, 
                status: 'No Folder Data Found'
            })
        }
       })
}


export const client_file_upload = (client_id, data, apiResponseCallBack) => {
    const req_url = CLIENT_FOLDERS + client_id + '/'
       console.log(req_url)
       Api('post-form', req_url, data, ).then(response => {
        console.log('file upload response', response.blah)
        apiResponseCallBack()
       })
}

export const client_file_delete = (file_id, success, failed) => {
    const req_url = CLIENT_FILE_DELETE + file_id + '/'
       console.log(req_url)
       Api('delete', req_url, '', success, failed).then(response => {
        console.log('file delete response', response)
       })
}

export const previous_jobs_fetch = (id, wasteType=null, str=null, fn=null, pg=1) =>{
    return function(dispatch){
    
    const get_url = `${PREV_JOBS}${id}/${wasteType===null ? 'all' : wasteType}${str===null ? '/?' : `/?child_id=${parseInt(str)}&&`}limit=20&&page=${pg}`
    Api('get', get_url).then(response => {
        console.log(response)
        if(fn){
            fn(false)
        }
        dispatch({ type:'PREV_JOBS', prevJob: response, wasteType: wasteType, tablePage: pg })
    })
    }
}

export const getAllSiteAttach = (id) =>{
    return function(dispatch){
        const get_url = SITE_ATTACH_CRUD + id + '/'
        Api('get', get_url).then(response => {
            dispatch({
                type: 'SITE_ATTACH',
                attach: response
            })
        })
    }
}

export const newSiteAttach = (content, id, success, failed) =>{
    if(id===null)
        Api('post-form', SITE_ATTACH_CRUD, content, success, failed).then()
    else 
        Api('put', `${SITE_ATTACH_CRUD}${id}/`, content, success, failed).then()
}

export const delSiteAttach = (id, success, failed) =>{
    Api('delete', `${SITE_ATTACH_CRUD}${id}/`,'', success, failed).then()
}

export const takeAllPrint = (type, tabType, v='key', success=null, failed=null) =>{
    Api('get',`${PRINT_ALL_LIST}${tabType}/?${v}=${type}`)
        .then(res =>{ 
            FileDownloader(res,type);
            if(success)
                success()
            })
        .catch(err =>{ if(failed){ failed() }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        } })
}

export const takeAllJobsPrint = (type, page_no, filterData, success=null, failed=null) =>{

    const successFx = (res) =>{
        FileDownloader(res,'Jobs_List','.csv');
                if(success)
                    success()
    }

    const failedFx = () =>{
        if(failed){ failed() }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                }
    }

    Api('get',`${SCHEDULE_JOB_LIST_PRINT}${type}/?${filterData}`, null, successFx, failedFx)
}

export const editTypeOfWaste = (wasteType, id, content, success, failed) => {
    return function(dispatch){
        const getUrl = `${CLIENT_EDIT}wastetype/${id}/`
        Api('put-json', getUrl, content, success, failed).then(
            response => {
                console.log(response)
                dispatch({
                    type : 'WASTE_TYPE',
                    typeOfWaste : response
                })
            }
        )
    }
}

export const getTypeOfWasteDataList = (id) => {
    return function(dispatch){
        const getUrl = `${SITE_TYPE_OF_WASTE_LIST}details/${id}/`
        Api('get', getUrl).then(
            response => {
                console.log(response)
                dispatch({
                    type : 'WASTE_TYPE',
                    typeOfWaste : response
                })
            }
        )
    }
}