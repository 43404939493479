import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@material-ui/core'
import Swal from 'sweetalert2'
// import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { HOST_WITHOUT_SLASH } from '../../../../store/endpoint';
// import { post_request, patch_request } from '../../../Store/api'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: theme.shadows[5],
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export default function TransitionsModal({ 
   tabType,
      modalStatus, 
      modalAction, 
      selected_vehicle, 
      edit_vehicle, 
      response_page,
      permEdit 
}) {

  const classes = useStyles();
  const handleClose = () => {
    modalAction(false)
  };

  
  let vehicle_id = ''

  if(selected_vehicle.id) {
     const { id } = selected_vehicle
     console.log("SELEcTED VEHICLE",selected_vehicle)
      // prev_rego_data = previous_rego
      vehicle_id = id

  }

//   const [PrvRego, setPrvRego] = useState(prev_rego_data)
  const [VehicleImages, setVehicleImages] = useState(null)
  const [multiImages, setMultiImages] = useState(true)

const edit_vehicle_fx = () => {
   
   let postData  = new FormData()
   postData.append('image1', VehicleImages)
   
   const success = (response) => {
      
       modalAction(false)
       Swal.fire({
         position: 'top-end',
         icon: 'success',
         title: 'Vehicle Images edited successfull!',
         showConfirmButton: false,
         timer: 1500
     })
   }

   const failed = (res) => {
      modalAction(false)
       Swal.fire({
           icon: 'error',
           title: 'Oops...',
           text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!'
       })
   }
   
   edit_vehicle(
      tabType,
      vehicle_id, 
      postData, 
      success, 
      failed, 
      response_page
   )   
}

let edit_btn_style = 'regi-vehc-btn disabled-btn'

if( multiImages ) {
   edit_btn_style = 'regi-vehc-btn'
}

const importMultipleImages = (e) =>{
   let file = e.target.files[0]
      setVehicleImages(file)
      setMultiImages(true)
}


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={modalStatus}
      onClose={handleClose}
      closeAfterTransition
    >
      <div className="team-edit-setting-layout">
      
      <div
      style={{
         display:'flex',
         flexDirection: 'row',
         justifyContent:'space-between'
      }}
      >
      <p className="team-edit-title">Update Vehicle</p>
         {  VehicleImages===null ?
            null
         :
           <button 
              onClick={() => edit_vehicle_fx()}
              className={edit_btn_style}>Update</button>                   
        }
      </div>
      
                <div className="team-edit-setting-main">
                  <div className="box-one-add-items">

                           
                           <div className="vehicleSingleImageDiv">   
                           
                              <div className="client-crt-dp Vehicle-multi-crt-dp Vehicle-single-dp-img" >
                                 <img className="Vehicle-multi-crt-dp Vehicle-single-dp-img" src={
                                  VehicleImages===null ?
                                 `${HOST_WITHOUT_SLASH}${selected_vehicle.image1}`
                                 :
                                 URL.createObjectURL(VehicleImages)   
                                 } alt="" style={{ width:'auto', height:'auto', maxHeight:'100%', maxWidth:'100%' }}/>
                                 {  permEdit ?
                                 <div className="document-delete document-delete-single" >
                                    <label htmlFor="file-input">
                                       <div style={{textDecoration:'none'}}>
                                          <EditRoundedIcon style={{ fontSize: 18,  margin: 0, padding: 0 }}/>
                                          <p> Change Picture</p>
                                       </div>
                                       <input id="file-input" style={{ display: 'none' }} type="file" accept={"image/*"} multiple={false}
                                          onChange={(image) => {
                                             importMultipleImages(image)
                                          }}
                                       />
                                    </label>
                                 </div>   :  null
                                 }
                              </div>                            
                              </div>      
                            
                           
                  </div> 

                                     
              </div>
            </div>


    </Modal>
);
}