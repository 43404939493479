import React, {useState} from 'react';
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import Scrollbars from '../../../../../common/ImprovedScrollBar';
import DateTimeInput from '../../../../../common/DateTimeInput';
import { getAllSiteAttach, newSiteAttach } from '../../../action';


function NewClientAttach({setJobCardModal, attachObj, clientId, modalType, setModalType, setAttachObj, create_attach, fetch_attach}) {

    const [attachOwner, setAttachOwner] = useState(attachObj===null ? '' : attachObj.owner)
    const [attachPump, setAttachPump] = useState(attachObj===null ? '' : attachObj.pump_assetNo)
    const [attachLoc, setAttachLoc] = useState(attachObj===null ? '' : attachObj.location)
    const [attachFreq, setAttachFreq] = useState(attachObj===null ? '' : attachObj.frequency)
    const [attachLastServ, setAttachLastServ] = useState(attachObj===null ? new Date() : attachObj.last_service)
    const [attachNextServ, setAttachNextServ] = useState(attachObj===null ? new Date() : attachObj.next_service)
    const [attachServDescp, setAttachServDescp] = useState(attachObj===null ? '' : attachObj.service_description)
    // const [attachSiteDetails, setAttachSiteDetails] = useState(attachObj===null ? '' : attachObj.site_details)

    const createAttachment = (funcType) =>{
        let dateStr1 = new Date(attachLastServ)
        let dateStr2 = new Date(attachNextServ)
        let postData = new FormData()
        postData.append('owner',attachOwner)
        postData.append('pump_assetNo',attachPump)
        postData.append('location',attachLoc)
        postData.append('frequency',attachFreq)
        postData.append('last_service',dateStr1.toISOString())
        postData.append('next_service',dateStr2.toISOString())
        postData.append('service_description',attachServDescp)
        postData.append('site_details',clientId)
        console.log("CALLED", funcType, attachObj)
        const success = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : 'Site Attachment Updated.',
                showConfirmButton: false,
                timer: 1500
            })
            fetch_attach(clientId)
            setModalType(false); setAttachObj(null); setJobCardModal(false)
        }

        const success_fx = () =>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title : 'Site Attachment Created.',
                showConfirmButton: false,
                timer: 1500
            })
            fetch_attach(clientId)
            setModalType(false); setAttachObj(null); setJobCardModal(false)
        }

        const failed = () =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            setModalType(false); setAttachObj(null); setJobCardModal(false)
        }

        if(funcType)
            newSiteAttach(postData, null, success_fx, failed)
        else 
            newSiteAttach(postData, attachObj.id, success, failed)

    }

    

    return (
        <div className="jobCardLayout" style={{ height:"55vh", width:"40vw" }}>
            <div className="header-button-layout" style={{ justifyContent:'space-between'}}
             onClick={()=> { setModalType(false); setAttachObj(null); setJobCardModal(false) }}
            >
                <h1 style={{ margin: '10px' }}>{ attachObj===null ? 'New' : null} Asset</h1>
                <div className="header-button-top">
                    Back
                </div>
                
            </div>
            <div className="jobCardContentDiv">
                <Scrollbars style={{ height:'45vh' }}>
                    <div className="attachCardDivInpContent">
                        <div>
                            <div className="attachCardDivContLeft">Owner</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <input type="text" className="attachCardDivInp"
                                    placeholder="Owner"
                                    disabled={!modalType ? false : true}
                                    value={attachOwner} onChange={(e)=>setAttachOwner(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="attachCardDivContLeft">Pump Asset No</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <input type="text" className="attachCardDivInp"
                                    placeholder="Pump Asset Number"
                                    disabled={!modalType ? false : true}
                                    value={attachPump} onChange={(e)=>setAttachPump(e.target.value)}
                                     />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="attachCardDivContLeft">Location</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <input type="text" className="attachCardDivInp"
                                    placeholder="Location"
                                    disabled={!modalType ? false : true}
                                    value={attachLoc} onChange={(e)=>setAttachLoc(e.target.value)}
                                     />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="attachCardDivContLeft">Frequency</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <input type="text" className="attachCardDivInp"
                                    placeholder="Frequency"
                                    disabled={!modalType ? false : true}
                                    value={attachFreq} onChange={(e)=>setAttachFreq(e.target.value)}
                                     />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="attachCardDivContLeft">Last Service</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <DateTimeInput
                                        dateSetFn={!modalType ? setAttachLastServ : ''}
                                        dateValue={attachLastServ}
                                        clsName={"attachCardDivInp"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="attachCardDivContLeft">Next Service</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <DateTimeInput
                                        dateValue={attachNextServ}
                                        dateSetFn={!modalType ? setAttachNextServ : ''}
                                        clsName={"attachCardDivInp"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="attachCardDivContLeft">Service Description</div>
                            <div>
                                <span>:</span>
                                <div>
                                    <input type="text" className="attachCardDivInp" 
                                    placeholder="Service Description"
                                    disabled={!modalType ? false : true}
                                    value={attachServDescp} onChange={(e)=>setAttachServDescp(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
                {   !modalType   ?
                <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                    <div onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); createAttachment(attachObj===null ? true : false) }}
                        className={ 
                            attachOwner && attachPump && attachServDescp && attachNextServ && attachLoc && attachLastServ && attachFreq ?
                            "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                        style={{ width:'15vw', height:'5vh', textAlign:'center' }}>
                        { attachObj===null ? 'Add ' : 'Update '} Asset
                    </div>
                </div>
                :   null
                }
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetch_attach : (id) => dispatch(getAllSiteAttach(id))
    }
}

export default connect(null, mapDispatchToProps) (NewClientAttach);