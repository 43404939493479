import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { cl_blue } from '../../../common/Colors'
import { team_dangerous_data } from '../action'
import Swal from 'sweetalert2'
import Scrollbars from '../../../common/ImprovedScrollBar.jsx';
import InformationModal from './UpdateDangerous'


function Dangerous({ team, edit_dangerous_data }) {
    // console.log(team, team.team_dangerous_data)

    const [modal, setmodal] = useState(false)
    const [modalType, setModalType] = useState(null)
    
    useEffect(() => {
        edit_dangerous_data(team.selected_employee.id, null)
        
    }, [team.selected_employee.id, edit_dangerous_data]);

    
    
    const update_data_fx = (jsonTempVal) => {
        let postData = {"data":JSON.stringify(jsonTempVal)}
        const success = () =>{
            setmodal(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Empoyee updated successfull!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () =>{

            setmodal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        edit_dangerous_data(team.selected_employee.id, postData, team.dataId, success, failed)
    }
 
    return (
        <div>
            <div  style={{ display: 'flex', flexDirection:'row', justifyContent: 'flex-end', marginRight: 10 }}>
                <button 
                    onClick={() =>{
                    setModalType("add");
                    setmodal(true);}}
                    style={{ height: 30, width: 100, borderRadius: 20, border: 0, backgroundColor: cl_blue, cursor: 'pointer' }} >Update Info</button>
            
                
            </div>
            <Scrollbars autoHide style={{ height:'15vh', marginLeft: 20 }}>
            <div className="team-detail-left-empolyee">
            {   team.team_dangerous_data && Object.keys(team.team_dangerous_data).map((item) => {
                    return(
                        <div className="team-main-data">
                            <div className="team-main-data-left">
                                <p className="p-0 m-0" style={{ fontSize:14, fontWeight:'600' }}>{item}</p>
                            </div>
                            <div className="team-main-data-center">
                                <p className="p-0 m-0"  style={{ fontSize:14 }}>:</p>
                            </div>
                            <div className="team-main-data-right">
                                <p className="p-0 m-0" style={{ fontSize:14 }}>{team.team_dangerous_data[item]}</p>
                            </div>
                        </div>
                        // <div className="team-detail-left-empolyee1" >
                        //     <div className='handling-dangerous-goods'>
                        //     {item}
                        //     </div>
                        //     <div className='handling-dangerous-goods-p'>
                        //         <p>{team.team_dangerous_data[item]}</p> 
                        //     </div>
                        // </div>
                    )
                })
                
            }
            </div>
            </Scrollbars>
            
            {   modal   ?
            <InformationModal 
                modalStatus={modal}
                modalAction={setmodal}
                update={update_data_fx}
                modalType={modalType}
                jsonObjArr={team.team_dangerous_data}
            />  :   null
            }
        </div>
        
    )
}

const mapPropState = (state) => {
    const { team } = state
    return {team}
}

const mapDispatchToProps = (dispatch) =>{
    return {
        edit_dangerous_data : (selId, data, dataId, success, failed) => dispatch(team_dangerous_data(selId, data, dataId, success, failed))
    }
}

export default connect(mapPropState, mapDispatchToProps) (Dangerous)
