import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { fetch_common_quote_registers_list, sales_quote_filter, reloadPage, sales_quoteReg_client } from '../../common/action';
import Navbar from './navbar'
import SalesExcecutieves from './sales_excecutieves';
import SalesHomeList from './sales_home_list';
import TableLayout from './table_layout';
import Swal from 'sweetalert2'
import './sales_tableStyle.css';
import { CircularProgress, Tooltip } from '@material-ui/core'
import NoDatasScreen from '../../../../common/no_datas'
import {
    SALES_QUOTE_ACTION, SALES_QUOTE_DELETE, SALES_QUOTE_RESEND_MAIL, SALES_QUOTE_SITE_QUOTEID_SEARCH, SALES_QUOTE_SITE_SEARCH, SCHEDULE_QUOTE_INFOS,
    //SCOPEOFWORK_CRUD_TYPE_OF_WASTE
} from '../../../../store/endpoint'
import Api from '../../../../store/api'
import QuoteModal from '../../../../common/CommonQuoteDetailModal'
// import { cl_blue } from '../../../../common/Colors';
import QuoteHistoryModalView from '../../../scheduling/component/quoteHistoryModal'
import ResendModal from './mailResend'
import moment from 'moment';
import { cl_green, cl_red } from '../../../../common/Colors';
import EditableEditorModal from '../../../invoice/editableEditorModal';
import EditTableModal from '../../../../common/mainEditorTable/standaloneQuoteTableData.js';
import { useRef } from 'react';
import { fetch_curr_template } from '../../../invoice/common/action';

function QuoteRegister({ quote_register, quote_register_page, fetch_quotes_list, fetch_data_filter, tableLoader, refresh_page, isLoading,
    fetch_search_data, tabType, quote_register_loader, quote_stop_pagination, fetch_quote_temp,
    set_quote_mainpage_content, quote_mainpage_content, quote_table_obj }) {

    useEffect(() => {
        refresh_page()
        fetch_quotes_list(1, tabType)
        return () => fetch_quotes_list.remove
    }, [fetch_quotes_list, refresh_page, tabType])

    const [invokeFilter, setInvokeFilter] = useState("")
    const [filterData, setFilterData] = useState(new FormData())
    const [searchDataFx, setSearchDataFx] = useState(null)

    const [quoteModal, setquoteModal] = useState(false)
    const [resendModal, setresendModal] = useState({ status: false, qObj: null, qK: null })
    const [quoteInfos, setQuoteInfos] = useState({ job: null, quote: null, schedul: null })
    const [quoteSpinner, setQuoteSpinner] = useState(false)
    const [resnedSpinner, setResendSpinner] = useState(false)
    const [quoteSpinnerBtnIndex, setQuoteSpinnerBTnIndex] = useState(null)
    const [QuoteHistoryModal, setQuoteHistoryModal] = useState(false)
    const [quoteHistoryInfos, setQuoteHistoryInfos] = useState([])
    const [jobVal, setJobVal] = useState(null)
    const [parentQuoteStatus, setParentQuoteStatus] = useState(null)
    const [parentQuoteId, setParentQuoteId] = useState(null)
    const [deleteQuoteIdx, setDeleteQuoteIdx] = useState(null)

    const [MailCC, setMailCC] = useState([])
    const [MailBCC, setMailBCC] = useState([])
    const [mailSubj, setMailSubj] = useState('')
    const [mailBody, setMailBody] = useState('')

    const [mainTemplate, setMainTemplate] = useState(false)
    const [sOWModal, setSOWModal] = useState(false)
    const [jobId, setJobId] = useState(null)
    const [typeOfWaste, setTypeofWaste] = useState([])
    const [addInfoList, setAddInfoList] = useState([])
    const templateRef = useRef(null)

    const [switchableSearchFnVal, switchableSearchFn] = useState({ label : 'Clients', value : 'clients' })
    // const [hoverVal, setHoverVal] = useState(null)


    console.log('COMMNJOBID==>',jobId)
    const nextPageFx = () => {
        if (searchDataFx !== null)
            fetch_search_data(tabType, searchDataFx, quote_register_page + 1, switchableSearchFnVal.value)
        else if (invokeFilter !== "")
            fetch_data_filter(filterData, invokeFilter, quote_register_page + 1, tabType)
        else
            fetch_quotes_list(quote_register_page + 1, tabType)
    }

    const prevPageFx = () => {
        // if(searchDataFx!==null)
        //     fetch_search_data(tabType, searchDataFx, quote_register_page-1)
        // else if(invokeFilter!=="")
        //     fetch_data_filter(filterData, invokeFilter, quote_register_page-1, tabType)
        // else
        //     fetch_quotes_list(quote_register_page - 1, tabType)
    }

    const changeStatus = (str, id) => {
        let get_url = `${SALES_QUOTE_ACTION}${str}/${id}/`
        Api('get', get_url).then(response => {
            console.log(response)
            if (response) {
                fetch_quotes_list(quote_register_page, tabType)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Status changed to ${str}`,
                    showConfirmButton: false,
                    timer: 1500
                })

            }
        })
    }


    const resendMail = (id, index) => {
        setQuoteSpinnerBTnIndex(index)
        setResendSpinner(true)
        let formData = new FormData()
        MailCC.map((item) => {
            formData.append('mail_cc', item);
            console.log('mail_cc', item)
            return null;
        })

        MailBCC.map((item) => {
            formData.append('mail_bcc', item);
            console.log('mail_bcc', item)
            return null;
        })

        if (mailSubj !== '')
            formData.append('mail_subject', mailSubj)
        formData.append('mail_body', mailBody)
        let get_url = `${SALES_QUOTE_RESEND_MAIL}${tabType}/${id}/`

        const success = () => {
            setResendSpinner(false)
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Quote Resend Successful`,
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () => {
            setResendSpinner(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }
        Api('post-form', get_url, formData, success, failed)
    }

    const fetchQuoteInfos = (id, index, jobVal, parentStatus, parentQuoteId) => {
        setQuoteSpinnerBTnIndex(index)
        setQuoteSpinner(true)

        const succ = (res) =>{
            console.log(res.data)
            if (res.data) {
                setQuoteInfos(res.data)
                setquoteModal(true)
                console.log('QUOTEINFOSJOBID==>',res.data.quote.id)
                if(res.data.quote && res.data.quote.id)
                    setJobId(res.data.quote.id)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
            setQuoteSpinner(false)
            setJobVal(jobVal)
            setParentQuoteStatus(parentStatus)
            setParentQuoteId(parentQuoteId)
        }

        const fail = (err) =>{
            setQuoteSpinner(false)
            
                Swal.fire({
                   icon: 'error',
                   title: 'Oops...',
                   text: err && err.app_data ? err.app_data : 'Something went wrong!'
               })
        }

        Api('get', `${SCHEDULE_QUOTE_INFOS}${id}/`,null, succ, fail)
    }

    // let limit = 8;
    // let balance = 0;
    // if (quote_register.length < limit) {
    //     balance = limit - quote_register.length
    // }

    const deleteQuote = (val) => {
        let get_url = `${SALES_QUOTE_DELETE}${val}/`

        Swal.fire({
            title: 'Are you sure?',
            text: 'All jobs and reoccured quotes will be deleted!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: `Yes`
        }).then((result) => {
            if (result.value) {
                setDeleteQuoteIdx(val)
                Api('delete', get_url).then(response => {
                    console.log(response)
                    setDeleteQuoteIdx(null)
                    if (response) {
                        fetch_quotes_list(quote_register_page, tabType)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: `Quote Deleted`,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(err => {
                    setDeleteQuoteIdx(null)
                })
            }

        });

    }

    const reboundFn = () => { setquoteModal(true) }

    return (
        <div>
            <Navbar />
            <div className="sales-main-layout">
                <SalesHomeList>
                    <TableLayout
                        row_count={quote_register.length}
                        page={quote_register_page}
                        nextPage={nextPageFx}
                        prevPage={prevPageFx}
                        setInvokeFilter={setInvokeFilter}
                        setFilterData={setFilterData}
                        refreshPage={fetch_quotes_list}
                        setSearchDataFx={setSearchDataFx}
                        page_type={tabType}
                        tableLoader={quote_register_loader}
                        tableStopPaging={quote_stop_pagination}
                        dateFilterFn={{
                            k: 'key',
                            disp: fetch_data_filter
                        }}
                        searchInpTabFn={{
                            cls: 'searchInpSalesQuote',
                            txt: `Search ${switchableSearchFnVal.label}`,
                            url: switchableSearchFnVal.value==='clients' ? 
                                `${SALES_QUOTE_SITE_SEARCH}${tabType}/?limit=20&&page=1`
                                :   switchableSearchFnVal.value==='job_code' ?
                                `${SALES_QUOTE_SITE_QUOTEID_SEARCH}${tabType}/?limit=20&&page=1`
                                : `${SALES_QUOTE_SITE_QUOTEID_SEARCH}${tabType}/?limit=20&&page=1`,
                            key : switchableSearchFnVal.value==='clients' ? 'key' : 
                            switchableSearchFnVal.value==='job_code' ? 'quote_id' :
                            'quote_id',
                            disp: (res) => ({ type: 'QUOTE_REGISTER', quotes: res, page: 1 }),
                            methodType : switchableSearchFnVal.value==='clients' ? 'post' : 'get'
                        }}
                        sortByFilterFn={{
                            k: 'key',
                            name: 'site',
                            disp: fetch_data_filter
                        }}
                        
                        switchableSearchObj={{
                            switchableSearchFn : switchableSearchFn,
                            switchableSearchFnVal : switchableSearchFnVal,
                            options : [
                                { label : 'Clients', value : 'clients' },
                                // { label : 'Quote No', value : 'quote' },
                                { label : 'Quote No', value : 'job_code' }
                            ],

                        }}
                    >
                        <table className="sales-table" style={{ position: 'relative', height: 'auto' }}>
                            <thead>
                                <tr className="table-head">
                                    <td>Quote No</td>
                                    {/* <td>Client Ref. Code</td> */}
                                    <td>Date</td>
                                    <td>Customer</td>
                                    {/* <td>Contact Name</td> */}
                                    <td>Contact Number</td>



                                    <td>Sales Person</td>
                                    <td>Won/Loss Date</td>
                                    <td>View</td>
                                    <td>Status</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // tableLoader ?
                                    //     <div style={{ display: 'flex', height:'100%', width:'100%', position:'absolute', justifyContent: 'center', alignItems: 'center' }} >
                                    //         <CircularProgress />
                                    //     </div>  :
                                    quote_register && quote_register.length === 0 ?
                                        !tableLoader &&
                                        <div style={{ display: 'flex', height: '100%', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center' }} >
                                            <NoDatasScreen />
                                        </div>
                                        :

                                        quote_register && quote_register.map((item, key) => {
                                            // console.log('quote reg waste case', item)
                                            const { id, date, customer, contact_number, status, sale_person, won_reject_date, schedule_status,
                                                is_quote_through_recurring, parent_quote_status, parent_quote_id, reoccurring, sales_team_review } = item
                                            return (
                                                <tr key={key} style={{ backgroundColor: key % 2 === 0 ? 'whitesmoke' : 'transparent' }} >
                                                    <Tooltip
                                                        //open={hoverVal === item.id}
                                                        title={`Reoccuring Quote Created on ${moment(item.created_date_time).format('DD-MM-YYYY')}`}
                                                        arrow={true} placement={'right'}>
                                                        <td style={{ position: 'relative' }}>
                                                            QUOTE{item.job_card_code}
                                                            {/* {id} */}
                                                            {parent_quote_id === null && reoccurring ? <span

                                                                // onMouseEnter={() => { setHoverVal(item.id) }}
                                                                // onMouseLeave={() => { setHoverVal(null) }}

                                                                className="indicatedDot"></span> : null}
                                                        </td>
                                                    </Tooltip>
                                                    {/* <td>{item.job_card_code}</td> */}
                                                    <td>{moment(date).format('DD-MM-YYYY')}</td>
                                                    <td style={{ wordWrap: 'break-word', width: '18%' }}>{customer}</td>

                                                    <td>{contact_number}</td>
                                                    {/* <td>{job_type}</td> */}


                                                    <td style={{ wordWrap: 'break-word', width: '12%' }}>{is_quote_through_recurring ? 'Reoccured' : sale_person}</td>
                                                    <td
                                                        style={{
                                                            backgroundColor:
                                                                item.won_lose_status ?
                                                                    item.won_lose_status === 'won' ? '#bbf1c1' :
                                                                        item.won_lose_status === 'rejected' || item.won_lose_status === 'lost' || item.won_lose_status === 'loss' ? '#f1c2bb' : '#f1efbb'
                                                                    : 'transparent'
                                                        }}
                                                    >{
                                                            item.won_lose_status ?
                                                                item.won_lose_status === 'won' ? `Won date ` :
                                                                    item.won_lose_status === 'rejected' || item.won_lose_status === 'lost' || item.won_lose_status === 'loss' ? `Loss date ` : 'Pending'
                                                                : ''
                                                        }
                                                        <br/>
                                                        {
                                                            won_reject_date !== '' && won_reject_date !== null ?
                                                                moment(won_reject_date).format('DD-MM-YYYY')
                                                                : ''
                                                        }</td>
                                                    {/* {   status!=='pending' && status!=='accepted' ? */}
                                                    <td>
                                                        <div style={{
                                                            display: 'flex', flexDirection: 'column',
                                                            alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'
                                                        }}>
                                                            {/* {
                                                                salesQuoteSpinnerBtnIndex === key ? (
                                                                    <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                                ) : (
                                                                    <button onClick={() =>{
                                                                        setJobId(item.id)
                                                                         fetchQuoteTemplate(item.id, key)}} className="viewBtn">View Template</button>
                                                                )
                                                            }
                                                            {
                                                                sOWSpinnerBtnIndex === key ? (
                                                                    <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                                ) : (
                                                                    <button onClick={() =>{
                                                                        setJobId(item.id)
                                                                        getTypeOfWaste(item.id, key)
                                                                         }} className="viewBtn" style={{ marginTop:'3%' }}>Update Pricing</button>
                                                                )
                                                            } */}
                                                            {/* </div>
                                                    </td>
                                                    :
                                                    <td>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}> */}
                                                            {
                                                                quoteSpinner && quoteSpinnerBtnIndex === key ? (
                                                                    <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                                ) : (
                                                                    <button onClick={() => fetchQuoteInfos(id, key, item.tab_type === 'pumps' ? item.job_card_id : null, parent_quote_status, parent_quote_id)} className="viewBtn"
                                                                        style={{ marginTop: '3%' }}>View Quote</button>
                                                                )
                                                            }

                                                        </div>
                                                    </td>
                                                    {/* } */}
                                                    {
                                                        schedule_status ? (
                                                            <td style={{ opacity: '0.5' }}>
                                                                <div className="quoteStatusButtonDiv">
                                                                    <div
                                                                        className="quoteStatusButton quoteStatusAccept disabled-btn"
                                                                        style={{
                                                                            backgroundColor: sales_team_review ? 'var(--cl_green)' : 'var(--cl_dark_orange)',
                                                                            color: 'black', fontWeight: '600', pointerEvents: 'none', cursor: 'default', width: '80%'
                                                                        }}>Sales Team{sales_team_review ? ' ' : ' To '}Approve{sales_team_review ? 'd' : ''}</div>

                                                                </div>
                                                            </td>
                                                        ) : (
                                                            <td>{status === "pending" ?
                                                                <div className="quoteStatusButtonDiv">
                                                                    <div
                                                                        className="quoteStatusButton quoteStatusAccept"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            changeStatus("accepted", id);
                                                                        }}>Accept</div>
                                                                    <div
                                                                        className="quoteStatusButton quoteStatusReject"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            changeStatus("rejected", id);
                                                                        }}>Reject</div>
                                                                </div>
                                                                :
                                                                status === "accepted" ?
                                                                    <div className="quoteStatusButtonDiv">
                                                                        <div
                                                                            className="quoteStatusButton quoteStatusAccept disabled-btn"
                                                                            style={{
                                                                                backgroundColor: sales_team_review ? 'var(--cl_green)' : 'var(--cl_dark_orange)', color: 'black', fontSize: "12px",
                                                                                fontWeight: '600', pointerEvents: 'none', cursor: 'default', width: '80%', padding: "2px"
                                                                            }}>Sales Team{sales_team_review ? ' ' : ' To '}Approve{sales_team_review ? 'd' : ''}</div>

                                                                    </div>
                                                                    :
                                                                    <div className="quoteStatusButtonDiv">
                                                                        <div
                                                                            className="quoteStatusButton quoteStatusReject disabled-btn"
                                                                            style={{
                                                                                color: 'black', fontWeight: '600', pointerEvents: 'none', cursor: 'default'
                                                                            }}>Rejected</div>
                                                                    </div>
                                                            }
                                                            </td>
                                                        )
                                                    }


                                                    <td style={{ width: '8vw' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                            {
                                                                resnedSpinner && quoteSpinnerBtnIndex === key ? (
                                                                    <button className="viewBtn" style={{ width: '6vw', minWidth: '0', backgroundColor: 'white', color: '#fff', fontWeight: '700' }}
                                                                    >
                                                                        <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                                    </button>

                                                                ) : (
                                                                    <button onClick={() => {
                                                                        setMailSubj(item.mail_subject)
                                                                        setMailBody(item.mail_body)
                                                                        setMailCC(item.mail_cc)
                                                                        setMailBCC(item.mail_bcc)
                                                                        setresendModal({ status: true, qObj: item, qK: key })
                                                                        // resendMail(id, key)
                                                                    }} className="viewBtn" style={{ width: '6vw', marginRight: '.5rem' }} >Resend Email</button>
                                                                )
                                                            }
                                                            {
                                                                deleteQuoteIdx===id ?
                                                                <button className="viewBtn" style={{ width: '6vw', minWidth: '0', backgroundColor: 'white', color: '#fff', fontWeight: '700' }}>
                                                                    <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                                                </button>
                                                                :
                                                                <button className="viewBtn" style={{ width: '6vw', minWidth: '0', backgroundColor: 'var(--cl_red)', color: '#fff', fontWeight: '700' }}
                                                                onClick={() => deleteQuote(id)}
                                                                >
                                                                    Delete Quote
                                                                </button>
                                                            }
                                                            
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                                {/* {
                                    [...Array(balance)].map((item, key) => {
                                        return (
                                            <tr key={key}><td></td></tr>
                                        )
                                    })
                                } */}
                            </tbody>
                        </table>
                    </TableLayout>
                </SalesHomeList>
                <SalesExcecutieves />
            </div>
            <QuoteModal
                modalStatus={quoteModal}
                modalAction={setquoteModal}
                quoteInfos={quoteInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteHistoryModalAction={setQuoteHistoryModal}
                jobVal={jobVal}
                parentQuoteStatus={parentQuoteStatus}
                parentQuoteId={parentQuoteId}
                fnRecall={() => fetch_quotes_list(quote_register_page, tabType)}
                fetch_quote_temp={fetch_quote_temp}
                setSOWModal={setSOWModal}
                setMainTemplate={setMainTemplate}
                setTypeofWaste={setTypeofWaste}
                setAddInfoList={setAddInfoList}
                setJobId={setJobId}

            />

            <QuoteHistoryModalView
                modalStatus={QuoteHistoryModal}
                modalAction={setQuoteHistoryModal}
                quoteHistoryInfos={quoteHistoryInfos}
                setQuoteHistoryInfos={setQuoteHistoryInfos}
                quoteId={quoteInfos ? quoteInfos.quote ? quoteInfos.quote.id : null : null}
                quoteModalAction={setquoteModal}
            />
            <ResendModal
                modalStatus={resendModal}
                modalAction={setresendModal}
                MailCC={MailCC}
                MailBCC={MailBCC}
                setMailCC={setMailCC}
                setMailBCC={setMailBCC}
                setMailBody={setMailBody}
                setMailSubj={setMailSubj}
                mailBody={mailBody}
                mailSubj={mailSubj}
                resendFn={resendMail}
            />
            {
                mainTemplate && jobId!==null ?
                    <EditableEditorModal
                        jobId={jobId}
                        Tempname={'Main Quote'}
                        modalStatus={mainTemplate}
                        modalAction={setMainTemplate}
                        editorRef={templateRef}
                        reboundFn={reboundFn}
                        reEditting={true}
                    />
                    : null
            }
            {
                sOWModal && jobId!==null ?
                    <EditTableModal
                        jobId={jobId}
                        modalStatus={sOWModal}
                        modalAction={setSOWModal}
                        QAmt={0}
                        typeOfWaste={typeOfWaste}
                        setTypeofWaste={setTypeofWaste}
                        addInfoList={addInfoList}
                        setAddInfoList={setAddInfoList}
                        reboundFn={reboundFn}
                        reEditting={true}
                        setTemplateStr={set_quote_mainpage_content}
                        templateStr={quote_mainpage_content}
                        tableDataObj={quote_table_obj}
                    />
                    : null
            }

        </div>
    )
}

const mapStateToProps = (state) => {
    const { quote_register, quote_register_page, tableLoader, quote_register_loader, quote_stop_pagination, isLoading } = state.sales
    const { quote_mainpage_content, quote_table_obj } = state.mainQuote

    return { quote_register, quote_register_page, tableLoader, quote_register_loader, quote_stop_pagination, isLoading, quote_mainpage_content, quote_table_obj }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_quotes_list: (page_no, tabType) => dispatch(fetch_common_quote_registers_list(page_no, tabType)),
        fetch_data_filter: (content, type, page, tabType) => dispatch(sales_quote_filter(content, type, page, tabType)),
        fetch_quote_temp: (id, successFx, failedFx) => dispatch(fetch_curr_template(id, successFx, failedFx)),
        refresh_page: () => dispatch(reloadPage()),
        fetch_search_data: (type, content, page, searchType) => dispatch(sales_quoteReg_client(type, content, page, searchType)),
        set_quote_mainpage_content: (content) => dispatch({ type: 'SET_MAIN_CONTENT_QUOTE', content: content }),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRegister)
