import React from 'react'
import { EnviroBrandLogo } from '../../../common/Images'

function Header() {
    return (
        <div className="common-header">
            <div className="common-brand-layout">
                <img className="common-brand-img" src={EnviroBrandLogo} alt='enviro logo' />
            </div>
        </div>
    )
}

export default Header
