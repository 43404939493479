import React, {useState} from 'react'
// import { Link } from 'react-router-dom'
import Scrollbar from '../../../../../common/ImprovedScrollBar'
import ClientChart from './Clientchart'
import ClientWork from './ClientWork'
//import Clientfolder from'./Clientfile'
import ClientWorkExpand from './ClientWorkExpand'
// import { client_file_delete, client_file_upload, client_folders } from '../../../action'
import "./FileChartStyle.css"

// import FolderApp from '../../../../../common/folder/folder_main'
import { connect } from 'react-redux'
// import { CircularProgress } from '@material-ui/core'
// import Swal from 'sweetalert2'
import IntranetLayout from '../../../../../common/filesNFolder/FileFoldersLayout'
import { INTRANET_FILES_RENAME, INTRANET_FOLDER_EDIT, OHS_AND_S_FILE_DELETE, OHS_AND_S_FOLDER_DELETE, SITE_FILESANDFOLDERS_GET, SITE_FILES_CREATE, 
   //SITE_FOLDER_CREATE, 
   SITE_PRIVATE_FOLDER_CREATE } from '../../../../../store/endpoint'
import ClientAttach from './ClientAttach'

function App({client_id, tabType, permissions}) {

   // const [folderLoader, setFolderLoader] = useState(true)
   // const [folderResponse, setFolderResponse] = useState([])
   // const [folderError, setFolderError] = useState(false)
   // const [folderStatus, setFolderStatus] = useState('')

   // useEffect(() => {
   //    client_folders(client_id, apiResponseCallBack)
   // }, [client_id])

   // const apiResponseCallBack = ({response, loader, error, status}) => {
   //    setFolderResponse(response)
   //    setFolderStatus(status)
   //    setFolderLoader(loader)
   //    setFolderError(error)
   // }

//    const uploadFileFeedback = (FilesData, folder_id) => {
//       let formBody = new FormData()
//         let i=0;
//         formBody.append('folder', folder_id)
//         for(i=0; i<FilesData.length; i++) {
//             formBody.append('attachments_list', FilesData[i], FilesData[i].name)
//         }
//       client_file_upload(client_id, formBody, apiRecall)
//    }

//    const apiRecall = () => {
//       client_folders(client_id, apiResponseCallBack)
//    }

//    const deleteFile = (id) => {
//       const success = () => {
//          Swal.fire({
//              position: 'top-end',
//              icon: 'success',
//              title: 'File Deleted successfull!',
//              showConfirmButton: false,
//              timer: 1500
//          })
//          apiRecall()
//       }
//       const failed = () => {
//          Swal.fire({
//              icon: 'error',
//              title: 'Oops...',
//              text: 'Something went wrong!'
//          })
//       }
//       client_file_delete(id, success, failed)
//   }

   // const FoldersWidget = () => {
   //    if(folderLoader) {
   //       return (
   //          <div className="folder-spinner-layout">
   //             <CircularProgress />
   //          </div>
   //       )
   //    } else if(folderError) {
   //       return (
   //          <div className="folder-spinner-layout">
   //             <p>{folderStatus}</p>
   //          </div>
   //       )
   //    } else return (
   //       <FolderApp  
   //          data={folderResponse}
   //          updation={true}
   //          updationFunction={uploadFileFeedback}
   //          deletion={deleteFile}
   //       />
   //    ) 
   // }

    const [Graphdiv, setGraphdiv] = useState(2);
    console.log(tabType)
    if(Graphdiv=== 0) {
        return (
         <div>
            <div className="add-client-right-top">
               <div  className='folder-btnMainDiv'>
                  <div className='folder-btnTab' onClick={()=>setGraphdiv(2)}>Files</div>
                  {  tabType==='pumps' ?
                     <div className='folder-btnTab' onClick={()=>setGraphdiv(3)}>Assets</div>   : null
                  }
                  <div className='folder-btnTab folder-btn-activeTab' onClick={()=>setGraphdiv(0)}>Sales Chart</div>
                  
               </div>
               {/* <Link to="/client/ClientPhoto" style={{ textDecoration: 'none' }}>
                   <div className='folder-btn' style={{ color:'black' }}>Client Photo</div>
               </Link>                             */}
            </div>
            <div className="add-client-right-center">
               <ClientChart setGraphdiv={setGraphdiv}/>
            </div>
            <div className="add-client-right-bottom">
               <ClientWork empId={client_id}  setGraphdiv={setGraphdiv} tabType={tabType}/>
            </div>
         </div> 
        )
    } else if(Graphdiv===1){
        return (
             <div>
               <div className="add-client-right-top">
               <div  className='folder-btnMainDiv'>
                  <div className='folder-btnTab' onClick={()=>setGraphdiv(2)}>Files</div>
                  {  tabType==='pumps' ?
                     <div className='folder-btnTab' onClick={()=>setGraphdiv(3)}>Assets</div>   : null
                  }
                  <div className='folder-btnTab' onClick={()=>setGraphdiv(0)}>Sales Chart</div>
                                 
               </div>
               </div>
               <div className="add-client-right-work">
                  <ClientWorkExpand empId={client_id} setGraphdiv={setGraphdiv}/>
               </div>
            </div>            
        )
    }
    else if(Graphdiv===2){
        return(
          <div>
            <div className="add-client-right-top">
               <div  className='folder-btnMainDiv'>
                  <div className='folder-btnTab folder-btn-activeTab' onClick={()=>setGraphdiv(2)}>Files</div>
                  {  tabType==='pumps' ?
                     <div className='folder-btnTab' onClick={()=>setGraphdiv(3)}>Assets</div>   : null
                  }
                  <div className='folder-btnTab' onClick={()=>setGraphdiv(0)}>Sales Chart</div>
                   
               </div>
               
            </div>
            <Scrollbar autoHide style={{ height: '41.15vh', width: '46.8vw' }} className="add-client-right-center">
               {/* <FoldersWidget /> */}
               <IntranetLayout
                APIURL={`${SITE_FILESANDFOLDERS_GET}${client_id}/`} title={'Site Folders'} 
                SELECTID={client_id}
                FILEEDIT={ permissions.site.edit ? INTRANET_FILES_RENAME : null}
                FOLDEREDIT={ permissions.site.edit ? INTRANET_FOLDER_EDIT : null} 
                FILEADD={ permissions.site.add ? SITE_FILES_CREATE : null} 
                FOLDERCREATE={ permissions.site.add ? SITE_PRIVATE_FOLDER_CREATE : null} 
                FOLDERDELETE={  permissions.site.delete ? OHS_AND_S_FOLDER_DELETE : null} 
                FILEDELETE={ permissions.site.delete ? OHS_AND_S_FILE_DELETE : null}
               />
            </Scrollbar>
           
            <div className="add-client-right-bottom">
               <ClientWork empId={client_id}  setGraphdiv={setGraphdiv} tabType={tabType}/>
            </div>
          </div> 
        )
    }
    else{
      return(
         <div>
           <div className="add-client-right-top">
              <div  className='folder-btnMainDiv'>
                 <div className='folder-btnTab' onClick={()=>setGraphdiv(2)}>Files</div>
                 {  tabType==='pumps' ?
                     <div className='folder-btnTab folder-btn-activeTab' onClick={()=>setGraphdiv(3)}>Assets</div>   : null
                  }
                 <div className='folder-btnTab' onClick={()=>setGraphdiv(0)}>Sales Chart</div>
                 
              </div>
              
           </div>
            <Scrollbar autoHide style={{ height: '41.15vh', width: '46.8vw' }} className="add-client-right-center">
              <ClientAttach empId={client_id}  setGraphdiv={setGraphdiv}/>
            </Scrollbar>
           <div className="add-client-right-bottom">
              <ClientWork empId={client_id}  setGraphdiv={setGraphdiv} tabType={tabType}/>
           </div>
         </div> 
       )
    }
                 
}

const mapStateToProps = (state) => {
   const { selected_client } = state.client
   const { permissions } = state.userLogin
   return { client_id: selected_client.id, permissions }
}

export default connect(mapStateToProps) (App)
