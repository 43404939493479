import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Scrollview from '../../../common/ImprovedScrollBar';
import Swal from 'sweetalert2'
import { cl_green, cl_red } from '../../../common/Colors';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DescriptionIcon from '@material-ui/icons/Description';
import { del_single_draft, fetch_folders_under_files, fetch_quote_drafts, fetch_temp_clients, fetch_waste_templates, reloadTemplates } from '../common/action';
import { connect } from 'react-redux';
import { CLIENT_SINGLE_FETCH, HOST_WITHOUT_SLASH, NEWJOBCARD_DELETE, NEWJOBCARD_VIEW } from '../../../store/endpoint';
import NoDatasScreen from '../../../common/no_datas'
import { CircularProgress } from '@material-ui/core';
import moment from 'moment'
import { AddCircleOutlined, DeleteForeverOutlined, EditOutlined, Search } from '@material-ui/icons';
import { SearchInputTab } from '../../../common/SearchInputTab';

import NewJobCard from '../../jobs/components/WasteJobCard';
import { listJobCards, refresh_job_view, searchJobCard } from '../../jobs/action';
import { fetch_client_list } from '../../client/action';
import Api from '../../../store/api'
import JobCardTypeModal from '../component/JobCardTypeModal';

function GenerateQuote({ fetch_templates, under_folders, quote_templates, fetch_under_folders, sale_draft_template_list, fetch_drafts, perm_Clients,
    setQuoteSavedDraft, tableLoader, jobCard_list, fetch_jobCards, fetch_perClients, fetch_tempClients, client_list, temp_client, setSelClient,
    jobCardSearchQ,  reloadTempData, reloadJobData, quote_template_Loader, sale_draft_template_loader, listLoader, delSingleDraft,
    stopPagingJobCard, jobCardListPage, user, set_quote_amount, set_quote_content }) {

    const base_route = '/sales/waste'
    useEffect(() => {
        fetch_templates()
        fetch_under_folders()
        fetch_drafts('waste')
        fetch_jobCards('waste', 1)
        
        reloadTempData()
        reloadJobData()
        return () => fetch_templates.remove
    }, [fetch_templates, fetch_under_folders, fetch_drafts, fetch_jobCards, reloadTempData, reloadJobData])
    

    const genQuoteData = [
        {
            id: 1,
            name: 'Quote Template',
        }
    ]

    const [page, setPage] = useState(0)
    const [jobModal, setJobModal] = useState(false)
    const [jobObj, setJobObj] = useState(null)
    const [jobModalType, setJobModalType] = useState('')
    const [crtJobLoad, setCrtJobLoad] = useState(false)
    const [searchData, setSearchData] = useState(false)

    const [jobListPageNo, setJobListPageNo] = useState(1)
    const [jobCardTypeStatus, setJobCardTypeStatus] = useState(false)
    const [jobCardTypeK, setJobCardTypeK] = useState(0)
    // const [selClientDet, setSelClient] = useState(null)

    let totalFolders = genQuoteData.concat(under_folders)

    const jobCardTypeAction = (k) =>{
        setJobCardTypeK(k)
        setJobCardTypeStatus(false)

        setCrtJobLoad(false);
        setJobObj(null)
        setJobModalType('add')
        setJobModal(true);
    }

    const delDraftCl = (val) =>{

        const success = () =>{
            fetch_drafts('waste')
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Draft deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
        }

        const failed = () =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
            
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                delSingleDraft(val,   
                success,                                
                failed)
        }
    });
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
   
    const delJobCard = (jobId) =>{
        const success = () => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Job Card deleted successful!',
                showConfirmButton: false,
                timer: 1500
            })
            fetch_jobCards('waste',1)
        }

        const failed = () => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: cl_red,
            cancelButtonColor: cl_green,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                let api_url = `${NEWJOBCARD_DELETE}${jobId}/`
                Api('delete', api_url, '', success, failed)
            }
        })
    
    }

    const SingleFolder = ({item, index}) => {
        return (
            <div 
                onClick={() => setPage(index)} 
                style={{ marginLeft: 0 }} className="intranet-single-file-layout">
                <div className="intranet-single-index">
                    <p>{index + 1}</p>
                </div>
                <div className={index === page ? "intraner-single-label-active" : "intraner-single-label"}>
                <p>{item?.name}</p>
                </div>
            </div>
        )
    }

    const cropFileName = (str) => {
        let lastIndx = str ? str.length : 'Untitled'
    return lastIndx >15 ? str.substring(0,5)+ '...' + str.substring(lastIndx-5,lastIndx): str;
    }

    const loadMore = (val) => {
        // if(tabVal===0){
        const { top } = val
        // console.log('SCROLL', top, stopPagingJobCard, jobListPageNo, jobCardListPage, (top === 1 && !stopPagingJobCard && ((jobListPageNo === jobCardListPage) || (jobCardListPage === 1 && jobListPageNo !== 1))))
        if (top === 1 && !stopPagingJobCard && ((jobListPageNo === jobCardListPage) || (jobCardListPage === 1 && jobListPageNo !== 1))) {
            setJobListPageNo(jobCardListPage===1 ? 2 : jobListPageNo+1)
            fetch_jobCards('waste', jobCardListPage+1)
        }

    }

    const refreshJobCardData = () => {
        fetch_jobCards('waste', 1)
    }

    const TemplateWidget = ({val}) => {
        if(page === 0) {
            if(val==='draft')
            return (
                <div className="sales-qt-gnrt-template-main">
                    {   
                        sale_draft_template_list.map((item, key) => {
                            const { id, client_name, created_date_time, edited_date_time } = item
                            // console.log("DRAFTS ",item)
                            return (
                                <Link to={base_route + `/generate-quote/${id}`} key={key} className="sales-qt-rgrt-template-single draftTile"
                                    data-testid={`draftId${id}`}
                                    onClick={()=>{
                                        Api('get',`${CLIENT_SINGLE_FETCH}${item.client}/`,'')
                                        .then(response => {
                                            setSelClient(temp_client.some(el=>el.id===item.client) ?
                                            {
                                                client          :   item.client,
                                                client_email    :   response.client_email,
                                                ...response
                                            }   :
                                            {
                                                client          :   item.client,
                                                client_email    :   response.client_email,
                                                ...response
                                            }
                                            )
                                        })
                                        if(item && item.template)
                                            set_quote_content(item.template)
                                        if(item && (item.amount || item.invoice_amount || item.invoice_amt))
                                            set_quote_amount(item.amount ? item.amount :
                                                 item.invoice_amount ? item.invoice_amount : item.invoice_amt)
                                        setQuoteSavedDraft({...item, table_data :  isJsonString(item && item.table_data) ? item.table_data : null})
                                    }}
                                >
                                    <div style={{ display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'space-evenly' }}>
                                        <p style={{ fontSize: 16, fontWeight:'600' }}>{cropFileName(client_name)}</p>
                                        <p style={{ fontSize: 14, fontWeight:'400' }}>{item.sales_person}</p>
                                        <span  style={{ fontSize: 12, fontWeight:'300', padding:'10px 5px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                                            <div>
                                                <p style={{ fontWeight:'500' }}>Created :</p><p> {moment(created_date_time).format('DD-MM-YYYY HH:mm:ss')}</p>
                                            </div>
                                            <div>
                                                <p style={{ fontWeight:'500' }}>Modified :</p><p> {moment(edited_date_time).format('DD-MM-YYYY HH:mm:ss')}</p>
                                            </div>
                                        </span>
                                        <span  style={{ fontSize: 6, fontWeight:'300', padding:'5px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                                            <div  className="cardEditDelBtn"  onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); delDraftCl(id); }} >
                                                <DeleteForeverOutlined  style={{ fontSize: 16 }}  />
                                            </div>
                                        </span>
                                    </div>
                                    
                                </Link>
                            )
                        })
                    }
                </div>
            )
            else if(val==='job')
            return (
                <div className="sales-qt-gnrt-template-main">
                    {
                        jobCard_list.map((item, key) => {
                            // console.log("DRAFTS ",item)
                            return (
                                // <Link to={base_route + `/generate-quote/${id}`} key={key} */}
                                <div className="sales-qt-rgrt-template-single draftTile" key={key}
                                data-testid={`jobCard${key}`}
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        e.stopPropagation()
                                        fetch_perClients(); fetch_tempClients();
                                        if(client_list && temp_client){
                                            setJobObj(item)
                                            setJobModalType('view')
                                            setJobModal(true); 
                                        }
                                    }}
                                 >
                                    <div style={{ display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'space-evenly' }}>
                                        <p style={{ fontSize: 16, fontWeight:'600' }}>{cropFileName(item.job_card_name ? item.job_card_name : item.client_name)}</p>
                                        { item.type_of_waste ? <p style={{ fontSize: 14, fontWeight:'400' }}>{item.type_of_waste}</p> : null}
                                        <span  style={{ fontSize: 12, fontWeight:'300', padding:'10px 5px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                                            <div>
                                                <p style={{ fontWeight:'500' }}>Created :</p><p> {moment(item.created_date_time).format('DD-MM-YYYY HH:mm:ss')}</p>
                                            </div>
                                            <div>
                                                <p style={{ fontWeight:'500' }}>Modified :</p><p> {moment(item.created_date_time).format('DD-MM-YYYY HH:mm:ss')}</p>
                                            </div>
                                        </span>
                                        <span  style={{ fontSize: 6, fontWeight:'300', padding:'5px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                                            <div className="cardEditDelBtn" onClick={(e)=>{
                                                e.preventDefault()
                                                e.stopPropagation()
                                                fetch_perClients(); fetch_tempClients();
                                                if(client_list && temp_client)
                                                setJobObj(item)
                                                setJobModalType('edit')
                                                setJobModal(true);
                                            }}>
                                                <EditOutlined style={{ fontSize: 16 }} />
                                            </div>
                                            {   item.connected ? null :
                                            <div  className="cardEditDelBtn" onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); delJobCard(item.id); }} >
                                                <DeleteForeverOutlined  style={{ fontSize: 16 }}  />
                                            </div>
                                            }
                                            <div  className={ !item.connected ? "cardActiveQuoteBtn" : "cardNotActiveQuoteBtn"}></div>
                                            
                                        </span>
                                        <div>
                                            <p style={{ fontWeight:'500', fontSize: 10 }}>{ !item.connected ? 'Available' : 'Used'} Job Card</p>
                                        </div>
                                    </div>
                                </div>    
                                // </Link>
                            )
                        })
                    } 
                </div>
            )
            else
            return (
                <div className="sales-qt-gnrt-template-main">
                    {   quote_templates ?
                        quote_templates.map((item, key) => {
                            const { id, template_name } = item
                            return (
                                <Link to={base_route + `/generate-quote/${id}`} key={key} className="sales-qt-rgrt-template-single"
                                onClick={()=>setQuoteSavedDraft(null)}
                                data-testid={template_name}
                                >
                                    <DescriptionIcon style={{ fontSize: 18, marginRight: 10 }} />
                                    <p>{template_name}</p>
                                    
                                </Link>
                            )
                        })
                        :
                        <NoDatasScreen />
                    }
                </div>
            )
        } else {
            return (
                <div className="sales-qt-gnrt-template-main">
                    {
                        totalFolders[page].files.map((item, key) => {
                            const { name, url } = item
                            let newPageUrl = HOST_WITHOUT_SLASH + url
                            return (
                                <div onClick={() => window.open(newPageUrl, "_blank")}  key={key} className="sales-qt-rgrt-template-single">
                                    <DescriptionIcon style={{ fontSize: 18, marginRight: 10 }} />
                                    {/* <p style={{ height: 20, width: 50 }}>{name} </p> */}
                                    <div style={{ display: 'block' }}>
                                        <p style={{ overflow: 'hidden', height: '15px', width: '100px', color: 'black' }}>{name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        totalFolders[page].files.length === 0 && (
                            <p style={{ fontSize: 12 }}>No files found in folder</p>
                        )
                    }
                </div>
            )
        } 
    }

    return (
        <div>
            <div className="navbar-layout-back-btn">
                <div 
                    style={{pointerEvents: 'none'}}
                    className="navbar-button">
                       <EventNoteIcon style={{ fontSize: 18, marginRight: 10 }} />  Generate Quote
                </div>
                <Link 
                    to={base_route}
                    style={{ backgroundColor: 'grey', color: 'white' }}
                    className="navbar-button">
                        Back
                </Link>
            </div>
            <div className="sales-generate-quote-layout">
                
                    <div className="sales-qt-gnrt-left">
                        <Scrollview autoHide style={{ height: '75vh'}}>
                            {
                                totalFolders.map((item, key) => {
                                    return (
                                        <SingleFolder key={key} item={item} index={key} />
                                    )
                                })
                            }
                        </Scrollview>
                    </div>
                
                <div className="sales-qt-gnrt-right">
                    <div style={{ display:'flex', flexDirection:'column', width:'90%' }}>
                    <div className="sales-qt-gnrt-template-layout" style={{height:'30vh'}}>
                        <div className="sales-qt-template-header">
                            <p>{totalFolders[page].name}</p>
                            
                        </div>
                        <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', position:'relative'}}>
                            <Scrollview style={{ height: '20vh', padding: '10px 20px 5px 20px'}}>
                            {
                                quote_template_Loader ?
                            <div style={{ position:'absolute', display:'flex', alignItems:'center', justifyContent:'center', top:'50%', left:'50%'}}> 
                                <CircularProgress />
                            </div>    :
                            
                                <TemplateWidget />
                                
                            }
                            </Scrollview>

                        </div>
                        
                    </div>
                    {   page===0 ?    
                    <div className="sales-qt-gnrt-template-layout" style={{ marginTop: '20px', height:'40vh' }}>
                        <div className="sales-qt-template-header">
                            <p>Saved Drafts</p>            
                        </div>
                        <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height: '38vh'}}>
                        {   
                        sale_draft_template_loader ?
                        <CircularProgress/> :
                        sale_draft_template_list && sale_draft_template_list.length!==0 && page===0 ?
                            <Scrollview style={{ height: '30vh', padding: '0 20px 20px 20px'}}>
                                <TemplateWidget val={'draft'}/>
                            </Scrollview>
                        :   
                            <NoDatasScreen />
                        }
                        </div>                        
                    </div>  : null

                    }
                     </div>
                {   page===0 ?    
                    <div className="sales-qt-gnrt-template-layout" style={{ marginLeft: '20px' }}>
                        <div className="sales-qt-template-header" style={{ justifyContent:'space-between' }}>
                            <p>Job Cards</p>     
                            
                            {   searchData ?
                            <div className='Search-an-employe SearchWidthAdjust'>
                               {/* <input className='Search-an-employe-input' placeholder='Search JobCard'
                                    onChange={(txt)=>jobCardSearchQ(txt.target.value)}
                                /> */}
                                <SearchInputTab
                                    cls={'Search-an-employe-input'} txt={'Search JobCard'}
                                    url={`${NEWJOBCARD_VIEW}?limit=20`}
                                    methodType={'get'}
                                    dispatchFn={(res) => ({
                                        type    :   'GET_JOBCARDS_LISTPAGING',
                                        jobs    :   res,
                                        page    :   1
                                    })
                                    }
                                    fn={()=>{ jobCardSearchQ(''); setSearchData(false);  }}
                                />
                                {/* <HighlightOffOutlined style={{ fontSize: '16px' }} onClick={()=>{ jobCardSearchQ(''); setSearchData(false); }} /> */}
                                
                            </div>
                            :
                            <div className="sales-qt-template-header"  onClick={()=> setSearchData(true)}
                            style={{ justifyContent:'space-between', color:'white', cursor:'pointer' }}>
                            <Search style={{ color:'white' }}/><span>Search</span>
                            </div>
                            }
                            <div className="sales-qtGnrt-CreatBtn" onClick={()=>{ fetch_perClients(); fetch_tempClients(); setCrtJobLoad(true);
                                if(client_list && temp_client){
                                     
                                    setJobCardTypeStatus(true)
                                }
                                }}>
                                {   crtJobLoad  ?
                                <CircularProgress style={{ margin: 0, padding: 0 }} size={15} />
                                :
                                <p style={{ display:'flex', alignItems:'center' }}><AddCircleOutlined /> Create Job Card</p>
                                }
                            </div>       
                        </div>
                        <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height: '68vh'}}>
                        {   
                         listLoader ?
                        <CircularProgress/> :
                        jobCard_list && jobCard_list.length!==0 && page===0 ?
                            <Scrollview 
                            onScrollFrame={loadMore}
                            style={{ height: '64vh', padding: '0 20px 20px 20px'}}>
                                <TemplateWidget val={'job'}/>
                            </Scrollview>
                        :   
                            <NoDatasScreen />
                        }
                        </div>                        
                    </div>  : null

                }    
                </div>
            </div>
        {   jobModal    ?
        <div className={!jobModal ? "jobCardModalBgDiv" : "jobCardModalBgDiv jobCardModalBgDivClose"}>
            <NewJobCard setJobCardModal={setJobModal} 
            jobObj={
                jobObj===null ? { add_info_button  :   jobCardTypeK }
                :
                {
                ...jobObj, add_info_button  :   jobCardTypeK
            }} 
            
            modalType={jobModalType} tabType={'waste'} fallBackFn={refreshJobCardData}
            clientList={null}/>
        </div>  :   null
        }
            <JobCardTypeModal modalStatus={jobCardTypeStatus} modalAction={jobCardTypeAction} />
        </div>
    )
}


const mapStateToProps = (state) => {
    const { client_list } = state.client
    const { quote_templates, under_folders, sale_draft_template_list, tableLoader, temp_client, perm_Clients, quote_template_Loader, sale_draft_template_loader } = state.sales
    const { jobCard_list, listLoader, stopPagingJobCard, jobCardListPage } = state.jobView
    const { user } = state.userLogin

    return { user, quote_templates, under_folders, sale_draft_template_list, tableLoader, jobCard_list, client_list, perm_Clients, temp_client, quote_template_Loader, sale_draft_template_loader, listLoader, stopPagingJobCard, jobCardListPage }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetch_templates     : ()    => dispatch(fetch_waste_templates('waste')),
        fetch_perClients    : ()    => dispatch(fetch_client_list(null, 'waste')),
        fetch_tempClients   : ()    => dispatch(fetch_temp_clients('waste')),
        fetch_under_folders : ()    => dispatch(fetch_folders_under_files('waste')),
        fetch_drafts        : (type) => dispatch(fetch_quote_drafts(type)),
        fetch_jobCards      : (tab, page)    => dispatch(listJobCards(tab, page)),
        setQuoteSavedDraft  : (obj) => dispatch({ type:'SET_SAVED_DRAFT_CLIENT', content:obj }),
        jobCardSearchQ      : (k)   => dispatch(searchJobCard(k)),
        delSingleDraft      : (id, success, failed) => dispatch(del_single_draft(id, success, failed)),

        reloadTempData  : () => dispatch(reloadTemplates()),
        reloadJobData   : () => dispatch(refresh_job_view()),
        set_quote_content: (content) => dispatch({ type: 'SALES_SET_CONTENT_QUOTE', content: content }),
        set_quote_amount: (amount) => dispatch({ type: 'SALES_SET_QUOTE_AMOUNT', amount: amount })
      }
}


export default connect(mapStateToProps, mapDispatchToProps) (GenerateQuote)