import { ErrorRounded } from "@material-ui/icons"

export const TextAreaLimiter = ({ v=null, configs=null, isValid=null, max=250, divBodyConfig="" }) => {
    
        let currentCss = null
        let vLength = v ? v.length : 0
        let maxLen = max
        /*This isn't entirely necessary, just playin around*/
        if (vLength < (maxLen/6)) {
          currentCss = '#666'
        }
        else if (vLength > ((maxLen/6)-1) && vLength < (maxLen/3)) {
          currentCss = '#576d55'
        }
        else if (vLength > ((maxLen/3)-1) && vLength < (maxLen/2)) {
          currentCss = '#793535'
        }
        else if (vLength > ((maxLen/2)-1) && vLength < (4*(maxLen/6))) {
          currentCss = '#4b7532'
        }
        else if (vLength > ((4*(maxLen/6))-1) && vLength < (5*(maxLen/6))) {
          currentCss = '#499c00'
        }
        
        else if (vLength >= (5*(maxLen/6))) {
          currentCss = '#499c00'
        } else {
          currentCss = '#666'
        }

        
        return (
          <div style={{ 
            width:'100%',
            fontSize: '1rem', 
            position : 'relative'
            }} className={`${divBodyConfig}`}>
            <textarea
            maxLength={maxLen}
            {...configs}
            onKeyUp={()=>{ 
            if(vLength > maxLen && isValid!==null)
              isValid(false)
            else if(isValid!==null)
              isValid(true)
           }}
            style={{
              marginBottom : vLength!==0 && typeof v === 'string' ? '25px' : 'unset'
            }}
            />
            { vLength!==0 && typeof v === 'string' ?
            <div id="the-count"
                style={{
                    fontWeight : vLength >= (5*(maxLen/6)) ? 'bold' : 'normal',
                    color : vLength>=maxLen ? '#8f0001' : 'inherit',
                    position : 'absolute',
                    bottom: 0,
                    right : '0%',
                    borderRadius:'20px',
                    padding: '.1rem .5rem',
                    backgroundColor:'lightgrey'
                }}
            >
                { vLength > maxLen ? <ErrorRounded fontSize="inherit" color="red" /> : null}
                <span id="current" style={{ color : vLength>=maxLen ? '#8f0001' : currentCss }}>{vLength}</span>
                <span id="maximum">/ {maxLen}</span>
            </div> : null}
          </div>
        )
}
