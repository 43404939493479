let initialState = {
    jobList: [],
    scheduleOfTheDay: [],
    notificationList: [],
    graphData: [],
    graph_peak: 0,
    weather: {},
    isLoading: true
}

const homeGetData = (state = initialState, actions) =>{
    switch (actions.type) {
        case "HOME_GET_API" :   
            return{
                ...state,
                jobList: actions.home.job_list,
                scheduleOfTheDay: actions.home.schedule_of_the_day,
                notificationList: actions.home.Notifications,
                graphData: actions.home.graph_data,
                graph_peak: actions.home.graph_peak,
                isLoading : false
            }

        case "HOME_WEATHER_API" :   
            return{
                ...state,
                weather: actions.weather
            }

        case "UPDATE_HOMELOADING" : 
            return{
                ...state,
                isLoading : actions.loadStatus
            }
        default:
            return state
    }
}

export default homeGetData