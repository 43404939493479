import React, { useState, useEffect} from 'react';
// import DateTimeInput from '../../../../common/DateTimeInput'
// import {connect} from 'react-redux'
// import Swal from 'sweetalert2'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { CLIENT_SINGLE_FETCH, SALES_ALL_CLIENTS_LIST } from '../../../../store/endpoint';
import Api from '../../../../store/api'

function AssetCheck({ jobObj, modalType, tabType=null, preSelect=null, setAssetCheck, client_name, setClientName, clientId, setClientId, jobObjConnected, preSelectVal, 
    //TypeofWaste, setTypeofWaste
    // clientList, addJob, editJob, verifyJobCard=null, user 
}) {

    const [compName, setCompName]           = useState(modalType==='add' ? preSelect===null ? '' :
    preSelect.company_name ? preSelect.company_name : preSelect.client_name : jobObj===null ? '' : jobObj.company_name ? jobObj.company_name : jobObj.client_name  )

    const [submersible, setSubmersible]     = useState(modalType!=='add' ? jobObj===null ? 'Sewer' : jobObj.submersiable : 'Sewer')
    const [hotWaterCir, setHotWaterCir]     = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.hot_water_cir : '')
    const [pressure, setPressure]           = useState(modalType!=='add' ? jobObj===null ? 'Portable' : jobObj.pressure : 'Portable')
    const [oilSeparator, setOilSeparator]   = useState(modalType!=='add' ? jobObj===null ? 'Submersiable' : jobObj.oil_separator : 'Submersiable')
    const [coldWaterBooster, setColdWaterBooster] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.cold_water_booster : '')
    const [boilerFeed, setBoilerFeed]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.boiler_feed : '')
    const [transferPump, setTransferPump]   = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.transfer_pump : '')
    const [borePump, setBorePump]           = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.bore_pump : '')
    const [jobTechnician, setJobTechnician] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.submersiable : '')
    const [assetLocation, setAssetLocation] = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_loc : '')
    const [assetNo, setAssetNo]             = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.asset_no : '')
    const [custUnavail, setCustUnavail]     = useState(modalType!=='add' ? jobObj===null ? false : jobObj.cust_unavailable : false)
    const [startTime, setStartTime]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.start_time : '')
    const [finishTime, setFinishTime]       = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.finish_time : '')
    const [totalTime, setTotalTime]         = useState(modalType!=='add' ? jobObj===null ? '' : jobObj.total_time : '')


    const [compToBeChecked, setCompToBeChecked] = useState(modalType!=='add' ? jobObj===null ? [
        { comp: 'Control Panel', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Panel lights', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Auto Alternation', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Alarm Operation', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Gauges', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Isolation Valves', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Check valves', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Float Switches', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pump Strainers', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pit cond / slit build up', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Shaft Seal', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Bearings (greased)', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Mounting Bolts', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Coupling', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Corrosion', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Excessive Noice / Vibration', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pump Rotation', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pressure Controllers', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pump Housing Condition', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Rails/ chains/ Lids/ ladders', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pipe Work Condition', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pressure Switches', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pressure Vessel', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Leaks', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
    ]
     : jobObj.component_to_be_checked : [
        { comp: 'Control Panel', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Panel lights', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Auto Alternation', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Alarm Operation', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Gauges', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Isolation Valves', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Check valves', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Float Switches', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pump Strainers', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pit cond / slit build up', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Shaft Seal', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Bearings (greased)', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Mounting Bolts', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Coupling', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Corrosion', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Excessive Noice / Vibration', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pump Rotation', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pressure Controllers', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pump Housing Condition', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Rails/ chains/ Lids/ ladders', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pipe Work Condition', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pressure Switches', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Pressure Vessel', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
        { comp: 'Leaks', pass:'', fail:'', na:'', fixed:'', quote:'', comment:'' },
    ])

    const [electricMotor, setElectricMotor] = useState(modalType!=='add' ? jobObj===null ?  [
        { motor:'Motor Current Phase 1 AMPS/HR', no1:'', no2:'', no3:'', no4:'' },
        { motor:'Motor Current Phase 2 AMPS/HR', no1:'', no2:'', no3:'', no4:'' },
        { motor:'Motor Current Phase 3 AMPS/HR', no1:'', no2:'', no3:'', no4:'' },
        { motor:'Insulation Resisitance', no1:'', no2:'', no3:'', no4:'' },
        { motor:'Phase to Earth', no1:'', no2:'', no3:'', no4:'' },
    ] 
        :
        jobObj.electric_motor
        :
        [
            { motor:'Motor Current Phase 1 AMPS/HR', no1:'', no2:'', no3:'', no4:'' },
            { motor:'Motor Current Phase 2 AMPS/HR', no1:'', no2:'', no3:'', no4:'' },
            { motor:'Motor Current Phase 3 AMPS/HR', no1:'', no2:'', no3:'', no4:'' },
            { motor:'Insulation Resisitance', no1:'', no2:'', no3:'', no4:'' },
            { motor:'Phase to Earth', no1:'', no2:'', no3:'', no4:'' },
        ])

    const [assetsTable, setAssetsTable]       = useState(modalType!=='add' ? jobObj===null ?  [
            {no : 1, assets_id : '', work:'', part_used : '', part_quantity : ''}, 
            {no : 2, assets_id : '', work:'', part_used : '', part_quantity : ''}, 
            {no : 3, assets_id : '', work:'', part_used : '', part_quantity : ''}]
        :   jobObj.assets_table : 
        [
            {no : 1, assets_id : '', work:'', part_used : '', part_quantity : ''}, 
            {no : 2, assets_id : '', work:'', part_used : '', part_quantity : ''}, 
            {no : 3, assets_id : '', work:'', part_used : '', part_quantity : ''}])
    
    const [allClientsList, setAllClientsList] = useState([])
    const [allClientsLoader, setAllClientsLoader] = useState(true)


    useEffect(()=>{
        Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType}/`).then(
            res =>{
                console.log(res)
                setAllClientsLoader(false)
                if(res.length>0){
                    setAllClientsList(res)
                    if((modalType==='edit' || modalType==='add') && clientId!==null && clientId!==''){
                    
                        Api('get',`${CLIENT_SINGLE_FETCH}${clientId}/`,'')
                            .then(response => {
                                setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                setClientName(response.client_name)
                        })
                         
                    ; }
                }else 
                    setAllClientsList([])
            }
                
        )
    },[tabType, clientId, modalType, setClientName])
    


    const addRem = (val) =>{
        if(val){
            setAssetsTable(data => [...data, {
                no              :   assetsTable.length+1,
                assets_id       :   '',
                work            :   '',
                part_used       :   '',
                part_quantity   :   ''
            }])
        }
        else{
            let newArr = assetsTable.filter((_,key) => key!==(assetsTable.length-1))
            setAssetsTable(newArr)
        }
    }

    const addJobCard = () =>{
        let data = {
            card_id             :   '4',
            client              :   clientId,  
            client_name         :   client_name,
            company_name        :   compName,
            submersiable        :   submersible,
            hot_water_cir       :   hotWaterCir,
            pressure            :   pressure,
            oil_separator       :   oilSeparator,
            cold_water_booster  :   coldWaterBooster,
            boiler_feed         :   boilerFeed,
            transfer_pump       :   transferPump,
            bore_pump           :   borePump,
            technician          :   jobTechnician,
            asset_loc           :   assetLocation,
            asset_no            :   assetNo,
            cust_unavailable    :   custUnavail,
            start_time          :   startTime,
            finish_time         :   finishTime,
            total_time          :   totalTime,
            component_to_be_checked :   compToBeChecked,
            electric_motor      :   electricMotor,
            assets_table        :   assetsTable
        }
        setAssetCheck(data)
    }

    return (
        <div className="jobCardDiv1">
            <div className="jobCardDivHead" >Asset Check</div>
            <div className="jobCardDivInpContent">
                <div className="jobCardInputDivSec" >
                    <div>Company Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'|| preSelectVal!==null   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{compName}</p>   :
                            <select className="jobCardDivInp" value={clientId} 
                            style={{ pointerEvents: preSelectVal===null ? 'auto' : 'none',
                                         cursor: preSelectVal===null ? 'pointer' : 'default' }}
                            onChange={(e)=>{ 
                                if(!allClientsLoader){
                                setClientId(e.target.value);
                                Api('get',`${CLIENT_SINGLE_FETCH}${e.target.value}/`,'')
                                    .then(response => {
                                        setCompName(response.company_name==='' || response.company_name===null ? response.client_name : response.company_name);
                                        setClientName(response.client_name)
                                })
                                 
                            ; }
                                 }}>
                                {   
                                    allClientsLoader    ?
                                    <option>Loading ...</option>
                                    :
                                    allClientsList.map((item, key)=>{
                                        
                                        return(
                                            <option value={item.id} key={key}>{item.client_name} ({item.client_type})</option>
                                        )
                                    })
                                }
                            </select>
                            }
                            {/* <input type="text" className="jobCardDivInp" placeholder="Company Name" value={compName} onChange={(e)=>setCompName(e.target.value)} /> */}
                    </div>
                        
                </div>
                {/* <div className="jobCardDivInpContentDiv">
                        <div>Type of Waste</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view' || modalType==='verify' || modalType==='edit'   ?
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{TypeofWaste}</p>   :
                                preSelect===null ?
                            <select className="jobCardDivInp" value={TypeofWaste} 
                                style={{ pointerEvents: preSelect===null ? 'auto' : 'none',
                                         cursor: preSelect===null ? 'pointer' : 'default' }}
                                 onChange={val =>{ setTypeofWaste(val.target.value.toUpperCase());  }}>
                                    <option value='' >Select Option</option>
                                    <option value='AMMONIA'>AMMONIA</option>
                                    <option value='DILUTION PIT'>DILUTION PIT</option>
                                    <option value='DRUMS'>DRUMS</option>
                                    <option value='FOOD WASTE'>FOOD WASTE</option>
                                    <option value='GERNIE WASHES'>GERNIE WASHES</option>
                                    <option value='GREASE'>GREASE</option>
                                    <option value='GROUND WATER'>GROUND WATER</option>
                                    <option value='J120'>J120</option> 
                                    <option value='LEVY'>LEVY</option>
                                    <option value='M250'>M250</option>
                                    <option value='N205'>N205</option> 
                                    <option value='OIL SEPERATOR'>OIL SEPERATOR</option>
                                    <option value='PACKAGED WASTE'>PACKAGED WASTE</option>
                                    <option value='PRODUCT DESTRUCTION'>PRODUCT DESTRUCTION</option>
                                    <option value='PUMP SERVICE'>PUMP SERVICE</option>
                                    <option value='RETENTION PIT'>RETENTION PIT</option>
                                    <option value='SEWER'>SEWER</option>
                                    <option value='STORMWATER'>STORMWATER</option>
                                    <option value='TANK WASH OUT'>TANK WASH OUT</option>      
                                    <option value='TRADE WASTE'>TRADE WASTE</option>                                              
                                    <option value='TRANSPORT'>TRANSPORT</option>                            
                                    <option value='Z140'>Z140</option>
                                    <option value='WIEGH BRIDGE'>WEIGH BRIDGE</option>                                    
                                    <option value='DESTRUCTION CERTIFICATE'>DESTRUCTION CERTIFICATE</option>
                                    <option value='NOT APPLICABLE'>NOT APPLICABLE</option>                                
                            </select>
                            :
                            <p>{TypeofWaste}</p>
                            }
                        </div>
                        
                    </div>
                     */}
                <div className="jobCardInputDivSec" >
                    <div>Technician</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Technician" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                        }
                    </div>
                </div>

                <div className="jobCardInputDivSec" >
                    <div>Asset Location</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetLocation}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Asset Location" value={assetLocation} onChange={(e)=>setAssetLocation(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="jobCardInputDivSec" >
                    <div>Asset Number</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{assetNo}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Asset Number" value={assetNo} onChange={(e)=>setAssetNo(e.target.value)} />
                        }
                    </div>
                </div>

                <div className="jobCardDivHead" >Asset Information</div>
                <div className="jobCardInputDivSec" >
                    <div>Submersible</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{submersible}</p>   :
                        <select className="jobCardDivInp" value={submersible} onChange={(e)=> setSubmersible(e.target.value) } >
                            <option value={'Sewer'}>Sewer</option>
                            <option value={'Stormwater'}>Stormwater</option>
                            <option value={'Grease'}>Grease</option>
                        </select>
                        }
                    </div>
                </div>
                <div className="jobCardInputDivSec" >
                    <div>Hot Water Circulator</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{hotWaterCir}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Hot Water Circulator" value={hotWaterCir} onChange={(e)=>setHotWaterCir(e.target.value)} />
                        }
                    </div>
                </div>
                
                <div className="jobCardInputDivSec" >
                    <div>Pressure</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{pressure}</p>   :
                        <select className="jobCardDivInp" value={pressure} onChange={(e)=> setPressure(e.target.value) }>
                            <option value={'Portable'}>Portable</option>
                            <option value={'Non-Portable'}>Non-Portable</option>
                            <option value={'Hot Water'}>Hot Water</option>
                        </select>
                        }
                    </div>
                </div>
                <div className="jobCardInputDivSec" >
                    <div>Oil Separator</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{oilSeparator}</p>   :
                        <select className="jobCardDivInp" value={oilSeparator} onChange={(e)=> setOilSeparator(e.target.value) }>
                            <option value={'Submersiable'}>Submersiable</option>
                            <option value={'Diaphragm'}>Diaphragm</option>
                            <option value={'Mono'}>Mono</option>
                        </select>
                        }
                    </div>
                </div>
                
                <div className="jobCardInputDivSec" >
                    <div>Cold Water Booster</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{coldWaterBooster}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Cold Water Booster" value={coldWaterBooster} onChange={(e)=>setColdWaterBooster(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="jobCardInputDivSec" >
                    <div>Boiler Feed</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{boilerFeed}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Boiler Feed" value={boilerFeed} onChange={(e)=>setBoilerFeed(e.target.value)} />
                        }
                    </div>
                </div>
                
                <div className="jobCardInputDivSec" >
                    <div>Transfer Pump</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{transferPump}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Transfer Pump" value={transferPump} onChange={(e)=>setTransferPump(e.target.value)} />
                        }
                    </div>
                </div>
                <div className="jobCardInputDivSec" >
                    <div>Bore Pump</div>
                    <div>
                        <span>:</span>
                        {   modalType==='view'   ?   
                        <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{borePump}</p>   :
                        <input type="text" className="jobCardDivInp" placeholder="Bore Pump" value={borePump} onChange={(e)=>setBorePump(e.target.value)} />
                        }
                    </div>
                </div>
                
                <div className="jobCardDiv3">
                    <table className="jobCardDivTableContent jobCardTableStyle" >
                        <tbody style={{ fontSize:'1rem' }}>
                            <th style={{ maxWidth:'40px' }}>Sl.No</th>
                            <th>Components To Be Checked</th>
                            <th>Pass</th>
                            <th>Fail</th>
                            <th>Fixed on Spot</th>
                            <th>Quote To Follow</th>
                            <th>Comments (Fault Found)</th>
                        {   compToBeChecked.map((item, key) => { 
                        return(
                            <tr>
                                <td style={{ maxWidth:'40px' }}>{key+1}</td>
                                <td style={{ fontSize:'.7em', fontWeight:'bold' }} >{item.comp}</td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="Pass"  disabled={modalType==='view' }
                                     value={item.pass} onChange={(e)=>{ 
                                        let temp = {...item, pass : e.target.value}; 
                                        let tempArr = compToBeChecked
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setCompToBeChecked(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text"  className="jobCardDivInp" placeholder="Fail"  disabled={modalType==='view' }
                                     value={item.fail} onChange={(e)=>{ 
                                        let temp = {...item, fail : e.target.value}; 
                                        let tempArr = compToBeChecked
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setCompToBeChecked(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="Fixed on Spot"  disabled={modalType==='view' }
                                     value={item.fixed} onChange={(e)=>{ 
                                        let temp = {...item, fixed : e.target.value}; 
                                        let tempArr = compToBeChecked
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setCompToBeChecked(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="Quote To Follow"  disabled={modalType==='view' }
                                     value={item.quote} onChange={(e)=>{ 
                                        let temp = {...item, quote : e.target.value}; 
                                        let tempArr = compToBeChecked
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setCompToBeChecked(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="Comments"  disabled={modalType==='view' }
                                     value={item.comment} onChange={(e)=>{ 
                                        let temp = {...item, comment : e.target.value}; 
                                        let tempArr = compToBeChecked
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setCompToBeChecked(Object.assign([],tempArr)) }} />
                                </td>
                                
                            </tr>)
                    })
                    }
                    </tbody>
                    </table>
                    
                    <table className="jobCardDivTableContent jobCardTableStyle" >
                        <tbody>
                            <th>Electric Motor</th><th>NO.1</th><th>NO.2</th><th>NO.3</th><th>NO.4</th>
                            {   electricMotor.map((item, key) => { 
                        return(
                            <tr>
                                <td style={{ fontSize:'.7em', fontWeight:'bold' }} >{item.motor}</td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="No.1"  disabled={modalType==='view' }
                                     value={item.no1} onChange={(e)=>{ 
                                        let temp = {...item, no1 : e.target.value}; 
                                        let tempArr = electricMotor
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setElectricMotor(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text"  className="jobCardDivInp" placeholder="No.2"  disabled={modalType==='view' }
                                     value={item.no2} onChange={(e)=>{ 
                                        let temp = {...item, no2 : e.target.value}; 
                                        let tempArr = electricMotor
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setElectricMotor(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="No.3"  disabled={modalType==='view' }
                                     value={item.no3} onChange={(e)=>{ 
                                        let temp = {...item, no3 : e.target.value}; 
                                        let tempArr = electricMotor
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setElectricMotor(Object.assign([],tempArr)) }} />
                                </td>
                                <td>
                                    <input type="text" className="jobCardDivInp" placeholder="No.4"  disabled={modalType==='view' }
                                     value={item.no4} onChange={(e)=>{ 
                                        let temp = {...item, no4 : e.target.value}; 
                                        let tempArr = electricMotor
                                        tempArr.splice(key,1, Object.assign({}, temp))
                                        setElectricMotor(Object.assign([],tempArr)) }} />
                                </td>
                                
                            </tr>)}
                            )}
                        </tbody>
                    </table>
                        
                    <div className="jobCardDivTableContent jobCardTableStyle">
                            <div className="jobCardDivTHead">
                                <div style={{ width:'20px' }}>Sl.No</div>
                                <div>Asset ID</div>
                                <div>Work Completed</div>
                                <div>Part Used</div>
                                <div>Part Quantity</div>
                            </div>
                            {   assetsTable.map((item, key) => { 
                            return(
                                <div className="jobCardDivTHead">
                                    <div style={{ width:'20px' }}>{key+1}</div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Asset ID"  disabled={modalType==='view' }
                                         value={item.assets_id} onChange={(e)=>{ 
                                            let temp = {...item, assets_id : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Worked Completed"  disabled={modalType==='view' }
                                         value={item.work} onChange={(e)=>{ 
                                            let temp = {...item, work : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    
                                    <div>
                                        <input type="text" className="jobCardDivInp" placeholder="Part Used"  disabled={modalType==='view' }
                                         value={item.part_used} onChange={(e)=>{ 
                                            let temp = {...item, part_used : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    <div>
                                        <input type="text"  className="jobCardDivInp" placeholder="Part Quantity"  disabled={modalType==='view' }
                                         value={item.part_quantity} onChange={(e)=>{ 
                                            let temp = {...item, part_quantity : e.target.value}; 
                                            let tempArr = assetsTable
                                            tempArr.splice(key,1, Object.assign({}, temp))
                                            setAssetsTable(Object.assign([],tempArr)) }} />
                                    </div>
                                    
                                </div>)
                    })
                    }
                        {   modalType==='view'  ?   null    :
                        <div style={{ display:'flex', flexDirection:'row', width:'100%', justifyContent:'flex-end', alignItems:'center', margin:'20px 0' }}>
                            <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(true)} ><AddCircleOutline style={{ fontSize:'16px' }}/> Add Row</div>
                            {   assetsTable.length>3 ?
                                <div className="header-button-top newJobCardBtnActive" onClick={() => addRem(false)}><RemoveCircleOutline style={{ fontSize:'16px' }}/> Remove Row</div>  :   null
                            }
                            </div>
                        }
                        </div>

                    </div>

                    <div className="jobCardInputDivSec" >
                        <div>Start Time(Rectify Issue)</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{startTime}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Start Time(Rectify Issue)" value={startTime} onChange={(e)=>setStartTime(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Finish Time</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{finishTime}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Finish Time" value={finishTime} onChange={(e)=>setFinishTime(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Total Time</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{totalTime}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Total Time" value={totalTime} onChange={(e)=>setTotalTime(e.target.value)} />
                            }
                        </div>
                    </div>
                    

                    <div className="jobCardInputDivSec" style={{ width:'50%', flexDirection:'row-reverse' }} >
                        <div >Customer Unavailable</div>
                        <div style={{ width:'20%' }} >
                            <input type="checkbox" className="jobCardDivInp" style={{ height:'1.5vh' }} disabled={modalType==='view'? true : false}
                             checked={custUnavail} onChange={()=>setCustUnavail(!custUnavail)} />
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Customer Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{client_name}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Customer Name" value={client_name} onChange={(e)=>setClientName(e.target.value)} />
                            }
                        </div>
                    </div>
                    <div className="jobCardInputDivSec" >
                        <div>Technician Name</div>
                        <div>
                            <span>:</span>
                            {   modalType==='view'   ?   
                            <p  className="jobCardDivInp" style={{ display:'flex', border:'none', alignItems:'center' }}>{jobTechnician}</p>   :
                            <input type="text" className="jobCardDivInp" placeholder="Technician Name" value={jobTechnician} onChange={(e)=>setJobTechnician(e.target.value)} />
                            }
                        </div>
                    </div>
                   
            </div>
            {   modalType!=='view'  ?
            <div style={{ display:'flex', alignContent:'center', justifyContent:'center' }}>
                <div 
                className={ clientId ? "header-button-top newJobCardBtnActive" : "header-button-top newJobCardBtnActive newJobCardBtnDisActive"}
                onClick={()=>{ 
                    if(modalType!=='verify')
                        addJobCard()
                    else if(!jobObjConnected)
                        addJobCard()
                    }} 
                  style={{ width:'15vw', height:'5vh', textAlign:'center' }}>
                {modalType==='add' ? 'Add Job Card to Collection' : modalType==='verify' ? jobObjConnected ?
                `This job card is already in use `
                :   'Update Job Card (Deselect the red checkbox to remove from collection)' :
                 'Update Job Card (Deselect the red checkbox to remove from collection)'} 
                </div>
            </div>
            :   null
            }
        </div>
    );
}

export default AssetCheck;