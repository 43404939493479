import React from 'react'
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { 
    //EnviroBrandLogo, EnviroDestructionLogo, EnviroHillsLogo, EnviroPumpLogo, 
    EnviroWasteLogo } from '../../../common/Images'

function Header({ permissions }) {
    const { pathname } = useLocation()

    let navHeadStatus = true;
    let chk_rout_o = pathname.split('/')
    let checking_value = '/' + chk_rout_o[1] + '/' + chk_rout_o[2] + '/generate-quote/' 
    let chk_route = pathname.split(checking_value)
    if(chk_route[0] === '' && chk_route[1]) {
        navHeadStatus = false
    }

    
    // const EnviroWaste = () => {

    //     if( permissions && permissions.sales.waste ) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
            
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'waste/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
            
    //         return (
    //             <Link to={route_path} data-testid="enviro_waste" 
    //             className={
    //                 //routing_path[2] === 'waste' ? 
    //                 "header-button-top header-button-top-active" 
    //                 //: "header-button-top"
    //                 }>
    //                 <p>Enviro Waste</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }

    // const EnviroPump = () => {
    //     if(permissions && permissions.sales.pumps) {
    //         let routing_path = pathname.split('/')
    // let route_path = '/'
    
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'pumps/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} data-testid="enviro_pumps" className={routing_path[2] === 'pumps' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Pumps</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }


    // const EnviroDestruction = () => {
    //     if(permissions && permissions.sales.destruction) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
            
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'destruction/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} data-testid="enviro_destruction" className={routing_path[2] === 'destruction' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Destruction</p>
    //             </Link>
    //         )
    //     } else return null

    //     // return (
    //     //     <div className="header-button-top disabled-btn">
    //     //         <p>Enviro Destruction</p>
    //     //     </div>
    //     // )
        
    // }

    // const EnviroHills = () => {
    //     if(permissions && permissions.sales.hills) {
    //         let routing_path = pathname.split('/')
    //         let route_path = '/'
            
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'hills/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} data-testid="enviro_hills" className={routing_path[2] === 'hills' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro Hills</p>
    //             </Link>
    //         )
    //     } else return null
        
    // }

    // const EnviroAll = () => {
    //     if(permissions && permissions.sales.waste && permissions.sales.pumps && permissions.sales.hills && permissions.sales.destruction) {
    //     //     let routing_path = pathname.split('/')
    //     //     let route_path = '/'
    //     //     routing_path.map((item, key) => {
    //     //         if(item) {
    //     //             if(key === 2) {
    //     //                 route_path = route_path + 'all/'
    //     //             } else {
    //     //                 route_path = route_path + routing_path[key] + '/'
    //     //             }
                    
    //     //         }
    //     //         return null
    //     //     })
    //     //     return (
    //     //         <Link to={route_path} className={routing_path[2] === 'all' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //     //             <p>Enviro All</p>
    //     //         </Link>
    //     //     )
    //     // } else return null

    //     let routing_path = pathname.split('/')
    //     let route_path = '/'
        
    //         routing_path.map((item, key) => {
    //             if(item) {
    //                 if(key === 2) {
    //                     route_path = route_path + 'all/'
    //                 } else {
    //                     route_path = route_path + routing_path[key] + '/'
    //                 }
                    
    //             }
    //             return null
    //         })
    //         return (
    //             <Link to={route_path} data-testid="enviro_all" className={routing_path[2] === 'all' ? "header-button-top header-button-top-active" : "header-button-top"}>
    //                 <p>Enviro All</p>
    //             </Link>
    //         )
    //     }
    //     else return null
        
    // }

    return (
        <div className="common-header">
            <div className="common-brand-layout">
                <img className="common-brand-img" src={
                    // chk_rout_o[2] === 'waste' ?
                    EnviroWasteLogo
                    // :
                    // chk_rout_o[2] === 'pumps' ?
                    // EnviroPumpLogo
                    // :
                    // chk_rout_o[2] === 'hills' ?
                    // EnviroHillsLogo
                    // :
                    // chk_rout_o[2] === 'destruction' ?
                    // EnviroDestructionLogo
                    // :
                    // EnviroBrandLogo
                } style={{ height:'100%' }}  alt='enviro logo' />
            </div>
            {
                navHeadStatus ? (
                    <div className="header-button-layout">
                        {/* <EnviroWaste /> */}
                        {/* <EnviroHills />
                        <EnviroPump />
                        <EnviroDestruction />
                        <EnviroAll /> */}
                    </div>
                ) : (
                    <Link to={checking_value} style={{ textDecoration: 'none' }} data-testid="header_back" className="header-button-layout">
                        <div className="header-button-top">
                            <p>Back</p>
                        </div>
                    </Link>
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { user, permissions } = state.userLogin
    return { user, permissions }
}


export default connect(mapStateToProps) (Header)
