import { 
     SALES_TEMP_CLIENTS, SALES_CREATE_TEMP_CLIENT, SALES_QUOTE_GENERATE, SALES_COMMON_QUOTE_REGISTER,
     
     SALES_FOLDER_MARKETING,SALES_FOLDER_POWER_POINT, SALES_FOLDER_PRICING, SALES_QUOTE_TENDER, SALES_PERFORM_VIEW,

     SALES_WASTE_SALE_PERFORMANCE, SALES_WASTE_JOBS, SALES_WASTE_TEMPLATES, SALES_FOLDER_DESCRIPTION_WASTE, 
     SALES_WASTE_UNDER_FOLDERS, SALES_WASTE_ATTACH_FOLDERS, SALES_WASTE_PERFORM_VIEW, SALES_WASTE_PERFORM_FILTER_VIEW, SALES_WASTE_HOME_JOBS, 

     SALES_PUMP_JOBS, SALES_PUMP_FOLDER_DESCRIPTION_WASTE, SALES_PUMP_FOLDER_MARKETING, SALES_PUMP_FOLDER_POWER_POINT, SALES_PUMP_FOLDER_PRICING, 
     SALES_PUMP_QUOTE_TENDER, SALES_PUMP_QUOTE_GENERATE, SALES_PUMP_ATTACH_FOLDERS, SALES_PUMP_PERFORM_VIEW, SALES_PUMP_PERFORM_FILTER_VIEW, 
     
     SALES_HILLS_JOBS, SALES_HILLS_FOLDER_DESCRIPTION_WASTE, SALES_HILLS_FOLDER_MARKETING, SALES_HILLS_FOLDER_POWER_POINT, SALES_HILLS_FOLDER_PRICING, 
     SALES_HILLS_QUOTE_TENDER, SALES_HILLS_SALE_PERFORMANCE, SALES_HILLS_QUOTE_GENERATE, SALES_HILLS_PERFORM_VIEW, SALES_HILLS_ATTACH_FOLDERS, 
     SALES_HILLS_PERFORM_FILTER_VIEW, 
     
     SALES_DESTRUCTION_JOBS, SALES_DESTRUCTION_ATTACH_FOLDERS, SALES_DESTRUCTION_FOLDER_DESCRIPTION_WASTE, SALES_DESTRUCTION_FOLDER_MARKETING, 
     SALES_DESTRUCTION_FOLDER_POWER_POINT, SALES_DESTRUCTION_FOLDER_PRICING, SALES_DESTRUCTION_QUOTE_TENDER, SALES_DESTRUCTION_QUOTE_GENERATE, 
     SALES_DESTRUCTION_SALE_PERFORMANCE, SALES_DESTRUCTION_PERFORM_VIEW, SALES_DESTRUCTION_PERFORM_FILTER_VIEW, 
     
     SALES_COMMON_BASE, SALES_QUOTE_FILTER_COMMON, SALES_JOB_ACTION,SALES_PERFORM_FILTER_VIEW, SALES_GENERATE_TEMPLATE_DRAFT, SALES_CRUD_TEMPLATE, 
     SALES_ALL_DRAFTS, SALES_GENERATE_SINGLE_TEMPLATE, SALES_QUOTE_CLIENTS_SEARCH, SALES_JOB_MAIN_FILTER, SALES_JOB_SITE_SEARCH, SALES_QUOTE_SITE_SEARCH, SALES_TEMPLATE_REEDIT, SALES_ALL_CLIENTS_LIST, SALES_ALL_SALE_PERFORMANCE, SALES_COMMON_PERFORM_FILTER_VIEW, SALES_PERFORMANCE_DETAIL_EXPORT_REPORT, SALES_QUOTE_GENERATE_ONLY, SALES_MULTI_QUOTE_GENERATE, SALES_QUOTE_REGISTER_EXPORT, SALES_REGISTER_LIST_EXPORT, SALES_JOB_SITE_JOBCODE_SEARCH, SALES_QUOTE_SITE_QUOTEID_SEARCH } from "../../../store/endpoint"
import Api from '../../../store/api'
import { FileDownloader } from "../../../common/file_downloader"
import Swal from "sweetalert2"




// *****************************************************
//                       A L L   T A B S
// *****************************************************

export const reloadPage = () => {
    return function(dispatch){
        dispatch({
            type:'RELOAD_PAGE',
        })
    }
}

export const reloadTemplates = () => {
    return function(dispatch){
        dispatch({
            type: 'RELOAD_TEMPLATE_LOADER'
        })
    }
}

export const fetch_all_clientsList = (tabType) =>{
    return function (dispatch){
        Api('get',`${SALES_ALL_CLIENTS_LIST}${tabType==='all' ? '' : tabType+'/'}`).then(
            res =>{
                console.log('fetchALLClients',res)
                if(res)
                    dispatch({
                        type:'ALL_CLIENTS_LISTING',
                        clients: res
                    })
            }
                
        )
    }
}

export const fetch_all_job_list_common = ( page_no, filter) => {
    return function (dispatch) {
        //let get_url = `${SALES_WASTE_JOBS}${page_no}/?limit=8`
        let get_url = `${SALES_COMMON_BASE}${'all'}/${page_no}/?limit=8`
        if(filter !== 'all') {
            get_url = `${SALES_COMMON_BASE}${'all'}/filter/${filter}/${page_no}/?limit=8`
        }
        Api('get', get_url).then(response => {
            
                dispatch({ type: 'JOB_LIST', jobs: response, page: page_no, filter: filter })
            
        })
    }
}

// export const fetch_common_quote_registers_list = (page_no) => {
//     return function (dispatch) {
//         // let get_url = `${SALES_WASTE_QUOTE_REGISTER}${page_no}/?limit=8`
//         let get_url = `${SALES_COMMON_BASE}${'all'}/${page_no}/?limit=8`
//         Api('get', get_url).then(response => {
//                 dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
//         })
//     }
// }

export const status_change_sales = (btn, id, success) =>{
    return function(dispatch){
        let get_url = `${SALES_JOB_ACTION}${btn}/${id}/`
        Api('get', get_url, '', success).then(response => {
            // console.log(response)
            if(response){
                dispatch({ type: 'JOB_LIST_ACTION',
                    job_id:   id,
                    btn:    btn
                })
            }
        })
    }
}

export const sales_perform_view_all = (managerId, pageNo, type, yr=null, month=null) =>{
    return function (dispatch){
        let get_url=''
        let dt = new Date()
        let dateFilter = `?month=${month? month : dt.getMonth()}&&year=${yr ? yr : dt.getFullYear()}`
    if(type==='accepted')
        get_url = `${SALES_PERFORM_FILTER_VIEW}accepted/${managerId}/${pageNo}/${dateFilter}`
    else if(type==='rejected')
        get_url = `${SALES_PERFORM_FILTER_VIEW}rejected/${managerId}/${pageNo}/${dateFilter}`
    else if(type==='pending')
        get_url = `${SALES_PERFORM_FILTER_VIEW}pending/${managerId}/${pageNo}/${dateFilter}`
    else
        get_url = `${SALES_PERFORM_VIEW}${managerId}/${pageNo}/${dateFilter}`
    
    Api('get', get_url).then(response => {
       
            dispatch({ type: 'SALES_PERFORMANCE_VIEW', perform: response, filterType: type, page: pageNo })
            
        })
    }
}

export const takeSalesPerformPrint = (tab, id, status, yr, month, success=null, failed=null) =>{
    Api('get',`${SALES_PERFORMANCE_DETAIL_EXPORT_REPORT}${tab}/${id}/${status}/export/?month=${month}&&year=${yr}`)
        .then(res =>{ 
            FileDownloader(res,'SALESPERFORMANCE','.csv');
            if(success)
                success()
            })
        .catch(err =>{ if(failed){ failed() }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        } })
}


export const sales_perform_view_common = (managerId, pageNo, type, tabType, filterMY=null, callBk=null) =>{
    return function (dispatch){
        console.log('managerId=>',managerId, 'pageNo=>', pageNo, 'type=>', type, 
        'tabType=>',tabType,'filterMY=>', filterMY=null,'callBk=>', callBk=null)
    if(callBk!==null){
        callBk(true)
    }
        let get_url=''
        let dt = new Date()
        let dateFilter = filterMY ? filterMY : `&&month=${dt.getMonth()+1}&&year=${dt.getFullYear()}`
    
    if(type==='accepted')
        get_url = `${SALES_COMMON_PERFORM_FILTER_VIEW.replace('TABTYPE',tabType)}accepted/${managerId}/${pageNo}/?limit=20${dateFilter}`
    else if(type==='rejected')
        get_url = `${SALES_COMMON_PERFORM_FILTER_VIEW.replace('TABTYPE',tabType)}rejected/${managerId}/${pageNo}/?limit=20${dateFilter}`
    else if(type==='pending')
        get_url = `${SALES_COMMON_PERFORM_FILTER_VIEW.replace('TABTYPE',tabType)}pending/${managerId}/${pageNo}/?limit=20${dateFilter}`
    else
        get_url = `${SALES_PERFORM_VIEW}${tabType}/${managerId}/${pageNo}/?limit=20${dateFilter}`
    
    Api('get', get_url).then(response => {
        if(callBk!==null){
            callBk(false)
        }
            dispatch({ type: 'SALES_PERFORMANCE_VIEW', perform: response, filterType: type, filterMY: filterMY, page: pageNo })
            
        })
    }
}


export const fetch_quote_drafts = (type) =>{
    return function (dispatch){
        
        Api('get', `${SALES_ALL_DRAFTS}${type}/`).then(response => {
            console.log("fetch_quote_drafts c-nil",response)
            dispatch({
                type:   'DRAFTED_TEMPLATES',
                templatesList: response
            })
        })
    }
}

export const fetch_quote_client_draft = (content, type, id, success, failed) =>{
    return function (dispatch){
        if(content===''){
            Api('get',`${SALES_GENERATE_TEMPLATE_DRAFT}${type}/${id}/`).then(response => {
                console.log(response)
                dispatch({
                    type:'SALE_CLIENT_DRAFTS',
                    templateList: response
                })
                dispatch(fetch_quote_drafts(type))
            })
        }
        else{
            Api('post-form',`${SALES_GENERATE_TEMPLATE_DRAFT}${type}/${id}/`, content, success, failed).then(response => {
                console.log(response)
                dispatch(
                    fetch_quote_client_draft('', type, id)
                )
                dispatch(fetch_quote_drafts(type))

            })
        }

    }
}

export const fetch_quote_template_new = ( content, type, edit, success, failed) =>{
    
    return function (dispatch){
        if(content!==''){
            if(edit!=="")
            Api('post-form', `${SALES_TEMPLATE_REEDIT}${type}/${edit}/`, content, success, failed).then(response => {
                console.log("fetch_quote_template c: ", response)
                for(var k of content.entries())
                    console.log(k[0]," ",k[1])
            })
            else
            Api('post-form', `${SALES_CRUD_TEMPLATE}${type}/`, content, success, failed).then(response => {
                console.log("fetch_quote_template c: ", response)
                for(var k of content.entries())
                    console.log(k[0]," ",k[1])
            })
        }else
            Api('get', `${SALES_CRUD_TEMPLATE}${type}/`).then(response => {
                console.log("fetch_quote_template ", response)
                dispatch({
                    type: 'SALE_EIGHT_TEMPLATE',
                    newTemplate: response
                })
            })
    }
} 

export const fetch_single_draft = (content, id, success, failed, type='') =>{
    return function (dispatch){
        if(content!==null)
            Api('get', `${SALES_GENERATE_SINGLE_TEMPLATE}${id}/`).then(response => {
            
            dispatch({
                type: 'SINGLE_CLIENT_DRAFT',
                clientDraft: response
            })

            if(type==='draft')            
                dispatch({
                    type: 'SET_SAVED_DRAFT_CLIENT',
                    content: response
                })  
        })
        else if(id!==null)
        Api('get', `${SALES_CRUD_TEMPLATE}${type}/${id}/`).then(response => {
            // console.log('get-idNULL',response)
            dispatch({
                type: 'SINGLE_CLIENT_DRAFT',
                clientDraft: response.template
            })
        })
        else{
            Api('post-form',`${SALES_GENERATE_SINGLE_TEMPLATE}${id}/`, content, success, failed).then(response => {
                console.log('single-post',response)
            })
        }
    }
}

export const edit_single_draft = (content, id, success, failed) =>{
    
    return function (dispatch){
        Api('patch-form',`${SALES_GENERATE_SINGLE_TEMPLATE}${id}/`, content, success, failed).then(
            dispatch(
                fetch_single_draft('',id)
            )
        )
    }
}


export const del_single_draft = ( id, success, failed) =>{
    return function (dispatch){
        Api('delete',`${SALES_GENERATE_SINGLE_TEMPLATE}${id}/`).then(res => {
            success()
        }).catch(err => {
            failed()
        })
    }
}

export const fetch_common_quote_registers_list = (page_no, tabType) => {
    return function (dispatch) {
        dispatch({type:'JOB_LIST_FETCHING'})
        let get_url = `${SALES_COMMON_QUOTE_REGISTER}${tabType}/${page_no}/?limit=20`
        Api('get', get_url).then(response => {
                dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
        })
    }
}


// *****************************************************
//                         W A S T E
// *****************************************************

export const fetch_job_list = (page_no, filter) => {
    return function (dispatch) {
        let get_url = `${SALES_WASTE_JOBS}${page_no}/?limit=8`
        if(filter !== 'all') {
            get_url = `${SALES_WASTE_JOBS}filter/${filter}/${page_no}/?limit=8`
        }
        Api('get', get_url).then(response => {
            
                dispatch({ type: 'JOB_LIST', jobs: response, page: page_no, filter: filter })
            
        })  
    }
}


export const fetch_job_list_for_all = (page_no, tab_type, filter) => {
    return function (dispatch) {
        let get_url = `${SALES_WASTE_HOME_JOBS}${tab_type}/${page_no}/?limit=20`
        if(filter !== 'all') {
            get_url = `${SALES_WASTE_HOME_JOBS}filter/${tab_type}/${filter}/${page_no}/?limit=20`
        }
        dispatch({type:'JOB_LIST_FETCHING'})
        Api('get', get_url).then(response => {
            
                dispatch({ type: 'JOB_LIST', jobs: response, page: page_no, filter: filter })
            
        })  
    }
}

// export const fetch_waste_quote_registers_list = (page_no) => {
//     return function (dispatch) {
//         let get_url = `${SALES_WASTE_QUOTE_REGISTER}${page_no}/?limit=8`
//         Api('get', get_url).then(response => {
//                 dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
//         })
//     }
// }
export const fetch_common_sale_performance = (year, month, tabType) => {
    return function (dispatch) {
        let get_url = `${SALES_ALL_SALE_PERFORMANCE}${tabType}/${year}/${month}/`
        Api('get', get_url).then(response => {
            
            dispatch({ type: 'SALE_PERFORMANCE', sales: response.app_data, month, year })
            
        })
    }
}


export const fetch_waste_sale_performance = (year, month) => {
    return function (dispatch) {
        let get_url = `${SALES_WASTE_SALE_PERFORMANCE}${year}/${month}/`
        Api('get', get_url).then(response => {
            
            dispatch({ type: 'SALE_PERFORMANCE', sales: response.app_data, month, year })
            
        })
    }
}

export const fetch_waste_templates = (tabType='') => {
    return function (dispatch) {
        Api('get', `${ tabType==='' ? SALES_WASTE_TEMPLATES : SALES_WASTE_TEMPLATES + tabType +'/' }`).then(response => {
            console.log("WASTE-TEMPLATE: ",response)
                dispatch({type: 'SALES_TEMPLATES', templates: response})
            
        })
    }
}

export const fetch_folders_under_files = (tab) => {
    return function (dispatch) {
        Api('get', `${SALES_WASTE_UNDER_FOLDERS}${tab}/`).then(response => {
            console.log('test response', response)
            
                dispatch({type: 'UNDER_FOLDERS', folders: response ? response.folders : []})
            
        })
    }
}

export const fetch_temp_clients = (tabType) => {
    return function (dispatch) {
        Api('get', `${SALES_TEMP_CLIENTS}${tabType}/`).then(response => {
            if(response) {
                dispatch({type: 'TEMP_CLIENTS', clients: response.length>0 ? response : []})
            }
        })
    }
}

export const create_temp_clients = (data, tabType, success, failed) => {
    return function (dispatch) {
        const success_fx = () => {
            dispatch(fetch_temp_clients(tabType))
            success()
        }
        Api('post-form', SALES_CREATE_TEMP_CLIENT, data, success_fx, failed )
    }
}

export const fetch_attach_folders = () => {
    return function (dispatch) {
        Api('get', SALES_WASTE_ATTACH_FOLDERS).then(response => {
            console.log(response.folders)
                if(response.folders.length > 0) {
                    dispatch({type: 'ATTACH_FOLDERS', folders: response.folders})
                }
        })
    }
}

export const fetch_sales_folder_market = (type, success, failed) => {
    let url = ''
    if(type === 'description') {
        url = SALES_FOLDER_DESCRIPTION_WASTE
    }
    if(type === 'marketing') {
        url = SALES_FOLDER_MARKETING
    }
    if(type === 'power-point') {
        url = SALES_FOLDER_POWER_POINT
    }
    if(type === 'pricing') {
        url = SALES_FOLDER_PRICING
    }
    if(type === 'tender') {
        url = SALES_QUOTE_TENDER
    }
    Api('get', url, '', success, failed)
}


export const sales_quoter_registration = (data, tab_type, status, selectedDraft, success, failed) => {
    for(let v of data.entries()){
        console.log('quote_register_form',v[0],v[1])
    }
    let api_url = ''
    if(selectedDraft!==null)
        api_url = `${SALES_MULTI_QUOTE_GENERATE}`
    else if(status===null)
        api_url = `${SALES_QUOTE_GENERATE_ONLY}${tab_type}/`
    else
        api_url = `${SALES_QUOTE_GENERATE}${tab_type}/`
    Api('post-form', api_url, data, success, failed)
}

export const sales_perform_view_waste = (managerId, pageNo, type) =>{
    return function (dispatch){
        let get_url=''
        console.log("CURRENT STATE",type)
    if(type==='accepted')
        get_url = `${SALES_WASTE_PERFORM_FILTER_VIEW}accepted/${managerId}/${pageNo}/`
    else if(type==='rejected')
        get_url = `${SALES_WASTE_PERFORM_FILTER_VIEW}rejected/${managerId}/${pageNo}/`
    else if(type==='pending')
        get_url = `${SALES_WASTE_PERFORM_FILTER_VIEW}pending/${managerId}/${pageNo}/`
    else
        get_url = `${SALES_WASTE_PERFORM_VIEW}${managerId}/${pageNo}/`

    Api('get', get_url).then(response => {
      
        dispatch({ type: 'SALES_PERFORMANCE_VIEW', perform: response, filterType: type, page: pageNo })
            
        })
    }
}


// *****************************************************
//                          P U M P
// *****************************************************

export const fetch_job_list_pump = (page_no, filter) => {
    return function (dispatch) {
        let get_url = `${SALES_PUMP_JOBS}${page_no}/?limit=8`
        if(filter !== 'all') {
            get_url = `${SALES_PUMP_JOBS}filter/${filter}/${page_no}/?limit=8`
        }
        Api('get', get_url).then(response => {
            
                dispatch({ type: 'JOB_LIST', jobs: response, page: page_no, filter: filter })
            
        })
    }
}

// export const fetch_pump_quote_registers_list = (page_no) => {
//     return function (dispatch) {
//         let get_url = `${SALES_PUMP_QUOTE_REGISTER}${page_no}/?limit=8`
//         Api('get', get_url).then(response => {
            
//                 dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
            
//         })
//     }
// }

export const fetch_pumps_attach_folders = () => {
    return function (dispatch) {
        Api('get', SALES_PUMP_ATTACH_FOLDERS).then(response => {
            console.log(response.folders)
                if(response.folders.length > 0) {
                    dispatch({type: 'ATTACH_FOLDERS', folders: response.folders})
                }
        })
    }
}


export const fetch_pumps_folder_market = (type, success, failed) => {
    let url = ''
    if(type === 'description') {
        url = SALES_PUMP_FOLDER_DESCRIPTION_WASTE
    }
    if(type === 'marketing') {
        url = SALES_PUMP_FOLDER_MARKETING
    }
    if(type === 'power-point') {
        url = SALES_PUMP_FOLDER_POWER_POINT
    }
    if(type === 'pricing') {
        url = SALES_PUMP_FOLDER_PRICING
    }
    if(type === 'tender') {
        url = SALES_PUMP_QUOTE_TENDER
    }
    Api('get', url, '', success, failed)
}


export const sales_quoter_registration_pump = (data, success, failed) => {
    Api('post-form', SALES_PUMP_QUOTE_GENERATE, data, success, failed)
}

export const sales_perform_view_pump = (managerId, pageNo, type) =>{
    return function (dispatch){
        let get_url=''
    if(type==='accepted')
        get_url = `${SALES_PUMP_PERFORM_FILTER_VIEW}accepted/${managerId}/${pageNo}/`
    else if(type==='rejected')
        get_url = `${SALES_PUMP_PERFORM_FILTER_VIEW}rejected/${managerId}/${pageNo}/`
    else if(type==='pending')
        get_url = `${SALES_PUMP_PERFORM_FILTER_VIEW}pending/${managerId}/${pageNo}/`
    else
        get_url = `${SALES_PUMP_PERFORM_VIEW}${managerId}/${pageNo}/`
        
    Api('get', get_url).then(response => {
       
            dispatch({ type: 'SALES_PERFORMANCE_VIEW', perform: response, filterType: type, page: pageNo })
            
        })
    }
}


// *****************************************************
//                          H I L L S
// *****************************************************

export const fetch_job_list_hills = (page_no, filter) => {
    return function (dispatch) {
        let get_url = `${SALES_HILLS_JOBS}${page_no}/?limit=8`
        if(filter !== 'all') {
            get_url = `${SALES_HILLS_JOBS}filter/${filter}/${page_no}/?limit=8`
        }
        Api('get', get_url).then(response => {
            
                dispatch({ type: 'JOB_LIST', jobs: response, page: page_no, filter: filter })
            
        })
    }
}

// export const fetch_hills_quote_registers_list = (page_no) => {
//     return function (dispatch) {
//         let get_url = `${SALES_HILLS_QUOTE_REGISTER}${page_no}/?limit=8`
//         Api('get', get_url).then(response => {
//                 dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
//         })
//     }
// }

export const fetch_hills_attach_folders = () => {
    return function (dispatch) {
        Api('get', SALES_HILLS_ATTACH_FOLDERS).then(response => {
            console.log(response.folders)
                if(response.folders.length > 0) {
                    dispatch({type: 'ATTACH_FOLDERS', folders: response.folders})
                }
        })
    }
}



export const fetch_hills_folder_market = (type, success, failed) => {
    let url = ''
    if(type === 'description') {
        url = SALES_HILLS_FOLDER_DESCRIPTION_WASTE
    }
    if(type === 'marketing') {
        url = SALES_HILLS_FOLDER_MARKETING
    }
    if(type === 'power-point') {
        url = SALES_HILLS_FOLDER_POWER_POINT
    }
    if(type === 'pricing') {
        url = SALES_HILLS_FOLDER_PRICING
    }
    if(type === 'tender') {
        url = SALES_HILLS_QUOTE_TENDER
    }
    Api('get', url, '', success, failed)
}


export const sales_quoter_registration_hills = (data, success, failed) => {
    Api('post-form', SALES_HILLS_QUOTE_GENERATE, data, success, failed)
}

export const fetch_hills_sale_performance = (year, month) => {
    return function (dispatch) {
        let get_url = `${SALES_HILLS_SALE_PERFORMANCE}${year}/${month}/`
        Api('get', get_url).then(response => {
            if(response.app_data && response.app_data.length > 0) {
                dispatch({ type: 'SALE_PERFORMANCE', sales: response.app_data, month, year })
            }
        })
    }
}

export const sales_perform_view_hills = (managerId, pageNo, type) =>{
    return function (dispatch){
        let get_url=''
    if(type==='accepted')
        get_url = `${SALES_HILLS_PERFORM_FILTER_VIEW}accepted/${managerId}/${pageNo}/`
    else if(type==='rejected')
        get_url = `${SALES_HILLS_PERFORM_FILTER_VIEW}rejected/${managerId}/${pageNo}/`
    else if(type==='pending')
        get_url = `${SALES_HILLS_PERFORM_FILTER_VIEW}pending/${managerId}/${pageNo}/`
    else
        get_url = `${SALES_HILLS_PERFORM_VIEW}${managerId}/${pageNo}/`
        
    Api('get', get_url).then(response => {
      
            dispatch({ type: 'SALES_PERFORMANCE_VIEW', perform: response, filterType: type, page: pageNo })
            
        })
    }
}





// *****************************************************
//                 D E S T R U C T I O N
// *****************************************************

export const fetch_job_list_destruction = (page_no, filter) => {
    return function (dispatch) {
        let get_url = `${SALES_DESTRUCTION_JOBS}${page_no}/?limit=8`
        if(filter !== 'all') {
            get_url = `${SALES_DESTRUCTION_JOBS}filter/${filter}/${page_no}/?limit=8`
        }
        Api('get', get_url).then(response => {
            
                dispatch({ type: 'JOB_LIST', jobs: response, page: page_no, filter: filter })
            
        })
    }
}

// export const fetch_destruction_quote_registers_list = (page_no) => {
//     return function (dispatch) {
//         let get_url = `${SALES_DESTRUCTION_QUOTE_REGISTER}${page_no}/?limit=8`
//         Api('get', get_url).then(response => {
//                 dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
//         })
//     }
// }

export const fetch_destruction_attach_folders = () => {
    return function (dispatch) {
        Api('get', SALES_DESTRUCTION_ATTACH_FOLDERS).then(response => {
            console.log(response.folders)
                if(response.folders.length > 0) {
                    dispatch({type: 'ATTACH_FOLDERS', folders: response.folders})
                }
        })
    }
}



export const fetch_destruction_folder_market = (type, success, failed) => {
    let url = ''
    if(type === 'description') {
        url = SALES_DESTRUCTION_FOLDER_DESCRIPTION_WASTE
    }
    if(type === 'marketing') {
        url = SALES_DESTRUCTION_FOLDER_MARKETING
    }
    if(type === 'power-point') {
        url = SALES_DESTRUCTION_FOLDER_POWER_POINT
    }
    if(type === 'pricing') {
        url = SALES_DESTRUCTION_FOLDER_PRICING
    }
    if(type === 'tender') {
        url = SALES_DESTRUCTION_QUOTE_TENDER
    }
    Api('get', url, '', success, failed)
}


export const sales_quoter_registration_destruction = (data, success, failed) => {
    Api('post-form', SALES_DESTRUCTION_QUOTE_GENERATE, data, success, failed)
}

export const fetch_destruction_sale_performance = (year, month) => {
    return function (dispatch) {
        let get_url = `${SALES_DESTRUCTION_SALE_PERFORMANCE}${year}/${month}/`
        Api('get', get_url).then(response => {
            if(response.app_data && response.app_data.length > 0) {
                dispatch({ type: 'SALE_PERFORMANCE', sales: response.app_data, month, year })
            }
        })
    }
}

export const sales_perform_view_destruction = (managerId, pageNo, type) =>{
    return function (dispatch){
        let get_url=''
    if(type==='accepted')
        get_url = `${SALES_DESTRUCTION_PERFORM_FILTER_VIEW}accepted/${managerId}/${pageNo}/`
    else if(type==='rejected')
        get_url = `${SALES_DESTRUCTION_PERFORM_FILTER_VIEW}rejected/${managerId}/${pageNo}/`
    else if(type==='pending')
        get_url = `${SALES_DESTRUCTION_PERFORM_FILTER_VIEW}pending/${managerId}/${pageNo}/`
    else
        get_url = `${SALES_DESTRUCTION_PERFORM_VIEW}${managerId}/${pageNo}/`
        
    Api('get', get_url).then(response => {
        
            dispatch({ type: 'SALES_PERFORMANCE_VIEW', perform: response, filterType: type, page: pageNo })
            
        })
    }
}

export const sales_quote_filter = (content, type, pageNo, page) =>{
    console.log('KEY: ',content.get('key'))
    return function (dispatch){
        dispatch({type:'JOB_LIST_FETCHING'})
        let get_url = `${SALES_QUOTE_FILTER_COMMON}${page}/`
        if(type==="date")
            get_url = get_url+'datetime/'+pageNo+'/?limit=20'
        else
            get_url = get_url+'site/'+pageNo+'/?limit=20'

        Api('post-form', get_url, content)
        .then(response => {
            dispatch({ type: 'SALES_QUOTE_SITEDATE_FILTER', quoteList : response, page: pageNo })
        })
        .catch(err =>{
            dispatch({ type: 'SALES_QUOTE_SITEDATE_FILTER', quoteList : [], page: pageNo })
        })
    }
}

export const sales_job_filter = (content, type, pageNo, page) =>{
    console.log('KEY/client id: ',content.get('client_id'))
    return function (dispatch){
        dispatch({type:'JOB_LIST_FETCHING'})
        let get_url = `${SALES_JOB_MAIN_FILTER}${page}/`
        if(type==="date")
            get_url = get_url+'date/?limit=20&page='+pageNo
        else
            get_url = get_url+'client/?limit=20&page='+pageNo

        Api('post-form', get_url, content)
        .then(response => {
            dispatch({ type: 'SALES_JOB_SITEDATE_FILTER', jobList : response, page: pageNo, filterData: content })
        })
        .catch(err =>{
            dispatch({ type: 'SALES_JOB_SITEDATE_FILTER', jobList : [], page: pageNo,  filterData: null })
        })
    }
}

export const sales_quote_client = (val) =>{
    return function (dispatch){
        let content = new FormData()
        content.append('key', val)
        let get_url = `${SALES_QUOTE_CLIENTS_SEARCH}`
        Api('post-form', get_url, content).then(
            response => {
                console.log(response)
                dispatch({
                    type: 'SALES_QUOTE_CLIENT_SEARCH',
                    temp:   response.temporary_client,
                    val:    val
                })
                dispatch({ type: 'CLIENT_LIST', clients: response.permenent })
            }
        )
    }
}

export const sales_quoteReg_client = (type, val, page_no, searchType) =>{
    return function (dispatch){
        dispatch({type:'JOB_LIST_FETCHING'})
        let content = new FormData()
        if(searchType==='clients')
            content.append('key', val)
        else if(searchType==='job_code')
            content.append('key', val)
        else
            content.append('quote_id', val)
        
        let get_url = searchType==='clients' ? `${SALES_QUOTE_SITE_SEARCH}${type}/?limit=20&&page=${page_no}`
        :   searchType==='job_code' ?
        `${SALES_QUOTE_SITE_QUOTEID_SEARCH}${type}/?limit=20&&page=${page_no}&key=${val}`
        :
        `${SALES_QUOTE_SITE_QUOTEID_SEARCH}${type}/?limit=20&&page=${page_no}&quote_id=${val}`
        
        // `${SALES_QUOTE_SITE_SEARCH}${type}/?limit=20&&page=${page_no}`
        Api(searchType==='clients' ? 'post-form' : 'get', get_url, content).then(
            response => {
                console.log(response)
                dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
            }
        )
    }
}

export const sales_jobSearch_client = (type, val, page_no, searchType) =>{
    return function (dispatch){
        let content = new FormData()
            content.append('key', val)
        let get_url = searchType==='clients' ? `${SALES_JOB_SITE_SEARCH}${type}/?limit=20&&page=${page_no}` 
        : `${SALES_JOB_SITE_JOBCODE_SEARCH}${type}/?limit=20&&page=${page_no}${searchType==='quote' ? 
        '&search_type=quote' : searchType==='ref_code' ? '&search_type=ref_code' : ''}&key=${val}`

        Api(searchType==='clients' ? 'post-form' : 'get', get_url, content).then(
            response => {
                console.log(response)
                dispatch({ type: 'JOB_LIST_CLIENT_SEARCH', jobs: response, page: page_no})
                
            }
        )
    }
}


export const takeSalesJobsQuotePrint = (type, page_no, filterData, success=null, failed=null) =>{
   
    Api('get',`${type? SALES_QUOTE_REGISTER_EXPORT : SALES_REGISTER_LIST_EXPORT}${
        filterData?.dateFilter || filterData?.siteFilter || (filterData?.searchFilter!=='' && filterData?.searchFilter)
        ? `?` : ''}${
        filterData  ?
            `${filterData?.dateFilter ? 
                `search_date=${filterData?.dateFilter}` : ''}${filterData?.siteFilter ? 
                    `${filterData?.dateFilter?'&':''}site=${filterData?.siteFilter}` : ''}${filterData?.searchFilter!=='' && filterData?.searchFilter ? 
                    `${filterData?.dateFilter||filterData?.siteFilter?'&':''}search_site=${filterData?.searchFilter}` : ''}`
    : ''}`)
        .then(res =>{ 
            if(res){
                FileDownloader(res,'Jobs_List','.csv');
                if(success)
                    success()
            }else{
                if(failed){ failed() }
                else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!'
                    })
                }
            }
            
            })
        .catch(err =>{ if(failed){ failed() }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            })
        } })
}

// export const fetch_all_tab_quote_registers_list = (page_no) => {
//     return function (dispatch) {
//         let get_url = `${SALES_ALL_QUOTE_REGISTER}${page_no}/?limit=8`
//         Api('get', get_url).then(response => {
//                 dispatch({ type: 'QUOTE_REGISTER', quotes: response, page: page_no })
//         })
//     }
// }


// export const sales_home_client_search = (val) =>{
//     return function (dispatch){
//         let content = new FormData()
//         content.append('key',val)
//         let get_url = `${SALES_JOB_MAIN_FILTER}`
//     }
// }