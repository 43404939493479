import Swal from 'sweetalert2'

const Failerrdevmsg = (res) => {
    const val = document.createElement("div");
      
    val.innerHTML = `<p>Developer Data &#9660;</p>`;
    
    // val.innerHTML = `Developer Data :${ openDrop ?
    //     res && res.Error ? Object.entries(res.Error).map(([k,v]) => `${k}=>${v}`) : res && res.dev_data ? Object.entries(res.dev_data).map(([k,v]) => `${k}=>${v}`) : res ? Object.entries(res).map(([k,v]) => `${k}=>${v}`) : '--'
    //    : <ArrowDropDownCircle/> }`;
    val.style.width = '100%';
    val.style.margin = '1%';
    val.style.padding = '1% 0';
    val.style.backgroundColor = 'rgba(255,0,0,0.1)';
    val.style.textAlign = 'center';
    val.style.borderRadius = '5px';
    val.style.border='1px solid red';
    val.onclick = function (){
       
          val.innerHTML = `<p>Developer Data :
          ${
             res && res.Error ? Object.entries(res.Error).map(([k,v]) => `${k}=>${v}`) : res && res.dev_data ? Object.entries(res.dev_data).map(([k,v]) => `${k}=>${v}`) : res ? Object.entries(res).map(([k,v]) => `${k}=>${v}`) : '--'
          }
          </p>`;
    }

    console.log(val)
    return( 
     Swal.fire({
         icon: 'error',
         title: 'Oops...',
         text: res ? res.app_data ? res.app_data : 'Something went wrong!' : 'Something went wrong!',
         footer: val
     }))
 

}

export default Failerrdevmsg;
