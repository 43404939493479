import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Sidebar from '../../../../common/sidebar'
import { Detailed, Header } from '../index'
import { CircularProgress } from '@material-ui/core';

const __DetailedStack = ({ team }) => {
    const { selected_employee, singleTeam } = team
    console.log('TEAM=>',team)
    if(selected_employee === undefined && !singleTeam) return <Redirect to='/team' />
    return (
        <div className="App">
            <Sidebar />
            <div className="main-layout">
                <Header page='detail' />
                {   singleTeam  ?
                <div style={{ position: 'absolute', marginTop: '20%', marginLeft: '50%' }}>
                   <CircularProgress />
                </div>
            :
                <Detailed />
                }
            </div>
        </div>
    )
}

const mapStateProps = (state) => {
    const { team } = state
    return {team}
}

export default connect(mapStateProps) (__DetailedStack)
