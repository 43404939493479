import React from 'react'
import Carousel from 'react-material-ui-carousel';
import { makeStyles } from '@material-ui/core/styles'
import { Box, Modal } from '@material-ui/core'
import { Close} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paperImg: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }
  }));
  

export default function FileCarousel({
    modalStatus,
    modalAction,
    folderName,
    fileArr
}){
    const classes = useStyles();
    const handleClose = () => {
        modalAction(false)
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >
            <div className="intranet-folder-modal-layout" style={{ height:'80vh', width:'70vw' }}>
                <div className="intranet-modal-title-layout" style={{ height:'10%' }}>
                    <p>{folderName ? folderName : 'Enviro Images'}</p>                    
                    <Close
                     onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
                </div>
                <div style={{ height:'90%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                {
                    fileArr ?
                        <Carousel
                            autoPlay={false}
                            animation={'slide'}
                            navButtonsAlwaysVisible={true}
                            fullHeightHover={true}
                            className="filesFolderCarousel"
                        >
                            {
                                fileArr.map((item, k) => {
                                    return(
                                        <Box key={k} 
                                            sx={{ height:'60%', display:'flex', alignItems:'center', justifyContent:"center" }}
                                            onClick={()=>{ 
                                                window.open(item.url, "_blank");
                                            }}
                                            >
                                            <img src={item.url} alt={'Not supported'} style={{ objectFit:'cover', width:'80%' }}/>
                                        </Box>
                                    )
                                })
                            }
                        </Carousel>
                    :
                        <p>Something went wrong</p>
                }          
                </div>      
            </div>

        </Modal>
    )
}

